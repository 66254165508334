import { useMemo } from 'react';

import { useAuth } from '@8base-react/auth';
import {
  AuthLink,
  SuccessLink,
  SubscriptionLink,
  isSubscriptionRequest,
  AuthHeadersLinkParameters,
} from '@8base/apollo-links';
import errorCodes from '@8base/error-codes';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider as BaseApolloProvider,
  defaultDataIdFromObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError as createErrorLink } from '@apollo/client/link/error';

import { processEnv } from '@builder/utils';

import { useDialogState } from '../DialogProvider';
import { useAppDispatch } from '../ReduxProvider';
import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';
import { BaseGraphQLError } from 'src/shared/types';
import { UI_BUILDER_EVENTS } from 'src/store';

export const ApolloProvider: React.FC = ({ children }) => {
  const { authClient } = useAuth();
  const send = useAppDispatch();
  const { openDialog } = useDialogState();

  const client = useMemo(() => {
    const getAuthState: AuthHeadersLinkParameters['getAuthState'] = options => {
      const skipAuth = options?.operation.getContext()[APOLLO_OPERATION_CONTEXT.NO_AUTH];
      if (skipAuth) {
        return {
          workspaceId: processEnv.getMultiTenantId(),
        };
      }

      const contextToken = options?.operation.getContext()[APOLLO_OPERATION_CONTEXT.AUTH_TOKEN];
      const contextWorkspace = options?.operation?.getContext()[
        APOLLO_OPERATION_CONTEXT.WORKSPACE_ID
      ];
      const { token } = authClient.getState();

      if (contextWorkspace) {
        return {
          token: contextToken || token,
          workspaceId: contextWorkspace,
        };
      }

      return {
        token: contextToken || token,
        workspaceId: processEnv.getMultiTenantId(),
      };
    };

    const onAuthError = async () => {
      await client.clearStore();

      authClient.batch(() => {
        authClient.purgeState();
        authClient.logout();
      });
    };

    const onIdTokenExpired = async () => {
      const { idToken, email } = await authClient.checkSession();

      const nextAuthState = {
        token: idToken,
        email,
      };

      authClient.setState(nextAuthState);
    };

    return new ApolloClient({
      connectToDevTools: true,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              appbuilder: {
                merge: true,
              },
            },
          },
        },
        dataIdFromObject: responseObject => {
          switch (responseObject.__typename) {
            case 'SystemQuery': {
              return 'SystemQuery';
            }
            case 'IntrospectionQueryResponse':
              return `IntrospectionQueryResponse:${responseObject.url}`;
            default:
              return defaultDataIdFromObject(responseObject);
          }
        },
      }),
      link: ApolloLink.from([
        createErrorLink(({ graphQLErrors = [], operation }) => {
          const isNotAuthorized = (graphQLErrors as BaseGraphQLError[]).some(
            error => error.code === errorCodes.NotAuthorizedErrorCode,
          );

          if (isNotAuthorized) {
            openDialog(DASHBOARD_DIALOGS.NOT_AUTHTORIZED_DIALOG_ID);
            return;
          }

          const context = operation.getContext();
          const ignoreErrorMessage = context[APOLLO_OPERATION_CONTEXT.IGNORE_ERROR_MESSAGE];
          const notificationErrorMessage = context[APOLLO_OPERATION_CONTEXT.ERROR_MESSAGE];

          if (ignoreErrorMessage) {
            return;
          }

          if (notificationErrorMessage) {
            send({
              type: UI_BUILDER_EVENTS.notificationSend,
              notification: {
                message: notificationErrorMessage,
                options: { variant: 'error' },
              },
            });
          } else {
            graphQLErrors.forEach(error => {
              if (error.message !== 'Deployment error.') {
                send({
                  type: UI_BUILDER_EVENTS.notificationSend,
                  notification: {
                    message: error.message,
                    options: { variant: 'error' },
                  },
                });
              }
            });
          }
        }),
        new SuccessLink({
          successHandler: ({ operation }) => {
            const notificationSuccessMessage = operation.getContext()[
              APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE
            ];

            if (notificationSuccessMessage) {
              send({
                type: UI_BUILDER_EVENTS.notificationSend,
                notification: {
                  message: notificationSuccessMessage,
                  options: {
                    variant: 'success',
                  },
                },
              });
            }
          },
        }),
        new AuthLink({ getAuthState, onAuthError, onIdTokenExpired }),
        ApolloLink.split(
          isSubscriptionRequest,
          new SubscriptionLink({
            uri: processEnv.getServerWssURL(),
            getAuthState,
            onAuthError,
            onIdTokenExpired,
          }),
          new BatchHttpLink({
            uri: operation => {
              const endpointUrl = operation.getContext()[APOLLO_OPERATION_CONTEXT.ENDPOINT_URL];
              if (endpointUrl) {
                return endpointUrl;
              }

              return processEnv.getServerURL();
            },
            batchKey: operation => {
              const noBatch = operation.getContext()[APOLLO_OPERATION_CONTEXT.NO_BATCH];
              const batchKey = operation.getContext()[APOLLO_OPERATION_CONTEXT.BATCH_KEY];
              const endpointUrl = operation.getContext()[APOLLO_OPERATION_CONTEXT.ENDPOINT_URL];
              const fallbackBatchKey = 'eager';

              if (noBatch) {
                return String(Math.random());
              }

              if (endpointUrl) {
                return `endpointUrl-${batchKey || fallbackBatchKey}`;
              }

              return batchKey || fallbackBatchKey;
            },
          }),
        ),
      ]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};
