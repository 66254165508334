import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { NS_ID, BASIC_PROPS_TOOLTIP, getBasicPropsSettings, BASIC_STYLE_TOOLTIP, } from '../../common';
import { getBackgroundSection, getBorderSection, getCSSSection, getCommonActionListSettings, getCommonActionSettings, getCursorSection, getDivider, getFlexChildSection, getGridChildSection, getPropertySections, getSpacingSection, } from '../../common/settings';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
import { getCommonBuilderComponentsSpeedDialActionSettings } from '../BuilderComponentsSpeedDialAction/BuilderComponentsSpeedDialAction.settings';
export const getBuilderComponentsSpeedDialSettings = () => {
    var _a, _b;
    return ({
        properties: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.properties.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        name: NS_ID.properties.basic,
                        titleDescription: BASIC_PROPS_TOOLTIP,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ariaLabel'],
                                name: 'ariaLabel',
                                label: 'Aria Label',
                                required: true,
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['direction'],
                                name: 'direction',
                                label: 'Direction',
                                options: ['down', 'left', 'right', 'up'].map(createSelectOptionByValue),
                            },
                            getDivider(),
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['open'],
                                name: 'open',
                                label: 'Open',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['hidden'],
                                name: 'hidden',
                                label: 'hidden',
                            },
                            getDivider(),
                            {
                                type: COMPONENT_SETTING_TYPES.node,
                                name: 'icon',
                                label: 'Icon',
                                keyPath: ['icon'],
                                required: true,
                                items: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        settings: getCommonIconSettings(),
                                        predefineds: {
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                            props: Object.assign(Object.assign({}, (((_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props) || {})), { fontSize: 'small', name: 'Add' }),
                                        },
                                    },
                                ],
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.node,
                                name: 'openIcon',
                                label: 'Open Icon',
                                keyPath: ['openIcon'],
                                required: true,
                                items: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        settings: getCommonIconSettings(),
                                        predefineds: {
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                            props: Object.assign(Object.assign({}, (((_b = BuilderComponentsIcon.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props) || {})), { fontSize: 'small', name: 'Close' }),
                                        },
                                    },
                                ],
                            },
                            getDivider(),
                            {
                                type: COMPONENT_SETTING_TYPES.section,
                                title: 'Actions',
                                name: 'actions-items',
                                children: [
                                    {
                                        type: COMPONENT_SETTING_TYPES.nodeList,
                                        label: 'Actions',
                                        name: 'children',
                                        keyPath: ['children'],
                                        itemLabelKeyPath: ['tooltipTitle'],
                                        itemLabelNodePath: ['tooltipTitle', 0, 'tooltipTitle', 0],
                                        items: [
                                            {
                                                name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDialAction,
                                                settings: getCommonBuilderComponentsSpeedDialActionSettings(),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            getDivider(),
            ...getBasicPropsSettings(),
            getDivider(),
            ...getPropertySections(),
        ],
        style: [
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Basic',
                name: NS_ID.style.basic,
                titleDescription: BASIC_STYLE_TOOLTIP,
                expandedByDefault: true,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Floating Action Button',
                        name: 'FabProps',
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['FabProps', 'variant'],
                                label: 'Variant',
                                options: ['circular', 'extended'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['FabProps', 'size'],
                                label: 'Size',
                                options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['FabProps', 'color'],
                                label: 'Color',
                                options: [
                                    'default',
                                    'error',
                                    'info',
                                    'inherit',
                                    'primary',
                                    'secondary',
                                    'success',
                                    'warning',
                                ].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['FabProps', 'sx', '&:hover', 'bgcolor'],
                                label: 'Hover Color',
                                options: [
                                    { label: 'Default', value: 'default.main' },
                                    { label: 'Error', value: 'error.main' },
                                    { label: 'Info', value: 'info.main' },
                                    { label: 'Inherit', value: 'inherit.main' },
                                    { label: 'Primary', value: 'primary.main' },
                                    { label: 'Secondary', value: 'secondary.main' },
                                    { label: 'Success', value: 'success.main' },
                                    { label: 'Warning', value: 'warning.main' },
                                ],
                            },
                            getDivider(),
                            {
                                type: COMPONENT_SETTING_TYPES.section,
                                name: 'advanced',
                                title: 'Advanced',
                                titleDescription: {
                                    title: 'Advanced',
                                    subtitle: 'Additional component-specific attributes',
                                },
                                expandedByDefault: false,
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['FabProps', 'disableElevation'],
                                        label: 'Disable Elevation',
                                    },
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['FabProps', 'disableFocusRipple'],
                                        label: 'Disable Focus Ripple',
                                    },
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['FabProps', 'disableRipple'],
                                        label: 'Disable Ripple',
                                    },
                                ],
                            },
                        ],
                    },
                    getDivider(),
                    getSpacingSection(),
                    getDivider(),
                    getFlexChildSection(),
                    getDivider(),
                    getGridChildSection(),
                    getDivider(),
                    getBackgroundSection(),
                    getDivider(),
                    getBorderSection({ prefixPath: [], systemPrefixPath: [] }),
                    getDivider(),
                    getCursorSection(),
                    getDivider(),
                    getCSSSection(),
                ],
            },
        ],
        events: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.eventsSection,
                        name: 'events',
                        title: 'Actions',
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onOpenAction',
                                label: 'On Open',
                                keyPath: ['onOpen'],
                                children: getCommonActionSettings(['onOpen']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onCloseAction',
                                label: 'On Close',
                                keyPath: ['onClose'],
                                children: getCommonActionSettings(['onClose']),
                            },
                            ...getCommonActionListSettings(),
                        ],
                    },
                ],
            },
        ],
    });
};
export const BuilderComponentsSpeedDialSettings = getBuilderComponentsSpeedDialSettings();
