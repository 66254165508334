import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { HTML_FRAGMENT_SETTINGS } from './BuilderComponentsHtmlFragment.settings';
export const BuilderComponentsHtmlFragment = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsHtmlFragment,
    displayName: 'HTML',
    icon: 'html',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'HtmlFragment',
    },
    overlayOutlineWhenEmpty: true,
    schema: Object.assign(Object.assign({ presentations: [visibleByCondition], mainPropPath: {
            mainProp: 'html',
        } }, getSysMinSizeStyle({ minWidth: 16, minHeight: 16 })), { predefineds: {
            props: {
                htmlFragment: '<!-- This is an HTML example -->',
            },
        }, props: Object.assign(Object.assign({}, commonComponentProps), { htmlFragment: { type: COMPONENT_DSL_PROP_TYPES.htmlFragment } }) }),
    settings: HTML_FRAGMENT_SETTINGS,
};
