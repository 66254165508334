import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonActionProps, commonBasicProps, commonReactProps, commonStyleProps, } from '../../common/commonProps';
import { BuilderComponentsRouterLinkSettings } from './BuilderComponentsRouterLink.settings';
export const BuilderComponentsRouterLink = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRouterLink,
    displayName: 'Link',
    icon: 'link',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'RouterLink',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Text',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        },
        predefineds: {
            props: {
                absolute: false,
                to: '/',
                color: 'primary',
                underline: 'none',
                style: getPredefinedStyles({
                    display: 'inline-block',
                    minWidth: 36,
                    minHeight: 36,
                }),
                variant: 'inherit',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Link',
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonActionProps), commonReactProps), commonBasicProps), { absolute: { type: COMPONENT_DSL_PROP_TYPES.boolean }, target: { type: COMPONENT_DSL_PROP_TYPES.enum }, href: { type: COMPONENT_DSL_PROP_TYPES.string }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, underline: { type: COMPONENT_DSL_PROP_TYPES.enum }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, to: { type: COMPONENT_DSL_PROP_TYPES.string }, replace: { type: COMPONENT_DSL_PROP_TYPES.boolean }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.Text],
                required: true,
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsRouterLinkSettings,
};
