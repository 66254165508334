import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_TYPES, STATE_SCOPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonStyleProps } from '../../common/commonProps';
import { BuilderComponentsTextFieldSettings } from './BuilderComponentsTextField.settings';
export const BuilderComponentsTextField = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
    displayName: 'Textfield',
    icon: 'input',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'TextField',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'textField',
                    defaultValue: '',
                },
            ],
            props: {
                label: 'Text Input',
                color: 'primary',
                variant: 'outlined',
                debounce: 0,
                type: 'text',
                size: 'medium',
                value: '{{ __STATES__.textField.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.textField.setValue(event.target.value)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { autoComplete: { type: COMPONENT_DSL_PROP_TYPES.string }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, defaultValue: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, error: { type: COMPONENT_DSL_PROP_TYPES.boolean }, FormHelperTextProps: { type: COMPONENT_DSL_PROP_TYPES.object }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, helperText: { type: COMPONENT_DSL_PROP_TYPES.string }, helperErrorText: { type: COMPONENT_DSL_PROP_TYPES.string }, validation: { type: COMPONENT_DSL_PROP_TYPES.string }, regularExpression: { type: COMPONENT_DSL_PROP_TYPES.string }, InputLabelProps: { type: COMPONENT_DSL_PROP_TYPES.object }, inputProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign({}, commonStyleProps),
            }, InputProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign(Object.assign({}, commonStyleProps), { startAdornment: {
                        type: COMPONENT_DSL_PROP_TYPES.reactNode,
                        allowedNodes: [
                            COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            COMPONENT_DSL_NAMES.MaterialTypography,
                        ],
                    }, endAdornment: {
                        type: COMPONENT_DSL_PROP_TYPES.reactNode,
                        allowedNodes: [
                            COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            COMPONENT_DSL_NAMES.MaterialTypography,
                        ],
                    } }),
            }, label: { type: COMPONENT_DSL_PROP_TYPES.string }, margin: { type: COMPONENT_DSL_PROP_TYPES.enum }, multiline: { type: COMPONENT_DSL_PROP_TYPES.boolean }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, placeholder: { type: COMPONENT_DSL_PROP_TYPES.string }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean }, rows: { type: COMPONENT_DSL_PROP_TYPES.string }, minRows: { type: COMPONENT_DSL_PROP_TYPES.string }, maxRows: { type: COMPONENT_DSL_PROP_TYPES.string }, select: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, type: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, debounce: { type: COMPONENT_DSL_PROP_TYPES.number } }),
    },
    settings: BuilderComponentsTextFieldSettings,
};
