/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';

import { Monaco } from '@monaco-editor/react';

import {
  appDSLSelectors,
  libraryListSelectors,
  StateListDSL,
  StateScopeTypes,
  STATE_SCOPES,
} from '@builder/schemas';

import {
  useAppDSL,
  useAppDSLGlobalStates,
  useAppDSLStates,
  useLibraryListDSL,
  useResourceListDSL,
} from 'src/providers';
import {
  addAppStatesTypes,
  addDefaultOptions,
  addGlobalTypes,
  addIterableTypes,
  addLibrariesTypes,
  addPredefinedTypes,
  disposePreviousModels,
} from 'src/shared/components';

export const useInitMonaco = (
  setMountedStates?: (val: StateListDSL) => void | undefined,
  scope?: StateScopeTypes | undefined,
): ((monaco: Monaco) => void) => {
  const appDSL = useAppDSL();
  const appStatesDSL = useAppDSLStates();
  const appGlobalStatesDSL = useAppDSLGlobalStates();
  const resourceListDSL = useResourceListDSL();
  const authResourceDSL = appDSLSelectors.getAuthResourceDSL(appDSL);
  const libraryListDSL = useLibraryListDSL();
  const libraryArrayDSL = libraryListSelectors.getLibraryArrayDSL(libraryListDSL);

  return useCallback(
    (monaco: Monaco) => {
      setMountedStates && setMountedStates(appStatesDSL);
      disposePreviousModels(monaco);
      addDefaultOptions(monaco);

      const { nodes: nodeListDSL } = appDSL;

      addPredefinedTypes(monaco, nodeListDSL);
      addGlobalTypes(monaco);
      addIterableTypes(monaco, []);
      addAppStatesTypes({
        monaco,
        gqlSchema: undefined,
        appStatesDSL: scope === STATE_SCOPES.global ? appGlobalStatesDSL : appStatesDSL,
        resourceListDSL,
        assetListDSL: {},
        authResourceDSL,
      });
      addLibrariesTypes(monaco, libraryArrayDSL);
    },
    [appDSL, appStatesDSL, resourceListDSL, authResourceDSL, libraryArrayDSL],
  );
};
