import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_18_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.1';
    }
    getChangeList() {
        return [`Extract events props from boxProps in AsynContent component`];
    }
    migrateNodeDSL(nodeDSL) {
        if (nodeDSL.name === COMPONENT_DSL_NAMES.AsyncContent) {
            const boxProps = nodeDSL.props.boxProps;
            const asyncProps = Object.assign(Object.assign({}, nodeDSL.props), boxProps);
            delete asyncProps.boxProps;
            return Object.assign(Object.assign({}, nodeDSL), { props: asyncProps });
        }
        return nodeDSL;
    }
}
export const migration_0_18_1 = new Migration_0_18_1();
