import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
export const MaterialGrid = {
    name: COMPONENT_DSL_NAMES.MaterialGrid,
    displayName: 'Grid',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    deprecated: {
        version: '0.4.0',
    },
    source: {
        importName: '@mui/material',
        componentName: 'Grid',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { alignContent: { type: COMPONENT_DSL_PROP_TYPES.enum }, alignItems: { type: COMPONENT_DSL_PROP_TYPES.enum }, component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, container: { type: COMPONENT_DSL_PROP_TYPES.boolean }, direction: { type: COMPONENT_DSL_PROP_TYPES.enum }, item: { type: COMPONENT_DSL_PROP_TYPES.boolean }, justifyContent: { type: COMPONENT_DSL_PROP_TYPES.enum }, lg: { type: COMPONENT_DSL_PROP_TYPES.enum }, md: { type: COMPONENT_DSL_PROP_TYPES.enum }, sm: { type: COMPONENT_DSL_PROP_TYPES.enum }, spacing: { type: COMPONENT_DSL_PROP_TYPES.enum }, wrap: { type: COMPONENT_DSL_PROP_TYPES.enum }, xl: { type: COMPONENT_DSL_PROP_TYPES.enum }, xs: { type: COMPONENT_DSL_PROP_TYPES.enum }, zeroMinWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: null,
};
