import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getDivider, getStylesSection, getActionSettings, getConditionSection, BASIC_STYLE_TOOLTIP, } from '../../common';
const getStyleSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_STYLE_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['xs'],
                label: 'Width',
                options: ['auto', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(createSelectOptionByValue),
            },
            {
                type: COMPONENT_SETTING_TYPES.subSection,
                title: 'Flexbox behavior',
                name: 'flexboxBehavior',
                children: [
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['alignContent'],
                        label: 'Align Content',
                        options: [
                            'stretch',
                            'center',
                            'flex-start',
                            'flex-end',
                            'space-between',
                            'space-around',
                        ].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['alignItems'],
                        label: 'Align Items',
                        options: ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['justify'],
                        label: 'Justify',
                        options: [
                            'flex-start',
                            'center',
                            'flex-end',
                            'space-between',
                            'space-around',
                            'space-evenly',
                        ].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['wrap'],
                        label: 'Wrap',
                        options: ['nowrap', 'wrap', 'wrap-reverse'].map(createSelectOptionByValue),
                    },
                ],
            },
        ],
    },
    getDivider(),
    ...getStylesSection(),
];
export const GridItemSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [getConditionSection()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSettings(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
