import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import { NodeDSL } from '@builder/schemas';

import { ActionScopeStrategy } from './ActionScopeStrategy';

export class StateConvertStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null {
    const newEventsToPush: EventPayload[] = [];
    const oldRouteIdScope = action.oldStateArgs.oldConnectedNodeID;

    if (
      eventPayload.operation === 'create' &&
      !eventPayload.data.key.includes(`${oldRouteIdScope}.states.`)
    ) {
      return { eventPayload: { ...eventPayload }, newEventsToPush };
    }

    return { eventPayload: { ...eventPayload, scope: oldRouteIdScope }, newEventsToPush };
  }
}
