import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useContext } from 'react';
import { ERROR_SCOPES, isNull, SystemError } from '@builder/utils';
export const UserAppEventEmitterContext = createContext(null);
export const UserAppEventEmitterProvider = ({ children, emitter, }) => {
    return (_jsx(UserAppEventEmitterContext.Provider, Object.assign({ value: emitter }, { children: children })));
};
export const useUserAppEventEmitter = () => {
    const eventEmitter = useContext(UserAppEventEmitterContext);
    if (isNull(eventEmitter)) {
        throw new SystemError(ERROR_SCOPES.uiBuilder, 'Event Emitter must be initialized');
    }
    return eventEmitter;
};
