var _a, _b;
import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsButton } from '../BuilderComponentsButton';
import { MaterialAccordionActionsSettings } from './MaterialAccordionActions.settings';
export const MaterialAccordionActions = {
    name: COMPONENT_DSL_NAMES.MaterialAccordionActions,
    displayName: 'Accordion Actions',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'AccordionActions',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        accessibility: {
            disableCreatingSymbol: true,
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialAccordion,
                },
            },
        ],
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                disableSpacing: false,
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsButton.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { color: 'primary', children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Cancel',
                                            },
                                        },
                                    ],
                                } }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                            props: Object.assign(Object.assign({}, (_b = BuilderComponentsButton.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { color: 'primary', children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Ok',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { disableSpacing: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: MaterialAccordionActionsSettings,
};
