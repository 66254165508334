import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { assocSettings, NS_ID, getCommonFormFieldPropertiesSettings, omitSettings, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings } from '../../common/settings';
import { getBuilderComponentsAutocompleteSettings } from '../../material-components/BuilderComponentsAutocomplete/BuilderComponentsAutocomplete.settings';
const basicBuilderComponentsAutocompleteSettings = getBuilderComponentsAutocompleteSettings([
    'autocompleteProps',
]);
export const BuilderComponentsFormAutocompleteSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [
            ...settings.children,
            ...getCommonFormFieldPropertiesSettings(),
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['value'],
                label: 'Value',
            },
        ] }))), omitSettings([[NS_ID.properties.root, NS_ID.properties.basic, 'defaultValue']]))(basicBuilderComponentsAutocompleteSettings.properties),
    style: basicBuilderComponentsAutocompleteSettings.style,
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: ['onChange'],
                            children: getCommonActionSettings(['onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onOpenAction',
                            label: 'On Open',
                            keyPath: ['onOpen'],
                            children: getCommonActionSettings(['onOpen']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: ['onClose'],
                            children: getCommonActionSettings(['onClose']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onHighlightChangeAction',
                            label: 'On Highlight Change',
                            keyPath: ['onHighlightChange'],
                            children: getCommonActionSettings(['onHighlightChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onInputChangeAction',
                            label: 'On Input Change',
                            keyPath: ['onInputChange'],
                            children: getCommonActionSettings(['onInputChange']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
