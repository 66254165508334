import { isEmpty } from 'ramda';
import { appDSLSelectors, resourceListSelectors } from '@builder/schemas';
import { APP_AUTH_RESOURCE_CONST, RESOURCES_LIST_CONST } from '../constants-generator/constants';
import { RESOURCE_TYPINGS_CONST } from '../types-generator/constants';
export class HooksGenerator {
    constructor(appDSL) {
        this.appDSL = appDSL;
    }
    generateUseResourceClientFile() {
        const authResourceDSL = appDSLSelectors.getAuthResourceDSL(this.appDSL);
        const resourceConstsImportLine = `
         import { ${authResourceDSL ? `${APP_AUTH_RESOURCE_CONST}, ` : ''}${RESOURCES_LIST_CONST} } from 'shared/constants';
    `;
        const getErrorLinkLine = `
    const getErrorLink = () =>
        createErrorLink(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(graphQLError => {
              console.warn(
                \`[GraphQL error]: Message: \${graphQLError.message}, Location: \${graphQLError.locations}, Path: \${graphQLError.path}\`,
              );
            });
          } else if (networkError) {
            console.warn(\`[Network error]: \${networkError}\`);
          }
        });
    `;
        const getApolloClientLine = `
    const getApolloClient = (apiEndpoint: string): ApolloClient<Record<string, unknown>> | null => {
        if (apiEndpoint) {
          const errorLink = getErrorLink();
          const apolloLinks = ApolloLink.from([errorLink, createHttpLink({ uri: apiEndpoint })]);

          return new ApolloClient({
            connectToDevTools: false,
            cache: new InMemoryCache(),
            link: apolloLinks,
          });
        }

        return null;
      };
    `;
        const useResourceClientLine = `
    export const useResourceClient = (apiUrl): Record<${RESOURCE_TYPINGS_CONST.ResourceNames} ,ApolloClient<Record<string, unknown>> | undefined> => {

        return getApolloClient(apiUrl);
      };
    `;
        return `
      import { ApolloClient, ApolloLink, InMemoryCache${authResourceDSL ? ', useApolloClient' : ''} } from '@apollo/client';
      import { onError as createErrorLink } from '@apollo/client/link/error';
      import { createHttpLink } from '@apollo/client/link/http';
      import { ${RESOURCE_TYPINGS_CONST.ResourceNames} } from 'shared/types';

      ${resourceConstsImportLine}

      ${getErrorLinkLine}

      ${getApolloClientLine}

      ${useResourceClientLine}
      `;
    }
    generateIndexFile() {
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(this.appDSL.resources);
        const useResourceClientImport = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? ''
            : "export * from './useResourceClient';";
        return `
        ${useResourceClientImport}
      `;
    }
}
