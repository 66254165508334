import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_6_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.6.1';
    }
    getChangeList() {
        return [`Add "label" prop to the Switch component`];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case 'MaterialSwitch': {
                return Object.assign(Object.assign({}, nodeDSL), { name: COMPONENT_DSL_NAMES.BuilderComponentsSwitch });
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_6_1 = new Migration_0_6_1();
