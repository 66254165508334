import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_BOOLEAN_VARIANTS, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { DialogSymbol } from '../DialogSymbol';
import { DialogSymbolSettings } from '../DialogSymbol/DialogSymbol.settings';
export const LocalDialogSymbol = {
    name: COMPONENT_DSL_NAMES.LocalDialogSymbol,
    displayName: 'Dialog',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.modal,
    componentListGroupName: MENU_GROUP_NAMES.other,
    icon: 'localDialog',
    schema: Object.assign(Object.assign({}, DialogSymbol.schema), { parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                },
            },
        ], codeFileStructure: undefined, predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.openClose,
                    name: 'modal',
                    defaultValue: false,
                },
            ],
            props: {
                dialogProps: {
                    open: '{{ __STATES__.modal.isOpened }}',
                    onClose: {
                        actionType: 'customCode',
                        args: {
                            code: '__STATES__.modal.close()',
                        },
                    },
                    fullWidth: true,
                    maxWidth: 'sm',
                },
                titleProps: {
                    title: 'Dialog Title',
                },
            },
        } }),
    settings: DialogSymbolSettings,
};
