import { COMPONENT_SETTING_POSSIBLE_UNITS } from '../../constants';
export const SIZE_POSSIBLE_UNITS = [
    COMPONENT_SETTING_POSSIBLE_UNITS.px,
    COMPONENT_SETTING_POSSIBLE_UNITS.percent,
    COMPONENT_SETTING_POSSIBLE_UNITS.rem,
    COMPONENT_SETTING_POSSIBLE_UNITS.em,
    COMPONENT_SETTING_POSSIBLE_UNITS.vh,
    COMPONENT_SETTING_POSSIBLE_UNITS.vw,
    COMPONENT_SETTING_POSSIBLE_UNITS.vmin,
    COMPONENT_SETTING_POSSIBLE_UNITS.vmax,
    COMPONENT_SETTING_POSSIBLE_UNITS.none,
];
export const BORDER_RADIUS_POSSIBLE_UNITS = [
    COMPONENT_SETTING_POSSIBLE_UNITS.px,
    COMPONENT_SETTING_POSSIBLE_UNITS.percent,
    COMPONENT_SETTING_POSSIBLE_UNITS.none,
];
export const BORDER_WIDTH_POSSIBLE_UNITS = [
    COMPONENT_SETTING_POSSIBLE_UNITS.px,
    COMPONENT_SETTING_POSSIBLE_UNITS.none,
];
export const FONT_POSSIBLE_UNITS = [
    COMPONENT_SETTING_POSSIBLE_UNITS.px,
    COMPONENT_SETTING_POSSIBLE_UNITS.rem,
    COMPONENT_SETTING_POSSIBLE_UNITS.em,
    COMPONENT_SETTING_POSSIBLE_UNITS.vh,
    COMPONENT_SETTING_POSSIBLE_UNITS.vw,
    COMPONENT_SETTING_POSSIBLE_UNITS.vmin,
    COMPONENT_SETTING_POSSIBLE_UNITS.vmax,
    COMPONENT_SETTING_POSSIBLE_UNITS.none,
];
/** @deprecated */
export const NODE_SETTING_NAMES = {
    properties: {
        root: 'properties',
        basic: 'basic',
        advanced: 'advanced',
    },
    style: {
        root: 'style',
        basic: 'basic',
        advanced: 'advanced',
        background: {
            root: 'background',
            backgroundColor: 'backgroundColor',
            backgroundImage: 'backgroundImage',
        },
        border: {
            root: 'border',
        },
        css: {
            root: 'css',
        },
        display: {
            root: 'display',
            display: 'display',
        },
        flexChild: {
            root: 'flexChild',
        },
        gridChild: {
            root: 'gridChild',
        },
        font: {
            root: 'font',
            typography: 'Typography',
            color: 'color',
            direction: 'direction',
            fontFamily: 'fontFamily',
            fontSize: 'fontSize',
            fontStyle: 'fontStyle',
            fontWeight: 'fontWeight',
            lineHeight: 'lineHeight',
            textAlign: 'textAlign',
            textDecoration: 'textDecoration',
            textTransform: 'textTransform',
            whiteSpace: 'whiteSpace',
        },
        customFont: {
            root: 'customFont',
            fontFamily: 'fontFamily',
        },
        size: {
            root: 'size',
            width: 'width',
            height: 'height',
            minWidth: 'minWidth',
            maxWidth: 'maxWidth',
            minHeight: 'minHeight',
            maxHeight: 'maxHeight',
            overflow: 'overflow',
        },
        spacing: {
            root: 'spacing',
            editor: 'spacingEditor',
        },
        cursor: {
            root: 'cursor',
        },
    },
    events: {
        root: 'events',
        events: 'events',
    },
};
export const NS_ID = NODE_SETTING_NAMES;
/** @deprecated */
export const NODE_SECTION_TABS = {
    properties: 'properties',
    style: 'style',
    events: 'events',
};
/** @deprecated */
export const STYLE_SECTION_TYPES = {
    basic: 'basic',
    background: 'background',
    border: 'border',
    css: 'css',
    display: 'display',
    font: 'font',
    size: 'size',
    spacing: 'spacing',
};
/** @deprecated */
export const EVENT_SECTIONS = {
    events: 'events',
};
/** @deprecated */
export const PROPERTY_SECTIONS = {
    basic: 'basic',
};
/** @deprecated */
export const TYPOGRAPHY_SECTION_NAMES = {
    color: 'color',
    direction: 'direction',
    fontFamily: 'fontFamily',
    fontSize: 'fontSize',
    fontStyle: 'fontStyle',
    fontWeight: 'fontWeight',
    lineHeight: 'lineHeight',
    textAlign: 'textAlign',
    textDecoration: 'textDecoration',
    textTransform: 'textTransform',
    whiteSpace: 'whiteSpace',
};
/** @deprecated */
export const SIZE_SECTION_NAMES = {
    width: 'width',
    height: 'height',
    minWidth: 'minWidth',
    maxWidth: 'maxWidth',
    minHeight: 'minHeight',
    maxHeight: 'maxHeight',
    overflow: 'overflow',
};
export const DISPLAY_OPTION_VALUES = {
    block: 'block',
    flex: 'flex',
    grid: 'grid',
    inline: 'inline',
    inlineBlock: 'inline-block',
    none: 'none',
};
export const COMMON_GRID_OPTIONS = ['inherit', 'initial', 'revert', 'revert-layer', 'unset'];
export const GRID_AUTOFLOW_OPTIONS = [
    'row',
    'column',
    'dense',
    'row dense',
    'column dense',
    ...COMMON_GRID_OPTIONS,
];
export const GRID_PLACE_SELF_OPTIONS = [
    'auto center',
    'normal start',
    'center normal',
    'start auto',
    'end normal',
    'self-start auto',
    'self-end normal',
    'flex-start auto',
    'flex-end normal',
    'left auto',
    'right normal',
    'baseline normal',
    'first baseline auto',
    'last baseline normal',
    'stretch auto',
    ...COMMON_GRID_OPTIONS,
];
export const GRID_PLACE_CONTENT_OPTIONS = [
    'center start',
    'start center',
    'end left',
    'flex-start center',
    'flex-end center',
    'baseline center',
    'first baseline space-evenly',
    'last baseline right',
    'space-between space-evenly',
    'space-around space-evenly',
    'space-evenly stretch',
    'stretch space-evenly',
    ...COMMON_GRID_OPTIONS,
];
export const GRID_PLACE_ITEMS_OPTIONS = [
    'center',
    'normal start',
    'center normal',
    'start legacy',
    'end normal',
    'self-start legacy',
    'self-end normal',
    'flex-start legacy',
    'flex-end normal',
    'left legacy',
    'right normal',
    'baseline normal',
    'first baseline legacy',
    'last baseline normal',
    'stretch legacy',
    ...COMMON_GRID_OPTIONS,
];
export const TEXT_FIELD_TYPE_OPTIONS = [
    'text',
    'number',
    'color',
    'email',
    'url',
    'date',
    'datetime-local',
    'month',
    'password',
    'tel',
    'time',
    'week',
];
export const TEXT_FIELD_VALIDATION_OPTIONS = [
    'email',
    'url',
    'us phone number',
    'regular expression',
];
export const BASIC_PROPS_TOOLTIP = {
    title: 'Basic',
    subtitle: 'These properties control the basic aspects and behavior of the components.',
};
export const BASIC_STYLE_TOOLTIP = {
    title: 'Basic',
    subtitle: 'Component-specific attributes to define its style',
};
export const FIELD_TYPE_OPTIONS = ['string', 'boolean', 'number', 'date', 'time'];
