import { useCallback, useEffect, useState } from 'react';
export const useOpenCloseModalState = (defaultState) => {
    // CODE_ENGINE_USAGE_START
    const [isOpened, setIsOpened] = useState(defaultState);
    const [args, setArgs] = useState(null);
    useEffect(() => {
        setIsOpened(defaultState);
    }, [defaultState]);
    const open = useCallback((passedArgs) => {
        setIsOpened(true);
        setArgs(passedArgs !== null && passedArgs !== void 0 ? passedArgs : null);
    }, []);
    const close = useCallback(() => {
        setIsOpened(false);
        setArgs(null);
    }, []);
    const toggle = useCallback(() => {
        if (isOpened) {
            close();
        }
        else {
            open();
        }
    }, [close, isOpened, open]);
    return {
        isOpened,
        open,
        close,
        toggle,
        args,
    };
    // CODE_ENGINE_USAGE_END
};
