import React, { useCallback, useRef } from 'react';

import { pathOr } from 'ramda';

import {
  NodeChecker,
  ComponentSettingNodeTextDSL,
  componentSettingSelectors,
  nodeListSelectors,
  nodeSelectors,
  RenderablePropValue,
  NodeDSL,
} from '@builder/schemas';
import { useDebouncedState } from '@builder/utils';

import { JsCodeInjectionInput } from '../../setting-views/common/JsCodeInjectionInput';
import { useNodeListDSL } from 'src/providers/ReduxProvider';
import { TextField } from 'src/shared/components';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';
import { useDoubleClickEditText } from 'src/shared/hooks';
import { getTargetNodeID } from 'src/store/dashboard/utils';

type NodeTextTypeProps = {
  onChange: (propData: { text: string; keyPath: Array<string | number> }) => void;
  selectedNodeDSL: NodeDSL;
  showFx?: boolean;
  setting: ComponentSettingNodeTextDSL;
  fxDefaultEnabled?: string;
  'data-test'?: string;
};

export const NodeTextType: React.FC<NodeTextTypeProps> = ({
  onChange,
  setting,
  selectedNodeDSL,
  showFx = true,
  'data-test': dataTest,
  fxDefaultEnabled = ACTIVEPROP.literal,
}) => {
  const nodeListDSL = useNodeListDSL();
  const keyPath = componentSettingSelectors.getSettingsKeyPath(setting);
  const nodePath = componentSettingSelectors.getSettingsNodePath(setting);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const targetNodeID = getTargetNodeID({
    nodeID: selectedNodeDSL?.id,
    nodePath,
    nodeListDSL,
  }) as string;

  useDoubleClickEditText<React.MutableRefObject<HTMLInputElement | null>>({
    targetNodeId: [targetNodeID, selectedNodeDSL.id],
    inputLabel: setting.label as string,
    ref: inputRef,
  });

  const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID: targetNodeID,
  });

  const { nodes } = pathOr({ nodes: [] }, keyPath, targetNodeDSL.props) as RenderablePropValue;
  const textNodeID = nodes.find(childrenID =>
    NodeChecker.Value.isTextNode(nodeListDSL[childrenID]),
  );
  const textNode = textNodeID
    ? nodeListSelectors.getNodeDSL(nodeListDSL, {
        nodeID: textNodeID,
      })
    : null;
  const textValue = NodeChecker.Value.isTextNode(textNode)
    ? nodeSelectors.getTextProp(textNode)
    : '';

  const changeTextValueByEvent = useCallback(
    (value: string) => onChange({ text: value || '', keyPath: setting.keyPath }),
    [onChange, setting.keyPath],
  );
  const [debouncedValue, setDebouncedValue] = useDebouncedState(textValue, changeTextValueByEvent);

  const updateValueByEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: changedValue } = event.target;

      setDebouncedValue(changedValue as string);
    },
    [setDebouncedValue],
  );

  return (
    <JsCodeInjectionInput
      label={setting.label}
      onChangePropValue={setDebouncedValue}
      nodeID={targetNodeDSL.id}
      showFx={showFx}
      propValue={debouncedValue}
      data-test={dataTest}
      fxDefaultEnabled={fxDefaultEnabled}
    >
      {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
        <TextField
          inputRef={inputRef}
          showFx={showFx}
          fullWidth
          multiline
          variant="outlined"
          label={setting.label}
          size="small"
          value={nonJsCodePropValue ?? debouncedValue}
          onChange={updateValueByEvent}
          data-test={dataTest}
          isFxEnabled={isFxEnabled}
          enableFx={enableFx}
          absoluteEndAdornmentPosition
        />
      )}
    </JsCodeInjectionInput>
  );
};
