/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { editor as MonacoEditorType } from 'monaco-editor';

import { isUndefined, isEmptyString } from '@builder/utils';

import { eventKeys } from '../../utils/index';
import { EditorInputPreview } from '../common/EditorInputPreview';
import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { CodeEditorDialogArgs, CodeEditorDialogLanguage, DASHBOARD_DIALOGS } from 'src/dialogs';
import { argTypes } from 'src/dialogs/GlobalDialogs/CodeEditorDialog/CodeEditorDialog';
import { useDialogState } from 'src/providers';
import { InputContainer, InputContainerProps } from 'src/shared/components';
import { useSelectedNode } from 'src/shared/hooks';

export type CodeViewEditorProps = Omit<InputContainerProps, 'isFxEnabled' | 'enableFx'> & {
  codePropValue?: string | null;
  defaultValue?: string;
  onChangeCode: (code?: string | null) => void;
  label: string;
  nodeID?: string;
  'data-test': string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  language?: CodeEditorDialogLanguage;
  options?: MonacoEditorType.IStandaloneEditorConstructionOptions;
  keyName?: string;
};

export const CodeViewEditor = ({
  label,
  codePropValue,
  defaultValue,
  onChangeCode,
  nodeID,
  showFx = false,
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
  language = 'typescript',
  options,
  'data-test': dataTest,
  keyName,
}: CodeViewEditorProps): JSX.Element => {
  const { openDialog } = useDialogState<CodeEditorDialogArgs>();
  const selectedNodeDSL = useSelectedNode();

  const onPreviewClick = (nonJsCodePropValue: string | null | undefined) => () => {
    const eventArgs = eventKeys(selectedNodeDSL?.name || '').filter(key => {
      return keyName?.includes(key.action);
    });
    const args = eventArgs.length > 0 ? eventArgs[0] : {};

    openDialog(DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID, {
      label,
      initialValues: nonJsCodePropValue ?? defaultValue,
      nodeID,
      onSave: onChangeCode,
      language,
      dataTest,
      options,
      arg: args as argTypes,
    });
  };

  const getLabelPrefix = (nonJsCodePropValue: any) => {
    if (
      defaultValue === nonJsCodePropValue?.trim() ||
      isEmptyString(nonJsCodePropValue) ||
      isUndefined(nonJsCodePropValue)
    ) {
      return 'Add';
    }

    return 'Edit';
  };

  return (
    <JsCodeInjectionInput
      label={label}
      propValue={codePropValue}
      onChangePropValue={onChangeCode}
      nodeID={nodeID}
      data-test={dataTest}
      showFx={showFx}
    >
      {({ enableFx, isFxEnabled, nonJsCodePropValue }) => {
        return (
          <InputContainer
            variant={variant}
            error={error}
            icon={icon}
            isTextIcon={isTextIcon}
            helperText={helperText}
            enableFx={enableFx}
            isFxEnabled={isFxEnabled}
            data-test={dataTest}
            showFx={showFx}
          >
            <EditorInputPreview
              label={`${getLabelPrefix(nonJsCodePropValue)} ${label}`}
              onClick={onPreviewClick(nonJsCodePropValue)}
              data-test={`${dataTest}.btn`}
              showFx={showFx}
            />
          </InputContainer>
        );
      }}
    </JsCodeInjectionInput>
  );
};
