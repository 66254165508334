var _a;
import { pipe, values } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { omitPropsSettings } from '../../../utils/prop-transformers/omitPropsSettings';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, NS_ID, getBasicPropsSettings, omitSettings, } from '../../common';
import { pickSettings } from '../../common/helpers';
import { getStyleSections, getEventsSection, getPropertySections, getDivider, } from '../../common/settings';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const BuilderComponentsButtonSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            label: 'Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['type'],
                            label: 'Type',
                            options: ['button', 'submit', 'reset'].map(createSelectOptionByValue),
                            hasFxButton: true,
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disabled'],
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['href'],
                            label: 'href',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['target'],
                            label: 'Target',
                            options: ['_blank', '_self'].map(createSelectOptionByValue),
                            showIf: [
                                {
                                    keyPath: ['href'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isNotNil,
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'startIcon',
                            label: 'Left Icon',
                            keyPath: ['startIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: omitSettings([['size']])(getCommonIconSettings()),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'endIcon',
                            label: 'Right Icon',
                            keyPath: ['endIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: omitSettings([['size']])(getCommonIconSettings()),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            options: ['contained', 'outlined', 'text'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Font Color',
                            options: ['inherit', 'primary', 'secondary'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['size'],
                            label: 'Size',
                            options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['fullWidth'],
                            label: 'Full Width',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'advanced',
                    title: 'Advanced',
                    titleDescription: {
                        title: 'Advanced',
                        subtitle: 'Additional component-specific attributes',
                    },
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableElevation'],
                            label: 'Disable Elevation',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableFocusRipple'],
                            label: 'Disable Focus Ripple',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableRipple'],
                            label: 'Disable Ripple',
                        },
                    ],
                },
                getDivider(),
                ...((_a = omitPropsSettings(getStyleSections(), NS_ID.style.font.typography, NS_ID.style.font.direction)) !== null && _a !== void 0 ? _a : []),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
export const getBuilderComponentsButtonSettings = () => pipe(pickSettings([], { withStructure: false }))(values(BuilderComponentsButtonSettings).flat());
