import { Migration } from '../Migration';
class Migration_0_13_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.13.0';
    }
    getChangeList() {
        return ['Add new ThemeDSL', 'Make old theme legacy'];
    }
    migrateAppDSL(appDSL) {
        const { theme } = appDSL;
        return Object.assign(Object.assign({}, appDSL), { __legacyTheme: theme, theme: {
                mediaQueries: {},
                icons: {},
                framework: 'MUI',
                components: {},
                fonts: {
                    google: {},
                    custom: {},
                },
                frameworkSettings: theme,
                css: {},
            } });
    }
}
export const migration_0_13_0 = new Migration_0_13_0();
