import { nodeListSelectors } from '@builder/schemas';
import { isArray } from '@builder/utils';
import { errorMessages } from '../audit-message-getters';
import { transformPropsFromJsCode } from '../utils/transformPropsFromJsCode';
import { AppEntityBaseValidator } from './AppEntityBaseValidator';
/**
 *  Contains node validators.
 */
export class NodeValidator extends AppEntityBaseValidator {
    constructor(errorGetter, nodeListDSL, nodeDSL) {
        super(errorGetter);
        this.nodeDSL = nodeDSL;
        this.nodeListDSL = nodeListDSL;
    }
    getMessageWithReference(message, nodeDSL) {
        return `${nodeDSL.alias} - ${message}`;
    }
    getValidationRule({ propPath, isNotValid, message, }) {
        if (isNotValid) {
            this.errorGetter.passErrorNotification({
                propPath,
                message: this.getMessageWithReference(message, this.nodeDSL),
            });
            return false;
        }
        return true;
    }
    checkUniqueProp({ propPath, propDSL, propValue }) {
        // TODO: unskip after https://8base-dev.atlassian.net/browse/APB-136
        // if (propDSL.uniq) {
        //   const allNodesWithTheSameName = nodeListSelectors.getNodesListDSLByName(this.nodeListDSL, {
        //     componentName: this.nodeDSL.name,
        //   });
        //   const allPropsWithTheSame = Object.values(allNodesWithTheSameName)
        //     .filter(nodeDSL => nodeDSL.id !== this.nodeDSL.id)
        //     .map(path(['props', ...propPath]));
        //   if (allPropsWithTheSame.includes(propValue)) {
        //     this.errorGetter.passErrorNotification({
        //       propPath: ['props', ...propPath],
        //       message: errorMessages.getUniqPropError(),
        //     });
        //     return false;
        //   }
        // }
        return true;
    }
    /**
     * Function to check if id prop is unique in the whole current DSL. this required { uniq: true } in the prop.
     * @returns Boolean
     */
    checkUniquePropOnDSL({ propPath, propDSL, propValue }, externalState) {
        if (propDSL.uniqAmongAllNodes) {
            const propName = propPath[propPath.length - 1].toString();
            const allNodesExceptCurrent = nodeListSelectors.getNodeListDSLWithoutNodes(this.nodeListDSL, {
                nodeIDs: [this.nodeDSL.id],
            });
            const allPropsByKey = nodeListSelectors.getAllPropsByKey(allNodesExceptCurrent, {
                propName,
            });
            const transformedPropsFromJsCode = transformPropsFromJsCode({
                nodePropValuesArray: allPropsByKey,
                propPath,
                externalState,
                nodeDSL: this.nodeDSL,
            });
            if (transformedPropsFromJsCode.includes(propValue)) {
                this.errorGetter.passErrorNotification({
                    propPath: ['props', ...propPath],
                    message: errorMessages.getUniqueIdPropError({ propPath, propValue }),
                });
                return false;
            }
        }
        return true;
    }
    checkNodeAlias() {
        // TODO: unskip after https://8base-dev.atlassian.net/browse/APB-136
        // const nodeAliasListWithoutCurrentNode = Object.values(this.nodeListDSL)
        //   .filter(nodeDSL => nodeDSL.id !== this.nodeDSL.id)
        //   .map(({ alias }) => alias);
        // if (nodeAliasListWithoutCurrentNode.includes(this.nodeDSL.alias)) {
        //   this.errorGetter.passErrorNotification({
        //     propPath: ['alias'],
        //     message: errorMessages.getUniqAliasError(),
        //   });
        //   return false;
        // }
        return true;
    }
    checkIteratorData(iteratorData, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isArray(iteratorData),
            message: errorMessages.getIteratorDataError({ iteratorData }),
        });
    }
}
