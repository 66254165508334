import styled from '@emotion/styled';

export const ThemeSettingsContent = styled.div`
  height: 100%;
  grid-gap: 96px;
  grid-template-columns: auto 40px;
`;

export const ThreeColorsPalette = styled.div`
  width: 100%;
  min-width: 350px;
  height: 210px;
  display: grid;
  grid-template-rows: 105px 105px;
  grid-template-columns: 1fr 1fr;

  > button:first-child {
    grid-column: span 2;
  }
`;

export const PaletteContainer = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${({ theme }) => theme.spacing(4)};
  grid-template-columns: repeat(auto-fill, minmax(210px, auto));
  grid-auto-rows: 105px;
`;

export const ThemeIconButtonContent = styled.div`
  width: max-content;
  margin: auto;
`;
