import { PossibleUnitTypes, POSSIBLE_UNIT_TYPES } from '@builder/schemas';

export const valueEndsWithUnit = (
  propValue?: string,
  unitList?: PossibleUnitTypes[],
): PossibleUnitTypes | undefined => {
  if (!unitList || !propValue) {
    return undefined;
  }

  const targetUnit = unitList.find(el => {
    if (el.type === POSSIBLE_UNIT_TYPES.postfix) {
      return propValue.endsWith(el.value);
    }

    return undefined;
  });

  return targetUnit;
};
