import React, { useEffect, useMemo } from 'react';

import {
  bindToggle,
  usePopupState,
  bindPopover,
  anchorRef,
  PopupState,
} from 'material-ui-popup-state/hooks';

import { COLORS, PALETTE, useTheme } from 'src/providers/ThemeProvider';
import { CssGrid, Icon, Popper, IconNameType } from 'src/shared/components';

import {
  IconLabelContainer,
  IconSettingsContainer,
  MaybeDraggablePart,
  PopupContent,
  PopupHeader,
  PopupTitle,
  SettingsListItemContainer,
  StyledTextIcon,
  TypographyStyled,
} from './SettingsListItem.styles';

type ListItemProps = {
  label?: string;
  title?: string;
  index?: number;
  popupTitle?: string;
  /** Used to control popper outside the component */
  popupState?: PopupState;
  startIconProps?: {
    name: string;
    isTextIcon?: boolean;
    isActive?: boolean;
  };
  dndProps?: {
    handlerID: unknown;
    isDragging: boolean;
    dndRef: React.RefObject<HTMLDivElement>;
  };
  'data-test'?: string;
  isMenuPopperOpened?: boolean;
};

export const LIST_ITEM_POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 20],
    },
  },
];

const SETTINGS_EDIT_POPUP_ID = 'SettingsEdit';
const DEFAULT_POPUP_TITLE = 'Settings';

export const SettingsListItem: React.FC<ListItemProps> = ({
  label,
  title,
  popupTitle,
  startIconProps,
  children,
  dndProps,
  popupState: popupExternalState,
  index,
  'data-test': dataTest,
  isMenuPopperOpened,
}) => {
  const isFormfieldValidationSection = dataTest?.includes('addFieldSection');
  const theme = useTheme();
  const popupLocalState = usePopupState({
    variant: 'popper',
    popupId: SETTINGS_EDIT_POPUP_ID,
  });
  const popupState = popupExternalState || popupLocalState;
  const popperRef = anchorRef(popupState);
  // use bindPopover because bindPopper doesn't have onClose callback
  const bindPopoverState = bindPopover(popupState);
  const bindToggleState = bindToggle(popupState);
  const isPopperOpened = bindPopoverState.open;
  const isDraggable = useMemo(() => Boolean(dndProps), [dndProps]);
  const dataTestItem = useMemo(() => {
    return isDraggable ? `${dataTest}.draggableListItem` : `${dataTest}.listItem`;
  }, [dataTest, isDraggable]);

  useEffect(
    function closeMenuPopper() {
      if (isPopperOpened && isMenuPopperOpened) {
        popupState.close();
      }
    },
    [isMenuPopperOpened, isPopperOpened, popupState],
  );

  return (
    <SettingsListItemContainer
      ref={popperRef as React.Ref<HTMLDivElement>}
      data-test={dataTestItem}
      isActive={startIconProps?.isActive}
    >
      <MaybeDraggablePart
        ref={dndProps?.dndRef}
        data-handler-id={dndProps?.handlerID}
        isDraggable={isDraggable}
        isDragging={dndProps?.isDragging}
        data-test={`${dataTestItem}.draggablePart`}
      >
        <If condition={startIconProps}>
          <Choose>
            <When condition={startIconProps?.isTextIcon}>
              <StyledTextIcon isActive={startIconProps?.isActive}>
                {startIconProps?.name}
              </StyledTextIcon>
            </When>
            <Otherwise>
              <IconLabelContainer>
                <Icon
                  name={startIconProps?.name as IconNameType}
                  fill={startIconProps?.isActive ? COLORS.blue300 : undefined}
                />
              </IconLabelContainer>
            </Otherwise>
          </Choose>
        </If>
        <TypographyStyled variant="body2">{label}</TypographyStyled>
      </MaybeDraggablePart>

      <IconSettingsContainer
        isActive={isPopperOpened}
        data-test={`${dataTestItem}.settingsBtn`}
        data-enum={`${dataTestItem}.${index}.settingsBtn`}
        data-key={`${dataTestItem}.${label}.settingsBtn`}
        {...bindToggleState}
      >
        <Icon
          name="settings"
          fill={isPopperOpened ? PALETTE.text.primary : PALETTE.text.secondary}
          element="button"
        />
      </IconSettingsContainer>

      {isPopperOpened && (
        <Popper
          placement={isFormfieldValidationSection ? 'left-start' : undefined}
          modifiers={LIST_ITEM_POPPER_MODIFIERS}
          {...bindPopoverState}
        >
          <PopupContent data-test={`${dataTest}.popup`}>
            <CssGrid gap={2}>
              <PopupHeader>
                <PopupTitle variant="body1">{popupTitle ?? DEFAULT_POPUP_TITLE}</PopupTitle>
                <Icon
                  element="button"
                  width="16"
                  height="16"
                  name="cross"
                  fill={theme.palette.grey[500]}
                  onClick={popupState.close}
                  data-test={`${dataTest}.popup.closeBtn`}
                />
              </PopupHeader>
              {title && (
                <TypographyStyled variant="h6" sx={{ mt: theme.spacing(1) }}>
                  {title}
                </TypographyStyled>
              )}
              {children}
            </CssGrid>
          </PopupContent>
        </Popper>
      )}
    </SettingsListItemContainer>
  );
};
