import { mapObjIndexed } from 'ramda';
import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, nodeListSelectors, } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_5_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.5.1';
    }
    getChangeList() {
        return ['Added schemaOverride interaction for root page switchers'];
    }
    migrateNodeListDSL(nodeListDSL) {
        const migratedNodeListDSL = mapObjIndexed(nodeDSL => {
            const isRouterSwitchNode = nodeDSL.name === COMPONENT_DSL_NAMES.RouterSwitch ||
                nodeDSL.name === COMPONENT_DSL_NAMES.RouterSwitchSymbol;
            if (!isRouterSwitchNode) {
                return nodeDSL;
            }
            const hasRouterSwitchAncestors = nodeListSelectors.hasAncestors(nodeListDSL, {
                nodeID: nodeDSL.id,
                ancestorNames: [COMPONENT_DSL_NAMES.RouterSwitch, COMPONENT_DSL_NAMES.RouterSwitchSymbol],
            });
            if (isRouterSwitchNode && !hasRouterSwitchAncestors) {
                return Object.assign(Object.assign({}, nodeDSL), { schemaOverride: {
                        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                    } });
            }
            return nodeDSL;
        }, nodeListDSL);
        return migratedNodeListDSL;
    }
}
export const migration_0_5_1 = new Migration_0_5_1();
