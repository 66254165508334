var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Autocomplete } from '../Autocomplete';
import { getIsInvalid, RESET_STATUS } from '../common';
export const FormAutocomplete = forwardRef((_a, ref) => {
    var { id, className, title, lang, translate, required = false, autofocus = false, readonly = false, fieldProps, autocompleteProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, onChange } = _a, rest = __rest(_a, ["id", "className", "title", "lang", "translate", "required", "autofocus", "readonly", "fieldProps", "autocompleteProps", 'data-test', 'data-node-id', 'data-node-render-path', "onChange"]);
    const { setFieldValue, status, setStatus, values } = useFormikContext();
    useEffect(() => {
        setFieldValue(fieldProps.name, {
            label: '',
            value: '',
        });
        return () => setFieldValue(fieldProps.name, undefined);
    }, [fieldProps.name, setFieldValue]);
    useEffect(() => {
        if (status === RESET_STATUS) {
            setStatus(undefined);
            const event = {};
            onChange === null || onChange === void 0 ? void 0 : onChange(event, null, 'clear', undefined);
            setFieldValue(fieldProps.name, null);
        }
    }, [fieldProps.name, setFieldValue, status, setStatus, onChange]);
    useEffect(() => {
        if (autocompleteProps.value != null &&
            onChange != null &&
            autocompleteProps.value !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, autocompleteProps.value);
        }
    }, [fieldProps.name, setFieldValue, autocompleteProps.value, values, onChange]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(Autocomplete, Object.assign({}, rest, autocompleteProps, { ref: ref, id: id, className: className, title: title, autoFocus: autofocus, required: required, lang: lang, translate: translate, readOnly: readonly, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, value: field.value, error: isInvalid, helperText: errorText, name: fieldProps.name, 
                // TODO: rewrite types for onChange function (Material-v4 => Material-v5)
                // This was a little workaround but after update @types/react to version 17.0.3
                // the typescript starts to show a warn here here so this function should be rewritten
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange: (event, value, reason, details) => {
                    form.setFieldValue(fieldProps.name, value);
                    onChange === null || onChange === void 0 ? void 0 : onChange(event, value, reason, details, {
                        field,
                        meta,
                        form,
                    });
                } })));
        } })));
});
