import { parse } from 'graphql';
import { isArray, isNil, isString } from '../type-checkers';
import { regexpForECMAScriptFunctionName } from './regexpForECMAScriptFunctionName';
export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);
export const required = (value) => {
    const isArrayEmpty = isArray(value) && value.length === 0;
    const isEmpty = isNil(value) || value === '';
    const haveOnlySpaces = isString(value) && value.trim() === '';
    if (isArrayEmpty || isEmpty || haveOnlySpaces) {
        return 'Required';
    }
};
export const mustBeJson = (value) => {
    try {
        JSON.parse(value);
        return undefined;
    }
    catch (_a) {
        return 'Invalid JSON';
    }
};
export const mustBeNumber = (value) => /[1-9]+/.test(value) ? undefined : 'Should be number';
export const mustBeEmail = (value) => /\S+@\S+\.\S+/.test(value) ? undefined : 'Invalid email';
export const minLength = (min) => (value) => !isString(value) || value.length >= min ? undefined : `Minimum length is ${min}`;
export const maxLength = (max) => (value) => !isString(value) || value.length <= max ? undefined : `Maximum length is ${max}`;
export const mustBeLength = (length) => (value) => !isString(value) || value.length === length ? undefined : `Length should be ${length}`;
export const mustBeCorrectVariableName = (value) => {
    if ((value === null || value === void 0 ? void 0 : value.toString().toLowerCase()) !== 'object' &&
        (!isString(value) || regexpForECMAScriptFunctionName.test(value))) {
        return undefined;
    }
    return `Invalid variable name`;
};
export const mustBeCorrectLabelName = (value) => !isString(value) || regexpForECMAScriptFunctionName.test(value.replace(/ /g, ''))
    ? undefined
    : `Invalid label name`;
export const existVariableNameInArray = (value, allValues) => !isString(value) || !allValues.includes(value) ? undefined : `Invalid variable name`;
export const mustBeCorrectGraphqlString = (value) => {
    try {
        if (isString(value)) {
            parse(value);
        }
        return undefined;
    }
    catch (error) {
        return error.message;
    }
};
export const mustNotStartWithSpaces = (value) => {
    return isString(value) && /^\s/.test(value) ? 'Name group cant not start with spaces' : undefined;
};
export const hasNotContainSpecialCharacters = (value) => {
    return isString(value) && /[!@#$%^&*(),.?":{}|<>/=]/.test(value)
        ? 'No special characters are allowed'
        : undefined;
};
export const mustNotStartWithNumbers = (value) => {
    return isString(value) && /^\d/.test(value) ? 'Group name cant start with number' : undefined;
};
export const mustBeCorrectClassName = (value) => {
    return isString(value) && /^([a-zA-Z_-]+[a-zA-Z0-9_-]*)$/.test(value)
        ? undefined
        : 'Invalid css Class name';
};
