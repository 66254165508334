import { isDevelopment, isDevLogEnabled } from './processEnv';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const logConsole = (type, ...args) => {
    // eslint-disable-next-line no-console
    const fn = console[type];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return fn(...args);
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const devLogConsole = (type, ...args) => {
    if (isDevelopment() && isDevLogEnabled()) {
        logConsole(type, ...args);
    }
};
export const log = {
    log: (...args) => logConsole('log', ...args),
    error: (...args) => logConsole('error', ...args),
    warn: (...args) => logConsole('warn', ...args),
    info: (...args) => logConsole('info', ...args),
};
export const devLog = {
    log: (...args) => devLogConsole('log', ...args),
    error: (...args) => devLogConsole('error', ...args),
    warn: (...args) => devLogConsole('warn', ...args),
    info: (...args) => devLogConsole('info', ...args),
};
