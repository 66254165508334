import { Middleware } from 'redux';

import { UI_BUILDER_EVENTS } from '../common';
import { USER_APP_IFRAME_ID } from 'src/shared/constants';

function createPostMessageMiddleware(): Middleware {
  return () => next => action => {
    const { type, ...rest } = action;
    next(action);

    if (
      [UI_BUILDER_EVENTS.modeRequestPreview, UI_BUILDER_EVENTS.modeRequestConstruction].includes(
        type,
      )
    ) {
      const iframe = document.getElementById(USER_APP_IFRAME_ID) as HTMLIFrameElement;
      iframe?.contentWindow?.postMessage(
        {
          type,
          payload: rest,
          source: 'APP_BUILDER_SOURCE',
        },
        window.location.origin,
      );
    }
  };
}

export { createPostMessageMiddleware as default };
