import { pick } from 'ramda';

import { AssetDSL, AssetFileWithBackendDataDSL, AssetDataDSL, ASSET_TYPES } from '@builder/schemas';

const COMMON_ASSET_PROPS = [
  'id',
  'type',
  'name',
  'title',
  'mimetype',
  'createdAt',
  'updatedAt',
  'createdBy',
] as const;
const COMMON_ASSET_FILE_PROPS = ['parentID', 'backendFileID', 'description', 'size'] as const;
const ASSET_FOLDER_PROPS = ['parentID', 'children'] as const;
const ASSET_IMAGE_PROPS = ['alt', 'height', 'width'] as const;
const ASSET_FONT_PROPS = ['format'] as const;

/**
 * remove all unnecessary data from the asset data which could be passed to the it by mistake
 *
 * @example
 *  {
 *      name: 'untitled246',
 *      parentID: 'AssetsRootFolder_ID',
 *      backendFileID: '5dc5f093-80c9-47a0-b7f8-021616f60818',
 *      type: 'image',
 *      title: 'Screenshot 2022 04 05 at 12 20 34',
 *      size: 437358,
 *      mimeType: 'image/png',
 *      height: 644,
 *      width: 650,
 *      id: 'I_fJr6W7o0XtZDW7uXVqG',
 *      src:
 *        'https://ucarecdn.com/1c109dfd-ad83-4a02-83bb-83bb17ff1bc9/?token=exp=1649203200~acl=/1c109dfd-ad83-4a02-83bb-83bb17ff1bc9/*~hmac=419fec16db88704ef80411200f165aff9fff7993f590d70c5e3c88296f7b5e1b',
 *      createdAt: '2022-04-05T15:25:06.792Z',
 *      updatedAt: '2022-04-05T15:25:06.792Z',
 *      createdBy: {
 *        __typename: 'User',
 *        id: 'ckttzk16m00lt09lbb5uke3i9',
 *        firstName: 'Petr312',
 *        lastName: 'Bukov1',
 *      },
 *    } ->
 *    {
 *      name: 'untitled246',
 *      parentID: 'AssetsRootFolder_ID',
 *      backendFileID: '5dc5f093-80c9-47a0-b7f8-021616f60818',
 *      type: 'image',
 *      title: 'Screenshot 2022 04 05 at 12 20 34',
 *      size: 437358,
 *      height: 644,
 *      width: 650,
 *      id: 'I_fJr6W7o0XtZDW7uXVqG',
 *    }
 */
export const clearAssetDSL = (
  assetDSL: AssetDSL | AssetFileWithBackendDataDSL | AssetDataDSL,
): AssetDSL | Omit<AssetDSL, 'id'> => {
  switch (assetDSL.type) {
    case ASSET_TYPES.folder: {
      return pick([...COMMON_ASSET_PROPS, ...ASSET_FOLDER_PROPS], assetDSL);
    }

    case ASSET_TYPES.image: {
      return pick(
        [...COMMON_ASSET_PROPS, ...COMMON_ASSET_FILE_PROPS, ...ASSET_IMAGE_PROPS],
        assetDSL,
      );
    }

    case ASSET_TYPES.font: {
      return pick(
        [...COMMON_ASSET_PROPS, ...COMMON_ASSET_FILE_PROPS, ...ASSET_FONT_PROPS],
        assetDSL,
      );
    }

    default: {
      return pick([...COMMON_ASSET_PROPS, ...COMMON_ASSET_FILE_PROPS], assetDSL);
    }
  }
};
