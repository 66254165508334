import { FileInfo } from '@uploadcare/react-widget';

import { AssetTypes, ASSET_TYPES } from '@builder/schemas';
import { isNil } from '@builder/utils';

import { CreateAssetData } from 'src/shared/graphql/hooks/useAssetsHooks';

import {
  getFileNameWithoutSpacesAndSymbols,
  getNextUniqueFilename,
  trimFilenameExtension,
} from './getNextUniqueFilename';

export const getAssetCreateInputByType = (
  type: Exclude<AssetTypes, 'folder'>,
  fileInfo: FileInfo,
  existingFilenames: string[],
): CreateAssetData | null => {
  if (isNil(fileInfo?.uuid) || isNil(fileInfo?.name)) {
    return null;
  }

  const filename = getNextUniqueFilename(
    existingFilenames,
    getFileNameWithoutSpacesAndSymbols(trimFilenameExtension(fileInfo.name)),
  );

  const baseAssetData = {
    fileId: fileInfo.uuid,
    filename,
    meta: {
      name: filename,
      mimetype: fileInfo?.mimeType,
      size: fileInfo?.size,
    },
  } as CreateAssetData;

  switch (type) {
    case ASSET_TYPES.image: {
      return {
        ...baseAssetData,
        meta: {
          ...baseAssetData.meta,
          type: ASSET_TYPES.image,
          height: fileInfo?.originalImageInfo?.height,
          width: fileInfo?.originalImageInfo?.width,
        },
      };
    }

    default: {
      return {
        ...baseAssetData,
        meta: {
          ...baseAssetData.meta,
          type,
        },
      };
    }
  }
};
