import { useCallback } from 'react';

import { Field as FormikField, FieldConfig, FieldProps as FieldFormikProps } from 'formik';

import { isFunction } from '@builder/utils';

export type FieldProps = FieldConfig & {
  /**
   * If true then use setFieldValue instead of handleChange.
   * handleChange expects event, but setFieldValue expects value.
   */
  useValueOnChange?: boolean;
};

export type FieldRenderProps = FieldFormikProps;

export const Field = ({ children, useValueOnChange = false, ...rest }: FieldProps): JSX.Element => {
  const getOnChange = useCallback(
    (name: string, setFieldValue: FieldRenderProps['form']['setFieldValue']) => (
      value: unknown,
    ) => {
      setFieldValue(name, value);
    },
    [],
  );

  return (
    <FormikField {...rest}>
      {(fieldRenderProps: FieldRenderProps) => {
        const onChange = getOnChange(
          fieldRenderProps.field.name,
          fieldRenderProps.form.setFieldValue,
        );

        const updatedFieldRenderProps = useValueOnChange
          ? {
              ...fieldRenderProps,
              field: {
                ...fieldRenderProps.field,
                onChange,
              },
            }
          : fieldRenderProps;

        return isFunction(children) ? children(updatedFieldRenderProps) : children;
      }}
    </FormikField>
  );
};
