import { Migration } from '../Migration';
class Migration_0_18_5 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.5';
    }
    getChangeList() {
        return ['Updating text component schema'];
    }
    migrateAppDSL(appDSL) {
        let nodes = Object.assign({}, appDSL.nodes);
        const createHtmlFromJson = (nodesArr) => {
            let html = '';
            for (const node of nodesArr) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { tag, props, style } = node;
                const styleString = style
                    ? Object.keys(style)
                        .map(key => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}:${style[key]};`)
                        .join('')
                    : '';
                const content = props.children || '';
                const styleprop = styleString === '' ? '' : ` style="${styleString}"`;
                html += `<${tag}${styleprop}>${content}</${tag}>`;
            }
            return html;
        };
        const getConentForNode = (mainNode) => {
            const fullChildrenNodes = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const children = mainNode.props.children;
            if (mainNode.name === 'Text') {
                const parent = nodes[mainNode.parentID];
                fullChildrenNodes.push(Object.assign(Object.assign({}, mainNode), { tag: parent.name, style: parent.props.style }));
            }
            if (children && typeof children === 'object' && 'nodes' in children) {
                const childNodes = [...children.nodes];
                for (const childId of childNodes) {
                    const childNode = nodes[childId];
                    if (childNode) {
                        const recrusionResult = getConentForNode(childNode);
                        fullChildrenNodes.push(...recrusionResult);
                    }
                }
            }
            return fullChildrenNodes;
        };
        const filteredNodes = Object.values(nodes).filter((node) => {
            return node.name === 'BuilderRichText';
        });
        if (filteredNodes.length === 0) {
            return appDSL;
        }
        for (const mainNode of filteredNodes) {
            const content = getConentForNode(mainNode);
            const htmlContent = createHtmlFromJson(content);
            nodes = Object.assign(Object.assign({}, nodes), { [mainNode.id]: Object.assign(Object.assign({}, mainNode), { props: Object.assign(Object.assign({}, mainNode.props), { content: htmlContent }) }) });
        }
        return Object.assign(Object.assign({}, appDSL), { nodes: Object.assign(Object.assign({}, appDSL.nodes), nodes) });
    }
}
export const migration_0_18_5 = new Migration_0_18_5();
