import { Monaco } from '@monaco-editor/react';
import { GraphQLSchema } from 'graphql';

import { AppStateAccessorFactory } from '@builder/code-engine';
import {
  LibraryDSL,
  AssetFileWithBackendDataListDSL,
  NodeDSL,
  NodeListDSL,
  ResourceDSL,
  ResourceListDSL,
  StateDSL,
  StateListDSL,
  STATE_REQUEST_VARIANTS,
  STATE_TYPES,
  GenerateTypes,
  EXPORT_AS_NAMESPACE,
  librarySelectors,
} from '@builder/schemas';
import {
  GlobalTypesGenerator,
  GqlTypesGenerator,
  IteratorTypesGenerator,
  PredefinedTypesGenerator,
  LibraryTypesGenerator,
} from '@builder/types-generators';
import { log } from '@builder/utils';

import { argTypes } from 'src/dialogs/GlobalDialogs/CodeEditorDialog/CodeEditorDialog';

/**
 * Note: all the functions mutate passed monaco instance, they are not pure
 */

export const disposePreviousModels = (monaco: Monaco): void => {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  monaco.editor.getModels().forEach(model => {
    if (
      // @ts-ignore
      model._languageIdentifier.language === 'typescript' ||
      // @ts-ignore
      model._languageIdentifier.language === 'javascript'
    ) {
      if (model.uri.scheme === 'file') {
        model.dispose();
      }
    }
  });
  /* eslint-enable @typescript-eslint/ban-ts-comment */
};

export const addDefaultOptions = (monaco: Monaco): void => {
  monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ES2016,
    allowNonTsExtensions: true,
    allowJs: true,
    noImplicitAny: false,
    moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
    module: monaco.languages.typescript.ModuleKind.CommonJS,
    noEmit: true,
    typeRoots: ['node_modules/@types'],
  });

  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
  });
};

export const addPredefinedTypes = (monaco: Monaco, nodeListDSL: NodeListDSL): void => {
  const predefinedTypesGenerator = new PredefinedTypesGenerator();
  const predefinedTypes = predefinedTypesGenerator.generateTypes(nodeListDSL);

  monaco.languages.typescript.typescriptDefaults.setExtraLibs(predefinedTypes);

  const modelUri = monaco.Uri.parse(`file:///states/predefined.ts`);
  const typings = predefinedTypes.filter(type => type.filePath.includes('predefined'))[0];

  monaco.editor.createModel(typings.content, 'typescript', modelUri);
};

export const addGlobalTypes = (monaco: Monaco): void => {
  const globalTypesGenerator = new GlobalTypesGenerator();
  const globalTypes = globalTypesGenerator.generateTypes();
  monaco.languages.typescript.typescriptDefaults.setExtraLibs(globalTypes);
};

export const addEventTypes = (monaco: Monaco, event: Event, args?: argTypes | undefined): void => {
  const context = args?.context?.map(arg => `declare const ${arg}: any = null;`).join('\n');

  const typings = args?.context
    ? `currentEvent
    ${context}`
    : 'currentEvent';

  const modelUri = monaco.Uri.parse(`file:///event.ts`);

  monaco.editor.createModel(typings, 'typescript', modelUri);
};

export const addIterableTypes = (monaco: Monaco, iterableNodeListDSL: NodeDSL[]): void => {
  const iteratorTypesGenerator = new IteratorTypesGenerator(iterableNodeListDSL);
  const iterableTypes = iteratorTypesGenerator.generateTypes();

  if (iterableTypes) {
    const modelUri = monaco.Uri.parse('file:///generated/iterable.ts');
    monaco.editor.createModel(iterableTypes, 'typescript', modelUri);
  }
};

const addExtraLibrary = ({
  types,
  monaco,
  varName,
  alias,
}: {
  types: GenerateTypes[];
  monaco: Monaco;
  varName: string;
  alias: string;
}): void => {
  types.forEach(({ content, filePath }: GenerateTypes) => {
    const aliasToReplace = `${EXPORT_AS_NAMESPACE} ${varName}`;
    const contentWithAlias = content.replace(
      aliasToReplace,
      `${EXPORT_AS_NAMESPACE} ${alias || varName}`,
    );
    monaco.languages.typescript.typescriptDefaults.addExtraLib(contentWithAlias, filePath);
  });
};

export const addLibrariesTypes = (monaco: Monaco, librariesDSL: LibraryDSL[]): void => {
  const libraryTypesGenerator = new LibraryTypesGenerator();

  librariesDSL.forEach((libraryDSL: LibraryDSL) => {
    const {
      libraryData: { varName },
      versionData,
    } = librarySelectors.getLibraryDataAndVersion(libraryDSL);
    const libraryData = { libraryDSL, varName, versionData };
    libraryTypesGenerator
      .generateTypes(libraryData)
      .then((types: GenerateTypes[]) => {
        addExtraLibrary({
          types,
          monaco,
          varName: libraryData.varName,
          alias: libraryData.libraryDSL.alias,
        });
      })
      .catch(err => log.error(err));
  });
};

// change location
// type GenerateTypesFunction = { content: string; filePath: string; functionName?: string };

/** @deprecated This function is not util while there is not a return type for the function in the functions editor. APB-2658 */
export const addFunctionTypes = (monaco: Monaco, stateListDSL: StateListDSL): void => {
  // const functionTypesGenerator = new FunctionTypesGenerator();
  // const functionArrayDSL = stateListSelectors.getFunctionStateArrayDSL(stateListDSL);
  // functionArrayDSL.forEach((functionDSL: StateFunctionDSL) => {
  // const { content, filePath }: GenerateTypesFunction = functionTypesGenerator.getFunctionType(
  //   functionDSL.name,
  //   functionDSL.return.definition,
  //   functionDSL.arguments,
  // );
  // monaco.languages.typescript.typescriptDefaults.addExtraLib(content, filePath);
  // });
};

export const addAppStatesTypes = ({
  monaco,
  gqlSchema,
  appStatesDSL,
  resourceListDSL,
  assetListDSL,
  authResourceDSL,
}: {
  monaco: Monaco;
  gqlSchema?: GraphQLSchema;
  appStatesDSL: StateListDSL;
  resourceListDSL: ResourceListDSL;
  assetListDSL: AssetFileWithBackendDataListDSL;
  authResourceDSL: ResourceDSL | null;
}): void => {
  const appStateAccessorFactory = new AppStateAccessorFactory({
    stateListDSL: appStatesDSL,
    resourceListDSL,
    assetListDSL,
    authResourceDSL,
  });
  const gqlTypesGenerator = gqlSchema ? new GqlTypesGenerator(gqlSchema) : undefined;
  const gqlStateDSL: StateDSL[] = [];

  const addStateTypesToEditor = (appStateDSL: StateDSL, gqlGeneratedTypes?: string) => {
    try {
      const appStateAccessor = appStateAccessorFactory.createAppStateAccessor(appStateDSL);
      const typings = appStateAccessor.getHookTypings(gqlGeneratedTypes);
      const modelUri = monaco.Uri.parse(`file:///states/${appStateDSL.name}.ts`);
      monaco.editor.createModel(typings, 'typescript', modelUri);
    } catch (err) {
      const rawStateDSL = JSON.stringify(appStateDSL, null, 2);
      log.error(`@builder/client/MonacoEditor Can not create types for: ${rawStateDSL}`);
      log.error(err);
    }
  };

  Object.keys(appStatesDSL).forEach(stateID => {
    const appStateDSL = appStatesDSL[stateID];

    // skip query states and generate types only for base hooks
    if (
      gqlTypesGenerator &&
      appStateDSL.type === STATE_TYPES.query &&
      appStateDSL.variant !== STATE_REQUEST_VARIANTS.rest
    ) {
      gqlTypesGenerator.addGqlState(appStateDSL);
      gqlStateDSL.push(appStateDSL);
      return;
    }

    addStateTypesToEditor(appStateDSL);
  });

  // add gql generated types to hooks
  if (gqlTypesGenerator) {
    gqlTypesGenerator.generateTypes().then(gqlGeneratedTypes => {
      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        gqlGeneratedTypes.replace(/export/gm, ''),
        'file:///generated/gql.ts',
      );

      gqlStateDSL.forEach(appStateDSL => {
        addStateTypesToEditor(appStateDSL, gqlGeneratedTypes);
      });
    });
  }
};
