import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { assocSettings, BASIC_PROPS_TOOLTIP, getCommonFormFieldActionSettings, NS_ID, getBasicPropsSettings, } from '../../common';
import { getDivider } from '../../common/settings/commonSettings';
import { getBuilderComponentsFileUploadSettings } from '../../material-components/BuilderComponentsFileUpload/BuilderComponentsFileUpload.settings';
const basicFileUploadSettings = getBuilderComponentsFileUploadSettings(['inputProps']);
export const BuilderComponentsFormFileUploadSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root], settings => (Object.assign(Object.assign({}, settings), { children: [
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Basic',
                name: NS_ID.properties.basic,
                titleDescription: BASIC_PROPS_TOOLTIP,
                children: [
                    {
                        componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['accept'],
                        label: 'Accepted Files',
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.number,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['maxFileSize'],
                        label: 'Max File Size (MB)',
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.checkbox,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['required'],
                        label: 'Required',
                    },
                ],
            },
            getDivider(),
            ...getBasicPropsSettings(),
            getDivider(),
        ] }))))(basicFileUploadSettings.properties),
    style: basicFileUploadSettings.style,
    events: pipe(assocSettings([NS_ID.events.root, NS_ID.events.events], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldActionSettings(), ...settings.children] }))))(basicFileUploadSettings.events),
};
