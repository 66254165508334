import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import { Button } from 'src/shared/components/Button/Button';

export const StyledViewButton = styled(Button)`
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  .MuiButton-startIcon {
    padding: 0;
    margin: 0;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

export const AssetsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;
