import { Dispatch } from 'redux';

import { MainPropType, NodeDSL, ComponentListDSL, COMPONENT_DSL_NAMES } from '@builder/schemas';

import { useNodeListDSL } from '../../../../providers/ReduxProvider/ReduxProvider';
import {
  AppEvents,
  COMPONENT_SELECT_ACTORS,
  DASHBOARD_EVENTS,
  NODE_SETTINGS_TABS,
  RIGHT_PANEL_TABS,
  UI_BUILDER_EVENTS,
} from 'src/store';

type SelectMainPropType = {
  conmponentList: ComponentListDSL;
  nodeDSL: NodeDSL;
  send: Dispatch<AppEvents>;
};

type SelectMain = {
  selectMainProp: ({ conmponentList, nodeDSL, send }: SelectMainPropType) => void;
};

export const useSelectMainProp = (): SelectMain => {
  const nodeListDSL = useNodeListDSL();

  const selectMainProp = ({ conmponentList, nodeDSL, send }: SelectMainPropType) => {
    const propPathList =
      (conmponentList?.[nodeDSL?.name ?? '']?.schema?.mainPropPath as MainPropType) ?? {};

    let nodeIDToSelect = nodeDSL.id;
    let currentNode = nodeDSL;
    if (nodeDSL?.name === COMPONENT_DSL_NAMES.BuilderRichText) {
      const spanElement = document.querySelector('span[data-lexical-text="true"]') as Element;
      const range = document.createRange();
      if (range && spanElement) {
        range?.selectNodeContents(spanElement);
        const selection = window.getSelection() as Selection;
        selection?.removeAllRanges();
        selection?.addRange(range);
      }

      return;
    }

    if (Object.values(propPathList).length && propPathList.mainProp && nodeIDToSelect) {
      if (propPathList?.selectNodeInside?.length) {
        (propPathList?.selectNodeInside ?? []).forEach((prop: string) => {
          nodeIDToSelect = (currentNode?.props?.[prop] as Record<string, string>)?.nodes?.[0];
          currentNode = nodeListDSL[nodeIDToSelect];
        });
        send({
          type: DASHBOARD_EVENTS.dashboardClearSelection,
        });

        send({
          type: DASHBOARD_EVENTS.componentToggleSelect,
          id: nodeIDToSelect,
          actor: COMPONENT_SELECT_ACTORS.dashboard,
        });
      }

      send({
        type: UI_BUILDER_EVENTS.updateRightPanel,
        rightPanel: {
          currentTab: RIGHT_PANEL_TABS.componentList,
          nodeSettingsArgs: {
            currentTab: NODE_SETTINGS_TABS.properties,
            focusLabelForCurrentNode: {
              inputLabelToFocus: propPathList.mainProp,
              focused: false,
              nodeId: nodeIDToSelect,
            },
          },
        },
      });
    }
  };

  return { selectMainProp };
};
