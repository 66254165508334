export const STATE_SCOPES = {
    global: 'global',
    local: 'local',
};
export const RUNTIMESTATE = {
    localState: 'localState',
    localStates: 'localStates',
};
export const ACTION_ARGS = {
    function: 'function',
    requestID: 'requestID',
    code: 'code',
};
export const STATE_CATEGORY = {
    functions: 'functions',
    requests: 'requests',
    customEntries: 'customEntries',
};
export const STATE_TYPES = {
    query: 'query',
    boolean: 'boolean',
    string: 'string',
    number: 'number',
    object: 'object',
    array: 'array',
    function: 'function',
};
export const STATE_BOOLEAN_VARIANTS = {
    trueFalse: 'true-false',
    openClose: 'open-close',
};
export const STATE_REQUEST_VARIANTS = {
    rest: 'rest',
    query: 'query',
    lazyQuery: 'lazyQuery',
    mutation: 'mutation',
};
export const STATE_REQUEST_CALLBACKS = {
    onCompleted: 'onCompleted',
    onError: 'OnError',
};
export const REST_DATA_TYPE = {
    raw: 'raw',
    json: 'json',
    forData: 'formData',
    binary: 'binary',
};
export const STATE_TYPE_CATEGORIES_MAPPER = {
    [STATE_TYPES.query]: STATE_CATEGORY.requests,
    [STATE_TYPES.function]: STATE_CATEGORY.functions,
    [STATE_TYPES.boolean]: STATE_CATEGORY.customEntries,
    [STATE_TYPES.string]: STATE_CATEGORY.customEntries,
    [STATE_TYPES.number]: STATE_CATEGORY.customEntries,
    [STATE_TYPES.object]: STATE_CATEGORY.customEntries,
    [STATE_TYPES.array]: STATE_CATEGORY.customEntries,
};
export const STATE_ACTIONS = {
    setValue: 'setValue',
    setProperty: 'setProperty',
    insertItemInArray: 'insertItemInArray',
    removeItemFromArray: 'removeItemFromArray',
    setTrue: 'setTrue',
    setFalse: 'setFalse',
    toggle: 'toggle',
};
