import React, { useCallback } from 'react';

import { ArrayPropValue } from '@builder/schemas';
import { Z_INDEXES_APP } from '@builder/utils';

import { Popper, PopperProps } from 'src/shared/components';

import { NODE_ACTIONS_POPPER_MODIFIERS } from './AddTestPopper.constants';
import { MenuButton, MenuContent } from './AddTestPopper.styles';

type NodeActionsPopperProps = PopperProps & {
  toggleMenu: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
  componentName?: string;
  nodeID?: string;
  isMultiSelected?: boolean;
  selectedTypeCases: string[];
  onUpdateProp: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  parentKeyPath: (string | number)[];
  parentKeyValue: ArrayPropValue<string>;
};

export const AddTestPopper: React.FC<NodeActionsPopperProps> = ({
  open: isOpen,
  anchorEl,
  toggleMenu,
  onClose,
  placement,
  modifiers,
  zIndex,
  selectedTypeCases,
  onUpdateProp,
  parentKeyPath,
  parentKeyValue,
}) => {
  const handleCreateTest = useCallback(
    (option: string, event) => {
      toggleMenu(event);
      onUpdateProp({
        keyValue: { Key: option },
        keyPath: [...parentKeyPath, parentKeyValue.length],
      });
    },
    [onUpdateProp, parentKeyPath, parentKeyValue.length, toggleMenu],
  );

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      placement={placement || 'bottom-start'}
      modifiers={modifiers || NODE_ACTIONS_POPPER_MODIFIERS}
      zIndex={zIndex || Z_INDEXES_APP.breadcrumbs}
      onClose={onClose}
    >
      {selectedTypeCases && (
        <MenuContent>
          {selectedTypeCases.map(option => (
            <MenuButton
              key={option}
              onClick={event => {
                handleCreateTest(option, event);
              }}
            >
              {option}
            </MenuButton>
          ))}
        </MenuContent>
      )}
    </Popper>
  );
};
