import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { ROUTING_COMPONENT_DSL_NAMES, nodeListSelectors, } from '@builder/schemas';
import { memo } from '@builder/utils';
import { PathProvider } from './app-state/path-context/PathContext';
import { AppEngineProvider, RuntimeErrorProvider, UserAppEventEmitterProvider } from './providers';
import { DashboardProvider } from './providers/DashboardProvider';
import { SCROLL, SCROLL_END, SCROLL_START, isRenderMoreHooksError } from './utils';
import { sortStatesByTimesUsed } from './utils/sortStatesByTimesUsed';
function removeTrailingSlash(str) {
    return str.endsWith('/') && str.length > 1 ? str.slice(0, -1) : str;
}
/**
 * Main component to generate to generate application from dsl
 */
const AppEngine = ({ appDSL, assetBackendList, componentListDSL, renderReactComponent, onRouterUrlChange, delegateErrorHandling, skipPresentations, onCloseErrorDialog, onAppCrashReported, onAppErrorReported, onAppAuditNotifications, onRuntimeStateReport, onRuntimeStateContext, onPaintComplete, emitter, contextWindow, cacheKey, currentRoute, presentationStates, }) => {
    var _a;
    const [remountIndex, setRemountIndex] = useState(0);
    const notificationsListRef = useRef([]);
    const currentPathClear = useMemo(() => removeTrailingSlash(currentRoute), [currentRoute]);
    useEffect(() => {
        // A little-known way to schedule a task after frame paint (when we see a rendered frame)
        requestAnimationFrame(() => {
            const messageChannel = new MessageChannel();
            messageChannel.port1.onmessage = onPaintComplete;
            messageChannel.port2.postMessage(undefined);
        });
    });
    useEffect(() => {
        // clear notifications list on before each render
        notificationsListRef.current = [];
        // pass notifications after each render
        return () => {
            onAppAuditNotifications === null || onAppAuditNotifications === void 0 ? void 0 : onAppAuditNotifications(notificationsListRef.current);
        };
    });
    const updateRemountIndex = useCallback(() => {
        setRemountIndex(prevIndex => prevIndex + 1);
    }, []);
    const handleRuntimeStateReport = useCallback((report) => {
        onRuntimeStateReport(report);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onRuntimeStateContext === null || onRuntimeStateContext === void 0 ? void 0 : onRuntimeStateContext(window.appRuntimeState);
    }, [onRuntimeStateContext, onRuntimeStateReport]);
    const closeErrorMessage = useCallback((errorMessage) => {
        onCloseErrorDialog === null || onCloseErrorDialog === void 0 ? void 0 : onCloseErrorDialog(errorMessage);
    }, [onCloseErrorDialog]);
    const onAppFunctionError = useCallback((error) => {
        (error === null || error === void 0 ? void 0 : error.message) && (onAppErrorReported === null || onAppErrorReported === void 0 ? void 0 : onAppErrorReported(error === null || error === void 0 ? void 0 : error.message));
    }, [onAppErrorReported]);
    const handleAppNotifications = useCallback((appAuditNotifications) => {
        notificationsListRef.current = [...notificationsListRef.current, ...appAuditNotifications];
        onAppAuditNotifications === null || onAppAuditNotifications === void 0 ? void 0 : onAppAuditNotifications(notificationsListRef.current);
        appAuditNotifications.forEach(notification => {
            onAppErrorReported === null || onAppErrorReported === void 0 ? void 0 : onAppErrorReported(notification.message);
        });
    }, [onAppAuditNotifications, onAppErrorReported]);
    const sortStatesByUse = useMemo(() => sortStatesByTimesUsed(appDSL, handleAppNotifications), [appDSL, handleAppNotifications]);
    const statesList = useMemo(() => sortStatesByUse.reduce((acc, current) => {
        return Object.assign(Object.assign({}, acc), { [current.id]: Object.assign({}, current) });
    }, {}), [sortStatesByUse]);
    /**
     * Needs to remount app every time hooks or api endpoint were changed.
     * Because of react doesn't support runtime changing of hooks amount or ordering.
     */
    const baseResourcesKeys = useMemo(() => Object.values(appDSL.resources).map(resource => resource.type === 'backend8base'
        ? `${resource.workspaceID}_${resource.environment}`
        : `${resource.id}_${resource.endpoint}`), [appDSL.resources]);
    const currentRouteMainNodeKey = useMemo(() => {
        var _a;
        return ((_a = nodeListSelectors.getCurrentRouteNodeDSL(appDSL.nodes, {
            currentPathname: currentRoute,
        })) === null || _a === void 0 ? void 0 : _a.id) || '';
    }, [appDSL.nodes, currentRoute]);
    const statesKey = useMemo(() => Object.values(statesList)
        .map(state => `${state.name} - ${state.type} - ${state.variant ? state.variant : ''} - ${state.scope}  - ${state.id}`)
        .join(','), [statesList]);
    const pageIdList = useMemo(() => Object.values(appDSL.nodes)
        .filter(node => node.name === ROUTING_COMPONENT_DSL_NAMES.BuilderComponentsRoute)
        .map(node => node.id)
        .join(','), [appDSL.nodes]);
    const appEngineUniqStateKey = useMemo(() => { var _a; return `pageIds-${pageIdList}-route:${currentRouteMainNodeKey}-${statesKey}-resources:${baseResourcesKeys}-authResourceID:${(_a = appDSL.settings) === null || _a === void 0 ? void 0 : _a.authResourceID}-${remountIndex}`; }, [
        (_a = appDSL.settings) === null || _a === void 0 ? void 0 : _a.authResourceID,
        baseResourcesKeys,
        currentRouteMainNodeKey,
        pageIdList,
        remountIndex,
        statesKey,
    ]);
    const appDSLRef = useRef(appDSL);
    useEffect(() => {
        appDSLRef.current = appDSL;
    }, [appDSL]);
    let isScrolling;
    window.addEventListener(SCROLL, () => {
        // Send a message to inform the client (abbBuilder) that they are scrolling within the iframe.
        window.parent.postMessage({ type: SCROLL_START }, '*');
        if (window.top) {
            if (window.scrollY === 0) {
                window.top.scrollYPosition = window.top.scrollYPosition || 0;
                if (window.top.scrollYPosition > 100) {
                    window.scrollTo(0, window.top.scrollYPosition);
                }
            }
            window.top.scrollYPosition = Math.floor(window.scrollY);
        }
        // Send a message to inform the client that the scrolling action has finished.
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
            window.parent.postMessage({ type: SCROLL_END }, '*');
        }, 100);
    });
    return (_jsx(ErrorBoundary, Object.assign({ resetKeys: [appDSL, remountIndex], onError: (error) => {
            /**
             * Skip throwing error if it is hook's error.
             * Needs because of user can change hooks in runtime but react doesn't support conditional hooks
             * (you will get this error if change amount or order of hooks in react component)
             */
            if (isRenderMoreHooksError(error.message)) {
                return updateRemountIndex();
            }
            console.warn(error);
            onAppCrashReported === null || onAppCrashReported === void 0 ? void 0 : onAppCrashReported(error.message);
        }, fallbackRender: ({ error }) => delegateErrorHandling ? null : (_jsxs(Dialog, Object.assign({ open: true, onClose: closeErrorMessage, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, Object.assign({ id: "app-runtime-dialog-title" }, { children: "Runtime error" })), _jsx(DialogContent, { children: _jsx(DialogContentText, Object.assign({ id: "app-runtime-dialog-description" }, { children: error.message })) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: () => closeErrorMessage(error.message), color: "primary" }, { children: "OK" })) })] }))) }, { children: _jsx(RuntimeErrorProvider, Object.assign({ onAppAuditNotifications: handleAppNotifications, onAppFunctionError: onAppFunctionError }, { children: _jsx(UserAppEventEmitterProvider, Object.assign({ emitter: emitter }, { children: _jsx(DashboardProvider, { children: _jsx(PathProvider, Object.assign({ path: currentPathClear }, { children: _jsx(AppEngineProvider, { presentationStates: presentationStates, currentRoute: currentRoute, contextWindow: contextWindow, appEngineUniqStateKey: appEngineUniqStateKey, appDSL: appDSL, assetBackendList: assetBackendList, componentListDSL: componentListDSL, skipPresentations: skipPresentations, renderReactComponent: renderReactComponent, onRouterUrlChange: onRouterUrlChange, onRuntimeStateReport: handleRuntimeStateReport, cacheKey: cacheKey, appDSLRef: appDSLRef }) })) }) })) })) })));
};
// TODO: ideally the skipping should be removed by memoizing non-changeable properties individually
//     : the fact that we are skipping them tells us their identities change (aren't wrapped into `useMemo/Callback`)
// TODO: the `memo` should be changed to `reactMemo` and skipping should be removed to avoid confusion;
export const AppEngineMemoized = memo('AppEngineMemoized', AppEngine, {
    skipCheckingFor: [
        // 'appDSL',                  - required
        // 'componentListDSL',        - required
        // 'assetBackendList',        - required
        'renderReactComponent',
        'onRouterUrlChange',
        'delegateErrorHandling',
        'skipPresentations',
        'onCloseErrorDialog',
        'onAppCrashReported',
        'onAppErrorReported',
        'onRuntimeStateReport',
        'onPaintComplete',
        'emitter',
        'contextWindow',
        'cacheKey', //                - could be skipped (one-time setting)
    ],
});
