import styled from '@emotion/styled';
import { Paper, Autocomplete as MuiAutocomplete, Box } from '@mui/material';

export const StyledMuiAutocomplete = styled(MuiAutocomplete)`
  flex: 1;
  & .MuiAutocomplete-endAdornment {
    top: auto;
  }

  & .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)} !important;

    .MuiAutocomplete-input {
      padding: 0;
      height: ${({ theme }) => theme.spacing(2)};
    }
  }

  & .MuiInputBase-root {
    padding: 0 ${({ theme }) => theme.spacing(1.25)};
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    border-style: hidden;
    &.MuiInputBase-adornedStart {
      padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(1.25)}`};
    }
  }

  & .MuiChip-root {
    background: ${({ theme }) => theme.palette.grey[300]};
    color: ${({ theme }) => theme.palette.grey[900]};
    font-size: 12px;
    svg {
      color: ${({ theme }) => theme.palette.grey[900]};
    }

    height: ${({ theme }) => theme.spacing(4)};
  }
`;

export const MuiAutocompleteAction = styled(MuiAutocomplete)`
  flex: 1;
  & .MuiAutocomplete-endAdornment {
    display: none;
    width: 0;
  }

  & .MuiChip-root {
    background: ${({ theme }) => theme.palette.grey[300]};
    color: white;
    font-size: 12px;
    svg {
      color: ${({ theme }) => theme.palette.grey[900]};
    }
    height: ${({ theme }) => theme.spacing(4)};
    border-style: none;
  }
`;

export const AutocompleteActionContainer = styled('div')`
  display: flex;
  background-color: transparent;
  height: 32px;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  .MuiSvgIcon-root {
    font-size: 24px;
    margin-left: 10px;
    margin-top: -8px;
    color: #dadada;
    cursor: pointer;
    &:hover {
      border-color: ${({ theme }) => theme.palette.grey[800]};
      color: white;
    }
  }
  .epa:hover {
    height: 32px;
    margin-top: -5px
    background-color: ${({ theme }) => theme.palette.grey[800]};
    border-color: ${({ theme }) => theme.palette.grey[800]};
    border-radius: 4px;
  }
`;

export const StyledMuiBox = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grey[800]};
  border-color: ${({ theme }) => theme.palette.grey[800]};
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 10px;
  width: 100%;
  &:hover {
    border-color: #0874f9;
    background-color: #0874f9;
    color: white;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
    font-size: 16px !important;
    margin-right: 5px !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    color: white !important;
  }
  .MuiInputBase-root {
    border: none;
    color: white !important;
    cursor: pointer !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: -30px !important;
    width: 120% !important;
    .MuiInputBase-input {
      cursor: pointer !important;
      margin: 0 !important;
      width: 100% !important;
      padding-left: 30px !important;
    }
  }
  .MuiInputBase-root::before {
    border: none;
    color: white !important;
    cursor: pointer !important;
  }
  .MuiInputBase-root:hover::before {
    border: none !important;
    color: white !important;
    cursor: pointer !important;
  }
`;

export const StyledPaper = styled(Paper)`
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
`;
