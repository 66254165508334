export const omitPropsSettings = (sections, section, prop) => {
    return sections.reduce((acc, setting) => {
        var _a;
        if ((setting === null || setting === void 0 ? void 0 : setting.title) === section) {
            const newChildren = (_a = setting === null || setting === void 0 ? void 0 : setting.children) === null || _a === void 0 ? void 0 : _a.filter(child => child.name !== prop);
            return [...acc, Object.assign(Object.assign({}, setting), { children: newChildren })];
        }
        return [...acc, setting];
    }, []);
};
