import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, LANG_OPTIONS, TRANSLATE_OPTIONS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getCommonPropertiesSections, getDivider, getStylesSection, getActionSettings, } from '../../common';
export const getPropertiesSettings = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'Common',
        expandedByDefault: true,
        titleDescription: {
            title: 'Common',
            subtitle: 'Common properties for all components',
        },
        children: [
            {
                type: COMPONENT_SETTING_TYPES.nodeText,
                keyPath: [...prefixPath, 'children'],
                label: 'Title',
            },
        ],
    },
    getDivider(),
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Common',
        name: 'Common',
        expandedByDefault: false,
        titleDescription: {
            title: 'Common',
            subtitle: 'Common properties for all components',
        },
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['id'],
                label: 'Id',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['className'],
                label: 'Class',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['lang'],
                label: 'Language',
                options: LANG_OPTIONS.map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['translate'],
                label: 'Translate',
                options: TRANSLATE_OPTIONS.map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['draggable'],
                label: 'Draggable',
            },
            getDivider(),
            {
                type: COMPONENT_SETTING_TYPES.section,
                name: 'customPropsSection',
                title: 'Custom Properties',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.list,
                        keyPath: ['customProps'],
                        name: 'customProps',
                        label: 'Custom Properties',
                        itemLabelKeyPath: ['Key'],
                        newObjectPredefineds: {
                            Key: 'propname',
                            Value: 'value',
                        },
                        settings: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['Key'],
                                label: 'Key',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['Value'],
                                label: 'Value',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    getDivider(),
    ...getCommonPropertiesSections(),
];
export const MaterialAlertTitleSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [...getPropertiesSettings(), getDivider()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
