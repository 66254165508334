import { CustomFontDSL } from '@builder/schemas';

const getFontFamily = (fontFamily: string) => {
  return `font-family: "${fontFamily}";`;
};

const getFontSrc = (format?: string): string => {
  return `src: url("{{ src }}")${format ? ` format("${format}")` : ''};`;
};

/**
 * @returns CSS string translated from font data provided by @param fontDSL
 * @example @param fontDSL { fontFamily: 'Roboto', format: 'woff2' }
 * @example @returns '@font-face {\n  font-family: "Roboto";\n  src: url("{{ src }}") format("woff2");\n}'
 * @template src requires valid link to be injected via @function injectFontFaceUrl
 */

export const createFontFaceCSS = (fontDSL: CustomFontDSL): string => {
  const fontFaceContents = [getFontFamily(fontDSL.fontFamily), getFontSrc(fontDSL?.format)].join(
    '\n  ',
  );

  return `
@font-face {
  ${fontFaceContents}
}`;
};
