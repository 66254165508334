import React from 'react';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  line-height: 1;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

type CustomSliderProps = {
  title: string;
  subTitle?: string;
  children: React.ReactElement;
};

export const SliderWithTitleWrapper: React.FC<CustomSliderProps> = ({
  title,
  subTitle,
  children,
}) => {
  return (
    <StyledWrapper>
      <TitleWrapper>
        <StyledTypography variant="subtitle2">{title}</StyledTypography>
        {subTitle && <StyledTypography variant="subtitle2">{subTitle}</StyledTypography>}
      </TitleWrapper>

      {children}
    </StyledWrapper>
  );
};
