import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialDialogContentSettings } from './MaterialDialogContent.settings';
export const MaterialDialogContent = {
    name: COMPONENT_DSL_NAMES.MaterialDialogContent,
    displayName: 'Dialog Content',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'DialogContent',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { dividers: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: MaterialDialogContentSettings,
};
