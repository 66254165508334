import { MutableRefObject } from 'react';

import { configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Rollbar from 'rollbar';

import { EventEmitter } from '@builder/utils';

import { OpenDialogFn } from 'src/providers/DialogProvider';
import { DraftEngine } from 'src/shared/draft-engine';

import { Store, AppEvents } from './common';
import { saga } from './cross-cutting/saga';
import { dashboardReducer } from './dashboard';
import { userAppRenderErrors } from './error-app-handler';
import createBroadcastChangesMiddleware from './middleware/broadcastChangesMiddleware';
import postMessageMiddleware from './middleware/postMessageMiddleware';
import { withAsyncReducer } from './middleware/workerMiddleware';
import { uiBuilderReducer } from './ui-builder';
import { userAppRenderDataReducer } from './user-app-render-data';
import { userAppRuntimeStateReducer } from './user-app-runtime';
import getAsyncReducer from './worker/rootAsyncReducer';

export const reducer = combineReducers<Store, AppEvents>({
  uiBuilder: uiBuilderReducer,
  dashboard: dashboardReducer,
  userAppRuntimeState: userAppRuntimeStateReducer,
  userAppNodeElements: userAppRenderDataReducer,
  userAppError: userAppRenderErrors,
});

export const getStore = (
  emitter: EventEmitter,
  draftEngineRef: MutableRefObject<DraftEngine | undefined>,
  rollbar: Rollbar,
  openDialog: OpenDialogFn,
  workspaceID: string | null,
): ToolkitStore<Store> => {
  const sagaMiddleware = createSagaMiddleware({ context: { emitter } });
  const broadcastChangesMiddleware = createBroadcastChangesMiddleware(
    draftEngineRef,
    rollbar,
    openDialog,
    workspaceID,
    reducer,
  );

  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
      broadcastChangesMiddleware,
      postMessageMiddleware(),
      withAsyncReducer(getAsyncReducer),
    ],
  });

  sagaMiddleware.run(saga);

  return store;
};
