import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import { COMPONENT_DSL_NAMES, NodeDSL, NodeStateConnectionDSL, StateDSL } from '@builder/schemas';

import { ActionScopeStrategy, SCOPE_GLOBAL } from './ActionScopeStrategy';

export class RouteCloneStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null {
    let newEventsToPush: EventPayload[] = [];
    if (
      eventPayload.operation === 'create' &&
      eventPayload.data.key.startsWith('appDSL.nodes') &&
      !eventPayload.data.key.includes(action.newPageId) &&
      eventPayload.data.node.name !== COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout
    ) {
      return { eventPayload: { ...eventPayload, scope: action.newPageId }, newEventsToPush };
    }

    if (
      eventPayload.operation === 'create' &&
      eventPayload.data.key.startsWith('appDSL.nodes') &&
      eventPayload.data.key.includes(action.newPageId) &&
      eventPayload.data.node.name !== COMPONENT_DSL_NAMES.BuilderComponentsRoute &&
      eventPayload.data.node.name !== COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout
    ) {
      return { eventPayload: { ...eventPayload, scope: action.newPageId }, newEventsToPush };
    }

    if (
      eventPayload.operation === 'arrayInsert' &&
      eventPayload.data.key.endsWith('.routes.nodes')
    ) {
      return { eventPayload: { ...eventPayload, scope: SCOPE_GLOBAL }, newEventsToPush };
    }

    if (
      eventPayload.operation === 'create' &&
      eventPayload.data.key.endsWith(`.nodes.${action.newPageId}`) &&
      eventPayload.data.node.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute &&
      Object.hasOwn(eventPayload.data.node.props, 'path')
    ) {
      const contextStatesCreation = Object.entries(
        eventPayload.data.node.context as StateDSL[],
      ).map(
        ([key, value]: [string, StateDSL]) =>
          ({
            operation: 'create',
            data: {
              key: `appDSL.nodes.${eventPayload.data.node.id}.context.${key}`,
              node: {
                ...value,
              },
            },
            scope: action.newPageId,
          } as EventPayload),
      );

      const statesArrayReferencesCreation = eventPayload.data.node.states.map(
        (state: NodeStateConnectionDSL, index: number) => ({
          operation: 'arrayInsert',
          data: {
            key: `appDSL.nodes.${eventPayload.data.node.id}.states`,
            index,
            item: state,
          },
          scope: action.newPageId,
        }),
      );

      newEventsToPush = [...contextStatesCreation, ...statesArrayReferencesCreation];

      return {
        eventPayload: {
          ...eventPayload,
          data: {
            ...eventPayload.data,
            node: {
              ...eventPayload.data.node,
              context: {},
              states: [],
            },
          },
          scope: SCOPE_GLOBAL,
        },
        newEventsToPush,
      };
    }

    if (
      eventPayload.operation === 'create' &&
      eventPayload.data.node.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout
    ) {
      const { children } = eventPayload.data.node.props;
      newEventsToPush = children.nodes.map((childId: string, index: number) => ({
        operation: 'arrayInsert',
        scope: action.newPageId,
        data: {
          key: `appDSL.nodes.${eventPayload.data.node.id}.props.children.nodes`,
          index,
          item: childId,
        },
      }));

      return {
        eventPayload: {
          ...eventPayload,
          data: {
            ...eventPayload.data,
            node: {
              ...eventPayload.data.node,
              props: {
                ...eventPayload.data.node.props,
                children: { nodes: [] },
              },
            },
          },
          scope: SCOPE_GLOBAL,
        },
        newEventsToPush,
      };
    }

    return { eventPayload: { ...eventPayload, scope: SCOPE_GLOBAL }, newEventsToPush };
  }
}
