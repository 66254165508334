import { parse as flatParse, stringify as flatString } from 'flatted';
import { mapObjIndexed } from 'ramda';
import { log } from '../dev';
import { isFunction, isObject } from '../type-checkers';
const removeAllComplexObjects = (obj) => {
    if (!isObject(obj)) {
        return obj;
    }
    return mapObjIndexed(value => {
        if (!isObject(value)) {
            return value;
        }
        if (isObject(value) && Object.getPrototypeOf(value) === Object.prototype) {
            return removeAllComplexObjects(value);
        }
        return null;
    }, obj);
};
const stringify = (value, options = {}) => {
    let stringifyValue = '';
    const valueToStringify = options.removeComplexObjects ? removeAllComplexObjects(value) : value;
    try {
        stringifyValue = options.pretty
            ? JSON.stringify(valueToStringify, null, 2)
            : JSON.stringify(valueToStringify);
    }
    catch (err) {
        log.warn(err);
    }
    return stringifyValue;
};
const parse = (value, onError = log.warn, onSuccess) => {
    try {
        const result = JSON.parse(value);
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result);
        return result;
    }
    catch (err) {
        onError(err);
        return undefined;
    }
};
const flatten = (value) => {
    try {
        return flatParse(flatString(value, (_, fieldValue) => {
            if (isFunction(fieldValue)) {
                return 'fn';
            }
            return fieldValue;
        }));
    }
    catch (_a) {
        return { error: 'Object is to big to properly serialize!' };
    }
};
export const serialize = {
    stringify,
    parse,
    flatParse,
    flatString,
    flatten,
};
