/* eslint-disable @typescript-eslint/no-explicit-any */

import { proxy } from 'comlink';
import debounce from 'lodash.debounce';

import { APP_RENDER_ERRORS, DASHBOARD_EVENTS, UI_BUILDER_EVENTS } from '../common';

const observedEvents = [
  DASHBOARD_EVENTS.appConfigurationInit,
  UI_BUILDER_EVENTS.saveProcessStarts,
  DASHBOARD_EVENTS.routeDelete,
  DASHBOARD_EVENTS.appConfigurationUpdateDslNodes,
];
let timeoutId: NodeJS.Timeout;

// eslint-disable-next-line no-shadow
const asyncAction = debounce(async (dispatch: any, state: any, getAsyncReducer: any) => {
  function callback(value: any) {
    window.requestAnimationFrame(() => {
      dispatch({
        type: APP_RENDER_ERRORS.pushError,
        payload: value,
      });
    });

    if (value.length && value.length > 0) {
      value.forEach((error: any) => {
        if (error.showToastNotification) {
          dispatch({
            type: UI_BUILDER_EVENTS.notificationSend,
            notification: {
              message: error.context.message,
              options: { variant: error.type === '@builder/warn' ? 'warning' : 'error' },
            },
          });
        }
      });
    }
  }

  const asyncReducer = await getAsyncReducer();
  await asyncReducer(proxy(callback), {
    type: 'DSL_LINT_CODE',
    payload: state,
  });
}, 300);

// eslint-disable-next-line no-shadow
const asyncActionReportNotify = debounce(
  async (dispatch: any, state: any, getAsyncReducer: any) => {
    function callback(value: any) {
      window.requestAnimationFrame(() => {
        dispatch({
          type: APP_RENDER_ERRORS.pushNotifications,
          payload: value,
        });
      });
    }

    const asyncReducer = await getAsyncReducer();
    await asyncReducer(proxy(callback), {
      type: 'REPORT_NOTIFICATIONS',
      payload: state,
    });
  },
  300,
);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function createWorkerMiddleware(getAsyncReducer: any) {
  return ({ dispatch, getState }: any) => (next: any) => (action: any) => {
    if (
      localStorage.getItem('disableErrorManagement') !== 'true' &&
      typeof action !== 'function' &&
      UI_BUILDER_EVENTS.appEngineAuditNotify === action.type
    ) {
      const newState = next(action);

      if (!action?.notifications || !action.notifications.length) {
        window.requestAnimationFrame(() => {
          dispatch({
            type: '@builder/error/notifications',
            payload: [],
          });
        });
        return newState;
      }

      asyncActionReportNotify(
        dispatch,
        {
          appConfiguration: { ...getState().dashboard.appConfiguration },
          components: { ...getState().dashboard.components },
          notifications: action.notifications,
        },
        getAsyncReducer,
      );

      return newState;
    }

    if (
      localStorage.getItem('disableErrorManagement') !== 'true' &&
      typeof action !== 'function' &&
      observedEvents.includes(action.type)
    ) {
      const newState = next(action);
      const nextStateResult = {
        appConfiguration: {
          ...getState().dashboard.appConfiguration,
          currentRoute: getState().dashboard?.router?.currentRoute,
        },
        components: { ...getState().dashboard.components },
      };

      asyncAction(dispatch, nextStateResult, getAsyncReducer);
      const getStateResult = () => ({
        appConfiguration: {
          ...getState().dashboard.appConfiguration,
          currentRoute: getState().dashboard?.router?.currentRoute,
        },
        components: { ...getState().dashboard.components },
      });

      if (!timeoutId) {
        timeoutId = setInterval(() => {
          asyncAction(dispatch, getStateResult(), getAsyncReducer);
        }, 60000);
      }

      return newState;
    }

    return next(action);
  };
}

const workerMiddleware: ({
  dispatch,
  getState,
}: {
  dispatch: any;
  getState: any;
}) => (next: any) => (action: any) => any = createWorkerMiddleware(() => undefined);

export { workerMiddleware as default, createWorkerMiddleware as withAsyncReducer };
