export const EMITTER_EVENTS = {
    refetchQuery: 'refetch-query',
    changeRoute: 'change-route',
};
export class EventEmitter {
    constructor() {
        this.events = {
            'refetch-query': [],
            'change-route': [],
        };
    }
    emit(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            event.forEach(fn => {
                fn.call(null, data);
            });
        }
    }
    subscribe(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.events[eventName].push(fn);
        return () => {
            this.events[eventName] = this.events[eventName].filter(eventFn => fn !== eventFn);
        };
    }
}
