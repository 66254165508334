var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge as MuiBadge, Box as MuiBox } from '@mui/material';
const StyleBox = styled(MuiBox)(() => ({
    width: '38px!important',
    height: '28px',
}));
export const Badge = forwardRef((_a, ref) => {
    var { loading, children, badgeContent, color, onClick } = _a, rest = __rest(_a, ["loading", "children", "badgeContent", "color", "onClick"]);
    const onClickCallback = useCallback((event) => {
        if (!loading) {
            onClick && onClick(event);
        }
    }, [loading, onClick]);
    return (_jsx(StyleBox, Object.assign({}, rest, { ref: ref, onClick: () => onClickCallback }, { children: _jsx(MuiBadge, Object.assign({ badgeContent: badgeContent, color: color, css: loading &&
                css `
              color: transparent;
            ` }, { children: children })) })));
});
