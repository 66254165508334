import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { omitPropsSettings } from '../../../utils/prop-transformers/omitPropsSettings';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, NS_ID, getBasicPropsSettings, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
const getBuilderComponentsFileUploadButtonBaseSettings = (prefixPath = []) => {
    var _a;
    return ({
        properties: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        name: 'basic',
                        titleDescription: BASIC_PROPS_TOOLTIP,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['textButton'],
                                label: 'Text',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.selectEightBaseAddedWorkspaces,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['workspaceId'],
                                label: '8Base Backend Workspace',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['apiToken'],
                                label: 'Authorization header',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['accept'],
                                label: 'Accepted Files',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.number,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['maxFileSize'],
                                label: 'Max File Size (MB)',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['uploadOnChange'],
                                label: 'Upload on Change',
                            },
                        ],
                    },
                    getDivider(),
                    ...getBasicPropsSettings(),
                    getDivider(),
                    ...getPropertySections(),
                ],
            },
        ],
        style: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        name: 'basic',
                        titleDescription: BASIC_STYLE_TOOLTIP,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['variant'],
                                label: 'Variant',
                                options: ['contained', 'outlined', 'text'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['color'],
                                label: 'Font Color',
                                options: ['inherit', 'primary', 'secondary'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['size'],
                                label: 'Size',
                                options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                hasFxButton: true,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['fullWidth'],
                                label: 'Full Width',
                            },
                        ],
                    },
                    getDivider(),
                    ...((_a = omitPropsSettings(getStyleSections(), NS_ID.style.font.typography, NS_ID.style.font.direction)) !== null && _a !== void 0 ? _a : []),
                ],
            },
        ],
        events: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.events.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.eventsSection,
                        name: NS_ID.events.events,
                        title: 'Events',
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onChangeAction',
                                label: 'On Change',
                                keyPath: [...prefixPath, 'onChange'],
                                children: getCommonActionSettings([...prefixPath, 'onChange']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onSuccessAction',
                                label: 'On Success',
                                keyPath: [...prefixPath, 'onSuccess'],
                                children: getCommonActionSettings([...prefixPath, 'onSuccess']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onErrorAction',
                                label: 'On Error',
                                keyPath: [...prefixPath, 'onError'],
                                children: getCommonActionSettings([...prefixPath, 'onError']),
                            },
                            ...getCommonActionListSettings(prefixPath),
                        ],
                    },
                ],
            },
        ],
    });
};
export const BuilderComponentsFileUploadButtonBaseSettings = getBuilderComponentsFileUploadButtonBaseSettings();
