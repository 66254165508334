import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialDividerSettings } from './MaterialDivider.settings';
export const MaterialDivider = {
    name: COMPONENT_DSL_NAMES.MaterialDivider,
    displayName: 'Divider',
    icon: 'divider',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@mui/material',
        componentName: 'Divider',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                variant: 'fullWidth',
                orientation: 'horizontal',
                absolute: false,
                flexItem: false,
                light: false,
                textAlign: 'center',
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, absolute: { type: COMPONENT_DSL_PROP_TYPES.boolean }, component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, flexItem: { type: COMPONENT_DSL_PROP_TYPES.boolean }, light: { type: COMPONENT_DSL_PROP_TYPES.boolean }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, textAlign: { type: COMPONENT_DSL_PROP_TYPES.enum }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: MaterialDividerSettings,
};
