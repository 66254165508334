var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { equals, omit } from 'ramda';
import { QUERY_ARGS_DSL, RESOURCE_DSL, } from '@builder/schemas';
import { isUndefined } from '@builder/utils';
import { transformRequestStateProps } from '../../app-nodes';
import { useRefetchQuerySubscribe } from '../../event-subscriptions';
import { useRuntimeErrorCallbacks } from '../../providers';
import { UI_BUILDER_MODES, useDashboardHook } from '../../providers/DashboardProvider';
import { createAndRunFunction, useTransformData } from '../../utils';
import { useUnsubscribePolling } from '../../utils/useUnsubscribePolling';
import { useGetApolloClientByAppState } from './useGetApolloClientByAppState';
const GLOBAL_QUERIES_CACHE = {};
const ERROR_DETECTED = true;
let isRun = false;
let callBacksAlreadyExecuted = false;
// In this file, there are flags and validations in place to achieve the desired behavior of lazyQueries in various scenarios, which include:
// 1. Retrieve the correct information from the Requests editor upon pressing Run.
// 2. Retrieve the correct information from the Requests editor upon pressing SaveAndRun.
// 3. Modify the query and/or variables and fetch updated information from the Requests editor upon pressing Run.
// 4. Modify the query and/or variables and fetch updated information from the Requests editor upon pressing SaveAndRun.
// 5. Obtain the correct response from the app-engine when executing the request using custom code in the following ways:
/**
 *
 * @example
 * const func = async () => {
 *  const res = await Request.run();
 *  alert(JSON.stringify(res));
 * }
 * func();
 *
 * */
// 6. Obtain the correct response when executing the request using the Run Request option.
// 7. Execute the request from local navigation events.
// 8. Obtain the correct response both in the initial execution of the request and in subsequent ones.
export const useAppEngineQueryState = ({ appStateDSL, resourceListDSL, componentListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    var _a, _b, _c, _d;
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const initialArgs = transformRequestStateProps({
        requestStateDSL: appStateDSL,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        propListDSL: QUERY_ARGS_DSL,
    }, appRuntimeState, { onAppAuditNotifications }, libraries);
    const cacheKey = JSON.stringify({ appStateDSL, initialArgs });
    const memoizedResponse = GLOBAL_QUERIES_CACHE[cacheKey];
    const transformCommonOptions = useMemo(() => ({
        appStateDSL,
        appRuntimeState,
    }), [appRuntimeState, appStateDSL]);
    let query = gql(appStateDSL.args.body);
    const [refetchData, setRefetchData] = useState(undefined);
    const transformData = useTransformData({ libraries });
    const appropriateApolloClient = useGetApolloClientByAppState({
        appStateDSL,
        resourceListDSL,
        appRuntimeState,
        componentListDSL,
        assetListDSL,
        propListDSL: RESOURCE_DSL,
    });
    const executeCallBacks = (updatedAppStateDSL, response, onError) => {
        var _a, _b, _c, _d, _e;
        const appRuntimeStateList = Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { data: !onError && response, error: onError && response }) });
        if (updatedAppStateDSL.args.typeOnCompleted === 'code' ||
            !updatedAppStateDSL.args.typeOnCompleted) {
            !onError &&
                createAndRunFunction((_b = (_a = updatedAppStateDSL === null || updatedAppStateDSL === void 0 ? void 0 : updatedAppStateDSL.args) === null || _a === void 0 ? void 0 : _a.onCompleted) !== null && _b !== void 0 ? _b : '', appRuntimeStateList, {
                    libraries,
                });
        }
        if (updatedAppStateDSL.args.typeOnCompleted === 'function') {
            !onError &&
                createAndRunFunction((_c = updatedAppStateDSL.args.onCompletedFunction) !== null && _c !== void 0 ? _c : '', appRuntimeStateList, {
                    libraries,
                });
        }
        if (onError && updatedAppStateDSL.args.typeOnError === 'code') {
            createAndRunFunction((_d = updatedAppStateDSL.args.onError) !== null && _d !== void 0 ? _d : '', appRuntimeStateList, {
                libraries,
            });
        }
        if (onError && updatedAppStateDSL.args.typeOnError === 'function') {
            createAndRunFunction((_e = updatedAppStateDSL.args.onErrorFunction) !== null && _e !== void 0 ? _e : '', appRuntimeStateList, {
                libraries,
            });
        }
    };
    const deleteFromWindow = (itemsToDelete) => {
        itemsToDelete.forEach(item => {
            if (typeof window.top === 'object' && window.top !== null) {
                delete window.top[item];
            }
        });
    };
    const { mode } = useDashboardHook();
    const isPreviewMode = mode === UI_BUILDER_MODES.preview;
    const queryResult = useQuery(query, Object.assign(Object.assign({}, omit(['body', 'transformer', 'pollInterval'], initialArgs)), { client: appropriateApolloClient, skip: !!memoizedResponse ||
            (!isPreviewMode && (!((_a = window.top) === null || _a === void 0 ? void 0 : _a.requestRun) || !((_b = window.top) === null || _b === void 0 ? void 0 : _b.requestSavedAndRun))), onCompleted: response => {
            callBacksAlreadyExecuted = true;
            const transformedData = transformData(transformCommonOptions, response);
            if (!equals(refetchData, transformedData)) {
                setRefetchData(transformedData);
                isRun = false;
            }
            executeCallBacks(appStateDSL, transformedData);
        }, onError: error => {
            callBacksAlreadyExecuted = true;
            setRefetchData(error);
            executeCallBacks(appStateDSL, error, ERROR_DETECTED);
        }, nextFetchPolicy: 'cache-first', fetchPolicy: 'cache-first' }));
    const updateDataForRefetch = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedAppStateDSL = Object.assign(Object.assign({}, appStateDSL), { args: Object.assign(Object.assign({}, appStateDSL.args), options) });
        query = (options === null || options === void 0 ? void 0 : options.body) ? gql(options.body) : query;
        try {
            isRun = true;
            const response = yield queryResult.refetch().then(res => {
                return transformData({
                    appStateDSL: updatedAppStateDSL,
                    appRuntimeState,
                }, res.data);
            });
            if (queryResult.error)
                return executeCallBacks(appStateDSL, queryResult.error, ERROR_DETECTED);
            if (!equals(refetchData, response)) {
                setRefetchData(response);
                isRun = false;
            }
            if (!callBacksAlreadyExecuted) {
                executeCallBacks(updatedAppStateDSL, response);
            }
            callBacksAlreadyExecuted = false;
            return response;
        }
        catch (error) {
            setRefetchData(error);
            if (!callBacksAlreadyExecuted) {
                executeCallBacks(appStateDSL, error, ERROR_DETECTED);
            }
            callBacksAlreadyExecuted = false;
            return error;
        }
        finally {
            deleteFromWindow(['requestRun', 'requestSavedAndRun', 'requestSaved']);
        }
    });
    useRefetchQuerySubscribe({
        stateID: appStateDSL.id,
        refetch: updateDataForRefetch,
    });
    useUnsubscribePolling({
        currentArgs: initialArgs,
        queryResult,
    });
    if (memoizedResponse) {
        return Object.assign(Object.assign({}, memoizedResponse), { data: refetchData });
    }
    if (appStateDSL.scope === 'global' &&
        !isUndefined(queryResult.data) &&
        !isUndefined(refetchData) &&
        queryResult.called &&
        !queryResult.loading &&
        !GLOBAL_QUERIES_CACHE[cacheKey] &&
        !isRun) {
        GLOBAL_QUERIES_CACHE[cacheKey] = Object.assign(Object.assign({}, omit(['client', 'observable'], queryResult)), { run: updateDataForRefetch, data: refetchData, name: appStateDSL.name, type: appStateDSL.type, query: appStateDSL.args.body, variables: initialArgs.variables, fetchPolicy: initialArgs.fetchPolicy, errorPolicy: initialArgs.errorPolicy, skip: initialArgs.skip, pollInterval: parseInt(initialArgs === null || initialArgs === void 0 ? void 0 : initialArgs.pollInterval, 10) || undefined, headers: ((_c = initialArgs.context) === null || _c === void 0 ? void 0 : _c.headers) || {}, notifyOn: initialArgs.notifyOnNetworkStatusChange });
    }
    return Object.assign(Object.assign({}, omit(['client', 'observable'], queryResult)), { run: updateDataForRefetch, data: refetchData, name: appStateDSL.name, type: appStateDSL.type, query: appStateDSL.args.body, variables: initialArgs.variables, fetchPolicy: initialArgs.fetchPolicy, errorPolicy: initialArgs.errorPolicy, skip: initialArgs.skip, pollInterval: parseInt(initialArgs === null || initialArgs === void 0 ? void 0 : initialArgs.pollInterval, 10) || undefined, headers: ((_d = initialArgs.context) === null || _d === void 0 ? void 0 : _d.headers) || {}, notifyOn: initialArgs.notifyOnNetworkStatusChange });
};
