import { ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST, USE_ERROR_BOUNDARY_CONST, USE_ERROR_EVENT_CONST, } from './constants';
export class ErrorBoundaryProviderGenerator {
    generateErrorBoundaryProvider() {
        return `
    import React, { createContext, useContext, useEffect, useState } from 'react';

    export type BoundaryContext = Record<string, any>;
    const ErrorBoundaryContext = createContext({} as BoundaryContext);
    
    export const ${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST}: React.FC = props => {
    
      return (
        <ErrorBoundaryContext.Provider value={props.error}>
          {props.children}
        </ErrorBoundaryContext.Provider>
      );
    };
    
    export const ${USE_ERROR_BOUNDARY_CONST} = (): BoundaryContext => {
      return useContext(ErrorBoundaryContext);
    };

    export const ${USE_ERROR_EVENT_CONST} = () => {
      const [error, setError] = useState({});
    
      useEffect(() => {
        const errorHandler = e => {
          setError({ message: e.message, name: e.error, stack: e.filename });
          return true;
        };
    
        window.addEventListener('error', errorHandler);
        return () => window.removeEventListener('error', errorHandler);
      }, []);
    
      return error;
    };
    `;
    }
}
