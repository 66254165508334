import React, { useMemo } from 'react';

import { ExtendSliderProps } from '../../types';
import { SliderWithTitleWrapper } from '../common';
import { Slider } from 'src/shared/components/Slider';

const HUE_DEGREE_STEP = 45;
const COLORS_COUNT = 360 / HUE_DEGREE_STEP;

export const HueSlider: React.FC<ExtendSliderProps> = React.memo(({ title, value, onChange }) => {
  // an array of hues from 0 to 360 degrees is formed with a ball of 45 degrees
  const colors = useMemo(
    () => Array.from({ length: COLORS_COUNT }, (_, i) => `hsl(${i * HUE_DEGREE_STEP}, 100%, 50%)`),
    [],
  );
  const pointerColor = useMemo(() => `hsl(${value}, 100%, 50%)`, [value]);

  return (
    <SliderWithTitleWrapper title={title}>
      <Slider
        valueRange={{
          min: 0,
          max: 360,
        }}
        value={value}
        height="8px"
        width="100%"
        colors={colors}
        borderRadius="10px"
        pointerColor={pointerColor}
        onChange={onChange}
      />
    </SliderWithTitleWrapper>
  );
});
