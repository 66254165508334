import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { pickSettings, NS_ID, getBasicPropsSettings, BASIC_PROPS_TOOLTIP } from '../../common';
import { getDivider, getEventsSection, getStyleSections } from '../../common/settings';
export const IteratorSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.iterator,
                            dataLabel: 'Loop Data',
                            itemNameLabel: 'Item Name',
                            looperLimitInDevMode: undefined,
                            name: 'loop',
                            nodePath: ['children', 0],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
export const getCommonIteratorSettings = () => pipe(pickSettings([[NS_ID.properties.root, NS_ID.properties.basic, 'loop']], {
    withStructure: false,
}))(values(IteratorSettings).flat());
