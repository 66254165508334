import { wrap } from 'comlink';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WorkerReducer from '../runtime-tracking/runtimeTrackingReducer.worker';

let singletonWorker: unknown = null;
let singletonBlocker = false;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function getAsyncReducer() {
  if (!singletonWorker && !singletonBlocker) {
    singletonBlocker = true;
    singletonWorker = wrap(WorkerReducer());
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(singletonWorker);
    }, 0);
  });
}

export default getAsyncReducer;
