var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEmpty, mapObjIndexed, pathOr } from 'ramda';
import { COMPONENT_DSL_PROP_TYPES, PropAssert, COMPONENT_DSL_CALLBACK_TYPES, hasPropJsCode, transformPropWithJsCode, COMPONENT_SETTING_ACTION_TYPE, componentListSelectors, PropChecker, PREDEFINED_ACTIONS, ACTIONS_ARGS_SCHEMA, assetListSelectors, stateListSelectors, STATE_TYPES, STATE_ACTIONS, STATE_REQUEST_VARIANTS, STATE_REQUEST_CALLBACKS, } from '@builder/schemas';
import { serialize, ERROR_SCOPES, isArray, isBoolean, isNumber, isObject, isString, isUndefined, log, SchemaValidationError, } from '@builder/utils';
import { OUTPUT_PROP_MODES } from '../../../constants';
import { transformArgsToString, transformStringSymbols } from '../../../utils';
import { REGULAR_EXPRESSION, TEXT_PROPERTIES, TRANSFORMATION_MAP_SYMBOLS } from './constants';
import { CSSPropTransformer } from './prop-type-transformers';
class PropTransformer {
    constructor(options) {
        this.generateNode = options.generateNode;
        this.cssPropTransformer = new CSSPropTransformer();
    }
    /**
     * Transforms render prop value to appropriate text to place it in jsx
     *
     * ```tsx
     * // input example:
     * { nodes: ['some-button-node-id']}
     * // output example:
     * {<Button>someText</Button>}
     * ```
     */
    transformReactNodeProp({ propValue, propName }, outputMode = OUTPUT_PROP_MODES.jsx) {
        var _a, _b;
        PropAssert.Value.assertIsReactNodeProp(propValue, propName);
        if (!((_a = propValue === null || propValue === void 0 ? void 0 : propValue.nodes) === null || _a === void 0 ? void 0 : _a.length)) {
            return null;
        }
        const isChildrenProp = propName === 'children';
        const transformedProp = `${propValue === null || propValue === void 0 ? void 0 : propValue.nodes.map(child => {
            var _a;
            return (_a = this.generateNode) === null || _a === void 0 ? void 0 : _a.call(this, child, {
                outputMode: isChildrenProp ? outputMode : OUTPUT_PROP_MODES.js,
            });
        }).join('')}`;
        if (isChildrenProp) {
            return transformedProp;
        }
        const reactNodeTransformedValue = ((_b = propValue === null || propValue === void 0 ? void 0 : propValue.nodes) === null || _b === void 0 ? void 0 : _b.length) > 1
            ? `<Fragment>${transformedProp}</Fragment>`
            : `${transformedProp}`;
        return outputMode === OUTPUT_PROP_MODES.jsx
            ? `{ ${reactNodeTransformedValue} }`
            : reactNodeTransformedValue;
    }
    /**
     * Transforms string value to appropriate text to place it in jsx
     */
    transformStringProp({ propValue, propName }, outputMode = OUTPUT_PROP_MODES.jsx) {
        PropAssert.Value.assertIsStringProp(propValue, propName);
        const trimmedPropValue = propValue.replace(/^\s+|\s+$/g, '');
        if (outputMode === OUTPUT_PROP_MODES.jsx) {
            if (propName === TEXT_PROPERTIES.children) {
                return `${transformStringSymbols(trimmedPropValue, TRANSFORMATION_MAP_SYMBOLS)
                    .replace(/\n/g, '')
                    .replace(/\n/g, '')}`;
            }
            return `"${trimmedPropValue.replace(/\n/g, '').replace(/\n/g, '')}"`;
        }
        if (outputMode === OUTPUT_PROP_MODES.js) {
            if (propName === REGULAR_EXPRESSION) {
                return `'${propValue.replaceAll('\\', '\\\\')}'`;
            }
            if (propName === TEXT_PROPERTIES.label) {
                const escapedStr = trimmedPropValue.replace(/'/g, "\\'");
                return `"${escapedStr.replace(/\n/g, '').replace(/\n/g, '')}"`;
            }
            return `'${trimmedPropValue.replace(/\n/g, '').replace(/\n/g, '')}'`;
        }
        return trimmedPropValue;
    }
    transformCallbackComponentProp({ propValue, propName, propDSL, entityName }, outputMode = OUTPUT_PROP_MODES.jsx) {
        PropAssert.Value.assertIsCallbackComponentProp(propValue, propName);
        PropAssert.Schema.assertIsCallbackComponentProp(propDSL, propName);
        let transformedProp = `${propValue === null || propValue === void 0 ? void 0 : propValue.nodes.map(child => { var _a; return (_a = this.generateNode) === null || _a === void 0 ? void 0 : _a.call(this, child, { outputMode }); }).join('')}`;
        if (propDSL.pathToUniqueKey.includes('row')) {
            transformedProp = `{${transformedProp}}`;
        }
        const untransformedProp = !/[{}]/.test(transformedProp);
        const argsString = transformArgsToString(propDSL.args);
        const customArgsString = argsString === 'formProps' ? `{errors, touched}` : argsString;
        const functionString = untransformedProp
            ? `(${customArgsString}) => ( <Fragment>{ ${transformedProp} }</Fragment> )`
            : `(${customArgsString}) => ( <Fragment>${transformedProp}</Fragment> )`;
        return outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${functionString} }` : functionString;
    }
    transformCallbackCodeProp({ propValue, propName, propDSL }, outputMode = OUTPUT_PROP_MODES.jsx) {
        PropAssert.Value.assertIsCallbackCodeProp(propValue, propName);
        PropAssert.Schema.assertIsCallbackCodeProp(propDSL, propName);
        const argsString = transformArgsToString(propDSL.args);
        const functionString = `(${argsString}) => {
      ${propValue}
    }`;
        const asyncFunctionString = propDSL.async || /await(\s|\()/gm.test(functionString)
            ? `async ${functionString}`
            : functionString;
        return outputMode === OUTPUT_PROP_MODES.jsx
            ? `{ ${asyncFunctionString} }`
            : asyncFunctionString;
    }
    transformActionProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, assetListDSL, stateListDSL, }) {
        var _a, _b, _c;
        const { propValue, propName, propDSL, entityName, propPath } = propAttributes;
        PropAssert.Value.assertIsActionProp(propValue, propName, { allowJSInjection: true });
        PropAssert.Schema.assertIsActionProp(propDSL, propName);
        const argsString = transformArgsToString(propDSL.args);
        if (!propValue.actionType) {
            return '';
        }
        const getResultByOutputMode = (code) => outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${code} }` : code;
        switch (propValue.actionType) {
            case COMPONENT_SETTING_ACTION_TYPE.customCode: {
                const transformedArgs = transformProps({
                    propData: {
                        props: (_a = propValue.args) !== null && _a !== void 0 ? _a : {},
                        propListDSL: ACTIONS_ARGS_SCHEMA[propValue.actionType],
                        entityName,
                        propPath,
                    },
                    outputMode: OUTPUT_PROP_MODES.raw,
                    assetListDSL,
                    stateListDSL,
                });
                const functionString = `(${argsString}) => { 
          ${transformedArgs.code} 
        }`;
                const asyncFunctionString = propDSL.async || /await(\s|\()/gm.test(functionString)
                    ? `async ${functionString}`
                    : functionString;
                return getResultByOutputMode(asyncFunctionString);
            }
            case COMPONENT_SETTING_ACTION_TYPE.runRequest: {
                const requestID = (_b = propValue.args) === null || _b === void 0 ? void 0 : _b.requestID;
                if (!stateListDSL || !requestID) {
                    // handle case with empty request to avoid `onClick={}`
                    return getResultByOutputMode('() => {}');
                }
                const request = stateListSelectors.getStateDSL(stateListDSL, { id: requestID });
                const requestName = request.name;
                const functionString = `() => { ${requestName}?.run?.() }`;
                const asyncFunctionString = propDSL.async || /await(\s|\()/gm.test(functionString)
                    ? `async ${functionString}`
                    : functionString;
                return getResultByOutputMode(asyncFunctionString);
            }
            case COMPONENT_SETTING_ACTION_TYPE.updateState: {
                const stateArgs = propValue.args.stateArgs;
                const { stateName, stateType, stateValue, stateAction, stateKey } = stateArgs;
                const transformPropToValue = (newState) => {
                    let parsedState;
                    try {
                        parsedState = newState ? JSON.parse(newState) : newState;
                    }
                    catch (e) {
                        parsedState = newState;
                    }
                    if (isString(parsedState))
                        return JSON.stringify(newState);
                    return newState;
                };
                const transformedStateValue = stateValue && hasPropJsCode(stateValue)
                    ? transformPropWithJsCode(stateValue)
                    : transformPropToValue(stateValue);
                const transformedStateKey = stateKey && hasPropJsCode(stateKey)
                    ? transformPropWithJsCode(stateKey)
                    : transformPropToValue(stateKey);
                if (stateName && stateAction) {
                    switch (stateType) {
                        case STATE_TYPES.string:
                            if (transformedStateValue) {
                                return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateValue})}`);
                            }
                            break;
                        case STATE_TYPES.number:
                            if (transformedStateValue) {
                                return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateValue})}`);
                            }
                            break;
                        case STATE_TYPES.boolean:
                            if (transformedStateValue && stateAction === STATE_ACTIONS.setValue) {
                                return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateValue})}`);
                            }
                            return getResultByOutputMode(`() => {${stateName}.${stateAction}()}`);
                        case STATE_TYPES.array:
                            if (transformedStateValue && stateAction === STATE_ACTIONS.setValue) {
                                return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateValue})}`);
                            }
                            return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateValue})}`);
                        case STATE_TYPES.object:
                            if (transformedStateValue && transformedStateKey) {
                                return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateKey},${transformedStateValue})}`);
                            }
                            return getResultByOutputMode(`() => {${stateName}.${stateAction}(${transformedStateValue})}`);
                    }
                }
                return getResultByOutputMode('() => {}');
            }
            case COMPONENT_SETTING_ACTION_TYPE.functionCode: {
                const functionProps = serialize.parse(String(propValue.args.function) || '');
                const codes = Object.values(functionProps.parameters).map((parameter) => {
                    const prop = { code: parameter, propValue };
                    const transformedArgs = transformProps({
                        propData: {
                            props: prop,
                            propListDSL: ACTIONS_ARGS_SCHEMA.customCode,
                            entityName,
                            propPath,
                        },
                        outputMode: OUTPUT_PROP_MODES.raw,
                        assetListDSL,
                        stateListDSL,
                    });
                    return transformedArgs.code;
                });
                const functionString = `(${argsString}) => {
          ${functionProps.nameFunction}(${codes.join(', ')}) }`;
                const asyncFunctionString = propDSL.async || /await(\s|\()/gm.test(functionString)
                    ? `async ${functionString}`
                    : functionString;
                return getResultByOutputMode(asyncFunctionString);
            }
            default: {
                const transformedArgs = transformProps({
                    propData: {
                        props: (_c = propValue.args) !== null && _c !== void 0 ? _c : {},
                        propListDSL: ACTIONS_ARGS_SCHEMA[propValue.actionType],
                        entityName,
                        propPath,
                    },
                    outputMode: OUTPUT_PROP_MODES.js,
                    assetListDSL,
                    stateListDSL,
                });
                const transformedParams = Object.keys(transformedArgs).reduce((accum, paramName) => {
                    return `${accum}const ${paramName} = ${transformedArgs[paramName]};\n`;
                }, '');
                const functionString = `(${argsString}) => {
          ${transformedParams}
          ${PREDEFINED_ACTIONS[propValue.actionType]};
        }`;
                return getResultByOutputMode(functionString);
            }
        }
    }
    /**
     * Transforms number value to appropriate text to place it in jsx
     */
    transformNumberProp({ propValue, propName }, outputMode = OUTPUT_PROP_MODES.jsx) {
        PropAssert.Value.assertIsNumberProp(propValue, propName);
        return outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${propValue} }` : propValue.toString();
    }
    /**
     * Transforms boolean value to appropriate text to place it in jsx
     */
    transformBooleanProp({ propValue }, outputMode = OUTPUT_PROP_MODES.jsx) {
        PropAssert.Value.assertIsBooleanProp(propValue);
        const transformedValue = hasPropJsCode(propValue)
            ? transformPropWithJsCode(propValue)
            : propValue;
        return outputMode === OUTPUT_PROP_MODES.jsx
            ? `{ ${transformedValue} }`
            : transformedValue.toString();
    }
    transformAssetProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, assetListDSL, }) {
        var _a;
        const { propValue, propName } = propAttributes;
        const hasJsCode = hasPropJsCode(propValue);
        const transformedValue = hasJsCode
            ? transformPropWithJsCode((_a = propValue) !== null && _a !== void 0 ? _a : '')
            : propValue;
        if (!hasJsCode && isString(transformedValue)) {
            return this.transformStringProp(propAttributes, outputMode);
        }
        if (PropChecker.Value.isAssetProp(propValue)) {
            try {
                const assetDSL = assetListSelectors.getAssetDSL(assetListDSL, {
                    assetID: propValue.assetID,
                });
                const assetStateValue = `assets.${assetDSL.name}.src`;
                if (outputMode === OUTPUT_PROP_MODES.raw && assetDSL.type === 'image') {
                    return assetDSL.src;
                }
                return outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${assetStateValue} }` : assetStateValue;
            }
            catch (e) {
                log.error(e);
                return '';
            }
        }
        PropAssert.Value.assertIsStringProp(transformedValue, propName);
        return outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${transformedValue} }` : transformedValue;
    }
    generatePropsSchema(propValue) {
        if (isString(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.string };
        if (isNumber(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.number };
        if (isBoolean(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.boolean };
        if (isArray(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.array, item: { type: COMPONENT_DSL_PROP_TYPES.any } };
        if (isObject(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.object };
        return { type: COMPONENT_DSL_PROP_TYPES.notSupported };
    }
    /**
     * Transforms object value to appropriate text to place it in jsx
     */
    transformObjectProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, assetListDSL, stateListDSL, }) {
        const { propValue, propDSL, propName, entityName, propPath } = propAttributes;
        if (!propValue) {
            return null;
        }
        if (isEmpty(propValue) && !PropChecker.Schema.isRequired(propDSL)) {
            return null;
        }
        PropAssert.Value.assertIsObjectProp(propValue, propName);
        PropAssert.Schema.assertIsObjectProp(propDSL, propName);
        const objectPropDSL = propDSL.props
            ? propDSL.props
            : mapObjIndexed(this.generatePropsSchema, propValue);
        const propsTransformationMap = this.createPropsTransformationMap({
            propData: {
                props: propValue,
                propListDSL: objectPropDSL,
                entityName,
                propPath: [...propPath, propName],
            },
            outputMode: OUTPUT_PROP_MODES.js,
            assetListDSL,
            stateListDSL,
        });
        const transformedValue = Object.keys(propsTransformationMap)
            .filter(keyName => !isUndefined(propsTransformationMap[keyName]))
            .map(keyName => {
            if (PropChecker.Schema.isReactNodeProp(objectPropDSL[keyName]) &&
                pathOr([], [keyName, 'nodes'], propValue).length > 1) {
                return `'${keyName}': <Fragment>${propsTransformationMap[keyName]}</Fragment>`;
            }
            return `'${keyName}': ${propsTransformationMap[keyName]}`;
        })
            .join(',');
        return outputMode === OUTPUT_PROP_MODES.jsx
            ? `{{ ${transformedValue} }}`
            : `{ ${transformedValue} }`;
    }
    transformArrayProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, assetListDSL, stateListDSL, }) {
        const { propValue, propDSL, propName, propListValues, propListDSL, entityName, propPath, } = propAttributes;
        PropAssert.Value.assertIsArrayProp(propValue, propName);
        PropAssert.Schema.assertIsArrayProp(propDSL, propName);
        const transformedValue = propValue
            .map(itemPropValue => {
            const itemPropDSL = propDSL.item;
            return this.transformProp({
                propAttributes: {
                    entityName,
                    propPath: [...propPath, propName],
                    propValue: itemPropValue,
                    propDSL: itemPropDSL,
                    propName,
                    propListValues,
                    propListDSL,
                },
                outputMode: OUTPUT_PROP_MODES.js,
                assetListDSL,
                stateListDSL,
            });
        })
            .join(',');
        return outputMode === OUTPUT_PROP_MODES.jsx
            ? `{[ ${transformedValue} ]}`
            : `[ ${transformedValue} ]`;
    }
    /**
     * @deprecated
     */
    transformEnumProp({ propValue, propName, propListValues, propListDSL, entityName, propPath }, outputMode = OUTPUT_PROP_MODES.jsx) {
        if (typeof propValue === 'string') {
            return this.transformStringProp({ propValue, propName, propListValues, propListDSL, entityName, propPath }, outputMode);
        }
        if (typeof propValue === 'number') {
            return this.transformNumberProp({ propValue, propName, propListValues, propListDSL, entityName, propPath }, outputMode);
        }
        if (typeof propValue === 'boolean') {
            return this.transformBooleanProp({ propValue, propName, propListValues, propListDSL, entityName, propPath }, outputMode);
        }
        return this.transformStringProp({
            propValue: '__enum_type_not_found__',
            propName,
            propListValues,
            propListDSL,
            entityName,
            propPath,
        });
    }
    transformProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, assetListDSL, stateListDSL, }) {
        const { propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath, } = propAttributes;
        PropAssert.Schema.assertIsNotEmpty(propDSL, propName);
        if (PropChecker.Schema.isRequired(propDSL) && !propValue) {
            throw new SchemaValidationError(ERROR_SCOPES.appEngine, `${propName} is required.`);
        }
        // Allow to pass empty css prop to allow to merge it with style
        if (isUndefined(propValue) && propDSL.type !== COMPONENT_DSL_PROP_TYPES.css) {
            return null;
        }
        // Remove system props
        if (propDSL.system) {
            return null;
        }
        // Allow to pass css prop with JS code which will be handled in this.cssPropTransformer.transform()
        if (propDSL.type !== COMPONENT_DSL_PROP_TYPES.css && hasPropJsCode(propValue)) {
            const transformedValue = typeof propValue === 'string'
                ? transformPropWithJsCode(propValue)
                : transformPropWithJsCode(JSON.stringify(propValue));
            return outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${transformedValue} }` : transformedValue;
        }
        switch (propDSL.type) {
            case COMPONENT_DSL_PROP_TYPES.reactNode:
                return this.transformReactNodeProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            case COMPONENT_DSL_PROP_TYPES.number:
                return this.transformNumberProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            case COMPONENT_DSL_PROP_TYPES.stringOrNumber: {
                return isString(propValue)
                    ? this.transformStringProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode)
                    : this.transformNumberProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            }
            case COMPONENT_DSL_PROP_TYPES.boolean:
                return this.transformBooleanProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            case COMPONENT_DSL_PROP_TYPES.css: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const stylesProp = propListValues === null || propListValues === void 0 ? void 0 : propListValues.style;
                let backgroundImageurl;
                if (propListValues && propListValues.style && (stylesProp === null || stylesProp === void 0 ? void 0 : stylesProp.backgroundImage)) {
                    const hasJsCode = hasPropJsCode(stylesProp === null || stylesProp === void 0 ? void 0 : stylesProp.backgroundImage);
                    if (typeof (stylesProp === null || stylesProp === void 0 ? void 0 : stylesProp.backgroundImage) === 'string') {
                        const alreadyIncludesURL = stylesProp === null || stylesProp === void 0 ? void 0 : stylesProp.backgroundImage.includes('url(');
                        if ((stylesProp.backgroundImage.startsWith('http') ||
                            stylesProp.backgroundImage.startsWith('data') ||
                            hasJsCode) &&
                            !alreadyIncludesURL) {
                            backgroundImageurl = `url(${stylesProp.backgroundImage})`;
                        }
                        else {
                            backgroundImageurl = `${stylesProp.backgroundImage}`;
                        }
                    }
                    else if ('assetID' in stylesProp.backgroundImage) {
                        const path = this.transformAssetProp({
                            propAttributes: {
                                propValue: stylesProp.backgroundImage,
                                propName,
                                propDSL,
                                propListValues,
                                propListDSL,
                                entityName,
                                propPath,
                            },
                            outputMode: OUTPUT_PROP_MODES.raw,
                            assetListDSL,
                        });
                        backgroundImageurl = `url(${path})`;
                    }
                }
                const spreadStyle = this.getSpreadStyle(backgroundImageurl, propListValues);
                const transformed = this.cssPropTransformer.transform({
                    propValue,
                    propName,
                    propDSL,
                    propListValues: Object.assign(Object.assign({}, propListValues), { style: spreadStyle }),
                    propListDSL,
                    entityName,
                    propPath,
                }, !backgroundImageurl ? outputMode : OUTPUT_PROP_MODES.raw);
                return transformed;
            }
            case COMPONENT_DSL_PROP_TYPES.style:
                // All styles must be merged in the css prop
                // and a prop with type equals to style was deleted
                // but to be sure that no values will appear in the code
                // for a prop with type "style" we return null here
                return null;
            case COMPONENT_DSL_PROP_TYPES.object:
                return this.transformObjectProp({
                    propAttributes: {
                        propValue,
                        propDSL,
                        propName,
                        propListValues,
                        propListDSL,
                        entityName,
                        propPath,
                    },
                    outputMode,
                    assetListDSL,
                    stateListDSL,
                });
            case COMPONENT_DSL_PROP_TYPES.array:
                return this.transformArrayProp({
                    propAttributes: {
                        propValue,
                        propName,
                        propDSL,
                        propListValues,
                        propListDSL,
                        entityName,
                        propPath,
                    },
                    outputMode,
                    assetListDSL,
                    stateListDSL,
                });
            case COMPONENT_DSL_PROP_TYPES.callback: {
                if (propDSL.body === COMPONENT_DSL_CALLBACK_TYPES.component) {
                    return this.transformCallbackComponentProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
                }
                return this.transformCallbackCodeProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            }
            case COMPONENT_DSL_PROP_TYPES.enum:
                return this.transformEnumProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            case COMPONENT_DSL_PROP_TYPES.notSupported:
                return this.transformStringProp({
                    propValue: '__not_supported_prop__',
                    propName,
                    propDSL,
                    propListValues,
                    propListDSL,
                    entityName,
                    propPath,
                }, outputMode);
            case COMPONENT_DSL_PROP_TYPES.action:
                return this.transformActionProp({
                    propAttributes: {
                        propValue,
                        propName,
                        propDSL,
                        propListValues,
                        propListDSL,
                        entityName,
                        propPath,
                    },
                    outputMode,
                    assetListDSL,
                    stateListDSL,
                });
            case COMPONENT_DSL_PROP_TYPES.string:
                return this.transformStringProp({ propValue, propName, propDSL, propListValues, propListDSL, entityName, propPath }, outputMode);
            case COMPONENT_DSL_PROP_TYPES.asset:
                return this.transformAssetProp({
                    propAttributes: {
                        propValue,
                        propName,
                        propDSL,
                        propListValues,
                        propListDSL,
                        entityName,
                        propPath,
                    },
                    outputMode,
                    assetListDSL,
                });
            case COMPONENT_DSL_PROP_TYPES.customProps: {
                const result = this.transformCustomPropsProp({
                    propAttributes: {
                        propName,
                        propPath,
                        propDSL,
                        propValue,
                        propListDSL,
                        propListValues,
                        entityName,
                    },
                    outputMode,
                });
                return result;
            }
            case COMPONENT_DSL_PROP_TYPES.fieldValidation: {
                const result = this.transformFieldValidationProp({
                    propAttributes: {
                        propName,
                        propPath,
                        propDSL,
                        propValue,
                        propListDSL,
                        propListValues,
                        entityName,
                    },
                    outputMode,
                });
                return result;
            }
            case COMPONENT_DSL_PROP_TYPES.any:
            default: {
                const anyPropDSL = this.generatePropsSchema(propValue);
                return this.transformProp({
                    propAttributes: {
                        propValue,
                        propName,
                        propDSL: anyPropDSL,
                        propListValues,
                        propListDSL,
                        entityName,
                        propPath,
                    },
                    assetListDSL,
                    stateListDSL,
                });
            }
        }
    }
    getSpreadStyle(backgroundImageurl, propListValues) {
        if (backgroundImageurl) {
            return Object.assign(Object.assign({}, (typeof propListValues.style !== 'string'
                ? propListValues.style
                : {})), { backgroundImage: backgroundImageurl });
        }
        if (typeof propListValues.style === 'string') {
            return propListValues.style;
        }
        return propListValues.style;
    }
    transformCustomPropsProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, }) {
        const { propValue, propDSL, propName } = propAttributes;
        PropAssert.Value.assertIsCustomPropsProp(propValue, propName);
        PropAssert.Schema.assertIsCustomPropsProp(propDSL, propName);
        const transformedValue = propValue.map(x => {
            const { Key, Value } = x;
            return { Key: Key === null || Key === void 0 ? void 0 : Key.toString().toLowerCase(), Value };
        });
        return transformedValue;
    }
    transformFieldValidationProp({ propAttributes, outputMode = OUTPUT_PROP_MODES.jsx, }) {
        const { propValue, propDSL, propName } = propAttributes;
        PropAssert.Value.assertIsFieldValidationProp(propValue, propName);
        PropAssert.Schema.assertIsFieldValidationProp(propDSL, propName);
        const transformedValue = propValue.map(x => {
            const { Key, Type, Value, Validation } = x;
            return { Key, Type, Value, Validation };
        });
        return transformedValue;
    }
    /**
     * Returns object in format { stringProp: `"stringValue"`, numberProp: `{ numberValue }`, render: `<div />`... }
     */
    createPropsTransformationMap({ propData, outputMode = OUTPUT_PROP_MODES.jsx, assetListDSL, stateListDSL, }) {
        const { props: propListValues, propListDSL, entityName, propPath = [] } = propData;
        const transformationPropsMap = Object.entries(propListDSL).reduce((accum, [propName, propDSL]) => {
            var _a, _b;
            const propValue = propListValues[propName];
            const { type } = propDSL;
            const transformedProp = this.transformProp({
                propAttributes: {
                    propValue,
                    propName,
                    propDSL,
                    propListValues,
                    propListDSL,
                    entityName,
                    propPath,
                },
                outputMode,
                assetListDSL,
                stateListDSL,
            });
            if (transformedProp && type === COMPONENT_DSL_PROP_TYPES.customProps) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const currentPropArr = transformedProp;
                return Object.assign(Object.assign({}, accum), currentPropArr.reduce((localAccum, currentValue) => {
                    const { Key, Value } = currentValue;
                    return Object.assign(Object.assign({}, localAccum), { [Key]: `"${Value}"` });
                }, []));
            }
            if (transformedProp && type === COMPONENT_DSL_PROP_TYPES.fieldValidation) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const currentPropArr = transformedProp;
                const arrayObject = currentPropArr.reduce((localAccum, currentValue) => {
                    const { Key, Type, Value, Validation } = currentValue;
                    const validationArray = Validation
                        ? Validation.map((validation) => {
                            const { Key: KeyValidation, Arguments, ErrorMessage, Limit, Max, Min, Regex, } = validation;
                            return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ Key: KeyValidation }, (Arguments && { Arguments })), (ErrorMessage && { ErrorMessage })), (Max && { Max })), (Min && { Min })), (Limit && { Limit: Type === 'date' ? `"${Limit}"` : Limit })), (Regex && { Regex }));
                        })
                        : [];
                    const propObject = {
                        Key,
                        Type,
                        Value,
                        Validation: validationArray.length > 0 ? validationArray : [],
                    };
                    localAccum.push(JSON.stringify(propObject));
                    return localAccum;
                }, []);
                const fieldValidationArray = `{[${arrayObject}]}`;
                return Object.assign(Object.assign({}, accum), { fieldValidation: fieldValidationArray });
            }
            const cssPropDSL = propDSL;
            const stylePropName = (_a = cssPropDSL.targetStylePropName) !== null && _a !== void 0 ? _a : '';
            const stylePropValue = (_b = propListValues[stylePropName]) !== null && _b !== void 0 ? _b : null;
            const transformObjectProps = transformedProp;
            const hasValidStyleProp = propName === 'css' &&
                transformedProp &&
                stylePropValue &&
                !(stylePropValue === null || stylePropValue === void 0 ? void 0 : stylePropValue.toString().match('symbolProps'));
            if (transformObjectProps === null || transformObjectProps === void 0 ? void 0 : transformObjectProps.styleCSS) {
                if ((transformObjectProps === null || transformObjectProps === void 0 ? void 0 : transformObjectProps.css) === '{ css`\n \n` }') {
                    return Object.assign(Object.assign({}, accum), { style: transformObjectProps === null || transformObjectProps === void 0 ? void 0 : transformObjectProps.styleCSS });
                }
                return Object.assign(Object.assign({}, accum), { style: transformObjectProps === null || transformObjectProps === void 0 ? void 0 : transformObjectProps.styleCSS, css: transformObjectProps === null || transformObjectProps === void 0 ? void 0 : transformObjectProps.css });
            }
            if (hasValidStyleProp) {
                return Object.assign(Object.assign({}, accum), { style: transformedProp });
            }
            return transformedProp
                ? Object.assign(Object.assign({}, accum), { [propName]: transformedProp }) : accum;
        }, {});
        return transformationPropsMap;
    }
}
export const transformProps = ({ propData, outputMode, generateNode, assetListDSL, stateListDSL, }) => {
    const { props, propListDSL, entityName, propPath } = propData;
    const nodePropTransformer = new PropTransformer({
        generateNode,
    });
    const transformationPropsMap = nodePropTransformer.createPropsTransformationMap({
        propData: { props, propListDSL, entityName, propPath },
        outputMode,
        assetListDSL,
        stateListDSL,
    });
    return transformationPropsMap;
};
export const transformPropsByMode = ({ nodePropData, outputMode, generateNode, assetListDSL, stateListDSL, }) => {
    var _a;
    const { nodeDSL, componentListDSL, entityName } = nodePropData;
    const propListDSL = componentListSelectors.getPropListDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const props = (_a = nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.props) !== null && _a !== void 0 ? _a : {};
    const _b = transformProps({
        propData: { props, propListDSL, entityName, propPath: [] },
        outputMode,
        generateNode,
        assetListDSL,
        stateListDSL,
    }), { children: transformedChildren } = _b, transformationPropsMap = __rest(_b, ["children"]);
    return {
        children: transformedChildren,
        otherPropsMap: transformationPropsMap,
    };
};
export const transformObjectProps = ({ propData, generateNode, assetListDSL, stateListDSL, }) => {
    const { props, propListDSL, entityName, additionalProps = '' } = propData;
    const transformationPropsMap = transformProps({
        propData: { props, propListDSL, entityName, propPath: [] },
        outputMode: OUTPUT_PROP_MODES.js,
        generateNode,
        assetListDSL,
        stateListDSL,
    });
    const transformedProps = Object.keys(transformationPropsMap).reduce((accum, propName) => `${accum} ${propName}:${transformationPropsMap[propName]},`, '');
    return `{ ${transformedProps} ${additionalProps} }`;
};
const getQueryVariant = (stateListDSL, entityName) => {
    const [queryVariant] = Object.values(stateListDSL)
        .filter((state) => (state === null || state === void 0 ? void 0 : state.name) === entityName)
        .map(querySelected => querySelected === null || querySelected === void 0 ? void 0 : querySelected.variant);
    return queryVariant;
};
export const transformQueryProps = ({ propData, generateNode, assetListDSL, stateListDSL, }) => {
    const { props, propListDSL, entityName, additionalProps = '' } = propData;
    const transformationPropsMap = transformProps({
        propData: { props, propListDSL, entityName, propPath: [] },
        outputMode: OUTPUT_PROP_MODES.js,
        generateNode,
        assetListDSL,
        stateListDSL,
    });
    const transformedProps = Object.keys(transformationPropsMap).reduce((accum, propName) => {
        if (propName === 'pollInterval') {
            if (!transformationPropsMap[propName].search(/('\d{1,}')/gi)) {
                return `${accum} ${propName}: parseInt(${transformationPropsMap[propName]}, 10) ,`;
            }
            return `${accum} ${propName}: ${transformationPropsMap[propName]},`;
        }
        if (propName === 'onError' && transformationPropsMap[propName] === '(error) => {  }') {
            return `${accum} ${propName}: (error) => { onError(error) },`;
        }
        if (getQueryVariant(stateListDSL, propData === null || propData === void 0 ? void 0 : propData.entityName) === STATE_REQUEST_VARIANTS.lazyQuery &&
            (propName === STATE_REQUEST_CALLBACKS.onCompleted ||
                propName === STATE_REQUEST_CALLBACKS.onError)) {
            return accum;
        }
        return `${accum} ${propName}:${transformationPropsMap[propName]},`;
    }, '');
    return `{ ${transformedProps} ${additionalProps} }`;
};
