import { combineReducers } from 'redux';

import { AppEvents, DashboardState } from '../../common';
import { dashboardInitial } from '../initial-state';
import { shallowEqualNaive } from 'src/shared/utils/shallowEqual';

import { appConfigurationReducer, layoutReducer, themeManagerReducer } from './basic';
import { dashboardComplexReducer } from './dashboardComplexReducer';
import { dashboardCompleteUpdateRestoreReducer } from './edge/dashboardCompleteUpdateRestoreReducer';
import { dashboardOperationsReducer } from './edge/dashboardOperationsReducer';
import { dashboardUndoRedoReducer } from './edge/dashboardUndoRedoReducer';

const intermediateBasicReducers = combineReducers<DashboardState, AppEvents>({
  operations: (s: DashboardState['operations'] = dashboardInitial.operations) => s,
  copyBuffer: (s: DashboardState['copyBuffer'] = dashboardInitial.copyBuffer) => s,
  copyStyle: (s: DashboardState['copyStyle'] = dashboardInitial.copyStyle) => s,
  openAsRootNode: (s: DashboardState['openAsRootNode'] = dashboardInitial.openAsRootNode) => s,
  components: (s: DashboardState['components'] = dashboardInitial.components) => s,
  history: dashboardUndoRedoReducer,
  router: (s: DashboardState['router'] = dashboardInitial.router) => s,
  iFrameRefreshed: (s: DashboardState['iFrameRefreshed'] = dashboardInitial.iFrameRefreshed) => s,
  navigator: (s: DashboardState['navigator'] = dashboardInitial.navigator) => s,
  loadingConfiguration: (
    s: DashboardState['loadingConfiguration'] = dashboardInitial.loadingConfiguration,
  ) => s,
  appConfiguration: appConfigurationReducer,
  themeManager: themeManagerReducer,
  layout: layoutReducer,
});

const hasChanges = (
  oldState: Record<string, unknown>,
  newState: Record<string, unknown>,
  keysFrom: Record<string, unknown> = dashboardInitial,
): boolean => {
  const stateKeys = Object.keys(keysFrom);
  const equal = shallowEqualNaive(oldState, newState, stateKeys);
  return !equal;
};

export const dashboardReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  /**
   * Reducers are composed and lined up based on their effect on `history`
   * , and complexity/scope (ie, whether or not they depend on the entire DashboardState)
   */

  /**
   * NOTE: should you struggle to understand where to put an event:
   * - when in doubt, just look at which state is available
   * - when you want to change an event to update something outside its initial scope:
   *    - consider moving the use-case to a more complex-reducer
   *    - if that feels wrong, consider adding an event that comes after the initial one
   *      and handle this case using sagas -- produce an event from another event
   *
   *    eg: componentSelected should also expand page-structure tree -- hence,
   *        componentSelected should actually produce another event navigatorExpandNode,
   *    ie: you can always try and split an event into sequence of smaller events,
   *        each of managing their own small business-cases
   */

  const newOperationsState = dashboardOperationsReducer(state.operations, event);
  if (hasChanges(state.operations, newOperationsState, dashboardInitial.operations)) {
    // operations are potentially high frequency and should not be committed to history
    return { ...state, operations: newOperationsState };
  }

  const newRestoredState = dashboardCompleteUpdateRestoreReducer(state, event);
  if (hasChanges(state, newRestoredState)) {
    return newRestoredState;
  }

  // those are simple scoped reducers
  const intermediateState = intermediateBasicReducers(state, event);
  // these require the entire DashboardState to reduce properly
  const newDashboardState = dashboardComplexReducer(intermediateState, event);

  if (hasChanges(state, newDashboardState)) {
    return newDashboardState;
  }

  // no changes - no problems
  return state;
};
