import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const PopupContent = styled.div`
  padding: ${({ theme }) => theme.spacing(1.5)};
  width: 316px;
  border-radius: 8px;
  background-color: #242c34;
  box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.32);
`;

export const PopupTitle = styled(Typography)`
  height: 20px;
  color: #ffffff;
  font-family: Poppins, serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
`;

export const PopupHeader = styled.div`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  margin: -${({ theme }) => theme.spacing(1.5)};
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;
