import React, { useCallback, useEffect, useMemo } from 'react';

import { DateTime } from 'luxon';

import { AppVersion, INITIAL_APP_VERSION } from '@builder/schemas';
import { generateID } from '@builder/utils';

import { DASHBOARD_DIALOGS } from '../../dialogs';
import { DEPLOY_STATUSES, MESSAGES } from '../../shared/constants';
import { DeployFragment, DeploysListQuery } from '../../shared/graphql/__generated__';
import {
  useApplicationDeploy,
  useDeploy,
  useDeployList,
  useDeploySettingsHooks,
} from '../../shared/graphql/hooks';
import { getAppVersionParts, incrementVersion } from '../../shared/utils';
import { UI_BUILDER_EVENTS } from '../../store';
import { useDialogState } from '../DialogProvider';
import { useAppDispatch, useAppVersion, useSchemaDSLVersion } from '../ReduxProvider';

type DeployFragmentExtended = DeployFragment & {
  isLocal?: boolean;
};
type DeployContextProps = {
  deploy: DeployFragment | undefined;
  startingDeploy: boolean;
  loadingDeployList: boolean;
  isDeploying: boolean;
  hadOneDeployment: boolean;
  latestDeployedAppVersion: string;
  buildName: string;
  deployVersionPart: string;
  appVersionPart: string;
  deployList?: DeploysListQuery;
  deployApplication: (appVersion: AppVersion) => Promise<void>;
  latestSuccessfulDeploy: DeployFragment | undefined;
  currentFakeVersion: DeployFragmentExtended;
  isLoadingDeployResult: boolean;
  refetchDeployList: () => void;
};

const DeployContext = React.createContext<DeployContextProps>({
  deploy: undefined,
  startingDeploy: false,
  isDeploying: false,
  latestDeployedAppVersion: '',
  buildName: '',
  hadOneDeployment: false,
  deployVersionPart: '',
  appVersionPart: '',
  deployApplication: () => Promise.resolve(),
  latestSuccessfulDeploy: undefined,
  loadingDeployList: false,
  currentFakeVersion: {},
  isLoadingDeployResult: false,
  refetchDeployList: () => {
    // do nothing
  },
});

export const DeployProvider: React.FC = ({ children }) => {
  const schemaDSLVersion = useSchemaDSLVersion();
  const dslAppVersion = useAppVersion();
  const buildName = React.useRef<string>('');
  const lastDeploy = React.useRef<DeployFragment | undefined>(undefined);
  const currentFakeVersion = React.useRef<DeployFragmentExtended>({});
  const isDeploying = React.useRef<boolean>(false);
  const deployListResult = useDeployList();
  const { openDialog } = useDialogState();
  const send = useAppDispatch();
  const { startDeploy, startingDeploy } = useApplicationDeploy();
  const { deploySettingsResult } = useDeploySettingsHooks();
  const loadingDeployList = deployListResult?.loading;
  const deployResult = useDeploy(buildName.current, isDeploying.current);
  const deploy = useMemo<DeployFragment | undefined>(
    () => deployResult.data?.appbuilder?.deploy as DeployFragment,
    [deployResult],
  );
  const deployList = useMemo(() => {
    return (deployListResult?.data?.appbuilder?.deployList?.items ?? []) as DeployFragment[];
  }, [deployListResult?.data?.appbuilder?.deployList?.items]);

  useEffect(() => {
    if (isDeploying.current && buildName.current !== '') {
      setTimeout(() => {
        deployResult.startPolling(2500);
      }, 1000);
    }

    return () => deployResult.stopPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeploying.current, buildName.current, deployResult.startPolling, deployResult.stopPolling]);

  useEffect(() => {
    if (isDeploying && lastDeploy.current == null) {
      lastDeploy.current = deployList.find(item => item.status === DEPLOY_STATUSES.pending);
    }
  }, [deployList]);

  const latestSuccessfulDeploy = useMemo(() => {
    if (isDeploying.current) {
      return lastDeploy.current || currentFakeVersion.current;
    }

    const succeededDeploys = deployList.filter(
      deployFragment => deployFragment?.status === DEPLOY_STATUSES.resolved,
    );

    return succeededDeploys[0] || deployList[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployList, isDeploying.current]);

  const latestDeployedAppVersion = deployList?.[0]?.deployedAppVersion ?? INITIAL_APP_VERSION;

  const [deployVersionPart, appVersionPart] = useMemo(() => {
    const [deployPart] = getAppVersionParts(latestDeployedAppVersion);
    const [, appPart] = getAppVersionParts(dslAppVersion);

    return [deployPart, appPart];
  }, [dslAppVersion, latestDeployedAppVersion]);

  const onDeployResolved = useCallback(() => {
    deploySettingsResult.refetch().then(data => {
      send({
        type: UI_BUILDER_EVENTS.successDeploy,
        notification: {
          key: buildName.current,
          actionCallback: () => {
            window.open(data.data?.appbuilder?.settings?.projectUrl || '', '_blank', 'noreferrer');
          },
          message: `You successfully deployed your project on ${
            data.data?.appbuilder?.settings?.projectUrl || ''
          }`,
        },
      });
    });
    lastDeploy.current = deploy;
    deployResult.stopPolling();
    buildName.current = '';
    isDeploying.current = false;
    deployListResult.refetch();
  }, [deploy, deployListResult, deployResult, deploySettingsResult, send]);

  const onDeployFailed = useCallback(() => {
    send({
      type: UI_BUILDER_EVENTS.failDeploy,
      notification: {
        key: buildName.current,
        message: MESSAGES.deploy.failDeploymentDescription,
      },
    });
    lastDeploy.current = deploy;
    deployResult.stopPolling();
    buildName.current = '';
    isDeploying.current = false;
    deployListResult.refetch();
  }, [deploy, deployListResult, deployResult, send]);

  useEffect(() => {
    if (deploy?.status === DEPLOY_STATUSES.resolved) {
      onDeployResolved();
    } else if (deploy?.status === DEPLOY_STATUSES.rejected) {
      onDeployFailed();
    }
  }, [deploy?.status, onDeployFailed, onDeployResolved]);

  useEffect(() => {
    const haveDeploying = deployListResult.data?.appbuilder?.deployList?.items?.find(
      item => item.status === DEPLOY_STATUSES.pending,
    );
    if (haveDeploying != null) {
      buildName.current = haveDeploying?.buildName ?? '';
      isDeploying.current = true;
    }
  }, [deployListResult]);

  const deployApplication = useCallback(
    async (appVersion: AppVersion) => {
      const [deployVersion] = getAppVersionParts(appVersion);
      const buildNameResult = await startDeploy({
        deployedAppVersion: deployVersion,
      });

      if (buildNameResult) {
        currentFakeVersion.current = {
          buildName: buildName.current,
          status: DEPLOY_STATUSES.pending,
          id: generateID(),
          deployedAppVersion: incrementVersion(deployVersionPart, [0, 0, 1]),
          schemaDSLVersion,
          createdAt: DateTime.now().toISO(),
          isLocal: true,
        };
        lastDeploy.current = undefined;
        buildName.current = buildNameResult;
        isDeploying.current = true;
        openDialog(DASHBOARD_DIALOGS.DEPLOY_PROGRESS_DIALOG_ID, {
          buildName,
        });
      }
    },
    [schemaDSLVersion, buildName, deployVersionPart, openDialog, startDeploy],
  );

  const hadOneDeployment = useMemo(() => {
    return deployList.some(item => item.status === DEPLOY_STATUSES.resolved);
  }, [deployList]);

  const refetchDeployList = () => {
    deployListResult.refetch();
  };

  return (
    <DeployContext.Provider
      value={{
        deploy: deploy != null ? deploy : lastDeploy.current,
        startingDeploy,
        isDeploying: isDeploying.current,
        latestDeployedAppVersion,
        deployApplication,
        latestSuccessfulDeploy,
        loadingDeployList,
        buildName: buildName.current,
        deployVersionPart,
        appVersionPart,
        hadOneDeployment,
        currentFakeVersion: currentFakeVersion.current,
        isLoadingDeployResult: deployResult.loading,
        deployList: deployListResult.data,
        refetchDeployList,
      }}
    >
      {children}
    </DeployContext.Provider>
  );
};

export function useDeployContext(): DeployContextProps {
  return React.useContext(DeployContext);
}
