import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getCommonPropertiesSections, getDivider, getStylesSection, getActionSettings, } from '../../common';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Grid Items',
        name: 'gridItems',
        children: [
            {
                type: COMPONENT_SETTING_TYPES.nodeList,
                keyPath: ['children'],
                label: 'Grid Items',
                name: 'gridItems',
                items: [
                    {
                        name: COMPONENT_DSL_NAMES.GridItemSymbol,
                        settings: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['xs'],
                                label: 'Width',
                                options: ['auto', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(createSelectOptionByValue),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    getDivider(),
    ...getCommonPropertiesSections(),
];
const getStyleSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        children: [
            {
                type: COMPONENT_SETTING_TYPES.subSection,
                title: 'Flexbox behavior',
                name: 'flexboxBehavior',
                children: [
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['alignContent'],
                        label: 'Align Content',
                        options: [
                            'stretch',
                            'center',
                            'flex-start',
                            'flex-end',
                            'space-between',
                            'space-around',
                        ].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['alignItems'],
                        label: 'Align Items',
                        options: ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['direction'],
                        label: 'Direction',
                        options: ['row', 'row-reverse', 'column', 'column-reverse'].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['justifyContent'],
                        label: 'Justify Content',
                        options: [
                            'flex-start',
                            'center',
                            'flex-end',
                            'space-between',
                            'space-around',
                            'space-evenly',
                        ].map(createSelectOptionByValue),
                    },
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['wrap'],
                        label: 'Wrap',
                        options: ['nowrap', 'wrap', 'wrap-reverse'].map(createSelectOptionByValue),
                    },
                ],
            },
            {
                type: COMPONENT_SETTING_TYPES.subSection,
                title: 'Spacing',
                name: 'spacing',
                children: [
                    {
                        componentView: COMPONENT_SETTING_VIEWS.select,
                        type: COMPONENT_SETTING_TYPES.prop,
                        keyPath: ['spacing'],
                        label: 'Spacing',
                        options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(createSelectOptionByValue),
                    },
                ],
            },
        ],
    },
    getDivider(),
    ...getStylesSection({
    // subSections: [
    //   STYLE_SECTION_TYPES.background,
    //   STYLE_SECTION_TYPES.border,
    //   STYLE_SECTION_TYPES.font,
    //   STYLE_SECTION_TYPES.size,
    //   STYLE_SECTION_TYPES.spacing,
    // ],
    }),
];
export const GridContainerSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSettings(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
