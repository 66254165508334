import { COMPONENT_SETTING_TYPES, COMPONENT_DSL_NAMES } from '../../../constants';
import { getStylesSection, getActionSettings } from '../../common';
const getStyleSettings = (prefixPath = []) => [
    ...getStylesSection({ prefixPath }),
];
export const MobileLayoutSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.node,
                    name: 'header',
                    label: 'Header',
                    keyPath: ['header'],
                    items: [
                        {
                            name: COMPONENT_DSL_NAMES.AppBarSymbol,
                            settings: [],
                        },
                    ],
                },
            ],
        },
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.node,
                    name: 'footer',
                    label: 'Footer',
                    keyPath: ['footer'],
                    items: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsFooter,
                            settings: [],
                        },
                    ],
                },
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSettings(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
