import { Migration } from '../Migration';
class Migration_0_19_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.3';
    }
    getChangeList() {
        return ['Delete default code in beforeEach and afterEach'];
    }
    migrateAppDSL(appDSL) {
        var _a, _b;
        const transFormDefaultcode = (code) => {
            const regex = /^return function\(\{([\w\s,]+)\}\) \{\s+\/\/ type here\n\}$/;
            const match = code.match(regex);
            return match ? '// Your code here' : code;
        };
        const routerHooks = appDSL.routeHooks || {
            beforeEach: {
                name: 'BeforeEachGlobalHook',
                code: '// Your code here',
                scope: 'global',
            },
            afterEach: {
                name: 'AfterEachGlobalHook',
                code: '// Your code here',
                scope: 'global',
            },
        };
        const beforeEachCode = transFormDefaultcode(routerHooks.beforeEach.code || '');
        const afterEachCode = transFormDefaultcode(routerHooks.afterEach.code || '');
        return Object.assign(Object.assign({}, appDSL), { routeHooks: {
                beforeEach: Object.assign(Object.assign({}, (_a = appDSL.routeHooks) === null || _a === void 0 ? void 0 : _a.beforeEach), { code: beforeEachCode, name: 'BeforeEachGlobalHook', scope: 'global' }),
                afterEach: Object.assign(Object.assign({}, (_b = appDSL.routeHooks) === null || _b === void 0 ? void 0 : _b.afterEach), { code: afterEachCode, name: 'AfterEachGlobalHook', scope: 'global' }),
            } });
    }
}
export const migration_0_19_3 = new Migration_0_19_3();
