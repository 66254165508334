var _a, _b, _c, _d, _e, _f;
import { BuilderComponentsTab } from '../component-schemas/material-components/BuilderComponentsTab';
import { COMPONENT_DSL_NAMES } from '../constants/component';
import { STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants/styleguide';
import { BASE_SCHEMA } from './common';
import basicTabsDSL from './style-guide-dsl/TabsStyleGuide/basicTabs.json';
import colorDSL from './style-guide-dsl/TabsStyleGuide/colorDsl.json';
import iconsDSL from './style-guide-dsl/TabsStyleGuide/iconsDsl.json';
import scrollDSL from './style-guide-dsl/TabsStyleGuide/scrollableDsl.json';
export const TabsStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiTabs,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
    dirName: 'TabsStyleGuide',
    title: 'Tabs',
    classNames: [
        '.MuiTab-root',
        '.Mui-checked',
        '.Mui-disabled',
        '.MuiTab-colorPrimary',
        '.MuiTab-colorSecondary',
    ],
    stories: [
        {
            title: 'Basic (Horizontal and Vertical)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        disabled: false,
                        label: undefined,
                        textcolor: 'primary',
                    },
                },
                {
                    props: {
                        disabled: false,
                        label: undefined,
                        orientation: 'vertical',
                        textcolor: 'primary',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: basicTabsDSL,
        },
        {
            title: 'Color Tab Indicator',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        disabled: false,
                        label: undefined,
                        textcolor: 'primary',
                    },
                },
                {
                    props: {
                        disabled: false,
                        label: undefined,
                        orientation: 'vertical',
                        textcolor: 'primary',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: colorDSL,
        },
        {
            title: 'Scrollable',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        disabled: false,
                        label: undefined,
                        textcolor: 'primary',
                        scrollButtons: true,
                        variant: 'scrollable',
                        style: {
                            width: '250px',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    props: Object.assign(Object.assign({}, (_a = BuilderComponentsTab.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { value: '{{ __STATES__.tab.value }}', header: 'header', content: 'content1' }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    props: Object.assign(Object.assign({}, (_b = BuilderComponentsTab.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { value: '{{ __STATES__.tab.value }}', header: 'value2', content: 'content2' }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    props: Object.assign(Object.assign({}, (_c = BuilderComponentsTab.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { value: '3', header: 'header', content: 'content3' }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    props: Object.assign(Object.assign({}, (_d = BuilderComponentsTab.schema.predefineds) === null || _d === void 0 ? void 0 : _d.props), { value: '4', header: 'header', content: 'content4' }),
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: scrollDSL,
        },
        {
            title: 'Icons',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        disabled: false,
                        label: undefined,
                        textcolor: 'primary',
                        scrollButtons: true,
                        variant: 'scrollable',
                        style: {
                            width: '300px',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    props: Object.assign(Object.assign({}, (_e = BuilderComponentsTab.schema.predefineds) === null || _e === void 0 ? void 0 : _e.props), { value: '1', header: 'tab', content: 'content1', icon: 'person', iconposition: 'top' }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    props: Object.assign(Object.assign({}, (_f = BuilderComponentsTab.schema.predefineds) === null || _f === void 0 ? void 0 : _f.props), { value: '2', header: 'tab', content: 'content2', icon: 'favorite', iconposition: 'start' }),
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: iconsDSL,
        },
    ],
};
