import { DEFAULT_IMAGE_COMPONENT_SOURCE } from '@builder/utils';
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsImageSettings } from './BuilderComponentsImage.settings';
export const BuilderComponentsImage = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsImage,
    displayName: 'Image',
    icon: 'image',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Image',
    },
    schema: Object.assign(Object.assign({ presentations: [visibleByCondition] }, getSysMinSizeStyle()), { predefineds: {
            props: {
                src: DEFAULT_IMAGE_COMPONENT_SOURCE,
            },
        }, props: Object.assign(Object.assign({}, commonComponentProps), { src: { type: COMPONENT_DSL_PROP_TYPES.asset }, srcSet: { type: COMPONENT_DSL_PROP_TYPES.string }, sizes: { type: COMPONENT_DSL_PROP_TYPES.string }, width: { type: COMPONENT_DSL_PROP_TYPES.number }, height: { type: COMPONENT_DSL_PROP_TYPES.number }, alt: { type: COMPONENT_DSL_PROP_TYPES.string } }) }),
    settings: BuilderComponentsImageSettings,
};
