import { isArray, isString } from '@builder/utils';

import {
  AppEvents,
  DashboardState,
  DASHBOARD_ANIM_EVENTS,
  DASHBOARD_EVENTS,
} from '../../../common';
import { dashboardInitial } from '../../initial-state';

export const dashboardOperationsReducer = (
  state: DashboardState['operations'] = dashboardInitial.operations,
  event: AppEvents,
): DashboardState['operations'] => {
  switch (event.type) {
    case DASHBOARD_EVENTS.dashboardClearSelection:
      return state.selectedID === null
        ? state
        : { ...state, selectedID: null, lastCreatedID: null };

    case DASHBOARD_EVENTS.componentToggleSelect: {
      return state;
    }

    case DASHBOARD_EVENTS.componentToggleSelectByID: {
      const selectedID = state.selectedID === event.id ? null : event.id;
      return {
        ...state,
        selectedID,
        lastCreatedID: null,
      };
    }

    case DASHBOARD_EVENTS.componentSelect: {
      return {
        ...state,
        selectedID: event.id,
        lastCreatedID: null,
      };
    }

    case DASHBOARD_EVENTS.componentSelectMultiple: {
      if (isArray(state.selectedID) && state.selectedID?.includes(event.id)) {
        const newSelectedId = state.selectedID.filter(id => id !== event.id);
        return newSelectedId.length === 1
          ? { ...state, selectedID: newSelectedId[0], lastCreatedID: null }
          : { ...state, selectedID: newSelectedId, lastCreatedID: null };
      }

      const prevNodes = isArray(state.selectedID) ? state.selectedID : [state.selectedID];
      return {
        ...state,
        selectedID: [...(prevNodes as string[]), event.id],
        lastCreatedID: null,
      };
    }

    case DASHBOARD_EVENTS.componentSelectedRemove: {
      return isString(state.selectedID) || isArray(state.selectedID)
        ? { ...state, selectedID: null, lastCreatedID: null }
        : state;
    }

    case DASHBOARD_EVENTS.componentHover:
      return {
        ...state,
        hoveredID: event.id,
      };

    case DASHBOARD_EVENTS.componentHoverRemove:
      return {
        ...state,
        hoveredID: null,
      };

    case DASHBOARD_EVENTS.componentToggleFocus:
      return {
        ...state,
        focusedNodeRenderID: state.focusedNodeRenderID === event.renderID ? null : event.renderID,
      };

    case DASHBOARD_ANIM_EVENTS.moveStart:
      return {
        ...state,
        dndInProgress: { sourceID: event.sourceID, nodeName: event.nodeName },
      };

    case DASHBOARD_ANIM_EVENTS.moveName:
      return {
        ...state,
        dndInProgress: state?.dndInProgress?.sourceID
          ? { ...state.dndInProgress, nodeName: event.nodeName }
          : state.dndInProgress,
      };

    case DASHBOARD_ANIM_EVENTS.move:
      return { ...state, dndPreview: { ...event.spec } };

    case DASHBOARD_ANIM_EVENTS.moveEnd:
      return { ...state, dndPreview: null, dndInProgress: null, dndOverNavigation: false };

    case DASHBOARD_ANIM_EVENTS.clear:
      return { ...state, dndPreview: null };

    case DASHBOARD_ANIM_EVENTS.navigationEnter:
      return { ...state, dndOverNavigation: true };

    case DASHBOARD_ANIM_EVENTS.navigationLeave:
      return { ...state, dndOverNavigation: false };

    default:
      return state;
  }
};
