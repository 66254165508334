import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialCardHeaderSettings } from './MaterialCardHeader.settings';
export const MaterialCardHeader = {
    name: COMPONENT_DSL_NAMES.MaterialCardHeader,
    displayName: 'Card Header',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'CardHeader',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyRemovable,
        accessibility: {
            hideOverlay: true,
        },
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                title: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Card',
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { avatar: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, subheader: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, title: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, disableTypography: { type: COMPONENT_DSL_PROP_TYPES.boolean }, titleTypographyProps: { type: COMPONENT_DSL_PROP_TYPES.object }, subheaderTypographyProps: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: MaterialCardHeaderSettings,
};
