import { none, isEmpty } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_16_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.4';
    }
    getChangeList() {
        return ['Restore States in page nodes and delete duplicated states'];
    }
    migrateAppDSL(appDSL) {
        const newDSL = Object.assign({}, appDSL);
        const nodes = Object.assign({}, newDSL.nodes);
        const states = Object.assign({}, newDSL.states);
        const statesEntries = Object.entries(states);
        const newNodesValues = Object.entries(nodes).map(([nodeKey, nodeValue]) => {
            var _a;
            if (nodeValue.props.path) {
                if (nodeValue.props.children !== undefined) {
                    if (nodeValue.props.children.nodes !== undefined) {
                        const childrenKey = nodeValue.props.children
                            .nodes[0];
                        const newNodeValue = Object.assign({}, nodeValue);
                        newNodeValue.states = [...((_a = nodeValue.states) !== null && _a !== void 0 ? _a : [])];
                        if (!isEmpty(newNodeValue.states)) {
                            const [firstState] = newNodeValue.states;
                            const nonRepeatingStates = [firstState];
                            newNodeValue.states.forEach((item) => {
                                const exist = (n) => n.stateID === item.stateID;
                                if (none(exist, nonRepeatingStates)) {
                                    nonRepeatingStates.push(item);
                                }
                            });
                            newNodeValue.states = nonRepeatingStates;
                        }
                        nodes[childrenKey].states = newNodeValue.states;
                        return [nodeKey, newNodeValue];
                    }
                }
            }
            return [nodeKey, nodeValue];
        });
        return Object.assign(Object.assign({}, newDSL), { nodes: Object.fromEntries(newNodesValues), states: Object.fromEntries(statesEntries) });
    }
}
export const migration_0_16_4 = new Migration_0_16_4();
