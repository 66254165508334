import { AppEvents, DashboardState, DASHBOARD_EVENTS } from '../../../common';
import { dashboardInitial } from '../../initial-state';

export const dashboardCompleteUpdateRestoreReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  // to force update set iFrameRefreshed = false (handled in pushBuilderUIData)
  switch (event.type) {
    case DASHBOARD_EVENTS.dashboardClear: {
      return dashboardInitial;
    }

    case DASHBOARD_EVENTS.appConfigurationInit: {
      return {
        ...state,
        iFrameRefreshed: false,
        appConfiguration: {
          ...state.appConfiguration,
          ...event.appConfiguration,
        },
      };
    }

    case DASHBOARD_EVENTS.appConfigurationUpdate: {
      return {
        ...state,
        router: {
          ...state.router,
          currentRoute: event.route || state.router.currentRoute,
        },
        appConfiguration: {
          ...state.appConfiguration,
          ...event.appConfiguration,
          appDSL: { ...state.appConfiguration.appDSL, ...event.appConfiguration.appDSL },
        },
      };
    }

    case DASHBOARD_EVENTS.appConfigurationUpdateDslNodes: {
      return {
        ...state,
        appConfiguration: {
          ...state.appConfiguration,
          appDSL: { ...state.appConfiguration.appDSL, nodes: event.nodes },
        },
      };
    }

    case DASHBOARD_EVENTS.appConfigurationUpdateVersion: {
      return {
        ...state,
        appConfiguration: {
          ...state.appConfiguration,
          appVersion: event.appVersion,
        },
      };
    }

    case DASHBOARD_EVENTS.dashboardIFrameRefresh: {
      return {
        ...state,
        iFrameRefreshed: true,
      };
    }

    case DASHBOARD_EVENTS.dashboardSetLoadingConfiguration: {
      return {
        ...state,
        loadingConfiguration: event.loading,
      };
    }

    default:
      return state;
  }
};
