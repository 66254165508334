import { hasPropJsCode, transformPropWithJsCode } from '@builder/schemas';
export const getTransformIteratorName = (iterator) => {
    return hasPropJsCode(iterator.name) ? transformPropWithJsCode(iterator.name) : iterator.name;
};
export const getTransformIteratorIndexName = (iterator) => {
    const transformedName = getTransformIteratorName(iterator);
    return `${transformedName}Index`;
};
export const getTransformIteratorData = (iterator) => {
    return hasPropJsCode(iterator.data)
        ? transformPropWithJsCode(iterator.data)
        : JSON.stringify(iterator.data);
};
/**
 * Transforms iterator data dsl to js data.
 * @example
 */
export const transformIterator = (iterator, nodeText) => {
    const transformedData = getTransformIteratorData(iterator);
    const transformedName = getTransformIteratorName(iterator);
    const transformedIndexName = getTransformIteratorIndexName(iterator);
    return `(${transformedData})?.map((${transformedName}, ${transformedIndexName}) => ( ${nodeText} ))`;
};
