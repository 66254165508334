import { useMemo } from 'react';

import { NodeID, nodeListSelectors } from '@builder/schemas';

import { useNodeListDSL } from 'src/providers/ReduxProvider';

import { useEditableNodeIDs } from './useEditableNodeIDs';

export const useFirstEditableNodeID = (): NodeID => {
  const nodeListDSL = useNodeListDSL();
  const editableNodeIDs = useEditableNodeIDs();

  return useMemo(() => {
    const firstEditableNodeDSL = nodeListSelectors.getNodeWithoutParentDSL(nodeListDSL, {
      nodeIDs: editableNodeIDs,
    });

    return firstEditableNodeDSL.id;
  }, [editableNodeIDs, nodeListDSL]);
};
