import { BroadcastStatePayload } from '@8base-private/event-handler';

import { processEnv } from '@builder/utils';

import { MESSAGES } from '../../shared/constants';
import {
  Store,
  AppEvents,
  UI_BUILDER_EVENTS,
  UI_BUILDER_MODES,
  NODE_SETTINGS_TABS,
  RIGHT_PANEL_TABS,
  LEFT_PANEL_TABS,
  ASSET_VIEW_MODES,
  UI_BUILDER_EDIT_MODES,
} from '../common';
import { getRandomColor } from 'src/shared/utils';

const initialUIBuilderState: Store['uiBuilder'] = {
  isSaveButtonDisabled: false,
  devModeEnabled: processEnv.isDevelopment() || localStorage.getItem('devmode') === 'true',
  mode: UI_BUILDER_MODES.construction,
  isOpenIssueManager: false,
  notifications: [],
  leftPanel: {
    currentTab: LEFT_PANEL_TABS.none,
    currentRoute: undefined,
    viewPanel: 'list',
    args: {
      isAffectOnDashboardLayout: false,
    },
  },
  rightPanel: {
    currentTab: RIGHT_PANEL_TABS.componentList,
    nodeSettingsArgs: {
      currentTab: NODE_SETTINGS_TABS.properties,
    },
  },
  audit: [],
  assetViewMode: ASSET_VIEW_MODES.grid,
  editMode: UI_BUILDER_EDIT_MODES.page,
  layoutNodesIds: [],
  usersSessions: {},
  visibleNodePresentation: {},
  hasUpdates: false,
};

export const uiBuilderReducer = (
  state: Store['uiBuilder'] = initialUIBuilderState,
  event: AppEvents,
): Store['uiBuilder'] => {
  switch (event.type) {
    case UI_BUILDER_EVENTS.saveState: {
      return {
        ...state,
        hasUpdates: event.havePendingChanges,
      };
    }
    case UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate: {
      return {
        ...state,
        isSaveButtonDisabled: event.isSaveButtonDisabled,
      };
    }

    case UI_BUILDER_EVENTS.toggleDevMode: {
      return {
        ...state,
        devModeEnabled: !state.devModeEnabled,
      };
    }

    case UI_BUILDER_EVENTS.updateRightPanel: {
      return { ...state, rightPanel: { ...state.rightPanel, ...event.rightPanel } };
    }

    case UI_BUILDER_EVENTS.modeRequestPreview: {
      // must be idempotent, so the state is not needlessly updated
      if (state.mode === UI_BUILDER_MODES.preview) {
        return state;
      }

      return { ...state, mode: UI_BUILDER_MODES.preview };
    }

    case UI_BUILDER_EVENTS.modeRequestConstruction: {
      // must be idempotent, so the state is not needlessly updated
      if (state.mode === UI_BUILDER_MODES.construction) {
        return state;
      }

      return { ...state, mode: UI_BUILDER_MODES.construction };
    }

    case UI_BUILDER_EVENTS.toggleAssetViewMode: {
      if (state.assetViewMode === ASSET_VIEW_MODES.grid) {
        return { ...state, assetViewMode: ASSET_VIEW_MODES.list };
      }

      return { ...state, assetViewMode: ASSET_VIEW_MODES.grid };
    }

    case UI_BUILDER_EVENTS.updateLeftPanel: {
      return { ...state, leftPanel: event.leftPanel };
    }

    case UI_BUILDER_EVENTS.notificationSend: {
      const key = event?.notification?.options?.key || new Date().getTime() + Math.random();

      return {
        ...state,
        notifications: [...state.notifications, { ...event.notification, key }],
      };
    }

    case UI_BUILDER_EVENTS.errorAppCrashedNotify: {
      const key = event?.key || new Date().getTime() + Math.random();
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key,
            message: event.errorMessage || 'Something went wrong',
            options: {
              key,
              variant: 'error',
            },
          },
        ],
      };
    }

    case UI_BUILDER_EVENTS.errorAppNotify: {
      const key = event?.key || new Date().getTime() + Math.random();
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key,
            message: event.errorMessage || 'Something went wrong',
            options: {
              key,
              variant: 'error',
            },
          },
        ],
      };
    }

    case UI_BUILDER_EVENTS.appEngineAuditNotify: {
      return {
        ...state,
        audit: event.notifications,
      };
    }

    case UI_BUILDER_EVENTS.successAppNotify: {
      const key = event?.key || new Date().getTime() + Math.random();
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key,
            message: event.successMessage,
            functionData: event.functionData,
            options: {
              key,
              variant: 'success',
            },
            action: event?.action || undefined,
          },
        ],
      };
    }

    case UI_BUILDER_EVENTS.successDeploy: {
      const key =
        event?.notification.key !== ''
          ? event?.notification.key
          : new Date().getTime() + Math.random();
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key,
            title: MESSAGES.deploy.successDeploymentTitle,
            message: event.notification.message,
            actionTitle: 'View',
            actionCallback: event.notification.actionCallback,
            options: {
              key,
              variant: 'success',
            },
          },
        ],
      };
    }

    case UI_BUILDER_EVENTS.failDeploy: {
      const key =
        event?.notification.key !== ''
          ? event?.notification.key
          : new Date().getTime() + Math.random();
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key,
            title: MESSAGES.deploy.failDeploymentTitle,
            message: event.notification.message,
            options: {
              key,
              variant: 'error',
            },
          },
        ],
      };
    }

    case UI_BUILDER_EVENTS.closeSnackbar:
      if (event?.key === 'multipleError') {
        return {
          ...state,
          notifications: state.notifications.map(notification =>
            notification.options?.variant === 'error'
              ? { ...notification, dismissed: true }
              : { ...notification },
          ),
        };
      }

      return {
        ...state,
        notifications: state.notifications.map(notification =>
          !event?.key || notification.key === event.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case UI_BUILDER_EVENTS.removeNotification: {
      return {
        ...state,
        notifications: state.notifications.filter(notification => {
          if (event.key === 'multipleError' || notification.options?.variant === 'error') {
            return false;
          }

          return notification.key !== event.key;
        }),
      };
    }

    case UI_BUILDER_EVENTS.setEditModeToLayout: {
      return {
        ...state,
        editMode: UI_BUILDER_EDIT_MODES.layout,
      };
    }

    case UI_BUILDER_EVENTS.setEditModeToPage: {
      return {
        ...state,
        editMode: UI_BUILDER_EDIT_MODES.page,
      };
    }

    case UI_BUILDER_EVENTS.setSelectedRoute: {
      return {
        ...state,
        leftPanel: { ...state.leftPanel, currentRoute: event.leftPanel.currentRoute },
      };
    }

    case UI_BUILDER_EVENTS.setCurrentLayoutNodesIds: {
      const { layoutNodesIds } = event;
      return {
        ...state,
        layoutNodesIds,
      };
    }

    case UI_BUILDER_EVENTS.designScopeInit: {
      const usersSessions = Object.keys(event.states).reduce((acc, sessionId) => {
        const sessionAlreadyRegisteredByEmail = Object.values(state.usersSessions).find(
          (session: BroadcastStatePayload) => session.email === event.states[sessionId].email,
        );

        return {
          ...acc,
          [sessionId]: {
            ...event.states[sessionId],
            color: sessionAlreadyRegisteredByEmail?.color || getRandomColor(),
          },
        };
      }, {});

      return {
        ...state,
        usersSessions,
      };
    }

    case UI_BUILDER_EVENTS.designScopeUpdateSession: {
      return {
        ...state,
        usersSessions: {
          ...state.usersSessions,
          [event.payload.sessionId]: {
            ...event.payload,
            color: state.usersSessions[event.payload.sessionId]?.color || getRandomColor(),
          },
        },
      };
    }

    case UI_BUILDER_EVENTS.setVisiblePresentation: {
      return {
        ...state,
        visibleNodePresentation: {
          ...state.visibleNodePresentation,
          [event.payload.nodeID]: event.payload.isVisible,
        },
      };
    }

    case UI_BUILDER_EVENTS.setIssueManagerVisibility: {
      return {
        ...state,
        isOpenIssueManager: event.payload.isVisible,
      };
    }
  }

  return state;
};
