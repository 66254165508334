/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useMemo, useEffect } from 'react';
import { equals } from 'ramda';
import { usePrevious } from './usePrevious';
export const useEffectDeep = (callback, dependencies) => {
    const updateRef = useRef(0);
    const prevDependencies = usePrevious(dependencies);
    if (!equals(dependencies, prevDependencies)) {
        updateRef.current += 1;
    }
    useEffect(callback, useMemo(() => dependencies, [updateRef.current]));
};
