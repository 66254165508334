import { gql } from '@apollo/client';

export const WORKSPACE_FRAGMENT = gql`
  fragment SystemWorkspace on SystemWorkspaceItem {
    id
    name
    description
    image {
      downloadUrl
      id
    }
    project {
      id
    }
    isOwner
  }
`;

export const FRONTEND_WORKSPACES_LIST_QUERY = gql`
  query WorkspacesList {
    system {
      workspacesFrontendList {
        items {
          ...SystemWorkspace
        }
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
`;

export const BACKEND_WORKSPACES_LIST_QUERY = gql`
  query AvailableWorkspacesList {
    system {
      workspacesList {
        items {
          id
          name
          apiHost
          project {
            id
            name
            image {
              downloadUrl
              id
            }
          }
        }
      }
    }
  }
`;

export const WORKSPACE_UPDATE_MUTATION = gql`
  mutation WorkspaceUpdate($data: SystemWorkspaceUpdateMutationInput!) {
    system {
      workspaceUpdate(data: $data) {
        id
        name
        description
      }
    }
  }
`;

export const INTROSPECTION_URL_QUERY = gql`
  query IntrospectionUrl {
    system {
      introspection {
        url
      }
    }
  }
`;

export const DEV_MODE_QUERY = gql`
  query DevMode($workspaceId: String!) {
    appbuilder {
      devMode(workspaceId: $workspaceId)
    }
  }
`;

export const ASYNC_SESSION_STATUS_QUERY = gql`
  query AsyncSessionStatus($sessionId: String!) {
    system {
      asyncSessionStatus(sessionId: $sessionId) {
        status
        result
      }
    }
  }
`;
