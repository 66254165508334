const PREDEFINED_COLORS = [
  '#0B77F7',
  '#14E1D4',
  '#9BA4F9',
  '#A43BF7',
  '#A6EA15',
  '#C10606',
  '#F945C7',
  '#FB7C1F',
  '#FF4444',
  '#FFD236',
];

/**
 * @returns string a random HEX color
 */
export const getRandomColor = (): string =>
  PREDEFINED_COLORS[Math.floor(Math.random() * PREDEFINED_COLORS.length)];
