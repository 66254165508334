import { assetListSelectors } from '@builder/schemas';
import { AssetGeneratorFactory } from './AssetGeneratorFactory';
export class AssetListImportsGenerator {
    constructor(appDSL, assetBackendList) {
        this.appDSL = appDSL;
        this.assetBackendList = assetBackendList;
        const assetListDSL = this.appDSL.assets || {};
        const assetFileArrayDSL = assetListSelectors.getAssetFileWithBackendDataArray(assetListDSL, {
            assetBackendArray: Object.values(this.assetBackendList),
        });
        this.assetGenerators = assetFileArrayDSL.map(assetFileDSL => AssetGeneratorFactory.create(assetFileDSL));
    }
    getImportDataList() {
        return this.assetGenerators.map(assetGenerator => assetGenerator.getImportData());
    }
}
