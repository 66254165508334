import { useCallback } from 'react';

import { NodeID } from '@builder/schemas';

import {
  useAppDispatch,
  useNodeListDSL,
  useUIBuilderPresentationStates,
} from 'src/providers/ReduxProvider';
import { UI_BUILDER_EVENTS } from 'src/store';

type UseVisiblePresentationReturns = {
  isVisible: boolean;
  canVisiblePresentationBeApplied: boolean;

  toggleVisiblePresentation: () => void;
};

export const useVisiblePresentation = ({
  nodeID,
}: {
  nodeID?: NodeID;
}): UseVisiblePresentationReturns => {
  const isVisible = useUIBuilderPresentationStates()[nodeID || ''];
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();

  let canVisiblePresentationBeApplied = false;
  if (nodeID && nodeListDSL[nodeID]) {
    const nodeDSL = nodeListDSL[nodeID];
    const isDialog = nodeDSL.name === 'LocalDialogSymbol' || nodeDSL.name === 'DialogSymbol';
    const hasCondition = Boolean(nodeDSL.condition?.showIf);
    canVisiblePresentationBeApplied = hasCondition || isDialog;
  }

  const toggleVisiblePresentation = useCallback(() => {
    if (!nodeID) {
      return;
    }

    send({
      type: UI_BUILDER_EVENTS.setVisiblePresentation,
      payload: {
        isVisible: !isVisible,
        nodeID,
      },
    });
  }, [nodeID, isVisible, send]);

  return {
    isVisible,
    canVisiblePresentationBeApplied,
    toggleVisiblePresentation,
  };
};
