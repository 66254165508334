import * as FullStory from '@fullstory/browser';

import { serialize } from '@builder/utils';

import { AUTH_KEY } from 'src/shared/constants';

type AuthSession = {
  email: string;
};

const localStorageAuthSession = localStorage.getItem(AUTH_KEY);
const previousSession = localStorageAuthSession
  ? serialize.parse<AuthSession>(localStorageAuthSession)
  : undefined;
const email = previousSession?.email;
const timestamp = new Date().toISOString();
const envOptions = {
  email,
  timestamp,
  planName: '',
};

export const handleSendFullStoryEvent = (
  eventType: string,
  options?: Record<string, unknown>,
): void =>
  FullStory.event(eventType, {
    ...envOptions,
    ...options,
  });
