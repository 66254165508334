import React, { useCallback, useMemo } from 'react';

import { Clear as ClearIcon } from '@mui/icons-material';
import { ChipTypeMap, Grid } from '@mui/material';

import {
  ComponentSettingStateDSL,
  COMPONENT_DSL_NAMES,
  nodeListSelectors,
  stateListSelectors,
} from '@builder/schemas';
import { isNil, isNilOrEmptyArray, memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { Chip, Autocomplete, AutocompleteOption } from 'src/shared/components';
import { useDashboardRouter } from 'src/shared/hooks';

type StateTypeProps = {
  setting: ComponentSettingStateDSL;
  'data-test'?: string;
};

export const StateType = memo(
  'StateType',
  ({ setting, 'data-test': dataTest }: StateTypeProps): JSX.Element => {
    const { selectedNodeDSL, appDSL, onUpdateLocalStatesConnections } = useNodeSettingsProps();
    const stateArrayDSL = stateListSelectors.getNotConnectedLocalStateArrayDSL(appDSL.states, {
      nodeListDSL: appDSL.nodes,
      nodeDSL: selectedNodeDSL,
    });
    const dashboardRouterState = useDashboardRouter();
    const currentRouteContentNodeDSL = nodeListSelectors.getCurrentRouteNodeDSL(appDSL.nodes, {
      currentPathname: dashboardRouterState.currentRoute,
    });

    const nodeStates = useMemo(() => selectedNodeDSL.states || [], [selectedNodeDSL.states]);
    const selectedStateIDs = nodeStates.map(stateConnection => stateConnection.stateID);
    const onChangeState = useCallback(
      (value?: string[]) => {
        if (!value) return;

        if (isNilOrEmptyArray(value)) {
          const requiredStates = nodeStates.filter(nodeState => !isNil(nodeState.componentBoundID));
          return onUpdateLocalStatesConnections(requiredStates, selectedNodeDSL.id);
        }

        const [removedStateID] = selectedStateIDs.filter(
          selectedStateID => !value.includes(selectedStateID),
        );
        if (!isNil(removedStateID)) {
          const [removedState] = nodeStates.filter(
            nodeState => nodeState.stateID === removedStateID,
          );

          if (!isNil(removedState.componentBoundID)) return;
        }

        const newNodeStatesConnection = value.map(id => {
          const existedStateConnection = selectedNodeDSL.states?.find(
            ({ stateID }) => stateID === id,
          );

          if (existedStateConnection) return existedStateConnection;

          return {
            stateID: id,
          };
        });

        selectedNodeDSL.parentID &&
          selectedNodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout &&
          onUpdateLocalStatesConnections(newNodeStatesConnection, selectedNodeDSL.parentID);

        onUpdateLocalStatesConnections(newNodeStatesConnection, selectedNodeDSL.id);
      },
      [
        onUpdateLocalStatesConnections,
        selectedNodeDSL.id,
        selectedNodeDSL.states,
        selectedNodeDSL.name,
        selectedNodeDSL.parentID,
        nodeStates,
        selectedStateIDs,
      ],
    );
    const stateListOptions = stateArrayDSL
      .filter(state => {
        if (state.parent === currentRouteContentNodeDSL.id) return true;

        return false;
      })
      .map(({ id, name }) => ({ value: id, label: name }));

    const checkOptionRequired = useCallback(
      (option: AutocompleteOption<string>) => {
        const stateAssociatedWithOption = selectedNodeDSL.states?.find(
          ({ stateID }) => stateID === option.value,
        );

        if (!stateAssociatedWithOption) return false;

        return stateAssociatedWithOption.required;
      },
      [selectedNodeDSL.states],
    );

    return (
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          multiple
          value={selectedStateIDs}
          label={setting.label}
          renderTags={(tagValue: unknown, getTagProps: unknown) =>
            (tagValue as Array<AutocompleteOption<string>>).map((option, index) => (
              <Chip
                key={option.label}
                label={option.label}
                deleteIcon={<ClearIcon />}
                {...(getTagProps as (obj: { index: number }) => Pick<ChipTypeMap, 'props'>)({
                  index,
                })}
                disabled={checkOptionRequired(option as AutocompleteOption<string>)}
              />
            ))
          }
          onChange={onChangeState}
          options={stateListOptions}
          data-test={dataTest}
        />
      </Grid>
    );
  },
);
