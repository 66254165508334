import { without } from 'ramda';

import { AppDSL, AssetDSL, assetListSelectors, assetSelectors } from '@builder/schemas';

export const updateAsset = ({
  appDSL,
  assetDSL,
}: {
  appDSL: AppDSL;
  assetDSL: AssetDSL;
}): AppDSL => {
  const assetListDSL = appDSL.assets || {};
  const targetAssetDSL = assetListSelectors.getAssetDSL(assetListDSL, {
    assetID: assetDSL.id,
  });

  if (targetAssetDSL.parentID === assetDSL.parentID) {
    return {
      ...appDSL,
      assets: {
        ...assetListDSL,
        [assetDSL.id]: assetDSL,
      },
    };
  }

  const previousParentID = assetSelectors.getAssetParentID(targetAssetDSL);
  const previousParent = assetListSelectors.getAssetFolderDSL(assetListDSL, {
    assetID: previousParentID,
  });
  const newParentID = assetSelectors.getAssetParentID(assetDSL);
  const newParent = assetListSelectors.getAssetFolderDSL(assetListDSL, {
    assetID: newParentID,
  });

  return {
    ...appDSL,
    assets: {
      ...assetListDSL,
      [previousParent.id]: {
        ...previousParent,
        children: without([assetDSL.id], previousParent.children),
      },
      [newParent.id]: {
        ...newParent,
        children: [...newParent.children, assetDSL.id],
      },
      [assetDSL.id]: assetDSL,
    },
  };
};
