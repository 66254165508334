import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { commonComponentProps } from '../../common/commonProps';
import { DIV_SETTINGS } from './div.settings';
export const div = {
    name: COMPONENT_DSL_NAMES.div,
    displayName: 'Section (Block)',
    icon: 'sectionBlock',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'div',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: DIV_SETTINGS,
};
