import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsBoxSettings } from './BuilderComponentsBox.settings';
export const builderComponentsBoxProps = {
    /** Display */
    display: { type: COMPONENT_DSL_PROP_TYPES.string },
    overflow: { type: COMPONENT_DSL_PROP_TYPES.string },
    textOverflow: { type: COMPONENT_DSL_PROP_TYPES.string },
    visibility: { type: COMPONENT_DSL_PROP_TYPES.string },
    whiteSpace: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Flex */
    flexDirection: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexWrap: { type: COMPONENT_DSL_PROP_TYPES.string },
    justifyContent: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignItems: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignContent: { type: COMPONENT_DSL_PROP_TYPES.string },
    order: { type: COMPONENT_DSL_PROP_TYPES.string },
    flex: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexGrow: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexShrink: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignSelf: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Palette */
    color: { type: COMPONENT_DSL_PROP_TYPES.string },
    backgroundColor: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Component Settings */
    htmlElement: { type: COMPONENT_DSL_PROP_TYPES.string },
};
export const BuilderComponentsBox = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
    displayName: 'Container',
    icon: 'container',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Box',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign(Object.assign({}, commonComponentProps), builderComponentsBoxProps), { style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsBoxSettings,
};
