import { useMemo } from 'react';

import { OverlayPresentationProps, OverlayState } from '../common/types';
import { useTheme } from 'src/providers/ThemeProvider';
import { MOVE_VARIANT } from 'src/store';

type FinalPresentationProps = OverlayPresentationProps & {
  controls:
    | { type: 'hide' }
    | {
        type: 'show';
        kind: 'full' | 'simple';
        isValid: boolean;
        isHover?: boolean;
        highlight?: boolean;
      };
};

export const useComponentOverlayPresentation = ({
  overlayState,
  isShowingBorder,
}: {
  overlayState: OverlayState;
  isShowingBorder: boolean;
}): FinalPresentationProps => {
  const theme = useTheme();
  const finalPresentationProps = useMemo<FinalPresentationProps>(() => {
    const none = '';
    const backgroundDisabled = 'rgba(0, 0, 0, 0.15)';
    const backgroundSource = 'rgba(255, 255, 255, 0.7)';
    const validShadowColor = theme.palette.primary.main;
    const selectedBorderColor = theme.palette.primary.main;
    const invalidShadowColor = theme.palette.error.main;
    const invalidShadowColorLayout = theme.palette.error.main;
    const borderHidden = `1px dashed transparent`;
    const borderShown = overlayState.isFocused
      ? `1px solid hotpink`
      : `1px dashed ${theme.palette.border.input}`;
    const borderConditional = isShowingBorder ? borderShown : borderHidden;
    const borderShownOnHover = `1px solid ${theme.palette.primary.light}`;
    const highlight = overlayState.isFocused;

    switch (overlayState.type) {
      case 'dnd-source': {
        return {
          border: `1px dashed silver`,
          boxShadow: none,
          background: backgroundSource,
          controls: { type: 'hide' },
        };
      }

      case 'dnd-target-valid': {
        switch (overlayState.kind) {
          case MOVE_VARIANT.before:
            return {
              border: borderConditional,
              boxShadow: `0 4px 0 0 inset ${validShadowColor}`,
              background: none,
              controls: { type: 'hide' },
            };

          case MOVE_VARIANT.after:
            return {
              border: borderConditional,
              boxShadow: `0 -4px 0 0 inset ${validShadowColor}`,
              background: none,
              controls: { type: 'hide' },
            };

          default:
          case MOVE_VARIANT.into:
            return {
              border: borderHidden,
              boxShadow: `0 0 0 4px inset ${validShadowColor}`,
              background: none,
              controls: { type: 'show', kind: 'simple', isValid: true, isHover: true, highlight },
            };
        }
      }

      case 'dnd-target-invalid': {
        switch (overlayState.kind) {
          case MOVE_VARIANT.before:
            return {
              border: borderConditional,
              boxShadow: `0 4px 0 0 inset ${invalidShadowColor}`,
              background: backgroundDisabled,
              controls: { type: 'hide' },
            };

          case MOVE_VARIANT.after:
            return {
              border: borderConditional,
              boxShadow: `0 -4px 0 0 inset ${invalidShadowColor}`,
              background: backgroundDisabled,
              controls: { type: 'hide' },
            };

          case MOVE_VARIANT.into:
          default:
            return {
              border: borderHidden,
              boxShadow: `0 0 0 4px inset ${invalidShadowColor}`,
              background: backgroundDisabled,
              controls: { type: 'show', kind: 'simple', isValid: false, isHover: true, highlight },
            };
        }
      }

      case 'dnd-target-invalid-layout': {
        switch (overlayState.kind) {
          case MOVE_VARIANT.before:
            return {
              border: borderConditional,
              boxShadow: `0 4px 0 0 inset ${invalidShadowColorLayout}`,
              background: backgroundDisabled,
              controls: { type: 'hide' },
            };

          case MOVE_VARIANT.after:
            return {
              border: borderConditional,
              boxShadow: `0 -4px 0 0 inset ${invalidShadowColorLayout}`,
              background: backgroundDisabled,
              controls: { type: 'hide' },
            };

          case MOVE_VARIANT.into:
          default:
            return {
              border: borderHidden,
              boxShadow: `0 0 0 4px inset ${invalidShadowColorLayout}`,
              background: backgroundDisabled,
              controls: { type: 'show', kind: 'simple', isValid: false, isHover: true, highlight },
            };
        }
      }

      case 'dnd-target-valid-container':
        return {
          border: borderHidden,
          boxShadow: `0 0 0 2px inset ${validShadowColor}`,
          background: none,
          controls: { type: 'show', kind: 'simple', isValid: true, isHover: true, highlight },
        };

      case 'dnd-target-invalid-container':
        return {
          border: borderHidden,
          boxShadow: `0 0 0 2px inset ${invalidShadowColor}`,
          background: backgroundDisabled,
          controls: { type: 'show', kind: 'simple', isValid: false, isHover: true, highlight },
        };

      case 'dnd-target-invalid-container-layout':
        return {
          border: borderHidden,
          boxShadow: `0 0 0 2px inset ${invalidShadowColorLayout}`,
          background: backgroundDisabled,
          controls: { type: 'show', kind: 'simple', isValid: false, isHover: true, highlight },
        };

      case 'selected-idle':
        return {
          border: `1px solid ${selectedBorderColor}`,
          background: none,
          boxShadow: 'none',
          controls: { type: 'show', kind: highlight ? 'simple' : 'full', isValid: true, highlight },
        };

      case 'hovered':
        return {
          border: borderShownOnHover,
          boxShadow: none,
          background: none,
          controls: { type: 'show', kind: 'simple', isValid: true, isHover: true, highlight },
        };

      case 'idle':
      default:
        return {
          border: borderConditional,
          boxShadow: none,
          background: none,
          controls: overlayState.isFocused
            ? { type: 'show', kind: 'simple', isValid: true, isHover: true, highlight }
            : { type: 'hide' },
        };
    }
  }, [isShowingBorder, overlayState, theme]);

  return finalPresentationProps;
};
