import { Formik, FormikConfig, FormikProps } from 'formik';

export type FormProps<
  FormValues extends Record<string, unknown>,
  InitialFormValues = Partial<FormValues>
> = Omit<FormikConfig<FormValues>, 'initialValues'> & {
  initialValues: InitialFormValues;
};

export type FormRenderProps<FormValues extends Record<string, unknown>> = FormikProps<FormValues>;

export const Form = <
  FormValues extends Record<string, unknown>,
  InitialFormValues = Partial<FormValues>
>(
  props: FormProps<FormValues, InitialFormValues>,
): JSX.Element => {
  // Formik typings doesn't resolve case with initial values so needs to override it.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Formik {...props} />;
};
