import { useState, useEffect, useCallback } from 'react';
import { usePrevious } from './usePrevious';
export const useDelayedLoadingState = ({ loadingState, actionCallback, }) => {
    const [delayedLoading, setDelayedLoading] = useState(false);
    const previousDelayedLoading = usePrevious(delayedLoading);
    // This function should run actionCallback if loadingState equals to false
    // Or set up delayedLoading value to true if the loadingState equals true
    // All the other logic ivolved in the hooks below
    const delayedActionCallback = useCallback(() => {
        if (loadingState) {
            setDelayedLoading(loadingState);
        }
        else {
            actionCallback();
        }
    }, [actionCallback, loadingState]);
    // When loadingState was changed to false we return delayedLoading to false value as well
    useEffect(() => {
        if (delayedLoading && !loadingState) {
            setDelayedLoading(false);
        }
    }, [loadingState, delayedLoading]);
    // Run actionCallback after the delayedLoading was changed back to false
    useEffect(() => {
        if (previousDelayedLoading && !delayedLoading) {
            actionCallback();
        }
    }, [actionCallback, previousDelayedLoading, delayedLoading]);
    return [delayedLoading, delayedActionCallback];
};
