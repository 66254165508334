import { COMPONENT_DSL_NAMES } from '../../constants';
import { nodeListSelectors } from '../../selectors';
export const isLocalDialogComponent = (nodeID, nodeListDSL) => {
    const currentNode = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
    if (currentNode.name === COMPONENT_DSL_NAMES.LocalDialogSymbol) {
        return currentNode.id;
    }
    if (currentNode.parentID) {
        const parentNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
            nodeID: currentNode.parentID,
        });
        if (parentNodeDSL) {
            return isLocalDialogComponent(parentNodeDSL.id, nodeListDSL);
        }
    }
};
export const isDialogComponent = (nodeID, nodeListDSL) => {
    const currentNode = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
    if (currentNode.name === COMPONENT_DSL_NAMES.DialogSymbol) {
        return currentNode.id;
    }
    if (currentNode.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
        currentNode.name === COMPONENT_DSL_NAMES.root) {
        return;
    }
    if (currentNode.parentID) {
        const parentNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
            nodeID: currentNode.parentID,
        });
        if (parentNodeDSL) {
            return isDialogComponent(parentNodeDSL.id, nodeListDSL);
        }
    }
};
