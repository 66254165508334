import { isEmpty } from 'ramda';
const GOOGLE_FONTS_API_BASE_URL = 'https://fonts.googleapis.com/css2';
const FONT_ITALIC_REGEX = /italic/;
const FONT_VALID_VARIANT_REGEX = /^(regular|italic|\d{2,4}(italic)?)$/;
export class GoogleFontsApiLinksGenerator {
    /** @example 'Roboto Condensed' -> 'Roboto+Condensed' */
    static formatFontFamily(fontFamily) {
        return (fontFamily === null || fontFamily === void 0 ? void 0 : fontFamily.replaceAll(' ', '+')) || 'Roboto+Condensed';
    }
    /**
     * @description turns Google Font Variants array into string
     * which specifies styles/weights for requested font in the format required by Google Fonts API
     * @see {@link https://developers.google.com/fonts/docs/css2#forming_api_urls} for more information on formatting rules
     * @param variants - list of font variants selected by user @example ['regular', 'italic', '700', '700italic']
     * @returns formatted string with selected font variants specified according to google fonts api requirements
     * @example ['regular', 'italic', '700', '700italic'] -> 'ital,wght@0,400;1,400;0,700;1,700'
     */
    static formatFontVariants(variants) {
        const formattedVariants = variants
            .filter(variant => FONT_VALID_VARIANT_REGEX.test(variant))
            .map((variant) => {
            const styleAndWeightQualifiers = [];
            styleAndWeightQualifiers[0] = FONT_ITALIC_REGEX.test(variant) ? 1 : 0;
            styleAndWeightQualifiers[1] = Number.parseInt(variant, 10) || 400;
            return styleAndWeightQualifiers;
        })
            .sort()
            .map(qualifiers => qualifiers.join(','));
        if (isEmpty(formattedVariants))
            return '';
        return `ital,wght@${formattedVariants.join(';')}`;
    }
    /**
     * @description assembles the final link to Google Fonts API
     * format: <google-fonts-api-base>?<font-description>[,&<font-description>]&display=swap
     * @example 'https://fonts.googleapis.com/css2?Roboto:ital,wght@0,400;0,700&Roboto+Condensed:ital,wght@0,400&display=swap'
     */
    static generateDownloadLink(googleFontListDSL) {
        const requestedGoogleFonts = Object.values(googleFontListDSL)
            .map(googleFontDSL => {
            const fontFamily = GoogleFontsApiLinksGenerator.formatFontFamily(googleFontDSL.fontFamily);
            const fontVariants = GoogleFontsApiLinksGenerator.formatFontVariants(googleFontDSL.variants);
            if (isEmpty(fontVariants))
                return '';
            return `family=${fontFamily}:${fontVariants}`;
        })
            .filter(Boolean);
        if (isEmpty(requestedGoogleFonts))
            return '';
        return `${GOOGLE_FONTS_API_BASE_URL}?${requestedGoogleFonts.join('&')}&display=swap`;
    }
    static getApiLinks(googleFontListDSL = {}) {
        const googleFontsLink = GoogleFontsApiLinksGenerator.generateDownloadLink(googleFontListDSL);
        return googleFontsLink
            ? `
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="stylesheet" type="text/css" href="${googleFontsLink}">`
            : '';
    }
}
