import React from 'react';
import { equals, omit, pick } from 'ramda';
export const MEMO_MODE_TYPES = {
    deep: 'deep',
    deepSelective: 'deep-selective',
    shallow: 'shallow',
    react: 'react',
};
const propsAreEqual = ({ prevProps, nextProps, skipCheckingFor, }) => {
    // all keys have to be collected and distinctly merged before scanning
    const keys = new Set([...Object.keys(prevProps), ...Object.keys(nextProps)]);
    for (const key of keys) {
        if (skipCheckingFor.includes(key)) {
            // eslint-disable-next-line no-continue
            continue;
        }
        if (prevProps[key] === nextProps[key]) {
            // eslint-disable-next-line no-continue
            continue;
        }
        return { res: false, key };
    }
    return { res: true };
};
/**
 * Prefer using `reactMemo` or @options - @mode - `MEMO_MODE_TYPES.react`. Should be good enough in general.
 * Though the `MEMO_MODE_TYPES.shallow` is the default one (legacy)!
 *
 * - Deep mode `MEMO_MODE_TYPES.deep` uses ramda' equal and does a deep scan (structural).
 * - Shallow mode `MEMO_MODE_TYPES.shallow` is the same(ish) as `MEMO_MODE_TYPES.react`, but allows for skipping props with @skipCheckingFor .
 * - Both `MEMO_MODE_TYPES.deep` & `MEMO_MODE_TYPES.shallow` allow for prop-skipping.
 *
 * Use with caution: the major difference b/w `useMemo` & `React.memo` is
 * - with `useMemo` the consumer gets to decide how and when the component renders (consumer has control)
 * - with `React.memo` you have decided that for the consumer beforehand (consumer has no or little control)
 * (both could be combined together to achieve the best results for certain use-cases)
 * (the rule of thumb is to memo components going from top-down, and useMemo - from bottom-up)
 *
 * ALSO!!! Try not to use @skipCheckingFor -- since the need for using it indicates
 * your props aren't preserving their identity b/w updates (aren't memoized properly)!
 * Try wrapping props into useMemo/Callback first; use @skipCheckingFor if wrapping props is impossible or even more confusing!
 *
 * @param name forces a name for a component (relevant for react-dev-profiler tool)
 * @param component the target component
 * @param options has a mode selection and a skipper for irrelevant props; the default mode is `MEMO_MODE_TYPES.shallow` (legacy)
 * @returns a memoized (single) component (`MEMO_MODE_TYPES.react`) `|` a memoized HOC (composition of 2 components) (the target component stays the same; relevant for profiling)
 */
export const memo = (name, component, options = {}) => {
    const { skipCheckingFor = [], mode = { type: MEMO_MODE_TYPES.shallow } } = options;
    const displayName = name;
    switch (mode.type) {
        case MEMO_MODE_TYPES.deep: {
            const memoizedComponent = React.memo(component, (prevProps, nextProps) => {
                return equals(omit(skipCheckingFor, prevProps), omit(skipCheckingFor, nextProps));
            });
            try {
                memoizedComponent.displayName = `${displayName} [Memo-Deep]`;
            }
            catch (_a) {
                // symbols do not have displayName
                // can one be set anyway?
            }
            return memoizedComponent;
        }
        case MEMO_MODE_TYPES.deepSelective: {
            const memoizedComponent = React.memo(component, (prevProps, nextProps) => {
                const [pDeep, nDeep] = [pick(mode.checkFor, prevProps), pick(mode.checkFor, nextProps)];
                const [pShallow, nShallow] = [
                    omit(mode.checkFor, prevProps),
                    omit(mode.checkFor, nextProps),
                ];
                return (equals(pDeep, nDeep) &&
                    propsAreEqual({
                        prevProps: pShallow,
                        nextProps: nShallow,
                        skipCheckingFor,
                    }).res);
            });
            try {
                memoizedComponent.displayName = `${displayName} [Memo-Deep-Selective]`;
            }
            catch (_b) {
                // symbols do not have displayName
                // can one be set anyway?
            }
            return memoizedComponent;
        }
        case MEMO_MODE_TYPES.shallow: {
            const memoizedComponent = React.memo(component, (prevProps, nextProps) => {
                return propsAreEqual({ prevProps, nextProps, skipCheckingFor }).res;
            });
            try {
                memoizedComponent.displayName = `${displayName} [Memo-Shallow]`;
            }
            catch (_c) {
                // symbols do not have displayName
                // can one be set anyway?
            }
            return memoizedComponent;
        }
        case MEMO_MODE_TYPES.react:
        default: {
            const memoizedComponent = React.memo(component);
            try {
                memoizedComponent.displayName = `${displayName} [Memo-React]`;
            }
            catch (_d) {
                // symbols do not have displayName
                // can one be set anyway?
            }
            return memoizedComponent;
        }
    }
};
/**
 * Prefer this one over `memo` for general use-case.
 * Uses vanilla react memoization, but still enforces a component name for easier profiling / debugging.
 *
 * Cannot skip props though!
 */
export const reactMemo = (name, component) => memo(name, component, { mode: { type: MEMO_MODE_TYPES.react } });
