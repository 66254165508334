import React, { useCallback, useState, useMemo } from 'react';

import styled from '@emotion/styled';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';
import { ButtonBase, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { isNil } from 'ramda';
import { FormatInputValueFunction, NumberFormatProps } from 'react-number-format';

import { useDebouncedState } from '@builder/utils';

import { TextField, CustomTextFieldProps } from '../TextField';

import { NumberInput } from './NumberInput';

const NumberInputButton = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  height: 10px;

  & :hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &.Mui-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  & svg {
    font-size: 18px;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  display: flex;
  flex-direction: column;
  margin: 0;
  height: auto;

  > button:nth-child(2) {
    margin-top: 4px;
  }
`;

export type NumberFieldProps = {
  // docs: https://github.com/s-yadav/react-number-format
  numberFormat?: NumberFormatProps<unknown>;
  formatMask?: string | FormatInputValueFunction;
  isNumericString?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<CustomTextFieldProps, 'onChange' | 'multiline' | 'enableFx' | 'isFxEnabled'>;

export const NumberField: React.FC<NumberFieldProps> = ({
  value,
  numberFormat,
  formatMask,
  isNumericString = false,
  InputProps,
  skipDebounce = true,
  onDebounceChange: onDebounceChangeProp = () => undefined,
  onChange: onChangeProp,
  ...textFieldProps
}) => {
  const [localValue, setLocalValue] = useState<unknown>(() => value);
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    value as string,
    onDebounceChangeProp,
    {
      skipDebounce,
    },
  );
  const actualValue = skipDebounce ? localValue : debouncedValue;
  const isMaxValue = (valueInput: string | unknown) => Number(valueInput) >= 7680;

  const isInvalidNumberValue = useMemo(() => {
    return isNil(actualValue) || isNaN(Number(actualValue));
  }, [actualValue]);

  const updateValue = useCallback(
    newValue => {
      skipDebounce ? setLocalValue(newValue) : setDebouncedValue(newValue);
    },
    [skipDebounce, setLocalValue, setDebouncedValue],
  );

  const onIncrement = useCallback(() => {
    if (isInvalidNumberValue) {
      return;
    }

    const incrementedValue = isMaxValue(value) ? value : String(Number(value) + 1);
    updateValue(incrementedValue);
  }, [isInvalidNumberValue, value, updateValue]);

  const onDecrement = useCallback(() => {
    if (isInvalidNumberValue) {
      return;
    }

    const decrementedValue = String(Number(value) - 1);
    updateValue(decrementedValue);
  }, [isInvalidNumberValue, value, updateValue]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeProp) {
        onChangeProp(e);
        setLocalValue(e.target.value);
      }
    },
    [onChangeProp, setLocalValue],
  );

  const updateValueByDebounce = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDebouncedValue(event.target.value);
    },
    [setDebouncedValue],
  );

  return (
    <TextField
      value={actualValue}
      variant="outlined"
      onChange={skipDebounce ? onChange : updateValueByDebounce}
      skipDebounce
      {...textFieldProps}
      InputProps={{
        ...InputProps,
        inputComponent: (NumberInput as unknown) as React.FC<InputBaseComponentProps>,
        inputProps: {
          numberFormat,
          isNumericString,
          format: formatMask,
        } as InputBaseComponentProps,
        endAdornment: (
          <StyledInputAdornment position="end">
            <NumberInputButton
              onTouchStart={onIncrement}
              onMouseDown={onIncrement}
              disabled={isInvalidNumberValue}
            >
              <ArrowDropUpIcon />
            </NumberInputButton>
            <NumberInputButton
              onTouchStart={onDecrement}
              onMouseDown={onDecrement}
              disabled={isInvalidNumberValue}
            >
              <ArrowDropDownIcon />
            </NumberInputButton>
          </StyledInputAdornment>
        ),
      }}
    />
  );
};
