import styled from '@emotion/styled';

export const MetaDescriptionText = styled.span<{ textAlign?: string }>`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme }) => theme.palette.grey[400]};
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 13px;
`;
