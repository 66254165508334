import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings } from '../../common';
import { getPropertySections, getStyleSections, getEventsSection, getDivider, } from '../../common/settings';
export const AUDIO_SETTINGS = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.assetEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['src'],
                            label: 'Source',
                            resetPathBeforeOnChange: [
                                ['style', 'width'],
                                ['style', 'height'],
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['autoPlay'],
                            label: 'Autoplay',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['loop'],
                            label: 'Loop',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['muted'],
                            label: 'Muted',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['controls'],
                            label: 'Controls',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['preload'],
                            label: 'Preload',
                            options: ['auto', 'metadata', 'none'].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
