import { hasPropJsCode, transformPropWithJsCode } from '@builder/schemas';
export class IteratorTypesGenerator {
    constructor(iterableNodeListDSL) {
        this.iterableNodeListDSL = iterableNodeListDSL;
    }
    getConnectedData(data) {
        if (Array.isArray(data)) {
            const connectedData = JSON.stringify(data);
            return connectedData;
        }
        if (hasPropJsCode(data)) {
            const connectedData = transformPropWithJsCode(data);
            return connectedData;
        }
        return null;
    }
    getItemType(name, connectedData) {
        const list = `const ${name}List = ${connectedData};\n`;
        const itemType = `type ${name}Type = typeof ${name}List[number];`;
        const item = `declare const ${name}: ${name}Type`;
        return `${list}\n${itemType}\n${item}`;
    }
    generateTypes() {
        const iteratorConnectionList = this.iterableNodeListDSL
            .map(el => {
            if (!el.iterator) {
                return null;
            }
            const { name, data } = el.iterator;
            const connectedData = this.getConnectedData(data);
            if (!connectedData) {
                return null;
            }
            const itemType = this.getItemType(name, connectedData);
            return itemType;
        })
            .filter(Boolean);
        if (iteratorConnectionList.length) {
            return iteratorConnectionList.join('\n');
        }
        return undefined;
    }
}
