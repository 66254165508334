var _a;
import { BuilderComponentsIcon } from '../component-schemas/material-components/BuilderComponentsIcon';
import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import badgeColorsDSL from './style-guide-dsl/BadgeStyleGuide/badgeColors.json';
import badgePositionDSL from './style-guide-dsl/BadgeStyleGuide/badgePosition.json';
import valueDSL from './style-guide-dsl/BadgeStyleGuide/value.json';
const IconComponentObject = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
    props: Object.assign(Object.assign({}, (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { name: 'Mail', color: 'info' }),
};
export const BadgeStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiBadge,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialBadge,
    dirName: 'BadgeStyleGuide',
    title: 'Badge',
    classNames: [
        '.MuiBadge-root',
        '.MuiBadge-badge',
        '.MuiBadge-dot',
        '.MuiBadge-standard',
        '.MuiBadge-anchorOriginTopRight',
        '.MuiBadge-anchorOriginBottomRight',
        '.MuiBadge-anchorOriginTopLeft',
        '.MuiBadge-anchorOriginBottomLeft',
        '.MuiBadge-invisible',
        '.MuiBadge-colorPrimary',
        '.MuiBadge-colorSecondary',
        '.MuiBadge-colorError',
        '.MuiBadge-colorInfo',
        '.MuiBadge-colorSuccess',
        '.MuiBadge-colorWarning',
        '.MuiBadge-anchorOriginTopRightRectangular',
        '.MuiBadge-anchorOriginBottomRightRectangular',
        '.MuiBadge-anchorOriginTopLeftRectangular',
        '.MuiBadge-anchorOriginBottomLeftRectangular',
        '.MuiBadge-anchorOriginTopRightCircular',
        '.MuiBadge-anchorOriginBottomRightCircular',
        '.MuiBadge-anchorOriginTopLeftCircular',
        '.MuiBadge-anchorOriginBottomLeftCircular',
        '.MuiBadge-overlapRectangular',
        '.MuiBadge-overlapCircular',
    ],
    stories: [
        {
            title: 'Badge colors',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialBadge,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'secondary',
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'error',
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'secondary',
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'success',
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'warning',
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: badgeColorsDSL,
        },
        {
            title: 'Badge position',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialBadge,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'secondary',
                        anchorOrigin: { vertical: 'top', horizontal: 'left' },
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'error',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: badgePositionDSL,
        },
        {
            title: 'Value',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialBadge,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                        badgeContent: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: '99',
                                    },
                                },
                            ],
                        },
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'secondary',
                        badgeContent: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: '100',
                                    },
                                },
                            ],
                        },
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
                {
                    props: {
                        color: 'error',
                        badgeContent: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: '1000',
                                    },
                                },
                            ],
                        },
                        style: {
                            marginTop: '10px',
                        },
                        children: {
                            nodes: [IconComponentObject],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: valueDSL,
        },
    ],
};
