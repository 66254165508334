import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { A_SETTINGS } from './a.settings';
export const a = {
    name: COMPONENT_DSL_NAMES.a,
    displayName: 'Hyperlink',
    icon: 'hyperLink',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'a',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { href: { type: COMPONENT_DSL_PROP_TYPES.string }, hreflang: { type: COMPONENT_DSL_PROP_TYPES.enum }, download: { type: COMPONENT_DSL_PROP_TYPES.string }, media: { type: COMPONENT_DSL_PROP_TYPES.string }, rel: { type: COMPONENT_DSL_PROP_TYPES.string }, target: { type: COMPONENT_DSL_PROP_TYPES.enum }, type: { type: COMPONENT_DSL_PROP_TYPES.string }, ping: { type: COMPONENT_DSL_PROP_TYPES.string }, referrerpolicy: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: A_SETTINGS,
};
