var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { SnackbarContent as SnackbarContentMUI, styled } from '@mui/material';
const SnackBarContentStyled = styled(SnackbarContentMUI) `
  .MuiSnackbarContent-message {
    font-family: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.fontFamily; }};
    font-size: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.fontSize; }};
    font-weight: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.fontWeight; }};
    color: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.color; }};
    font-style: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.fontStyle; }};
    text-decoration: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.textDecoration; }};
    text-transform: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.textTransform; }};
    text-align: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.textAlign; }};
    direction: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.direction; }};
  }

  .MuiSnackbarContent-root {
    background-color: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.backgroundColor; }};
    border-radius: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.borderRadius; }};
    border: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.border; }};
    cursor: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.cursor; }};
    height: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.height; }};
    max-height: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.maxHeight; }};
    min-height: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.minHeight; }};
    width: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.height; }};
    max-width: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.maxWidth; }};
    min-width: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.minWidth; }};
    overflow: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.overflow; }};
  }
`;
export const SnackbarContent = forwardRef((_a, ref) => {
    var { children, action, className, role } = _a, rest = __rest(_a, ["children", "action", "className", "role"]);
    return (_jsx(SnackBarContentStyled, Object.assign({ className: className }, rest, { message: rest.message, action: action, role: role, ref: ref })));
});
