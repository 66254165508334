import { omit } from 'ramda';
import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_8_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.8.1';
    }
    getChangeList() {
        return [`Removed "src" property from Card Media`];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.MaterialCardMedia: {
                return Object.assign(Object.assign({}, nodeDSL), { props: omit(['src'], nodeDSL.props) });
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_8_1 = new Migration_0_8_1();
