import { last, equals, not, isNil, path, isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { COMPONENT_SETTING_CONDITION_TYPES } from '../constants';
import { NodeSchemaChecker } from '../validation/helpers/NodeSchemaChecker';
import * as nodeListSelectors from './node-list-selectors';
const getSetting = (setting) => setting;
const getSettingList = (settings) => settings;
const getShowIfConditions = (showIfConditions) => showIfConditions;
const getNodeSetting = (setting) => setting;
const getSourceEntity = (_, props) => props.sourceEntity;
const getNodeListDSL = (_, props) => props.nodeListDSL;
const getPrefixKeyPath = (_, props) => props.prefixKeyPath;
export const getSettingsKeyPath = createSelector(getSetting, (setting) => {
    const { keyPath } = setting;
    return keyPath || [];
});
export const getSettingsNodePath = createSelector(getSetting, (setting) => {
    const { nodePath } = setting;
    return nodePath || [];
});
export const getSettingsKeyNamePath = createSelector(getSettingsKeyPath, keyPath => {
    return keyPath.join('.');
});
export const getSettingsKeyName = createSelector(getSettingsKeyPath, (keyPath) => {
    return last(keyPath) || '';
});
export const getNodeSettingPossibleComponents = createSelector(getNodeSetting, (nodeSetting) => {
    return nodeSetting.items;
});
export const hasMoreThanOnePossibleComponent = createSelector(getNodeSettingPossibleComponents, componentNames => componentNames.length > 1);
export const isRequired = createSelector(getSetting, setting => !!setting.required);
const isParentSetting = createSelector(getSetting, (setting) => {
    return 'children' in setting;
});
const getAllChildrenSettingPaths = createSelector(getSetting, (setting) => {
    const childrenPaths = setting.children.map(childSetting => {
        if (isParentSetting(childSetting)) {
            return getAllChildrenSettingPaths(childSetting);
        }
        return [childSetting.keyPath];
    });
    return childrenPaths.flat();
});
// returns all non empty children props to reset
// setting: { ..., children: [ { ..., keyPath: ['id'] }]}
// sourceEntity: { id: 123 }
// =>
// [{ keyValue: undefined, keyPath: ['id'] }]
export const getChildrenPropsToReset = createSelector(getSetting, getSourceEntity, (setting, sourceEntity) => {
    const childrenPaths = getAllChildrenSettingPaths(setting);
    return childrenPaths
        .filter(childrenPath => path(childrenPath, sourceEntity) !== undefined)
        .map(childrenPath => ({
        keyValue: undefined,
        keyPath: childrenPath,
    }));
});
const getConditionResult = ({ sourceEntity, condition, nodeListDSL, prefixKeyPath, }) => {
    const { type } = condition;
    /*
     * Injecting 'props' should be removed after https://8base-dev.atlassian.net/browse/APB-1302
     */
    const getKeyPath = () => {
        const { keyPath: settingKeyPath = [] } = condition;
        const isNode = NodeSchemaChecker.isNode(sourceEntity);
        const nodeKeyPath = prefixKeyPath && !isEmpty(prefixKeyPath)
            ? ['props', ...prefixKeyPath, ...settingKeyPath]
            : ['props', ...settingKeyPath];
        const propkeyPath = prefixKeyPath && !isEmpty(prefixKeyPath)
            ? [...prefixKeyPath, ...settingKeyPath]
            : settingKeyPath;
        return isNode ? nodeKeyPath : propkeyPath;
    };
    switch (type) {
        case COMPONENT_SETTING_CONDITION_TYPES.equals: {
            const { value: conditionValue } = condition;
            const value = path(getKeyPath(), sourceEntity);
            return equals(value, conditionValue);
        }
        case COMPONENT_SETTING_CONDITION_TYPES.notEquals: {
            const { value: conditionValue } = condition;
            const value = path(getKeyPath(), sourceEntity);
            return not(equals(value, conditionValue));
        }
        case COMPONENT_SETTING_CONDITION_TYPES.isNil: {
            const value = path(getKeyPath(), sourceEntity);
            return isNil(value);
        }
        case COMPONENT_SETTING_CONDITION_TYPES.isNotNil: {
            const value = path(getKeyPath(), sourceEntity);
            return !isNil(value);
        }
        case COMPONENT_SETTING_CONDITION_TYPES.hasAncestors: {
            if (!NodeSchemaChecker.isNode(sourceEntity)) {
                return false;
            }
            const { value: conditionValue } = condition;
            return nodeListSelectors.hasAncestors(nodeListDSL, {
                nodeID: sourceEntity.id,
                ancestorNames: conditionValue,
            });
        }
        case COMPONENT_SETTING_CONDITION_TYPES.isIncluded: {
            const { value: conditionValue } = condition;
            const value = path(getKeyPath(), sourceEntity);
            return conditionValue.includes(value);
        }
        default: {
            return true;
        }
    }
};
export const isShowingSetting = createSelector(getShowIfConditions, getSourceEntity, getNodeListDSL, getPrefixKeyPath, (showIfConditions, sourceEntity, nodeListDSL, prefixKeyPath) => {
    if (!showIfConditions) {
        return true;
    }
    return showIfConditions.every(condition => getConditionResult({ condition, sourceEntity, nodeListDSL, prefixKeyPath }));
});
export const getItemLabelKeyPath = createSelector(getSetting, (setting) => {
    const { itemLabelKeyPath } = setting;
    return itemLabelKeyPath || [];
});
const getFlattenSettingsDSL = createSelector(getSetting, isParentSetting, (setting, isParent) => {
    if (isParent) {
        const relatedSettingList = setting === null || setting === void 0 ? void 0 : setting.children.reduce((acc, childSetting) => {
            if (isParentSetting(childSetting)) {
                const childrenSettings = getFlattenSettingsDSL(childSetting);
                return [...acc, ...childrenSettings];
            }
            return [...acc, childSetting];
        }, []);
        return relatedSettingList;
    }
    return [setting];
});
export const getSettingDSLByRelatedCondition = createSelector(getSetting, (_, relatedSettingPath) => relatedSettingPath, getFlattenSettingsDSL, (_, relatedSettingPath, relatedSettingList) => {
    return relatedSettingList.filter(relatedSetting => {
        var _a;
        return (_a = relatedSetting === null || relatedSetting === void 0 ? void 0 : relatedSetting.showIf) === null || _a === void 0 ? void 0 : _a.some(condition => equals(relatedSettingPath, condition === null || condition === void 0 ? void 0 : condition.keyPath));
    });
});
/**
 * @returns List of settings DSL which is `showIf.keyPath` is equals to `relatedSettingPath`
 */
export const getSettingListDSLByRelatedCondition = createSelector(getSettingList, (_, relatedSettingPath) => relatedSettingPath, (settings, relatedSettingPath) => {
    const relatedSettings = settings.reduce((acc, setting) => {
        const related = getSettingDSLByRelatedCondition(setting, relatedSettingPath);
        return [...acc, ...related];
    }, []);
    return relatedSettings;
});
export const getItemLabelNodePath = createSelector(getSetting, (setting) => {
    const { itemLabelNodePath } = setting;
    return itemLabelNodePath || [];
});
