/* eslint-disable padding-line-between-statements */
import { useEffect } from 'react';
import { usePrevious } from '@builder/utils';
export const useUnsubscribePolling = ({ currentArgs, queryResult, }) => {
    const previousArgs = usePrevious(currentArgs);
    const { startPolling, stopPolling } = queryResult;
    const prevPollInterval = parseInt(previousArgs === null || previousArgs === void 0 ? void 0 : previousArgs.pollInterval, 10);
    const currentPollInterval = parseInt(currentArgs === null || currentArgs === void 0 ? void 0 : currentArgs.pollInterval, 10);
    useEffect(() => {
        if (currentPollInterval && currentPollInterval !== prevPollInterval) {
            startPolling(currentPollInterval);
            return () => stopPolling();
        }
    }, [prevPollInterval, currentPollInterval, startPolling, stopPolling]);
};
