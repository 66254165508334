import compareVersions, { compare } from 'compare-versions';
import { equals, groupWith, last } from 'ramda';
import { SCHEMA_DSL_VERSION } from '@builder/schemas';
import { isArray, isNull } from '@builder/utils';
import { MIGRATION_TYPES } from './constants';
import { createErrorMigrationResult, createSuccessMigrationResult } from './createMigrationResult';
import * as migrations from './migrations';
export class MigrationList {
    constructor(possibleMigrations) {
        this.sortedMigrations = Object.values(possibleMigrations || migrations).sort((aMigration, bMigration) => {
            return compareVersions(aMigration.toVersion, bMigration.toVersion);
        });
    }
    getVersionDuplicate() {
        const allVersions = this.sortedMigrations.map(migration => migration.toVersion);
        // groupedByDuplicatesVersions = [[0.1.0], [0.2.0, 0.2.0], [0.3.0]]
        const groupedByDuplicatesVersions = groupWith(equals, allVersions);
        for (const groupOfVersion of groupedByDuplicatesVersions) {
            // if found two versions with the same number
            if (groupOfVersion.length > 1) {
                return groupOfVersion[0];
            }
        }
        return null;
    }
    getAllNextMigrations(appConfiguration) {
        if (!isNull(this.getVersionDuplicate())) {
            return createErrorMigrationResult({
                message: `Was found two migrations with the same version ${this.getVersionDuplicate()}.`,
            });
        }
        const nextMigrations = this.sortedMigrations.filter(migration => compare(migration.toVersion, appConfiguration.schemaDSLVersion, '>'));
        if (nextMigrations.length > 0) {
            return nextMigrations;
        }
        return createErrorMigrationResult({
            message: `Can't migrate to ${SCHEMA_DSL_VERSION}. Appropriate migration not found. Please, contact us by Slack.`,
        });
    }
    applyAllMigrations(appConfiguration) {
        const nextMigrations = this.getAllNextMigrations(appConfiguration);
        if (!isArray(nextMigrations)) {
            return nextMigrations;
        }
        let currentAppConfiguration = appConfiguration;
        const migrationsResult = [];
        for (const migration of nextMigrations) {
            const migrationResult = migration.migrate(currentAppConfiguration);
            if (migrationResult.type === MIGRATION_TYPES.success) {
                const { appConfiguration: nextAppConfiguration } = migrationResult;
                currentAppConfiguration = nextAppConfiguration;
                migrationsResult.push(migrationResult);
            }
            else {
                return migrationResult;
            }
        }
        const lastMigrationResult = last(migrationsResult);
        const mergedChangeList = migrationsResult
            .map(migrationResult => migrationResult.info.changeList)
            .flat();
        return createSuccessMigrationResult(lastMigrationResult.appConfiguration, {
            fromVersion: appConfiguration.schemaDSLVersion,
            toVersion: lastMigrationResult.appConfiguration.schemaDSLVersion,
            changeList: mergedChangeList,
        });
    }
    getNextMigration(appConfiguration) {
        if (!isNull(this.getVersionDuplicate())) {
            return createErrorMigrationResult({
                message: `Was found two migrations with the same version ${this.getVersionDuplicate()}.`,
            });
        }
        for (const migration of this.sortedMigrations) {
            if (compare(migration.toVersion, appConfiguration.schemaDSLVersion, '>')) {
                return migration;
            }
        }
        return createErrorMigrationResult({
            message: `Can't migrate to ${SCHEMA_DSL_VERSION}. Appropriate migration not found. Please, contact us by Slack.`,
        });
    }
    /**
     * @returns True if app version less than expected
     */
    needsToMigrate(appConfiguration) {
        return compare(SCHEMA_DSL_VERSION, appConfiguration.schemaDSLVersion, '>');
    }
    /**
     * @returns True if app version more than expected
     * It can be if someone migrates application on prestaging and opens the same app on the staging
     */
    isDSLVersionMoreThanExpected(appConfiguration) {
        return compare(SCHEMA_DSL_VERSION, appConfiguration.schemaDSLVersion, '<');
    }
    getSchemaDSLVersion() {
        return SCHEMA_DSL_VERSION;
    }
    /**
     * @param {string} firstVersion is the first version to compare.
     * @param {string} secondVersion is the second version to compare.
     * @returns {boolean} True if the `firstVersion` is greater than `secondVersion`.
     */
    isDSLVersionGreater(firstVersion, secondVersion) {
        return compare(firstVersion, secondVersion, '>');
    }
}
