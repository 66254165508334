import { assetListSelectors, fontListSelectors, themeSelectors, } from '@builder/schemas';
import { getFileNameFromPath, injectFontFaceUrl } from '@builder/utils';
export class FontFaceCSSGenerator {
    constructor({ themeDSL, assetListDSL, assetBackendList, useAssetDirectImports, }) {
        var _a;
        this.themeDSL = themeDSL !== null && themeDSL !== void 0 ? themeDSL : {};
        this.fontsDSL = (_a = themeDSL === null || themeDSL === void 0 ? void 0 : themeDSL.fonts) !== null && _a !== void 0 ? _a : {};
        this.assetListDSL = assetListDSL !== null && assetListDSL !== void 0 ? assetListDSL : {};
        this.assetBackendList = assetBackendList;
        this.useAssetDirectImports = useAssetDirectImports;
    }
    getLocalFilePath() {
        return 'assets/fonts/';
    }
    getLocalFileName(path) {
        return getFileNameFromPath(path);
    }
    getRawFontFaceList() {
        return themeSelectors.getRawFontFaceListDSL(this.themeDSL);
    }
    getCustomFontList() {
        return fontListSelectors.getCustomFontListDSL(this.fontsDSL);
    }
    getAssetFileWithBackendDataList() {
        return assetListSelectors.getAssetFileWithBackendDataList(this.assetListDSL, {
            assetBackendList: this.assetBackendList,
        });
    }
    getFontFaceCSSWithUrlInjected(cssWithSrcTemplate, fontAssetBackend) {
        if (this.useAssetDirectImports) {
            const filename = this.getLocalFileName(fontAssetBackend === null || fontAssetBackend === void 0 ? void 0 : fontAssetBackend.path);
            return filename
                ? injectFontFaceUrl(cssWithSrcTemplate, `${this.getLocalFilePath()}${filename}`)
                : '';
        }
        return injectFontFaceUrl(cssWithSrcTemplate, fontAssetBackend.src);
    }
    generateFontFaceCSS() {
        const fontFaceList = this.getRawFontFaceList();
        const customFontList = this.getCustomFontList();
        const assetFileWithBackendDataList = this.getAssetFileWithBackendDataList();
        return Object.values(fontFaceList).reduce((fontFaceCSS, fontFaceDSL) => {
            const customFontDSL = customFontList[fontFaceDSL.fontID];
            const fontAssetBackend = assetFileWithBackendDataList[customFontDSL === null || customFontDSL === void 0 ? void 0 : customFontDSL.assetID];
            return `${fontFaceCSS}${this.getFontFaceCSSWithUrlInjected(fontFaceDSL.css, fontAssetBackend)}\n`;
        }, '');
    }
}
