var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useRef, forwardRef, useLayoutEffect, useCallback, useEffect, useState, } from 'react';
import styled from '@emotion/styled';
import { DataGrid as MaterialDataGrid, } from '@mui/x-data-grid';
import _ from 'lodash';
import { isNil } from 'ramda';
const DataGridWrapper = styled.div `
  & .MuiDataGrid-root {
    ${props => !props.showTableBorder && `border: none;`}
  }
`;
export const DataGrid = forwardRef((_a, ref) => {
    var { style, className, showTableBorder, rows = [], columns = [], 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, translate, primaryField, pageSize, rowsPerPageOptions = [5, 10, 20] } = _a, gridRestProps = __rest(_a, ["style", "className", "showTableBorder", "rows", "columns", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "translate", "primaryField", "pageSize", "rowsPerPageOptions"]);
    const domRef = useRef(null);
    const filteredRows = Array.isArray(rows) && !isNil(primaryField) ? rows.filter(row => row[primaryField]) : [];
    const [columnsOrdered, setColumnsOrdered] = useState(null);
    const [currentGridRestpros, setCurrentGridRestProps] = useState(gridRestProps);
    const [rowsPage, setRowsPage] = useState(pageSize);
    const [paginationOptions, setPaginationOptions] = useState(rowsPerPageOptions);
    const refCallback = useCallback((node) => {
        if (typeof ref === 'function')
            ref(node);
        if (typeof domRef === 'object')
            domRef.current = node;
    }, [ref]);
    const transformColumns = useCallback(() => {
        if (columns === null || columns === void 0 ? void 0 : columns.length) {
            let primaryKey = {};
            let primaryKeyIndex = null;
            const newColumns = columns.map((column, index) => {
                if (column.field === primaryField) {
                    primaryKey = column;
                    primaryKeyIndex = index;
                }
                return column;
            });
            if (primaryKeyIndex) {
                newColumns.splice(primaryKeyIndex, 1);
                return [...new Set([primaryKey, ...newColumns])];
            }
        }
        return columns;
    }, [columns, primaryField]);
    useEffect(() => {
        if (!isNil(primaryField) && !_.isUndefined(primaryField)) {
            const newColumns = transformColumns();
            if (!_.isEqual(newColumns, columnsOrdered)) {
                setColumnsOrdered(newColumns);
                setCurrentGridRestProps(Object.assign(Object.assign({}, gridRestProps), { primaryField }));
            }
        }
    }, [primaryField, columns, gridRestProps, columnsOrdered, transformColumns]);
    useLayoutEffect(() => {
        if (typeof domRef === 'object' && (domRef === null || domRef === void 0 ? void 0 : domRef.current)) {
            const gridDiv = domRef.current;
            const gridEl = gridDiv.querySelector('div');
            if (gridEl === null || gridEl === void 0 ? void 0 : gridEl.style)
                gridEl.style.height = '';
        }
    });
    useEffect(() => {
        pageSize && setRowsPage(pageSize);
    }, [pageSize]);
    useEffect(() => {
        setPaginationOptions(rowsPerPageOptions);
    }, [rowsPerPageOptions]);
    const handleSize = (size) => {
        setRowsPage(size);
    };
    return (_jsx(DataGridWrapper, Object.assign({ ref: refCallback, style: style, id: id, className: className, title: title, lang: lang, translate: translate, showTableBorder: showTableBorder, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath }, { children: _jsx(MaterialDataGrid, Object.assign({}, currentGridRestpros, { onSelectionModelChange: gridRestProps.onSelectionChange, componentsProps: {
                cell: {
                    onMouseEnter: gridRestProps.onCellHover,
                },
                row: {
                    onMouseEnter: gridRestProps.onRowHover,
                },
                baseCheckbox: {
                    onInput: gridRestProps.onRowSelected,
                },
                errorOverlay: gridRestProps.onError,
            }, rows: filteredRows, columns: (columnsOrdered || columns).filter(col => !col.hide), getRowId: row => (!isNil(primaryField) && row[primaryField]) || row.id, onPageSizeChange: handleSize, pageSize: rowsPage, rowsPerPageOptions: paginationOptions })) })));
});
