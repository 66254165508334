import { DateTime } from 'luxon';
import { isNil } from '../type-checkers';
/**
 * @param date - Target date (must be ISO string if @param fromFormatPattern is not stated)
 * @param toFormatPattern - Date format pattern string to convert to
 * @param fromFormatPattern - Date format pattern string to convert from
 * @returns formatted date string or null if @param date is nullish
 */
export const formatDate = (date, toFormatPattern, fromFormatPattern) => {
    if (isNil(date)) {
        return null;
    }
    if (isNil(fromFormatPattern)) {
        return DateTime.fromISO(date).toFormat(toFormatPattern);
    }
    return DateTime.fromFormat(date, fromFormatPattern).toFormat(toFormatPattern);
};
