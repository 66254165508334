import React from 'react';

import { Menu, MenuItem } from '@mui/material';
import { usePopupState, bindTrigger, bindMenu, PopupState } from 'material-ui-popup-state/hooks';

import { componentListSelectors, ComponentSettingNodeItemDSL } from '@builder/schemas';

import { useComponentListDSL } from 'src/providers/ReduxProvider';
import { Button } from 'src/shared/components';

type NodeSettingsAddNodeButtonProps = {
  buttonText: string;
  popupState?: PopupState;
  possibleComponents: ComponentSettingNodeItemDSL[];
  onAdd: (newComponent: ComponentSettingNodeItemDSL) => void;
  'data-test'?: string;
};

const NODE_SETTINGS_ADD_NODE_POPUP_ID = 'nodeSettingsAddNode';

export const NodeSettingsAddNodeButton: React.FC<NodeSettingsAddNodeButtonProps> = ({
  buttonText,
  popupState: popupExternalState,
  possibleComponents,
  onAdd,
  'data-test': dataTest,
}) => {
  const componentListDSL = useComponentListDSL();
  const popupLocalState = usePopupState({
    variant: 'popover',
    popupId: NODE_SETTINGS_ADD_NODE_POPUP_ID,
  });
  const popupState = popupExternalState || popupLocalState;
  const bindMenuState = bindMenu(popupState);
  const bindTriggerState = bindTrigger(popupState);

  return (
    <>
      <Button
        data-test={`${dataTest}.chooseItemMenuBtn`}
        variant="contained"
        color="default"
        {...bindTriggerState}
      >
        <span>{buttonText}</span>
      </Button>

      <Menu {...bindMenuState}>
        {possibleComponents.map(newComponent => (
          <MenuItem
            key={newComponent.name}
            onClick={() => onAdd(newComponent)}
            data-test={`${dataTest}.componentNameItem.${newComponent.name}`}
          >
            {componentListSelectors.getDisplayName(componentListDSL, {
              componentName: newComponent.name,
            })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
