import { createContext } from 'react';
export const RouteHookContext = createContext({
    state: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMounted: (value) => { },
    isMounted: true,
    nextPath: undefined,
    currentPath: undefined,
    prevLocation: undefined,
    location: undefined,
    currentRoute: undefined,
    globalHooks: {
        beforeEach: undefined,
        afterEach: undefined,
    },
    localHooks: {
        beforeRouteEnter: undefined,
        beforeRouteUpdate: undefined,
        beforeRouteExit: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    runRequest: (requestID) => { },
    createFunction: (code, location) => {
        return undefined;
    },
    stateList: {},
});
