import { Migration } from '../Migration';
class Migration_0_15_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.15.0';
    }
    getChangeList() {
        return [`Add function information to global state`];
    }
    getFunctionStateNames(states) {
        return Object.keys(states).reduce((functionNames, stateName) => {
            if (states[stateName].type === 'function') {
                return [...functionNames, states[stateName].name];
            }
            return functionNames;
        }, []);
    }
    getRegExpByPattern(pattern) {
        return new RegExp(`(?::s*)(?:"|')?(?<value>.*(${pattern})(?!.function).*)(?:"|')?`, 'g');
    }
    migrateNodeDSL(nodeDSL, appDSL) {
        const nodeDSLString = JSON.stringify(nodeDSL, undefined, 4);
        const functionStateNames = this.getFunctionStateNames(appDSL.states);
        const newNodeDSLString = functionStateNames.reduce((resultNodeDSLString, functionName) => {
            const existFunctionNameInDSL = this.getRegExpByPattern(functionName);
            if (existFunctionNameInDSL.test(resultNodeDSLString)) {
                return resultNodeDSLString.replaceAll(`${functionName}`, `${functionName}.function`);
            }
            return resultNodeDSLString;
        }, nodeDSLString);
        return JSON.parse(newNodeDSLString);
    }
}
export const migration_0_15_0 = new Migration_0_15_0();
