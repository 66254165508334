import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialListItemButtonSettings } from './MaterialListItemButton.settings';
export const MaterialListItemButton = {
    name: COMPONENT_DSL_NAMES.MaterialListItemButton,
    displayName: 'List Item Button',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'ListItemButton',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { alignItems: { type: COMPONENT_DSL_PROP_TYPES.enum }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, dense: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableGutters: { type: COMPONENT_DSL_PROP_TYPES.boolean }, divider: { type: COMPONENT_DSL_PROP_TYPES.boolean }, focusVisibleClassName: { type: COMPONENT_DSL_PROP_TYPES.string }, selected: { type: COMPONENT_DSL_PROP_TYPES.boolean }, sx: { type: COMPONENT_DSL_PROP_TYPES.enum }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.MaterialListItemText,
                    COMPONENT_DSL_NAMES.MaterialListItemIcon,
                ],
            } }),
    },
    settings: MaterialListItemButtonSettings,
};
