import { useCallback, useMemo } from 'react';

import { Divider } from '@mui/material';
import { path } from 'ramda';

import {
  ComponentSettingDSL,
  COMPONENT_SETTING_TYPES,
  componentSettingSelectors,
  NodePropValue,
  nodeListSelectors,
  NodeDSL,
  ObjectPropValue,
  ComponentSettingNodeDSL,
  ComponentSettingPrimaryKeyPropDSL,
  UpdateStateArgs,
} from '@builder/schemas';
import { pathToString, memo } from '@builder/utils';

import {
  ActionType,
  AllowedRolesType,
  checkIsPopupTypeActive,
  ConditionType,
  CustomFontSelectorType,
  DataGeneratorColumnsType,
  DataGeneratorType,
  EventsSection,
  HTMLFragment,
  IteratorType,
  ListType,
  MixedType,
  NodeListType,
  NodeTextType,
  NodeType,
  PopupsSectionType,
  PopupType,
  PropType,
  RequestSelectorType,
  RootSectionType,
  SchemaOverrideType,
  SectionType,
  SpacingType,
  StateSelectorType,
  StateType,
  SubListType,
  SubSectionType,
} from '../setting-types';
import { RichTextViewEditor } from '../setting-views';
import { NodeSettingCommonProps } from '../types';
import { getSettingKey } from '../utils/getSettingKey';
import { useNodeListDSL, useUIBuilderState } from 'src/providers/ReduxProvider';
import { CssGrid } from 'src/shared/components';
import { getTargetNodeID } from 'src/store/dashboard/utils';

import { useNodeSettingsProps } from './NodeSettingsProvider';

export type ValidationListItem = {
  name: NodePropValue<string>;
  type: NodePropValue<string>;
  validation: NodePropValue<string>;
};

export type NodeSettingsGeneratorProps = NodeSettingCommonProps & {
  setting: ComponentSettingDSL;
  validationList?: ValidationListItem;
};

export type NodeSettingListGeneratorProps = NodeSettingCommonProps & {
  settings: ComponentSettingDSL[];
  validationList?: ValidationListItem;
};

const EMPTY_SECTION_PATH: (string | number)[] = [];

export const NodeSettingsGenerator = memo(
  'NodeSettingsGenerator',
  ({
    setting,
    sectionPath,
    prefixKeyPath = [],
    selectedNodeDSL: substituteSelectedNodeDSL,
    validationList,
  }: NodeSettingsGeneratorProps): JSX.Element | null => {
    const {
      selectedNodeDSL: defaultSelectedNodeDSL,
      nodeStyle,
      appDSL,
      onUpdateCondition,
      onUpdateAllowedRoles,
      onUpdateIterator,
      onUpdateNodeText,
      onUpdateProp,
      onUpdatePropMany,
      onCreateNode,
      onDeleteNode,
      onUpdateSchemaOverride,
    } = useNodeSettingsProps();
    const { devModeEnabled } = useUIBuilderState();
    const nodeListDSL = useNodeListDSL();
    const selectedNodeDSL = substituteSelectedNodeDSL || defaultSelectedNodeDSL;
    const keyPath = componentSettingSelectors.getSettingsKeyPath(setting);
    const nodePath = componentSettingSelectors.getSettingsNodePath(setting);
    const targetNodeID = useMemo(
      () =>
        getTargetNodeID({
          nodeID: selectedNodeDSL.id,
          nodePath,
          nodeListDSL: appDSL.nodes,
        }),
      [appDSL.nodes, nodePath, selectedNodeDSL.id],
    );
    const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: targetNodeID });
    const targetNode = nodeListSelectors.getNodeDSL(appDSL.nodes, { nodeID: targetNodeID });
    const targetNodeWithDefaultStylesInjection = useMemo<NodeDSL>(() => {
      if (!nodeStyle || defaultSelectedNodeDSL.id !== targetNodeID) {
        return targetNode;
      }

      return {
        ...targetNode,
        props: {
          ...targetNode.props,
          style: {
            ...(nodeStyle as ObjectPropValue),
            ...(targetNode.props.style as ObjectPropValue),
          },
        },
      };
    }, [nodeStyle, targetNode, targetNodeID, defaultSelectedNodeDSL.id]);

    const keyValue = path<NodePropValue | null>(
      [...prefixKeyPath, ...keyPath],
      targetNodeWithDefaultStylesInjection.props,
    );
    const listSelectedType = validationList?.type;

    const updateProp = useCallback(
      (propData: Parameters<typeof onUpdateProp>[0]) => {
        onUpdateProp(
          { ...propData, keyPath: [...prefixKeyPath, ...propData.keyPath] },
          targetNodeID,
          (setting as ComponentSettingNodeDSL).label,
          setting,
        );
      },
      [onUpdateProp, prefixKeyPath, setting, targetNodeID],
    );

    const updatePropMany = useCallback(
      (propDataArray: Parameters<typeof onUpdatePropMany>[0]) => {
        const propDataArrayWithPrefixKeyPaths = propDataArray.map(propData => ({
          ...propData,
          keyPath: [...prefixKeyPath, ...propData.keyPath],
        }));

        onUpdatePropMany(propDataArrayWithPrefixKeyPaths, targetNodeID);
      },
      [onUpdatePropMany, prefixKeyPath, targetNodeID],
    );

    const updateNodeText = useCallback(
      (propData: Parameters<typeof onUpdateNodeText>[0]) => {
        onUpdateNodeText(propData, targetNodeID);
      },
      [onUpdateNodeText, targetNodeID],
    );

    const updateCondition = useCallback(
      (propData: Parameters<typeof onUpdateCondition>[0] = '') => {
        onUpdateCondition(propData, targetNodeID);
      },
      [onUpdateCondition, targetNodeID],
    );

    const updateIterator = useCallback(
      (propData: Parameters<typeof onUpdateIterator>[0]) => {
        onUpdateIterator(propData, targetNodeID);
      },
      [onUpdateIterator, targetNodeID],
    );

    const updateSchemaOverride = useCallback(
      (schemaOverrideData: Parameters<typeof onUpdateSchemaOverride>[0]) => {
        onUpdateSchemaOverride(
          { ...schemaOverrideData, keyPath: [...prefixKeyPath, ...schemaOverrideData.keyPath] },
          targetNodeID,
        );
      },
      [onUpdateSchemaOverride, prefixKeyPath, targetNodeID],
    );

    const updateAllowedRoles = useCallback(
      (allowedRoles: Parameters<typeof onUpdateAllowedRoles>[0]) => {
        onUpdateAllowedRoles(allowedRoles, targetNodeID);
      },
      [onUpdateAllowedRoles, targetNodeID],
    );
    const isHiddenByDevMode = !devModeEnabled && setting.devMode;
    const isShowingSetting = componentSettingSelectors.isShowingSetting(setting.showIf, {
      sourceEntity: selectedNodeDSL,
      nodeListDSL: appDSL.nodes,
      prefixKeyPath: listSelectedType ? prefixKeyPath : undefined,
    });

    if (!isShowingSetting || isHiddenByDevMode) {
      return null;
    }

    switch (setting.type) {
      case COMPONENT_SETTING_TYPES.rootSection:
        return (
          <RootSectionType rootSectionSetting={setting}>
            {setting.children.map(childSetting => {
              return (
                <NodeSettingsGenerator
                  key={getSettingKey(childSetting, `section=root`)}
                  setting={childSetting}
                  sectionPath={EMPTY_SECTION_PATH}
                />
              );
            })}
          </RootSectionType>
        );

      case COMPONENT_SETTING_TYPES.spacing:
        return (
          <SpacingType spacingSetting={setting}>
            {setting.children.map(childSetting => {
              return (
                <NodeSettingsGenerator
                  key={getSettingKey(childSetting, `section=spacing`)}
                  setting={childSetting}
                  sectionPath={sectionPath}
                />
              );
            })}
          </SpacingType>
        );

      case COMPONENT_SETTING_TYPES.section:
        return (
          <SectionType sectionSetting={setting} data-test={`propSettings.${setting.name}`}>
            {setting.children.map(childSetting => {
              return (
                <NodeSettingsGenerator
                  key={getSettingKey(childSetting, `section=${setting.name}`)}
                  setting={childSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              );
            })}
          </SectionType>
        );

      case COMPONENT_SETTING_TYPES.subSection:
        return (
          <SubSectionType subSectionSetting={setting}>
            {setting.children.map(childSetting => (
              <CssGrid key={getSettingKey(childSetting, `sub-section=${setting.name}`)} gap={1}>
                <NodeSettingsGenerator
                  setting={childSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              </CssGrid>
            ))}
          </SubSectionType>
        );

      case COMPONENT_SETTING_TYPES.prop: {
        return (
          <PropType
            setting={setting}
            keyValue={keyValue}
            dataTestPrefix={pathToString(['propSettings', ...sectionPath])}
            validationList={validationList}
            onChange={updateProp}
            onChangeMany={updatePropMany}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.htmlFragment: {
        return (
          <HTMLFragment
            setting={setting}
            keyValue={keyValue}
            onChange={updateProp}
            onChangeMany={updatePropMany}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.customFont: {
        return (
          <CustomFontSelectorType
            setting={setting}
            onChange={updateProp}
            keyValue={keyValue}
            dataTestPrefix={pathToString(['propSettings', ...sectionPath])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.nodeText: {
        return (
          <NodeTextType
            setting={setting}
            onChange={updateNodeText}
            selectedNodeDSL={selectedNodeDSL}
            data-test={pathToString(['propSettings', ...sectionPath, ...prefixKeyPath, ...keyPath])}
            showFx={setting.hasFxButton}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.condition: {
        return (
          <ConditionType
            setting={setting}
            onChangeConditionValue={updateCondition}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
            showFx={setting.hasFxButton}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.iterator: {
        return (
          <IteratorType
            setting={setting}
            targetNodeDSL={targetNodeDSL}
            onChangeIterator={updateIterator}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.schemaOverride: {
        return (
          <SchemaOverrideType
            setting={setting}
            onChangeSchemaOverrideValue={updateSchemaOverride}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.state: {
        return (
          <StateType
            setting={setting}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.requestSelector: {
        return (
          <RequestSelectorType
            setting={setting}
            onChange={updateProp}
            keyValue={keyValue}
            dataTestPrefix={pathToString(['propSettings', ...sectionPath])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.stateSelector: {
        return (
          <StateSelectorType
            setting={setting}
            onChange={updateProp}
            keyArgs={keyValue as UpdateStateArgs}
            dataTestPrefix={pathToString(['propSettings', ...sectionPath])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.node: {
        return (
          <NodeType
            setting={setting}
            onUpdateProp={updateProp}
            prefixKeyPath={prefixKeyPath}
            selectedNodeDSL={selectedNodeDSL}
            onCreateNode={onCreateNode}
            onDeleteNode={onDeleteNode}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          >
            {({ settings, targetNode: currentTargetNode }) => (
              <NodeSettingListGenerator
                settings={settings}
                selectedNodeDSL={currentTargetNode}
                sectionPath={sectionPath.concat(setting.name)}
              />
            )}
          </NodeType>
        );
      }

      case COMPONENT_SETTING_TYPES.nodeList: {
        return (
          <NodeListType
            setting={setting}
            onUpdateProp={updateProp}
            prefixKeyPath={prefixKeyPath}
            sectionPath={sectionPath}
          >
            {({ settings, targetNodeDSL: currentTargetNode, nodeListSectionPath }) => (
              <NodeSettingListGenerator
                settings={settings}
                selectedNodeDSL={currentTargetNode}
                sectionPath={nodeListSectionPath}
              />
            )}
          </NodeListType>
        );
      }

      case COMPONENT_SETTING_TYPES.list: {
        return (
          <ListType setting={setting} onUpdateProp={updateProp} sectionPath={sectionPath}>
            {({
              settings,
              prefixKeyPath: listPrefixKeyPath,
              listSectionPath,
              validationList: subValidationList,
            }) => (
              <NodeSettingListGenerator
                prefixKeyPath={listPrefixKeyPath}
                settings={settings}
                sectionPath={listSectionPath}
                validationList={subValidationList}
              />
            )}
          </ListType>
        );
      }

      case COMPONENT_SETTING_TYPES.subList: {
        return (
          <SubListType
            setting={setting}
            validationList={validationList}
            onUpdateProp={updateProp}
            sectionPath={sectionPath}
            prefixKeyPath={prefixKeyPath as (string | number)[]}
          >
            {({
              settings,
              prefixKeyPath: listPrefixKeyPath,
              listSectionPath,
              validationList: subValidationList,
            }) => (
              <NodeSettingListGenerator
                prefixKeyPath={listPrefixKeyPath}
                settings={settings}
                sectionPath={listSectionPath}
                validationList={subValidationList}
              />
            )}
          </SubListType>
        );
      }

      case COMPONENT_SETTING_TYPES.dataGenerator: {
        return (
          <DataGeneratorType
            setting={setting}
            onChangePropValue={updateProp}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.updatedPrimaryKey: {
        return (
          <DataGeneratorColumnsType
            setting={setting as ComponentSettingPrimaryKeyPropDSL}
            onChange={updateProp}
            keyValue={keyValue}
            dataTestPrefix={pathToString(['propSettings', ...sectionPath])}
          />
        );
      }

      // TODO: rename or remove
      case COMPONENT_SETTING_TYPES.childrenTextRich: {
        return (
          <RichTextViewEditor
            targetNodeId={targetNodeID}
            keyValue={keyValue}
            setting={setting}
            onChange={updateProp}
            data-test={`propSettings.${pathToString(sectionPath)}.richTextEditor`}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.mixed: {
        return (
          <MixedType setting={setting}>
            {({ settings }) => {
              return settings.map(currentSetting => (
                <NodeSettingsGenerator
                  key={getSettingKey(currentSetting, 'main')}
                  setting={currentSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              ));
            }}
          </MixedType>
        );
      }

      case COMPONENT_SETTING_TYPES.popup: {
        const isActive = checkIsPopupTypeActive({
          settings: setting.children,
          selectedNodeID: selectedNodeDSL.id,
          nodeListDSL: appDSL.nodes,
        });

        return (
          <PopupType setting={setting} isActive={isActive} data-test={`popup.${setting.name}`}>
            {({ settings }) => {
              return settings.map(currentSetting => (
                <NodeSettingsGenerator
                  key={getSettingKey(currentSetting, 'main')}
                  setting={currentSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              ));
            }}
          </PopupType>
        );
      }

      case COMPONENT_SETTING_TYPES.popupsSection: {
        return (
          <PopupsSectionType setting={setting}>
            {({ settings }) => {
              return settings.map(currentSetting => (
                <NodeSettingsGenerator
                  key={getSettingKey(currentSetting, 'main')}
                  setting={currentSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              ));
            }}
          </PopupsSectionType>
        );
      }

      case COMPONENT_SETTING_TYPES.eventsSection: {
        return (
          <EventsSection
            setting={setting}
            sectionPath={sectionPath}
            onUpdateProp={updateProp}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          >
            {({ settings, settingsPickerSectionPath }) => {
              return settings.map(currentSetting => (
                <NodeSettingsGenerator
                  key={getSettingKey(currentSetting, 'main')}
                  setting={currentSetting}
                  sectionPath={settingsPickerSectionPath}
                />
              ));
            }}
          </EventsSection>
        );
      }

      case COMPONENT_SETTING_TYPES.action: {
        return (
          <ActionType
            setting={setting}
            onUpdateProp={updateProp}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          >
            {setting.children.map(childSetting => {
              return (
                <NodeSettingsGenerator
                  key={getSettingKey(childSetting, `section=${setting.name}`)}
                  setting={childSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              );
            })}
          </ActionType>
        );
      }

      case COMPONENT_SETTING_TYPES.allowedRoles: {
        return (
          <AllowedRolesType
            setting={setting}
            onUpdateAllowedRoles={updateAllowedRoles}
            data-test={pathToString(['propSettings', ...sectionPath, setting.name])}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.divider: {
        return <Divider />;
      }

      default:
        return null;
    }
  },
);

export const NodeSettingListGenerator = memo(
  'NodeSettingListGenerator',
  ({ settings, ...rest }: NodeSettingListGeneratorProps): JSX.Element => {
    return (
      <CssGrid gap={2}>
        {settings.map(setting => (
          <NodeSettingsGenerator key={getSettingKey(setting, 'main')} setting={setting} {...rest} />
        ))}
      </CssGrid>
    );
  },
);
