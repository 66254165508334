var _a;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialTypography } from '../MaterialTypography';
import { BuilderComponentsTabSettings } from './BuilderComponentsTab.settings';
export const BuilderComponentsTab = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
    displayName: 'Tab',
    icon: 'tab',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Tab',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
                },
            },
        ],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            props: {
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            alias: 'Tab Label',
                            schemaOverride: {
                                interaction: 'only-editable',
                            },
                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Tab Item',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
                fieldProps: {
                    name: 'Tab',
                },
                row: false,
                header: 'tab',
                wrapped: false,
                disabled: false,
                icon: 'none',
                iconposition: 'start',
                color: 'primary',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
                            schemaOverride: {
                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                            },
                        },
                    ],
                },
            },
        },
        dndTargetPropName: 'component',
        props: Object.assign(Object.assign({}, commonComponentProps), { label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, value: { type: COMPONENT_DSL_PROP_TYPES.number }, header: { type: COMPONENT_DSL_PROP_TYPES.string }, content: { type: COMPONENT_DSL_PROP_TYPES.string }, icon: { type: COMPONENT_DSL_PROP_TYPES.enum }, wrapped: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFocusRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, iconposition: { type: COMPONENT_DSL_PROP_TYPES.enum }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            } }),
    },
    settings: BuilderComponentsTabSettings,
};
