import React, { useCallback, useMemo } from 'react';

import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';

import { AssetID } from '@builder/schemas';

import { DialogContentWithIcon } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';
import { useAssetsHooks } from 'src/shared/graphql/hooks';

const DIALOG_ID = DASHBOARD_DIALOGS.ASSET_DELETE_DIALOG_ID;

export type AssetDeleteDialogArgs = {
  assetIDs: AssetID[];
  afterDeleteCallback?: () => void;
};

export const AssetDeleteDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<AssetDeleteDialogArgs>(DIALOG_ID);
  const { deleteAsset } = useAssetsHooks();

  const onCloseDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const onAssetDelete = useCallback(() => {
    deleteAsset(args.assetIDs);
    onCloseDialog();
    args.afterDeleteCallback?.();
  }, [deleteAsset, onCloseDialog, args]);

  const isDeletingMultipleAssets = useMemo(() => args.assetIDs.length > 1, [args.assetIDs.length]);

  return (
    <Dialog open={isOpen} onClose={onCloseDialog} data-test="assets.assetDeleteDialog">
      <DialogTitle data-test="assets.assetDeleteDialog.title">
        <Choose>
          <When condition={isDeletingMultipleAssets}>Delete assets</When>
          <Otherwise>Delete asset</Otherwise>
        </Choose>
      </DialogTitle>
      <DialogContentWithIcon>
        <WarningAmberIcon color="warning" />
        <DialogContentText data-test="assets.assetDeleteDialog.text">
          <Choose>
            <When condition={isDeletingMultipleAssets}>
              Are you sure you want to delete these assets?
            </When>
            <Otherwise>Are you sure you want to delete this asset?</Otherwise>
          </Choose>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button
          variant="contained"
          color="default"
          onClick={onCloseDialog}
          data-test="assets.assetDeleteDialog.cancelButton"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={onAssetDelete}
          data-test="assets.assetDeleteDialog.deleteButton"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
