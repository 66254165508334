import { getDivider } from '../component-schemas/common/settings';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../constants';
import { createSelectOptionByValue } from '../utils';
export const ThemeSettings = [
    {
        type: COMPONENT_SETTING_TYPES.rootSection,
        children: [
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Theme',
                name: 'theme',
                disableCollapse: true,
                maxWidth: '450px',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.grid,
                        name: 'theme',
                        props: {
                            gap: 1.5,
                            gridTemplateColumns: '1fr',
                        },
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'theme',
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.select,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'name'],
                                        label: 'Theme',
                                        options: [
                                            'custom',
                                            'classicBlue',
                                            'summerGrass',
                                            'sunny',
                                            'neon',
                                            'corall',
                                            'night',
                                        ].map(createSelectOptionByValue),
                                        hasFxButton: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            getDivider(),
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Palette',
                name: 'palette',
                disableCollapse: true,
                maxWidth: '450px',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.grid,
                        name: 'palette',
                        props: {
                            gap: 1.5,
                            gridTemplateColumns: '1fr 1fr',
                        },
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'primary',
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'palette', 'primary', 'main'],
                                        label: 'Primary Color',
                                        hasFxButton: false,
                                        skipDebounce: true,
                                    },
                                ],
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'secondary',
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'palette', 'secondary', 'main'],
                                        label: 'Secondary Color',
                                        hasFxButton: false,
                                        skipDebounce: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            getDivider(),
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Typography',
                name: 'typography',
                disableCollapse: true,
                maxWidth: '450px',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.grid,
                        name: 'palette',
                        props: {
                            gap: 1.5,
                            gridTemplateColumns: 'repeat(6, 1fr)',
                        },
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'basicFont',
                                props: {
                                    gridColumnStart: 1,
                                    gridColumnEnd: 5,
                                },
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.text,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'typography', 'fontFamily'],
                                        label: 'Basic Font',
                                        hasFxButton: false,
                                    },
                                ],
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'basicFontSize',
                                props: {
                                    gridColumnStart: 5,
                                    gridColumnEnd: 7,
                                },
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.number,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'typography', 'fontSize'],
                                        label: 'Basic Font Size',
                                        hasFxButton: false,
                                        skipDebounce: true,
                                    },
                                ],
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'basicFontColor',
                                props: {
                                    gridColumnStart: 1,
                                    gridColumnEnd: 4,
                                },
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'palette', 'text', 'primary'],
                                        label: 'Basic Font Color',
                                        hasFxButton: false,
                                        skipDebounce: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            getDivider(),
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Basic',
                name: 'basic',
                disableCollapse: true,
                maxWidth: '450px',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.grid,
                        name: 'palette',
                        props: {
                            gap: 1.5,
                            gridTemplateColumns: 'repeat(3, 1fr)',
                        },
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'spacing',
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.number,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme', 'spacing'],
                                        label: 'Spacing',
                                        hasFxButton: false,
                                        skipDebounce: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            getDivider(),
            {
                type: COMPONENT_SETTING_TYPES.section,
                title: 'Advanced',
                name: 'advanced',
                disableCollapse: true,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.grid,
                        name: 'palette',
                        props: {
                            gap: 1.5,
                            gridTemplateColumns: '1fr',
                        },
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.gridItem,
                                name: 'advanced',
                                children: [
                                    {
                                        componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                                        type: COMPONENT_SETTING_TYPES.prop,
                                        keyPath: ['theme'],
                                        label: 'Advanced Options',
                                        hasFxButton: false,
                                        passInvalidData: false,
                                        skipDebounce: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
