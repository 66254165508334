import { INITIAL_DEPLOY_VERSION } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_12_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.12.0';
    }
    getChangeList() {
        return ['Changed app version format from A.B.C to A.B.C.D'];
    }
    migrateAppConfiguration(configuration) {
        const appVersionTuple = configuration.appVersion.split('.');
        if (appVersionTuple.length === 4) {
            return super.migrateAppConfiguration(configuration);
        }
        const appVersionPart = appVersionTuple[appVersionTuple.length - 1];
        return Object.assign(Object.assign({}, super.migrateAppConfiguration(configuration)), { appVersion: `${INITIAL_DEPLOY_VERSION}.${appVersionPart}` });
    }
}
export const migration_0_12_0 = new Migration_0_12_0();
