import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { CANVAS_SETTINGS } from './canvas.settings';
export const canvas = {
    name: COMPONENT_DSL_NAMES.canvas,
    displayName: 'Canvas',
    type: COMPONENT_DSL_TYPES.htmlElement,
    icon: 'canvas',
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'canvas',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        props: Object.assign({}, commonComponentProps),
    },
    settings: CANVAS_SETTINGS,
};
