import { COMPONENT_DSL_NAMES } from '../../constants';
export const SELECT_SCHEMA = {
    baseNodeListDSL: {
        root: {
            id: 'root',
            name: COMPONENT_DSL_NAMES.root,
            parentID: null,
            props: {
                children: {
                    nodes: ['wrapperID'],
                },
            },
            alias: 'Root',
        },
        wrapperID: {
            name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
            alias: 'Wrapper',
            props: {
                children: {
                    nodes: ['q_TFacB5'],
                },
                css: `display: grid; gap: 20px; grid-template-columns: 250px 250px 250px; padding: 10px`,
            },
            id: 'wrapperID',
            parentID: 'root',
            states: [],
        },
        /**
         * This is to skip the DSL compilation step when using BASE_SCHEMA
         * TODO: rid useless nodes after [APB-2496]
         * */
        // eslint-disable-next-line camelcase
        ErrorPageRootRoute_ID: {
            name: 'BuilderComponentsRoute',
            id: 'ErrorPageRootRoute_ID',
            parentID: 'EmptyPageRouterSwitch_ID',
            props: {
                children: {
                    nodes: ['ErrorPageRootRouteLayout_ID'],
                },
                path: '/500',
                authAccess: 'any',
            },
            states: [],
            alias: 'Error Page Wrapper',
            schemaOverride: {
                disableDelete: true,
            },
        },
        // eslint-disable-next-line camelcase
        ErrorPageRootRouteLayout_ID: {
            name: 'BuilderComponentsRouteLayout',
            id: 'ErrorPageRootRouteLayout_ID',
            parentID: 'ErrorPageRootRoute_ID',
            props: {
                children: {
                    nodes: [],
                },
                style: {
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: '0%',
                    flexDirection: 'column',
                    height: '100%',
                },
            },
            states: [],
            alias: 'Error Page',
        },
        // eslint-disable-next-line camelcase
        NotFoundPageRootRoute_ID: {
            name: 'BuilderComponentsRoute',
            id: 'NotFoundPageRootRoute_ID',
            parentID: 'EmptyPageRouterSwitch_ID',
            props: {
                children: {
                    nodes: ['NotFoundRootRouteLayout_ID'],
                },
                path: '/404',
                authAccess: 'any',
            },
            states: [],
            alias: 'Not Found Wrapper',
            schemaOverride: {
                disableDelete: true,
            },
        },
        // eslint-disable-next-line camelcase
        NotFoundRootRouteLayout_ID: {
            name: 'BuilderComponentsRouteLayout',
            id: 'NotFoundRootRouteLayout_ID',
            parentID: 'NotFoundPageRootRoute_ID',
            props: {
                children: {
                    nodes: [],
                },
                style: {
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: '0%',
                    flexDirection: 'column',
                    height: '100%',
                },
            },
            states: [],
            alias: 'Not Found Page',
        },
    },
    rootID: 'wrapperID',
    css: `body { background-color: transparent !important; }`,
};
