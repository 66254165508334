import React from 'react';

import { ComponentSettingSectionDSL } from '@builder/schemas';
import { memo } from '@builder/utils';

import { AccordionSection, CssGrid } from 'src/shared/components';

type SectionTypeProps = {
  sectionSetting: ComponentSettingSectionDSL;
  children: React.ReactNode;
  'data-test'?: string;
};

export const SectionType = memo(
  'SectionType',
  ({ sectionSetting, children, ...props }: SectionTypeProps): JSX.Element => {
    return (
      <AccordionSection
        title={sectionSetting.title}
        titleDescription={sectionSetting.titleDescription}
        expandedByDefault={sectionSetting.expandedByDefault}
        disableCollapse={sectionSetting.disableCollapse}
        {...props}
      >
        <CssGrid gap={1.5} width="100%" maxWidth={sectionSetting.maxWidth}>
          {children}
        </CssGrid>
      </AccordionSection>
    );
  },
);
