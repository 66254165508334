import { themeSelectors } from '@builder/schemas';
import { FontFaceCSSGenerator } from '../fontface-css-generator';
import { CSS_FILE_NAMES } from './constants';
export class GlobalCSSGenerator {
    constructor({ appDSL, assetBackendList, useAssetDirectImports, }) {
        var _a, _b;
        this.themeDSL = (_a = appDSL.theme) !== null && _a !== void 0 ? _a : {};
        this.assetListDSL = (_b = appDSL.assets) !== null && _b !== void 0 ? _b : {};
        this.assetBackendList = assetBackendList;
        this.useAssetDirectImports = useAssetDirectImports;
    }
    generateGlobalCSS() {
        const globalCSS = themeSelectors.getGlobalCSS(this.themeDSL);
        return globalCSS
            ? {
                [`src/${CSS_FILE_NAMES.global}`]: { content: globalCSS },
            }
            : null;
    }
    generateCustomGlobalCSS() {
        const customCSS = themeSelectors.getCustomCSS(this.themeDSL);
        return customCSS
            ? {
                [`src/${CSS_FILE_NAMES.custom}`]: { content: customCSS },
            }
            : null;
    }
    generateComponentCSS() {
        const componentCSS = themeSelectors.getComponentsCSS(this.themeDSL);
        return componentCSS
            ? {
                [`src/${CSS_FILE_NAMES.component}`]: { content: componentCSS },
            }
            : null;
    }
    generateFontFaceCSS() {
        const fontFaceCssGenerator = new FontFaceCSSGenerator({
            themeDSL: this.themeDSL,
            assetListDSL: this.assetListDSL,
            assetBackendList: this.assetBackendList,
            useAssetDirectImports: this.useAssetDirectImports,
        });
        const fontFaceCSS = fontFaceCssGenerator.generateFontFaceCSS();
        return fontFaceCSS
            ? {
                [`src/${CSS_FILE_NAMES.fontFace}`]: { content: fontFaceCSS },
            }
            : null;
    }
    generateTypographyCSS() {
        const typographyCSS = themeSelectors.getTypographyCSS(this.themeDSL);
        return typographyCSS
            ? {
                [`src/${CSS_FILE_NAMES.typography}`]: { content: typographyCSS },
            }
            : null;
    }
    generateMediaQueryCSS() {
        const mediaQueryCSS = themeSelectors.getMediaQueryCSS(this.themeDSL);
        return mediaQueryCSS
            ? {
                [`src/${CSS_FILE_NAMES.mediaQuery}`]: { content: mediaQueryCSS },
            }
            : null;
    }
    generateFileStructure() {
        return [
            this.generateGlobalCSS(),
            this.generateFontFaceCSS(),
            this.generateTypographyCSS(),
            this.generateCustomGlobalCSS(),
            this.generateComponentCSS(),
            this.generateMediaQueryCSS(),
        ]
            .filter(Boolean)
            .reduce((accum, currentStructure) => {
            return Object.assign(Object.assign({}, accum), currentStructure);
        }, {});
    }
}
