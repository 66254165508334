import React, { useMemo, useRef } from 'react';

import { useDrop, DropTargetMonitor, XYCoord, useDrag, DragLayerMonitor } from 'react-dnd';

import { SettingsListItem } from '../SettingsListItem';

type SettingsDraggableListItemProps = {
  index: number;
  label?: string;
  title?: string;
  onReorder: (dragIndex: number, dropIndex: number) => void;
  'data-test'?: string;
  isMenuPopperOpened?: boolean;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const DRAGGABLE_LITS_ITEM_ID = '@draggable-list-item';

export const SettingsDraggableListItem: React.FC<SettingsDraggableListItemProps> = ({
  index,
  label,
  title,
  onReorder,
  children,
  'data-test': dataTest,
  isMenuPopperOpened,
}) => {
  const dndRef = useRef<HTMLDivElement>(null);
  const [{ handlerID }, drop] = useDrop({
    accept: DRAGGABLE_LITS_ITEM_ID,
    collect(monitor) {
      return {
        handlerID: monitor.getHandlerId(),
      };
    },
    hover(dragItem: DragItem, monitor: DropTargetMonitor) {
      if (!dndRef.current) {
        return;
      }

      const dragIndex = dragItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dndRef.current?.getBoundingClientRect()?.toJSON();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onReorder(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAGGABLE_LITS_ITEM_ID,
    item: { index, type: DRAGGABLE_LITS_ITEM_ID },
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(dndRef));

  const startIconProps = useMemo(
    () => ({
      name: 'dragIndicator',
    }),
    [],
  );

  const dndProps = useMemo(
    () => ({
      handlerID,
      isDragging,
      dndRef,
    }),
    [handlerID, isDragging],
  );

  return (
    <SettingsListItem
      startIconProps={startIconProps}
      label={label}
      title={title}
      data-test={dataTest}
      dndProps={dndProps}
      index={index}
      isMenuPopperOpened={isMenuPopperOpened}
    >
      {children}
    </SettingsListItem>
  );
};
