import styled from '@emotion/styled';

export const DialogWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 1040px;
  min-height: 261px;
`;

export const DeployProgressWrapper = styled.div`
  border-radius: 8px;
  height: 320px;
  overflow: auto;
  text-align: center;
  width: 70%;

  .MuiGrid-container.MuiGrid-item {
    align-items: center;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    p {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export const PRIMARY_BUTTON_STYLES = { width: '200px', fontSize: '15px', fontWeight: 'normal' };
export const SECONDARY_BUTTON_STYLES = {
  backgroundColor: 'var(--grays-gray-40, #A3AFB7)',
  width: '200px',
  fontSize: '15px',
  fontWeight: 'normal',
};
