import * as changeCase from 'change-case';

const hasFileWithName = (existingNames: string[], filename: string): boolean => {
  return existingNames.includes(filename);
};

const appendIndexToFilename = (filename: string, index: number): string => {
  const dotSeparatedFilenameParts = filename.split('.');
  const indexedFilename = `${dotSeparatedFilenameParts[0]}_${index}`;
  dotSeparatedFilenameParts[0] = indexedFilename;

  return dotSeparatedFilenameParts.join('.');
};

export const trimFilenameExtension = (filename: string): string => {
  const dotSeparatedFilenameParts = filename.split('.');

  return dotSeparatedFilenameParts.length > 1
    ? dotSeparatedFilenameParts.slice(0, dotSeparatedFilenameParts.length - 1).join('.')
    : dotSeparatedFilenameParts[0];
};

export const getFileNameWithoutSpacesAndSymbols = (filename: string): string => {
  const newFileName = filename.replace(/[^A-Za-z\d]/g, '');
  return newFileName.replace(/([0-9]+)/, '') || 'asset';
};

export const getNextUniqueFilename = (existingNames: string[], filename: string): string => {
  let index = 1;
  let indexedFilename = filename;

  while (hasFileWithName(existingNames, indexedFilename)) {
    indexedFilename = appendIndexToFilename(filename, index);
    index += 1;
  }

  return indexedFilename;
};

export const transformFilenameToTitle = (filename: string): string => {
  return changeCase.sentenceCase(filename);
};
