import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyStateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
  max-width: 250px;
  text-align: center;
`;
