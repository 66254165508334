import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonActionProps, commonBasicProps, commonComponentProps, commonReactProps, } from '../../common/commonProps';
import { BuilderComponentsFileDropZoneSettings } from './BuilderComponentsFileDropZone.settings';
export const builderComponentsFileDropZoneProps = {
    /** Display */
    display: { type: COMPONENT_DSL_PROP_TYPES.string },
    overflow: { type: COMPONENT_DSL_PROP_TYPES.string },
    textOverflow: { type: COMPONENT_DSL_PROP_TYPES.string },
    visibility: { type: COMPONENT_DSL_PROP_TYPES.string },
    whiteSpace: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Flex */
    flexDirection: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexWrap: { type: COMPONENT_DSL_PROP_TYPES.string },
    justifyContent: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignItems: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignContent: { type: COMPONENT_DSL_PROP_TYPES.string },
    order: { type: COMPONENT_DSL_PROP_TYPES.string },
    flex: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexGrow: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexShrink: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignSelf: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Palette */
    color: { type: COMPONENT_DSL_PROP_TYPES.string },
    backgroundColor: { type: COMPONENT_DSL_PROP_TYPES.string },
    style: { type: COMPONENT_DSL_PROP_TYPES.object },
    css: { type: COMPONENT_DSL_PROP_TYPES.css },
};
// Implemented in src/component-schemas/composite-components/FileDropZoneSymbol
export const BuilderComponentsFileDropZone = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFileDropZone,
    displayName: 'File Dropzone',
    icon: 'container',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FileDropZone',
    },
    overlayOutlineWhenEmpty: true,
    schema: Object.assign(Object.assign({ dndTargetPropName: 'children' }, getSysMinSizeStyle()), { presentations: [visibleByCondition], props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), commonBasicProps), builderComponentsFileDropZoneProps), { 
            /** Component Settings */
            accept: { type: COMPONENT_DSL_PROP_TYPES.string }, maxFileSize: { type: COMPONENT_DSL_PROP_TYPES.number }, showFileList: { type: COMPONENT_DSL_PROP_TYPES.boolean }, exceededFileSizeText: { type: COMPONENT_DSL_PROP_TYPES.string }, wrongFileTypeText: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            } }) }),
    settings: BuilderComponentsFileDropZoneSettings,
};
