import React, { useCallback, useMemo } from 'react';

import styled from '@emotion/styled';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';
import pluralize from 'pluralize';

import { DialogContentWithIcon } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';

const DIALOG_ID = DASHBOARD_DIALOGS.PREVENT_DELETE_RESOURCE_DIALOG_ID;

const StyledTypography = styled(Typography)`
  font-size: 13px;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

export type PreventDeleteResourceDialogArgs = {
  resourceDisplayName?: string;
  requestDisplayNames?: string[];
};

export const PreventDeleteResourceDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<PreventDeleteResourceDialogArgs>(DIALOG_ID);

  const closePreventDeleteResourceDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const numberOfRequests = args?.requestDisplayNames?.length || 0;

  const pluralizedRequestString = useMemo(() => pluralize('request', numberOfRequests), [
    numberOfRequests,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={closePreventDeleteResourceDialog}
      data-test="resourcesManager.preventDeleteDialog"
    >
      <DialogTitle>Delete Resource</DialogTitle>
      <DialogContentWithIcon>
        <InfoOutlinedIcon color="primary" />
        <DialogContentText>
          {`Deleting the Resource "${args?.resourceDisplayName}" is not allowed`}
          <StyledTypography variant="body1">
            {`You can’t remove resource which is used in the next ${pluralizedRequestString} "${args?.requestDisplayNames?.join(
              ', ',
            )}"`}
          </StyledTypography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="success" onClick={closePreventDeleteResourceDialog}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
