import { XYCoord } from 'react-dnd';

import { NodeDSL } from '@builder/schemas';

import { isValidPathWithSchemaCheck } from '../shared/canDropOn';
import { DRAGGABLES, CORNER_SIZE_FOR_TYPE_SWITCH_NAV } from '../shared/constants';
import { DndSpec, BuildDndSpecArgs } from '../shared/types';
import { ComponentMoveMeta, MOVE_VARIANT, DND_TARGET_TYPES } from 'src/store';

import { moveRelativelyCaseDetails } from './common';

const estimateMoveType = ({
  cursorPosition,
  monitor,
  targetEl,
  isTargetExpandedBranch,
}: { isTargetExpandedBranch: boolean; cursorPosition: XYCoord } & Pick<
  BuildDndSpecArgs,
  'targetEl' | 'monitor'
>): ComponentMoveMeta['type'] => {
  const cornerSizeForTypeSwitchPreview = CORNER_SIZE_FOR_TYPE_SWITCH_NAV;
  // Let's see what the actual boundaries of the target element are
  // and where the cursor is relative to the corners of the element.
  const hoverBoundingRect = targetEl.getBoundingClientRect().toJSON();
  const hoverClientY = Math.floor(cursorPosition.y - hoverBoundingRect.top);
  const topCorner = 0;
  const bottomCorner = Math.floor(hoverBoundingRect.bottom - hoverBoundingRect.top);
  // Cases:
  const movingOverStartSide = hoverClientY < topCorner + cornerSizeForTypeSwitchPreview;
  const movingOverEndSide = hoverClientY > bottomCorner - cornerSizeForTypeSwitchPreview;
  const movingIntoTarget = hoverClientY > topCorner && hoverClientY < bottomCorner;

  // Note: case-order matters!
  switch (true) {
    case movingOverStartSide:
      return MOVE_VARIANT.before;

    case movingOverEndSide: {
      if (isTargetExpandedBranch) {
        return MOVE_VARIANT.into;
      }

      return MOVE_VARIANT.after;
    }

    case movingIntoTarget: {
      if (monitor.isOver({ shallow: true })) {
        return MOVE_VARIANT.into;
      }

      return MOVE_VARIANT.after;
    }

    default: {
      const defaultType =
        hoverClientY <= bottomCorner / 2 ? MOVE_VARIANT.before : MOVE_VARIANT.after;

      if (isTargetExpandedBranch) {
        return MOVE_VARIANT.into;
      }

      return defaultType;
    }
  }
};

const moveIntoCaseDetails = ({
  componentListDSL,
  nodeListDSL,
  item,
  isTargetExpandedBranch,
  targetNodeDSL,
}: { targetNodeDSL: NodeDSL; isTargetExpandedBranch: boolean } & Pick<
  BuildDndSpecArgs,
  'componentListDSL' | 'nodeListDSL' | 'item'
>): ComponentMoveMeta => {
  // if we are dropping into (a slot),
  // let's check whether it's available or not
  const targetComponentDSL = componentListDSL[targetNodeDSL.name];
  const targetPropName = targetComponentDSL.schema.dndTargetPropName
    ? [targetComponentDSL.schema.dndTargetPropName]
    : null;

  const targetAvailable = isValidPathWithSchemaCheck({
    nodeListDSL,
    componentListDSL,
    itemID: item.id,
    itemName: item.type === DRAGGABLES.ICON ? item.meta.name : item.name,
    intoNode: targetNodeDSL,
    intoTargetPropName: targetPropName,
  });

  const moveMeta: ComponentMoveMeta = {
    type: MOVE_VARIANT.into,
    kind: targetAvailable ? 'valid' : 'invalid',
    sourceID: item.id,
    target: {
      type: DND_TARGET_TYPES.into,
      nodeID: targetNodeDSL.id,
      propName: [targetComponentDSL.schema.dndTargetPropName ?? ''],
    },
  };

  return moveMeta;
};

/**
 * Draw a new spec depending on the target & draggable-item props & el-states.
 *
 * FOR NAVIGATOR ONLY!
 *
 * NOTE: This spec may look the same and share a common behavior with the default one
 * , but for the purpose of readability I decided to completely split them apart.
 *
 * Mixing both into one uniform spec makes it very difficult to debug issues when they appear.
 * Let's just keep them apart for now to see if a proper pattern emerges!
 */
export const buildDndSpecForNavItemOverlay = ({
  target,
  targetEl,
  item,
  monitor,
  nodeListDSL,
  componentListDSL,
  currentRootNodeID,
  collapsedNodes,
  lazyLeafMarkerMapRef,
}: BuildDndSpecArgs): DndSpec => {
  const cursorPosition = monitor.getClientOffset() as XYCoord;

  const isTargetIntoDidNotChange =
    item.dndMutableMeta?.type === MOVE_VARIANT.into &&
    item.dndMutableMeta?.target?.nodeID === target.nodeID;

  if (isTargetIntoDidNotChange) {
    // suppressing excessive movements in navigator to avoid node stuttering all over the place
    return { meta: item.dndMutableMeta as ComponentMoveMeta };
  }

  const targetNodeDSL = nodeListDSL[target.nodeID];
  const isTargetExpandedBranch = Boolean(
    !lazyLeafMarkerMapRef?.current?.[targetNodeDSL.id] && collapsedNodes?.[targetNodeDSL.id],
  );

  const type = estimateMoveType({
    cursorPosition,
    monitor,
    targetEl,
    isTargetExpandedBranch,
  });

  switch (type) {
    case MOVE_VARIANT.before:
    case MOVE_VARIANT.after: {
      const meta = moveRelativelyCaseDetails({
        componentListDSL,
        targetNodeDSL,
        nodeListDSL,
        item,
        type,
      });

      return { meta };
    }

    case MOVE_VARIANT.into:
    default: {
      const meta = moveIntoCaseDetails({
        componentListDSL,
        targetNodeDSL,
        nodeListDSL,
        item,
        isTargetExpandedBranch,
      });

      return { meta };
    }
  }
};
