var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';
import { pick } from 'ramda';
import { REST_ARGS_DSL, resourceListSelectors, ResourceAssert, REST_METHODS, } from '@builder/schemas';
import { isArray, isObject, buildUrl, getParamsFromPath } from '@builder/utils';
import { createStateRequestError } from '../../app-audit';
import { transformRequestStateProps } from '../../app-nodes';
import { useRefetchQuerySubscribe } from '../../event-subscriptions';
import { useRuntimeErrorCallbacks } from '../../providers';
import { useTransformValue, createAndRunFunction } from '../../utils';
const splitParams = (path, params = {}) => {
    const pathParamsKeys = getParamsFromPath(path);
    const pathParamsList = Object.entries(params)
        .map(([key, value]) => {
        if (isObject(value) || isArray(value)) {
            return null;
        }
        if (pathParamsKeys.includes(key)) {
            return [key, value];
        }
        return null;
    })
        .filter(el => Boolean(el));
    const queryParamsList = Object.entries(params)
        .map(([key, value]) => {
        if (isObject(value) || isArray(value)) {
            return [key, value];
        }
        if (pathParamsKeys.includes(key)) {
            return null;
        }
        return [key, value];
    })
        .filter(el => Boolean(el));
    return {
        pathParams: Object.fromEntries(pathParamsList),
        queryParams: Object.fromEntries(queryParamsList),
    };
};
const useArgsTransformer = ({ componentListDSL, resourceListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    return (requestStateDSL) => transformRequestStateProps({
        requestStateDSL,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        propListDSL: REST_ARGS_DSL,
    }, appRuntimeState, { onAppAuditNotifications }, libraries);
};
const useRestRequest = ({ axiosInstance, appStateDSL, componentListDSL, resourceListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [isCalled, setIsCalled] = useState(false);
    const transformArgs = useArgsTransformer({
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        appRuntimeState,
        libraries,
    });
    const [args, setArguments] = useState(Object.assign(Object.assign({}, appStateDSL.args), { body: appStateDSL.args.data }));
    const setArgs = (newArgs) => {
        setArguments((oldArgs) => (Object.assign(Object.assign({}, oldArgs), newArgs)));
    };
    useEffect(() => {
        setArgs(Object.assign(Object.assign({}, appStateDSL.args), { body: appStateDSL.args.data }));
    }, [appStateDSL.args]);
    const isGetRequest = args.method === REST_METHODS.GET;
    /*
      TODO:
      It is necessary to investigate why local requests are subscribing twice to fetch data correctly and fix any rendering
      issues in the app. When a local request is made, it is possible that the request is subscribed to twice, leading to two
      requests being made instead of one, resulting in issues with the rendering of the app and API Calls.
  
      The getData function is checked for the number of times it is rendered because the local functions are subscribing twice,
      causing two requests to be made. This workaround was implemented to only
      make the request on the second render if the scope is local.
    */
    const getData = useMemo(() => ({ queryParams, url, transformedArgs, }) => __awaiter(void 0, void 0, void 0, function* () {
        const { data: responseFetch } = yield axiosInstance(Object.assign(Object.assign({}, pick(['method', 'headers', 'data'], transformedArgs !== null && transformedArgs !== void 0 ? transformedArgs : {})), { params: queryParams, url }));
        return responseFetch;
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosInstance]);
    const sendRequest = useCallback((fetchOptions) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const transformer = appStateDSL.args.typeOnTransformer === 'code' || !appStateDSL.args.typeOnTransformer
            ? (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.transformer) || (args === null || args === void 0 ? void 0 : args.transformer) || `return data`
            : `return ${(_a = appStateDSL.args.onTransformerFunction) === null || _a === void 0 ? void 0 : _a.split('(')[0]}(data)`;
        const newArgs = Object.assign(Object.assign(Object.assign({}, args), fetchOptions), { data: (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.body) || (fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.data) || args.body });
        const transformedArgs = transformArgs(Object.assign(Object.assign({}, appStateDSL), { args: newArgs }));
        setIsCalled(true);
        let response;
        try {
            setLoading(true);
            setError(undefined);
            setData(undefined);
            const path = transformedArgs.url;
            const params = transformedArgs.params;
            const { pathParams, queryParams } = splitParams(path, params);
            const url = buildUrl(path, { pathParams });
            response = yield getData({ queryParams, url, transformedArgs });
            if (!response)
                return;
            const transformerData = !transformer
                ? response
                : createAndRunFunction(transformer, Object.assign(Object.assign({}, appRuntimeState), { localState: Object.assign(Object.assign({}, appRuntimeState === null || appRuntimeState === void 0 ? void 0 : appRuntimeState.localState), { data: response }) }), {
                    throwReactRenderError: true,
                    onAppFunctionError: transformError => onAppAuditNotifications([
                        createStateRequestError({
                            message: transformError.message,
                            stateID: appStateDSL.id,
                            propPath: ['args', 'transformer'],
                        }),
                    ]),
                    libraries,
                });
            const appRuntimeStateList = Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { data: transformerData }) });
            if (appStateDSL.args.typeOnCompleted === 'code' || !appStateDSL.args.typeOnCompleted) {
                createAndRunFunction(fetchOptions ? (_b = fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.onCompleted) !== null && _b !== void 0 ? _b : '' : (_d = (_c = appStateDSL.args) === null || _c === void 0 ? void 0 : _c.onCompleted) !== null && _d !== void 0 ? _d : '', appRuntimeStateList, {
                    libraries,
                });
            }
            if (appStateDSL.args.typeOnCompleted === 'function') {
                createAndRunFunction(fetchOptions
                    ? (_e = fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.onCompletedFunction) !== null && _e !== void 0 ? _e : ''
                    : (_g = (_f = appStateDSL.args) === null || _f === void 0 ? void 0 : _f.onCompletedFunction) !== null && _g !== void 0 ? _g : '', appRuntimeStateList, {
                    libraries,
                });
            }
            setData(transformerData);
            return Promise.resolve(transformerData);
        }
        catch (err) {
            if (appStateDSL.args.typeOnError === 'code' || !appStateDSL.args.typeOnError) {
                createAndRunFunction(fetchOptions ? (_h = fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.onError) !== null && _h !== void 0 ? _h : '' : (_k = (_j = appStateDSL.args) === null || _j === void 0 ? void 0 : _j.onError) !== null && _k !== void 0 ? _k : '', Object.assign(Object.assign({}, appRuntimeState), { localState: Object.assign(Object.assign({}, appRuntimeState === null || appRuntimeState === void 0 ? void 0 : appRuntimeState.localState), { error: err }) }), {
                    libraries,
                });
            }
            if (appStateDSL.args.typeOnError === 'function') {
                createAndRunFunction(fetchOptions
                    ? (_l = fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.onErrorFunction) !== null && _l !== void 0 ? _l : ''
                    : (_o = (_m = appStateDSL.args) === null || _m === void 0 ? void 0 : _m.onErrorFunction) !== null && _o !== void 0 ? _o : '', appRuntimeState, {
                    libraries,
                });
            }
            setError(err);
            return Promise.reject(err);
        }
        finally {
            setLoading(false);
        }
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appRuntimeState, appStateDSL, args]);
    useEffect(() => {
        const checkTrigger = Object.assign(Object.assign({}, appStateDSL), { isSaved: appStateDSL.isSaved });
        if (!args.isTriggeredManually && !isCalled && isGetRequest && checkTrigger.isSaved) {
            sendRequest();
        }
    }, [isCalled, isGetRequest, sendRequest, appStateDSL, args.isTriggeredManually]);
    const transformedArgs = transformArgs(Object.assign(Object.assign({}, appStateDSL), { args: Object.assign(Object.assign({}, args), { data: args.body }) }));
    return {
        data,
        loading,
        error,
        refetch: sendRequest,
        run: sendRequest,
        name: appStateDSL.name,
        type: appStateDSL.type,
        method: transformedArgs.method,
        url: transformedArgs.url,
        headers: transformedArgs.headers,
        params: transformedArgs.params,
        body: transformedArgs.data,
        setArgs,
    };
};
export const useAppEngineRestState = ({ appStateDSL, componentListDSL, resourceListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    const resourceRestDSL = resourceListSelectors.getResourceDSL(resourceListDSL, {
        resourceID: appStateDSL.resourceID,
    });
    ResourceAssert.Schema.assertIsRestResource(resourceRestDSL);
    const transformer = useTransformValue(appRuntimeState, libraries);
    const axiosInstance = useMemo(() => axios.create({
        baseURL: transformer(resourceRestDSL.endpoint),
    }), [transformer, resourceRestDSL.endpoint]);
    const result = useRestRequest({
        axiosInstance,
        appStateDSL,
        appRuntimeState,
        resourceListDSL,
        assetListDSL,
        componentListDSL,
        libraries,
    });
    useRefetchQuerySubscribe({
        stateID: appStateDSL.id,
        refetch: result.refetch,
    });
    return result;
};
