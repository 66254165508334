import { assocPath, path } from 'ramda';

import { PropAssert, nodeSelectors } from '@builder/schemas';

import {
  DashboardState,
  DND_TARGET_TYPES,
  ComponentMoveMeta,
  isValidTargetToMove,
} from '../../common';
import { assertIsNotPage, parseReactNode } from '../utils';
import { dashboardSelectors } from 'src/store';

/**
 * Cases:
 * - [x] moving into
 *  - [x] a page
 *  - [x] a component
 *
 * - [x] moving relative to a component
 *  - [x] withing its parent
 *  - [x] outside its parent
 *
 * - [x] important notes:
 *  - [x] Dropping a branch into itself is forbidden!
 *  - [x] Dropping a component into its current parent makes no sense and should be skipped.
 *
 * - [ ] TODO: check if target is a layout
 *
 * @param state
 * @param event
 */
export const componentMove = (state: DashboardState, place: ComponentMoveMeta): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);

  // NOTE: @place.targetID could be a page (their parent is null)!

  // disable drop into itself
  if (place.sourceID === place.target.nodeID) {
    return state;
  }

  if (place.kind === 'invalid') {
    return state;
  }

  const sourceComponent = nodeListDSL[place.sourceID];
  const sourceParentID = assertIsNotPage(sourceComponent.parentID);
  const sourceParent = nodeListDSL[sourceParentID];
  const sourceParentSchemaDSL = componentListDSL[sourceParent.name].schema.props;
  const sourceParentProps = sourceParent.props;
  const sourceParentProp = nodeSelectors.findNodePlacedPropNameInParent(
    sourceParentSchemaDSL,
    sourceParentProps,
    sourceComponent.id,
  );
  const sourceParentChildren = parseReactNode(path(sourceParentProp, sourceParent.props));
  PropAssert.Value.assertIsRenderableNodeProp(sourceParentChildren);

  const sourceParentIndex = sourceParentChildren.nodes.indexOf(place.sourceID);

  const targetParentID =
    place.type === 'into'
      ? place.target.nodeID
      : assertIsNotPage(nodeListDSL[place.target.nodeID].parentID);
  const targetParent = nodeListDSL[targetParentID];
  const targetParentSchemaDSL = componentListDSL[targetParent.name].schema.props;
  const targetParentProps = targetParent.props;
  const targetParentProp =
    place.target.type === DND_TARGET_TYPES.relative
      ? nodeSelectors.findNodePlacedPropNameInParent(
          targetParentSchemaDSL,
          targetParentProps,
          place.target.nodeID,
        )
      : place.target.propName;

  const targetParentChildren = parseReactNode(path(targetParentProp, targetParent.props));
  PropAssert.Value.assertIsRenderableNodeProp(targetParentChildren);

  const targetParentIndex =
    targetParentChildren.nodes.indexOf(place.target.nodeID) + (place.type === 'after' ? 1 : 0);

  if (!isValidTargetToMove(nodeListDSL, sourceComponent.id, targetParentID)) {
    return state;
  }

  const withinItsParent = sourceParentID === targetParentID;
  const moveType = withinItsParent
    ? 'simple-relative-sorting-within-parent'
    : 'complex-outside-movement';

  switch (moveType) {
    case 'simple-relative-sorting-within-parent': {
      if (sourceParentIndex === targetParentIndex || targetParentIndex === -1) {
        return state;
      }

      const updatedSourceParentProps = assocPath(
        sourceParentProp,
        {
          ...sourceParentChildren,
          nodes: [
            ...sourceParentChildren.nodes
              .slice(0, targetParentIndex)
              .filter(v => v !== sourceComponent.id),
            sourceComponent.id,
            ...sourceParentChildren.nodes
              .slice(targetParentIndex)
              .filter(v => v !== sourceComponent.id),
          ],
        },
        sourceParent.props,
      );

      return {
        ...state,
        appConfiguration: {
          ...state.appConfiguration,
          appDSL: {
            ...state.appConfiguration.appDSL,
            nodes: {
              ...nodeListDSL,
              [sourceParentID]: {
                ...sourceParent,
                props: {
                  ...updatedSourceParentProps,
                },
              },
            },
          },
        },
      };
    }

    case 'complex-outside-movement': {
      const newSourceNodes = sourceParentChildren.nodes.slice();
      newSourceNodes.splice(sourceParentIndex, 1);
      const newTargetNodes = targetParentChildren.nodes.slice();
      newTargetNodes.splice(targetParentIndex, 0, sourceComponent.id);

      const updatedSourceParentProps = assocPath(
        sourceParentProp,
        {
          ...sourceParentChildren,
          nodes: newSourceNodes,
        },
        sourceParent.props,
      );

      const updatedTargetParentProps = assocPath(
        targetParentProp,
        {
          ...targetParentChildren,
          nodes:
            place.type === 'into'
              ? [...targetParentChildren.nodes, sourceComponent.id]
              : newTargetNodes,
        },
        targetParent.props,
      );

      return {
        ...state,
        appConfiguration: {
          ...state.appConfiguration,
          appDSL: {
            ...state.appConfiguration.appDSL,
            nodes: {
              ...nodeListDSL,
              [sourceParentID]: {
                ...sourceParent,
                props: {
                  ...updatedSourceParentProps,
                },
              },
              [targetParentID]: {
                ...targetParent,
                props: {
                  ...updatedTargetParentProps,
                },
              },

              // updating source
              [sourceComponent.id]: {
                ...sourceComponent,
                parentID: targetParentID,
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
