import { ERROR_SCOPES, SchemaValidationError } from '@builder/utils';
import { COMPONENT_DSL_PROP_TYPES } from '../../constants';
import { PropSchemaChecker } from './PropSchemaChecker';
/**
 * Throw runtime error if prop type in the component DSL has incorrect attributes.
 */
export const PropSchemaAssert = {
    assertIsNotEmpty(propDSL, propName) {
        if (!PropSchemaChecker.isNotEmpty(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop schema for prop "${propName}" isn't found.`);
        }
    },
    assertIsReactNodeProp(propDSL, propName) {
        if (!PropSchemaChecker.isReactNodeProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} type is expected to be a ${COMPONENT_DSL_PROP_TYPES.reactNode} type, but its prop schema is ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsCSSProp(propDSL, propName) {
        if (!PropSchemaChecker.isCSSProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'css', targetStylePropName: string }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsStyleProp(propDSL, propName) {
        if (!PropSchemaChecker.isStyleProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'style', props?: {} }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsObjectProp(propDSL, propName) {
        if (!PropSchemaChecker.isObjectProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'object', props?: {} }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsArrayProp(propDSL, propName) {
        if (!PropSchemaChecker.isArrayProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'array', item: {} }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsCallbackComponentProp(propDSL, propName) {
        if (!PropSchemaChecker.isCallbackComponentProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'callback', body: 'component', args: [] }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsCallbackCodeProp(propDSL, propName) {
        if (!PropSchemaChecker.isCallbackCodeProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'callback', body: 'component', args: [] }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsActionProp(propDSL, propName) {
        if (!PropSchemaChecker.isActionProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'action', body: 'component', args: [] }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsAssetProp(propDSL, propName) {
        if (!PropSchemaChecker.isAssetProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'asset' }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsCustomPropsProp(propDSL, propName) {
        if (!PropSchemaChecker.isCustomProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'asset' }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
    assertIsFieldValidationProp(propDSL, propName) {
        if (!PropSchemaChecker.isfieldValidationProp(propDSL)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} schema should be in the next format: "{ type: 'asset' }", but got ${JSON.stringify(propDSL)}.`);
        }
    },
};
