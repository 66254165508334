/* eslint-disable @typescript-eslint/ban-ts-comment, import/no-webpack-loader-syntax */
// @ts-ignore
import APP_STATE_TYPINGS from '!!raw-loader!@builder/app-engine/src/app-state/predefined-states/types.ts';
// @ts-ignore
import APOLLO_TYPINGS from '!!raw-loader!@builder/types-generators/src/types-templates/apollo-client.global.d.ts.template';
// @ts-ignore
import REACT_TYPINGS from '!!raw-loader!@types/react/index.d.ts';
/* eslint-enable @typescript-eslint/ban-ts-comment, import/no-webpack-loader-syntax */
/**
 * Generate global types for monaco editor
 * @types/react
 * ./apollo-client.global.d.ts.template
 * ../state-types.d.ts
 */
export class GlobalTypesGenerator {
    generateTypes() {
        return [
            {
                content: REACT_TYPINGS,
                filePath: 'ts:react.d.ts',
            },
            {
                content: APOLLO_TYPINGS,
                filePath: 'ts:apollo.d.ts',
            },
            {
                // We use it as global typings so it doesn't work with export declarations.
                content: APP_STATE_TYPINGS.replace(/export/gm, ''),
                filePath: 'ts:app-state.d.ts',
            },
        ];
    }
}
