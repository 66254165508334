var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { ClickAwayListener, Popper as MuiPopper, } from '@mui/material';
import { bindToggle, usePopupState, bindPopper } from 'material-ui-popup-state/hooks';
export const Popper = forwardRef((_a, ref) => {
    var { target, content, forceOpen, __nodeAlias, style, css: cssProp, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, className, lang, translate } = _a, popperProps = __rest(_a, ["target", "content", "forceOpen", "__nodeAlias", "style", "css", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "className", "lang", "translate"]);
    const popupState = usePopupState({
        variant: 'popper',
        popupId: __nodeAlias,
    });
    const bindPopperState = useMemo(() => bindPopper(popupState), [popupState]);
    const bindToggleState = useMemo(() => bindToggle(popupState), [popupState]);
    const open = forceOpen === undefined ? bindPopperState.open : forceOpen;
    return (_jsxs("div", Object.assign({ ref: ref, style: style, css: cssProp, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, className: className, lang: lang, translate: translate }, { children: [_jsx("div", Object.assign({}, bindToggleState, { style: { display: 'inline-flex' } }, { children: target })), _jsx(MuiPopper, Object.assign({}, bindPopperState, popperProps, { open: open }, { children: _jsx(ClickAwayListener, Object.assign({ onClickAway: popupState.close }, { children: _jsx("div", { children: content({ onMouseLeave: bindPopperState.onMouseLeave }) }) })) }))] })));
});
