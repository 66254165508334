var _a, _b;
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialToggleButton } from '../MaterialToggleButton/MaterialToggleButton';
import { MaterialToggleButtonGroupSettings } from './MaterialToggleButtonGroup.settings';
export const MaterialToggleButtonGroup = {
    name: COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
    displayName: 'Toggle Button Group',
    icon: 'toggleGroup',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'ToggleButtonGroup',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.array,
                    name: 'ToggleButtonGroup',
                    defaultValue: [],
                },
            ],
            props: {
                row: false,
                size: 'medium',
                color: 'primary',
                orientation: 'horizontal',
                value: '{{ __STATES__.ToggleButtonGroup.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: 'typeof value === "object" ? __STATES__.ToggleButtonGroup.setValue(value) : __STATES__.ToggleButtonGroup.setValue([value])',
                    },
                },
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialFormLabel,
                            alias: 'Toggle Button Group Label',
                            props: {
                                component: 'label',
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Toggle Button Group',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialToggleButton,
                            props: Object.assign(Object.assign({}, (_a = MaterialToggleButton.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { value: 'value1' }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.MaterialToggleButton,
                            props: Object.assign(Object.assign({}, (_b = MaterialToggleButton.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { value: 'value2' }),
                        },
                    ],
                },
                style: getPredefinedStyles({ display: 'flex' }),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, sx: { type: COMPONENT_DSL_PROP_TYPES.string }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, exclusive: { type: COMPONENT_DSL_PROP_TYPES.boolean }, classes: { type: COMPONENT_DSL_PROP_TYPES.object }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialToggleButton],
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string || COMPONENT_DSL_PROP_TYPES.array }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: 'value',
                        path: [1],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.string,
                        },
                    },
                ],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: MaterialToggleButtonGroupSettings,
};
