import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsTimePickerSettings } from './BuilderComponentsTimePicker.settings';
export const BuilderComponentsTimePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsTimePicker,
    displayName: 'Time Picker',
    icon: 'timepicker',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'TimePicker',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'timePicker',
                    defaultValue: '' || undefined,
                },
            ],
            props: {
                ampm: true,
                showToolbar: true,
                minutesStep: 1,
                open: false,
                openTo: 'hours',
                hours: true,
                minutesView: true,
                seconds: false,
                responsiveness: 'desktop',
                orientation: 'portrait',
                inputFormat: 'HH:mm:ss',
                inputVariant: 'outlined',
                label: 'Time Picker',
                minTime: '00:00:00',
                maxTime: '23:59:59',
                value: '{{ __STATES__.timePicker.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.timePicker.setValue(value)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'time',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, inputFormat: { type: COMPONENT_DSL_PROP_TYPES.string }, inputValue: { type: COMPONENT_DSL_PROP_TYPES.string }, inputVariant: { type: COMPONENT_DSL_PROP_TYPES.enum }, label: { type: COMPONENT_DSL_PROP_TYPES.string }, maxTime: { type: COMPONENT_DSL_PROP_TYPES.string }, minTime: { type: COMPONENT_DSL_PROP_TYPES.string }, minutesStep: { type: COMPONENT_DSL_PROP_TYPES.number }, onAccept: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'time',
                        path: [0],
                    },
                ],
            }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'error',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onviewChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'view',
                        path: [0],
                    },
                ],
            }, openTo: { type: COMPONENT_DSL_PROP_TYPES.enum }, responsiveness: { type: COMPONENT_DSL_PROP_TYPES.enum }, hours: { type: COMPONENT_DSL_PROP_TYPES.boolean }, minutesView: { type: COMPONENT_DSL_PROP_TYPES.boolean }, seconds: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showToolbar: { type: COMPONENT_DSL_PROP_TYPES.boolean }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, views: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.string,
                },
            }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, ampm: { type: COMPONENT_DSL_PROP_TYPES.boolean }, ampmInClock: { type: COMPONENT_DSL_PROP_TYPES.boolean }, 
            // RegExp
            rifmFormatter: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'str',
                        path: [0],
                    },
                ],
            } }),
    },
    settings: BuilderComponentsTimePickerSettings,
};
