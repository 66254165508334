import { pipe } from 'ramda';
import { assocSettings } from '../../common';
import { NS_ID } from '../../common/constants';
import { getCommonFormFieldActionSettings, getCommonFormFieldPropertiesSettings, } from '../../common/settings';
import { getBuilderComponentsSliderSettings } from '../../material-components/BuilderComponentsSlider/BuilderComponentsSlider.settings';
const PREFIX_PATH = ['sliderProps'];
const basicBuilderComponentsSliderSettings = getBuilderComponentsSliderSettings(PREFIX_PATH);
export const BuilderComponentsFormSliderSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldPropertiesSettings(), ...settings.children] }))))(basicBuilderComponentsSliderSettings.properties),
    style: basicBuilderComponentsSliderSettings.style,
    events: pipe(assocSettings([NS_ID.events.root, NS_ID.events.events], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldActionSettings(), ...settings.children] }))))(basicBuilderComponentsSliderSettings.events),
};
