import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

import {
  IconLabelContainer,
  IconSettingsContainer,
  SettingsListItemContainer,
} from '../../common/components/SettingsListItem/SettingsListItem.styles';
import { AccordionSection } from 'src/shared/components';

export const ActionItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  padding: ${({ theme }) => `0 ${theme.spacing(1.5)}`};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const ActionAccordionSection = styled(AccordionSection)`
  & .MuiAccordion-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding: 8px 12px 12px;
  }
  & .MuiAccordionDetails-root {
    padding-top: 4px;
  }
  & .MuiAccordionSummary-expandIconWrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const PopupContent = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1.5)};
  width: ${({ theme }) => theme.layout.RightPanel.size}px;
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
  border-radius: 8px;
`;

export const PopupTitle = styled(Typography)`
  font-weight: 600;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  margin: -${({ theme }) => theme.spacing(1.5)};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const EventListItemContainer = styled.div<{ color: string }>`
  ${SettingsListItemContainer} {
    border: 0;
    background-color: ${({ theme }) => theme.palette.background.default};
    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.primary.main};
      ${IconLabelContainer},${IconSettingsContainer} {
        svg {
          path {
            fill: ${({ theme }) => theme.palette.text.primary};
          }
        }
      }
      & .MuiTypography-root {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
  }
  & .MuiTypography-root {
    color: ${({ color }) => color};
  }
  ${IconLabelContainer} {
    svg {
      path {
        fill: ${({ theme }) => theme.palette.grey[400]};
      }
    }
  }
`;
