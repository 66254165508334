import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { commonComponentProps } from '../../common/commonProps';
import { P_SETTINGS } from './p.settings';
export const p = {
    name: COMPONENT_DSL_NAMES.p,
    displayName: COMPONENT_DSL_NAMES.p,
    icon: 'paragraph',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'p',
    },
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 30, minHeight: 30 }),
            },
        },
        props: Object.assign({}, commonComponentProps),
    },
    settings: P_SETTINGS,
};
