var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_6_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.6.2';
    }
    getChangeList() {
        return [
            `Move all label related props to the labelProps prop`,
            `Change schema for an items prop for "node" and "node-list" type in settings`,
        ];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case 'BuilderComponentsCheckbox':
            case 'BuilderComponentsSwitch': {
                const _a = nodeDSL.props, { label, labelPlacement } = _a, otherProps = __rest(_a, ["label", "labelPlacement"]);
                const labelProps = label || labelPlacement
                    ? {
                        label,
                        labelPlacement,
                    }
                    : undefined;
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, otherProps), { labelProps }) });
            }
            case 'BuilderComponentsFormCheckbox': {
                const _b = nodeDSL.props, { checkboxProps } = _b, otherProps = __rest(_b, ["checkboxProps"]);
                const _c = checkboxProps, { label, labelPlacement } = _c, otherCheckbpxProps = __rest(_c, ["label", "labelPlacement"]);
                const labelProps = label || labelPlacement
                    ? {
                        label,
                        labelPlacement,
                    }
                    : undefined;
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, otherProps), { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        checkboxProps: Object.assign(Object.assign({}, otherCheckbpxProps), { labelProps }) }) });
            }
            case 'BuilderComponentsFormSwitch': {
                const _d = nodeDSL.props, { switchProps = {} } = _d, otherProps = __rest(_d, ["switchProps"]);
                const _e = switchProps, { label, labelPlacement } = _e, otherSwitchProps = __rest(_e, ["label", "labelPlacement"]);
                const labelProps = label || labelPlacement
                    ? {
                        label,
                        labelPlacement,
                    }
                    : undefined;
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, otherProps), { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        switchProps: Object.assign(Object.assign({}, otherSwitchProps), { labelProps }) }) });
            }
            default:
                return nodeDSL;
        }
    }
    migrateSettingDSL(setting, appDSL) {
        switch (setting.type) {
            case 'node-list':
            case 'node': {
                const newItemSettings = Object.entries(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setting.items).map(([componentName, componentSettings]) => ({
                    name: componentName,
                    settings: this.migrateSettingListDSL(componentSettings, appDSL),
                }));
                return Object.assign(Object.assign({}, setting), { items: newItemSettings });
            }
            case 'rootSection':
            case 'section':
            case 'sub-section':
            case 'mixed':
            case 'popup':
            case 'popupsSection':
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-fallthrough
            case 'settings-picker':
            case 'action': {
                return Object.assign(Object.assign({}, setting), { children: this.migrateSettingListDSL(setting.children, appDSL) });
            }
            case 'list': {
                return Object.assign(Object.assign({}, setting), { settings: this.migrateSettingListDSL(setting.settings, appDSL) });
            }
            default:
                return setting;
        }
    }
    migrateUserComponentListDSL(userComponentListDSL, appDSL) {
        return mapObjIndexed(userComponent => {
            if (!userComponent.schema.symbolNodes) {
                return userComponent;
            }
            return Object.assign(Object.assign({}, userComponent), { schema: Object.assign(Object.assign({}, userComponent.schema), { props: this.migratePropListDSL(userComponent.schema.props, appDSL), symbolNodes: mapObjIndexed(nodeDSL => this.migrateNodeDSL(nodeDSL), userComponent.schema.symbolNodes) }), settings: this.migrateUserComponentSettingListDSL(userComponent.settings, appDSL) });
        }, userComponentListDSL);
    }
}
export const migration_0_6_2 = new Migration_0_6_2();
