import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getStylesSection, getActionSettings, getBasicPropsSettings } from '../../common';
export const BasePageLayoutSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['drawerOpen'],
                    label: 'Drawer Open',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.number,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['drawerOpenWidth'],
                    label: 'Drawer Open Width',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.number,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['drawerClosedWidth'],
                    label: 'Drawer Closed Width',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['drawerAnchor'],
                    label: 'Drawer Anchor',
                    options: ['left', 'right'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['drawerVariant'],
                    label: 'Drawer Variant',
                    options: ['permanent', 'persistent', 'temporary'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                    type: COMPONENT_SETTING_TYPES.prop,
                    hasFxButton: true,
                    keyPath: ['drawerOnClose'],
                    label: 'Drawer On Close',
                },
                ...getBasicPropsSettings(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
