var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Migration } from '../Migration';
class Migration_0_8_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.8.3';
    }
    getChangeList() {
        return [
            `DatePicker: add ability to set "Input Variant" prop`,
            `DatePicker: remove "Variant" prop because there is no such prop in the Material Docs`,
            `DatePicker: remove "Landscape" prop because this prop doesn't work with such type of DatePicker (It works only with StaticDatePicker)`,
            `DatePicker: remove "Max Date Message" prop`,
            `DatePicker: remove "Min Date Message" prop`,
            `DatePicker: remove "Invalid Date message" prop`,
        ];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case 'BuilderComponentsDatePicker': {
                const _a = nodeDSL.props, { variant, orientation, invalidDateMessage, maxDateMessage, minDateMessage } = _a, otherProps = __rest(_a, ["variant", "orientation", "invalidDateMessage", "maxDateMessage", "minDateMessage"]);
                return Object.assign(Object.assign({}, nodeDSL), { props: otherProps });
            }
            case 'BuilderComponentsFormDatePicker': {
                const _b = nodeDSL.props, { datePickerProps } = _b, otherProps = __rest(_b, ["datePickerProps"]);
                const _c = (datePickerProps || {}), { variant, orientation, invalidDateMessage, maxDateMessage, minDateMessage } = _c, otherDatePickerProps = __rest(_c, ["variant", "orientation", "invalidDateMessage", "maxDateMessage", "minDateMessage"]);
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, otherProps), { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        datePickerProps: Object.assign({}, otherDatePickerProps) }) });
            }
            default:
                return nodeDSL;
        }
    }
}
export const migration_0_8_3 = new Migration_0_8_3();
