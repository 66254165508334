import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonSymbolProps } from '../../common/commonProps';
import { BaseLayoutSettings } from './BaseLayout.settings';
export const BaseLayout = {
    name: COMPONENT_DSL_NAMES.BaseLayout,
    displayName: 'Base Layout',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.layout,
    memoization: {
        disable: true,
    },
    schema: {
        dndTargetPropName: 'content',
        accessibility: {
            hideBreadcrumb: true,
            hideOverlay: true,
        },
        interaction: COMPONENT_DSL_INTERACTION_TYPES.none,
        props: Object.assign(Object.assign({}, commonSymbolProps), { content: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, header: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, router: {
                type: COMPONENT_DSL_PROP_TYPES.string,
            } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout,
            props: {
                path: '{{ symbolProps.path }}',
                exact: true,
                authAccess: 'any',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.div,
                            props: {
                                style: {
                                    display: 'flex',
                                    flex: '1 1 0%',
                                    flexDirection: 'column',
                                    height: '100%',
                                },
                                children: '{{ symbolProps.content }}',
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: BaseLayoutSettings,
};
