import styled from '@emotion/styled';
import { Paper, Typography, Grid, FormControlLabel } from '@mui/material';

import { Button, ButtonProps } from 'src/shared/components';

const SPACING_EDITOR_HEIGHT = 180;
const SPACING_EDITOR_LAYOUT = {
  height: '180px',
  topAndBottomPaddingWidth: '50px',
  // 2 * leftAndRightMarginsWidth + paddingsSectionWidth = 100%
  leftAndRightMarginsWidth: '22%',
  paddingsSectionWidth: '56%',
  // 2 * topAndBottomMarginsHeight + paddingsSectionHeight = 100%
  topAndBottomMarginsHeight: '22%',
  paddingsSectionHeight: '56%',
};

export const SpacingViewEditorContent = styled.div`
  position: relative;
`;

export const MarginWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: ${SPACING_EDITOR_LAYOUT.height};
  border: ${({ theme }) => `1px solid ${theme.palette.border.input}`};
`;

export const PaddingWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border: ${({ theme }) => `1px solid ${theme.palette.border.input}`};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: ${SPACING_EDITOR_LAYOUT.paddingsSectionHeight};
  width: ${SPACING_EDITOR_LAYOUT.paddingsSectionWidth};
`;

export const ElementPlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: ${SPACING_EDITOR_LAYOUT.topAndBottomPaddingWidth};
  height: 4px;
`;

export const Label = styled.span`
  position: absolute;
  left: 4px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.typography.caption.fontSize}px;
`;

const CommonButton = styled(Button)<ButtonProps>`
  padding: 0;
  min-width: auto;
  border-radius: 0;
  color: ${({ theme }) => theme.palette.common.white};
  text-transform: initial;
  position: absolute;
  overflow: hidden;
  font-weight: normal;
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  :hover {
    opacity: 1;
  }
`;

export const MarginTopButton = styled(CommonButton)`
  top: 0;
  left: ${SPACING_EDITOR_LAYOUT.leftAndRightMarginsWidth};
  height: ${SPACING_EDITOR_LAYOUT.topAndBottomMarginsHeight};
  width: ${SPACING_EDITOR_LAYOUT.paddingsSectionWidth};
`;

export const MarginRightButton = styled(CommonButton)`
  right: 0;
  height: ${SPACING_EDITOR_LAYOUT.height};
  width: ${SPACING_EDITOR_LAYOUT.leftAndRightMarginsWidth};
`;

export const MarginBottomButton = styled(CommonButton)`
  bottom: 0;
  left: ${SPACING_EDITOR_LAYOUT.leftAndRightMarginsWidth};
  height: ${SPACING_EDITOR_LAYOUT.topAndBottomMarginsHeight};
  width: ${SPACING_EDITOR_LAYOUT.paddingsSectionWidth};
`;

export const MarginLeftButton = styled(CommonButton)`
  left: 0;
  height: ${SPACING_EDITOR_HEIGHT}px;
  width: ${SPACING_EDITOR_LAYOUT.leftAndRightMarginsWidth};
`;

export const PaddingTopButton = styled(CommonButton)`
  top: 0;
  width: ${SPACING_EDITOR_LAYOUT.topAndBottomPaddingWidth};
  left: 50%;
  transform: translate(-50%, 0);
  height: calc(50% - 2px);
`;

export const PaddingRightButton = styled(CommonButton)`
  right: 0;
  height: 100%;
  width: calc((100% - ${SPACING_EDITOR_LAYOUT.topAndBottomPaddingWidth}) / 2);
`;

export const PaddingBottomButton = styled(CommonButton)`
  bottom: 0;
  width: ${SPACING_EDITOR_LAYOUT.topAndBottomPaddingWidth};
  left: 50%;
  transform: translate(-50%, 0);
  height: calc(50% - 2px);
`;

export const PaddingLeftButton = styled(CommonButton)`
  left: 0;
  height: 100%;
  width: calc((100% - ${SPACING_EDITOR_LAYOUT.topAndBottomPaddingWidth}) / 2);
`;

export const PopperContent = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
`;

export const PopperTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-transform: capitalize;
`;

export const PopperTextFieldContent = styled(Grid)`
  width: 150px;
`;

export const CheckboxContent = styled(FormControlLabel)`
  width: 100%;
`;
