import { values } from 'ramda';
import { COMPONENT_DSL_TYPES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_7_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.7.0';
    }
    getChangeList() {
        return ['New Icon for group components'];
    }
    migrateUserComponentListDSL(userComponentListDSL) {
        const userComponentSymbolArrayDSL = values(userComponentListDSL).filter(userComponent => userComponent.type === COMPONENT_DSL_TYPES.symbol);
        const migratedUserComponentListDSL = Object.assign({}, userComponentListDSL);
        for (const userComponentSymbolDSL of userComponentSymbolArrayDSL) {
            migratedUserComponentListDSL[userComponentSymbolDSL.name] = Object.assign(Object.assign({}, userComponentSymbolDSL), { icon: 'customGroup' });
        }
        return migratedUserComponentListDSL;
    }
}
export const migration_0_7_0 = new Migration_0_7_0();
