import React from 'react';

import { Tooltip } from '@mui/material';

import { AnchorPoint, StyledAnchorWrapper } from './DraggableAnchor.styles';

type DraggableAnchorProps = {
  width: number;
  onDragStart: (event: React.MouseEvent<Node>) => void;
};

export const DraggableAnchor: React.FC<DraggableAnchorProps> = ({ width, onDragStart }) => {
  return (
    <StyledAnchorWrapper>
      <Tooltip disableInteractive placement="top" title={width}>
        <AnchorPoint onMouseDown={onDragStart} />
      </Tooltip>
    </StyledAnchorWrapper>
  );
};
