import { assocPath, isEmpty } from 'ramda';
import { put, select, takeEvery } from 'redux-saga/effects';

import {
  ComponentListDSL,
  forEachNodeListProps,
  NodeListDSL,
  ForEachNodePropsArgs,
  PropChecker,
  NodeDSL,
  COMPONENT_SETTING_ACTION_TYPE,
  DynamicSelectValue,
  nodeListSelectors,
  hasPropJsCode,
  UpdateStateArgs,
} from '@builder/schemas';
import { serialize } from '@builder/utils';

import {
  DashboardContextUpdateEvents,
  DASHBOARD_EVENTS,
  Store,
  UI_BUILDER_EVENTS,
  updateNodeIfJsCode,
  updateStringArguments,
  updateIteratorData,
  updateNavigationEvent,
} from '../../common';
import { dashboardSelectors } from 'src/store';

import { getReplaceText, replaceReference } from './utils';

type DashComponentUpdateManyEvent = Extract<
  DashboardContextUpdateEvents,
  { type: typeof DASHBOARD_EVENTS.componentUpdateMany }
>;

function* updateStateForRelatedNodes(
  event: Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateUpdate }>,
) {
  const nodeListDSL: NodeListDSL = yield select((store: Store) =>
    dashboardSelectors.getNodeListDSL(store.dashboard),
  );
  const componentListDSL: ComponentListDSL = yield select((store: Store) =>
    dashboardSelectors.getComponentListDSL(store.dashboard),
  );

  const updatedNodeListDSL: NodeListDSL = { ...nodeListDSL };

  const updateStateName = (nodePropsArgs: ForEachNodePropsArgs) => {
    const nodeDSL = updatedNodeListDSL[nodePropsArgs.nodeDSL.id];

    updateNodeIfJsCode(nodePropsArgs, nodeDSL, updatedNodeListDSL, event);

    updateStringArguments(nodePropsArgs, nodeDSL, updatedNodeListDSL, event);

    updateIteratorData(nodeDSL, event, updatedNodeListDSL);
  };

  forEachNodeListProps(updateStateName, componentListDSL, nodeListDSL);

  yield put<DashComponentUpdateManyEvent>({
    type: DASHBOARD_EVENTS.componentUpdateMany,
    nodes: updatedNodeListDSL,
  });
}

function* updateNavigationEventForRelatedNodes(
  event: Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateUpdate }>,
) {
  const nodeListDSL: NodeListDSL = yield select((store: Store) =>
    dashboardSelectors.getNodeListDSL(store.dashboard),
  );
  const componentListDSL: ComponentListDSL = yield select((store: Store) =>
    dashboardSelectors.getComponentListDSL(store.dashboard),
  );

  const updatedNodeListDSL: NodeListDSL = { ...nodeListDSL };
  const nodesUpdated = [] as NodeDSL[];
  const updatePath = (nodePropsArgs: ForEachNodePropsArgs) => {
    const nodeDSL = updatedNodeListDSL[nodePropsArgs.nodeDSL.id];
    updateNavigationEvent(nodePropsArgs, nodeDSL, updatedNodeListDSL, event, nodesUpdated);
  };

  forEachNodeListProps(updatePath, componentListDSL, nodeListDSL);

  const routeParent = nodesUpdated.length
    ? nodeListSelectors.getRouteParent(nodeListDSL, nodesUpdated[0])
    : undefined;

  yield put<DashComponentUpdateManyEvent>({
    type: DASHBOARD_EVENTS.componentUpdateMany,
    nodes: updatedNodeListDSL,
    isFromRouteupdate: routeParent,
  });
}

function* removeStateForRelatedNodes(
  event: Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateRemove }>,
  isConnectedState = false,
) {
  if (event.state) {
    const nodeListDSL: NodeListDSL = yield select((store: Store) =>
      dashboardSelectors.getNodeListDSL(store.dashboard),
    );

    const looperContentNodes = nodeListSelectors.getAllLooperContentNodes(nodeListDSL);

    const componentListDSL: ComponentListDSL = yield select((store: Store) =>
      dashboardSelectors.getComponentListDSL(store.dashboard),
    );

    const updatedNodeListDSL: NodeListDSL = { ...nodeListDSL };

    const updateNode = ({ nodeDSL, propPath }: { nodeDSL: NodeDSL; propPath: string[] }) => {
      updatedNodeListDSL[nodeDSL.id] = assocPath(['props', ...propPath], undefined, nodeDSL);
    };

    const removeStateEvents = (nodePropsArgs: ForEachNodePropsArgs) => {
      if (PropChecker.Value.isActionPropWithArgs(nodePropsArgs.propValue)) {
        const nodeDSL = updatedNodeListDSL[nodePropsArgs.nodeDSL.id];
        switch (nodePropsArgs.propValue.actionType) {
          case COMPONENT_SETTING_ACTION_TYPE.functionCode:
            {
              const { nameFunction }: DynamicSelectValue = serialize.parse(
                String(nodePropsArgs.propValue.args?.function) || '',
              ) as DynamicSelectValue;

              if (nameFunction === event.state?.name) {
                updateNode({
                  nodeDSL,
                  propPath: [...nodePropsArgs.propPath, nodePropsArgs.propName],
                });
              }
            }

            break;

          case COMPONENT_SETTING_ACTION_TYPE.runRequest:
            {
              const requestID = nodePropsArgs.propValue.args?.requestID as string;

              if (requestID === event.id) {
                updateNode({
                  nodeDSL,
                  propPath: [...nodePropsArgs.propPath, nodePropsArgs.propName],
                });
              }
            }

            break;

          case COMPONENT_SETTING_ACTION_TYPE.updateState:
            {
              const stateID = (nodePropsArgs.propValue.args?.stateArgs as UpdateStateArgs)
                .stateID as string;

              if (stateID === event.id) {
                updateNode({
                  nodeDSL,
                  propPath: [...nodePropsArgs.propPath, nodePropsArgs.propName],
                });
              }
            }

            break;
        }
      }
    };

    const removeReferenceOnLoopers = (nodePropsArgs: ForEachNodePropsArgs) => {
      const looperContentNodeDSL = updatedNodeListDSL[nodePropsArgs.nodeDSL.id];
      const loopingNodeListDSL = nodeListSelectors
        .getAllChildrenIDs(updatedNodeListDSL, {
          componentListDSL,
          nodeID: looperContentNodeDSL.id,
        })
        .map(nodeId => updatedNodeListDSL[nodeId]);
      const looperItemName = nodePropsArgs.nodeDSL?.iterator?.name;
      const isLooperReferencingState =
        nodePropsArgs.nodeDSL?.iterator?.data &&
        hasPropJsCode(nodePropsArgs.nodeDSL?.iterator?.data) &&
        nodePropsArgs.nodeDSL?.iterator?.data.includes(`${event?.state?.name.trim()}.`);

      if (isLooperReferencingState) {
        updatedNodeListDSL[looperContentNodeDSL.id] = assocPath(
          ['iterator', 'data'],
          [],
          looperContentNodeDSL,
        );

        if (loopingNodeListDSL?.length && looperItemName) {
          loopingNodeListDSL.forEach(node => {
            const replaceText = getReplaceText(node, nodeListDSL, componentListDSL);
            const updatedNode = replaceReference(node, looperItemName, replaceText);
            updatedNodeListDSL[node.id] = { ...updatedNode };
          });
        }
      }
    };

    forEachNodeListProps(removeReferenceOnLoopers, componentListDSL, looperContentNodes);
    forEachNodeListProps(removeStateEvents, componentListDSL, nodeListDSL);

    if (!isConnectedState) {
      yield put({
        type: UI_BUILDER_EVENTS.notificationSend,
        notification: {
          message:
            'You may have broken references in those components with related events after deleting functions/requests/states.',
          options: {
            variant: 'warning',
          },
        },
      });
    }

    yield put<DashComponentUpdateManyEvent>({
      type: DASHBOARD_EVENTS.componentUpdateMany,
      nodes: updatedNodeListDSL,
    });
  }
}

function* removeConnectedStatesForRelatedNodes(
  event: Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.componentRemove }>,
) {
  const { connectedStates } = event;
  if (connectedStates && !isEmpty(connectedStates)) {
    for (const state of connectedStates) {
      const removeStateEvent = {
        type: DASHBOARD_EVENTS.stateRemove,
        id: state.id,
        state,
      };
      yield* removeStateForRelatedNodes(removeStateEvent, true);
    }

    yield put({
      type: UI_BUILDER_EVENTS.notificationSend,
      notification: {
        message:
          'You may have broken references in those components with related events after deleting components with connected states.',
        options: {
          variant: 'warning',
        },
      },
    });
  }
}

export function* watchStateChange(): Generator {
  yield takeEvery(DASHBOARD_EVENTS.stateUpdate, updateStateForRelatedNodes);
  yield takeEvery(DASHBOARD_EVENTS.stateUpdateType, updateStateForRelatedNodes);
  yield takeEvery(DASHBOARD_EVENTS.stateRemove, removeStateForRelatedNodes);
  yield takeEvery(DASHBOARD_EVENTS.componentRemove, removeConnectedStatesForRelatedNodes);
  yield takeEvery(DASHBOARD_EVENTS.routeCurrentUpdate, updateNavigationEventForRelatedNodes);
}
