import { createSelector } from 'reselect';
import { ERROR_SCOPES, SchemaError } from '@builder/utils';
import { STATE_CATEGORY, STATE_REQUEST_VARIANTS, STATE_SCOPES, STATE_TYPES, STATE_TYPE_CATEGORIES_MAPPER, } from '../constants';
import { sortAlphabetically } from '../utils/sortAlphabetically';
import * as nodeListSelectors from './node-list-selectors';
import { getResourceID } from './resource-list-selectors';
import * as stateSelectors from './state-selectors';
const getStateListDSL = (stateListDSL) => stateListDSL;
const getStateID = (_, props) => props.id;
const getStateName = (_, props) => props.name;
const getNodeListDSL = (_, props) => props.nodeListDSL;
const getNodeDSL = (_, props) => props.nodeDSL;
const getCurrentPathname = (_, props) => props.currentPathname;
const getComponentListDSL = (_, props) => props.componentListDSL;
export const getStateArrayDSL = createSelector(getStateListDSL, statesDSL => Object.values(statesDSL));
export const getStateNameArrayDSL = createSelector(getStateArrayDSL, statesArrayDSL => statesArrayDSL.map(stateDSL => stateDSL.name));
export const getStateDSL = createSelector(getStateListDSL, getStateID, (statesDSL, stateID) => {
    if (!statesDSL[stateID]) {
        throw new SchemaError(ERROR_SCOPES.schemas, `State with id ${stateID} wasn't found.`);
    }
    return statesDSL[stateID];
});
export const isStateDSLNameNotUnique = createSelector(getStateArrayDSL, (_, props) => props, (stateListDSL, props) => {
    const { currentName, currentScope, currentParentId } = props;
    let isDuplicated = false;
    if (!(currentName === null || currentName === void 0 ? void 0 : currentName.trim())) {
        return isDuplicated;
    }
    // eslint-disable-next-line array-callback-return
    isDuplicated = stateListDSL.some(state => {
        const { scope, name, parent } = state;
        if (parent === undefined && scope === 'global') {
            return name === currentName;
        }
        if (parent === currentParentId && currentScope === 'local') {
            return name === currentName;
        }
        if (currentScope === 'global') {
            return name === currentName;
        }
    });
    return isDuplicated;
    // return stateListDSL.some(stateDSL => stateDSL.name === stateName.trim());
});
export const isGlobalStateDSL = createSelector(getStateDSL, stateDSL => stateDSL.scope === STATE_SCOPES.global);
export const isLocalStateDSL = createSelector(getStateDSL, stateDSL => stateDSL.scope === STATE_SCOPES.local);
export const getCustomStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.type !== STATE_TYPES.function &&
        stateDSL.type !== STATE_TYPES.query &&
        stateDSL.id !== 'errorState' &&
        stateDSL.variant !== 'open-close') {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getCustomStateArrayDSL = createSelector(getCustomStateListDSL, statesDSL => Object.values(statesDSL));
export const getFunctionStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.type === STATE_TYPES.function) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getFunctionLocalStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.type === STATE_TYPES.function && stateDSL.scope === STATE_SCOPES.local) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getFunctionLocalStateArrayDSL = createSelector(getFunctionLocalStateListDSL, statesDSL => sortAlphabetically(Object.values(statesDSL), 'name'));
export const getFunctionStateArrayDSL = createSelector(getFunctionStateListDSL, statesDSL => Object.values(statesDSL));
export const getFunctionArgumentsByName = createSelector(getFunctionStateArrayDSL, getStateName, (functionStatesArrayDSL, name) => {
    const functionStateDSL = functionStatesArrayDSL.find(functionStateDSLElement => functionStateDSLElement.name === name);
    return (functionStateDSL === null || functionStateDSL === void 0 ? void 0 : functionStateDSL.arguments) || [];
});
export const getFunctionGlobalStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.type === STATE_TYPES.function && stateDSL.scope === STATE_SCOPES.global) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getFunctionGlobalStateArrayDSL = createSelector(getFunctionGlobalStateListDSL, statesDSL => sortAlphabetically(Object.values(statesDSL), 'name'));
export const getQueryStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.type === STATE_TYPES.query) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getQueryStateArrayDSL = createSelector(getQueryStateListDSL, statesDSL => Object.values(statesDSL));
export const getQueryStateNamesByResource = createSelector(getQueryStateArrayDSL, getResourceID, (statesListArray, resource) => statesListArray
    .filter(queryStateDSL => queryStateDSL.resourceID === resource)
    .map(queryStateDSL => queryStateDSL.name));
/**
 * @returns Global queries and local queries which connected to route on the current route
 */
export const getAccessibleOnRouteQueryStateListDSL = createSelector(getQueryStateListDSL, getComponentListDSL, getNodeListDSL, getCurrentPathname, (queryStates, componentListDSL, nodeListDSL, currentPathname) => {
    return Object.keys(queryStates).reduce((accum, stateID) => {
        const queryStateDSL = queryStates[stateID];
        const nodeCurrentPage = nodeListSelectors.getCurrentRouteNodeDSL(nodeListDSL, {
            currentPathname,
        });
        const isStateAccessibleOnRoute = stateSelectors.isStateAccessibleOnRoute(queryStateDSL, {
            componentListDSL,
            nodeListDSL,
            currentPathname,
        });
        if (nodeCurrentPage.id === queryStateDSL.parent) {
            return Object.assign(Object.assign({}, accum), { [stateID]: queryStateDSL });
        }
        if (isStateAccessibleOnRoute) {
            return Object.assign(Object.assign({}, accum), { [stateID]: queryStateDSL });
        }
        return accum;
    }, {});
});
/**
 * @returns Global queries and local queries which connected to node or it children
 */
export const getAccessibleOnNodeQueryStateListDSL = createSelector(getQueryStateListDSL, getNodeListDSL, (_, props) => props.nodeID, (queryStates, nodeListDSL, nodeID) => {
    return Object.keys(queryStates).reduce((accum, stateID) => {
        const queryStateDSL = queryStates[stateID];
        const isStateAccessibleOnNode = stateSelectors.isStateAccessibleOnNode(queryStateDSL, {
            nodeListDSL,
            nodeID,
        });
        if (isStateAccessibleOnNode) {
            return Object.assign(Object.assign({}, accum), { [stateID]: queryStateDSL });
        }
        return accum;
    }, {});
});
export const getAccessibleOnNodeQueryStateArrayDSL = createSelector(getAccessibleOnNodeQueryStateListDSL, queriesListDSL => {
    return Object.values(queriesListDSL);
});
/**
 * @returns Global queries and local states which connected to node or it children
 */
export const getAccessibleOnNodeStateListDSL = createSelector(getStateListDSL, getNodeListDSL, (_, props) => props.nodeID, (statList, nodeListDSL, nodeID) => {
    return Object.keys(statList).reduce((accum, stateID) => {
        const stateDSL = statList[stateID];
        const isStateAccessibleOnNode = stateSelectors.isStateAccessibleOnNode(stateDSL, {
            nodeListDSL,
            nodeID,
        });
        if (isStateAccessibleOnNode) {
            return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
        }
        return accum;
    }, {});
});
/**
 * @returns Global queries and local states which connected to node or it partner
 */
export const getAccessibleOnNodeStatesWithScopeListDSL = createSelector(getStateListDSL, getNodeListDSL, (_, props) => props.nodeID, (statList, nodeListDSL, nodeID) => {
    return Object.keys(statList).reduce((accum, stateID) => {
        const stateDSL = statList[stateID];
        const isStateAccessibleOnNode = stateSelectors.isStateAccessibleOnNodeWithScope(stateDSL, {
            nodeListDSL,
            nodeID,
        });
        if (isStateAccessibleOnNode) {
            return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
        }
        return accum;
    }, {});
});
/**
 * @returns Array of global queries and local states which connected to node or it parent and can be triggered mannualy
 */
export const getAccessibleRunnableQueryWithScopeArrayDSL = createSelector(getAccessibleOnNodeStatesWithScopeListDSL, stateListDSL => {
    return getStateArrayDSL(stateListDSL).filter(stateSelectors.isRunnable);
});
export const getBooleanStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.type === STATE_TYPES.boolean) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getBooleanStateArrayDSL = createSelector(getBooleanStateListDSL, statesDSL => Object.values(statesDSL));
export const getGlobalStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.scope === STATE_SCOPES.global) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getGlobalStateArrayDSL = createSelector(getGlobalStateListDSL, statesDSL => Object.values(statesDSL));
export const getLocalStateListDSL = createSelector(getStateListDSL, statesDSL => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateDSL.scope === STATE_SCOPES.local) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getLocalStateArrayDSL = createSelector(getLocalStateListDSL, statesDSL => Object.values(statesDSL));
export const getNotConnectedLocalStateArrayDSL = createSelector(getLocalStateArrayDSL, getNodeListDSL, getNodeDSL, (stateListDSL, nodeListDSL, nodeDSL) => {
    return stateListDSL.filter(stateDSL => {
        var _a;
        if ((_a = nodeDSL.states) === null || _a === void 0 ? void 0 : _a.find(nodeState => nodeState.stateID === stateDSL.id)) {
            return true;
        }
        const nodeWithConnectedState = nodeListSelectors.getNodeWithConnectedState(nodeListDSL, {
            stateDSL,
        });
        return !nodeWithConnectedState;
    });
});
export const getLocalQueryStateArrayDSL = createSelector(getLocalStateArrayDSL, stateListDSL => stateListDSL.filter(stateDSL => stateDSL.type === STATE_TYPES.query && stateDSL.variant === STATE_REQUEST_VARIANTS.query));
export const getFilteredStatesListDSL = createSelector(getStateListDSL, (_, stateIDList) => stateIDList, (statesDSL, stateIDList) => Object.keys(statesDSL).reduce((accum, stateID) => {
    const stateDSL = statesDSL[stateID];
    if (stateIDList.some(({ stateID: nodeStateID }) => nodeStateID === stateDSL.id)) {
        return Object.assign(Object.assign({}, accum), { [stateID]: stateDSL });
    }
    return accum;
}, {}));
export const getStateArrayDSLByType = createSelector(getStateListDSL, (_, props) => props.stateDSLType, (statesDSL, stateDSLType) => {
    return Object.values(statesDSL).filter(stateDSL => {
        if (stateDSL.type === stateDSLType) {
            return true;
        }
        return false;
    });
});
export const getFilteredLocalStateListDSL = createSelector(getFilteredStatesListDSL, statesDSL => getLocalStateListDSL(statesDSL));
export const getFilteredLocalStateArrayDSL = createSelector(getFilteredLocalStateListDSL, statesDSL => Object.values(statesDSL));
/**
 * Check if any state with such name already exist
 */
export const isStateExist = createSelector(getStateListDSL, getStateName, (statesDSL, stateName) => {
    return Object.entries(statesDSL).some(([_, state]) => state.name === stateName);
});
/**
 * @returns Unique state name based on existing states
 */
export const calculateStateName = createSelector(getStateListDSL, getStateName, (statesDSL, stateName) => {
    let counter = 0;
    let calculatedName = stateName;
    while (isStateExist(statesDSL, { name: calculatedName })) {
        const state = stateName.indexOf('_') === -1 ? stateName : stateName.substring(0, stateName.indexOf('_'));
        counter += 1;
        calculatedName = `${state}_${counter}`;
    }
    return calculatedName;
});
/**
 * Create unique state based on existing states. Always has at least one counter to passed state name
 * @example
 * 'modal' => 'modal_1'
 */
export const calculateTemplateStateName = createSelector(getStateListDSL, getStateName, (statesDSL, stateName) => {
    let counter = 0;
    let calculatedName = stateName;
    while (calculatedName === stateName || isStateExist(statesDSL, { name: calculatedName })) {
        counter += 1;
        calculatedName = `${stateName}_${counter}`;
    }
    return calculatedName;
});
const shortStateCategories = (statesCategorized) => {
    if (statesCategorized.customEntries && Object.keys(statesCategorized.customEntries).length > 0) {
        const { customEntries } = statesCategorized;
        const reorderedCategories = Object.assign({ customEntries }, Object.entries(statesCategorized)
            .filter(([key]) => key !== 'customEntries')
            .reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [key]: value })), {}));
        return reorderedCategories;
    }
    return statesCategorized;
};
export const getStateCategories = (stateListDSL, state) => {
    const statesCategorized = stateListDSL.reduce((categories, stateDSL) => {
        const selectedState = state[stateDSL.name];
        const category = STATE_TYPE_CATEGORIES_MAPPER[stateDSL.type];
        if (category === STATE_CATEGORY.functions) {
            return categories;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (category && state.hasOwnProperty(stateDSL.name)) {
            return Object.assign(Object.assign({}, categories), { [category]: Object.assign(Object.assign({}, categories[category]), { [stateDSL.name]: selectedState }) });
        }
        return categories;
    }, {});
    const shortedCategories = shortStateCategories(statesCategorized);
    return shortedCategories;
};
export const getGlobalStateCategorized = createSelector([getGlobalStateArrayDSL, (_stateListDSL, state) => state], getStateCategories);
export const getLocalStateCategorized = createSelector([getLocalStateArrayDSL, (_stateListDSL, state) => state], getStateCategories);
