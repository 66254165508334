/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isNil, path } from 'ramda';
import { isNilOrEmptyArray } from '@builder/utils';
import { COMPONENT_DSL_NAMES } from '../constants';
import { appDSLSelectors, nodeListSelectors } from '../selectors';
import { PropAssert } from '../validation';
import { addAliasToAllNodes, isDialogComponent, transformNodeWithStateTreeToList, } from './schema-transformers';
/**
 * Creates new nodes from the NodeTemplate
 */
export const generateNodeDSLFromTemplate = ({ appDSL, componentListDSL, nodeListDSL, stateListDSL, }, { componentName, alias, predefinedProps = {}, predefinedStates = [], selectNodeOnCreate = true, incrementAlias = true, parentID, parentPropName, toIndex, schemaOverride, currentRouteNodeDSLId, currentRouteParentNodeDSLId, }) => {
    var _a;
    const parentNode = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: parentID });
    const parentChildrenProp = (_a = path(parentPropName, parentNode.props)) !== null && _a !== void 0 ? _a : {
        nodes: [],
    };
    PropAssert.Value.assertIsRenderableProp(parentChildrenProp, parentPropName.join('.'));
    const newStateListDSL = Object.keys(nodeListDSL).reduce((allStates, key) => {
        var _a, _b, _c;
        if (((_a = nodeListDSL[key]) === null || _a === void 0 ? void 0 : _a.context) && ((_c = Object.keys(((_b = nodeListDSL[key]) === null || _b === void 0 ? void 0 : _b.context) || {})) === null || _c === void 0 ? void 0 : _c.length)) {
            return Object.assign(Object.assign({}, allStates), nodeListDSL[key].context);
        }
        return allStates;
    }, stateListDSL || {});
    const { childrenNodes: createdChildrenNodes, rootNodeDSL, appStates: createdAppStates, connectedStates, appGlobalStates, } = transformNodeWithStateTreeToList({
        existingStates: newStateListDSL,
        componentListDSL,
        nodeStatesTemplate: predefinedStates,
        currentRouteParentNodeDSLId,
        parentID,
        nodesTree: {
            name: componentName,
            alias,
            props: predefinedProps,
            schemaOverride,
        },
        nodeListDSL,
    });
    const newUserAppState = Object.assign(Object.assign({}, appDSL), { nodes: addAliasToAllNodes({
            nodeListDSL: Object.assign(Object.assign(Object.assign({}, nodeListDSL), createdChildrenNodes), { [rootNodeDSL.id]: rootNodeDSL }),
            updateAliasForNodes: incrementAlias
                ? Object.assign(Object.assign({}, createdChildrenNodes), { [rootNodeDSL.id]: rootNodeDSL }) : {},
            componentListDSL,
        }), states: Object.assign(Object.assign(Object.assign({}, stateListDSL), (componentName === COMPONENT_DSL_NAMES.DialogSymbol ? appGlobalStates : {})), (isDialogComponent(parentID, nodeListDSL) ? appGlobalStates : {})) });
    const currentRouteWrapperNode = newUserAppState.nodes[currentRouteParentNodeDSLId];
    if (!isNilOrEmptyArray(connectedStates) &&
        !isNil(currentRouteNodeDSLId) &&
        !isDialogComponent(parentID, nodeListDSL)) {
        const [connectedState] = connectedStates;
        const newStateArray = [];
        if (rootNodeDSL.props.children) {
            // @ts-ignore: Unreachable code error
            rootNodeDSL.props.children.nodes.forEach((element) => {
                var _a, _b, _c, _d, _e, _f;
                if (
                // @ts-ignore: Unreachable code error
                ((_c = (_b = (_a = newUserAppState === null || newUserAppState === void 0 ? void 0 : newUserAppState.nodes[element]) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.predefineds) === null || _c === void 0 ? void 0 : _c.states) &&
                    // @ts-ignore: Unreachable code error
                    ((_f = (_e = (_d = newUserAppState === null || newUserAppState === void 0 ? void 0 : newUserAppState.nodes[element]) === null || _d === void 0 ? void 0 : _d.props) === null || _e === void 0 ? void 0 : _e.predefineds) === null || _f === void 0 ? void 0 : _f.states.length) > 0) {
                    const newState = Object.assign(Object.assign({}, connectedState), { componentBoundID: newUserAppState === null || newUserAppState === void 0 ? void 0 : newUserAppState.nodes[element].id });
                    // @ts-ignore: Unreachable code error
                    newStateArray.push(newState);
                }
            });
        }
        const newState = Object.assign(Object.assign({}, connectedState), { componentBoundID: rootNodeDSL.id });
        const bindStateToParentNode = (nodeId, node) => {
            var _a;
            newUserAppState.nodes[nodeId] = Object.assign(Object.assign(Object.assign({}, node), (node.context
                ? {
                    context: Object.assign(Object.assign({}, node.context), createdAppStates),
                }
                : {
                    context: createdAppStates,
                })), { states: isNil(node.states)
                    ? [newState]
                    : (_a = node.states) === null || _a === void 0 ? void 0 : _a.concat(newStateArray.length > 0 ? newStateArray : newState) });
        };
        bindStateToParentNode(currentRouteParentNodeDSLId, currentRouteWrapperNode);
    }
    // connects new node to the parent node
    const newUserAppStateWithUpdatedParent = appDSLSelectors.assocAppPath(newUserAppState, ['nodes', parentNode.id, 'props', ...parentPropName], Object.assign(Object.assign({}, parentChildrenProp), { nodes: [
            ...parentChildrenProp.nodes.slice(0, toIndex),
            rootNodeDSL.id,
            ...parentChildrenProp.nodes.slice(toIndex),
        ] }));
    return { rootNodeDSL, appDSL: newUserAppStateWithUpdatedParent };
};
