/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  ActionBroadcastEvent,
  applyEvents,
  Event,
  EventOperation,
  EventType,
  EventPayload,
  EventPayloadUpdate,
} from '@8base-private/event-handler';
import { useApolloClient } from '@apollo/client';
import { useRollbar } from '@rollbar/react';
import { nanoid as uuid } from 'nanoid';
import { unstable_batchedUpdates } from 'react-dom';
import { useStore } from 'react-redux';

import {
  AppConfiguration,
  AppDSL,
  UserComponentListDSL,
  nodeListSelectors,
} from '@builder/schemas';
import { API_STANDS, isString, processEnv } from '@builder/utils';

import { INITIAL_WORKSPACE_ID_KEY } from '../constants';
import { useConfigurationGet } from '../draft-engine/hooks';
import { useCurrentWorkspace, useSavedConfiguration } from '../graphql/hooks';
import { getEnvironmentString } from '../utils';
import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { useDialogState, useDraftEngine } from 'src/providers';
import {
  useAppConfiguration,
  useAppDispatch,
  useUserComponentListDSL,
} from 'src/providers/ReduxProvider';
import { DASHBOARD_EVENTS, UI_BUILDER_EVENTS, Store } from 'src/store';
import { assertDSL } from 'src/store/middleware/utils/DSLChecker';

import { useDashboardCurrentRouteNodeDSL } from './dashboardRouter';
import { useCurrentWorkspaceID } from './useCurrentWorkspaceID';
import { useMigrations } from './useMigrations';

type UseInitialLoadReturnType = {
  isWSConnected: boolean;
  isLoading: boolean;
  error?: string;
  shouldRedirectToDashboard: boolean;
  redirectWorkspaceID?: string;
};

const skipLoadServerData = processEnv.getUseDefaultExampleData();

const useWorkspaceRedirect = () => {
  const { workspaceID: pathnameWorkspaceID } = useCurrentWorkspaceID();
  const initialWorkspaceID = useMemo(() => localStorage.getItem(INITIAL_WORKSPACE_ID_KEY), []);
  const devTestFrontendWorkspaceID = processEnv.getDevTestFrontendWorkspaceID();

  const redirectWorkspaceID =
    pathnameWorkspaceID || initialWorkspaceID || devTestFrontendWorkspaceID;
  const shouldRedirectToDashboard = Boolean(redirectWorkspaceID);

  return {
    redirectWorkspaceID,
    shouldRedirectToDashboard,
  };
};

const shouldClearSelection = (state: Store, event: ActionBroadcastEvent): boolean => {
  const selectedId = state.dashboard.operations.selectedID;

  if (selectedId && isString(selectedId)) {
    const currentKey = `appDSL.nodes.${selectedId}`;

    const deletedKeys = event.payload
      .filter((payload: EventPayload) => payload.operation === EventOperation.delete)
      .map((payload: EventPayload) => payload.data.key);

    if (deletedKeys.length === 0) return false;

    if (deletedKeys.includes(currentKey)) return true;

    const parentNodes = nodeListSelectors.getParents(
      state.dashboard.appConfiguration.appDSL.nodes,
      { nodeID: selectedId },
    );

    return parentNodes
      .map(node => `appDSL.nodes.${node.id}`)
      .some(parentKey => parentKey === currentKey);
  }

  return false;
};

const shouldRedirectToNewPath = (
  state: Store,
  event: ActionBroadcastEvent,
): { shouldRedirect: boolean; redirectPath: string | undefined } => {
  const currentRoutePath = state.dashboard.router.currentRoute;
  const currentRouteNode = nodeListSelectors.getCurrentRouteNodeDSL(
    state.dashboard.appConfiguration.appDSL.nodes,
    { currentPathname: currentRoutePath },
  );

  if (!currentRouteNode) return { shouldRedirect: false, redirectPath: undefined };

  const filteredEvents = event.payload.filter(
    (payload: EventPayload) =>
      payload.operation === EventOperation.update &&
      payload.scope === 'global' &&
      payload.data.key.includes(currentRouteNode.id),
  );

  if (filteredEvents.length === 0) return { shouldRedirect: false, redirectPath: undefined };

  const redirectPath = filteredEvents.find(payload =>
    payload.data.key.endsWith('props.path'),
  ) as EventPayloadUpdate;

  return { shouldRedirect: true, redirectPath: redirectPath?.data.node };
};

export const useInitialLoad = (): UseInitialLoadReturnType => {
  const send = useAppDispatch();
  const { getState } = useStore();
  const rollbar = useRollbar();
  const state = getState();
  const currentAppDSL = state.dashboard.appConfiguration.appDSL as AppDSL;
  const client = useApolloClient();
  const { openDialog, closeDialog } = useDialogState();
  const initialLoadRef = useRef(true);
  const { isConnected, draftEngine } = useDraftEngine();
  const { loading: loadingCurrentWorkspace } = useCurrentWorkspace();
  const { redirectWorkspaceID, shouldRedirectToDashboard } = useWorkspaceRedirect();
  const { id: currentRouteNodeId } = useDashboardCurrentRouteNodeDSL();
  const appConfiguration = useAppConfiguration();
  const { refetch: refetchSavedConfiguration } = useSavedConfiguration();
  const { isDSLVersionMoreThanExpected } = useMigrations();
  const [refetchHash, setRefetchHash] = useState<string>();
  const { getConfiguration, getConfigurationResult } = useConfigurationGet();
  const isLoading = initialLoadRef.current ? getConfigurationResult.loading : false;
  const hasLostInternetConnection = useRef(false);
  const userComponents = useUserComponentListDSL();

  const checkLoadedDSL = useCallback(
    (appDSL: AppDSL, userComponentsDSL: UserComponentListDSL) => {
      const isDSLValid = assertDSL(appDSL, userComponentsDSL);

      if (!isDSLValid) {
        rollbar.error(`dslConflictError: DSL conflict detected in ${redirectWorkspaceID} `, {
          apiEnvironment: getEnvironmentString(API_STANDS, processEnv.getServerURL()),
          appDSL: getConfigurationResult?.data?.appConfiguration,
        });
        openDialog(DASHBOARD_DIALOGS.BROKEN_LOADED_DSL_DIALOG_ID);

        send({
          type: UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate,
          isSaveButtonDisabled: true,
        });
      }

      return isDSLValid;
    },
    [
      getConfigurationResult?.data?.appConfiguration,
      openDialog,
      redirectWorkspaceID,
      rollbar,
      send,
    ],
  );
  const setPresetantionState = useCallback(
    (appDSL: AppDSL) => {
      Object.values(appDSL.nodes).forEach(nodeDSL => {
        const isDialog = nodeDSL.name === 'LocalDialogSymbol' || nodeDSL.name === 'DialogSymbol';
        const hasCondition = Boolean(nodeDSL.condition?.showIf);

        if (isDialog || hasCondition) {
          send({
            type: UI_BUILDER_EVENTS.setVisiblePresentation,
            payload: {
              isVisible: false,
              nodeID: nodeDSL.id,
            },
          });
        }
      });
    },
    [send],
  );

  useEffect(() => {
    if (!draftEngine || skipLoadServerData) return;

    send({
      type: DASHBOARD_EVENTS.dashboardSetLoadingConfiguration,
      loading: true,
    });

    draftEngine.send({
      type: EventType.Scope,
      uuid: uuid(),
      payload: currentRouteNodeId,
    });

    draftEngine.send({
      type: EventType.State,
      uuid: uuid(),
      payload: { local: currentRouteNodeId },
    });

    getConfiguration({
      pageKey: currentRouteNodeId,
      withoutGlobal: !initialLoadRef.current,
    });
  }, [draftEngine, send, getConfiguration, currentRouteNodeId, refetchHash]);

  useEffect(() => {
    if (!getConfigurationResult.data) return;

    send({
      type: UI_BUILDER_EVENTS.designScopeInit,
      states: getConfigurationResult.data.states,
    });

    send({
      type: DASHBOARD_EVENTS.dashboardSetLoadingConfiguration,
      loading: false,
    });

    const userComponentsDSL =
      (getConfigurationResult.data.appConfiguration?.userComponentsDSL as UserComponentListDSL) ||
      userComponents ||
      {};
    if (initialLoadRef.current) {
      const isValidDSL = checkLoadedDSL(
        getConfigurationResult.data.appConfiguration.appDSL as AppDSL,
        userComponentsDSL,
      );
      if (isValidDSL) {
        send({
          type: UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate,
          isSaveButtonDisabled: false,
        });
        send({
          type: DASHBOARD_EVENTS.appConfigurationInit,
          appConfiguration: getConfigurationResult.data.appConfiguration,
        });

        send({
          type: DASHBOARD_EVENTS.dashboardSetInitialHistory,
          payload: getConfigurationResult.history,
        });
      }
    } else {
      const isValidDSL = checkLoadedDSL(
        {
          ...currentAppDSL,
          nodes: { ...getConfigurationResult.data.appConfiguration.appDSL.nodes },
        } as AppDSL,
        userComponentsDSL,
      );

      if (isValidDSL) {
        send({
          type: UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate,
          isSaveButtonDisabled: false,
        });

        send({
          type: DASHBOARD_EVENTS.appConfigurationUpdate,
          appConfiguration: getConfigurationResult.data.appConfiguration as AppConfiguration,
        });

        send({
          type: DASHBOARD_EVENTS.dashboardSetInitialHistory,
          payload: getConfigurationResult.history,
        });
      }
    }

    setPresetantionState(getConfigurationResult.data.appConfiguration.appDSL as AppDSL);
    initialLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoadedDSL, getConfigurationResult.data, send, setPresetantionState]);

  useEffect(() => {
    if (!draftEngine) return;

    const broadcastEventListener = async (event: Event) => {
      const currentState = getState();
      const currentConfiguration = currentState.dashboard.appConfiguration;

      switch (event.type) {
        case EventType.BroadcastEvent:
          const { result: updatedConfiguration } = applyEvents(currentConfiguration, {}, event);

          if (event.history) {
            send({
              type: DASHBOARD_EVENTS.dashboardSetInitialHistory,
              payload: event.history,
            });
          }

          if (shouldClearSelection(currentState, event)) {
            send({ type: DASHBOARD_EVENTS.dashboardClearSelection });
          }

          const { shouldRedirect, redirectPath } = shouldRedirectToNewPath(currentState, event);

          send({
            type: DASHBOARD_EVENTS.appConfigurationUpdate,
            appConfiguration: updatedConfiguration,
            route: shouldRedirect ? redirectPath : undefined,
          });

          if (shouldRedirect && redirectPath) {
            send({
              type: DASHBOARD_EVENTS.routerPathUpdate,
              route: redirectPath,
            });
          }

          break;
        case EventType.BroadcastSave:
          send({
            type: DASHBOARD_EVENTS.dashboardSetInitialHistory,
            payload: { future: [], past: [] },
          });
          await refetchSavedConfiguration();
          break;
        case EventType.BroadcastReplace:
          initialLoadRef.current = true;

          unstable_batchedUpdates(() => {
            setRefetchHash(uuid());
            send({ type: DASHBOARD_EVENTS.dashboardClear });
          });

          await refetchSavedConfiguration();
          break;
        case EventType.BroadcastInvalidate:
          await client.refetchQueries({
            include: [event.payload],
          });

          break;
        case EventType.BroadcastState:
          send({
            type: UI_BUILDER_EVENTS.designScopeUpdateSession,
            payload: event.payload,
          });

          break;
        case EventType.BroadcastSaveState:
          send({
            type: UI_BUILDER_EVENTS.saveState,
            havePendingChanges: event.payload.havePendingChanges,
          });
          break;
      }
    };

    draftEngine.addEventListener(broadcastEventListener);
    return () => draftEngine.removeEventListener(broadcastEventListener);
  }, [draftEngine, getState, send, refetchSavedConfiguration, setRefetchHash, client]);

  useEffect(() => {
    if (!appConfiguration || !isDSLVersionMoreThanExpected) return;

    openDialog(DASHBOARD_DIALOGS.CHECK_DSL_VERSION_DIALOG_ID, {
      appConfiguration,
    });
  }, [openDialog, appConfiguration, isDSLVersionMoreThanExpected]);

  //* This useEffect is triggered when the user's connection is restored / lost
  useEffect(() => {
    const lostConnectionDialogID = DASHBOARD_DIALOGS.LOST_CONNECTION_DIALOG_ID;
    if (!isConnected && !initialLoadRef.current) {
      hasLostInternetConnection.current = true;
      send({
        type: UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate,
        isSaveButtonDisabled: true,
      });
      hasLostInternetConnection.current = true;
      openDialog(lostConnectionDialogID);
    }

    if (
      isConnected &&
      !initialLoadRef.current &&
      draftEngine &&
      hasLostInternetConnection.current
    ) {
      getConfiguration({
        pageKey: currentRouteNodeId,
        withoutGlobal: false,
      });
      draftEngine.send({
        type: EventType.Scope,
        uuid: uuid(),
        payload: currentRouteNodeId,
      });
      closeDialog(lostConnectionDialogID);
      send({
        type: UI_BUILDER_EVENTS.isSaveButtonDisabledUpdate,
        isSaveButtonDisabled: false,
      });

      hasLostInternetConnection.current = false;
    }
  }, [
    closeDialog,
    currentRouteNodeId,
    draftEngine,
    getConfiguration,
    isConnected,
    openDialog,
    send,
  ]);

  return {
    isWSConnected: isConnected && !initialLoadRef.current,
    isLoading: isLoading || loadingCurrentWorkspace,
    error: getConfigurationResult.error,
    shouldRedirectToDashboard,
    redirectWorkspaceID,
  };
};
