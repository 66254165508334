import React from 'react';

import { ComponentDSLNameTypes, ComponentDSLTypes } from '@builder/schemas';

import { ComponentPresentationOnly } from '../ComponentPresentationOnly';
import { useDashIconDrag } from 'src/shared/dnd';

type DraggableMenuComponentItemProps = {
  meta: {
    name: ComponentDSLNameTypes;
    title: string;
    icon: string;
    type: ComponentDSLTypes;
    tooltipTitle?: string;
    currentRouteNodeDSLId?: string;
    currentRouteParentNodeDSLId?: string;
  };
  isGroup?: boolean;
  ['data-test']?: string;
  isLayoutEditorButton?: boolean;
};

export const DraggableMenuComponentItem: React.FC<DraggableMenuComponentItemProps> = ({
  meta,
  isGroup,
  'data-test': dataTest,
  isLayoutEditorButton,
}) => {
  const { isDragging, ref } = useDashIconDrag({ meta });

  return (
    <ComponentPresentationOnly
      ref={ref}
      data-test={dataTest}
      title={meta.title}
      tooltipTitle={meta.tooltipTitle}
      icon={meta.icon}
      isDragging={isDragging}
      isGroup={isGroup}
      isLayoutEditorButton={isLayoutEditorButton}
    />
  );
};
