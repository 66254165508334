/* eslint-disable camelcase */
import { path, values } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_14_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.14.2';
    }
    getChangeList() {
        return ['Rename EmptyPageLayout_ID node to BaseLayout_ID V2'];
    }
    migrateAppConfiguration(configuration) {
        var _a, _b, _c, _d;
        const { appDSL } = configuration;
        const { nodes } = appDSL;
        const layouts = (_b = (_a = nodes === null || nodes === void 0 ? void 0 : nodes.RootLayoutsContainer_ID) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.layouts;
        const leftNavLayoutChild = (_d = (_c = nodes === null || nodes === void 0 ? void 0 : nodes.LeftNavLayoutMenu_ID) === null || _c === void 0 ? void 0 : _c.props) === null || _d === void 0 ? void 0 : _d.children;
        const EMPTY_PAGE_ROUTER_SWITCH_ID = 'EmptyPageRouterSwitch_ID';
        const LEFT_NAV_ROUTER_SWITCH_ID = 'LeftNavRouterSwitch_ID';
        const LEFT_NAV_LAYOUT_MENU_ID = 'LeftNavLayoutMenu_ID';
        const ROUTER_SWITCH_SYMBOL = 'RouterSwitchSymbol';
        const EMPTY_PAGE_LAYOUT_ID = 'EmptyPageLayout_ID';
        const TAG_LAYOUT_ROUTER_SWITCH_ID = 'ayowC0uV';
        const BASE_LAYOUT_ID = 'BaseLayout_ID';
        const MOBILE_LAYOUT = 'MobileLayout';
        const BASE_LAYOUT = 'BaseLayout';
        const layoutsNodes = layouts === null || layouts === void 0 ? void 0 : layouts.nodes;
        if (path([EMPTY_PAGE_LAYOUT_ID], nodes)) {
            nodes.BaseLayout_ID = nodes.EmptyPageLayout_ID;
            const index = layoutsNodes.findIndex((item) => item === EMPTY_PAGE_LAYOUT_ID);
            layoutsNodes[index] = BASE_LAYOUT_ID;
            nodes.BaseLayout_ID.name = BASE_LAYOUT;
            nodes.BaseLayout_ID.id = BASE_LAYOUT_ID;
            nodes.EmptyPageRouterSwitch_ID.parentID = BASE_LAYOUT_ID;
            delete nodes.EmptyPageLayout_ID;
        }
        if (path([EMPTY_PAGE_ROUTER_SWITCH_ID], nodes)) {
            nodes.EmptyPageRouterSwitch_ID = Object.assign(Object.assign({}, nodes.EmptyPageRouterSwitch_ID), { hiddenLayout: true });
        }
        if (path([LEFT_NAV_LAYOUT_MENU_ID], nodes)) {
            if (leftNavLayoutChild.nodes.length === 0) {
                nodes.LeftNavRouterSwitch_ID = Object.assign(Object.assign({}, nodes.LeftNavRouterSwitch_ID), { hiddenLayout: true });
            }
        }
        values(nodes).forEach(node => {
            if (node.name === MOBILE_LAYOUT) {
                const header = node.props.header;
                const footer = node.props.footer;
                values(nodes).forEach(child => {
                    var _a, _b;
                    if (child.id === header.nodes[0] || child.id === footer.nodes[0]) {
                        const propsChild = (_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.children;
                        if (((_b = propsChild === null || propsChild === void 0 ? void 0 : propsChild.nodes) === null || _b === void 0 ? void 0 : _b.length) === 0 || !path(['children'], child.props)) {
                            values(nodes).forEach(subChild => {
                                if (subChild.name === ROUTER_SWITCH_SYMBOL &&
                                    subChild.id !== EMPTY_PAGE_ROUTER_SWITCH_ID &&
                                    subChild.id !== LEFT_NAV_ROUTER_SWITCH_ID &&
                                    subChild.id !== TAG_LAYOUT_ROUTER_SWITCH_ID) {
                                    nodes[subChild.id] = Object.assign(Object.assign({}, nodes[subChild.id]), { hiddenLayout: true });
                                }
                            });
                        }
                    }
                });
            }
        });
        return configuration;
    }
}
export const migration_0_14_2 = new Migration_0_14_2();
