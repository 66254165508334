import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';

import { useNotifier } from './hooks/useNotifier';

// I know we don't use make styles but I can't change styles of container other way https://iamhosseindhv.com/notistack/demos#custom-variants
const useStyles = makeStyles(theme => ({
  containerAnchorOriginBottomLeft: {
    left: '65px !important',
  },
}));

const Notifications: React.FC = () => {
  useNotifier();
  return null;
};

export const NotificationsProvider: React.FC = () => {
  const classes = useStyles();
  return (
    <SnackbarProvider classes={classes} preventDuplicate>
      <Notifications />
    </SnackbarProvider>
  );
};
