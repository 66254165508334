import { z } from 'zod';
import { EventOperation, EventType } from './types';
const createPayloadSchema = (operation, dataShape) => {
    return z.object({
        operation: z.enum([operation]),
        scope: z.string(),
        data: z.object(dataShape),
    });
};
export const isEventValid = (event) => {
    const schema = z.object({
        type: z.enum([EventType.Event, EventType.BroadcastEvent]),
        uuid: z.string(),
        payload: z.array(z.union([
            createPayloadSchema(EventOperation.create, {
                key: z.string(),
                node: z.any(),
            }),
            createPayloadSchema(EventOperation.update, {
                key: z.string(),
                node: z.any(),
            }),
            createPayloadSchema(EventOperation.delete, {
                key: z.string(),
            }),
            createPayloadSchema(EventOperation.arrayInsert, {
                key: z.string(),
                index: z.number(),
                item: z.any(),
            }),
            createPayloadSchema(EventOperation.arrayReplace, {
                key: z.string(),
                index: z.number(),
                item: z.any(),
            }),
            createPayloadSchema(EventOperation.arrayDelete, {
                key: z.string(),
                index: z.number(),
            }),
        ])),
    });
    return schema.safeParse(event).success;
};
