import { IMPORT_DEPENDENCY_TYPES } from '@builder/schemas';
import { IMPORT_FILE_SCOPE_TYPES, IMPORT_FILE_USAGE_TYPES } from '../../../constants';
import { RESOURCES_LIST_CONST } from '../../constants-generator/constants';
import { ASSETS_STATE_NAME, USE_ASSETS_CONST } from '../../providers-generators';
export const ROLES_STATE_NAME = 'roles';
export const RESOURCES_STATE_NAME = 'resources';
export const LIBRARIES_STATE_NAME = 'libraries';
export const ROUTES_STATE_NAME = 'router';
export const PREDEFINED_IMPORTS = [
    {
        importData: {
            importType: IMPORT_DEPENDENCY_TYPES.default,
            importName: 'react',
            varName: 'React',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.all],
            usage: IMPORT_FILE_USAGE_TYPES.var,
            omit: false,
        },
    },
    {
        importData: {
            importName: 'react',
            varName: 'Fragment',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
                IMPORT_FILE_SCOPE_TYPES.appIndex,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
            omit: false,
        },
    },
    {
        importData: {
            importName: 'react',
            varName: 'useCallback',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'react',
            varName: 'useState',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
            omit: false,
        },
    },
    {
        importData: {
            importName: 'react',
            varName: 'useEffect',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks, IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'react',
            varName: 'useMemo',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'react',
            varName: 'useRef',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'ApolloClient',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'ApolloProvider',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'InMemoryCache',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'gql',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'useLazyQuery',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'useQuery',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'useMutation',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'useApolloClient',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'useReactiveVar',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@apollo/client',
            varName: 'useSubscription',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importType: IMPORT_DEPENDENCY_TYPES.default,
            importName: 'axios',
            varName: 'axios',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'react-router-dom',
            varName: 'useHistory',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: 'routerHistory',
            omit: false,
        },
    },
    {
        importData: {
            importName: 'react-router-dom',
            varName: 'useParams',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: 'params',
        },
    },
    {
        importData: {
            importName: 'react-router-dom',
            varName: 'useLocation',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            omit: false,
            stateName: 'routerLocation',
        },
    },
    {
        importData: {
            importName: 'react-router-dom',
            varName: 'matchPath',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importType: IMPORT_DEPENDENCY_TYPES.default,
            importName: './providers/ErrorBoundaryRouter',
            varName: 'ErrorBoundaryRouter',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@mui/material',
            varName: 'ThemeProvider',
            varNameAlias: 'MuiThemeProvider',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@mui/material',
            varName: 'createTheme',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@mui/material',
            varName: 'CssBaseline',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@mui/material',
            varName: 'StyledEngineProvider',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@emotion/react',
            varName: 'css',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.appIndex,
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
            omit: false,
        },
    },
    {
        importData: {
            importName: '@emotion/react',
            varName: 'SerializedStyles',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
            omit: false,
        },
    },
    {
        importData: {
            importName: '@emotion/react',
            varName: 'Global',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@emotion/react',
            varName: 'jsx',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.appIndex,
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
            omit: false,
        },
    },
    {
        importData: {
            importName: '@emotion/react',
            varName: 'ThemeProvider',
            varNameAlias: 'EmotionThemeProvider',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@mui/x-date-pickers',
            varName: 'LocalizationProvider',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@mui/x-date-pickers/AdapterLuxon',
            varName: 'AdapterLuxon',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@emotion/react',
            varName: 'useTheme',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: 'theme',
            typeName: 'any',
            omit: false,
        },
    },
    {
        importData: {
            importName: '@8base-react/auth',
            varName: 'useAuth',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            omit: false,
            stateName: 'auth',
        },
    },
    {
        importData: {
            importName: 'react-helmet',
            varName: 'Helmet',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex, IMPORT_FILE_SCOPE_TYPES.extractedComponent],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: '@8base-react/app-provider',
            varName: 'AppProvider',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'shared/components',
            varName: 'AsyncContent',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.appIndex],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'shared/hooks',
            varName: 'useRoutes',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: ROUTES_STATE_NAME,
            omit: false,
        },
    },
    {
        importData: {
            importName: 'shared/hooks/useResourceClient',
            varName: 'useResourceClient',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'shared/hooks/predefined/useRestRequest',
            varName: 'useRestRequest',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'shared/hooks/predefined/useLazyQueryState',
            varName: 'useLazyQueryState',
        },
        options: {
            scopes: [IMPORT_FILE_SCOPE_TYPES.hooks],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'shared/constants',
            varName: RESOURCES_LIST_CONST,
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.var,
        },
    },
    {
        importData: {
            importName: 'providers',
            varName: USE_ASSETS_CONST,
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: ASSETS_STATE_NAME,
        },
    },
    {
        importData: {
            importName: 'shared/hooks',
            varName: 'useResources',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: RESOURCES_STATE_NAME,
        },
    },
    {
        importData: {
            importName: 'shared/hooks',
            varName: 'useLibraries',
        },
        options: {
            scopes: [
                IMPORT_FILE_SCOPE_TYPES.extractedComponent,
                IMPORT_FILE_SCOPE_TYPES.hooks,
                IMPORT_FILE_SCOPE_TYPES.symbols,
            ],
            usage: IMPORT_FILE_USAGE_TYPES.hook,
            stateName: LIBRARIES_STATE_NAME,
        },
    },
];
export const LIBRARY_IMPORT_OPTIONS = {
    scopes: [
        IMPORT_FILE_SCOPE_TYPES.extractedComponent,
        IMPORT_FILE_SCOPE_TYPES.hooks,
        IMPORT_FILE_SCOPE_TYPES.symbols,
    ],
    usage: IMPORT_FILE_USAGE_TYPES.var,
};
