import { EventType } from '@8base-private/event-handler';
import { MutationTuple, QueryResult, useMutation, useQuery } from '@apollo/client';
import { nanoid as uuid } from 'nanoid';

import {
  DEPLOY_SETTINGS_QUERY,
  DEPLOY_SETTINGS_UPDATE_MUTATION,
  DEPLOY_SETTINGS_CERTIFICATE_UPSERT_MUTATION,
  DEPLOY_SETTINGS_CERTIFICATE_DELETE_MUTATION,
  DEPLOY_SETTINGS_HOSTED_ZONE_CREATE_MUTATION,
  DEPLOY_SETTINGS_HOSTED_ZONE_DELETE_MUTATION,
} from '../fragments';
import { useDraftEngine } from 'src/providers';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';
import {
  DeploySettingsCertificateDeleteMutation,
  DeploySettingsCertificateDeleteMutationVariables,
  DeploySettingsCertificateUpsertMutation,
  DeploySettingsCertificateUpsertMutationVariables,
  DeploySettingsHostedZoneCreateMutation,
  DeploySettingsHostedZoneCreateMutationVariables,
  DeploySettingsHostedZoneDeleteMutation,
  DeploySettingsHostedZoneDeleteMutationVariables,
  DeploySettingsQuery,
  DeploySettingsUpdateMutation,
  DeploySettingsUpdateMutationVariables,
} from 'src/shared/graphql/__generated__';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

type UseDeploySettingsHooksReturn = {
  deploySettingsResult: QueryResult<DeploySettingsQuery>;
  deploySettingsUpdate: MutationTuple<
    DeploySettingsUpdateMutation,
    DeploySettingsUpdateMutationVariables
  >[0];
  deploySettingsUpdateResult: MutationTuple<
    DeploySettingsUpdateMutation,
    DeploySettingsUpdateMutationVariables
  >[1];
  certificateUpsert: MutationTuple<
    DeploySettingsCertificateUpsertMutation,
    DeploySettingsCertificateUpsertMutationVariables
  >[0];
  certificateUpsertResult: MutationTuple<
    DeploySettingsCertificateUpsertMutation,
    DeploySettingsCertificateUpsertMutationVariables
  >[1];
  certificateDelete: MutationTuple<
    DeploySettingsCertificateDeleteMutation,
    DeploySettingsCertificateDeleteMutationVariables
  >[0];
  certificateDeleteResult: MutationTuple<
    DeploySettingsCertificateDeleteMutation,
    DeploySettingsCertificateDeleteMutationVariables
  >[1];
  hostedZoneCreate: MutationTuple<
    DeploySettingsHostedZoneCreateMutation,
    DeploySettingsHostedZoneCreateMutationVariables
  >[0];
  hostedZoneCreateResult: MutationTuple<
    DeploySettingsHostedZoneCreateMutation,
    DeploySettingsHostedZoneCreateMutationVariables
  >[1];
  hostedZoneDelete: MutationTuple<
    DeploySettingsHostedZoneDeleteMutation,
    DeploySettingsHostedZoneDeleteMutationVariables
  >[0];
  hostedZoneDeleteResult: MutationTuple<
    DeploySettingsHostedZoneDeleteMutation,
    DeploySettingsHostedZoneDeleteMutationVariables
  >[1];
};

export const useDeploySettingsHooks = (): UseDeploySettingsHooksReturn => {
  const { draftEngine } = useDraftEngine();
  const { workspaceID } = useCurrentWorkspaceID();

  const deploySettingsResult = useQuery<DeploySettingsQuery>(DEPLOY_SETTINGS_QUERY, {
    variables: {
      workspaceId: workspaceID,
    },
    skip: workspaceID === null,
    fetchPolicy: 'no-cache',
  });

  const [deploySettingsUpdate, deploySettingsUpdateResult] = useMutation<
    DeploySettingsUpdateMutation,
    DeploySettingsUpdateMutationVariables
  >(DEPLOY_SETTINGS_UPDATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeploySettings'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully updated settings`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'DeploySettings',
        });
      }
    },
  });

  const [certificateUpsert, certificateUpsertResult] = useMutation<
    DeploySettingsCertificateUpsertMutation,
    DeploySettingsCertificateUpsertMutationVariables
  >(DEPLOY_SETTINGS_CERTIFICATE_UPSERT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeploySettings'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully uploaded certificate`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'DeploySettings',
        });
      }
    },
  });

  const [certificateDelete, certificateDeleteResult] = useMutation<
    DeploySettingsCertificateDeleteMutation,
    DeploySettingsCertificateDeleteMutationVariables
  >(DEPLOY_SETTINGS_CERTIFICATE_DELETE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeploySettings'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully deleted certificate`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'DeploySettings',
        });
      }
    },
  });

  const [hostedZoneCreate, hostedZoneCreateResult] = useMutation<
    DeploySettingsHostedZoneCreateMutation,
    DeploySettingsHostedZoneCreateMutationVariables
  >(DEPLOY_SETTINGS_HOSTED_ZONE_CREATE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeploySettings'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully created hosted zone`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'DeploySettings',
        });
      }
    },
  });

  const [hostedZoneDelete, hostedZoneDeleteResult] = useMutation<
    DeploySettingsHostedZoneDeleteMutation,
    DeploySettingsHostedZoneDeleteMutationVariables
  >(DEPLOY_SETTINGS_HOSTED_ZONE_DELETE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeploySettings'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully deleted hosted zone`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'DeploySettings',
        });
      }
    },
  });

  return {
    deploySettingsResult,
    deploySettingsUpdate,
    deploySettingsUpdateResult,
    certificateUpsert,
    certificateUpsertResult,
    certificateDelete,
    certificateDeleteResult,
    hostedZoneCreate,
    hostedZoneCreateResult,
    hostedZoneDelete,
    hostedZoneDeleteResult,
  };
};
