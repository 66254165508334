import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getCommonPropertiesSections, getDivider, getStylesSection, getActionSettings, getCommonActionSettings, getBasicPropsSettings, } from '../../common';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.nodeText,
        label: 'Text',
        keyPath: ['children'],
    },
    {
        type: COMPONENT_SETTING_TYPES.node,
        name: 'icon',
        label: 'Icon',
        keyPath: ['icon'],
        items: [
            {
                name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                settings: getCommonIconSettings(),
            },
        ],
    },
    {
        type: COMPONENT_SETTING_TYPES.node,
        name: 'action',
        label: 'Action Button',
        keyPath: ['action'],
        items: [
            {
                name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                settings: [
                    {
                        type: COMPONENT_SETTING_TYPES.nodeText,
                        keyPath: ['children'],
                        label: 'Text',
                    },
                ],
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: ['severity'],
        label: 'Severity',
        options: ['error', 'info', 'success', 'warning'].map(createSelectOptionByValue),
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: ['variant'],
        label: 'Variant',
        options: ['filled', 'outlined', 'standard'].map(createSelectOptionByValue),
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: ['closeText'],
        label: 'Close Text',
    },
    getDivider(),
    ...getBasicPropsSettings(['appBarProps']),
    getDivider(),
    ...getCommonPropertiesSections(),
];
export const MaterialAlertSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings([], [
                {
                    type: COMPONENT_SETTING_TYPES.action,
                    name: 'onClose',
                    label: 'On Close',
                    keyPath: ['onClose'],
                    children: getCommonActionSettings(['onClose']),
                },
            ]),
        },
    ],
};
