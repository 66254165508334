import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { pickSettings, getBasicPropsSettings } from '../../common';
import { NS_ID, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP } from '../../common/constants';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const MaterialTypographySettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            name: 'text',
                            keyPath: ['children'],
                            label: 'Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'paragraph',
                            keyPath: ['paragraph'],
                            label: 'Paragraph',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'variant',
                            keyPath: ['variant'],
                            options: [
                                'h1',
                                'h2',
                                'h3',
                                'h4',
                                'h5',
                                'h6',
                                'subtitle1',
                                'subtitle2',
                                'body1',
                                'body2',
                                'caption',
                                'button',
                                'overline',
                                'inherit',
                            ].map(createSelectOptionByValue),
                            label: 'Variant',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'align',
                            keyPath: ['align'],
                            options: ['inherit', 'left', 'center', 'right', 'justify'].map(createSelectOptionByValue),
                            label: 'Align',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'color',
                            keyPath: ['color'],
                            options: [
                                'initial',
                                'inherit',
                                'primary',
                                'secondary',
                                'textPrimary',
                                'textSecondary',
                                'error',
                            ].map(createSelectOptionByValue),
                            label: 'Color',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'noWrap',
                            keyPath: ['noWrap'],
                            label: 'No Wrap',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'gutterBottom',
                            keyPath: ['gutterBottom'],
                            label: 'Gutter Bottom',
                        },
                    ],
                },
                getDivider(),
                ...pipe(pickSettings([
                    [NS_ID.style.font.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.background.root],
                    [NS_ID.style.cursor.root],
                    [NS_ID.style.css.root],
                ]))(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonTypographySettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'text'],
    [NS_ID.style.root, NS_ID.style.basic, 'variant'],
    [NS_ID.style.root, NS_ID.style.basic, 'color'],
    [NS_ID.style.root, NS_ID.style.basic, 'gutterBottom'],
], {
    withStructure: false,
}))(values(MaterialTypographySettings).flat());
