import { pipe } from 'ramda';
import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils/createSelectOptionByValue';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, disableRadioOptions, disableSpacingPaddings, DISPLAY_OPTION_VALUES, getBasicPropsSettings, NS_ID, pickSettings, trimDividers, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
export const getBuilderComponentsTimePickerSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'value'],
                            label: 'Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'label'],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'inputFormat'],
                            label: 'Format',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'maxTime'],
                            label: 'Max Time',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'minTime'],
                            label: 'Min Time',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'minutesStep'],
                            label: 'Minutes Step',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'responsiveness'],
                            label: 'Responsiveness',
                            options: ['mobile', 'desktop', 'static'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'showToolbar'],
                            label: 'Show Toolbar',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'orientation'],
                            label: 'Orientation',
                            options: ['landscape', 'portrait'].map(createSelectOptionByValue),
                            showIf: [
                                {
                                    keyPath: [...prefixPath, 'showToolbar'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'ampm'],
                            label: 'AM/PM',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'ampmInClock'],
                            label: 'AM/PM In Clock',
                            showIf: [
                                {
                                    keyPath: [...prefixPath, 'ampm'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'readOnly'],
                            label: 'Read Only',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'openTo'],
                            label: 'Open To',
                            options: ['hours', 'minutes', 'seconds'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'hours'],
                            label: 'Hours View',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'minutesView'],
                            label: 'Minutes View',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'seconds'],
                            label: 'Seconds View',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'inputVariant'],
                            label: 'Input Variant',
                            options: ['standard', 'outlined', 'filled'].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], disableSpacingPaddings), assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])), pickSettings([
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.display.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.font.root, NS_ID.style.font.color],
                    [NS_ID.style.font.root, NS_ID.style.font.fontFamily],
                    [NS_ID.style.font.root, NS_ID.style.font.fontSize],
                    [NS_ID.style.font.root, NS_ID.style.font.fontWeight],
                    [NS_ID.style.font.root, NS_ID.style.font.textDecoration],
                    [NS_ID.style.size.root, NS_ID.style.size.width],
                    [NS_ID.style.size.root, NS_ID.style.size.height],
                    [NS_ID.style.background.root],
                    [NS_ID.style.border.root],
                    [NS_ID.style.css.root],
                ]), trimDividers)(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onAcceptAction',
                            label: 'On Accept',
                            keyPath: [...prefixPath, 'onAccept'],
                            children: getCommonActionSettings([...prefixPath, 'onAccept']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: [...prefixPath, 'onClose'],
                            children: getCommonActionSettings([...prefixPath, 'onClose']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onErrorAction',
                            label: 'On Error',
                            keyPath: [...prefixPath, 'onError'],
                            children: getCommonActionSettings([...prefixPath, 'onError']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onOpenAction',
                            label: 'On Open',
                            keyPath: [...prefixPath, 'onOpen'],
                            children: getCommonActionSettings([...prefixPath, 'onOpen']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'rifmFormatterAction',
                            label: 'Rifm Formatter',
                            keyPath: [...prefixPath, 'rifmFormatter'],
                            children: getCommonActionSettings([...prefixPath, 'rifmFormatter']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onViewChangeAction',
                            label: 'On View Change',
                            keyPath: [...prefixPath, 'onViewChange'],
                            children: getCommonActionSettings([...prefixPath, 'onViewChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'shouldDisableTimeAction',
                            label: 'Should Disable Time',
                            keyPath: [...prefixPath, 'shouldDisableTime'],
                            children: getCommonActionSettings([...prefixPath, 'shouldDisableTime']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsTimePickerSettings = getBuilderComponentsTimePickerSettings();
