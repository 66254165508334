/* eslint-disable no-new-func */
/* eslint-disable no-continue */
/* eslint-disable no-new */
import { ERROR_SCOPES, isString, SystemError } from '@builder/utils';
export const TRANSFORM_OUTPUT_MODE = {
    quotes: 'quotes',
    backticks: 'backticks',
};
export const checkAndTransformPropWithJsCode = (propValue, options) => {
    // if input string doesn't have handlebars at all
    if (!/\{\{.*/.test(propValue)) {
        return {
            transformedValue: null,
            originalValue: propValue,
            hasValidHandlebarJS: false,
        };
    }
    const outputMode = (options === null || options === void 0 ? void 0 : options.outputMode) || TRANSFORM_OUTPUT_MODE.quotes;
    const codeParts = [];
    // because this algorithm checks pairs str{{var}} and it will not handle case without last {{}}
    // for example foo{{1}}bar -> "foo" + 1
    let s = `${propValue}{{}}`;
    let insideString = false;
    let quoteChar = null;
    // we reduce `s` until last {{
    main: while (s.length > 0) {
        const matchOpen = s.match(/\{\{/);
        if (!matchOpen || matchOpen.index === undefined)
            break;
        // string without {{  `foo{{1}}` -> `foo`
        const str = s.substr(0, matchOpen.index);
        if (str !== '') {
            codeParts.push(outputMode === TRANSFORM_OUTPUT_MODE.quotes ? JSON.stringify(str) : str);
        }
        // `foo{{1}}bar` -> `1}}bar`
        s = s.substr(matchOpen.index + 2);
        // Check if we are inside a string
        for (const char of str) {
            if (insideString) {
                if (char === quoteChar) {
                    insideString = false;
                    quoteChar = null;
                }
            }
            else if (char === '"' || char === "'" || char === '`') {
                insideString = true;
                quoteChar = char;
            }
        }
        if (insideString) {
            // If we are inside a string, skip processing handlebars
            return {
                transformedValue: null,
                originalValue: propValue,
                hasValidHandlebarJS: false,
            };
        }
        for (const matchClose of s.matchAll(/\}(?=\})/g)) {
            if (matchClose.index === undefined)
                break;
            // `1}}bar` -> `1`
            const code = s.substr(0, matchClose.index).trim();
            try {
                // skip empty handlebars
                if (code !== '') {
                    // check `code` for syntax errors
                    new Function(`(()=>{return(${code})})`);
                    // if we can reach here then it means `code` is valid js code
                    codeParts.push(outputMode === TRANSFORM_OUTPUT_MODE.quotes ? code.trim() : `\${${code.trim()}}`);
                }
                // remove current handlebars `1}}bar{{2}}` -> `bar{{2}}`
                s = s.substr(matchClose.index + 2);
                // we found valid }}, so let go to find next {{
                continue main;
            }
            catch (e) {
                // if error then try next }}
            }
        }
        // if valid }} is not found
        return {
            transformedValue: null,
            originalValue: propValue,
            hasValidHandlebarJS: false,
        };
    }
    return {
        transformedValue: codeParts.join(outputMode === TRANSFORM_OUTPUT_MODE.quotes ? ' + ' : ''),
        originalValue: propValue,
        hasValidHandlebarJS: true,
    };
};
export const transformComplexCondition = (propValue) => {
    const complexPattern = new RegExp(`${'\\?'}` && `${'\\:'}`, 'g');
    if (complexPattern.test(propValue)) {
        return `(${propValue})`;
    }
    return propValue;
};
export const transformPropWithJsCode = (propValue, options) => {
    const result = checkAndTransformPropWithJsCode(propValue, options);
    if (!result.hasValidHandlebarJS) {
        throw new SystemError(ERROR_SCOPES.utils, `JS is expected to be valid. Please use the hasPropJsCode function to check the propValue before using this function.`);
    }
    return transformComplexCondition(result.transformedValue);
};
export const hasPropJsCode = (propValue) => {
    if (!isString(propValue)) {
        return false;
    }
    const { hasValidHandlebarJS } = checkAndTransformPropWithJsCode(propValue);
    return hasValidHandlebarJS;
};
export const STATES_RESERVED_VARIABLE = '__STATES__';
export const FORM_NAME_RESERVED_VARIABLE = '__FORM__';
export const hasPredefinedStates = (propValue) => {
    const stateReplaceRegexp = new RegExp(`${STATES_RESERVED_VARIABLE}.`, 'g');
    return stateReplaceRegexp.test(propValue);
};
export const hasPredefinedFormName = (propValue) => {
    const stateReplaceRegexp = new RegExp(`${FORM_NAME_RESERVED_VARIABLE}.`, 'g');
    return stateReplaceRegexp.test(propValue);
};
export const transformStringWithPredefinedName = (createdAppStates, propValue) => {
    let parsedValue = propValue;
    Object.values(createdAppStates).forEach(createdState => {
        parsedValue = createdState.name;
    });
    return parsedValue;
};
export const transformStringWithPredefinedState = (createdAppStates, propValue) => {
    let parsedValue = propValue;
    Object.values(createdAppStates).forEach(createdState => {
        const stateReplaceRegexp = new RegExp(`${STATES_RESERVED_VARIABLE}.${createdState.predefinedName}`, 'g');
        parsedValue = parsedValue.replace(stateReplaceRegexp, createdState.name);
    });
    return parsedValue;
};
export const replaceJSInjectionWithNewStates = (jsInjection, replacedStateArrayDSL) => {
    return replacedStateArrayDSL.reduce(replaceState, jsInjection);
};
/**
 * @example
 * replaceState(
 *   "{{ stateName }}",
 *   { newName: "newStateName", previousName: "stateName" }
 * )
 * =>
 * "{{ newStateName }}"
 * */
export const replaceState = (jsInjection, replacedState) => {
    return jsInjection.replace(new RegExp(`${replacedState.previousName}\\b`, 'g'), replacedState.newName);
};
export const validateHasHandleBars = (value) => {
    return value.includes('{{') && value.includes('}}');
};
const transformResourceFields = (resourceField) => {
    return `\${${resourceField.replace('{{', '').replace('}}', '').trim()}}`;
};
export const transformEndpointResourceValue = (resourceField) => {
    return validateHasHandleBars(resourceField)
        ? transformResourceFields(resourceField)
        : `${resourceField}`;
};
export const transform8baseEndpointResourceValue = (resourceField) => {
    return validateHasHandleBars(resourceField)
        ? `\`${transformResourceFields(resourceField)}\``
        : `'${resourceField}'`;
};
export const removeHandleBars = (value) => {
    return validateHasHandleBars(value)
        ? `${value.replace('{{', '').replace('}}', '').trim()}`
        : `'${value}'`;
};
