import { COMPONENT_SCHEMAS, makeNodeAliasUnique } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_4_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.4.0';
    }
    getChangeList() {
        return [`Make all node alias unique`];
    }
    migrateAppConfiguration(configuration) {
        const { appDSL, userComponentsDSL: userComponentListDSL } = configuration;
        const componentListDSL = Object.assign(Object.assign({}, COMPONENT_SCHEMAS), userComponentListDSL);
        const migratedNodesListDSL = makeNodeAliasUnique(appDSL.nodes, componentListDSL);
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: migratedNodesListDSL }) });
    }
}
export const migration_0_4_0 = new Migration_0_4_0();
