import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getBasicPropsSettings, NS_ID } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getFormPropertySections, getStyleSections, } from '../../common/settings';
export const BuilderComponentsFormSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getFormPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onSubmitAction',
                            label: 'On Submit',
                            keyPath: ['onSubmit'],
                            children: getCommonActionSettings(['onSubmit']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
