import * as React from 'react';
import * as MaterialUiCoreComponents from '@mui/material';
import * as ReactRouterDOM from 'react-router-dom';
import * as BuilderComponents from '@builder/components';
import { componentListSelectors, COMPONENT_DSL_IMPORT_SOURCES, COMPONENT_DSL_TYPES, nodeListSelectors, } from '@builder/schemas';
import { ERROR_SCOPES, SchemaError } from '@builder/utils';
const getReactComponentBySchema = (componentListDSL, componentName) => {
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, { componentName });
    if (!(componentDSL === null || componentDSL === void 0 ? void 0 : componentDSL.type)) {
        throw new SchemaError(ERROR_SCOPES.utils, `"type" not found in ${componentName}.`);
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.htmlElement) {
        return componentDSL.source.htmlElement;
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.reactImportComponent &&
        componentDSL.source.importName === COMPONENT_DSL_IMPORT_SOURCES.materialUICore) {
        return MaterialUiCoreComponents[componentDSL.source.componentName];
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.reactImportComponent &&
        (componentDSL.source.importName === COMPONENT_DSL_IMPORT_SOURCES.builderComponents ||
            componentDSL.source.importName === COMPONENT_DSL_IMPORT_SOURCES.sharedComponents)) {
        return BuilderComponents[componentDSL.source.componentName];
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.reactImportComponent &&
        componentDSL.source.importName === COMPONENT_DSL_IMPORT_SOURCES.reactRouterDOM) {
        return ReactRouterDOM[componentDSL.source.componentName];
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.reactImportComponent &&
        componentDSL.source.importName === COMPONENT_DSL_IMPORT_SOURCES.react) {
        return React[componentDSL.source.componentName];
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.symbol) {
        const rootNode = nodeListSelectors.getRootNodeDSL(componentDSL.schema.symbolNodes);
        return getReactComponentBySchema(componentListDSL, rootNode.name);
    }
    if (componentDSL.type === COMPONENT_DSL_TYPES.textComponent) {
        return React.Fragment;
    }
    throw new SchemaError(ERROR_SCOPES.utils, `Schema for ${componentDSL.name} component not found.`);
};
export const getReactComponentByName = (componentListDSL, componentName) => {
    return getReactComponentBySchema(componentListDSL, componentName);
};
