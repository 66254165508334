import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basicTextFieldDSL from './style-guide-dsl/TextFieldStyleGuide/basicTextField.json';
import colorDSL from './style-guide-dsl/TextFieldStyleGuide/color.json';
import multilineDSL from './style-guide-dsl/TextFieldStyleGuide/multiline.json';
import sizeDSL from './style-guide-dsl/TextFieldStyleGuide/size.json';
import textFieldWithIconsDSL from './style-guide-dsl/TextFieldStyleGuide/textFieldWithIcons.json';
import variantsDSL from './style-guide-dsl/TextFieldStyleGuide/variants.json';
export const TextFieldStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiTextField,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
    dirName: 'TextFieldStyleGuide',
    title: 'Text Field',
    classNames: ['.MuiTextField-root'],
    stories: [
        {
            title: 'Basic Text Field',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'standard',
                        label: undefined,
                        value: 'Hello world',
                    },
                },
                {
                    props: {
                        variant: 'standard',
                        label: undefined,
                        placeholder: 'Placeholder',
                    },
                },
                {
                    props: {
                        variant: 'standard',
                        label: undefined,
                        value: 'Disabled',
                        disabled: true,
                    },
                },
                {
                    props: {
                        label: undefined,
                        variant: 'standard',
                        value: 'Error',
                        error: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: basicTextFieldDSL,
        },
        {
            title: 'Variants',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                    },
                },
                {
                    props: {
                        variant: 'filled',
                    },
                },
                {
                    props: {
                        variant: 'standard',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: variantsDSL,
        },
        {
            title: 'Multiline',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        label: 'Multiline',
                        multiline: true,
                        maxRows: '4',
                    },
                },
                {
                    props: {
                        label: 'Multiline with Placeholder',
                        multiline: true,
                        maxRows: '4',
                        placeholder: 'Placeholder',
                    },
                },
                {
                    props: {
                        label: 'Multiline with rows',
                        multiline: true,
                        rows: '4',
                        value: 'Default Value',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: multilineDSL,
        },
        {
            title: 'Text Field with Icons',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                        InputProps: {
                            startAdornment: {
                                nodes: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        alias: 'Text field Icon',
                                        props: {
                                            name: 'Help',
                                            variant: 'filled',
                                            color: 'inherit',
                                            fontSize: 'medium',
                                            viewBox: '0 0 24 24',
                                            style: {
                                                display: 'flex',
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                {
                    props: {
                        variant: 'filled',
                        InputProps: {
                            startAdornment: {
                                nodes: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        alias: 'Text field Icon',
                                        props: {
                                            name: 'Help',
                                            variant: 'filled',
                                            color: 'inherit',
                                            fontSize: 'medium',
                                            viewBox: '0 0 24 24',
                                            style: {
                                                display: 'flex',
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                {
                    props: {
                        variant: 'standard',
                        InputProps: {
                            startAdornment: {
                                nodes: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        alias: 'Text field Icon',
                                        props: {
                                            name: 'Help',
                                            variant: 'filled',
                                            color: 'inherit',
                                            fontSize: 'medium',
                                            viewBox: '0 0 24 24',
                                            style: {
                                                display: 'flex',
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: textFieldWithIconsDSL,
        },
        {
            title: 'Size',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        size: 'small',
                        variant: 'outlined',
                        value: 'Small',
                        label: 'Small',
                    },
                },
                {
                    props: {
                        size: 'small',
                        variant: 'filled',
                        value: 'Small',
                        label: 'Small',
                    },
                },
                {
                    props: {
                        size: 'small',
                        variant: 'standard',
                        value: 'Small',
                        label: 'Small',
                    },
                },
                {
                    props: {
                        size: 'medium',
                        variant: 'outlined',
                        value: 'Medium',
                        label: 'Medium',
                    },
                },
                {
                    props: {
                        size: 'medium',
                        variant: 'filled',
                        value: 'Medium',
                        label: 'Medium',
                    },
                },
                {
                    props: {
                        size: 'medium',
                        variant: 'standard',
                        value: 'Medium',
                        label: 'Medium',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: sizeDSL,
        },
        {
            title: 'Color',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsTextField,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                        color: 'secondary',
                        label: 'Outlined secondary',
                    },
                },
                {
                    props: {
                        variant: 'filled',
                        color: 'success',
                        label: 'Filled success',
                    },
                },
                {
                    props: {
                        variant: 'standard',
                        color: 'warning',
                        label: 'Standard warning',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: colorDSL,
        },
    ],
};
