import React, { forwardRef, useMemo } from 'react';

import { CloseOutlined as CloseOutlinedIcon } from '@mui/icons-material';
import { AlertColor, Typography } from '@mui/material';
import { SnackbarMessage, SnackbarKey, OptionsObject } from 'notistack';

import { StateScopeTypes } from '@builder/dsl-validator/dist/mjs/schemas';
import { capitalize } from '@builder/utils';

import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { useIssuesFinder } from 'src/features/issues-manager/hooks/useIssuesFinder';
import { useDialogState } from 'src/providers/DialogProvider';
import { useAppDispatch, useNodeListDSL, useUserAppErrors } from 'src/providers/ReduxProvider';
import { VIEW_STATES } from 'src/shared/hooks';
import { functionDataTypes, LEFT_PANEL_TABS, UI_BUILDER_EVENTS } from 'src/store';

import { emptyIssue } from './constants';
import {
  SnackbarContainer,
  SnackbarContent,
  StyledAlert,
  StyledIconButton,
  StyledTypography,
  StyledTypographyIssue,
  StyledTypographyView,
  StyleTypographyIssues,
  StyledTypographyViewAction,
} from './Snackbar.styles';
import { issuesSelector } from './utils';

type SnackbarProps = {
  snackbarKey: SnackbarKey | undefined;
  message: SnackbarMessage;
  functionData: functionDataTypes;
  options: OptionsObject;
  title?: string;
  actionButtonTitle?: string;
  actionButtonCallback?: () => void;
  action?: () => void;
};

export const Snackbar: React.FC<SnackbarProps> = forwardRef<HTMLDivElement, SnackbarProps>(
  // 'ref' is used for correct animations
  (
    {
      snackbarKey,
      message,
      options,
      functionData,
      title,
      actionButtonCallback,
      actionButtonTitle,
      action,
    },
    ref,
  ) => {
    const send = useAppDispatch();
    const nodeListDSL = useNodeListDSL();
    const { errors, warnings } = useUserAppErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const issue = useMemo(() => issuesSelector(errors, warnings, nodeListDSL, message), [
      errors,
      warnings,
    ]);
    const { updatePanel } = useIssuesFinder(issue ?? emptyIssue);
    const { closeDialog } = useDialogState();
    const isCorrectSeverity = Boolean(options?.variant && options.variant !== 'default');

    const handleClick = () => {
      updatePanel();
    };

    const handleOpenFunction = () => {
      closeDialog(DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID);
      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          currentTab: LEFT_PANEL_TABS.functions,
          viewPanel: VIEW_STATES.item,
          scope: functionData.scope as StateScopeTypes,
          targetID: functionData._id,
          args: {
            isAffectOnDashboardLayout: false,
          },
        },
      });
    };

    const snackBarTitle = React.useMemo(() => {
      if (title) {
        return title;
      }

      return isCorrectSeverity ? capitalize(options.variant) : 'Info';
    }, [isCorrectSeverity, title, options.variant]);

    const handleActionButton = () => {
      actionButtonCallback && actionButtonCallback();
    };

    const closeSnackbar = () => {
      send({ type: UI_BUILDER_EVENTS.closeSnackbar, key: snackbarKey });
    };

    const openIssueManager = () => {
      closeSnackbar();
      send({
        type: UI_BUILDER_EVENTS.setIssueManagerVisibility,
        payload: {
          isVisible: true,
        },
      });
    };

    return (
      <SnackbarContainer ref={ref}>
        <SnackbarContent>
          {isCorrectSeverity && <StyledAlert severity={options.variant as AlertColor} />}
          <Typography variant="h6">
            {snackBarTitle}
            <StyledTypography>{message} </StyledTypography>
            {issue && (
              <StyledTypographyIssue onClick={handleClick}>Fix Issue</StyledTypographyIssue>
            )}
            {functionData && (
              <StyledTypographyIssue onClick={handleOpenFunction}>
                Open function
              </StyledTypographyIssue>
            )}
            {snackbarKey === 'multipleError' && (
              <StyleTypographyIssues onClick={openIssueManager}>Fix issues</StyleTypographyIssues>
            )}
          </Typography>
        </SnackbarContent>
        {action && <StyledTypographyViewAction onClick={action}>View</StyledTypographyViewAction>}
        {actionButtonTitle && (
          <StyledTypographyView onClick={handleActionButton}>
            {actionButtonTitle}
          </StyledTypographyView>
        )}
        <StyledIconButton
          data-test="notification.closeIcon"
          onClick={() => send({ type: UI_BUILDER_EVENTS.closeSnackbar, key: snackbarKey })}
        >
          <CloseOutlinedIcon />
        </StyledIconButton>
      </SnackbarContainer>
    );
  },
);
