import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { commonActionProps, commonStyleProps } from '../../common/commonProps';
import { BuilderComponentsPopoverSettings } from './BuilderComponentsPopover.settings';
/** @deprecated */
export const BuilderComponentsPopover = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsPopover,
    displayName: 'Popover',
    icon: 'container',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    deprecated: {
        version: '0.4.0',
    },
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Popover',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        predefineds: {
            props: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                elevation: 4,
                target: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.div,
                            alias: 'Popover Target',
                            props: {
                                style: {
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                                            props: {
                                                color: 'primary',
                                                variant: 'contained',
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'Button',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                content: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.div,
                            nodeName: 'PopoverContent',
                            alias: 'Popover Content',
                            schemaOverride: Object.assign({}, getSysMinSizeStyle({ display: null, minWidth: 72, minHeight: 36 })),
                        },
                    ],
                },
            },
        },
        passNodeAliasToProps: true,
        props: Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonActionProps), { target: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                required: true,
            }, content: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'popupState',
                        path: [0],
                    },
                ],
                required: true,
            }, forceOpen: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            }, anchorOrigin: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    vertical: {
                        type: COMPONENT_DSL_PROP_TYPES.enum,
                    },
                    horizontal: {
                        type: COMPONENT_DSL_PROP_TYPES.enum,
                    },
                },
            }, transformOrigin: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    vertical: {
                        type: COMPONENT_DSL_PROP_TYPES.enum,
                    },
                    horizontal: {
                        type: COMPONENT_DSL_PROP_TYPES.enum,
                    },
                },
            }, elevation: {
                type: COMPONENT_DSL_PROP_TYPES.number,
            } }),
    },
    settings: BuilderComponentsPopoverSettings,
};
