import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, gql, } from '@apollo/client';
export const getServerUrl = (resourceId) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resources = window.appResourceList;
    const api = resourceId && resources && resources[resourceId] && resources[resourceId].api;
    return api || process.env.REACT_APP_SERVER_URL || '';
};
export const getApolloClient = (workspaceId, token) => {
    const resourceId = workspaceId ? workspaceId.split('_')[0] : '';
    const apiEndpoint = `${getServerUrl(resourceId)}/${workspaceId}`;
    const httpLink = createHttpLink({ uri: apiEndpoint });
    const authLink = new ApolloLink((operation, foward) => {
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        });
        return foward(operation);
    });
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
    });
    return client;
};
export const validateCorrectFileTypes = (files, acceptString, wrongFileTypeText = '') => {
    var _a;
    if (!acceptString)
        return true;
    const acceptedTypes = acceptString.split(',');
    const acceptedTypesTrimmed = acceptedTypes.map(type => type.trim());
    for (const file of files) {
        let isValidType = false;
        for (const acceptedTypeTrimmed of acceptedTypesTrimmed) {
            if (acceptedTypeTrimmed.startsWith('.')) {
                // Check file extension
                const fileExtension = `.${(_a = file.name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase()}`;
                if (fileExtension === acceptedTypeTrimmed.toLowerCase()) {
                    isValidType = true;
                    break;
                }
            }
            else if (acceptedTypeTrimmed.endsWith('/*')) {
                if (file.type.startsWith(acceptedTypeTrimmed.slice(0, -2))) {
                    isValidType = true;
                    break;
                }
            }
            else if (file.type === acceptedTypeTrimmed) {
                isValidType = true;
                break;
            }
        }
        if (!isValidType) {
            // eslint-disable-next-line no-alert
            alert(wrongFileTypeText);
            return false;
        }
    }
    return true;
};
export const isFileExceedingFileSize = (files, maxFileSize, exceededFileSizeText = '') => {
    if (!maxFileSize)
        return false;
    const isExceedingSize = [...files].some(file => file.size > maxFileSize * 1024 * 1024);
    // eslint-disable-next-line no-alert
    isExceedingSize && alert(exceededFileSizeText.replace('{maxSize}', maxFileSize.toString()));
    return isExceedingSize;
};
export const FILE_UPLOAD_INFO_MUTATION = gql `
  mutation createFile($file: FileCreateInput!) {
    fileCreate(data: $file) {
      id
    }
  }
`;
export const FILE_UPLOAD_INFO_QUERY = gql `
  query FileUploadInfo {
    fileUploadInfo {
      policy
      signature
      apiKey
      path
    }
  }
`;
