import React, { StrictMode } from 'react';

import * as FullStory from '@fullstory/browser';
import ReactDOM from 'react-dom';

import './index.css';
import { isProductionStand } from '@builder/utils';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

FullStory.init({ orgId: 'NEXG3', devMode: !isProductionStand() });

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('ui-builder-root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
