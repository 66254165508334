var _a;
import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_INTERACTION_TYPES, } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings, pickSettings, removeAllDividers, } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const MaterialAccordionSummarySettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'expandIcon',
                            label: 'Expand Icon',
                            keyPath: ['expandIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                    predefineds: {
                                        props: Object.assign(Object.assign({}, (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { name: 'ExpandMore', fontSize: 'medium' }),
                                        schemaOverride: {
                                            interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonAccordionSummarySettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'expandIcon'],
    [NS_ID.style.root, NS_ID.style.spacing.root, NS_ID.style.spacing.editor],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.fontFamily],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.fontSize],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.color],
], { withStructure: false }), removeAllDividers)(values(MaterialAccordionSummarySettings).flat());
