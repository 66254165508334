// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function deepEqual(q: any, e: any): boolean {
  if (q === e) return true;

  if (Array.isArray(q) && Array.isArray(e)) {
    // check if q and e are arrays
    if (q.length !== e.length) return false; // return false if they have different lengths

    for (let i = 0; i < q.length; i++) {
      // iterate over each element of both arrays
      if (!deepEqual(q[i], e[i])) return false; // recursively compare each element
    }

    return true;
  }

  if (typeof q !== 'object' || typeof e !== 'object' || q === null || e === null) return false;

  const keysA = Object.keys(q);
  const keysB = Object.keys(e);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key)) return false;

    if (!deepEqual(q[key], e[key])) return false;
  }

  return true;
}

export { deepEqual };
