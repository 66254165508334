import React, { useCallback, useState, useMemo } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { log } from '@builder/utils';

import { Button, CssGrid } from 'src/shared/components';

export const CdnToggleDialog = ({
  opened,
  close,
  isCdnEnabled,
  handleCdnStatusChange,
}: {
  opened: boolean;
  close: () => void;
  isCdnEnabled: boolean;
  handleCdnStatusChange: (cdn: boolean) => Promise<void>;
}): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  const updateCdnStatus = useCallback(
    async (status: boolean) => {
      setIsLoading(true);

      try {
        await handleCdnStatusChange(status);
      } catch (error) {
        log.warn(error);
      }

      setIsLoading(false);
    },
    [handleCdnStatusChange, setIsLoading],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cdnStatus = useMemo(() => isCdnEnabled, []);

  if (cdnStatus) {
    return (
      <Dialog open={opened} onClose={close}>
        <DialogTitle>Disable CDN</DialogTitle>
        <DialogContent>
          <CssGrid gap={0.5}>
            <Typography variant="body1">
              Are you sure you want to disable CDN for your project?
            </Typography>
            <Typography variant="body1">Your content will take much longer to deliver</Typography>
          </CssGrid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => updateCdnStatus(false)}
            loading={isLoading}
          >
            Agree and Disable
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={opened} onClose={close}>
      <DialogTitle>Enable CDN</DialogTitle>
      <DialogContent>
        <CssGrid gap={0.5}>
          <Typography variant="body1">
            Content Distribution Network (CDN) is a premium feature.
          </Typography>
          <Typography variant="body1">You will be extra charged for its usage</Typography>
        </CssGrid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => updateCdnStatus(true)}
          loading={isLoading}
        >
          Agree and Enable
        </Button>
      </DialogActions>
    </Dialog>
  );
};
