/* eslint-disable import/no-webpack-loader-syntax */
import { CODE_ENGINE_MARKERS } from '../../../../predefined-states';
import { BaseAppStateAccessor } from './BaseAppStateAccessor';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import useBooleanStateRaw from '!!raw-loader!../../../../predefined-states/useBooleanState.template.ts';
export class TrueFalseBooleanStateAccessor extends BaseAppStateAccessor {
    constructor(stateDSL, stateListDSL) {
        super();
        this.stateDSL = stateDSL;
        this.stateListDSL = stateListDSL;
    }
    getHookDeclarationName() {
        return `useBooleanState`;
    }
    getHookCallArgumentsString() {
        var _a;
        return `${(_a = this.stateDSL.defaultValue) !== null && _a !== void 0 ? _a : ''}`;
    }
    getHookDeclarationArgumentsString() {
        return `defaultState: boolean`;
    }
    getHookDeclarationBodyString() {
        return useBooleanStateRaw
            .replace(new RegExp(`(.|\n|\r)*${CODE_ENGINE_MARKERS.codeEngineUsageStart}`), '')
            .replace(new RegExp(`${CODE_ENGINE_MARKERS.codeEngineUsageEnd}(.|\n|\r)*`), '');
    }
    getStateTypeName() {
        return 'AppRuntimeBooleanState';
    }
}
