import { Dispatch } from 'react';

import { DragSourceMonitor } from 'react-dnd';

import { COMPONENT_DSL_NAMES, NodeListDSL } from '@builder/schemas';
import { isArray } from '@builder/utils';

import { handleSendFullStoryEvent } from '../../fullStory/index';
import {
  DashboardContextUpdateEvents,
  DashboardAnimationEvents,
  DASHBOARD_ANIM_EVENTS,
  DASHBOARD_EVENTS,
} from 'src/store';

import { DRAGGABLES, DROPPABLE_AREAS } from './shared/constants';
import { DraggableIconItem, DraggableItem, DropResults } from './shared/types';

/**
 * Drag-end business
 * @param send
 */

const removePreview = () => {
  const targetDocument = document?.getElementById('user-app-iframe') as HTMLIFrameElement;
  const previousDiv = targetDocument?.contentWindow?.document.getElementById('placeholderDiv');
  previousDiv?.parentNode?.removeChild(previousDiv);
  const previousPreview = targetDocument?.contentWindow?.document.querySelector(
    '[data-previewid="previewElement"]',
  );
  previousPreview?.parentNode?.removeChild(previousPreview);
};

export const dragEnd = (
  send: Dispatch<DashboardContextUpdateEvents | DashboardAnimationEvents>,
  nodeListDSL = {} as NodeListDSL,
  workspaceID?: string | null,
) => (item: DraggableItem | undefined, monitor: DragSourceMonitor): void | null => {
  const nodesIdList = Object.values(nodeListDSL).map(x => x.id);
  const isOverlayItem = isArray(item);
  // regardless where the item is dropped, animation have to be cleared!
  send({ type: DASHBOARD_ANIM_EVENTS.moveEnd });

  removePreview();
  // the item can be dropped out of bounds or somehow removed
  const dropResult = monitor.getDropResult() as DropResults;
  const handleFSEvent = (method: string, element: DraggableIconItem) => {
    const componentType = element.meta.name;
    const targetID = element.dndMutableMeta?.targetComponent?.nodeID;
    const targetNode = ((nodeListDSL as unknown) as NodeListDSL)[targetID ?? ''];
    const target =
      targetNode.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout
        ? targetNode.alias
        : targetNode.name;

    handleSendFullStoryEvent(`The user creates a component by drag/drop, copy/paste, or cloning`, {
      componentType,
      target,
      method,
      workspaceID,
      defineStr: `The ${componentType} created in the ${target} for the ${method} method`,
    });
  };

  if (!item || !dropResult?.name) {
    return null;
  }

  if (
    (isOverlayItem && !item.every(x => x.dndMutableMeta?.kind === 'valid')) ||
    (!isOverlayItem && item.dndMutableMeta?.kind === 'invalid')
  ) {
    return null;
  }

  // OverlayItems logic
  if (isOverlayItem) {
    item.forEach(draggableItem => {
      switch (dropResult.name) {
        case DROPPABLE_AREAS.OVERLAY:
          return draggableItem.dndMutableMeta
            ? send({
                type: DASHBOARD_EVENTS.componentMove,
                place: { ...draggableItem.dndMutableMeta },
              })
            : null;

        default:
          return null;
      }
    });

    return;
  }

  // Icons
  // what we are dropping
  switch (item.type) {
    case DRAGGABLES.ICON:
      // and where we are dropping it
      switch (dropResult.name) {
        case DROPPABLE_AREAS.OVERLAY: {
          if (!item.dndMutableMeta) return null;

          if (!nodesIdList.includes(item.dndMutableMeta.target.nodeID)) {
            return null;
          }

          send({
            type: DASHBOARD_EVENTS.componentCreate,
            place: { ...item.dndMutableMeta },
            value: item.meta,
          });

          return handleFSEvent(DASHBOARD_EVENTS.componentCreate, item);
        }

        default:
          return null;
      }

    default:
      return null;
  }
};
