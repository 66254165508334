import { useCallback } from 'react';

import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonGroup, ButtonProps, Tooltip } from '@mui/material';

import { JSInjection, ComponentSettingRadioOption } from '@builder/schemas';

import { useTheme } from 'src/providers/ThemeProvider';
import {
  Button,
  Icon,
  IconNameType,
  InputContainer,
  InputContainerProps,
} from 'src/shared/components';

const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1 1 0%;
  background-color: ${({ theme }) => theme.palette.background.input};
  .MuiButtonGroup-grouped {
    min-width: 35px;
  }
`;

const activeButtonCSS = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.background.radioButtonActive};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  &:hover,
  &:focus {
    background-color: ${theme.palette.background.radioButtonActive};
  }

  + button {
    ::before {
      display: none !important;
    }
  }
`;

const disabledButtonCSS = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.background.radioButtonDisabled} !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  &:hover,
  &:focus {
    background-color: ${theme.palette.background.radioButtonDisabled} !important;
  }

  + button {
    ::before {
      display: none !important;
    }
  }
`;

const activeTextCSS = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.main};
`;

const BLACK_LIST_PROPS = ['isActive'];
const shouldForwardProp = (prop: string): boolean => !BLACK_LIST_PROPS.includes(prop);

const StyledTextIcon = styled.span<{ isActive?: boolean }>`
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.fontSize};

  ${({ isActive }) => isActive && activeTextCSS}
`;

const StyledButton = styled(Button, { shouldForwardProp })<{ isActive: boolean }>`
  position: relative;
  min-width: ${({ theme }) => theme.spacing(4)};
  min-height: ${({ theme }) => theme.spacing(4)};
  padding: 0;
  flex: 1;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.background.radioButtonActive : theme.palette.background.input};
  border: none;
  border-radius: 4px !important;

  ${StyledTextIcon}, svg {
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    color: ${({ theme, isActive }) =>
      isActive ? theme.palette.common.white : theme.palette.text.primary};
  }

  :hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.palette.background.radioButtonActive : theme.palette.background.input};
    border: none;
    ${StyledTextIcon}, svg {
      opacity: 1;
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  :focus {
    outline: none;
  }

  :not(:first-of-type)::before {
    content: '';
    display: block;
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.palette.grey[600]};
    position: absolute;
    left: 1px;
  }

  ${({ isActive }) => isActive && activeButtonCSS}
  ${({ disabled }) => disabled && disabledButtonCSS}
`;

const StyledTooltip = styled<typeof Tooltip>(({ className, ...other }) => (
  <Tooltip classes={{ tooltip: className }} {...other} />
))`
  max-width: ${({ theme }) => theme.layout.controls.tooltip.maxWidth};
  text-align: center;
`;

export type RadioViewEditorProps<T = string | JSInjection | undefined> = InputContainerProps & {
  propValue: T;
  options: Array<ComponentSettingRadioOption>;
  onChangePropValue: (propValue: T) => void;
};

const getIconSize = (name: string) => {
  switch (name) {
    case 'styleAuto': {
      return { width: 30, height: 16 };
    }

    default: {
      return { width: 16, height: 16 };
    }
  }
};

// need to pass props to Button component from ButtonGroup
function WireToButton({
  children,
  ...other
}: ButtonProps & { children: (prop: ButtonProps) => JSX.Element }) {
  return children(other);
}

export const RadioViewEditor: React.FC<RadioViewEditorProps> = ({
  label,
  labelPlacement,
  propValue,
  onChangePropValue,
  options,
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
  enableFx,
  isFxEnabled,
  'data-test': dataTest,
}) => {
  const theme = useTheme();

  const updatePropByEvent = useCallback(
    (value: string) => () => {
      if (value === propValue) {
        onChangePropValue(undefined);
      } else {
        onChangePropValue(value);
      }
    },
    [onChangePropValue, propValue],
  );

  return (
    <InputContainer
      label={label}
      labelPlacement={labelPlacement}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      enableFx={enableFx}
      isFxEnabled={isFxEnabled}
      data-test={dataTest}
    >
      <StyledButtonGroup size="small" data-test={dataTest}>
        {options.map(el => {
          const isActive = el.value === propValue;
          const iconSize = getIconSize(el.icon);

          const optionIcon = (
            <Choose>
              <When condition={el.isTextIcon}>
                <StyledTextIcon isActive={isActive}>{el.icon}</StyledTextIcon>
              </When>
              <Otherwise>
                <Icon
                  name={el.icon as IconNameType}
                  width={iconSize.width}
                  height={iconSize.height}
                  fill={theme.palette.common.white}
                />
              </Otherwise>
            </Choose>
          );

          if (el.disabled) {
            return null;
          }

          if (el.tooltip) {
            return (
              <WireToButton key={el.value}>
                {prop => (
                  <StyledTooltip disableInteractive placement="top" title={el.tooltip} arrow>
                    <StyledButton
                      {...prop}
                      data-value={el.value}
                      data-active={isActive || undefined}
                      isActive={isActive}
                      onClick={updatePropByEvent(el.value)}
                      disableRipple
                    >
                      {optionIcon}
                    </StyledButton>
                  </StyledTooltip>
                )}
              </WireToButton>
            );
          }

          return (
            <StyledButton
              key={el.value}
              data-value={el.value}
              data-active={isActive || undefined}
              isActive={isActive}
              onClick={updatePropByEvent(el.value)}
              disableRipple
              data-test={`${dataTest}.${el.value}`}
            >
              {optionIcon}
            </StyledButton>
          );
        })}
      </StyledButtonGroup>
    </InputContainer>
  );
};
