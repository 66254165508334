import styled from '@emotion/styled';

export const StyledPre = styled.pre`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 100;
  line-height: 1.6;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  user-select: text;
  width: 100%;
  overflow: hidden;
  margin: 0;
`;
