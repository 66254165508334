import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { Button } from '@builder/components';

import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { DialogContentWithIcon } from 'src/dialogs/common/components';
import { useDialogState } from 'src/providers';
import { MESSAGES } from 'src/shared/constants';

const DIALOG_ID = DASHBOARD_DIALOGS.LOST_CONNECTION_DIALOG_ID;

export const LostConnectionDialog: React.FC = () => {
  const { isOpen } = useDialogState(DIALOG_ID);
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{MESSAGES.dialogs.lostConnectionDialog.title}</DialogTitle>
      <DialogContentWithIcon>
        <DialogContentText>
          <Box display="flex">
            <ErrorOutlineIcon
              color="error"
              style={{
                marginRight: '8px',
              }}
            />
            <Typography variant="h6" color="white">
              {MESSAGES.dialogs.lostConnectionDialog.subTitle}
            </Typography>
          </Box>
          <Typography ml="32px" mt={0.5}>
            {MESSAGES.dialogs.lostConnectionDialog.body}
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="success">
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  );
};
