import { useCallback, useMemo, useState } from 'react';

import {
  Event,
  EventType,
  GetPayload,
  GetResponsePayload,
  UserActionHistory,
} from '@8base-private/event-handler';
import { nanoid as uuid } from 'nanoid';

import { useDraftEngine } from '../../../providers';

type UseConfigurationGetReturn = {
  getConfiguration: (payload?: GetPayload) => void;
  getConfigurationAsync: () => Promise<
    | {
        data?: GetResponsePayload | undefined;
        error?: string | undefined;
        loading: boolean;
      }
    | string
  >;
  getConfigurationResult: {
    data?: GetResponsePayload;
    error?: string;
    loading: boolean;
    history: UserActionHistory['history'];
  };
};

export const useConfigurationGet = (): UseConfigurationGetReturn => {
  const { draftEngine } = useDraftEngine();
  const [history, setHistory] = useState<UserActionHistory['history']>(
    {} as UserActionHistory['history'],
  );
  const [data, setData] = useState<GetResponsePayload>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const getConfiguration = useCallback(
    (payload?: GetPayload) => {
      if (!draftEngine) return;

      const eventId = uuid();

      const getResponseListener = async (event: Event) => {
        if (event.uuid !== eventId) return;

        switch (event.type) {
          case EventType.GetResponse:
            setLoading(false);
            setHistory({
              future: event.history.future || [],
              past: event.history.past || [],
            });
            setData(event.payload);
            break;
          case EventType.Error:
            setLoading(false);
            setError(event.payload);
            break;
        }

        draftEngine.removeEventListener(getResponseListener);
      };

      draftEngine.addEventListener(getResponseListener);

      setLoading(true);
      setError(undefined);

      draftEngine.send({
        type: EventType.Get,
        uuid: eventId,
        payload: payload ?? {},
      });
    },
    [draftEngine],
  );

  const getConfigurationAsync = useCallback(() => {
    return new Promise<
      | {
          data?: GetResponsePayload;
          error?: string;
          loading: boolean;
        }
      | string
    >((resolve, reject) => {
      if (!draftEngine) {
        reject(new Error('Draft engine is not initialized').message);
        return;
      }

      const eventId = uuid();

      const getResponseListener = async (event: Event) => {
        if (event.uuid !== eventId) return;

        switch (event.type) {
          case EventType.GetResponse:
            setLoading(false);
            setData(event.payload);
            resolve({ data: event.payload, loading: false });
            break;
          case EventType.Error:
            setLoading(false);
            setError(event.payload);
            reject(event.payload);
            break;
        }

        draftEngine.removeEventListener(getResponseListener);
      };

      draftEngine.addEventListener(getResponseListener);

      setLoading(true);
      setError(undefined);

      draftEngine.send({
        type: EventType.Get,
        uuid: eventId,
        payload: {},
      });
    });
  }, [draftEngine]);

  const result = useMemo(
    () => ({
      getConfiguration,
      getConfigurationAsync,
      getConfigurationResult: { data, error, loading, history },
    }),
    [data, error, getConfiguration, getConfigurationAsync, history, loading],
  );

  return result;
};
