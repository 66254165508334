import React from 'react';

import { Paper, PopperPlacementType, Typography } from '@mui/material';
import { bindPopover, PopupState } from 'material-ui-popup-state/hooks';

import { Popper } from 'src/shared/components';
import { TeamMemberWithColor } from 'src/store';

type UserDetailsPopperProps = {
  placement: PopperPlacementType;
  popupState: PopupState;
  member: TeamMemberWithColor | null;
};

export const UserDetailsPopper: React.FC<UserDetailsPopperProps> = ({
  placement,
  popupState,
  member,
}) => {
  return (
    <Popper placement={placement} {...bindPopover(popupState)}>
      <Paper sx={{ padding: 2 }}>
        <Typography>
          Name: {member?.firstName} {member?.lastName}
        </Typography>
        <Typography>Email: {member?.email}</Typography>
      </Paper>
    </Popper>
  );
};
