var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useCallback, useState, useEffect, } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton as MuiIconButton } from '@mui/material';
import { useFormikContext } from 'formik';
import { RESET_STATUS, SUBMIT_STATUS } from '../common';
const LabelWrapper = styled.div `
  font-size: 12px;
  font-weight: 600;
  width: 100%;
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const IconButton = forwardRef((_a, ref) => {
    var { loading, children, type, onClick, label = '' } = _a, rest = __rest(_a, ["loading", "children", "type", "onClick", "label"]);
    const contextForm = useFormikContext();
    const [initialValues, setInitialValues] = useState(contextForm === null || contextForm === void 0 ? void 0 : contextForm.values);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    useEffect(() => {
        var _a;
        if (contextForm && ((_a = Object.keys(contextForm.values)) === null || _a === void 0 ? void 0 : _a.length) && !isFirstLoad) {
            setInitialValues(contextForm.values);
            setIsFirstLoad(true);
        }
    }, [contextForm, isFirstLoad]);
    const onClickCallback = useCallback((event) => {
        if (contextForm && type === RESET_STATUS) {
            contextForm.resetForm({ values: initialValues });
            contextForm.setStatus(RESET_STATUS);
        }
        else if (contextForm && type === SUBMIT_STATUS)
            contextForm.submitForm();
        if (!loading) {
            onClick && onClick(event);
        }
    }, [loading, type, contextForm, initialValues, onClick]);
    return (_jsx(MuiIconButton, Object.assign({}, rest, { type: type, "aria-label": "add", ref: ref, onClick: onClickCallback, css: loading &&
            css `
            color: transparent;
          ` }, { children: _jsxs(Content, { children: [_jsx("div", Object.assign({ style: { width: '100%' } }, { children: children })), _jsx(LabelWrapper, { children: label })] }) })));
});
