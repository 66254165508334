import { isEmpty } from 'ramda';
import { removeDoubleQuotes } from '../string';
/**
 * @example
 * const fontFamily = "Times New Roman, mono, sens"
 * parseFontFamilyWithFallbackFonts(fontFamily) = {
 *   fontFamily: "Times New Roman",
 *   fallbackFonts: ["mono", "sens"],
 * }
 */
export const parseFontFamilyWithFallbackFonts = (fontFamilyCSS) => {
    if (!fontFamilyCSS) {
        return {
            fontFamily: undefined,
            fallbackFonts: undefined,
        };
    }
    const [fontFamily, ...fallbackFonts] = fontFamilyCSS
        .split(',')
        .map(font => removeDoubleQuotes(font.trim()));
    return {
        fontFamily,
        fallbackFonts: !isEmpty(fallbackFonts) ? fallbackFonts : undefined,
    };
};
