import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialAccordionDetailsSettings } from './MaterialAccordionDetails.settings';
export const MaterialAccordionDetails = {
    name: COMPONENT_DSL_NAMES.MaterialAccordionDetails,
    displayName: 'Accordion Details',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'AccordionDetails',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        accessibility: {
            disableCreatingSymbol: true,
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialAccordion,
                },
            },
        ],
        presentations: [visibleByCondition],
        dndTargetPropName: 'children',
        props: Object.assign({}, commonComponentProps),
    },
    settings: MaterialAccordionDetailsSettings,
};
