import { hasPropJsCode } from '@builder/schemas';
import { isArray, isObject, isString } from '@builder/utils';
export function transformValue({ toTransform, path = [], runtimePropExecutor, state, libraries, }) {
    if (isObject(toTransform)) {
        return Object.entries(toTransform).reduce((newState, [key, value]) => {
            if (isObject(value)) {
                return Object.assign(Object.assign({}, newState), { [key]: transformValue({
                        path: [key],
                        toTransform: value,
                        runtimePropExecutor,
                        state,
                        libraries,
                    }) });
            }
            return Object.assign(Object.assign({}, newState), { [key]: value });
        }, {});
    }
    if (isArray(toTransform)) {
        return toTransform.map(item => transformValue({ toTransform: item, runtimePropExecutor, state, libraries }));
    }
    if (isString(toTransform) && hasPropJsCode(toTransform)) {
        const transformed = runtimePropExecutor.transformPropJsCode({
            propValue: toTransform,
            propPath: path,
            externalState: state,
        });
        if (transformed) {
            return transformed;
        }
    }
    return toTransform;
}
