import _ from 'lodash';

import { ComponentSettingDSL } from '@builder/schemas';

/**
 * Removes any consecutive dividers, first/last divider and empty sections.
 * @param sections An array of ComponentSettingDSL objects.
 * @returns An array of valid ComponentSettingDSL objects.
 */
export const formatSections = (sections: ComponentSettingDSL[]): ComponentSettingDSL[] => {
  let hasDivider = false;
  let validSections: ComponentSettingDSL[] = [];

  _.each(sections, section => {
    if (section.type === 'divider') {
      if (hasDivider) {
        // If there is already a divider, skip this section
        return;
      }

      hasDivider = true;
    } else {
      hasDivider = false;
    }

    validSections.push(section);
  });

  // Removes empty sections
  validSections = validSections.filter(section => {
    if (section.type === 'divider') return true;

    if ('children' in section) {
      return section.children.length;
    }

    return false;
  });

  // If the last or fist section is a divider, remove it
  if (_.last(validSections)?.type === 'divider') {
    validSections.pop();
  }

  if (_.first(validSections)?.type === 'divider') {
    validSections.shift();
  }

  return validSections;
};
