export const RESET_STATUS = 'reset';
export const SUBMIT_STATUS = 'submit';
export const getCurrentDate = () => {
    const date = new Date();
    const currentDay = String(date.getDate()).padStart(2, '0');
    const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
    const currentYear = date.getFullYear();
    return `${currentMonth}/${currentDay}/${currentYear}`;
};
export const getCurrentTime = () => {
    const today = new Date();
    return `${today.getHours() > 9 ? today.getHours() : `0${today.getHours()}`}:${today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`}:${today.getSeconds() > 9 ? today.getSeconds() : `0${today.getSeconds()}`}`;
};
