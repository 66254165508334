import { createSelector } from 'reselect';
const getNodeDSL = (state) => state.nodeDSL;
const getComponentDSL = (state) => state.componentDSL;
const getRouteNodeDSL = (state) => state.routeNodeDSL;
export const getMinSizeStyles = createSelector(getNodeDSL, getComponentDSL, (nodeDSL, componentDSL) => {
    var _a, _b, _c;
    if ((_a = nodeDSL.schemaOverride) === null || _a === void 0 ? void 0 : _a.minSizeStyles) {
        return (_b = nodeDSL.schemaOverride) === null || _b === void 0 ? void 0 : _b.minSizeStyles;
    }
    return (_c = componentDSL.schema) === null || _c === void 0 ? void 0 : _c.minSizeStyles;
});
export const getCodeFileStructure = createSelector(getNodeDSL, getComponentDSL, (nodeDSL, componentDSL) => {
    var _a, _b, _c;
    if ((_a = nodeDSL.schemaOverride) === null || _a === void 0 ? void 0 : _a.codeFileStructure) {
        return (_b = nodeDSL.schemaOverride) === null || _b === void 0 ? void 0 : _b.codeFileStructure;
    }
    return (_c = componentDSL.schema) === null || _c === void 0 ? void 0 : _c.codeFileStructure;
});
export const getInteraction = createSelector(getNodeDSL, getComponentDSL, (nodeDSL, componentDSL) => {
    var _a, _b, _c;
    if ((_a = nodeDSL.schemaOverride) === null || _a === void 0 ? void 0 : _a.interaction) {
        return (_b = nodeDSL.schemaOverride) === null || _b === void 0 ? void 0 : _b.interaction;
    }
    return (_c = componentDSL.schema) === null || _c === void 0 ? void 0 : _c.interaction;
});
export const getDisableDelete = createSelector(getRouteNodeDSL, routeNodeDSL => {
    var _a;
    if (routeNodeDSL) {
        return (_a = routeNodeDSL.schemaOverride) === null || _a === void 0 ? void 0 : _a.disableDelete;
    }
    return null;
});
export const getMergedSchemaOverrides = createSelector(getMinSizeStyles, getCodeFileStructure, getInteraction, (minSizeStyles, codeFileStructure, interaction) => {
    return {
        minSizeStyles,
        codeFileStructure,
        interaction,
    };
});
