import React, { useCallback, useMemo, useState } from 'react';

import styled from '@emotion/styled';

import { useDragging } from 'src/shared/hooks';
import { Coords, ElementSize, Range } from 'src/shared/types';

type PointerProps = {
  top: number;
  left: number;
  size: number;
  color: string;
  borderColor: string;
};

type SliderProps = {
  height: string;
  width: string;
  backgroundColor: string;
  borderRadius: string;
};

type CustomSliderProps = {
  valueRange: Range;
  value: number;
  height: string;
  width: string;
  colors?: string[];
  pointerColor?: string;
  pointerBorderColor?: string;
  borderRadius?: string;
  onChange: (value: number) => void;
};

const Pointer = styled.div<PointerProps>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  background-color: ${({ color }) => color};
  box-shadow: 0 0 2px 2px ${({ borderColor }) => borderColor};
  border-radius: 50%;
`;

const SliderWrapper = styled.div<SliderProps>`
  position: relative;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const getLinearGradient = (colors: string[]): string | null => {
  if (!colors.length) {
    return null;
  }

  if (colors.length === 1) {
    return colors[0];
  }

  return `linear-gradient(to right, ${colors.join(', ')})`;
};

const COEFFICIENT_FOR_POINTER_SIZE = 1.5;
const INITIAL_TOP = 0;
const DEFAULT_SLIDER_BORDER_RADIUS = '0';
const DEFAULT_POINTER_COLOR = 'white';
const DEFAULT_POINTER_BORDER_COLOR = 'rgba(0, 0, 0, 0.75)';

/**
 * @value - the value from which the pointer position is formed (useful information used from the outside)
 * */
export const Slider: React.FC<CustomSliderProps> = ({
  valueRange,
  value,
  height,
  width,
  colors = [],
  pointerColor,
  pointerBorderColor,
  borderRadius,
  onChange,
}) => {
  const [sliderWidth, setSliderWidth] = useState<number>(0);
  const handleSliderRefChange = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setSliderWidth(node.offsetWidth);
    }
  }, []);

  /**
   * @coords - cursor coords
   * @elementSize - size of dragRef element
   * */
  const handleDragging = useCallback(
    ({ x }: Coords, { width: dragRefWidth }: ElementSize) => {
      // get the new value by multiplying the current cursor position by the ratio of the maximum allowed value to the size of the container
      const newValue = x * (valueRange.max / dragRefWidth);
      const isOutOfRange = newValue < valueRange.min || newValue > valueRange.max;

      if (isOutOfRange) return;

      onChange(newValue);
    },
    [onChange, valueRange.max, valueRange.min],
  );

  const { setDragRef, onDragStart: handleMouseDown } = useDragging<HTMLDivElement>({
    onDragging: handleDragging,
  });

  const parsedHeight = Number.parseInt(height, 10);
  const pointerSize = useMemo(() => Math.round(parsedHeight * COEFFICIENT_FOR_POINTER_SIZE), [
    parsedHeight,
  ]);

  // a linear gradient is formed for the background of the slider, or some specific color
  const backgroundColor = useMemo(() => getLinearGradient(colors) ?? 'white', [colors]);

  // get the new pointer position by dividing the current cursor position by the ratio of the maximum allowed value to the size of the container
  const left = useMemo(() => value / (valueRange.max / sliderWidth), [
    sliderWidth,
    value,
    valueRange.max,
  ]);

  return (
    <SliderWrapper
      ref={node => {
        handleSliderRefChange(node);
        setDragRef(node);
      }}
      height={height}
      width={width}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius ?? DEFAULT_SLIDER_BORDER_RADIUS}
      onMouseDown={handleMouseDown}
    >
      <Pointer
        left={left - pointerSize / 2}
        top={INITIAL_TOP - (pointerSize - parsedHeight) / 2}
        size={pointerSize}
        color={pointerColor ?? DEFAULT_POINTER_COLOR}
        borderColor={pointerBorderColor ?? DEFAULT_POINTER_BORDER_COLOR}
      />
    </SliderWrapper>
  );
};
