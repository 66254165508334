export const TOOLTIP_GLOBAL_BEFOREEACH =
  'to: the target route location, from: the current route location. Statements here are called in creation order, whenever a navigation is triggered.';
export const TOOLTIP_GLOBAL_AFTEREACH =
  'to: the target route location, from: the current route location. Statements here are executed after every navigation.';
export const TOOLTIP_LOCAL_BEFOREROUTE_ENTER =
  'to: the target route location, from: the current route location. Statements here are called before the route that renders this component is confirmed.';
export const TOOLTIP_LOCAL_BEFOREROUTE_UPDATE =
  'to: the target route location, from: the current route location. Statements here are called when the route that renders this component has changed, but this component is reused in the new route.';
export const TOOLTIP_LOCAL_BEFOREROUTE_EXIT =
  'to: the target route location, from: the current route location. Statements here are called when the route that renders this component is about to be navigated away from.';
export const TOOLTIP_TRANSFORMER =
  'Data for transformer action: it transforms the client request before they reach the upstream server.';
export const TOOLTIP_CALLBACK_ONCOMPLETED =
  'Data for OnCompleted action: It executes instructions when the request returns any successful data.';
export const TOOLTIP_CALLBACK_ONERROR =
  'Error for OnError action: It executes instructions when the request returns a non-null error object.';
