import React, { useMemo } from 'react';

import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import { anchorRef, bindPopover, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';

import { AssetID, assetListSelectors } from '@builder/schemas';
import { DEFAULT_IMAGE_COMPONENT_SOURCE, isString } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { TextViewEditor } from '../TextViewEditor';
import { AssetsManager } from 'src/features/assets-manager/AssetsManager';
import { AssetMedia } from 'src/features/assets-manager/components/AssetMedia';
import { AssetMeta } from 'src/features/assets-manager/components/AssetMeta';
import { useAssetListDSL } from 'src/providers';
import { CssGrid, Icon, Popper } from 'src/shared/components';
import { useAssetsHooks } from 'src/shared/graphql/hooks';

import {
  AssetMediaContainer,
  LabelWithTooltip,
  PopupContent,
  PopupHeader,
  PopupTitle,
  StyledAssetButton,
} from './AssetViewEditor.styles';
import { AssetEditorTooltip } from './components';
import { getAssetIdFromString } from './utils';

type AssetViewEditorProps = {
  label?: string;
  assetID?: AssetID;
  propValue: { assetID: AssetID } | string;
  updateProp: (value?: { assetID: AssetID } | string) => void;
  'data-test'?: string;
  showFx?: boolean;
};

const ASSET_MANAGER_POPPER_ID = 'ASSET_MANAGER_POPPER_ID';
const ASSET_RELATIVE_PATH_REGEXP = /^assets\/+.*$/;

export const ASSET_MANAGER_POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 20],
    },
  },
];

export const AssetViewEditor: React.FC<AssetViewEditorProps> = ({
  propValue,
  assetID,
  updateProp,
  label,
  'data-test': dataTest,
  showFx,
}) => {
  const theme = useTheme();
  const assetListDSL = useAssetListDSL();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: ASSET_MANAGER_POPPER_ID,
  });
  const popperRef = anchorRef(popupState);
  const bindToggleState = bindToggle(popupState);
  const bindPopoverState = bindPopover(popupState);
  const { assetFileArray } = useAssetsHooks();
  const assetFile = useMemo(
    () => assetListSelectors.getAssetFileDSL(assetFileArray, { assetID: assetID ?? '' }),
    [assetFileArray, assetID],
  );
  const { selectedNodeDSL } = useNodeSettingsProps();
  const nodeID = selectedNodeDSL?.id;

  const initialPresentationValue = useMemo(() => {
    try {
      return assetID && !isString(propValue)
        ? assetListSelectors.getAssetPathByID(assetListDSL, { assetID })
        : (propValue as string);
    } catch {
      return DEFAULT_IMAGE_COMPONENT_SOURCE;
    }
  }, [assetID, assetListDSL, propValue]);

  const onSelectAsset = (assetIDValue?: AssetID) => {
    const localPropValue = assetIDValue
      ? { assetID: assetIDValue }
      : DEFAULT_IMAGE_COMPONENT_SOURCE;
    updateProp(localPropValue);
  };

  const onChangePropValue = (value: string | undefined) => {
    const localValue = value ?? '';
    const hasAssetPathInValue = ASSET_RELATIVE_PATH_REGEXP.test(localValue);

    if (!hasAssetPathInValue) {
      return updateProp(localValue);
    }

    const assetIDFromString = getAssetIdFromString(localValue, assetListDSL);

    if (assetIDFromString !== assetID) {
      const localPropValue = assetIDFromString ? { assetID: assetIDFromString } : localValue;
      updateProp(localPropValue);
    }
  };

  return (
    <CssGrid gap={3} ref={popperRef as React.Ref<HTMLDivElement>}>
      <CssGrid gap={1} gridTemplateColumns="auto 30px" alignItems="flex-end">
        <CssGrid gap={0.5}>
          <LabelWithTooltip>
            <Typography variant="body1">{label}</Typography>
            <AssetEditorTooltip />
          </LabelWithTooltip>
          <TextViewEditor
            propValue={initialPresentationValue}
            onChangePropValue={onChangePropValue}
            data-test={dataTest}
            nodeID={nodeID}
            showFx={false}
          />
        </CssGrid>

        <StyledAssetButton
          {...bindToggleState}
          startIcon={<Icon name="imageWhite" />}
          color="default"
          variant="contained"
          data-test={`${dataTest}.openAssetViewEditorButton`}
        />
      </CssGrid>
      {assetFile && (
        <CssGrid gap={1} justifyContent="center" alignItems="center" justifyItems="center">
          <AssetMediaContainer>
            <AssetMedia
              assetType={assetFile?.type}
              downloadUrl={assetFile?.previewUrl}
              height="176px"
            />
          </AssetMediaContainer>
          <AssetMeta textAlign="center" asset={assetFile} />
        </CssGrid>
      )}

      <If condition={popupState.isOpen}>
        <Popper
          placement="left-start"
          {...bindPopoverState}
          modifiers={ASSET_MANAGER_POPPER_MODIFIERS}
        >
          <PopupContent>
            <CssGrid height="720px" gridTemplateRows="32px 688px">
              <PopupHeader>
                <PopupTitle variant="body1">Choose image</PopupTitle>
                <Icon
                  element="button"
                  width="16"
                  height="16"
                  name="cross"
                  fill={theme.palette.grey[500]}
                  hoverFill={theme.palette.common.white}
                  onClick={popupState?.close}
                />
              </PopupHeader>
              <AssetsManager selectedAssetID={assetID} onSelectAsset={onSelectAsset} />
            </CssGrid>
          </PopupContent>
        </Popper>
      </If>
    </CssGrid>
  );
};
