import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialAccordionSettings } from './MaterialAccordion.settings';
export const MaterialAccordion = {
    name: COMPONENT_DSL_NAMES.MaterialAccordion,
    displayName: 'Accordion',
    icon: 'accordion',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'Accordion',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                defaultExpanded: true,
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialAccordionSummary,
                        },
                        {
                            name: COMPONENT_DSL_NAMES.MaterialAccordionDetails,
                        },
                        {
                            name: COMPONENT_DSL_NAMES.MaterialAccordionActions,
                        },
                    ],
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.MaterialAccordionDetails,
                    COMPONENT_DSL_NAMES.MaterialAccordionSummary,
                    COMPONENT_DSL_NAMES.MaterialAccordionActions,
                ],
            }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, square: { type: COMPONENT_DSL_PROP_TYPES.boolean }, expanded: { type: COMPONENT_DSL_PROP_TYPES.boolean }, defaultExpanded: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: MaterialAccordionSettings,
};
