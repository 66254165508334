import { useMemo, useState } from 'react';

import { ApolloError, MutationTuple, useMutation } from '@apollo/client';

import { APOLLO_OPERATION_CONTEXT, ASYNC_SESSION_STATUSES } from '../../constants';
import {
  AppbuilderUserConfiguration,
  UserConfigurationMigrateMutation,
  UserConfigurationMigrateMutationVariables,
} from '../__generated__';
import { USER_CONFIGURATION_MIGRATE_MUTATION } from '../fragments';

import { useAsyncSession } from './useAsyncSession';

type UseMigrateAppConfigurationReturn = {
  migrateAppConfiguration: MutationTuple<
    UserConfigurationMigrateMutation,
    UserConfigurationMigrateMutationVariables
  >[0];
  migrateAppConfigurationResult: {
    userConfiguration?: AppbuilderUserConfiguration;
    error?: ApolloError;
    loading: boolean;
  };
};

export const useMigrateConfiguration = (): UseMigrateAppConfigurationReturn => {
  const [sessionId, setSessionId] = useState<string>();

  const [migrateAppConfiguration, { error, loading }] = useMutation<
    UserConfigurationMigrateMutation,
    UserConfigurationMigrateMutationVariables
  >(USER_CONFIGURATION_MIGRATE_MUTATION, {
    context: {
      [APOLLO_OPERATION_CONTEXT.IGNORE_ERROR_MESSAGE]: true,
    },
    onCompleted: data => {
      setSessionId(data.appbuilder?.userConfigurationMigrate?.sessionId);
    },
  });

  const { asyncSession, error: sessionError, loading: sessionLoading } = useAsyncSession(sessionId);

  const userConfiguration = useMemo((): AppbuilderUserConfiguration | undefined => {
    if (asyncSession?.status === ASYNC_SESSION_STATUSES.success) {
      return asyncSession.result?.userConfiguration;
    }
  }, [asyncSession]);

  const resultError = useMemo(() => {
    if (asyncSession?.status === ASYNC_SESSION_STATUSES.error) {
      return asyncSession?.result;
    }

    return sessionError || error;
  }, [error, sessionError, asyncSession]);

  return {
    migrateAppConfiguration,
    migrateAppConfigurationResult: {
      userConfiguration,
      error: resultError,
      loading: loading || sessionLoading,
    },
  };
};
