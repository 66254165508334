import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_ACTION_TYPE, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getCommonActionListSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
export const getMaterialChipSettings = () => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['label'],
                            label: 'Label',
                            name: 'label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['clickable'],
                            label: 'Clickable',
                            name: 'clickable',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disabled'],
                            label: 'Disabled',
                            name: 'disabled',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            name: 'variant',
                            options: ['filled', 'outlined'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['size'],
                            label: 'Size',
                            name: 'size',
                            options: ['medium', 'small'].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onDeleteAction',
                            keyPath: ['onDelete'],
                            label: 'On Delete',
                            children: [
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['onDelete', 'actionType'],
                                    resetPathBeforeOnChange: [['onDelete', 'args']],
                                    label: 'Type',
                                    options: [
                                        {
                                            value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                                            label: 'Custom Code',
                                        },
                                    ],
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['onDelete', 'args', 'code'],
                                    showIf: [
                                        {
                                            keyPath: ['onDelete', 'actionType'],
                                            type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                            value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                                        },
                                    ],
                                    defaultValue: '// Your JavaScript code here',
                                    label: 'Custom Code',
                                },
                            ],
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
});
export const MaterialChipSettings = getMaterialChipSettings();
export const getCommonChipSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'label'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'clickable'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'disabled'],
    [NS_ID.style.root, NS_ID.style.basic, 'variant'],
    [NS_ID.style.root, NS_ID.style.basic, 'size'],
], { withStructure: false }))(values(MaterialChipSettings).flat());
