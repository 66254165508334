import { createContext, useEffect, useState, useContext } from 'react';

import { buildClientSchema, GraphQLSchema } from 'graphql';

import { log } from '@builder/utils';

import { useUserApiEndpoint } from '../ReduxProvider';
import { useIntrospectionUrl } from 'src/shared/graphql/hooks';

type GqlSchemaContextType = {
  isSchemaLoaded: boolean;
  schema: GraphQLSchema | undefined;
};

const GqlSchemaContext = createContext<GqlSchemaContextType>({
  isSchemaLoaded: false,
  schema: undefined,
});

/**
 * Need to get GQL schema witch can be used on api-explorer
 */
export const GqlSchemaProvider: React.FC = ({ children }) => {
  const userApiEndpoint = useUserApiEndpoint();
  const { introspectionUrl } = useIntrospectionUrl();
  const [isSchemaLoaded, setIsSchemaLoaded] = useState<boolean>(false);
  const [schema, setSchema] = useState<GraphQLSchema | undefined>(undefined);

  useEffect(() => {
    if (introspectionUrl) {
      setIsSchemaLoaded(false);
      fetch(introspectionUrl)
        .then(res => res.json())
        .then(data => {
          const loadedSchema = buildClientSchema(data);
          setSchema(loadedSchema);
          setIsSchemaLoaded(true);
        })
        .catch(err => log.error(err));
    }
  }, [introspectionUrl]);

  return (
    <GqlSchemaContext.Provider
      value={{
        isSchemaLoaded: Boolean(userApiEndpoint) && isSchemaLoaded,
        schema,
      }}
    >
      {children}
    </GqlSchemaContext.Provider>
  );
};

export const useGqlSchema = (): GqlSchemaContextType => {
  const graphQLSchemaContext = useContext(GqlSchemaContext);

  return graphQLSchemaContext;
};
