import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basicDSL from './style-guide-dsl/IconButtonStyleGuide/basic.json';
import colorDSL from './style-guide-dsl/IconButtonStyleGuide/color.json';
import sizeDSL from './style-guide-dsl/IconButtonStyleGuide/size.json';
export const IconButtonStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiIconButton,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialIconButton,
    dirName: 'IconButtonStyleGuide',
    title: 'Icon Button',
    classNames: [
        '.MuiIconButton-root',
        '.MuiIconButton-edgeStart',
        '.MuiIconButton-edgeEnd',
        '.MuiIconButton-colorInherit',
        '.MuiIconButton-colorPrimary',
        '.MuiIconButton-colorSecondary',
        '.Mui-disabled',
        '.MuiIconButton-sizeSmall',
        '.MuiIconButton-sizeMedium',
        '.MuiIconButton-sizeLarge',
    ],
    stories: [
        {
            title: 'Basic',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialIconButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: {
                                        name: 'MoreVert',
                                        style: {
                                            display: 'flex',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        color: 'secondary',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: {
                                        name: 'Delete',
                                        style: {
                                            display: 'flex',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        disabled: true,
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: {
                                        name: 'Search',
                                        style: {
                                            display: 'flex',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: basicDSL,
        },
        {
            title: 'Size',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialIconButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        size: 'small',
                        color: 'primary',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: {
                                        name: 'MoreVert',
                                        style: {
                                            display: 'flex',
                                        },
                                        fontSize: 'inherit',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        size: 'medium',
                        color: 'primary',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: {
                                        name: 'MoreVert',
                                        style: {
                                            display: 'flex',
                                        },
                                        fontSize: 'inherit',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        size: 'large',
                        color: 'primary',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: {
                                        name: 'MoreVert',
                                        style: {
                                            display: 'flex',
                                        },
                                        fontSize: 'inherit',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: sizeDSL,
        },
        {
            title: 'Color',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialIconButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                    },
                },
                {
                    props: {
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        color: 'success',
                    },
                },
                {
                    props: {
                        color: 'warning',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: colorDSL,
        },
    ],
};
