import { Migration } from '../Migration';
class Migration_0_11_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.0';
    }
    getChangeList() {
        return ['Add resources to the DSL structure'];
    }
    migrateAppDSL(appDSL) {
        return Object.assign(Object.assign({}, appDSL), { resources: {} });
    }
}
export const migration_0_11_0 = new Migration_0_11_0();
