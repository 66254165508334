var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Checkbox as MaterialCheckbox, } from '@mui/material';
export const Checkbox = forwardRef((_a, ref) => {
    var { disabled, label: initLabel = '', labelPlacement = 'end', className, style, css, error, helperText, variant, size, name, checked, onChange, fullWidth, icon: initlUncheckedIcon, checkedIcon: initCheckedIcon, indeterminateIcon: initIndeterminateIcon, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, translate } = _a, otherSwitchProps = __rest(_a, ["disabled", "label", "labelPlacement", "className", "style", "css", "error", "helperText", "variant", "size", "name", "checked", "onChange", "fullWidth", "icon", "checkedIcon", "indeterminateIcon", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "translate"]);
    // if we receive array of nodes here we should take only first one to avoid an error
    const uncheckedIcon = Array.isArray(initlUncheckedIcon)
        ? initlUncheckedIcon[0]
        : initlUncheckedIcon;
    const checkedIcon = Array.isArray(initCheckedIcon) ? initCheckedIcon[0] : initCheckedIcon;
    const indeterminateIcon = Array.isArray(initIndeterminateIcon)
        ? initIndeterminateIcon[0]
        : initIndeterminateIcon;
    const label = Array.isArray(initLabel) ? initLabel[0] : initLabel;
    return (_jsxs(FormControl, Object.assign({ size: size, disabled: disabled, className: className, style: style, css: css, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, fullWidth: fullWidth, id: id, title: title, lang: lang, translate: translate }, { children: [_jsx(FormControlLabel, { disabled: disabled, label: label, labelPlacement: labelPlacement, name: name, disableTypography: true, control: _jsx(MaterialCheckbox, Object.assign({}, otherSwitchProps, { size: size, checked: checked, onChange: onChange, icon: uncheckedIcon, checkedIcon: checkedIcon, indeterminateIcon: indeterminateIcon })) }), helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
