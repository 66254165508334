import { sort } from 'ramda';
import { STATE_TYPES, nodeListSelectors, } from '@builder/schemas';
import { hasUsedVarInJs, hasUsedVarInNodeListDSL } from '../../utils';
import { STATE_SUFFIX } from './constants';
export const NON_CUSTOM_STATES = [STATE_TYPES.query, STATE_TYPES.function];
export const STATE_TYPE_ORDER = {
    [STATE_TYPES.query]: 2,
    [STATE_TYPES.function]: 1,
    [STATE_TYPES.boolean]: 0,
    [STATE_TYPES.string]: 0,
    [STATE_TYPES.number]: 0,
    [STATE_TYPES.object]: 0,
    [STATE_TYPES.array]: 0,
};
export const sortStatesByUse = (states) => {
    return sort((a, b) => STATE_TYPE_ORDER[a.type] - STATE_TYPE_ORDER[b.type], states);
};
export const isCustomEntry = (state) => {
    return !NON_CUSTOM_STATES.includes(state.type);
};
export const isUsedInSomeState = (state, states) => {
    const isNonCustomState = NON_CUSTOM_STATES.includes(state.type);
    // Only custom states can be used in another states.
    if (isNonCustomState) {
        return false;
    }
    return sortStatesByUse(states)
        .filter(({ type }) => NON_CUSTOM_STATES.includes(type)) // Get only non-custom states
        .some(value => hasUsedVarInJs(state.name, value)); // Get all the non-custom states where state is being used.
};
export const isNotCurrentState = (name, state) => {
    return name !== state.name;
};
export const isUsedInSomeNode = (state, nodeListDSL, componentListDSL) => {
    return hasUsedVarInNodeListDSL(state.name, nodeListDSL, componentListDSL);
};
export const isUsedInSomeRouterHook = (state, routerHooks) => {
    return hasUsedVarInJs(state.name, routerHooks);
};
const includesSubstring = (str, substr) => {
    return str.includes(substr);
};
export const sortHooksCalls = (hooksCalls) => {
    return [
        ...hooksCalls.filter(localHookCall => includesSubstring(localHookCall, '()')),
        ...hooksCalls.filter(localHookCall => includesSubstring(localHookCall, '"')),
        ...hooksCalls.filter(localHookCall => !includesSubstring(localHookCall, '"') && !includesSubstring(localHookCall, '()')),
    ];
};
/**
 *
 * @param args query args for example `{ errorPolicy:'none', onCompleted:(data) =>
 * { console.log('Text from Query onCompleted') }, skip:false }`
 *
 * @returns inserts setData(transformer(data)); in the beggining of the onCompleted function block `{ errorPolicy:'none',
 *  onCompleted:(data) => { setData(transformer(data));
 *  console.log('Text from Query onCompleted') }, skip:false }`
 *
 * This is needed because on the first request on mount transformer should be executed
 * and the "data" state should be updated to return the data
 */
const regex = /onCompleted:(\s*\([^)]*\))\s*=>\s*{([\s\S]*?)}/;
export const insertTransformerInOnCompleted = (args) => {
    const match = args.match(regex);
    if (match) {
        const isFunction = match[2].trim().length === 0;
        const callbackParam = match[1].trim().replace(/[()]/g, '');
        const transformedInput = args.replace(match[0], `onCompleted:${match[1]} => { setData(transformer(${callbackParam})); \n ${match[2]} ${isFunction ? 'onCompleted(data)' : ''}}`);
        return transformedInput;
    }
    return `${args.slice(0, -1)} \n onCompleted:(response) => { setData(transformer(response)); \n onCompleted(data); }}`;
};
export const resetCallbacksInLazyQuery = (args) => {
    return `${args.slice(0, -1)} \n onCompleted:() => {}, \n onError:() => {}}`;
};
export const getCallbackAndTransformer = (stateDSL, arg, transformationPropsMap) => {
    var _a, _b, _c;
    switch (arg) {
        case 'onTranformer':
            return stateDSL.args.typeOnTransformer === 'code' || !stateDSL.args.typeOnTransformer
                ? stateDSL.args.transformer || '(data) => data'
                : stateDSL.args.onTransformerFunction &&
                    `return ${(_a = stateDSL.args.onTransformerFunction) === null || _a === void 0 ? void 0 : _a.split('(')[0]}(data)`;
        case 'onCompleted':
            return stateDSL.args.typeOnCompleted === 'code' || !stateDSL.args.typeOnCompleted
                ? transformationPropsMap.onCompleted || '(data) => data'
                : stateDSL.args.onCompletedFunction &&
                    `(data) => {
      return ${(_b = stateDSL.args.onCompletedFunction) === null || _b === void 0 ? void 0 : _b.split('(')[0]}(data)
    }`;
        case 'onError':
            return stateDSL.args.typeOnError === 'code' || !stateDSL.args.typeOnError
                ? transformationPropsMap.onError || '(error) => error'
                : stateDSL.args.onErrorFunction &&
                    `(error) => {
      return ${(_c = stateDSL.args.onErrorFunction) === null || _c === void 0 ? void 0 : _c.split('(')[0]}(error)
    }`;
        default:
            return undefined;
    }
};
export const generateHookDeclarationNameWithPageName = (appDSL, stateDSL, originalDeclarationName) => {
    var _a;
    const routeNodes = nodeListSelectors.getAllRouteNodes(appDSL.nodes);
    const pagesStates = routeNodes.reduce((acc, routeNode) => {
        const pageInfo = {
            pageName: routeNode.alias.trim().replaceAll(' ', '').replace('Wrapper', ''),
            stateIDList: routeNode.states ? routeNode.states.map(state => state.stateID) : [],
        };
        return [...acc, pageInfo];
    }, []);
    const stateParentPageName = ((_a = pagesStates.find(page => page.stateIDList.includes(stateDSL.id))) === null || _a === void 0 ? void 0 : _a.pageName) || '';
    return `${originalDeclarationName}${stateParentPageName}${STATE_SUFFIX}`;
};
