import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { SchemaError, ERROR_SCOPES, isNil, ensureCorrectMimetype } from '@builder/utils';
import { ASSET_TYPES } from '../constants';
import { isFileAssetDSL } from './asset-selectors';
const getAssetListDSL = (assetListDSL) => assetListDSL;
const getAssetID = (_, props) => props.assetID;
const getAssetBackendArray = (_, props) => props.assetBackendArray;
const getAssetBackendList = (_, props) => props.assetBackendList;
const getAssetName = (_, props) => props.assetName;
const getAssetFileWithBackendData = (assetFileDSL, assetBackend) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return Object.assign(Object.assign({}, assetFileDSL), { src: (_a = assetBackend.file) === null || _a === void 0 ? void 0 : _a.downloadUrl, previewUrl: (_b = assetBackend.file) === null || _b === void 0 ? void 0 : _b.previewUrl, path: (_d = (_c = assetBackend.file) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.path, mimeType: ensureCorrectMimetype((_f = (_e = assetBackend.file) === null || _e === void 0 ? void 0 : _e.meta) === null || _f === void 0 ? void 0 : _f.path, (_h = (_g = assetBackend.file) === null || _g === void 0 ? void 0 : _g.meta) === null || _h === void 0 ? void 0 : _h.mimetype) });
};
export const getAssetDSL = createSelector(getAssetListDSL, getAssetID, (assetListDSL, assetID) => {
    const assetDSL = assetListDSL[assetID];
    if (!assetDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Asset with id ${assetID} not found.`);
    }
    return assetDSL;
});
export const getAssetFolderDSL = createSelector(getAssetDSL, assetDSL => {
    if (assetDSL.type !== ASSET_TYPES.folder) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Asset with id ${assetDSL.id} has not folder type.`);
    }
    return assetDSL;
});
export const getAssetArrayDSL = createSelector(getAssetListDSL, assetListDSL => {
    return Object.values(assetListDSL);
});
export const getAssetFileArrayDSL = createSelector(getAssetArrayDSL, (assetArrayDSL) => {
    return assetArrayDSL.filter(isFileAssetDSL);
});
export const getAssetFileListDSL = createSelector(getAssetListDSL, (assetListDSL) => {
    return Object.values(assetListDSL).reduce((assetFileListDSL, assetDSL) => {
        if (assetDSL.type === ASSET_TYPES.folder) {
            return assetFileListDSL;
        }
        return Object.assign(Object.assign({}, assetFileListDSL), { [assetDSL.id]: assetDSL });
    }, {});
});
export const getFilteredAssetTypes = (_, props) => {
    return props.filteredAssetTypes;
};
export const getAssetFileArrayByType = createSelector((assetFileWithBackendDataArray) => assetFileWithBackendDataArray, getFilteredAssetTypes, (assetFileWithBackendDataArray, filteredAssetTypes) => {
    return assetFileWithBackendDataArray.filter(assetFileWithBackendDataDSL => !filteredAssetTypes.includes(assetFileWithBackendDataDSL.type));
});
export const getAssetFileBackendIDs = createSelector(getAssetFileArrayDSL, assetFileArrayDSL => {
    return assetFileArrayDSL.map(({ backendFileID }) => backendFileID).filter(Boolean);
});
/**
 * @returns array of assets which exists both in DSL and backend
 */
export const getAssetFileWithBackendDataArray = createSelector(getAssetFileArrayDSL, getAssetBackendArray, (assetFileArrayDSL, assetBackendArray) => {
    return assetFileArrayDSL
        .map(assetFileDSL => {
        const appropriateAssetBackend = assetBackendArray.find(assetBackend => { var _a; return ((_a = assetBackend.file) === null || _a === void 0 ? void 0 : _a.fileId) === assetFileDSL.backendFileID; });
        if (!appropriateAssetBackend) {
            return null;
        }
        return getAssetFileWithBackendData(assetFileDSL, appropriateAssetBackend);
    })
        .filter(Boolean);
});
/**
 * @returns list of assets which exists both in DSL and backend
 */
export const getAssetFileWithBackendDataList = createSelector(getAssetFileListDSL, getAssetBackendList, (assetFileListDSL, assetBackendList) => {
    return Object.values(assetFileListDSL).reduce((assetFileWithBackendDataList, assetFileDSL) => {
        const appropriateAssetBackend = assetBackendList[assetFileDSL.backendFileID];
        if (!appropriateAssetBackend) {
            return assetFileWithBackendDataList;
        }
        return Object.assign(Object.assign({}, assetFileWithBackendDataList), { [assetFileDSL.id]: getAssetFileWithBackendData(assetFileDSL, appropriateAssetBackend) });
    }, {});
});
const getAssetPath = (assetListDSL, assetID, relativePath) => {
    const assetDSL = getAssetDSL(assetListDSL, { assetID });
    const { parentID } = assetDSL;
    if (isNil(parentID)) {
        return `${assetDSL.name}/${relativePath}`;
    }
    return getAssetPath(assetListDSL, parentID, `${assetDSL.name}/${relativePath}`);
};
/**
 * @returns representation of asset in string format "assets/{someFolders}/assetID"
 */
export const getAssetPathByID = createSelector(getAssetListDSL, getAssetID, (assetListDSL, assetID) => {
    const assetDSL = getAssetDSL(assetListDSL, { assetID });
    const { parentID } = assetDSL;
    if (parentID) {
        return getAssetPath(assetListDSL, parentID, assetDSL.name);
    }
    return assetDSL.name;
});
export const getAssetIDByName = createSelector(getAssetArrayDSL, getAssetName, (assetArray, assetName) => {
    var _a;
    return (_a = assetArray.find(asset => asset.name === assetName)) === null || _a === void 0 ? void 0 : _a.id;
});
export const isAssetNameDSLNotUnique = createSelector(getAssetArrayDSL, (_, props) => props, (assetListDSL, { name }) => {
    if (!(name === null || name === void 0 ? void 0 : name.trim())) {
        return false;
    }
    return assetListDSL.some(assetDSL => assetDSL.name === name.trim());
});
const getAssetFileArray = (assetFileArray) => assetFileArray;
export const getAssetFileDSL = createSelector(getAssetFileArray, getAssetID, (asetFileArray, assetID) => {
    return asetFileArray.find(asset => asset.id === assetID);
});
export const getAllDescendantFolderIDs = createSelector(getAssetListDSL, (_, props) => props, (assetListDSL, { folderIDs }) => {
    const childrenIDs = folderIDs
        .map(folderID => {
        const assetDSL = assetListDSL[folderID];
        if (assetDSL.type !== ASSET_TYPES.folder) {
            return [];
        }
        return assetDSL.children;
    })
        .flat();
    const childrenFolderIDs = childrenIDs.filter(assetID => assetListDSL[assetID].type === ASSET_TYPES.folder);
    if (isEmpty(childrenFolderIDs)) {
        return childrenFolderIDs;
    }
    return [
        ...childrenFolderIDs,
        ...getAllDescendantFolderIDs(assetListDSL, { folderIDs: childrenFolderIDs }),
    ];
});
