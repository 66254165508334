import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { assocSettings } from '../../common';
import { NS_ID } from '../../common/constants';
import { getCommonFormFieldPropertiesSettings, getCommonActionListSettings, getCommonActionSettings, } from '../../common/settings';
import { getBuilderComponentsSwitchSettings } from '../../material-components/BuilderComponentsSwitch/BuilderComponentsSwitch.settings';
const PREFIX_PATH = ['switchProps'];
const basicBuilderComponentsSwitchSettings = getBuilderComponentsSwitchSettings(PREFIX_PATH);
export const BuilderComponentsFormSwitchSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldPropertiesSettings(), ...settings.children] }))))(basicBuilderComponentsSwitchSettings.properties),
    style: basicBuilderComponentsSwitchSettings.style,
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: ['onChange'],
                            children: getCommonActionSettings(['onChange']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
