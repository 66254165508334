/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types, no-new-func */
import debounce from 'lodash.debounce';
import { isEmpty } from 'ramda';

import { LIBRARIES, LibraryDSL } from '@builder/schemas';
import { log } from '@builder/utils';

const libsInstances: any = {};

const loadScriptLibrary = (idScript: string, urlCDN: string) => {
  if (libsInstances[idScript]) {
    return libsInstances[idScript];
  }

  const request = new XMLHttpRequest();
  request.open('GET', urlCDN, false);
  request.send(null);

  if (request.status === 200) {
    const script = document.createElement('script');
    script.id = idScript;
    script.type = 'text/javascript';
    script.async = false;
    script.defer = false;
    script.text = `${request.responseText}`;

    document.body.appendChild(script);
  } else {
    // TODO: implement alternative library when process fail
  }

  libsInstances[idScript] = (window as any)[idScript];

  const libraryCDN = (window as any).document.getElementById(idScript);
  libraryCDN && (window as any).document.body.removeChild(libraryCDN);
  try {
    delete (window as any)[idScript];
  } catch (e) {
    (window as any)[idScript] = undefined;
  }

  return libsInstances[idScript];
};

export const throttledLogWarn = debounce(log.warn, 500);
export const createFunction = (
  functionCode: string,
  functionState: Record<string, unknown>,
  functionLibraries: LibraryDSL[],
  isNewFunction: boolean,
): Function => {
  const handler = {
    apply() {
      const librariesState = functionLibraries.reduce((libraries, library) => {
        const libraryData = LIBRARIES.find(lib => lib.name === library.name);
        const libraryVersion = libraryData?.versions.find(
          ({ version }) => version === library.version,
        );

        const libraryInstance =
          libraryVersion?.CDN && libraryData?.varName
            ? loadScriptLibrary(libraryData?.varName, libraryVersion.CDN)
            : undefined;

        return {
          ...libraries,
          [library.alias]: libraryInstance,
        };
      }, {});

      if (!isEmpty(librariesState)) {
        if ('librariesState' in (window.parent as any)) {
          (window.parent as any).librariesState = {
            ...(window.parent as any).librariesState,
            ...librariesState,
          };
        } else {
          (window.parent as any).librariesState = librariesState;
        }
      }

      const allStates = {
        ...functionState,
        ...librariesState,
      };

      if (!isNewFunction && /await(\s|\()/gm.test(functionCode)) {
        const AsyncFunction = new Function(
          'return Object.getPrototypeOf(async function () {}).constructor',
        )();
        const func = new AsyncFunction(...Object.keys(allStates), `${functionCode}`);
        return func(...Object.values(allStates));
      }

      const func = new Function(...Object.keys(allStates), `${functionCode}`);

      return func(...Object.values(allStates));
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Proxy(() => {}, handler);
};
