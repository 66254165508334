import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsFilePickerSettings } from './BuilderComponentsFilePicker.settings';
export const BuilderComponentsFilePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFilePicker,
    displayName: 'File Picker',
    icon: 'formBlock',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FilePicker',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                acceptedFiles: [],
                initialFiles: [],
                filesLimit: 3,
                maxFileSize: 3000000,
                dropzoneText: 'Drag and drop a file here or click',
                clearOnUnmount: true,
                disableRejectionFeedback: false,
                showPreviews: false,
                showPreviewsInDropzone: true,
                showFileNames: false,
                showFileNamesInPreview: false,
                useChipsForPreview: false,
                previewText: 'Preview:',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { acceptedFiles: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.string,
                },
            }, filesLimit: { type: COMPONENT_DSL_PROP_TYPES.number }, initialFiles: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: { type: COMPONENT_DSL_PROP_TYPES.any },
            }, clearOnUnmount: { type: COMPONENT_DSL_PROP_TYPES.boolean }, Icon: { type: COMPONENT_DSL_PROP_TYPES.elementType }, fileObjects: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                },
            }, maxFileSize: { type: COMPONENT_DSL_PROP_TYPES.number }, dropzoneText: { type: COMPONENT_DSL_PROP_TYPES.string }, dropzoneClass: { type: COMPONENT_DSL_PROP_TYPES.string }, dropzoneParagraphClass: { type: COMPONENT_DSL_PROP_TYPES.string }, disableRejectionFeedback: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showPreviews: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showPreviewsInDropzone: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showFileNames: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showFileNamesInPreview: { type: COMPONENT_DSL_PROP_TYPES.boolean }, useChipsForPreview: { type: COMPONENT_DSL_PROP_TYPES.boolean }, previewChipProps: { type: COMPONENT_DSL_PROP_TYPES.object }, previewGridClasses: { type: COMPONENT_DSL_PROP_TYPES.object }, previewGridProps: { type: COMPONENT_DSL_PROP_TYPES.object }, previewText: { type: COMPONENT_DSL_PROP_TYPES.string }, showAlerts: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                // 'error', 'info', 'success'
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.enum,
                },
            }, alertSnackbarProps: { type: COMPONENT_DSL_PROP_TYPES.object }, dropzoneProps: { type: COMPONENT_DSL_PROP_TYPES.object }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, getFileLimitExceedMessage: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'filesLimit',
                        path: [0],
                    },
                ],
            }, getFileAddedMessage: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'fileName',
                        path: [0],
                    },
                ],
            }, getFileRemovedMessage: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'fileName',
                        path: [0],
                    },
                ],
            }, getDropRejectMessage: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'rejectedFile',
                        path: [0],
                    },
                    {
                        name: 'acceptedFiles',
                        path: [1],
                    },
                    {
                        name: 'maxFileSize',
                        path: [2],
                    },
                ],
            }, getPreviewIcon: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'objectFile',
                        path: [0],
                    },
                    {
                        name: 'classes',
                        path: [1],
                    },
                ],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'loadedFiles',
                        path: [0],
                    },
                ],
            }, onAdd: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'newFiles',
                        path: [0],
                    },
                ],
            }, onDelete: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'deletedFileObject',
                        path: [0],
                    },
                    {
                        name: 'index',
                        path: [1],
                    },
                ],
            }, onDrop: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'droppedFiles',
                        path: [0],
                    },
                    {
                        name: 'event',
                        path: [1],
                    },
                ],
            }, onDropRejected: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'rejectedFiles',
                        path: [0],
                    },
                    {
                        name: 'event',
                        path: [1],
                    },
                ],
            }, onAlert: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'message',
                        path: [0],
                    },
                    {
                        name: 'variant',
                        path: [1],
                    },
                ],
            } }),
    },
    settings: BuilderComponentsFilePickerSettings,
};
