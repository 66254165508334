import { NodeID } from '@builder/schemas';

import { useEditableNodeListDSL } from './useEditableNodeListDSL';

/**
 * @returns List of the nodes which can be showed in the navigator and can be edited in the dashboard
 */
export const useEditableNodeIDs = (): NodeID[] => {
  const editableNodeListDSL = useEditableNodeListDSL();

  return Object.keys(editableNodeListDSL);
};
