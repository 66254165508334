import styled from '@emotion/styled';
import { Select as MuiSelect } from '@mui/material';

import { InputLabel } from '../common';

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 8px;
  font-size: 10px;
`;

export const HiddenSelect = styled(MuiSelect)`
  display: none;
`;

export const AddButton = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
