var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { Link as MuiLink } from '@mui/material';
import { useHistory } from 'react-router-dom';
export const RouterLink = forwardRef((_a, ref) => {
    var { absolute = false, onClick, to = '/', replace = false, style = {}, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, rest = __rest(_a, ["absolute", "onClick", "to", "replace", "style", 'data-test', 'data-node-id', 'data-node-render-path']);
    const routerHistory = useHistory();
    const commonProps = Object.assign(Object.assign({}, rest), { 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, style: Object.assign({ cursor: 'pointer' }, style) });
    if (absolute) {
        return _jsx(MuiLink, Object.assign({ ref: ref, onClick: onClick }, commonProps));
    }
    return (_jsx(MuiLink, Object.assign({ ref: ref }, commonProps, { onClick: (event) => {
            onClick === null || onClick === void 0 ? void 0 : onClick(event);
            replace ? routerHistory.replace(to) : routerHistory.push(to);
        } })));
});
