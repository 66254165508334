import { values } from 'ramda';
import { GroupOfStates } from './utils/classes/GroupOfStates';
import { SeparateStatesByType } from './utils/classes/SeparateStatesByType';
import { getFirstItemOfTheArray, getLastItemOfTheArray, getOrderedStatesAsStateDSLList, getTimesUsed, sortFromLargestToSmallest, statesListIsEmpty, } from './utils/functions';
// This function orders the states by the number of times they are called in other states.
// The states that are called the most times are the ones that must be declared first.
export const sortStatesByTimesUsed = (appDSL, onAppAuditNotifications) => {
    const statesMetadata = [];
    const groupOfStatesByTimesUsed = [];
    const groupOfStatesByTimesUsedByLevels = [];
    // 1. Separate states by type, becaus the sort process only makes sense for query and function states
    const statesByType = new SeparateStatesByType(appDSL.states);
    const queryAndFunctionStates = statesByType.getFunctionAndQueryStates();
    const noQueryOrFunctionStates = statesByType.getNoQueryOrFunctionStates();
    // 2. If the list of states is empty, or the list of query and function states is empty,return the list of states
    if (statesListIsEmpty(appDSL.states) || statesListIsEmpty(queryAndFunctionStates)) {
        return [...noQueryOrFunctionStates, ...queryAndFunctionStates];
    }
    // 3. Add a value for each state (timesUsed), to store the times a state is used in other states
    queryAndFunctionStates.forEach(state => {
        statesMetadata.push(Object.assign(Object.assign({}, state), { timesUsed: getTimesUsed(state, [...queryAndFunctionStates, ...values(appDSL.resources)]) }));
    });
    // 4. Sort the states from largest to smallest by timesUsed
    sortFromLargestToSmallest(statesMetadata);
    const firstItem = getFirstItemOfTheArray(statesMetadata);
    // 5. If the states are no used (called by other states), it is not necessary to sort them.
    if (firstItem.timesUsed === 0) {
        return [...noQueryOrFunctionStates, ...queryAndFunctionStates];
    }
    // 6. Group the states by timesUsed in arrays, and store the arrays in a big array
    const lastItem = getLastItemOfTheArray(statesMetadata);
    for (let index = firstItem.timesUsed; index >= lastItem.timesUsed; index -= 1) {
        groupOfStatesByTimesUsed.push(statesMetadata.filter(state => state.timesUsed === index));
    }
    // 7. If there are a group of states that is called the same number of times, It is necessary to sort them by levels
    const sortGroupOfStatesByLevels = (states) => {
        const groupOfStates = new GroupOfStates(states);
        const groupOfStatesSorted = groupOfStates.sort();
        if (groupOfStatesSorted.length > 1) {
            for (const stateGroup of groupOfStatesSorted) {
                if (stateGroup.length > 0) {
                    sortGroupOfStatesByLevels(stateGroup);
                }
            }
        }
        groupOfStatesByTimesUsedByLevels.push(...groupOfStatesSorted);
    };
    sortGroupOfStatesByLevels(statesMetadata);
    return [
        ...noQueryOrFunctionStates,
        ...getOrderedStatesAsStateDSLList(groupOfStatesByTimesUsedByLevels, onAppAuditNotifications),
    ];
};
