var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { omit } from 'ramda';
import { MUTATION_ARGS_DSL, RESOURCE_DSL, } from '@builder/schemas';
import { transformRequestStateProps } from '../../app-nodes';
import { useRefetchQuerySubscribe } from '../../event-subscriptions';
import { useRuntimeErrorCallbacks } from '../../providers';
import { createAndRunFunction, useTransformData } from '../../utils';
import { useGetApolloClientByAppState } from './useGetApolloClientByAppState';
export const useAppEngineMutationState = ({ appStateDSL, resourceListDSL, componentListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const args = transformRequestStateProps({
        requestStateDSL: appStateDSL,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        propListDSL: MUTATION_ARGS_DSL,
    }, appRuntimeState, { onAppAuditNotifications }, libraries);
    const transformCommonOptions = {
        appStateDSL,
        appRuntimeState,
    };
    const transformData = useTransformData({ libraries });
    const appropriateApolloClient = useGetApolloClientByAppState({
        appStateDSL,
        resourceListDSL,
        appRuntimeState,
        componentListDSL,
        assetListDSL,
        propListDSL: RESOURCE_DSL,
    });
    const { ignoreResults } = appStateDSL.args;
    const [mutationData, setMutationData] = useState();
    const [mutationFunc, { loading, error: mutationError, data }] = useMutation(gql(appStateDSL.args.body), Object.assign(Object.assign({}, omit(['body', 'transformer'], args)), { onCompleted: response => {
            var _a, _b, _c, _d;
            const transformedData = transformData(transformCommonOptions, ignoreResults ? data : response);
            if (appStateDSL.args.typeOnCompleted === 'code' || !appStateDSL.args.typeOnCompleted) {
                ((_a = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _a === void 0 ? void 0 : _a.onCompleted) &&
                    createAndRunFunction((_b = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _b === void 0 ? void 0 : _b.onCompleted, Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { data: transformedData }) }), { libraries });
            }
            if (appStateDSL.args.typeOnCompleted === 'function') {
                ((_c = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _c === void 0 ? void 0 : _c.onCompletedFunction) &&
                    createAndRunFunction(`return ${(_d = appStateDSL.args.onCompletedFunction) === null || _d === void 0 ? void 0 : _d.split('(')[0]}(data)`, Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { data: transformedData }) }), { libraries });
            }
            ignoreResults ? setMutationData(data) : setMutationData(transformedData);
        }, optimisticResponse: _ => {
            appStateDSL.args.optimisticResponse
                ? setMutationData(transformData(transformCommonOptions, appStateDSL.args.optimisticResponse))
                : setMutationData(mutationData);
        }, onError: error => {
            var _a, _b, _c, _d, _e;
            if (appStateDSL.args.typeOnError === 'code' || !appStateDSL.args.typeOnError) {
                ((_a = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _a === void 0 ? void 0 : _a.onError) &&
                    createAndRunFunction((_b = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _b === void 0 ? void 0 : _b.onError, Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { error }) }), { libraries });
            }
            if (appStateDSL.args.typeOnError === 'function') {
                ((_c = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _c === void 0 ? void 0 : _c.onErrorFunction) &&
                    createAndRunFunction(`return ${(_e = (_d = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _d === void 0 ? void 0 : _d.onErrorFunction) === null || _e === void 0 ? void 0 : _e.split('(')[0]}(error)`, Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { error }) }), { libraries });
            }
        }, client: appropriateApolloClient }));
    const updateDataForRefetch = useCallback(() => {
        return mutationFunc({
            variables: args.variables,
        });
    }, [mutationFunc, args.variables]);
    useRefetchQuerySubscribe({
        stateID: appStateDSL.id,
        refetch: updateDataForRefetch,
    });
    const handleMutationFunc = useCallback((options) => __awaiter(void 0, void 0, void 0, function* () {
        return mutationFunc(options).then(response => {
            return transformData(transformCommonOptions, ignoreResults ? data : response.data);
        });
    }), [mutationFunc, transformData]);
    return {
        loading,
        error: mutationError,
        data: mutationData,
        run: handleMutationFunc,
        name: appStateDSL.name,
        type: appStateDSL.type,
        query: appStateDSL.args.body,
        variables: appStateDSL.args.variables,
        refetchQueries: appStateDSL.args.refetchQueries,
        optimisticResponse: appStateDSL.args.optimisticResponse,
        awaitRefetchQueries: appStateDSL.args.awaitRefetchQueries,
        ignoreResults: appStateDSL.args.ignoreResults,
    };
};
