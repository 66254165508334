import { path, prop, isEmpty } from 'ramda';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { ERROR_SCOPES, isArray, isString, isUndefined, SchemaError, SystemError, } from '@builder/utils';
import { STATE_SCOPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES, ROOT_NODE_ID, } from '../constants';
import { PropAssert, PropChecker } from '../validation';
import { PropSchemaChecker } from '../validation/helpers/PropSchemaChecker';
import { getAllChildrenIDsByProps } from './utils';
const getNodeDSL = (nodeDSL) => nodeDSL;
const getNodeDSLWithParams = (nodeDSL, _) => nodeDSL;
const getComponentDSLFromList = (nodeDSL, componentListDSL) => componentListDSL[nodeDSL.name];
const getStateListDSL = (_, props) => props.appStateListDSL;
const getComponentListDSL = (_, props) => props.componentListDSL;
const getComponentDSL = createSelector(getNodeDSL, getComponentListDSL, (nodeDSL, componentListDSL) => {
    let componentDSL = componentListDSL[nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.name];
    if (isUndefined(nodeDSL)) {
        componentDSL = componentListDSL.root;
    }
    if (!componentDSL) {
        throw new SystemError(ERROR_SCOPES.schemas, `Component with ${nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.name} name not found`);
    }
    return componentDSL;
});
export const isRoot = createSelector(getNodeDSL, (nodeDSL) => {
    return nodeDSL.id === ROOT_NODE_ID;
});
export const isNotRoot = createSelector(getNodeDSL, (nodeDSL) => {
    return nodeDSL.id !== ROOT_NODE_ID;
});
export const isTextNode = createSelector(getNodeDSL, nodeDSL => nodeDSL.name === COMPONENT_DSL_NAMES.Text);
export const isFragmentNode = createSelector(getNodeDSL, nodeDSL => nodeDSL.name === COMPONENT_DSL_NAMES.Fragment);
export const isImageNode = createSelector(getNodeDSL, nodeDSL => {
    return nodeDSL.name === COMPONENT_DSL_NAMES.img;
});
export const isImageNodeAndEmpty = createSelector(getNodeDSL, nodeDSL => {
    return nodeDSL.name === COMPONENT_DSL_NAMES.img && isEmpty(nodeDSL.props.src);
});
export const isIconNode = createSelector(getNodeDSL, nodeDSL => {
    return nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsIcon;
});
export const isIconNodeAndEmpty = createSelector(getNodeDSL, nodeDSL => {
    return nodeDSL.name === COMPONENT_DSL_NAMES.MaterialListItemIcon && isEmpty(nodeDSL.props.name);
});
export const isChildrenPropEmpty = createSelector(getNodeDSL, nodeDSL => {
    var _a;
    const childrenProp = nodeDSL.props.children;
    return !((_a = childrenProp === null || childrenProp === void 0 ? void 0 : childrenProp.toString()) === null || _a === void 0 ? void 0 : _a.trim());
});
export const getStateArrayListDSL = createSelector(getStateListDSL, states => Object.values(states));
/**
 * Returns @node ' immediate children ids.
 * @param chooseOnlyFromProps forces selector to only scan for provided propNames
 */
export const getAllImmediateChildrenIDsWithParams = createSelector(getNodeDSLWithParams, getComponentDSL, (_, props) => props.chooseOnlyFromProps, (nodeDSL, componentDSL, chooseOnlyFromProps) => {
    if (!componentDSL) {
        throw new SystemError(ERROR_SCOPES.schemas, `Component schema for node ${nodeDSL.name} not found`);
    }
    return getAllChildrenIDsByProps({
        propsSchema: componentDSL.schema.props,
        chooseOnlyFromProps,
        nodeProps: nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.props,
    });
});
/**
 * TODO: move to app-schema-selectors
 */
export const getAllImmediateChildrenIDs = createSelector(getNodeDSL, getComponentListDSL, (nodeDSL, componentListDSL) => {
    return getAllImmediateChildrenIDsWithParams(nodeDSL, { componentListDSL });
});
export const getAllImmediateDefaultChildrenIDs = createSelector(getNodeDSL, getComponentListDSL, getComponentDSL, (nodeDSL, componentListDSL, componentDSL) => {
    const targetPropName = componentDSL.schema.dndTargetPropName || 'children';
    const childrenIDs = getAllImmediateChildrenIDsWithParams(nodeDSL, {
        chooseOnlyFromProps: [targetPropName],
        componentListDSL,
    });
    return childrenIDs;
});
export const isSymbol = createSelector(getNodeDSL, getComponentDSLFromList, (nodeDSL, componentDSL) => {
    return componentDSL.type === COMPONENT_DSL_TYPES.symbol;
});
/**
 * Returns node children ids which specified in arg.
 */
export const getIntersectionImmediateChildrenIDs = createSelector(getNodeDSL, getComponentListDSL, (_, props) => props.nodeIDs, (nodeDSL, componentListDSL, nodeIDs) => {
    const childrenNodeIDs = getAllImmediateChildrenIDs(nodeDSL, { componentListDSL });
    return childrenNodeIDs.filter(nodeID => nodeIDs.some(id => id === nodeID));
});
export const getTextProp = createSelector(getNodeDSL, (nodeDSL) => {
    return nodeDSL.props.children ? `${nodeDSL.props.children}` : '';
});
export const getShowIfCondition = createSelector(getNodeDSL, (nodeDSL) => {
    var _a, _b;
    return (_b = (_a = nodeDSL.condition) === null || _a === void 0 ? void 0 : _a.showIf) !== null && _b !== void 0 ? _b : null;
});
export const getAllowedRoles = createSelector(getNodeDSL, (nodeDSL) => {
    var _a;
    return ((_a = nodeDSL.condition) === null || _a === void 0 ? void 0 : _a.allowedRoles) || [];
});
export const hasConditionRule = createSelector(getShowIfCondition, (showIfCondition) => {
    return Boolean(showIfCondition);
});
export const hasAllowedRolesRule = createSelector(getAllowedRoles, (allowedRoles) => {
    return !isEmpty(allowedRoles);
});
export const isIterable = createSelector(getNodeDSL, (nodeDSL) => {
    var _a, _b;
    return Boolean((_a = nodeDSL.iterator) === null || _a === void 0 ? void 0 : _a.data) && Boolean((_b = nodeDSL.iterator) === null || _b === void 0 ? void 0 : _b.name);
});
export const getIterator = createSelector(getNodeDSL, (nodeDSL) => {
    var _a;
    return (_a = nodeDSL.iterator) !== null && _a !== void 0 ? _a : null;
});
/**
 * @returns True if route node match the pathname.
 */
export const matchRouteNodePath = createSelector(getNodeDSL, (_, pathname) => pathname, (nodeDSL, pathname) => {
    if (nodeDSL.name !== COMPONENT_DSL_NAMES.BuilderComponentsRoute &&
        nodeDSL.name !== COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout) {
        throw new SystemError(ERROR_SCOPES.appEngine, `Expected a ${COMPONENT_DSL_NAMES.BuilderComponentsRoute} component, but got ${nodeDSL.name}`);
    }
    const { path: routeNodePath } = nodeDSL.props;
    if (!isString(routeNodePath) && !isArray(routeNodePath)) {
        throw new SchemaError(ERROR_SCOPES.appEngine, `Path prop must be string or array of strings in the ${COMPONENT_DSL_NAMES.BuilderComponentsRoute} component`);
    }
    const match = matchPath(pathname, {
        path: routeNodePath,
        exact: true,
        strict: false,
    });
    return match;
});
export const isRootRoute = createSelector(getNodeDSL, (nodeDSL) => {
    return path(['props', 'path'], nodeDSL) === '/';
});
export const isRoute = createSelector(getNodeDSL, (nodeDSL) => {
    return path(['props', 'path'], nodeDSL) !== undefined;
});
export const shouldBeShownInBreadcrumbs = createSelector(getComponentDSLFromList, componentDSL => {
    var _a, _b;
    const hideBreadcrumb = (_b = (_a = componentDSL === null || componentDSL === void 0 ? void 0 : componentDSL.schema) === null || _a === void 0 ? void 0 : _a.accessibility) === null || _b === void 0 ? void 0 : _b.hideBreadcrumb;
    return !hideBreadcrumb;
});
/**
 * @deprecated rewrite with reselect
 */
export const findNodePlacedPropNameInParent = (parentNodeSchemaDSL, parentNodePropsDSL, nodeID, prefixPath = []) => {
    const allPropNames = Object.keys(parentNodeSchemaDSL);
    for (const propName of allPropNames) {
        const propSchema = parentNodeSchemaDSL[propName];
        const propValue = parentNodePropsDSL[propName];
        if (PropChecker.Schema.isRenderableProp(propSchema)) {
            if (propValue) {
                PropAssert.Value.assertIsReactNodeProp(propValue);
                if (propValue.nodes.includes(nodeID)) {
                    return [...prefixPath, propName];
                }
            }
        }
        if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(propSchema)) {
            const arrayPropSchema = propSchema.item.props;
            PropAssert.Value.assertIsArrayProp(propValue, propName);
            let arrayPropIndex = 0;
            for (const arrayItem of propValue) {
                const childrenPropFromArray = findNodePlacedPropNameInParent(arrayPropSchema, arrayItem, nodeID, [...prefixPath, propName, arrayPropIndex]);
                if (!isEmpty(childrenPropFromArray)) {
                    return [...childrenPropFromArray];
                }
                arrayPropIndex += 1;
            }
        }
        if (PropChecker.Schema.isObjectPropWithDefinedProps(propSchema)) {
            const objectPropSchema = propSchema.props;
            if (propValue) {
                PropAssert.Value.assertIsObjectProp(propValue, propName);
                const objectPropName = findNodePlacedPropNameInParent(objectPropSchema, propValue, nodeID, [
                    ...prefixPath,
                    propName,
                ]);
                if (!isEmpty(objectPropName)) {
                    return objectPropName;
                }
            }
        }
    }
    return [];
};
export const getStateConnectionsList = createSelector(getNodeDSL, nodeDSL => { var _a; return (_a = nodeDSL.states) !== null && _a !== void 0 ? _a : []; });
export const getLocalStateConnectionsList = createSelector(getStateConnectionsList, getStateListDSL, (allStateConnections, appStates) => {
    return allStateConnections.filter(({ stateID }) => {
        const state = appStates[stateID];
        if (!appStates[stateID]) {
            throw new SchemaError(ERROR_SCOPES.schemas, `State with id ${stateID} wasn't found.`);
        }
        return state.scope === STATE_SCOPES.local;
    });
});
export const getLocalStateArrayDSL = createSelector(getLocalStateConnectionsList, getStateListDSL, (localStateConnections, appStates) => {
    return localStateConnections.map(({ stateID }) => {
        const state = appStates[stateID];
        if (!appStates[stateID]) {
            throw new SchemaError(ERROR_SCOPES.schemas, `State with id ${stateID} wasn't found.`);
        }
        return state;
    });
});
export const getGlobalStateConnectionsList = createSelector(getStateConnectionsList, getStateListDSL, (allStateConnections, appStates) => {
    return allStateConnections.filter(stateConnection => {
        const state = appStates[stateConnection.stateID];
        return state.scope === STATE_SCOPES.global;
    });
});
export const getRequiredStateConnectionIDs = createSelector(getStateConnectionsList, stateConnectionList => stateConnectionList.filter(stateConnection => stateConnection.required).map(prop('stateID')));
/**
 * @returns true if child node alone and inside the required prop
 */
export const getChildNodeRequired = createSelector(getNodeDSL, getComponentDSLFromList, (_, __, childNodeID) => childNodeID, (nodeDSL, componentDSL, childNodeID) => {
    return Object.keys(nodeDSL.props).reduce((accum, propName) => {
        const propSchema = componentDSL.schema.props[propName];
        const propValue = nodeDSL.props[propName];
        if (PropSchemaChecker.isRenderableProp(propSchema) && propSchema.required) {
            PropAssert.Value.assertIsRenderableProp(propValue);
            const isItParentProp = propValue.nodes.includes(childNodeID);
            if (isItParentProp && propValue.nodes.length === 1)
                return true;
        }
        return accum;
    }, false);
});
/**
 * @returns true if presentation was applied to the node
 */
export const isPresentationApplied = createSelector(getNodeDSL, (_, props) => props.presentationName, (nodeDSL, presentationName) => {
    var _a;
    return (_a = nodeDSL.presentations) === null || _a === void 0 ? void 0 : _a.some(appliedPresentationName => appliedPresentationName === presentationName);
});
/**
 * @returns Node either display alias or common display name
 */
export const getNodeAlias = createSelector(getNodeDSL, getComponentDSL, (nodeDSL, componentDSL) => {
    return nodeDSL.alias || componentDSL.schema.aliasPrefix || componentDSL.displayName;
});
const hasCustomAlias = createSelector(getNodeDSL, (_, props) => props.componentDSL, (nodeDSL, componentDSL) => {
    if (!nodeDSL.alias) {
        return false;
    }
    const defaultAliasPrefix = componentDSL.schema.aliasPrefix || componentDSL.displayName;
    return nodeDSL.alias.replace(new RegExp(`^${defaultAliasPrefix} `), '') !== defaultAliasPrefix;
});
/**
 * @returns New alias prefix for node
 * @example
 * BuilderComponentsButton DSL => 'Button'
 * { alias: 'Unique Alias' } => 'Unique Alias'
 * { alias: 'Unique Alias 1' } => 'Unique Alias'
 * { alias: 'Unique Alias 123 1' } => 'Unique Alias 123'
 */
export const getNodeAliasPrefix = createSelector(getNodeDSL, (_, props) => props.componentDSL, (nodeDSL, componentDSL) => {
    const defaultAliasPrefix = componentDSL.schema.aliasPrefix || componentDSL.displayName;
    if (!nodeDSL.alias) {
        return defaultAliasPrefix;
    }
    if (!hasCustomAlias(nodeDSL, { componentDSL })) {
        return defaultAliasPrefix;
    }
    return nodeDSL.alias.replace(new RegExp(/ \d+$/), '');
});
