import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonActionProps, commonBasicProps, commonComponentProps, commonReactProps, } from '../../common/commonProps';
import { FileDropZoneSymbolSettings } from './FileDropZoneSymbol.settings';
export const FileDropZoneSymbol = {
    name: COMPONENT_DSL_NAMES.FileDropZoneSymbol,
    displayName: 'File Dropzone',
    icon: 'FileDropZone',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'alias',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.array,
                    name: 'FileDropZone',
                    defaultValue: [],
                },
            ],
            props: {
                css: '\n  transition: all .2s;\n  &.disabled {\n    opacity:.5;\n  } \n  &.hovering {\n    background-color: rgba(0,0,0,.2);\n  }\n',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FileDropZone.setValue(event)',
                    },
                },
                exceededFileSizeText: 'Max file size exceeded ({maxSize})',
                wrongFileTypeText: 'Wrong file type uploaded',
                showFileList: true,
                icon: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: {
                                name: 'FileUploadOutlined',
                            },
                        },
                    ],
                },
                inputText: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Drag and drop files here',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), commonBasicProps), { exceededFileSizeText: { type: COMPONENT_DSL_PROP_TYPES.string }, wrongFileTypeText: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, inputText: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialTypography],
            }, showFileList: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.BuilderComponentsFileDropZone,
            props: {
                style: '{{ symbolProps.style }}',
                onClick: '{{ symbolProps.onClick }}',
                onChange: '{{ symbolProps.onChange }}',
                css: '{{ symbolProps.css }}',
                children: '{{ [symbolProps.icon, symbolProps.inputText ] }}',
                id: '{{ symbolProps.id }}',
                title: '{{ symbolProps.title }}',
                className: '{{ symbolProps.className }}',
                lang: '{{ symbolProps.lang }}',
                translate: '{{ symbolProps.translate }}',
                draggable: '{{ symbolProps.draggable }}',
                accept: '{{ symbolProps.accept }}',
                disabled: '{{ symbolProps.disabled }}',
                maxFileSize: '{{ symbolProps.maxFileSize }}',
                showFileList: '{{ symbolProps.showFileList }}',
                exceededFileSizeText: '{{ symbolProps.exceededFileSizeText }}',
                wrongFileTypeText: '{{ symbolProps.wrongFileTypeText }}',
            },
        },
    },
    settings: FileDropZoneSymbolSettings,
};
