export const ERROR_SCOPES = {
    appEngine: 'app-engine',
    codeEngine: 'code-engine',
    componentSettingsEngine: 'component-setting-engine',
    schemas: 'schemas',
    migrations: 'migrations',
    utils: 'utils',
    dashboard: 'dashboard',
    uiBuilder: 'ui-builder',
};
