import { incrementName, mustBeCorrectVariableName } from '@builder/utils';

export const generateVariableNameFromString = ({
  value,
  fallbackValue = 'untitled',
  existedValues = [],
}: {
  value: string;
  fallbackValue?: string;
  existedValues?: string[];
}): string => {
  let resultValue = value;
  if (mustBeCorrectVariableName(value)) {
    // TODO: add here logic to really transform an any string into a correct variable name
    // https://8base-dev.atlassian.net/browse/APB-1861
    resultValue = fallbackValue;
  }

  return incrementName({ nameToIncrement: resultValue, dictionary: existedValues, delimiter: '' });
};
