import { gql } from '@apollo/client';

export const DEPLOY_STEP_FRAGMENT = gql`
  fragment DeployStep on AppbuilderDeployStep {
    id
    step
    exitCode
    message
    log
  }
`;

export const DEPLOY_STEPS_LIST_QUERY = gql`
  query DeployStepsList($workspaceId: String!, $filter: AppbuilderDeployStepFilter!) {
    appbuilder {
      deployStepList(workspaceId: $workspaceId, filter: $filter) {
        items {
          ...DeployStep
        }
      }
    }
  }
  ${DEPLOY_STEP_FRAGMENT}
`;
