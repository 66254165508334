import { transformGqlActionName } from '@builder/utils';
import { BaseAppStateAccessor } from './BaseAppStateAccessor';
export class GqlBaseStateAccessor extends BaseAppStateAccessor {
    getGenericGqlAction(gqlGeneratedTypes, type) {
        let genericName;
        if (gqlGeneratedTypes) {
            const queryName = transformGqlActionName(this.stateDSL.name, type);
            if (gqlGeneratedTypes.match(queryName)) {
                genericName = queryName;
            }
        }
        return genericName;
    }
}
