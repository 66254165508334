import { gql } from '@apollo/client';

export const ASSET_FRAGMENT = gql`
  fragment Asset on Asset {
    id
    key
    content
    file {
      id
      fileId
      filename
      downloadUrl
      previewUrl: downloadUrl @resizeImage(resize: { height: 176 })
      meta
    }
  }
`;

export const ASSET_LIST_QUERY = gql`
  query AssetsList($filter: AssetFilter) {
    assetsList(filter: $filter) {
      items {
        ...Asset
      }
    }
  }
  ${ASSET_FRAGMENT}
`;

export const ASSET_CREATE_MUTATION = gql`
  mutation AssetCreate($data: AssetCreateInput!) {
    assetCreate(data: $data) {
      ...Asset
    }
  }
  ${ASSET_FRAGMENT}
`;
