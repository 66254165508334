import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, LANG_OPTIONS, TRANSLATE_OPTIONS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, NS_ID } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
import { getCommonTextSettings } from '../../text-components/Text/Text.settings';
import { getCommonTypographySettings } from '../MaterialTypography/MaterialTypography.settings';
export const BuilderComponentsTooltipSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'title',
                            label: 'Title',
                            keyPath: ['title'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    settings: getCommonTextSettings(),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                    settings: getCommonTypographySettings(),
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['open'],
                            label: 'Open',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableInteractive'],
                            label: 'Disable Interactive',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableFocusListener'],
                            label: 'Disable Focus Listener',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableHoverListener'],
                            label: 'Disable Hover Listener',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableTouchListener'],
                            label: 'Disable Touch Listener',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['enterDelay'],
                            label: 'Enter Delay',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['enterNextDelay'],
                            label: 'Enter Next Delay',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['enterTouchDelay'],
                            label: 'Enter Touch Delay',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['leaveDelay'],
                            label: 'Leave Delay',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['leaveTouchDelay'],
                            label: 'Leave Touch Delay',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Common',
                    name: 'Common',
                    expandedByDefault: false,
                    titleDescription: {
                        title: 'Common',
                        subtitle: 'Common properties for all components',
                    },
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['id'],
                            label: 'Id',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['className'],
                            label: 'Class',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['lang'],
                            label: 'Language',
                            options: LANG_OPTIONS.map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['translate'],
                            label: 'Translate',
                            options: TRANSLATE_OPTIONS.map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['draggable'],
                            label: 'Draggable',
                        },
                        getDivider(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'customPropsSection',
                            title: 'Custom Properties',
                            children: [
                                {
                                    type: COMPONENT_SETTING_TYPES.list,
                                    keyPath: ['customProps'],
                                    name: 'customProps',
                                    label: 'Custom Properties',
                                    itemLabelKeyPath: ['Key'],
                                    newObjectPredefineds: {
                                        Key: 'propname',
                                        Value: 'value',
                                    },
                                    settings: [
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['Key'],
                                            label: 'Key',
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['Value'],
                                            label: 'Value',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['placement'],
                            options: [
                                'bottom-end',
                                'bottom-start',
                                'bottom',
                                'left-end',
                                'left-start',
                                'left',
                                'right-end',
                                'right-start',
                                'right',
                                'top-end',
                                'top-start',
                                'top',
                            ].map(createSelectOptionByValue),
                            label: 'Placement',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['arrow'],
                            label: 'Arrow',
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onOpenAction',
                            label: 'On Open',
                            keyPath: ['onOpen'],
                            children: getCommonActionSettings(['onOpen']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'on Close',
                            keyPath: ['onClose'],
                            children: getCommonActionSettings(['onClose']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
