import { AppDSL, CSS, themeSelectors } from '@builder/schemas';
import { CSSParser, ROOT_SELECTORS_ARRAY } from '@builder/utils';

import { updateFrameworkSettingsColorVariables } from './themeCSSGlobalSelectorsUpdate';

export const themeCSSGlobalUpdate = ({
  appDSL,
  cssBody,
}: {
  appDSL: AppDSL;
  cssBody: CSS;
}): AppDSL => {
  const { theme } = appDSL;
  const globalCSS = themeSelectors.getGlobalCSS(theme);
  const rootCSS = CSSParser.getCSSBodyWithOneRule(globalCSS, ROOT_SELECTORS_ARRAY);

  const newFrameworkSettings = updateFrameworkSettingsColorVariables({
    frameworkSettings: theme?.frameworkSettings,
    cssBody: rootCSS,
  });

  return {
    ...appDSL,

    theme: {
      ...theme,
      frameworkSettings: { ...newFrameworkSettings },
      css: {
        ...theme?.css,
        global: cssBody,
      },
    },
  };
};
