import { useEffect, useRef, useState } from 'react';

import { CodeEngine } from '@builder/code-engine';
import { AppConfiguration, assetListSelectors } from '@builder/schemas';
import { processEnv } from '@builder/utils';

import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { useComponentListDSL, useDialogState } from 'src/providers';
import { Spinner } from 'src/shared/components';
import { MESSAGES } from 'src/shared/constants';
import { useAssetsBackendHooks } from 'src/shared/graphql/hooks';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

import ErrorPage from './ErrorPage';

export const Preview = ({
  appConfiguration,
}: {
  appConfiguration: AppConfiguration;
}): JSX.Element => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [previewError, setPreviewError] = useState({});
  const [codeHasError, setCodeHasError] = useState(false);
  const frame = useRef<HTMLIFrameElement | null>(null);
  const workspaceID = useCurrentWorkspaceID();
  const bundlerServer = processEnv.getCodeBundlerServerURL();
  const componentListDSL = useComponentListDSL();
  const assetListDSL = appConfiguration?.appDSL.assets || {};
  const { assetBackendList, assetListOptions } = useAssetsBackendHooks();
  const {
    preview: {
      loading: { title: LoadingTitle, description: LoadingDescription },
    },
  } = MESSAGES;
  const { openDialog } = useDialogState<{
    path: string;
    message: string;
  }>();
  const assetFileWithBackendDataListDSL = assetListSelectors.getAssetFileWithBackendDataList(
    assetListDSL,
    {
      assetBackendList,
    },
  );

  /**
   * Extracts the clean preview URL from an input URL.
   * @param inputURL - The input URL.
   * @returns The portion of the input URL that starts from the beginning and ends at the index
   * of '/preview' plus the length of '/preview', or undefined if '/preview' is not found.
   */
  function extractCleanPreviewURL(inputURL: string): string | undefined {
    const previewIndex = inputURL.indexOf('/preview');

    if (previewIndex === -1) {
      return '';
    }

    return inputURL.substring(0, previewIndex + '/preview'.length);
  }

  /**
   * Extracts the relative path to navigate based on the current URL.
   * @returns The relative path or '/' if '/preview/' is not found in the URL.
   */
  function extractRelativePathToNavigate() {
    const { href } = window.location;
    const previewText = '/preview/';
    const previewIndex = href.indexOf(previewText);

    if (previewIndex === -1) {
      return '';
    }

    return `${href.slice(previewIndex + previewText.length)}`;
  }

  useEffect(() => {
    /**
     * Handles iframe messages and updates the browser's history based on the received navigation data.
     * @param {MessageEvent} event - Represents an event that occurs when a message is received from an iframe.
     */
    const handleIframeMessage = (event: MessageEvent) => {
      // Check if the event data type is 'navigation'

      if (event.data.type === 'error') {
        setCodeHasError(true);
        setIsIframeLoaded(true);
        setPreviewError(event.data);
      }

      if (event.data.type === 'navigation') {
        const cleanPreviewURL = extractCleanPreviewURL(window.location.href);
        const newPathname = event.data.location.pathname;
        const newURL = `${cleanPreviewURL}${newPathname}${event.data.location.search || ''}${
          event.data.location.hash || ''
        }`;

        window.history.pushState({}, '', newURL);
      }
    };

    if (appConfiguration && !assetListOptions.loading) {
      try {
        // There is logic to re-fetch the app link if the response has the "refetch" value
        // However, it is necessary to avoid an infinite loop.
        const MAX_RETRIES = 3;
        let retryCount = 0;
        const appFiles = CodeEngine.generateApp({
          appDSL: appConfiguration.appDSL,
          componentListDSL,
          assetBackendList,
          assetListDSL: assetFileWithBackendDataListDSL,
          useAssetDirectImports: false,
          isPreview: true,
        });

        Object.values(appFiles).forEach(file => {
          if (file.content.includes('error_formating_code')) {
            setCodeHasError(true);
            return openDialog(DASHBOARD_DIALOGS.CODE_ENGINE_ERROR_DIALOG_ID, {
              path: file.toString(),
              message: file.content.split('error_formating_code:')[1],
            });
          }
        });

        /* This code block is an immediately invoked async function expression (IIFE) that is
          responsible for fetching the generated code from the bundler server and loading it into an
          iframe. */
        (async () => {
          // Fetch the generated code from the bundler server
          const fetchAppLink = async () => {
            retryCount += 1;
            const response = await fetch(`${bundlerServer}/generate`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'cross-domain': 'true',
                'Access-Control-Allow-Origin': '*',
              },
              body: JSON.stringify({
                files: { ...appFiles },
                workspaceID: workspaceID.workspaceID,
              }),
            });

            // Parse the response JSON
            const result = await response.json();
            if (result.refetch && retryCount <= MAX_RETRIES) {
              fetchAppLink();
            }

            if (result.error) {
              setCodeHasError(true);
              setIsIframeLoaded(true);
              setPreviewError(result.error);
              return;
            }

            // Concatenate the link and relative path to navigate
            const newSrc = `${result.link}${extractRelativePathToNavigate()}`;
            // Set the source of the iframe
            if (frame.current) {
              frame.current.src = newSrc;
              frame.current.addEventListener('load', () => {
                setIsIframeLoaded(true);
              });
            }

            // Listen for iframe messages
            window.addEventListener('message', handleIframeMessage);
          };

          fetchAppLink();
        })();
      } catch (e) {
        console.error(e);
      }

      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
    }
  }, [
    appConfiguration,
    assetBackendList,
    assetFileWithBackendDataListDSL,
    assetListOptions.loading,
    bundlerServer,
    codeHasError,
    componentListDSL,
    frame,
    openDialog,
    workspaceID.workspaceID,
  ]);

  return (
    <div style={{ height: '100vh' }}>
      {!isIframeLoaded && <Spinner title={LoadingTitle} description={LoadingDescription} animate />}
      {codeHasError && <ErrorPage error={previewError} />}
      <iframe style={{ width: '100%', height: '100vh' }} title="preview" ref={frame} />
    </div>
  );
};
