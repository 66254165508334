import { AppEvents, DashboardState, DASHBOARD_EVENTS } from '../../../common';

const initialState = {
  past: [],
  future: [],
};

const HISTORY_LIMIT = 50;

export const dashboardUndoRedoReducer = (
  state: DashboardState['history'] = initialState,
  event: AppEvents,
): DashboardState['history'] => {
  const newPast = [...state.past];
  const pastToPresent = newPast.pop();

  switch (event.type) {
    case DASHBOARD_EVENTS.dashboardUndo:
      if (!pastToPresent) {
        return state;
      }

      if (event.completely) {
        return {
          ...state,
          past: newPast,
          future: [],
        };
      }

      return {
        ...state,
        past: newPast,
        future: [pastToPresent, ...state.future],
      };

    case DASHBOARD_EVENTS.dashboardRedo: {
      const newFuture = [...state.future];
      const futureToPresent = newFuture.shift();
      if (!futureToPresent) {
        return state;
      }

      return {
        ...state,
        past: [...state.past, futureToPresent],
        future: newFuture,
      };
    }

    case DASHBOARD_EVENTS.dashboardUpdateHistory: {
      return {
        ...state,
        future: [],
        past:
          state.past.length < HISTORY_LIMIT
            ? // just adding
              [...state.past, event.payload]
            : // dropping one to fit one
              [...state.past.slice(1), event.payload],
      };
    }

    case DASHBOARD_EVENTS.dashboardSetInitialHistory: {
      return {
        past: event.payload.past || [],
        future: event.payload.future || [],
      };
    }

    default:
      return state;
  }
};
