/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_18_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.2';
    }
    getChangeList() {
        return [`Change global scope to local scope in components with bad scope asignation`];
    }
    migrateAppDSL(appDSL) {
        var _a, _b;
        const newAppDSL = Object.assign({}, appDSL);
        const routes = (_b = (_a = newAppDSL.nodes.EmptyPageRouterSwitch_ID) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.routes;
        const pagesID = routes === null || routes === void 0 ? void 0 : routes.nodes;
        if (pagesID !== undefined) {
            pagesID.forEach((pageID) => {
                const statesPagesContext = newAppDSL.nodes[pageID];
                if (statesPagesContext.context !== undefined) {
                    Object.keys(statesPagesContext.context).forEach(key => {
                        if (statesPagesContext.context !== undefined) {
                            const state = statesPagesContext.context[key];
                            if (state.scope === 'global') {
                                state.scope = 'local';
                                newAppDSL.states = Object.assign({}, omit([key], newAppDSL.states));
                            }
                        }
                    });
                }
            });
        }
        return Object.assign({}, newAppDSL);
    }
}
export const migration_0_18_2 = new Migration_0_18_2();
