import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
export const RootLayoutsContainer = {
    name: COMPONENT_DSL_NAMES.RootLayoutsContainer,
    displayName: 'Root Layout Container',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.rootLayoutContainer,
    memoization: {
        disable: true,
    },
    schema: {
        dndTargetPropName: 'layouts',
        interaction: COMPONENT_DSL_INTERACTION_TYPES.none,
        props: {
            layouts: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
            redirects: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
        },
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.div,
            props: {
                style: {
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.RouterSwitch,
                            props: {
                                children: '{{ [symbolProps.layouts, symbolProps.redirects] }}',
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: null,
};
