import { has, omit, uniq, values } from 'ramda';
import { stateSelectors, appDSLSelectors, } from '@builder/schemas';
import { isEmptyString } from '@builder/utils';
import { RESOURCE_ID, REST } from '../../../providers-generators';
import { PredefinedStateListUsageGenerator } from '../../predefined-state';
import { AppStateAccessorFactory } from '../AppStateAccessor';
const QUERY_AND_FUNCTION = ['query', 'function'];
export class HookGenerator {
    constructor({ appDSL, componentListDSL, appStateDSL, assetListDSL, appCurrentContextStatesDSL, }) {
        const authResourceDSL = appDSLSelectors.getAuthResourceDSL(appDSL);
        const currentContextStates = () => {
            if (appStateDSL.parent) {
                return appDSL.nodes[appStateDSL.parent].context;
            }
            return {};
        };
        const globalStates = Object.values(appDSL.states).reduce((accState, currentState) => {
            if (currentState.scope === 'local') {
                return accState;
            }
            return Object.assign(Object.assign({}, accState), { [currentState.id]: currentState });
        }, {});
        const layoutStates = Object.values(appDSL.nodes)
            .filter(node => {
            const { props } = node;
            return ((props === null || props === void 0 ? void 0 : props.path) && typeof props.path === 'string' && props.path.startsWith('/__layouts/'));
        })
            .reduce((acc, node) => {
            return Object.assign(Object.assign({}, acc), node.context);
        }, {});
        this.appStatesDSL =
            appStateDSL.scope === 'local'
                ? Object.assign(Object.assign(Object.assign({}, currentContextStates()), globalStates), layoutStates) : globalStates;
        const appStateAccessorFactory = new AppStateAccessorFactory({
            stateListDSL: this.appStatesDSL,
            resourceListDSL: appDSL.resources,
            authResourceDSL,
            assetListDSL,
        });
        this.appDSL = appDSL;
        this.appStateDSL = appStateDSL;
        this.appResourcesDSL = appDSL.resources;
        this.stateAccessor = appStateAccessorFactory.createAppStateAccessor(appStateDSL);
        this.predefinedStateListUsageGenerator = new PredefinedStateListUsageGenerator(componentListDSL);
    }
    isLocal() {
        return stateSelectors.isLocal(this.appStateDSL);
    }
    isGlobal() {
        return stateSelectors.isGlobal(this.appStateDSL);
    }
    /**
     * @returns Hook call based on app state.
     *
     * @example
     * () => 'const userDialog = useUserDialog(false);'
     */
    isStateUsedInString(text, stateName) {
        return (text.includes(`${stateName}.`) ||
            text.includes(`${stateName}[`) ||
            text.includes(`${stateName}(`) ||
            text.includes(`${stateName}?`));
    }
    get getHeadersNames() {
        var _a, _b, _c;
        const isRequest = has('resourceListDSL');
        if (isRequest(this.stateAccessor)) {
            const resource = this.stateAccessor.resourceListDSL[this.stateAccessor.stateDSL.resourceID];
            const resourceHeaders = resource.type === REST
                ? (_a = resource === null || resource === void 0 ? void 0 : resource.default) === null || _a === void 0 ? void 0 : _a.headers
                : (_c = (_b = resource === null || resource === void 0 ? void 0 : resource.default) === null || _b === void 0 ? void 0 : _b.context) === null || _c === void 0 ? void 0 : _c.headers;
            const resourceHeadersNames = [];
            values(resourceHeaders).forEach(resourceHeader => {
                values(this.appStatesDSL).forEach(({ name: stateName }) => {
                    if (this.isStateUsedInString(JSON.stringify(resourceHeader), stateName)) {
                        resourceHeadersNames.push(stateName);
                    }
                });
            });
            return resourceHeadersNames;
        }
        return [];
    }
    includesStateUsedInResource(hookProps) {
        const handledHookProps = hookProps;
        if (RESOURCE_ID in this.appStateDSL) {
            const resource = this.appResourcesDSL[this.appStateDSL.resourceID];
            values(this.appStatesDSL).forEach(state => {
                if (this.isStateUsedInString(JSON.stringify(resource), state.name)) {
                    handledHookProps.push(state.name);
                }
            });
        }
        return uniq(handledHookProps);
    }
    getHookCall() {
        const hookArguments = this.stateAccessor.getHookCallArgumentsString();
        const hookName = this.stateAccessor.getHookDeclarationName(this.appDSL);
        const stateName = this.stateAccessor.getStateName();
        const headersNames = this.getHeadersNames;
        let hookArgumentsArray = [];
        let hookProps = [];
        if (QUERY_AND_FUNCTION.includes(this.appStateDSL.type)) {
            hookArgumentsArray = isEmptyString(hookArguments) ? [] : hookArguments.split(',');
            hookProps = this.includesStateUsedInResource(uniq([...hookArgumentsArray, ...headersNames]));
        }
        const hasBody = has('body');
        if (hasBody(this.appStateDSL.args)) {
            const innerStateWithoutName = omit(['name'], this.appStateDSL);
            return `const ${stateName} = ${hookName}(${`${hookProps
                .filter(state => this.isStateUsedInString(JSON.stringify(innerStateWithoutName), state) ||
                (RESOURCE_ID in this.appStateDSL &&
                    JSON.stringify(this.appResourcesDSL[this.appStateDSL.resourceID]).includes(state)))
                .join(', ')}`});`;
        }
        return `const ${stateName} = ${hookName}(${`${QUERY_AND_FUNCTION.includes(this.appStateDSL.type) ? hookProps.join(', ') : hookArguments}`});`;
    }
    /**
     *
     * Is used to generating hook declaration string to use it inside the react component.
     *
     * @returns Hook declaration based on app state.
     *
     * @example
     * ```
     * () => `const useUserDialog = (defaultState) => {
     *   const [isOpened, setIsOpened] = useState(defaultState);
     *
     *   ....
     *
     *   return { isOpened, ... };
     * };`
     * ```
     */
    getHookDeclaration() {
        const hookConstants = this.stateAccessor.getHookAssociatedConstantsString();
        const hookName = this.stateAccessor.getHookDeclarationName(this.appDSL);
        const hookArguments = this.stateAccessor.getHookDeclarationArgumentsString();
        const hookCode = this.stateAccessor.getHookDeclarationBodyString();
        return `
    ${hookConstants}

    export const ${hookName} = (${hookArguments}) => {
      ${this.predefinedStateListUsageGenerator.generatePredefinedHooksCallsForHook(hookCode)};
      ${hookCode}
    };`;
    }
    generateHookFile() {
        const hookConstants = this.stateAccessor.getHookAssociatedConstantsString();
        const hookName = this.stateAccessor.getHookDeclarationName(this.appDSL);
        const hookArguments = this.stateAccessor.getHookDeclarationArgumentsString();
        const hookCode = this.stateAccessor.getHookDeclarationBodyString();
        const headersNames = this.getHeadersNames;
        const hookArgumentsArray = isEmptyString(hookArguments) ? [] : hookArguments.split(',');
        const hookProps = this.includesStateUsedInResource(uniq([...hookArgumentsArray, ...headersNames]));
        return `
      ${hookConstants}

      export const ${hookName} = (${`${hookProps.join(', ')}`}) => {
        ${this.predefinedStateListUsageGenerator.generatePredefinedHooksCallsForHook(hookCode)};
        ${hookCode}
      };`;
    }
    getImportData() {
        const hookName = this.stateAccessor.getHookDeclarationName(this.appDSL);
        return {
            importName: 'shared/hooks',
            varName: hookName,
        };
    }
}
