import React, { useCallback, useMemo } from 'react';

import {
  FormatListBulleted as FormatListBulleteIcon,
  GridView as GridViewIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { isEmpty } from 'ramda';

import { AssetFileWithTemporaryStatesDSL } from '../../common/types';
import { AssetAddButton, UploadcareFilePicker } from '../UploadcareFilePicker';
import { AssetDeleteDialogArgs } from 'src/dialogs';
import { LeftPanelSectionTitle } from 'src/features/common/components';
import { useAppDispatch, useUIBuilderAssetViewMode } from 'src/providers';
import { CssGrid } from 'src/shared/components';
import { MESSAGES } from 'src/shared/constants';
import { CreateAssetData } from 'src/shared/graphql/hooks/useAssetsHooks';
import { ASSET_VIEW_MODES, UI_BUILDER_EVENTS } from 'src/store';

import { AssetsHeaderContainer, StyledViewButton } from './AssetsHeader.styles';

type AssetsHeaderProps = {
  assetFileNames: string[];
  assetList: AssetFileWithTemporaryStatesDSL[];
  createAssetLoading: boolean;
  createFileAsset: (assetCreateInput: CreateAssetData) => Promise<unknown>;
  deleteAsset: (args: AssetDeleteDialogArgs) => void;
};

export const AssetsHeader: React.FC<AssetsHeaderProps> = ({
  assetFileNames,
  assetList,
  createAssetLoading,
  createFileAsset,
  deleteAsset,
}) => {
  const send = useAppDispatch();
  const assetViewMode = useUIBuilderAssetViewMode();

  const toggleAssetViewMode = useCallback(() => {
    send({ type: UI_BUILDER_EVENTS.toggleAssetViewMode });
  }, [send]);

  const selectedAssets = useMemo(() => assetList.filter(asset => asset.isSelected), [assetList]);

  const onAssetDelete = useCallback(() => {
    deleteAsset({ assetIDs: selectedAssets.map(asset => asset.id) });
  }, [deleteAsset, selectedAssets]);

  return (
    <AssetsHeaderContainer>
      <LeftPanelSectionTitle
        title={MESSAGES.assetsManager.leftPanel.title}
        titleDescription={{
          title: MESSAGES.assetsManager.leftPanel.titleDescription,
          subtitle: MESSAGES.assetsManager.leftPanel.subtitleDescription,
        }}
        rightPart={
          <CssGrid
            gridTemplateColumns="auto auto auto auto"
            gridColumnGap={1}
            height="32px"
            alignItems="center"
          >
            <UploadcareFilePicker
              assetFileNames={assetFileNames}
              onFileUpload={createFileAsset}
              mutationLoading={createAssetLoading}
              AddButtonComponent={AssetAddButton}
              data-test="assets.header.addAssetButton"
            />
            <Choose>
              <When condition={assetViewMode === ASSET_VIEW_MODES.grid}>
                <Tooltip disableInteractive arrow title="Switch to list view">
                  <StyledViewButton
                    data-test="assets.header.toggleAssetViewModeButton"
                    onClick={toggleAssetViewMode}
                    startIcon={<FormatListBulleteIcon />}
                    color="default"
                    variant="contained"
                  />
                </Tooltip>
              </When>
              <When condition={assetViewMode === ASSET_VIEW_MODES.list}>
                <Tooltip disableInteractive arrow title="Switch to grid view">
                  <StyledViewButton
                    data-test="assets.header.toggleAssetViewModeButton"
                    onClick={toggleAssetViewMode}
                    startIcon={<GridViewIcon />}
                    color="default"
                    variant="contained"
                  />
                </Tooltip>
              </When>
            </Choose>
            <If condition={!isEmpty(selectedAssets)}>
              <StyledViewButton
                data-test="assets.header.deleteAssetButton"
                onClick={onAssetDelete}
                startIcon={<DeleteIcon />}
                color="error"
                variant="outlined"
              />
            </If>
          </CssGrid>
        }
      />
    </AssetsHeaderContainer>
  );
};
