import { values } from 'ramda';

import { StateDSL, StateListDSL } from '@builder/schemas';

import { OBJECT_OBJECT } from './constants';

export const filterStatesByType = (states: StateListDSL, type: string): StateListDSL =>
  (values(states).filter(
    (state: StateDSL) => (state as StateDSL).type === type,
  ) as unknown) as StateListDSL;

export const isObject = (value: unknown): boolean =>
  Object.prototype.toString.call(value) === OBJECT_OBJECT;

/**
 * The next RegEx validates if the content of a string is a number
 * @example /^[0-9]+$/.test('test123'); // false
 * @example /^[0-9]+$/.test('123'); // true
 * */
export const isNumber = (text: string): boolean => {
  return /^[0-9]+$/.test(text);
};
