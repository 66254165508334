import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialAlertTitleSettings } from './MaterialAlertTitle.settings';
export const MaterialAlertTitle = {
    name: COMPONENT_DSL_NAMES.MaterialAlertTitle,
    displayName: 'Alert Title',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'AlertTitle',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyRemovable,
        presentations: [visibleByCondition],
        props: commonComponentProps,
        predefineds: {
            props: {
                closeText: 'Close',
                role: 'alert',
                severity: 'success',
                variant: 'standard',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Title',
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: MaterialAlertTitleSettings,
};
