import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const AddMetaTagButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: ${({ theme }) => theme.spacing(1.75)};
  justify-self: flex-start;
`;

export const DeleteMetaTagButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grey[500]};
  background-color: ${({ theme }) => theme.palette.grey[700]};

  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.grey[700]};
    filter: brightness(1.2);
  }

  min-width: ${({ theme }) => theme.spacing(4)};
`;
