const FLOAT_PATTERN = '-?\\d*(?:\\.\\d+)';
export const NUMBER_PATTERN = `(${FLOAT_PATTERN}?)`;
export const PERCENTAGE_PATTERN = `(${FLOAT_PATTERN}?%)`;
export const NUMBER_OR_PERCENTAGE_PATTERN = `(${FLOAT_PATTERN}?%?)`;
const HEX_PATTERN = /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/;
export const HEX_REGEXP = new RegExp(HEX_PATTERN, 'i');
const HSL3_PATTERN = `^
  hsla?\\(
    \\s*(-?\\d*(?:\\.\\d+)?(?:deg|rad|turn)?)\\s*,
    \\s*${PERCENTAGE_PATTERN}\\s*,
    \\s*${PERCENTAGE_PATTERN}\\s*
    (?:,\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
  \\)
  $
`.replace(/\n|\s/g, '');
export const HSL3_REGEXP = new RegExp(HSL3_PATTERN);
const HSL4_PATTERN = `^
  hsla?\\(
    \\s*(-?\\d*(?:\\.\\d+)?(?:deg|rad|turn)?)\\s*
    \\s+${PERCENTAGE_PATTERN}
    \\s+${PERCENTAGE_PATTERN}
    \\s*(?:\\s*\\/\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
  \\)
  $
`.replace(/\n|\s/g, '');
export const HSL4_REGEXP = RegExp(HSL4_PATTERN);
const RGB3_NUMBER_PATTERN = `^
  rgba?\\(
    \\s*${NUMBER_PATTERN}\\s*,
    \\s*${NUMBER_PATTERN}\\s*,
    \\s*${NUMBER_PATTERN}\\s*
    (?:,\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
  \\)
  $
`.replace(/\n|\s/g, '');
export const RGB3_NUMBER_REGEXP = RegExp(RGB3_NUMBER_PATTERN);
const RGB3_PERCENTAGE_PATTERN = `^
  rgba?\\(
    \\s*${PERCENTAGE_PATTERN}\\s*,
    \\s*${PERCENTAGE_PATTERN}\\s*,
    \\s*${PERCENTAGE_PATTERN}\\s*
    (?:,\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
  \\)
  $
`.replace(/\n|\s/g, '');
export const RGB3_PERCENTAGE_REGEXP = RegExp(RGB3_PERCENTAGE_PATTERN);
const RGB4_NUMBER_PATTERN = `^
  rgba?\\(
    \\s*${NUMBER_PATTERN}
    \\s+${NUMBER_PATTERN}
    \\s+${NUMBER_PATTERN}
    \\s*(?:\\s*\\/\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
  \\)
$
`.replace(/\n|\s/g, '');
export const RGB4_NUMBER_REGEXP = new RegExp(RGB4_NUMBER_PATTERN);
const RGB4_PERCENTAGE_PATTERN = `^
  rgba?\\(
    \\s*${PERCENTAGE_PATTERN}
    \\s+${PERCENTAGE_PATTERN}
    \\s+${PERCENTAGE_PATTERN}
    \\s*(?:\\s*\\/\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
  \\)
$
`.replace(/\n|\s/g, '');
export const RGB4_PERCENTAGE_REGEXP = RegExp(RGB4_PERCENTAGE_PATTERN);
const TRANSPARENT_PATTERN = /^transparent$/;
export const TRANSPARENT_REGEXP = new RegExp(TRANSPARENT_PATTERN, 'i');
const LAB_PATTERN = `^
lab\\(
  \\s*${PERCENTAGE_PATTERN}
  \\s+${NUMBER_PATTERN}
  \\s+${NUMBER_PATTERN}
  \\s*(?:\\s*\\/\\s*${NUMBER_OR_PERCENTAGE_PATTERN}\\s*)?
\\)
$
`.replace(/\n|\s/g, '');
export const LAB_REGEXP = new RegExp(LAB_PATTERN, 'i');
