import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { pickSettings, NS_ID, BASIC_PROPS_TOOLTIP, getBasicPropsSettings } from '../../common';
import { getDivider } from '../../common/settings';
export const TextSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'text',
                            keyPath: ['children'],
                            label: 'Text',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
            ],
        },
    ],
    style: [],
    events: [],
};
export const getCommonTextSettings = () => pipe(pickSettings([[NS_ID.properties.root, NS_ID.properties.basic, 'text']], {
    withStructure: false,
}))(values(TextSettings).flat());
