import styled from '@emotion/styled';
import { Button } from '@mui/material';

import { IconWrapperSpan } from 'src/shared/components';

export const ACTIVEPROP = {
  literal: 'literal',
  page: 'page',
  code: 'code',
  state: 'state',
};

export type FxButtonProps = {
  onClick: () => void;
  activeProp: string;
  'data-test'?: string;
  label?: string;
  disabled?: boolean;
  skipCode?: boolean;
};

const BLACK_LIST_PROPS = ['activeProp'];
const shouldForwardProp = (prop: string): boolean => !BLACK_LIST_PROPS.includes(prop);

export const StyledButton = styled(Button, { shouldForwardProp })<{ activeProp: string }>`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 0;
  background-color: #45505a;
  &:hover {
    ${IconWrapperSpan} * {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
