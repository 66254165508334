/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { path } from 'ramda';
import { MOBILE_LAYOUT_NODE_ID, ROOT_LAYOUT_CONTAINER_NODE_ID } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_16_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.2';
    }
    getChangeList() {
        return ['relation mobile layout when is used and hidden authenticated layout when is not used'];
    }
    // protected migrateNodeDSL(nodeDSL: NodeDSL): NodeDSL {
    //   if (nodeDSL.name === COMPONENT_DSL_NAMES.img) {
    //     return {
    //       ...nodeDSL,
    //       name: COMPONENT_DSL_NAMES.BuilderComponentsImage,
    //     };
    //   }
    //   return nodeDSL;
    // }
    // protected migrateSettingDSL(setting: ComponentSettingDSL): ComponentSettingDSL {
    //   if (SettingChecker.Schema.isSettingTypeOfProp(setting) && setting.resetPathBeforeOnChange) {
    //     return {
    //       ...setting,
    //       // typings changed for resetPathBeforeOnChange from [] to [][]
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-ignore
    //       resetPathBeforeOnChange: [setting.resetPathBeforeOnChange],
    //     };
    //   }
    //   return setting;
    // }
    migrateAppDSL(appDSL) {
        var _a, _b;
        const layouts = (_a = appDSL.nodes[ROOT_LAYOUT_CONTAINER_NODE_ID]) === null || _a === void 0 ? void 0 : _a.props.layouts;
        if (path([MOBILE_LAYOUT_NODE_ID], appDSL.nodes)) {
            layouts.nodes.push(MOBILE_LAYOUT_NODE_ID);
        }
        if (typeof appDSL.nodes.jaQ_WMBs !== 'undefined') {
            if (path(['jaQ_WMBs'], appDSL.nodes)) {
                const viewComponentChildren = (_b = appDSL.nodes.jaQ_WMBs) === null || _b === void 0 ? void 0 : _b.props.routes;
                if (viewComponentChildren.nodes.length < 1) {
                    layouts.nodes = layouts.nodes.filter(item => item !== 'P-keoa0X');
                }
            }
        }
        return appDSL;
    }
}
export const migration_0_16_2 = new Migration_0_16_2();
