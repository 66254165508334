import React from 'react';

import styled from '@emotion/styled';
import { FormHelperText, FormHelperTextProps } from '@mui/material';

const StyledHelperText = styled(FormHelperText)`
  font-weight: normal;
  position: relative;
  padding-top: 4px;
  font-size: ${({ theme }) => theme.typography.caption.fontSize}px;
  line-height: unset;
`;

export const HelperText: React.FC<FormHelperTextProps & { helperText: React.ReactNode }> = ({
  helperText,
  ...rest
}) => {
  return <StyledHelperText {...rest}>{helperText}</StyledHelperText>;
};
