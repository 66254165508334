import React from 'react';

import styled from '@emotion/styled';
import { Close as CloseIcon } from '@mui/icons-material';

const CloseButtonWrapper = styled.div`
  position: absolute;

  top: 15px;
  right: 15px;
`;

const StyledButton = styled.button`
  padding: 0;

  width: 16px;
  height: 16px;

  border: none;
  outline: none;
  cursor: pointer;

  background-color: transparent;

  color: ${({ theme }) => `${theme.palette.grey[400]}`};

  :hover {
    color: ${({ theme }) => `${theme.palette.grey[200]}`};
  }

  :active {
    color: ${({ theme }) => `${theme.palette.grey[600]}`};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 100%;
  height: 100%;
`;

type CloseButtonProps = {
  onClick: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <CloseButtonWrapper className="no-drag">
      <StyledButton onClick={onClick}>
        <StyledCloseIcon />
      </StyledButton>
    </CloseButtonWrapper>
  );
};
