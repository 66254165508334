import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getBasicPropsSettings, getCommonPropertiesSections, getDivider, getStylesSection, } from '../../common';
const getPropertiesSettings = () => [
    getDivider(),
    ...getBasicPropsSettings(),
    getDivider(),
    ...getCommonPropertiesSections(),
];
export const MaterialDialogContentSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
};
