import { values, isEmpty, uniq } from 'ramda';
import { appNodesSchemaSelectors, appSettingsSelectors, nodeListSelectors, } from '@builder/schemas';
import { CodeNodeGenerator } from '../app-nodes-generator';
import { LocalStateContainerListGenerator, PredefinedStateListUsageGenerator, } from '../app-states-generator';
import { CSSConstantListGenerator } from '../css-constant-generator';
import { ImportsGenerator } from '../imports-generator';
import { GlobalStateProviderGenerator } from '../providers-generators';
import { ComponentFileAccessor } from './ComponentFileAccessor';
/**
 * Generates file for target node
 * Includes all nodes between target node and first met node with codeFileStructure setting
 */
export class ComponentFileGenerator {
    constructor({ appDSL, componentListDSL, assetBackendList, assetListDSL, nodeID, }) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
        const shouldMinSizeBeAppliedInCodeEngine = appSettingsSelectors.shouldMinSizeBeAppliedInCodeEngine(this.appDSL.settings);
        this.nodeDSL = nodeListSelectors.getNodeDSL(this.appDSL.nodes, { nodeID });
        this.componentFileAccessor = new ComponentFileAccessor({ appDSL, componentListDSL, nodeID });
        this.nodeGenerator = new CodeNodeGenerator({
            appDSL,
            componentListDSL,
            assetListDSL,
            options: {
                rootNodeID: nodeID,
                applyMinSizeStyles: shouldMinSizeBeAppliedInCodeEngine,
                stateListDSL: appDSL.states,
            },
        });
        this.importsGenerator = new ImportsGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        this.predefinedStateListUsageGenerator = new PredefinedStateListUsageGenerator(componentListDSL);
        this.localStateContainerListGenerator = new LocalStateContainerListGenerator({
            appDSL,
            componentListDSL,
            assetListDSL,
        });
        this.globalStateProviderGenerator = new GlobalStateProviderGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        this.cssConstantListGenerator = new CSSConstantListGenerator(appDSL.nodes, componentListDSL);
    }
    generateImports() {
        const currentFileNodes = appNodesSchemaSelectors.getCodeFileNodeList({
            nodeListDSL: this.appDSL.nodes,
            componentListDSL: this.componentListDSL,
        }, {
            nodeID: this.nodeDSL.id,
        });
        const allChildrenCodeFileNodes = appNodesSchemaSelectors.getChildrenCodeFileNodeList({
            nodeListDSL: this.appDSL.nodes,
            componentListDSL: this.componentListDSL,
        }, {
            nodeID: this.nodeDSL.id,
        });
        const allUsedComponentNames = values(currentFileNodes).map(({ name }) => name);
        const imports = [
            ...this.importsGenerator.predefinedStateImports.getExtractedComponentImportDataList(currentFileNodes),
            ...this.importsGenerator.componentsImports.getImportDataList(Object.assign(Object.assign({}, currentFileNodes), allChildrenCodeFileNodes), this.nodeDSL.id),
            ...this.importsGenerator.providersImports.getProviderHooksImportDataList(currentFileNodes),
            ...this.importsGenerator.hookImports.getLocalImportDataList(currentFileNodes),
            ...this.importsGenerator.symbolsImports.getImportDataList(currentFileNodes),
            ...this.importsGenerator.getDSLImports(allUsedComponentNames),
        ];
        return this.importsGenerator.generateImports(imports);
    }
    get getStatesFilteredByWrapperNode() {
        return values(this.appDSL.states).filter(state => {
            if (state.scope === CodeNodeGenerator.LOCAL) {
                return state.parent === this.nodeDSL.parentID;
            }
            return undefined;
        });
    }
    generateComponentFile() {
        const currentFileNodes = appNodesSchemaSelectors.getCodeFileNodeList({
            nodeListDSL: this.appDSL.nodes,
            componentListDSL: this.componentListDSL,
        }, {
            nodeID: this.nodeDSL.id,
        });
        const predefinedStatesUsed = this.predefinedStateListUsageGenerator.generatePredefinedHooksCallsForComponent(currentFileNodes);
        const globalStates = this.globalStateProviderGenerator.generateGlobalStateHook(currentFileNodes);
        const stateNames = this.getStatesFilteredByWrapperNode.map(state => state.name);
        const layoutStates = CodeNodeGenerator.getParentLayoutStates(this.nodeDSL.parentID, this.appDSL);
        const allStates = isEmpty(layoutStates) ? stateNames : [...stateNames, ...layoutStates];
        const imports = this.generateImports();
        const localStatesContainersDefinition = this.localStateContainerListGenerator.generateStateContainers(currentFileNodes);
        const hasLocalStates = localStatesContainersDefinition.length !== 0;
        const isUseLocationDefined = this.generateImports().includes('useLocation');
        const declareLocation = !isUseLocationDefined && hasLocalStates;
        const insertTextOrEmptyString = (validation, text) => {
            return validation ? `${text}` : '';
        };
        return `
      /** @jsxRuntime classic */
      /** @jsx jsx */
      ${imports.includes('useGlobalState')
            ? imports
            : `${imports}\nimport { useGlobalState } from 'providers';`}
      ${insertTextOrEmptyString(declareLocation, "import { useLocation } from 'react-router-dom';")}
      ${localStatesContainersDefinition}
      
      export const ${this.componentFileAccessor.getComponentName()}: React.FC = ({ props }) => {
        
        ${insertTextOrEmptyString(declareLocation, 'const routerLocation = useLocation();')}
        ${predefinedStatesUsed}
        ${globalStates}

        ${isEmpty(allStates) ? '' : `const {${uniq(allStates).join(',')}} = props;`}


        return (
          ${this.nodeGenerator.runGeneratingNode()}
        );
      }
    `;
    }
    generateComponentExport() {
        const componentName = this.componentFileAccessor.getComponentName();
        return `export { ${componentName} } from './${componentName}'`;
    }
}
