import { equals } from 'ramda';
import { DropTargetMonitor, XYCoord } from 'react-dnd';

import { NodeDSL, ROOT_NODE_ID } from '@builder/schemas';
import { isArray } from '@builder/utils';

import { buildDndSpecMemoizer } from '../utils';
import { DASHBOARD_ANIM_EVENTS, DASHBOARD_EVENTS, MOVE_VARIANT } from 'src/store';

import { buildDndSpecForComponentOverlay, buildDndSpecForNavItemOverlay } from './buildDndSpec';
import { estimateMoveType } from './buildDndSpec/buildDndSpec';
import { ICON_ID_TAG } from './shared/constants';
import {
  BuildDndSpecArgs,
  DndSpec,
  DraggableIconItem,
  DraggableItem,
  DraggableOverlayOrIconItem,
  DragHoverCreateArgs,
} from './shared/types';

const showPreview = (
  type: 'into' | 'before' | 'after',
  targetEl: HTMLDivElement,
  targetNodeDSL: NodeDSL,
  item: DraggableIconItem | DraggableOverlayOrIconItem,
) => {
  const targetDocument = document.getElementById('user-app-iframe') as HTMLIFrameElement | null;
  const previousDiv = targetDocument?.contentWindow?.document.getElementById('placeholderDiv');

  // Remove any previously created placeholder div
  if (previousDiv) {
    previousDiv.parentNode?.removeChild(previousDiv);
  }

  const itemDragged = item as DraggableIconItem;
  const targetStyles = targetNodeDSL?.props?.style as Record<string, string>;
  const styles = `
    border: 1px dashed #999;
    background-color: rgba(50, 60, 71, 0.9);
    color: #999;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-style: italic;
    display: flex;
    position: relative;
    margin: ${targetStyles?.display === 'flex' ? '0 10px' : '10px 0'};
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    color: #fff;
    font-size: 10px;
  `;

  // Create and insert the new placeholder div
  const placeholderDiv = document.createElement('div');
  placeholderDiv.id = 'placeholderDiv';
  placeholderDiv.innerText = `${itemDragged.meta.title} Placeholder`;
  placeholderDiv.style.cssText = styles;
  const element = targetDocument?.contentWindow?.document.querySelector<HTMLElement>(
    `[data-node-id="${targetEl.dataset.nodeId}"]`,
  );
  if (!element) {
    console.error(`Element with data-node-id '${targetEl.dataset.nodeId}' not found.`);
    return;
  }

  switch (type) {
    case 'into':
      element.appendChild(placeholderDiv);
      break;
    case 'before':
      if (targetNodeDSL.name === 'BuilderComponentRouteLayout') {
        element.insertAdjacentElement('afterbegin', placeholderDiv);
      } else {
        element.insertAdjacentElement('beforebegin', placeholderDiv);
      }

      break;
    case 'after':
      element.insertAdjacentElement('afterend', placeholderDiv);
      break;
    default:
      console.error('Invalid option type');
  }
};

const showComponentPreview = (
  type: 'into' | 'before' | 'after',
  targetEl: HTMLElement,
  targetNodeDSL: NodeDSL,
  item: DraggableIconItem | DraggableOverlayOrIconItem,
) => {
  const targetDocument = document.getElementById('user-app-iframe') as HTMLIFrameElement | null;
  const previousDiv = targetDocument?.contentWindow?.document.querySelector(
    '[data-previewid="previewElement"]',
  );
  const draggableItem = targetDocument?.contentWindow?.document.querySelector<HTMLElement>(
    `[data-node-id="${item.id}"]`,
  );
  // Remove any previously created placeholder div
  if (previousDiv) {
    previousDiv.parentNode?.removeChild(previousDiv);
  }

  let clone: HTMLElement | null = null;
  if (draggableItem && item.dndMutableMeta?.kind === 'valid') {
    clone = draggableItem.cloneNode(true) as HTMLElement;
    clone.dataset.previewid = 'previewElement';
    clone.style.opacity = '0.6';
  }

  const element = targetDocument?.contentWindow?.document.querySelector<HTMLElement>(
    `[data-node-id="${targetEl.dataset.nodeId}"]`,
  );
  const previous: HTMLElement | null = element?.previousElementSibling as HTMLElement;
  const next: HTMLElement | null = element?.nextElementSibling as HTMLElement;

  if (!element) {
    console.error(`Element with data-node-id '${targetEl.dataset.nodeId}' not found.`);
    return;
  }

  if (clone && clone.dataset.nodeId !== element.dataset.nodeId) {
    switch (type) {
      case 'into':
        element.appendChild(clone);
        break;
      case 'before':
        if (clone.dataset.nodeId !== previous?.dataset?.nodeId) {
          if (targetNodeDSL.name === 'BuilderComponentRouteLayout') {
            element.insertAdjacentElement('afterbegin', clone);
          } else {
            element.insertAdjacentElement('beforebegin', clone);
          }
        }

        break;
      case 'after':
        if (clone.dataset.nodeId !== next?.dataset?.nodeId) {
          element.insertAdjacentElement('afterend', clone);
        }

        break;
      default:
        console.error('Invalid option type');
    }
  }
};

/**
 * Drag-hover business
 */
export const createDragHover = ({
  buildSpecDnd,
}: {
  buildSpecDnd: (args: BuildDndSpecArgs) => DndSpec;
}) => ({
  send,
  ref,
  target,
  nodeListDSL: nodes,
  componentListDSL: components,
  currentRootNodeID,
  collapsedNodes,
  lazyLeafMarkerMapRef,
}: DragHoverCreateArgs) => (
  item: DraggableItem,
  monitor: DropTargetMonitor,
  reinforceMovingIntoNodeID?: string | undefined,
): void => {
  const isOverlay = isArray(item);

  if (!ref.current) {
    return;
  }

  const currentRef = ref.current;

  if (!monitor.isOver({ shallow: true })) {
    return;
  }

  const itemNodes = isOverlay ? item.map(draggableItem => nodes[draggableItem.id]) : nodes[item.id];
  const itemsIds = isOverlay ? item.map(el => el.id) : item.id;
  // moving page is forbidden
  if (
    !isOverlay &&
    item.id !== ICON_ID_TAG &&
    (item.id === ROOT_NODE_ID || item.id === currentRootNodeID || !(itemNodes as NodeDSL)?.parentID)
  ) {
    return;
  }

  if (
    isOverlay &&
    item.every(i => i.id !== ICON_ID_TAG) &&
    (itemsIds.includes(ROOT_NODE_ID) ||
      itemsIds.includes(currentRootNodeID || '') ||
      (itemNodes as NodeDSL[]).some(x => !x.parentID))
  ) {
    return;
  }

  // TODO: we should double-check if schema-selectors are properly memoized
  const memoizedBuildSpecDnd = buildDndSpecMemoizer(buildSpecDnd);

  // validation for overlays
  if (isOverlay) {
    const prevMeta: Record<string, DndSpec> = item.reduce(
      (acc, current) => ({ ...acc, [current.id]: { meta: current.dndMutableMeta } }),
      {},
    );
    const newSpecs: Record<string, DndSpec> = item.reduce((acc, current) => {
      return {
        ...acc,
        [current.id]: memoizedBuildSpecDnd.call(this, {
          target,
          targetEl: currentRef,
          item: current,
          monitor,
          nodeListDSL: nodes,
          componentListDSL: components,
          reinforceMovingIntoNodeID,
          currentRootNodeID,
          collapsedNodes,
          lazyLeafMarkerMapRef,
        }),
      };
    }, {});
    item.forEach((_, idx) => {
      const current = item[idx];
      current.dndMutableMeta = newSpecs[current.id].meta;

      if (!equals(prevMeta, newSpecs)) {
        send({ type: DASHBOARD_ANIM_EVENTS.move, spec: newSpecs[current.id].meta });
      }
    });

    const isEverySourceTargetTypeInto = Object.values(newSpecs).every(
      spec => spec.meta.target.type === MOVE_VARIANT.into,
    );
    const isEverySourceTargetValid = item.every(i => i.dndMutableMeta?.kind === 'valid');
    const targetNodeID = newSpecs[item[0]?.dndMutableMeta?.sourceID || '']?.meta.target.nodeID;

    // Open collapsed target node
    if (
      isEverySourceTargetTypeInto &&
      targetNodeID &&
      collapsedNodes &&
      collapsedNodes[targetNodeID] &&
      isEverySourceTargetValid
    ) {
      send({
        type: DASHBOARD_EVENTS.navigatorExpandNode,
        nodeID: targetNodeID,
      });
    }

    const invalidItemsDrop = Object.values(newSpecs).filter(spec => spec.meta.kind === 'invalid');
    if (invalidItemsDrop.length) {
      const invalidItemDropId = invalidItemsDrop[0].meta.sourceID;
      send({ type: DASHBOARD_ANIM_EVENTS.move, spec: invalidItemsDrop[0].meta });
      send({ type: DASHBOARD_ANIM_EVENTS.moveName, nodeName: nodes[invalidItemDropId].name });
    }

    item.forEach(internalitem => {
      if (ref.current) {
        const { meta } = newSpecs[internalitem.id];
        const targetNodeDSL = nodes[meta.target.nodeID];
        const targetDocument = document.getElementById(
          'user-app-iframe',
        ) as HTMLIFrameElement | null;
        const element = targetDocument?.contentWindow?.document.querySelector(
          `[data-node-id="${meta.target.nodeID}"]`,
        ) as HTMLElement;
        if (element) {
          showComponentPreview(meta.type, element, targetNodeDSL, internalitem);
        }
      }
    });
    return;
  }

  // validations for icons
  const newSpec = memoizedBuildSpecDnd.call(this, {
    target,
    targetEl: ref.current,
    item,
    monitor,
    nodeListDSL: nodes,
    componentListDSL: components,
    reinforceMovingIntoNodeID,
    currentRootNodeID,
    collapsedNodes,
    lazyLeafMarkerMapRef,
  });

  const prevMeta = item.dndMutableMeta;
  const newMeta = newSpec.meta;

  // eslint-disable-next-line no-param-reassign
  item.dndMutableMeta = { ...newMeta, targetComponent: target };

  if (!equals(prevMeta, newMeta)) {
    send({ type: DASHBOARD_ANIM_EVENTS.move, spec: newMeta });

    // Obtain variables to calculate the preview
    const targetNodeDSL = nodes[target.nodeID];
    const cursorPosition = monitor.getClientOffset() as XYCoord;
    const targetEl = ref.current;
    const type = estimateMoveType({
      targetEl,
      targetNodeDSL,
      cursorPosition,
    });
    showPreview(type, ref.current, targetNodeDSL, item);
  }
};

export const dragHoverForComponentOverlay = createDragHover({
  buildSpecDnd: buildDndSpecForComponentOverlay,
});
/** @deprecated use either `dragHoverForComponentOverlay` or `dragHoverForNavItemOverlay` */
export const dragHover = dragHoverForComponentOverlay;
export const dragHoverForNavItemOverlay = createDragHover({
  buildSpecDnd: buildDndSpecForNavItemOverlay,
});
