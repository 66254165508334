var _a;
import { omit } from 'ramda';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_BOOLEAN_VARIANTS, STATE_SCOPES, STATE_TYPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsSwitch } from '../../material-components/BuilderComponentsSwitch';
import { BuilderComponentsFormSwitchSettings } from './BuilderComponentsFormSwitch.settings';
export const BuilderComponentsFormSwitch = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormSwitch,
    displayName: 'Form Switch',
    icon: 'formSwitch',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormSwitch',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.trueFalse,
                    name: 'FormSwitch',
                    defaultValue: false,
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormSwitch',
                },
                switchProps: Object.assign(Object.assign({}, omit(['checked', 'onChange'], (_a = BuilderComponentsSwitch.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)), { checked: '{{ __STATES__.FormSwitch.value }}' }),
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FormSwitch.setValue(event.target.checked)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { formik: { type: COMPONENT_DSL_PROP_TYPES.object }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            }, checked: { type: COMPONENT_DSL_PROP_TYPES.boolean }, switchProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsSwitch.schema.props,
            }, autofocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                ],
            } }),
    },
    settings: BuilderComponentsFormSwitchSettings,
};
