import { HEX, HSBA, LAB, RGBA } from '../../types';

import { Hex } from './Hex';
import { Hsba } from './Hsba';
import { Lab } from './Lab';
import { Rgba } from './Rgba';

export class Converter {
  public static hex(color: HEX): Hex {
    return new Hex(color);
  }

  public static hsba(object: HSBA): Hsba {
    return new Hsba(object);
  }

  public static rgba(object: RGBA | string): Rgba {
    return new Rgba(object);
  }

  public static lab(object: LAB | string): Lab {
    return new Lab(object);
  }
}
