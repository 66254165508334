import { ApiStandTypes, API_STANDS } from '@builder/utils';

import { Maybe } from '../graphql/__generated__';

/**
 * getEnviromentString
 * @example the function receives these two parameters and gets this
 *  obj: API_STANDS: {prod: 'https://api.8base.com', staging: 'https://staging-api.8basedev.com', qa1: 'https://qa-api.8basedev.com', qa2: 'https://prestaging-api.8basedev.com'}
 *  value: defaultApi: "https://prestaging-api.8basedev.com"
 *  and returns environmentString: "qa2"
 */
export const getEnvironmentString = (
  obj: Record<ApiStandTypes, string>,
  value: Maybe<string> | undefined,
): string =>
  (Object.keys(obj) as (keyof typeof API_STANDS)[]).find(key => obj[key] === value) || '';
