import React from 'react';

import styled from '@emotion/styled';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';

type ContextTypes = {
  parameter: string;
  action: string;
  tooltipInfo: string;
};

type FunctionSectionProps = {
  children: React.ReactNode;
  isAsyncFunction: boolean;
  functionName: string;
  showContext?: boolean;
  args: string[];
  isContext?: boolean;
  context?: ContextTypes;
};

const Container = styled.div`
  background: #323c47;
  height: 100%;
  display: grid;
  width: 100%;
`;
const FunctionDefinition = styled(Typography)`
  padding: 0;
  margin: 0;
  .brackets {
    color: #c4b211;
  }
  .parentheses {
    color: #63c1b6;
  }
  .function-and-arrow {
    color: #4b8ab2;
  }
  .async {
    color: #b1a199;
  }
  .function-name {
    color: #b1a199;
  }
  .bracketsContext {
    color: #b80cfd;
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const commonCharacter = (args: string[], isHook = false) => {
  return (
    <Box>
      <span className="brackets">{isHook && ' async '} (</span>
      <span className="bracketsContext">{'{ '}</span>
      <span className="parentheses">{args.join(', ')}</span>
      <span className="bracketsContext">{' }'}</span>
      <span className="brackets">)</span>
      <span className="function-and-arrow">{` => `}</span>
      <span className="brackets">{'{'}</span>
    </Box>
  );
};

const basicContext = (args: string[]) => {
  return (
    <>
      <span className="parentheses">(</span>
      {args.join(', ')}
      <span className="parentheses">)</span>
      <span className="function-and-arrow">{` => `}</span>
      <span className="brackets">{'{'}</span>
    </>
  );
};

const basicContextWithOutCurlyBraces = (args: string[]) => {
  return (
    <Box>
      <span className="brackets">(</span>
      <span className="parentheses">{` ${args.join(', ')} `}</span>
      <span className="brackets">)</span>
      <span className="function-and-arrow">{` => `}</span>
      <span className="brackets">{'{'}</span>
    </Box>
  );
};

export const FunctionDefinitionContainer: React.FC<FunctionSectionProps> = ({
  children,
  isAsyncFunction,
  args,
  functionName,
  showContext = true,
  isContext = false,
  context = { parameter: '', action: '', tooltipInfo: '' },
}) => {
  const { parameter, tooltipInfo, action } = context;
  const paramsElements = ['local hooks', 'global hooks'];
  const isHook = paramsElements.includes(action);
  const contextToRender = !paramsElements.includes(action)
    ? basicContextWithOutCurlyBraces(args)
    : commonCharacter(args, isHook);

  const contextWrapper = () => {
    if (!showContext) {
      return false;
    }

    if (parameter)
      return (
        <TooltipContainer>
          <Tooltip
            title={tooltipInfo}
            arrow
            color="black"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  color: 'black',
                  backgroundColor: 'white',
                },
              },
            }}
          >
            {contextToRender}
          </Tooltip>
        </TooltipContainer>
      );

    return basicContextWithOutCurlyBraces(args);
  };

  return (
    <Container>
      <FunctionDefinition>
        {isAsyncFunction && <span className="brackets">async </span>}
        {!isContext && <span className="function-and-arrow">function </span>}
        <span className="function-name">{functionName}</span>
        {isContext ? contextWrapper() : basicContext(args)}
      </FunctionDefinition>
      {children}
      <FunctionDefinition>
        <span className="brackets">{'}'}</span>
      </FunctionDefinition>
    </Container>
  );
};
