var _a;
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
const inDashboard = (_a = window.location.href) === null || _a === void 0 ? void 0 : _a.includes('in-dashboard');
export const UI_BUILDER_MODES = {
    construction: 'construction',
    preview: 'preview',
};
const initialState = {
    mode: inDashboard ? UI_BUILDER_MODES.construction : UI_BUILDER_MODES.preview,
};
export const actions = {
    modeRequestPreview: '@builder/mode-req/preview',
    modeRequestConstruction: '@builder/mode-req/construction',
};
const DashboardContext = React.createContext(initialState);
DashboardContext.displayName = 'DashboardContext';
const reducer = (state, action) => {
    switch (action.type) {
        case actions.modeRequestPreview:
            return Object.assign(Object.assign({}, state), { mode: UI_BUILDER_MODES.preview });
        case actions.modeRequestConstruction:
            return Object.assign(Object.assign({}, state), { mode: UI_BUILDER_MODES.construction });
        default:
            return state;
    }
};
export const DashboardProvider = ({ children, }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    React.useEffect(() => {
        const listener = (event) => {
            var _a, _b;
            if (event.origin !== window.location.origin ||
                ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.source) !== 'APP_BUILDER_SOURCE' ||
                !((_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.type)) {
                return;
            }
            const { type, payload } = event.data;
            if ([actions.modeRequestPreview, actions.modeRequestConstruction].includes(type)) {
                dispatch(Object.assign({ type }, (payload || {})));
            }
        };
        window.addEventListener('message', listener, false);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, []);
    const value = React.useMemo(() => ({
        mode: state.mode,
    }), [state.mode]);
    return _jsx(DashboardContext.Provider, Object.assign({ value: value }, { children: children }));
};
export const useDashboardHook = () => {
    const context = React.useContext(DashboardContext);
    if (context === undefined) {
        throw new Error('useDashboardHook must be used within a DashboardProvider');
    }
    return {
        mode: context.mode,
    };
};
