import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { getCommonSymbolEventProps, getCommonSymbolStyleProps } from '../../common/commonProps';
import { MaterialAlert } from '../../material-components/MaterialAlert';
import { AlertSymbolSettings } from './AlertSymbol.settings';
export const AlertSymbol = {
    name: COMPONENT_DSL_NAMES.AlertSymbol,
    displayName: 'Alert',
    icon: 'alert',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.other,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            selectNodeInside: ['title'],
            mainProp: 'Title',
        },
        dndTargetPropName: 'content',
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        },
        predefineds: {
            props: {
                closeText: 'Close',
                role: 'alert',
                severity: 'success',
                variant: 'standard',
                title: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialAlertTitle,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Title',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Alert Text',
                            },
                        },
                    ],
                },
                style: getPredefinedStyles({ display: 'flex', minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, MaterialAlert.schema.props), { title: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialAlertTitle],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialAlert,
            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { id: '{{ symbolProps.id }}', className: '{{ symbolProps.className }}', lang: '{{ symbolProps.lang }}', translate: '{{ symbolProps.translate }}', action: '{{ symbolProps.action }}', closeText: '{{ symbolProps.closeText }}', color: '{{ symbolProps.color }}', icon: '{{ symbolProps.icon }}', iconMapping: '{{ symbolProps.iconMapping }}', onClose: '{{ symbolProps.onClose }}', role: '{{ symbolProps.role }}', severity: '{{ symbolProps.severity }}', variant: '{{ symbolProps.variant }}', children: '{{ [symbolProps.title, symbolProps.children] }}', draggable: '{{ symbolProps.draggable }}' }),
        },
    },
    settings: AlertSymbolSettings,
};
