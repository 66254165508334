import { stateListSelectors, } from '@builder/schemas';
import { hasUsedVarInNodeListDSL } from '../../utils';
import { isUsedInSomeNode, isUsedInSomeState } from '../app-states-generator';
import { ASSETS_PROVIDER_CONST, GLOBAL_STATE_PROVIDER_CONST, ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST, USE_ERROR_BOUNDARY_CONST, USE_ASSETS_CONST, USE_GLOBAL_STATE_CONST, ASSETS_STATE_NAME, } from './constants';
/**
 * Provides an api to generate providers imports.
 */
export class ProviderListImportsGenerator {
    constructor(appDSL, componentListDSL) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
    }
    hasUsedGlobalStates(usedNodeListDSL) {
        const { states } = this.appDSL;
        const globalStates = stateListSelectors.getGlobalStateArrayDSL(states);
        const localStates = stateListSelectors.getLocalStateArrayDSL(states);
        const globalStateUsedInApp = globalStates.some(globalState => isUsedInSomeNode(globalState, usedNodeListDSL, this.componentListDSL) ||
            isUsedInSomeState(globalState, localStates));
        return globalStateUsedInApp;
    }
    hasUsedAssetsState(usedNodeListDSL) {
        return hasUsedVarInNodeListDSL(ASSETS_STATE_NAME, usedNodeListDSL, this.componentListDSL);
    }
    hasUsedErrorProvider(usedNodeListDSL) {
        return hasUsedVarInNodeListDSL(USE_ERROR_BOUNDARY_CONST, usedNodeListDSL, this.componentListDSL);
    }
    getProvidersImportDataList() {
        return [
            {
                importName: 'providers',
                varName: GLOBAL_STATE_PROVIDER_CONST,
            },
            {
                importName: 'providers',
                varName: ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST,
            },
            {
                importName: 'providers',
                varName: ASSETS_PROVIDER_CONST,
            },
        ];
    }
    getProviderHooksImportDataList(usedNodeListDSL) {
        const imports = [];
        if (this.hasUsedGlobalStates(usedNodeListDSL)) {
            imports.push({
                importName: 'providers',
                varName: USE_GLOBAL_STATE_CONST,
            });
        }
        if (this.hasUsedAssetsState(usedNodeListDSL)) {
            imports.push({
                importName: 'providers',
                varName: USE_ASSETS_CONST,
            });
        }
        if (this.hasUsedErrorProvider(usedNodeListDSL)) {
            imports.push({
                importName: 'providers',
                varName: USE_ERROR_BOUNDARY_CONST,
            });
        }
        return imports;
    }
}
