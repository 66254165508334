import { mapObjIndexed } from 'ramda';
export class PresentationsContainer {
    constructor() {
        this.presentations = {};
    }
    addPresentation(nodeID, presentations) {
        this.presentations[nodeID] = presentations;
    }
    getPresentationList() {
        return mapObjIndexed(presentation => {
            return presentation.getPresentation();
        }, this.presentations);
    }
    getPresentation(nodeID) {
        return this.presentations[nodeID];
    }
}
