var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AppNodeListValidator_1;
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ERROR_SCOPES, isObject, SchemaValidationError } from '@builder/utils';
import { COMPONENT_SCHEMAS } from '../../../component-schemas';
import { nodeSelectors, nodeListSelectors } from '../../../selectors';
import { staticImplements } from '../types';
import { AppNodeValidator } from './AppNodeValidator';
let AppNodeListValidator = AppNodeListValidator_1 = class AppNodeListValidator {
    static checkNodesValid(appNodeListMaybe) {
        if (!isObject(appNodeListMaybe)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `App Nodes must be an object with nodes, but got ${typeof appNodeListMaybe}`);
        }
        Object.values(appNodeListMaybe).forEach(nodeMaybe => {
            AppNodeValidator.checkValid(nodeMaybe);
        });
    }
    static checkValid(appNodeListMaybe, userComponentListDSL) {
        AppNodeListValidator_1.checkNodesValid(appNodeListMaybe);
        const nodeListDSL = appNodeListMaybe;
        const childrenIdsSet = new Set();
        Object.values(nodeListDSL).forEach(nodeDSL => {
            const childrenIDs = nodeSelectors.getAllImmediateChildrenIDs(nodeDSL, {
                componentListDSL: Object.assign(Object.assign({}, COMPONENT_SCHEMAS), userComponentListDSL),
            });
            if (!nodeListSelectors.isNodeAliasUnique(nodeListDSL, {
                nodeID: nodeDSL.id,
                alias: nodeDSL.alias,
            })) {
                throw new SchemaValidationError(ERROR_SCOPES.schemas, `"${nodeDSL.id}"" node alias "${nodeDSL.alias}" is not unique`);
            }
            childrenIDs.forEach(childrenID => {
                if (childrenIdsSet.has(childrenID)) {
                    throw new SchemaValidationError(ERROR_SCOPES.schemas, `"${childrenID}" should has only one parent`);
                }
                childrenIdsSet.add(childrenID);
            });
        });
    }
};
AppNodeListValidator = AppNodeListValidator_1 = __decorate([
    staticImplements()
], AppNodeListValidator);
export { AppNodeListValidator };
