import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@mui/material';
import { equals } from 'ramda';

import {
  CloseButton,
  ColorPreview,
  SaturationBrightnessSlider,
  Tabs,
  ColorSlider,
  HueSlider,
} from './components';
import { COLOR_MODELS, HSBA_KEYS } from './constants';
import {
  SlidersBar,
  TabsWrapper,
  StyledThemeColorPickerWrapper,
  StyledTypography,
} from './ThemeColorPicker.styles';
import { Color, ColorModel, HSBA, inputState, ValidatorType } from './types';
import {
  convertColorToHsba,
  convertHsbaToColorByType,
  getColorFromString,
  getColorString,
  getInitialColorValue,
} from './utils/colors';
import { Converter } from './utils/converter';
import { getPercentageTitle } from './utils/helpers';
import { Validator } from './utils/validator';

type ThemeColorPickerProps = {
  title?: string;
  color?: string;
  validator?: ValidatorType;
  onClose: () => void;
  onApply: (color: string) => void;
  onColorChange?: (color: Color) => void;
};

const TAB_OPTIONS = Object.values(COLOR_MODELS).map(model => ({
  label: model,
  value: model,
}));

const INITIAL_COLOR = {
  type: COLOR_MODELS.hex,
  value: '#FF0000',
} as Color;

const INITIAL_INPUT = {
  value: '',
  isInvalid: false,
} as inputState;

const INITIAL_ALPHA = 1;

const INITIAL_HSBA = {
  h: 0,
  s: 1,
  b: 1,
  a: INITIAL_ALPHA,
};

export const ThemeColorPicker: React.FC<ThemeColorPickerProps> = ({
  title,
  color = '',
  validator = Validator,
  onClose,
  onApply,
  onColorChange,
}) => {
  const initialColor = getInitialColorValue(color);
  const [currentColor, setCurrentColor] = useState<Color>(initialColor || INITIAL_COLOR);
  const [input, setInput] = useState<inputState>(INITIAL_INPUT);
  const [hsba, setHsba] = useState<HSBA>(INITIAL_HSBA);
  const [selectedColorModel, setSelectedColorModel] = useState<ColorModel>(COLOR_MODELS.hex);

  const rgbaColorString = useMemo(() => {
    const rgba = Converter.hsba(hsba).toRgba();
    return Converter.rgba(rgba).toString();
  }, [hsba]);

  useEffect(() => {
    if (onColorChange) {
      onColorChange(currentColor);
    }
  }, [currentColor, onColorChange]);

  const updateCurrentColor = useCallback(
    (newColor: Color, affectedCallback?: (newColorForAffect: Color) => void) => {
      setCurrentColor(newColor);
      affectedCallback?.(newColor);
    },
    [],
  );

  const updateHsbaByColor = useCallback((newColor: Color) => {
    const newHsba = convertColorToHsba(newColor);
    setHsba(newHsba);
  }, []);

  const updateInputByColor = useCallback((newColor: Color) => {
    const newInputValue = getColorString(newColor);
    setInput(prevInput => ({ ...prevInput, value: newInputValue }));
  }, []);

  const updateHsbaByKey = useCallback(
    (
      hsbaKey: keyof typeof HSBA_KEYS,
      value: number,
      newSelectedColorModel: ColorModel = selectedColorModel,
    ) => {
      setHsba(prevHsba => {
        const newHsba = { ...prevHsba, [hsbaKey]: value };
        const newColor = convertHsbaToColorByType(newHsba, newSelectedColorModel);
        updateCurrentColor(newColor, updateInputByColor);
        return newHsba;
      });
    },
    [selectedColorModel, updateCurrentColor, updateInputByColor],
  );

  const handleInputChange = useCallback(
    (newInputValue: string) => {
      const newColor = getColorFromString(newInputValue, selectedColorModel);
      const isValidColor = validator.isValidColor(newColor);
      const newInput = { value: newInputValue, isInvalid: !isValidColor } as inputState;

      if (isValidColor) {
        updateCurrentColor(newColor, updateHsbaByColor);
      }

      setInput(newInput);
    },
    [selectedColorModel, updateCurrentColor, updateHsbaByColor, validator],
  );

  const handleColorModelChange = useCallback(
    (newSelectedColorModel: string) => {
      setSelectedColorModel(newSelectedColorModel as ColorModel);
      updateHsbaByKey(HSBA_KEYS.a, INITIAL_ALPHA, newSelectedColorModel as ColorModel);
    },
    [updateHsbaByKey],
  );

  const handleApplyColor = useCallback(() => {
    const actualColorString = getColorString(currentColor, false);
    onApply(actualColorString);
    onClose();
  }, [currentColor, onApply, onClose]);

  useEffect(() => {
    setSelectedColorModel(currentColor.type);
    updateInputByColor(currentColor);
    updateHsbaByColor(currentColor);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledThemeColorPickerWrapper>
      <CloseButton onClick={onClose} />

      <StyledTypography variant="subtitle1" className="no-drag">
        {title}
      </StyledTypography>

      <SaturationBrightnessSlider
        width="100%"
        height="130px"
        hsb={{
          h: hsba.h,
          s: hsba.s,
          b: hsba.b,
        }}
        onSaturationChange={(newSaturation: number) => updateHsbaByKey(HSBA_KEYS.s, newSaturation)}
        onBrightnessChange={(newBrightness: number) => updateHsbaByKey(HSBA_KEYS.b, newBrightness)}
      />

      <TabsWrapper className="no-drag">
        <Tabs
          selectedValue={selectedColorModel as string}
          options={TAB_OPTIONS}
          onChange={handleColorModelChange}
        />
      </TabsWrapper>

      <ColorPreview color={rgbaColorString} input={input} onChange={handleInputChange} />

      <SlidersBar className="no-drag">
        <HueSlider
          title="Gamma"
          value={hsba.h}
          onChange={(newHue: number) => updateHsbaByKey(HSBA_KEYS.h, newHue)}
        />

        <ColorSlider
          title="Brightness"
          subTitle={getPercentageTitle(hsba.b)}
          value={hsba.b}
          hue={hsba.h}
          initialColor="black"
          onChange={(newBrightness: number) => updateHsbaByKey(HSBA_KEYS.b, newBrightness)}
        />
        <ColorSlider
          title="Saturation"
          subTitle={getPercentageTitle(hsba.s)}
          value={hsba.s}
          hue={hsba.h}
          initialColor="white"
          onChange={(newSaturation: number) => updateHsbaByKey(HSBA_KEYS.s, newSaturation)}
        />

        {selectedColorModel === COLOR_MODELS.rgba && (
          <ColorSlider
            title="Opacity"
            subTitle={getPercentageTitle(hsba.a)}
            value={hsba.a}
            hue={hsba.h}
            initialColor="transparent"
            onChange={(newAlpha: number) => updateHsbaByKey(HSBA_KEYS.a, newAlpha)}
          />
        )}
      </SlidersBar>

      <Button
        variant="contained"
        color="success"
        fullWidth
        onClick={handleApplyColor}
        disabled={equals(initialColor, currentColor)}
        className="no-drag"
      >
        Apply
      </Button>
    </StyledThemeColorPickerWrapper>
  );
};
