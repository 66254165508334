/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { mapObjIndexed, path } from 'ramda';
import { COMPONENT_SCHEMAS, forEachNodeListProps, hasPropJsCode, PropChecker, } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_16_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.0';
        this.propParent = (reqId, updatedNodeListDSL) => {
            let oldParentChildren = reqId.parent || '';
            let oldAliasChildren = reqId.alias || '';
            let flagChildren = 0;
            while ((!oldAliasChildren.includes('Wrapper') || !oldAliasChildren) &&
                flagChildren < Object.keys(updatedNodeListDSL).length - 1) {
                flagChildren += 1;
                // eslint-disable-next-line no-loop-func, array-callback-return
                Object.keys(updatedNodeListDSL).map(node => {
                    if (node === oldParentChildren) {
                        const aliasTextWrapper = path(['alias'], updatedNodeListDSL[updatedNodeListDSL[node].parentID]);
                        if (!(aliasTextWrapper || '').includes('Wrapper')) {
                            oldParentChildren = updatedNodeListDSL[node].parentID;
                            oldAliasChildren = updatedNodeListDSL[node].alias || '';
                        }
                    }
                });
            }
            return oldParentChildren;
        };
    }
    getChangeList() {
        return ['Assigning orphan requests to parent states'];
    }
    migrateNodeListDSL(nodeListDSL, appDSL) {
        return mapObjIndexed((nodeDSL, _) => this.migrateNodeDSL(nodeDSL, appDSL), nodeListDSL);
    }
    migrateAppConfiguration(configuration) {
        const { appDSL, userComponentsDSL: userComponentListDSL } = configuration;
        const { nodes } = appDSL;
        const componentListDSL = Object.assign(Object.assign({}, COMPONENT_SCHEMAS), userComponentListDSL);
        const migratedNodesListDSL = this.migrateNodeListDSL(nodes, appDSL);
        const updatedNodeListDSL = Object.assign({}, migratedNodesListDSL);
        const queryStates = Object.keys(appDSL.states)
            .map(state => {
            return appDSL.states[state];
        })
            .filter(state => {
            return state.type === 'query';
        });
        const changePropValue = (nodePropsArgs) => {
            var _a, _b, _c, _d;
            const nodeDSL = updatedNodeListDSL[nodePropsArgs.nodeDSL.id];
            let requestID = { parent: '', request: '', alias: '' };
            let requestIDJS = { parent: '', request: '', alias: '' };
            if (hasPropJsCode(nodePropsArgs.propValue)) {
                const hasState = queryStates.filter(state => {
                    var _a, _b;
                    return (((_a = nodePropsArgs.propValue) === null || _a === void 0 ? void 0 : _a.includes(`${state.name}?.`)) ||
                        ((_b = nodePropsArgs.propValue) === null || _b === void 0 ? void 0 : _b.includes(`${state.name}.`)));
                });
                if (hasState.length > 0) {
                    requestIDJS = {
                        parent: nodeDSL.parentID,
                        request: hasState[0].id,
                        alias: nodeDSL.alias,
                    };
                }
            }
            const oldParentChildren = requestIDJS.parent
                ? this.propParent(requestIDJS, updatedNodeListDSL)
                : '';
            if (updatedNodeListDSL[oldParentChildren] &&
                ((_a = updatedNodeListDSL[oldParentChildren].states) === null || _a === void 0 ? void 0 : _a.filter(state => state.stateID === requestIDJS.request).length) === 0) {
                return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: Object.assign(Object.assign({}, updatedNodeListDSL), { [`${updatedNodeListDSL[oldParentChildren]}`]: Object.assign(Object.assign({}, updatedNodeListDSL[oldParentChildren]), { states: (_b = updatedNodeListDSL[oldParentChildren].states) === null || _b === void 0 ? void 0 : _b.push({
                                    stateID: requestIDJS.request,
                                }) }) }) }) });
            }
            if (PropChecker.Value.isActionPropWithArgs(nodePropsArgs.propValue) &&
                nodePropsArgs.propValue.actionType === 'runRequest') {
                requestID = {
                    parent: nodeDSL.parentID,
                    request: nodePropsArgs.propValue.args.requestID,
                    alias: nodeDSL.alias,
                };
            }
            const oldParent = requestID.parent ? this.propParent(requestID, updatedNodeListDSL) : '';
            if (updatedNodeListDSL[oldParent] &&
                ((_c = updatedNodeListDSL[oldParent].states) === null || _c === void 0 ? void 0 : _c.filter(state => state.stateID === requestID.request).length) === 0) {
                return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: Object.assign(Object.assign({}, updatedNodeListDSL), { [`${updatedNodeListDSL[oldParent]}`]: Object.assign(Object.assign({}, updatedNodeListDSL[oldParent]), { states: (_d = updatedNodeListDSL[oldParent].states) === null || _d === void 0 ? void 0 : _d.push({ stateID: requestID.request }) }) }) }) });
            }
        };
        forEachNodeListProps(changePropValue, componentListDSL, migratedNodesListDSL);
        return configuration;
    }
}
export const migration_0_16_0 = new Migration_0_16_0();
