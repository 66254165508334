import React, { useCallback } from 'react';

import { useDebouncedState } from '@builder/utils';

import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { ColorPicker, ColorPickerProps } from 'src/shared/components';

type ColorPickerViewEditorProps = ColorPickerProps & {
  label: string;
  value?: string;
  onChange: (propValue: string) => void;
  nodeID?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  skipDebounce?: boolean;
};

export const ColorPickerViewEditor: React.FC<ColorPickerViewEditorProps> = ({
  label,
  value = '',
  onChange,
  nodeID,
  'data-test': dataTest,
  showFx = true,
  skipDebounce,
}) => {
  const [debouncedValue, setDebouncedValue] = useDebouncedState(value, onChange, {
    skipDebounce,
  });

  const updatePropByEvent = useCallback(
    (changedValue: unknown) => {
      setDebouncedValue(changedValue as string);
    },
    [setDebouncedValue],
  );

  return (
    <JsCodeInjectionInput
      label={label}
      propValue={debouncedValue}
      onChangePropValue={updatePropByEvent}
      nodeID={nodeID}
      showFx={showFx}
      data-test={dataTest}
      typeField="string"
    >
      {({ isFxEnabled, enableFx, nonJsCodePropValue = '' }) => (
        <ColorPicker
          value={nonJsCodePropValue}
          label={label}
          onChange={updatePropByEvent}
          isFxEnabled={isFxEnabled}
          showFx={showFx}
          enableFx={enableFx}
          data-test={dataTest}
        />
      )}
    </JsCodeInjectionInput>
  );
};
