import { isEmpty } from 'ramda';

import { COMPONENT_DSL_NAMES, nodeListSelectors, nodeSelectors } from '@builder/schemas';

import { componentCreate, componentMove } from '../../../complex-cases';
import { dashboardInitial } from '../../../initial-state';
import { createUrlFromPathWithDefaultPathParams } from 'src/features/routing/common';
import { dashboardSelectors } from 'src/store';
import {
  AppEvents,
  createReducer,
  DashboardState,
  DASHBOARD_EVENTS,
  DND_TARGET_TYPES,
  MOVE_VARIANT,
} from 'src/store/common';

export const ROUTE_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.routeCreate,
  DASHBOARD_EVENTS.routeCurrentUpdate,
  DASHBOARD_EVENTS.routeLayoutUpdate,
];
const defaultHookFunction = `return function({from,to,state}) {
  // type here
}`;
const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof ROUTE_REDUCER_EVENTS[number]>({
    events: ROUTE_REDUCER_EVENTS,
    reduce: (state, event) => {
      const componentListDSL = dashboardSelectors.getComponentListDSL(state);
      const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
      const stateListDSL = dashboardSelectors.getStateListDSL(state);
      switch (event.type) {
        // routes
        case DASHBOARD_EVENTS.routeCreate: {
          return componentCreate(
            state,
            {
              type: 'into',
              target: {
                type: DND_TARGET_TYPES.into,
                nodeID: event.layoutID,
                propName: ['routes'],
              },
            },
            {
              name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
              alias: `${event.nodeAlias} Wrapper`,
              props: {
                path: event.path,
                __defaultPathParams: event.defaultPathParams,
                title: event.title,
                metaTags: event.metaTags,
                authAccess: 'any',
                children: {
                  nodes: [
                    {
                      name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                      alias: event.nodeAlias,
                      props:
                        componentListDSL[COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout].schema
                          .predefineds?.props,
                      states: [],
                    },
                  ],
                },
                routerHooks: {
                  beforeRouteEnter: event.routerHooks?.beforeRouteEnter || defaultHookFunction,
                  beforeRouteUpdate: event.routerHooks?.beforeRouteUpdate || defaultHookFunction,
                  beforeRouteExit: event.routerHooks?.beforeRouteExit || defaultHookFunction,
                },
                __meta: event.meta,
              },
              incrementAlias: false,
              selectNodeOnCreate: false,
            },
          );
        }

        case DASHBOARD_EVENTS.routeCurrentUpdate: {
          const routeContentNodeID = nodeListSelectors.getAllRouteLayoutNodes(nodeListDSL, {
            nodeID: event.routeID,
            componentListDSL,
          })[0].id;

          const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
            nodeID: routeContentNodeID,
          });

          const globalStates = nodeSelectors.getGlobalStateConnectionsList(nodeDSL, {
            appStateListDSL: stateListDSL,
          });
          const parentID = nodeDSL.parentID as string;

          if (!routeContentNodeID) return state;

          const newState = componentMove(state, {
            type: MOVE_VARIANT.into,
            sourceID: event.routeID,
            kind: 'valid',
            target: {
              type: DND_TARGET_TYPES.into,
              nodeID: event.layoutID,
              propName: ['routes'],
            },
          });
          const { appConfiguration } = newState;

          const newStateWithRoute = {
            ...newState,
            appConfiguration: {
              ...appConfiguration,
              appDSL: {
                ...appConfiguration.appDSL,

                nodes: {
                  ...appConfiguration.appDSL.nodes,
                  [event.routeID]: {
                    ...appConfiguration.appDSL.nodes[event.routeID],
                    alias: `${event.nodeAlias} Wrapper`,
                    props: {
                      ...appConfiguration.appDSL.nodes[event.routeID].props,
                      __defaultPathParams: event.defaultPathParams,
                      title: event.title,
                      metaTags: event.metaTags,
                      path: event.path,
                      authAccess: event.authAccess,
                      routerHooks: event.routerHooks,
                      __meta: event.meta,
                    },
                  },
                  [routeContentNodeID]: {
                    ...appConfiguration.appDSL.nodes[routeContentNodeID],
                    alias: event.nodeAlias,
                  },
                },
              },
            },
          };
          const newStateLayout = {
            ...newStateWithRoute,
            appConfiguration: {
              ...newStateWithRoute.appConfiguration,
              appDSL: {
                ...newStateWithRoute.appConfiguration.appDSL,
                nodes: {
                  ...newStateWithRoute.appConfiguration.appDSL.nodes,
                  [parentID]: {
                    ...newStateWithRoute.appConfiguration.appDSL.nodes[parentID],
                    states: [...globalStates, ...event.states],
                  },
                },
              },
            },
          };

          if (event.path !== state.router.currentRoute || !isEmpty(event.defaultPathParams)) {
            if (nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout) {
              return {
                ...newStateLayout,
                router: {
                  ...newStateWithRoute.router,
                  currentRoute: createUrlFromPathWithDefaultPathParams(
                    event.path,
                    event.defaultPathParams.map(({ name, value }) => ({ key: name, value })),
                  ),
                },
              };
            }

            return {
              ...newStateWithRoute,
              router: {
                ...newStateWithRoute.router,
                currentRoute: createUrlFromPathWithDefaultPathParams(
                  event.path,
                  event.defaultPathParams.map(({ name, value }) => ({ key: name, value })),
                ),
              },
            };
          }

          if (nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout) {
            return newStateLayout;
          }

          return newStateWithRoute;
        }
        case DASHBOARD_EVENTS.routeLayoutUpdate: {
          const newState = event.routeIDs.reduce((tempState, routeID) => {
            return componentMove(tempState, {
              type: MOVE_VARIANT.into,
              sourceID: routeID,
              kind: 'valid',
              target: {
                type: DND_TARGET_TYPES.into,
                nodeID: event.layoutID,
                propName: ['routes'],
              },
            });
          }, state);
          return newState;
        }
      }
    },
  }),
]);

export const routeReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
