import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, } from '../../common';
import { getStyleSections, getPropertySections, getDivider, getCommonActionSettings, getCommonActionListSettings, } from '../../common/settings';
import { getCommonToggleButtonSettings } from '../MaterialToggleButton/MaterialToggleButton.settings';
export const getMaterialToggleButtonGroupSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            name: 'label',
                            nodePath: [...prefixPath, 'label', 0],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'value',
                            label: 'Value',
                            keyPath: [...prefixPath, 'value'],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'classes'],
                            name: 'classes',
                            label: 'Classes',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'exclusive',
                            label: 'Exclusive',
                            keyPath: [...prefixPath, 'exclusive'],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disabled'],
                            label: 'Disabled',
                        },
                        getDivider(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'toggleButtons-items',
                            title: 'Toggle Buttons Items',
                            expandedByDefault: false,
                            children: [
                                {
                                    type: COMPONENT_SETTING_TYPES.nodeList,
                                    keyPath: ['children'],
                                    label: 'Toggle Buttons Items',
                                    name: 'ToggleItems',
                                    itemLabelKeyPath: ['children'],
                                    itemLabelNodePath: ['label', 0, 'children', 0],
                                    items: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialToggleButton,
                                            settings: getCommonToggleButtonSettings(),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['orientation'],
                            label: 'Orientation	',
                            options: ['horizontal', 'vertical'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['size'],
                            label: 'Size',
                            options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Font Color',
                            options: ['primary', 'secondary', 'error', 'info', 'success', 'warning'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['fullWidth'],
                            label: 'Full Width',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Custom sx',
                    name: 'customCSS',
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.cssEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['sx'],
                            name: 'panelsx',
                            label: 'Toggle Button Group Styles (sx)',
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(prefixPath),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
export const MaterialToggleButtonGroupSettings = getMaterialToggleButtonGroupSettings();
