import React from 'react';

import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

import { useDebouncedState } from '@builder/utils';

import { useTheme } from 'src/providers/ThemeProvider';
import { TextField, TextFieldProps, Icon } from 'src/shared/components';

type SearchProps = TextFieldProps & {
  onSearch: (value: string) => void;
  skipDebounce?: boolean;
  adornmentPosition?: 'end' | 'start';
};

export const Search: React.FC<SearchProps> = ({
  'data-test': dataTest,
  placeholder = 'Search...',
  multiline = false,
  skipDebounce,
  onSearch,
  value: initialValue = '',
  adornmentPosition = 'end',
  ...props
}) => {
  const theme = useTheme();
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    initialValue as string,
    onSearch,
    {
      skipDebounce,
    },
  );

  return (
    <TextField
      {...props}
      data-test={dataTest}
      multiline={multiline}
      placeholder={placeholder}
      InputProps={{
        [adornmentPosition === 'start' ? 'startAdornment' : 'endAdornment']: (
          <InputAdornment position="start">
            <Choose>
              <When condition={Boolean(debouncedValue)}>
                <Icon
                  name="cross"
                  fill={theme.palette.grey[400]}
                  hoverFill={theme.palette.primary.main}
                  width={16}
                  height={16}
                  element="button"
                  onClick={() => setDebouncedValue('')}
                />
              </When>
              <Otherwise>
                <SearchIcon style={{ fontSize: 16, color: theme.palette.common.white }} />
              </Otherwise>
            </Choose>
          </InputAdornment>
        ),
      }}
      value={debouncedValue}
      onChange={event => setDebouncedValue(event.currentTarget.value)}
    />
  );
};
