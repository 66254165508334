import parserTypeScript from 'prettier/parser-typescript';
import prettier from 'prettier/standalone';
export const formatCode = (code) => {
    try {
        return prettier.format(code, {
            semi: true,
            trailingComma: 'all',
            singleQuote: true,
            printWidth: 80,
            parser: 'typescript',
            plugins: [parserTypeScript],
        });
    }
    catch (err) {
        console.error(err);
        return err.message;
    }
};
