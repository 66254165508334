import { ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST } from './constants';
export class RouterProviderGenerator {
    generateErrorBounderRouter() {
        return `
        import React from 'react';

        import { BrowserRouter } from 'react-router-dom';

        import ErrorBoundary from './ErrorBoundary';

        type Props = {
        children: React.ReactNode[] | React.ReactNode;
        };

        class ErrorBoundaryRouter extends React.Component<Props> {
        render() {
            return (
                <BrowserRouter>
                    <ErrorBoundary>
                        {this.props.children}
                    </ErrorBoundary>
                </BrowserRouter>
            );
        }
        }

        export default ErrorBoundaryRouter;`;
    }
    generateErrorBoundary(isPreview) {
        return `
    import React, { ErrorInfo } from 'react';

    import { ${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST} } from './ErrorBoundaryContextProvider';
    
    type Props = {
      children: React.ReactNode[] | React.ReactNode;
    };
    
    type State = {
      hasError: boolean;
      error?: Error;
      info?: ErrorInfo;
    };
    
    class ErrorBoundary extends React.Component<Props, State> {
      constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
      }
    
      static getDerivedStateFromError(error: string) {
        return { hasError: true, error };
      }
    
      componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, info: errorInfo });
        console.warn('error: ', this.state.error);
      }
    
      render() {
        ${isPreview
            ? `if (this.state.error) {
          parent.postMessage(
            {
              type: 'error',
              error: {
                message: this.state.error.message,
                stack: this.state.error.stack
              }
            },
            '*',
          );
        }`
            : ''}
        return (
          <${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST} error={this.state.error}>{this.props.children}</${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST}>
        );
      }
    }
    
    export default ErrorBoundary;

    `;
    }
}
