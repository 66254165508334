export const GLOBAL_STATE_PROVIDER_CONST = 'GlobalStateProvider';
export const USE_GLOBAL_STATE_CONST = 'useGlobalState';
export const USE_ERROR_BOUNDARY_CONST = 'useErrorBoundary';
export const USE_ERROR_EVENT_CONST = 'useCatchEventErrors';
export const ERROR_BOUNDARY_ROUTER_PROVIDER_CONST = 'ErrorBoundaryRouter';
export const ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST = 'ErrorBoundaryContextProvider';
export const ERROR_BOUNDARY_PROVIDER_CONST = 'ErrorBoundary';
export const ASSETS_PROVIDER_CONST = 'AssetsProvider';
export const USE_ASSETS_CONST = 'useAssets';
export const PATH_PROVIDER_CONST = 'PathProvider';
export const USE_PATH_CONST = 'usePath';
export const ASSETS_STATE_NAME = 'assets';
export const REST = 'rest';
export const ROOT_CONTENT = 'RootContent';
export const RESOURCE_ID = 'resourceID';
export const ERROR_PAGE_ID = 'ErrorPageRootRouteLayout_ID';
