import React from 'react';

import { Typography, TypographyProps } from '@mui/material';

import { AssetFileWithBackendDataDSL, ASSET_TYPES } from '@builder/schemas';

import { CssGrid } from 'src/shared/components';

import { MetaDescriptionText } from './AssetMeta.styles';
import { AssetMetaCommon } from './AssetMetaCommon';

type AssetMetaProps = {
  asset: AssetFileWithBackendDataDSL;
  textAlign?: TypographyProps['align'];
};

export const AssetMeta: React.FC<AssetMetaProps> = ({ asset, textAlign }) => {
  const renderMeta = () => {
    switch (asset?.type) {
      case ASSET_TYPES.image: {
        return (
          <>
            <MetaDescriptionText
              textAlign={textAlign}
              data-test="assets.assetPropertiesPopper.metaData.dimensions"
            >
              Dimensions: {asset?.width} x {asset?.height} px
            </MetaDescriptionText>
          </>
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <CssGrid>
      <Typography noWrap align={textAlign} sx={{ mb: 0.5 }} variant="body1">
        {asset?.name}
      </Typography>
      {renderMeta()}
      <AssetMetaCommon textAlign={textAlign} asset={asset} />
    </CssGrid>
  );
};
