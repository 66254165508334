import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Button } from '@builder/components';

import { BackgroundWrapperPaper } from 'src/dialogs/GlobalDialogs/DeployProgressDialog/components/BackgroundWrapperPaper';

const ErrorPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 1040px;
`;

const ErrorDetailSection = styled.div`
  background: ${({ theme }) => theme.palette.grey[900]};
  min-height: 320px;
  max-height: 420px;
  overflow: auto;
  padding: 5px;
  width: 100%;
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ErrorPage = ({ error }: { error: any }): JSX.Element => {
  return (
    <BackgroundWrapperPaper style={{ height: '100vh' }} backgroundIconColor="red">
      <ErrorPageContainer>
        <Typography variant="h3">Something went wrong</Typography>
        <ErrorDetailSection>
          <pre>
            <code>{JSON.stringify(error, null, 2)}</code>
          </pre>
        </ErrorDetailSection>
        <Button variant="contained" onClick={() => window.close()}>
          Close
        </Button>
      </ErrorPageContainer>
    </BackgroundWrapperPaper>
  );
};

export default ErrorPage;
