var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useState, useEffect } from 'react';
import { Select } from '../Select';
export const Multiselect = forwardRef((_a, ref) => {
    var { multiple, value = [], onChange } = _a, otherMultiselectProps = __rest(_a, ["multiple", "value", "onChange"]);
    const [localValue, setLocalValue] = useState(value);
    const handleChange = (event, children) => {
        const newValue = Array.isArray(event.target.value)
            ? event.target.value
            : [event.target.value];
        setLocalValue(newValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(event, children);
    };
    useEffect(() => {
        setLocalValue(value);
    }, [value]);
    return (_jsx(Select, Object.assign({ ref: ref, multiple: true, value: localValue, onChange: handleChange }, otherMultiselectProps)));
});
