/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { has, isEmpty, values } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_17_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.2';
        this.removeFunctionWrapper = (code) => {
            let handledCode = code;
            let indx = handledCode.indexOf('{');
            indx = handledCode.indexOf('{');
            if (indx > 0) {
                handledCode = handledCode.slice(indx + 4).replace('\n}', '');
            }
            return handledCode;
        };
        this.objectToJson = (appDSL) => {
            return JSON.stringify(appDSL);
        };
        this.jsonToObject = (appDSLJSON) => {
            return JSON.parse(appDSLJSON);
        };
        this.removefunctionExtension = (appDSL) => {
            let appDSLJSON = this.objectToJson(appDSL);
            do {
                appDSLJSON = appDSLJSON.replace(this.getConstants.FUNCTIONEXTENSION, this.getConstants.EMPTYSTRING);
            } while (appDSLJSON.includes(this.getConstants.FUNCTIONEXTENSION));
            return this.jsonToObject(appDSLJSON);
        };
        this.codeHasAwaitExpressions = (code) => {
            if (/await(\s|\()/gm.test(code)) {
                return true;
            }
            return false;
        };
    }
    getChangeList() {
        return [
            'Delete .function extension if an fuction is used',
            'In the functions: Remove "return"',
            'In the functions: Set definition as ""',
            'In the functions: Create bodyFunction',
        ];
    }
    get getConstants() {
        return {
            RETURN: Migration_0_17_2.RETURN,
            BODYFUNCTION: Migration_0_17_2.BODYFUNCTION,
            FUNCTION: Migration_0_17_2.FUNCTION,
            EMPTYSTRING: Migration_0_17_2.EMPTYSTRING,
            FUNCTIONEXTENSION: Migration_0_17_2.FUNCTIONEXTENSION,
            ISASYNCFUNCTION: Migration_0_17_2.ISASYNCFUNCTION,
        };
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        const hasReturn = has(this.getConstants.RETURN);
        const hasBodyFunction = has(this.getConstants.BODYFUNCTION);
        const hasIsAsyncFunction = has(this.getConstants.ISASYNCFUNCTION);
        values(appDSL.states).forEach((state) => {
            var _a;
            if (state.type === this.getConstants.FUNCTION) {
                if (hasReturn(state)) {
                    delete state.return;
                }
                if (!isEmpty(state.arguments)) {
                    (_a = state.arguments) === null || _a === void 0 ? void 0 : _a.forEach(argument => (argument.definition = this.getConstants.EMPTYSTRING));
                }
                if (!hasBodyFunction(state)) {
                    state.bodyFunction = this.removeFunctionWrapper(state.code);
                }
                if (!hasIsAsyncFunction(state)) {
                    if (this.codeHasAwaitExpressions(state.code)) {
                        state.isAsyncFunction = true;
                    }
                }
            }
        });
        const newAppDSL = this.removefunctionExtension(appDSL);
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign({}, newAppDSL) });
    }
}
Migration_0_17_2.RETURN = 'return';
Migration_0_17_2.BODYFUNCTION = 'bodyFunction';
Migration_0_17_2.FUNCTION = 'function';
Migration_0_17_2.FUNCTIONEXTENSION = '.function';
Migration_0_17_2.ISASYNCFUNCTION = 'isAsyncFunction';
Migration_0_17_2.EMPTYSTRING = '';
export const migration_0_17_2 = new Migration_0_17_2();
