import styled from '@emotion/styled';
import { Popover } from '@mui/material';

import {
  LabelPlacementTypes,
  LABEL_PLACEMENT,
  LabelSizeTypes,
  LABEL_SIZE,
} from './ThemeColorPickerInput.constants';

export const StyledButton = styled.button<{
  background?: string;
  labelPlacement?: LabelPlacementTypes;
  fontColor?: string;
}>`
  position: relative;
  border: none;
  border-radius: 0;
  min-height: 105px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: ${({ labelPlacement }) =>
    labelPlacement === LABEL_PLACEMENT.top ? 'start' : 'end'};

  background-color: ${({ background }) => background} !important;
  color: ${({ fontColor }) => fontColor};
  cursor: pointer;
`;

export const ColorLabel = styled.div<{ labelSize: LabelSizeTypes }>`
  font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  font-size: ${({ labelSize }) => (labelSize === LABEL_SIZE.small ? '10px' : '14px')};
  line-height: 16px;
  color: white;
  text-shadow: 0.5px 0.5px black;
`;

export const ColorValue = styled.div`
  font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 10px;
  line-height: 16px;
  color: white;
  text-shadow: 0.5px 0.5px black;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
`;

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    box-shadow: ${({ theme }) => theme.palette.grey[900]};
  }
`;
