import styled from '@emotion/styled';
import { Alert, IconButton, Paper, Typography } from '@mui/material';

export const SnackbarContainer = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  left: 50px;
  &.SnackbarContainer-left {
    left: 50px;
  }
`;

export const StyledAlert = styled(Alert)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  & .MuiAlert-icon {
    padding: 0;
    margin: 0;
    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
`;
export const SnackbarContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;
export const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.grey[500]};
  padding: 0;
  height: fit-content;
  margin-left: ${({ theme }) => theme.spacing(3)};
  & .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
`;
export const StyledTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  max-width: 460px;
  overflow-wrap: break-word;
  color: #abb1b8;
`;

export const StyledTypographyView = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  max-width: 460px;
  overflow-wrap: break-word;
  color: #abb1b8;
  cursor: pointer;
`;

export const StyledTypographyIssue = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  max-width: 460px;
  overflow-wrap: break-word;
  color: #61a5fa;
  cursor: pointer;
`;

export const StyleTypographyIssues = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  max-width: 460px;
  overflow-wrap: break-word;
  color: #61a5fa;
  cursor: pointer;
  flex: 10;
`;

export const StyledTypographyViewAction = styled(Typography)`
  position: relative;
  right: -16px;
  top: 4px;
  overflow-wrap: break-word;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
`;
