import { useCallback } from 'react';

import { STICKY_LEFT_PANEL } from 'src/constant';
import { useAppDispatch, useUIBuilderLeftPanel } from 'src/providers/ReduxProvider';
import { UI_BUILDER_EVENTS } from 'src/store';

type UseToggleStickState = () => void;

export const useToggleStickState = (): UseToggleStickState => {
  const send = useAppDispatch();
  const leftPanel = useUIBuilderLeftPanel();
  localStorage.setItem(
    STICKY_LEFT_PANEL,
    leftPanel?.args?.isAffectOnDashboardLayout ? 'true' : 'false',
  );

  const toggleStickState = useCallback(
    () =>
      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          ...leftPanel,
          args: {
            isAffectOnDashboardLayout: !(leftPanel?.args?.isAffectOnDashboardLayout ?? false),
          },
        },
      }),
    [leftPanel, send],
  );

  return toggleStickState;
};
