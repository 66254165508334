import { useCallback, useEffect, useRef } from 'react';

type Subscriber = () => void;

const RESIZE_EVENT = 'resize';

export const useResizeListener = (subscriber: Subscriber): { unsubscribe: () => void } => {
  const listenerRef = useRef<Subscriber>(subscriber);

  const handleResize = useCallback(() => {
    listenerRef.current();
  }, []);

  const unsubscribe = useCallback(() => {
    window.removeEventListener(RESIZE_EVENT, handleResize);
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener(RESIZE_EVENT, handleResize);

    return () => {
      window.removeEventListener(RESIZE_EVENT, handleResize);
    };
  }, [handleResize]);

  return {
    unsubscribe,
  };
};
