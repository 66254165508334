var _a;
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_TYPES, STATE_BOOLEAN_VARIANTS, STATE_SCOPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialTypography } from '../MaterialTypography';
import { BuilderComponentsSwitchSettings } from './BuilderComponentsSwitch.settings';
export const BuilderComponentsSwitch = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSwitch,
    displayName: 'Switch',
    icon: 'switch',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Switch',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.trueFalse,
                    name: 'switch',
                    defaultValue: false,
                },
            ],
            props: {
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            alias: 'Switch Label',
                            schemaOverride: {
                                interaction: 'only-editable',
                            },
                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Switch',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
                labelPlacement: 'end',
                color: 'primary',
                size: 'medium',
                checked: '{{ __STATES__.switch.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.switch.setValue(event.target.checked)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { checked: { type: COMPONENT_DSL_PROP_TYPES.boolean }, checkedIcon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, title: { type: COMPONENT_DSL_PROP_TYPES.string }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                ],
            }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, labelPlacement: { type: COMPONENT_DSL_PROP_TYPES.enum }, label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, className: { type: COMPONENT_DSL_PROP_TYPES.string }, translate: { type: COMPONENT_DSL_PROP_TYPES.string }, lang: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsSwitchSettings,
};
