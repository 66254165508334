import React from 'react';

import styled from '@emotion/styled';

import { ComponentSettingDSL, ComponentSettingMixedDSL } from '@builder/schemas';

import { InputContainer, InputContainerProps } from 'src/shared/components';

type MixedTypeProps = {
  setting: ComponentSettingMixedDSL;

  children: (args: { settings: ComponentSettingDSL[] }) => React.ReactNode;
} & InputContainerProps;

const MixedTypeItemsContent = styled.div`
  flex: 1;
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(1)};
`;

export const MixedType: React.FC<MixedTypeProps> = ({
  setting,
  children,
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
}) => {
  return (
    <InputContainer
      label={setting.label}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
    >
      <MixedTypeItemsContent>{children({ settings: setting.children })}</MixedTypeItemsContent>
    </InputContainer>
  );
};
