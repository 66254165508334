import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Code as CodeIcon,
  SwapHoriz as SwapHorizIcon,
} from '@mui/icons-material';
import { Divider, Typography, IconButton } from '@mui/material';

import { themeSelectors } from '@builder/schemas';
import { ROOT_SELECTOR, ROOT_SELECTORS_ARRAY, CSSParser } from '@builder/utils';

import { CSSEditorDialogArgs, DASHBOARD_DIALOGS } from 'src/dialogs';
import { useAppDispatch, useAppDSLTheme, useDialogState } from 'src/providers';
import { CssGrid } from 'src/shared/components';
import { getInitialColorValue } from 'src/shared/components/ThemeColorPicker/utils/colors';
import { DASHBOARD_EVENTS } from 'src/store';

import { ThemeColorPickerInput } from './ThemeColorPickerInput';
import {
  DEFAULT_CSS_COLOR_VARIABLES_PROPS,
  DEFAULT_CHANGE_CSS_COLOR_VARIABLES,
  THEME_SETTINGS_SECTIONS,
} from './ThemeManagerColorSettings.constants';
import {
  ThemeIconButtonContent,
  ThemeSettingsContent,
  ThreeColorsPalette,
  PaletteContainer,
} from './ThemeManagerColorSettings.styles';
import { ThemeSettingsSection } from './ThemeSettingsSection';

const PROPERTY_PARTS = ['primary', 'secondary', 'error'];
const MAIN = 'Main';

/**
 * Checks css for the presence of the main color, if there are secondary colors
 *
 * @example
 * ```
 * const cssBody = `
 *    :root {
 *        --primary: "#ffffff";
 *        --secondary: "#000000";
 *    }
 * }
 * isValidCss(cssBody) => true
 *
 * const cssBody = `
 *    :root {
 *        --primary: ;
 *        --secondary: "#000000";
 *    }
 * }
 * isValidCss(cssBody) => false
 * ```
 */
const isValidCss = (css: string): boolean => {
  const cssVariables = CSSParser.getVariablesFromCSSBody(css);

  for (const propertyPart of PROPERTY_PARTS) {
    const cssVariablesKey = `--${propertyPart}${MAIN}`;
    const mainValue = cssVariables[cssVariablesKey];
    // if there is no color, or it is invalid, the function will return undefined, otherwise an object of type Color
    const mainColor = getInitialColorValue(mainValue);
    // pattern checking css variable without the word `Main`
    const regExpPattern = RegExp(`${propertyPart}(?!${MAIN})`);

    if (!mainColor && regExpPattern.test(css)) return false;
  }

  return true;
};

export const ThemeManagerColorSettings: React.FC = () => {
  const send = useAppDispatch();
  const { openDialog } = useDialogState<CSSEditorDialogArgs>();
  const themeDSL = useAppDSLTheme();
  const globalCSS = themeSelectors.getGlobalCSS(themeDSL);
  const [cssBodyRoot, setCssBodyRoot] = useState<string>('');
  const cssVariablesRoot = useMemo(() => CSSParser.getVariablesFromCSSBody(cssBodyRoot), [
    cssBodyRoot,
  ]);

  useEffect(() => {
    setCssBodyRoot(CSSParser.getCSSBodyWithOneRule(globalCSS, ROOT_SELECTORS_ARRAY));
  }, [cssBodyRoot, globalCSS]);

  const updateThemeColorVariablesCSS = useCallback(
    (cssBody: string) => {
      setCssBodyRoot(cssBody);

      if (!isValidCss(cssBody)) return;

      send({
        type: DASHBOARD_EVENTS.themeCSSGlobalSelectorsUpdate,
        cssBody,
        selectors: ROOT_SELECTORS_ARRAY,
      });
    },
    [send],
  );

  const updateSingleThemeColorVariableCSS = useCallback(
    ({ value, propertyName }: { value: string | undefined; propertyName: string }) => {
      const newCSSBody = CSSParser.updateCSSPropertyFromCSSBodyBySelectors({
        cssBody: cssBodyRoot || CSSParser.wrapCSSpropertiesWithSelector(cssBodyRoot, ROOT_SELECTOR),
        value,
        selectors: ROOT_SELECTORS_ARRAY,
        propertyName,
      });

      updateThemeColorVariablesCSS(newCSSBody);
    },
    [cssBodyRoot, updateThemeColorVariablesCSS],
  );

  const onPreviewClick = useCallback(() => {
    openDialog(DASHBOARD_DIALOGS.CSS_EDITOR_DIALOG_ID, {
      label: 'Code View',
      initialValues:
        cssBodyRoot || CSSParser.wrapCSSpropertiesWithSelector(cssBodyRoot, ROOT_SELECTOR),
      onSave: updateThemeColorVariablesCSS,
      dataTest: 'themeColorCSSEditor',
    });
  }, [openDialog, cssBodyRoot, updateThemeColorVariablesCSS]);

  const onChangeThemeClick = useCallback(() => {
    let newCSSBody =
      cssBodyRoot || CSSParser.wrapCSSpropertiesWithSelector(cssBodyRoot, ROOT_SELECTOR);
    DEFAULT_CHANGE_CSS_COLOR_VARIABLES.settings.forEach(setting => {
      newCSSBody = CSSParser.updateCSSPropertyFromCSSBodyBySelectors({
        cssBody: newCSSBody || CSSParser.wrapCSSpropertiesWithSelector(newCSSBody, ROOT_SELECTOR),
        value: cssVariablesRoot[setting.value],
        selectors: ROOT_SELECTORS_ARRAY,
        propertyName: setting.propertyName,
      });
    });
    updateThemeColorVariablesCSS(newCSSBody);
  }, [cssBodyRoot, cssVariablesRoot, updateThemeColorVariablesCSS]);

  return (
    <ThemeSettingsContent>
      <CssGrid
        gridColumnGap={4}
        gridAutoFlow="column"
        gridTemplateColumns="minmax(auto, 800px) 40px"
        justifyContent="space-between"
      >
        <CssGrid gridRowGap={4}>
          <Typography variant="h4">Color</Typography>
          <ThemeSettingsSection {...THEME_SETTINGS_SECTIONS.primaryAndSecondary}>
            <CssGrid gridAutoFlow="column" gridColumnGap={4}>
              <ThreeColorsPalette>
                <ThemeColorPickerInput
                  {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.primaryMain.colorPickerProps}
                  labelIcon={<CheckCircleIcon />}
                  cssVariablesObject={cssVariablesRoot}
                  onChange={updateSingleThemeColorVariableCSS}
                />
                <ThemeColorPickerInput
                  {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.primaryLight.colorPickerProps}
                  cssVariablesObject={cssVariablesRoot}
                  onChange={updateSingleThemeColorVariableCSS}
                />
                <ThemeColorPickerInput
                  {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.primaryDark.colorPickerProps}
                  cssVariablesObject={cssVariablesRoot}
                  onChange={updateSingleThemeColorVariableCSS}
                />
              </ThreeColorsPalette>
              <ThemeIconButtonContent>
                <IconButton onClick={onChangeThemeClick}>
                  <SwapHorizIcon />
                </IconButton>
              </ThemeIconButtonContent>
              <ThreeColorsPalette>
                <ThemeColorPickerInput
                  {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.secondaryMain.colorPickerProps}
                  labelIcon={<CheckCircleIcon />}
                  cssVariablesObject={cssVariablesRoot}
                  onChange={updateSingleThemeColorVariableCSS}
                />
                <ThemeColorPickerInput
                  {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.secondaryLight.colorPickerProps}
                  cssVariablesObject={cssVariablesRoot}
                  onChange={updateSingleThemeColorVariableCSS}
                />
                <ThemeColorPickerInput
                  {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.secondaryDark.colorPickerProps}
                  cssVariablesObject={cssVariablesRoot}
                  onChange={updateSingleThemeColorVariableCSS}
                />
              </ThreeColorsPalette>
            </CssGrid>
          </ThemeSettingsSection>
          <Divider />
          <ThemeSettingsSection {...THEME_SETTINGS_SECTIONS.surfaceBackgroundError}>
            <PaletteContainer>
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.backgroundDefault.colorPickerProps}
                labelIcon={<CheckCircleIcon />}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.backgroundSurface.colorPickerProps}
                labelIcon={<CheckCircleIcon />}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.errorMain.colorPickerProps}
                labelIcon={<ErrorIcon />}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
            </PaletteContainer>
          </ThemeSettingsSection>
          <Divider />
          <ThemeSettingsSection {...THEME_SETTINGS_SECTIONS.typographyIconography}>
            <PaletteContainer>
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.primaryContrastText.colorPickerProps}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.secondaryContrastText.colorPickerProps}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.textBackgroundPrimary.colorPickerProps}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
              <ThemeColorPickerInput
                {...DEFAULT_CSS_COLOR_VARIABLES_PROPS.errorContrastText.colorPickerProps}
                cssVariablesObject={cssVariablesRoot}
                onChange={updateSingleThemeColorVariableCSS}
              />
            </PaletteContainer>
          </ThemeSettingsSection>
        </CssGrid>
        <div>
          <IconButton onClick={onPreviewClick}>
            <CodeIcon />
          </IconButton>
        </div>
      </CssGrid>
    </ThemeSettingsContent>
  );
};
