import React from 'react';

import styled from '@emotion/styled';
import { Help as HelpIcon } from '@mui/icons-material';
import { Tooltip, Box, Typography, TooltipProps, tooltipClasses } from '@mui/material';
import { styled as styledMUI } from '@mui/material/styles';

import { handleSendFullStoryEvent } from '../../fullStory';
import { MESSAGES } from 'src/shared/constants';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

const TitleContainer = styled('div')`
  width: 270px;
  padding: ${({ theme }) => theme.spacing(1)}};
`;

const StyledHelpIcon = styled(HelpIcon)`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 16px;
  padding-top: 2px;
`;

// used styledMUI because styled from emotion can't override MUI theme
export const CustomTooltip = styledMUI(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[900],
  },
}));

const handleFSEvent = (title: string, subtitle: string, workspaceID: string | null) => {
  let source = title;
  const isComponent = Object.values(MESSAGES.dashboard.componentList).find(
    (component: Record<string, unknown>) => component?.subtitleDescription === subtitle,
  );
  const isPage =
    MESSAGES.pagesManager?.leftPanel?.pageList?.subtitleDescription === subtitle ||
    MESSAGES.pagesManager?.leftPanel?.layoutList?.subtitleDescription === subtitle;

  if (isComponent) {
    source = `Components/${title}`;
  }

  if (isPage) {
    source = `Pages/${title}`;
  }

  handleSendFullStoryEvent(`Description tooltip displayed in ${title}`, {
    source,
    defineStr: `The Tooltip displayed in ${source} section`,
    workspaceID,
  });
};

export type DescriptionTooltipWrapperProps = {
  title: string;
  subtitle: string;
  wrapCondition?: boolean;
  icon?: JSX.Element;
  children?: React.ReactNode;
};

export const DescriptionTooltipWrapper: React.FC<DescriptionTooltipWrapperProps> = ({
  title,
  subtitle,
  wrapCondition,
  icon,
  children,
}) => {
  const { workspaceID } = useCurrentWorkspaceID();

  return (
    <Choose>
      <When condition={wrapCondition}>
        <Box display="flex" alignItems="center" alignContent="center">
          <Box mr={1}>{children}</Box>
          <Box>
            <CustomTooltip
              onOpen={() => handleFSEvent(title, subtitle, workspaceID)}
              title={
                <TitleContainer>
                  <Box mb={1}>
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                      {subtitle}
                    </Typography>
                  </Box>
                </TitleContainer>
              }
              placement="right-start"
            >
              {icon || <StyledHelpIcon fontSize="small" />}
            </CustomTooltip>
          </Box>
        </Box>
      </When>
      <Otherwise>{children}</Otherwise>
    </Choose>
  );
};
