import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings } from '../../common';
import { getBackgroundSection, getBorderSection, getCSSSection, getCommonActionListSettings, getCommonActionSettings, getCursorSection, getDivider, getPropertySections, getSizeSection, getTypographySection, } from '../../common/settings';
import { getBuilderComponentsButtonSettings } from '../BuilderComponentsButton/BuilderComponentsButton.settings';
export const getBuilderComponentsSnackbar = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'open'],
                            label: 'open',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'message'],
                            name: 'message',
                            label: 'Message',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            keyPath: ['action'],
                            label: 'Action',
                            name: 'action',
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                                    settings: getBuilderComponentsButtonSettings(),
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'vertical'],
                            label: 'vertical',
                            options: [
                                { label: 'Top', value: 'top' },
                                { label: 'Bottom', value: 'bottom' },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'horizontal'],
                            label: 'horizontal',
                            options: [
                                { label: 'Left', value: 'left' },
                                { label: 'Right', value: 'right' },
                                { label: 'Center', value: 'center' },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autoHideDuration'],
                            label: 'Auto Hide Duration',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disableWindowBlurListener'],
                            label: 'Disable Window Blur Listener',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'key'],
                            label: 'Key',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'resumeHideDuration'],
                            label: 'Resume Hide Duration',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'transitionDuration'],
                            label: 'Transition Duration',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'transition'],
                            label: 'Transition Component',
                            options: [
                                {
                                    label: 'Fade',
                                    value: 'Fade',
                                },
                                {
                                    label: 'Grow',
                                    value: 'Grow',
                                },
                                {
                                    label: 'Slide',
                                    value: 'Slide',
                                },
                                {
                                    label: 'Zoom',
                                    value: 'Zoom',
                                },
                                {
                                    label: 'Collapse',
                                    value: 'Collapse',
                                },
                            ],
                        },
                        getDivider(),
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'role'],
                            name: 'role',
                            label: 'Role',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                getSizeSection(prefixPath),
                getDivider(),
                getTypographySection(prefixPath),
                getDivider(),
                getBackgroundSection(prefixPath),
                getDivider(),
                getBorderSection({ prefixPath, systemPrefixPath: prefixPath }),
                getDivider(),
                getCursorSection(prefixPath),
                getDivider(),
                getCSSSection(prefixPath),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: [...prefixPath, 'onClose'],
                            children: getCommonActionSettings([...prefixPath, 'onClose']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsSnackbarSettings = getBuilderComponentsSnackbar();
