import { COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES, COMPONENT_DSL_PROP_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialListItemIconSettings } from './MaterialListItemIcon.settings';
export const MaterialListItemIcon = {
    name: COMPONENT_DSL_NAMES.MaterialListItemIcon,
    displayName: 'List Item Icon',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'ListItemIcon',
    },
    schema: {
        predefineds: {
            props: {
                style: {
                    minWidth: 'auto',
                },
            },
        },
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            } }),
    },
    settings: MaterialListItemIconSettings,
};
