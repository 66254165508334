import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

import { IconWrapperButton } from 'src/shared/components';

export const IconSettingsContainer = styled.div<{ isActive?: boolean }>`
  margin-left: auto;
  & ${IconWrapperButton} {
    outline: none;
    margin: ${({ theme }) => theme.spacing(0.5)};
    margin-right: 0;
    width: 16px;
    height: 16px;
    :hover {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.text.primary};
        }
      }
    }
  }
`;

export const SettingsListItemContainer = styled.div<{ isActive?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing(4)};
  border: ${({ theme }) => `1px solid ${theme.palette.border.input}`};
  border-radius: 8px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  ${IconSettingsContainer} {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  }
  :hover {
    ${IconSettingsContainer} {
      display: block;
    }
  }
`;

const activeTextCss = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.main};
`;

export const StyledTextIcon = styled.span<{ isActive?: boolean }>`
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  margin-right: 12px;

  ${({ isActive }) => isActive && activeTextCss}
`;

export const IconLabelContainer = styled.div`
  margin-right: 4px;
  width: 16px;
  height: 16px;
  span {
    width: 16px;
    height: 16px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PopupContent = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1.5)};
  width: ${({ theme }) => theme.layout.RightPanel.size}px;
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
  border-radius: 8px;
`;

export const PopupHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  margin: -${({ theme }) => theme.spacing(1.5)};
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const PopupTitle = styled(Typography)`
  font-weight: 600;
`;

export const TypographyStyled = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MaybeDraggablePart = styled.div<{ isDraggable?: boolean; isDragging?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: ${({ isDraggable }) => (isDraggable ? 'ns-resize' : 'unset')};
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  max-width: 250px;
`;
