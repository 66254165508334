import { useCallback, useMemo } from 'react';

import { EventType } from '@8base-private/event-handler';
import { QueryTuple, useLazyQuery, useMutation } from '@apollo/client';
import { nanoid as uuid } from 'nanoid';

import { useCurrentWorkspaceID } from '../../hooks';
import {
  ConfigurationBackupsListQuery,
  ConfigurationBackupsListQueryVariables,
  ConfigurationBackupQuery,
  ConfigurationBackupQueryVariables,
  ConfigurationBackupCreateMutation,
  ConfigurationBackupCreateMutationVariables,
} from '../__generated__';
import {
  CONFIGURATION_BACKUPS_LIST_QUERY,
  CONFIGURATION_BACKUP_QUERY,
  CONFIGURATION_BACKUP_CREATE_MUTATION,
} from '../fragments';
import { useDraftEngine } from 'src/providers';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';

type UseConfigurationBackupHooksReturn = {
  loadConfigurationBackupsList: QueryTuple<
    ConfigurationBackupsListQuery,
    ConfigurationBackupsListQueryVariables
  >[0];
  loadConfigurationBackupsListResult: QueryTuple<
    ConfigurationBackupsListQuery,
    ConfigurationBackupsListQueryVariables
  >[1];
  loadConfigurationBackup: QueryTuple<
    ConfigurationBackupQuery,
    ConfigurationBackupQueryVariables
  >[0];
  loadConfigurationBackupResult: QueryTuple<
    ConfigurationBackupQuery,
    ConfigurationBackupQueryVariables
  >[1];
  createConfigurationBackup: () => Promise<void>;
  creatingConfigurationBackup: boolean;
};

export const useConfigurationBackupHooks = (): UseConfigurationBackupHooksReturn => {
  const { draftEngine } = useDraftEngine();
  const { workspaceID } = useCurrentWorkspaceID();

  const [loadConfigurationBackupsList, loadConfigurationBackupsListResult] = useLazyQuery<
    ConfigurationBackupsListQuery,
    ConfigurationBackupsListQueryVariables
  >(CONFIGURATION_BACKUPS_LIST_QUERY);

  const [loadConfigurationBackup, loadConfigurationBackupResult] = useLazyQuery<
    ConfigurationBackupQuery,
    ConfigurationBackupQueryVariables
  >(CONFIGURATION_BACKUP_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [createConfigurationBackupMutation, { loading: creatingConfigurationBackup }] = useMutation<
    ConfigurationBackupCreateMutation,
    ConfigurationBackupCreateMutationVariables
  >(CONFIGURATION_BACKUP_CREATE_MUTATION, {
    refetchQueries: ['ConfigurationBackupsList'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully created the application backup`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'ConfigurationBackupsList',
        });
      }
    },
  });

  const createConfigurationBackup = useCallback(async () => {
    if (!workspaceID) {
      return;
    }

    await createConfigurationBackupMutation({
      variables: {
        data: {
          workspaceId: workspaceID,
        },
      },
    });
  }, [createConfigurationBackupMutation, workspaceID]);

  const memorizedHook = useMemo(
    () => ({
      loadConfigurationBackupsList,
      loadConfigurationBackupsListResult,
      loadConfigurationBackup,
      loadConfigurationBackupResult,
      createConfigurationBackup,
      creatingConfigurationBackup,
    }),
    [
      createConfigurationBackup,
      creatingConfigurationBackup,
      loadConfigurationBackup,
      loadConfigurationBackupResult,
      loadConfigurationBackupsList,
      loadConfigurationBackupsListResult,
    ],
  );

  return memorizedHook;
};
