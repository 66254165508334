import { useEffect, useState } from 'react';
export const useNumberState = (defaultState = 0) => {
    // CODE_ENGINE_USAGE_START
    const [numberState, setNumberState] = useState(defaultState);
    useEffect(() => {
        setNumberState(defaultState);
    }, [defaultState]);
    return { value: numberState, setValue: setNumberState };
    // CODE_ENGINE_USAGE_END
};
