export const API_STAND_KEY = {
    prod: 'prod',
    staging: 'staging',
    staginguk: 'staginguk',
    pre: 'pre',
    qa1: 'qa1',
    qa2: 'qa2',
    qa3: 'qa3',
    qa4: 'qa4',
};
export const API_STANDS = {
    prod: 'https://api.8base.com',
    staging: 'https://staging-api.8basedev.com',
    staginguk: 'https://uk.staging-api.8basedev.com',
    qa1: 'https://qa-api.8basedev.com',
    qa2: 'https://qa2-api.8basedev.com',
    qa3: 'https://qa3-api.8basedev.com',
    qa4: 'https://qa4-api.8basedev.com',
    pre: 'https://prestaging-api.8basedev.com',
};
export const CONSOLE_STANDS = {
    prod: 'https://api.8base.com',
    staging: 'https://staging-api.8basedev.com',
    staginguk: 'https://uk.staging-api.8basedev.com',
    qa1: 'https://qa-api.8basedev.com',
    qa2: 'https://qa2-api.8basedev.com',
    qa3: 'https://qa3-api.8basedev.com',
    qa4: 'https://qa4-api.8basedev.com',
    pre: 'https://prestaging-api.8basedev.com',
};
