import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { assocSettings, getCommonFormFieldPropertiesSettings, NS_ID, omitSettings, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getCommonFormFieldActionSettings, } from '../../common/settings';
import { getBuilderComponentsRadioGroupSettings } from '../../material-components/BuilderComponentsRadioGroup/BuilderComponentsRadioGroup.settings';
const PREFIX_PATH = ['radioGroupProps'];
const basicBuilderComponentsRadioGroupSettings = getBuilderComponentsRadioGroupSettings(PREFIX_PATH);
export const BuilderComponentsFormRadioGroupSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [
            ...getCommonFormFieldPropertiesSettings(),
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['value'],
                label: 'Value',
            },
            ...settings.children,
        ] }))), omitSettings([[NS_ID.properties.root, NS_ID.properties.basic, 'value']]))(basicBuilderComponentsRadioGroupSettings.properties),
    style: basicBuilderComponentsRadioGroupSettings.style,
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: ['onChange'],
                            children: getCommonActionSettings(['onChange']),
                        },
                        ...getCommonFormFieldActionSettings(),
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
