var _a, _b;
import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { MaterialTypography } from '../MaterialTypography';
import { MaterialAccordionSummarySettings } from './MaterialAccordionSummary.settings';
export const MaterialAccordionSummary = {
    name: COMPONENT_DSL_NAMES.MaterialAccordionSummary,
    displayName: 'Accordion Summary',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'AccordionSummary',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        accessibility: {
            disableCreatingSymbol: true,
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialAccordion,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            alias: 'Accordion Summary Title',
                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Accordion Title',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
                expandIcon: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: Object.assign(Object.assign({}, (_b = BuilderComponentsIcon.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { name: 'ExpandMore', fontSize: 'medium' }),
                            schemaOverride: {
                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                            },
                        },
                    ],
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { expandIcon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            } }),
    },
    settings: MaterialAccordionSummarySettings,
};
