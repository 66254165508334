import { STATE_BOOLEAN_VARIANTS, STATE_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, PRESENTATIONS, COMPONENT_DSL_KINDS, STATE_SCOPES, } from '../../../constants';
import { getCommonSymbolStyleProps, commonComponentProps, commonActionProps, commonReactProps, } from '../../common/commonProps';
import { MaterialDialog } from '../../material-components/MaterialDialog/MaterialDialog';
import { DialogSymbolSettings } from './DialogSymbol.settings';
export const DialogSymbol = {
    name: COMPONENT_DSL_NAMES.DialogSymbol,
    displayName: 'Dialog',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.modal,
    schema: {
        dndTargetPropName: 'children',
        codeFileStructure: {
            componentPath: 'dialogs',
            postfix: 'Component',
        },
        parentContraints: [],
        accessibility: {
            disableCreatingSymbol: true,
            hideOverlayForNonSelectableElement: true,
        },
        presentations: [
            {
                name: PRESENTATIONS.visible,
                props: {
                    dialogProps: {
                        open: true,
                    },
                },
            },
        ],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.global,
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.openClose,
                    name: 'modal',
                    defaultValue: false,
                },
            ],
            props: {
                dialogProps: {
                    open: '{{ __STATES__.modal.isOpened }}',
                    onClose: {
                        actionType: 'customCode',
                        args: {
                            code: '__STATES__.modal.close()',
                        },
                    },
                    fullWidth: true,
                    maxWidth: 'sm',
                },
                titleProps: {
                    title: 'Dialog Title',
                },
            },
        },
        props: Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, dialogProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: MaterialDialog.schema.props,
                required: true,
            }, titleProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    title: { type: COMPONENT_DSL_PROP_TYPES.string },
                },
            } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialDialog,
            props: Object.assign(Object.assign({}, getCommonSymbolStyleProps(['dialogProps'], true)), { 'aria-describedby': '{{ symbolProps.dialogProps["aria-describedby"] }}', 'aria-labelledby': '{{ symbolProps.dialogProps["aria-labelledby"] }}', disableEscapeKeyDown: '{{ symbolProps.dialogProps.disableEscapeKeyDown }}', fullScreen: '{{ symbolProps.dialogProps.fullScreen }}', fullWidth: '{{ symbolProps.dialogProps.fullWidth }}', maxWidth: '{{ symbolProps.dialogProps.maxWidth }}', onBackdropClick: '{{ symbolProps.dialogProps.onBackdropClick }}', onClose: '{{ symbolProps.dialogProps.onClose }}', open: '{{ symbolProps.dialogProps.open }}', PaperProps: '{{ symbolProps.dialogProps.PaperProps }}', scroll: '{{ symbolProps.dialogProps.scroll }}', transitionDuration: '{{ symbolProps.dialogProps.transitionDuration }}', TransitionProps: '{{ symbolProps.dialogProps.TransitionProps }}', draggable: '{{ symbolProps.draggable }}', children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialDialogTitle,
                            condition: {
                                showIf: '{{ symbolProps.titleProps?.title }}',
                            },
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: '{{ symbolProps.titleProps?.title }}',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            name: COMPONENT_DSL_NAMES.MaterialDialogContent,
                            props: {
                                children: '{{ symbolProps.children }}',
                            },
                        },
                    ],
                } }),
        },
    },
    settings: DialogSymbolSettings,
};
