var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { transformGqlActionName } from '@builder/utils';
// For some reasons without this we have runtime error
// related:
// - https://github.com/dotansimha/graphql-code-generator/issues/3665
// - if we will have some issue with SSR
// - https://github.com/dotansimha/graphql-code-generator/blob/9c5cf7bc103915fd6377b37d4c0ce04ac71fae67/website/src/pages/index.js#L3
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
process.hrtime = () => null;
export class GqlTypesGenerator {
    constructor(schema) {
        this.getDocumentFromStateDSL = (stateDSL) => {
            const name = transformGqlActionName(stateDSL.name);
            if (stateDSL.args.body.match(/^query {/)) {
                return stateDSL.args.body.replace(/^query {/, `query ${name} {`);
            }
            if (stateDSL.args.body.match(/^{/)) {
                return stateDSL.args.body.replace(/^{/, `query ${name}{`);
            }
            if (stateDSL.args.body.match(/^query \w+/)) {
                return stateDSL.args.body.replace(/^query \w+/, `query ${name}`);
            }
            if (stateDSL.args.body.match(/^mutation {/)) {
                return stateDSL.args.body.replace(/^mutation {/, `mutation ${name} {`);
            }
            if (stateDSL.args.body.match(/^{/)) {
                return stateDSL.args.body.replace(/^{/, `mutation ${name}{`);
            }
            if (stateDSL.args.body.match(/^mutation \w+/)) {
                return stateDSL.args.body.replace(/^mutation \w+/, `mutation ${name}`);
            }
            return stateDSL.args.body;
        };
        this.addGqlState = (stateDSL) => {
            const document = this.getDocumentFromStateDSL(stateDSL);
            this.documentList.push(document);
        };
        this.generateTypes = () => __awaiter(this, void 0, void 0, function* () {
            const [{ codegen }, { parse, printSchema }, tsPlugin, tsOperationPlugin] = yield Promise.all([
                import('@graphql-codegen/core').then(m => ('default' in m ? m.default : m)),
                import('graphql').then(m => ('default' in m ? m.default : m)),
                import('@graphql-codegen/typescript'),
                import('@graphql-codegen/typescript-operations'),
            ]);
            const schemaStr = printSchema(this.schema);
            const schema = parse(schemaStr);
            const documents = this.documentList.map(el => {
                return { document: parse(el) };
            });
            const types = yield codegen({
                filename: 'generated.ts',
                schema,
                documents,
                skipDocumentsValidation: true,
                plugins: [{ typescript: {} }, { 'typescript-operation': {} }],
                config: {},
                pluginMap: {
                    typescript: tsPlugin,
                    'typescript-operation': tsOperationPlugin,
                },
            });
            this.generatedTypes = types;
            return types;
        });
        this.schema = schema;
        this.documentList = [];
    }
}
