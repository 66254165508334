import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsRichtextEditorSettings } from './BuilderComponentsRichtextEditor.settings';
export const BuilderComponentsRichtextEditor = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRichtextEditor,
    displayName: 'Rich Text Editor',
    icon: 'richtexteditor',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'RichtextEditor',
    },
    schema: {
        presentations: [visibleByCondition],
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsRichtextEditor,
                },
            },
        ],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'richtextEditor',
                    defaultValue: '',
                },
            ],
            props: {
                editorType: 'Classic',
                data: '{{ __STATES__.richtextEditor.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.richtextEditor.setValue(editor.getData())',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { editorType: { type: COMPONENT_DSL_PROP_TYPES.string }, data: { type: COMPONENT_DSL_PROP_TYPES.string }, onReady: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'editor',
                        path: [0],
                    },
                ],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'editor',
                        path: [1],
                    },
                ],
            }, onFocus: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'editor',
                        path: [1],
                    },
                ],
            }, onBlur: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'editor',
                        path: [1],
                    },
                ],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'error',
                        path: [0],
                    },
                ],
            }, id: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, config: { type: COMPONENT_DSL_PROP_TYPES.object }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsRichtextEditorSettings,
};
