import { HeadingTagType } from '@lexical/rich-text';
import { TextFormatType } from 'lexical';

export const PARAGRAPH = 'paragraph';
export const HEADINGS_FORMATS: Record<string, HeadingTagType> = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};
export const TEXT_FORMATS: Record<string, TextFormatType> = {
  BOLD: 'bold',
  ITALIC: 'italic',
};

export const CUSTOM_TEXT_FORMATS: Record<string, 'ul' | 'ol' | 'quote' | 'link'> = {
  UL: 'ul',
  OL: 'ol',
  QUOTE: 'quote',
  LINK: 'link',
};
