var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Switch as MaterialSwitch, } from '@mui/material';
// why should we use such a weird styles there?
// by default Material-UI has negative marginLeft value and this component falls out its parent
// on the left side if it has the prop "size" equals to "small"
// so to avoid such weird behaviour we set marginLeft equals to "-6px"
const SMALL_SIZE_STYLES = {
    marginLeft: '-6px',
};
export const Switch = forwardRef((_a, ref) => {
    var { id, title, translate, disabled, className, style, css, error, helperText, variant, size, name, lang, checked, onChange, label: initLabel = '', labelPlacement = 'end', 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, otherSwitchProps = __rest(_a, ["id", "title", "translate", "disabled", "className", "style", "css", "error", "helperText", "variant", "size", "name", "lang", "checked", "onChange", "label", "labelPlacement", 'data-test', 'data-node-id', 'data-node-render-path']);
    const label = Array.isArray(initLabel) ? initLabel[0] : initLabel;
    return (_jsxs(FormControl, Object.assign({ size: size, disabled: disabled, className: className, style: style, css: css, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, title: title, id: id, lang: lang, translate: translate }, { children: [_jsx(FormControlLabel, { disabled: disabled, label: label, labelPlacement: labelPlacement, name: name, disableTypography: true, sx: size === 'small' ? SMALL_SIZE_STYLES : undefined, control: _jsx(MaterialSwitch, Object.assign({}, otherSwitchProps, { size: size, checked: checked !== null && checked !== void 0 ? checked : false, onChange: onChange })) }), helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
