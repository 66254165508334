import React, { SyntheticEvent, useCallback, useMemo } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Grid, Typography } from '@mui/material';
import { isEmpty, equals, pick, pickBy } from 'ramda';

import {
  ASSET_TYPES,
  AssetID,
  COMPONENT_DSL_NAMES,
  COMPONENT_SETTING_VIEWS,
  ComponentSettingPropDSL,
  componentSettingSelectors,
  ComponentSettingSelectPropDSL,
  createSelectOptionByValue,
  NodeID,
  nodeListSelectors,
  NodePropValue,
  PossibleUnitTypes,
  PropAssert,
  PropChecker,
  ResourceBackendEightBaseWithWorkspaceDSL,
  ResourceListDSL,
  StateFunctionDSL,
  stateListSelectors,
} from '@builder/schemas';
import { FIELD_TYPE_OPTIONS } from '@builder/schemas/dist/mjs/component-schemas/common';
import { isNil, memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { ValidationListItem } from '../../node-settings-generator/NodeSettingsGenerator';
import {
  CodeViewEditor,
  JSONViewEditor,
  TextViewEditor,
  TextAreaViewEditor,
  NumberViewEditor,
  CheckboxViewEditor,
  SelectViewEditor,
  CSSViewEditor,
  ColorPickerViewEditor,
  IconViewEditor,
  SpacingViewEditor,
  RadioViewEditor,
  AssetViewEditor,
  SPACING_TYPES,
  FunctionDynamicSelect,
} from '../../setting-views';
import { LabelWithTooltip } from '../../setting-views/AssetViewEditor/AssetViewEditor.styles';
import { TimePickerTooltip } from '../../setting-views/AssetViewEditor/components';
import { TabTooltip } from '../../setting-views/AssetViewEditor/components/TabTooltip';
import { DateFieldViewEditor } from '../../setting-views/DateFieldViewEditor';
import { TimeFieldViewEditor } from '../../setting-views/TimeFieldViewEditor';
import { CHECKBOX, CHECKMARKS, CHIP, DEFAULT } from '../../utils/constants';
import { showGoComponentButton } from '../../utils/multiselect/showGoComponentButton';
import { multiselectsValidations } from '../../utils/multiselect/validations';
import {
  useAppDSLStates,
  useAppDispatch,
  useNodeListDSL,
  useResourceListDSL,
} from 'src/providers/ReduxProvider';
import { Button, CssGrid, Icon, AutocompleteOption } from 'src/shared/components';
import { AutocompleteActionContainer } from 'src/shared/components/Autocomplete/Autocomplete.styles';
import { useAssetsHooks } from 'src/shared/graphql/hooks';
import { DASHBOARD_EVENTS } from 'src/store';
import { getTargetNodeID } from 'src/store/dashboard/utils';

const UNORDERED_LIST = 'Unordered List';
const VALUE = 'Value';
type PropTypeProps = {
  /**
   * Setting which determines element to render.
   */
  setting: ComponentSettingPropDSL;
  /**
   * Prefix to add before each data-test attribute.
   */
  dataTestPrefix?: string;
  /**
   * The value that is stored in the DSL by the corresponding keyPath.
   */
  keyValue: NodePropValue | null;
  /**
   * Callback to update keyValue.
   */
  validationList?: ValidationListItem;
  /**
   * Used when parent is a validation field list.
   */
  onChange: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  /**
   * Callback to update several keyValue.
   */
  onChangeMany: (
    propDataArray: {
      keyValue: unknown;
      keyPath: (string | number)[];
    }[],
  ) => void;
};

/**
 * Renders appropriate input field depends on component setting.
 */
export const PropType = memo(
  'PropType',
  ({
    setting,
    keyValue,
    dataTestPrefix = '',
    validationList = {
      name: undefined,
      type: undefined,
      validation: [],
    },
    onChange,
    onChangeMany,
  }: PropTypeProps): JSX.Element | null => {
    const send = useAppDispatch();
    const { selectedNodeDSL } = useNodeSettingsProps();
    const nodeID = selectedNodeDSL?.id;
    const nodeListDSL = useNodeListDSL();
    const nodePath = componentSettingSelectors.getSettingsNodePath(setting);
    const keyName = componentSettingSelectors.getSettingsKeyNamePath(setting);
    const isPropRequired = componentSettingSelectors.isRequired(setting);
    const dataTest = `${dataTestPrefix}.${keyName}`;
    const {
      icon,
      isTextIcon,
      label = '',
      resetPathBeforeOnChange,
    } = setting as ComponentSettingPropDSL & {
      icon?: string;
      isTextIcon?: boolean;
      resetPathBeforeOnChange?: Array<string | number>[];
    };
    const userAppStates = useAppDSLStates();
    const customStatesArray = stateListSelectors.getCustomStateArrayDSL(userAppStates);
    const resources = useResourceListDSL();
    const { assetFileArray } = useAssetsHooks();
    const targetNodeId = getTargetNodeID({
      nodeID,
      nodePath,
      nodeListDSL,
    });
    const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: targetNodeId });
    const { name: listName, type: listType, validation: listValidation } = validationList;

    const { functionStateArrayDSL } = useMemo(() => {
      return {
        functionStateArrayDSL: {
          local: stateListSelectors.getFunctionLocalStateArrayDSL(userAppStates),
          global: stateListSelectors.getFunctionGlobalStateArrayDSL(userAppStates),
        },
      };
    }, [userAppStates]);

    const updateProp = useCallback(
      (value: unknown) => {
        if (isPropRequired && (isNil(value) || value === '')) {
          return;
        }

        const assetID =
          value && String(value) ? (value as { assetID: AssetID }).assetID : undefined;

        const filteredData = assetFileArray.filter(item => item.id === assetID);
        const filteredHeight = filteredData[0]?.height;
        const filteredWidth = filteredData[0]?.width;

        const filteredImageType = filteredData.filter(
          item => item.type === 'other' || item.type === 'svg',
        );

        const filteredImageWithNoDimensions =
          filteredImageType[0]?.mimeType === 'image/gif' ||
          filteredImageType[0]?.mimeType === 'image/svg+xml';

        const targetNodeDslImage =
          targetNodeDSL.name === 'MaterialCardMedia' && filteredData.length > 0;
        const isImageSetting = setting.name === ASSET_TYPES.image;

        if (resetPathBeforeOnChange) {
          const propsToReset = resetPathBeforeOnChange.map(resetPath => ({
            keyPath: resetPath,
            keyValue: undefined,
          }));
          onChangeMany(propsToReset);
        }

        if (value === '') {
          onChange({
            keyValue: undefined,
            keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
          });

          if (isImageSetting) {
            onChange({
              keyValue: undefined,
              keyPath: ['style', 'height'],
            });

            onChange({
              keyValue: undefined,
              keyPath: ['style', 'width'],
            });
          }

          return;
        }

        if (targetNodeDslImage && isImageSetting) {
          onChange({
            keyValue: `${filteredHeight}px`,
            keyPath: ['style', 'height'],
          });

          onChange({
            keyValue: `${filteredWidth}px`,
            keyPath: ['style', 'width'],
          });
        }

        if (filteredImageWithNoDimensions && isImageSetting) {
          onChange({
            keyValue: '',
            keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
          });

          onChange({
            keyValue: '',
            keyPath: ['style', 'height'],
          });

          onChange({
            keyValue: '',
            keyPath: ['style', 'width'],
          });
        }

        onChange({
          keyValue: value,
          keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
        });
      },
      [
        assetFileArray,
        isPropRequired,
        onChange,
        onChangeMany,
        resetPathBeforeOnChange,
        setting,
        targetNodeDSL.name,
      ],
    );

    const updatePropMany = useCallback(
      (propDataArray: Parameters<typeof onChangeMany>[0]) => {
        const isSomeValueIsEmpty = propDataArray.some(
          ({ keyValue: currentKeyValue }) => isNil(currentKeyValue) || currentKeyValue === '',
        );

        if (isPropRequired && isSomeValueIsEmpty) {
          return;
        }

        if (resetPathBeforeOnChange) {
          const propsToReset = resetPathBeforeOnChange.map(resetPath => ({
            keyPath: resetPath,
            keyValue: undefined,
          }));
          onChangeMany(propsToReset);
        }

        const propDataArrayWithFullKeyPath = propDataArray.map(
          ({ keyValue: curretKeyValue, keyPath }) => ({
            keyValue: curretKeyValue === '' ? undefined : curretKeyValue,
            keyPath: [...componentSettingSelectors.getSettingsKeyPath(setting), ...keyPath],
          }),
        );

        onChangeMany(propDataArrayWithFullKeyPath);
      },
      [isPropRequired, onChangeMany, resetPathBeforeOnChange, setting],
    );
    const isfieldValidationTypeSelect = equals(
      (setting as ComponentSettingSelectPropDSL).options,
      FIELD_TYPE_OPTIONS.map(createSelectOptionByValue),
    );

    const isActionOrType = useMemo(
      () =>
        isfieldValidationTypeSelect
          ? false
          : setting.label === 'Action' ||
            (setting.label === 'Type' && setting.keyPath[0] !== 'type'),
      [isfieldValidationTypeSelect, setting.label, setting.keyPath],
    );

    switch (setting.componentView) {
      case COMPONENT_SETTING_VIEWS.selectEightBaseAddedWorkspaces: {
        PropAssert.Value.assertIsStringOrNumberProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        const eightBaseWorkspaces = pickBy<
          ResourceListDSL,
          ResourceBackendEightBaseWithWorkspaceDSL[]
        >((val, _) => val?.workspaceID?.length, resources);

        const options = Object.entries(eightBaseWorkspaces).map(e => ({
          label: e[1].name,
          value: `${e[1].workspaceID}_${e[1].environment}`,
        }));

        return (
          <Grid item xs={12}>
            <SelectViewEditor
              noOptionsText="No 8base backend Workspaces specified"
              label={label}
              propValue={keyValue}
              onChangePropValue={updateProp}
              nodeID={nodeID}
              options={options as Array<AutocompleteOption<string | number>>}
              required
              data-test={dataTest}
              showFx={false}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.select: {
        PropAssert.Value.assertIsStringOrNumberProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        const buildRatingOptios = (max: string) => {
          const ratingOptions = [{ value: 0.5, label: '0.5p' }];
          for (let i = 1; i <= parseInt(max, 10); i++) {
            ratingOptions.push({ value: i, label: `${i.toString()}p` });
          }

          return ratingOptions;
        };

        const ratingProps: string | undefined =
          nodeListDSL[nodeID].name === COMPONENT_DSL_NAMES.BuilderComponentsRating
            ? nodeListDSL[nodeID].props.max?.toString()
            : undefined;

        const options =
          ratingProps && setting.keyPath.includes('precision')
            ? buildRatingOptios(ratingProps)
            : setting.options
                .filter(
                  option =>
                    componentSettingSelectors.isShowingSetting(option.showIf, {
                      sourceEntity: selectedNodeDSL,
                      nodeListDSL,
                    }) && !(option.value === 'updateState' && isEmpty(customStatesArray)),
                )
                .map(({ value, label: optionLabel }) => ({ value, label: optionLabel }));

        const getRemoveActionFunction = () => {
          if (isActionOrType) {
            const keyPathAsString = setting?.keyPath?.join();

            if (keyPathAsString.startsWith('selectProps,')) {
              onChange({ keyValue: undefined, keyPath: [setting.keyPath[0], setting.keyPath[1]] });
              return;
            }

            if (keyPathAsString === 'fieldProps,validate,actionType') {
              onChange({ keyPath: ['fieldProps', 'validate'], keyValue: undefined });
              return;
            }

            onChange({ keyValue: undefined, keyPath: [setting.keyPath[0]] });
          }
        };

        const selectNode = (childrenNodeID: NodeID) => (event: SyntheticEvent) => {
          event.stopPropagation();
          send({
            type: DASHBOARD_EVENTS.componentSelect,
            id: childrenNodeID,
          });
        };

        let selectKeyValue = keyValue;

        // Validation to display the button that takes the user to the Chip or CheckBox component
        // in the Behavior property of MultiSelects components
        const {
          isBuilderComponentsMultiselect,
          isBuilderComponentsFormMultiselect,
        } = multiselectsValidations(selectedNodeDSL, label, keyValue);

        if (
          (isBuilderComponentsMultiselect || isBuilderComponentsFormMultiselect) &&
          selectKeyValue === undefined
        ) {
          selectKeyValue = DEFAULT;
        }

        const { show: showComponent, componentID: componentToShowID } = showGoComponentButton(
          selectedNodeDSL,
          label,
          keyValue,
          nodeListDSL,
        );
        return (
          <Grid item xs={12}>
            <Choose>
              <When condition={isActionOrType}>
                <AutocompleteActionContainer>
                  <SelectViewEditor
                    label={label}
                    propValue={keyValue}
                    onChangePropValue={updateProp}
                    nodeID={nodeID}
                    options={options as Array<AutocompleteOption<string | number>>}
                    required={setting.required}
                    data-test={dataTest}
                    showFx={setting.hasFxButton}
                    actionEventDesign={isActionOrType}
                  />
                  <DeleteOutlinedIcon
                    onClick={() => {
                      getRemoveActionFunction();
                    }}
                    titleAccess="Remove Event"
                  />
                </AutocompleteActionContainer>
              </When>
              <Otherwise>
                <>
                  <SelectViewEditor
                    label={label}
                    propValue={selectKeyValue}
                    onChangePropValue={updateProp}
                    nodeID={nodeID}
                    options={options as Array<AutocompleteOption<string | number>>}
                    required={setting.required}
                    data-test={dataTest}
                    disabled={listValidation ? !isEmpty(listValidation) : false}
                    showFx={isfieldValidationTypeSelect ? false : setting.hasFxButton}
                  />
                  {/* For MultiSelects components in the Behavior property */}
                  {showComponent && (
                    <Button
                      endIcon={<Icon width={16} height={16} name="openComponent" />}
                      onClick={selectNode(componentToShowID)}
                    >
                      {`Go to ${keyValue === CHECKMARKS ? CHECKBOX : CHIP} Component`}
                    </Button>
                  )}
                </>
              </Otherwise>
            </Choose>
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.functionDynamicSelect: {
        PropAssert.Value.assertIsStringOrNumberProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        const createFunctionListOptions = (value: StateFunctionDSL) => ({
          value: value.name,

          label: value.name,
        });

        const globalFunctions = functionStateArrayDSL.global.map(createFunctionListOptions);
        const localFunctions = functionStateArrayDSL.local.map(createFunctionListOptions);
        const options = [...globalFunctions, ...localFunctions];

        return (
          <Grid item xs={12}>
            <FunctionDynamicSelect
              label={label}
              onChangePropValue={updateProp}
              nodeID={nodeID}
              options={options}
              required={setting.required}
              data-test={dataTest}
              showFx={false}
              setting={setting}
              keyValue={keyValue}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.validationListValue: {
        switch (listType) {
          case 'number':
            return (
              <Grid item xs={12}>
                <NumberViewEditor
                  label={label}
                  propValue={Number(keyValue) || undefined}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                />
              </Grid>
            );
          case 'date':
            return (
              <Grid item xs={12}>
                <DateFieldViewEditor
                  label={label}
                  propValue={keyValue as string}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  required={setting.required}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                />
              </Grid>
            );
          case 'time':
            return (
              <Grid item xs={12}>
                <TimeFieldViewEditor
                  label={label}
                  propValue={keyValue as string}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  required={setting.required}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                />
              </Grid>
            );
          case 'boolean':
            PropAssert.Value.assertIsBooleanProp(
              !!keyValue,
              componentSettingSelectors.getSettingsKeyNamePath(setting),
              { allowJSInjection: true, allowEmpty: true },
            );
            return (
              <Grid item container xs={12}>
                <CheckboxViewEditor
                  label={label}
                  propValue={!!keyValue}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                />
              </Grid>
            );
          case 'string':
          default:
            PropAssert.Value.assertIsStringOrNumberProp(
              String(keyValue),
              componentSettingSelectors.getSettingsKeyNamePath(setting),
              { allowJSInjection: true, allowEmpty: true },
            );

            return (
              <Grid item xs={12}>
                <TextViewEditor
                  targetNodeId={targetNodeId}
                  label={label}
                  icon={icon}
                  isTextIcon={isTextIcon}
                  propValue={keyValue ? String(keyValue) : ''}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  possibleUnits={setting.possibleUnits}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                  updateOnBlur={!!listName}
                />
              </Grid>
            );
        }
      }

      case COMPONENT_SETTING_VIEWS.text: {
        PropAssert.Value.assertIsStringOrNumberProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        const { skipDebounce, possibleUnits, hasFxButton } = setting as ComponentSettingPropDSL & {
          possibleUnits?: PossibleUnitTypes[];
          skipDebounce?: boolean;
          hasFxButton?: boolean;
        };
        const parsedValue = keyValue === undefined ? undefined : String(keyValue);

        return (
          <>
            <If condition={label !== 'Max Time' && label !== 'Min Time'}>
              <Grid item xs={12}>
                <TextViewEditor
                  targetNodeId={targetNodeId}
                  label={label}
                  icon={icon}
                  isTextIcon={isTextIcon}
                  propValue={parsedValue}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  possibleUnits={possibleUnits}
                  data-test={dataTest}
                  skipDebounce={skipDebounce}
                  updateOnBlur={!!listName}
                  showFx={hasFxButton}
                />
              </Grid>
            </If>
            <If condition={label === 'Max Time' || label === 'Min Time'}>
              <CssGrid gap={0.5}>
                <LabelWithTooltip>
                  <Typography variant="body1">{label}</Typography>
                  <TimePickerTooltip />
                </LabelWithTooltip>
                <TextViewEditor
                  targetNodeId={targetNodeId}
                  icon={icon}
                  isTextIcon={isTextIcon}
                  propValue={parsedValue}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  possibleUnits={possibleUnits}
                  data-test={dataTest}
                  skipDebounce={skipDebounce}
                />
              </CssGrid>
            </If>
          </>
        );
      }

      case COMPONENT_SETTING_VIEWS.textArea: {
        PropAssert.Value.assertIsStringProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        const { skipDebounce } = setting;

        return (
          <Grid item xs={12}>
            <TextAreaViewEditor
              label={label}
              propValue={keyValue}
              onChangePropValue={updateProp}
              data-test={dataTest}
              skipDebounce={skipDebounce}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.number: {
        if (listType === 'date') {
          return (
            <Grid item xs={12}>
              <DateFieldViewEditor
                label={label}
                propValue={keyValue as string}
                onChangePropValue={updateProp}
                nodeID={nodeID}
                required={setting.required}
                data-test={dataTest}
                showFx={setting.hasFxButton}
              />
            </Grid>
          );
        }

        PropAssert.Value.assertIsNumberProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        const { skipDebounce } = setting;
        const parentID: string = nodeListDSL[nodeID].parentID || '';
        const parentIsUnorderedList =
          nodeListDSL[parentID].name === UNORDERED_LIST && label === VALUE;

        return (
          <>
            <If condition={label !== 'Tab Index' && !parentIsUnorderedList}>
              <Grid item xs={12}>
                <NumberViewEditor
                  label={label}
                  propValue={keyValue}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                  skipDebounce={skipDebounce}
                />
              </Grid>
            </If>
            <If condition={parentIsUnorderedList}>
              <Grid item xs={12}>
                <NumberViewEditor
                  label={`${label} (No available in an unordered list)`}
                  propValue={keyValue}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  data-test={dataTest}
                  showFx={false}
                  disabled
                  skipDebounce={skipDebounce}
                />
              </Grid>
            </If>
            <If condition={label === 'Tab Index'}>
              <CssGrid gap={0.5}>
                <LabelWithTooltip>
                  <Typography variant="body1">{label}</Typography>
                  <TabTooltip />
                </LabelWithTooltip>
                <NumberViewEditor
                  propValue={keyValue}
                  onChangePropValue={updateProp}
                  nodeID={nodeID}
                  data-test={dataTest}
                  showFx={setting.hasFxButton}
                  skipDebounce={skipDebounce}
                />
              </CssGrid>
            </If>
          </>
        );
      }

      case COMPONENT_SETTING_VIEWS.checkbox: {
        const booleanValue = keyValue === '' ? !!keyValue : keyValue;

        PropAssert.Value.assertIsBooleanProp(
          booleanValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        return (
          <Grid item container xs={12}>
            <CheckboxViewEditor
              label={label}
              propValue={booleanValue}
              onChangePropValue={updateProp}
              nodeID={nodeID}
              data-test={dataTest}
              showFx={setting.hasFxButton}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.jsonEditor: {
        const { skipDebounce, passInvalidData } = setting;

        return (
          <Grid item xs={12}>
            <JSONViewEditor
              label={label}
              propValue={keyValue as Record<string, unknown>}
              onChangePropValue={updateProp}
              nodeID={nodeID}
              skipDebounce={skipDebounce}
              passInvalidData={passInvalidData}
              data-test={dataTest}
              showFx={setting.hasFxButton}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.jsEditor: {
        if (PropChecker.Value.isNotEmptyProp(keyValue)) {
          PropAssert.Value.assertIsCallbackCodeProp(
            keyValue,
            componentSettingSelectors.getSettingsKeyNamePath(setting),
            { allowJSInjection: true, allowEmpty: true },
          );
        }

        const { defaultValue } = setting;

        return (
          <Grid item xs={12}>
            <CodeViewEditor
              codePropValue={keyValue}
              defaultValue={defaultValue}
              onChangeCode={updateProp}
              nodeID={nodeID}
              label={label}
              data-test={dataTest}
              showFx={setting.hasFxButton}
              keyName={keyName}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.cssEditor: {
        const stringifiedCssValue = keyValue || '';

        if (PropChecker.Value.isNotEmptyProp(stringifiedCssValue)) {
          PropAssert.Value.assertIsCSSProp(
            stringifiedCssValue,
            componentSettingSelectors.getSettingsKeyNamePath(setting),
            { allowJSInjection: true, allowEmpty: true },
          );
        }

        return (
          <Grid item xs={12}>
            <CSSViewEditor
              title={label}
              value={stringifiedCssValue}
              onChange={updateProp}
              data-test={dataTest}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.colorEditor: {
        const stringifiedCssValue = keyValue || '';

        if (PropChecker.Value.isNotEmptyProp(stringifiedCssValue)) {
          PropAssert.Value.assertIsStringProp(
            stringifiedCssValue,
            componentSettingSelectors.getSettingsKeyNamePath(setting),
            { allowJSInjection: true, allowEmpty: true },
          );
        }

        const { skipDebounce } = setting;

        return (
          <Grid item xs={12}>
            <ColorPickerViewEditor
              label={label}
              value={stringifiedCssValue}
              onChange={updateProp}
              nodeID={nodeID}
              data-test={dataTest}
              showFx={setting.hasFxButton}
              skipDebounce={skipDebounce}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.iconEditor: {
        const stringifiedIconName = keyValue || '';

        if (PropChecker.Value.isNotEmptyProp(stringifiedIconName)) {
          PropAssert.Value.assertIsStringProp(
            stringifiedIconName,
            componentSettingSelectors.getSettingsKeyNamePath(setting),
            { allowJSInjection: true, allowEmpty: true },
          );
        }

        return (
          <Grid item xs={12}>
            <IconViewEditor
              value={stringifiedIconName}
              nodeID={nodeID}
              onChange={updateProp}
              data-test={dataTest}
              customIcon={targetNodeDSL?.props?.customIcon as { assetID: string } | string}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.spacingEditor: {
        if (PropChecker.Value.isNotEmptyProp(keyValue)) {
          PropAssert.Value.assertIsObjectProp(
            keyValue,
            componentSettingSelectors.getSettingsKeyNamePath(setting),
          );
        }

        const { skipDebounce, disablePaddings } = setting;
        const values = pick(Object.keys(SPACING_TYPES), keyValue || {});

        return (
          <Grid item xs={12}>
            <SpacingViewEditor
              propValue={values as React.CSSProperties}
              onChangeMany={updatePropMany}
              data-test={dataTest}
              skipDebounce={skipDebounce}
              disablePaddings={disablePaddings}
              showFx={false}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.radio: {
        PropAssert.Value.assertIsStringProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowJSInjection: true, allowEmpty: true },
        );

        return (
          <Grid item xs={12}>
            <RadioViewEditor
              label={label}
              propValue={keyValue}
              onChangePropValue={updateProp}
              data-test={dataTest}
              options={setting.options}
            />
          </Grid>
        );
      }

      case COMPONENT_SETTING_VIEWS.assetEditor: {
        // TODO: add prop checker in https://8base-dev.atlassian.net/browse/APB-903
        const assetID = (keyValue as { assetID: string })?.assetID as string | undefined;

        return (
          <AssetViewEditor
            label={label}
            data-test={dataTest}
            propValue={keyValue as { assetID: string } | string}
            assetID={assetID}
            updateProp={updateProp}
            showFx={setting.hasFxButton}
          />
        );
      }

      default:
        return null;
    }
  },
);
