import { XYCoord } from 'react-dnd';

import { NodeDSL, NodeListDSL, nodeSelectors, ComponentListDSL } from '@builder/schemas';

import { DndSpec, DraggableOverlayOrIconItem } from '../shared/types';
import { MOVE_VARIANT, DND_TARGET_TYPES } from 'src/store';

/**
 * If slot is actually available, there still might already be children inside
 * and if that's the case, we want to switch move-type to be relative to a closest child
 */
export const buildDndSpecRelativelyToTargetChildren = ({
  targetNodeDSL: targetNode,
  item,
  nodeListDSL,
  cursorPosition,
  componentListDSL,
}: {
  targetNodeDSL: NodeDSL;
  item: DraggableOverlayOrIconItem;
  nodeListDSL: NodeListDSL;
  cursorPosition: XYCoord;
  componentListDSL: ComponentListDSL;
}): DndSpec | undefined => {
  const targetChildrenIDs = nodeSelectors.getAllImmediateDefaultChildrenIDs(targetNode, {
    componentListDSL,
  });

  let targetChildClosest: {
    nodeID: string;
    cornerOffset: number;
    type: typeof MOVE_VARIANT.before | typeof MOVE_VARIANT.after;
  } | null = null;

  for (const targetChildID of targetChildrenIDs) {
    if (targetChildID === item.id) {
      // showing movement relative to itself is not necessarily useful
      // eslint-disable-next-line no-continue
      continue;
    }

    const targetChildNode = nodeListDSL[targetChildID];
    const targetChildEl = document.querySelector(
      `[data-node-id="${targetChildID}"][data-test="overlay.node.${targetChildNode.name}"]`,
    );
    if (targetChildEl) {
      // for each target we are also looking for boundaries
      // and calculating the offset b/w them and the cursor' position
      const targetChildBoundRect = targetChildEl.getBoundingClientRect().toJSON();
      const targetChildHoverY = Math.floor(cursorPosition.y - targetChildBoundRect.top);
      const targetChildTop = 0;
      const targetChildBottom = Math.floor(targetChildBoundRect.bottom - targetChildBoundRect.top);
      const targetChildTopOffset = Math.abs(targetChildTop - targetChildHoverY);
      const targetChildBottomOffset = Math.abs(targetChildBottom - targetChildHoverY);
      const targetChildMinOffset = Math.min(targetChildTopOffset, targetChildBottomOffset);

      if (targetChildClosest === null || targetChildClosest.cornerOffset > targetChildMinOffset) {
        // saving the closest child
        targetChildClosest = {
          nodeID: targetChildID,
          cornerOffset: targetChildMinOffset,
          type:
            targetChildMinOffset === targetChildTopOffset
              ? MOVE_VARIANT.before
              : MOVE_VARIANT.after,
        };
      }
    }
  }

  if (targetChildClosest) {
    // if we have a closest child -- selecting it as a relative target
    return {
      meta: {
        type: targetChildClosest.type,
        kind: 'valid',
        sourceID: item.id,
        target: {
          type: DND_TARGET_TYPES.relative,
          nodeID: targetChildClosest.nodeID,
          propName: ['children'],
        },
      },
    };
  }
};
