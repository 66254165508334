import * as mime from 'mime-types';
/**
 * @description mime-type of some file formats is interpreted incorrectly
 * and is derived to generic 'application/octet-stream' from which it's impossible to define asset type
 * this function provides fallback and returns mimetype based on file extension when failed to determine the mime by file contents
 * @param filepath - filename or filepath with extension present
 * @param currentMimetype - mimetype associated with the file by default
 * @returns mimetype derived from actual mimetype determined by file contents (@param currentMimetype)
 * or file extension (if @param currentMimetype equals to generic application/octet-stream)
 * // if any of the arguments is nullish -> return generic mimetype
 * @example (nil, nil) | (nil, string) | (string, nil) => 'application/octet-stream'
 * // if filepath contains invalid or unrecognized extension and its default mime is octet-stream -> return octet-stream
 * @example ('file.<unknown-extenstion>', 'application/octet-stream') => 'application/octet-stream'
 * // fonts are not recognized by default so their mime will be octet-stream but extension lookup will return font/*
 * @example ('file.ttf', 'application/octet-stream') => 'font/ttf'
 * @example ('file.png', 'application/octet-stream') | ('file.png', 'image/png') => 'image/png'
 */
export const ensureCorrectMimetype = (filepath, currentMimetype) => {
    if (!currentMimetype || !filepath) {
        return 'application/octet-stream';
    }
    if (currentMimetype === 'application/octet-stream') {
        return mime.lookup(filepath) || currentMimetype;
    }
    return currentMimetype;
};
