export const RESOURCE_TYPINGS_CONST = {
    Resource: 'Resource',
    ResourceNames: 'ResourceNames',
    ResourceEndpoint: 'ResourceEndpoint',
    ResourceTypes: 'ResourceTypes',
    ResourceList: 'ResourceList',
};
export const RESOURCE_TYPES_CONST = 'RESOURCE_TYPES';
export const RESOURCE_NAMES_CONST = 'RESOURCE_NAMES';
export const COMMON_TYPINGS = {
    ValueOf: 'ValueOf',
};
