import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { orange } from '@mui/material/colors';

import { IconWrapperSpan } from 'src/shared/components/Icon';

const BLACK_LIST_PROPS = ['isDragging'];
const shouldForwardProp = (prop: string): boolean => !BLACK_LIST_PROPS.includes(prop);

export const ComponentItemTitle = styled(Typography, { shouldForwardProp })<{
  isDragging: boolean;
}>`
  line-height: ${({ theme }) => theme.typography.caption.lineHeight}px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey[300]};
  text-align: center;
  letter-spacing: 0.2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 100ms ease-out 100ms;
`;

export const ComponentItemIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ theme }) => theme.palette.grey[900]};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(1.5)}`};
  border-radius: ${({ theme }) => theme.spacing(1)};
  transition: background-color 100ms ease-out 100ms;
`;

export const ComponentItemContainer = styled.div<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
  cursor: grab;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  ${IconWrapperSpan} path,
  ${IconWrapperSpan} rect,
  ${IconWrapperSpan} circle {
    transition: fill 100ms ease-out 100ms;
  }
  &:hover {
    ${IconWrapperSpan} path,
    ${IconWrapperSpan} rect,
    ${IconWrapperSpan} circle {
      stroke: ${({ theme }) => theme.palette.text.primary};
    }
    ${ComponentItemTitle} {
      color: ${({ theme }) => theme.palette.text.primary};
    }
    ${ComponentItemIcon} {
      background-color: ${({ theme, isDragging }) =>
        isDragging ? theme.palette.primary.light : theme.palette.primary.main};
    }

    ${IconWrapperSpan} path.white,
    ${IconWrapperSpan} rect.white,
    ${IconWrapperSpan} circle.white {
      fill: white !important;
      stroke: transparent;
    }
  }
`;

export const ComponentItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.layout.LeftPanel.iconSize}px;
  height: ${({ theme }) => theme.layout.LeftPanel.iconSize}px;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
`;

export const GroupsItemContainer = styled.div<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
  box-sizing: border-box;
  cursor: grab;
  display: flex;
  width: 100%;
  align-items: center;
  ${ComponentItemTitle} {
    margin-left: 12px;
    font-size: 12px;
    line-height: 16px;
    width: 160px;
    text-align: left;
    height: fit-content;
  }
  ${ComponentItemIcon} {
    max-width: 71px;
    margin: 0;
  }

  ${IconWrapperSpan} path,
  ${IconWrapperSpan} rect,
  ${IconWrapperSpan} circle {
    transition: fill 100ms ease-out 100ms;
  }
  &:hover {
    ${IconWrapperSpan} path,
    ${IconWrapperSpan} rect,
    ${IconWrapperSpan} circle {
      fill: ${({ theme, isDragging }) =>
        isDragging ? theme.palette.text.primary : theme.palette.text.primary};
    }
    ${ComponentItemTitle} {
      color: ${({ theme }) => theme.palette.text.primary};
    }
    ${ComponentItemIcon} {
      background-color: ${({ theme, isDragging }) =>
        isDragging ? theme.palette.primary.light : theme.palette.primary.main};
    }
  }
`;

export const LayoutEditorComponent = styled.div<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  cursor: grab;
  box-sizing: border-box;
  font-weight: 500;
  border-radius: 16px;
  border-width: 1px;
  border-style: dashed;
  padding: 7px;
  background: ${orange[600]}1A;
  border-color: ${orange[600]};
  color: ${orange[600]};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  &:hover {
    border-style: solid;
    color: ${orange[50]};
    background: linear-gradient(${orange[300]}, ${orange[600]});
  }
`;
