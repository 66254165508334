export const TYPES = {
  string: 'string',
  number: 'number',
  bigInt: 'BigInt',
  boolean: 'Boolean',
  symbol: 'Symbol',
  object: 'Object',
  void: 'void',
  list: 'list',
  noList: 'nolist',
} as const;

export const LIST_TYPES = {
  list: 'list',
  noList: 'nolist',
} as const;

export const DATA_TYPE_OPTIONS = [
  {
    label: 'String',
    value: TYPES.string,
  },
  {
    label: 'Number',
    value: TYPES.number,
  },
  {
    label: 'BigInt',
    value: TYPES.bigInt,
  },
  {
    label: 'Boolean',
    value: TYPES.boolean,
  },
  {
    label: 'Symbol',
    value: TYPES.symbol,
  },
  {
    label: 'Object',
    value: TYPES.object,
  },
];

export const RETURN_TYPE_OPTIONS = [
  ...DATA_TYPE_OPTIONS,
  {
    label: 'Void',
    value: TYPES.void,
  },
];

export const LIST_TYPE_OPTIONS = [
  {
    label: 'List',
    value: TYPES.list,
  },
  {
    label: 'No list',
    value: TYPES.noList,
  },
];

export const EDITOR_PLACE_HOLDER = '// Your code here';
