import { DEFAULT_HTML_TITLE } from '@builder/schemas';
import { GoogleFontsApiLinksGenerator } from '../google-fonts-generator';
export class HtmlTemplatesGenerator {
    static getIndexHtml({ theme, settings }) {
        var _a;
        return `<!DOCTYPE html>
<html lang="en">
  <head>
  ${(settings === null || settings === void 0 ? void 0 : settings.customFavicon) ? `<link rel="icon" href=${settings.customFavicon} />` : ''}
  <title>${(settings === null || settings === void 0 ? void 0 : settings.htmlDefaultTitle) || DEFAULT_HTML_TITLE}</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
    <style>
      html,
      body {
        margin: 0;
        padding: 0;
        height: 100%;
      }
      #user-app-root {
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }
    </style>
    ${GoogleFontsApiLinksGenerator.getApiLinks((_a = theme === null || theme === void 0 ? void 0 : theme.fonts) === null || _a === void 0 ? void 0 : _a.google)}
    ${(settings === null || settings === void 0 ? void 0 : settings.htmlHeadAdditionalContent) || ''}
  </head>
  <body>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="user-app-root"></div>
    ${(settings === null || settings === void 0 ? void 0 : settings.htmlFooterAdditionalContent) || ''}
  </body>
</html>`;
    }
    static getIndexHtmlEsbuild({ theme, settings }, isPreview) {
        var _a;
        return `<!DOCTYPE html>
<html lang="en">
  <head>
    ${(settings === null || settings === void 0 ? void 0 : settings.customFavicon) ? `<link rel="icon" href=${settings === null || settings === void 0 ? void 0 : settings.customFavicon} />` : ''}
    <title>${(settings === null || settings === void 0 ? void 0 : settings.htmlDefaultTitle) || DEFAULT_HTML_TITLE}</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
    <style>
      html,
      body {
        margin: 0;
        padding: 0;
        height: 100%;
      }
      #user-app-root {
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }
    </style>

    ${isPreview
            ? `<script>
              window.addEventListener('click', () => {
                parent.postMessage(
                  {
                    type: 'navigation',
                    location: { href: window.location.href, pathname: window.location.pathname, hash: window.location.hash, search: window.location.search },
                  },
                  '*',
                );
              });

              window.addEventListener('error', () => {
                parent.postMessage(
                  {
                    type: 'error',
                    error: event.message,
                  },
                  '*',
                );
              });
          </script>`
            : ''}

    ${GoogleFontsApiLinksGenerator.getApiLinks((_a = theme === null || theme === void 0 ? void 0 : theme.fonts) === null || _a === void 0 ? void 0 : _a.google)}
    ${(settings === null || settings === void 0 ? void 0 : settings.htmlHeadAdditionalContent) || ''}
    {% for path in assetsMap["css"] %}<link rel="stylesheet" href="/{{ path }}">{% endfor %}
  </head>
  <body>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="user-app-root"></div>
    ${(settings === null || settings === void 0 ? void 0 : settings.htmlFooterAdditionalContent) || ''}
    {% for path in assetsMap["js"] %}<script src="/{{ path }}"></script>{% endfor %}
  </body>
</html>`;
    }
}
