/* eslint-disable @typescript-eslint/no-explicit-any */
import { prop, sortBy } from 'ramda';

import { APP_RENDER_ERRORS, AppEvents, errorContext, ItemErrorContext, Store } from '../common';

export const INITIAL_STATE: Store['userAppError'] = {
  errors: [],
  warnings: [],
  lintErrors: [],
  lintErrorsPrev: [],
  runtimeErrors: [],
  hasError: 0,
  hasWarning: 0,
};

export const userAppRenderErrors = (
  state: Store['userAppError'] = INITIAL_STATE,
  event: AppEvents,
): Store['userAppError'] => {
  if (event.type === APP_RENDER_ERRORS.pushNotifications) {
    const keyLintErrors = state.lintErrors
      .concat(state.lintErrorsPrev)
      .map(err =>
        []
          .concat(err.targetID as any, err.context.propPath as any, err.context.fullPath as any)
          .join(''),
      );
    const errors = ((event.payload as unknown) as ItemErrorContext[]).filter(err => {
      return !keyLintErrors.includes(
        []
          .concat(err.targetID as any, err.context.propPath as any, err.context.fullPath as any)
          .join(''),
      );
    });

    const newState = {
      ...state,
      errors: sortBy(prop('context.title'))(
        errors.concat(
          state.lintErrors.filter(err => err.type === '@builder/error') as any,
        ) as ItemErrorContext[],
      ),
      runtimeErrors: errors,
    };

    return newState as errorContext;
  }

  if (event.type === APP_RENDER_ERRORS.pushError) {
    const keyLintErrors = ((event.payload as unknown) as ItemErrorContext[])
      .concat(state.lintErrorsPrev)
      .map(err =>
        []
          .concat(err.targetID as any, err.context.propPath as any, err.context.fullPath as any)
          .join(''),
      );
    const errors = ((state.runtimeErrors as unknown) as ItemErrorContext[]).filter(err => {
      return !keyLintErrors.includes(
        []
          .concat(err.targetID as any, err.context.propPath as any, err.context.fullPath as any)
          .join(''),
      );
    });

    const allErrors = (errors || []).concat(event.payload) as ItemErrorContext[];

    return {
      ...state,
      errors: sortBy(prop('context.title'))(allErrors.filter(err => err.type === '@builder/error')),
      warnings: allErrors.filter(err => err.type === '@builder/warn'),
      lintErrors: ((event.payload as unknown) as ItemErrorContext[]) || [],
      lintErrorsPrev: state.lintErrors,
      runtimeErrors: errors,
    };
  }

  return state;
};
