import colorConvert from 'color-convert';

import { CSS_COLOR_MODEL, parseCSSColorModel } from '@builder/utils';

import { COLOR_MODELS } from '../../constants';
import { Color, ColorModel, HEX, HSBA, LAB, RGBA } from '../../types';
import { Converter } from '../converter';

/**
 * @example convertColorToHsba({
 *   type: 'HEX';
 *   value: '#964141';
 * }) => {
 *   h: 360,
 *   s: 0.59,
 *   b: 0.57,
 *   a: 1,
 * }
 * */
export const convertColorToHsba = (color: Color): HSBA => {
  const { type, value } = color;

  switch (type) {
    case COLOR_MODELS.hex:
      return Converter.hex(value as HEX).toHsb();
    case COLOR_MODELS.rgba:
      return Converter.rgba(value as RGBA).toHsb();
    case COLOR_MODELS.lab:
      return Converter.lab(value as LAB).toHsb();
  }
};

/**
 * @example convertHsbaToColorByType({
 *   h: 100,
 *   s: 0.42,
 *   b: 0.12,
 *   a: 1,
 * }, 'HEX') => {
 *   type: 'HEX';
 *   value: '#161F12';
 * }
 * */
export const convertHsbaToColorByType = (hsba: HSBA, type: ColorModel): Color => {
  switch (type) {
    case COLOR_MODELS.hex:
      return {
        type,
        value: Converter.hsba(hsba).toHex(),
      };
    case COLOR_MODELS.rgba:
      return {
        type,
        value: Converter.hsba(hsba).toRgba(),
      };
    case COLOR_MODELS.lab:
      return {
        type,
        value: Converter.hsba(hsba).toLab(),
      };
  }
};

export const getColorString = (color: Color, hasOnlyNumbers = true): string => {
  if (!color) return '';

  const { type, value } = color;

  switch (type) {
    case COLOR_MODELS.hex:
      return value as string;
    case COLOR_MODELS.rgba:
      return Converter.rgba(value as RGBA).toString(hasOnlyNumbers);
    case COLOR_MODELS.lab:
      return Converter.lab(value as LAB).toString(hasOnlyNumbers);
  }
};

export const getColorFromString = (colorString: string, colorModel: ColorModel): Color => {
  switch (colorModel) {
    case COLOR_MODELS.hex:
      return { type: colorModel, value: colorString };
    case COLOR_MODELS.rgba:
      return { type: colorModel, value: Converter.rgba(colorString) };
    case COLOR_MODELS.lab:
      return { type: colorModel, value: Converter.lab(colorString) };
  }
};

export const getInitialColorValue = (stringColor: string): Color | undefined => {
  const cssColorModel = parseCSSColorModel(stringColor);

  if (cssColorModel) {
    switch (cssColorModel.type) {
      case CSS_COLOR_MODEL.hex: {
        return {
          type: COLOR_MODELS.hex,
          value: stringColor,
        };
      }

      case CSS_COLOR_MODEL.rgba: {
        return {
          type: COLOR_MODELS.rgba,
          value: Converter.rgba(stringColor),
        };
      }

      case CSS_COLOR_MODEL.hsl: {
        const [r, g, b] = colorConvert.hsl.rgb(cssColorModel.value);
        return {
          type: COLOR_MODELS.rgba,
          value: Converter.rgba({
            r,
            g,
            b,
            a: cssColorModel.alpha,
          }),
        };
      }

      case CSS_COLOR_MODEL.lab: {
        const [l, a, b] = cssColorModel.value;
        return {
          type: COLOR_MODELS.lab,
          value: Converter.lab({
            l,
            a,
            b,
          }),
        };
      }

      case CSS_COLOR_MODEL.rgb:
      case CSS_COLOR_MODEL.transparent:
      case CSS_COLOR_MODEL.colorName: {
        const [r, g, b] = cssColorModel.value;
        return {
          type: COLOR_MODELS.rgba,
          value: Converter.rgba({
            r,
            g,
            b,
            a: cssColorModel.alpha,
          }),
        };
      }
    }
  }
};
