/* eslint-disable import/no-webpack-loader-syntax */
import { CODE_ENGINE_MARKERS } from '../../../../predefined-states';
import { BaseAppStateAccessor } from './BaseAppStateAccessor';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import useNumberStateRaw from '!!raw-loader!../../../../predefined-states/useNumberState.template.ts';
export class NumberStateAccessor extends BaseAppStateAccessor {
    constructor(stateDSL, stateListDSL) {
        super();
        this.stateDSL = stateDSL;
        this.stateListDSL = stateListDSL;
    }
    getHookDeclarationName() {
        return `useNumberState`;
    }
    getHookCallArgumentsString() {
        var _a;
        return `${(_a = this.stateDSL.defaultValue) !== null && _a !== void 0 ? _a : ''}`;
    }
    getHookDeclarationArgumentsString() {
        return `defaultState: number`;
    }
    getHookDeclarationBodyString() {
        return useNumberStateRaw
            .replace(new RegExp(`(.|\n|\r)*${CODE_ENGINE_MARKERS.codeEngineUsageStart}`), '')
            .replace(new RegExp(`${CODE_ENGINE_MARKERS.codeEngineUsageEnd}(.|\n|\r)*`), '');
    }
    getStateTypeName() {
        return 'AppRuntimeNumberState';
    }
}
