export * from './routing';
export * from './shallowEqual';
export * from './generateVariableNameFromString';
export * from './svg';
export * from './getNodeStyle';
export * from './asset';
export * from './version';
export * from './getEnvironmentString';
export * from './getCalculatedDashboardWidth';
export * from './getCalculatedNavigatorWidth';
export * from './getTheDifferentValues';
export * from './getRandomColor';
export * from './deepEqual';
export * from './filterValidStyles';
export * from './copyValidStyles';
export * from './validateIsFormComponent';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

export const EMPTY_OBJECT = {} as const;
