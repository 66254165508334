var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { FormControlLabel, Radio as MaterialRadio, } from '@mui/material';
export const Radio = forwardRef((_a, ref) => {
    var { disabled, label: initLabel, labelPlacement = 'end', value, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, style, id, title, className, lang, translate, readOnly } = _a, otherRadioProps = __rest(_a, ["disabled", "label", "labelPlacement", "value", 'data-test', 'data-node-id', 'data-node-render-path', "style", "id", "title", "className", "lang", "translate", "readOnly"]);
    // if we receive array of nodes here we should take only first one to avoid an error
    const label = Array.isArray(initLabel) ? initLabel[0] : initLabel;
    return (_jsx(FormControlLabel, { ref: ref, disabled: disabled, disableTypography: true, label: label, labelPlacement: labelPlacement, value: value, control: _jsx(MaterialRadio, Object.assign({ readOnly: readOnly }, otherRadioProps, { sx: { paddingLeft: 0 } })), "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, style: style, id: id, title: title, className: className, lang: lang, translate: translate, 
        // why should we set such a weird styles there?
        // 1. by default Material-UI has negative marginLeft value and this component falls out its parent on the left side
        // so to avoid such weird behaviour we set marginLeft equals to 0
        // 2. by default Material-UI has positive marginRight value and it's cause of strange looking of overlays on the right side.
        // an HTML node looks shorter than we want so to avoid such weird behaviour we set marginRight equals to 0
        sx: { marginLeft: 0, marginRight: 0 } }));
});
