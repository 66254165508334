import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { DescriptionTooltipWrapper } from '../../DescriptionTooltipWrapper';
import { FxButton } from '../FxButton';
import { HelperText } from '../HelperText';
import { InputLabel } from '../InputLabel';
import { InputLabelIcon } from '../InputLabelIcon';
import { MESSAGES } from 'src/shared/constants';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

type VariantType = 'standard' | 'outlined' | 'filled';

export type LabelPlacementType = 'top' | 'left';

export type InputContainerProps = {
  variant?: VariantType;
  label?: string;
  labelPlacement?: LabelPlacementType;
  icon?: string;
  isTextIcon?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  enableFx?: (() => void) | undefined;
  isFxEnabled?: string;
  fullHeight?: boolean;
  showFx?: boolean;
  noCode?: boolean;
  'data-test'?: string;
};

const Container = styled.div<{ labelPlacement: LabelPlacementType; fullHeight?: boolean }>`
  ${({ fullHeight }) =>
    fullHeight === true &&
    css`
      height: 100%;
    `}
  ${({ labelPlacement }) =>
    labelPlacement === 'left' &&
    css`
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 8px;
    `}
  width: 100%;
`;

const Content = styled.div<{ fullHeight?: boolean }>`
  ${({ fullHeight }) =>
    fullHeight === true &&
    css`
      height: 100%;
    `}
  display: flex;
  align-items: center;
`;

const FxButtonWrapper = styled.div`
  height: ${({ theme }) => theme.layout.controls.height}px;
  margin-top: auto;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const InputContainer: React.FC<InputContainerProps> = ({
  label,
  labelPlacement = 'top',
  error,
  variant,
  icon,
  isTextIcon,
  helperText,
  enableFx,
  isFxEnabled,
  children,
  showFx = true,
  fullHeight,
  noCode = false,
  'data-test': dataTest,
}) => {
  const {
    title: REQ_TOOLTIP_TITLE,
    description: REQ_TOOLTIP_DESCRIPTION,
  } = MESSAGES.nodeSettings.events.runRequest.tooltip;
  const REQUESTS = 'Requests';
  return (
    <Container fullHeight={fullHeight} labelPlacement={labelPlacement}>
      {label && (
        <Choose>
          <When condition={label === REQUESTS}>
            <DescriptionTooltipWrapper
              title={REQ_TOOLTIP_TITLE}
              subtitle={REQ_TOOLTIP_DESCRIPTION}
              wrapCondition
            >
              <InputLabel error={error} title={label} variant={variant} />
            </DescriptionTooltipWrapper>
          </When>
          <Otherwise>
            <InputLabel error={error} title={label} variant={variant} />
          </Otherwise>
        </Choose>
      )}

      <Content fullHeight={fullHeight}>
        {icon && <InputLabelIcon icon={icon} isTextIcon={isTextIcon} />}
        {children}
        {enableFx && showFx && (
          <FxButtonWrapper>
            <FxButton
              activeProp={isFxEnabled ?? ACTIVEPROP.literal}
              onClick={enableFx}
              data-test={`${dataTest}.fxBtn`}
              label={label}
              skipCode={noCode}
            />
          </FxButtonWrapper>
        )}
      </Content>
      {helperText && (
        <HelperText
          data-test={`${dataTest}.helperText`}
          variant={variant}
          error={error}
          helperText={helperText}
        />
      )}
    </Container>
  );
};
