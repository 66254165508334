import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, LANG_OPTIONS, TRANSLATE_OPTIONS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, NS_ID, pickSettings } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const MaterialCardMediaSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['title'],
                            label: 'Title',
                            name: 'title',
                        },
                        getDivider(),
                        {
                            componentView: COMPONENT_SETTING_VIEWS.assetEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['image'],
                            label: 'Image',
                            name: 'image',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Common',
                    name: 'Common',
                    expandedByDefault: false,
                    titleDescription: {
                        title: 'Common',
                        subtitle: 'Common properties for all components',
                    },
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['id'],
                            label: 'Id',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['className'],
                            label: 'Class',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['lang'],
                            label: 'Language',
                            options: LANG_OPTIONS.map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['translate'],
                            label: 'Translate',
                            options: TRANSLATE_OPTIONS.map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['draggable'],
                            label: 'Draggable',
                        },
                        getDivider(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'customPropsSection',
                            title: 'Custom Properties',
                            children: [
                                {
                                    type: COMPONENT_SETTING_TYPES.list,
                                    keyPath: ['customProps'],
                                    name: 'customProps',
                                    label: 'Custom Properties',
                                    itemLabelKeyPath: ['Key'],
                                    newObjectPredefineds: {
                                        Key: 'propname',
                                        Value: 'value',
                                    },
                                    settings: [
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['Key'],
                                            label: 'Key',
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['Value'],
                                            label: 'Value',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonMaterialCardMediaSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'title'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'image'],
    [NS_ID.style.root, NS_ID.style.size.root, 'height'],
], { withStructure: false }))(values(MaterialCardMediaSettings).flat());
