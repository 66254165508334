import { pipe } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, pickSettings, trimDividers, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const getBuilderComponentsAutocompleteSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'label'],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'required'],
                            label: 'Required',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autofocus'],
                            label: 'Autofocus',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'readOnly'],
                            label: 'Read-Only?',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'options'],
                            label: 'Options',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'value',
                            keyPath: [...prefixPath, 'value'],
                            label: 'Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'defaultValue',
                            keyPath: [...prefixPath, 'defaultValue'],
                            label: 'Default Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            hasFxButton: true,
                            keyPath: [...prefixPath, 'getOptionLabel'],
                            label: 'Get Option Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            hasFxButton: true,
                            keyPath: [...prefixPath, 'isOptionEqualToValue'],
                            label: 'Is Option Equal To Value',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: NS_ID.properties.advanced,
                    title: 'Advanced',
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autoComplete'],
                            label: 'Auto Complete',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autoHighlight'],
                            label: 'Auto Highlight',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autoSelect'],
                            label: 'Auto Select',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'loading'],
                            label: 'Loading',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'clearOnBlur'],
                            label: 'Clear On Blur',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'clearOnEscape'],
                            label: 'Clear On Escape',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'loadingText'],
                            label: 'Loading Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'noOptionsText'],
                            label: 'No Options Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'openText'],
                            label: 'Open Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'open'],
                            label: 'Open',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'openOnFocus'],
                            label: 'Open On Focus',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'popupIcon',
                            label: 'Popup Icon',
                            keyPath: [...prefixPath, 'popupIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'clearIcon',
                            label: 'Clear Icon',
                            keyPath: [...prefixPath, 'clearIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disableClearable'],
                            label: 'Disable Clearable',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disableCloseOnSelect'],
                            label: 'Disable Close On Select',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabledItemsFocusable'],
                            label: 'Disabled Items Focusable',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disableListWrap'],
                            label: 'Disable List Wrap',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disablePortal'],
                            label: 'Disable Portal',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'filterSelectedOptions'],
                            label: 'Filter Selected Options',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'freeSolo'],
                            label: 'Free Solo',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            hasFxButton: true,
                            keyPath: [...prefixPath, 'filterOptions'],
                            label: 'Filter Options',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            hasFxButton: true,
                            keyPath: [...prefixPath, 'getOptionDisabled'],
                            label: 'Get Option Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            hasFxButton: true,
                            keyPath: [...prefixPath, 'groupBy'],
                            label: 'GroupBy',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'handleHomeEndKeys'],
                            label: 'Handle Home End Keys',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'includeInputInList'],
                            label: 'Include Input In List',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'inputValue'],
                            label: 'Input Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'selectOnFocus'],
                            label: 'Select On Focus',
                        },
                        // TODO: uncomment after "multiple" prop would be implemented
                        // https://8base-dev.atlassian.net/browse/APB-905
                        // {
                        //   componentView: COMPONENT_SETTING_VIEWS.number,
                        //   type: COMPONENT_SETTING_TYPES.prop,
                        //   keyPath: [...prefixPath, 'limitTags'],
                        //   label: 'Limit Tags',
                        // },
                        // {
                        //   componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                        //   type: COMPONENT_SETTING_TYPES.prop,
                        //   hasFxButton: true,
                        //   keyPath: [...prefixPath, 'getLimitTagsText'],
                        //   label: 'Get Limit Tags Text',
                        // },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'variant'],
                            label: 'Variant',
                            options: ['standard', 'outlined', 'filled'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'size'],
                            label: 'Size',
                            options: ['medium', 'small'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'fullWidth'],
                            label: 'Full Width',
                        },
                        getDivider(),
                    ],
                },
                ...pipe(pickSettings([
                    [NS_ID.style.display.root],
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.size.root, NS_ID.style.size.width],
                    [NS_ID.style.size.root, NS_ID.style.size.height],
                    [NS_ID.style.size.root, NS_ID.style.size.minWidth],
                    [NS_ID.style.size.root, NS_ID.style.size.minHeight],
                    [NS_ID.style.size.root, NS_ID.style.size.maxWidth],
                    [NS_ID.style.size.root, NS_ID.style.size.maxHeight],
                    [NS_ID.style.css.root],
                ]), assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], setting => (Object.assign(Object.assign({}, setting), { disablePaddings: true }))), trimDividers)(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onOpenAction',
                            label: 'On Open',
                            keyPath: [...prefixPath, 'onOpen'],
                            children: getCommonActionSettings([...prefixPath, 'onOpen']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: [...prefixPath, 'onClose'],
                            children: getCommonActionSettings([...prefixPath, 'onClose']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onHighlightChangeAction',
                            label: 'On Highlight Change',
                            keyPath: [...prefixPath, 'onHighlightChange'],
                            children: getCommonActionSettings([...prefixPath, 'onHighlightChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onInputChangeAction',
                            label: 'On Input Change',
                            keyPath: [...prefixPath, 'onInputChange'],
                            children: getCommonActionSettings([...prefixPath, 'onInputChange']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsAutocompleteSettings = getBuilderComponentsAutocompleteSettings();
