import convert from 'color-convert';

import { PERCENT } from '../../constants';
import { HSBA, HEX } from '../../types';
import { roundToDigits } from '../helpers';

import { Lab } from './Lab';
import { Rgba } from './Rgba';

export class Hsba {
  private readonly h: number;

  private readonly s: number;

  private readonly b: number;

  private readonly a: number;

  constructor(hsb: HSBA) {
    this.h = roundToDigits(hsb.h);
    this.s = roundToDigits(hsb.s, 2);
    this.b = roundToDigits(hsb.b, 2);
    this.a = roundToDigits(hsb.a, 2);
  }

  toHex(): HEX {
    // Saturation and brightness are being converted to percentages
    return `#${convert.hsv.hex([this.h, this.s * PERCENT, this.b * PERCENT])}` as HEX;
  }

  toRgba(): Rgba {
    // Saturation and brightness are being converted to percentages
    const [r, g, b] = convert.hsv.rgb([this.h, this.s * PERCENT, this.b * PERCENT]);
    return new Rgba({
      r,
      g,
      b,
      a: this.a,
    });
  }

  toLab(): Lab {
    // Saturation and brightness are being converted to percentages
    const [l, a, b] = convert.hsv.lab([this.h, this.s * PERCENT, this.b * PERCENT]);
    return new Lab({
      l,
      a,
      b,
    });
  }
}
