import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonSymbolProps, commonComponentProps } from '../../common/commonProps';
import { BasePageLayoutSettings } from './BasePageLayout.settings';
const DRAWER_WIDTH_OPEN = 242;
const DRAWER_WIDTH_CLOSED = 75;
const TRANSITION_STYLE = '300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms';
export const BasePageLayout = {
    name: COMPONENT_DSL_NAMES.BasePageLayout,
    displayName: 'Base Layout',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.layout,
    memoization: {
        disable: true,
    },
    schema: {
        dndTargetPropName: 'content',
        accessibility: {
            hideBreadcrumb: true,
            hideOverlay: true,
        },
        // interaction: COMPONENT_DSL_INTERACTION_TYPES.none,
        predefineds: {
            props: {
                drawerOpen: true,
                drawerAnchor: 'left',
                drawerVariant: 'permanent',
                drawerOpenWidth: DRAWER_WIDTH_OPEN,
                drawerClosedWidth: DRAWER_WIDTH_CLOSED,
            },
        },
        interaction: COMPONENT_DSL_INTERACTION_TYPES.all,
        props: Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonSymbolProps), { content: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.RouterSwitch, COMPONENT_DSL_NAMES.RouterSwitchSymbol],
            }, leftMenu: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, header: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, drawerOpen: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            }, drawerOpenWidth: {
                type: COMPONENT_DSL_PROP_TYPES.number,
            }, drawerClosedWidth: {
                type: COMPONENT_DSL_PROP_TYPES.number,
            }, drawerAnchor: { type: COMPONENT_DSL_PROP_TYPES.enum }, drawerVariant: { type: COMPONENT_DSL_PROP_TYPES.enum }, drawerOnClose: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, __presentationName: {
                type: COMPONENT_DSL_PROP_TYPES.string,
                system: true,
            } }),
        system: true,
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
            props: {
                path: '{{ symbolProps.path }}',
                exact: true,
                authAccess: 'any',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.div,
                                            alias: 'Drawer Wrapper',
                                            props: {
                                                style: {
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: '{{ symbolProps?.drawerAnchor === "left" ? "flex-end" : "flex-start"}}',
                                                    height: '100vh',
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.MaterialDrawer,
                                                            props: {
                                                                open: '{{ symbolProps.drawerOpen }}',
                                                                onClose: '{{ symbolProps?.drawerOnClose }}',
                                                                variant: '{{ symbolProps?.drawerVariant }}',
                                                                anchor: '{{ symbolProps?.drawerAnchor }}',
                                                                transitionDuration: 300,
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialDrawerContent,
                                                                            props: {
                                                                                children: '{{ symbolProps.leftMenu }}',
                                                                                style: {
                                                                                    width: `{{ symbolProps.drawerOpen && symbolProps.drawerVariant === "permanent" ? \`\${symbolProps.drawerOpenWidth}px\` : !symbolProps.drawerOpen && symbolProps.drawerVariant === "permanent" ? \`\${symbolProps.drawerClosedWidth}px\` : \`\${symbolProps.drawerOpenWidth}px\` }}`,
                                                                                    transition: TRANSITION_STYLE,
                                                                                    flexShrink: 0,
                                                                                    height: '100vh',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflowX: 'hidden',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.div,
                                                            alias: 'Content Wrapper',
                                                            props: {
                                                                style: {
                                                                    flexGrow: 1,
                                                                    maxWidth: `{{ symbolProps.drawerOpen && symbolProps.drawerVariant === "permanent" ? \`calc(100% - \${symbolProps.drawerOpenWidth}px)\` : !symbolProps.drawerOpen && symbolProps.drawerVariant === "permanent" ? \`calc(100% - \${symbolProps.drawerClosedWidth}px)\` : symbolProps.drawerOpen && symbolProps.drawerVariant === "persistent" ? \`calc(100% - \${symbolProps.drawerOpenWidth}px)\` : "100%" }}`,
                                                                    transition: TRANSITION_STYLE,
                                                                    height: '100%',
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.div,
                                                                            alias: 'Content',
                                                                            props: {
                                                                                style: {
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    flex: '1 1 0',
                                                                                    height: '100%',
                                                                                },
                                                                                children: '{{ [symbolProps.header, symbolProps.content] }}',
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: BasePageLayoutSettings,
};
