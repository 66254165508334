import colorName from 'color-name';
import { HEX_REGEXP, HSL3_REGEXP, HSL4_REGEXP, RGB3_NUMBER_REGEXP, RGB3_PERCENTAGE_REGEXP, RGB4_NUMBER_REGEXP, RGB4_PERCENTAGE_REGEXP, TRANSPARENT_REGEXP, LAB_REGEXP, } from './regexes';
import { CSS_COLOR_MODEL } from './types';
import { getHEX, getRGB, getHSL, getLab, getRGBA } from './utils';
export const parseCSSColorModel = (stringColor) => {
    if (typeof stringColor !== 'string') {
        return undefined;
    }
    const hex = HEX_REGEXP.exec(stringColor);
    if (hex) {
        return getHEX(stringColor);
    }
    const hsl = HSL4_REGEXP.exec(stringColor) || HSL3_REGEXP.exec(stringColor);
    if (hsl) {
        return getHSL(hsl);
    }
    const rgba = RGB4_NUMBER_REGEXP.exec(stringColor) || RGB4_PERCENTAGE_REGEXP.exec(stringColor);
    if (rgba) {
        return getRGBA(rgba);
    }
    const rgb = RGB3_NUMBER_REGEXP.exec(stringColor) || RGB3_PERCENTAGE_REGEXP.exec(stringColor);
    if (rgb) {
        return getRGB(rgb);
    }
    const lab = LAB_REGEXP.exec(stringColor);
    if (lab) {
        return getLab(lab);
    }
    if (TRANSPARENT_REGEXP.exec(stringColor)) {
        return Object.assign(Object.assign({}, getRGB(['', '0', '0', '0', '0'])), { type: CSS_COLOR_MODEL.transparent });
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cn = colorName[stringColor.toLowerCase()];
    if (cn) {
        return Object.assign(Object.assign({}, getRGB(['', cn[0], cn[1], cn[2], '1'])), { type: CSS_COLOR_MODEL.colorName });
    }
    return undefined;
};
