import React, { useCallback, useEffect } from 'react';

import { useSnackbar, SnackbarKey } from 'notistack';
import { uniqBy } from 'ramda';

import { Snackbar } from '../components/Snackbar';
import { useAppDispatch, useUIBuilderState } from 'src/providers/ReduxProvider';
import { functionDataTypes, Notification, UI_BUILDER_EVENTS } from 'src/store';

type DisplayedNotification = {
  key?: SnackbarKey;
  kind: string;
};

export const useNotifier = (): void => {
  const send = useAppDispatch();
  const { notifications } = useUIBuilderState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const displayed = React.useRef<Array<DisplayedNotification | undefined>>([]);

  // A most of code was copied from notistack docs https://iamhosseindhv.com/notistack/demos#redux-/-mobx-example

  const storeDisplayed = useCallback((notification: DisplayedNotification) => {
    if (notification.key) {
      displayed.current = [...displayed.current, notification];
    }
  }, []);

  const removeDisplayed = useCallback((key?: SnackbarKey) => {
    displayed.current = [
      ...displayed.current.filter(localNotification => localNotification?.key !== key),
    ];
  }, []);

  const handleEnqueueSnackbar = useCallback(
    ({
      key,
      message,
      functionData,
      options = {},
      actionCallback,
      actionTitle,
      title,
      action,
    }: Notification) => {
      enqueueSnackbar(message, {
        key,
        ...options,
        // Main customization is terrible for our design, so I have to make custom component
        content: (
          <Snackbar
            snackbarKey={key}
            message={message}
            options={options}
            actionButtonCallback={actionCallback}
            actionButtonTitle={actionTitle}
            title={title}
            action={action}
            functionData={functionData as functionDataTypes}
          />
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          send({ type: UI_BUILDER_EVENTS.removeNotification, key: myKey });
          removeDisplayed(myKey);
        },
      });
    },
    [enqueueSnackbar, removeDisplayed, send],
  );

  useEffect(() => {
    const errorNotifications = uniqBy(
      item => item.key,
      notifications.filter(item => item.options?.variant === 'error'),
    );
    errorNotifications.forEach(({ key, message, functionData, options, dismissed, title }) => {
      let newKey = key;
      const errorSingleDisplayed = displayed.current.find(
        item => item?.kind === 'error' && item.key !== 'multipleError',
      );
      const errorMultipleDisplayed = displayed.current.find(
        item => item?.kind === 'error' && key === 'multipleError',
      );

      let notification: Notification = {
        key: newKey,
        message,
        functionData,
        options,
        title: title || undefined,
      };
      if (errorSingleDisplayed && errorNotifications.length > 1) {
        closeSnackbar(errorSingleDisplayed.key);
      }

      if (errorNotifications.length > 1 && !errorMultipleDisplayed) {
        newKey = 'multipleError';
        notification = {
          key: newKey,
          message: 'Multiple errors need to be resolved.',
          functionData,
          options: {
            variant: 'error',
          },
        };
      }

      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(newKey);
        return;
      }

      handleEnqueueSnackbar(notification);
      // keep track of snackbars that we've displayed
      storeDisplayed({
        key: newKey,
        kind: 'error',
      });
    });
    notifications
      .filter(item => item.options?.variant !== 'error')
      .forEach(
        ({
          key,
          message,
          functionData,
          options = {},
          dismissed = false,
          action,
          actionTitle,
          actionCallback,
        }) => {
          if (dismissed) {
            // dismiss snackbar using notistack
            closeSnackbar(key);
            return;
          }

          // do nothing if snackbar is already displayed
          if (displayed.current.some(item => item?.key === key)) return;

          // display snackbar using notistack
          handleEnqueueSnackbar({
            key,
            message,
            functionData,
            options,
            action,
            actionTitle,
            actionCallback,
          });

          // keep track of snackbars that we've displayed
          storeDisplayed({
            key,
            kind: options.variant ?? 'default',
          });
        },
      );
  }, [
    notifications,
    closeSnackbar,
    enqueueSnackbar,
    send,
    storeDisplayed,
    removeDisplayed,
    handleEnqueueSnackbar,
  ]);
};
