import styled from '@emotion/styled';
import { Card, CardProps } from '@mui/material';

export const CardPreviewBadgeContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CardPreviewContainer = styled.div`
  width: 100%;
  margin-bottom: 4px;
  border: 2px solid transparent;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  position: relative;
`;

const CommonBadge = styled.div`
  width: 24px;
  height: 24px;
  display: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
`;

export const SettingsBadge = styled(CommonBadge)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primary.main};
  right: 6px;
`;

export const CheckboxBadge = styled(CommonBadge)`
  :before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.palette.grey[800]};
    opacity: 0.4;
    position: absolute;
    border-radius: 50%;
  }
  left: 6px;

  svg {
    z-index: 1;
  }
`;

type StyledCardProps = CardProps & {
  isActive: boolean;
  isSelected: boolean;
};

export const StyledCard = styled(({ isActive, isSelected, ...props }: StyledCardProps) => (
  <Card {...props} />
))<StyledCardProps>`
  background-color: transparent;
  box-shadow: none;
  .MuiCardMedia-root {
    border-radius: 8px;
  }
  padding-bottom: ${({ isSelected }) => isSelected && '10px'};
  ${SettingsBadge} {
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  }
  ${CardPreviewContainer} {
    border: 2px solid
      ${({ theme, isActive }) => (isActive ? theme.palette.primary.main : 'transparent')};
  }
  ${CardPreviewBadgeContainer} {
    padding: ${({ isSelected }) => isSelected && '10px 10px 0 10px'};
  }
  ${CheckboxBadge} {
    display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
    :before {
      opacity: ${({ isSelected }) => (isSelected ? '1' : '0.4')};
    }
  }
  :hover {
    ${CardPreviewContainer} {
      cursor: pointer;
      border: 2px solid ${({ theme }) => theme.palette.primary.main};
    }
    ${SettingsBadge} {
      display: flex;
    }
    ${CheckboxBadge} {
      display: flex;
    }
  }
`;

export const PopperRefDiv = styled.div`
  position: absolute;
  right: 0;
  width: 1px;
  height: 1px;
`;
