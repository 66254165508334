import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { pickSettings, NS_ID, getBasicPropsSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, } from '../../common';
import { getCommonActionListSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
export const getBuilderComponentsIconSettings = () => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.iconEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['name'],
                            name: 'name',
                            label: 'Name',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.assetEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['customIcon'],
                            label: 'Custom Icon Source',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    showIf: [
                        {
                            keyPath: ['customIcon'],
                            type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                        },
                    ],
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            name: 'color',
                            label: 'Font Color',
                            options: ['inherit', 'primary', 'secondary', 'action', 'disabled', 'error'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['fontSize'],
                            label: 'Size',
                            name: 'size',
                            options: ['inherit', 'small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['viewBox'],
                            name: 'viewBox',
                            label: 'View Box',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.colorEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['style', 'fill'],
                            name: 'fill',
                            label: 'Fill',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.colorEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['style', 'stroke'],
                            name: 'stroke',
                            label: 'Stroke',
                        },
                    ],
                },
                ...getStyleSections(),
                getDivider({
                    showIf: [
                        {
                            keyPath: ['customIcon'],
                            type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                        },
                    ],
                }),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [...getCommonActionListSettings()],
                },
            ],
        },
    ],
});
export const BuilderComponentsIconSettings = getBuilderComponentsIconSettings();
export const getCommonIconSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'name'],
    [NS_ID.style.root, NS_ID.style.basic, 'color'],
    [NS_ID.style.root, NS_ID.style.basic, 'size'],
    [NS_ID.style.root, NS_ID.style.basic, 'viewBox'],
    [NS_ID.style.root, NS_ID.style.basic, 'fill'],
    [NS_ID.style.root, NS_ID.style.basic, 'stroke'],
], { withStructure: false }))(values(BuilderComponentsIconSettings).flat());
