var _a, _b;
import { omit } from 'ramda';
import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsMultiselect } from '../../material-components/BuilderComponentsMultiselect';
import { MaterialTypography } from '../../material-components/MaterialTypography';
import { BuilderComponentsFormMultiselectSettings } from './BuilderComponentsFormMultiselect.settings';
export const BuilderComponentsFormMultiselect = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormMultiselect,
    displayName: 'Form Multiselector',
    icon: 'formMultiselect',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormMultiselect',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [visibleByCondition],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.array,
                    name: 'FormMultiselect',
                    defaultValue: [],
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormMultiselect',
                },
                selectProps: Object.assign(Object.assign({}, omit(['value', 'onChange', 'children'], (_a = BuilderComponentsMultiselect.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)), { value: '{{ __STATES__.FormMultiselect.value }}', onChange: {
                        actionType: 'customCode',
                        args: {
                            code: '__STATES__.FormMultiselect.setValue(event.target.value)',
                        },
                    }, children: {
                        nodes: [
                            {
                                name: COMPONENT_DSL_NAMES.MaterialMenuItem,
                                iterator: {
                                    data: [
                                        { value: 'One', label: 'One!' },
                                        { value: 'Two', label: 'Two!' },
                                        { value: 'Three', label: 'Three!' },
                                    ],
                                    name: 'item',
                                },
                                props: {
                                    value: '{{ item.value }}',
                                    children: {
                                        nodes: [
                                            {
                                                name: 'Text',
                                                props: {
                                                    children: '{{ item.label }}',
                                                },
                                            },
                                            {
                                                name: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
                                                props: {
                                                    color: 'primary',
                                                    size: 'medium',
                                                    // auxLabel is important for the checkboxes that are inside a multiselect
                                                    auxLabel: '{{ item.label }}',
                                                    checked: '{{ __STATES__.FormMultiselect.value.indexOf(item.value) > -1 }}',
                                                    labelPlacement: 'end',
                                                    label: {
                                                        nodes: [
                                                            {
                                                                name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                alias: 'Checkbox Label',
                                                                schemaOverride: {
                                                                    interaction: 'only-editable',
                                                                },
                                                                props: Object.assign(Object.assign({}, (_b = MaterialTypography.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { children: {
                                                                        nodes: [
                                                                            {
                                                                                name: COMPONENT_DSL_NAMES.Text,
                                                                                props: {
                                                                                    children: '{{ item.label }}',
                                                                                },
                                                                            },
                                                                        ],
                                                                    } }),
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                            {
                                                name: COMPONENT_DSL_NAMES.MaterialChip,
                                                props: {
                                                    label: {
                                                        nodes: [
                                                            {
                                                                name: COMPONENT_DSL_NAMES.Text,
                                                                props: {
                                                                    children: '{{ item.label }}',
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    variant: 'filled',
                                                    size: 'medium',
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                        ],
                    } }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { selectProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsMultiselect.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            } }),
    },
    settings: BuilderComponentsFormMultiselectSettings,
};
