import { createStateRequestError } from '../../../../app-audit';
/**
 * Class pass state errors in appropriate format.
 */
export class StateRequestErrorGetter {
    constructor(requestState, onAppAuditNotifications) {
        this.requestState = requestState;
        this.onAppAuditNotifications = onAppAuditNotifications;
    }
    passErrorNotification({ propPath, message, }) {
        this.onAppAuditNotifications([
            createStateRequestError({
                stateID: this.requestState.id,
                propPath,
                message,
            }),
        ]);
    }
}
