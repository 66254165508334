import { hasPropJsCode, transformPropWithJsCode, } from '@builder/schemas';
import { isUndefined } from '@builder/utils';
import { createAndRunFunction } from '../utils/createAndRunFunction';
export class RuntimePropExecutor {
    constructor({ errorGetter, libraries, onAppFunctionError }) {
        this.errorGetter = errorGetter;
        this.libraries = libraries;
        this.onAppFunctionError = onAppFunctionError;
    }
    hasPropJsCode(propValue) {
        return hasPropJsCode(propValue);
    }
    transformPropJsCode({ propValue, propPath, externalState, }) {
        const funcResult = createAndRunFunction(`return ${transformPropWithJsCode(propValue.trim())}`, externalState, {
            libraries: this.libraries,
            onAppFunctionError: this.onAppFunctionError
                ? this.onAppFunctionError(propPath)
                : error => {
                    this.errorGetter.passErrorNotification({ propPath, message: error.message });
                },
        });
        return isUndefined(funcResult) ? null : funcResult;
    }
}
