import React, { useCallback, useState, useMemo } from 'react';

import styled from '@emotion/styled';
import { AddOutlined as AddOutlinedIcon, Image as ImageIcon } from '@mui/icons-material';
import { CircularProgress, css, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { omit } from 'ramda';

import {
  AssetFileWithBackendDataDSL,
  AssetID,
  ASSET_TYPES,
  assetListSelectors,
} from '@builder/schemas';

import { sortAlphabetically } from 'src/features/utils/functions';
import { useUIBuilderAssetViewMode } from 'src/providers';
import {
  EmptyState,
  LeftPanelFloatingContainer,
  CenterLayout,
  Search,
  Button,
} from 'src/shared/components';
import { MESSAGES } from 'src/shared/constants';
import { useAssetsHooks } from 'src/shared/graphql/hooks/useAssetsHooks';

import { AssetsGrid } from './components/AssetsGrid';
import { AssetsHeader } from './components/AssetsHeader';
import { UploadcareFilePicker } from './components/UploadcareFilePicker';

const StyledImageIcon = styled(ImageIcon)`
  font-size: 98px;
  color: ${({ theme }) => theme.palette.grey[200]};
`;

type AssetsManagerProps = {
  selectedAssetID?: AssetID;
  onSelectAsset?: (assetID?: AssetID) => void;
};

const EMPTY_STATE_TITLE = MESSAGES.assetsManager.leftPanel.emptyState.title;
const EMPTY_STATE_DESCRIPTION = MESSAGES.assetsManager.leftPanel.emptyState.description;

export const AssetsManager: React.FC<AssetsManagerProps> = ({ selectedAssetID, onSelectAsset }) => {
  const {
    assetListOptions,
    assetFileArray: unfilteredAssetFileArray,
    assetFileNames,
    createFileAsset,
    createAssetOptions,
    deleteAssetWithConfirmation,
  } = useAssetsHooks();
  const [search, setSearch] = useState<string>('');
  const assetViewMode = useUIBuilderAssetViewMode();
  const assetFileArray = useMemo(
    () =>
      assetListSelectors.getAssetFileArrayByType(unfilteredAssetFileArray, {
        filteredAssetTypes: [ASSET_TYPES.font],
      }) as AssetFileWithBackendDataDSL[],
    [unfilteredAssetFileArray],
  );
  const noAssets = assetFileArray.length === 0;
  const isCenterLoaderDisplayed = createAssetOptions.loading
    ? noAssets
    : assetListOptions.loading && !assetListOptions.previousData;
  const [selectedAssets, setSelectedAssets] = useState<Record<AssetID, AssetID>>({});

  const toggleAssetSelection = useCallback(
    (assetID: AssetID) => {
      if (selectedAssets[assetID]) {
        setSelectedAssets(omit([assetID], selectedAssets));
      } else {
        setSelectedAssets({
          ...selectedAssets,
          [assetID]: assetID,
        });
      }
    },
    [selectedAssets],
  );

  const assetFileListWithTemporaryStateDSL = useMemo(
    () =>
      assetFileArray.map(backendAssetDSL => ({
        ...backendAssetDSL,
        isSelected: Boolean(selectedAssets[backendAssetDSL.id]),
      })),
    [assetFileArray, selectedAssets],
  );

  const filtersAssetFileList = useMemo(
    () =>
      sortAlphabetically(
        assetFileListWithTemporaryStateDSL.filter(assetFileDSL => {
          const name = assetFileDSL?.name?.toLowerCase().trim();
          const target = search.toLowerCase().trim();

          return name.includes(target);
        }),
        'name',
      ),
    [assetFileListWithTemporaryStateDSL, search],
  );

  const pluralizedItemString = useMemo(() => pluralize('item', filtersAssetFileList.length), [
    filtersAssetFileList.length,
  ]);

  return (
    <LeftPanelFloatingContainer overflow="auto">
      <AssetsHeader
        assetFileNames={assetFileNames}
        assetList={assetFileListWithTemporaryStateDSL}
        createFileAsset={createFileAsset}
        createAssetLoading={createAssetOptions.loading}
        deleteAsset={deleteAssetWithConfirmation}
      />
      <Search
        size="small"
        variant="outlined"
        onSearch={setSearch}
        placeholder="Search"
        adornmentPosition="start"
      />
      <Typography>
        <If condition={search}>
          {`${filtersAssetFileList.length}
            ${pluralizedItemString} out of ${assetFileArray.length}`}
        </If>
        <If condition={filtersAssetFileList.length}>
          {filtersAssetFileList.length} {pluralizedItemString}
        </If>
      </Typography>
      <Choose>
        <When condition={isCenterLoaderDisplayed}>
          <CenterLayout>
            <CircularProgress color="primary" />
          </CenterLayout>
        </When>
        <When condition={noAssets && !assetListOptions.loading}>
          <EmptyState
            title={EMPTY_STATE_TITLE}
            icon={<StyledImageIcon />}
            description={EMPTY_STATE_DESCRIPTION}
            addCustomButton={
              <UploadcareFilePicker
                assetFileNames={assetFileNames}
                onFileUpload={createFileAsset}
                mutationLoading={createAssetOptions.loading}
                AddButtonComponent={props => (
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    size="small"
                    css={css`
                      margin-top: 20px;
                    `}
                    variant="contained"
                    color="success"
                    {...props}
                  >
                    Add Assets
                  </Button>
                )}
              />
            }
            data-test="assets.emptyState"
          />
        </When>
        <When condition={search && filtersAssetFileList.length === 0}>
          <EmptyState title="No matching assets" />
        </When>
        <Otherwise>
          <AssetsGrid
            selectedAssetID={selectedAssetID}
            onSelectAsset={onSelectAsset}
            assetList={filtersAssetFileList}
            assetViewMode={assetViewMode}
            toggleAssetSelection={toggleAssetSelection}
          />
        </Otherwise>
      </Choose>
    </LeftPanelFloatingContainer>
  );
};
