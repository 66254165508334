var _a, _b, _c, _d, _e, _f;
import { ListItemSymbol } from '../component-schemas/composite-components/ListItemSymbol';
import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import insetDividersDSL from './style-guide-dsl/DividerStyleGuide/insetDividers.json';
import listDividerDSL from './style-guide-dsl/DividerStyleGuide/listDivider.json';
import middleDividersDSL from './style-guide-dsl/DividerStyleGuide/middleDividers.json';
export const DividerStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiDivider,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialDivider,
    dirName: 'DividerStyleGuide',
    title: 'Divider',
    classNames: [
        '.MuiDivider-root',
        '.MuiDivider-absolute',
        '.MuiDivider-inset',
        '.MuiDivider-fullWidth',
        '.MuiDivider-light',
        '.MuiDivider-middle',
        '.MuiDivider-vertical',
        '.MuiDivider-flexItem',
        '.MuiDivider-withChildren',
        '.MuiDivider-withChildrenVertical',
        '.MuiDivider-textAlignRight',
        '.MuiDivider-textAlignLeft',
        '.MuiDivider-wrapper',
        '.MuiDivider-wrapperVertical',
    ],
    stories: [
        {
            title: 'List divider',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialList,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        style: {
                            backgroundColor: '#000000',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.ListItemSymbol,
                                    props: Object.assign(Object.assign({}, (_a = ListItemSymbol.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { listItemText: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                                    props: {
                                                        style: {
                                                            color: '#ffffff',
                                                        },
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'List Item 1',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        } }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialDivider,
                                    props: {
                                        style: {
                                            backgroundColor: '#ffffff',
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.ListItemSymbol,
                                    props: Object.assign(Object.assign({}, (_b = ListItemSymbol.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { listItemText: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                                    props: {
                                                        style: {
                                                            color: '#ffffff',
                                                        },
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'List Item 2',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        } }),
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: listDividerDSL,
        },
        {
            title: 'Inset dividers',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialList,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        style: {
                            backgroundColor: '#000000',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.ListItemSymbol,
                                    props: Object.assign(Object.assign({}, (_c = ListItemSymbol.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { listItemText: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                                    props: {
                                                        style: {
                                                            color: '#ffffff',
                                                        },
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'List Item 1',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        } }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialDivider,
                                    props: {
                                        variant: 'inset',
                                        style: {
                                            backgroundColor: '#ffffff',
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.ListItemSymbol,
                                    props: Object.assign(Object.assign({}, (_d = ListItemSymbol.schema.predefineds) === null || _d === void 0 ? void 0 : _d.props), { listItemText: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                                    props: {
                                                        style: {
                                                            color: '#ffffff',
                                                        },
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'List Item 2',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        } }),
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: insetDividersDSL,
        },
        {
            title: 'Middle dividers',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialList,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        style: {
                            backgroundColor: '#000000',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.ListItemSymbol,
                                    props: Object.assign(Object.assign({}, (_e = ListItemSymbol.schema.predefineds) === null || _e === void 0 ? void 0 : _e.props), { listItemText: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                                    props: {
                                                        style: {
                                                            color: '#ffffff',
                                                        },
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'List Item 1',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        } }),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialDivider,
                                    props: {
                                        variant: 'middle',
                                        style: {
                                            backgroundColor: '#ffffff',
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.ListItemSymbol,
                                    props: Object.assign(Object.assign({}, (_f = ListItemSymbol.schema.predefineds) === null || _f === void 0 ? void 0 : _f.props), { listItemText: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                                    props: {
                                                        style: {
                                                            color: '#ffffff',
                                                        },
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'List Item 2',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        } }),
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: middleDividersDSL,
        },
    ],
};
