var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { keys, omit } from 'ramda';
import { LAZY_QUERY_ARGS_DSL, RESOURCE_DSL, } from '@builder/schemas';
import { isUndefined } from '@builder/utils';
import { transformRequestStateProps } from '../../app-nodes';
import { useRefetchQuerySubscribe } from '../../event-subscriptions';
import { useRuntimeErrorCallbacks } from '../../providers';
import { UI_BUILDER_MODES, useDashboardHook } from '../../providers/DashboardProvider';
import { createAndRunFunction, useTransformData, useTransformValue } from '../../utils';
import { useUnsubscribePolling } from '../../utils/useUnsubscribePolling';
import { useGetApolloClientByAppState } from './useGetApolloClientByAppState';
const deleteFromWindow = (itemsToDelete) => {
    itemsToDelete.forEach(item => {
        if (typeof window.top === 'object' && window.top !== null) {
            delete window.top[item];
        }
    });
};
const getItemFromWindow = (item) => {
    if (typeof window.top === 'object' && window.top !== null) {
        return window.top[item] || false;
    }
    return false;
};
let isTransformerExecuted = false;
// In this file, there are flags and validations in place to achieve the desired behavior of lazyQueries in various scenarios, which include:
// 1. Retrieve the correct information from the Requests editor upon pressing Run.
// 2. Retrieve the correct information from the Requests editor upon pressing SaveAndRun.
// 3. Modify the query and/or variables and fetch updated information from the Requests editor upon pressing Run.
// 4. Modify the query and/or variables and fetch updated information from the Requests editor upon pressing SaveAndRun.
// 5. Obtain the correct response from the app-engine when executing the request using custom code in the following ways:
/**
 *
 * @example
 * const func = async () => {
 *  const res = await Request.run();
 *  alert(JSON.stringify(res));
 * }
 * func();
 *
 * */
// 6. Obtain the correct response when executing the request using the Run Request option.
// 7. Execute the request from local navigation events.
// 8. Obtain the correct response both in the initial execution of the request and in subsequent ones.
export const useAppEngineLazyQueryState = ({ appStateDSL, resourceListDSL, componentListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    var _a;
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const { mode } = useDashboardHook();
    const isPreviewMode = mode === UI_BUILDER_MODES.preview;
    const handleBarsTransformer = useTransformValue(appRuntimeState, libraries);
    const args = transformRequestStateProps({
        requestStateDSL: appStateDSL,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        propListDSL: LAZY_QUERY_ARGS_DSL,
    }, appRuntimeState, { onAppAuditNotifications }, libraries);
    const transformData = useTransformData({ libraries });
    const appropriateApolloClient = useGetApolloClientByAppState({
        appStateDSL,
        resourceListDSL,
        appRuntimeState,
        componentListDSL,
        assetListDSL,
        propListDSL: RESOURCE_DSL,
    });
    const onCompleted = useCallback(transformedData => {
        var _a, _b, _c, _d;
        const appRuntimeStateList = Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { data: transformedData }) });
        if ((appStateDSL.args.typeOnCompleted === 'code' || !appStateDSL.args.typeOnCompleted) &&
            ((_a = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _a === void 0 ? void 0 : _a.onCompleted)) {
            createAndRunFunction((_b = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _b === void 0 ? void 0 : _b.onCompleted, appRuntimeStateList, { libraries });
        }
        if (appStateDSL.args.typeOnCompleted === 'function' &&
            ((_c = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _c === void 0 ? void 0 : _c.onCompletedFunction)) {
            createAndRunFunction(`return ${(_d = appStateDSL.args.onCompletedFunction) === null || _d === void 0 ? void 0 : _d.split('(')[0]}(data)`, appRuntimeStateList, { libraries });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appStateDSL, appRuntimeState]);
    const onError = useCallback(error => {
        var _a, _b, _c, _d, _e;
        const appRuntimeStateList = Object.assign(Object.assign({}, appRuntimeState), { temporaryState: Object.assign(Object.assign({}, appRuntimeState.temporaryState), { error }) });
        if ((appStateDSL.args.typeOnError === 'code' || !appStateDSL.args.typeOnError) &&
            ((_a = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _a === void 0 ? void 0 : _a.onError)) {
            createAndRunFunction((_b = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _b === void 0 ? void 0 : _b.onError, Object.assign({}, appRuntimeStateList), { libraries });
        }
        if (appStateDSL.args.typeOnError === 'function' && ((_c = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _c === void 0 ? void 0 : _c.onErrorFunction)) {
            createAndRunFunction(`return ${(_e = (_d = appStateDSL === null || appStateDSL === void 0 ? void 0 : appStateDSL.args) === null || _d === void 0 ? void 0 : _d.onErrorFunction) === null || _e === void 0 ? void 0 : _e.split('(')[0]}(error)`, appRuntimeStateList, { libraries });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appRuntimeState, appStateDSL.args]);
    const [queryData, setQueryData] = useState();
    const [queryFunc, queryResult] = useLazyQuery(gql(appStateDSL.args.body), Object.assign(Object.assign({}, omit(['body', 'transformer', 'variables', 'pollInterval'], args)), { onCompleted: data => {
            if (!getItemFromWindow('AppSaved') &&
                (getItemFromWindow('requestSavedAndRun') ||
                    getItemFromWindow('requestRun') ||
                    isPreviewMode)) {
                const transformedData = transformData({ appStateDSL, appRuntimeState }, data);
                setQueryData(transformedData);
                onCompleted(transformedData);
                deleteFromWindow(['requestSavedAndRun', 'requestRun']);
                return transformedData;
            }
            deleteFromWindow(['requestSavedAndRun', 'AppSaved']);
        }, onError: error => {
            if (!getItemFromWindow('AppSaved') &&
                (getItemFromWindow('requestSavedAndRun') ||
                    getItemFromWindow('requestRun') ||
                    isPreviewMode)) {
                setQueryData(error);
                onError(error);
                deleteFromWindow(['requestSavedAndRun', 'requestRun']);
                return error;
            }
            deleteFromWindow(['requestSavedAndRun', 'AppSaved']);
        }, client: appropriateApolloClient, fetchPolicy: 'network-only', nextFetchPolicy: 'network-only', refetchWritePolicy: 'overwrite', initialFetchPolicy: 'network-only' }));
    const transformHandleBarsInVariables = useCallback((variables) => {
        if (isUndefined(variables))
            return variables;
        return keys(variables).reduce((accum, current) => {
            return Object.assign(Object.assign({}, accum), { [current]: handleBarsTransformer(variables[current]) });
        }, {});
    }, [handleBarsTransformer]);
    const handleQueryFunc = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const uniqueQueryOptions = Object.assign(Object.assign({}, options), { requestId: new Date().toISOString() });
        const updatedAppStateDSL = Object.assign(Object.assign({}, appStateDSL), { args: Object.assign(Object.assign({}, appStateDSL.args), uniqueQueryOptions) });
        const variables = transformHandleBarsInVariables(uniqueQueryOptions === null || uniqueQueryOptions === void 0 ? void 0 : uniqueQueryOptions.variables);
        try {
            const data = !queryResult.called || isPreviewMode
                ? yield new Promise((resolve, reject) => {
                    queryFunc({
                        onCompleted: response => {
                            isTransformerExecuted = true;
                            const transformedData = transformData({ appStateDSL: updatedAppStateDSL, appRuntimeState }, response);
                            if (!getItemFromWindow('requestSaved')) {
                                onCompleted(transformedData);
                                setQueryData(transformedData);
                            }
                            deleteFromWindow(['requestSaved']);
                            resolve(transformedData);
                        },
                        onError: error => {
                            if (!getItemFromWindow('requestSaved')) {
                                isTransformerExecuted = true;
                                onError(error);
                                setQueryData(error);
                            }
                            deleteFromWindow(['requestSaved']);
                            reject(error);
                        },
                        variables: Object.assign(Object.assign({}, variables), { requestId: new Date().toISOString() }),
                        query: gql((uniqueQueryOptions === null || uniqueQueryOptions === void 0 ? void 0 : uniqueQueryOptions.body) || appStateDSL.args.body),
                    });
                })
                : yield queryFunc({
                    variables: Object.assign(Object.assign({}, variables), { requestId: new Date().toISOString() }),
                    query: gql((uniqueQueryOptions === null || uniqueQueryOptions === void 0 ? void 0 : uniqueQueryOptions.body) || appStateDSL.args.body),
                }).then(response => {
                    return response.data;
                });
            if (queryResult.error) {
                return queryResult.error;
            }
            const transformedData = isTransformerExecuted
                ? data
                : transformData({ appStateDSL: updatedAppStateDSL, appRuntimeState }, data);
            isTransformerExecuted = false;
            setQueryData(transformedData);
            return transformedData;
        }
        catch (error) {
            return error;
        }
        finally {
            deleteFromWindow(['requestRun', 'requestSavedAndRun', 'requestSaved']);
        }
    });
    useRefetchQuerySubscribe({
        stateID: appStateDSL.id,
        refetch: handleQueryFunc,
    });
    useUnsubscribePolling({
        currentArgs: args,
        queryResult,
    });
    return Object.assign(Object.assign({}, omit(['client', 'observable'], queryResult)), { run: handleQueryFunc, refetch: handleQueryFunc, data: queryData, name: appStateDSL.name, type: appStateDSL.type, query: appStateDSL.args.body, variables: args.variables, fetchPolicy: args.fetchPolicy, errorPolicy: args.errorPolicy, skip: args.skip, pollInterval: parseInt(args === null || args === void 0 ? void 0 : args.pollInterval, 10) || undefined, headers: ((_a = args.context) === null || _a === void 0 ? void 0 : _a.headers) || {}, notifyOn: args.notifyOnNetworkStatusChange });
};
