import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { componentListSelectors, stateListSelectors, createRenderPathData, } from '@builder/schemas';
import { isNilOrEmptyArray } from '@builder/utils';
import { transformIteratorData, transformIteratorName } from '../node-transformers';
import { NodeErrorGetter, NodeValidator } from '../props-transformers';
const Container = ({ appRuntimeState, children }) => {
    return children(appRuntimeState === null || appRuntimeState === void 0 ? void 0 : appRuntimeState.localStates);
};
/**
 * Renders react nodes from dsl nodes.
 */
export class NodeDSLToReactRenderer {
    constructor(options, nodeDSLToReactTransformer, libraries) {
        this.localStateListRef = {};
        this.nodeDSLToReactTransformer = nodeDSLToReactTransformer;
        this.libraries = libraries;
        this.options = options;
    }
    init() {
        this.nodeDSLToReactTransformer.init(this.generateReactNode.bind(this));
    }
    transformIterator(nodeWithIterator, externalState) {
        var _a, _b, _c, _d;
        const { onAppAuditNotifications } = this.options;
        const iteratorData = (_b = (_a = nodeWithIterator === null || nodeWithIterator === void 0 ? void 0 : nodeWithIterator.iterator) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
        const iteratorName = (_d = (_c = nodeWithIterator === null || nodeWithIterator === void 0 ? void 0 : nodeWithIterator.iterator) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : null;
        const errorGetter = new NodeErrorGetter(nodeWithIterator, onAppAuditNotifications);
        const nodeValidator = new NodeValidator(errorGetter, this.options.nodeListDSL, nodeWithIterator);
        if (!iteratorData) {
            return null;
        }
        if (!iteratorName) {
            errorGetter.passErrorNotification({
                propPath: ['iterator', 'name'],
                message: 'Iterator name should be specified ',
            });
            return null;
        }
        const transformedIteratorData = transformIteratorData(iteratorData, externalState, nodeValidator);
        const transformedIteratorName = transformIteratorName(iteratorName, externalState, nodeValidator);
        return { data: transformedIteratorData, name: transformedIteratorName };
    }
    generateReactNodeWithoutContainer(nodeDSL, externalState, renderPathData, baseDSLControlRef) {
        var _a, _b, _c, _d;
        this.localStateListRef[nodeDSL.id] = Object.assign(Object.assign({}, externalState.localState), { symbolProps: undefined });
        const { renderReactComponent, componentListDSL, presentationStates } = this.options;
        const transformedIterator = this.transformIterator(nodeDSL, externalState);
        const isComponentOverlayHidden = componentListSelectors.isComponentOverlayHidden(componentListDSL, { componentName: nodeDSL.name });
        // we should explicitly set iterator name so JS code will not fall when running, for example
        // const fn = ({ iteratorName, ... }) => iteratorName?.value
        // fn({ iteratorName: undefined, ... })
        const defaultLocalState = (transformedIterator === null || transformedIterator === void 0 ? void 0 : transformedIterator.name)
            ? Object.assign(Object.assign({}, externalState.localState), { [transformedIterator.name]: undefined }) : Object.assign({}, externalState.localState);
        let generatedNodes = transformedIterator && !isNilOrEmptyArray(transformedIterator.data)
            ? (_a = transformedIterator.data) === null || _a === void 0 ? void 0 : _a.map((self, index) => {
                const innerRenderPathData = createRenderPathData({
                    nodeID: nodeDSL.id,
                    parentRenderPath: index > 0 ? null : renderPathData,
                    iterationSuffix: index,
                });
                const result = this.nodeDSLToReactTransformer.generateReactComponentWithProps(nodeDSL, Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign({}, defaultLocalState), { [transformedIterator.name]: self, [`${transformedIterator.name}Index`]: index }) }), innerRenderPathData, this.libraries, presentationStates, baseDSLControlRef);
                return result;
            }).flat()
            : this.nodeDSLToReactTransformer.generateReactComponentWithProps(nodeDSL, Object.assign(Object.assign({}, externalState), { localState: defaultLocalState }), renderPathData, this.libraries, presentationStates, baseDSLControlRef);
        (_c = (_b = this.options).onLocalStateUpdate) === null || _c === void 0 ? void 0 : _c.call(_b, {
            localStateList: this.localStateListRef,
            presentationList: this.nodeDSLToReactTransformer.presentationsContainer.getPresentationList(),
        });
        if (((_d = nodeDSL.iterator) === null || _d === void 0 ? void 0 : _d.looperLimitInDevMode) && !this.options.isPreviewMode) {
            generatedNodes = generatedNodes.slice(0, nodeDSL.iterator.looperLimitInDevMode);
        }
        return generatedNodes.map(generatedNode => {
            var _a, _b;
            if (generatedNode.ReactComponent === null) {
                return null;
            }
            const { ReactComponent, transformedProps, renderPathData: subRenderPathData } = generatedNode;
            const hasChanged = (_b = (_a = baseDSLControlRef[nodeDSL.id]) === null || _a === void 0 ? void 0 : _a.changed) !== null && _b !== void 0 ? _b : false;
            return renderReactComponent ? (renderReactComponent({
                component: ReactComponent,
                componentProps: Object.assign({ key: subRenderPathData.renderID }, transformedProps),
                nodeDSL,
                renderPathData: subRenderPathData,
                isComponentOverlayHidden,
                hasChanged,
            })) : (_jsx(ReactComponent, Object.assign({}, transformedProps), subRenderPathData.renderID));
        });
    }
    /**
     * Creates react node from dsl node.
     * @param nodeDSL - node dsl
     * @param externalState - state which will be added to each component (it allows to access js objects in the app dsl, e.g. {{ usersQuery.loading }} )
     */
    generateReactNode(nodeDSL, externalState, renderPathData, baseDSLToControlRef) {
        var _a;
        if (!nodeDSL)
            return null;
        let generatedNodes;
        const { appStates } = this.options;
        if (this.nodeDSLToReactTransformer.shouldBeSkipped(nodeDSL, externalState)) {
            return null;
        }
        const stateArrayDSL = stateListSelectors.getFilteredLocalStateArrayDSL(appStates, (_a = nodeDSL.states) !== null && _a !== void 0 ? _a : []);
        if (stateArrayDSL.length === 0) {
            generatedNodes = this.generateReactNodeWithoutContainer(nodeDSL, externalState, renderPathData, baseDSLToControlRef);
        }
        else {
            generatedNodes = (_jsx(Container, Object.assign({ appRuntimeState: externalState }, { children: addedProps => (_jsx(_Fragment, { children: this.generateReactNodeWithoutContainer(nodeDSL, Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign({}, externalState.localState), addedProps) }), renderPathData, baseDSLToControlRef) })) }), stateArrayDSL.map(({ name }) => name).join(':')));
        }
        return generatedNodes;
    }
}
