import { values } from 'ramda';
import { createSelector } from 'reselect';
import { getContext } from './app-selectors';
import { getAssetArrayDSL } from './asset-list-selectors';
import { getComponentArrayDSL } from './component-list-selectors';
import { getLibraryArrayDSL } from './library-list-selectors';
import { getResourceArrayDSL } from './resource-list-selectors';
import { getStateArrayDSL } from './state-list-selectors';
const getUserComponentsDSL = createSelector((appConfiguration) => appConfiguration.userComponentsDSL, userComponentsDSL => userComponentsDSL);
const getComponentListDSL = createSelector((_, props) => props.componentListDSL, componentListDSL => componentListDSL);
const getComponentDSL = createSelector((_, props) => props.componentDSL, componentDSL => componentDSL);
const getComponentName = createSelector((_, props) => props.componentName, componentName => componentName);
const getAppDSL = createSelector((props) => props.appDSL, appDSL => appDSL);
const getName = createSelector((_, props) => props.name, name => name);
const getParentId = createSelector((_, props) => props.parentID, parentID => parentID);
const getScope = createSelector((_, props) => props.scope, scope => scope);
export const isUserComponent = createSelector(getUserComponentsDSL, getComponentDSL, (userComponentListDSL, componentDSL) => {
    return Boolean(userComponentListDSL[componentDSL.name]);
});
export const isUserComponentUsed = createSelector(getUserComponentsDSL, getComponentListDSL, getComponentName, (userComponentListDSL, componentsListDSL, componentName) => {
    const componentArrayDSL = getComponentArrayDSL(componentsListDSL);
    return componentArrayDSL
        .filter(componentDSL => isUserComponent({ userComponentsDSL: userComponentListDSL }, { componentDSL }))
        .some(componentDSL => {
        return Object.values(componentDSL.schema.symbolNodes).some(symbolNode => symbolNode.name === componentName);
    });
});
export const isNameUsedInDSL = createSelector(getAppDSL, getName, getParentId, getScope, (appDSL, currentName, currentParentId, currentScope) => {
    let isDuplicated = false;
    const assetArrayDSL = getAssetArrayDSL(appDSL.assets || {});
    const libraryArrayDSL = getLibraryArrayDSL(appDSL.libraries || {});
    const resourceArrayDSL = getResourceArrayDSL(appDSL.resources);
    const stateArrayDSL = getStateArrayDSL(appDSL.states);
    const localStates = getContext(appDSL);
    // Assets are global so the name has to be unique.
    isDuplicated = assetArrayDSL.some(asset => {
        const { parentID, name } = asset;
        return name === currentName && parentID === currentParentId;
    });
    // Libraries are global so the name has to be unique.
    isDuplicated = libraryArrayDSL.some(library => {
        const { name } = library;
        return name === currentName;
    });
    // Resources are global so the name has to be unique.
    isDuplicated = resourceArrayDSL.some(resource => {
        const { name } = resource;
        return name === currentName;
    });
    if (!isDuplicated) {
        isDuplicated = [...stateArrayDSL, ...values(localStates || {})].some(state => {
            return state.name === currentName;
        });
    }
    return isDuplicated;
});
