import styled from '@emotion/styled';
import { Button } from '@mui/material';

const EditorInputPreviewWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledButton = styled(Button)`
  text-transform: initial;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  font-weight: 600;
  height: ${({ theme }) => theme.layout.controls.height}px;
`;

export type EditorInputPreviewProps = {
  label: string;
  onClick: () => void;
  'data-test'?: string;
  showFx?: boolean;
};

export const EditorInputPreview = ({
  label,
  onClick,
  showFx,
  'data-test': dataTest,
}: EditorInputPreviewProps): JSX.Element => {
  return (
    <EditorInputPreviewWrapper>
      <StyledButton onClick={onClick} fullWidth variant="contained" data-test={dataTest}>
        {label}
      </StyledButton>
    </EditorInputPreviewWrapper>
  );
};
