import styled from '@emotion/styled';

type TooltipProps = {
  left: number;
  top: number;
};

export const Tooltip = styled.div<TooltipProps>`
  position: absolute;
  padding: 14px;
  max-width: 240px;
  min-width: 240px;
  border-radius: 10px;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0 10px 20px 6px rgba(34, 60, 80, 0.2);
  user-select: none;
  z-index: 10000;
`;
