import { useMemo, useContext, createContext } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useRollbar } from '@rollbar/react';
import { values } from 'ramda';
import { Provider, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Dispatch } from 'redux';

import { AppRuntimeStateList, PresentationData } from '@builder/app-engine';
import {
  NodeList,
  NodeListDSL,
  AppDSL,
  StateListDSL,
  ComponentDSL,
  ComponentDSLNameTypes,
  ComponentListDSL,
  NodeDSL,
  NodeID,
  AuthListDSL,
  RenderPathData,
  AUTH_TYPES,
  ApiTokenAuthDSL,
  NodeElementData,
  NodeElementDataRecords,
  UserComponentListDSL,
  ThemeDSL,
  MediaQueryListDSL,
  AppConfiguration,
  WebNativeAuthDSL,
  AssetListDSL,
  appDSLSelectors,
  LibraryListDSL,
  ResourceListDSL,
  CSS,
  FontsDSL,
  ThemeFrameworkSettingsDSL,
  STATE_SCOPES,
  AppVersion,
  SchemaDSLVersion,
} from '@builder/schemas';
import { ERROR_SCOPES, SystemError } from '@builder/utils';

import { useDialogState } from '../DialogProvider';
import { DraftEngineContext } from '../DraftEngineProvider';
import { useEventEmitter } from '../EventEmitterProvider';
import { useLocalWidth } from 'src/features/utils/useLocalWidth';
import { useCurrentWorkspaceID } from 'src/shared/hooks';
import {
  Store,
  AppEvents,
  DashboardAnimationState,
  DashboardState,
  getStore,
  ThemeManager,
} from 'src/store';

export const ReduxProvider: React.FC = ({ children }) => {
  const rollbarInstance = useRollbar();
  const emitter = useEventEmitter();
  const { draftEngineRef } = useContext(DraftEngineContext);
  const { openDialog } = useDialogState();
  const { workspaceID } = useCurrentWorkspaceID();

  const store = useMemo(
    () => getStore(emitter, draftEngineRef, rollbarInstance, openDialog, workspaceID),
    [emitter, draftEngineRef, rollbarInstance, openDialog, workspaceID],
  );

  return <Provider store={store}>{children}</Provider>;
};

export const useUIBuilderState = (): Store['uiBuilder'] => {
  const uiBuilderState = useSelector<Store, Store['uiBuilder']>(
    state => state.uiBuilder,
    shallowEqual,
  );

  return uiBuilderState;
};

export const useIsDevModeEnabled = (): boolean => {
  const devModeEnabled = useSelector<Store, boolean>(
    state => state.uiBuilder.devModeEnabled,
    shallowEqual,
  );

  return useMemo(() => devModeEnabled, [devModeEnabled]);
};

export const useIsSaveButtonEnabled = (): boolean => {
  const isSaveButtonEnabledState = useSelector<Store, boolean>(
    state => state.uiBuilder.isSaveButtonDisabled,
    shallowEqual,
  );

  return isSaveButtonEnabledState;
};

export const useHasUpdates = (): { hasUpdates: boolean } => {
  const hasUpdates = useSelector<Store, boolean>(state => state.uiBuilder.hasUpdates, shallowEqual);

  return useMemo(
    () => ({
      hasUpdates,
    }),
    [hasUpdates],
  );
};

export const useUIBuilderMode = (): Store['uiBuilder']['mode'] => {
  const uiBuilderMode = useSelector<Store, Store['uiBuilder']['mode']>(
    state => state.uiBuilder.mode,
    shallowEqual,
  );

  return useMemo(() => uiBuilderMode, [uiBuilderMode]);
};

export const useUIBuilderLeftPanel = (): Store['uiBuilder']['leftPanel'] => {
  const leftPanel = useSelector<Store, Store['uiBuilder']['leftPanel']>(
    state => state.uiBuilder.leftPanel,
    shallowEqual,
  );

  return leftPanel;
};

export const useUIBuilderLeftPanelCurrentRoute = (): Store['uiBuilder']['leftPanel']['currentRoute'] => {
  const currentRoute = useSelector<Store, Store['uiBuilder']['leftPanel']['currentRoute']>(
    state => state.uiBuilder.leftPanel.currentRoute,
    shallowEqual,
  );

  return currentRoute;
};

export const useUIBuilderRightPanel = (): Store['uiBuilder']['rightPanel'] => {
  const rightPanel = useSelector<Store, Store['uiBuilder']['rightPanel']>(
    state => state.uiBuilder.rightPanel,
    shallowEqual,
  );

  return rightPanel;
};

export const useUIBuilderAssetViewMode = (): Store['uiBuilder']['assetViewMode'] => {
  const assetViewMode = useSelector<Store, Store['uiBuilder']['assetViewMode']>(
    state => state.uiBuilder.assetViewMode,
    shallowEqual,
  );

  return assetViewMode;
};

export const useUIBuilderEditMode = (): Store['uiBuilder']['editMode'] => {
  const editMode = useSelector<Store, Store['uiBuilder']['editMode']>(
    state => state.uiBuilder.editMode,
    shallowEqual,
  );

  return editMode;
};

export const useUIBuilderUsersSessions = (): Store['uiBuilder']['usersSessions'] => {
  const usersSessions = useSelector<Store, Store['uiBuilder']['usersSessions']>(
    state => state.uiBuilder.usersSessions,
    shallowEqual,
  );

  return usersSessions;
};

export const useLayoutNodesIds = (): Store['uiBuilder']['layoutNodesIds'] => {
  const layoutNodesIds = useSelector<Store, Store['uiBuilder']['layoutNodesIds']>(
    state => state.uiBuilder.layoutNodesIds,
    shallowEqual,
  );

  return layoutNodesIds;
};

export const useUIBuilderPresentationStates = (): Store['uiBuilder']['visibleNodePresentation'] => {
  const presentationStates = useSelector<Store, Store['uiBuilder']['visibleNodePresentation']>(
    state => state.uiBuilder.visibleNodePresentation,
    shallowEqual,
  );

  return presentationStates;
};

/**
 * Returns full app DSL state.
 */
export const useDashboardState = (): DashboardState => {
  const dashboardStateContext = useSelector<Store, DashboardState>(state => {
    return state.dashboard;
  }, shallowEqual);

  return dashboardStateContext;
};

export const useAppConfiguration = (): AppConfiguration => {
  const appConfiguration = useSelector<Store, AppConfiguration>(state => {
    return state.dashboard.appConfiguration;
  }, shallowEqual);

  return appConfiguration;
};

export const useAppVersion = (): AppVersion => {
  const appVersion = useSelector<Store, AppVersion>(state => {
    return state.dashboard.appConfiguration.appVersion;
  }, shallowEqual);

  return appVersion;
};

export const useSchemaDSLVersion = (): SchemaDSLVersion => {
  const schemaDSLVersion = useSelector<Store, SchemaDSLVersion>(state => {
    return state.dashboard.appConfiguration.schemaDSLVersion;
  }, shallowEqual);

  return schemaDSLVersion;
};

const appConfiguration = (state: Store) => state.dashboard.appConfiguration || {};
const appDSLRaw = (state: Store) => state.dashboard.appConfiguration.appDSL;
const appDSLSelector = createSelector(appDSLRaw, appDSL => appDSL);

const appConfigurationWithStates = createSelector(appConfiguration, currentAppConfiguration => {
  const oldAppDSL = currentAppConfiguration.appDSL;
  const newStates = Object.keys(oldAppDSL.nodes).reduce((allStates, key) => {
    if (oldAppDSL.nodes[key]?.context && Object.keys(oldAppDSL.nodes[key]?.context || {}).length) {
      return {
        ...allStates,
        ...oldAppDSL.nodes[key].context,
      };
    }

    return allStates;
  }, oldAppDSL.states || {});

  return {
    ...currentAppConfiguration,
    appDSL: {
      ...oldAppDSL,
      states: newStates,
    },
  };
});

export const useAppConfigurationWithStates = (): AppConfiguration => {
  const result = useSelector<Store, AppConfiguration>(appConfigurationWithStates, shallowEqual);

  return result;
};

export const useAppDSL = (): AppDSL => {
  const appDSL = useSelector<Store, AppDSL>(appDSLSelector, shallowEqual);

  return appDSL;
};

export const useAssetListDSL = (): AssetListDSL => {
  const assetListDSL = useSelector<Store, AssetListDSL>(
    state => state.dashboard.appConfiguration.appDSL.assets || {},
    shallowEqual,
  );

  return assetListDSL;
};

export const useFontsDSL = (): FontsDSL => {
  const fontsDSL = useSelector<Store, FontsDSL>(
    state => state.dashboard.appConfiguration.appDSL.theme?.fonts || {},
    shallowEqual,
  );

  return fontsDSL;
};

export const useThemeManagerState = (): ThemeManager => {
  const themeManagerState = useSelector<Store, ThemeManager>(
    state => state.dashboard.themeManager,
    shallowEqual,
  );

  return themeManagerState;
};

const nodesSelector = (state: Store) => state.dashboard.appConfiguration.appDSL.nodes;

export const useNodeListDSL = (): NodeListDSL => {
  const nodeListDSL = useSelector<Store, NodeListDSL>(nodesSelector, shallowEqual);

  return nodeListDSL;
};

export const useLibraryListDSL = (): LibraryListDSL => {
  const libraryListDSL = useSelector<Store, LibraryListDSL>(
    state => state.dashboard.appConfiguration.appDSL.libraries || {},
    shallowEqual,
  );

  return libraryListDSL;
};

export const useNodeByID = (id: NodeID): NodeDSL => {
  const nodeListDSL = useNodeListDSL();
  const nodeDSL = useMemo(() => nodeListDSL[id], [id, nodeListDSL]);

  return nodeDSL;
};

const selectRawState = (state: Store) => state.dashboard.appConfiguration.appDSL || {};

const selectTransformedStates = createSelector(selectRawState, rawState => {
  return Object.keys(rawState.nodes).reduce((allStates, key) => {
    if (rawState.nodes[key]?.context && Object.keys(rawState.nodes[key]?.context || {})?.length) {
      return {
        ...allStates,
        ...rawState.nodes[key].context,
      };
    }

    return allStates;
  }, rawState.states || {});
});

export const useAppDSLStates = (): StateListDSL => {
  const states = useSelector<Store, StateListDSL>(selectTransformedStates, shallowEqual);

  return states;
};

export const useAppDSLGlobalStates = (): StateListDSL => {
  const appDSLStates = useAppDSLStates();

  return useMemo(
    () =>
      values(appDSLStates).reduce((accum, current) => {
        if (current.scope === STATE_SCOPES.global) {
          return {
            ...accum,
            [current.id]: current,
          };
        }

        return accum;
      }, {}),
    [appDSLStates],
  );
};

export const useResourceListDSL = (): ResourceListDSL => {
  const resourceListDSL = useSelector<Store, ResourceListDSL>(
    state => state.dashboard.appConfiguration.appDSL.resources,
    shallowEqual,
  );

  return resourceListDSL;
};

const componentsSelector = (state: Store) => state.dashboard.components;
const userComponentsDSLSelector = (state: Store) =>
  state.dashboard.appConfiguration.userComponentsDSL;
const componentListDSLSelector = createSelector(
  componentsSelector,
  userComponentsDSLSelector,
  (components, userComponentsDSL) => ({
    ...components,
    ...userComponentsDSL,
  }),
);

export const useComponentListDSL = (): ComponentListDSL => {
  // Memorize the result of the hook using useSelector and shallowEqual
  const componentListDSL = useSelector<Store, ComponentListDSL>(
    componentListDSLSelector,
    shallowEqual,
  );

  return componentListDSL;
};

export const useUserComponentListDSL = (): UserComponentListDSL => {
  const userComponentListDSL = useSelector<Store, UserComponentListDSL>(
    state => state.dashboard.appConfiguration.userComponentsDSL,
    shallowEqual,
  );

  return userComponentListDSL;
};

export const useComponentDSL = (name: ComponentDSLNameTypes): ComponentDSL => {
  const componentListDSL = useComponentListDSL();
  const componentDSL = componentListDSL[name];

  if (!componentDSL) {
    throw new SystemError(ERROR_SCOPES.dashboard, `Component with name ${name} wasn't found.`);
  }

  return componentDSL;
};

export const useDashboardOperations = createSelector(
  useDashboardState,
  dashboard => dashboard.operations,
);
export const useOperationsSelectedID = createSelector(
  useDashboardOperations,
  operations => operations.selectedID,
);

export const useSelectedNodeID = (): DashboardState['operations']['selectedID'] => {
  const selectedID = useSelector<Store, DashboardState['operations']['selectedID']>(
    state => state.dashboard.operations.selectedID,
    shallowEqual,
  );
  if (Array.isArray(selectedID)) return selectedID.filter(item => item !== null);

  return selectedID;
};

export const useNodeContainerParentID = (childNodeID: NodeID): NodeID | null => {
  const childNode = useNodeByID(childNodeID);
  const nodeList = useNodeListDSL();

  const parentID = useMemo(() => {
    if (!childNode || !childNode.parentID) return null;

    const parentNode = nodeList[childNode.parentID];

    if (parentNode && parentNode.name === 'BuilderComponentsBox') {
      return parentNode.id;
    }

    return null;
  }, [childNode, nodeList]);

  return parentID;
};

export const useNodeIndexInContainer = (childNodeID: NodeID): number | null => {
  const nodeList: NodeList = useNodeListDSL();
  const childNode = nodeList[childNodeID];

  const index = useMemo(() => {
    if (!childNode || !childNode.parentID) return null;

    const parentNode = nodeList[childNode.parentID];

    if (
      parentNode &&
      parentNode.name === 'BuilderComponentsBox' &&
      parentNode.props &&
      parentNode.props.children &&
      parentNode.props.children.nodes
    ) {
      const positionIndex = parentNode.props.children.nodes.indexOf(childNode.id);
      if (positionIndex !== -1) {
        return positionIndex;
      }
    }

    return null;
  }, [childNode, nodeList]);

  return index;
};

export const useTotalNodesInContainer = (childNodeID: NodeID): number | null => {
  const nodeList: NodeList = useNodeListDSL();
  const childNode = nodeList[childNodeID];

  const totalNodes = useMemo(() => {
    if (!childNode || !childNode.parentID) return null;

    const parentNode = nodeList[childNode.parentID];

    if (
      parentNode &&
      parentNode.name === 'BuilderComponentsBox' &&
      parentNode.props &&
      parentNode.props.children &&
      parentNode.props.children.nodes
    ) {
      const containerNodesLength = parentNode.props.children.nodes.length;
      if (containerNodesLength !== 0) {
        return containerNodesLength;
      }
    }

    return null;
  }, [childNode, nodeList]);

  return totalNodes;
};

export const useIsSelectedNodeCreated = (): boolean => {
  const selectedID = useSelector<Store, DashboardState['operations']['selectedID']>(
    state => state.dashboard.operations.selectedID,
    shallowEqual,
  );

  const lastCreatedID = useSelector<Store, DashboardState['operations']['lastCreatedID']>(
    state => state.dashboard.operations.lastCreatedID,
    shallowEqual,
  );

  const isSelectedNodeCreated = useMemo(() => {
    return selectedID === lastCreatedID;
  }, [selectedID, lastCreatedID]);

  return isSelectedNodeCreated;
};

export const useFocusedNodeRenderID = (): DashboardState['operations']['focusedNodeRenderID'] => {
  const focusedNodeRenderID = useSelector<
    Store,
    DashboardState['operations']['focusedNodeRenderID']
  >(state => state.dashboard.operations.focusedNodeRenderID, shallowEqual);

  return focusedNodeRenderID;
};

export const useDndInProgress = (): DashboardState['operations']['dndInProgress'] => {
  const dndInProgress = useSelector<Store, DashboardState['operations']['dndInProgress']>(
    state => state.dashboard.operations.dndInProgress,
    shallowEqual,
  );

  return dndInProgress;
};

export const useDndPreview = (): DashboardState['operations']['dndPreview'] => {
  const dndPreview = useSelector<Store, DashboardState['operations']['dndPreview']>(
    state => state.dashboard.operations.dndPreview,
    shallowEqual,
  );

  return dndPreview;
};

export const useIsDndInProgress = (): boolean => {
  const dndInProgress = useDndInProgress();
  const isDndInProgress = Boolean(dndInProgress);
  return useMemo(() => isDndInProgress, [isDndInProgress]);
};

export const useHoveredIDs = (): DashboardState['operations']['hoveredID'] => {
  const hoveredID = useSelector<Store, DashboardState['operations']['hoveredID']>(
    state => state.dashboard.operations.hoveredID,
    shallowEqual,
  );

  return hoveredID;
};

export const useIsHovered = (id: NodeID): boolean => {
  const hoveredID = useHoveredIDs();
  return useMemo(() => hoveredID === id, [hoveredID, id]);
};

export const useDashboardSelectedID = (): DashboardState['operations']['selectedID'] => {
  const selectedID = useSelector<Store, DashboardState['operations']['selectedID']>(
    state => state.dashboard.operations.selectedID,
    shallowEqual,
  );

  return selectedID;
};

export const useDashboardHistory = (): DashboardState['history'] => {
  const history = useSelector<Store, DashboardState['history']>(
    state => state.dashboard.history,
    shallowEqual,
  );

  const memoizedHistory = useMemo(() => history, [history]);

  return memoizedHistory;
};

export const useAppDSLSettings = (): AppDSL['settings'] => {
  const { newSettings } = useLocalWidth();

  const settings = useSelector<Store, AppDSL['settings']>(state => {
    const { settings: oldSettings } = state.dashboard.appConfiguration.appDSL;

    return newSettings({ oldSettings });
  }, shallowEqual);

  return settings;
};

export const useAppDSLSize = (): AppDSL['settings'] => {
  const settings = useSelector<Store, AppDSL['settings']>(
    state => state.dashboard.appConfiguration.appDSL.settings,
    shallowEqual,
  );

  return useMemo(() => settings, [settings]);
};

export const useUserApiEndpoint = (): string | undefined => {
  const apiEndpoint = useSelector<Store, string | undefined>(
    state => appDSLSelectors.getAuthResourceEndpoint(state.dashboard.appConfiguration.appDSL),
    shallowEqual,
  );

  return apiEndpoint;
};

export const useAuthListDSL = (): AuthListDSL => {
  const auth = useSelector<Store, AuthListDSL>(
    state => appDSLSelectors.getAuthResourceAuthListDSL(state.dashboard.appConfiguration.appDSL),
    shallowEqual,
  );

  return auth ?? {};
};

export const useAuthList = (): {
  apiAuthToken: string | undefined;
  authProfileID: string | undefined;
} => {
  const apiAuthToken = useSelector<Store, string | undefined>(state => {
    const authListDSL = appDSLSelectors.getAuthResourceAuthListDSL(
      state.dashboard.appConfiguration.appDSL,
    );

    const apiAuth = Object.values(authListDSL).find(
      el => el.type === AUTH_TYPES.token,
    ) as ApiTokenAuthDSL;

    return apiAuth?.apiAuthToken;
  }, shallowEqual);

  const authProfileID = useSelector<Store, string | undefined>(state => {
    const authListDSL = appDSLSelectors.getAuthResourceAuthListDSL(
      state.dashboard.appConfiguration.appDSL,
    );

    const apiAuth = Object.values(authListDSL).find(
      el => el.type === AUTH_TYPES.webNative,
    ) as WebNativeAuthDSL;

    return apiAuth?.authProfileID;
  }, shallowEqual);

  const authList = useMemo(() => {
    return { apiAuthToken, authProfileID };
  }, [apiAuthToken, authProfileID]);

  return authList;
};

export const useApiAuthToken = (): string | undefined => {
  const apiAuthToken = useSelector<Store, string | undefined>(state => {
    const authListDSL = appDSLSelectors.getAuthResourceAuthListDSL(
      state.dashboard.appConfiguration.appDSL,
    );

    const apiAuth = Object.values(authListDSL).find(
      el => el.type === AUTH_TYPES.token,
    ) as ApiTokenAuthDSL;

    return apiAuth?.apiAuthToken;
  }, shallowEqual);

  return apiAuthToken;
};

export const useAppDSLFrameworkSettings = (): ThemeFrameworkSettingsDSL => {
  const theme = useSelector<Store, ThemeFrameworkSettingsDSL | undefined>(
    state => state.dashboard.appConfiguration.appDSL.theme?.frameworkSettings,
  );

  return theme ?? {};
};

export const useAppDSLTheme = (): ThemeDSL => {
  const theme = useSelector<Store, AppDSL['theme']>(
    state => state.dashboard.appConfiguration.appDSL.theme,
  );

  return theme ?? {};
};

export const useMediaQueryListDSL = (): MediaQueryListDSL => {
  const mediaQueries = useSelector<Store, MediaQueryListDSL | undefined>(
    state => state.dashboard.appConfiguration.appDSL.theme?.mediaQueries,
  );

  return mediaQueries ?? {};
};

/**
 * Returns animation state.
 */
export const useDashboardAnimState = (): DashboardAnimationState => {
  const dashboardAnimStateContext = useSelector<Store, DashboardAnimationState>(
    state => ({
      dndInProgress: state.dashboard.operations.dndInProgress,
      dndPreview: state.dashboard.operations.dndPreview,
      dndOverNavigation: state.dashboard.operations.dndOverNavigation,
    }),
    shallowEqual,
  );

  const dashboardAnimState = useMemo(() => {
    return dashboardAnimStateContext;
  }, [dashboardAnimStateContext]);

  return dashboardAnimState;
};

export const useUserAppRuntimeState = (): Store['userAppRuntimeState'] => {
  const userAppRuntimeState = useSelector<Store, Store['userAppRuntimeState']>(
    state => state.userAppRuntimeState,
    shallowEqual,
  );

  return userAppRuntimeState;
};

export const useUserAppErrors = (): Store['userAppError'] => {
  const userAppErrorState = useSelector<Store, Store['userAppError']>(state => state.userAppError);

  const { errors, warnings } = useMemo(() => {
    return userAppErrorState;
  }, [userAppErrorState]);

  const hasError = useMemo(() => {
    return errors?.length;
  }, [errors]);

  const hasWarning = useMemo(() => {
    return warnings?.length;
  }, [warnings]);

  return useMemo(() => {
    return {
      ...userAppErrorState,
      errors,
      hasError,
      hasWarning,
    };
  }, [userAppErrorState, errors, hasError, hasWarning]);
};

export const useNodeAppRuntimeState = (
  nodeID?: NodeID,
): Pick<AppRuntimeStateList, 'globalState' | 'localState' | 'presentations'> => {
  const userAppRuntimeState = useUserAppRuntimeState();

  const globalState = useMemo(() => {
    return userAppRuntimeState.globalState ?? {};
  }, [userAppRuntimeState.globalState]);

  const localState = useMemo(() => {
    return nodeID ? userAppRuntimeState.localStateList?.[nodeID] ?? {} : undefined;
  }, [userAppRuntimeState.localStateList, nodeID]);

  const presentations = useMemo(() => {
    return nodeID
      ? userAppRuntimeState.presentationList?.[nodeID] ?? ({} as PresentationData)
      : undefined;
  }, [userAppRuntimeState.presentationList, nodeID]);

  return {
    globalState,
    localState,
    presentations,
  };
};

export const useAppNodeElements = (): NodeElementDataRecords => {
  const records = useSelector<Store, Store['userAppNodeElements']['elements']>(
    state => state.userAppNodeElements.elements,
    shallowEqual,
  );

  return records;
};

export const useAppNodeElementsByNodeID = (): Store['userAppNodeElements']['elementsByNodeID'] => {
  const records = useSelector<Store, Store['userAppNodeElements']['elementsByNodeID']>(
    state => state.userAppNodeElements.elementsByNodeID,
    shallowEqual,
  );

  return records;
};

export const useAppNodeElementByRenderID = (
  renderID: RenderPathData['renderID'],
): NodeElementData => {
  const record = useSelector<Store, NodeElementData>(
    state => state.userAppNodeElements.elements[renderID],
    shallowEqual,
  );

  return record;
};

export const useAppNodeElementByNodeID = (nodeID: NodeID): NodeElementData => {
  const record = useSelector<Store, NodeElementData>(
    state => state.userAppNodeElements.elementsByNodeID[nodeID],
    shallowEqual,
  );

  return record;
};

export const useCopyBuffer = (): DashboardState['copyBuffer'] => {
  const copyBuffer = useSelector<Store, DashboardState['copyBuffer']>(
    state => state.dashboard.copyBuffer,
  );

  return copyBuffer;
};

export const useLayout = (): DashboardState['layout'] => {
  const layout = useSelector<Store, DashboardState['layout']>(state => {
    return state.dashboard.layout;
  });

  return layout;
};

export const useMediaQueryCSS = (): CSS => {
  const mediaQueryCSS = useSelector<Store, CSS>(
    state => state.dashboard.appConfiguration.appDSL.theme?.css?.mediaQueries || '',
  );

  return mediaQueryCSS;
};

export const useComponentCSS = (componentName: ComponentDSLNameTypes): CSS => {
  const componentCSS = useSelector<Store, CSS>(
    state => state.dashboard.appConfiguration.appDSL.theme?.css?.components?.[componentName] || '',
  );

  return componentCSS;
};

/**
 * Updates state both app and animation states.
 */
export const useAppDispatch = (): Dispatch<AppEvents> => {
  const appDispatchContext = useDispatch<Dispatch<AppEvents>>();

  return appDispatchContext;
};

const ReduxContext = createContext<Store | null>(null);
export const useReduxState = (): Store => {
  const state = useContext(ReduxContext);
  if (state === null) {
    throw new Error('useReduxState must be used within a ReduxProvider');
  }

  return state;
};
