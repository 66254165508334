import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basic from './style-guide-dsl/ToggleButtonStyleGuide/basic.json';
import color from './style-guide-dsl/ToggleButtonStyleGuide/color.json';
import size from './style-guide-dsl/ToggleButtonStyleGuide/size.json';
export const ToggleButtonStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiToggleButton,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButton,
    dirName: 'ToggleButtonStyleGuide',
    title: 'ToggleButton',
    classNames: [
        '.MuiToggleButton-root',
        '.Mui-disabled',
        '.Mui-selected',
        '.MuiToggleButton-standard',
        '.MuiToggleButton-primary',
        '.MuiToggleButton-secondary',
        '.MuiToggleButton-sizeSmall',
        '.MuiToggleButton-sizeMedium',
        '.MuiToggleButton-sizeLarge',
    ],
    stories: [
        {
            title: 'Basic',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            cases: [
                {
                    props: {
                        color: 'primary',
                    },
                },
            ],
            generatedAppDSL: basic,
        },
        {
            title: 'Color',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                    },
                },
                {
                    props: {
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        color: 'warning',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: color,
        },
        {
            title: 'Size',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        size: 'small',
                    },
                },
                {
                    props: {
                        size: 'standart',
                    },
                },
                {
                    props: {
                        size: 'large',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: size,
        },
    ],
};
