import { AssetListDSL, assetListSelectors } from '@builder/schemas';
import { isString } from '@builder/utils';

export const assetNameUniqueValidator = (assetListDSL?: AssetListDSL, initialValue?: string) => (
  value: unknown,
): string | undefined => {
  if (!isString(value) || !assetListDSL) {
    return undefined;
  }

  if (initialValue && initialValue === value) {
    return undefined;
  }

  if (assetListSelectors.isAssetNameDSLNotUnique(assetListDSL, { name: value })) {
    return `Name is not unique`;
  }
};
