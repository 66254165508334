import { mapObjIndexed, omit } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_9_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.9.1';
    }
    getChangeList() {
        return [
            `[Accordion] add properties setting: "Summary", "Details", "Actions", "Disabled", "Expanded" and all the others regular props`,
            `[Accordion Summary] add the prop "Expand Icon" and all the others regular props`,
            `[Accordion Detail] add all regular props`,
            `[Accordion Actions] add the prop "Disable Spacing" and all the others regular props`,
        ];
    }
    migrateAppDSL(appDSL) {
        const { nodes: nodeListDSL, states: stateListDSL } = appDSL;
        let createdStateListDSL = {};
        let updatedNodeListDSL = {};
        const migratedNodeListDSL = mapObjIndexed((nodeDSL, propName) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            if (nodeDSL.name === 'MaterialAccordion') {
                const accordionStateName = this.generateSecuredStateName(Object.assign(Object.assign({}, stateListDSL), createdStateListDSL), 'accordion');
                const createdStatesCounter = Object.values(createdStateListDSL).length + 1;
                const accordionStateID = `m0_9_1_accordion_state_${createdStatesCounter}`;
                const accordionState = {
                    scope: 'global',
                    type: 'boolean',
                    variant: 'open-close',
                    name: accordionStateName,
                    defaultValue: Boolean((_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.defaultExpanded),
                    predefinedName: 'accordion',
                    id: accordionStateID,
                };
                createdStateListDSL = Object.assign(Object.assign({}, createdStateListDSL), { [accordionState.id]: accordionState });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const accordionSummaryID = (_d = (_c = (_b = nodeDSL.props) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.nodes) === null || _d === void 0 ? void 0 : _d[0];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const accordionDetailsID = (_g = (_f = (_e = nodeDSL.props) === null || _e === void 0 ? void 0 : _e.children) === null || _f === void 0 ? void 0 : _f.nodes) === null || _g === void 0 ? void 0 : _g[1];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const accordionActionsID = (_k = (_j = (_h = nodeDSL.props) === null || _h === void 0 ? void 0 : _h.children) === null || _j === void 0 ? void 0 : _j.nodes) === null || _k === void 0 ? void 0 : _k[2];
                const accordionSummaryDSL = accordionSummaryID
                    ? nodeListDSL[accordionSummaryID]
                    : undefined;
                const updatedAccordionSummaryDSL = accordionSummaryDSL
                    ? Object.assign(Object.assign({}, accordionSummaryDSL), { props: Object.assign(Object.assign({}, accordionSummaryDSL.props), { onClick: {
                                actionType: 'customCode',
                                args: {
                                    code: `${accordionState.name}.toggle()`,
                                },
                            } }) }) : undefined;
                const accordionActions = accordionActionsID
                    ? nodeListDSL[accordionActionsID]
                    : undefined;
                const updatedAccordionActionsDSL = accordionActions
                    ? Object.assign(Object.assign({}, accordionActions), { props: Object.assign(Object.assign({}, accordionActions.props), { disableSpacing: false }) }) : undefined;
                updatedNodeListDSL = Object.assign(Object.assign(Object.assign({}, updatedNodeListDSL), (updatedAccordionSummaryDSL
                    ? { [updatedAccordionSummaryDSL.id]: updatedAccordionSummaryDSL }
                    : {})), (updatedAccordionActionsDSL
                    ? { [updatedAccordionActionsDSL.id]: updatedAccordionActionsDSL }
                    : {}));
                const newAccordionNodeDSL = Object.assign(Object.assign({}, nodeDSL), { name: 'AccordionSymbol', states: [
                        {
                            stateID: accordionStateID,
                            required: true,
                        },
                    ], props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, omit(['defaultExpanded', 'children'], nodeDSL.props)), { expanded: `{{ ${accordionState.name}.isOpened }}` }), (accordionSummaryID
                        ? {
                            summary: {
                                nodes: [accordionSummaryID],
                            },
                        }
                        : {})), (accordionDetailsID
                        ? {
                            details: {
                                nodes: [accordionDetailsID],
                            },
                        }
                        : {})), (accordionActionsID
                        ? {
                            actions: {
                                nodes: [accordionActionsID],
                            },
                        }
                        : {})) });
                return newAccordionNodeDSL;
            }
            return nodeDSL;
        }, nodeListDSL);
        const migratedStateListDSL = Object.assign(Object.assign({}, stateListDSL), createdStateListDSL);
        return Object.assign(Object.assign({}, appDSL), { nodes: Object.assign(Object.assign({}, migratedNodeListDSL), updatedNodeListDSL), states: migratedStateListDSL });
    }
}
export const migration_0_9_1 = new Migration_0_9_1();
