export const callBackPrefix = [
  'oncompleted',
  'onerror',
  'fetchpolicy',
  'errorpolicy',
  'skip',
  'notifyonnetworkstatuschange',
  'pollinterval',
  'refetchqueries',
  'optimisticresponse',
  'awaitrefetchqueries',
  'ignoreresults',
];

export const VARIABLES = 'variables';
export const COLUMNS = 'columns';

export const emptyIssue = {
  type: '',
  context: {
    type: '',
    targetID: '',
    propPath: [''],
    message: '',
    fullPath: [''],
    nodeID: '',
  },
  key: '',
  targetID: '',
};
