import { useCallback } from 'react';

import { AppConfiguration } from '@builder/schemas';
import { downloadObjectAsJSONFile } from '@builder/utils';

import { useCurrentWorkspace } from '../graphql/hooks';

type UseDownloadConfigurationReturn = {
  downloadConfiguration: (appConfiguration: AppConfiguration) => void;
};

export const useDownloadConfiguration = (): UseDownloadConfigurationReturn => {
  const { workspace } = useCurrentWorkspace();

  const downloadConfiguration = useCallback(
    async (appConfiguration: AppConfiguration) => {
      const dateTime = new Date().toISOString();
      const fileName = `${workspace?.name || 'app'}.dsl.${dateTime}.json`;

      downloadObjectAsJSONFile(appConfiguration, fileName);
    },
    [workspace],
  );

  return { downloadConfiguration };
};
