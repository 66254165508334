import React, { useCallback } from 'react';

import { WarningAmberOutlined as WarningAmberOutlinedIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { AppConfiguration } from '@builder/schemas';

import { DialogContentWithIcon, VersionText } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';
import { useMigrations, useDownloadConfiguration } from 'src/shared/hooks';

const DIALOG_ID = DASHBOARD_DIALOGS.CHECK_DSL_VERSION_DIALOG_ID;

type CheckDSLVersionDialogArgs = {
  appConfiguration: AppConfiguration;
};

export const CheckDSLVersionDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<CheckDSLVersionDialogArgs>(DIALOG_ID);
  const targetConfiguration = args?.appConfiguration;
  const { schemaDSLVersion } = useMigrations(targetConfiguration);
  const { downloadConfiguration } = useDownloadConfiguration();

  const closeCheckDSLVersionDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const downloadCurrentDSL = useCallback(() => {
    downloadConfiguration(targetConfiguration);
  }, [targetConfiguration, downloadConfiguration]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle>DSL version more than expected</DialogTitle>
      <DialogContentWithIcon>
        <WarningAmberOutlinedIcon color="warning" />
        <DialogContentText>
          App Schema DSL Version is{' '}
          <VersionText>{targetConfiguration.schemaDSLVersion}</VersionText>, but is expected to be{' '}
          <VersionText>{schemaDSLVersion}</VersionText>.
          <Typography variant="body3">
            App Engine will be blocked. Try to load <VersionText>{schemaDSLVersion}</VersionText>{' '}
            from backups.
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="default" onClick={downloadCurrentDSL}>
          Save DSL
        </Button>
        <Button variant="contained" color="success" onClick={closeCheckDSLVersionDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
