import { useMemo, useState, useEffect } from 'react';

import { ApolloError, MutationTuple, useMutation } from '@apollo/client';

import { useAppDispatch } from '../../../providers';
import { UI_BUILDER_EVENTS } from '../../../store';
import { APOLLO_OPERATION_CONTEXT, ASYNC_SESSION_STATUSES } from '../../constants';
import { DeployCodeSandboxMutation, DeployCodeSandboxMutationVariables } from '../__generated__';
import { DEPLOY_CODESANDBOX_MUTATION } from '../fragments';

import { useAsyncSession } from './useAsyncSession';

type UseUploadCodeSandboxReturn = {
  deployCodeSandbox: MutationTuple<
    DeployCodeSandboxMutation,
    DeployCodeSandboxMutationVariables
  >[0];
  deployCodeSandboxResult: {
    sandboxUrl?: string;
    error?: ApolloError;
    loading: boolean;
  };
};

export const useDeployCodeSandbox = (): UseUploadCodeSandboxReturn => {
  const send = useAppDispatch();
  const [sessionId, setSessionId] = useState<string>();

  const [deployCodeSandbox, { error, loading }] = useMutation<
    DeployCodeSandboxMutation,
    DeployCodeSandboxMutationVariables
  >(DEPLOY_CODESANDBOX_MUTATION, {
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: 'Deploy to CodeSandbox started',
      [APOLLO_OPERATION_CONTEXT.IGNORE_ERROR_MESSAGE]: true,
    },
    onCompleted: data => {
      setSessionId(data.appbuilder?.uploadCodeSandbox?.buildName);
    },
  });

  const { asyncSession, error: sessionError, loading: sessionLoading } = useAsyncSession(sessionId);

  const sandboxUrl = useMemo((): string | undefined => {
    if (asyncSession?.status === ASYNC_SESSION_STATUSES.success) {
      return asyncSession.result;
    }
  }, [asyncSession]);

  const resultError = useMemo(() => {
    if (asyncSession?.status === ASYNC_SESSION_STATUSES.error) {
      return asyncSession?.result;
    }

    return sessionError || error;
  }, [error, sessionError, asyncSession]);

  useEffect(() => {
    if (sandboxUrl) {
      send({
        type: UI_BUILDER_EVENTS.successAppNotify,
        successMessage: 'Deploy to CodeSandbox completed successfully',
      });
    }
  }, [send, sandboxUrl]);

  useEffect(() => {
    if (resultError) {
      send({
        type: UI_BUILDER_EVENTS.errorAppNotify,
        errorMessage: 'Unable to deploy to CodeSandbox',
      });
    }
  }, [send, resultError]);

  return {
    deployCodeSandbox,
    deployCodeSandboxResult: {
      sandboxUrl,
      error: resultError,
      loading: loading || sessionLoading,
    },
  };
};
