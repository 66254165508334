import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { CircularProgress, LinearProgress, } from '@mui/material';
import { Box } from '../Box';
const STRETCH_PROPS = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
};
export const AsyncContent = forwardRef(({ style, children, loading, stretch, boxProps = {}, id, title, className, lang, translate, circularProgressProps = {}, type, value, color, variant, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, }, ref) => {
    const stretchProps = stretch && loading ? STRETCH_PROPS : {};
    const resultBoxProps = Object.assign(Object.assign({}, boxProps), stretchProps);
    let content = null;
    if (loading) {
        content =
            type === 'linear' ? (_jsx(LinearProgress, { value: value, color: color, variant: variant, style: { width: '100%' } })) : (_jsx(CircularProgress, Object.assign({ value: value, color: color, variant: variant }, circularProgressProps)));
    }
    else if (children) {
        content = children;
    }
    return (_jsx(Box, Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, style: style, id: id, title: title, className: className, lang: lang, translate: translate }, resultBoxProps, { children: content })));
});
