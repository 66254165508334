import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const PopupContent = styled.div`
  padding: ${({ theme }) => theme.spacing(1.5)};
  width: 330px;
  border-radius: 8px;
  background-color: #242c34;
  box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.32);
`;

export const PopupTitle = styled(Typography)`
  height: 20px;
  color: #ffffff;
  font-family: Poppins, serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
`;

export const PopupHeader = styled.div`
  padding: 8px 12px;
  height: 32px;
  background: #323c47;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
`;

export const PopupActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
`;
