import { gql } from '@apollo/client';

export const DEPLOY_FRAGMENT = gql`
  fragment Deploy on AppbuilderDeploy {
    id
    buildName
    createdAt
    deployedAppVersion
    schemaDSLVersion
    status
    message
  }
`;

export const DEPLOYS_LIST_QUERY = gql`
  query DeploysList($workspaceId: String!, $sort: AppbuilderDeploySort) {
    appbuilder {
      deployList(workspaceId: $workspaceId, sort: $sort) {
        items {
          ...Deploy
        }
      }
    }
  }
  ${DEPLOY_FRAGMENT}
`;

export const DEPLOY_QUERY = gql`
  query Deploy($workspaceId: String!, $buildName: String!) {
    appbuilder {
      deploy(workspaceId: $workspaceId, buildName: $buildName) {
        ...Deploy
      }
    }
  }
  ${DEPLOY_FRAGMENT}
`;

export const DEPLOY_PRODUCTION_MUTATION = gql`
  mutation DeployProduction($data: AppbuilderDeployInput!) {
    appbuilder {
      deploy(data: $data) {
        buildName
      }
    }
  }
`;

export const DEPLOY_CODESANDBOX_MUTATION = gql`
  mutation DeployCodeSandbox($data: AppbuilderUploadCodeSandboxInput!) {
    appbuilder {
      uploadCodeSandbox(data: $data) {
        buildName
      }
    }
  }
`;

export const DEPLOY_S3_MUTATION = gql`
  mutation DeployS3($data: AppbuilderUploadS3Input!) {
    appbuilder {
      uploadS3(data: $data) {
        buildName
      }
    }
  }
`;
