/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types, no-new-func */
import memoize from 'fast-memoize';
import { isEmpty } from 'ramda';
import { serialize, getFunctionDeps } from '@builder/utils';
import { UI_BUILDER_MODES } from '../providers/DashboardProvider';
import { createFunction, throttledLogWarn } from './createFunction';
import { isRenderMoreHooksError } from './isRenderMoreHooksError';
export const createAndRunFunction = (functionCode, appRuntimeStateList, options) => {
    var _a;
    const librariesUsedInCode = Object.values((options === null || options === void 0 ? void 0 : options.libraries) || {}).filter(lib => functionCode.includes(lib.alias));
    const { uiBuilderMode } = window;
    try {
        const { predefinedState, globalState: appState, localState, temporaryState, localStates, } = appRuntimeStateList;
        const state = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, predefinedState), appState), localState), temporaryState), localStates);
        const func = createFunction(functionCode, state, librariesUsedInCode, !!(options === null || options === void 0 ? void 0 : options.isNewFunction));
        return func();
    }
    catch (err) {
        throttledLogWarn(`\n\n_____Runtime Function Error_____\n\n\n`, err, `_____Function Code___`, functionCode);
        (_a = options === null || options === void 0 ? void 0 : options.onAppFunctionError) === null || _a === void 0 ? void 0 : _a.call(options, err);
        if (uiBuilderMode === UI_BUILDER_MODES.preview && err.name !== 'ReferenceError') {
            appRuntimeStateList.predefinedState.routerHistory.push('/500');
            const errorState = appRuntimeStateList.globalState.uncaughtErrors;
            if (!isEmpty(errorState.value[0].error)) {
                errorState.setValue([
                    ...errorState.value,
                    {
                        message: 'Runtime Function Error',
                        error: err,
                        code: functionCode,
                    },
                ]);
            }
            else {
                errorState.setValue([
                    {
                        message: 'Runtime Function Error',
                        error: err,
                        code: functionCode,
                    },
                ]);
            }
        }
        // if hook is added then the need to catch this error on the top of AppEngine anr remount it
        if ((options === null || options === void 0 ? void 0 : options.throwReactRenderError) && isRenderMoreHooksError(err.message)) {
            throw err;
        }
    }
};
export const createAndRunFunctionWithMemo = memoize(createAndRunFunction, {
    serializer: ([functionCode, appRuntimeStateList]) => {
        const { appState, localState = {} } = appRuntimeStateList;
        const states = Object.assign(Object.assign({}, appState), { localState });
        const stateNames = Object.keys(states);
        const functionDepNames = getFunctionDeps(functionCode, stateNames);
        const functionDeps = Object.keys(states).reduce((accum, stateName) => {
            if (functionDepNames.includes(stateName)) {
                return Object.assign(Object.assign({}, accum), { [stateName]: states[stateName] });
            }
            return accum;
        }, {});
        return functionCode + serialize.stringify(functionDeps, { removeComplexObjects: true });
    },
});
