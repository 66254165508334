import { StateDSL } from '@builder/schemas';

import { isNumber } from './common';
import { ARRAY } from './constants';
import { MetaStates } from './types';

export const objectsContainArrays = (
  statesValues: StateDSL[],
  statesKeys: string[],
): MetaStates => {
  return statesValues.reduce((accum: MetaStates, current: StateDSL, index: number) => {
    if (current && Array.isArray(current)) {
      if (isNumber(statesKeys[index])) return accum;

      return {
        ...accum,
        [statesKeys[index]]: {
          id: null,
          name: statesKeys[index],
          type: ARRAY,
          value: current,
          children: null,
        },
      };
    }

    return accum;
  }, {});
};
