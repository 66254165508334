import { pipe, values } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getDivider, getStyleSections, getPropertySections, getEventsSection, } from '../../common/settings';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const getMaterialBadgeSettings = () => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: NS_ID.properties.basic,
                    title: 'Basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['badgeContent'],
                            label: 'Badge Content',
                            name: 'badgeContent',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showZero'],
                            label: 'Show Zero',
                            name: 'showZero',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['invisible'],
                            label: 'Invisible',
                            name: 'invisible',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['max'],
                            label: 'Maximum',
                            name: 'maximum',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            label: 'Icon',
                            name: 'icon',
                            keyPath: ['children'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: NS_ID.style.basic,
                    title: 'Basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            name: 'variant',
                            options: ['dot', 'standard'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Font Color',
                            name: 'color',
                            options: ['default', 'error', 'primary', 'secondary'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['overlap'],
                            label: 'Overlap',
                            name: 'overlap',
                            options: ['circular', 'rectangular'].map(createSelectOptionByValue),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.subSection,
                            title: 'Anchor Origin',
                            name: 'anchorOrigin',
                            children: [
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['anchorOrigin', 'horizontal'],
                                    label: 'Horizontal',
                                    required: true,
                                    options: ['left', 'right'].map(createSelectOptionByValue),
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['anchorOrigin', 'vertical'],
                                    label: 'Vertical',
                                    required: true,
                                    options: ['bottom', 'top'].map(createSelectOptionByValue),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
});
export const MaterialBadgeSettings = getMaterialBadgeSettings();
export const getCommonBadgeSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'icon'],
    [NS_ID.style.root, NS_ID.style.basic, 'variant'],
    [NS_ID.style.root, NS_ID.style.basic, 'color'],
    [NS_ID.style.root, NS_ID.style.basic, 'overlap'],
], { withStructure: false }))(values(MaterialBadgeSettings).flat());
