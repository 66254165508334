export const RESOURCE_TYPES = {
    rest: 'rest',
    graphql: 'graphql',
    backendEightBase: 'backend8base',
};
export const REST_METHODS = {
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    GET: 'GET',
    DELETE: 'DELETE',
};
export const CONTENT_TYPE = {
    text: 'text/plain',
    json: 'application/json',
};
export const HEADER_CONTENT_TYPE_KEY = 'Content-Type';
