import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_COLUMN_GROUP } from './tableColumnGroup.settings';
export const tableColumnGroup = {
    displayName: 'Table Column Group',
    name: COMPONENT_DSL_NAMES.tableColumnGroup,
    icon: 'htmlTableColumnGroup',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'colgroup',
    },
    schema: {
        dndTargetPropName: 'children',
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.table,
                },
            },
        ],
        props: Object.assign(Object.assign({}, commonComponentProps), { span: { type: COMPONENT_DSL_PROP_TYPES.number }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.tableColumn],
            } }),
    },
    settings: TABLE_COLUMN_GROUP,
};
