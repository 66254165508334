import React from 'react';

import styled from '@emotion/styled';
import { CardMedia } from '@mui/material';

import { AssetTypes, ASSET_TYPES } from '@builder/schemas';

import { useTheme } from 'src/providers/ThemeProvider';
import { Icon } from 'src/shared/components';

const MediaContainer = styled.div<{
  height: string;
  width: string;
  borderRadius: string;
  backgroundColor: string;
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PDFText = styled.span<{ isListItem: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: Poppins;
  font-size: ${({ isListItem }) => (isListItem ? '12px' : '20px')};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
`;

type AssetMediaProps = {
  assetType: AssetTypes;
  downloadUrl: string;
  height?: string;
  width?: string;
  borderRadius?: string;
  listItem?: boolean;
};

export const AssetMedia: React.FC<AssetMediaProps> = ({
  assetType,
  downloadUrl,
  height = '90px',
  width = '100%',
  borderRadius = '8px',
  listItem = false,
}) => {
  const theme = useTheme();
  const renderCardMedia = () => {
    switch (assetType) {
      case ASSET_TYPES.image: {
        return (
          <CardMedia
            component="img"
            height={height}
            sx={{
              width,
              borderRadius,
            }}
            image={downloadUrl}
          />
        );
      }
      case ASSET_TYPES.svg: {
        return (
          <CardMedia
            component="img"
            height={height}
            sx={{
              width,
              borderRadius,
            }}
            image={downloadUrl}
          />
        );
      }
      case ASSET_TYPES.pdf: {
        return (
          <MediaContainer
            height={height}
            width={width}
            borderRadius={borderRadius}
            backgroundColor={theme.palette.common.pdf}
          >
            <PDFText isListItem={listItem}>PDF</PDFText>
          </MediaContainer>
        );
      }
      case ASSET_TYPES.folder:
      case ASSET_TYPES.other:
      default: {
        return (
          <MediaContainer
            height={height}
            width={width}
            borderRadius={borderRadius}
            backgroundColor={theme.palette.common.file}
          >
            <Icon
              width={32}
              height={32}
              name="insertDriveFileOutlined"
              fill={theme.palette.text.primary}
            />
          </MediaContainer>
        );
      }
    }
  };

  return renderCardMedia();
};
