import convert from 'color-convert';

import { PERCENT } from '../../constants';
import { HEX, HSBA } from '../../types';

export class Hex {
  private readonly color: HEX;

  constructor(color: HEX) {
    this.color = color;
  }

  toHsb(): HSBA {
    const [h, s, b] = convert.hex.hsv(this.color);
    // Saturation and brightness are being translated from percent to decimal from 0 to 1
    return {
      h,
      s: s / PERCENT,
      b: b / PERCENT,
      a: 1,
    };
  }
}
