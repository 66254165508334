import React, { useCallback } from 'react';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { Button } from '@builder/components';

import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { DialogContentWithIcon } from 'src/dialogs/common/components';
import { useDialogState } from 'src/providers';
import { MESSAGES } from 'src/shared/constants';

const DIALOG_ID = DASHBOARD_DIALOGS.PREVENT_BREAKING_DSL_DIALOG_ID;

export const PreventBreakingDSLDialog: React.FC = () => {
  const { isOpen, closeDialog } = useDialogState(DIALOG_ID);

  const closePreventBreakingDSLDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  return (
    <Dialog open={isOpen} onClose={closePreventBreakingDSLDialog}>
      <DialogTitle>{MESSAGES.dialogs.preventBreakingDSLDialog.title}</DialogTitle>
      <DialogContentWithIcon>
        <InfoOutlinedIcon color="primary" />
        <DialogContentText>
          <Typography mt={0.5} variant="body3">
            {MESSAGES.dialogs.preventBreakingDSLDialog.body}
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="success" onClick={closePreventBreakingDSLDialog}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
