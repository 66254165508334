var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { Slide, Grow, Zoom, Collapse, Fade, Snackbar as SnackbarMUI, styled, } from '@mui/material';
import { SnackbarContent } from '../SnackbarContent';
const SnackBarStyled = styled(SnackbarMUI) `
  background-color: ${props => { var _a; return (_a = props === null || props === void 0 ? void 0 : props.style) === null || _a === void 0 ? void 0 : _a.background; }};
`;
const Transition = {
    Slide,
    Grow,
    Zoom,
    Collapse,
    Fade,
};
export const Snackbar = forwardRef((_a, ref) => {
    var { children, horizontal, vertical, transition, action, message, style, className, role } = _a, props = __rest(_a, ["children", "horizontal", "vertical", "transition", "action", "message", "style", "className", "role"]);
    return (_jsx(SnackBarStyled, Object.assign({}, props, { TransitionComponent: Transition[transition], anchorOrigin: { horizontal, vertical }, ref: ref }, { children: _jsx(SnackbarContent, { style: style, role: role, action: action, className: className, message: message }) })));
});
