import {
  NodeDSL,
  NodeStateConnectionDSL,
  StateDSLWithDialog,
  StateListDSL,
} from '@builder/schemas';

export const globalNestedStates = ({
  stateListDSL,
  childrenIDs,
  rootCopyBufferNodeDSL,
}: {
  stateListDSL: StateListDSL;
  childrenIDs: string[];
  rootCopyBufferNodeDSL: NodeDSL;
}): NodeStateConnectionDSL[] =>
  Object.values(stateListDSL ?? {})
    .filter(stateDSL =>
      [...childrenIDs, rootCopyBufferNodeDSL.id].includes(
        (stateDSL as StateDSLWithDialog).componentBoundID as string,
      ),
    )
    ?.map(globalState => {
      return {
        stateID: globalState.id,
        required: true,
        componentBoundID: (globalState as StateDSLWithDialog).componentBoundID,
      };
    }) ?? [];

export const globalBoundedStates = ({
  stateListDSL,
}: {
  stateListDSL: StateListDSL;
}): NodeStateConnectionDSL[] =>
  Object.values(stateListDSL ?? {})?.map(globalState => {
    return {
      stateID: globalState.id,
      required: true,
      componentBoundID: (globalState as StateDSLWithDialog).componentBoundID,
    };
  }) ?? [];
