import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { MobileLayoutSettings } from './MobileLayout.settings';
/** @deprecated */
/** Default layouts removed in version 0.12.1
 */
export const MobileLayout = {
    name: COMPONENT_DSL_NAMES.MobileLayout,
    displayName: 'Mobile Layout',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.layout,
    memoization: {
        disable: true,
    },
    schema: {
        accessibility: {
            hideBreadcrumb: true,
            hideOverlay: true,
        },
        interaction: COMPONENT_DSL_INTERACTION_TYPES.none,
        props: Object.assign(Object.assign({}, commonComponentProps), { path: {
                type: COMPONENT_DSL_PROP_TYPES.enum,
                allowJS: false,
            }, exact: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            }, header: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, content: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.RouterSwitch, COMPONENT_DSL_NAMES.RouterSwitchSymbol],
            }, footer: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, router: {
                type: COMPONENT_DSL_PROP_TYPES.string,
            } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout,
            props: {
                path: '{{ symbolProps.path }}',
                exact: true,
                authAccess: 'any',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.div,
                                            alias: 'Wrapper',
                                            props: {
                                                style: {
                                                    display: 'flex',
                                                    width: '100%',
                                                    height: '100vh',
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.div,
                                                            alias: 'Content',
                                                            props: {
                                                                style: {
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    flex: '1 1 0',
                                                                    height: '100%',
                                                                },
                                                                children: '{{ [symbolProps.header, symbolProps.content, symbolProps.footer] }}',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: MobileLayoutSettings,
};
