import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsButtonSettings } from './BuilderComponentsButton.settings';
export const BuilderComponentsButton = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
    displayName: 'Button',
    icon: 'button',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Button',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Text',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        },
        predefineds: {
            props: {
                color: 'primary',
                variant: 'text',
                size: 'medium',
                disabled: false,
                loading: false,
                type: 'button',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Button',
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, loading: { type: COMPONENT_DSL_PROP_TYPES.boolean }, loadingIndicator: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, loadingPosition: { type: COMPONENT_DSL_PROP_TYPES.enum }, disableElevation: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFocusRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, endIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, href: { type: COMPONENT_DSL_PROP_TYPES.string }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, startIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, target: { type: COMPONENT_DSL_PROP_TYPES.enum }, type: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsButtonSettings,
};
