import { CSS_MEDIA_TYPE, CSS_RULE_TYPE, CSS_DECLARATION_TYPE } from '../constants';
export const isRule = (rule) => {
    return rule.type === CSS_RULE_TYPE;
};
export const isMedia = (rule) => {
    return rule.type === CSS_MEDIA_TYPE;
};
export const isDeclaration = (declaration) => {
    return declaration.type === CSS_DECLARATION_TYPE;
};
