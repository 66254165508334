import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { usePrevious } from './usePrevious';
export const useDebouncedState = (initialValue, onChangeValue, options = {}) => {
    const { debounceTime = 300, skipDebounce = false } = options;
    const [localValue, setLocalValue] = useState(initialValue);
    const [debouncedLocalValue, setDebouncedLocalValue] = useState(initialValue);
    const prevInitialValue = usePrevious(initialValue);
    const onDebounceChangeValue = useCallback((value) => {
        onChangeValue(value);
        setDebouncedLocalValue(value);
    }, [onChangeValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangePropValue = useCallback(debounce(onDebounceChangeValue, debounceTime), [
        debounceTime,
        onDebounceChangeValue,
    ]);
    const setDebouncedValue = useCallback((value) => {
        setLocalValue(value);
        debouncedChangePropValue(value);
    }, [debouncedChangePropValue]);
    const setOnlyLocalValue = useCallback((value) => {
        setLocalValue(value);
    }, [setLocalValue]);
    // There are some cases when we want to reset the initialValue bypassing the debounce feature
    // As en example: when we change name of the global state from "imageSRC" to "imageSource"
    // we want to change the field value from "{{ imageSRC.value }}" to "{{ imageSource.value }}"
    useEffect(() => {
        // there is only one case when these props can be an object
        // we use object type in the SpacingViewEditor.tsx
        const parsedLocalValue = typeof localValue === 'object' ? JSON.stringify(localValue) : localValue;
        const parsedDebouncedLocalValue = typeof debouncedLocalValue === 'object'
            ? JSON.stringify(debouncedLocalValue)
            : debouncedLocalValue;
        const parsedInitialValue = typeof initialValue === 'object' ? JSON.stringify(initialValue) : initialValue;
        const parsedPrevInitialValue = typeof prevInitialValue === 'object' ? JSON.stringify(prevInitialValue) : prevInitialValue;
        if (!skipDebounce &&
            parsedLocalValue === parsedDebouncedLocalValue && // If this condition is true it means that all debounced changes was applied
            // If these two conditions bellow are true it means that value was changes in the another place
            parsedLocalValue !== parsedInitialValue &&
            parsedPrevInitialValue !== parsedInitialValue) {
            setDebouncedValue(initialValue);
        }
    }, [
        initialValue,
        debouncedLocalValue,
        localValue,
        skipDebounce,
        prevInitialValue,
        setDebouncedValue,
    ]);
    if (skipDebounce) {
        return [initialValue, onChangeValue, setOnlyLocalValue];
    }
    return [localValue, setDebouncedValue, setOnlyLocalValue];
};
