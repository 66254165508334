import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useTheme as useMaterialUITheme, CssBaseline, GlobalStyles, } from '@mui/material';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider as MuiThemeProvider, } from '@mui/material/styles';
import { clone } from 'ramda';
import { themeSelectors, } from '@builder/schemas';
export const useTheme = () => {
    const customTheme = useMaterialUITheme();
    return customTheme;
};
export const ThemeProvider = ({ theme, contextWindow, children, cacheKey, assets, assetBackendList = {} }) => {
    const appTheme = useMemo(() => {
        // Is needed because of material has a problem with changing theme in runtime.
        // Material UI doesn't update components without cloning object.
        return createMuiTheme(clone(theme === null || theme === void 0 ? void 0 : theme.frameworkSettings));
    }, [theme === null || theme === void 0 ? void 0 : theme.frameworkSettings]);
    const globalStyles = (_jsx(GlobalStyles, { styles: themeSelectors.getThemeCSS(theme, {
            assetListDSL: assets !== null && assets !== void 0 ? assets : {},
            assetBackendList,
        }) }));
    const globalCache = createCache({
        key: `global-${cacheKey.toLowerCase()}`,
        container: contextWindow.document.head,
        prepend: true,
    });
    const localCache = createCache({
        key: cacheKey.toLowerCase(),
        container: contextWindow.document.head,
        prepend: false,
    });
    return (_jsx(CacheProvider, Object.assign({ value: globalCache }, { children: _jsxs(MuiThemeProvider, Object.assign({ theme: appTheme }, { children: [_jsx(CssBaseline, {}), globalStyles, _jsx(CacheProvider, Object.assign({ value: localCache }, { children: children }))] })) })));
};
