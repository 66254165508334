import { values } from 'ramda';
import { createSelector } from 'reselect';
import { ERROR_SCOPES, incrementName, SystemError } from '@builder/utils';
import { COMPONENT_DSL_NAMES } from '../constants';
import * as componentSelectors from './component-selectors';
const getComponentListDSL = (componentListDSL) => componentListDSL;
const getComponentName = (_, props) => {
    if (props.componentName === 'EmptyPageLayout') {
        return COMPONENT_DSL_NAMES.BaseLayout;
    }
    return props.componentName;
};
const getComponentDisplayName = (_, props) => props.componentDisplayName;
const getUserComponentArrayDSL = (userComponentArrayDSL) => userComponentArrayDSL;
export const getComponentDSL = createSelector(getComponentListDSL, getComponentName, (componentListDSL, componentName) => {
    if (!componentName) {
        throw new SystemError(ERROR_SCOPES.dashboard, `Component name must be specified.`);
    }
    if (!componentListDSL[componentName]) {
        throw new SystemError(ERROR_SCOPES.dashboard, `Schema for ${componentName} component not found.`);
    }
    return componentListDSL[componentName];
});
export const getComponentArrayDSL = createSelector(getComponentListDSL, componentListDSL => Object.values(componentListDSL));
export const getPropListDSL = createSelector(getComponentDSL, componentSelectors.getPropSchemaListDSL);
export const getPropSchemaDSL = createSelector(getComponentDSL, (_, props) => props.propName, componentSelectors.getPropSchemaDSL);
export const getDisplayName = createSelector(getComponentDSL, componentSelectors.getDisplayName);
export const isMemoizationEnabled = createSelector(getComponentDSL, componentSelectors.isMemoizationEnabled);
export const isComponentOverlayHidden = createSelector(getComponentDSL, componentSelectors.isComponentOverlayHidden);
export const isCreatingSymbolDisabled = createSelector(getComponentDSL, componentSelectors.isCreatingSymbolDisabled);
export const isDeprecated = createSelector(getComponentDSL, componentSelectors.isDeprecated);
export const isSymbol = createSelector(getComponentDSL, componentSelectors.isSymbol);
export const getIconName = createSelector(getComponentDSL, componentSelectors.getIconName);
/**
 * @returns New countered name for component.
 * @example
 * ({ componentName: 'BuilderComponentsButton'}) => 'BuilderComponentsButton_1'
 */
export const getIncrementedComponentName = createSelector(getComponentListDSL, getComponentName, (componentListDSL, componentName) => {
    const allAppropriateComponentNames = values(componentListDSL).map(({ name }) => name);
    return incrementName({
        nameToIncrement: componentName,
        dictionary: allAppropriateComponentNames,
        delimiter: '_',
    });
});
/**
 * @returns New countered name for component.
 * @example
 * ({ componentDisplayName: 'Button'}) => 'Button 1'
 */
export const getIncrementedComponentDisplayName = createSelector(getComponentListDSL, getComponentDisplayName, (componentListDSL, componentDisplayName) => {
    const allAppropriateComponentNames = values(componentListDSL).map(({ displayName }) => displayName);
    return incrementName({
        nameToIncrement: componentDisplayName,
        dictionary: allAppropriateComponentNames,
        delimiter: ' ',
    });
});
export const getUserComponentListDSL = createSelector(getUserComponentArrayDSL, userComponentArrayDSL => userComponentArrayDSL.reduce((accum, userComponentDSL) => (Object.assign(Object.assign({}, accum), { [userComponentDSL.name]: userComponentDSL })), {}));
export const isSymbolNameNotUnique = createSelector(getComponentArrayDSL, getComponentName, (componentArrayDSL, componentName) => {
    return componentArrayDSL.some(component => component.name === componentName);
});
