import { createSelector } from 'reselect';
import { isNull } from '@builder/utils';
import { STATE_SCOPES, STATE_TYPES, STATE_REQUEST_VARIANTS } from '../constants';
import * as nodeListSelectors from './node-list-selectors';
const getStateDSL = (appStateDSL) => appStateDSL;
const getNodeListDSL = (_, props) => props.nodeListDSL;
const getNodeID = (_, props) => props.nodeID;
const getCurrentPathname = (_, props) => props.currentPathname;
const getComponentListDSL = (_, props) => props.componentListDSL;
export const isGlobal = createSelector(getStateDSL, stateDSL => stateDSL.scope === STATE_SCOPES.global);
export const isLocal = createSelector(getStateDSL, stateDSL => stateDSL.scope === STATE_SCOPES.local);
export const isRunnable = createSelector(getStateDSL, stateDSL => {
    if (stateDSL.type === STATE_TYPES.query) {
        if (stateDSL.variant === STATE_REQUEST_VARIANTS.query) {
            return false;
        }
        if (stateDSL.variant === STATE_REQUEST_VARIANTS.rest ||
            stateDSL.variant === STATE_REQUEST_VARIANTS.mutation) {
            const { isTriggeredManually } = stateDSL.args;
            return Boolean(isTriggeredManually);
        }
        return true;
    }
    return false;
});
/**
 * @returns True if state is connected to existed route or if it is global state.
 */
export const isStateAccessibleOnRoute = createSelector(getStateDSL, getComponentListDSL, getNodeListDSL, getCurrentPathname, isGlobal, (stateDSL, componentListDSL, nodeListDSL, currentPathname, isGlobalState) => {
    // Global state visible on any route.
    if (isGlobalState) {
        return true;
    }
    const nodeWithConnectedState = nodeListSelectors.getNodeWithConnectedState(nodeListDSL, {
        stateDSL,
    });
    // If there is no node with state it means state wasn't connected to any node
    if (isNull(nodeWithConnectedState)) {
        return false;
    }
    return nodeListSelectors.isNodeVisibleOnRoute(nodeListDSL, {
        componentListDSL,
        nodeID: nodeWithConnectedState.id,
        currentPathname,
    });
});
/**
 * @returns True if state is connected to node or to it children.
 */
export const isStateAccessibleOnNode = createSelector(getStateDSL, getNodeListDSL, getNodeID, isGlobal, (stateDSL, nodeListDSL, nodeID, isGlobalState) => {
    // Global state visible on any route.
    if (isGlobalState) {
        return true;
    }
    const nodeWithConnectedState = nodeListSelectors.getNodeWithConnectedState(nodeListDSL, {
        stateDSL,
    });
    // If there is no node with state it means state wasn't connected to any node
    if (isNull(nodeWithConnectedState)) {
        return false;
    }
    return (nodeWithConnectedState.id === nodeID ||
        nodeListSelectors.hasParentNode(nodeListDSL, {
            nodeID: nodeWithConnectedState.id,
            parentID: nodeID,
        }));
});
/**
 * @returns True if state is connected to node or to it parent.
 */
export const isStateAccessibleOnNodeWithScope = createSelector(getStateDSL, getNodeListDSL, getNodeID, isGlobal, (stateDSL, nodeListDSL, nodeID, isGlobalState) => {
    // Global state visible on any route.
    if (isGlobalState) {
        return true;
    }
    const nodeWithConnectedState = nodeListSelectors.getNodeWithConnectedState(nodeListDSL, {
        stateDSL,
    });
    // If there is no node with state it means state wasn't connected to any node
    if (isNull(nodeWithConnectedState)) {
        return false;
    }
    return (nodeWithConnectedState.id === nodeID ||
        nodeListSelectors.hasParentNode(nodeListDSL, {
            nodeID,
            parentID: nodeWithConnectedState.id,
        }));
});
