import { createSelector } from 'reselect';
import { CONSOLE_STANDS, CheckResourceApiHostValue } from '@builder/utils';
import { RESOURCE_TYPES } from '../constants';
import { transform8baseEndpointResourceValue, transformEndpointResourceValue, } from '../utils/prop-transformers/transformPropWithJsCode';
const getResourceDSL = (resourceDSL) => resourceDSL;
export const isRestResource = createSelector(getResourceDSL, (resourceDSL) => {
    return resourceDSL.type === RESOURCE_TYPES.rest;
});
export const isEightbaseBackendResource = createSelector(getResourceDSL, (resourceDSL) => {
    return resourceDSL.type === RESOURCE_TYPES.backendEightBase;
});
export const getEightbaseResourceApiURL = createSelector(getResourceDSL, resourceDSL => {
    if ((resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.type) !== RESOURCE_TYPES.backendEightBase) {
        return '';
    }
    const apiUrl = CheckResourceApiHostValue(resourceDSL.api) || resourceDSL.api;
    return apiUrl;
});
export const getResourceEndpoint = createSelector(getResourceDSL, resourceDSL => {
    if ((resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.type) !== RESOURCE_TYPES.backendEightBase) {
        return resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.endpoint;
    }
    const apiUrl = CheckResourceApiHostValue(resourceDSL.api) || resourceDSL.api;
    const workspaceApiUrl = [apiUrl, resourceDSL.workspaceID].join('/');
    return resourceDSL.environment
        ? `${workspaceApiUrl}_${resourceDSL.environment}`
        : workspaceApiUrl;
});
export const getResourceEndpointCodeEngine = createSelector(getResourceDSL, resourceDSL => {
    if ((resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.type) !== RESOURCE_TYPES.backendEightBase) {
        return (resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.endpoint) && transform8baseEndpointResourceValue(resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.endpoint);
    }
    const apiUrl = CheckResourceApiHostValue(resourceDSL.api) || resourceDSL.api;
    const handledWorkspaceID = (resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.workspaceID) && transformEndpointResourceValue(resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.workspaceID);
    const handledEnvironment = (resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.environment) && transformEndpointResourceValue(resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.environment);
    const workspaceApiUrl = [apiUrl, handledWorkspaceID].join('/');
    return resourceDSL.environment
        ? `\`${workspaceApiUrl}_${handledEnvironment}\``
        : `\`${workspaceApiUrl}\``;
});
export const getWorkspaceConsoleLink = createSelector(getResourceDSL, resourceDSL => {
    if ((resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.type) !== RESOURCE_TYPES.backendEightBase) {
        return null;
    }
    const consoleUrl = CONSOLE_STANDS[resourceDSL.api];
    const workspaceConsoleUrl = [consoleUrl, 'workspace', resourceDSL.workspaceID].join('/');
    return resourceDSL.environment
        ? `${workspaceConsoleUrl}_${resourceDSL.environment}`
        : workspaceConsoleUrl;
});
export const getResourceTitle = createSelector(getResourceDSL, resourceDSL => {
    return resourceDSL.title;
});
