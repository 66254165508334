var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { forwardRef } from 'react';
import { Box, Chip, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select as MaterialSelect, } from '@mui/material';
export const Select = forwardRef((_a, ref) => {
    var { disabled, label, className, style, error, helperText, variant, size, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, translate, behavior, children, fullWidth } = _a, otherSelectProps = __rest(_a, ["disabled", "label", "className", "style", "error", "helperText", "variant", "size", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "translate", "behavior", "children", "fullWidth"]);
    let selectComponent;
    switch (behavior) {
        case 'Chip':
            selectComponent = (_jsx(MaterialSelect, Object.assign({ error: error, label: label, className: className, style: style }, otherSelectProps, { renderValue: selected => {
                    return (_jsx(Box, Object.assign({ sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, { children: selected.map(value => {
                            if (children) {
                                return children
                                    .filter(innerChild => innerChild.props.value === value)
                                    .map(innerChildFiltered => {
                                    var _a;
                                    return Array.isArray(((_a = innerChildFiltered === null || innerChildFiltered === void 0 ? void 0 : innerChildFiltered.props) === null || _a === void 0 ? void 0 : _a.children) || '') ? (innerChildFiltered === null || innerChildFiltered === void 0 ? void 0 : innerChildFiltered.props.children[2]) : (_jsx(Chip, { label: value }, value));
                                });
                            }
                            return null;
                        }) })));
                } }, { children: React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return Object.assign(Object.assign({}, child), { props: Object.assign(Object.assign({}, child.props), { children: Array.isArray(child.props.children)
                                    ? [child.props.children[0]]
                                    : child.props.children }) });
                    }
                }) })));
            break;
        case 'Checkmarks':
            selectComponent = (_jsx(MaterialSelect, Object.assign({ error: error, label: label, className: className, style: style }, otherSelectProps, { renderValue: selected => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const renderLabels = children.reduce((acc, curr) => {
                        var _a, _b, _c, _d, _e, _f, _g;
                        if (curr === null || curr === undefined) {
                            return acc;
                        }
                        if (Array.isArray((_a = curr === null || curr === void 0 ? void 0 : curr.props) === null || _a === void 0 ? void 0 : _a.children)) {
                            return Object.assign(Object.assign({}, acc), { [curr === null || curr === void 0 ? void 0 : curr.props.value]: (_d = (_c = (_b = curr === null || curr === void 0 ? void 0 : curr.props) === null || _b === void 0 ? void 0 : _b.children[1]) === null || _c === void 0 ? void 0 : _c.props) === null || _d === void 0 ? void 0 : _d.auxLabel });
                        }
                        return Object.assign(Object.assign({}, acc), { [curr.props.value]: ((_g = (_f = (_e = curr === null || curr === void 0 ? void 0 : curr.props) === null || _e === void 0 ? void 0 : _e.children) === null || _f === void 0 ? void 0 : _f.props) === null || _g === void 0 ? void 0 : _g.children) || curr.props.children });
                    }, {});
                    if (renderLabels === null || renderLabels === undefined)
                        return selected.join(', ');
                    return selected
                        .map(item => renderLabels[item])
                        .join(', ');
                } }, { children: React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return (_jsx(MenuItem, Object.assign({ value: child.props.value }, { children: _jsx(ListItemText, { primary: Array.isArray(child.props.children)
                                    ? child.props.children[1]
                                    : child.props.children }) }), child.props.value));
                    }
                    return child;
                }) })));
            break;
        case 'Native':
            selectComponent = (_jsx(MaterialSelect, Object.assign({ className: className, error: error, label: label, native: true, multiple: true, style: style }, otherSelectProps, { onChange: (event, child) => {
                    const target = event.target;
                    const selectedOptions = Array.from(target.options).filter(option => option.selected);
                    const values = selectedOptions.map(option => option.value);
                    if (otherSelectProps.onChange) {
                        const customEvent = Object.assign(Object.assign({}, event), { target: {
                                value: values,
                                name: target.name,
                            } });
                        otherSelectProps.onChange(customEvent, child);
                    }
                } }, { children: React.Children.map(children, (child, index) => {
                    if (React.isValidElement(child)) {
                        const handledChild = Array.isArray(child.props.children)
                            ? child.props.children[0]
                            : child.props.children;
                        return (_jsx("option", Object.assign({ value: child.props.value }, { children: typeof handledChild === 'object' ? handledChild.props.children : handledChild }), child.props.value));
                    }
                    return null;
                }) })));
            break;
        case 'Default':
            selectComponent = (_jsx(MaterialSelect, Object.assign({ className: className, error: error, label: label, style: style }, otherSelectProps, { children: React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return Object.assign(Object.assign({}, child), { props: Object.assign(Object.assign({}, child.props), { children: Array.isArray(child.props.children)
                                    ? [child.props.children[0]]
                                    : child.props.children }) });
                    }
                }) })));
            break;
        default:
            selectComponent = (_jsx(MaterialSelect, Object.assign({ className: className, error: error, label: label, style: style }, otherSelectProps, { children: children })));
            break;
    }
    return (_jsxs(FormControl, Object.assign({ variant: variant, size: size, disabled: disabled, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, lang: lang, translate: translate, fullWidth: fullWidth }, { children: [label && _jsx(InputLabel, { children: label }), selectComponent, helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
