import { BaseAppStateAccessor } from './BaseAppStateAccessor';
export class FunctionStateAccessor extends BaseAppStateAccessor {
    constructor(stateDSL, stateListDSL) {
        super();
        this.stateDSL = stateDSL;
        this.stateListDSL = stateListDSL;
    }
    getHookDeclarationBodyString() {
        const { code: functionCode = '' } = this.stateDSL;
        return `return (function() { ${functionCode} })();`;
    }
    getHookTypings() {
        const stateName = this.getStateName();
        const hookBody = this.getHookDeclarationBodyString();
        return `const ${stateName} = (() => { ${hookBody} })()`;
    }
}
