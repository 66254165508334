import { match } from 'path-to-regexp';
/**
 * Extract params names from currentRoutePath and params values from locationPath.
 * @example
 * ('/profile/:name/active/:active', '/profile/jorge/active/true') => { name: 'jorge', active: 'true' }
 * ('/id/:id', '/id/123') => { id: '123' }
 */
export const getCurrentRouteParams = (currentRoutePath, locationPath) => {
    const parsedCurrentRoutePath = match(currentRoutePath, { decode: decodeURIComponent });
    const parsedPath = parsedCurrentRoutePath(locationPath);
    if (parsedPath !== false) {
        return parsedPath.params;
    }
    return {};
};
