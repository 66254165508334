import { createSelector } from 'reselect';

import { nodeListSelectors } from '@builder/schemas';

import { DashboardState, Store } from '../common';

import * as dashboardSelectors from './dashboard-selectors';

export const getStoreState = (store: Store): Store => store;
export const getDashboardState = (store: Store): DashboardState => store.dashboard;

export const getAppConfiguration = createSelector(getDashboardState, dashboardState => {
  return dashboardState.appConfiguration;
});

export const getComponentListDSL = createSelector(
  getDashboardState,
  dashboardSelectors.getComponentListDSL,
);

export const getCurrentRouteNode = createSelector(getDashboardState, dashboardState => {
  const nodeDSL = dashboardSelectors.getNodeListDSL(dashboardState);
  const { currentRoute } = dashboardState.router;

  return nodeListSelectors.getCurrentRouteNodeDSL(nodeDSL, {
    currentPathname: currentRoute,
  });
});
