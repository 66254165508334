import { AppVersion } from '@builder/schemas';

import { joinVersion } from './joinVersion';
import { splitVersion } from './splitVersion';

/**
 * @example
 * ["0.1.0", "1"]
 */
type AppVersionParts = [string, string];

export const getAppVersionParts = (appVersion: AppVersion): AppVersionParts => {
  const splittedVersion = splitVersion(appVersion);

  return [joinVersion(splittedVersion.slice(0, 3)), splittedVersion[3]];
};
