import { nodeListSelectors, nodeSelectors, forEachComponentProps, PropChecker, } from '@builder/schemas';
import { isNull } from '@builder/utils';
import { transformVariableName } from '../../utils/transformVariableName';
import { CSSPropTransformer } from '../app-nodes-generator/prop-transformers/prop-type-transformers';
/**
 * Generates all constant styles for one node
 */
export class CSSConstantGenerator {
    constructor(nodeListDSL, componentListDSL) {
        this.nodeListDSL = nodeListDSL;
        this.componentListDSL = componentListDSL;
        this.cssPropTransformer = new CSSPropTransformer();
    }
    /**
     * Goes through the all node props and generates css constant for all styles
     */
    generateCSSConstant(nodeID) {
        const nodeDSL = nodeListSelectors.getNodeDSL(this.nodeListDSL, { nodeID });
        const nodeAlias = transformVariableName(nodeSelectors.getNodeAlias(nodeDSL, {
            componentListDSL: this.componentListDSL,
        }));
        let generatedCSSConstants = '';
        forEachComponentProps(({ propName, propPath, propValue, propDSL, propListValues, propListDSL }) => {
            if (PropChecker.Schema.isCSSProp(propDSL)) {
                const entityName = nodeAlias;
                const cssConstant = this.cssPropTransformer.generateCSSConstant({
                    propName,
                    propPath,
                    propValue,
                    propDSL,
                    propListValues: propListValues !== null && propListValues !== void 0 ? propListValues : {},
                    propListDSL,
                    entityName,
                });
                if (!isNull(cssConstant)) {
                    generatedCSSConstants += `${cssConstant}\n\n`;
                }
            }
        }, this.componentListDSL, nodeDSL);
        return generatedCSSConstants;
    }
}
