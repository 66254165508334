import { APP_ENGINE_AUDIT_SCOPES, APP_ENGINE_AUDIT_TYPES } from './constants';
export const createNodePropError = ({ nodeID, propPath, message, }) => ({
    scope: APP_ENGINE_AUDIT_SCOPES.node,
    type: APP_ENGINE_AUDIT_TYPES.error,
    nodeID,
    propPath: ['props', ...propPath],
    message,
});
export const createNodeError = ({ nodeID, propPath, message, }) => ({
    scope: APP_ENGINE_AUDIT_SCOPES.node,
    type: APP_ENGINE_AUDIT_TYPES.error,
    nodeID,
    propPath,
    message,
});
export const createNodeConditionError = ({ nodeID, message, }) => ({
    scope: APP_ENGINE_AUDIT_SCOPES.node,
    type: APP_ENGINE_AUDIT_TYPES.error,
    nodeID,
    propPath: ['condition'],
    message,
});
export const createStateRequestError = ({ stateID, propPath, message, }) => ({
    scope: APP_ENGINE_AUDIT_SCOPES.stateRequest,
    type: APP_ENGINE_AUDIT_TYPES.error,
    stateID,
    propPath,
    message,
});
export const createStateError = ({ stateID, message, propPath = [], }) => ({
    scope: APP_ENGINE_AUDIT_SCOPES.state,
    type: APP_ENGINE_AUDIT_TYPES.error,
    propPath,
    stateID,
    message,
});
