import { ERROR_SCOPES, SystemError } from '../errors';
import { serialize } from '../string';
const downloadFile = (dataStr, filename) => {
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
};
export const downloadObjectAsJSONFile = (object, filename) => {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(serialize.stringify(object, { pretty: true }))}`;
    downloadFile(dataStr, filename);
};
export const simulateFileInputClick = (fileInputID) => {
    const loadDSLInput = document.getElementById(fileInputID);
    if (!loadDSLInput) {
        throw new SystemError(ERROR_SCOPES.utils, 'Input not found.');
    }
    loadDSLInput.click();
};
