import { useMemo } from 'react';

import { StateFunctionDSL, stateListSelectors } from '@builder/schemas';

import { useAppDSLStates } from 'src/providers/ReduxProvider';

type UseStateFunctionListDSLReturnType = {
  localStateFunctionArrayDSL: StateFunctionDSL[];
  globalStateFunctionArrayDSL: StateFunctionDSL[];
  currentStateFunctionListDSL: Record<string, StateFunctionDSL>;
};

export const useStateFunctionListDSL = (): UseStateFunctionListDSLReturnType => {
  const userAppStates = useAppDSLStates();

  return useMemo(() => {
    const currentStateFunctionListDSL = stateListSelectors.getFunctionStateListDSL(userAppStates);
    const localStateFunctionArrayDSL = stateListSelectors.getLocalStateArrayDSL(
      currentStateFunctionListDSL,
    ) as StateFunctionDSL[];
    const globalStateFunctionArrayDSL = stateListSelectors.getGlobalStateArrayDSL(
      currentStateFunctionListDSL,
    ) as StateFunctionDSL[];

    return {
      localStateFunctionArrayDSL,
      globalStateFunctionArrayDSL,
      currentStateFunctionListDSL,
    };
  }, [userAppStates]);
};
