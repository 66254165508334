import { AppEvents, DashboardState, DASHBOARD_EVENTS } from '../../../../common';
import { dashboardInitial } from '../../../initial-state';

export const themeManagerReducer = (
  state: DashboardState['themeManager'] = dashboardInitial.themeManager,
  event: AppEvents,
): DashboardState['themeManager'] => {
  switch (event.type) {
    case DASHBOARD_EVENTS.setThemeManagerVisibility:
      return {
        ...state,
        isOpen: event.value,
      };

    default:
      return state;
  }
};
