import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, getBasicPropsSettings, getCommonPropertiesSections, getStylesSection, } from '../../common';
import { getDivider } from '../../common/settings';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_PROPS_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['disableSpacing'],
                label: 'Disable Spacing',
            },
        ],
    },
    getDivider(),
    ...getBasicPropsSettings(),
    getDivider(),
    ...getCommonPropertiesSections(),
];
export const MaterialDialogActionsSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
};
