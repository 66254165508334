import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonBasicProps, commonComponentProps, getCommonSymbolEventProps, getCommonSymbolStyleProps, } from '../../common/commonProps';
import { ListItemSymbolSettings } from './ListItemSymbol.settings';
const DEFAULT_LIST_ITEM = {
    name: COMPONENT_DSL_NAMES.MaterialListItem,
    props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { alignItems: '{{ symbolProps.alignItems }}', dense: '{{ symbolProps.dense }}', disableGutters: '{{ symbolProps.disableGutters }}', divider: '{{ symbolProps.divider }}', children: {
            nodes: [
                {
                    name: COMPONENT_DSL_NAMES.Fragment,
                    condition: {
                        showIf: '{{ symbolProps.startIcon }}',
                    },
                    props: {
                        children: '{{ symbolProps.startIcon }}',
                    },
                },
                {
                    name: COMPONENT_DSL_NAMES.Fragment,
                    props: {
                        children: '{{ symbolProps.listItemText }}',
                    },
                },
            ],
        } }),
};
const DEFAULT_LIST_ITEM_BUTTON = {
    name: COMPONENT_DSL_NAMES.MaterialListItemButton,
    props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { selected: '{{ symbolProps.selected }}', alignItems: '{{ symbolProps.alignItems }}', autoFocus: '{{ symbolProps.autoFocus }}', dense: '{{ symbolProps.dense }}', disabled: '{{ symbolProps.disabled }}', disableGutters: '{{ symbolProps.disableGutters }}', divider: '{{ symbolProps.divider }}', id: '{{ symbolProps.id }}', title: '{{ symbolProps.title }}', className: '{{ symbolProps.className }}', lang: '{{ symbolProps.lang }}', translate: '{{ symbolProps.translate }}', children: {
            nodes: [
                {
                    name: COMPONENT_DSL_NAMES.Fragment,
                    condition: {
                        showIf: '{{ symbolProps.startIcon }}',
                    },
                    props: {
                        children: '{{ symbolProps.startIcon }}',
                    },
                },
                {
                    name: COMPONENT_DSL_NAMES.Fragment,
                    props: {
                        children: '{{ symbolProps.listItemText }}',
                    },
                },
            ],
        } }),
};
export const ListItemSymbol = {
    name: COMPONENT_DSL_NAMES.ListItemSymbol,
    displayName: 'List Item',
    icon: 'listItem',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.other,
    schema: {
        mainPropPath: {
            mainProp: 'Text',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialList,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsMenu,
                },
            },
        ],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        },
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                button: false,
                tooltipPlacement: 'bottom',
                alignItems: 'center',
                listItemText: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialListItemText,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'List Item',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonBasicProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.MaterialListItemIcon,
                    COMPONENT_DSL_NAMES.MaterialListItemText,
                    COMPONENT_DSL_NAMES.BuilderComponentsTooltip,
                ],
            }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, tooltipTitle: { type: COMPONENT_DSL_PROP_TYPES.string }, tooltipPlacement: { type: COMPONENT_DSL_PROP_TYPES.enum }, button: { type: COMPONENT_DSL_PROP_TYPES.boolean }, selected: { type: COMPONENT_DSL_PROP_TYPES.boolean }, alignItems: { type: COMPONENT_DSL_PROP_TYPES.enum }, dense: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableGutters: { type: COMPONENT_DSL_PROP_TYPES.boolean }, divider: { type: COMPONENT_DSL_PROP_TYPES.boolean }, startIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, listItemText: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, startIconStyle: { type: COMPONENT_DSL_PROP_TYPES.object }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.Fragment,
            props: {
                children: {
                    nodes: [
                        Object.assign(Object.assign({}, DEFAULT_LIST_ITEM), { condition: {
                                showIf: '{{ !symbolProps.tooltipTitle && !symbolProps.button }}',
                            } }),
                        Object.assign(Object.assign({}, DEFAULT_LIST_ITEM_BUTTON), { condition: {
                                showIf: '{{ !symbolProps.tooltipTitle && symbolProps.button }}',
                            } }),
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsTooltip,
                            condition: {
                                showIf: '{{ symbolProps.tooltipTitle }}',
                            },
                            props: {
                                placement: '{{ symbolProps.tooltipPlacement }}',
                                title: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: '{{ symbolProps.tooltipTitle }}',
                                            },
                                        },
                                    ],
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Fragment,
                                            props: {
                                                children: {
                                                    nodes: [
                                                        Object.assign(Object.assign({}, DEFAULT_LIST_ITEM), { condition: {
                                                                showIf: '{{ !symbolProps.button }}',
                                                            } }),
                                                        Object.assign(Object.assign({}, DEFAULT_LIST_ITEM_BUTTON), { condition: {
                                                                showIf: '{{ symbolProps.button }}',
                                                            } }),
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                draggable: '{{ symbolProps.draggable }}',
            },
        },
    },
    settings: ListItemSymbolSettings,
};
