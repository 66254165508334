import { NodeDSL } from '@builder/schemas';

import {
  BEHAVIOR,
  BUILDER_COMPONENTS_FORM_MULTISELECT,
  BUILDER_COMPONENTS_MULTISELECT,
  CHECKMARKS,
  CHIP,
} from '../constants';

export const multiselectsValidations = (
  selectedNodeDSL: NodeDSL,
  propLabel: string,
  keyValue: string | number,
): {
  isBuilderComponentsMultiselect: boolean;
  isBuilderComponentsFormMultiselect: boolean;
  isBehaviorPropAndCheckOrChip: boolean;
} => {
  return {
    isBuilderComponentsMultiselect: selectedNodeDSL.name === BUILDER_COMPONENTS_MULTISELECT,
    isBuilderComponentsFormMultiselect:
      selectedNodeDSL.name === BUILDER_COMPONENTS_FORM_MULTISELECT,
    isBehaviorPropAndCheckOrChip:
      propLabel === BEHAVIOR && (keyValue === CHECKMARKS || keyValue === CHIP),
  };
};
