var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PhoneIcon from '@mui/icons-material/Phone';
import { FormControlLabel, Tab as MaterialPanel, } from '@mui/material';
export const Tab = forwardRef((_a, ref) => {
    var { disabled, value, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, style, title, className, lang, translate, wrapped, icon, iconposition, id, label, header, sx } = _a, otherTabProps = __rest(_a, ["disabled", "value", 'data-test', 'data-node-id', 'data-node-render-path', "style", "title", "className", "lang", "translate", "wrapped", "icon", "iconposition", "id", "label", "header", "sx"]);
    let iconToshow;
    switch (icon) {
        case 'phone':
            iconToshow = _jsx(PhoneIcon, {});
            break;
        case 'favorite':
            iconToshow = _jsx(FavoriteIcon, {});
            break;
        case 'person':
            iconToshow = _jsx(PersonPinIcon, {});
            break;
        default:
            iconToshow = undefined;
            break;
    }
    return (_jsx(FormControlLabel, { ref: ref, disableTypography: true, style: style, label: "", control: _jsx(MaterialPanel, Object.assign({}, otherTabProps, { label: label, style: style, value: value, id: value, disabled: disabled, icon: iconToshow, iconPosition: iconposition, wrapped: wrapped, className: className, sx: sx })), "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, sx: Object.assign(Object.assign({ justifyContent: 'center' }, sx), { marginLeft: '5px' }), title: title, className: className, lang: lang, id: id, translate: translate }));
});
