export const getFirstValues = ({
  stateAccessPath,
  position,
  isStateOrRequest,
  selectedState,
  selectedStateFirstChild,
}: {
  stateAccessPath: string[];
  position: number;
  isStateOrRequest: boolean;
  selectedState?: string;
  selectedStateFirstChild?: string;
}): string => {
  let response = '';
  if (isStateOrRequest) {
    if (stateAccessPath && stateAccessPath[position]) {
      response = stateAccessPath[position].replace('?.value', '').replace('?.data', '');
    }

    if (position === 1 && selectedState !== '') {
      response = stateAccessPath[position] || '';
    }

    if (position === 2 && selectedStateFirstChild !== '') {
      response = stateAccessPath[position] || '';
    }
  }

  return response;
};
