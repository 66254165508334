import { UPPERCASE_PATTERN, MS_PATTERN } from './constants';
/**
 * Hyphenates a camelcased CSS property name
 * @example
 * 'backgroundColor' -> 'background-color'
 * 'color' -> 'color'
 * 'WebkitTransition' -> '-webkit-transition'
 * 'msTransition' -> '-ms-transition'
 */
export const hyphenateStyleName = (propName) => {
    //  Note: we cannot use changeCase.paramCase(propName) here because we want to handle such cases:
    // 'WebkitTransition' -> '-webkit-transition'
    const hyphenatedName = propName.replace(UPPERCASE_PATTERN, (match) => `-${match.toLowerCase()}`);
    // If the propName starts from "ms" we add a "dash" symbol before it
    return MS_PATTERN.test(hyphenatedName) ? `-${hyphenatedName}` : hyphenatedName;
};
