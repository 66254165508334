import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, COMPONENT_DSL_NAMES, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { NS_ID, pickSettings, getBasicPropsSettings, omitSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const getBuilderComponentsRatingSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'value'],
                            label: 'Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'defaultValue'],
                            name: 'defaultValue',
                            label: 'Default value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'precision'],
                            options: [
                                { value: 0.5, label: '0.5p' },
                                { value: 1, label: '1p' },
                                { value: 2, label: '2p' },
                                { value: 3, label: '3p' },
                                { value: 4, label: '4p' },
                                { value: 5, label: '5p' },
                            ],
                            name: 'precision',
                            label: 'Precision',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'max'],
                            name: 'max',
                            label: 'Max',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'emptyLabelText'],
                            name: 'emptyLabelText',
                            label: 'Empty label text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'name'],
                            name: 'name',
                            label: 'Rating name',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            label: 'Read only',
                            name: 'readOnly',
                            keyPath: [...prefixPath, 'readOnly'],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            name: 'disabled',
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'highlightSelectedOnly'],
                            name: 'highlightSelectedOnly',
                            label: 'Highlight selected only',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'icon',
                            label: 'Icon',
                            keyPath: [...prefixPath, 'icon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: omitSettings([['size'], ['viewBox']])(getCommonIconSettings()),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'emptyIcon',
                            label: 'Empty Icon',
                            keyPath: [...prefixPath, 'emptyIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: omitSettings([['size'], ['viewBox']])(getCommonIconSettings()),
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'getLabelText',
                            keyPath: [...prefixPath, 'getLabelText'],
                            label: 'Get Label Text',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'size'],
                            label: 'Size',
                            name: 'size',
                            options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...pipe(pickSettings([
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.font.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.size.root],
                    [NS_ID.style.background.root],
                    [NS_ID.style.border.root],
                    [NS_ID.style.cursor.root],
                    [NS_ID.style.css.root],
                ]))(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeActiveAction',
                            label: 'On Change Active',
                            keyPath: [...prefixPath, 'onChangeActive'],
                            children: getCommonActionSettings([...prefixPath, 'onChangeActive']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsRatingSettings = getBuilderComponentsRatingSettings();
