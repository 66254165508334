var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useState, forwardRef, useCallback, useMemo, useEffect } from 'react';
import { ApolloProvider, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import * as filestack from 'filestack-js';
import { FILE_UPLOAD_INFO_MUTATION, FILE_UPLOAD_INFO_QUERY, getApolloClient, validateCorrectFileTypes, isFileExceedingFileSize, } from './utils';
const FileInput = styled.input `
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
const InputFileUpload = ({ 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, accept, alignContent, alignItems, alignSelf, apiToken, backgroundColor, children, className, color, disabled, display, draggable, exceededFileSizeText, flex, flexDirection, flexGrow, flexShrink, flexWrap, id, justifyContent, lang, maxFileSize, style = {}, order, overflow, textOverflow, title, translate, uploadOnChange, visibility, whiteSpace, workspaceId, wrongFileTypeText, onChange, onSuccess, onError, setMethods, }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [fileStackClient, setFileStackClient] = useState();
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFile, setUploadedFile] = useState({
        filename: '',
        fileId: '',
    });
    const isDisabled = useMemo(() => disabled || !apiToken || !workspaceId, [
        disabled,
        apiToken,
        workspaceId,
    ]);
    useEffect(() => {
        setMethods((prev) => (Object.assign(Object.assign({}, prev), { fileUploaded: uploadedFile, progress, upload: () => uploadFile(selectedFile) })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, progress, uploadedFile]);
    const { data: fileUploadInfo } = useQuery(FILE_UPLOAD_INFO_QUERY, {
        onCompleted(data) {
            const { apiKey, policy, signature } = data.fileUploadInfo;
            setFileStackClient(filestack.init(apiKey, {
                security: {
                    policy,
                    signature,
                },
                sessionCache: false,
            }));
        },
        onError() {
            setFileStackClient(null);
        },
    });
    const [addFile] = useMutation(FILE_UPLOAD_INFO_MUTATION);
    /** Handlers */
    // Sets a className when user is draging over the component
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsHovering(true);
    };
    // Removes a className when leaving the container
    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsHovering(false);
    };
    const handleDrop = (event) => {
        event.preventDefault();
        const fileArray = [];
        if (event.dataTransfer.items) {
            for (const item of event.dataTransfer.items) {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    file && fileArray.push(file);
                }
            }
        }
        else {
            for (const item of event.dataTransfer.files) {
                item.type && fileArray.push(item);
            }
        }
        // Removes hovering className
        if (isHovering) {
            setIsHovering(false);
        }
        // If file is correct uploads the file or saves it in the component state (not 8base state)
        if (!isFileExceedingFileSize(fileArray, maxFileSize, exceededFileSizeText) &&
            validateCorrectFileTypes(fileArray, accept, wrongFileTypeText)) {
            if (uploadOnChange) {
                uploadFile(fileArray).then(() => onChange === null || onChange === void 0 ? void 0 : onChange(fileArray));
            }
            else {
                setUploadedFile({ fileId: '', filename: fileArray[0].name });
                setSelectedFile(fileArray);
                onChange === null || onChange === void 0 ? void 0 : onChange(fileArray);
            }
        }
    };
    // Handles when user clicks and uploads file using OS
    const handleInputChange = (event) => {
        event.preventDefault();
        const fileArray = event.target.files ? Array.from(event.target.files) : [];
        if (!isFileExceedingFileSize(fileArray, maxFileSize, exceededFileSizeText) &&
            validateCorrectFileTypes(fileArray, accept, wrongFileTypeText)) {
            if (uploadOnChange) {
                uploadFile(fileArray).then(r => onChange === null || onChange === void 0 ? void 0 : onChange(fileArray));
            }
            else {
                setUploadedFile({ fileId: '', filename: fileArray[0].name });
                setSelectedFile(fileArray);
                onChange === null || onChange === void 0 ? void 0 : onChange(fileArray);
            }
        }
    };
    // Uploads the files to the 8Base backend
    const uploadFile = useCallback((files) => __awaiter(void 0, void 0, void 0, function* () {
        if (!files)
            return;
        setProgress(0);
        try {
            const response = yield (fileStackClient === null || fileStackClient === void 0 ? void 0 : fileStackClient.upload(files[0], {
                onProgress: evt => {
                    setProgress(evt.totalPercent);
                },
            }, {
                path: fileUploadInfo === null || fileUploadInfo === void 0 ? void 0 : fileUploadInfo.fileUploadInfo.path,
            }).then(r => {
                setProgress(100);
                return r;
            }));
            yield addFile({
                variables: {
                    file: { filename: response.filename, fileId: response.handle },
                },
            });
            setUploadedFile({ filename: response.filename, fileId: response.handle });
            onSuccess && onSuccess();
        }
        catch (e) {
            const errorMsg = new Error(JSON.stringify(e));
            onError && onError(errorMsg);
            setProgress(0);
        }
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addFile, fileStackClient, fileUploadInfo === null || fileUploadInfo === void 0 ? void 0 : fileUploadInfo.fileUploadInfo.path, onError, onSuccess]);
    return (_jsx(_Fragment, { children: _jsxs("label", Object.assign({ className: `${className || ''} ${isDisabled ? 'disabled' : ''} ${isHovering ? 'hovering' : ''}`, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, draggable: draggable, htmlFor: id ? `dropzonebase-${id}` : undefined, id: id, lang: lang, onDragLeave: handleDragLeave, onDragOver: handleDragOver, onDrop: handleDrop, style: Object.assign({ alignContent,
                alignSelf,
                alignItems,
                backgroundColor,
                color,
                display,
                flex,
                flexDirection,
                flexGrow,
                flexShrink,
                flexWrap,
                justifyContent, minHeight: '36 px', order,
                overflow, pointerEvents: isDisabled ? 'none' : undefined, textOverflow,
                visibility,
                whiteSpace }, style), title: title, translate: translate }, { children: [_jsx(FileInput, { accept: accept, id: id ? `dropzonebase-${id}` : undefined, lang: lang, name: "fileDnDBase", onChange: handleInputChange, translate: translate, type: "file" }), children] })) }));
};
export const FileDropZoneBase = forwardRef((_a, ref) => {
    var { workspaceId, apiToken } = _a, rest = __rest(_a, ["workspaceId", "apiToken"]);
    const client = useMemo(() => getApolloClient(workspaceId, apiToken), [apiToken, workspaceId]);
    return (_jsx(ApolloProvider, Object.assign({ client: client }, { children: _jsx(InputFileUpload, Object.assign({ apiToken: apiToken, workspaceId: workspaceId }, rest)) })));
});
