import { isUndefined } from '@builder/utils';
import { COMPONENT_DSL_NAMES } from '../constants';
import { hasPropJsCode } from '../utils/prop-transformers/transformPropWithJsCode';
import { PropAssert, PropChecker } from '../validation';
export const getAllChildrenIDsByProps = ({ propsSchema, chooseOnlyFromProps, nodeProps, }) => {
    return Object.keys(propsSchema).reduce((accum, propName) => {
        if (isUndefined(nodeProps)) {
            return accum;
        }
        const propSchema = propsSchema[propName];
        const propValue = nodeProps[propName];
        if (isUndefined(propValue)) {
            return accum;
        }
        if (chooseOnlyFromProps && !chooseOnlyFromProps.includes(propName)) {
            return accum;
        }
        if (PropChecker.Schema.isObjectPropWithDefinedProps(propSchema)) {
            return [
                ...accum,
                ...getAllChildrenIDsByProps({
                    propsSchema: propSchema.props,
                    nodeProps: nodeProps[propName],
                    chooseOnlyFromProps,
                }),
            ];
        }
        if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(propSchema)) {
            PropAssert.Value.assertIsArrayProp(propValue, propName);
            const childrenIdsFromArray = propValue.flatMap(arrayValue => getAllChildrenIDsByProps({
                propsSchema: propSchema.item.props,
                nodeProps: arrayValue,
                chooseOnlyFromProps,
            }));
            return [...accum, ...childrenIdsFromArray];
        }
        if (PropChecker.Schema.isRenderableProp(propSchema)) {
            const renderableNodeTemplate = nodeProps[propName];
            if (hasPropJsCode(renderableNodeTemplate)) {
                return accum;
            }
            return [...accum, ...renderableNodeTemplate.nodes];
        }
        return accum;
    }, []);
};
export const formContainerNames = [
    COMPONENT_DSL_NAMES.BuilderComponentsForm,
    COMPONENT_DSL_NAMES.BuilderComponentsFormFieldArray,
];
export const formChildrenValidNames = [
    COMPONENT_DSL_NAMES.BuilderComponentsButton,
    COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
    COMPONENT_DSL_NAMES.MaterialButtonGroup,
    COMPONENT_DSL_NAMES.MaterialIconButton,
    COMPONENT_DSL_NAMES.BuilderComponentsFileUploadBase,
    COMPONENT_DSL_NAMES.BuilderComponentsFileUploadButtonBase,
    COMPONENT_DSL_NAMES.MaterialAlert,
    COMPONENT_DSL_NAMES.AccordionSymbol,
    COMPONENT_DSL_NAMES.AlertSymbol,
    COMPONENT_DSL_NAMES.LocalDialogSymbol,
    COMPONENT_DSL_NAMES.BuilderComponentsDataGrid,
    COMPONENT_DSL_NAMES.BuilderComponentsIcon,
    COMPONENT_DSL_NAMES.BuilderComponentsImage,
    COMPONENT_DSL_NAMES.BuilderComponentsRating,
    COMPONENT_DSL_NAMES.BuilderComponentsRouterLink,
    COMPONENT_DSL_NAMES.HeadingSymbol,
    COMPONENT_DSL_NAMES.ListItemSymbol,
    COMPONENT_DSL_NAMES.NavListItemSymbol,
    COMPONENT_DSL_NAMES.MaterialAvatar,
    COMPONENT_DSL_NAMES.MaterialDivider,
    COMPONENT_DSL_NAMES.MaterialBreadcrumbs,
    COMPONENT_DSL_NAMES.MaterialBadge,
    COMPONENT_DSL_NAMES.MaterialList,
    COMPONENT_DSL_NAMES.MaterialChip,
    COMPONENT_DSL_NAMES.BuilderComponentsTooltip,
    COMPONENT_DSL_NAMES.MaterialTypography,
    COMPONENT_DSL_NAMES.BuilderRichText,
    COMPONENT_DSL_NAMES.BuilderComponentsFormRichtextEditor,
    COMPONENT_DSL_NAMES.AppBarSymbol,
    COMPONENT_DSL_NAMES.BuilderComponentsMenu,
    COMPONENT_DSL_NAMES.BuilderComponentsPopper,
    COMPONENT_DSL_NAMES.CardSymbol,
    COMPONENT_DSL_NAMES.BuilderComponentsBox,
    COMPONENT_DSL_NAMES.AsyncContent,
    COMPONENT_DSL_NAMES.BuilderComponentsFooter,
    COMPONENT_DSL_NAMES.MaterialPaper,
    COMPONENT_DSL_NAMES.Iterator,
    COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
];
