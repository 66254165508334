export const OUTPUT_PROP_MODES = {
    jsx: 'jsx',
    js: 'js',
    raw: 'raw',
};
export const REGULAR_EXPRESSION = 'regularExpression';
export const TRANSFORMATION_MAP_SYMBOLS = {
    '{': '&#123;',
    '}': '&#125;',
    '!': '&#33;',
    '"': '&quot;',
    '#': '&#35;',
    $: '&#36;',
    '%': '&#37;',
    '&': '&amp;',
    '‘': '&#39;',
    '(': '&#40;',
    ')': '&#41;',
    '*': '&#42;',
    '+': '&#43;',
    ',': '&#44;',
    '–': '&#45;',
    '.': '&#46;',
    '/': '&#47;',
    ':': '&#58;',
    ';': '&#59;',
    '<': '&lt;',
    '=': '&#61;',
    '>': '&gt;',
    '?': '&#63;',
    '@': '&#64;',
    '[': '&#91;',
    '\\': '&#92;',
    ']': '&#93;',
    '^': '&#94;',
    _: '&#95;',
    '`': '&#96;',
    '|': '&#124;',
    '~': '&#126;',
};
export const TEXT_PROPERTIES = {
    children: 'children',
    label: 'label',
};
