import React, { useCallback, useMemo } from 'react';

import { isNil } from 'ramda';

import { hasPropJsCode, JSInjection } from '@builder/schemas';
import { serialize, isObject, isArray, useDebouncedState, isString } from '@builder/utils';

import { CodeEditorDialogArgs, DASHBOARD_DIALOGS } from 'src/dialogs';
import { useDialogState } from 'src/providers';
import { TextField, CodeEditorOpenButton } from 'src/shared/components';

type JSONViewEditorValue = Record<string, unknown> | Array<unknown> | JSInjection | undefined;

export type JSONViewEditorProps<T = JSONViewEditorValue> = {
  label: string;
  propValue?: T;
  passInvalidData?: boolean;
  onChangePropValue: (propValue?: T) => void;
  nodeID?: string;
  'data-test'?: string;
  skipDebounce?: boolean;
  showFx?: boolean;
  error?: boolean;
  helperText?: string;
};

const getInitialValue = <T extends JSONViewEditorValue>(value: T): T => {
  if (hasPropJsCode(value)) {
    return value;
  }

  if (isString(value)) {
    return value;
  }

  return serialize.stringify(value, { pretty: true }) as T;
};

export const JSONViewEditor = <T extends JSONViewEditorValue>({
  label,
  propValue,
  onChangePropValue: onChange,
  nodeID,
  passInvalidData = true,
  showFx = true,
  error,
  helperText,
  'data-test': dataTest,
  skipDebounce,
}: JSONViewEditorProps<T>): JSX.Element => {
  const initialValue = useMemo<T>(() => getInitialValue(propValue) as T, [propValue]);

  const updatePropByEvent = useCallback(
    (newValue: T) => {
      if (isNil(newValue) || newValue === '') {
        return onChange(undefined);
      }

      if (hasPropJsCode(newValue as T)) {
        onChange(newValue);
      } else {
        serialize.parse(
          newValue as string,
          () => {
            if (passInvalidData) {
              onChange(newValue);
            }
          },
          parsedData => {
            onChange(parsedData as T);
          },
        );
      }
    },
    [onChange, passInvalidData],
  );

  const [debouncedValue, setDebouncedValue] = useDebouncedState(initialValue, updatePropByEvent, {
    skipDebounce,
  });

  const { openDialog } = useDialogState<CodeEditorDialogArgs>();

  const openEditorDialog = useCallback(() => {
    const parsedData = serialize.parse(debouncedValue as string);
    const isJSON = isObject(parsedData) || isArray(parsedData);

    const validateBeforeSave = (value: string | undefined) => {
      if (!passInvalidData && !isNil(value)) {
        const result = serialize.parse(value);

        if (isNil(result)) {
          return 'Wrong syntax';
        }
      }

      return undefined;
    };

    openDialog(DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID, {
      initialValues: debouncedValue as string,
      label,
      nodeID,
      language: isJSON ? 'json' : 'typescript',
      validateBeforeSave,
      onSave: (val = '') => updatePropByEvent(val as T),
    });
  }, [debouncedValue, openDialog, label, nodeID, passInvalidData, updatePropByEvent]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      showFx={showFx}
      value={debouncedValue}
      onChange={event => setDebouncedValue(event.currentTarget.value as T)}
      data-test={dataTest}
      absoluteEndAdornmentPosition
      error={error}
      helperText={error && helperText}
      InputProps={{
        endAdornment: <CodeEditorOpenButton onClick={openEditorDialog} />,
      }}
    />
  );
};
