import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_17_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.3';
    }
    getChangeList() {
        return ['Migrate all local requests, functions and states to local scope'];
    }
    migrateNodeDSL(nodeDSL, appDSL) {
        const allContextInNode = Object.keys(appDSL.states).filter(stateID => appDSL.states[stateID].scope === 'local' && appDSL.states[stateID].parent === nodeDSL.id);
        if (allContextInNode.length) {
            const context = allContextInNode.reduce((allContext, stateID) => {
                const stateContext = appDSL.states[stateID];
                return Object.assign(Object.assign({}, allContext), { [stateContext.id]: stateContext });
            }, {});
            return Object.assign(Object.assign({}, nodeDSL), { context });
        }
        return nodeDSL;
    }
    migrateStateDSL(stateDSL, appDSL) {
        if (stateDSL.scope === 'local' && stateDSL.parent) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return null;
        }
        return stateDSL;
    }
    migrateStateListDSL(stateListDSL, appDSL) {
        const results = mapObjIndexed(stateDSL => this.migrateStateDSL(stateDSL, appDSL), stateListDSL);
        return Object.keys(results).reduce((acumm, key) => {
            if (results[key] === null) {
                return acumm;
            }
            return Object.assign(Object.assign({}, acumm), { [key]: results[key] });
        }, {});
    }
}
export const migration_0_17_3 = new Migration_0_17_3();
