/* eslint-disable camelcase */
import { ValueOf } from '@builder/utils';

export const DEPLOY_STATUSES = {
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
} as const;

export type DeployStatuses = ValueOf<typeof DEPLOY_STATUSES>;

export const DEPLOY_STATUS_TEXTS: Record<DeployStatuses, string> = {
  pending: 'Deploying',
  resolved: 'Deployed',
  rejected: 'Error',
} as const;

export const DEPLOY_STEP_NAMES = {
  download: 'download',
  deploy: 'deploy',
} as const;

export type DeployStepNames = ValueOf<typeof DEPLOY_STEP_NAMES>;

export const DEPLOY_STEP_NAME_TEXTS: Record<DeployStepNames, string> = {
  download: 'Download',
  deploy: 'Deploy',
} as const;

export const DEPLOY_STEP_TIMESTAMP_FORMAT = "yyyy-MM-dd HH:mm:ss 'GMT:'";
