import css from 'css';
import { intersection, isEmpty, isNil, uniqBy, uniq, equals } from 'ramda';
import { log } from '../../dev';
import { removeLineBreaks } from '../../string';
import { CSS_RULE_TYPE, CSS_STYLESHEET_TYPE } from '../constants';
import { FEATURE_PROP_PATTERNS, MQ_FEATURE } from './regexp';
import { isRule, isMedia, isDeclaration } from './typeGuards';
export class CSSParser {
    static updateCSSRulesInStylesheet(stylesheet, updateRules) {
        return Object.assign(Object.assign({}, stylesheet), { stylesheet: Object.assign(Object.assign({}, stylesheet.stylesheet), { rules: updateRules }) });
    }
    /**
     * @example
     * ```
     * getCSSBodyFromStylesheet(Stylesheet) => `
     *  :root {
     *    --primaryMain: #202020;
     *  }
     *  .someClass {
     *    background-color: #ffffff;
     *  }
     * `
     * ```
     */
    static getCSSBodyFromStylesheet(stylesheet, options = {}) {
        return stylesheet ? css.stringify(stylesheet, options) : '';
    }
    /**
     * @example
     * const cssBody = `
     *   :root {
     *     --primary: "#fff";
     *     --secondary: "#000";
     *   }
     *
     *  body {
     *     font-size: 12px;
     *   }
     *
     *   .some_class {
     *     color: var(--primary);
     *   }
     * `;
     *
     * getStylesheetFromCSSBody(cssBody) => Stylesheet
     */
    static getStylesheetFromCSSBody(cssBody) {
        let result = {
            stylesheet: {
                parsingErrors: [],
                rules: [],
            },
            type: CSS_STYLESHEET_TYPE,
        };
        try {
            const stylesheet = css.parse(cssBody || '');
            result = stylesheet;
            // eslint-disable-next-line no-empty
        }
        catch (error) {
            log.error(error);
        }
        return result;
    }
    /**
     * @example
     * ```
     * const value = `
     *   --primary: "#fff";
     *   --secondary: "#000";
     * `
     * const selector = ":root"
     *
     * wrapCSSpropertiesWithSelector(value, selector) => `
     *   :root {
     *     --primary: "#fff";
     *     --secondary: "#000";
     *   }
     * `
     * ```
     */
    static wrapCSSpropertiesWithSelector(value = '', selector) {
        return `${selector} {\n  ${value.trim()}\n}`;
    }
    /**
     * @example
     * selectorsEquals([':root'], [':root']) => true
     * selectorsEquals(['.some_class', 'tag_name'], ['tag_name', '.some_class']) => true
     * selectorsEquals(['.some_class', 'tag_name'], ['tag_name']) => false
     */
    static selectorsEquals(selectorsA = [], selectorsB = []) {
        if (selectorsA.length !== selectorsB.length) {
            return false;
        }
        const selectorsIntersection = intersection(selectorsA, selectorsB);
        return selectorsIntersection.length === selectorsA.length;
    }
    /**
     * @example
     * selectorsEquals([':root'], [':root']) => true
     * selectorsEquals(['.some_class', 'tag_name'], ['tag_name', '.some_class']) => true
     * selectorsEquals(['.some_class', 'tag_name'], ['tag_name']) => false
     */
    static selectorsInclude(selectorsA = [], selectorsB = []) {
        if (selectorsA.includes(selectorsB[0])) {
            return true;
        }
        return false;
    }
    /**
     * @example
     * const cssBody = `
     *   :root {
     *     --primary: "#fff";
     *     --secondary: "#000";
     *   }
     *
     *  body {
     *     font-size: 12px;
     *   }
     *
     *   .some_class {
     *     color: var(--primary);
     *   }
     * `;
     *
     * getVariablesFromCSSBody(cssBody) => { --primary: "#fff", --secondary: "#000" }
     */
    static getVariablesFromCSSBody(cssBody) {
        var _a;
        if (!cssBody) {
            return {};
        }
        const parsedCSS = this.getStylesheetFromCSSBody(cssBody);
        const cssRules = ((_a = parsedCSS === null || parsedCSS === void 0 ? void 0 : parsedCSS.stylesheet) === null || _a === void 0 ? void 0 : _a.rules) || [];
        const variables = cssRules
            .filter(rule => isRule(rule))
            .map(rule => rule.declarations)
            .flat()
            .filter(declaration => {
            var _a;
            return declaration && isDeclaration(declaration) && ((_a = declaration.property) === null || _a === void 0 ? void 0 : _a.startsWith('--'));
        })
            .reduce((acc, variableDeclaration) => {
            return Object.assign(Object.assign({}, acc), { [variableDeclaration
                    .property]: variableDeclaration.value });
        }, {});
        return variables;
    }
    /**
     * * @example
     * ```
     * const cssBody = `
     *    :root {
     *        --primary: "#ffffff";
     *        --secondary: "#000";
     *    }
     *    body {
     *        max-width: 30px;
     *    }
     * `
     * getCSSBodyWithOneRule(cssBody, [":root"]) => `
     *    :root {
     *        --primary: "#ffffff";
     *        --secondary: "#000";
     *    }
     * `
     * ```
     */
    static getCSSBodyWithOneRule(cssBody, selectors) {
        if (!cssBody) {
            return '';
        }
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        const stylesheetWithOneRule = this.getStylesheetWithOneRule(stylesheet, selectors);
        return stylesheetWithOneRule ? this.getCSSBodyFromStylesheet(stylesheetWithOneRule) : '';
    }
    /**
     * @example
     * ```
     * getCSSRulesFromStylesheet(stylesheet) => stylesheet.stylesheet?.rules || [];
     * ```
     */
    static getCSSRulesFromStylesheet(stylesheet) {
        var _a;
        return ((_a = stylesheet.stylesheet) === null || _a === void 0 ? void 0 : _a.rules) || [];
    }
    /**
     * Use this function if stylesheet contains lots of rules but you want to get Stylesheet with only one
     * specific rule from the initial Stylesheet.
     */
    static getStylesheetWithOneRule(stylesheet, selectors) {
        if (!stylesheet) {
            return undefined;
        }
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        if (isNil(cssRules) || isEmpty(cssRules)) {
            return undefined;
        }
        const selectorsEquals = (rule) => isRule(rule) && this.selectorsEquals(selectors, rule.selectors);
        const selectorRule = cssRules.find(selectorsEquals);
        return this.updateCSSRulesInStylesheet(stylesheet, selectorRule ? [selectorRule] : []);
    }
    /**
     * Use this function If you want to get a Rule with specific selectors from the initial Stylesheet
     */
    static getCSSRuleFromStylesheetBySelectors(stylesheet, selectors) {
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        if (isEmpty(cssRules)) {
            return undefined;
        }
        const selectorRule = cssRules.find(rule => isRule(rule) && this.selectorsEquals(selectors, rule.selectors));
        return selectorRule;
    }
    /**
     * Use this function If you want to get a Rule with specific selectors from the initial cssBody
     * @example
     * ```
     * const cssBody = `
     *    :root {
     *        --primary: "#ffffff";
     *        --secondary: "#000";
     *    }
     *    body {
     *        max-width: 30px;
     *    }
     * `;
     * const selectors = [':root'];
     *
     *
     * getCSSRuleFromCSSBodyBySelectors(cssBody, selectors) => Rule (AST which will describe all css properties in the ':root' selector)
     * ```
     */
    static getCSSRuleFromCSSBodyBySelectors(cssBody, selectors) {
        if (!cssBody) {
            return undefined;
        }
        const stylesheet = CSSParser.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return undefined;
        }
        return this.getCSSRuleFromStylesheetBySelectors(stylesheet, selectors);
    }
    /**
     * @returns value of css property from Stylesheet by specific selectors
     * @example
     * ```
     * getCSSPropertyFromStylesheetBySelectors({
     *  stylesheet,
     *  selectors: [':root'],
     *  propertyName: '--primaryMain',
     * }) => '#bada55'
     * ```
     */
    static getCSSPropertyFromStylesheetBySelectors({ stylesheet, selectors, propertyName, }) {
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        for (const rule of cssRules) {
            if (isRule(rule) && this.selectorsEquals(selectors, rule.selectors)) {
                const ruleDeclarations = [...(rule.declarations || [])];
                for (const declaration of ruleDeclarations) {
                    if (isDeclaration(declaration)) {
                        if (declaration.property === propertyName) {
                            return declaration.value;
                        }
                    }
                }
            }
        }
    }
    /**
     * @returns true if there's a css property with name equals to propertyName in the Rule with appropriate selectors and false otherwise.
     * @example
     * ```
     * checkIfCSSPropertyExistInStylesheetBySelectors({
     *  stylesheet,
     *  selectors: [':root'],
     *  propertyName: '--primaryMain',
     * }) => true
     * ```
     */
    static checkIfCSSPropertyExistInStylesheetBySelectors(data) {
        const cssPropertyValue = this.getCSSPropertyFromStylesheetBySelectors(data);
        return !isNil(cssPropertyValue);
    }
    /**
     * @returns Stylesheet without css property with name equals to propertyName
     */
    static removeCSSPropertyFromStylesheetBySelectors({ stylesheet, selectors, propertyName, }) {
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        const updatedCSSRules = cssRules.map(rule => {
            if (!isRule(rule) || !this.selectorsEquals(selectors, rule.selectors)) {
                return rule;
            }
            const currentRule = rule;
            const updatedCurrentRuleDeclarations = (currentRule.declarations || []).filter(declaration => {
                if (isDeclaration(declaration)) {
                    if (declaration.property === propertyName) {
                        return false;
                    }
                }
                return true;
            });
            return Object.assign(Object.assign({}, currentRule), { declarations: updatedCurrentRuleDeclarations });
        });
        return this.updateCSSRulesInStylesheet(stylesheet, updatedCSSRules);
    }
    static removeCSSFontFamilyPropertyFromStylesheetBySelectors({ stylesheet, selectors, propertyName, fontFamilyValue, }) {
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        const updatedCSSRules = cssRules.map(rule => {
            if (!isRule(rule) || !this.selectorsEquals(selectors, rule.selectors)) {
                return rule;
            }
            const currentRule = rule;
            const updatedCurrentRuleDeclarations = (currentRule.declarations || []).filter(declaration => {
                if (isDeclaration(declaration)) {
                    if (declaration.property === propertyName && declaration.value === fontFamilyValue) {
                        return false;
                    }
                }
                return true;
            });
            return Object.assign(Object.assign({}, currentRule), { declarations: updatedCurrentRuleDeclarations });
        });
        return this.updateCSSRulesInStylesheet(stylesheet, updatedCSSRules);
    }
    /**
     * @returns new Stylesheet with updated css property if it was exist in the initial Stylesheet otherwise returns Initial Stylesheet
     */
    static updateExistingCSSPropertyFromStylesheetBySelectors({ stylesheet, selectors, value, propertyName, }) {
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        const updatedCSSRules = cssRules.map(rule => {
            if (!isRule(rule) || !this.selectorsEquals(selectors, rule.selectors)) {
                return rule;
            }
            const currentRule = rule;
            const updatedCurrentRuleDeclarations = (currentRule.declarations || []).map(declaration => {
                if (isDeclaration(declaration)) {
                    if (declaration.property === propertyName) {
                        return Object.assign(Object.assign({}, declaration), { value });
                    }
                }
                return declaration;
            });
            return Object.assign(Object.assign({}, currentRule), { declarations: updatedCurrentRuleDeclarations });
        });
        return this.updateCSSRulesInStylesheet(stylesheet, updatedCSSRules);
    }
    /**
     * @returns new Stylesheet in which was added new css property
     */
    static addCSSPropertyFromStylesheetBySelectors({ stylesheet, selectors, value, propertyName, }) {
        const cssRules = this.getCSSRulesFromStylesheet(stylesheet);
        let isNewPropertyAddedToRule = false;
        const updatedCSSRules = [];
        for (const rule of cssRules) {
            if (!isRule(rule) || !this.selectorsEquals(selectors, rule.selectors)) {
                updatedCSSRules.push(rule);
            }
            else {
                const currentRule = rule;
                const newRule = Object.assign(Object.assign({}, currentRule), { declarations: [
                        ...(currentRule.declarations || []),
                        {
                            property: propertyName,
                            type: 'declaration',
                            value,
                        },
                    ] });
                isNewPropertyAddedToRule = true;
                updatedCSSRules.push(newRule);
            }
        }
        // If property wasn't added to the existed rule it means
        // that there's no such rule so we just add a Rule with appropriate selectors
        // and add new Property (Declaration) to this Rule
        if (!isNewPropertyAddedToRule) {
            updatedCSSRules.push({
                declarations: [
                    {
                        property: propertyName,
                        type: 'declaration',
                        value,
                    },
                ],
                selectors,
                type: CSS_RULE_TYPE,
            });
        }
        return this.updateCSSRulesInStylesheet(stylesheet, updatedCSSRules);
    }
    /**
     * @returns new Stylesheet in which was added new css property or updated or removed existed property
     */
    static updateCSSPropertyFromStylesheetBySelectors({ stylesheet, selectors, value, propertyName, }) {
        const isPropertyExist = this.checkIfCSSPropertyExistInStylesheetBySelectors({
            stylesheet,
            selectors,
            propertyName,
        });
        // 1. If value equals to null or undefined we should remove it
        if (!value) {
            if (!isPropertyExist) {
                // 1.1. property doesn't exist so we don't have to remove it
                return stylesheet;
            }
            // 1.2. remove existing property
            return this.removeCSSPropertyFromStylesheetBySelectors({
                stylesheet,
                selectors,
                propertyName,
            });
        }
        // 2. If value doesn't equal to null or undefined we should update it
        if (isPropertyExist) {
            // 2.1. update existing property
            return this.updateExistingCSSPropertyFromStylesheetBySelectors({
                stylesheet,
                value,
                selectors,
                propertyName,
            });
        }
        // 2.2. add new property if it doesn't exist
        return this.addCSSPropertyFromStylesheetBySelectors({
            stylesheet,
            value,
            selectors,
            propertyName,
        });
    }
    /**
     * update value of a single property inside the css string (cssBody)
     * * @example
     * ```
     * const initialData = {
     *   cssBody: `
     *      :root {
     *        --primary: "#fff";
     *        --secondary: "#000";
     *      }
     *
     *      body {
     *        font-size: 12px;
     *      }
     *    `,
     *    selectors: [":root"],
     *    value: "#bada55",
     *    propertyName: "--primary"
     * }
     *
     * updateCSSPropertyFromCSSBodyBySelectors(initialData) => `
     *      :root {
     *        --primary: "#bada55"; // <== this property was updated
     *        --secondary: "#000";
     *      }
     *
     *      body {
     *        font-size: 12px;
     *      }
     *    `
     * ```
     */
    static updateCSSPropertyFromCSSBodyBySelectors({ cssBody, selectors, value, propertyName, }) {
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return '';
        }
        const updatedStylesheet = this.updateCSSPropertyFromStylesheetBySelectors({
            stylesheet,
            selectors,
            value,
            propertyName,
        });
        return this.getCSSBodyFromStylesheet(updatedStylesheet);
    }
    /**
     * @returns new Stylesheet in which was replaced all Rule with appropriate selectors
     */
    static updateRuleFromStylesheetBySelectors({ stylesheet, selectors, cssBodyRule, }) {
        const currentCSSRules = this.getCSSRulesFromStylesheet(stylesheet);
        const stylesheetRule = this.getStylesheetFromCSSBody(cssBodyRule);
        if (!stylesheetRule) {
            return stylesheet;
        }
        const newRule = this.getCSSRuleFromStylesheetBySelectors(stylesheetRule, selectors);
        if (!newRule) {
            return stylesheet;
        }
        let isRuleReplacedWithNewOne = false;
        const updatedCSSRules = [];
        for (const rule of currentCSSRules) {
            if (isRule(rule) && CSSParser.selectorsEquals(selectors, rule.selectors)) {
                isRuleReplacedWithNewOne = true;
                updatedCSSRules.push(newRule);
            }
            else {
                updatedCSSRules.push(rule);
            }
        }
        if (!isRuleReplacedWithNewOne) {
            updatedCSSRules.push(newRule);
        }
        return this.updateCSSRulesInStylesheet(stylesheet, updatedCSSRules);
    }
    /**
     * @returns new cssBody in which was replaced all Rule with appropriate selectors
     * @example
     * ```
     * const cssBody = `
     *    :root {
     *        --primary: #202020;
     *        --secondary: #fff;
     *    }
     *    body {
     *        max-width: 30px;
     *    }
     * `;
     * const selectors = [':root'];
     * const cssBodyRule = `
     *    :root {
     *        --primary: #ffffff;
     *        --secondary: #000;
     *        --coolProperty: 20px;
     *    }
     * `;
     *
     * updateRuleFromCSSBodyBySelectors({
     *  cssBody,
     *  selectors,
     *  cssBodyRule
     * }) => `
     *    :root {
     *        --primary: #ffffff;
     *        --secondary: #000;
     *        --coolProperty: 20px;
     *    }
     *    body {
     *        max-width: 30px;
     *    }
     * `
     * ```
     */
    static updateRuleFromCSSBodyBySelectors({ cssBody = '', selectors, cssBodyRule, }) {
        if (!cssBodyRule) {
            return cssBody;
        }
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return cssBodyRule;
        }
        const updatedStylesheet = this.updateRuleFromStylesheetBySelectors({
            stylesheet,
            selectors,
            cssBodyRule,
        });
        return this.getCSSBodyFromStylesheet(updatedStylesheet);
    }
    /**
     * Use this function If you want to get a Rule with specific selectors from the initial cssBody
     * @example
     * ```
     * const cssBody = `
     *    .selector1 {
     *        background-color: red;
     *    }
     *    .selector2 {
     *        background-color: green;
     *    }
     *    .selector3 {}
     * `;
     * removeSelectorsFromCSSBody(cssBody, ['.selector1']) => `
     *    .selector2 {
     *        background-color: green;
     *    }
     *    .selector3 {}
     * `
     */
    static removeSelectorsFromCSSBody(cssBody = '', selectors) {
        var _a, _b;
        if (!cssBody) {
            return '';
        }
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return '';
        }
        const rules = (_b = (_a = stylesheet.stylesheet) === null || _a === void 0 ? void 0 : _a.rules) !== null && _b !== void 0 ? _b : [];
        const savedRules = [];
        for (const rule of rules) {
            if (isRule(rule) && !this.selectorsEquals(rule.selectors, selectors)) {
                savedRules.push(rule);
            }
            else if (!isRule(rule)) {
                savedRules.push(rule);
            }
        }
        const emptySelectorsCSS = this.getCSSBodyByEmptySelectors(savedRules);
        const notEmptySelectorsCSS = this.getCSSBodyFromStylesheet(Object.assign(Object.assign({}, stylesheet), { stylesheet: Object.assign(Object.assign({}, stylesheet.stylesheet), { rules: savedRules }) }));
        const trimmedNotEmptySelectorsCSS = removeLineBreaks(notEmptySelectorsCSS);
        return `${trimmedNotEmptySelectorsCSS}\n${emptySelectorsCSS}`;
    }
    /**
     * Use this function If you want to get css by empty selectors
     * css stringify methods can't stringify selectors with empty declarations in rules
     * @param {RuleUnionTypes[]} rules .
     * @example
     * ```
     * getCSSBodyByEmptySelectors(rules) => `
     *   .selector3 {}
     *   .selector4 {}
     * `
     */
    static getCSSBodyByEmptySelectors(rules) {
        const emptySelectors = rules
            .filter(rule => {
            var _a, _b;
            return isRule(rule) && ((_a = rule.selectors) === null || _a === void 0 ? void 0 : _a.length) !== 0 && ((_b = rule.declarations) === null || _b === void 0 ? void 0 : _b.length) === 0;
        })
            .map((rule) => {
            if (rule.selectors) {
                if (rule.selectors.length === 1) {
                    return rule.selectors[0];
                }
                return rule.selectors.join(', ');
            }
            return '';
        });
        return emptySelectors.map(selector => `${selector} {}\n`).join('');
    }
    /**
     * Use this function If you want to get a Rule with specific selectors from the initial cssBody
     * @example
     * ```
     * const cssBody = `
     *    .selector1 {
     *        background-color: red;
     *    }
     *    .selector1 {
     *        background-color: green;
     *    }
     * `;
     * getCSSBodyByUniqSelectors(cssBody, selectors) => `
     *    .selector1 {
     *        background-color: red;
     *    }
     * `
     */
    static getCSSBodyByUniqSelectors(cssBody = '') {
        var _a, _b;
        if (!cssBody) {
            return '';
        }
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return '';
        }
        const rules = (_b = (_a = stylesheet.stylesheet) === null || _a === void 0 ? void 0 : _a.rules) !== null && _b !== void 0 ? _b : [];
        const onlyRules = rules.filter(rule => isRule(rule) && rule.selectors);
        const uniqRules = uniqBy((rule) => uniq(rule.selectors))(onlyRules);
        const otherRules = rules.filter(rule => !isRule(rule));
        const uniqSelectorsCSS = this.getCSSBodyFromStylesheet(Object.assign(Object.assign({}, stylesheet), { stylesheet: Object.assign(Object.assign({}, stylesheet.stylesheet), { rules: [...uniqRules, ...otherRules] }) }));
        const trimmedUniqSelectorsCSS = removeLineBreaks(uniqSelectorsCSS);
        const emptySelectorsCSS = this.getCSSBodyByEmptySelectors(uniqRules);
        return `${trimmedUniqSelectorsCSS}\n ${emptySelectorsCSS}`;
    }
    // Media Queries
    static getMediaStringFromMediaRule(mediaRule) {
        return mediaRule.media;
    }
    /**
     * @returns feature property from feature
     * @param {RegExp} pattern.
     * @param {string} feature.
     * @example
     * ```
     * const feature = (min-width: 20px);
     * getFeaturePropByPattern(
     *    FEATURE_PROP_PATTERNS.resolutionValue,
     *    mediaFeature,
     *  ) => '20'
     *  getFeaturePropByPattern(
     *    FEATURE_PROP_PATTERNS.resolutionUnit,
     *    mediaFeature,
     *  ) => 'px'
     */
    static getFeaturePropByPattern(pattern, feature) {
        var _a;
        return (_a = feature.match(pattern)) === null || _a === void 0 ? void 0 : _a[0];
    }
    /**
     * Build new media feature string by AST properties.
     * @returns new media feature
     * @param {MediaFeature} mediaFeature - AST media feature.
     * @example
     * ```
     * const mediaFeature = { resolution: 'min-width', resolutionValue: '150', resolutionUnit: 'px' };
     * buildMediaQueryFeature(mediaFeature) => '(min-width: 150px)'
     * ```
     */
    static buildMediaQueryFeature(mediaFeature) {
        const { resolution, resolutionUnit, resolutionValue } = mediaFeature;
        return `(${resolution}: ${resolutionValue}${resolutionUnit})`;
    }
    /**
     * Parse media query string to media features
     * @returns {Array<MediaFeature>}
     * @param {string} mediaQuery.
     * @example
     * ```
     * const mediaString = 'only screen and (min-width: 1200px) and (max-width: 1400px)';
     * getMediaFeatures(mediaString) => [
     *  { resolution: 'min-width', resolutionValue: '1200', resolutionUnit: 'px' },
     *  { resolution: 'max-width', resolutionValue: '1400', resolutionUnit: 'px' },
     * ]
     * ```
     */
    static getMediaFeatures(mediaQuery) {
        const mediaFeatures = mediaQuery.match(MQ_FEATURE) || [];
        return mediaFeatures.map((mediaFeature) => {
            return {
                resolution: this.getFeaturePropByPattern(FEATURE_PROP_PATTERNS.resolution, mediaFeature),
                resolutionValue: this.getFeaturePropByPattern(FEATURE_PROP_PATTERNS.resolutionValue, mediaFeature),
                resolutionUnit: this.getFeaturePropByPattern(FEATURE_PROP_PATTERNS.resolutionUnit, mediaFeature),
            };
        });
    }
    /**
     * Use this function if you want to check has media features in media query.
     * @returns {boolean}
     * @param {Array<MediaFeature>} mediaFeatures.
     * @param {Array<Partial<MediaFeature>>} feature.
     * @example
     * ```
     * const mediaFeatures = [
     *  { resolution: 'min-width', resolutionValue: '1200', resolutionUnit: 'px' },
     *  { resolution: 'max-width', resolutionValue: '1400', resolutionUnit: 'px' },
     * ]
     * hasMediaFeature(mediaFeatures, { resolutionValue: '1200' }) => true
     * hasMediaFeature(mediaFeatures, { resolutionUnit: 'em' }) => false
     * ```
     */
    static hasMediaFeature(mediaFeatures, feature) {
        return Boolean(mediaFeatures.find(equals(feature)));
    }
    /**
     * Use this function if you want to replace media features properties in media query string.
     * @returns {string}
     * @param {string} mediaQueryString.
     * @param {Array<Partial<MediaFeature>>} mediaFeatures.
     * @example
     * ```
     * const mediaString = 'only screen and (min-width: 1200px) and (max-width: 1400px)';
     * const mediaFeatures = [
     *  { resolution: 'min-width', resolutionValue: '50', resolutionUnit: 'px' },
     *  { resolution: 'max-width', resolutionValue: '300', resolutionUnit: 'px' },
     * ]
     * convertMediaFeaturesToMediaString(
     *    mediaString,
     *    mediaFeatures
     *  ) => 'only screen and (min-width: 50px) and (max-width: 300px)'
     *  ```
     */
    static convertMediaFeaturesToMediaString(mediaQueryString, mediaFeatures) {
        var _a;
        if (!mediaFeatures) {
            return mediaQueryString;
        }
        // example: mediaQueryString = 'only screen and (min-width: 50px) and (max-width: 300px)'
        let newMediaQueryStr = mediaQueryString;
        (_a = mediaQueryString.match(MQ_FEATURE)) === null || _a === void 0 ? void 0 : _a.forEach((feature, idx) => {
            // example: feature = (min-width: 50px)
            let currentFeature = feature;
            // iterate by new mediaFeatures
            Object.entries(mediaFeatures[idx]).forEach(mediaFeature => {
                if (mediaFeature) {
                    const [featurePropName, featureValue] = mediaFeature;
                    // example: featurePropName = 'resolution'; featureValue = 'min'
                    // get regular expression
                    const featurePatternByFeaturePropName = FEATURE_PROP_PATTERNS[featurePropName];
                    if (featurePatternByFeaturePropName.test(feature) && featureValue) {
                        // replace old values of current media feature by regular expression
                        // example: (min-width: 50px) -> (min-width: 80px)
                        const updatedFeature = currentFeature.replace(featurePatternByFeaturePropName, featureValue);
                        currentFeature = updatedFeature;
                    }
                }
            });
            // replace old media feature
            // example: 'only screen and (min-width: 50px) and (max-width: 300px)' ->
            // 'only screen and (min-width: 80px) and (max-width: 300px)'
            newMediaQueryStr = newMediaQueryStr.replace(feature, currentFeature);
        });
        return newMediaQueryStr;
    }
    /**
     * Add new media feature in media query string to start or to end.
     * @returns {string}
     * @param {string} mediaQuery - source media query string.
     * @param {MediaFeature} mediaFeature.
     * @param {string} insertToStart - position new media feature in new media
     * @example
     * ```
     * const mediaString = 'only screen and (max-width: 1200px)';
     * const mediaFeature = { resolution: 'min-width', resolutionValue: '800', resolutionUnit: 'px' };
     * addMediaFeature(
     *  mediaString,
     *  mediaFeature
     * ) => 'only screen and (min-width: 800px) and (max-width: 1200px)'
     * addMediaFeature(
     *  mediaString,
     *  mediaFeature,
     *  false
     * ) => 'only screen and (max-width: 1200px) and (min-width: 800px)'
     * ```
     */
    static addMediaFeature(mediaQuery, mediaFeature, insertToStart = true) {
        var _a;
        const newMediaFeatureString = this.buildMediaQueryFeature(mediaFeature);
        const otherFeatures = ((_a = mediaQuery.match(MQ_FEATURE)) === null || _a === void 0 ? void 0 : _a.join('and')) || '';
        if (insertToStart) {
            return `only screen and ${newMediaFeatureString} and ${otherFeatures}`;
        }
        return `${mediaQuery} and ${newMediaFeatureString}`;
    }
    /**
     * Remove media feature in media query string.
     * @returns {string}
     * @param {string} mediaQuery - source media query string.
     * @param {MediaFeature} mediaFeature.
     * @example
     * ```
     * const mediaString = 'only screen and (min-width: 800px) (max-width: 1200px)';
     * const mediaFeature = { resolution: 'min-width', resolutionValue: '800', resolutionUnit: 'px' };
     * removeMediaFeature(
     *  mediaString,
     *  mediaFeature
     * ) => 'only screen and (max-width: 1200px)'
     * ```
     */
    static removeMediaFeature(mediaQuery, mediaFeature) {
        const mediaFeatureString = this.buildMediaQueryFeature(mediaFeature);
        return mediaQuery.replace(` and ${mediaFeatureString}`, '');
    }
    /**
     * Remove media feature in media query string.
     * @returns {string}
     * @param {string} cssBody - CSS.
     * @param {MediaFeaturePredicate} mediaFeaturePredicate
     *  Iterate by each media rule.
     *  Should return true if you want to remove media query item.
     * @example
     * ```
     * const cssBody = `
     *    @media only screen and (min-width: 300px) {}
     *    @media only screen and (min-width: 800px) (max-width: 1200px) {}
     *  `;
     *  removeMediaQueryByMediaFeatures(
     *    cssBody,
     *    mediaFeatures => hasMediaFeature(mediaFeatures, { resolutionValue: '300' })
     *  ) => `
     *    @media only screen and (min-width: 800px) (max-width: 1200px) {}
     *  `
     * ```
     */
    static removeMediaQueryByMediaFeatures(cssBody = '', mediaFeaturePredicate) {
        if (!cssBody) {
            return '';
        }
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return '';
        }
        const rules = this.getCSSRulesFromStylesheet(stylesheet);
        const updatedMediaQueryRules = rules.filter(rule => {
            if (!isMedia(rule)) {
                return true;
            }
            const mediaFeatureString = this.getMediaStringFromMediaRule(rule);
            if (!mediaFeatureString) {
                return false;
            }
            return !mediaFeaturePredicate(this.getMediaFeatures(mediaFeatureString), mediaFeatureString);
        });
        const updatedStylesheet = this.updateCSSRulesInStylesheet(stylesheet, updatedMediaQueryRules);
        return this.getCSSBodyFromStylesheet(updatedStylesheet);
    }
    /**
     * Remove media feature in media query string.
     * @returns {string}
     * @param {string} cssBody - CSS.
     * @param {MediaFeaturePredicate} mediaFeaturePredicate
     *  Iterate by each media rule.
     *  Should return new media string.
     * @example
     * ```
     * const cssBody = `
     *    @media only screen and (min-width: 300px) {}
     *    @media only screen and (min-width: 800px) (max-width: 1200px) {}
     *  `;
     *  updateMediaQueryFeatures(
     *    cssBody,
     *    (mediaFeatures, mediaQuery) => {
     *      const hasMinWidth300 = hasMediaFeature({ resolution: 'min-width', resolutionValue: '300' })
     *      if (hasMinWidth300) {
     *        return convertMediaFeaturesToMediaString(mediaQuery, {
     *          resolution: 'min-width',
     *          resolutionValue: '500',
     *          resolutionUnit: 'px',
     *        })
     *      }
     *
     *      return mediaQuery;
     *    })
     *  ) => `
     *    @media only screen and (min-width: 800px) (max-width: 1200px) {}
     *  `
     * ```
     */
    static updateMediaQueryFeatures(cssBody = '', mediaFeaturePredicate) {
        if (!cssBody) {
            return '';
        }
        const stylesheet = this.getStylesheetFromCSSBody(cssBody);
        if (!stylesheet) {
            return '';
        }
        const rules = this.getCSSRulesFromStylesheet(stylesheet);
        const updatedRules = rules.map(rule => {
            if (!isMedia(rule)) {
                return rule;
            }
            const mediaQueryString = this.getMediaStringFromMediaRule(rule);
            if (!mediaQueryString) {
                return rule;
            }
            const mediaFeatures = this.getMediaFeatures(mediaQueryString);
            return Object.assign(Object.assign({}, rule), { media: mediaFeaturePredicate(mediaFeatures, mediaQueryString) });
        });
        const updatedStyleSheet = this.updateCSSRulesInStylesheet(stylesheet, updatedRules);
        return this.getCSSBodyFromStylesheet(updatedStyleSheet);
    }
}
