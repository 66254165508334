import { nodeListSelectors, } from '@builder/schemas';
import { HookGenerator } from './HookGenerator';
/**
 * Provides an api to generate hooks state imports.
 */
export class HookListImportsGenerator {
    constructor({ appDSL, componentListDSL, assetListDSL, }) {
        this.appDSL = appDSL;
        this.hookGenerators = Object.values(this.appDSL.states).map(appStateDSL => new HookGenerator({ appDSL: this.appDSL, componentListDSL, appStateDSL, assetListDSL }));
    }
    getImportDataList() {
        return this.hookGenerators.map(hookGenerator => hookGenerator.getImportData());
    }
    getLocalImportDataList(usedNodeListDSL) {
        return this.hookGenerators
            .filter(hookGenerator => hookGenerator.isLocal() &&
            Boolean(nodeListSelectors.getNodeWithConnectedState(usedNodeListDSL, {
                stateDSL: hookGenerator.appStateDSL,
            })))
            .map(hookGenerator => hookGenerator.getImportData());
    }
    getGlobalImportDataList() {
        return this.hookGenerators
            .filter(hookGenerator => hookGenerator.isGlobal())
            .map(hookGenerator => hookGenerator.getImportData());
    }
}
