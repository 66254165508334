import { useMemo } from 'react';

import { JSHINT } from 'jshint';

import { APP_ENGINE_PREDEFINED_IMPORTS } from '@builder/app-engine';
import { libraryListSelectors } from '@builder/schemas';

import { useAppDispatch, useLibraryListDSL, useUserAppRuntimeState } from 'src/providers';
import { useDashboardCurrentRouteContentNodeDSL } from 'src/shared/hooks';
import { UI_BUILDER_EVENTS } from 'src/store';

const MAX_NOTIFICATION_ERRORS = 3;
const ECMASCRIPT_VERSION = 11;

const DEFAULT_JS_HINT_OPTIONS = {
  esversion: ECMASCRIPT_VERSION, // This option is used to specify the ECMAScript version to which the code must adhere.
  browser: true, // This option defines globals exposed by modern browsers: all the way from good old document and navigator to the HTML5 FileReader and other new developments in the browser world.
  devel: true, // This option defines globals that are usually used for logging poor-man's debugging: console, alert, etc.
  undef: true, // This option prohibits the use of explicitly undeclared variables. This option is very useful for spotting leaking and mistyped variables.
  eqeqeq: true, // This options prohibits the use of == and != in favor of === and !==
  asi: true, // This option suppresses warnings about missing semicolons.
  globals: [], // This option can be used to specify a white list of global variables that are not formally defined in the source code. This is most useful when combined with the undef option in order to suppress warnings for project-specific global variables.
} as const;

const predefinedAppEngineGlobals = APP_ENGINE_PREDEFINED_IMPORTS.map(imports => imports.varName);

type UseValidateJSCodeReturnType = {
  validate: (code: string, args?: [string]) => boolean;
};

export const useValidateJsCode = (): UseValidateJSCodeReturnType => {
  const send = useAppDispatch();
  const { globalState = {}, predefinedState = {}, localStateList = {} } = useUserAppRuntimeState();
  const currentRouteContentNodeDSL = useDashboardCurrentRouteContentNodeDSL();
  const localStateConnected = localStateList[currentRouteContentNodeDSL?.id as string];
  const libraryListDSL = useLibraryListDSL();
  const libraryArrayDSL = libraryListSelectors.getLibraryArrayDSL(libraryListDSL);
  const libraryGlobals = libraryArrayDSL.map(lib => lib.alias);
  const jsHintOptions = useMemo(
    () => ({
      ...DEFAULT_JS_HINT_OPTIONS,
      globals: [
        ...DEFAULT_JS_HINT_OPTIONS.globals,
        ...libraryGlobals,
        ...predefinedAppEngineGlobals,
        ...Object.keys(predefinedState),
        ...Object.keys(globalState),
        ...Object.keys(localStateConnected ?? {}),
      ],
    }),
    [libraryGlobals, predefinedState, globalState, localStateConnected],
  );

  const validate = (code: string, args?: [string]): boolean => {
    if (!args) JSHINT(code, jsHintOptions);
    else JSHINT(code, { ...jsHintOptions, globals: [...jsHintOptions.globals, ...args] });

    const data = JSHINT.data();

    if (data?.errors) {
      data?.errors.slice(0, MAX_NOTIFICATION_ERRORS).forEach(error => {
        send({
          type: UI_BUILDER_EVENTS.notificationSend,
          notification: {
            message: error.reason,
            options: { variant: 'error' },
          },
        });
      });
      return false;
    }

    return true;
  };

  return { validate };
};
