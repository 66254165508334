import { SettingsDSL } from '@builder/schemas';

export const useLocalWidth = (): {
  setLocalWidth: (width: number | undefined) => void;
  newSettings: ({ oldSettings }: { oldSettings: SettingsDSL | undefined }) => SettingsDSL;
} => {
  const LOCAL_WIDTH = 'dashboardWidth';
  const localWidth =
    (localStorage.getItem(LOCAL_WIDTH) === 'undefined'
      ? undefined
      : localStorage.getItem(LOCAL_WIDTH)) ?? undefined;
  const setLocalWidth = (width: number | undefined) =>
    localStorage.setItem(LOCAL_WIDTH, JSON.stringify(width));

  const newSettings = ({ oldSettings = {} }: { oldSettings?: SettingsDSL | undefined }) =>
    ({
      ...oldSettings,
      size: {
        ...oldSettings?.size,
        width: localWidth ? Number(localWidth) : oldSettings?.size?.width,
      },
    } as SettingsDSL);

  return { setLocalWidth, newSettings };
};
