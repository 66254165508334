import { useCallback } from 'react';

import { JSInjectionPropValue, StringPropValue } from '@builder/schemas';
import { useDebouncedState } from '@builder/utils';

import { TextField } from 'src/shared/components';

export type TextAreaViewEditorProps<T = StringPropValue | JSInjectionPropValue | undefined> = {
  label: string;
  propValue: T;
  onChangePropValue: (propValue: T) => void;
  skipDebounce?: boolean;
  'data-test'?: string;
};

export const TextAreaViewEditor: React.FC<TextAreaViewEditorProps> = ({
  label,
  propValue,
  onChangePropValue,
  skipDebounce,
  'data-test': dataTest,
}) => {
  const [debouncedValue, setDebouncedValue] = useDebouncedState(propValue, onChangePropValue, {
    skipDebounce,
  });

  const updatePropByEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDebouncedValue(event.target.value as string);
    },
    [setDebouncedValue],
  );

  return (
    <TextField
      fullWidth
      multiline
      variant="outlined"
      label={label}
      value={debouncedValue}
      size="small"
      onChange={updatePropByEvent}
      data-test={dataTest}
    />
  );
};
