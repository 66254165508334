import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const TABLE_COLUMN_GROUP = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['span'],
                            label: 'Span',
                            showIf: [
                                {
                                    keyPath: ['children', 'nodes', 0],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'col',
                            title: 'Columns',
                            expandedByDefault: true,
                            showIf: [
                                {
                                    keyPath: ['span'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                                },
                            ],
                            children: [
                                {
                                    type: COMPONENT_SETTING_TYPES.nodeList,
                                    keyPath: ['children'],
                                    label: 'Column',
                                    name: 'column',
                                    itemLabelKeyPath: [],
                                    items: [
                                        {
                                            name: COMPONENT_DSL_NAMES.tableColumn,
                                            settings: [
                                                {
                                                    componentView: COMPONENT_SETTING_VIEWS.number,
                                                    type: COMPONENT_SETTING_TYPES.prop,
                                                    keyPath: ['span'],
                                                    label: 'Span',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
