/* eslint-disable import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires, global-require */
export const PROJECT_TEMPLATE = {
    'scripts/build-esbuild.mjs': {
        content: require('!!raw-loader!./project-template/scripts/build-esbuild.mjs.template').default,
    },
    'src/shared/components/index.ts': {
        content: require('!!raw-loader!@builder/components/src/index.ts').default,
    },
    'src/shared/components/common/index.ts': {
        content: require('!!raw-loader!@builder/components/src/common/index.ts').default,
    },
    'src/shared/components/common/getIsInvalid.ts': {
        content: require('!!raw-loader!@builder/components/src/common/getIsInvalid.ts').default,
    },
    'src/shared/components/common/fieldValidation.ts': {
        content: require('!!raw-loader!@builder/components/src/common/fieldValidation.ts').default,
    },
    'src/shared/components/common/formComponentUtils.ts': {
        content: require('!!raw-loader!@builder/components/src/common/formComponentUtils.ts').default,
    },
    'src/shared/components/common/generateYupSchema.ts': {
        content: require('!!raw-loader!@builder/components/src/common/generateYupSchema.ts').default,
    },
    'src/shared/components/common/matchRoutesPaths.ts': {
        content: require('!!raw-loader!@builder/components/src/common/matchRoutesPaths.ts').default,
    },
    'src/shared/components/Button/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Button/index.ts').default,
    },
    'src/shared/components/Button/Button.tsx': {
        content: require('!!raw-loader!@builder/components/src/Button/Button.tsx').default,
    },
    'src/shared/components/FloatingActionButton/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FloatingActionButton/index.ts').default,
    },
    'src/shared/components/FloatingActionButton/FloatingActionButton.tsx': {
        content: require('!!raw-loader!@builder/components/src/FloatingActionButton/FloatingActionButton.tsx')
            .default,
    },
    'src/shared/components/Badge/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Badge/index.ts').default,
    },
    'src/shared/components/Badge/Badge.tsx': {
        content: require('!!raw-loader!@builder/components/src/Badge/Badge.tsx').default,
    },
    'src/shared/components/Box/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Box/index.ts').default,
    },
    'src/shared/components/Box/Box.tsx': {
        content: require('!!raw-loader!@builder/components/src/Box/Box.tsx').default,
    },
    'src/shared/components/DataGrid/index.ts': {
        content: require('!!raw-loader!@builder/components/src/DataGrid/index.ts').default,
    },
    'src/shared/components/DataGrid/DataGrid.tsx': {
        content: require('!!raw-loader!@builder/components/src/DataGrid/DataGrid.tsx').default,
    },
    'src/shared/components/Form/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Form/index.ts').default,
    },
    'src/shared/components/Form/Form.tsx': {
        content: require('!!raw-loader!@builder/components/src/Form/Form.tsx').default,
    },
    'src/shared/components/Form/types.tsx': {
        content: require('!!raw-loader!@builder/components/src/Form/types.ts').default,
    },
    'src/shared/components/FormFieldArray/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormFieldArray/index.ts').default,
    },
    'src/shared/components/FormFieldArray/FormFieldArray.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormFieldArray/FormFieldArray.tsx')
            .default,
    },
    'src/shared/components/FormTextField/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormTextField/index.ts').default,
    },
    'src/shared/components/FormTextField/FormTextField.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormTextField/FormTextField.tsx')
            .default,
    },
    'src/shared/components/FormCheckbox/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormCheckbox/index.ts').default,
    },
    'src/shared/components/FormCheckbox/FormCheckbox.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormCheckbox/FormCheckbox.tsx').default,
    },
    'src/shared/components/FormFileUpload/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormFileUpload/index.ts').default,
    },
    'src/shared/components/FormFileUpload/FormFileUpload.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormFileUpload/FormFileUpload.tsx')
            .default,
    },
    'src/shared/components/Checkbox/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Checkbox/index.ts').default,
    },
    'src/shared/components/Checkbox/Checkbox.tsx': {
        content: require('!!raw-loader!@builder/components/src/Checkbox/Checkbox.tsx').default,
    },
    'src/shared/components/Radio/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Radio/index.ts').default,
    },
    'src/shared/components/ToggleButton/index.ts': {
        content: require('!!raw-loader!@builder/components/src/ToggleButton/index.ts').default,
    },
    'src/shared/components/ToggleButton/ToggleButton.tsx': {
        content: require('!!raw-loader!@builder/components/src/ToggleButton/ToggleButton.tsx').default,
    },
    'src/shared/components/ToggleButtonGroup/index.ts': {
        content: require('!!raw-loader!@builder/components/src/ToggleButtonGroup/index.ts').default,
    },
    'src/shared/components/ToggleButtonGroup/ToggleButtonGroup.tsx': {
        content: require('!!raw-loader!@builder/components/src/ToggleButtonGroup/ToggleButtonGroup.tsx')
            .default,
    },
    'src/shared/components/Radio/Radio.tsx': {
        content: require('!!raw-loader!@builder/components/src/Radio/Radio.tsx').default,
    },
    'src/shared/components/RadioGroup/index.ts': {
        content: require('!!raw-loader!@builder/components/src/RadioGroup/index.ts').default,
    },
    'src/shared/components/RadioGroup/RadioGroup.tsx': {
        content: require('!!raw-loader!@builder/components/src/RadioGroup/RadioGroup.tsx').default,
    },
    'src/shared/components/Tabs/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Tabs/index.ts').default,
    },
    'src/shared/components/Tabs/Tabs.tsx': {
        content: require('!!raw-loader!@builder/components/src/Tabs/Tabs.tsx').default,
    },
    'src/shared/components/Tab/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Tab/index.ts').default,
    },
    'src/shared/components/Tab/Tab.tsx': {
        content: require('!!raw-loader!@builder/components/src/Tab/Tab.tsx').default,
    },
    'src/shared/components/FormRadioGroup/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormRadioGroup/index.ts').default,
    },
    'src/shared/components/FormRadioGroup/FormRadioGroup.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormRadioGroup/FormRadioGroup.tsx')
            .default,
    },
    'src/shared/components/Select/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Select/index.ts').default,
    },
    'src/shared/components/Select/Select.tsx': {
        content: require('!!raw-loader!@builder/components/src/Select/Select.tsx').default,
    },
    'src/shared/components/Autocomplete/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Autocomplete/index.ts').default,
    },
    'src/shared/components/Autocomplete/Autocomplete.tsx': {
        content: require('!!raw-loader!@builder/components/src/Autocomplete/Autocomplete.tsx').default,
    },
    'src/shared/components/FormAutocomplete/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormAutocomplete/index.ts').default,
    },
    'src/shared/components/FormAutocomplete/FormAutocomplete.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormAutocomplete/FormAutocomplete.tsx')
            .default,
    },
    'src/shared/components/FilePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FilePicker/index.ts').default,
    },
    'src/shared/components/FilePicker/FilePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/FilePicker/FilePicker.tsx').default,
    },
    'src/shared/components/FileUploadBase/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadBase/index.ts').default,
    },
    'src/shared/components/FileUploadBase/FileUploadBase.tsx': {
        content: require('!!raw-loader!@builder/components/src/FileUploadBase/FileUploadBase.tsx')
            .default,
    },
    'src/shared/components/FileUploadBase/types.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadBase/types.ts').default,
    },
    'src/shared/components/FileUploadBase/utils.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadBase/utils.ts').default,
    },
    'src/shared/components/FileUploadButtonBase/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadButtonBase/index.ts').default,
    },
    'src/shared/components/FileUploadButtonBase/FileUploadButtonBase.tsx': {
        content: require('!!raw-loader!@builder/components/src/FileUploadButtonBase/FileUploadButtonBase.tsx')
            .default,
    },
    'src/shared/components/FileUploadButtonBase/types.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadButtonBase/types.ts').default,
    },
    'src/shared/components/FileUploadButtonBase/utils.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadButtonBase/utils.ts').default,
    },
    'src/shared/components/FileUploadButton/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUploadButton/index.ts').default,
    },
    'src/shared/components/FileUploadButton/FileUploadButton.tsx': {
        content: require('!!raw-loader!@builder/components/src/FileUploadButton/FileUploadButton.tsx')
            .default,
    },
    'src/shared/components/Menu/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Menu/index.ts').default,
    },
    'src/shared/components/Menu/Menu.tsx': {
        content: require('!!raw-loader!@builder/components/src/Menu/Menu.tsx').default,
    },
    'src/shared/components/Popover/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Popover/index.ts').default,
    },
    'src/shared/components/Popover/Popover.tsx': {
        content: require('!!raw-loader!@builder/components/src/Popover/Popover.tsx').default,
    },
    'src/shared/components/Popper/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Popper/index.ts').default,
    },
    'src/shared/components/Popper/Popper.tsx': {
        content: require('!!raw-loader!@builder/components/src/Popper/Popper.tsx').default,
    },
    'src/shared/components/Switch/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Switch/index.ts').default,
    },
    'src/shared/components/Switch/Switch.tsx': {
        content: require('!!raw-loader!@builder/components/src/Switch/Switch.tsx').default,
    },
    'src/shared/components/Slider/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Slider/index.ts').default,
    },
    'src/shared/components/Slider/Slider.tsx': {
        content: require('!!raw-loader!@builder/components/src/Slider/Slider.tsx').default,
    },
    'src/shared/components/FormSwitch/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormSwitch/index.ts').default,
    },
    'src/shared/components/FormSwitch/FormSwitch.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormSwitch/FormSwitch.tsx').default,
    },
    'src/shared/components/FormSlider/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormSlider/index.ts').default,
    },
    'src/shared/components/FormSlider/FormSlider.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormSlider/FormSlider.tsx').default,
    },
    'src/shared/components/Multiselect/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Multiselect/index.ts').default,
    },
    'src/shared/components/Multiselect/Multiselect.tsx': {
        content: require('!!raw-loader!@builder/components/src/Multiselect/Multiselect.tsx').default,
    },
    'src/shared/components/FormMultiselect/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormMultiselect/index.ts').default,
    },
    'src/shared/components/FormMultiselect/FormMultiselect.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormMultiselect/FormMultiselect.tsx')
            .default,
    },
    'src/shared/components/Icon/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Icon/index.ts').default,
    },
    'src/shared/components/Icon/Icon.tsx': {
        content: require('!!raw-loader!@builder/components/src/Icon/Icon.tsx').default,
    },
    'src/shared/components/IconButton/index.ts': {
        content: require('!!raw-loader!@builder/components/src/IconButton/index.ts').default,
    },
    'src/shared/components/IconButton/IconButton.tsx': {
        content: require('!!raw-loader!@builder/components/src/IconButton/IconButton.tsx').default,
    },
    'src/shared/components/Rating/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Rating/index.ts').default,
    },
    'src/shared/components/Rating/Rating.tsx': {
        content: require('!!raw-loader!@builder/components/src/Rating/Rating.tsx').default,
    },
    'src/shared/components/FormRating/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormRating/index.ts').default,
    },
    'src/shared/components/FormRating/FormRating.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormRating/FormRating.tsx').default,
    },
    'src/shared/components/FormRichtextEditor/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormRichtextEditor/index.ts').default,
    },
    'src/shared/components/FormRichtextEditor/FormRichtextEditor.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormRichtextEditor/FormRichtextEditor.tsx')
            .default,
    },
    'src/shared/components/FormSelect/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormSelect/index.ts').default,
    },
    'src/shared/components/FormSelect/FormSelect.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormSelect/FormSelect.tsx').default,
    },
    'src/shared/components/RichText/index.ts': {
        content: require('!!raw-loader!@builder/components/src/RichText/index.ts').default,
    },
    'src/shared/components/RichText/RichText.tsx': {
        content: require('!!raw-loader!@builder/components/src/RichText/RichText.tsx').default,
    },
    'src/shared/components/RichtextEditor/index.ts': {
        content: require('!!raw-loader!@builder/components/src/RichtextEditor/index.ts').default,
    },
    'src/shared/components/RichtextEditor/RichtextEditor.tsx': {
        content: require('!!raw-loader!@builder/components/src/RichtextEditor/RichtextEditor.tsx')
            .default,
    },
    'src/shared/components/RouteLayout/index.ts': {
        content: require('!!raw-loader!@builder/components/src/RouteLayout/index.ts').default,
    },
    'src/shared/components/RouteLayout/RouteLayout.tsx': {
        content: require('!!raw-loader!@builder/components/src/RouteLayout/RouteLayout.tsx').default,
    },
    'src/shared/components/Route/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Route/index.ts').default,
    },
    'src/shared/components/Route/Route.tsx': {
        content: require('!!raw-loader!@builder/components/src/Route/Route.tsx').default,
    },
    'src/shared/components/RouteHook/index.ts': {
        content: require('!!raw-loader!./project-template/src/shared/components/RouteHook/index.ts.template')
            .default,
    },
    'src/shared/components/RouteHook/RouteHook.tsx': {
        content: require('!!raw-loader!./project-template/src/shared/components/RouteHook/RouteHook.ts.template')
            .default,
    },
    'src/shared/components/RouterHookProvider/index.ts': {
        content: require('!!raw-loader!@builder/components/src/RouterHookProvider/index.ts').default,
    },
    'src/shared/components/RouterHookProvider/RouterHookProvider.tsx': {
        content: require('!!raw-loader!@builder/components/src/RouterHookProvider/RouterHookProvider.ts')
            .default,
    },
    'src/shared/components/TextField/index.ts': {
        content: require('!!raw-loader!@builder/components/src/TextField/index.ts').default,
    },
    'src/shared/components/TextField/TextField.tsx': {
        content: require('!!raw-loader!@builder/components/src/TextField/TextField.tsx').default,
    },
    'src/shared/components/Map/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Map/index.ts').default,
    },
    'src/shared/components/Map/Map.tsx': {
        content: require('!!raw-loader!@builder/components/src/Map/Map.tsx').default,
    },
    'src/shared/components/RouterLink/index.ts': {
        content: require('!!raw-loader!@builder/components/src/RouterLink/index.ts').default,
    },
    'src/shared/components/RouterLink/RouterLink.tsx': {
        content: require('!!raw-loader!@builder/components/src/RouterLink/RouterLink.tsx').default,
    },
    'src/shared/components/AsyncContent/index.ts': {
        content: require('!!raw-loader!@builder/components/src/AsyncContent/index.ts').default,
    },
    'src/shared/components/AsyncContent/AsyncContent.tsx': {
        content: require('!!raw-loader!@builder/components/src/AsyncContent/AsyncContent.tsx').default,
    },
    'src/shared/components/DatePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/DatePicker/index.ts').default,
    },
    'src/shared/components/DatePicker/DatePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/DatePicker/DatePicker.tsx').default,
    },
    'src/shared/components/DateTimePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/DateTimePicker/index.ts').default,
    },
    'src/shared/components/DateTimePicker/DateTimePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/DateTimePicker/DateTimePicker.tsx')
            .default,
    },
    'src/shared/components/FormDatePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormDatePicker/index.ts').default,
    },
    'src/shared/components/FormDatePicker/FormDatePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormDatePicker/FormDatePicker.tsx')
            .default,
    },
    'src/shared/components/FormDateTimePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormDateTimePicker/index.ts').default,
    },
    'src/shared/components/FormDateTimePicker/FormDateTimePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormDateTimePicker/FormDateTimePicker.tsx')
            .default,
    },
    'src/shared/components/TimePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/TimePicker/index.ts').default,
    },
    'src/shared/components/TimePicker/TimePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/TimePicker/TimePicker.tsx').default,
    },
    'src/shared/components/FormTimePicker/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FormTimePicker/index.ts').default,
    },
    'src/shared/components/FormTimePicker/FormTimePicker.tsx': {
        content: require('!!raw-loader!@builder/components/src/FormTimePicker/FormTimePicker.tsx')
            .default,
    },
    'src/shared/components/Tooltip/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Tooltip/index.ts').default,
    },
    'src/shared/components/Tooltip/Tooltip.tsx': {
        content: require('!!raw-loader!@builder/components/src/Tooltip/Tooltip.tsx').default,
    },
    'src/shared/components/Image/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Image/index.ts').default,
    },
    'src/shared/components/Image/Image.tsx': {
        content: require('!!raw-loader!@builder/components/src/Image/Image.tsx').default,
    },
    'src/shared/components/types.ts': {
        content: require('!!raw-loader!@builder/components/src/types.ts').default,
    },
    'src/shared/hooks/predefined/useResourceClient.ts': {
        content: require('!!raw-loader!./project-template/src/shared/hooks/predefined/useResourceClient.ts.template')
            .default,
    },
    'src/shared/hooks/predefined/useRestRequest.ts': {
        content: require('!!raw-loader!./project-template/src/shared/hooks/predefined/useRestRequest.ts.template')
            .default,
    },
    'src/shared/hooks/predefined/useLazyQueryState.ts': {
        content: require('!!raw-loader!./project-template/src/shared/hooks/predefined/useLazyQueryState.ts.template')
            .default,
    },
    'src/shared/utils/predefined/generateScopedCSS.ts': {
        content: require('!!raw-loader!./project-template/src/shared/utils/predefined/generateScopedCSS.ts.template')
            .default,
    },
    'src/shared/utils/predefined/url.ts': {
        content: require('!!raw-loader!./project-template/src/shared/utils/predefined/url.ts.template')
            .default,
    },
    'src/index.tsx': {
        content: require('!!raw-loader!./project-template/src/index.tsx.template').default,
    },
    '.eslintrc.js': {
        content: require('!!raw-loader!./project-template/.eslintrc.js.template').default,
    },
    '.prettierrc': {
        content: require('!!raw-loader!./project-template/.prettierrc.template').default,
    },
    'package-lock.json': {
        content: require('!!raw-loader!./project-template/package-lock.json.template').default,
    },
    'react-shim.js': {
        content: require('!!raw-loader!./project-template/react-shim.js.template').default,
    },
    'tsconfig.json': {
        content: require('!!raw-loader!./project-template/tsconfig.json.template').default,
    },
    'src/shared/hooks/predefined/useResources.ts': {
        content: require('!!raw-loader!./project-template/src/shared/hooks/predefined/useResources.template')
            .default,
    },
    'src/shared/hooks/predefined/useLibraries.ts': {
        content: require('!!raw-loader!./project-template/src/shared/hooks/predefined/useLibraries.template')
            .default,
    },
    'src/shared/components/FileUpload/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FileUpload/index.ts').default,
    },
    'src/shared/components/FileUpload/FileUpload.tsx': {
        content: require('!!raw-loader!@builder/components/src/FileUpload/FileUpload.tsx').default,
    },
    'src/shared/components/FileDropZone/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FileDropZone/index.ts').default,
    },
    'src/shared/components/FileDropZone/FileDropZone.tsx': {
        content: require('!!raw-loader!@builder/components/src/FileDropZone/FileDropZone.tsx').default,
    },
    'src/shared/components/FileDropZoneBase/index.ts': {
        content: require('!!raw-loader!@builder/components/src/FileDropZoneBase/index.ts').default,
    },
    'src/shared/components/FileDropZoneBase/FileDropZoneBase.tsx': {
        content: require('!!raw-loader!@builder/components/src/FileDropZoneBase/FileDropZoneBase.tsx')
            .default,
    },
    'src/shared/components/FileDropZoneBase/types.ts': {
        content: require('!!raw-loader!@builder/components/src/FileDropZoneBase/types.ts').default,
    },
    'src/shared/components/FileDropZoneBase/utils.ts': {
        content: require('!!raw-loader!@builder/components/src/FileDropZoneBase/utils.ts').default,
    },
    'src/shared/components/MenuItem/index.ts': {
        content: require('!!raw-loader!@builder/components/src/MenuItem/index.ts').default,
    },
    'src/shared/components/MenuItem/MenuItem.tsx': {
        content: require('!!raw-loader!@builder/components/src/MenuItem/MenuItem.tsx').default,
    },
    'src/shared/components/SpeedDial/index.ts': {
        content: require('!!raw-loader!@builder/components/src/SpeedDial/index.ts').default,
    },
    'src/shared/components/SpeedDial/SpeedDial.tsx': {
        content: require('!!raw-loader!@builder/components/src/SpeedDial/SpeedDial.tsx').default,
    },
    'src/shared/components/Snackbar/index.ts': {
        content: require('!!raw-loader!@builder/components/src/Snackbar/index.ts').default,
    },
    'src/shared/components/Snackbar/Snackbar.tsx': {
        content: require('!!raw-loader!@builder/components/src/Snackbar/Snackbar.tsx').default,
    },
    'src/shared/components/SnackbarContent/index.ts': {
        content: require('!!raw-loader!@builder/components/src/SnackbarContent/index.ts').default,
    },
    'src/shared/components/SnackbarContent/SnackbarContent.tsx': {
        content: require('!!raw-loader!@builder/components/src/SnackbarContent/SnackbarContent.tsx')
            .default,
    },
    'src/shared/components/HTMLFragment/index.ts': {
        content: require('!!raw-loader!@builder/components/src/HTMLFragment/index.ts').default,
    },
    'src/shared/components/HTMLFragment/HTMLFragment.tsx': {
        content: require('!!raw-loader!@builder/components/src/HTMLFragment/HTMLFragment.tsx').default,
    },
};
