import styled from '@emotion/styled';

import LoaderBackground from 'src/assets/images/circuit-board.svg';

export const StyledSpinner8BaseBackground = styled.div`
  align-items: center;
  background-image: url(${LoaderBackground}), linear-gradient(180deg, #f9fbfd, #d8deec);
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
`;

export const Title = styled.div`
  align: Center;
  animation-duration: 5s;
  animation-name: slidein;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  opacity: 1;

  @keyframes slidein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes goUp {
    from {
      margin-top: 105px;
    }

    to {
      margin-top: -105px;
    }
  }
`;

export const Description = styled.div`
  align: Center;
  animation-duration: 5s;
  animation-name: slidein;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  margin-top: 10px;
  opacity: 1;
`;

export const StyledSpinner8Base = styled.img<{ hasTitle?: boolean }>`
  width: 20vh;
`;

export const StyledSpinner8BaseAnimated = styled.img<{ hasTitle?: boolean }>`
  width: 20vh;
  margin-top: -105px;
  animation-duration: 1.5s;
  animation-name: goUp;
  margin-bottom: 50px;
`;
