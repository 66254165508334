import { ROOT_NODE_ID } from '@builder/schemas';

export const DRAGGABLES = {
  ICON: '@dash-dnd/icon',
  OVERLAY: '@dash-dnd/overlay',
} as const;

// areas you can drop draggables into
export const DROPPABLE_AREAS = {
  OVERLAY: DRAGGABLES.OVERLAY,
} as const;

// droppables -to- draggables relation map
export const DROPPABLES_2_DRAGGABLES = {
  [DROPPABLE_AREAS.OVERLAY]: [DRAGGABLES.ICON, DRAGGABLES.OVERLAY],
};

/**
 * This is used to identify icons as draggables.
 * Eg, when starting to drag, to write some info w/ @DASHBOARD_ANIM_EVENTS .moveStart
 */
export const ICON_ID_TAG = '@dashIcon' as const;

/**
 * Special indicator for the root node to warrant strict-into move-case.
 */
export const ROOT_NODE_ID_SUPER = `${ROOT_NODE_ID}-super` as const;

/**
 * Size of the corner where we would switch to before/after move-types
 */
export const CORNER_SIZE_FOR_TYPE_SWITCH_PREVIEW = 8; // this is a non-commitment value (only showing, not actually switching)
export const CORNER_SIZE_FOR_TYPE_SWITCH = 4; // when the switch actually happens
export const CORNER_SIZE_FOR_TYPE_SWITCH_NAV = 8; // when the switch actually happens (for navigator items)
