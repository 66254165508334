import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import {
  AppConfiguration,
  COMPONENT_DSL_NAMES,
  NodeDSL,
  isDialogComponent,
} from '@builder/schemas';

import { DASHBOARD_EVENTS } from 'src/store/common';

import { ActionScopeStrategy, SCOPE_GLOBAL } from './ActionScopeStrategy';

function getNodeFromStoreWithId(appConfig: AppConfiguration, id: string): NodeDSL {
  return appConfig.appDSL.nodes[id];
}

export class ComponentActionsStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
    currentConfiguration: AppConfiguration,
    previousConfiguration: AppConfiguration,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null {
    const newEventsToPush: EventPayload[] = [];
    let scope = SCOPE_GLOBAL;
    if (action.type === DASHBOARD_EVENTS.componentCreate) {
      const node = action.place?.targetComponent?.nodeID
        ? getNodeFromStoreWithId(currentConfiguration, action.place.targetComponent.nodeID)
        : null;

      const partentIsDialogComponent =
        node && node.parentID
          ? !!isDialogComponent(node.id, currentConfiguration.appDSL.nodes)
          : false;

      if (action.value.name === COMPONENT_DSL_NAMES.DialogSymbol || partentIsDialogComponent) {
        return { eventPayload: { ...eventPayload, scope: SCOPE_GLOBAL }, newEventsToPush };
      }
    }

    if (action.type === DASHBOARD_EVENTS.componentRemove) {
      const nodeToRemove = getNodeFromStoreWithId(previousConfiguration, action.id);
      const nodeToRemovePartentIsDialogComponent =
        nodeToRemove && nodeToRemove.parentID
          ? !!isDialogComponent(nodeToRemove.id, previousConfiguration.appDSL.nodes)
          : false;

      scope =
        nodeToRemove.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
        nodeToRemove.name === COMPONENT_DSL_NAMES.DialogSymbol ||
        nodeToRemovePartentIsDialogComponent
          ? SCOPE_GLOBAL
          : action.value?.currentRouteParentNodeDSLId ?? currentRouteNode.id;
      return { eventPayload: { ...eventPayload, scope }, newEventsToPush };
    }

    if (action.type === DASHBOARD_EVENTS.componentSaveAsGlobalCSS) {
      if (eventPayload.data.key === 'appDSL.theme.css.custom') {
        return { eventPayload: { ...eventPayload, scope: SCOPE_GLOBAL }, newEventsToPush };
      }
    }

    scope = action.value?.currentRouteParentNodeDSLId ?? currentRouteNode.id;

    if (action.isFromRouteupdate?.id) {
      return {
        eventPayload: { ...eventPayload, scope: action.isFromRouteupdate?.id },
        newEventsToPush,
      };
    }

    return { eventPayload: { ...eventPayload, scope }, newEventsToPush };
  }
}
