import { ERROR_SCOPES, isBoolean, SchemaValidationError, serialize } from '@builder/utils';
import { RuntimePropExecutor } from '../RuntimePropExecutor';
/**
 * Transforms DSL condition to js boolean.
 * @example
 * {{ false }} => false
 * {{ someBooleanVariable }} => false
 */
export const transformCondition = (showIfConditionValue, externalState, errorGetter) => {
    if (isBoolean(showIfConditionValue)) {
        return showIfConditionValue;
    }
    if (!showIfConditionValue) {
        return true;
    }
    const runtimeExecutor = new RuntimePropExecutor({ errorGetter });
    if (/{{\s*}}/g.test(showIfConditionValue)) {
        errorGetter.passErrorNotification({
            propPath: ['condition'],
            message: `Condition value should be JS code, but got: ${serialize.stringify(showIfConditionValue)}`,
        });
    }
    if (!runtimeExecutor.hasPropJsCode(showIfConditionValue)) {
        throw new SchemaValidationError(ERROR_SCOPES.appEngine, `Condition value should be JS code, but got: ${serialize.stringify(showIfConditionValue)}`);
    }
    const transformedCondition = !!runtimeExecutor.transformPropJsCode({
        propValue: showIfConditionValue,
        propPath: ['condition'],
        externalState,
    });
    return transformedCondition;
};
