export const ASSET_PROPERTIES_POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 20],
    },
  },
];

export const NAME_FIELD_PROPS = {
  fieldProps: {
    name: `name`,
  },
  inputProps: { label: 'Name', variant: 'outlined', fullWidth: true },
} as const;

export const ALT_FIELD_PROPS = {
  fieldProps: { name: `alt` },
  inputProps: { label: 'Alt Text', variant: 'outlined', fullWidth: true },
} as const;

export const DESCRIPTION_FIELD_PROPS = {
  fieldProps: { name: `description` },
  inputProps: { label: 'Description', variant: 'outlined', fullWidth: true },
} as const;

export const ASSET_PROPERTIES_POPPER_ID = 'ASSET_PROPERTIES_POPPER_ID';
