import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ERROR_SCOPES, isNull, SystemError } from '@builder/utils';

import { GlobalTooltip } from './components';

type CursorTooltipContextType = {
  isVisible: boolean;
  showCursorTooltip: (message: React.ReactNode) => void;
  hideCursorTooltip: () => void;
};

type UseCursorTooltipHookResult = CursorTooltipContextType;

const CursorTooltipContext = createContext<CursorTooltipContextType | null>(null);
const EMPTY_MESSAGE = null;
const ERROR_MESSAGE = 'Cursor Tooltip must be initialized';

export const CursorTooltipProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<React.ReactNode>(EMPTY_MESSAGE);
  const hasMessage = useMemo(() => Boolean(message), [message]);

  const showCursorTooltip = useCallback((newMessage: React.ReactNode) => {
    setMessage(newMessage);
  }, []);

  const hideCursorTooltip = useCallback(() => {
    setMessage(EMPTY_MESSAGE);
  }, []);

  return (
    <CursorTooltipContext.Provider
      value={{
        isVisible: hasMessage,
        showCursorTooltip,
        hideCursorTooltip,
      }}
    >
      {children}
      <GlobalTooltip title={message} isVisible={hasMessage} />
    </CursorTooltipContext.Provider>
  );
};

export const useCursorTooltip = (): UseCursorTooltipHookResult => {
  const cursorTooltip = useContext(CursorTooltipContext);

  if (isNull(cursorTooltip)) {
    throw new SystemError(ERROR_SCOPES.uiBuilder, ERROR_MESSAGE);
  }

  return cursorTooltip;
};
