import React from 'react';

import styled from '@emotion/styled';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import { useBoolState } from '@builder/utils';

import { DescriptionTooltipWrapper } from '../DescriptionTooltipWrapper';

type AccordionSummaryContainerProps = AccordionSummaryProps & {
  disableCollapse?: boolean;
  isStartIcon: boolean;
};

type AccordionSectionProps = {
  children: React.ReactNode;
  title: string;
  titleDescription?: {
    title: string;
    subtitle: string;
  };
  titleProps?: TypographyProps;
  disableCollapse?: boolean;
  expandedByDefault?: boolean;
  className?: string;
  startIcon?: React.ReactNode;
  'data-test'?: string;
};

const AccordionSummaryContainer = styled(
  ({ disableCollapse, isStartIcon, ...rest }: AccordionSummaryContainerProps) => (
    <AccordionSummary {...rest} />
  ),
)`
  .MuiAccordionSummary-content {
    gap: 8px;
    align-items: center;
    display: grid;
    grid-template-columns: ${({ isStartIcon }) => (isStartIcon ? '16px auto' : 'auto')};
  }

  ${props =>
    props.disableCollapse &&
    `
    pointer-events: none;
  `}
`;

export const AccordionSection = ({
  children,
  'data-test': dataTest,
  title,
  titleDescription,
  titleProps,
  startIcon,
  disableCollapse = false,
  expandedByDefault = true,
  className,
}: AccordionSectionProps): JSX.Element => {
  const [isSectionOpened, { toggle: toggleSection }] = useBoolState(expandedByDefault);
  const isSectionExpanded = isSectionOpened || disableCollapse;
  const expandIcon = disableCollapse ? undefined : <ExpandMoreIcon />;

  return (
    <div className={className}>
      <Accordion expanded={isSectionExpanded} onChange={toggleSection}>
        <AccordionSummaryContainer
          data-test={dataTest}
          expandIcon={expandIcon}
          disableCollapse={disableCollapse}
          isStartIcon={Boolean(startIcon)}
        >
          {startIcon}
          <DescriptionTooltipWrapper
            title={titleDescription?.title || ''}
            subtitle={titleDescription?.subtitle || ''}
            wrapCondition={Boolean(titleDescription)}
          >
            <Typography variant="subtitle2" {...titleProps}>
              {title}
            </Typography>
          </DescriptionTooltipWrapper>
        </AccordionSummaryContainer>
        <AccordionDetails>
          <If condition={isSectionExpanded}>{children}</If>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
