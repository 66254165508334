import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { getCommonSymbolStyleProps, getCommonSymbolEventProps, commonComponentProps, commonActionProps, commonReactProps, } from '../../common/commonProps';
import { GridItemSymbolSettings } from './GridItemSymbol.settings';
export const GridItemSymbol = {
    name: COMPONENT_DSL_NAMES.GridItemSymbol,
    displayName: 'Grid Item',
    type: COMPONENT_DSL_TYPES.symbol,
    deprecated: {
        version: '0.4.0',
    },
    overlayOutlineWhenEmpty: true,
    memoization: {
        disable: true,
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.all,
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.GridContainerSymbol,
                },
            },
        ],
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                xs: 'auto',
                style: getPredefinedStyles(),
            },
        },
        props: Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), { alignContent: { type: COMPONENT_DSL_PROP_TYPES.enum }, alignItems: { type: COMPONENT_DSL_PROP_TYPES.enum }, direction: { type: COMPONENT_DSL_PROP_TYPES.enum }, justify: { type: COMPONENT_DSL_PROP_TYPES.enum }, wrap: { type: COMPONENT_DSL_PROP_TYPES.enum }, lg: { type: COMPONENT_DSL_PROP_TYPES.enum }, md: { type: COMPONENT_DSL_PROP_TYPES.enum }, sm: { type: COMPONENT_DSL_PROP_TYPES.enum }, xl: { type: COMPONENT_DSL_PROP_TYPES.enum }, xs: { type: COMPONENT_DSL_PROP_TYPES.enum }, zeroMinWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialGrid,
            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { item: true, lg: '{{ symbolProps.lg }}', md: '{{ symbolProps.md }}', sm: '{{ symbolProps.sm }}', xl: '{{ symbolProps.xl }}', xs: '{{ symbolProps.xs }}', zeroMinWidth: '{{ symbolProps.zeroMinWidth }}', wrap: '{{ symbolProps.wrap }}', draggable: '{{ symbolProps.draggable }}', children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.div,
                            props: {
                                children: '{{ symbolProps.children }}',
                                style: {
                                    display: 'flex',
                                    height: '100%',
                                    width: '100%',
                                    alignContent: '{{ symbolProps.alignContent }}',
                                    alignItems: '{{ symbolProps.alignItems }}',
                                    justifyContent: '{{ symbolProps.justify }}',
                                },
                            },
                        },
                    ],
                }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        },
    },
    settings: GridItemSymbolSettings,
};
