import { FC, HTMLProps } from 'react';

import Loader from 'src/assets/images/8base-loader.gif';

import {
  Description,
  StyledSpinner8Base,
  StyledSpinner8BaseAnimated,
  StyledSpinner8BaseBackground,
  Title,
} from './spinerStyles';

interface SimpleSpinnerProps extends HTMLProps<HTMLImageElement> {
  hasTitle?: boolean;
  animate?: boolean;
}
export const SimpleSpinner: FC<SimpleSpinnerProps> = ({ hasTitle, animate }, props) => {
  const Spinner = hasTitle && animate ? StyledSpinner8BaseAnimated : StyledSpinner8Base;
  return <Spinner src={Loader} hasTitle={hasTitle} {...props} />;
};

export const Spinner = ({
  title,
  description,
  animate = false,
}: {
  title?: string;
  description?: string;
  animate?: boolean;
}): JSX.Element => {
  return (
    <StyledSpinner8BaseBackground>
      <SimpleSpinner hasTitle={!!title} animate={animate} />
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </StyledSpinner8BaseBackground>
  );
};
