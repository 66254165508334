import memoize from 'fast-memoize';
import { filter } from 'ramda';
import { ERROR_SCOPES, generateID, isObject, isString, isUndefined, SystemError, } from '@builder/utils';
import { COMPONENT_DSL_NAMES } from '../../constants';
import { componentListSelectors, nodeListSelectors, stateListSelectors } from '../../selectors';
import { PropAssert, PropChecker } from '../../validation';
import { hasPredefinedStates, hasPropJsCode, hasPredefinedFormName, transformStringWithPredefinedName, transformStringWithPredefinedState, } from '../prop-transformers';
import { addAliasToAllNodes, addParentIDToAllNodes } from './addMissedDataToDSL';
import { isDialogComponent } from './isDialogComponent';
export const createStatesFromTemplates = (existingStates, nodeStatesTemplate, currentRouteParentNodeDSLId, nodesTree) => {
    return nodeStatesTemplate.map(stateTemplate => {
        var _a, _b;
        const id = generateID();
        if ((nodesTree === null || nodesTree === void 0 ? void 0 : nodesTree.name) === COMPONENT_DSL_NAMES.DialogSymbol && (nodesTree === null || nodesTree === void 0 ? void 0 : nodesTree.alias)) {
            return Object.assign(Object.assign({}, stateTemplate), { name: (_a = nodesTree.alias) === null || _a === void 0 ? void 0 : _a.replaceAll(' ', '_'), predefinedName: (_b = nodesTree.alias) === null || _b === void 0 ? void 0 : _b.replaceAll(' ', '_'), id, parent: currentRouteParentNodeDSLId });
        }
        const stateName = stateListSelectors.calculateTemplateStateName(existingStates, {
            name: stateTemplate.name,
        });
        return Object.assign(Object.assign({}, stateTemplate), { name: stateName, predefinedName: stateTemplate.name, id, parent: currentRouteParentNodeDSLId });
    });
};
export const transformStatesListToObject = (states) => {
    return states.reduce((acc, curr) => {
        return Object.assign(Object.assign({}, acc), { [curr.id]: curr });
    }, {});
};
/**
 * Convert children nodes to node ids
 * @example
 * { nodeProps: { children: { nodes: [{ name: 'text' }] } } } =>
 * { nodeProps: { children: { nodes: ['new-id'] } }, childrenNodes: [{ id:'new-id', name: 'text' }]  }
 */
const flattenProps = ({ propsSchema, nodeProps, componentListDSL, appStates, nodesTree, }) => {
    if (!nodeProps) {
        return { transformedNodeProps: {}, childrenNodes: [] };
    }
    let childrenNodes = [];
    const transformedNodeProps = Object.keys(propsSchema).reduce((accum, propName) => {
        var _a;
        const propSchema = propsSchema[propName];
        const propValue = nodeProps[propName];
        const dialogStateName = (name) => name.replaceAll(' ', '_');
        if (isUndefined(propValue)) {
            return accum;
        }
        if (isString(propValue) && hasPredefinedFormName(propValue)) {
            const transformedValue = transformStringWithPredefinedName(appStates, propValue);
            return Object.assign(Object.assign({}, accum), { [propName]: transformedValue });
        }
        if (isString(propValue) && hasPredefinedStates(propValue)) {
            const transformedValue = transformStringWithPredefinedState(appStates, (nodesTree === null || nodesTree === void 0 ? void 0 : nodesTree.name) === 'DialogSymbol' && (nodesTree === null || nodesTree === void 0 ? void 0 : nodesTree.alias)
                ? propValue.replace('modal', dialogStateName(nodesTree.alias))
                : propValue);
            return Object.assign(Object.assign({}, accum), { [propName]: transformedValue });
        }
        if (PropChecker.Schema.isActionProp(propSchema) &&
            PropChecker.Value.isActionPropWithArgs(propValue)) {
            const transformedActionArgs = Object.entries(propValue.args).reduce((acc, [argName, argValue]) => {
                if (isString(argValue) && hasPredefinedStates(argValue)) {
                    const transformedValue = transformStringWithPredefinedState(appStates, (nodesTree === null || nodesTree === void 0 ? void 0 : nodesTree.name) === 'DialogSymbol' && (nodesTree === null || nodesTree === void 0 ? void 0 : nodesTree.alias)
                        ? argValue === null || argValue === void 0 ? void 0 : argValue.replace('modal', dialogStateName(nodesTree.alias))
                        : argValue);
                    return Object.assign(Object.assign({}, acc), { [argName]: transformedValue });
                }
                return Object.assign(Object.assign({}, acc), { [argName]: argValue });
            }, {});
            return Object.assign(Object.assign({}, accum), { [propName]: Object.assign(Object.assign({}, propValue), { args: transformedActionArgs }) });
        }
        // if prop is object and its props was defined
        // then transform its props based on schema
        if (PropChecker.Schema.isObjectPropWithDefinedProps(propSchema)) {
            const { transformedNodeProps: childrenNodeProps, childrenNodes: grandChildrenNodes, } = flattenProps({
                propsSchema: propSchema.props,
                nodeProps: nodeProps[propName],
                componentListDSL,
                appStates,
                nodesTree,
            });
            childrenNodes = [...childrenNodes, ...grandChildrenNodes];
            return Object.assign(Object.assign({}, accum), { [propName]: childrenNodeProps });
        }
        // if prop is object and it's props wasn't defined
        // then transform its props based on values and not on schema
        if (PropChecker.Schema.isObjectPropWithNoDefinedProps(propSchema) &&
            // We check if the propValue is object because of the case
            // when propValue could be equal such string `{{ symbolProps.dialogProps.classes }}`
            PropChecker.Value.isObjectProp(propValue)) {
            const transformedPropValue = Object.entries(propValue).reduce((transformedObject, [key, value]) => {
                if (isString(value) && hasPredefinedStates(value)) {
                    const transformedValue = transformStringWithPredefinedState(appStates, value);
                    return Object.assign(Object.assign({}, transformedObject), { [key]: transformedValue });
                }
                return Object.assign(Object.assign({}, transformedObject), { [key]: value });
            }, {});
            return Object.assign(Object.assign({}, accum), { [propName]: transformedPropValue });
        }
        // if prop is array of objects then transform its props
        if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(propSchema)) {
            const arrayPropSchema = propSchema.item.props;
            const arrayPropValue = nodeProps[propName];
            PropAssert.Value.assertIsArrayProp(arrayPropValue, propName);
            const childrenNodeArrayProps = arrayPropValue.map(arrayPropItemValue => {
                const { transformedNodeProps: childrenNodeProps, childrenNodes: grandChildrenNodes, } = flattenProps({
                    propsSchema: arrayPropSchema,
                    nodeProps: arrayPropItemValue,
                    componentListDSL,
                    appStates,
                });
                childrenNodes = [...childrenNodes, ...grandChildrenNodes];
                return childrenNodeProps;
            });
            return Object.assign(Object.assign({}, accum), { [propName]: childrenNodeArrayProps });
        }
        // if prop renderable then extract children nodes
        if (PropChecker.Schema.isRenderableProp(propSchema)) {
            const renderableNodeTemplate = nodeProps[propName];
            if (hasPropJsCode(renderableNodeTemplate)) {
                return Object.assign(Object.assign({}, accum), { [propName]: renderableNodeTemplate });
            }
            const transformedChildrenNodes = (_a = renderableNodeTemplate.nodes) === null || _a === void 0 ? void 0 : _a.map(nodeTemplate => {
                const { nodeDSL: childrenNodeDSL, childrenNodes: grandChildrenNodes, } = transformNodeTreeToListWithoutParentIDs({
                    nodesTree: nodeTemplate,
                    componentListDSL,
                    appStates,
                });
                childrenNodes = [...childrenNodes, childrenNodeDSL, ...grandChildrenNodes];
                return childrenNodeDSL;
            });
            return Object.assign(Object.assign({}, accum), { [propName]: Object.assign(Object.assign({}, renderableNodeTemplate), { nodes: transformedChildrenNodes.map(({ id }) => id) }) });
        }
        // if prop is primitive
        return Object.assign(Object.assign({}, accum), { [propName]: propValue });
    }, {});
    const finalProps = isObject(nodeProps)
        ? Object.assign(Object.assign({}, nodeProps), transformedNodeProps) : transformedNodeProps;
    return { transformedNodeProps: finalProps, childrenNodes };
};
/**
 * @returns Transformed node and all his children nodes
 */
const transformNodeTreeToListWithoutParentIDs = ({ nodesTree, componentListDSL, appStates, }) => {
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodesTree.name,
    });
    const { transformedNodeProps, childrenNodes } = flattenProps({
        nodeProps: nodesTree.props,
        nodesTree,
        propsSchema: componentDSL.schema.props,
        componentListDSL,
        appStates,
    });
    const nodeDSL = Object.assign(Object.assign({}, nodesTree), { id: generateID(), props: transformedNodeProps });
    return { nodeDSL, childrenNodes };
};
/**
 * Transforms tree of nodes to the list of the nodes.
 */
export const transformNodeTreeToList = ({ nodesTree, componentListDSL, appStates = {}, withAliases = false, }) => {
    const { nodeDSL: rootNodeDSL, childrenNodes } = transformNodeTreeToListWithoutParentIDs({
        nodesTree,
        componentListDSL,
        appStates,
    });
    const appNodes = [rootNodeDSL, ...childrenNodes].reduce((accum, nodeDSLWithoutID) => {
        return Object.assign(Object.assign({}, accum), { [nodeDSLWithoutID.id]: nodeDSLWithoutID });
    }, {});
    const nodesWithParentIDs = addParentIDToAllNodes(appNodes, componentListDSL);
    return withAliases
        ? addAliasToAllNodes({
            nodeListDSL: nodesWithParentIDs,
            updateAliasForNodes: nodesWithParentIDs,
            componentListDSL,
        })
        : nodesWithParentIDs;
};
/**
 * Transforms tree of nodes to the list of the nodes with states.
 */
export const transformNodeWithStateTreeToList = ({ existingStates, parentID = null, nodesTree, nodeStatesTemplate = [], componentListDSL, currentRouteParentNodeDSLId, withAliases = false, nodeListDSL, }) => {
    const statesFromTemplates = createStatesFromTemplates(existingStates, nodeStatesTemplate, currentRouteParentNodeDSLId, nodesTree);
    const appStates = transformStatesListToObject(statesFromTemplates);
    const connectedStates = statesFromTemplates.map((stateFromTemplate) => ({
        stateID: stateFromTemplate.id,
        required: true,
    }));
    const appNodes = transformNodeTreeToList({
        nodesTree,
        componentListDSL,
        appStates,
        withAliases,
    });
    const rootNodeDSL = nodeListSelectors.getRootNodeDSL(appNodes);
    const checkStates = 'states' in rootNodeDSL;
    const transformDialogStates = Object.values(appStates).map(value => {
        return {
            [value.id]: Object.assign(Object.assign(Object.assign({}, value), { scope: 'global', componentBoundID: rootNodeDSL.id, dialogBoundID: parentID && nodeListDSL && isDialogComponent(parentID, nodeListDSL) }), (parentID &&
                nodeListDSL &&
                isDialogComponent(parentID, nodeListDSL) && { parent: undefined })),
        };
    });
    let appGlobalStates = {};
    transformDialogStates.forEach(state => {
        appGlobalStates = Object.assign({}, state);
    });
    if (!checkStates &&
        (rootNodeDSL.name === COMPONENT_DSL_NAMES.BasePageLayout ||
            rootNodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute)) {
        rootNodeDSL.states = [];
    }
    if (!rootNodeDSL) {
        throw new SystemError(ERROR_SCOPES.schemas, "Root node wasn't found.");
    }
    const childrenNodes = filter(nodeDSL => nodeDSL.id !== rootNodeDSL.id, appNodes);
    return {
        rootNodeDSL: Object.assign(Object.assign({}, rootNodeDSL), { parentID }),
        appStates,
        childrenNodes,
        connectedStates,
        appGlobalStates,
    };
};
export const transformNodeTreeToListMemoized = memoize(transformNodeTreeToList);
