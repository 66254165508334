import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import colorsDSL from './style-guide-dsl/SpeedDialGuide/colors.json';
import directionDSL from './style-guide-dsl/SpeedDialGuide/direction.json';
import sizesDSL from './style-guide-dsl/SpeedDialGuide/sizes.json';
export const SpeedDialStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiSpeedDial,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDial,
    dirName: 'SpeedDialButtonGuide',
    title: 'Speed Dial',
    classNames: [
        '.MuiSpeedDial-root',
        '.MuiSpeedDial-fab',
        '.MuiSpeedDial-directionUp',
        '.MuiSpeedDial-directionDown',
        '.MuiSpeedDial-directionLeft',
        '.MuiSpeedDial-directionRight',
        '.MuiSpeedDial-actions',
        '.MuiSpeedDial-actionsClosed',
    ],
    stories: [
        {
            title: 'Circular Primary (Large, Medium, small)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDial,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'large',
                        },
                    },
                },
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'medium',
                        },
                    },
                },
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'small',
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.19.5',
            isAppDSLCommitted: true,
            generatedAppDSL: sizesDSL,
        },
        {
            title: 'Floating Action Button(Inherit, Primary, Secondary)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDial,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'large',
                        },
                    },
                },
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'medium',
                        },
                    },
                },
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'small',
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.19.5',
            isAppDSLCommitted: true,
            generatedAppDSL: colorsDSL,
        },
        {
            title: 'Directions (Up, Down, Left, Right)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDial,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'large',
                        },
                    },
                },
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'medium',
                        },
                    },
                },
                {
                    props: {
                        FabProps: {
                            color: 'primary',
                            size: 'small',
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.19.5',
            isAppDSLCommitted: true,
            generatedAppDSL: directionDSL,
        },
    ],
};
