import qs from 'qs';
import { complement, filter, isNil } from 'ramda';
import { generatePath } from 'react-router-dom';
import { API_STANDS } from '../constants/stands';
/**
 * Build url with query and path params.
 *
 * @example
 * buildUrl('/products/:id', { pathParams: { id: 123 }, queryParams: { filter: 'someFilter' } }) // "/products/123?filter=someFilter"
 */
export const buildUrl = (path, options = {}) => {
    var _a;
    let query = '';
    if (options.queryParams) {
        const queryParamsString = qs.stringify(options.queryParams);
        query = queryParamsString.length > 0 ? `?${queryParamsString}` : '';
    }
    const filteredPathParams = filter(complement(isNil), (_a = options.pathParams) !== null && _a !== void 0 ? _a : {});
    const generatedPath = generatePath(path, filteredPathParams);
    return `${generatedPath}${query}`;
};
/**
 *
 * @example
 * getParamsFromPath('/products/:id') -> ['id']
 */
export const getParamsFromPath = (path) => {
    const params = path.match(/:(\w+)/g) || [];
    return params.map(el => el.replace(':', ''));
};
export const CheckResourceApiHostValue = (apiHost) => {
    if (apiHost === API_STANDS[apiHost]) {
        return apiHost;
    }
    return API_STANDS[apiHost];
};
