import { last } from 'ramda';

import { AssetListDSL, assetListSelectors } from '@builder/schemas';

export const getAssetIdFromString = (
  value: string,
  assetListDSL: AssetListDSL,
): string | undefined => {
  const parsedAssetsNamesArray = value.split('/');
  const assetName = last(parsedAssetsNamesArray);
  if (assetName) {
    return assetListSelectors.getAssetIDByName(assetListDSL, {
      assetName,
    });
  }
};
