import { values } from 'ramda';
import { componentListSelectors, componentSelectors, } from '@builder/schemas';
import { onlyUnique } from '@builder/utils';
import { SymbolAccessor } from './SymbolAccessor';
/**
 * Provides an api to generate symbols imports.
 */
export class SymbolListImportsGenerator {
    constructor(componentListDSL) {
        this.componentListDSL = componentListDSL;
        this.symbolAccessor = new SymbolAccessor(componentListDSL);
    }
    getAllUsedSymbolNames(usedNodeListDSL) {
        const componentsUsedInSymbols = values(usedNodeListDSL)
            .map(nodeDSL => componentListSelectors.getComponentDSL(this.componentListDSL, {
            componentName: nodeDSL.name,
        }))
            .filter((componentDSL) => componentSelectors.isSymbol(componentDSL))
            .map(({ name }) => name);
        return componentsUsedInSymbols.filter(onlyUnique);
    }
    getImportDataList(usedNodeListDSL) {
        const symbolNames = this.getAllUsedSymbolNames(usedNodeListDSL);
        return symbolNames.reduce((accum, symbolName) => {
            return [
                ...accum,
                {
                    importName: 'shared/symbols',
                    varName: this.symbolAccessor.getSymbolComponentName(symbolName),
                },
            ];
        }, []);
    }
}
