import { API_STANDS } from '../constants';
import { ERROR_SCOPES, SystemError } from '../errors';
const getRequiredEnvVariable = (variableName) => {
    const variable = process.env[variableName];
    if (!variable) {
        // need this to skip error with test script
        if (process.env.NODE_ENV === 'test') {
            return '';
        }
        throw new SystemError(ERROR_SCOPES.utils, `${variableName} env variable should be specified`);
    }
    return variable;
};
export const getEnvironment = () => { var _a; return (_a = process.env.NODE_ENV) !== null && _a !== void 0 ? _a : 'development'; };
export const isProduction = () => process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod';
export const isDevelopment = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev';
export const isDevLogEnabled = () => process.env.REACT_APP_DEV_LOGS_ENABLED === 'true';
export const getConsoleURL = () => getRequiredEnvVariable('REACT_APP_CONSOLE_URL');
export const getMultiTenantId = () => getRequiredEnvVariable('REACT_APP_MULTITENANT_ID');
export const getServerURL = () => getRequiredEnvVariable('REACT_APP_SERVER_URL');
export const getServerWssURL = () => getRequiredEnvVariable('REACT_APP_SERVER_WSS_URL');
export const getDraftServerURL = () => getRequiredEnvVariable('REACT_APP_DRAFT_SERVER_URL');
export const getCodeBundlerServerURL = () => getRequiredEnvVariable('REACT_APP_CODE_BUNDLER_SERVER_URL');
export const getAuthDomain = () => getRequiredEnvVariable('REACT_APP_AUTH_DOMAIN');
export const getAuthClientID = () => getRequiredEnvVariable('REACT_APP_AUTH_CLIENT_ID');
export const getGoogleFontsURL = () => getRequiredEnvVariable('REACT_APP_GOOGLE_FONTS_URL');
export const getGoogleFontsAPIKey = () => getRequiredEnvVariable('REACT_APP_GOOGLE_FONTS_API_KEY');
export const getRunCodeEngineLocal = () => process.env.REACT_APP_RUN_CODE_ENGINE_LOCAL === 'true';
export const getAuthLogoutRedirectURL = () => getRequiredEnvVariable('REACT_APP_AUTH_LOGOUT_REDIRECT_URL');
export const getDevTestFrontendWorkspaceID = () => process.env.REACT_APP_DEV_TEST_FRONTEND_WORKSPACE_ID;
export const getSkipAuthCheckSession = () => process.env.REACT_APP_SKIP_AUTH_CHECK_SESSION === 'true';
export const getUseDefaultExampleData = () => process.env.REACT_APP_USE_DEFAULT_EXAMPLE_DATA === 'true';
export const isEightBaseProdAPI = () => {
    const currentAPI = getServerURL();
    return currentAPI === API_STANDS.prod;
};
export const getRollbarAccessToken = () => process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
export const getBuilderVersion = () => process.env.REACT_APP_VERSION;
