import { omit } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, STATE_SCOPES, STATE_TYPES, COMPONENT_DSL_CALLBACK_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsDatePicker } from '../../material-components/BuilderComponentsDatePicker';
import { BuilderComponentsFormDatePickerSettings } from './BuilderComponentsFormDatePicker.settings';
const timeElapsed = Date.now();
const today = new Date(timeElapsed);
export const BuilderComponentsFormDatePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormDatePicker,
    displayName: 'Form Date Picker',
    icon: 'formDatePicker',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormDatePicker',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'FormDatePicker',
                    defaultValue: today.toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    }),
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormDatePicker',
                },
                datePickerProps: Object.assign(Object.assign({}, omit(['value', 'onChange'], BuilderComponentsDatePicker.schema.props.datePickerProps)), { value: '{{ __STATES__.FormDatePicker.value }}', onChange: {
                        actionType: 'customCode',
                        args: {
                            code: '__STATES__.FormDatePicker.setValue(date)',
                        },
                    } }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { onAccept: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, datePickerProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsDatePicker.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            } }),
    },
    settings: BuilderComponentsFormDatePickerSettings,
};
