import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* Recursively generates react nodes from dsl nodes. */
import { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';
import { has } from 'ramda';
import { ROOT_NODE_ID, createRenderPathData, } from '@builder/schemas';
import { serialize } from '@builder/utils';
import { SCROLL_END, SCROLL_START } from '../../utils';
import { NodeDSLToReactRenderer } from './NodeDSLToReactRenderer';
import { NodeDSLToReactTransformer } from './NodeDSLToReactTransformer';
export const AppNodeGenerator = ({ isPreviewMode, appRuntimeState, libraries, nodeListDSL, appStates, appSettings, componentListDSL, resourceListDSL, assetListDSL, skipPresentations, onAppAuditNotifications, renderReactComponent, presentationStates, onRuntimeStateReport, }) => {
    const baseDSLToControlRef = useRef({});
    const onRuntimeStateReportEffect = debounce(({ localStateList, presentationList, }) => {
        try {
            onRuntimeStateReport({
                states: {
                    globalState: serialize.flatten(appRuntimeState.globalState),
                    predefinedState: serialize.flatten({
                        resources: appRuntimeState.predefinedState.resources,
                        theme: appRuntimeState.predefinedState.theme,
                        routerHistory: appRuntimeState.predefinedState.routerHistory,
                        routerLocation: appRuntimeState.predefinedState.routerLocation,
                        auth: appRuntimeState.predefinedState.auth,
                        apolloClient: appRuntimeState.predefinedState.apolloClient,
                        roles: appRuntimeState.predefinedState.roles,
                        router: appRuntimeState.predefinedState.router,
                        assets: appRuntimeState.predefinedState.assets,
                        requestRunner: appRuntimeState.predefinedState.requestRunner,
                    }),
                    localStateList: serialize.flatten(localStateList),
                    localStates: serialize.flatten(appRuntimeState.localStates),
                    presentationList,
                },
            });
        }
        catch (e) {
            console.warn('onRuntimeStateReport failed!');
            console.error(e);
        }
    }, 30);
    const nodeDSLToReactTransformer = useMemo(() => new NodeDSLToReactTransformer({
        isPreviewMode,
        nodeListDSL,
        appStates,
        appSettings,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        skipPresentations,
        onAppAuditNotifications,
        libraries,
        presentationStates,
    }), [
        appSettings,
        appStates,
        assetListDSL,
        componentListDSL,
        isPreviewMode,
        libraries,
        nodeListDSL,
        onAppAuditNotifications,
        presentationStates,
        resourceListDSL,
        skipPresentations,
    ]);
    const nodeDSLToReactRenderer = useMemo(() => new NodeDSLToReactRenderer({
        isPreviewMode,
        nodeListDSL,
        appStates,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        renderReactComponent,
        onAppAuditNotifications,
        onLocalStateUpdate: onRuntimeStateReportEffect,
        presentationStates,
    }, nodeDSLToReactTransformer, libraries), [
        appStates,
        assetListDSL,
        componentListDSL,
        isPreviewMode,
        libraries,
        nodeDSLToReactTransformer,
        nodeListDSL,
        onAppAuditNotifications,
        onRuntimeStateReportEffect,
        presentationStates,
        renderReactComponent,
        resourceListDSL,
    ]);
    nodeDSLToReactRenderer.init();
    useEffect(() => {
        const hasPendoUserMetadata = has('pendoUserMetadata');
        if (hasPendoUserMetadata(window.top)) {
            window.pendo.initialize(window.top.pendoUserMetadata);
            delete window.top.pendoUserMetadata;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const nodeTree = nodeDSLToReactRenderer.generateReactNode(nodeListDSL.root, Object.assign({}, appRuntimeState), createRenderPathData({
        nodeID: ROOT_NODE_ID,
        parentRenderPath: null,
    }), baseDSLToControlRef.current);
    setTimeout(() => {
        const elementByDataTest = document.querySelector('[data-test="preview.node.MaterialDialogContent"]');
        let isScrolling;
        if (elementByDataTest) {
            elementByDataTest.addEventListener('scroll', () => {
                // Send a message to inform the client (abbBuilder) that they are scrolling within the Dialog opened in the iframe.
                window.parent.postMessage({ type: SCROLL_START }, '*');
                // Send a message to inform the client that the scrolling action has finished.
                window.clearTimeout(isScrolling);
                isScrolling = setTimeout(() => {
                    window.parent.postMessage({ type: SCROLL_END }, '*');
                }, 100);
            });
        }
    }, 1000);
    return _jsx(_Fragment, { children: nodeTree });
};
