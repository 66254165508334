import React, { useMemo } from 'react';

import { Grid, useTheme } from '@mui/material';
import { empty, path } from 'ramda';

import {
  ComponentSettingActionDSL,
  ActionTypes,
  COMPONENT_SETTING_ACTION_COLORS,
  COMPONENT_SETTING_ACTION_TYPE,
} from '@builder/schemas';
import { memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';

import { ActionAccordionSection, EventListItemContainer } from './ActionType.styles';

type ActionTypeProps = {
  onUpdateProp: (propData: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  setting: ComponentSettingActionDSL;
  children: React.ReactNode;
  'data-test'?: string;
};

type PropsValuesTypes = {
  actionType:
    | typeof COMPONENT_SETTING_ACTION_TYPE.functionCode
    | typeof COMPONENT_SETTING_ACTION_TYPE.runRequest
    | typeof COMPONENT_SETTING_ACTION_TYPE.updateState;
  args: {
    function: string;
  };
};

export const ActionType = memo(
  'ActionType',
  ({ onUpdateProp, setting, children, 'data-test': dataTest }: ActionTypeProps): JSX.Element => {
    const theme = useTheme();
    const { selectedNodeDSL } = useNodeSettingsProps();

    const settingValue = path<ActionTypes>(
      [...setting.keyPath, 'actionType'],
      selectedNodeDSL.props,
    );

    useMemo(() => {
      Object.entries(selectedNodeDSL.props).forEach(([key]) => {
        const propsValues = selectedNodeDSL.props[key] as PropsValuesTypes;

        const hasFunctionOrRequest = [
          COMPONENT_SETTING_ACTION_TYPE.functionCode,
          COMPONENT_SETTING_ACTION_TYPE.runRequest,
          COMPONENT_SETTING_ACTION_TYPE.updateState,
        ].includes(propsValues.actionType);

        const hasEmptyArgs =
          // eslint-disable-next-line no-prototype-builtins
          !propsValues?.hasOwnProperty('args') ||
          propsValues.args.function?.includes('undefined') ||
          !empty(propsValues.args);

        if (hasFunctionOrRequest && hasEmptyArgs) {
          onUpdateProp({ keyValue: '', keyPath: [`${key}`, 'actionType'] });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Grid container spacing={1}>
        <Grid alignItems="center" item xs={12}>
          <ActionAccordionSection title={setting.label}>
            <EventListItemContainer
              color={
                settingValue
                  ? theme.palette[COMPONENT_SETTING_ACTION_COLORS[settingValue]].dark
                  : theme.palette.text.primary
              }
            >
              {children}
            </EventListItemContainer>
          </ActionAccordionSection>
        </Grid>
      </Grid>
    );
  },
);
