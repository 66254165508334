import { createSelector } from 'reselect';
import { injectFontFaceUrl } from '@builder/utils';
const getThemeDSL = (appThemeDSL) => appThemeDSL;
const getAssetListDSL = (_, props) => props.assetListDSL;
const getAssetBackendList = (_, props) => props.assetBackendList;
export const getCustomFontListDSL = createSelector(getThemeDSL, themeDSL => { var _a, _b; return (_b = (_a = themeDSL === null || themeDSL === void 0 ? void 0 : themeDSL.fonts) === null || _a === void 0 ? void 0 : _a.custom) !== null && _b !== void 0 ? _b : {}; });
export const getThemeCSSSection = createSelector(getThemeDSL, themeDSL => { var _a; return ((_a = themeDSL === null || themeDSL === void 0 ? void 0 : themeDSL.css) !== null && _a !== void 0 ? _a : {}); });
export const getGlobalCSS = createSelector(getThemeCSSSection, themeCSS => { var _a; return (_a = themeCSS.global) !== null && _a !== void 0 ? _a : ''; });
export const getCustomCSS = createSelector(getThemeCSSSection, themeCSS => { var _a; return (_a = themeCSS.custom) !== null && _a !== void 0 ? _a : ''; });
export const getMediaQueryCSS = createSelector(getThemeCSSSection, themeCSS => { var _a; return (_a = themeCSS.mediaQueries) !== null && _a !== void 0 ? _a : ''; });
export const getComponentsCSS = createSelector(getThemeCSSSection, themeCSS => {
    var _a;
    return Object.values((_a = themeCSS.components) !== null && _a !== void 0 ? _a : {}).reduce((accum, css) => {
        return `${accum + css}\n`;
    }, '');
});
export const getTypographyCSS = createSelector(getThemeCSSSection, themeCSS => {
    var _a;
    return (_a = themeCSS.typography) !== null && _a !== void 0 ? _a : '';
});
export const getRawFontFaceListDSL = createSelector(getThemeCSSSection, themeCSS => { var _a; return (_a = themeCSS === null || themeCSS === void 0 ? void 0 : themeCSS.fontFaces) !== null && _a !== void 0 ? _a : {}; });
export const getFontFacesCSS = createSelector(getRawFontFaceListDSL, getCustomFontListDSL, getAssetListDSL, getAssetBackendList, (fontFaceListDSL, customFontListDSL, assetListDSL, assetBackendList) => {
    return Object.values(fontFaceListDSL !== null && fontFaceListDSL !== void 0 ? fontFaceListDSL : {}).reduce((fontFaceCSS, fontFaceDSL) => {
        var _a, _b;
        const fontDSL = customFontListDSL[fontFaceDSL.fontID];
        const fontBackendFileID = (_a = assetListDSL[fontDSL === null || fontDSL === void 0 ? void 0 : fontDSL.assetID]) === null || _a === void 0 ? void 0 : _a.backendFileID;
        const fontBackendAsset = assetBackendList[fontBackendFileID];
        if (!fontBackendAsset) {
            return fontFaceCSS;
        }
        return `${fontFaceCSS +
            injectFontFaceUrl(fontFaceDSL.css, (_b = fontBackendAsset === null || fontBackendAsset === void 0 ? void 0 : fontBackendAsset.file) === null || _b === void 0 ? void 0 : _b.downloadUrl)}\n`;
    }, '');
});
export const getThemeCSS = createSelector(getGlobalCSS, getMediaQueryCSS, getFontFacesCSS, getTypographyCSS, getCustomCSS, getComponentsCSS, (globalCSS, mediaQueryCSS, fontFacesCSS, typographyCSS, customCSS, componentsCSS) => {
    return [fontFacesCSS, typographyCSS, globalCSS, componentsCSS, customCSS, mediaQueryCSS].join('\n');
});
