import { useCallback, useState } from 'react';

import { Event, EventType } from '@8base-private/event-handler';
import { nanoid as uuid } from 'nanoid';
import { useStore } from 'react-redux';

import { useAppDispatch, useDraftEngine } from 'src/providers';
import { incrementVersion } from 'src/shared/utils';
import { DASHBOARD_EVENTS, UI_BUILDER_EVENTS } from 'src/store';

type UseConfigurationSaveReturn = {
  savingConfiguration: boolean;
  saveConfiguration: () => Promise<void>;
};

export const useConfigurationSave = (): UseConfigurationSaveReturn => {
  const send = useAppDispatch();
  const { getState } = useStore();
  const { draftEngine } = useDraftEngine();
  const [loading, setLoading] = useState(false);

  const saveConfiguration = useCallback(async () => {
    if (!draftEngine) return;

    const state = getState();
    const { appVersion } = state.dashboard.appConfiguration;

    send({
      type: DASHBOARD_EVENTS.appConfigurationDSLCleanUp,
    });

    send({
      type: DASHBOARD_EVENTS.appConfigurationUpdateVersion,
      appVersion: incrementVersion(appVersion, [0, 0, 0, 1]),
    });

    const eventId = uuid();

    const saveResponseListener = async (event: Event) => {
      if (event.uuid !== eventId) return;

      switch (event.type) {
        case EventType.BroadcastSave:
          setLoading(false);

          send({
            type: DASHBOARD_EVENTS.dashboardSetInitialHistory,
            payload: { future: [], past: [] },
          });

          send({
            type: UI_BUILDER_EVENTS.successAppNotify,
            successMessage: `Successfully saved the application`,
          });

          send({
            type: UI_BUILDER_EVENTS.saveProcessStarts,
          });
          break;
        case EventType.Error:
          setLoading(false);
          send({
            type: UI_BUILDER_EVENTS.errorAppNotify,
            errorMessage: `Couldn't save the application`,
          });
          break;
      }

      draftEngine.removeEventListener(saveResponseListener);
    };

    draftEngine.addEventListener(saveResponseListener);

    setLoading(true);
    draftEngine.send({
      type: EventType.Save,
      uuid: eventId,
      payload: undefined,
    });
  }, [draftEngine, getState, send]);

  return {
    savingConfiguration: loading,
    saveConfiguration,
  };
};
