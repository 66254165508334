import { useAppDispatch, useUIBuilderState } from 'src/providers';
import { NODE_SETTINGS_TABS, RIGHT_PANEL_TABS, UI_BUILDER_EVENTS } from 'src/store';

type UseDoubleClickEditTextOptions<T> = {
  targetNodeId: string | string[];
  ref: T;
  inputLabel: string;
};

export const useDoubleClickEditText = <T extends React.MutableRefObject<HTMLInputElement | null>>({
  ref,
  targetNodeId,
  inputLabel,
}: UseDoubleClickEditTextOptions<T>): void => {
  const { nodeSettingsArgs } = useUIBuilderState().rightPanel;
  const currentNodeSelected = nodeSettingsArgs?.focusLabelForCurrentNode?.nodeId || '';
  const send = useAppDispatch();

  /**
   * this condition is to only enable this hook for the desired input
   */
  if (inputLabel !== nodeSettingsArgs?.focusLabelForCurrentNode?.inputLabelToFocus) {
    return;
  }

  const shouldFocusInputRef =
    targetNodeId?.includes(currentNodeSelected) &&
    nodeSettingsArgs?.focusLabelForCurrentNode?.focused === false;

  if (shouldFocusInputRef && ref.current) {
    ref.current.focus();
    ref.current.select();
    send({
      type: UI_BUILDER_EVENTS.updateRightPanel,
      rightPanel: {
        currentTab: RIGHT_PANEL_TABS.componentList,
        nodeSettingsArgs: {
          currentTab: NODE_SETTINGS_TABS.properties,
          focusLabelForCurrentNode: {
            inputLabelToFocus: nodeSettingsArgs.focusLabelForCurrentNode
              ?.inputLabelToFocus as string,
            focused: true,
            nodeId: targetNodeId as string,
          },
        },
      },
    });
  }
};
