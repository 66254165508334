import { PossibleUnitTypes, POSSIBLE_UNIT_TYPES } from '@builder/schemas';

export const getCurrentValue = (
  propValue: string | undefined,
  unit: PossibleUnitTypes | undefined,
): string | undefined => {
  if (!unit || !propValue || unit.type === POSSIBLE_UNIT_TYPES.value) {
    return propValue;
  }

  return propValue.replace(unit.value, '');
};
