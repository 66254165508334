/* eslint-disable @typescript-eslint/no-explicit-any */
import { pipe, omit } from 'ramda';
import { AUTH_RESOURCE_ID_TEMPLATE } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_11_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.1';
    }
    getChangeList() {
        return ['Move auth backend to the  resources DSL section'];
    }
    migrateAppDSL(appDSL) {
        var _a, _b, _c, _d;
        const authListDSL = appDSL === null || appDSL === void 0 ? void 0 : appDSL.auth;
        const apiEndpoint = (_a = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _a === void 0 ? void 0 : _a.apiEndpoint;
        const currentAuthID = (_b = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _b === void 0 ? void 0 : _b.authID;
        if (!authListDSL && !apiEndpoint) {
            return appDSL;
        }
        if (!apiEndpoint) {
            return Object.assign(Object.assign({}, omit(['auth'], appDSL)), { settings: Object.assign({}, omit(['authID'])(appDSL.settings)), resources: Object.assign({}, ((_c = appDSL.resources) !== null && _c !== void 0 ? _c : {})) });
        }
        return Object.assign(Object.assign({}, omit(['auth'], appDSL)), { settings: Object.assign(Object.assign({}, pipe(omit(['apiEndpoint']), omit(['authID']))(appDSL.settings)), { authResourceID: AUTH_RESOURCE_ID_TEMPLATE.id }), resources: Object.assign(Object.assign({}, ((_d = appDSL.resources) !== null && _d !== void 0 ? _d : {})), { [AUTH_RESOURCE_ID_TEMPLATE.id]: Object.assign(Object.assign({}, AUTH_RESOURCE_ID_TEMPLATE), { endpoint: apiEndpoint !== null && apiEndpoint !== void 0 ? apiEndpoint : '', name: 'eightBase', title: 'eightBase', auth: {
                        currentAuthID,
                        authList: authListDSL,
                    } }) }) });
    }
}
export const migration_0_11_1 = new Migration_0_11_1();
