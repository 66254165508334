import React from 'react';

import NumberFormat, { InputAttributes, NumberFormatProps } from 'react-number-format';

export interface NumberInputProps {
  name: string;
  numberFormat?: NumberFormatProps<unknown>;
  isNumericString?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInput = React.forwardRef<NumberFormat<InputAttributes>, NumberInputProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, numberFormat, isNumericString, ...other } = props;

    return (
      <NumberFormat
        {...other}
        {...numberFormat}
        getInputRef={ref}
        thousandSeparator
        decimalScale={2}
        decimalSeparator="."
        isNumericString
        onValueChange={(values, sourceInfo) => {
          const event = sourceInfo?.event ?? {};
          onChange({
            ...event,
            target: {
              ...(event.target || {}),
              name: props.name,
              value: values.value,
            },
            currentTarget: {
              ...(event.currentTarget || {}),
              value: values.value,
              name: props.name,
            },
          });
        }}
      />
    );
  },
);
