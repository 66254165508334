import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

import { Button } from 'src/shared/components';
import { StyledLeftPanelFloatingContainer } from 'src/shared/components/LeftPanelFloatingContainer/LeftPanelFloatingContainer';

export const AssetMediaContainer = styled.div`
  width: 172px;
`;

export const StyledAssetButton = styled(Button)`
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  .MuiButton-startIcon {
    padding: 0;
    margin: 0;
  }
`;

export const PopupContent = styled(Paper)`
  width: ${({ theme }) => theme.layout.RightPanel.size}px;
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
  border-radius: 8px;
  ${StyledLeftPanelFloatingContainer} {
    border-radius: 8px;
    box-shadow: none;
  }
`;

export const PopupHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const PopupTitle = styled(Typography)`
  font-weight: 600;
`;

export const LabelWithTooltip = styled.div`
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
