import { Migration } from '../Migration';
class Migration_0_17_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.0';
    }
    getChangeList() {
        return ['Fixing MomentJS library name'];
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        const libraries = Object.assign({}, (appDSL.libraries || {}));
        const newLibs = Object.entries(libraries).map(([libKey, libValue]) => {
            if (libKey === 'momentjs') {
                return ['moment', Object.assign(Object.assign({}, libValue), { name: 'moment' })];
            }
            return [libKey, libValue];
        });
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { libraries: Object.fromEntries(newLibs) }) });
    }
}
export const migration_0_17_0 = new Migration_0_17_0();
