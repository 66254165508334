import { omit } from 'ramda';
import { COMPONENT_SCHEMAS, COMPONENT_DSL_NAMES, appNodesSchemaSelectors, nodeListSelectors, } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_5_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.5.0';
    }
    getChangeList() {
        return [
            `Migrate material components from version 4 to 5. Please, check all components with '[CHECK]' suffix.`,
        ];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete: {
                return Object.assign(Object.assign({}, nodeDSL), { alias: `${nodeDSL.alias} [CHECK]`, props: Object.assign(Object.assign({}, omit(['closeIcon', 'isOptionEqualToValue', 'debug', 'renderOption'], nodeDSL.props)), { clearIcon: nodeDSL.props.closeIcon, getOptionSelected: nodeDSL.props.isOptionEqualToValue }) });
            }
            case COMPONENT_DSL_NAMES.MaterialAvatar: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { variant: nodeDSL.props.variant === 'cicrle' ? 'circular' : nodeDSL.props.variant }) });
            }
            case COMPONENT_DSL_NAMES.MaterialBadge: {
                const getOverlap = () => {
                    if (nodeDSL.props.overlap === 'circle') {
                        return 'circular';
                    }
                    if (nodeDSL.props.overlap === 'rectangle') {
                        return 'rectangular';
                    }
                    return nodeDSL.props.overlap;
                };
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { overlap: getOverlap() }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsButton: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { color: nodeDSL.props.color === 'default' ? 'primary' : nodeDSL.props.color }) });
            }
            case COMPONENT_DSL_NAMES.MaterialChip: {
                if (nodeDSL.props.variant === 'default') {
                    return Object.assign(Object.assign({}, nodeDSL), { props: omit(['variant'], nodeDSL.props) });
                }
                return nodeDSL;
            }
            case COMPONENT_DSL_NAMES.AsyncContent: {
                const circularProgressProps = (nodeDSL.props.circularProgressProps ||
                    {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { circularProgressProps: Object.assign(Object.assign({}, circularProgressProps), { variant: circularProgressProps.variant === 'static'
                                ? 'determinate'
                                : circularProgressProps.variant }) }) });
            }
            case COMPONENT_DSL_NAMES.MaterialDialog: {
                const TransitionProps = (nodeDSL.props.TransitionProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, omit([
                        'disableBackdropClick',
                        'onEnter',
                        'onEntered',
                        'onEntering',
                        'onExit',
                        'onExited',
                        'onExiting',
                    ], nodeDSL.props)), { TransitionProps: Object.assign(Object.assign({}, TransitionProps), { onEnter: nodeDSL.props.onEnter, onEntered: nodeDSL.props.onEntered, onEntering: nodeDSL.props.onEntering, onExit: nodeDSL.props.onExit, onExited: nodeDSL.props.onExited, onExiting: nodeDSL.props.onExiting }) }) });
            }
            case COMPONENT_DSL_NAMES.DialogSymbol: {
                const dialogProps = (nodeDSL.props.dialogProps || {});
                const TransitionProps = (dialogProps.TransitionProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { dialogProps: Object.assign(Object.assign({}, omit([
                            'disableBackdropClick',
                            'onEnter',
                            'onEntered',
                            'onEntering',
                            'onExit',
                            'onExited',
                            'onExiting',
                        ], dialogProps)), { TransitionProps: Object.assign(Object.assign({}, TransitionProps), { onEnter: dialogProps.onEnter, onEntered: dialogProps.onEntered, onEntering: dialogProps.onEntering, onExit: dialogProps.onExit, onExited: dialogProps.onExited, onExiting: dialogProps.onExiting }) }) }) });
            }
            case COMPONENT_DSL_NAMES.MaterialDialogTitle: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign({}, omit(['disableTypography'], nodeDSL.props)) });
            }
            case COMPONENT_DSL_NAMES.MaterialGrid:
            case COMPONENT_DSL_NAMES.GridContainerSymbol: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, omit(['justify'], nodeDSL.props)), { justifyContent: nodeDSL.props.justify }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsIcon: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { fontSize: nodeDSL.props.fontSize === 'default' ? 'medium' : nodeDSL.props.fontSize }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsSelect: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, omit(['labelWidth'], nodeDSL.props)), { variant: nodeDSL.props.variant === 'standard' ? 'outlined' : nodeDSL.props.variant }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsFormSelect: {
                const selectProps = (nodeDSL.props.selectProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { selectProps: Object.assign(Object.assign({}, omit(['labelWidth'], selectProps)), { variant: selectProps.variant === 'standard' ? 'outlined' : selectProps.variant }) }) });
            }
            case 'MaterialSwitch': {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { color: nodeDSL.props.color === 'default' ? 'primary' : nodeDSL.props.color }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsFormSwitch: {
                const switchProps = (nodeDSL.props.switchProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { switchProps: Object.assign(Object.assign({}, switchProps), { color: switchProps.color === 'default' ? 'primary' : switchProps.color }) }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsTextField: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, omit(['rowsMax'], nodeDSL.props)), { variant: nodeDSL.props.variant === 'standard' ? 'outlined' : nodeDSL.props.variant, maxRows: nodeDSL.props.rowsMax }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsFormTextField: {
                const inputProps = (nodeDSL.props.inputProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { inputProps: Object.assign(Object.assign({}, omit(['rowsMax'], inputProps)), { variant: inputProps.variant === 'standard' ? 'outlined' : inputProps.variant, maxRows: inputProps.rowsMax }) }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsTooltip: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign({}, omit(['interactive'], nodeDSL.props)) });
            }
            case COMPONENT_DSL_NAMES.MaterialTypography: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { variant: nodeDSL.props.variant === 'srOnly' ? 'inherit' : nodeDSL.props.variant }) });
            }
            case 'BuilderComponentsKeyboardDatePicker': {
                return Object.assign(Object.assign({}, nodeDSL), { name: COMPONENT_DSL_NAMES.BuilderComponentsDatePicker, props: Object.assign(Object.assign({}, omit(['format'], nodeDSL.props)), { inputFormat: nodeDSL.props.format }) });
            }
            case 'BuilderComponentsFormKeyboardDatePicker': {
                const datePickerProps = (nodeDSL.props.datePickerProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { name: COMPONENT_DSL_NAMES.BuilderComponentsFormDatePicker, props: Object.assign(Object.assign({}, nodeDSL.props), { datePickerProps: Object.assign(Object.assign({}, omit(['format'], datePickerProps)), { inputFormat: datePickerProps.format }) }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsFilePicker: {
                return Object.assign(Object.assign({}, nodeDSL), { alias: `${nodeDSL.alias} [REMOVED]` });
            }
            default: {
                return nodeDSL;
            }
        }
    }
    migrateAppConfiguration(configuration) {
        var _a;
        const { appDSL } = configuration;
        const componentListDSL = Object.assign(Object.assign({}, COMPONENT_SCHEMAS), configuration.userComponentsDSL);
        const nodesToRemove = nodeListSelectors
            .getNodesArrayDSL(appDSL.nodes)
            .filter(node => node.name === COMPONENT_DSL_NAMES.BuilderComponentsAutocompleteOption);
        const nodesWithoutRemoved = nodesToRemove.reduce((acc, nodeDSL) => {
            return appNodesSchemaSelectors.removeComponentFromRelatedComponents({ nodeListDSL: acc, componentListDSL }, {
                nodeID: nodeDSL.id,
            });
        }, Object.assign({}, appDSL.nodes));
        const migratedNodesListDSL = this.migrateNodeListDSL(nodesWithoutRemoved, appDSL);
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: migratedNodesListDSL, theme: Object.assign(Object.assign({}, omit(['overrides'], configuration.appDSL.theme)), { 
                    // Using ts-ignore because type was changed from overrides to components
                    // But it still exists in non-migrated DSL
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    components: ((_a = configuration.appDSL.theme) === null || _a === void 0 ? void 0 : _a.overrides) || {} }) }) });
    }
}
export const migration_0_5_0 = new Migration_0_5_0();
