import { AppEvents, DashboardState, DASHBOARD_EVENTS } from '../../../../common';
import { dashboardInitial } from '../../../initial-state';

export const layoutReducer = (
  state: DashboardState['layout'] = dashboardInitial.layout,
  event: AppEvents,
): DashboardState['layout'] => {
  switch (event.type) {
    case DASHBOARD_EVENTS.layoutModeUpdate:
      return {
        ...state,
        layoutMode: event.layoutMode,
      };

    default:
      return state;
  }
};
