var _a;
import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { BuilderComponentsSpeedDialActionSettings } from './BuilderComponentsSpeedDialAction.settings';
export const BuilderComponentsSpeedDialAction = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDialAction,
    displayName: 'Speed Dial Action',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'SpeedDialAction',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyRemovable,
        accessibility: {
            hideOverlay: true,
        },
        presentations: [visibleByCondition],
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.Iterator,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDial,
                },
            },
        ],
        predefineds: {
            props: {
                delay: 0,
                open: false,
                tooltipOpen: false,
                tooltipTitle: 'New Action',
                tooltipPlacement: 'left',
                icon: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { name: 'RocketLaunch', fontSize: 'small' }),
                            schemaOverride: {
                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { id: { type: COMPONENT_DSL_PROP_TYPES.string }, FabProps: { type: COMPONENT_DSL_PROP_TYPES.object }, delay: { type: COMPONENT_DSL_PROP_TYPES.number }, icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, tooltipOpen: { type: COMPONENT_DSL_PROP_TYPES.boolean }, tooltipPlacement: { type: COMPONENT_DSL_PROP_TYPES.string }, tooltipTitle: { type: COMPONENT_DSL_PROP_TYPES.string }, events: { type: COMPONENT_DSL_PROP_TYPES.object }, style: { type: COMPONENT_DSL_PROP_TYPES.object }, sx: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsSpeedDialActionSettings,
};
