import { AccordionStyleGuide } from './AccordionStyleGuide';
import { AlertStyleGuide } from './AlertStyleGuide';
import { AutocompleteStyleGuide } from './AutocompleteStyleGuide';
import { AvatarStyleGuide } from './AvatarStyleGuide';
import { BadgeStyleGuide } from './BadgeStyleGuide';
import { ButtonGroupStyleGuide } from './ButtonGroupStyleGuide';
import { ButtonStyleGuide } from './ButtonStyleGuide';
import { CheckboxStyleGuide } from './CheckboxStyleGuide';
import { ChipStyleGuide } from './ChipStyleGuide';
import { DividerStyleGuide } from './DividerStyleGuide';
import { FloatingActionButton } from './FloatingActionButton';
import { IconButtonStyleGuide } from './IconButtonStyleGuide';
import { RadioGroupStyleGuide } from './RadioGroupStyleGuide';
import { RatingStyleGuide } from './RatingStyleGuide';
import { SelectStyleGuide } from './SelectStyleGuide';
import { SliderStyleGuide } from './SliderStyleGuide';
import { SnackbarStyleGuide } from './SnackbarStyleGuide';
import { SpeedDialStyleGuide } from './SpeedDialStyleGuide';
import { SwitchStyleGuide } from './SwitchStyleGuide';
import { TabsStyleGuide } from './TabsStyleGuide';
import { TextFieldStyleGuide } from './TextFieldStyleGuide';
import { ToggleButtonGroupStyleGuide } from './ToggleButtonGroupStyleGuide';
import { ToggleButtonStyleGuide } from './ToggleButtonStyleGuide';
import { TypographyStyleGuide, TYPOGRAPHY_VARIANT_CLASSES, TYPOGRAPHY_COMMON_CLASSES, TypographyStyleGuideGlobalStyle, } from './TypographyStyleGuide';
export { TYPOGRAPHY_VARIANT_CLASSES, TYPOGRAPHY_COMMON_CLASSES, TypographyStyleGuideGlobalStyle };
export * from './generateStyleGuideDSL';
export const STYLE_GUIDE_SCHEMA = {
    [ButtonStyleGuide.name]: ButtonStyleGuide,
    [ButtonGroupStyleGuide.name]: ButtonGroupStyleGuide,
    [ToggleButtonStyleGuide.name]: ToggleButtonStyleGuide,
    [ToggleButtonGroupStyleGuide.name]: ToggleButtonGroupStyleGuide,
    [CheckboxStyleGuide.name]: CheckboxStyleGuide,
    [RadioGroupStyleGuide.name]: RadioGroupStyleGuide,
    [RatingStyleGuide.name]: RatingStyleGuide,
    [TabsStyleGuide.name]: TabsStyleGuide,
    [SwitchStyleGuide.name]: SwitchStyleGuide,
    [SliderStyleGuide.name]: SliderStyleGuide,
    [TypographyStyleGuide.name]: TypographyStyleGuide,
    [AutocompleteStyleGuide.name]: AutocompleteStyleGuide,
    [SelectStyleGuide.name]: SelectStyleGuide,
    [TextFieldStyleGuide.name]: TextFieldStyleGuide,
    [IconButtonStyleGuide.name]: IconButtonStyleGuide,
    [AccordionStyleGuide.name]: AccordionStyleGuide,
    [AvatarStyleGuide.name]: AvatarStyleGuide,
    [BadgeStyleGuide.name]: BadgeStyleGuide,
    [ChipStyleGuide.name]: ChipStyleGuide,
    [DividerStyleGuide.name]: DividerStyleGuide,
    [AlertStyleGuide.name]: AlertStyleGuide,
    [FloatingActionButton.name]: FloatingActionButton,
    [SpeedDialStyleGuide.name]: SpeedDialStyleGuide,
    [SnackbarStyleGuide.name]: SnackbarStyleGuide,
};
