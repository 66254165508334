import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
export const BuilderComponentsFilePickerSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['acceptedFiles'],
                            label: 'Accepted Files',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['filesLimit'],
                            label: 'Files Limit',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['maxFileSize'],
                            label: 'Max File Size',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['dropzoneText'],
                            label: 'Dropzone Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['clearOnUnmount'],
                            label: 'Clear On Unmount',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableRejectionFeedback'],
                            label: 'Disable Rejection Feedback',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showPreviews'],
                            label: 'Show Previews',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showPreviewsInDropzone'],
                            label: 'Show Previews In Dropzone',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showFileNamesInPreview'],
                            label: 'Show File Names In Preview',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['useChipsForPreview'],
                            label: 'Use Chips For Preview',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['previewText'],
                            label: 'Preview Text',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: ['onChange'],
                            children: getCommonActionSettings(['onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onAddAction',
                            label: 'On Add',
                            keyPath: ['onAdd'],
                            children: getCommonActionSettings(['onAdd']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onDeleteAction',
                            label: 'On Delete',
                            keyPath: ['onDelete'],
                            children: getCommonActionSettings(['onDelete']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onDropAction',
                            label: 'On Drop',
                            keyPath: ['onDrop'],
                            children: getCommonActionSettings(['onDrop']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onDropRejectedAction',
                            label: 'On Drop Rejected',
                            keyPath: ['onDropRejected'],
                            children: getCommonActionSettings(['onDropRejected']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onAlertAction',
                            label: 'On Alert',
                            keyPath: ['onAlert'],
                            children: getCommonActionSettings(['onAlert']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'getFileLimitExceedMessageAction',
                            label: 'Get File Limit Exceed Message',
                            keyPath: ['getFileLimitExceedMessage'],
                            children: getCommonActionSettings(['getFileLimitExceedMessage']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'getFileAddedMessageAction',
                            label: 'Get File Added Message',
                            keyPath: ['getFileAddedMessage'],
                            children: getCommonActionSettings(['getFileAddedMessage']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'getFileRemovedMessageAction',
                            label: 'Get File Removed Message',
                            keyPath: ['getFileRemovedMessage'],
                            children: getCommonActionSettings(['getFileRemovedMessage']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'getDropRejectMessageAction',
                            label: 'Get Drop Reject Message',
                            keyPath: ['getDropRejectMessage'],
                            children: getCommonActionSettings(['getDropRejectMessage']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'getPreviewIconAction',
                            label: 'Get Preview Icon',
                            keyPath: ['getPreviewIcon'],
                            children: getCommonActionSettings(['getPreviewIcon']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
