import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsRatingSettings } from './BuilderComponentsRating.settings';
export const BuilderComponentsRating = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRating,
    displayName: 'Rating',
    icon: 'rating',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Rating',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.number,
                    name: 'rating',
                    defaultValue: 1.0,
                },
            ],
            props: {
                defaultValue: 1.0,
                size: 'medium',
                disabled: false,
                highlightSelectedOnly: false,
                max: 5,
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.rating.setValue(value)',
                    },
                },
                precision: 1,
                readOnly: false,
                value: '{{ __STATES__.rating.value }}',
                color: 'inherit',
                style: getPredefinedStyles({ display: 'flex', minWidth: 24, minHeight: 24 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, emptyIcon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, defaultValue: { type: COMPONENT_DSL_PROP_TYPES.number }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, emptyLabelText: { type: COMPONENT_DSL_PROP_TYPES.string }, getLabelText: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'value',
                        path: [0],
                    },
                ],
            }, highlightSelectedOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, max: { type: COMPONENT_DSL_PROP_TYPES.number }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, onChangeActive: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, precision: { type: COMPONENT_DSL_PROP_TYPES.enum }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, value: { type: COMPONENT_DSL_PROP_TYPES.number } }),
    },
    settings: BuilderComponentsRatingSettings,
};
