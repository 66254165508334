import React, { useCallback } from 'react';

import {
  CloseOutlined as CloseOutlinedIcon,
  AddOutlined as AddOutlinedIcon,
} from '@mui/icons-material';
import { FilterOptionsState } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { isEmpty, remove, isNil } from 'ramda';

import { MetaTag } from '@builder/schemas';
import { DEFAULT_META_TAG_NAME } from '@builder/schemas/src/constants';
import { generateID } from '@builder/utils';

import { Autocomplete, CssGrid, TextField, AutocompleteOption } from 'src/shared/components';

import {
  DEFAULT_META_TAG_OPTIONS,
  DEFAULT_NEW_META_TAG,
  META_TAG_CHARSET_OPTIONS,
  UTF8_VALUE,
} from './MetaTagsEditor.constants';
import { AddMetaTagButton, DeleteMetaTagButton } from './MetaTagsEditor.styles';

const filterMetaTagNameOptions = createFilterOptions();

export type FormMetaTag = { name?: boolean };
export type FormMetaTagArray = Array<FormMetaTag> | undefined;

type MetaTagsEditorProps = {
  value?: MetaTag[];
  errors?: string | string[] | Partial<MetaTag | undefined>[];
  touched?: FormMetaTagArray;
  onChange: (
    value: string | MetaTag[] | MetaTag | undefined,
    field?: Array<string | number>,
  ) => void;
  handleFieldTouched: (value: boolean | FormMetaTagArray, field?: Array<string | number>) => void;
  'data-test'?: string;
};

export const MetaTagsEditor: React.FC<MetaTagsEditorProps> = ({
  value = [],
  errors,
  touched,
  onChange,
  handleFieldTouched,
  'data-test': dataTest,
}) => {
  const onChangeContent = useCallback(
    (newValue: string, index: number) => {
      onChange(newValue, [index, 'content']);
    },
    [onChange],
  );

  const onChangeName = useCallback(
    (newValue: string, index: number) => onChange(newValue, [index, 'name']),
    [onChange],
  );

  const handleChangeName = useCallback(
    (newValue: string, index: number) => {
      onChangeName(newValue, index);

      if (newValue === DEFAULT_META_TAG_NAME.charset) {
        onChangeContent(UTF8_VALUE, index);
      }
    },
    [onChangeContent, onChangeName],
  );

  const handleAddMetaTag = useCallback(
    () =>
      onChange(
        {
          id: generateID(),
          ...DEFAULT_NEW_META_TAG,
        },
        [value.length],
      ),
    [onChange, value],
  );

  const handleDeleteMetaTag = useCallback(
    (index: number) => {
      handleFieldTouched(
        isNil(touched) || isEmpty(touched) ? undefined : remove(index, 1, touched),
      );
      onChange(remove(index, 1, value));
    },
    [handleFieldTouched, onChange, touched, value],
  );

  const filterOptions = useCallback(
    (options: unknown[], params: FilterOptionsState<unknown>) => {
      const filtered = filterMetaTagNameOptions(options, params);
      const filteredWithoutExistingValues = filtered.filter(
        option => !value.some(({ name }) => (option as AutocompleteOption).value === name),
      );
      const { inputValue } = params;
      const isExisting = options.some(
        option =>
          inputValue === (option as AutocompleteOption).label ||
          inputValue === (option as AutocompleteOption).value ||
          value.some(({ name }) => name === inputValue),
      );

      // Suggest the creation of a new value
      if (inputValue !== '' && !isExisting) {
        filteredWithoutExistingValues.push({
          inputValue,
          label: `Add Custom: "${inputValue}"`,
        });
      }

      return filteredWithoutExistingValues;
    },
    [value],
  );

  return (
    <CssGrid gridRowGap={1.5}>
      <CssGrid gridRowGap={1.5}>
        {value.map((metaTag, index) => {
          const isMetaTagNameTouched = Boolean(touched?.[index]?.name);

          const metaTagNameErrorMessage = Array.isArray(errors)
            ? (errors?.[index] as Partial<MetaTag>)?.name
            : undefined;
          const showMetaTagNameError = Boolean(metaTagNameErrorMessage) && isMetaTagNameTouched;
          const helperText = showMetaTagNameError ? metaTagNameErrorMessage : undefined;

          return (
            <CssGrid
              key={metaTag.id}
              gridAutoFlow="column"
              gridTemplateColumns="206px 1fr 32px"
              gridColumnGap={1}
              gridRowGap={1.5}
            >
              <Autocomplete
                freeSolo
                clearOnBlur
                options={DEFAULT_META_TAG_OPTIONS}
                size="small"
                value={metaTag.name}
                onChange={newValue => handleChangeName(newValue, index)}
                filterOptions={filterOptions}
                error={showMetaTagNameError}
                helperText={helperText}
                onBlur={() => handleFieldTouched(true, [index, 'name'])}
                data-test={`${dataTest}.nameInput_${index}`}
              />
              <Choose>
                <When condition={metaTag.name === DEFAULT_META_TAG_NAME.charset}>
                  <Autocomplete
                    fullWidth
                    value={metaTag.content}
                    onChange={newValue => onChangeContent(newValue || '', index)}
                    options={META_TAG_CHARSET_OPTIONS}
                    disableClearable
                    sx={{
                      // In designs for some reasons only for case with Charset field
                      // we should prevent from stretching field to more than 206px
                      maxWidth: '206px',
                    }}
                    data-test={`${dataTest}.contentInput_${index}`}
                  />
                </When>
                <Otherwise>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={metaTag.content}
                    onChange={event => onChangeContent(event.target.value, index)}
                    data-test={`${dataTest}.contentInput_${index}`}
                  />
                </Otherwise>
              </Choose>

              <DeleteMetaTagButton
                variant="contained"
                onClick={() => handleDeleteMetaTag(index)}
                size="small"
                data-test={`${dataTest}.deleteButton_${index}`}
              >
                <CloseOutlinedIcon fontSize="medium" />
              </DeleteMetaTagButton>
            </CssGrid>
          );
        })}
      </CssGrid>
      <AddMetaTagButton
        data-test={`${dataTest}.addMetaTagButton`}
        startIcon={<AddOutlinedIcon />}
        onClick={handleAddMetaTag}
      >
        ADD META TAG
      </AddMetaTagButton>
    </CssGrid>
  );
};
