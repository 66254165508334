import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_14_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.14.4';
    }
    getChangeList() {
        return ['Removed non-existing states connected to node'];
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        const stateListDSL = appDSL.states;
        const migratedNodesListDSL = mapObjIndexed(nodeDSL => {
            var _a;
            const newStateConnections = (_a = nodeDSL.states) === null || _a === void 0 ? void 0 : _a.filter(({ stateID }) => Boolean(stateListDSL[stateID]));
            return Object.assign(Object.assign({}, nodeDSL), { states: newStateConnections });
        }, appDSL.nodes);
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: migratedNodesListDSL }) });
    }
}
export const migration_0_14_4 = new Migration_0_14_4();
