var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import styled from '@emotion/styled';
const ImageContainer = styled.div `
  display: block;
  width: fit-content;
  height: fit-content;
`;
const ImageBlock = styled.img `
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;
/**
 * @description Image wrapper over the original html <img>
 * The wrapper is needed because raw <img> might not resize properly
 * when src gets changed and <img>'s parent is page root
 */
export const Image = forwardRef((_a, ref) => {
    var { src, alt, id, className, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, lang, translate, title, style, css } = _a, imageProps = __rest(_a, ["src", "alt", "id", "className", 'data-test', 'data-node-id', 'data-node-render-path', "lang", "translate", "title", "style", "css"]);
    return (_jsx(ImageContainer, Object.assign({ id: id, style: style, css: css, className: className, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, lang: lang, translate: translate, title: title, ref: ref }, { children: _jsx(ImageBlock, Object.assign({ src: src, alt: alt }, imageProps)) })));
});
