import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getCommonPropertiesSections, getLooperSection, getStylesSection, getDivider, getActionSettings, BASIC_PROPS_TOOLTIP, getBasicPropsSettings, } from '../../common';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_PROPS_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['alignItems'],
                options: ['flex-start', 'center'].map(createSelectOptionByValue),
                label: 'Align Items',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                label: 'Tooltip Title',
                keyPath: ['tooltipTitle'],
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['tooltipPlacement'],
                options: [
                    'bottom-end',
                    'bottom-start',
                    'bottom',
                    'left-end',
                    'left-start',
                    'left',
                    'right-end',
                    'right-start',
                    'right',
                    'top-end',
                    'top-start',
                    'top',
                ].map(createSelectOptionByValue),
                label: 'Tooltip Placement',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['autoFocus'],
                label: 'Auto Focus',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['selected'],
                label: 'Selected',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['dense'],
                label: 'Dense',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['disabled'],
                label: 'Disabled',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['disableGutters'],
                label: 'Disable Gutters',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['divider'],
                label: 'Divider',
            },
        ],
    },
    getDivider(),
    ...getCommonPropertiesSections(),
    getDivider(),
    getLooperSection(),
    getDivider(),
    ...getBasicPropsSettings(),
];
export const getStyleSettings = (prefixPath = []) => [
    ...getStylesSection({ prefixPath }),
];
export const MaterialListItemButtonSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSettings(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
