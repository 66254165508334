import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getPropertiesSettings } from '../MaterialAlertTitle/MaterialAlertTitle.settings';
export const MaterialDialogTitleSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
};
