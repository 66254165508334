import { COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { RICH_TEXT_SETTINGS } from './BuilderRichText.settings';
export const BuilderRichText = {
    name: COMPONENT_DSL_NAMES.BuilderRichText,
    displayName: 'Editable Text',
    icon: 'formEditableText',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'RichText',
    },
    overlayOutlineWhenEmpty: true,
    schema: Object.assign(Object.assign({ presentations: [visibleByCondition], supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        } }, getSysMinSizeStyle({ minWidth: 16, minHeight: 16, display: null })), { predefineds: {
            props: {
                content: '<p>Text</p>',
                children: {
                    nodes: [{ name: COMPONENT_DSL_NAMES.Text, props: { children: 'Text' } }],
                    // TODO: predefined styles do not seem to work
                    style: { overflow: 'auto' },
                },
            },
        }, dndTargetPropName: 'children', props: Object.assign(Object.assign({}, commonComponentProps), { content: {
                type: COMPONENT_DSL_PROP_TYPES.string,
            }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [],
            } }) }),
    settings: RICH_TEXT_SETTINGS,
};
