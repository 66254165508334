import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonReactProps } from '../../common/commonProps';
export const Fragment = {
    name: COMPONENT_DSL_NAMES.Fragment,
    displayName: COMPONENT_DSL_NAMES.Fragment,
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: 'react',
        componentName: 'Fragment',
    },
    schema: {
        dndTargetPropName: 'children',
        accessibility: {
            hideBreadcrumb: true,
            disableDashboardSelect: true,
        },
        props: Object.assign(Object.assign({}, commonReactProps), { children: { type: COMPONENT_DSL_PROP_TYPES.reactNode } }),
    },
    settings: null,
};
