import { useState, useCallback, useEffect } from 'react';
export const useBooleanState = (defaultState = false) => {
    // CODE_ENGINE_USAGE_START
    const [booleanState, setBooleanState] = useState(defaultState);
    useEffect(() => {
        setBooleanState(defaultState);
    }, [defaultState]);
    const setBooleanStateToTrue = useCallback(() => {
        setBooleanState(true);
    }, []);
    const setBooleanStateToFalse = useCallback(() => {
        setBooleanState(false);
    }, []);
    const toggleBooleanState = useCallback(() => {
        setBooleanState(!booleanState);
    }, [booleanState]);
    return {
        value: booleanState,
        setValue: setBooleanState,
        setTrue: setBooleanStateToTrue,
        setFalse: setBooleanStateToFalse,
        toggle: toggleBooleanState,
    };
    // CODE_ENGINE_USAGE_END
};
