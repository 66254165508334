export const STATE = 'state';
export const REQUEST = 'request';
export const CODE = 'code';
export const ARRAY = 'array';
export const OBJECT = 'object';
export const QUERY = 'query';
export const VALUE = 'value';
export const DATA = 'data';
export const PLACEHOLDER_DATA_TYPE = 'Select data type';
export const PLACEHOLDER_SELECT_OPT = 'Type to search, or select';
export const NO_OPTIONS_TEXT = 'No Array entries available';
export const LABELS_SELECT = {
  request: 'Select Request',
  state: 'Select State',
};

export const OBJECT_OBJECT = '[object Object]';

export const INPUT_TYPES = [
  {
    value: STATE,
    label: 'From State',
  },
  {
    value: REQUEST,
    label: 'From Request',
  },
  {
    value: CODE,
    label: 'Enter Code',
  },
];
