import { StateRequestDSL } from '@builder/schemas';

const ITEM_VARIANT_QUERY = 'query';
const ITEM_VARIANT_LAZY_QUERY = 'lazyQuery';
const ITEM_VARIANT_MUTATION = 'mutation';
const ITEM_VARIANT_REST = 'rest';

export function filterTypeRequestOnNavigationEvents(item: StateRequestDSL): boolean | undefined {
  return (
    (item.variant as string) === ITEM_VARIANT_LAZY_QUERY ||
    ((item.variant as string) === ITEM_VARIANT_MUTATION && item.args.isTriggeredManually) ||
    ((item.variant as string) === ITEM_VARIANT_REST && item.args.isTriggeredManually)
  );
}

export function filterTypeRequest(item: StateRequestDSL): boolean | undefined {
  return (
    (item.variant as string) === ITEM_VARIANT_LAZY_QUERY ||
    (item.variant as string) === ITEM_VARIANT_QUERY ||
    ((item.variant as string) === ITEM_VARIANT_MUTATION && item.args.isTriggeredManually) ||
    ((item.variant as string) === ITEM_VARIANT_REST && item.args.isTriggeredManually)
  );
}

export const sortAlphabetically = <T,>(arr: T[], argument: keyof T): T[] =>
  arr.sort((a, b) => (a[argument] as string).localeCompare(b[argument] as string));
