import { DragElementWrapper, DragSourceOptions, useDrag } from 'react-dnd';

import { DRAGGABLES, ICON_ID_TAG, dragEnd, dragStart, DraggableIconItem } from '../logic';
import { useAppDispatch, useNodeListDSL } from 'src/providers/ReduxProvider';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

type UseDashIconDragArgs = { meta: DraggableIconItem['meta'] };
type UseDashIconDragResult = { isDragging: boolean; ref: DragElementWrapper<DragSourceOptions> };

/**
 * ICONS (left-panel)
 */
export const useDashIconDrag = ({ meta }: UseDashIconDragArgs): UseDashIconDragResult => {
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const { workspaceID } = useCurrentWorkspaceID();

  const [{ isDragging }, ref] = useDrag({
    type: DRAGGABLES.ICON,
    item: monitor => {
      dragStart(ICON_ID_TAG, send, meta.name)(monitor);
      return {
        type: DRAGGABLES.ICON,
        id: ICON_ID_TAG,
        meta,
        dndMutableMeta: null,
      };
    },
    end: dragEnd(send, nodeListDSL, workspaceID),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return { isDragging, ref };
};
