import styled from '@emotion/styled';
import { XYCoord } from 'react-dnd';

import {
  NodeDSL,
  ComponentDSL,
  NodeListDSL,
  componentSelectors,
  nodeListSelectors,
  COMPONENT_DSL_INSIDE_ONLY_TYPES,
} from '@builder/schemas';

export const PREVIEW_SIZE = 16;
export const PREVIEW_ICON_SIZE = 80;
export const PREVIEW_MAX_SIZE = 400;

type PreviewTransform = { transform?: string; display?: 'none' };

export const BasicPreview = styled.div<PreviewTransform>`
  width: ${PREVIEW_SIZE}px;
  height: ${PREVIEW_SIZE}px;
  border: 1px solid black;
  border-radius: 50%;
  transform: ${({ transform }) => transform};
  display: ${({ display }) => display};
`;

export const IconPreview = styled.div<PreviewTransform>`
  width: ${PREVIEW_ICON_SIZE}px;
  height: ${PREVIEW_ICON_SIZE}px;
  transform: ${({ transform }) => transform};
  display: ${({ display }) => display};
`;

export const getPreviewTransform = ({
  initialOffset,
  currentOffset,
  previewWidth,
  previewHeight,
  scaleDown = false,
}: {
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
  previewWidth: number;
  previewHeight: number;
  scaleDown?: boolean;
}): PreviewTransform => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const offsetWidth = previewWidth / 2;
  const offsetHeight = previewHeight / 2;

  let transform = `translate(${x - offsetWidth}px, ${y - offsetHeight}px)`;
  if (scaleDown) {
    const biggestSize = previewWidth >= previewHeight ? previewWidth : previewHeight;
    if (biggestSize > PREVIEW_MAX_SIZE) {
      const scaleFactor = (PREVIEW_MAX_SIZE / biggestSize).toFixed(1);
      transform = `${transform} scale(${scaleFactor})`;
    }
  }

  return {
    transform,
  };
};

export const getInvalidMessage = (
  nodeListDSL: NodeListDSL,
  {
    dragTargetDSL,
    dropTargetDSL,
    dropTargetNode,
  }: { dragTargetDSL?: ComponentDSL; dropTargetDSL?: ComponentDSL; dropTargetNode: NodeDSL },
): string | undefined => {
  if (!dragTargetDSL && !dropTargetNode) return;

  const rules = componentSelectors.getParentContraints(dragTargetDSL as ComponentDSL);

  for (const rule of rules) {
    if (
      rule.container.type === COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent &&
      !nodeListSelectors.canBeParentFor(null, {
        parentContraintsRule: rule,
        targetNode: dropTargetNode,
      })
    )
      return `'${dropTargetDSL?.displayName}' cannot be a parent for '${dragTargetDSL?.displayName}'`;

    if (
      rule.container.type === COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor &&
      !nodeListSelectors.canBeNestedParentFor(nodeListDSL, {
        parentContraintsRule: rule,
        targetNode: dropTargetNode,
      })
    )
      return `'${dragTargetDSL?.displayName}' cannot be nested in '${dropTargetDSL?.displayName}'`;
  }

  return `Cannot drop '${dragTargetDSL?.displayName}' into '${dropTargetDSL?.displayName}'`;
};
