import produce from 'immer';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import { EventOperation } from './types';
import { isEventValid } from './validate';
const isActionApplicable = (action, scope) => {
    return !scope || action.scope === 'global' || scope === action.scope;
};
export const applyEvents = (draft, options, ...events) => {
    const result = produce(draft, (initial) => {
        for (const event of events) {
            if (isEventValid(event)) {
                for (const action of event.payload) {
                    if (isActionApplicable(action, options.scope)) {
                        switch (action.operation) {
                            case EventOperation.create:
                                if (get(initial, action.data.key) === undefined) {
                                    set(initial, action.data.key, action.data.node);
                                }
                                break;
                            case EventOperation.update:
                                if (get(initial, action.data.key) !== undefined) {
                                    set(initial, action.data.key, action.data.node);
                                }
                                break;
                            case EventOperation.delete:
                                if (get(initial, action.data.key) !== undefined) {
                                    unset(initial, action.data.key);
                                }
                                break;
                            case EventOperation.arrayInsert:
                                if (Array.isArray(get(initial, action.data.key)) &&
                                    get(initial, action.data.key).length >= action.data.index &&
                                    action.data.index >= 0) {
                                    get(initial, action.data.key).splice(action.data.index, 0, action.data.item);
                                }
                                break;
                            case EventOperation.arrayDelete:
                                if (Array.isArray(get(initial, action.data.key)) &&
                                    get(initial, action.data.key).length > action.data.index &&
                                    action.data.index >= 0) {
                                    get(initial, action.data.key).splice(action.data.index, 1);
                                }
                                break;
                            case EventOperation.arrayReplace:
                                {
                                    const array = get(initial, action.data.key);
                                    if (Array.isArray(array) &&
                                        array.includes(action.data.item) &&
                                        array.length > action.data.index &&
                                        action.data.index >= 0) {
                                        array.splice(action.data.index, 1, action.data.newItem);
                                    }
                                }
                                break;
                        }
                    }
                }
            }
        }
    });
    return { result };
};
