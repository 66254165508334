import { PredefinedStateAccessor } from './PredefinedStateAccessor';
export class PredefinedHookAccessor extends PredefinedStateAccessor {
    constructor(importData, options) {
        super(importData);
        this.stateName = options.stateName;
        this.typeName = options.typeName;
    }
    hasTypes() {
        return Boolean(this.typeName);
    }
    getHookDeclarationName() {
        return this.importData.varNameAlias || this.importData.varName || '_';
    }
    getStateTypeName() {
        var _a;
        return (_a = this.typeName) !== null && _a !== void 0 ? _a : '';
    }
    getStateName() {
        return this.stateName;
    }
}
