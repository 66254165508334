import React, { useState } from 'react';

import styled from '@emotion/styled';
import { InputAdornment, Popover, PopoverOrigin } from '@mui/material';
import { SketchPicker, ColorResult } from 'react-color';

import { Button } from '../Button';
import { InputContainer, InputContainerProps } from '../common';
import { TextField } from '../TextField';

const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export type ColorPickerProps = InputContainerProps & {
  value?: string;
  onChange?: (value: string) => void;
  presetColors?: string[];
};

const StyledButton = styled(Button)<{ background?: string }>`
  text-transform: initial;
  font-weight: 600;
  min-width: ${({ theme }) => theme.layout.controls.colorPickerAdornment.width}px;
  width: ${({ theme }) => theme.layout.controls.colorPickerAdornment.width}px;
  height: ${({ theme }) => theme.layout.controls.colorPickerAdornment.height}px;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  background-color: ${({ background }) => background} !important;
`;

const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    box-shadow: ${({ theme }) => theme.palette.grey[900]};
  }
`;

export const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  value,
  onChange,
  presetColors = [],
  labelPlacement,
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
  enableFx,
  isFxEnabled,
  showFx = true,
  'data-test': dataTest,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const colorPickerOpened = Boolean(anchorEl);

  const openColorPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeColorPicker = () => {
    setAnchorEl(null);
  };

  const handleColorPickerChange = (color: ColorResult) => {
    onChange?.(color.hex);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.currentTarget.value);
  };

  return (
    <InputContainer
      label={label}
      labelPlacement={labelPlacement}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      enableFx={enableFx}
      showFx={showFx}
      isFxEnabled={isFxEnabled}
      data-test={dataTest}
    >
      <TextField
        variant="outlined"
        size="small"
        value={value}
        multiline={false}
        onChange={handleTextFieldChange}
        data-test={dataTest}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledButton
                variant="outlined"
                onClick={openColorPicker}
                background={value}
                data-test={`${dataTest}.pickerBtn`}
              />
            </InputAdornment>
          ),
        }}
      />

      <StyledPopover
        open={colorPickerOpened}
        anchorEl={anchorEl}
        onClose={closeColorPicker}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
      >
        <SketchPicker
          disableAlpha
          presetColors={presetColors}
          color={value}
          onChange={handleColorPickerChange}
        />
      </StyledPopover>
    </InputContainer>
  );
};
