export const FALLBACK_FONTS_OPTIONS = [
    {
        label: 'Serif',
        value: 'serif',
    },
    {
        label: 'Sans-serif',
        value: 'sans-serif',
    },
    {
        label: 'Monospace',
        value: 'monospace',
    },
    {
        label: 'Arial',
        value: 'arial',
    },
    {
        label: 'Arial Black',
        value: 'arial-black',
    },
    {
        label: 'Verdana',
        value: 'verdana',
    },
    {
        label: 'Times New Roman',
        value: 'times-new-roman',
    },
    {
        label: 'Didot',
        value: 'didot',
    },
    {
        label: 'Georgia',
        value: 'georgia',
    },
    {
        label: 'American Typewriter',
        value: 'American Typewriter',
    },
    {
        label: 'Courier',
        value: 'Courier',
    },
    {
        label: 'Monaco',
        value: 'Monaco',
    },
    {
        label: 'Bradley Hand',
        value: 'Bradley Hand',
    },
];
