import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialListItemTextSettings } from './MaterialListItemText.settings';
export const MaterialListItemText = {
    name: COMPONENT_DSL_NAMES.MaterialListItemText,
    displayName: 'List Item Text',
    icon: 'list',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'ListItemText',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { disableTypography: { type: COMPONENT_DSL_PROP_TYPES.boolean }, inset: { type: COMPONENT_DSL_PROP_TYPES.boolean }, primary: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, primaryTypographyProps: { type: COMPONENT_DSL_PROP_TYPES.object }, secondary: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, secondaryTypographyProps: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: MaterialListItemTextSettings,
};
