import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsFloatingActionButtonSettings } from './BuilderComponentsFloatingActionButton.settings';
export const BuilderComponentsFloatingActionButton = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
    displayName: 'Floating Button',
    icon: 'floatingActionButton',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Fab',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                color: 'primary',
                variant: 'circular',
                size: 'small',
                disabled: false,
                loading: false,
                type: 'button',
                icon: 'Add',
                children: {
                    nodes: [],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, loading: { type: COMPONENT_DSL_PROP_TYPES.boolean }, loadingIndicator: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, loadingPosition: { type: COMPONENT_DSL_PROP_TYPES.enum }, disableElevation: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFocusRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, href: { type: COMPONENT_DSL_PROP_TYPES.string }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, sx: { type: COMPONENT_DSL_PROP_TYPES.string }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, target: { type: COMPONENT_DSL_PROP_TYPES.enum }, type: { type: COMPONENT_DSL_PROP_TYPES.string }, icon: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsFloatingActionButtonSettings,
};
