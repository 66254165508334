import { nodeListSelectors } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_19_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.1';
    }
    getChangeList() {
        return ['Removing wrong states references from route nodes'];
    }
    migrateAppDSL(appDSL) {
        const routeNodes = nodeListSelectors.getAllRouteNodes(appDSL.nodes);
        const routeNodesWithRightStates = routeNodes === null || routeNodes === void 0 ? void 0 : routeNodes.reduce((acc, current) => {
            var _a;
            const contextStates = Object.keys(current.context || {});
            const newStateList = ((_a = current.states) === null || _a === void 0 ? void 0 : _a.filter(state => contextStates.includes(state.stateID))) || [];
            const newRouteNode = Object.assign(Object.assign({}, current), { states: newStateList });
            return Object.assign(Object.assign({}, acc), { [newRouteNode.id]: newRouteNode });
        }, {});
        return Object.assign(Object.assign({}, appDSL), { nodes: Object.assign(Object.assign({}, appDSL.nodes), routeNodesWithRightStates) });
    }
}
export const migration_0_19_1 = new Migration_0_19_1();
