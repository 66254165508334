import { NodeDSL } from '@builder/schemas';

export type NodeSettingCommonProps = {
  sectionPath: Array<string | number>;
  prefixKeyPath?: Array<string | number>;
  selectedNodeDSL?: NodeDSL;
};
export type AutoCompleteOptions = {
  value: string;
  label: string;
};

export const PAGE_LABEL = 'Page';
