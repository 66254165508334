import React, { useCallback, useState } from 'react';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { componentListSelectors, NodeDSL, nodeListSelectors } from '@builder/schemas';
import { isArray, mustBeCorrectLabelName } from '@builder/utils';

import { useIsSelectedNodeBelongToLayout } from '../../../../dashboard/overlay/hooks';
import {
  useAppDispatch,
  useComponentListDSL,
  useNodeListDSL,
  useIsSelectedNodeCreated,
  useUIBuilderEditMode,
} from 'src/providers/ReduxProvider';
import { useTheme } from 'src/providers/ThemeProvider';
import {
  Icon,
  NodeActionsPopper,
  NodeCreateGroupDialog,
  NodeCSSAsGlobalDialog,
} from 'src/shared/components';
import { BackButton } from 'src/shared/components/common/BackButton';
import { InlineTextField } from 'src/shared/components/InlineTextField';
import { useNodeActions, useNodeActionsInfo, useVisiblePresentation } from 'src/shared/hooks';
import { DASHBOARD_EVENTS, UI_BUILDER_EDIT_MODES } from 'src/store';

import {
  AliasEditorContainer,
  NodeSettingsTitleContainer,
  StyledButton,
  StyledHelperText,
} from './NodeSettingsTitle.styles';

export const NodeSettingsTitle: React.FC<{
  nodeDSL: NodeDSL | NodeDSL[];
  onChangeAlias: (value: string) => void;
}> = ({ nodeDSL, onChangeAlias }) => {
  const isMultiSelected = isArray(nodeDSL);
  const selectedNodeIds = isMultiSelected ? nodeDSL.map(node => node.id) : nodeDSL.id;
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();
  const theme = useTheme();
  const firstNode = isMultiSelected ? nodeDSL[0] : nodeDSL;
  const {
    isVisible,
    canVisiblePresentationBeApplied,
    toggleVisiblePresentation,
  } = useVisiblePresentation({ nodeID: isMultiSelected ? firstNode.id : nodeDSL.id }); // TODO: Refactor this logic for multiple components selection
  const isSelectedNodeCreated = useIsSelectedNodeCreated();
  const mainProp = componentListDSL[firstNode.name]?.schema?.mainPropPath?.mainProp;
  const editFirst = isSelectedNodeCreated && (mainProp === 'alias' || !mainProp);

  const selectedNodeDisplayName = componentListSelectors.getDisplayName(componentListDSL, {
    componentName: firstNode.name,
  });
  const [aliasName, setAliasName] = useState<string | undefined>(
    firstNode.alias || selectedNodeDisplayName,
  );

  const isAliasNameUnique = nodeListSelectors.isNodeAliasUnique(nodeListDSL, {
    nodeID: firstNode.id,
    alias: aliasName?.trim(),
  });
  const invalidNameError =
    aliasName && (mustBeCorrectLabelName(aliasName.replace(/ /g, '')) as string);
  const isInvalidName = !isAliasNameUnique || !!invalidNameError;
  const uniqueError = isAliasNameUnique ? undefined : 'Alias already exist';

  const navigateToComponentsList = useCallback(() => {
    send({
      type: DASHBOARD_EVENTS.componentSelectedRemove,
    });
  }, [send]);

  const isComponentDeprecated = componentListSelectors.isDeprecated(componentListDSL, {
    componentName: firstNode.name,
  });

  const {
    deleteNode,
    createSymbolToBuffer,
    copyComponent,
    copyStyle,
    pasteStyle,
    copyComponentToBuffer,
    applyBufferToNode,
    openAsRoot,
    backToView,
    saveAsGlobalCSS,
  } = useNodeActions(selectedNodeIds);

  const { showActions } = useNodeActionsInfo({
    nodeID: firstNode.id,
    nodeName: firstNode.name,
    deleteNode,
    createSymbolToBuffer,
    copyComponent,
    copyStyle,
    pasteStyle,
    copyComponentToBuffer,
    applyBufferToNode,
    openAsRoot,
    backToView,
  });

  const editMode = useUIBuilderEditMode();
  const isLayoutNode = useIsSelectedNodeBelongToLayout();
  const isActionsAvailable =
    editMode === UI_BUILDER_EDIT_MODES.layout || (showActions && !isLayoutNode);

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [showCSSAsGlobalDialog, setShowCSSAsGlobalDialog] = useState<boolean>(false);
  const isMenuOpen = Boolean(menuAnchorEl);

  const toggleMenu = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();

    if (menuAnchorEl || !event) {
      return setMenuAnchorEl(null);
    }

    setMenuAnchorEl(event.currentTarget);
  };

  const changeAlias = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAliasName(event.currentTarget.value);
  }, []);

  const applyAlias = useCallback(() => {
    if (isInvalidName) {
      return;
    }

    const finalAlias = aliasName?.trim();

    if (!finalAlias) {
      const previousNodeAlias = (firstNode.alias || selectedNodeDisplayName).trim();
      setAliasName(previousNodeAlias);
      onChangeAlias(previousNodeAlias);
    } else {
      setAliasName(finalAlias);
      onChangeAlias(finalAlias);
    }
  }, [isInvalidName, aliasName, firstNode.alias, selectedNodeDisplayName, onChangeAlias]);

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const onCreateSymbolToBufferHandler = (event: React.SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    setShowCreateDialog(true);
  };

  const onCloseCreateSymbolHandler = (event: React.SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    setShowCreateDialog(false);
  };

  const onCloseCSSAsGlobalDialog = (_event: React.SyntheticEvent<Element, Event>) => {
    setShowCSSAsGlobalDialog(false);
    closeMenu();
  };

  const onCreateCSSAsGlobal = (event: React.SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    setShowCSSAsGlobalDialog(true);
  };

  const HelperTextWrapper = (
    <StyledHelperText>
      <If condition={invalidNameError}>{invalidNameError}</If>
      <If condition={uniqueError}>{uniqueError}</If>
    </StyledHelperText>
  );

  return (
    <NodeSettingsTitleContainer>
      <BackButton
        onClick={navigateToComponentsList}
        data-test="rightPanel.nodeSettings.navigateToComponentsListBtn"
      />
      <AliasEditorContainer>
        <InlineTextField
          id={`alias-input-${aliasName}`}
          value={isMultiSelected ? nodeDSL.map(node => `${node?.alias}`).toString() : aliasName}
          title={isMultiSelected ? nodeDSL.map(node => `${node?.alias}`).toString() : aliasName}
          error={isInvalidName}
          helperText={HelperTextWrapper}
          autoFocus={editFirst}
          editFirst={editFirst}
          multiline={false}
          onChange={changeAlias}
          onBlur={applyAlias}
          data-test="propSettings.nodeAlias"
          disabled={!isActionsAvailable || isMultiSelected}
        />
        <div>
          <If condition={isAliasNameUnique}>
            <Choose>
              <When condition={isComponentDeprecated}>
                <Typography color="error" lineHeight="inherit">
                  @deprecated(
                  {isMultiSelected ? nodeDSL.map(node => `${node?.alias}`).toString() : aliasName})
                </Typography>
              </When>
              <Otherwise>
                <Typography noWrap color="text.secondary" lineHeight="inherit">
                  {isMultiSelected ? nodeDSL.map(node => `${node?.alias}`).toString() : aliasName}
                </Typography>
              </Otherwise>
            </Choose>
          </If>
        </div>
      </AliasEditorContainer>
      <If condition={canVisiblePresentationBeApplied && !isMultiSelected}>
        <StyledButton
          size="small"
          onClick={toggleVisiblePresentation}
          data-test={
            isVisible
              ? 'rightPanel.nodeSettings.visibleOnIcon'
              : 'rightPanel.nodeSettings.visibleOffIcon'
          }
        >
          <Icon
            name={isVisible ? 'visibilityOn' : 'visibilityOff'}
            fill={isVisible ? theme.palette.text.primary : theme.palette.text.secondary}
          />
        </StyledButton>
      </If>
      <If condition={isActionsAvailable}>
        <>
          <StyledButton
            size="small"
            data-test="rightPanel.nodeActions.menuBtn"
            onClick={toggleMenu}
          >
            <MoreVertIcon fontSize="inherit" />
          </StyledButton>
          <NodeCreateGroupDialog
            onClose={onCloseCreateSymbolHandler}
            isOpen={showCreateDialog}
            onCreateGroup={createSymbolToBuffer}
          />
          <NodeCSSAsGlobalDialog
            isOpen={showCSSAsGlobalDialog}
            onClose={onCloseCSSAsGlobalDialog}
            onSaveCSS={saveAsGlobalCSS}
          />
          <NodeActionsPopper
            componentName={firstNode.name}
            nodeID={firstNode.id}
            open={isMenuOpen}
            onClose={closeMenu}
            anchorEl={menuAnchorEl}
            onCopyStyle={copyStyle}
            onPasteStyle={pasteStyle}
            toggleMenu={toggleMenu}
            onCreateSymbol={onCreateSymbolToBufferHandler}
            onDelete={deleteNode}
            onCopy={copyComponent}
            onCopyToBuffer={copyComponentToBuffer}
            onApplyBuffer={applyBufferToNode}
            onOpenAsRoot={openAsRoot}
            onBackToView={backToView}
            onSaveStylesAsGlobalCSS={onCreateCSSAsGlobal}
          />
        </>
      </If>
    </NodeSettingsTitleContainer>
  );
};
