import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsDatePickerSettings } from './BuilderComponentsDatePicker.settings';
export const BuilderComponentsDatePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsDatePicker,
    displayName: 'Date Picker',
    icon: 'datePicker',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'DatePicker',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'datePicker',
                    defaultValue: '',
                },
            ],
            props: {
                animateYearScrolling: false,
                autoOk: false,
                disableFuture: false,
                disablePast: false,
                disableToolbar: false,
                emptyLabel: '',
                invalidLabel: '',
                maskChar: '_',
                strictCompareDates: false,
                inputVariant: 'outlined',
                label: 'Date Picker',
                value: '{{ __STATES__.datePicker.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.datePicker.setValue(value)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, animateYearScrolling: { type: COMPONENT_DSL_PROP_TYPES.boolean }, autoOk: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFuture: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disablePast: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableToolbar: { type: COMPONENT_DSL_PROP_TYPES.boolean }, emptyLabel: { type: COMPONENT_DSL_PROP_TYPES.string }, inputFormat: { type: COMPONENT_DSL_PROP_TYPES.string }, initialFocusedDate: { type: COMPONENT_DSL_PROP_TYPES.string }, InputAdornmentProps: { type: COMPONENT_DSL_PROP_TYPES.object }, inputValue: { type: COMPONENT_DSL_PROP_TYPES.string }, inputVariant: { type: COMPONENT_DSL_PROP_TYPES.enum }, invalidLabel: { type: COMPONENT_DSL_PROP_TYPES.string }, KeyboardButtonProps: { type: COMPONENT_DSL_PROP_TYPES.object }, keyboardIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, label: { type: COMPONENT_DSL_PROP_TYPES.string }, labelFunc: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                    {
                        name: 'invalidLabel',
                        path: [1],
                    },
                ],
            }, leftArrowButtonProps: { type: COMPONENT_DSL_PROP_TYPES.object }, leftArrowIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, loadingIndicator: { type: COMPONENT_DSL_PROP_TYPES.elementType }, mask: { type: COMPONENT_DSL_PROP_TYPES.string }, maskChar: { type: COMPONENT_DSL_PROP_TYPES.string }, maxDate: { type: COMPONENT_DSL_PROP_TYPES.string }, minDate: { type: COMPONENT_DSL_PROP_TYPES.string }, onAccept: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                ],
            }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'error',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, onMonthChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                ],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onYearChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                ],
            }, openTo: { type: COMPONENT_DSL_PROP_TYPES.enum }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, PopoverProps: { type: COMPONENT_DSL_PROP_TYPES.object }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, 
            // RegExp
            refuse: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, renderDay: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'day',
                        path: [0],
                    },
                    {
                        name: 'selectedDate',
                        path: [1],
                    },
                    {
                        name: 'dayInCurrentMonth',
                        path: [2],
                    },
                    {
                        name: 'dayComponent',
                        path: [2],
                    },
                ],
            }, rifmFormatter: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'str',
                        path: [0],
                    },
                ],
            }, rightArrowButtonProps: { type: COMPONENT_DSL_PROP_TYPES.object }, rightArrowIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, shouldDisableDate: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'day',
                        path: [0],
                    },
                ],
            }, strictCompareDates: { type: COMPONENT_DSL_PROP_TYPES.boolean }, TextFieldComponent: { type: COMPONENT_DSL_PROP_TYPES.component }, ToolbarComponent: { type: COMPONENT_DSL_PROP_TYPES.component }, views: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.any,
                },
            } }),
    },
    settings: BuilderComponentsDatePickerSettings,
};
