import React, { useMemo, createContext, useContext } from 'react';

import {
  NodeDSL,
  AppDSL,
  NodeID,
  ComponentDSLNameTypes,
  JSInjection,
  IteratorDSL,
  NodeStateConnectionDSL,
  ComponentSettingNodeItemPredefinedsDSL,
  ComponentSettingDSL,
} from '@builder/schemas';
import { memo } from '@builder/utils';

type NodeSettingsProviderContext = {
  selectedNodeDSL: NodeDSL;
  nodeStyle: Record<string, unknown> | null;
  appDSL: AppDSL;
  onCreateNode: (nodeData: {
    targetNodeID: NodeID;
    targetPropName: Array<string | number>;
    newComponentName: ComponentDSLNameTypes;
    predefineds?: ComponentSettingNodeItemPredefinedsDSL;
  }) => void;
  onUpdateAllowedRoles: (allowedRoles: string[], nodeID: NodeID) => void;
  onUpdateCondition: (conditionValue: JSInjection, nodeID: NodeID) => void;
  onUpdateIterator: (iterator: IteratorDSL, nodeID: NodeID) => void;
  onUpdateSchemaOverride: (
    schemaOverrideData: { keyValue: unknown; keyPath: Array<string | number> },
    nodeID: NodeID,
  ) => void;
  onDeleteNode: (nodeData: { nodeID: NodeID }) => void;
  onUpdateProp: (
    propData: { keyValue: unknown; keyPath: Array<string | number> },
    nodeID: NodeID,
    settingLabel: string,
    setting: ComponentSettingDSL,
  ) => void;
  onUpdatePropMany: (
    propDataArray: {
      keyValue: unknown;
      keyPath: Array<string | number>;
    }[],
    nodeID: NodeID,
  ) => void;
  onUpdateLocalStatesConnections: (states: NodeStateConnectionDSL[], nodeID: NodeID) => void;
  onUpdateNodeText: (
    propData: { text: string; keyPath: Array<string | number> },
    nodeID: NodeID,
  ) => void;
};

export const NodeSettingsContext = createContext<NodeSettingsProviderContext | null>(null);

export const NodeSettingsProvider = memo(
  'NodeSettingsProvider',
  ({
    children,
    selectedNodeDSL,
    nodeStyle,
    appDSL,
    onCreateNode,
    onDeleteNode,
    onUpdateProp,
    onUpdatePropMany,
    onUpdateNodeText,
    onUpdateLocalStatesConnections,
    onUpdateCondition,
    onUpdateAllowedRoles,
    onUpdateIterator,
    onUpdateSchemaOverride,
  }: NodeSettingsProviderContext & { children: React.ReactNode }): JSX.Element => {
    const settingsProps = useMemo(
      () => ({
        selectedNodeDSL,
        nodeStyle,
        appDSL,
        onCreateNode,
        onDeleteNode,
        onUpdateProp,
        onUpdatePropMany,
        onUpdateLocalStatesConnections,
        onUpdateNodeText,
        onUpdateCondition,
        onUpdateAllowedRoles,
        onUpdateIterator,
        onUpdateSchemaOverride,
      }),
      [
        selectedNodeDSL,
        nodeStyle,
        appDSL,
        onCreateNode,
        onDeleteNode,
        onUpdateCondition,
        onUpdateAllowedRoles,
        onUpdateIterator,
        onUpdateLocalStatesConnections,
        onUpdateNodeText,
        onUpdateProp,
        onUpdatePropMany,
        onUpdateSchemaOverride,
      ],
    );

    return (
      <NodeSettingsContext.Provider value={settingsProps}>{children}</NodeSettingsContext.Provider>
    );
  },
);

export const useNodeSettingsProps = (): NodeSettingsProviderContext => {
  const nodeSettingsProps = useContext(NodeSettingsContext);

  return (nodeSettingsProps || {}) as NodeSettingsProviderContext;
};
