import { values } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { NS_ID, getBasicPropsSettings } from '../../common';
import { pickSettings } from '../../common/helpers';
import { getDivider, getEventsSection, getStyleSections } from '../../common/settings';
export const MaterialCardContentSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [...getBasicPropsSettings([], true), getDivider()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: pickSettings([
                [NS_ID.style.spacing.root],
                [NS_ID.style.display.root],
                [NS_ID.style.flexChild.root],
                [NS_ID.style.gridChild.root],
                [NS_ID.style.size.root],
                [NS_ID.style.background.root],
                [NS_ID.style.border.root],
                [NS_ID.style.css.root],
            ])(getStyleSections()),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonMaterialCardContentSettings = () => pickSettings([[NS_ID.style.root, NS_ID.style.spacing.root, NS_ID.style.spacing.editor]], {
    withStructure: false,
})(values(MaterialCardContentSettings).flat());
