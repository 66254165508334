import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { TextSettings } from './Text.settings';
export const Text = {
    name: COMPONENT_DSL_NAMES.Text,
    displayName: COMPONENT_DSL_NAMES.Text,
    icon: 'text',
    type: COMPONENT_DSL_TYPES.textComponent,
    schema: {
        accessibility: {
            hideOverlay: true,
        },
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                children: '',
            },
        },
        props: {
            children: { type: COMPONENT_DSL_PROP_TYPES.string },
        },
    },
    settings: TextSettings,
};
