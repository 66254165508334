import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings, getCommonActionSettings, } from '../../common';
import { getDivider, getPropertySections, getStyleSections } from '../../common/settings';
const prefixPath = [];
export const HTML_FRAGMENT_SETTINGS = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.htmlFragment,
                            keyPath: ['htmlFragment'],
                            label: 'Content',
                        },
                        ...getBasicPropsSettings(),
                    ],
                },
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onClickAction',
                            label: 'On Click',
                            keyPath: [...prefixPath, 'onClick'],
                            children: getCommonActionSettings([...prefixPath, 'onClick']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMouseOverAction',
                            label: 'On Mouse Over',
                            keyPath: [...prefixPath, 'onMouseOver'],
                            children: getCommonActionSettings([...prefixPath, 'onMouseOver']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMouseOutAction',
                            label: 'On Mouse Out',
                            keyPath: [...prefixPath, 'onMouseOut'],
                            children: getCommonActionSettings([...prefixPath, 'onMouseOut']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMouseDownAction',
                            label: 'On Mouse Down',
                            keyPath: [...prefixPath, 'onMouseDown'],
                            children: getCommonActionSettings([...prefixPath, 'onMouseDown']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMouseUpAction',
                            label: 'On Mouse Up',
                            keyPath: [...prefixPath, 'onMouseUp'],
                            children: getCommonActionSettings([...prefixPath, 'onMouseUp']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMouseEnterAction',
                            label: 'On Mouse Enter',
                            keyPath: [...prefixPath, 'onMouseEnter'],
                            children: getCommonActionSettings([...prefixPath, 'onMouseEnter']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMouseLeaveAction',
                            label: 'On Mouse Leave',
                            keyPath: [...prefixPath, 'onMouseLeave'],
                            children: getCommonActionSettings([...prefixPath, 'onMouseLeave']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onWheelAction',
                            label: 'Mouse Wheel',
                            keyPath: [...prefixPath, 'onWheel'],
                            children: getCommonActionSettings([...prefixPath, 'onWheel']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onContextMenuAction',
                            label: 'Context',
                            keyPath: [...prefixPath, 'onContextMenu'],
                            children: getCommonActionSettings([...prefixPath, 'onContextMenu']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onAuxClickAction',
                            label: 'Aux Click',
                            keyPath: [...prefixPath, 'onAuxClick'],
                            children: getCommonActionSettings([...prefixPath, 'onAuxClick']),
                        },
                    ],
                },
            ],
        },
    ],
};
