var _a, _b, _c;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsButton } from '../BuilderComponentsButton';
import { MaterialButtonGroupSettings } from './MaterialButtonGroup.settings';
export const MaterialButtonGroup = {
    name: COMPONENT_DSL_NAMES.MaterialButtonGroup,
    displayName: 'Button Group',
    icon: 'buttonGroup',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@mui/material',
        componentName: 'ButtonGroup',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        predefineds: {
            states: [],
            props: {
                row: false,
                size: 'medium',
                orientation: 'horizontal',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsButton.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { value: 'value1', size: '' }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                            props: Object.assign(Object.assign({}, (_b = BuilderComponentsButton.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { value: 'value2', size: '' }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                            props: Object.assign(Object.assign({}, (_c = BuilderComponentsButton.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { value: 'value3', size: '' }),
                        },
                    ],
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, sx: { type: COMPONENT_DSL_PROP_TYPES.string }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, exclusive: { type: COMPONENT_DSL_PROP_TYPES.boolean }, classes: { type: COMPONENT_DSL_PROP_TYPES.object }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsButton],
            } }),
    },
    settings: MaterialButtonGroupSettings,
};
