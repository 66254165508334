import _ from 'lodash';

import { ComponentListDSL, NodeDSL, nodeSelectors } from '@builder/schemas';

import { COMPONENTS_WITH_CHILDREN_NODE_SETTINGS } from '../constants';

/**
 * * If you need to add new components to this logic just add the name to the COMPONENTS_WITH_CHILDREN_NODE_SETTINGS constant array
 *
 * Returns the list of selected ids
 * This is useful because if we have multiselected group of checkboxes or radio buttons
 * we need to send the id of the label instead of the checkbox/radio group id to the reducer
 */
export const getVerifiedNodeIDs = (
  selectedNodesDSL: NodeDSL[],
  componentListDSL: ComponentListDSL,
): string[] => {
  const UnverifiedNodeDSLs = COMPONENTS_WITH_CHILDREN_NODE_SETTINGS.map(componentName =>
    selectedNodesDSL.filter(nodeDSL => nodeDSL.name === componentName),
  ).flat();

  const verifiedSelectedIds = getImmediateChildrenFromComponent(
    UnverifiedNodeDSLs,
    componentListDSL,
  );

  const restOfNodeIds = _.without(
    selectedNodesDSL.map(x => x.id),
    ...[...UnverifiedNodeDSLs].map(x => x.id),
  );

  return [...verifiedSelectedIds, ...restOfNodeIds];
};

function getImmediateChildrenFromComponent(
  nodeDSLs: NodeDSL[],
  componentListDSL: ComponentListDSL,
) {
  const IDs = nodeDSLs.reduce((prev: string[], currentDSL) => {
    const childrenIDs = nodeSelectors.getAllImmediateChildrenIDs(currentDSL, {
      componentListDSL,
    });

    return childrenIDs ? [...childrenIDs, ...prev] : [...prev];
  }, []);

  return IDs;
}
