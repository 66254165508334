import React from 'react';

import { InputLabel as MuiInputLabel, InputLabelProps } from '@mui/material';

export const InputLabel: React.FC<InputLabelProps> = ({ title, ...rest }) => {
  return (
    <MuiInputLabel title={title} {...rest}>
      {title}
    </MuiInputLabel>
  );
};
