import React from 'react';

import styled from '@emotion/styled';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';

export const StyledTooltipIconButton = styled(IconButton)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const ToolTipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2.5)} ${({ theme }) => theme.spacing(2)};
  max-width: 220px;
`;

const TIMEPICKER_TOOLTIP_DATA = [
  {
    title: 'Max-Min Time Format',
    description: 'Specifies the min or max time. The following formats are available:',
  },
  { title: 'HH:mm:ss' },
  { title: 'HH:mm' },
  { title: 'HH' },
];

export const TimePickerTooltip: React.FC = () => {
  return (
    <Tooltip
      disableInteractive
      arrow
      placement="top-start"
      title={
        <ToolTipContent>
          {TIMEPICKER_TOOLTIP_DATA.map(oneEntity => (
            <div key={oneEntity.title}>
              <Typography fontWeight={600} variant="body1">
                {oneEntity.title}
              </Typography>
              <Typography variant="body1">{oneEntity.description}</Typography>
            </div>
          ))}
        </ToolTipContent>
      }
    >
      <StyledTooltipIconButton>
        <HelpOutlineOutlinedIcon fontSize="inherit" />
      </StyledTooltipIconButton>
    </Tooltip>
  );
};
