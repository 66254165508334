import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
export const MaterialCard = {
    name: COMPONENT_DSL_NAMES.MaterialCard,
    displayName: 'Card',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'Card',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                raised: true,
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { raised: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: null,
};
