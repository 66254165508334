import styled from '@emotion/styled';

type CssGridProps = {
  gap?: number;
  gridColumnGap?: number;
  gridRowGap?: number;
  gridTemplateColumns?: React.CSSProperties['gridTemplateColumns'];
  gridTemplateRows?: React.CSSProperties['gridTemplateRows'];
  gridTemplateAreas?: React.CSSProperties['gridTemplateAreas'];
  gridAutoFlow?: React.CSSProperties['gridAutoFlow'];
  justifyContent?: React.CSSProperties['justifyContent'];
  justifyItems?: React.CSSProperties['justifyItems'];
  alignItems?: React.CSSProperties['alignItems'];
  flexDirection?: React.CSSProperties['flexDirection'];
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  overFlow?: boolean;
};

export const CssGrid = styled.div<CssGridProps>`
  display: grid;

  ${props =>
    props.gap &&
    `
    grid-gap: ${props.theme.spacing(props.gap)};
  `}

  ${props =>
    props.gridColumnGap &&
    `
    grid-column-gap: ${props.theme.spacing(props.gridColumnGap)};
  `}

  ${props =>
    props.gridRowGap &&
    `
    grid-row-gap: ${props.theme.spacing(props.gridRowGap)};
  `}

  ${props =>
    props.gridTemplateColumns &&
    `
    grid-template-columns: ${props.gridTemplateColumns};
  `}

  ${props =>
    props.gridTemplateRows &&
    `
    grid-template-rows: ${props.gridTemplateRows};
  `}

  ${props =>
    props.gridTemplateAreas &&
    `
    grid-template-areas: ${props.gridTemplateAreas};
  `}

  ${props =>
    props.width &&
    `
    width: ${props.width};
    `}

  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth};
    `}

    ${props =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight};
    `}

  ${props =>
    props.height &&
    `
    height: ${props.height};
    `}

    ${props =>
    props.gridAutoFlow &&
    `
    grid-auto-flow: ${props.gridAutoFlow};
    `}

     ${props =>
    props.justifyContent &&
    `
    justify-content: ${props.justifyContent};
    `}

${props =>
    props.justifyItems &&
    `
    justify-items: ${props.justifyItems};
    `}

    ${props =>
    props.alignItems &&
    `
    align-items: ${props.alignItems};
    `}

     ${props =>
    props.overFlow &&
    `
    overflow: auto;
    `}
`;
