import { NodeDSL, nodeListSelectors } from '@builder/schemas';

import { isValidPathWithSchemaCheck } from '../shared/canDropOn';
import { DRAGGABLES } from '../shared/constants';
import { BuildDndSpecArgs } from '../shared/types';
import { MOVE_VARIANT, ComponentMoveMeta, DND_TARGET_TYPES } from 'src/store';

export const moveRelativelyCaseDetails = ({
  componentListDSL,
  nodeListDSL,
  item,
  targetNodeDSL,
  type,
}: {
  targetNodeDSL: NodeDSL;
  type: typeof MOVE_VARIANT.before | typeof MOVE_VARIANT.after;
} & Pick<BuildDndSpecArgs, 'componentListDSL' | 'nodeListDSL' | 'item'>): ComponentMoveMeta => {
  // if we are dropping relatively to the target
  const moveMeta: ComponentMoveMeta = {
    type,
    kind: 'invalid',
    sourceID: item.id,
    target: {
      type: DND_TARGET_TYPES.relative,
      nodeID: targetNodeDSL.id,
      propName: ['children'],
    },
  };

  // let's check if it's parent is available
  const targetParentNode = nodeListSelectors.getParentNodeDSL(nodeListDSL, {
    nodeID: targetNodeDSL.id,
  });

  if (targetParentNode) {
    const targetParentComponent = componentListDSL[targetParentNode.name];
    const targetParentPropName = targetParentComponent.schema.dndTargetPropName
      ? [targetParentComponent.schema.dndTargetPropName]
      : null;

    const targetParentAvailable = isValidPathWithSchemaCheck({
      nodeListDSL,
      componentListDSL,
      itemID: item.id,
      itemName: item.type === DRAGGABLES.ICON ? item.meta.name : item.name,
      intoNode: targetParentNode,
      intoTargetPropName: targetParentPropName,
    });

    // let's update availability of the target
    moveMeta.kind = targetParentAvailable ? 'valid' : 'invalid';
  }

  return moveMeta;
};
