import React, { useState, useMemo, useCallback } from 'react';

import { isNil } from 'ramda';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { getSupportedMuiColor } from '@builder/utils';

import { useTheme } from 'src/providers/ThemeProvider';
import { ThemeColorPicker } from 'src/shared/components/ThemeColorPicker';
import { Color } from 'src/shared/components/ThemeColorPicker/types';
import { getColorString } from 'src/shared/components/ThemeColorPicker/utils/colors';

import {
  ANCHOR_ORIGIN,
  LabelPlacementTypes,
  LabelSizeTypes,
  LABEL_PLACEMENT,
  LABEL_SIZE,
  TRANSFORM_ORIGIN,
} from './ThemeColorPickerInput.constants';
import {
  ColorLabel,
  ColorValue,
  IconContainer,
  StyledButton,
  StyledPopover,
} from './ThemeColorPickerInput.styles';

export type ThemeColorPickerProps = {
  label?: string;
  labelPlacement?: LabelPlacementTypes;
  labelSize?: LabelSizeTypes;
  cssVariablesObject: Record<string, string>;
  currentColorPropertyName: string;
  fontColorPropertyName?: string;
  fontDefaultColor?: string;
  onChange: (data: { value: string | undefined; propertyName: string }) => void;
  labelIcon?: React.ReactNode;
  'data-test'?: string;
};

export const ThemeColorPickerInput: React.FC<ThemeColorPickerProps> = ({
  label,
  labelSize = LABEL_SIZE.medium,
  labelPlacement = LABEL_PLACEMENT.top,
  cssVariablesObject,
  currentColorPropertyName,
  fontColorPropertyName,
  fontDefaultColor = '#202020',
  onChange,
  labelIcon,
  'data-test': dataTest,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [parentColor, setParentColor] = useState<Color | null>(null);
  const colorPickerOpened = Boolean(anchorEl);

  const currentColor = useMemo(() => cssVariablesObject[currentColorPropertyName], [
    cssVariablesObject,
    currentColorPropertyName,
  ]);
  const fontColor = useMemo(() => cssVariablesObject[fontColorPropertyName || ''], [
    cssVariablesObject,
    fontColorPropertyName,
  ]);
  const supportedCurrentColor = useMemo(() => getSupportedMuiColor(currentColor), [currentColor]);
  const supportedFontColor = useMemo(() => getSupportedMuiColor(fontColor), [fontColor]);

  const tempColor = useMemo(() => {
    if (parentColor) {
      return getColorString(parentColor, false);
    }

    return null;
  }, [parentColor]);

  const openColorPicker = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeColorPicker = useCallback(() => {
    setTimeout(() => setPosition({ x: 0, y: 0 }), 300);
    setAnchorEl(null);
    setParentColor(null);
  }, []);

  const handleColorChange = (color: Color) => {
    if (colorPickerOpened) {
      setParentColor(color);
    }
  };

  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x, y: data.y });
  };

  const handleColorPickerChange = (color: string | undefined) => {
    onChange?.({ value: color, propertyName: currentColorPropertyName });
    setParentColor(null);
  };

  const showLabelIcon = useMemo(() => !isNil(labelIcon), [labelIcon]);

  const resultFontColor = useMemo(() => {
    if (!isNil(supportedFontColor)) {
      return supportedFontColor;
    }

    if (supportedCurrentColor) {
      return theme.palette.getContrastText(supportedCurrentColor);
    }

    return fontDefaultColor;
  }, [supportedFontColor, supportedCurrentColor, fontDefaultColor, theme]);

  return (
    <>
      <StyledButton
        onClick={openColorPicker}
        background={tempColor || supportedCurrentColor}
        labelPlacement={labelPlacement}
        data-test={`${dataTest}.pickerBtn`}
        fontColor={resultFontColor}
      >
        <ColorLabel labelSize={labelSize}>{label}</ColorLabel>
        <ColorValue>{tempColor || currentColor}</ColorValue>
        <If condition={showLabelIcon}>
          <IconContainer>{labelIcon}</IconContainer>
        </If>
      </StyledButton>

      <Draggable position={position} onStop={handleStop} cancel=".no-drag">
        <StyledPopover
          open={colorPickerOpened}
          anchorEl={anchorEl}
          onClose={closeColorPicker}
          anchorOrigin={ANCHOR_ORIGIN}
          transformOrigin={TRANSFORM_ORIGIN}
        >
          <ThemeColorPicker
            title={label}
            color={currentColor}
            onClose={closeColorPicker}
            onApply={handleColorPickerChange}
            onColorChange={handleColorChange}
          />
        </StyledPopover>
      </Draggable>
    </>
  );
};
