import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { MaterialDialogTitleSettings } from './MaterialDialogTitle.settings';
export const MaterialDialogTitle = {
    name: COMPONENT_DSL_NAMES.MaterialDialogTitle,
    displayName: 'Dialog Title',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'DialogTitle',
    },
    schema: {
        dndTargetPropName: 'children',
        props: {
            onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            },
            children: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
        },
    },
    settings: MaterialDialogTitleSettings,
};
