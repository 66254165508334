import { indexBy } from 'ramda';

import { AppEvents, DASHBOARD_EVENTS, Store } from '../common';

const INITIAL_STATE: Store['userAppNodeElements'] = { elements: {}, elementsByNodeID: {} };

export const userAppRenderDataReducer = (
  state: Store['userAppNodeElements'] = INITIAL_STATE,
  event: AppEvents,
): Store['userAppNodeElements'] => {
  switch (event.type) {
    case DASHBOARD_EVENTS.updateNodeMeasures: {
      const newMeasures = {
        elements: event.elements,
        elementsByNodeID: indexBy(element => element.nodeID, Object.values(event.elements)),
      };

      return newMeasures;
    }
  }

  return state;
};
