import { LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { QuoteNode, HeadingNode } from '@lexical/rich-text';

import { ComponentSettingChildrenTextRichElementDSL, NodePropValue } from '@builder/schemas';

import { RichTextEditor } from 'src/shared/components';
/**
 * Stateful container around RichTextEditor;
 * - is aware of dashboard state
 * - is able to convert nodes into rich-text-state
 */
export const RichTextViewEditor = ({
  onChange,
  'data-test': dataTest,
  setting,
  keyValue,
  targetNodeId,
}: {
  targetNodeId: string;
  setting: ComponentSettingChildrenTextRichElementDSL;
  onChange: (propData: { keyValue: unknown; keyPath: (string | number)[] }) => void;
  'data-test': string;
  keyValue: NodePropValue | null;
}): JSX.Element => {
  const initialConfig = {
    namespace: 'MyEditor',
    theme: {},
    onError: (err: unknown) => console.error(err),
    nodes: [ListNode, ListItemNode, QuoteNode, HeadingNode, LinkNode],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextEditor
        targetNodeId={targetNodeId}
        keyValue={keyValue}
        setting={setting}
        onChange={onChange}
        dataTest={dataTest}
      />
    </LexicalComposer>
  );
};
