import { createSelector } from 'reselect';
import { SchemaError, ERROR_SCOPES } from '@builder/utils';
const getLibraryListDSL = (libraryListDSL) => {
    return libraryListDSL;
};
const getLibraryID = createSelector((_, props) => props.libraryID, libraryID => libraryID);
export const getLibraryDSL = createSelector(getLibraryListDSL, getLibraryID, (libraryListDSL, libraryID) => {
    const libraryDSL = libraryListDSL[libraryID];
    if (!libraryDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Library with id ${libraryID} not found.`);
    }
    return libraryDSL;
});
export const getLibraryArrayDSL = createSelector(getLibraryListDSL, libraryListDSL => Object.values(libraryListDSL));
