import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings, omitSettings } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getPropertySections, getStyleSections, getDivider, } from '../../common/settings';
export const getBuilderComponentsMapSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['bootstrapURLKeys'],
                            label: 'Bootstrap URL keys',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            passInvalidData: true,
                            keyPath: ['defaultCenter'],
                            label: 'Default center',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['center'],
                            label: 'Center',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['zoom'],
                            label: 'Zoom',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['markers'],
                            label: 'Markers',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['layerTypes'],
                            label: 'Layer types',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['hoverDistance'],
                            label: 'Hover distance',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['mapMargin'],
                            label: 'Margin',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['resetBoundsOnResize'],
                            label: 'Reset bounds on resize',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['options'],
                            label: 'Options',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['distanceToMouse'],
                            label: 'Distance to mouse',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['googleMapLoader'],
                            label: 'Google map loader',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    componentView: COMPONENT_SETTING_VIEWS.cssEditor,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'markerCss'],
                    label: 'Marker css',
                },
                ...pipe(omitSettings([
                    [NS_ID.style.display.display],
                    [NS_ID.style.font.root],
                    [NS_ID.style.size.root, NS_ID.style.size.overflow],
                ]))(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChildClick',
                            label: 'On Child Click',
                            keyPath: [...prefixPath, 'onChildClick'],
                            children: getCommonActionSettings([...prefixPath, 'onChildClick']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChildMouseLeave',
                            label: 'On Child Mouse Leave',
                            keyPath: [...prefixPath, 'onChildMouseLeave'],
                            children: getCommonActionSettings([...prefixPath, 'onChildMouseLeave']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChange',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onZoomAnimationStart',
                            label: 'On Zoom Animation Start',
                            keyPath: [...prefixPath, 'onZoomAnimationStart'],
                            children: getCommonActionSettings([...prefixPath, 'onZoomAnimationStart']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onZoomAnimationEnd',
                            label: 'On Zoom Animation End',
                            keyPath: [...prefixPath, 'onZoomAnimationEnd'],
                            children: getCommonActionSettings([...prefixPath, 'onZoomAnimationEnd']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onDrag',
                            label: 'On Drag',
                            keyPath: [...prefixPath, 'onDrag'],
                            children: getCommonActionSettings([...prefixPath, 'onDrag']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onDragEnd',
                            label: 'On Drag End',
                            keyPath: [...prefixPath, 'onDragEnd'],
                            children: getCommonActionSettings([...prefixPath, 'onDragEnd']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChildMouseEnter',
                            label: 'On Child Mouse Enter',
                            keyPath: [...prefixPath, 'onChildMouseEnter'],
                            children: getCommonActionSettings([...prefixPath, 'onChildMouseEnter']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMapTypeIdChange',
                            label: 'On Map Type Id Change',
                            keyPath: [...prefixPath, 'onMapTypeIdChange'],
                            children: getCommonActionSettings([...prefixPath, 'onMapTypeIdChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onTilesLoaded',
                            label: 'On Tiles Loaded',
                            keyPath: [...prefixPath, 'onTilesLoaded'],
                            children: getCommonActionSettings([...prefixPath, 'onTilesLoaded']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsMapSettings = getBuilderComponentsMapSettings();
