var _a, _b;
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, COMPONENT_DSL_NAMES, COMPONENT_DSL_INTERACTION_TYPES, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
import { MaterialTypography } from '../MaterialTypography';
import { getCommonTypographySettings } from '../MaterialTypography/MaterialTypography.settings';
export const MaterialAvatarSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.root,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'label',
                            label: 'Label',
                            keyPath: ['children'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                    predefineds: {
                                        alias: 'Avatar Label',
                                        props: (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props,
                                        schemaOverride: {
                                            interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                    settings: getCommonTypographySettings(),
                                    predefineds: {
                                        alias: 'Avatar Label',
                                        props: Object.assign(Object.assign({}, (_b = MaterialTypography.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { children: {
                                                nodes: [
                                                    {
                                                        name: COMPONENT_DSL_NAMES.Text,
                                                        props: {
                                                            children: 'A',
                                                        },
                                                    },
                                                ],
                                            } }),
                                        schemaOverride: {
                                            interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['src'],
                            label: 'Source',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['alt'],
                            label: 'Alt text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['srcSet'],
                            label: 'Source Set',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            options: ['circular', 'rounded', 'square'].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
