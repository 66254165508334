import { ValueOf } from '@builder/utils';

export const STATE_SCOPES = {
  global: 'global',
  local: 'local',
} as const;

export const RUNTIMESTATE = {
  localState: 'localState',
  localStates: 'localStates',
} as const;

export const ACTION_ARGS = {
  function: 'function',
  requestID: 'requestID',
  code: 'code',
} as const;

export type StateScopeTypes = ValueOf<typeof STATE_SCOPES>;

export const STATE_CATEGORY = {
  functions: 'functions',
  requests: 'requests',
  customEntries: 'customEntries',
} as const;

export type StateCategories = ValueOf<typeof STATE_CATEGORY>;

export const STATE_TYPES = {
  query: 'query',
  boolean: 'boolean',
  string: 'string',
  number: 'number',
  object: 'object',
  array: 'array',
  function: 'function',
} as const;

export type StateTypes = ValueOf<typeof STATE_TYPES>;

export const STATE_BOOLEAN_VARIANTS = {
  trueFalse: 'true-false',
  openClose: 'open-close',
} as const;

export type StateBooleanVariantTypes = ValueOf<typeof STATE_BOOLEAN_VARIANTS>;

export const STATE_REQUEST_VARIANTS = {
  rest: 'rest',
  query: 'query',
  lazyQuery: 'lazyQuery',
  mutation: 'mutation',
} as const;

export const STATE_REQUEST_CALLBACKS = {
  onCompleted: 'onCompleted',
  onError: 'OnError',
} as const;

export type StateQueryVariantTypes = ValueOf<typeof STATE_REQUEST_VARIANTS>;

export const REST_DATA_TYPE = {
  raw: 'raw',
  json: 'json',
  forData: 'formData',
  binary: 'binary',
} as const;

export type RestDataTypes = ValueOf<typeof REST_DATA_TYPE>;

export const STATE_TYPE_CATEGORIES_MAPPER = {
  [STATE_TYPES.query]: STATE_CATEGORY.requests,
  [STATE_TYPES.function]: STATE_CATEGORY.functions,
  [STATE_TYPES.boolean]: STATE_CATEGORY.customEntries,
  [STATE_TYPES.string]: STATE_CATEGORY.customEntries,
  [STATE_TYPES.number]: STATE_CATEGORY.customEntries,
  [STATE_TYPES.object]: STATE_CATEGORY.customEntries,
  [STATE_TYPES.array]: STATE_CATEGORY.customEntries,
};

export const STATE_ACTIONS = {
  setValue: 'setValue',
  setProperty: 'setProperty',
  insertItemInArray: 'insertItemInArray',
  removeItemFromArray: 'removeItemFromArray',
  setTrue: 'setTrue',
  setFalse: 'setFalse',
  toggle: 'toggle',
};
