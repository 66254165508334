import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';

import { DEPLOY_STATUSES } from 'src/shared/constants';
import { DeployFragment } from 'src/shared/graphql/__generated__';
import { useDeploySteps } from 'src/shared/graphql/hooks';
import { useInitialValue } from 'src/shared/hooks';

import { DeployInitializeProgress, DeployStepProgress } from './components';

type DeployProgressProps = {
  deploy?: DeployFragment;
};

export const DeployProgressContainer = styled.div`
  background: ${({ theme }) => theme.palette.grey[900]};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2.4)};
  border-radius: 4px;
`;

export const DeployProgress: React.FC<DeployProgressProps> = ({ deploy }) => {
  const deployStepListResult = useDeploySteps(deploy?.id as string);

  useEffect(() => {
    if (deploy?.status === DEPLOY_STATUSES.pending) {
      deployStepListResult.startPolling(2500);
    } else if (
      deploy?.status === DEPLOY_STATUSES.resolved ||
      deploy?.status === DEPLOY_STATUSES.rejected
    ) {
      deployStepListResult.stopPolling();
    }

    return () => deployStepListResult.stopPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploy, deployStepListResult.startPolling, deployStepListResult.stopPolling]);

  // To avoid changing timestamps when the deployment is created
  const startedAt = useInitialValue<DateTime>(() => {
    if (deploy) {
      return DateTime.fromISO(deploy.createdAt).minus({ minutes: 1 });
    }

    return DateTime.now();
  });

  return (
    <DeployProgressContainer>
      <Grid direction="column" container gap={2}>
        <DeployInitializeProgress isPending={!deploy} startedAt={startedAt} />
        {deployStepListResult.data?.appbuilder?.deployStepList?.items?.map(deployStep => (
          <DeployStepProgress key={deployStep.id} deployStep={deployStep} />
        ))}
      </Grid>
    </DeployProgressContainer>
  );
};
