import { pipe } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, disableRadioOptions, disableSpacingPaddings, DISPLAY_OPTION_VALUES, getBasicPropsSettings, NS_ID, pickSettings, TEXT_FIELD_TYPE_OPTIONS, TEXT_FIELD_VALIDATION_OPTIONS, } from '../../common';
import { getBorderSection, getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, getTypographySection, } from '../../common/settings';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
import { getCommonTypographySettings } from '../MaterialTypography/MaterialTypography.settings';
export const getBuilderComponentsTextFieldSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'label'],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'value',
                            keyPath: [...prefixPath, 'value'],
                            label: 'Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'placeholder'],
                            label: 'Placeholder',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'helperText',
                            keyPath: [...prefixPath, 'helperText'],
                            label: 'Helper Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'type'],
                            label: 'Type',
                            options: TEXT_FIELD_TYPE_OPTIONS.map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'validation'],
                            label: 'Validation',
                            options: TEXT_FIELD_VALIDATION_OPTIONS.map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'regularExpression',
                            keyPath: [...prefixPath, 'regularExpression'],
                            label: 'Regular Expression',
                            showIf: [
                                {
                                    keyPath: [...prefixPath, 'validation'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'regular expression',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'helperErrorText',
                            keyPath: [...prefixPath, 'helperErrorText'],
                            label: 'Helper Error Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'debounce'],
                            label: 'Debounce',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'required'],
                            label: 'Required',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'autoFocus'],
                            label: 'Auto Focus',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Multiline',
                    name: 'multiline',
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'multiline'],
                            label: 'Multiline',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'minRows'],
                            label: 'Min Rows',
                            showIf: [
                                {
                                    keyPath: [...prefixPath, 'multiline'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'maxRows'],
                            label: 'Max Rows',
                            showIf: [
                                {
                                    keyPath: [...prefixPath, 'multiline'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Adornments',
                    name: 'adornments',
                    expandedByDefault: false,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'startAdornment',
                            label: 'Start Adornment',
                            keyPath: [...prefixPath, 'InputProps', 'startAdornment'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                    settings: getCommonTypographySettings(),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'endAdornment',
                            label: 'End Adornment',
                            keyPath: [...prefixPath, 'InputProps', 'endAdornment'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                    settings: getCommonTypographySettings(),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'variant'],
                            label: 'Variant',
                            options: ['filled', 'outlined'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'color'],
                            label: 'Color',
                            options: ['primary', 'secondary'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'size'],
                            label: 'Size',
                            options: ['medium', 'small'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'fullWidth'],
                            label: 'Full Width',
                        },
                    ],
                },
                getDivider(),
                // common styles
                ...pipe(assocSettings([NS_ID.style.size.root, NS_ID.style.size.height], setting => (Object.assign(Object.assign({}, setting), { keyPath: [...prefixPath, 'style', 'height'] }))), assocSettings([NS_ID.style.border.root], () => getBorderSection({
                    prefixPath: [...prefixPath],
                    systemPrefixPath: prefixPath,
                })), assocSettings([NS_ID.style.font.root], () => getTypographySection([...prefixPath])), assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], disableSpacingPaddings), assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])), pickSettings([
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.display.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.font.root, NS_ID.style.font.color],
                    [NS_ID.style.font.root, NS_ID.style.font.fontFamily],
                    [NS_ID.style.font.root, NS_ID.style.font.fontSize],
                    [NS_ID.style.font.root, NS_ID.style.font.fontWeight],
                    [NS_ID.style.font.root, NS_ID.style.font.textAlign],
                    [NS_ID.style.font.root, NS_ID.style.font.textDecoration],
                    [NS_ID.style.font.root, NS_ID.style.font.textTransform],
                    [NS_ID.style.size.root, NS_ID.style.size.width],
                    [NS_ID.style.size.root, NS_ID.style.size.height],
                    [NS_ID.style.background.root],
                    [NS_ID.style.border.root],
                    [NS_ID.style.css.root],
                ]))(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsTextFieldSettings = getBuilderComponentsTextFieldSettings();
