import { RESOURCE_TYPES } from '@builder/schemas';
import { mustBeCorrectVariableName } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_16_7 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.7';
    }
    getChangeList() {
        return ['Fix eight base backend resources with wrong names'];
    }
    migrateResourceDSL(resourceDSL, _appDSL) {
        const isNonBackendEightBase = resourceDSL.type !== RESOURCE_TYPES.backendEightBase;
        const isNameCorrect = !mustBeCorrectVariableName(resourceDSL.name);
        if (isNonBackendEightBase || isNameCorrect) {
            return resourceDSL;
        }
        return Object.assign(Object.assign({}, resourceDSL), { name: `_${resourceDSL.name}` });
    }
}
export const migration_0_16_7 = new Migration_0_16_7();
