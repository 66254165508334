import { SyntheticEvent } from 'react';

import { isEmpty } from 'ramda';

import {
  appNodesSchemaSelectors,
  componentListSelectors,
  nodeListSelectors,
  NodeID,
  ComponentDSLNameTypes,
  nodeSelectors,
  NodeChecker,
} from '@builder/schemas';
import { isString, isUndefined } from '@builder/utils';

import {
  useComponentListDSL,
  useCopyBuffer,
  useDashboardState,
  useNodeListDSL,
  useUIBuilderLeftPanel,
  useNodeContainerParentID,
  useTotalNodesInContainer,
} from 'src/providers/ReduxProvider';
import { copyBufferSelectors, LEFT_PANEL_TABS } from 'src/store';

type UseNodeActionsInfo = (data?: {
  nodeID?: NodeID;
  nodeName?: ComponentDSLNameTypes;
  deleteNode?: (event: React.SyntheticEvent<Element, Event>) => void;
  openAsRoot?: (event: React.SyntheticEvent<Element, Event>) => void;
  backToView?: (event: React.SyntheticEvent<Element, Event>) => void;
  createSymbolToBuffer?: (event: React.SyntheticEvent<Element, Event>, name: string) => void;
  copyComponent?: (event: React.SyntheticEvent<Element, Event>) => void;
  copyStyle?: (event: React.SyntheticEvent<Element, Event>) => void;
  pasteStyle?: (event: React.SyntheticEvent<Element, Event>) => void;
  copyComponentToBuffer?: (event: React.SyntheticEvent<Element, Event>) => void;
  applyBufferToNode?: (event: React.SyntheticEvent<Element, Event>) => void;
  saveAsGlobalCSS?: (event: SyntheticEvent, className: string) => void;
}) => {
  showActions: boolean;
  showCreateSymbolAction: boolean;
  showCopyAction: boolean;
  showCopyAndPasteStyleAction: boolean;
  showCopyToBufferAction: boolean;
  showApplyBufferAction: boolean;
  showDeleteAction: boolean;
  showOpenAsRootAction: boolean;
  showBackToViewAction: boolean;
  showMoveUpDownAction: boolean;
  showSaveAsGlobalCSS: boolean;
};

export const useNodeActionsInfo: UseNodeActionsInfo = ({
  nodeID,
  nodeName,
  copyStyle,
  pasteStyle,
  deleteNode,
  openAsRoot,
  backToView,
  createSymbolToBuffer,
  copyComponent,
  copyComponentToBuffer,
  applyBufferToNode,
  saveAsGlobalCSS,
} = {}) => {
  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();
  const appDSL = { nodeListDSL, componentListDSL };
  const copyBuffer = useCopyBuffer();
  const { currentTab } = useUIBuilderLeftPanel();
  const { openAsRootNode } = useDashboardState();
  const selectedNode = nodeID ? nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID }) : undefined;
  const nestedNodes = !isUndefined(selectedNode)
    ? nodeSelectors
        .getAllImmediateChildrenIDs(selectedNode, {
          componentListDSL,
        })
        .map(childrenID => nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: childrenID }))
        .filter(
          childrenNode =>
            !(
              NodeChecker.Value.isTextNode(childrenNode) ||
              NodeChecker.Value.isRichTextNode(childrenNode)
            ),
        )
    : [];
  const hasNestedNodes = !isEmpty(nestedNodes);
  const totalNodesInContainer = useTotalNodesInContainer(nodeID as string);
  const containerParentID = useNodeContainerParentID(nodeID as string);

  const showCreateSymbolAction = Boolean(
    nodeName &&
      createSymbolToBuffer &&
      !componentListSelectors.isCreatingSymbolDisabled(componentListDSL, {
        componentName: nodeName,
      }),
  );

  const showCopyAction = Boolean(
    nodeID &&
      copyComponent &&
      !appNodesSchemaSelectors.isCopyingDisabled(appDSL, {
        nodeID,
      }),
  );

  const showCopyToBufferAction = Boolean(
    nodeID &&
      copyComponentToBuffer &&
      !appNodesSchemaSelectors.isCopyingDisabled(appDSL, {
        nodeID,
      }),
  );

  const showApplyBufferAction = Boolean(
    nodeID &&
      applyBufferToNode &&
      !copyBufferSelectors.isCopyBufferEmpty(copyBuffer) &&
      !appNodesSchemaSelectors.isApplyingCopyBufferDisabled(appDSL, {
        nodeID,
      }),
  );

  const showCopyAndPasteStyleAction = Boolean(nodeID && copyStyle);

  const showDeleteAction = Boolean(
    nodeID &&
      deleteNode &&
      nodeListSelectors.isNodeDeletable(nodeListDSL, {
        nodeID,
        componentListDSL,
      }),
  );

  const componentNode = selectedNode?.name
    ? componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: selectedNode?.name,
      })
    : undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const basicProps: any = componentNode?.settings?.properties[0];
  const haveClassProperty = basicProps?.children[0]?.children?.filter(
    (child: { label: string }) => child.label === 'Class',
  )?.length;
  const showSaveAsGlobalCSS = Boolean(nodeID && saveAsGlobalCSS && haveClassProperty);

  const showOpenAsRootAction = Boolean(
    nodeID &&
      openAsRoot &&
      hasNestedNodes &&
      currentTab === LEFT_PANEL_TABS.navigator &&
      isEmpty(openAsRootNode.nodeID),
  );

  const showBackToViewAction = Boolean(
    nodeID &&
      backToView &&
      hasNestedNodes &&
      nodeID === openAsRootNode.nodeID &&
      !isEmpty(openAsRootNode.nodeID),
  );

  const showMoveUpDownAction = Boolean(
    nodeID && isString(containerParentID) && totalNodesInContainer !== 1,
  );

  const showActions =
    showCreateSymbolAction ||
    showCopyAction ||
    showCopyAndPasteStyleAction ||
    showCopyToBufferAction ||
    showSaveAsGlobalCSS ||
    showApplyBufferAction ||
    showDeleteAction ||
    showOpenAsRootAction ||
    showBackToViewAction ||
    showMoveUpDownAction;

  return {
    showActions,
    showCreateSymbolAction,
    showCopyAction,
    showCopyAndPasteStyleAction,
    showCopyToBufferAction,
    showSaveAsGlobalCSS,
    showApplyBufferAction,
    showDeleteAction,
    showOpenAsRootAction,
    showBackToViewAction,
    showMoveUpDownAction,
  };
};
