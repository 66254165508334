import React, { forwardRef } from 'react';

import {
  SpeedDial as MaterialSpeedDial,
  SpeedDialProps as MaterialSpeedDialProps,
  SpeedDialIcon as MaterialSpeedDialIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ComponentDataProps } from '@builder/schemas';

export type SpeedDialProps = MaterialSpeedDialProps & ComponentDataProps;

const StyledMaterialSpeedDial = styled(MaterialSpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

export const SpeedDial = forwardRef<HTMLDivElement, SpeedDialProps>((props, ref) => {
  const {
    id,
    icon,
    open,
    lang,
    style,
    onOpen,
    hidden,
    onClose,
    children,
    openIcon,
    translate,
    ariaLabel,
    direction,
    FabProps = {},
    'data-test': dataTest,
    'data-node-id': dataNodeID,
    'data-node-render-path': dataRenderPath,
    ...restOfProps
  } = props;

  return (
    <StyledMaterialSpeedDial
      {...restOfProps}
      id={id}
      ref={ref}
      lang={lang}
      open={open}
      style={style}
      hidden={hidden}
      onOpen={onOpen}
      onClose={onClose}
      FabProps={FabProps}
      translate={translate}
      direction={direction}
      ariaLabel={ariaLabel}
      data-test={dataTest}
      data-node-id={dataNodeID}
      data-node-render-path={dataRenderPath}
      icon={<MaterialSpeedDialIcon icon={icon} openIcon={openIcon} />}
    >
      {children}
    </StyledMaterialSpeedDial>
  );
});
