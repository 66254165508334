import React from 'react';

import { useTheme } from '@mui/material';

import { useToggleStickState } from 'src/features/navigator/hooks/useToggleStickState';
import { useUIBuilderLeftPanel } from 'src/providers';
import { Icon } from 'src/shared/components';

export const LeftPanelStickButton: React.FC = () => {
  const theme = useTheme();
  const toggleStickState = useToggleStickState();
  const leftPanel = useUIBuilderLeftPanel();
  const isAffectOnDashboardLayout = leftPanel.args?.isAffectOnDashboardLayout;

  return (
    <Icon
      element="button"
      onClick={toggleStickState}
      name="leftPanel"
      fill={theme.palette.text[isAffectOnDashboardLayout ? 'primary' : 'secondary']}
    />
  );
};
