import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_TYPES, STATE_SCOPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonStyleProps } from '../../common/commonProps';
import { BuilderComponentsFileUploadButtonSettings } from './BuilderComponentsFileUploadButton.settings';
export const BuilderComponentsFileUploadButton = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFileUploadButton,
    displayName: 'File Upload Button',
    icon: 'fileUploadButton',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FileUploadButton',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.array,
                    name: 'FileUploadButton',
                    defaultValue: [],
                },
            ],
            props: {
                color: 'primary',
                variant: 'outlined',
                debounce: 0,
                size: 'medium',
                type: 'button',
                textButton: 'Upload file',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FileUploadButton.setValue(event.target.files)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { autoComplete: { type: COMPONENT_DSL_PROP_TYPES.string }, defaultValue: {
                type: COMPONENT_DSL_PROP_TYPES.object,
            }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, error: { type: COMPONENT_DSL_PROP_TYPES.boolean }, inputProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign({}, commonStyleProps),
            }, InputProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign(Object.assign({}, commonStyleProps), { startAdornment: {
                        type: COMPONENT_DSL_PROP_TYPES.reactNode,
                        allowedNodes: [
                            COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            COMPONENT_DSL_NAMES.MaterialTypography,
                        ],
                    }, endAdornment: {
                        type: COMPONENT_DSL_PROP_TYPES.reactNode,
                        allowedNodes: [
                            COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            COMPONENT_DSL_NAMES.MaterialTypography,
                        ],
                    } }),
            }, textButton: { type: COMPONENT_DSL_PROP_TYPES.string }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, margin: { type: COMPONENT_DSL_PROP_TYPES.enum }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, rows: { type: COMPONENT_DSL_PROP_TYPES.string }, minRows: { type: COMPONENT_DSL_PROP_TYPES.string }, maxRows: { type: COMPONENT_DSL_PROP_TYPES.string }, select: { type: COMPONENT_DSL_PROP_TYPES.boolean }, value: {
                type: COMPONENT_DSL_PROP_TYPES.array,
            }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, accept: { type: COMPONENT_DSL_PROP_TYPES.string }, maxFileSize: { type: COMPONENT_DSL_PROP_TYPES.number } }),
    },
    settings: BuilderComponentsFileUploadButtonSettings,
};
