import { useMemo } from 'react';

import { QueryResult, useQuery } from '@apollo/client';

import { AssetBackend, AssetBackendList, assetListSelectors } from '@builder/schemas';

import { AssetsListQuery } from '../__generated__';
import { ASSET_LIST_QUERY } from '../fragments';
import { useAssetListDSL } from 'src/providers/ReduxProvider';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

export type useAssetsBackendHooksReturn = {
  assetBackendArray: AssetBackend[];
  assetBackendList: AssetBackendList;
  assetListOptions: Omit<QueryResult<AssetsListQuery>, 'data'>;
};

export const useAssetsBackendHooks = (): useAssetsBackendHooksReturn => {
  const { workspaceID } = useCurrentWorkspaceID();
  const assetListDSL = useAssetListDSL();
  const assetBackendIDs = useMemo(() => assetListSelectors.getAssetFileBackendIDs(assetListDSL), [
    assetListDSL,
  ]);

  const { data: assetListQueryData, ...assetListOptions } = useQuery<AssetsListQuery>(
    ASSET_LIST_QUERY,
    {
      variables: {
        filter: {
          app: {
            workspaceId: {
              equals: workspaceID,
            },
          },
          file: {
            fileId: {
              in: assetBackendIDs,
            },
          },
        },
      },
      skip: !workspaceID,
    },
  );

  const { previousData: previousAssetListQueryData } = assetListOptions;
  const assetBackendArray = useMemo<AssetBackend[]>(
    () =>
      assetListQueryData?.assetsList?.items || previousAssetListQueryData?.assetsList?.items || [],
    [assetListQueryData, previousAssetListQueryData],
  );

  const assetBackendList = useMemo<AssetBackendList>(
    () =>
      assetBackendArray.reduce((list, asset) => {
        if (!asset?.file?.fileId) {
          return list;
        }

        return {
          ...list,
          [asset.file.fileId]: asset,
        };
      }, {} as AssetBackendList),
    [assetBackendArray],
  );

  return {
    assetListOptions,
    assetBackendArray,
    assetBackendList,
  };
};
