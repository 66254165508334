import React from 'react';

import styled from '@emotion/styled';
import { Typography, TypographyProps } from '@mui/material';

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const DescriptionText: React.FC<TypographyProps> = ({ children }) => {
  return <StyledTypography variant="body1">{children}</StyledTypography>;
};
