import React from 'react';

import { AuthProvider as BaseAuthProvider } from '@8base-react/auth';
import { Auth, AUTH_STRATEGIES, ISubscribableAuthClient } from '@8base/auth';
import { useRollbarPerson } from '@rollbar/react';

import { processEnv } from '@builder/utils';

import { APP_URL } from 'src/shared/constants';

const authClient = Auth.createClient(
  {
    strategy: AUTH_STRATEGIES.WEB_AUTH0,
    subscribable: true,
  },
  {
    domain: processEnv.getAuthDomain(),
    clientId: processEnv.getAuthClientID(),
    redirectUri: `${window.location.origin}${APP_URL.public.auth.callback}`,
    logoutRedirectUri:
      processEnv.getAuthLogoutRedirectURL() ||
      `${window.location.origin}${APP_URL.public.auth.root}`,
  },
) as ISubscribableAuthClient;

export const AuthProvider: React.FC = ({ children }) => {
  useRollbarPerson(authClient.getState());
  return <BaseAuthProvider authClient={authClient}>{children}</BaseAuthProvider>;
};
