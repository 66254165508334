import { PossibleUnitTypes, POSSIBLE_UNIT_TYPES } from '@builder/schemas';

import { valueEndsWithUnit } from './valueEndsWithUnit';

export const getInitialUnit = (
  propValue: string | undefined,
  unitList: PossibleUnitTypes[] | undefined,
): PossibleUnitTypes | undefined => {
  if (!unitList || unitList.length === 0) {
    return undefined;
  }

  if (unitList.length === 1) {
    return unitList[0];
  }

  const defaultUnit = unitList[0];

  if (!propValue) {
    return defaultUnit;
  }

  const unit = valueEndsWithUnit(propValue, unitList);
  const valueUnit = unitList.find(
    maybeValueUnit => maybeValueUnit.type === POSSIBLE_UNIT_TYPES.value,
  );

  return unit || valueUnit || defaultUnit;
};
