import { EventType } from '@8base-private/event-handler';
import { MutationTuple, useMutation } from '@apollo/client';
import { nanoid as uuid } from 'nanoid';

import {
  WorkspacesListQuery,
  WorkspaceUpdateMutation,
  WorkspaceUpdateMutationVariables,
} from '../__generated__';
import { FRONTEND_WORKSPACES_LIST_QUERY, WORKSPACE_UPDATE_MUTATION } from '../fragments';
import { useDraftEngine } from 'src/providers';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';

type UseWorkspaceHooksReturn = {
  updateWorkspaceMutation: MutationTuple<
    WorkspaceUpdateMutation,
    WorkspaceUpdateMutationVariables
  >[0];
  updateWorkspaceMutationResult: MutationTuple<
    WorkspaceUpdateMutation,
    WorkspaceUpdateMutationVariables
  >[1];
};

export const useWorkspaceHooks = (): UseWorkspaceHooksReturn => {
  const { draftEngine } = useDraftEngine();

  const [updateWorkspaceMutation, updateWorkspaceMutationResult] = useMutation<
    WorkspaceUpdateMutation,
    WorkspaceUpdateMutationVariables
  >(WORKSPACE_UPDATE_MUTATION, {
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully updated workspace`,
      [APOLLO_OPERATION_CONTEXT.ERROR_MESSAGE]: `Error occurred during updating workspace. Changes not applied.`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'WorkspacesList',
        });
      }
    },
    update(cache, { data }) {
      const updatedWorkspace = data?.system?.workspaceUpdate;

      const workspacesListQuery = cache.readQuery<WorkspacesListQuery>({
        query: FRONTEND_WORKSPACES_LIST_QUERY,
      });

      const updatedWorkspacesListData = {
        system: {
          workspacesFrontendList: {
            items: workspacesListQuery?.system?.workspacesFrontendList?.items.map(
              frontendWorkspace => {
                if (frontendWorkspace.id !== updatedWorkspace?.id) {
                  return frontendWorkspace;
                }

                return {
                  ...frontendWorkspace,
                  name: updatedWorkspace.name,
                  description: updatedWorkspace.description,
                };
              },
            ),
          },
        },
      };

      cache.writeQuery({
        query: FRONTEND_WORKSPACES_LIST_QUERY,
        data: updatedWorkspacesListData,
      });
    },
  });

  return {
    updateWorkspaceMutation,
    updateWorkspaceMutationResult,
  };
};
