import { mapObjIndexed, path } from 'ramda';
import { componentListSelectors, nodeSelectors, } from '@builder/schemas';
import { isBoolean, flatObject } from '@builder/utils';
/**
 * Abstraction to work with node presentation.
 * It applies presentations to the node and checks if some presentation was already applied.
 */
export class Presentation {
    constructor({ componentListDSL, nodeDSL, }) {
        /**
         * @returns true if props already has presentation values.
         * @example
         * {
         *   transformedProps: { isOpen: true };
         *   presentationDSL: { name: 'visible', props: { isOpen: true }};
         * } => true
         * @example
         * {
         *   transformedProps: { isOpen: false };
         *   presentationDSL: { name: 'visible', props: { isOpen: true }};
         * } => false
         */
        this.isPresentationCorrespondProps = ({ presentationDSL, transformedProps, }) => {
            if (!presentationDSL.props) {
                return true;
            }
            const flattedPresentationProps = flatObject(presentationDSL.props);
            return flattedPresentationProps.every(({ path: keyPath, value }) => path(keyPath, transformedProps) === value);
        };
        /**
         * @returns List of all presentation with data if presentation correspond props.
         */
        this.getPresentationCorrespondsByProps = (transformedProps) => {
            var _a;
            const allPresentations = (_a = this.componentDSL.schema.presentations) !== null && _a !== void 0 ? _a : [];
            return allPresentations.reduce((accum, presentationDSL) => {
                const isPresentationsCorrespondProps = this.isPresentationCorrespondProps({
                    presentationDSL,
                    transformedProps,
                });
                return Object.assign(Object.assign({}, accum), { [presentationDSL.name]: isPresentationsCorrespondProps });
            }, {});
        };
        this.presentationChecks = {};
        this.componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
        });
        this.nodeDSL = nodeDSL;
        this.setPresentationApplied();
    }
    /**
     * @returns result of checks which shows if some presentation was applied to the node.
     */
    getPresentation() {
        return this.presentationChecks;
    }
    /**
     * Merges new presentation with existed presentation.
     * Needs because we need to merge condition and props calculation of applying.
     * Also we need to merge before and after applying data.
     */
    mergePresentations(newPresentationChecks) {
        this.presentationChecks = Object.assign(Object.assign({}, this.presentationChecks), mapObjIndexed((newPresentation, presentationName) => {
            var _a, _b;
            let mergedPresentation = this.presentationChecks[presentationName];
            if (isBoolean(newPresentation.isNodeMatchPresentationBeforeApply)) {
                mergedPresentation = Object.assign(Object.assign({}, mergedPresentation), { isNodeMatchPresentationBeforeApply: isBoolean((_a = this.presentationChecks[presentationName]) === null || _a === void 0 ? void 0 : _a.isNodeMatchPresentationBeforeApply)
                        ? (newPresentation === null || newPresentation === void 0 ? void 0 : newPresentation.isNodeMatchPresentationBeforeApply) &&
                            this.presentationChecks[presentationName].isNodeMatchPresentationBeforeApply
                        : newPresentation === null || newPresentation === void 0 ? void 0 : newPresentation.isNodeMatchPresentationBeforeApply });
            }
            if (isBoolean(newPresentation.isNodeMatchPresentation)) {
                mergedPresentation = Object.assign(Object.assign({}, mergedPresentation), { isNodeMatchPresentation: isBoolean((_b = this.presentationChecks[presentationName]) === null || _b === void 0 ? void 0 : _b.isNodeMatchPresentation)
                        ? newPresentation.isNodeMatchPresentation &&
                            this.presentationChecks[presentationName].isNodeMatchPresentation
                        : newPresentation.isNodeMatchPresentation });
            }
            if (isBoolean(newPresentation.isPresentationApplied)) {
                mergedPresentation = Object.assign(Object.assign({}, mergedPresentation), { isPresentationApplied: newPresentation.isPresentationApplied });
            }
            return mergedPresentation;
        }, newPresentationChecks));
    }
    /**
     * Sets true if some presentation was applied to the node.
     */
    setPresentationApplied() {
        const presentationsDSL = this.componentDSL.schema.presentations;
        if (!presentationsDSL) {
            return;
        }
        const presentationsApplied = presentationsDSL.reduce((accum, presentationDSL) => {
            const isPresentationApplied = nodeSelectors.isPresentationApplied(this.nodeDSL, {
                presentationName: presentationDSL.name,
            });
            return Object.assign(Object.assign({}, accum), { [presentationDSL.name]: {
                    isPresentationApplied,
                } });
        }, {});
        this.mergePresentations(presentationsApplied);
    }
    /**
     * Corresponds presentation with props and adds this information to the presentation checker.
     */
    correspondWithPropsBeforePresentationOverride(transformedProps) {
        const presentationPropsCorrespond = this.getPresentationCorrespondsByProps(transformedProps);
        this.mergePresentations(mapObjIndexed(value => ({ isNodeMatchPresentationBeforeApply: value }), presentationPropsCorrespond));
    }
    /**
     * Corresponds presentation with props and adds this information to the presentation checker.
     */
    correspondWithPropsAfterPresentationOverride(transformedProps) {
        const presentationPropsCorrespond = this.getPresentationCorrespondsByProps(transformedProps);
        this.mergePresentations(mapObjIndexed(value => ({ isNodeMatchPresentation: value }), presentationPropsCorrespond));
    }
    /**
     * Corresponds presentation with condition and adds this information to the presentation checker.
     */
    correspondWithConditionBeforePresentationOverride(transformedCondition) {
        const presentationsDSL = this.componentDSL.schema.presentations;
        if (!presentationsDSL) {
            return;
        }
        const presentationConditionCorrespond = presentationsDSL.reduce((accum, presentationDSL) => {
            if (!presentationDSL.condition) {
                return accum;
            }
            return Object.assign(Object.assign({}, accum), { [presentationDSL.name]: {
                    isNodeMatchPresentationBeforeApply: presentationDSL.condition.showIf === !!transformedCondition,
                } });
        }, {});
        this.mergePresentations(presentationConditionCorrespond);
    }
    /**
     * Corresponds presentation with condition and adds this information to the presentation checker.
     */
    correspondWithConditionAfterPresentationOverride(transformedCondition) {
        const presentationsDSL = this.componentDSL.schema.presentations;
        if (!presentationsDSL) {
            return;
        }
        const presentationConditionCorrespond = presentationsDSL.reduce((accum, presentationDSL) => {
            if (!presentationDSL.condition) {
                return accum;
            }
            return Object.assign(Object.assign({}, accum), { [presentationDSL.name]: {
                    isNodeMatchPresentation: presentationDSL.condition.showIf === !!transformedCondition,
                } });
        }, {});
        this.mergePresentations(presentationConditionCorrespond);
    }
}
