import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import disabledDSL from './style-guide-dsl/FloatingActionButtonGuide/disabled.json';
import extendedDSL from './style-guide-dsl/FloatingActionButtonGuide/extended.json';
import primaryDSL from './style-guide-dsl/FloatingActionButtonGuide/primary.json';
import secondaryDSL from './style-guide-dsl/FloatingActionButtonGuide/secondary.json';
export const FloatingActionButton = {
    name: STYLE_GUIDE_NAMES.MuiFab,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
    dirName: 'FloatingActionButtonGuide',
    title: 'Floating Action Button',
    classNames: [
        '.MuiFab-root',
        '.MuiFab-primary',
        '.MuiFab-secondary',
        '.MuiFab-extended',
        '.MuiFab-circular',
        '.Mui-focusVisible',
        '.Mui-disabled',
        '.MuiFab-colorInherit',
        '.MuiFab-sizeSmall',
        '.MuiFab-sizeMedium',
    ],
    stories: [
        {
            title: 'Circular Primary (Large, Medium, small)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'text',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'text',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'text',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: primaryDSL,
        },
        {
            title: 'Circular Secondary (Large, Medium, Small)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.19.4',
            isAppDSLCommitted: true,
            generatedAppDSL: secondaryDSL,
        },
        {
            title: 'Extended Primary (Large, Medium, Small)',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'contained',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'contained',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'contained',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: extendedDSL,
        },
        {
            title: 'Disabled',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsFloatingActionButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.19.4',
            isAppDSLCommitted: true,
            generatedAppDSL: disabledDSL,
        },
    ],
};
