import React, { useRef, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import * as MaterialIcons from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  SvgIconTypeMap,
  Tooltip,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import debounce from 'lodash.debounce';
import { FixedSizeGrid } from 'react-window';

import { AssetID, assetListSelectors, hasPropJsCode } from '@builder/schemas';
import { hasHandlebars, isString, useBoolState, isURL } from '@builder/utils';

import { useAssetListDSL, useNodeAppRuntimeState } from '../../../../providers';
import { useAssetsHooks } from '../../../../shared/graphql/hooks';
import { getAssetIdFromString } from '../AssetViewEditor/utils';
import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { Button, ValueOf } from 'src/shared/components';
import { FxButton } from 'src/shared/components/common/FxButton';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

type MaterialIconNames = keyof typeof MaterialIcons;

type IconViewEditorProps = {
  value: string;
  nodeID?: string;
  onChange: (value: string) => void;
  customIcon?: { assetID: AssetID } | string;
  'data-test'?: string;
};

const CurrentIconWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;

  & .MuiSvgIcon-root {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const CurrentIconTitle = styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  margin-left: 4px;
  white-space: nowrap;
  max-width: 130px;
`;

const IconWrapper = styled(Grid)`
  cursor: pointer;
  &:hover {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

const DEFAULT_SIZE = 30;

const StyledDialogActions = styled(DialogActions)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

const EmptyStateContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 700px;
  height: 190px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-template-columns: 60px auto 32px;
  align-items: center;
  justify-items: end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const FxButtonWrapper = styled.div`
  height: ${({ theme }) => theme.layout.controls.height}px;
  margin-top: auto;
`;

const StyledFormControl = styled(FormControl)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

const GRID = {
  height: 190,
  width: 700,
  rowHeight: 40,
  columnsCount: 13,
  columnWidth: 53.5,
  styles: {
    marginTop: 24,
  },
};

const ICON_STYLE = {
  width: 24,
  height: 24,
};

const MATERIAL_ICON_VARIANTS = {
  Filled: 'Filled',
  Outlined: 'Outlined',
  Rounded: 'Rounded',
  TwoTone: 'TwoTone',
  Sharp: 'Sharp',
} as const;

export type MaterialIconVariantTypes = ValueOf<typeof MATERIAL_ICON_VARIANTS>;

const REGULAR_MATERIAL_ICONS = Object.entries(MaterialIcons).filter(
  ([name]) =>
    !name.endsWith('Outlined') &&
    !name.endsWith('Rounded') &&
    !name.endsWith('TwoTone') &&
    !name.endsWith('Sharp'),
);

const getMaterialIconsByVariant = (
  variant: MaterialIconVariantTypes,
): [string, OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>][] => {
  if (variant === MATERIAL_ICON_VARIANTS.Filled) {
    return REGULAR_MATERIAL_ICONS;
  }

  return Object.entries(MaterialIcons).filter(([name]) => name.endsWith(variant));
};

type StateResult = {
  type: string;
  variant: string;
  value?: boolean | string | Record<string, unknown> | never[] | number;
  setProperty?: void;
  hasItemInArray?: void;
};

export const IconViewEditor: React.FC<IconViewEditorProps> = ({
  value,
  nodeID,
  onChange,
  customIcon,
  'data-test': dataTest,
}) => {
  const { assetFileArray } = useAssetsHooks();
  const assetListDSL = useAssetListDSL();
  const { localState, globalState } = useNodeAppRuntimeState(nodeID);
  const allStates = useMemo(() => {
    return { ...globalState, ...localState } as Record<string, StateResult>;
  }, [globalState, localState]);
  const customIconURL = useMemo(() => {
    if (customIcon && isString(customIcon) && hasPropJsCode(customIcon)) {
      const assetString = customIcon.replace(/[{}]/g, '').split('.');
      const stateObject = Object.entries(allStates).find(item => item[0] === assetString[0].trim());
      let assetName = assetString[1];
      if (stateObject) {
        const valueState = stateObject[1];
        if (isString(valueState.value) && hasHandlebars(valueState.value)) {
          assetName = (valueState.value as string).split('.')[1].trim();
        } else if (isString(valueState.value) && !isURL(valueState.value)) {
          const assetIDFromString = getAssetIdFromString(valueState.value, assetListDSL);
          if (assetIDFromString) {
            const asset = assetListSelectors.getAssetFileDSL(assetFileArray, {
              assetID: assetIDFromString || '',
            });
            return {
              name: asset?.name,
              url: asset?.previewUrl,
            };
          }
        } else if (isString(valueState.value) && isURL(valueState.value)) {
          return {
            name: undefined,
            url: valueState.value as string,
          };
        }
      }

      if (assetName) {
        const assetID = assetListSelectors.getAssetIDByName(assetListDSL, { assetName });
        const asset = assetListSelectors.getAssetFileDSL(assetFileArray, {
          assetID: assetID || '',
        });
        return {
          name: asset?.name,
          url: asset?.previewUrl,
        };
      }
    }

    if (customIcon && !isString(customIcon)) {
      const asset = assetListSelectors.getAssetFileDSL(assetFileArray, {
        assetID: customIcon?.assetID || '',
      });
      return {
        name: asset?.name,
        url: asset?.previewUrl,
      };
    }

    if (isString(customIcon as string)) {
      return {
        name: undefined,
        url: customIcon as string,
      };
    }

    return undefined;
  }, [allStates, assetFileArray, assetListDSL, customIcon]);
  const [isDialogOpened, { setTrue: openDialog, setFalse: closeDialog }] = useBoolState(false);
  const [search, setSearch] = useState<string>('');
  const [selectedIcon, setSelectedIcon] = useState<string>(value);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const debounceSearchFn = useRef(
    debounce((nextSearch: string) => setDebouncedSearch(nextSearch), 300),
  ).current;
  const CurrentIcon = MaterialIcons[value as MaterialIconNames];
  const isActionButtonsDisabled = !selectedIcon || selectedIcon === value;

  const [iconVariant, setIconVariant] = useState<MaterialIconVariantTypes>(() => {
    if (selectedIcon) {
      if (selectedIcon.endsWith(MATERIAL_ICON_VARIANTS.Outlined)) {
        return MATERIAL_ICON_VARIANTS.Outlined;
      }

      if (selectedIcon.endsWith(MATERIAL_ICON_VARIANTS.Rounded)) {
        return MATERIAL_ICON_VARIANTS.Rounded;
      }

      if (selectedIcon.endsWith(MATERIAL_ICON_VARIANTS.TwoTone)) {
        return MATERIAL_ICON_VARIANTS.TwoTone;
      }

      if (selectedIcon.endsWith(MATERIAL_ICON_VARIANTS.Sharp)) {
        return MATERIAL_ICON_VARIANTS.Sharp;
      }
    }

    return MATERIAL_ICON_VARIANTS.Filled;
  });
  const handleIconVariantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIconVariant(event?.target?.value as MaterialIconVariantTypes);
  };

  const filteredIcons = useMemo(() => {
    return getMaterialIconsByVariant(iconVariant).filter(([name]) =>
      name.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase()),
    );
  }, [debouncedSearch, iconVariant]);
  const hasSearchedIcons = filteredIcons.length > 0;

  useEffect(() => {
    debounceSearchFn(search);
  }, [debounceSearchFn, search]);

  const handleCloseDialog = () => {
    closeDialog();
    setSelectedIcon(value);
    setSearch('');
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleIconChange = () => {
    closeDialog();
    onChange(selectedIcon);
    setSearch('');
  };

  const handleIconClick = (name: string) => {
    if (name === selectedIcon) {
      handleIconChange();
    } else {
      setSelectedIcon(name);
    }
  };

  const customIconEnabledTooltip = customIconURL
    ? 'This button is disabled because a custom icon is currently in use'
    : '';

  return (
    <JsCodeInjectionInput
      label="IconName"
      propValue={value}
      onChangePropValue={onChange}
      disableFx={() => onChange('Help')}
      nodeID={nodeID}
      data-test={dataTest}
      typeField="string"
    >
      {({ isFxEnabled, enableFx }) => (
        <Container>
          <CurrentIconWrapper>
            {CurrentIcon && !customIconURL && (
              <CurrentIcon data-test={`${dataTest}.selectedIcon`} />
            )}
            {!customIconURL && (
              <CurrentIconTitle title={value}>{value || 'Not selected'}</CurrentIconTitle>
            )}
            {customIconURL && (
              <>
                <svg width={DEFAULT_SIZE} height={DEFAULT_SIZE}>
                  <image href={customIconURL.url} width={DEFAULT_SIZE} height={DEFAULT_SIZE} />
                </svg>
                <CurrentIconTitle title={customIconURL?.name}>
                  {customIconURL?.name}
                </CurrentIconTitle>
              </>
            )}
          </CurrentIconWrapper>
          <Tooltip title={customIconEnabledTooltip}>
            <span>
              <Button
                onClick={openDialog}
                variant="contained"
                color="default"
                disabled={!!customIconURL}
                data-test={`${dataTest}.openIconEditorBtn`}
              >
                Change
              </Button>
            </span>
          </Tooltip>
          {enableFx && (
            <Tooltip title={customIconEnabledTooltip}>
              <FxButtonWrapper>
                <FxButton
                  disabled={!!customIconURL}
                  activeProp={ACTIVEPROP.literal}
                  onClick={enableFx}
                  data-test={`${dataTest}.fxBtn`}
                />
              </FxButtonWrapper>
            </Tooltip>
          )}

          <Dialog open={isDialogOpened} onClose={handleCloseDialog} maxWidth="md">
            <DialogTitle>Choose an icon</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    value={search}
                    onChange={handleSearch}
                  />
                </Grid>
                <Grid item xs={2}>
                  <StyledFormControl>
                    <RadioGroup value={iconVariant} onChange={handleIconVariantChange}>
                      {Object.values(MATERIAL_ICON_VARIANTS).map(materialIconVariant => {
                        return (
                          <FormControlLabel
                            key={materialIconVariant}
                            value={materialIconVariant}
                            control={<Radio color="primary" />}
                            label={materialIconVariant}
                          />
                        );
                      })}
                    </RadioGroup>
                  </StyledFormControl>
                </Grid>

                <Grid item xs={10}>
                  <Choose>
                    <When condition={!hasSearchedIcons}>
                      <EmptyStateContainer>
                        <Typography>No matching icons</Typography>
                      </EmptyStateContainer>
                    </When>
                    <Otherwise>
                      <FixedSizeGrid
                        style={GRID.styles}
                        columnCount={GRID.columnsCount}
                        columnWidth={GRID.columnWidth}
                        height={GRID.height}
                        rowCount={Math.ceil(filteredIcons.length / GRID.columnsCount)}
                        rowHeight={GRID.rowHeight}
                        width={GRID.width}
                      >
                        {({ columnIndex, rowIndex, style }) => {
                          const iconIndex = rowIndex * GRID.columnsCount + columnIndex;
                          if (iconIndex >= filteredIcons.length) {
                            return null;
                          }

                          const [name, Icon] = filteredIcons[iconIndex];
                          const iconColor = name === selectedIcon ? 'primary' : undefined;
                          return (
                            <IconWrapper
                              container
                              style={style}
                              key={name}
                              title={name}
                              justifyContent="center"
                              onClick={() => handleIconClick(name)}
                            >
                              <Icon color={iconColor} style={ICON_STYLE} />
                            </IconWrapper>
                          );
                        }}
                      </FixedSizeGrid>
                    </Otherwise>
                  </Choose>
                </Grid>
              </Grid>
            </DialogContent>
            <StyledDialogActions>
              <Button variant="contained" color="default" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleIconChange}
                disabled={isActionButtonsDisabled}
              >
                Apply
              </Button>
            </StyledDialogActions>
          </Dialog>
        </Container>
      )}
    </JsCodeInjectionInput>
  );
};
