var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useState, forwardRef } from 'react';
import styled from '@emotion/styled';
const FileInput = styled.input `
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
export const FileDropZone = forwardRef((_a, ref) => {
    var { accept, alignItems, alignContent, alignSelf, backgroundColor, children, className, color, disabled, display, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, draggable, exceededFileSizeText, flex, flexDirection, flexGrow, flexShrink, flexWrap, id, justifyContent, lang, maxFileSize, onChange, order, overflow, showFileList, style = {}, title, translate, textOverflow, visibility, whiteSpace, wrongFileTypeText } = _a, rest = __rest(_a, ["accept", "alignItems", "alignContent", "alignSelf", "backgroundColor", "children", "className", "color", "disabled", "display", 'data-test', 'data-node-id', 'data-node-render-path', "draggable", "exceededFileSizeText", "flex", "flexDirection", "flexGrow", "flexShrink", "flexWrap", "id", "justifyContent", "lang", "maxFileSize", "onChange", "order", "overflow", "showFileList", "style", "title", "translate", "textOverflow", "visibility", "whiteSpace", "wrongFileTypeText"]);
    const [fileLocalArray, setFileLocalArray] = useState([]);
    const [isHovering, setIsHovering] = useState(false);
    // Verifies fileSize
    const isFileExceedingFileSize = (files, sizeExceededText = '') => {
        if (!maxFileSize)
            return false;
        const isExceedingSize = [...files].some(file => file.size > maxFileSize * 1024 * 1024);
        // eslint-disable-next-line no-alert
        isExceedingSize && alert(sizeExceededText.replace('{maxSize}', maxFileSize.toString()));
        return isExceedingSize;
    };
    // Verifies fileType
    const validateCorrectFileTypes = (files, acceptString, wrongText = '') => {
        var _a;
        if (!acceptString)
            return true;
        const acceptedTypes = acceptString.split(',');
        const acceptedTypesTrimmed = acceptedTypes.map(type => type.trim());
        for (const file of files) {
            let isValidType = false;
            for (const acceptedTypeTrimmed of acceptedTypesTrimmed) {
                if (acceptedTypeTrimmed.startsWith('.')) {
                    // Check file extension
                    const fileExtension = `.${(_a = file.name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase()}`;
                    if (fileExtension === acceptedTypeTrimmed.toLowerCase()) {
                        isValidType = true;
                        break;
                    }
                }
                else if (acceptedTypeTrimmed.endsWith('/*')) {
                    if (file.type.startsWith(acceptedTypeTrimmed.slice(0, -2))) {
                        isValidType = true;
                        break;
                    }
                }
                else if (file.type === acceptedTypeTrimmed) {
                    isValidType = true;
                    break;
                }
            }
            if (!isValidType) {
                // eslint-disable-next-line no-alert
                alert(wrongText);
                return false;
            }
        }
        return true;
    };
    /** Handlers */
    // Handles selection by file picker
    const handleInputChange = (event) => {
        event.preventDefault();
        const fileArray = event.target.files ? Array.from(event.target.files) : [];
        if (!isFileExceedingFileSize(fileArray, exceededFileSizeText) &&
            validateCorrectFileTypes(fileArray, accept, wrongFileTypeText)) {
            setFileLocalArray(fileArray);
            onChange === null || onChange === void 0 ? void 0 : onChange(fileArray);
        }
    };
    // Handles selection by drag and drop
    const handleDrop = (event) => {
        event.preventDefault();
        const fileArray = [];
        if (event.dataTransfer.items) {
            for (const item of event.dataTransfer.items) {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    file && fileArray.push(file);
                }
            }
        }
        else {
            for (const item of event.dataTransfer.files) {
                item.type && fileArray.push(item);
            }
        }
        if (isHovering) {
            setIsHovering(false);
        }
        if (!isFileExceedingFileSize(fileArray, exceededFileSizeText) &&
            validateCorrectFileTypes(fileArray, accept, wrongFileTypeText)) {
            setFileLocalArray(fileArray);
            onChange === null || onChange === void 0 ? void 0 : onChange(fileArray);
        }
    };
    // Sets a className when user is draging a file over component
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsHovering(true);
    };
    // Removes a className when user drag the file out of the container
    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsHovering(false);
    };
    // Removes a single file of the list
    const handleRemove = (file) => {
        const filteredItems = [...fileLocalArray].filter(item => item.name !== file.name);
        setFileLocalArray(filteredItems);
        onChange === null || onChange === void 0 ? void 0 : onChange(filteredItems);
    };
    // Lists the files, each with a remove button
    const ListFiles = () => {
        if (fileLocalArray && fileLocalArray.length) {
            return (_jsx("ul", { children: fileLocalArray.map(file => (_jsxs("li", { children: [_jsx("span", { children: file.name }), _jsx("button", Object.assign({ style: { marginLeft: '.5rem' }, type: "button", onClick: () => handleRemove(file) }, { children: "Remove" }))] }, file.name))) }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs(_Fragment, { children: [_jsxs("label", Object.assign({ className: `${className || ''} ${disabled ? 'disabled' : ''} ${isHovering ? 'hovering' : ''}`, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, draggable: draggable, htmlFor: id ? `dropzone-${id}` : undefined, id: id, lang: lang, onDragLeave: handleDragLeave, onDragOver: handleDragOver, onDrop: handleDrop, ref: ref, style: Object.assign({ alignContent,
                    alignItems,
                    alignSelf,
                    backgroundColor,
                    color,
                    display,
                    flex,
                    flexDirection,
                    flexGrow,
                    flexShrink,
                    flexWrap,
                    justifyContent, minHeight: '36 px', order,
                    overflow, pointerEvents: disabled ? 'none' : undefined, textOverflow,
                    visibility,
                    whiteSpace }, style), title: title, translate: translate }, rest, { children: [_jsx(FileInput, { accept: accept, id: id ? `dropzone-${id}` : undefined, lang: lang, multiple: true, name: "fileDnD", disabled: disabled, onChange: handleInputChange, translate: translate, type: "file" }), children] })), showFileList && _jsx(ListFiles, {})] }));
});
