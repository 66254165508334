var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { FormControl, FormHelperText, RadioGroup as MaterialRadioGroup, } from '@mui/material';
export const RadioGroup = forwardRef((_a, ref) => {
    var { size, className, style, css, error, helperText, variant, name, label, value, onChange, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, children, id, title, lang, translate, disabled } = _a, otherRadioGroupProps = __rest(_a, ["size", "className", "style", "css", "error", "helperText", "variant", "name", "label", "value", "onChange", 'data-test', 'data-node-id', 'data-node-render-path', "children", "id", "title", "lang", "translate", "disabled"]);
    return (_jsxs(FormControl, Object.assign({ className: className, css: css, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, "data-test": dataTest, id: id, lang: lang, ref: ref, size: size, style: style, title: title, translate: translate, disabled: disabled }, { children: [label, _jsx(MaterialRadioGroup, Object.assign({}, otherRadioGroupProps, { name: name, value: value, onChange: onChange }, { children: children })), helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
