import { StateCategoriesDSL, StateFunctionDSL } from '@builder/schemas';

type Property = {
  label: string;
  type:
    | 'undefined'
    | 'object'
    | 'boolean'
    | 'number'
    | 'string'
    | 'function'
    | 'symbol'
    | 'bigint'
    | string;
  apply: string;
  detail:
    | 'undefined'
    | 'object'
    | 'boolean'
    | 'number'
    | 'string'
    | 'function'
    | 'symbol'
    | 'bigint'
    | string;
};

export const getObjectProperties = (obj: Record<string, never>, path = ''): Property[] => {
  return Object.entries(obj).flatMap(([key, stateValue]) => {
    const propPath = path ? `${path}.${key}` : key;
    if (typeof stateValue === 'object' && stateValue !== null) {
      return [
        { label: propPath, type: 'property', apply: propPath, detail: 'Object' },
        ...getObjectProperties(stateValue, propPath),
      ];
    }

    if (stateValue === 'fn') {
      const applyValue = stateValue === 'fn' ? `${path ? `${path}.` : ''}${key}()` : `${propPath}`;
      return [{ label: propPath, type: 'function', apply: `${applyValue}`, detail: 'Function' }];
    }

    return [
      {
        label: propPath,
        type: typeof stateValue || '',
        apply: `${propPath}`,
        detail: typeof stateValue || '',
      },
    ];
  });
};

const functionTransform = ({ name }: StateFunctionDSL) => {
  return { [name]: 'fn' };
};

export const transformFunctions = (
  stateFunctions: StateFunctionDSL[],
): { [x: string]: string }[] => {
  return stateFunctions.map(sf => functionTransform(sf));
};

export const flatternObject = (obj: StateCategoriesDSL): Record<string, never> => {
  const { customEntries = {}, requests = {}, resources = {}, router = {}, ...rest } = obj;
  return { ...rest, ...customEntries, ...requests, ...resources, ...router };
};
