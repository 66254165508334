import { matchPath } from 'react-router';

import { APP_URL } from 'src/shared/constants';

type Params = {
  workspaceID: string;
};

export const getWorkspaceIDFromPathname = (pathname: string): string | undefined => {
  const match = matchPath(pathname, {
    path: APP_URL.workspace.root,
  });

  return (match?.params as Params)?.workspaceID;
};
