import { IMPORT_FILE_USAGE_TYPES, } from '../../../constants';
import { PredefinedHookAccessor } from './PredefinedHookAccessor';
import { PredefinedStateAccessor } from './PredefinedStateAccessor';
/**
 * Provides an api to generate predefined states.
 */
export class PredefinedStateGenerator {
    constructor(importData, options) {
        var _a;
        this.importData = importData;
        this.scopes = options.scopes;
        this.usage = options.usage;
        this.omit = (_a = options.omit) !== null && _a !== void 0 ? _a : true;
        this.stateAccessor =
            options.usage === IMPORT_FILE_USAGE_TYPES.var
                ? new PredefinedStateAccessor(importData)
                : new PredefinedHookAccessor(importData, {
                    stateName: options.stateName,
                    typeName: options.typeName,
                });
    }
    isHookState() {
        return this.usage === IMPORT_FILE_USAGE_TYPES.hook;
    }
    mustBeOmitted() {
        return this.omit;
    }
    /**
     * @example
     * ```
     * () => 'const theme = useTheme();'
     * ```
     */
    getHookCall() {
        const hookName = this.stateAccessor.getHookDeclarationName();
        const stateName = this.stateAccessor.getStateName();
        const hookArguments = this.stateAccessor.getHookCallArgumentsString();
        const hasTypes = this.stateAccessor.hasTypes();
        const stateTypeName = this.stateAccessor.getStateTypeName();
        const stateWithTypes = hasTypes ? `${stateName}: ${stateTypeName}` : stateName;
        return `const ${stateWithTypes} = ${hookName}(${hookArguments});`;
    }
    getImportData() {
        return this.importData;
    }
}
