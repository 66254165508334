import { QueryResult, useQuery } from '@apollo/client';

import { processEnv } from '@builder/utils';

import { WorkspaceMemberListQuery, WorkspaceMemberListQueryVariables } from '../__generated__';
import { TEAM_MEMBER_LIST_QUERY } from '../fragments';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';

export const useTeamMemberList = (
  backendWorkspaceID: string,
  backendWorkspaceApiHost: string,
): QueryResult<WorkspaceMemberListQuery, WorkspaceMemberListQueryVariables> => {
  return useQuery<WorkspaceMemberListQuery, WorkspaceMemberListQueryVariables>(
    TEAM_MEMBER_LIST_QUERY,
    {
      context: {
        [APOLLO_OPERATION_CONTEXT.WORKSPACE_ID]: backendWorkspaceID,
        [APOLLO_OPERATION_CONTEXT.NO_BATCH]: true,
        [APOLLO_OPERATION_CONTEXT.ENDPOINT_URL]:
          backendWorkspaceApiHost || processEnv.getServerURL(),
      },
      skip: !backendWorkspaceID,
    },
  );
};
