import { useMemo } from 'react';

import { LAYOUT_MODES, NodeListDSL, nodeListSelectors } from '@builder/schemas';

import { useCurrentPathname } from '../dashboardRouter';
import {
  useComponentListDSL,
  useDashboardState,
  useNodeListDSL,
} from 'src/providers/ReduxProvider';

/**
 * @returns List of the node ids which can be showed in the navigator and can be edited in the dashboard
 */
export const useEditableNodeListDSL = (): NodeListDSL => {
  const nodeListDSL = useNodeListDSL();
  const currentPathname = useCurrentPathname();
  const {
    layout: { layoutMode },
  } = useDashboardState();
  const componentListDSL = useComponentListDSL();

  return useMemo(() => {
    switch (layoutMode) {
      case LAYOUT_MODES.currentRouteNodes: {
        return nodeListSelectors.getCurrentRouteNodesExceptLayoutNodeListDSL(nodeListDSL, {
          componentListDSL,
          currentPathname,
        });
      }

      case LAYOUT_MODES.layoutNodes: {
        return nodeListSelectors.getCurrentLayoutNodeListDSL(nodeListDSL, {
          componentListDSL,
          currentPathname,
        });
      }

      case LAYOUT_MODES.allPageNodes: {
        return nodeListSelectors.getAllPageNodeListDSL(nodeListDSL, {
          componentListDSL,
          currentPathname,
        });
      }

      case LAYOUT_MODES.hiddenCanvasNodes: {
        return nodeListSelectors.getModalsNodeListDSL(nodeListDSL, {
          componentListDSL,
        });
      }

      default: {
        return {};
      }
    }
  }, [componentListDSL, currentPathname, layoutMode, nodeListDSL]);
};
