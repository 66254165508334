import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { ROUTE_SETTINGS } from './Redirect.settings';
export const Redirect = {
    name: COMPONENT_DSL_NAMES.Redirect,
    displayName: COMPONENT_DSL_NAMES.Redirect,
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: 'react-router-dom',
        componentName: 'Redirect',
    },
    memoization: {
        disable: true,
    },
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.RouterSwitch,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                to: '/',
            },
        },
        props: {
            from: { type: COMPONENT_DSL_PROP_TYPES.string },
            to: { type: COMPONENT_DSL_PROP_TYPES.string, required: true },
            push: { type: COMPONENT_DSL_PROP_TYPES.boolean },
            exact: { type: COMPONENT_DSL_PROP_TYPES.boolean },
            strict: { type: COMPONENT_DSL_PROP_TYPES.boolean },
            sensitive: { type: COMPONENT_DSL_PROP_TYPES.boolean },
        },
    },
    settings: ROUTE_SETTINGS,
};
