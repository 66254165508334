import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { IteratorSettings } from './Iterator.settings';
export const Iterator = {
    name: COMPONENT_DSL_NAMES.Iterator,
    displayName: 'Looper',
    icon: 'looper',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.data,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Box',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Fragment,
                            alias: 'Looper Content',
                            iterator: {
                                data: [],
                                name: 'item',
                                looperLimitInDevMode: 5,
                            },
                        },
                    ],
                },
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                required: true,
                allowedNodes: [COMPONENT_DSL_NAMES.Fragment],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: IteratorSettings,
};
