export const getNodeStyle = (nodeElement?: HTMLElement | null): Record<string, unknown> | null => {
  if (!nodeElement || !nodeElement.style) {
    return null;
  }

  const nodeComputedStyles = nodeElement.style;
  const nodeStyle: Record<string, unknown> = {};

  for (const key in nodeComputedStyles) {
    if (isNaN(Number(key))) {
      nodeStyle[key] = nodeComputedStyles[key];
    }
  }

  return nodeStyle as Record<string, unknown>;
};
