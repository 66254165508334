import { omit } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_8_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.8.4';
    }
    getChangeList() {
        return ['Removed `componentListGroupName: "custom"` from user components'];
    }
    migrateUserComponentListDSL(userComponentListDSL) {
        const userComponentSymbolArrayDSL = Object.values(userComponentListDSL);
        const migratedUserComponentListDSL = Object.assign({}, userComponentListDSL);
        for (const userComponentSymbolDSL of userComponentSymbolArrayDSL) {
            migratedUserComponentListDSL[userComponentSymbolDSL.name] = omit(['componentListGroupName'], userComponentSymbolDSL);
        }
        return migratedUserComponentListDSL;
    }
}
export const migration_0_8_4 = new Migration_0_8_4();
