import { useEffect } from 'react';
import { EMITTER_EVENTS } from '@builder/utils';
import { useUserAppEventEmitter } from '../providers';
const queryInstances = {};
export const useRefetchQuerySubscribe = ({ stateID, refetch, }) => {
    const emitter = useUserAppEventEmitter();
    useEffect(() => {
        let unsub;
        if (!queryInstances[stateID]) {
            queryInstances[stateID] = true;
            unsub = emitter.subscribe(EMITTER_EVENTS.refetchQuery, ({ queryID, options }) => {
                if (queryID === stateID) {
                    refetch === null || refetch === void 0 ? void 0 : refetch(options);
                }
            });
        }
        return () => {
            unsub === null || unsub === void 0 ? void 0 : unsub();
            queryInstances[stateID] = false;
        };
    });
};
