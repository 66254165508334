export const COLOR_MODELS = {
  hex: 'HEX',
  rgba: 'RGBA',
  lab: 'LAB',
} as const;

export const HSBA_KEYS = {
  h: 'h',
  s: 's',
  b: 'b',
  a: 'a',
} as const;

export const PERCENT = 100;
