import React from 'react';

import { ComponentSettingGridItemDSL } from '@builder/schemas';
import { memo } from '@builder/utils';

import { CssGridItem } from 'src/shared/components';

type GridItemTypeProps = {
  gridItemSetting: ComponentSettingGridItemDSL;
  children: React.ReactNode;
  'data-test'?: string;
};

export const GridItemType = memo(
  'GridItemType',
  ({ gridItemSetting, children }: GridItemTypeProps): JSX.Element => {
    return <CssGridItem {...gridItemSetting.props}>{children}</CssGridItem>;
  },
);
