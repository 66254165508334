/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import * as changeCase from 'change-case';
import { isEmpty, omit, uniq } from 'ramda';
import { DEFAULT_HTML_TITLE, libraryListSelectors, resourceListSelectors, librarySelectors, DEFAULT_META_TAG_NAME, resourceSelectors, stateListSelectors, nodeListSelectors, transformPropWithJsCode, hasPropJsCode, } from '@builder/schemas';
import { isUndefined, serialize } from '@builder/utils';
import { PROJECT_TEMPLATE } from './constants';
import { ComponentFileListUsageGenerator, ConstantsGenerator, GLOBAL_STATE_PROVIDER_CONST, ERROR_BOUNDARY_PROVIDER_CONST, ERROR_BOUNDARY_ROUTER_PROVIDER_CONST, USE_GLOBAL_STATE_CONST, USE_ERROR_BOUNDARY_CONST, GlobalStateProviderGenerator, SymbolListUsageGenerator, ApolloProviderGenerator, ImportsGenerator, HookListUsageGenerator, AssetsGenerator, ASSETS_PROVIDER_CONST, USE_ASSETS_CONST, RouteHooksGenerator, RouterProviderGenerator, HtmlTemplatesGenerator, sortStatesByUse, HookGenerator, sortHooksCalls, ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST, ErrorBoundaryProviderGenerator, ERROR_PAGE_ID, USE_ERROR_EVENT_CONST, PATH_PROVIDER_CONST, USE_PATH_CONST, } from './generators';
import { camelize } from './generators/constants-generator/ConstantsGenerator';
import { GlobalCSSGenerator } from './generators/global-css-generators';
import { HooksGenerator } from './generators/hooks-generator';
import { AssetsProvider } from './generators/providers-generators/AssetsProviderGenerator';
import { TypesGenerator } from './generators/types-generator';
import { formatCode } from './utils';
export class CodeEngine {
    static emptyToString(value) {
        return value || '';
    }
    static generateAppWrapper(appDSL, componentListDSL, assetListDSL) {
        var _a, _b, _c, _d, _e;
        const apolloProviderGenerator = new ApolloProviderGenerator(appDSL);
        const getStatesMeta = (localHooksCalls, hookNames) => {
            return localHooksCalls.map(localHook => {
                const [name] = hookNames.filter(hookName => localHook.includes(`const ${hookName} =`));
                return {
                    call: localHook,
                    name,
                    timesUsed: 0,
                };
            });
        };
        const matchHookNameInText = (text, hookName) => {
            return (text.includes(`(${hookName})`) ||
                text.includes(`${hookName})`) ||
                text.includes(`${hookName},`));
        };
        const textsAreDifferent = (text1, text2) => {
            return text1 !== text2;
        };
        const textsMatch = (text1, text2) => {
            return text1 === text2;
        };
        const getTimesStatesAreUsed = (localHooksMeta, hookNames) => {
            hookNames.forEach(hookName => {
                localHooksMeta.forEach(localHookMeta => {
                    if (textsAreDifferent(hookName, localHookMeta.name) &&
                        matchHookNameInText(localHookMeta.call, hookName)) {
                        localHooksMeta.forEach(localMeta => {
                            if (textsMatch(localMeta.name, hookName)) {
                                localMeta.timesUsed += 1;
                            }
                        });
                    }
                });
            });
        };
        const getMaxnumber = (arr) => arr.reduce((max, obj) => {
            if (obj.timesUsed > max) {
                return obj.timesUsed;
            }
            return max;
        }, arr[0].timesUsed);
        const groupByTimesUsed = (maxNumAStateIsUsed, statesMetaData, statesGroupByTimesUsed) => {
            for (let item = maxNumAStateIsUsed; item >= 0; item--) {
                const items = statesMetaData.filter(state => state.timesUsed === item);
                statesGroupByTimesUsed.push(items);
            }
        };
        const sortByTimesUsedLargestToSmallest = (localHooksMeta) => {
            localHooksMeta.sort((a, b) => {
                return b.timesUsed - a.timesUsed;
            });
        };
        const globalStatesUsed = sortStatesByUse(stateListSelectors.getGlobalStateArrayDSL(appDSL.states));
        const statesSortedByGroups = [];
        const sortGroupOfStates = (statesGroupByTimesUsed, statesNames) => {
            for (const stateGroup of statesGroupByTimesUsed) {
                if (stateGroup.length > 0) {
                    const initialMaxNumber = getMaxnumber(stateGroup);
                    getTimesStatesAreUsed(stateGroup, statesNames);
                    const endMaxNumber = getMaxnumber(stateGroup);
                    if (endMaxNumber !== initialMaxNumber) {
                        const innerNames = stateGroup.map(state => state.name);
                        const innerStatesGroupByTimesUsed = [];
                        groupByTimesUsed(endMaxNumber, stateGroup, innerStatesGroupByTimesUsed);
                        sortGroupOfStates(innerStatesGroupByTimesUsed.filter(innerState => innerState.length > 0), innerNames);
                    }
                    sortByTimesUsedLargestToSmallest(stateGroup);
                    statesSortedByGroups.push(stateGroup);
                }
            }
        };
        const statesDefinition = globalStatesUsed.map((appState) => {
            const hookGenerator = new HookGenerator({
                appDSL,
                componentListDSL,
                appStateDSL: appState,
                assetListDSL,
            });
            return hookGenerator.getHookCall();
        });
        const statesNames = globalStatesUsed.map((appState) => {
            const hookGenerator = new HookGenerator({
                appDSL,
                componentListDSL,
                appStateDSL: appState,
                assetListDSL,
            });
            return hookGenerator.stateAccessor.getStateName();
        });
        const statesGroupByTimesUsed = [];
        const statesMetaData = getStatesMeta(statesDefinition, statesNames);
        getTimesStatesAreUsed(statesMetaData, statesNames);
        groupByTimesUsed(getMaxnumber(statesMetaData), statesMetaData, statesGroupByTimesUsed);
        try {
            sortGroupOfStates(statesGroupByTimesUsed, statesNames);
        }
        catch (error) {
            console.error('Possible circular dependency', error);
        }
        const reorderStates = (states) => {
            const sortedStates = [];
            const checked = new Set();
            const path = [];
            function findAndAddState(state) {
                if (checked.has(state)) {
                    return;
                }
                const currentState = states.find(stateToEvaluate => stateToEvaluate.name === state);
                if (currentState && path.includes(state)) {
                    const circularPath = [...path, state].join(' -> ');
                    console.error(`Circular dependency detected > ${circularPath}`);
                    return;
                }
                if (isUndefined(currentState) || isEmpty(currentState)) {
                    return;
                }
                const { call } = currentState;
                path.push(state);
                states.forEach(stateToEvaluate => {
                    if ((stateToEvaluate.name !== state && call.includes(`(${stateToEvaluate.name})`)) ||
                        call.includes(`${stateToEvaluate.name})`) ||
                        call.includes(`${stateToEvaluate.name},`)) {
                        findAndAddState(stateToEvaluate.name);
                    }
                });
                sortedStates.unshift(currentState);
                checked.add(state);
                path.pop();
            }
            try {
                states.forEach(stateToEvaluate => findAndAddState(stateToEvaluate.name));
            }
            catch (error) {
                console.error('Possible circular dependency >', error);
            }
            return sortedStates.reverse();
        };
        const coreContent = `
      <${PATH_PROVIDER_CONST} path={routerLocation.pathname}>
        <${GLOBAL_STATE_PROVIDER_CONST} states={{${statesNames.join(',')}}}>
          <${ASSETS_PROVIDER_CONST}>
            {runtimeErrors || !isEmpty(eventErrors) ? (
              <Fragment>
                <Route path="/500">
                  <${this.getNameErrorPage(appDSL)} />
                </Route>
                <Redirect to="/500" />
              </Fragment>
            ) : (
              <RootContent />
            )}
          </${ASSETS_PROVIDER_CONST}>
        </${GLOBAL_STATE_PROVIDER_CONST}>
      </${PATH_PROVIDER_CONST}>`;
        const showDefaultMetaTagCharset = !(((_a = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _a === void 0 ? void 0 : _a.htmlDefaultMetaTags) || []).some(metaTag => metaTag.name === DEFAULT_META_TAG_NAME.charset);
        const showDefaultMetaTagViewport = !(((_b = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _b === void 0 ? void 0 : _b.htmlDefaultMetaTags) || []).some(metaTag => metaTag.name === DEFAULT_META_TAG_NAME.viewport);
        return `
        export const App: React.FC = () => {
          const runtimeErrors = useErrorBoundary();
          const eventErrors = useCatchEventErrors();
          const routerLocation = useLocation();
          ${sortHooksCalls(reorderStates(uniq(statesSortedByGroups.flat())).map((stateMetaData) => stateMetaData.call)).join('\n')}
          ${apolloProviderGenerator.generateApolloClient()}
          useEffect(() => {
            const globalErrors = runtimeErrors ?? eventErrors;
            if (globalErrors) {
              if (!isEmpty((uncaughtErrors as any).value[0].error)) {
                uncaughtErrors.setValue([
                  ...uncaughtErrors.value,
                  {
                    message: globalErrors?.message,
                    error: globalErrors?.name,
                    code: globalErrors?.stack,
                  },
                ]);
              } else {
                uncaughtErrors.setValue([
                  {
                    message: globalErrors?.message,
                    error: globalErrors?.name,
                    code: globalErrors?.stack,
                  },
                ]);
              }
            }
          }, [runtimeErrors, eventErrors]);


          return (
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={appTheme}>
                <EmotionThemeProvider theme={appTheme}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <CssBaseline />
                    <Helmet>
                      <title>${((_d = (_c = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _c === void 0 ? void 0 : _c.htmlDefaultTitle) === null || _d === void 0 ? void 0 : _d.replace('{', '').replace('}', '').replace(';', '')) || DEFAULT_HTML_TITLE}</title>
                      ${(((_e = appDSL === null || appDSL === void 0 ? void 0 : appDSL.settings) === null || _e === void 0 ? void 0 : _e.htmlDefaultMetaTags) || [])
            .map(metaTag => {
            switch (metaTag.name) {
                case DEFAULT_META_TAG_NAME.charset: {
                    return `<meta charSet="${metaTag.content}" />`;
                }
                default: {
                    return `<meta name="${metaTag.name}" content="${metaTag.content}" />`;
                }
            }
        })
            .join('')}
                      ${showDefaultMetaTagCharset ? '<meta charset="UTF-8" />' : ''}
                      ${showDefaultMetaTagViewport
            ? '<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />'
            : ''}
                    </Helmet>
                    ${apolloProviderGenerator.wrapInApolloProvider(coreContent)}
                  </LocalizationProvider>
                </EmotionThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          )
        }
      `;
    }
    static getNameErrorPage(appDSL) {
        const targetlNode = nodeListSelectors.getNodeDSL(appDSL.nodes, {
            nodeID: ERROR_PAGE_ID,
        });
        const nameErrorPage = changeCase.pascalCase(targetlNode.alias ? targetlNode.alias : '', {
            transform: changeCase.pascalCaseTransformMerge,
        });
        return nameErrorPage.concat('Page');
    }
    static generateAppRootCode({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        var _a;
        const importsGenerator = new ImportsGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        // jsxRuntime and jsx needed to use emotion css without babel plugin
        const appCode = `
      /** @jsxRuntime classic */
      /** @jsx jsx */
      ${importsGenerator.generateAppRootImports()}

      ${importsGenerator.generateImports(importsGenerator.hookImports.getGlobalImportDataList())}
      import { ${USE_ERROR_BOUNDARY_CONST}, ${USE_ERROR_EVENT_CONST} } from './providers/${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST}';
      import { ${PATH_PROVIDER_CONST}} from './providers/${PATH_PROVIDER_CONST}';
      import { ${this.getNameErrorPage(appDSL)} } from 'pages';
      import { isEmpty } from 'ramda';
      import { Redirect, Route, useLocation } from 'react-router-dom';
      const appTheme = createTheme(${serialize.stringify((_a = appDSL.theme) === null || _a === void 0 ? void 0 : _a.frameworkSettings)} as any);

      ${CodeEngine.generateAppWrapper(appDSL, componentListDSL, assetListDSL)}
    `;
        return formatCode(appCode);
    }
    static generateSymbols({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        const symbolGenerator = new SymbolListUsageGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        const symbolsFileStructure = symbolGenerator.getAllSymbolNames().reduce((accum, symbolName) => {
            return Object.assign(Object.assign({}, accum), { [`src/shared/symbols/${symbolGenerator.symbolAccessor.getSymbolComponentName(symbolName)}.tsx`]: {
                    content: formatCode(symbolGenerator.generateSymbolFile(symbolName)),
                } });
        }, {});
        return Object.assign({ 'src/shared/symbols/index.ts': {
                content: formatCode(symbolGenerator.generateSymbolIndex()),
            } }, symbolsFileStructure);
    }
    static generateConstants(appDSL) {
        const constantsGenerator = new ConstantsGenerator(appDSL);
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(appDSL.resources);
        // It doesn't necessary to create such file if there is no graphql resources
        const resourceFileStructure = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? {}
            : {
                'src/shared/constants/resource.ts': {
                    content: formatCode(constantsGenerator.generateResourceFile()),
                },
            };
        return Object.assign({ 'src/shared/constants/index.ts': {
                content: formatCode(constantsGenerator.generateIndexFile()),
            }, 'src/shared/constants/routes.ts': {
                content: formatCode(constantsGenerator.generateRoutesFile()),
            }, 'src/shared/constants/resources.ts': {
                content: formatCode(constantsGenerator.generateResourcesFile()),
            }, 'src/shared/constants/libraries.ts': {
                content: formatCode(constantsGenerator.generateLibrariesFile()),
            } }, resourceFileStructure);
    }
    static generateTypes(appDSL) {
        const typesGenerator = new TypesGenerator(appDSL);
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(appDSL.resources);
        // It doesn't necessary to create such file if there is no graphql resources
        const resourceFileStructure = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? {}
            : {
                'src/shared/types/resource.ts': {
                    content: formatCode(typesGenerator.generateResourceFile()),
                },
            };
        const aliasesFile = typesGenerator.generateAliasesFile().trim();
        const aliasesFileStructure = isEmpty(aliasesFile)
            ? {}
            : {
                'src/shared/types/aliases.ts': {
                    content: formatCode(aliasesFile),
                },
            };
        return Object.assign(Object.assign({ 'src/shared/types/index.ts': {
                content: formatCode(typesGenerator.generateIndexFile()),
            }, 'src/shared/types/common.ts': {
                content: formatCode(typesGenerator.generateCommonFile()),
            } }, aliasesFileStructure), resourceFileStructure);
    }
    static generateHooks({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        const importsGenerator = new ImportsGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        const hookListGenerator = new HookListUsageGenerator({
            appDSL,
            componentListDSL,
            assetListDSL,
        });
        const routeHooksGenerator = new RouteHooksGenerator(appDSL);
        const commonHooksGenerator = new HooksGenerator(appDSL);
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(appDSL.resources);
        // It doesn't necessary to create such file if there is no graphql resources
        const useResourceClientFileStructure = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? {}
            : {
                'src/shared/hooks/useResourceClient.ts': {
                    content: formatCode(commonHooksGenerator.generateUseResourceClientFile()),
                },
            };
        const useRoutesFileStructure = {
            'src/shared/hooks/predefined/useRoutes.ts': {
                content: CodeEngine.generateUseRoutesTemplate(appDSL),
            },
        };
        const useRouterHookFileStructure = {
            'src/shared/hooks/predefined/useRouterHooks.ts': {
                content: CodeEngine.generateUseRouterHooksTemplate(appDSL),
            },
        };
        const hooksFileStructure = hookListGenerator.hookGenerators.reduce((accum, hookGenerator) => {
            const hookFile = hookGenerator.generateHookFile();
            return Object.assign(Object.assign({}, accum), { [`src/shared/hooks/${hookGenerator.stateAccessor.getHookDeclarationName(appDSL)}.ts`]: {
                    content: formatCode(`
            ${importsGenerator.generateHookImports(hookFile)}

            ${hookFile}
          `),
                } });
        }, {});
        return Object.assign(Object.assign(Object.assign(Object.assign({ 'src/shared/hooks/index.ts': {
                content: formatCode(`
          ${hookListGenerator.generateHooksExportDeclarations()}
          ${commonHooksGenerator.generateIndexFile()}
          ${routeHooksGenerator.generateExportsDeclarations()}
        `),
            } }, hooksFileStructure), useResourceClientFileStructure), useRoutesFileStructure), useRouterHookFileStructure);
    }
    static generateProviders({ appDSL, componentListDSL, assetBackendList, assetListDSL, useAssetDirectImports, isPreview, }) {
        const globalStateProviderGenerator = new GlobalStateProviderGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        const assetsProviderGenerator = new AssetsProvider({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
            useAssetDirectImports,
        });
        const routerProviderGenerator = new RouterProviderGenerator();
        const errorBoundaryProviderGenerator = new ErrorBoundaryProviderGenerator();
        return {
            'src/providers/index.ts': {
                content: formatCode(`
          export { ${GLOBAL_STATE_PROVIDER_CONST}, ${USE_GLOBAL_STATE_CONST}  } from './${GLOBAL_STATE_PROVIDER_CONST}';
          export { ${ASSETS_PROVIDER_CONST}, ${USE_ASSETS_CONST}  } from './${ASSETS_PROVIDER_CONST}';
          export { ${PATH_PROVIDER_CONST}, ${USE_PATH_CONST}  } from './${PATH_PROVIDER_CONST}';
          export { ${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST}, ${USE_ERROR_BOUNDARY_CONST}  } from './${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST}';
        `),
            },
            [`src/providers/${GLOBAL_STATE_PROVIDER_CONST}.tsx`]: {
                content: formatCode(globalStateProviderGenerator.generateGlobalStateProvider()),
            },
            [`src/providers/${ASSETS_PROVIDER_CONST}.tsx`]: {
                content: formatCode(assetsProviderGenerator.generateAssetsProvider()),
            },
            [`src/providers/${ERROR_BOUNDARY_ROUTER_PROVIDER_CONST}.tsx`]: {
                content: formatCode(routerProviderGenerator.generateErrorBounderRouter()),
            },
            [`src/providers/${ERROR_BOUNDARY_PROVIDER_CONST}.tsx`]: {
                content: formatCode(routerProviderGenerator.generateErrorBoundary(isPreview)),
            },
            [`src/providers/${ERROR_BOUNDARY_CONTEXT_PROVIDER_CONST}.tsx`]: {
                content: formatCode(errorBoundaryProviderGenerator.generateErrorBoundaryProvider()),
            },
            [`src/providers/${PATH_PROVIDER_CONST}.tsx`]: {
                content: formatCode(`
          import React, { createContext, FC, useContext, useEffect, useState } from 'react';

type PathValues = {
  previousPath: string | undefined;
  currentPath: string;
  isMounted: boolean;
  setMounted: (value: boolean) => void;
};

const PathContext = createContext<PathValues>({
  previousPath: undefined,
  currentPath: '',
  isMounted: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMounted: (value: boolean) => {},
});

interface PathProviderProps {
  path?: string;
  children: React.ReactNode;
}

export const PathProvider: FC<PathProviderProps> = ({ path, children }: PathProviderProps) => {
  const [previousPath, setPreviousPath] = useState<string | undefined>(undefined);
  const [currentPath, setCurrentPath] = useState<string>(path || '');
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setPreviousPath(currentPath);
    setCurrentPath(path || '');
    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <PathContext.Provider value={{ previousPath, currentPath, isMounted, setMounted }}>
      {children}
    </PathContext.Provider>
  );
};

export const usePath = (): PathValues => {
  return useContext(PathContext);
};
        `),
            },
        };
    }
    static generateAssets({ appDSL, assetBackendList, useAssetDirectImports, }) {
        const assetsGenerator = new AssetsGenerator({
            appDSL,
            assetBackendList,
            useAssetDirectImports,
        });
        return {
            'src/assets/assets.json': {
                content: assetsGenerator.generateAssetArray(),
            },
        };
    }
    static generateRouteHooks(appDSL) {
        const routeHooksGenerator = new RouteHooksGenerator(appDSL);
        return {
            'src/shared/hooks/useAfterEach.ts': {
                content: formatCode(`
          ${routeHooksGenerator.generateUseAfterEachHookCode()}
        `),
            },
            'src/shared/hooks/useBeforeEach.ts': {
                content: formatCode(`
          ${routeHooksGenerator.generateUseBeforeEachHookCode()}
        `),
            },
        };
    }
    static generateCSS({ appDSL, assetBackendList, useAssetDirectImports, }) {
        const globalCssGenerator = new GlobalCSSGenerator({
            appDSL,
            assetBackendList,
            useAssetDirectImports,
        });
        return globalCssGenerator.generateFileStructure();
    }
    static generateApp({ appDSL: _appDSL, componentListDSL, assetBackendList, assetListDSL, useAssetDirectImports, isPreview = false, }) {
        const localAndGlobalStates = Object.keys(_appDSL.nodes).reduce((allStates, key) => {
            var _a, _b, _c;
            if (((_a = _appDSL.nodes[key]) === null || _a === void 0 ? void 0 : _a.context) && ((_c = Object.keys(((_b = _appDSL.nodes[key]) === null || _b === void 0 ? void 0 : _b.context) || {})) === null || _c === void 0 ? void 0 : _c.length)) {
                return Object.assign(Object.assign({}, allStates), _appDSL.nodes[key].context);
            }
            return allStates;
        }, _appDSL.states || {});
        const appDSL = Object.assign(Object.assign({}, _appDSL), { states: localAndGlobalStates });
        const componentsStructureGenerator = new ComponentFileListUsageGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, PROJECT_TEMPLATE), { 'public/index.html': {
                content: HtmlTemplatesGenerator.getIndexHtml(appDSL),
            }, 'public-esbuild/index.html': {
                content: HtmlTemplatesGenerator.getIndexHtmlEsbuild(appDSL, isPreview),
            }, 'src/App.tsx': {
                content: CodeEngine.generateAppRootCode({
                    appDSL,
                    componentListDSL,
                    assetBackendList,
                    assetListDSL,
                }),
            }, '.env': {
                content: CodeEngine.generateEnvTemplate(appDSL),
            }, 'package.json': {
                content: CodeEngine.generatePackageJsonTemplate(appDSL),
            } }), componentsStructureGenerator.generateComponentsFileStructure(appDSL)), CodeEngine.generateConstants(appDSL)), CodeEngine.generateTypes(appDSL)), CodeEngine.generateHooks({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        })), CodeEngine.generateSymbols({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        })), CodeEngine.generateProviders({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
            useAssetDirectImports,
            isPreview,
        })), CodeEngine.generateAssets({
            appDSL,
            assetBackendList,
            useAssetDirectImports,
        })), CodeEngine.generateRouteHooks(appDSL)), CodeEngine.generateCSS({
            appDSL,
            assetBackendList,
            useAssetDirectImports,
        }));
    }
}
CodeEngine.addLuxonLibrary = (libraryArrayDSL) => libraryArrayDSL.find(library => library.name === 'luxon') ? '' : `"luxon": "1.28.0",`;
CodeEngine.addLodashLibrary = (libraryArrayDSL) => libraryArrayDSL.find(library => library.name === 'lodash') ? '' : `"lodash": "^4.17.21",`;
CodeEngine.addLuxonTypes = (libraryArrayDSL) => libraryArrayDSL.find(library => library.name === 'luxon') ? '' : `   `;
CodeEngine.generateEnvTemplate = (appDSL) => {
    var _a;
    const endpoint = (_a = resourceListSelectors
        .getEightBaseResourceArrayDSL(appDSL.resources)
        .map(resource => resourceSelectors.getEightbaseResourceApiURL(resource))) === null || _a === void 0 ? void 0 : _a[0];
    return `REACT_APP_SERVER_URL=${endpoint}
FAST_REFRESH=false
TSC_COMPILE_ON_ERROR=true
ESLINT_NO_DEV_ERRORS=true
GENERATE_SOURCEMAP=false
`;
};
CodeEngine.generateUseRoutesTemplate = (appDSL) => {
    const pagesTransformed = serialize
        .stringify(nodeListSelectors
        .getAllRouteNodes(appDSL.nodes)
        .map(routeNodeDSL => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return ({
            path: routeNodeDSL.props.path,
            beforeRouteEnter: {
                type: (_a = routeNodeDSL.props.routerHooks) === null || _a === void 0 ? void 0 : _a.beforeRouteEnter,
                code: (_b = routeNodeDSL.props.routerHooks) === null || _b === void 0 ? void 0 : _b.beforeRouteEnterCode,
                request: (_c = routeNodeDSL.props.routerHooks) === null || _c === void 0 ? void 0 : _c.beforeRouteEnterRequestName,
                function: (_d = routeNodeDSL.props.routerHooks) === null || _d === void 0 ? void 0 : _d.beforeRouteEnterFunctionName,
            },
            beforeRouteUpdate: {
                type: (_e = routeNodeDSL.props.routerHooks) === null || _e === void 0 ? void 0 : _e.beforeRouteUpdate,
                code: (_f = routeNodeDSL.props.routerHooks) === null || _f === void 0 ? void 0 : _f.beforeRouteUpdateCode,
                request: (_g = routeNodeDSL.props.routerHooks) === null || _g === void 0 ? void 0 : _g.beforeRouteUpdateRequestName,
                function: (_h = routeNodeDSL.props.routerHooks) === null || _h === void 0 ? void 0 : _h.beforeRouteUpdateFunctionName,
            },
            beforeRouteExit: {
                type: (_j = routeNodeDSL.props.routerHooks) === null || _j === void 0 ? void 0 : _j.beforeRouteExit,
                code: (_k = routeNodeDSL.props.routerHooks) === null || _k === void 0 ? void 0 : _k.beforeRouteExitCode,
                request: (_l = routeNodeDSL.props.routerHooks) === null || _l === void 0 ? void 0 : _l.beforeRouteExitRequestName,
                function: (_m = routeNodeDSL.props.routerHooks) === null || _m === void 0 ? void 0 : _m.beforeRouteExitFunctionName,
            },
            title: routeNodeDSL.props.title,
            exact: routeNodeDSL.props.exact,
            strict: routeNodeDSL.props.strict,
            fragment: {},
            // eslint-disable-next-line no-underscore-dangle
            meta: routeNodeDSL.props.__meta,
            // eslint-disable-next-line no-underscore-dangle
            params: routeNodeDSL.props.__defaultPathParams,
            name: camelize(routeNodeDSL.alias ? routeNodeDSL.alias.replace('Wrapper', '') : ''),
        });
    })
        .reduce((acc, curr) => {
        return Object.assign(Object.assign({}, acc), { [curr.name]: Object.assign(Object.assign({}, omit(['params', 'fragment'], curr)), { meta: (curr === null || curr === void 0 ? void 0 : curr.meta)
                    ? Object.entries(curr.meta).reduce((prev, [name, value]) => {
                        return Object.assign(Object.assign({}, prev), { [name]: hasPropJsCode(value)
                                ? `####${transformPropWithJsCode(value, {
                                    outputMode: 'backticks',
                                })}####`
                                : value });
                    }, {})
                    : {} }) });
    }, {}))
        .replaceAll(/"####\$\{(.+?)\}####"/gi, '$1');
    const globalStateNames = stateListSelectors
        .getGlobalStateArrayDSL(appDSL.states)
        .map(i => i.name);
    return formatCode(`
    import { useCallback } from 'react';

    import { matchPath, useLocation, useHistory } from 'react-router-dom';

    import { APP_ROUTES } from 'shared/constants';

    import { match } from 'path-to-regexp';

    import { omit } from 'ramda';
    import { useGlobalState, usePath } from 'providers';

    export type RouteList = {
      path: string;
      title: string;
      exact?: boolean;
      strict?: boolean;
      meta: Record<string, unknown>;
      name: string;
    };

    export type Route = {
      path: string;
      title?: string;
      exact?: boolean;
      strict?: boolean;
      params: Record<string, unknown>;
      fragment: Record<string, unknown>;
      queryString?: Record<string, unknown>;
      meta: Record<string, unknown>;
      name: string;
    };

    type Routes = Route | Route[];

    export type RouterReturnType = {
      list: RouteList[];
      [routeName: string]: Routes;
      currentRoute?: Route;
      navigate: (path: string | Route, params?: Record<string, string>) => void;
      routerLocation: string;
      prevLocation: string;
    };

    function camelize(str: string) {
      return (
        str
          // eslint-disable-next-line func-names
          .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          })
          .replace(/\s+/g, '')
      );
    }

    const DEFAULT_ROUTE = {
      path: '',
      title: '',
      exact: false,
      strict: false,
      params: {},
      fragment: {},
      meta: {},
      name: '',
    };

    const getCurrentRouteFragments = (hashPath: string): Record<string, unknown> => {
      if (hashPath) {
        const hash = hashPath.substring(1);
        const fragments = hash.split('&').reduce((res, item) => {
          const [n, v] = item.split('=');
          return { ...res, [n]: v };
        }, {});

        return fragments;
      }

      return {};
    };

    const getCurrentRouteParams = (
      currentRoutePath: string,
      locationPath: string,
    ): Record<string, unknown> => {
      const parsedCurrentRoutePath = match(currentRoutePath, { decode: decodeURIComponent });
      const parsedPath = parsedCurrentRoutePath(locationPath);
      if (parsedPath !== false) {
        return parsedPath.params as Record<string, unknown>;
      }

      return {};
    };

  export const useRoutes = (): RouterReturnType => {
    const routerLocation = useLocation();
    const routerHistory = useHistory();
    const { currentPath, previousPath } = usePath();

    const {${globalStateNames.join()}} = useGlobalState();

    const previousRoute =
      APP_ROUTES.find(route => matchPath(previousPath || '', { path: route.path, exact: true })) ||
      DEFAULT_ROUTE;

    const currentRoute =
      APP_ROUTES.find(route => matchPath(currentPath === '' ? routerLocation.pathname : currentPath, { path: route.path, exact: true })) ||
      DEFAULT_ROUTE;

    const currentParams = getCurrentRouteParams(currentRoute.path, routerLocation.pathname);
    const currentFragments = getCurrentRouteFragments(routerLocation.hash);

    const urlSearchQueryString = new URLSearchParams(routerLocation.search);
    const urlSearchQueryStringParams = Object.fromEntries(urlSearchQueryString.entries());
    const currentRouteWithParams = {
      ...currentRoute,
      params: currentParams,
      fragment: currentFragments,
      queryString: urlSearchQueryStringParams,
    };



    const pages = ${pagesTransformed}

    const pagesList = APP_ROUTES.map(route => omit(['params', 'fragment'], route));
    const navigate = useCallback(
      (path: string | Route, params?: Record<string, string>) => {
        const routePath = path instanceof Object ? path.path : path;
        const paramsKeys = Object.keys(params || {});
        const { nextPath, query } = paramsKeys.reduce(
          (accum, key) => {
            const isParamInPath = accum.nextPath.includes('/:' + key);
            const paramValue = params?.[key] || '';
            const next = accum.nextPath.replace(':' + key, paramValue);
            const paramsArray = [...accum.query];
            if (!isParamInPath) {
              paramsArray.push(key + '=' + paramValue);
            }

            return {
              nextPath: next,
              query: paramsArray,
            };
          },
          { nextPath: routePath, query: [] as string[] },
        );
        const pathToPush = query.length ? nextPath + '?' + query.join('&&') : nextPath;

        routerHistory.push(pathToPush);
      },
      [routerHistory],
    );
      return {
    list: pagesList,
    currentRoute: currentRouteWithParams,
    routerLocation: JSON.stringify(currentRoute),
    prevLocation: JSON.stringify(previousRoute),
    navigate,
    ...pages,
  };
};
    `);
};
CodeEngine.generateUseRouterHooksTemplate = (appDSL) => {
    const libraryArrayDSL = libraryListSelectors.getLibraryArrayDSL(appDSL.libraries || {});
    const importMap = libraryArrayDSL.map((library) => `import * as ${library.alias} from '${library.name}';`);
    const librariesArgs = libraryArrayDSL.map((library) => library.alias).join(',');
    return formatCode(`
    import { useRef, useState, useEffect } from 'react';

    import { useHistory, matchPath, useLocation } from 'react-router-dom';
    import { useRoutes, useBeforeEach, useAfterEach, useResources, useLibraries } from 'shared/hooks';
    import { useTheme } from '@emotion/react';
    import { useAuth } from '@8base-react/auth';
    import { useGlobalState, usePath, useAssets } from '../../../providers';
    ${importMap.join('\n')}

    const HOOK_TYPES = {
      code: 'code',
      request: 'request',
      function: 'function'
    } as const;

    const DEFAULT_HOOK_FUNCTION = 'return function() {return}';
    const hooksParams = ['from', 'to', 'state']
    const SESSION_STORAGE_KEY = 'previousLocation';
    const SESSION_PATHNAME_STORAGE_KEY = 'previouspathname';

    const getRouteParams = ({ pattern, routeLocation }: { pattern: string; routeLocation: Location }) => {
        const match = matchPath(routeLocation.pathname, { path: pattern });
        const currentParams = match?.params;
        const currentFragments = routeLocation.hash.slice(1);
        const urlSearchQueryString = new URLSearchParams(routeLocation.search);
        const urlSearchQueryStringParams = Object.fromEntries(urlSearchQueryString.entries());

        return {
            params: currentParams,
            fragment: currentFragments,
            queryString: urlSearchQueryStringParams,
        };
    };

    const areObjectsDifferent = (
      obj1: { params: never; queryString: never },
      obj2: { params: never; queryString: never },
    ): boolean => {
      const params1 = JSON.stringify(obj1.params);
      const params2 = JSON.stringify(obj2.params);
      const queryString1 = JSON.stringify(obj1.queryString);
      const queryString2 = JSON.stringify(obj2.queryString);
      return params1 !== params2 || queryString1 !== queryString2;
    };

    const FilterPathLayout = (path: string[]): boolean => {
        return path?.some((item: string | string[]) => item.includes('/__layouts'));
    };

    const transformCode = (code) => {
      const wrapperRegex = /return\\s+function\\s*\\(\\s*\\{[^}]*\\}\\s*\\)\\s*{\\s*([\\s\\S]*)\\s*}/;
      const wrapperRegexAsync = /return async\\s+function\\s*\\(\\s*\\{[^}]*\\}\\s*\\)\\s*{\\s*([\\s\\S]*)\\s*}/;

        return code?.replace(wrapperRegex, "$1")?.replace(wrapperRegexAsync, "$1") ?? code;
      };

    const AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function () {}).constructor',
      )();

    export function useRouterHooks(path, localStates = {}) {
      const libraries = useLibraries();
      const beforeEach = useBeforeEach();
      const afterEach = useAfterEach();
      const router = useRoutes();
      const routerHistory = useHistory();
      const resources = useResources();
      const { currentRoute, routerLocation: currentLocation, prevLocation } = router;
      const routerLocation = useLocation();
      const theme = useTheme();
      const auth = useAuth();
      const assets = useAssets();

      const { currentPath, isMounted, setMounted } = usePath();
      const [waitingHooks, setWaitingHooks] = useState(true);
      const [data, setCallback] = useState({});
      const librariesAlias = Object.values(libraries ?? {}).map(({ alias }) => alias);
      const globalState = useGlobalState();
      const state = { ...globalState, router, resources, ...localStates, auth, routerLocation, routerHistory, theme, assets };
      const functionParams = '{' + [...hooksParams, ...Object.keys(state), ...librariesAlias] + '}';
      const beforeEachFunction = new AsyncFunction(
        functionParams,
        transformCode(beforeEach?.code) || DEFAULT_HOOK_FUNCTION,
      );
      const afterEachFunction = new AsyncFunction(
        functionParams,
        transformCode(afterEach?.code) || DEFAULT_HOOK_FUNCTION,
      );
      const beforeRouteEnter = new AsyncFunction(
        functionParams,
        transformCode(currentRoute?.beforeRouteEnter?.code) || DEFAULT_HOOK_FUNCTION,
      );
      const beforeRouteExit = new AsyncFunction(
        functionParams,
        transformCode(currentRoute?.beforeRouteExit?.code) || DEFAULT_HOOK_FUNCTION,
      );
      const beforeRouteUpdate = new AsyncFunction(
        functionParams,
        transformCode(currentRoute?.beforeRouteUpdate?.code) || DEFAULT_HOOK_FUNCTION,
      );

      useEffect(() => {
        // Verifies we are checking against the actual route and not a layout
        const isActualRoute =
          matchPath(currentPath, {
            path: path,
            exact: true,
          }) && !FilterPathLayout(path);
        if (isActualRoute) {
          const parsedPrevUpdateLocation = JSON.parse(
            window.sessionStorage.getItem(SESSION_PATHNAME_STORAGE_KEY) || '{}',
          );
          const parsedCurrentLocation = currentLocation ? JSON.parse(currentLocation) : '';
          const isSamePath = matchPath(currentPath, {
            path: parsedPrevUpdateLocation?.path,
            exact: true,
          });
          const actualLocation = {
            ...parsedCurrentLocation,
            ...getRouteParams({
              pattern: parsedCurrentLocation.path as string,
              routeLocation: routerHistory.location,
            }),
          };
    
          const sameRouteWithDifferentParams =
            isSamePath && areObjectsDifferent(actualLocation, parsedPrevUpdateLocation);
          if (sameRouteWithDifferentParams && currentRoute.beforeRouteUpdate?.type) {
            switch (currentRoute.beforeRouteUpdate?.type) {
              case HOOK_TYPES.code:
                beforeRouteUpdate({ from: parsedPrevUpdateLocation, to: actualLocation, state, ...state, ${librariesArgs} });
                break;
    
              case HOOK_TYPES.request:
                state[currentRoute.beforeRouteUpdate?.request]?.run()
                break;
    
              case HOOK_TYPES.function:
                state[currentRoute?.beforeRouteUpdate?.function ?? '']?.(
                  parsedPrevUpdateLocation,
                  actualLocation,
                  state,
                );
                break;
    
              default:
                break;
            }
          }
          window.sessionStorage.setItem(SESSION_PATHNAME_STORAGE_KEY, JSON.stringify(actualLocation));
        }
      }, [currentPath]);

      useEffect(() => {
        const parsedCurrentLocation = currentLocation ? JSON.parse(currentLocation) : '';
        const prevLocation = window.sessionStorage.getItem(SESSION_STORAGE_KEY);
        const previousLocationWithParams = prevLocation ? JSON.parse(prevLocation) : '';
        const skipHooks =
          !parsedCurrentLocation ||
          FilterPathLayout(path as string[]) ||
          isMounted ||
          path?.[0] !== parsedCurrentLocation.path;

        if (skipHooks) return;

        setMounted(true);

        const currentLocationWithParams = {
            ...parsedCurrentLocation,
            ...getRouteParams({
                pattern: parsedCurrentLocation.path as string,
                routeLocation: routerHistory.location,
            }),
        };

        window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(currentLocationWithParams));

        setWaitingHooks(false);
        setCallback({
          runBeforeEach: () => {
            switch (beforeEach.type) {
              case HOOK_TYPES.code:
                beforeEachFunction({ from: previousLocationWithParams, to: currentLocationWithParams, state, ...state, ${librariesArgs} });
                break;

              case HOOK_TYPES.request:
                state[beforeEach?.requestName]?.run();
                break;

              case HOOK_TYPES.function:
                state[beforeEach?.functionName ?? '']?.(
                  previousLocationWithParams,
                  currentLocationWithParams,
                  state,
                );
                break;

              default:
                break;
            }

            switch (currentRoute.beforeRouteEnter?.type) {
              case HOOK_TYPES.code:
                  beforeRouteEnter({ from: previousLocationWithParams, to: currentLocationWithParams, state, ...state, ${librariesArgs} });
                break;

              case HOOK_TYPES.request:
                state[currentRoute.beforeRouteEnter?.request]?.run();
                break;

              case HOOK_TYPES.function:
                state[currentRoute?.beforeRouteEnter?.function ?? '']?.(
                  previousLocationWithParams,
                  currentLocationWithParams,
                  state,
                );
                break;

              default:
                break;
            }

            setWaitingHooks(true);
          },
          runAfterEach: () => {
            switch (afterEach.type) {
              case HOOK_TYPES.code:
                afterEachFunction({ from: previousLocationWithParams, to: currentLocationWithParams, state, ...state, ${librariesArgs} });
                break;

              case HOOK_TYPES.request:
                state[afterEach?.requestName]?.run();
                break;

              case HOOK_TYPES.function:
                state[afterEach?.functionName ?? '']?.(
                  previousLocationWithParams,
                  currentLocationWithParams,
                  state,
                );
                break;

              default:
                break;
            }
          },
        });

        return () => {
          if (isMounted) return;

          switch (currentRoute.beforeRouteExit?.type) {
            case HOOK_TYPES.code:
              beforeRouteExit({ from: previousLocationWithParams, to: currentLocationWithParams, state, ...state, ${librariesArgs} });
              break;

            case HOOK_TYPES.request:
              state[currentRoute.beforeRouteExit?.request]?.run();
              break;

            case HOOK_TYPES.function:
              state[currentRoute?.beforeRouteExit?.function ?? '']?.(
                previousLocationWithParams,
                currentLocationWithParams,
                state,
              );
              break;

            default:
              break;
          }

          setWaitingHooks(true);

        };
      }, [currentLocation]);

      return { waitingHooks, data };
    }

    `);
};
CodeEngine.generatePackageJsonTemplate = (appDSL) => {
    const libraryArrayDSL = libraryListSelectors.getLibraryArrayDSL(appDSL.libraries || {});
    const libraries = [];
    const librariesTypes = [];
    libraryArrayDSL.forEach((libraryDSL) => {
        const libraryTypesVersion = librarySelectors
            .getLibraryTypesVersion(libraryDSL)
            .split('@')
            .pop();
        libraries.push(`    "${libraryDSL.name}": "${libraryDSL.version}",`);
        librariesTypes.push(`    "@types/${libraryDSL.name}": "${libraryTypesVersion}",`);
    });
    // TODO: add when codeSandbox get stabilized in dependency
    // "filestack-js": "3.7.0",
    const packageJson = `
    {
      "version": "1.0.0",
      "name": "eight-base-builder-app",
      "main": "src/index.tsx",
      "keywords": [
        "typescript",
        "react",
        "8base"
      ],
      "scripts": {
        "test": "./node_modules/.bin/react-scripts test --env=jsdom",
        "start": "./node_modules/.bin/react-scripts start",
        "eject": "./node_modules/.bin/react-scripts eject",
        "build": "./node_modules/.bin/react-scripts build",
        "build:esbuild": "node ./scripts/build-esbuild.mjs",
        "lint": "./node_modules/.bin/eslint \\"src/**/*.{js,jsx,ts,tsx}\\"",
        "lint:fix": "npm run lint --fix",
        "typecheck": "./node_modules/.bin/tsc --noEmit"
      },
      "dependencies": {
        ${libraries.join('\n')}
        ${CodeEngine.addLodashLibrary(libraryArrayDSL)}
        ${CodeEngine.addLuxonLibrary(libraryArrayDSL)}
        "@8base-react/app-provider": "^2.5.2",
        "@8base-react/auth": "^2.5.2",
        "@apollo/client": "3.7.1",
        "@emotion/core": "^11.0.0",
        "@emotion/react": "^11.1.4",
        "@emotion/styled": "^11.0.0",
        "@mui/icons-material": "^5.2.1",
        "@mui/material": "5.10.7",
        "@mui/x-data-grid": "^5.1.0",
        "@mui/x-date-pickers": "5.0.19",
        "@ckeditor/ckeditor5-react": "^6.1.0",
        "ckeditor5-custom": "git://github.com/8base/CKEditor",
        "filestack-js": "3.7.0",
        "axios": "0.26.1",
        "dayjs": "1.11.6",
        "formik": "2.2.6",
        "google-map-react": "2.2.1",
        "graphql": "15.5.0",
        "graphql-request": "^3.4.0",
        "lodash.debounce": "^4.0.8",
        "material-ui-popup-state": "1.9.0",
        "nanoid": "^3.1.25",
        "ramda": "^0.27.1",
        "react": "17.0.2",
        "react-dom": "17.0.2",
        "react-helmet": "^6.1.0",
        "react-html-string": "0.1.1",
        "react-router-dom": "5.2.0",
        "path-to-regexp": "^6.2.1",
        "qs": "^6.11.2",
        "yup": "^1.2.0"
      },
      "devDependencies": {
        ${librariesTypes.join('\n')}
        ${CodeEngine.addLuxonTypes(libraryArrayDSL)}
        "@emotion/babel-preset-css-prop": "11.2.0",
        "@types/ramda": "^0.27.1",
        "@types/react": "17.0.2",
        "@types/react-dom": "17.0.2",
        "@types/react-helmet": "^6.1.5",
        "@types/react-router-dom": "5.1.9",
        "@typescript-eslint/eslint-plugin": "^4.9.0",
        "@typescript-eslint/parser": "^4.9.0",
        "@types/google-map-react": "^2.1.7",
        "dotenv": "^16.0.1",
        "esbuild": "^0.14.45",
        "eslint": "7.15.0",
        "eslint-config-airbnb": "18.2.1",
        "eslint-config-prettier": "7.0.0",
        "eslint-config-react-app": "6.0.0",
        "eslint-plugin-flowtype": "5.2.0",
        "eslint-plugin-import": "2.22.1",
        "eslint-plugin-jsx-a11y": "6.4.1",
        "eslint-plugin-prettier": "3.2.0",
        "eslint-plugin-react": "7.22.0",
        "eslint-plugin-react-hooks": "4.2.0",
        "eslint-plugin-unused-imports": "1.1.5",
        "fs-extra": "^10.1.0",
        "glob-promise": "^4.2.2",
        "nunjucks": "^3.2.3",
        "react-scripts": "4.0.2",
        "typescript": "4.1.3"
      },
      "browserslist": [
        ">0.2%",
        "not dead",
        "not ie <= 11",
        "not op_mini all"
      ]
    }
  `;
    return packageJson;
};
