import { values, isEmpty, uniq } from 'ramda';
import { componentListSelectors, COMPONENT_DSL_KINDS, nodeListSelectors, appNodesSchemaSelectors, } from '@builder/schemas';
import { ComponentFileAccessor } from '../..';
import { LocalStateContainerGenerator } from '../../app-states-generator';
import { CodeNodeBaseGenerator } from './CodeNodeBaseGenerator';
export class CodeNodeGenerator extends CodeNodeBaseGenerator {
    constructor({ appDSL, componentListDSL, assetListDSL, options, }) {
        super({ nodeListDSL: appDSL.nodes, componentListDSL, assetListDSL, options });
        this.appDSL = appDSL;
    }
    /**
     * Adds addiction logic after regular prop transformation.
     * Path is overrode to pass all children routes inside them.
     */
    afterLayoutKindPropTransformation(nodeDSL, propsMap) {
        const allChildrenRouteNodes = nodeListSelectors.getAllChildrenRouteNodes(this.nodeListDSL, {
            nodeID: nodeDSL.id,
            componentListDSL: this.componentListDSL,
        });
        return Object.assign(Object.assign({}, propsMap), { path: `{[${allChildrenRouteNodes.map(node => `"${node.props.path.trim()}"`).join(',')}]}` });
    }
    /**
     * Adds addiction logic after regular prop transformation.
     * Path is overrode to pass all children routes inside them.
     */
    afterRouteKindPropTransformation(nodeDSL, propsMap) {
        const allChildrenRouteNodes = nodeListSelectors.getAllChildrenRouteNodes(this.nodeListDSL, {
            nodeID: nodeDSL.id,
            componentListDSL: this.componentListDSL,
        });
        const allPaths = [
            nodeDSL.props.path,
            ...allChildrenRouteNodes.map(node => node.props.path),
        ];
        return Object.assign(Object.assign({}, propsMap), { path: `{[${allPaths.map(path => `"${path.trim()}"`).join(',')}]}`, exact: '{true}' });
    }
    afterTransformNodeProp(args) {
        const { nodeDSL } = args;
        const { children, otherPropsMap } = super.afterTransformNodeProp(args);
        let transformedOtherPropsMap = otherPropsMap;
        const componentDSL = componentListSelectors.getComponentDSL(this.componentListDSL, {
            componentName: nodeDSL.name,
        });
        if (componentDSL.kind === COMPONENT_DSL_KINDS.layout && nodeDSL.id) {
            transformedOtherPropsMap = this.afterLayoutKindPropTransformation(nodeDSL, transformedOtherPropsMap);
        }
        if (componentDSL.kind === COMPONENT_DSL_KINDS.route && nodeDSL.id) {
            transformedOtherPropsMap = this.afterRouteKindPropTransformation(nodeDSL, transformedOtherPropsMap);
        }
        return { children, otherPropsMap: transformedOtherPropsMap };
    }
    generateNodeBasic(nodeDSL, { outputMode }) {
        const nodeText = super.generateNodeBasic(nodeDSL, { outputMode });
        const localStateContainerGenerator = new LocalStateContainerGenerator({
            appDSL: this.appDSL,
            componentListDSL: this.componentListDSL,
            nodeDSL,
            assetListDSL: this.assetListDSL,
        });
        if (!localStateContainerGenerator.hasLocalStates()) {
            return nodeText;
        }
        return localStateContainerGenerator.wrapInLocalStateContainerCall(nodeText);
    }
    getStatesFilteredByWrapperNode(nodeDSL) {
        return values(this.appDSL.states).filter(state => {
            if (state.scope === CodeNodeGenerator.LOCAL) {
                return state.parent === nodeDSL.parentID;
            }
            return undefined;
        });
    }
    static getStates(nodeDSL, appDSL) {
        return values(appDSL.states).filter(state => {
            if (state.scope === CodeNodeGenerator.LOCAL) {
                return state.parent === nodeDSL.id;
            }
            return undefined;
        });
    }
    static getParentLayoutStates(nodeID, appDSL) {
        var _a;
        if (!nodeID)
            return [];
        const currentNode = appDSL.nodes[nodeID];
        const isLayoutNodeDSL = (_a = currentNode.props.path) === null || _a === void 0 ? void 0 : _a.includes('/__layouts/');
        if (currentNode.context && isLayoutNodeDSL) {
            return CodeNodeGenerator.getStates(currentNode, appDSL).map(state => state.name);
        }
        return CodeNodeGenerator.getParentLayoutStates(currentNode.parentID, appDSL);
    }
    generateNode(nodeID, { outputMode }) {
        const nodeDSL = nodeListSelectors.getNodeDSL(this.appDSL.nodes, { nodeID });
        const codeFileStructure = appNodesSchemaSelectors.geCodeFileStructure({
            componentListDSL: this.componentListDSL,
            nodeListDSL: this.appDSL.nodes,
        }, {
            nodeID,
        });
        if (codeFileStructure && nodeDSL.id !== this.rootNodeID) {
            const componentFileAccessor = new ComponentFileAccessor({
                appDSL: this.appDSL,
                componentListDSL: this.componentListDSL,
                nodeID: nodeDSL.id,
            });
            const stateNames = this.getStatesFilteredByWrapperNode(nodeDSL).map(state => state.name);
            const layoutStates = CodeNodeGenerator.getParentLayoutStates(nodeID, this.appDSL);
            const allStates = isEmpty(layoutStates) ? stateNames : [...stateNames, ...layoutStates];
            return `
        <${componentFileAccessor.getComponentName()} ${isEmpty(allStates) ? '' : `props={{${uniq(allStates).join(', ')}}}`} />
      `;
        }
        return super.generateNode(nodeID, { outputMode });
    }
}
CodeNodeGenerator.LOCAL = 'local';
