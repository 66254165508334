import { componentListSelectors, nodeListSelectors, nodeSelectors } from '../../selectors';
/**
 * The main goal of this function to find parentID if it does not exist or incorrect.
 */
const getParentID = ({ nodeListDSL, nodeID, componentListDSL, }) => {
    const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        const allImmediateChildrenNodes = nodeSelectors.getAllImmediateChildrenIDs(nodeDSL, {
            componentListDSL,
        });
        const isParent = allImmediateChildrenNodes.some(id => targetNodeDSL.id === id);
        return isParent ? nodeDSL.id : accum;
    }, null);
};
export const addParentIDToAllNodes = (nodeListDSL, componentListDSL) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        const parentID = getParentID({
            nodeListDSL: nodeListDSL,
            nodeID: nodeDSL.id,
            componentListDSL,
        });
        return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: Object.assign(Object.assign({}, nodeDSL), { parentID }) });
    }, {});
};
export const addAliasToAllNodes = ({ nodeListDSL, updateAliasForNodes, componentListDSL, }) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        if (!updateAliasForNodes[nodeDSL.id]) {
            return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL });
        }
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
        });
        const alias = nodeListSelectors.getIncrementedNodeAlias(Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL }), {
            componentDSL,
            nodeID: nodeDSL.id,
        });
        return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: Object.assign(Object.assign({}, nodeDSL), { alias }) });
    }, {});
};
export const makeNodeAliasUnique = (nodeListDSL, componentListDSL) => {
    return Object.values(nodeListDSL).reduce((accum, nodeDSL) => {
        const isNodeAliasUnique = nodeListSelectors.isNodeAliasUnique(Object.assign(Object.assign({}, nodeListDSL), accum), {
            nodeID: nodeDSL.id,
            alias: nodeDSL.alias,
        });
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
        });
        const alias = isNodeAliasUnique
            ? nodeDSL.alias
            : nodeListSelectors.getIncrementedNodeAlias(Object.assign(Object.assign({}, accum), { [nodeDSL.id]: nodeDSL }), {
                componentDSL,
                nodeID: nodeDSL.id,
            });
        return Object.assign(Object.assign({}, accum), { [nodeDSL.id]: Object.assign(Object.assign({}, nodeDSL), { alias }) });
    }, {});
};
export const addParentIDToAllNodesInApp = (appDSL, componentListDSL) => {
    return Object.assign(Object.assign({}, appDSL), { nodes: addParentIDToAllNodes(appDSL.nodes, componentListDSL) });
};
