var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_8_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.8.2';
    }
    getChangeList() {
        return [
            `Radio: make its label editable (as a separate component)`,
            `Radio Group (Form Radio Group): make its label editable (as a separate component)`,
            `Checkbox (Form Checkbox): make its label editable (as a separate component)`,
            `Switch (Form Switch): make its label editable (as a separate component)`,
        ];
    }
    migrateNodeListDSL(nodeListDSL) {
        let createdNodeListDSL = {};
        const labelPrefixID = 'migration_0_8_2_label_node';
        const textPrefixID = 'migration_0_8_2_text_node';
        let labelIDsCounter = 0;
        const createLabelNode = ({ allNodeListDSL, parentID, labelAliasPrefix, labelValue, labelStyle, labelComponentName = 'MaterialTypography', }) => {
            const materialTypographyID = `${labelPrefixID}_${labelIDsCounter}`;
            const materialTypographyAlias = this.generateSecuredNodeAlias(allNodeListDSL, labelAliasPrefix);
            const textID = `${textPrefixID}_${labelIDsCounter}`;
            const textAlias = this.generateSecuredNodeAlias(allNodeListDSL, 'Text');
            labelIDsCounter += 1;
            const predefinedsProps = {
                MaterialTypography: {
                    variant: 'inherit',
                    align: 'inherit',
                    color: 'inherit',
                    paragraph: false,
                },
                MaterialFormLabel: { component: 'label' },
            }[labelComponentName];
            const labelComponentProps = Object.assign(Object.assign({}, predefinedsProps), { children: {
                    nodes: [textID],
                } });
            if (labelStyle) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                labelComponentProps.style = labelStyle;
            }
            return {
                label: {
                    nodes: [materialTypographyID],
                },
                newNodeListDSL: {
                    [materialTypographyID]: {
                        name: labelComponentName,
                        alias: materialTypographyAlias,
                        schemaOverride: {
                            interaction: 'only-editable',
                        },
                        props: labelComponentProps,
                        id: materialTypographyID,
                        parentID,
                    },
                    [textID]: {
                        name: 'Text',
                        props: {
                            children: labelValue,
                        },
                        id: textID,
                        parentID: materialTypographyID,
                        alias: textAlias,
                    },
                },
            };
        };
        const migratedNodeListDSL = mapObjIndexed((nodeDSL, propName) => {
            var _a, _b;
            switch (nodeDSL.name) {
                case 'BuilderComponentsRadio': {
                    const { label: labelValue = '' } = nodeDSL.props;
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Radio Label',
                        labelValue: labelValue,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { label }) });
                }
                case 'BuilderComponentsRadioGroup': {
                    const { label: labelValue = '' } = nodeDSL.props;
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Radio Group Label',
                        labelValue: labelValue,
                        labelComponentName: 'MaterialFormLabel',
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { label }) });
                }
                case 'BuilderComponentsFormRadioGroup': {
                    const radioGroupProps = (((_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.radioGroupProps) || {});
                    const { label: labelValue = '' } = radioGroupProps;
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Radio Group Label',
                        labelValue: labelValue,
                        labelComponentName: 'MaterialFormLabel',
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { radioGroupProps: Object.assign(Object.assign({}, (((_b = nodeDSL.props) === null || _b === void 0 ? void 0 : _b.radioGroupProps) || {})), { label }) }) });
                }
                case 'BuilderComponentsCheckbox': {
                    const _c = (nodeDSL.props || {}), { labelProps, inputProps } = _c, otherCheckboxProps = __rest(_c, ["labelProps", "inputProps"]);
                    const { label: labelValue = '', labelPlacement = 'end', style: labelStyle, } = (labelProps || {});
                    const { style: inputPropsStyle } = (inputProps || {});
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Checkbox Label',
                        labelValue: labelValue,
                        labelStyle,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    const checkboxPropsValue = Object.assign(Object.assign({}, otherCheckboxProps), { label, labelPlacement: labelPlacement });
                    if ((otherCheckboxProps === null || otherCheckboxProps === void 0 ? void 0 : otherCheckboxProps.style) || inputPropsStyle) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        checkboxPropsValue.style = Object.assign(Object.assign({}, ((otherCheckboxProps === null || otherCheckboxProps === void 0 ? void 0 : otherCheckboxProps.style) || {})), inputPropsStyle);
                    }
                    return Object.assign(Object.assign({}, nodeDSL), { props: checkboxPropsValue });
                }
                case 'BuilderComponentsSwitch': {
                    const _d = (nodeDSL.props || {}), { labelProps, inputProps } = _d, otherSwitchProps = __rest(_d, ["labelProps", "inputProps"]);
                    const { label: labelValue = '', labelPlacement = 'end', style: labelStyle, } = (labelProps || {});
                    const { style: inputPropsStyle } = (inputProps || {});
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Switch Label',
                        labelValue: labelValue,
                        labelStyle,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    const switchPropsValue = Object.assign(Object.assign({}, otherSwitchProps), { label, labelPlacement: labelPlacement });
                    if ((otherSwitchProps === null || otherSwitchProps === void 0 ? void 0 : otherSwitchProps.style) || inputPropsStyle) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        switchPropsValue.style = Object.assign(Object.assign({}, ((otherSwitchProps === null || otherSwitchProps === void 0 ? void 0 : otherSwitchProps.style) || {})), inputPropsStyle);
                    }
                    return Object.assign(Object.assign({}, nodeDSL), { props: switchPropsValue });
                }
                case 'BuilderComponentsFormCheckbox': {
                    const { checkboxProps } = (nodeDSL.props || {});
                    const _e = (checkboxProps || {}), { labelProps, inputProps } = _e, otherCheckboxProps = __rest(_e, ["labelProps", "inputProps"]);
                    const { label: labelValue = '', labelPlacement = 'end', style: labelStyle, } = (labelProps || {});
                    const { style: inputPropsStyle } = (inputProps || {});
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Checkbox Label',
                        labelValue: labelValue,
                        labelStyle,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    const checkboxPropsValue = Object.assign(Object.assign({}, otherCheckboxProps), { label, labelPlacement: labelPlacement });
                    if ((otherCheckboxProps === null || otherCheckboxProps === void 0 ? void 0 : otherCheckboxProps.style) || inputPropsStyle) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        checkboxPropsValue.style = Object.assign(Object.assign({}, ((otherCheckboxProps === null || otherCheckboxProps === void 0 ? void 0 : otherCheckboxProps.style) || {})), inputPropsStyle);
                    }
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { checkboxProps: checkboxPropsValue }) });
                }
                case 'BuilderComponentsFormSwitch': {
                    const { switchProps } = (nodeDSL.props || {});
                    const _f = (switchProps || {}), { labelProps, inputProps } = _f, otherSwitchProps = __rest(_f, ["labelProps", "inputProps"]);
                    const { label: labelValue = '', labelPlacement = 'end', style: labelStyle, } = (labelProps || {});
                    const { style: inputPropsStyle } = (inputProps || {});
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Switch Label',
                        labelValue: labelValue,
                        labelStyle,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    const switchPropsValue = Object.assign(Object.assign({}, otherSwitchProps), { label, labelPlacement: labelPlacement });
                    if ((otherSwitchProps === null || otherSwitchProps === void 0 ? void 0 : otherSwitchProps.style) || inputPropsStyle) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        switchPropsValue.style = Object.assign(Object.assign({}, ((otherSwitchProps === null || otherSwitchProps === void 0 ? void 0 : otherSwitchProps.style) || {})), inputPropsStyle);
                    }
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { switchProps: switchPropsValue }) });
                }
                default:
                    return nodeDSL;
            }
        }, nodeListDSL);
        return Object.assign(Object.assign({}, migratedNodeListDSL), createdNodeListDSL);
    }
}
export const migration_0_8_2 = new Migration_0_8_2();
