import React, { useCallback } from 'react';

import { AppRuntimeStateList, AppRuntimeStates } from '@builder/app-engine';
import { BooleanPropValue, JSInjectionPropValue } from '@builder/schemas';

import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { Switch } from 'src/shared/components';

export type CheckboxViewEditorProps<T = BooleanPropValue | JSInjectionPropValue | undefined> = {
  label: string;
  propValue: T;
  onChangePropValue: (propValue: T) => void;
  nodeID?: string;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  disabled?: boolean;
  typeField?: string;
  localStates?: AppRuntimeStateList | AppRuntimeStates;
};

export const CheckboxViewEditor: React.FC<CheckboxViewEditorProps> = ({
  label,
  propValue,
  onChangePropValue,
  nodeID,
  'data-test': dataTest,
  showFx = true,
  fxDefaultEnabled = 'literal',
  disabled = false,
  typeField = 'boolean',
  localStates = {},
}) => {
  const updatePropByEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangePropValue(event.target.checked);
    },
    [onChangePropValue],
  );

  return (
    <JsCodeInjectionInput
      label={label}
      propValue={propValue}
      onChangePropValue={onChangePropValue}
      nodeID={nodeID}
      data-test={dataTest}
      showFx={showFx}
      typeField={typeField}
      fxDefaultEnabled={fxDefaultEnabled}
    >
      {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
        <Switch
          checked={Boolean(nonJsCodePropValue)}
          onChange={updatePropByEvent}
          label={label}
          data-test={dataTest}
          showFx={showFx}
          enableFx={enableFx}
          isFxEnabled={isFxEnabled}
          disabled={disabled}
        />
      )}
    </JsCodeInjectionInput>
  );
};
