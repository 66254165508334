import styled from '@emotion/styled';
import { ListItem, ListItemButton } from '@mui/material';

export const SecondaryActionContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-items: center;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
`;

export const StyledListItemButton = styled(ListItemButton)<{ isSelected: boolean }>`
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ isSelected, theme }) => isSelected && theme.palette.grey[900]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledListItem = styled(ListItem)`
  :hover {
    ${SecondaryActionContainer} {
      opacity: 1;
    }
  }
`;
