/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all } from 'redux-saga/effects';

import {
  watchForErrorNotices,
  watchDnd,
  watchNodeSelection,
  watchRouteChange,
  watchStateChange,
  watchComponentCreate,
} from './watchers';

/**
 * Use saga if your case requires you to work around effects, ie
 * - when an event produces other events
 * - when an event has to be consumed in order for some background effects to kick in
 * - when you have a background work that can produce events on its own
 * - when you have sequential | conditional logic on firing events from other events
 */
export function* saga() {
  yield all([
    watchForErrorNotices(),
    watchDnd(),
    watchNodeSelection(),
    watchRouteChange(),
    watchStateChange(),
    watchComponentCreate(),
  ]);
}
