import { useEffect, createContext, useContext, useReducer } from 'react';

import {
  componentListSelectors,
  componentSelectors,
  NodeElementDataRecords,
  traverseRenderParents,
} from '@builder/schemas';
import { log, ValueOf } from '@builder/utils';

import { useAppDispatch, useComponentListDSL, useNodeListDSL } from '../ReduxProvider';
import { USER_APP_IFRAME_ID } from 'src/shared/constants';
import { getNodeStyle } from 'src/shared/utils';
import { DASHBOARD_EVENTS } from 'src/store';

import { getImmediateChildrenElRectanglesImperative } from './getChildrenRectStrat';

const EFFECTS = {
  reqUpdateMeasures: 'req-update-measures',
  updatingMeasures: '!updating-measures',
  none: null,
} as const;

type OverlayPositionEffects = ValueOf<typeof EFFECTS>;

export type OverlayPositionStore = {
  nodes: NodeElementDataRecords;
  effect: OverlayPositionEffects;
};

export const OVERLAY_POSITION_ACTIONS = {
  updateRegistry: 'update-registry',
  switchToUpdateMeasures: 'switch-to-update-measures',
  wipeRegistry: 'wipe-registry',
  measuringDone: 'measuring-done',
} as const;

export type OverlayPositionAction =
  | {
      type: typeof OVERLAY_POSITION_ACTIONS.updateRegistry;
      toRegister: NodeElementDataRecords;
      toUnregister: NodeElementDataRecords;
    }
  | { type: typeof OVERLAY_POSITION_ACTIONS.switchToUpdateMeasures }
  | { type: typeof OVERLAY_POSITION_ACTIONS.measuringDone }
  | { type: typeof OVERLAY_POSITION_ACTIONS.wipeRegistry };

const initialStore: OverlayPositionStore = { nodes: {}, effect: null };

const OverlayPositionActionContext = createContext<React.Dispatch<OverlayPositionAction>>(
  () => null,
);

export const OverlayPositionProvider: React.FC = ({ children }) => {
  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();
  const send = useAppDispatch();

  const [localStore, localSend] = useReducer(
    (state: OverlayPositionStore, action: OverlayPositionAction): OverlayPositionStore => {
      switch (action.type) {
        case OVERLAY_POSITION_ACTIONS.updateRegistry: {
          // update-registry combines registering and un-registering steps
          // simply out of fear those could be desynchronized;
          // hence, we start by removing (un-registering) the nodes first,
          // and add the new ones (register) after
          const newNodes = Object.keys(action.toUnregister).reduce<NodeElementDataRecords>(
            function preemptivelyRemovingUnregisteredNodes(nodes, renderID) {
              // eslint-disable-next-line no-param-reassign
              delete nodes[renderID];
              return nodes;
            },
            {
              ...state.nodes,
            },
          );

          return {
            ...state,
            nodes: { ...newNodes, ...action.toRegister },
            effect: EFFECTS.reqUpdateMeasures,
          };
        }

        // an additional step to make sure we'll be able to abort measuring
        // if update-registry happens just before the attempt
        case OVERLAY_POSITION_ACTIONS.switchToUpdateMeasures: {
          return {
            ...state,
            effect: EFFECTS.updatingMeasures,
          };
        }

        // an additional step to make sure we'll be able to restart measuring
        // if updateMeasuresRequest | updateMeasuresRequestOnModeSwitch happens
        case OVERLAY_POSITION_ACTIONS.measuringDone: {
          return state.effect === EFFECTS.updatingMeasures
            ? {
                ...state,
                effect: EFFECTS.none,
              }
            : state;
        }

        case OVERLAY_POSITION_ACTIONS.wipeRegistry: {
          return initialStore;
        }

        default:
          return state;
      }
    },
    initialStore,
  );

  useEffect(
    function switchToUpdateMeasures() {
      const updateMeasuresRequest = localStore.effect === EFFECTS.reqUpdateMeasures;

      if (updateMeasuresRequest) {
        // important supporting step to make sure
        // we'll catch incoming update-registry events
        localSend({ type: OVERLAY_POSITION_ACTIONS.switchToUpdateMeasures });
      }
    },
    [localStore.effect],
  );

  useEffect(
    function updateAllMeasuresOnTrigger() {
      const startUpdatingMeasures = localStore.effect === EFFECTS.updatingMeasures;
      if (startUpdatingMeasures) {
        // since that's a RAF it is still possible
        // update-registry will happen just before it
        // (since browser may suspend RAFs non-deterministically);
        // the react then should cancel the frame on sync-update of the effect;
        // but just to be safe we'll add current-effect check clause (state.effect === '!updating-measures')
        // for the update-node-measures event
        const rafID = requestAnimationFrame(() => {
          // RAF is needed to batch all the getBoundingClientRect calls together,
          // to reduce potential layout-trashing effect on the dom

          const newElementDataRecord: NodeElementDataRecords = {};
          const nonSelectableDataRecords: NodeElementDataRecords = {};

          /**
           * (1) Main measurement procedure: taking selectors and requesting their `getBoundingClientRect`.
           * - nonSelectable are recorded as-is for now and will be remeasured after;
           */
          for (const [renderID, nodeElementData] of Object.entries(localStore.nodes)) {
            const iframe = document.querySelector(
              `[data-test="${USER_APP_IFRAME_ID}"]`,
            ) as HTMLIFrameElement;
            const [nodeElement, ...rest] =
              iframe?.contentWindow?.document.querySelectorAll<HTMLElement>(
                nodeElementData.nodeElSelector,
              ) || [];

            if (rest.length) {
              log.warn(
                `Element ${nodeElementData.nodeID} has several (${
                  rest.length + 1
                }) representations!\n` +
                  `Make sure each representation has unique _renderPathData.renderID_!\n` +
                  `Otherwise they won't be covered by an overlay. `,
              );
            }

            const rect = nodeElement?.getBoundingClientRect()?.toJSON() || null;
            const styles = getNodeStyle(nodeElement);

            if (rect === null) {
              nonSelectableDataRecords[renderID] = nodeElementData;
            }

            newElementDataRecord[renderID] = { ...nodeElementData, rect, styles };
          }

          /**
           * (2) nonSelectable do not have their own element (representation),
           * Hence, we either have to use their parent' or children' size.
           */
          const nonSelectableRecordsASC = Object.entries(nonSelectableDataRecords).sort(
            ([_idA, a], [_idB, b]) =>
              // we are sorting to ascending order
              // to make sure parent sizes are all measured before checking children
              a.renderPathData.renderPath.length - b.renderPathData.renderPath.length,
          );

          for (const [renderID, nonSelectableData] of nonSelectableRecordsASC) {
            const nodeDSL = nodeListDSL[nonSelectableData.nodeID];

            if (!nodeDSL) {
              // when this happens, ideally we'll have to run remeasurement again,
              // but then if node managed to disappear just before measurement effect,
              // this probably means the new data is on the way and remeasurement will fire automatically
              // note: if this turns out not to be the case, then we'll have to figure something out...
              log.warn(
                `Cannot measure ${nonSelectableData.nodeID}, no data in DSL registry (potential dsync)!`,
              );
              // eslint-disable-next-line no-continue
              continue;
            }

            const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
              componentName: nodeDSL.name,
            });

            /**
             * (2.1) skipping cases which we manually defined
             */
            if (componentSelectors.hideOverlayForNonSelectableElement(componentDSL)) {
              // eslint-disable-next-line no-continue
              continue;
            }

            /**
             * (2.2.1) trying to scale element from its children' sizes
             *
             * NOTE: this is a difficult flaky strategy, since branches could be deep
             * and children could also be fragments or otherwise non-selectable.
             */
            let aggregatedRect: DOMRect | null = null;
            for (const childElementRect of getImmediateChildrenElRectanglesImperative(
              nonSelectableData,
            )) {
              if (aggregatedRect === null) {
                aggregatedRect = childElementRect;
                // eslint-disable-next-line no-continue
                continue;
              }

              if (aggregatedRect && childElementRect) {
                aggregatedRect = {
                  top:
                    childElementRect.top < aggregatedRect.top
                      ? childElementRect.top
                      : aggregatedRect.top,

                  left:
                    childElementRect.left < aggregatedRect.left
                      ? childElementRect.left
                      : aggregatedRect.left,

                  right:
                    childElementRect.right > aggregatedRect.right
                      ? childElementRect.right
                      : aggregatedRect.right,

                  bottom:
                    childElementRect.bottom > aggregatedRect.bottom
                      ? childElementRect.bottom
                      : aggregatedRect.bottom,
                  // DOMRect also has {x,y,width,height} props
                  // but they could be calculated afterwards
                } as DOMRect;
              }
            }

            /**
             * (2.2.2) if aggregate rect is created we are setting it and moving to the next non-selectable
             */
            if (aggregatedRect) {
              aggregatedRect = {
                ...aggregatedRect,
                x: aggregatedRect.left,
                y: aggregatedRect.top,
                width: aggregatedRect.right - aggregatedRect.left,
                height: aggregatedRect.bottom - aggregatedRect.top,
              };

              newElementDataRecord[renderID] = { ...nonSelectableData, rect: aggregatedRect };
              // eslint-disable-next-line no-continue
              continue;
            }

            /**
             * (2.3-opt) (when prev. strategy didn't work) trying to scale element from its parent' size
             */
            for (const parentRenderPath of traverseRenderParents(
              nonSelectableData.renderPathData,
            )) {
              const parentElementData = newElementDataRecord[parentRenderPath.renderID];
              const parentRect = parentElementData?.rect;

              if (parentRect) {
                newElementDataRecord[renderID] = { ...nonSelectableData, rect: parentRect };
                break;
              }
            }
          }

          /**
           * (3) One everything is measured we are sending an update event to the __ global store __.
           */
          localSend({ type: OVERLAY_POSITION_ACTIONS.measuringDone });
          send({ type: DASHBOARD_EVENTS.updateNodeMeasures, elements: newElementDataRecord });
        });

        return () => cancelAnimationFrame(rafID);
      }
    },
    // we are only reacting on `startUpdatingMeasures` flag in here!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStore.effect],
  );

  return (
    <OverlayPositionActionContext.Provider value={localSend}>
      {children}
    </OverlayPositionActionContext.Provider>
  );
};

export const useOverlayPositionActions = (): React.Dispatch<OverlayPositionAction> =>
  useContext(OverlayPositionActionContext);
