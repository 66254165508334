import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { isObject } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_19_7 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.7';
    }
    getChangeList() {
        return ['Fix styleProps nested in textField and Select components'];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    migrateNodeDSL(nodeDSL) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.BuilderComponentsFormTextField: {
                if (((_c = (_b = (_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.inputProps) === null || _b === void 0 ? void 0 : _b.InputProps) === null || _c === void 0 ? void 0 : _c.style) &&
                    isObject(nodeDSL.props.inputProps.InputProps.style)) {
                    const inputStyle = nodeDSL.props.inputProps.InputProps.style;
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { inputProps: Object.assign(Object.assign({}, nodeDSL.props.inputProps), { style: Object.assign(Object.assign({}, nodeDSL.props.inputProps.style), inputStyle), InputProps: undefined }) }) });
                }
                return nodeDSL;
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsTextField: {
                if (((_e = (_d = nodeDSL.props) === null || _d === void 0 ? void 0 : _d.InputProps) === null || _e === void 0 ? void 0 : _e.style) && isObject((_f = nodeDSL.props.InputProps) === null || _f === void 0 ? void 0 : _f.style)) {
                    const inputStyle = nodeDSL.props.InputProps.style;
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { style: Object.assign(Object.assign({}, nodeDSL.props.style), inputStyle), InputProps: undefined }) });
                }
                return nodeDSL;
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsFormMultiselect:
            case COMPONENT_DSL_NAMES.BuilderComponentsFormSelect: {
                if (((_j = (_h = (_g = nodeDSL.props) === null || _g === void 0 ? void 0 : _g.selectProps) === null || _h === void 0 ? void 0 : _h.SelectDisplayProps) === null || _j === void 0 ? void 0 : _j.style) &&
                    isObject(nodeDSL.props.selectProps.SelectDisplayProps.style)) {
                    const inputStyle = nodeDSL.props.selectProps.SelectDisplayProps.style;
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { selectProps: Object.assign(Object.assign({}, nodeDSL.props.selectProps), { style: Object.assign(Object.assign({}, nodeDSL.props.selectProps.style), inputStyle), SelectDisplayProps: undefined }) }) });
                }
                return nodeDSL;
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsMultiselect:
            case COMPONENT_DSL_NAMES.BuilderComponentsSelect: {
                if (((_l = (_k = nodeDSL.props) === null || _k === void 0 ? void 0 : _k.SelectDisplayProps) === null || _l === void 0 ? void 0 : _l.style) &&
                    isObject(nodeDSL.props.SelectDisplayProps.style)) {
                    const inputStyle = nodeDSL.props.SelectDisplayProps.style;
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { style: Object.assign(Object.assign({}, nodeDSL.props.style), inputStyle), SelectDisplayProps: undefined }) });
                }
                return nodeDSL;
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_19_7 = new Migration_0_19_7();
