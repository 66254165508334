export const RESERVED_WORDS = [
  'eval',
  'uneval',
  'isFinite',
  'isNaN',
  'parseFloat',
  'parseInt',
  'decodeURI',
  'decodeURIComponent',
  'encodeURI',
  'encodeURIComponent',
  'escape',
  'unescape',
  'Object',
  'Function',
  'Boolean',
  'Symbol',
  'Error',
  'EvalError',
  'InternalError',
  'RangeError',
  'ReferenceError',
  'SyntaxError',
  'TypeError',
  'URIError',
  'Number',
  'BigInt',
  'Math',
  'Date',
  'String',
  'RegExp',
  'Array',
  'Map',
  'Set',
  'WeakMap',
  'WeakSet',
  'ArrayBuffer',
  'SharedArrayBuffer',
  'DataView',
  'Float32Array',
  'Float64Array',
  'Int8Array',
  'Int16Array',
  'Int32Array',
  'Uint8Array',
  'Uint8ClampedArray',
  'Uint16Array',
  'Uint32Array',
  'Atomics',
  'JSON',
  'Promise',
  'Generator',
  'GeneratorFunction',
  'AsyncFunction',
  'Reflect',
  'Proxy',
  'Intl',
  'WebAssembly',
  'console',
  'event',
  'location',
  'history',
  'routerHistory',
  'routerLocation',
  'theme',
  'auth',
  'router',
  'assets',
  'requestRunner',
  'resources',
];
