import React from 'react';

import styled from '@emotion/styled';

export const StyledLeftPanelFloatingContainer = styled.div<{ overflow?: string; shadow?: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 100%;
  overflow: auto;
  ${({ shadow, theme }) => (shadow ? `box-shadow: 0 30px 20px 0 ${theme.palette.grey[900]}` : '')};
  padding: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2.5)};

  > * {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  ${({ overflow }) => (overflow ? `overflow: ${overflow}` : '')};
`;

export const LeftPanelFloatingContainer: React.FC<{ overflow?: string; shadow?: boolean }> = ({
  children,
  shadow = true,
  ...props
}) => {
  return (
    <StyledLeftPanelFloatingContainer shadow={shadow} {...props}>
      {children}
    </StyledLeftPanelFloatingContainer>
  );
};
