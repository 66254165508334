import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, disableRadioOptions, DISPLAY_OPTION_VALUES, getBasicPropsSettings, NS_ID, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
export const getBuilderComponentsSliderSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            name: 'label',
                            nodePath: [...prefixPath, 'label', 0],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'name'],
                            label: 'Name',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'arialabel'],
                            label: 'Aria label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'arialabelledby'],
                            label: 'Aria labelled by',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'ariavaluetext'],
                            label: 'Aria value text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'value'],
                            label: 'Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'min'],
                            label: 'Min',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'max'],
                            label: 'Max',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'defaultValue'],
                            label: 'Default Value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'scale'],
                            label: 'Scale',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'step'],
                            label: 'Step',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'getAriaLabel'],
                            label: 'Get Aria Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'getAriaValueText'],
                            label: 'Get Aria Value Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'classes'],
                            name: 'classes',
                            label: 'Classes',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'marks'],
                            name: 'marks',
                            label: 'Marks',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disableSwap'],
                            name: 'disableSwap',
                            label: 'Disable Swap',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            name: 'disabled',
                            label: 'Disabled',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'color'],
                            label: 'Color',
                            name: 'color',
                            options: ['primary', 'secondary', 'error', 'info', 'success', 'warning'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'size'],
                            label: 'Size',
                            name: 'size',
                            options: ['medium', 'small'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'labelPlacement',
                            label: 'Label Placement',
                            keyPath: [...prefixPath, 'labelPlacement'],
                            options: ['top', 'bottom', 'start', 'end'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'track',
                            label: 'Track',
                            keyPath: [...prefixPath, 'track'],
                            options: ['normal', 'inverted', 'false'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'valueLabelDisplay',
                            label: 'Value Label Display',
                            keyPath: [...prefixPath, 'valueLabelDisplay'],
                            options: ['auto', 'off', 'on'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'orientation',
                            label: 'Orientation',
                            keyPath: [...prefixPath, 'orientation'],
                            options: ['horizontal', 'vertical'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'tabIndex'],
                            name: 'tabIndex',
                            label: 'TabIndex',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'valueLabelFormat'],
                            name: 'valueLabelFormat',
                            label: 'ValueLabelFormat',
                        },
                    ],
                },
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Custom sx',
                    name: 'customCSS',
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.cssEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'sx'],
                            name: 'sx',
                            label: 'Slider Styles (sx)',
                        },
                    ],
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])))(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsSliderSettings = getBuilderComponentsSliderSettings();
