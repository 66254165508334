import { ASSETS_ROOT_FOLDER_ID, ASSET_TYPES } from '../../constants';
const transformAssetFolder = ({ assetFolder, assetListDSL, assetBackendList, }) => {
    const { children } = assetFolder;
    return children.reduce((accum, fileID) => {
        var _a, _b, _c, _d, _e, _f;
        if (assetListDSL[fileID].type === ASSET_TYPES.folder) {
            return Object.assign(Object.assign({}, accum), { [assetListDSL[fileID].name]: transformAssetFolder({
                    assetFolder: assetListDSL[fileID],
                    assetListDSL,
                    assetBackendList,
                }) });
        }
        const backendFileID = ((_a = assetListDSL[fileID]) === null || _a === void 0 ? void 0 : _a.backendFileID) || '';
        return Object.assign(Object.assign({}, accum), { [assetListDSL[fileID].name]: Object.assign(Object.assign({}, assetListDSL[fileID]), { src: (_c = (_b = assetBackendList[backendFileID]) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.downloadUrl, mimeType: (_f = (_e = (_d = assetBackendList[backendFileID]) === null || _d === void 0 ? void 0 : _d.file) === null || _e === void 0 ? void 0 : _e.meta) === null || _f === void 0 ? void 0 : _f.mimetype }) });
    }, {});
};
/**
 * @example
 * (
 *    assetListDSL: {
 *     [ASSETS_ROOT_FOLDER_ID]: {
 *          id: ASSETS_ROOT_FOLDER_ID,
 *          type: ASSET_TYPES.folder,
 *          parentID: null,
 *          name: 'assets',
 *          children: ['imagesFolderID', 'imageID_1'],
 *        },
 *        imagesFolderID: {
 *          id: 'imagesFolderID',
 *          name: 'images',
 *          type: ASSET_TYPES.folder,
 *          parentID: ASSETS_ROOT_FOLDER_ID,
 *          children: ['imageID_2'],
 *        },
 *        imageID_1: {
 *          id: 'imageID_1',
 *          name: 'dragon',
 *          type: ASSET_TYPES.image,
 *          parentID: ASSETS_ROOT_FOLDER_ID,
 *          backendFileID: 'backendFileID_imageID_1',
 *        },
 *        imageID_2: {
 *          id: 'imageID_2',
 *          name: 'dog',
 *          type: ASSET_TYPES.image,
 *          parentID: 'imagesFolderID',
 *          backendFileID: 'backendFileID_imageID_2',
 *        },
 *      },
 * ) -> {
 *        dragon: {
 *          id: 'imageID_1',
 *          name: 'dragon',
 *          type: ASSET_TYPES.image,
 *          parentID: ASSETS_ROOT_FOLDER_ID,
 *          backendFileID: 'backendFileID_imageID_1',
 *          downloadUrl: 'downloadUrl_imageID_1',
 *        },
 *        images: {
 *          dog: {
 *            id: 'imageID_2',
 *            name: 'dog',
 *            type: ASSET_TYPES.image,
 *            parentID: 'imagesFolderID',
 *            backendFileID: 'backendFileID_imageID_2',
 *            downloadUrl: 'downloadUrl_imageID_2',
 *          },
 *        }
 *      }
 */
export const transformAssetListToTree = (assetListDSL, assetBackendList) => {
    const assetsRootFolder = assetListDSL[ASSETS_ROOT_FOLDER_ID];
    return transformAssetFolder({ assetFolder: assetsRootFolder, assetListDSL, assetBackendList });
};
