import colorConvert from 'color-convert';
import { CSS_COLOR_MODEL, parseCSSColorModel } from './parseCSSColorModel';
export const getSupportedMuiColor = (color) => {
    if (!color) {
        return undefined;
    }
    const colorModel = parseCSSColorModel(color);
    if (!colorModel) {
        return undefined;
    }
    switch (colorModel.type) {
        case CSS_COLOR_MODEL.rgb:
        case CSS_COLOR_MODEL.rgba:
        case CSS_COLOR_MODEL.hsl:
        case CSS_COLOR_MODEL.hex: {
            return color;
        }
        case CSS_COLOR_MODEL.lab: {
            const [r, g, b] = colorConvert.lab.rgb(colorModel.value);
            const rgbaString = [r, g, b, colorModel.alpha].join(', ');
            return `rgba(${rgbaString})`;
        }
        case CSS_COLOR_MODEL.colorName:
        case CSS_COLOR_MODEL.transparent: {
            const [r, g, b] = colorModel.value;
            const rgbaString = [r, g, b, colorModel.alpha].join(', ');
            return `rgba(${rgbaString})`;
        }
        default: {
            return undefined;
        }
    }
};
