import { keys, values } from 'ramda';

import { StateDSL } from '@builder/schemas';

import { isNumber, isObject } from './common';
import { OBJECT } from './constants';
import { objectsContainArrays } from './objectsContainArrays';
import { MetaStates } from './types';

export const objectsContainObjects = (
  statesValues: StateDSL[],
  statesKeys: string[],
): MetaStates => {
  return statesValues.reduce((accum: MetaStates, current: StateDSL, index: number) => {
    if (isObject(current)) {
      const statesWithArraysInFirstLevel = objectsContainArrays(
        values(current) as StateDSL[],
        keys(current) as string[],
      );

      if (isNumber(statesKeys[index])) return accum;

      return {
        ...accum,
        [statesKeys[index]]: {
          id: null,
          name: statesKeys[index],
          type: OBJECT,
          value: null,
          children: { ...statesWithArraysInFirstLevel },
        },
      };
    }

    return accum;
  }, {});
};
