import { append } from 'ramda';
import { COMPONENT_DSL_TYPES, componentListSelectors, ROOT_NODE_ID, IMPORT_DEPENDENCY_TYPES, } from '@builder/schemas';
import { onlyUnique } from '@builder/utils';
import { IMPORT_FILE_SCOPE_TYPES } from '../../constants';
import { HookListImportsGenerator, PredefinedStateListImportsGenerator, } from '../app-states-generator';
import { AssetListImportsGenerator } from '../assets-generator';
import { ComponentFileAccessor, ComponentFileListImportsGenerator } from '../components-generator';
import { GlobalCSSListImportsGenerator } from '../global-css-generators';
import { ProviderListImportsGenerator } from '../providers-generators';
import { SymbolListImportsGenerator } from '../symbols-generator';
export class ImportsGenerator {
    constructor({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        this.appDSL = appDSL;
        const libraryListDSL = appDSL.libraries || {};
        this.componentListDSL = componentListDSL;
        this.hookImports = new HookListImportsGenerator({ appDSL, componentListDSL, assetListDSL });
        this.predefinedStateImports = new PredefinedStateListImportsGenerator(componentListDSL, libraryListDSL);
        this.symbolsImports = new SymbolListImportsGenerator(componentListDSL);
        this.providersImports = new ProviderListImportsGenerator(appDSL, componentListDSL);
        this.componentsImports = new ComponentFileListImportsGenerator(appDSL, componentListDSL);
        this.assetImports = new AssetListImportsGenerator(appDSL, assetBackendList);
        this.cssImports = new GlobalCSSListImportsGenerator(appDSL, assetBackendList);
    }
    getDSLImports(componentNames) {
        return componentNames
            .filter(onlyUnique)
            .reduce((accum, componentName) => {
            const componentDSL = componentListSelectors.getComponentDSL(this.componentListDSL, {
                componentName,
            });
            if (componentDSL.type !== COMPONENT_DSL_TYPES.reactImportComponent)
                return accum;
            const { importName, codeEngineImportName } = componentDSL.source;
            const importNameForCodeEngine = codeEngineImportName || importName;
            return [
                ...accum,
                {
                    importName: importNameForCodeEngine,
                    varName: componentDSL.source.componentName,
                },
            ];
        }, []);
    }
    /**
     * Converts an import list with an import data to the object with import names in the keys.
     */
    getImportsMap(imports) {
        return imports.reduce((accum, importData) => {
            var _a, _b;
            if (!importData.varName) {
                return Object.assign(Object.assign({}, accum), { [importData.importName]: {
                        empty: true,
                    } });
            }
            switch (importData.importType) {
                case IMPORT_DEPENDENCY_TYPES.module:
                case IMPORT_DEPENDENCY_TYPES.default: {
                    return Object.assign(Object.assign({}, accum), { [importData.importName]: Object.assign(Object.assign({}, accum[importData.importName]), { default: importData.importType === IMPORT_DEPENDENCY_TYPES.module && importData.varNameAlias
                                ? importData.varNameAlias
                                : importData.varName, module: importData.importType === IMPORT_DEPENDENCY_TYPES.module, alias: importData.varNameAlias }) });
                }
                default: {
                    return Object.assign(Object.assign({}, accum), { [importData.importName]: Object.assign(Object.assign({}, accum[importData.importName]), { items: append({ name: importData.varName, alias: importData.varNameAlias }, (_b = (_a = accum[importData.importName]) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []) }) });
                }
            }
        }, {});
    }
    /**
     * @example
     * [{
     *    name: 'assoc',
     *    alias: 'set'
     * },{
     *    name: 'path',
     *    alias: 'get'
     * }] => ['assoc as set', 'path as get']
     */
    convertImportItemsToStrings(importItems = []) {
        return importItems
            .map(importItem => {
            if (importItem.alias && importItem.name !== importItem.alias) {
                return `${importItem.name} as ${importItem.alias}`;
            }
            return importItem.name;
        })
            .filter(onlyUnique);
    }
    joinToStringByCommaSeparator(str) {
        var _a;
        return (_a = str === null || str === void 0 ? void 0 : str.filter(Boolean).join(',')) !== null && _a !== void 0 ? _a : '';
    }
    /**
     * @example
     * {
     *   math: {
     *     module: true,
     *     default: 'math,
     *   },
     *   ramda: {
     *     default: 'R',
     *     items: [{
     *        name: 'assoc',
     *        alias: 'set'
     *     }]
     *   }
     * } => `
     *   import * as math from 'math';
     *   import R, { assoc as set } from 'ramda';
     * `
     */
    generateImportsByMap(importsMap) {
        return Object.keys(importsMap).reduce((accum, importName) => {
            const importData = importsMap[importName];
            const importItemStrings = this.convertImportItemsToStrings(importData.items);
            if (importData.empty) {
                const importLine = `import '${importName}';`;
                return `${accum}\n ${importLine}`;
            }
            const importItems = importItemStrings.length > 0
                ? `{ ${this.joinToStringByCommaSeparator(importItemStrings)} }`
                : '';
            const importAliasName = importData.alias || importData.default;
            const importDefault = importData.module ? `* as ${importAliasName}` : importData.default;
            const importDefaultAndComponents = this.joinToStringByCommaSeparator([
                importDefault,
                importItems,
            ]);
            const importLine = `import ${importDefaultAndComponents} from '${importName}';`;
            return `${accum}\n ${importLine}`;
        }, ``);
    }
    generateImports(imports) {
        const importsMap = this.getImportsMap(imports);
        return this.generateImportsByMap(importsMap);
    }
    generateAppRootImports() {
        const rootComponentFileAccessor = new ComponentFileAccessor({
            appDSL: this.appDSL,
            componentListDSL: this.componentListDSL,
            nodeID: ROOT_NODE_ID,
        });
        const imports = [
            ...this.predefinedStateImports.getImportDataList(IMPORT_FILE_SCOPE_TYPES.appIndex),
            ...this.providersImports.getProvidersImportDataList(),
            rootComponentFileAccessor.getImport(),
            ...this.cssImports.getImportDataList(),
        ];
        return this.generateImports(imports);
    }
    generateHookImports(hookCode) {
        const imports = [...this.predefinedStateImports.getHookImportDataList(hookCode)];
        return this.generateImports(imports);
    }
    generateAssetImports() {
        const imports = this.assetImports.getImportDataList();
        return this.generateImports(imports);
    }
}
