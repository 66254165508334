import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings } from '../../common';
import { pickSettings } from '../../common/helpers';
import { getEventsSection, getPropertySections, getDivider, getCSSSection, getCursorSection, getBorderSection, getBackgroundSection, getFlexChildSection, getSpacingSection, getGridChildSection, } from '../../common/settings';
export const BuilderComponentsFloatingActionButtonSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disabled'],
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['href'],
                            label: 'href',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.iconEditor,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['icon'],
                            label: 'Icon',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['type'],
                            label: 'Type',
                            options: ['button', 'submit', 'reset'].map(createSelectOptionByValue),
                            hasFxButton: true,
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['target'],
                            label: 'Target',
                            options: ['_blank', '_self'].map(createSelectOptionByValue),
                            showIf: [
                                {
                                    keyPath: ['href'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isNotNil,
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            options: ['circular', 'extended', 'string'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['size'],
                            label: 'Size',
                            options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Color',
                            options: ['primary', 'secondary', 'inherit'].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'advanced',
                    title: 'Advanced',
                    titleDescription: {
                        title: 'Advanced',
                        subtitle: 'Additional component-specific attributes',
                    },
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableElevation'],
                            label: 'Disable Elevation',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableFocusRipple'],
                            label: 'Disable Focus Ripple',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableRipple'],
                            label: 'Disable Ripple',
                        },
                    ],
                },
                getDivider(),
                getSpacingSection(),
                getDivider(),
                getFlexChildSection(),
                getDivider(),
                getGridChildSection(),
                getDivider(),
                getBackgroundSection(),
                getDivider(),
                getBorderSection({ prefixPath: [], systemPrefixPath: [] }),
                getDivider(),
                getCursorSection(),
                getDivider(),
                getCSSSection(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
export const getBuilderComponentsButtonSettings = () => pipe(pickSettings([], { withStructure: false }))(values(BuilderComponentsFloatingActionButtonSettings).flat());
