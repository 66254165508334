import { createSelector } from 'reselect';
import { SchemaError, ERROR_SCOPES, incrementName, mustBeCorrectVariableName, } from '@builder/utils';
import { RESOURCE_TYPES } from '../constants';
import { sortAlphabetically } from '../utils/sortAlphabetically';
import * as resourceSelectors from './resource-selectors';
const firstSymbolToUppercase = (str) => str.charAt(0).toUpperCase() + str.slice(1);
const firstSymbolToLowercase = (str) => str.charAt(0).toLowerCase() + str.slice(1);
const getResourceListDSL = (resourceListDSL) => {
    return resourceListDSL;
};
export const getResourceID = (_, props) => props.resourceID;
export const hasResource = createSelector(getResourceListDSL, getResourceID, (resourceListDSL, resourceID) => {
    return resourceID in resourceListDSL;
});
export const getResourceDSL = createSelector(getResourceListDSL, getResourceID, (resourceListDSL, resourceID) => {
    const resourceDSL = resourceListDSL[resourceID];
    if (!resourceDSL) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Resource with id ${resourceID} not found.`);
    }
    return resourceDSL;
});
export const getResourceDSLInfo = createSelector(getResourceDSL, resourceDSL => {
    const resourceType = resourceDSL.type;
    const isRestResource = resourceSelectors.isRestResource(resourceDSL);
    const isEightBaseBackendResource = resourceSelectors.isEightbaseBackendResource(resourceDSL);
    return {
        resourceDSL,
        resourceType,
        isRestResource,
        isEightBaseBackendResource,
    };
});
export const getResourceArrayDSL = createSelector(getResourceListDSL, resourceListDSL => sortAlphabetically(Object.values(resourceListDSL), 'name'));
export const getGraphqlResourceArrayDSL = createSelector(getResourceListDSL, resourceListDSL => Object.values(resourceListDSL).filter((resourceDSL) => resourceDSL.type === RESOURCE_TYPES.graphql));
export const getEightBaseResourceArrayDSL = createSelector(getResourceListDSL, resourceListDSL => Object.values(resourceListDSL).filter((resourceDSL) => resourceDSL.type === RESOURCE_TYPES.backendEightBase));
export const get8baseAndGraphqlResourceArrayDSL = createSelector(getResourceListDSL, resourceListDSL => Object.values(resourceListDSL).filter((resourceDSL) => resourceDSL.type === RESOURCE_TYPES.backendEightBase ||
    resourceDSL.type === RESOURCE_TYPES.graphql));
export const getRestResourceArrayDSL = createSelector(getResourceListDSL, resourceListDSL => Object.values(resourceListDSL).filter((resourceDSL) => resourceDSL.type === RESOURCE_TYPES.rest));
export const isResourceDSLStringFieldNotUnique = createSelector(getResourceArrayDSL, (_, props) => props, (resourceListDSL, { fieldKey, value }) => {
    if (!(value === null || value === void 0 ? void 0 : value.trim())) {
        return false;
    }
    return resourceListDSL.some(resourceDSL => resourceDSL[fieldKey] === value.trim());
});
export const getUniqResourceNameByTitle = createSelector(getResourceListDSL, (_, props) => props, (resourceListDSL, { title }) => {
    const titleWithoutSpecialSymbols = title.replace(/[^A-Za-z\d\s]/g, '') || 'resource';
    const titleWithUppercase = titleWithoutSpecialSymbols
        .split(' ')
        .map(firstSymbolToUppercase)
        .join('');
    const correctTitle = mustBeCorrectVariableName(titleWithUppercase)
        ? `_${titleWithUppercase}`
        : titleWithUppercase;
    const allResourceNames = Object.values(resourceListDSL).map(({ name }) => name);
    return incrementName({
        nameToIncrement: firstSymbolToLowercase(correctTitle),
        dictionary: allResourceNames,
        delimiter: '_',
    });
});
