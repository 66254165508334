import { Migration } from '../Migration';
class Migration_0_18_9 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.9';
    }
    getChangeList() {
        return ['Add redirects to RootLayoutsContainer'];
    }
    migrateNodeDSL(nodeDSL) {
        return nodeDSL;
        // disabled for now, because it breaks the save userconfigurationSaveHandler
        // switch (nodeDSL.name) {
        //   case COMPONENT_DSL_NAMES.RootLayoutsContainer: {
        //     return {
        //       ...nodeDSL,
        //       props: {
        //         ...nodeDSL.props,
        //         redirects: {
        //           nodes: ['NotFoundPageRootRoute_ID'],
        //         },
        //       },
        //     };
        //   }
        //   default: {
        //     return nodeDSL;
        //   }
        // }
    }
}
export const migration_0_18_9 = new Migration_0_18_9();
