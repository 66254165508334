/**
 * Provides an api to generate predefined state names.
 */
export class PredefinedStateAccessor {
    constructor(importData) {
        this.importData = importData;
    }
    hasTypes() {
        return false;
    }
    getHookDeclarationBodyString() {
        return '';
    }
    getHookTypings() {
        return '';
    }
    getHookCallArgumentsString() {
        return '';
    }
    getHookDeclarationArgumentsString() {
        return '';
    }
    getHookAssociatedConstantsString() {
        return '';
    }
    getHookDeclarationName() {
        return ``;
    }
    getStateTypeName() {
        return '';
    }
    getStateName() {
        return this.importData.varNameAlias || this.importData.varName || '_';
    }
}
