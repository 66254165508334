var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, RESET_STATUS } from '../common';
import { Multiselect } from '../Multiselect';
export const FormMultiselect = forwardRef((_a, ref) => {
    var { fieldProps, selectProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, className, lang, translate } = _a, rest = __rest(_a, ["fieldProps", "selectProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "className", "lang", "translate"]);
    const { setFieldValue, status, setStatus, values } = useFormikContext();
    useEffect(() => {
        setFieldValue(fieldProps.name, (selectProps === null || selectProps === void 0 ? void 0 : selectProps.value) || []);
        return () => setFieldValue(fieldProps.name, undefined);
    }, [fieldProps.name, selectProps === null || selectProps === void 0 ? void 0 : selectProps.value, setFieldValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            const event = {
                target: {
                    value: [],
                },
            };
            (_a = selectProps === null || selectProps === void 0 ? void 0 : selectProps.onChange) === null || _a === void 0 ? void 0 : _a.call(selectProps, event, {});
            setFieldValue(fieldProps.name, []);
        }
    }, [selectProps, status, setStatus, setFieldValue, fieldProps.name]);
    useEffect(() => {
        if ((selectProps === null || selectProps === void 0 ? void 0 : selectProps.value) != null &&
            (selectProps === null || selectProps === void 0 ? void 0 : selectProps.onChange) != null &&
            (selectProps === null || selectProps === void 0 ? void 0 : selectProps.value) !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, selectProps.value);
        }
    }, [fieldProps.name, selectProps === null || selectProps === void 0 ? void 0 : selectProps.onChange, selectProps.value, setFieldValue, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(Multiselect, Object.assign({}, rest, selectProps, { name: fieldProps.name, value: field.value || [], onChange: (event, child) => {
                    var _a;
                    field.onChange(event);
                    (_a = selectProps.onChange) === null || _a === void 0 ? void 0 : _a.call(selectProps, event, child);
                }, error: isInvalid, helperText: errorText, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, className: className, lang: lang, translate: translate })));
        } })));
});
