import React, { useState, useCallback, useRef } from 'react';

import styled from '@emotion/styled';
import LinkIcon from '@mui/icons-material/Link';
import { IconProps } from '@mui/material';
import {
  Widget as UploadcareWidget,
  WidgetAPI,
  FileInfo,
  FileUpload,
} from '@uploadcare/react-widget';

import { ensureCorrectMimetype, isNil, useDelayedLoadingState } from '@builder/utils';

import { useAppDispatch } from 'src/providers/ReduxProvider';
import { useUploadcareCredentials } from 'src/shared/graphql/hooks';
import { CreateAssetData } from 'src/shared/graphql/hooks/useAssetsHooks';
import { getAssetCreateInputByType, getAssetTypeByMime } from 'src/shared/utils';
import { UI_BUILDER_EVENTS } from 'src/store';

import { UPLOADCARE_DIALOG_CSS_CLASS } from './UploadcareFilePicker.constants';
import { clearFilePickerState, preventClickPropagation } from './UploadcareFilePicker.utils';

const UploadcareWidgetContainer = styled.div`
  display: none;
`;

type FilePickerAddButtonComponentProps = {
  loading: boolean;
  onClick: () => void;
};

type UploadcareFilePickerProps = {
  assetFileNames: string[];
  AddButtonComponent: React.FC<FilePickerAddButtonComponentProps>;
  mutationLoading: boolean;
  onFileUpload: (assetCreateInput: CreateAssetData) => Promise<unknown>;
  'data-test'?: string;
};

const customMenuIcon = (iconID: string, children: IconProps) => {
  return (
    <svg width="0" height="0">
      <symbol id={iconID}>{children}</symbol>
    </svg>
  );
};

export const UploadcareFilePicker: React.FC<UploadcareFilePickerProps> = ({
  assetFileNames,
  AddButtonComponent,
  mutationLoading,
  onFileUpload,
  'data-test': dataTest,
}) => {
  const send = useAppDispatch();
  const widgetRef = useRef() as React.MutableRefObject<WidgetAPI>;
  const dialogRef = useRef<HTMLDivElement | null>();
  const [value, setValue] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isErrorType, setErrorType] = useState<boolean>(false);
  const [key, setKey] = useState<React.Key | null>(null);
  const { uploadcareSignInfo, loading: uploadcareSignInfoLoading } = useUploadcareCredentials();

  const openUploadcareDialog = useCallback(() => {
    if (!isNil(widgetRef?.current)) {
      // openDialog typing requires tab name as an argument
      // but it does not seem to affect the tab that gets opened
      // use dialog.switchTab(tab) instead
      // dialog instance can be returned from openDialog (void is wrong return type)
      widgetRef.current.openDialog('file');
    }
  }, []);

  const [delayedUploadcareSignInfoLoading, delayedOpenUploadcareDialog] = useDelayedLoadingState({
    loadingState: uploadcareSignInfoLoading,
    actionCallback: openUploadcareDialog,
  });

  const onFileSelect = useCallback(
    (fileInfo: FileUpload | null) => {
      if (!isNil(fileInfo)) {
        setUploading(true);
        // Extended JQ promise typings missing
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileInfo?.done(() => setUploading(false));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileInfo?.fail((_, __, error) => {
          const errorMessage = error?.message ?? 'Uploading error';
          setUploading(false);
          // Key need to reset widget
          setKey(new Date().toISOString());
          send({
            type: UI_BUILDER_EVENTS.notificationSend,
            notification: {
              message: isErrorType ? 'The type of file is not allowed.' : errorMessage,
              options: { variant: 'error' },
            },
          });
          setErrorType(false);
        });
      }
    },
    [isErrorType, send],
  );

  const onChange = useCallback(
    async (fileInfo: FileInfo) => {
      if (!isNil(fileInfo)) {
        const assetType = getAssetTypeByMime(
          ensureCorrectMimetype(fileInfo?.name, fileInfo?.mimeType),
        );
        const assetCreateInput = getAssetCreateInputByType(assetType, fileInfo, assetFileNames);

        if (!isNil(assetCreateInput)) {
          await onFileUpload(assetCreateInput);
        }

        setValue(clearFilePickerState);
      }
    },
    [assetFileNames, onFileUpload],
  );

  /**
   * @function onDialogOpen
   * used to track uploadcare dialog overlay and prevent any clicks
   * originated in uploadcare dialog from propagating out to enclosing elements
   * this is done because click propagation may close other active poppers which is undesired
   */
  const onDialogOpen = useCallback(() => {
    dialogRef.current = document.querySelector<HTMLDivElement>(UPLOADCARE_DIALOG_CSS_CLASS);
    dialogRef.current?.addEventListener('click', preventClickPropagation);
  }, []);

  /**
   * @function onDialogClose
   * removes assigned click listener from dialog as it is closing
   */
  const onDialogClose = useCallback(() => {
    dialogRef.current?.removeEventListener('click', preventClickPropagation);
    dialogRef.current = null;
  }, []);

  const loading = delayedUploadcareSignInfoLoading || uploading || mutationLoading;

  const fileTypeValidation = () => {
    return function _(fileInfo: FileInfo) {
      if (fileInfo.name === null) {
        return;
      }

      const extension = fileInfo.name.split('.').pop();

      if (extension === fileInfo.name) {
        setErrorType(true);
        throw new Error('fileType');
      }
    };
  };

  const validators = [fileTypeValidation()];
  return (
    <>
      <AddButtonComponent
        loading={isErrorType ? false : loading}
        onClick={delayedOpenUploadcareDialog}
        data-test={dataTest}
      />
      <UploadcareWidgetContainer onClick={event => event.stopPropagation()}>
        <UploadcareWidget
          key={key}
          ref={widgetRef}
          tabs="file url"
          value={value as string}
          onChange={onChange}
          // onFileSelect takes different args depending on multiple/single-file mode
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onFileSelect={onFileSelect}
          onDialogOpen={onDialogOpen}
          onDialogClose={onDialogClose}
          publicKey={uploadcareSignInfo.publicKey}
          secureExpire={uploadcareSignInfo.expire}
          secureSignature={uploadcareSignInfo.signature}
          validators={validators}
        />
        {customMenuIcon('uploadcare--icon-url', <LinkIcon />)};
      </UploadcareWidgetContainer>
    </>
  );
};
