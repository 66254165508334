var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_9_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.9.2';
    }
    getChangeList() {
        return [`Avatar: add ability to set text or icon for label`];
    }
    migrateNodeListDSL(nodeListDSL) {
        let createdNodeListDSL = {};
        const labelPrefixID = 'migration_0_9_2_avatar_label_node';
        let labelIDsCounter = 0;
        const createLabelNode = ({ allNodeListDSL, parentID, labelAliasPrefix, labelValue, }) => {
            var _a;
            const existedLabelID = (_a = labelValue === null || labelValue === void 0 ? void 0 : labelValue.nodes) === null || _a === void 0 ? void 0 : _a[0];
            if (!existedLabelID) {
                return {
                    newNodeListDSL: {},
                };
            }
            const existedLabelNode = allNodeListDSL[existedLabelID];
            const materialTypographyID = `${labelPrefixID}_${labelIDsCounter}`;
            const materialTypographyAlias = this.generateSecuredNodeAlias(allNodeListDSL, labelAliasPrefix);
            labelIDsCounter += 1;
            return {
                label: {
                    nodes: [existedLabelNode.name === 'Text' ? materialTypographyID : existedLabelNode.id],
                },
                newNodeListDSL: Object.assign(Object.assign({}, (existedLabelNode.name === 'Text'
                    ? {
                        [materialTypographyID]: {
                            name: 'MaterialTypography',
                            alias: materialTypographyAlias,
                            schemaOverride: {
                                interaction: 'only-editable',
                            },
                            props: {
                                variant: 'inherit',
                                align: 'inherit',
                                color: 'inherit',
                                paragraph: false,
                                children: {
                                    nodes: [existedLabelID],
                                },
                            },
                            id: materialTypographyID,
                            states: [],
                            parentID,
                        },
                    }
                    : {})), { [existedLabelID]: Object.assign(Object.assign({}, existedLabelNode), { parentID: existedLabelNode.name === 'Text' ? materialTypographyID : existedLabelNode.parentID }) }),
            };
        };
        const migratedNodeListDSL = mapObjIndexed((nodeDSL, propName) => {
            switch (nodeDSL.name) {
                case 'MaterialAvatar': {
                    const _a = nodeDSL.props, { children: labelValue } = _a, otherProps = __rest(_a, ["children"]);
                    const { newNodeListDSL, label } = createLabelNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        labelAliasPrefix: 'Avatar Label',
                        labelValue: labelValue,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    const avatarProps = Object.assign({}, otherProps);
                    if (label) {
                        avatarProps.children = label;
                    }
                    return Object.assign(Object.assign({}, nodeDSL), { props: avatarProps });
                }
                default:
                    return nodeDSL;
            }
        }, nodeListDSL);
        return Object.assign(Object.assign({}, migratedNodeListDSL), createdNodeListDSL);
    }
}
export const migration_0_9_2 = new Migration_0_9_2();
