import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { DevModeQuery } from '../__generated__';
import { DEV_MODE_QUERY } from '../fragments';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

type UseDevModeReturn = {
  devMode: boolean;
};

export const useDevMode = (): UseDevModeReturn => {
  const { workspaceID } = useCurrentWorkspaceID();

  const { data } = useQuery<DevModeQuery>(DEV_MODE_QUERY, {
    variables: {
      workspaceId: workspaceID,
    },
    skip: !workspaceID,
  });

  // Memorize the result of the hook using useMemo
  const memoizedData = useMemo(() => data?.appbuilder?.devMode === true, [data]);

  return {
    devMode: memoizedData,
  };
};
