import { mapObjIndexed } from 'ramda';
import { STATE_REQUEST_VARIANTS, STATE_TYPES, } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_11_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.3';
    }
    getChangeList() {
        return ['Set all resource id to each request', 'Set default name to auth resource'];
    }
    migrateStateDSL(stateDSL, appDSL) {
        var _a;
        const { authResourceID } = (_a = appDSL.settings) !== null && _a !== void 0 ? _a : {};
        const authResourceDSL = authResourceID && appDSL.resources[authResourceID];
        if (stateDSL.type === STATE_TYPES.query) {
            if (stateDSL.variant === STATE_REQUEST_VARIANTS.query ||
                stateDSL.variant === STATE_REQUEST_VARIANTS.lazyQuery ||
                stateDSL.variant === STATE_REQUEST_VARIANTS.mutation) {
                if (!authResourceDSL || !authResourceID) {
                    throw new Error(`Can't migrate "${stateDSL.id}" request. Auth resource not found.`);
                }
                return Object.assign(Object.assign({}, stateDSL), { resourceID: authResourceID });
            }
        }
        return stateDSL;
    }
    migrateResourceListDSL(resourceListDSL, appDSL) {
        var _a;
        const { authResourceID } = (_a = appDSL.settings) !== null && _a !== void 0 ? _a : {};
        const placeholderResourceName = 'resource';
        let index = 0;
        return mapObjIndexed(resourceDSL => {
            if (resourceDSL.id === authResourceID) {
                return Object.assign(Object.assign({}, resourceDSL), { name: resourceDSL.name || 'eightBase', title: resourceDSL.name || 'eightBase' });
            }
            index += 1;
            return Object.assign(Object.assign({}, resourceDSL), { name: resourceDSL.name || `${placeholderResourceName}_${index}`, title: resourceDSL.name || `${placeholderResourceName}_${index}` });
        }, resourceListDSL);
    }
}
export const migration_0_11_3 = new Migration_0_11_3();
