import { AppEvents, Store } from './types';

type ReducerDescriptor<State, Events> = (state: State, event: Events) => State;

type ReducerInfo<
  State = Store,
  Events extends { type: AppEvents['type'] } = AppEvents,
  EventTypes extends string = Events['type']
> = {
  events: EventTypes[];
  reduce: ReducerDescriptor<State, Events>;
};

type ReducerEventsDomain<
  State = Store,
  Events extends { type: AppEvents['type'] } = AppEvents,
  EventTypes extends string = Events['type']
> = Record<EventTypes, ReducerDescriptor<State, Events>>;

const getReducerEventsDomain = <
  State = Store,
  Events extends { type: AppEvents['type'] } = AppEvents,
  EventTypes extends string = Events['type']
>(
  reducerInfoList: ReducerInfo<State, Events, EventTypes>[],
): ReducerEventsDomain<State, Events> => {
  return reducerInfoList.reduce((acc, info) => {
    return {
      ...acc,
      ...info.events.reduce((eventsAcc, event) => {
        return {
          ...eventsAcc,
          [event]: info.reduce,
        };
      }, {} as ReducerEventsDomain<State, Events>),
    };
  }, {} as ReducerEventsDomain<State, Events>);
};

export const createReducer = <
  State = Store,
  Events extends { type: AppEvents['type'] } = AppEvents
>(
  callback: (
    declare: <
      EventsSubsetTypes extends AppEvents['type'] = AppEvents['type'],
      EventsSubset = Extract<Events, { type: EventsSubsetTypes }>
    >(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      info: ReducerInfo<State, EventsSubset>,
    ) => ReducerInfo<State, Events>,
  ) => ReducerInfo<State, Events>[],
): {
  reducerEventsDomain: ReducerEventsDomain<State, Events>;
  reducerEvents: Events['type'][];
} => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const reducerInfoList = callback(function declare({ events, reduce }) {
    return ({
      events,
      reduce,
    } as unknown) as ReducerInfo<State, Events>;
  });

  const reducerEventsDomain = getReducerEventsDomain(reducerInfoList);

  return {
    reducerEventsDomain,
    reducerEvents: Object.keys(reducerEventsDomain) as Events['type'][],
  };
};
