import {
  COMPONENT_DSL_NAMES,
  COMPONENT_LIST_MODE,
  ROOT_LAYOUT_CONTAINER_NODE_ID,
} from '@builder/schemas';

import { componentCreate } from '../../../complex-cases';
import { dashboardInitial } from '../../../initial-state';
import {
  AppEvents,
  createReducer,
  DashboardState,
  DASHBOARD_EVENTS,
  DND_TARGET_TYPES,
} from 'src/store/common';

export const LAYOUT_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.layoutCreate,
  DASHBOARD_EVENTS.layoutUpdate,
  DASHBOARD_EVENTS.layoutChange,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof LAYOUT_REDUCER_EVENTS[number]>({
    events: LAYOUT_REDUCER_EVENTS,
    reduce: (state, event) => {
      switch (event.type) {
        // routes
        case DASHBOARD_EVENTS.layoutCreate: {
          return componentCreate(
            state,
            {
              type: 'into',
              target: {
                type: DND_TARGET_TYPES.into,
                nodeID: ROOT_LAYOUT_CONTAINER_NODE_ID,
                propName: ['layouts'],
              },
            },
            {
              name: COMPONENT_DSL_NAMES.BasePageLayout,
              props: {
                content: {
                  nodes: [
                    {
                      name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
                      props: {
                        children: {
                          nodes: [
                            {
                              name: COMPONENT_DSL_NAMES.RouterSwitchSymbol,
                              componentListMode: COMPONENT_LIST_MODE.layout,
                              hiddenLayout: false,
                              system: true,
                              schemaOverride: {
                                interaction: 'only-editable',
                              },
                              props: {
                                routes: {
                                  nodes: [
                                    {
                                      name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                                      props: {
                                        children: {
                                          nodes: [
                                            {
                                              name:
                                                COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                                              props: {
                                                children: {
                                                  nodes: [],
                                                },
                                                style: {
                                                  display: 'flex',
                                                  flexGrow: 1,
                                                  flexShrink: 1,
                                                  flexBasis: '0%',
                                                  flexDirection: 'column',
                                                  height: 'auto',
                                                },
                                              },
                                              states: [],
                                              alias: `${event.nodeAlias}`,
                                            },
                                          ],
                                        },
                                        path: `/__layouts/__${event.nodeAlias
                                          .toLowerCase()
                                          .replace(/ /g, '')}`,
                                        system: true,

                                        authAccess: 'any',
                                      },
                                      states: [],
                                      alias: `${event.nodeAlias}`,
                                      incrementAlias: false,
                                    },
                                  ],
                                },
                                redirects: { nodes: [] },
                              },
                              states: [],
                              alias: `${event.nodeAlias}`,
                            },
                          ],
                        },
                        style: {
                          height: '100%',
                        },
                      },
                      states: [],
                      alias: `${event.nodeAlias}`,
                    },
                  ],
                },
              },
              states: [],
              alias: `${event.nodeAlias}`,
              incrementAlias: false,
            },
          );
        }
        case DASHBOARD_EVENTS.layoutChange: {
          const layout = { ...state.layout, layoutSelected: event.layoutSelected };
          return {
            ...state,
            layout,
          };
        }

        case DASHBOARD_EVENTS.layoutUpdate: {
          // add this event to update more than layoutName
          return state;
        }
      }
    },
  }),
]);

export const layoutReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
