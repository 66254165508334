import React, { useMemo } from 'react';

import { ExtendSliderProps } from '../../types';
import { SliderWithTitleWrapper } from '../common';
import { Slider } from 'src/shared/components/Slider';

const VALUE_RANGE = { min: 0, max: 1 };

export const ColorSlider: React.FC<ExtendSliderProps> = React.memo(
  ({ title, subTitle, value, hue, initialColor, onChange }) => {
    const colors = useMemo(() => {
      if (!initialColor) return undefined;

      if (typeof hue !== 'number') return [initialColor];

      return [initialColor, `hsl(${Math.round(hue)}, 100%, 50%)`];
    }, [initialColor, hue]);
    const pointerColor = useMemo(
      () => (typeof hue === 'number' ? `hsl(${hue}, 100%, 50%)` : undefined),
      [hue],
    );

    return (
      <SliderWithTitleWrapper title={title} subTitle={subTitle}>
        <Slider
          valueRange={VALUE_RANGE}
          value={value}
          height="8px"
          width="100%"
          colors={colors}
          borderRadius="10px"
          pointerColor={pointerColor}
          onChange={onChange}
        />
      </SliderWithTitleWrapper>
    );
  },
);
