import { useMemo } from 'react';
import { resourceListSelectors, } from '@builder/schemas';
import { SchemaValidationError, ERROR_SCOPES, CheckResourceApiHostValue, } from '@builder/utils';
import { transformResourceProps } from '../../app-nodes/props-transformers/prop-transformer/PropTransformer';
import { useRuntimeErrorCallbacks } from '../../providers';
import { useGraphqlResource } from './useGraphqlResource';
export const useGetApolloClientByAppState = ({ appStateDSL, resourceListDSL, appRuntimeState, componentListDSL, assetListDSL, propListDSL, }) => {
    if (!appStateDSL.resourceID) {
        throw new SchemaValidationError(ERROR_SCOPES.appEngine, `State ${appStateDSL.name} does not have required resourceID property`);
    }
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const appropriateResourceDSL = resourceListSelectors.getResourceDSL(resourceListDSL, {
        resourceID: appStateDSL.resourceID,
    });
    const handledGraphqlResourceDSL = transformResourceProps({
        resourceDSL: appropriateResourceDSL,
        componentListDSL,
        resourceListDSL,
        assetListDSL,
        propListDSL,
    }, appRuntimeState, { onAppAuditNotifications });
    const transformedEndpoint = 'api' in handledGraphqlResourceDSL
        ? CheckResourceApiHostValue(handledGraphqlResourceDSL.api)
        : handledGraphqlResourceDSL.endpoint || null;
    const workspaceID = handledGraphqlResourceDSL.workspaceID || null;
    const environment = handledGraphqlResourceDSL.environment || null;
    const apolloClient = useGraphqlResource(appropriateResourceDSL, appStateDSL, transformedEndpoint, workspaceID, environment, handledGraphqlResourceDSL.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedClient = useMemo(() => apolloClient, [appStateDSL]);
    return memoizedClient;
};
