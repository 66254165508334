import { isUndefined } from 'lodash';

import { NodeListDSL } from '@builder/schemas';
import { isString } from '@builder/utils';

import { ROOT } from './constants';

export const checkAncestor = (
  ancestor: string | string[],
  parentNodeSelectedID: string,
  nodeListDSL: NodeListDSL,
): { validation: boolean; componentID: string } => {
  if (
    parentNodeSelectedID === ROOT ||
    isUndefined(parentNodeSelectedID) ||
    isUndefined(nodeListDSL[parentNodeSelectedID])
  )
    return { validation: false, componentID: '' };

  if (isString(ancestor)) {
    if (nodeListDSL[parentNodeSelectedID].name === ancestor) {
      return { validation: true, componentID: parentNodeSelectedID };
    }
  }

  if (ancestor.includes(nodeListDSL[parentNodeSelectedID].name)) {
    return { validation: true, componentID: parentNodeSelectedID };
  }

  return checkAncestor(ancestor, nodeListDSL[parentNodeSelectedID].parentID || ROOT, nodeListDSL);
};
