import { isNil } from 'ramda';
import { isObject } from '@builder/utils';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_PROP_TYPES } from '../../constants';
/**
 * Provider helpers to check prop schema.
 * It checks prop by component DSL.
 */
export const PropSchemaChecker = {
    isNotEmpty(propSchema) {
        return !isNil(propSchema) && Boolean(propSchema.type);
    },
    isReactNodeProp(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.reactNode;
    },
    /**
     * Renderable prop checker. Renderable prop are both react-node and render-prop props.
     */
    isRenderableProp(propSchema) {
        return ((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.reactNode ||
            ((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.callback &&
                (propSchema === null || propSchema === void 0 ? void 0 : propSchema.body) === COMPONENT_DSL_CALLBACK_TYPES.component));
    },
    isObjectProp(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.object;
    },
    isStyleProp(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.style;
    },
    isCSSProp(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.css;
    },
    isObjectPropWithDefinedProps(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.object && isObject(propSchema === null || propSchema === void 0 ? void 0 : propSchema.props);
    },
    isObjectPropWithNoDefinedProps(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.object && !isObject(propSchema === null || propSchema === void 0 ? void 0 : propSchema.props);
    },
    isStylePropWithDefinedProps(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.style && isObject(propSchema === null || propSchema === void 0 ? void 0 : propSchema.props);
    },
    isArrayProp(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.array && isObject(propSchema === null || propSchema === void 0 ? void 0 : propSchema.item);
    },
    /** @deprecated */
    isArrayPropWithDefinedProps(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.array && isObject(propSchema === null || propSchema === void 0 ? void 0 : propSchema.item);
    },
    isArrayPropWithDefinedObjectProps(propSchema) {
        return (PropSchemaChecker.isArrayProp(propSchema) &&
            PropSchemaChecker.isObjectPropWithDefinedProps(propSchema === null || propSchema === void 0 ? void 0 : propSchema.item));
    },
    isArrayPropWithNoDefinedObjectProps(propSchema) {
        return (PropSchemaChecker.isArrayProp(propSchema) &&
            PropSchemaChecker.isObjectPropWithNoDefinedProps(propSchema === null || propSchema === void 0 ? void 0 : propSchema.item));
    },
    isCallbackComponentProp(propSchema) {
        return ((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.callback &&
            Boolean(propSchema.body) &&
            Boolean(propSchema.args));
    },
    isCallbackCodeProp(propSchema) {
        return ((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.callback &&
            Boolean(propSchema.body) &&
            Boolean(propSchema.args));
    },
    isActionProp(propSchema) {
        return ((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.action &&
            Boolean(propSchema.body) &&
            Boolean(propSchema.args));
    },
    isAssetProp(propSchema) {
        return (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.asset;
    },
    isRequired(propSchema) {
        return Boolean(propSchema === null || propSchema === void 0 ? void 0 : propSchema.required);
    },
    isSystem(propSchema) {
        return Boolean(propSchema === null || propSchema === void 0 ? void 0 : propSchema.system);
    },
    isCustomProp(propSchema) {
        return Boolean((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.customProps);
    },
    isfieldValidationProp(propSchema) {
        return Boolean((propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === COMPONENT_DSL_PROP_TYPES.fieldValidation);
    },
    hasTypeDefinition(propSchema) {
        return Boolean(propSchema === null || propSchema === void 0 ? void 0 : propSchema.typeDefinition);
    },
};
