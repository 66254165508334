import React, { useCallback, useEffect } from 'react';

import styled from '@emotion/styled';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

import { useAppDispatch, useUIBuilderLeftPanel } from 'src/providers';
import { EVENT_KEYS } from 'src/shared/constants';
import { LEFT_PANEL_TABS, UI_BUILDER_EVENTS } from 'src/store';

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const EscapeLayoutButton: React.FC = () => {
  const send = useAppDispatch();
  const leftPanel = useUIBuilderLeftPanel();
  const closeLeftPanel = useCallback(
    () =>
      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          ...leftPanel,
          currentTab: LEFT_PANEL_TABS.none,
        },
      }),
    [leftPanel, send],
  );

  const closeSettingsOnEsc = useCallback(
    event => {
      if (event.key === EVENT_KEYS.ESCAPE) {
        closeLeftPanel();
      }
    },
    [closeLeftPanel],
  );

  useEffect(() => {
    document.addEventListener('keydown', closeSettingsOnEsc, false);

    return () => {
      document.removeEventListener('keydown', closeSettingsOnEsc, false);
    };
  }, [closeSettingsOnEsc]);

  return (
    <CloseContainer>
      <IconButton onClick={closeLeftPanel}>
        <CloseIcon />
      </IconButton>
      <Typography variant="body1">esc</Typography>
    </CloseContainer>
  );
};
