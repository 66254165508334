import { COMPONENT_DSL_NAMES } from '../constants/component';
import { STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants/styleguide';
import { BASE_SCHEMA } from './common';
import basicRadioGroupDSL from './style-guide-dsl/RadioGroupStyleGuide/basicRadioGroup.json';
import colorDSL from './style-guide-dsl/RadioGroupStyleGuide/color.json';
import sizeDSL from './style-guide-dsl/RadioGroupStyleGuide/size.json';
import standaloneRadioButtonDSL from './style-guide-dsl/RadioGroupStyleGuide/standaloneRadioButton.json';
export const RadioGroupStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiRadioGroup,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
    dirName: 'RadioGroupStyleGuide',
    title: 'Radio Group',
    classNames: [
        '.MuiRadio-root',
        '.Mui-checked',
        '.Mui-disabled',
        '.MuiRadio-colorPrimary',
        '.MuiRadio-colorSecondary',
    ],
    stories: [
        {
            title: 'Basic Radio Group',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        row: false,
                        label: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialFormLabel,
                                    alias: 'Radio Group Label',
                                    props: {
                                        component: 'label',
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.Text,
                                                    props: {
                                                        children: 'Radio Group Column',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        row: true,
                        label: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialFormLabel,
                                    alias: 'Radio Group Label',
                                    props: {
                                        component: 'label',
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.Text,
                                                    props: {
                                                        children: 'Radio Group Row',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: basicRadioGroupDSL,
        },
        {
            title: 'Standalone Radio Button',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: { name: 'radio', value: 'radio1', checked: true },
                },
                {
                    props: { name: 'radio', value: 'radio2', checked: false },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: standaloneRadioButtonDSL,
        },
        {
            title: 'Color',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        row: true,
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        color: 'primary',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        color: 'primary',
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 1',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value1',
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        color: 'secondary',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        color: 'secondary',
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 2',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value2',
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        color: 'success',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        color: 'success',
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 3',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value3',
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        color: 'default',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        color: 'default',
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 4',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value4',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: colorDSL,
        },
        {
            title: 'Size',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        row: true,
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        size: 'small',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 1',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value1',
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        size: 'medium',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 2',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value2',
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                                    props: {
                                        size: 'large',
                                        label: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Radio Label',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Value 3',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        value: 'value3',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: sizeDSL,
        },
    ],
};
