var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { mapObjIndexed, path } from 'ramda';
import { isUndefined } from '@builder/utils';
import { componentListSelectors } from '../../selectors';
import { PropChecker } from '../../validation/helpers';
const forEachPropListDSL = (callback, propListDSL, propPath = []) => {
    mapObjIndexed((propDSL, propName) => {
        if (PropChecker.Schema.isObjectPropWithDefinedProps(propDSL)) {
            forEachPropListDSL(callback, propDSL.props, [...propPath, propName]);
        }
        if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(propDSL)) {
            forEachPropListDSL(callback, propDSL.item.props, [...propPath, propName]);
        }
        callback(propListDSL, propName, propPath);
    }, propListDSL);
};
/**
 * Run callback on each props which are exist in the component DSL without information about node.
 */
export const forEachPropDSL = (callback, propListDSL) => {
    forEachPropListDSL((nestedPropListDSL, propName, propPath) => {
        const propDSL = nestedPropListDSL[propName];
        callback({
            propName,
            propPath,
            propDSL,
        });
    }, propListDSL);
};
/**
 * Run callback on each props which are exist in the component DSL.
 */
export const forEachComponentProps = (callback, componentListDSL, nodeDSL) => {
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    forEachPropListDSL((propListDSL, propName, propPath) => {
        const propDSL = propListDSL[propName];
        const propListValues = path(propPath, nodeDSL.props);
        const propValue = propListValues === null || propListValues === void 0 ? void 0 : propListValues[propName];
        callback({
            propName,
            propPath,
            propValue,
            propDSL,
            propListValues,
            propListDSL,
        });
    }, componentDSL.schema.props);
};
/**
 * Run callback on each props which are exist in the node DSL.
 */
export const forEachNodeProps = (callback, componentListDSL, nodeDSL) => {
    forEachComponentProps((_a) => {
        var { propValue, propListValues } = _a, rest = __rest(_a, ["propValue", "propListValues"]);
        if (!isUndefined(propValue) && !isUndefined(propListValues)) {
            callback(Object.assign({ propValue,
                propListValues,
                nodeDSL }, rest));
        }
    }, componentListDSL, nodeDSL);
};
/**
 * Run callback on each props which are exist in the list of node DSL.
 */
export const forEachNodeListProps = (callback, componentListDSL, nodeListDSL) => {
    Object.values(nodeListDSL).forEach(nodeDSL => {
        forEachNodeProps(callback, componentListDSL, nodeDSL);
    });
};
