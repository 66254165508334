import {
  appDSLSelectors,
  CommonPropSettingDSL,
  COMPONENT_DSL_NAMES,
  componentListSelectors,
  componentSelectors,
  componentSettingSelectors,
  NodeChecker,
  NodeID,
  nodeListSelectors,
  NodeRouteDSL,
  ObjectPropValue,
  PRESENTATIONS,
  PresentationTypes,
} from '@builder/schemas';
import { ERROR_SCOPES, isString, SystemError } from '@builder/utils';

import { validateIsFormComponent } from '../../../../../shared/utils';
import {
  componentCopy,
  componentCreate,
  componentMove,
  componentRemove,
  pageClone,
  openAsRoot,
} from '../../../complex-cases';
import { dashboardInitial } from '../../../initial-state';
import { convertReactStyleToCSS, getLiteralStyleValues, StyleObject } from '../../../utils';
import { routerReducer } from '../routerReducer';
import { dashboardSelectors } from 'src/store';
import {
  AppEvents,
  createReducer,
  DASHBOARD_EVENTS,
  DashboardState,
  DND_TARGET_TYPES,
  MOVE_VARIANT,
} from 'src/store/common';

export const COMPONENTS_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.componentCreate,
  DASHBOARD_EVENTS.componentMove,
  DASHBOARD_EVENTS.componentSelectedRemove,
  DASHBOARD_EVENTS.componentRemove,
  DASHBOARD_EVENTS.openAsRoot,
  DASHBOARD_EVENTS.routeClone,
  DASHBOARD_EVENTS.componentCopy,
  DASHBOARD_EVENTS.componentPresentationVisibleToggle,
  DASHBOARD_EVENTS.componentReactNodeTextUpdate,
  DASHBOARD_EVENTS.componentPropUpdate,
  DASHBOARD_EVENTS.componentPropUpdateMany,
  DASHBOARD_EVENTS.componentUpdateMany,
  DASHBOARD_EVENTS.componentPasteStyle,
  DASHBOARD_EVENTS.componentCopyStyle,
  DASHBOARD_EVENTS.componentSaveAsGlobalCSS,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof COMPONENTS_REDUCER_EVENTS[number]>({
    events: COMPONENTS_REDUCER_EVENTS,
    reduce: (state, event): DashboardState => {
      const appDSL = dashboardSelectors.getAppDSL(state);
      const nodeListDSL = dashboardSelectors.getNodeListDSL(state);

      switch (event.type) {
        // components
        case DASHBOARD_EVENTS.componentCreate: {
          return componentCreate(state, event.place, event.value);
        }

        case DASHBOARD_EVENTS.componentMove:
          return componentMove(state, event.place);

        case DASHBOARD_EVENTS.componentSelectedRemove: {
          return isString(state.operations.selectedID)
            ? componentRemove(state, state.operations.selectedID)
            : state;
        }

        case DASHBOARD_EVENTS.componentRemove:
          return componentRemove(state, event.id, event.currentPathName);

        case DASHBOARD_EVENTS.openAsRoot:
          return openAsRoot(state, event.nodeID);

        case DASHBOARD_EVENTS.routeClone: {
          let newState = state;
          const nodeObject = nodeListDSL[event.id] as NodeRouteDSL;
          if (nodeObject.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute) {
            newState = pageClone(
              newState,
              nodeObject.id,
              event.newPageId,
              event.appConfiguration.appDSL,
              nodeObject.props.path,
            );
          }

          const stateWithNewPath = routerReducer(newState, {
            type: DASHBOARD_EVENTS.routerPathUpdate,
            route: newState.appConfiguration.appDSL.nodes[event.newPageId].props.path as string,
          });

          return stateWithNewPath;
        }

        case DASHBOARD_EVENTS.componentPresentationVisibleToggle: {
          const currentPresentations =
            (appDSLSelectors.appPath(appDSL, [
              'nodes',
              event.nodeID,
              'presentations',
            ]) as PresentationTypes[]) ?? [];

          const isPresentationApplied = currentPresentations.some(
            presentation => presentation === PRESENTATIONS.visible,
          );

          const updatedAppDSL = appDSLSelectors.assocAppPath(
            appDSL,
            ['nodes', event.nodeID, 'presentations'],
            isPresentationApplied
              ? currentPresentations.filter(presentation => presentation !== PRESENTATIONS.visible)
              : [...currentPresentations, PRESENTATIONS.visible],
          );

          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: updatedAppDSL,
            },
            operations: {
              ...state.operations,
              selectedID: event.nodeID,
            },
          };
        }

        case DASHBOARD_EVENTS.componentReactNodeTextUpdate: {
          let newState = { ...state };
          const { keyPath } = event.value;

          if (!keyPath) {
            throw new SystemError(ERROR_SCOPES.dashboard, `Target prop name wasn't specified`);
          }

          event.id.forEach(targetNodeID => {
            const targetNode = appDSL.nodes[targetNodeID];

            const targetChildrenIDs = appDSLSelectors.getRenderableNodesByPropPath<NodeID[]>(
              appDSL,
              targetNode.id,
              keyPath,
            );

            if (targetChildrenIDs) {
              const childrenTextNode = targetChildrenIDs.find((childrenID: string | number) =>
                NodeChecker.Value.isTextNode(appDSL.nodes[childrenID]),
              );

              if (childrenTextNode) {
                const newAppDSL = appDSLSelectors.assocAppPath(
                  newState.appConfiguration.appDSL,
                  ['nodes', childrenTextNode, 'props', 'children'],
                  event.value.text,
                );

                newState = {
                  ...state,
                  appConfiguration: {
                    ...state.appConfiguration,
                    appDSL: newAppDSL,
                  },
                };
              } else {
                newState = componentCreate(
                  state,
                  {
                    type: MOVE_VARIANT.into,
                    target: {
                      type: DND_TARGET_TYPES.into,
                      nodeID: targetNodeID,
                      propName: keyPath,
                    },
                  },
                  {
                    selectNodeOnCreate: false,
                    name: COMPONENT_DSL_NAMES.Text,
                    props: {
                      children: event.value.text,
                    },
                  },
                );
              }
            }
          });

          return newState;
        }

        case DASHBOARD_EVENTS.componentCopy: {
          return componentCopy(state, event.id, event.currentPathName);
        }

        case DASHBOARD_EVENTS.componentPropUpdate: {
          const { id: nodeID } = event;
          const { keyValue, keyPath } = event.value;

          const newAppDSL = appDSLSelectors.assocAppPath(
            state.appConfiguration.appDSL,
            ['nodes', nodeID, 'props', ...keyPath],
            keyValue,
          );

          const componentListDSL = dashboardSelectors.getComponentListDSL(state);
          const nodeListDSLItem = dashboardSelectors.getNodeListDSL(state);
          const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSLItem, { nodeID });
          const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
          });

          if (!componentDSL) {
            return {
              ...state,
              appConfiguration: {
                ...state.appConfiguration,
                appDSL: newAppDSL,
              },
            };
          }

          // find related props by showIf.keyPath with all affected
          // and reset hidden props
          const settingsDSL = componentSelectors.getFlatListSettingsDSL(componentDSL);
          const affectedSettings = componentSettingSelectors.getSettingListDSLByRelatedCondition(
            settingsDSL,
            keyPath,
          ) as CommonPropSettingDSL[];

          const propsToReset = affectedSettings
            .filter(el => {
              if (!el.keyPath) {
                return false;
              }

              const targetNodeDSL = newAppDSL.nodes[nodeID];

              if (!targetNodeDSL) {
                return false;
              }

              const isShowingSetting = componentSettingSelectors.isShowingSetting(el?.showIf, {
                sourceEntity: targetNodeDSL,
                nodeListDSL,
              });

              return !isShowingSetting;
            })
            .map(el => {
              return {
                keyValue: undefined,
                keyPath: el.keyPath,
              };
            });

          const changedPropsList = [
            {
              keyValue,
              keyPath,
            },
            ...propsToReset,
          ];

          const newUserAppDSL = changedPropsList.reduce((acc, current) => {
            return appDSLSelectors.assocAppPath(
              acc,
              ['nodes', event.id, 'props', ...current.keyPath],
              current.keyValue,
            );
          }, state.appConfiguration.appDSL);

          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: newUserAppDSL,
            },
          };
        }

        case DASHBOARD_EVENTS.componentPropUpdateMany: {
          const { id: nodeID, values } = event;

          const newAppDSL = values.reduce((acc, current) => {
            return appDSLSelectors.assocAppPath(
              acc,
              ['nodes', event.id, 'props', ...current.keyPath],
              current.keyValue,
            );
          }, state.appConfiguration.appDSL);

          const componentListDSL = dashboardSelectors.getComponentListDSL(state);
          const nodeListDSLItem = dashboardSelectors.getNodeListDSL(state);
          const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSLItem, { nodeID });
          const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
          });

          if (!componentDSL) {
            return {
              ...state,
              appConfiguration: {
                ...state.appConfiguration,
                appDSL: newAppDSL,
              },
            };
          }

          // find related props by showIf.keyPath with all affected
          // and reset hidden props
          const settingsDSL = componentSelectors.getFlatListSettingsDSL(componentDSL);
          const affectedSettings = values
            .map(({ keyPath }) => {
              return componentSettingSelectors.getSettingListDSLByRelatedCondition(
                settingsDSL,
                keyPath,
              ) as CommonPropSettingDSL[];
            })
            .flat();

          const propsToReset = affectedSettings
            .filter(el => {
              if (!el.keyPath) {
                return false;
              }

              const targetNodeDSL = newAppDSL.nodes[nodeID];

              if (!targetNodeDSL) {
                return false;
              }

              const isShowingSetting = componentSettingSelectors.isShowingSetting(el?.showIf, {
                sourceEntity: targetNodeDSL,
                nodeListDSL,
              });

              return !isShowingSetting;
            })
            .map(el => {
              return {
                keyValue: undefined,
                keyPath: el.keyPath,
              };
            });

          const changedPropsList = [...values, ...propsToReset];

          const newUserAppDSL = changedPropsList.reduce((acc, current) => {
            return appDSLSelectors.assocAppPath(
              acc,
              ['nodes', event.id, 'props', ...current.keyPath],
              current.keyValue,
            );
          }, state.appConfiguration.appDSL);

          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: newUserAppDSL,
            },
          };
        }

        case DASHBOARD_EVENTS.componentUpdateMany: {
          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: {
                ...state.appConfiguration.appDSL,
                nodes: event.nodes,
              },
            },
          };
        }

        case DASHBOARD_EVENTS.componentCopyStyle: {
          const { style } = event;

          return { ...state, copyStyle: style };
        }

        case DASHBOARD_EVENTS.componentPasteStyle: {
          const { id: nodesId, style } = event;

          if (Array.isArray(nodesId)) {
            return nodesId.reduce(
              (acc, nodeID) => {
                const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
                let propsMerged;
                if (formComponent.isFormComponent && formComponent.key != null) {
                  propsMerged = {
                    ...nodeDSL.props,
                    ...{
                      [formComponent.key]: style,
                    },
                  };
                } else {
                  propsMerged = {
                    ...nodeDSL.props,
                    ...style,
                  };
                }

                const newNodeDSL = {
                  ...nodeDSL,
                  props: propsMerged,
                };
                return {
                  ...acc,
                  appConfiguration: {
                    ...acc.appConfiguration,
                    appDSL: {
                      ...acc.appConfiguration.appDSL,
                      nodes: {
                        ...acc.appConfiguration.appDSL.nodes,
                        [nodeID]: newNodeDSL,
                      },
                    },
                  },
                };
              },
              { ...state },
            );
          }

          const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: nodesId });
          const formComponent = validateIsFormComponent(Object.keys(nodeDSL.props));
          let propsMerged;
          if (formComponent.isFormComponent && formComponent.key != null) {
            propsMerged = {
              ...nodeDSL.props,
              ...{
                [formComponent.key]: {
                  ...(nodeDSL.props[formComponent.key] as ObjectPropValue),
                  ...style,
                },
              },
            };
          } else {
            propsMerged = {
              ...nodeDSL.props,
              ...style,
            };
          }

          const newNodeDSL = {
            ...nodeDSL,
            props: propsMerged,
          };
          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: {
                ...state.appConfiguration.appDSL,
                nodes: {
                  ...state.appConfiguration.appDSL.nodes,
                  [nodesId]: newNodeDSL,
                },
              },
            },
          };
        }

        case DASHBOARD_EVENTS.componentSaveAsGlobalCSS: {
          const { nodeID, className } = event;
          const newState = structuredClone(state);
          const nodeListDSLItem = dashboardSelectors.getNodeListDSL(newState);
          const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSLItem, { nodeID });
          const { style, className: nodeClassName, css } = nodeDSL.props;
          const { literalStyle, rest } = getLiteralStyleValues(style as StyleObject);
          const {
            appConfiguration: {
              appDSL: { theme },
            },
          } = newState;
          const previousGlobalCSS = theme?.css?.custom || '';

          const updatedNode = {
            ...nodeDSL,
            props: {
              ...nodeDSL.props,
              className: `${nodeClassName ? `${nodeClassName} ` : ''}${className}`,
              style: rest,
              css: undefined,
            },
          };

          const newGlobalCSS = `${previousGlobalCSS}\n.${className} {\n${convertReactStyleToCSS(
            literalStyle as StyleObject,
          )}\n${((css || '') as string).replace(/^\s*/gm, '')}\n}`;

          return {
            ...newState,
            appConfiguration: {
              ...newState.appConfiguration,
              appDSL: {
                ...newState.appConfiguration.appDSL,
                nodes: {
                  ...newState.appConfiguration.appDSL.nodes,
                  [nodeID]: updatedNode,
                },
                theme: {
                  ...theme,
                  css: {
                    ...theme?.css,
                    custom: newGlobalCSS,
                  },
                },
              },
            },
          };
        }
      }
    },
  }),
]);

export const componentsReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
