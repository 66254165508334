import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings } from '../../common';
import { getBorderSection, getCSSSection, getCommonActionSettings, getCommonFormFieldPropertiesSettings, getCursorSection, getDivider, getFlexChildSection, getGridChildSection, getPropertySections, getSizeSection, getSpacingSection, } from '../../common/settings';
export const getBuilderComponentsFormRichtextEditor = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        ...getCommonFormFieldPropertiesSettings(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'richtext-editor',
                            title: 'Rich Text Editor',
                            children: [
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: [...prefixPath, 'editorType'],
                                    label: 'Type',
                                    required: true,
                                    options: [
                                        { label: 'Classic', value: 'Classic' },
                                        { label: 'Inline', value: 'Inline' },
                                        { label: 'Document', value: 'Document' },
                                        { label: 'Ballon Block', value: 'BallonBlock' },
                                        { label: 'Ballon', value: 'Ballon' },
                                    ],
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.textArea,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: [...prefixPath, 'data'],
                                    label: 'Data',
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: [...prefixPath, 'config'],
                                    label: 'Config',
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: [...prefixPath, 'disabled'],
                                    label: 'Disabled',
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(prefixPath),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                getSpacingSection(prefixPath),
                getDivider(),
                getFlexChildSection(prefixPath),
                getDivider(),
                getGridChildSection(prefixPath),
                getDivider(),
                getSizeSection(prefixPath),
                getDivider(),
                getBorderSection({ prefixPath, systemPrefixPath: prefixPath }),
                getDivider(),
                getCursorSection(prefixPath),
                getDivider(),
                getCSSSection(prefixPath),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChange',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onReady',
                            label: 'On Ready',
                            keyPath: [...prefixPath, 'onReady'],
                            children: getCommonActionSettings([...prefixPath, 'onReady']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onBlur',
                            label: 'On Blur',
                            keyPath: [...prefixPath, 'onBlur'],
                            children: getCommonActionSettings([...prefixPath, 'onBlur']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onFocus',
                            label: 'On Focus',
                            keyPath: [...prefixPath, 'onFocus'],
                            children: getCommonActionSettings([...prefixPath, 'onFocus']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChange',
                            label: 'On Error',
                            keyPath: [...prefixPath, 'onError'],
                            children: getCommonActionSettings([...prefixPath, 'onError']),
                        },
                    ],
                },
            ],
        },
    ],
});
export const BuilderComponentsFormRichtextEditorSettings = getBuilderComponentsFormRichtextEditor();
