/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types, no-new-func */
import debounce from 'lodash.debounce';
import { isEmpty } from 'ramda';
import { LIBRARIES } from '@builder/schemas';
import { log } from '@builder/utils';
const libsInstances = {};
const loadScriptLibrary = (idScript, urlCDN) => {
    if (libsInstances[idScript]) {
        return libsInstances[idScript];
    }
    const request = new XMLHttpRequest();
    request.open('GET', urlCDN, false);
    request.send(null);
    if (request.status === 200) {
        const script = document.createElement('script');
        script.id = idScript;
        script.type = 'text/javascript';
        script.async = false;
        script.defer = false;
        script.text = `${request.responseText}`;
        document.body.appendChild(script);
    }
    else {
        // TODO: implement alternative library when process fail
    }
    libsInstances[idScript] = window[idScript];
    const libraryCDN = window.document.getElementById(idScript);
    libraryCDN && window.document.body.removeChild(libraryCDN);
    try {
        delete window[idScript];
    }
    catch (e) {
        window[idScript] = undefined;
    }
    return libsInstances[idScript];
};
export const throttledLogWarn = debounce(log.warn, 500);
export const createFunction = (functionCode, functionState, functionLibraries, isNewFunction) => {
    const handler = {
        apply() {
            const librariesState = functionLibraries.reduce((libraries, library) => {
                const libraryData = LIBRARIES.find(lib => lib.name === library.name);
                const libraryVersion = libraryData === null || libraryData === void 0 ? void 0 : libraryData.versions.find(({ version }) => version === library.version);
                const libraryInstance = (libraryVersion === null || libraryVersion === void 0 ? void 0 : libraryVersion.CDN) && (libraryData === null || libraryData === void 0 ? void 0 : libraryData.varName)
                    ? loadScriptLibrary(libraryData === null || libraryData === void 0 ? void 0 : libraryData.varName, libraryVersion.CDN)
                    : undefined;
                return Object.assign(Object.assign({}, libraries), { [library.alias]: libraryInstance });
            }, {});
            if (!isEmpty(librariesState)) {
                if ('librariesState' in window.parent) {
                    window.parent.librariesState = Object.assign(Object.assign({}, window.parent.librariesState), librariesState);
                }
                else {
                    window.parent.librariesState = librariesState;
                }
            }
            const allStates = Object.assign(Object.assign({}, functionState), librariesState);
            if (!isNewFunction && /await(\s|\()/gm.test(functionCode)) {
                const AsyncFunction = new Function('return Object.getPrototypeOf(async function () {}).constructor')();
                const func = new AsyncFunction(...Object.keys(allStates), `${functionCode}`);
                return func(...Object.values(allStates));
            }
            const func = new Function(...Object.keys(allStates), `${functionCode}`);
            return func(...Object.values(allStates));
        },
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Proxy(() => { }, handler);
};
