import { gql } from '@apollo/client';

export const TEAM_MEMBER_FRAGMENT = gql`
  fragment TeamMember on SystemEnvironmentMember {
    email
    firstName
    lastName
    registeredAt
    avatar {
      id
      downloadUrl
    }
    roles {
      items {
        id
        name
      }
    }
  }
`;

export const TEAM_MEMBER_LIST_QUERY = gql`
  query WorkspaceMemberList {
    system {
      environmentMembersList {
        items {
          ...TeamMember
        }
      }
    }
  }
  ${TEAM_MEMBER_FRAGMENT}
`;

export const CURRENT_TEAM_MEMBER_QUERY = gql`
  query CurrentTeamMember($filter: SystemEnvironmentMemberFilter!) {
    system {
      environmentMember(filter: $filter) {
        ...TeamMember
      }
    }
  }
  ${TEAM_MEMBER_FRAGMENT}
`;
