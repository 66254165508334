import React, { useState, useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { StyledPre } from '../DeployProgress.styles';
import { DEPLOY_STEP_TIMESTAMP_FORMAT } from 'src/shared/constants';

type DeployStepProgressProps = {
  isPending: boolean;
  startedAt: DateTime;
};

const FAKE_STEPS = [
  { delay: 8678, message: 'Connecting to server' },
  { delay: 9774, message: 'Adding task in the queue' },
  { delay: 11473, message: 'Waiting for available instances' },
  { delay: 3667, message: 'Getting instance' },
  { delay: 16179, message: 'Configuring instance' },
  { delay: 9734, message: 'Running task' },
];

const getElapsedTime = (index: number) => {
  return FAKE_STEPS.slice(0, index).reduce((acc, step) => acc + step.delay, 0);
};

const formatTimestamp = (date: DateTime) => {
  return date.toUTC().toFormat(DEPLOY_STEP_TIMESTAMP_FORMAT);
};

// Initialize is a fake loader for ~60 seconds (while AWS starting task in Fargate)
// for deploy in pending state it adds new step every 10 seconds to provide a better UX
export const DeployInitializeProgress: React.FC<DeployStepProgressProps> = ({
  isPending,
  startedAt,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(
    function simulateInitializeStepProgress() {
      if (isPending) {
        const timeoutId = setInterval(() => {
          setCurrentStep(prevStep => {
            if (prevStep + 1 <= FAKE_STEPS.length) {
              return prevStep + 1;
            }

            return prevStep;
          });
        }, 10000);

        return () => clearInterval(timeoutId);
      }

      // If we rendering finished deploy we must skip progress and display complete log
      setCurrentStep(FAKE_STEPS.length);
    },
    [isPending],
  );

  const renderSteps = (index: number) => {
    return FAKE_STEPS.slice(0, index)
      .map((step, stepIndex) => {
        return `${formatTimestamp(startedAt.plus(getElapsedTime(stepIndex)))} ${step.message}`;
      })
      .join('\n');
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item container direction="row" gap={1} alignItems="center">
        <Grid item>
          <Choose>
            <When condition={currentStep === FAKE_STEPS.length}>
              <CheckCircleIcon style={{ verticalAlign: 'top' }} />
            </When>
            <Otherwise>
              <PendingIcon style={{ verticalAlign: 'top' }} />
            </Otherwise>
          </Choose>
        </Grid>
        <Grid item>
          <Typography variant="body1">Initialize</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Choose>
          <When condition={currentStep === 0}>
            <StyledPre>Loading...</StyledPre>
          </When>
          <Otherwise>
            <StyledPre>{renderSteps(currentStep)}</StyledPre>
          </Otherwise>
        </Choose>
      </Grid>
    </Grid>
  );
};
