import React from 'react';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { DialogContentWithIcon } from 'src/dialogs/common/components';
import { useDialogState } from 'src/providers';
import { MESSAGES } from 'src/shared/constants';

const DIALOG_ID = DASHBOARD_DIALOGS.BROKEN_LOADED_DSL_DIALOG_ID;
export const BrokenLoadedDSLDialog: React.FC = () => {
  const { isOpen } = useDialogState(DIALOG_ID);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{MESSAGES.dialogs.brokenLoadedDSLDialog.title}</DialogTitle>
      <DialogContentWithIcon>
        <InfoOutlinedIcon color="primary" />
        <DialogContentText>
          <Typography mt={0.5} variant="body3">
            {MESSAGES.dialogs.brokenLoadedDSLDialog.body}
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Link
          href="https://8base-dev.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          color="rgb(255,255,255)"
          variant="subtitle1"
        >
          Contact Support
        </Link>
      </DialogActions>
    </Dialog>
  );
};
