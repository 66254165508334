import React, { forwardRef, Ref } from 'react';

import { Tooltip } from '@mui/material';

import { Icon, IconNameType } from 'src/shared/components/Icon';

import {
  ComponentItemContainer,
  ComponentItemIcon,
  ComponentItemIconWrapper,
  ComponentItemTitle,
  GroupsItemContainer,
  LayoutEditorComponent,
} from './ComponentPresentationOnly.styles';

type ComponentItemPresentationOnlyProps = {
  title: string;
  icon: string;
  isDragging: boolean;
  tooltipTitle?: string;
  isGroup?: boolean;
  isLayoutEditorButton?: boolean;
  'data-test'?: string;
};

interface ComponentTooltipWrapperProps {
  children: JSX.Element;
  title?: string;
}

const ComponentTooltipWrapper: React.FC<ComponentTooltipWrapperProps> = ({ children, title }) => {
  if (!title) {
    return children;
  }

  return (
    <Tooltip arrow disableInteractive title={title as string}>
      {children}
    </Tooltip>
  );
};

export const ComponentPresentationOnly = forwardRef(
  (
    {
      title,
      icon,
      isDragging,
      tooltipTitle,
      'data-test': dataTest,
      isGroup,
      isLayoutEditorButton,
    }: ComponentItemPresentationOnlyProps,

    ref: Ref<HTMLDivElement>,
  ): JSX.Element => {
    const componentContent = (
      <>
        <ComponentItemIcon>
          <ComponentItemIconWrapper>
            <Icon name={icon as IconNameType} />
          </ComponentItemIconWrapper>
        </ComponentItemIcon>
        <ComponentItemTitle isDragging={isDragging} variant="caption">
          {title}
        </ComponentItemTitle>
      </>
    );

    return (
      <Choose>
        <When condition={Boolean(isGroup)}>
          <ComponentTooltipWrapper title={tooltipTitle}>
            <GroupsItemContainer ref={ref} isDragging={isDragging} data-test={dataTest}>
              {componentContent}
            </GroupsItemContainer>
          </ComponentTooltipWrapper>
        </When>
        <When condition={Boolean(isLayoutEditorButton)}>
          <ComponentTooltipWrapper title={tooltipTitle}>
            <LayoutEditorComponent ref={ref} isDragging={isDragging} data-test={dataTest}>
              {title}
            </LayoutEditorComponent>
          </ComponentTooltipWrapper>
        </When>
        <Otherwise>
          <ComponentTooltipWrapper title={tooltipTitle}>
            <ComponentItemContainer ref={ref} isDragging={isDragging} data-test={dataTest}>
              {componentContent}
            </ComponentItemContainer>
          </ComponentTooltipWrapper>
        </Otherwise>
      </Choose>
    );
  },
);
