import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { required } from '@builder/utils';

import { SslCertificateFormValues } from 'src/features/deploy/deploy-settings/types';
import { Button, CssGrid, Form, FormTextField, TextFieldProps } from 'src/shared/components';

const CRT_FIELD = {
  fieldProps: { name: `crt`, validate: required },
  inputProps: {
    label: 'Certificate (CRT)',
    variant: 'outlined',
    fullWidth: true,
    multiline: true,
    minRows: 3,
  } as TextFieldProps,
};

const KEY_FIELD = {
  fieldProps: { name: `key`, validate: required },
  inputProps: {
    label: 'Private Key (KEY)',
    variant: 'outlined',
    fullWidth: true,
    multiline: true,
    minRows: 3,
  } as TextFieldProps,
};

const CABUNDLE_FIELD = {
  fieldProps: { name: `cabundle`, validate: required },
  inputProps: {
    label: 'Certificate Authority Bundle (CABUNDLE)',
    variant: 'outlined',
    fullWidth: true,
    multiline: true,
    minRows: 3,
  } as TextFieldProps,
};

export const SslCertificateUpsertDialog = ({
  opened,
  close,
  initialValues,
  handleSubmit,
}: {
  opened: boolean;
  close: () => void;
  initialValues: SslCertificateFormValues;
  handleSubmit: (data: SslCertificateFormValues) => Promise<void>;
}): React.ReactElement => {
  return (
    <Dialog open={opened} onClose={close} fullWidth maxWidth="xs">
      <Form enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {formRenderProps => (
          <form onSubmit={formRenderProps.handleSubmit}>
            <DialogTitle>Upload SSL Certificate</DialogTitle>
            <DialogContent>
              <CssGrid gap={2}>
                <FormTextField
                  fieldProps={CRT_FIELD.fieldProps}
                  inputProps={CRT_FIELD.inputProps}
                />
                <FormTextField
                  fieldProps={KEY_FIELD.fieldProps}
                  inputProps={KEY_FIELD.inputProps}
                />
                <FormTextField
                  fieldProps={CABUNDLE_FIELD.fieldProps}
                  inputProps={CABUNDLE_FIELD.inputProps}
                />
              </CssGrid>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="success"
                disabled={!formRenderProps.dirty}
                loading={formRenderProps.isSubmitting}
              >
                Upload
              </Button>
              <Button variant="contained" color="default" onClick={close}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};
