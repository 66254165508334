import { MediaQueryDSL, CSS } from '@builder/schemas';
import { CSSParser } from '@builder/utils';

import { DashboardState } from 'src/store/common';

export const deleteCSSMediaQuery = (
  dashboardState: DashboardState,
  mediaQuery: MediaQueryDSL,
): CSS => {
  const mediaQueriesCSS = dashboardState.appConfiguration.appDSL.theme?.css?.mediaQueries || '';

  if (mediaQueriesCSS) {
    return CSSParser.removeMediaQueryByMediaFeatures(mediaQueriesCSS, mediaFeatures => {
      const minFeaturePX = mediaFeatures.find(
        feature => feature.resolution === 'min-width' && feature.resolutionUnit === 'px',
      );
      const maxFeaturePX = mediaFeatures.find(
        feature => feature.resolution === 'max-width' && feature.resolutionUnit === 'px',
      );

      return (
        (minFeaturePX?.resolutionValue ?? '') === (mediaQuery.min ?? '') &&
        (maxFeaturePX?.resolutionValue ?? '') === (mediaQuery.max ?? '')
      );
    });
  }

  return mediaQueriesCSS;
};
