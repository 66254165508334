import { isEmpty } from 'ramda';
import { resourceListSelectors, RESOURCE_TYPES } from '@builder/schemas';
import { transformVariableName } from '../../utils/transformVariableName';
import { COMMON_TYPINGS, RESOURCE_NAMES_CONST, RESOURCE_TYPES_CONST, RESOURCE_TYPINGS_CONST, } from './constants';
export class TypesGenerator {
    constructor(appDSL) {
        this.appDSL = appDSL;
    }
    generateAliasesFile() {
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(this.appDSL.resources);
        const resourceAliasesLine = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? ''
            : `
          export type ${RESOURCE_TYPINGS_CONST.ResourceEndpoint} = string;
      `;
        return `      
      ${resourceAliasesLine}
    `;
    }
    generateCommonFile() {
        return `
      export type ${COMMON_TYPINGS.ValueOf}<T> = T[keyof T];
    `;
    }
    generateResourceFile() {
        const resourceListDSL = this.appDSL.resources;
        return `
      import { ${COMMON_TYPINGS.ValueOf} } from './common';
      import { ${RESOURCE_TYPINGS_CONST.ResourceEndpoint} } from './aliases';

      export const ${RESOURCE_TYPES_CONST} = {
        ${RESOURCE_TYPES.backendEightBase}: '${RESOURCE_TYPES.backendEightBase}',
        ${RESOURCE_TYPES.graphql}: '${RESOURCE_TYPES.graphql}',
      } as const;
        
      export type ${RESOURCE_TYPINGS_CONST.ResourceTypes} = ${COMMON_TYPINGS.ValueOf}<typeof ${RESOURCE_TYPES_CONST}>;

      export const ${RESOURCE_NAMES_CONST} = {
        ${Object.values(resourceListDSL)
            .filter(resource => resource.type !== RESOURCE_TYPES.rest)
            .map(resource => `${transformVariableName(resource.name)}: '${transformVariableName(resource.name)}',`)
            .join('\n')}
      } as const;
        
      export type ${RESOURCE_TYPINGS_CONST.ResourceNames} = ${COMMON_TYPINGS.ValueOf}<typeof ${RESOURCE_NAMES_CONST}>;

      export type ${RESOURCE_TYPINGS_CONST.Resource} = {
        name: ${RESOURCE_TYPINGS_CONST.ResourceNames};
        endpoint: ${RESOURCE_TYPINGS_CONST.ResourceEndpoint};
        type: ${RESOURCE_TYPINGS_CONST.ResourceTypes};
      };
      
      export type ${RESOURCE_TYPINGS_CONST.ResourceList} = Record<${RESOURCE_TYPINGS_CONST.ResourceNames}, ${RESOURCE_TYPINGS_CONST.Resource}>;
    `;
    }
    generateIndexFile() {
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(this.appDSL.resources);
        const resourceFileImport = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? ''
            : "export * from './resource';";
        const aliasesFile = this.generateAliasesFile().trim();
        const aliasesFileImport = isEmpty(aliasesFile) ? '' : "export * from './aliases';";
        return `
        export * from './common';
        ${aliasesFileImport}
        ${resourceFileImport}
      `;
    }
}
