import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { getCommonPropertiesSections, getDivider, getStylesSection, getActionSettings, getBasicPropsSettings, BASIC_PROPS_TOOLTIP, pickSettings, NS_ID, } from '../../common';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_PROPS_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.childrenTextRich,
                name: 'text',
                label: 'Content',
                keyPath: ['content'],
            },
        ],
    },
    getDivider(),
    ...getBasicPropsSettings(),
    getDivider(),
    ...getCommonPropertiesSections(),
    getDivider(),
];
const getStyleSettings = (prefixPath = []) => [
    ...getStylesSection({ prefixPath }),
];
export const RICH_TEXT_SETTINGS = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: pipe(pickSettings([
                [NS_ID.style.flexChild.root],
                [NS_ID.style.gridChild.root],
                [NS_ID.style.font.root],
                [NS_ID.style.background.root],
                [NS_ID.style.border.root],
                [NS_ID.style.cursor.root],
                [NS_ID.style.css.root],
            ]))(getStyleSettings()),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
