import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { builderComponentsBoxProps } from '../BuilderComponentsBox/BuilderComponentsBox';
import { BuilderComponentsBoxSettings } from '../BuilderComponentsBox/BuilderComponentsBox.settings';
export const BuilderComponentsFooter = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFooter,
    displayName: 'Footer',
    icon: 'footer',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Box',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.tableHeader,
                },
            },
        ],
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 56 }),
                htmlElement: 'footer',
            },
        },
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), builderComponentsBoxProps),
    },
    settings: BuilderComponentsBoxSettings,
};
