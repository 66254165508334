import { mapObjIndexed, path, pathOr, mergeDeepRight, omit, values } from 'ramda';
import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_SETTING_ACTION_TYPE, PropAssert, PropChecker, componentListSelectors, resourceListSelectors, PREDEFINED_ACTIONS, ACTIONS_ARGS_SCHEMA, hasPropJsCode, transformPropWithJsCode, STATE_SCOPES, RUNTIMESTATE, COMPONENT_DSL_NAMES, STATE_ACTIONS, STATE_TYPES, } from '@builder/schemas';
import { ERROR_SCOPES, isArray, isBoolean, isNil, isNull, isNumber, isObject, isString, SystemError, pathToString, serialize, } from '@builder/utils';
import { createAndRunFunction, createCSSSerializedStylesWithMemo } from '../../../utils';
import { getReactComponentByName } from '../../getReactComponentByName';
import { RuntimePropExecutor } from '../../RuntimePropExecutor';
import { NodeErrorGetter, StateRequestErrorGetter } from '../audit-message-getters';
import { NodeValidator, StateRequestValidator } from '../prop-validator';
import { GetStatesByArgsType } from '../utils/getStatesByArgType';
import { isValidReactElement } from '../utils/isValidReactElement';
class PropTransformer {
    constructor(componentListDSL, assetListDSL, { appEngineDSLValidator }, libraries) {
        this.componentListDSL = componentListDSL;
        this.assetListDSL = assetListDSL;
        this.appEngineDSLValidator = appEngineDSLValidator;
        this.runtimePropExecutor = new RuntimePropExecutor({
            errorGetter: appEngineDSLValidator.errorGetter,
            libraries,
            onAppFunctionError: appEngineDSLValidator.getFunctionPassErrorNotification.bind(appEngineDSLValidator),
        });
        this.appDSLLibraries = libraries;
    }
    /**
     * Transforms React node prop.
     */
    transformReactNodeProp({ propPath, propValue, propDSL }, externalState, { generateNode }) {
        PropAssert.Schema.assertIsReactNodeProp(propDSL, pathToString(propPath));
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const reactElement = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            const filteredReactElement = isArray(reactElement)
                ? reactElement.filter(Boolean)
                : reactElement;
            if (!this.appEngineDSLValidator.checkTransformedReactNodeProp(filteredReactElement, propPath)) {
                return null;
            }
            return filteredReactElement;
        }
        if (!this.appEngineDSLValidator.checkReactNodeProp(propValue, propPath)) {
            return null;
        }
        const resultNodes = propValue.nodes
            .map((childNodID) => generateNode && generateNode(childNodID, externalState))
            .flat();
        if (resultNodes.length === 0) {
            return null;
        }
        if (resultNodes.length === 1) {
            return resultNodes[0];
        }
        return resultNodes;
    }
    /**
     * Transforms calculated react node prop.
     * @deprecated
     */
    transformCalculatedReactNodeProp({ propPath, propValue }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            return this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
        }
        PropAssert.Value.assertIsCalculatedProp(propValue, pathToString(propPath));
        const func = createAndRunFunction(`return ${propValue.calc}`, externalState, {
            onAppFunctionError: this.appEngineDSLValidator.getFunctionPassErrorNotification(propPath),
            libraries: this.appDSLLibraries,
        });
        return func ? func(propValue.data) : null;
    }
    /**
     * Transforms callback code prop.
     */
    transformCallbackCodeProp({ propPath, propValue, propDSL: propSchema }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            return this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
        }
        PropAssert.Value.assertIsCallbackCodeProp(propValue, pathToString(propPath));
        PropAssert.Schema.assertIsCallbackCodeProp(propSchema, pathToString(propPath));
        return (...functionArgs) => {
            const passedData = propSchema.args.reduce((accum, arg) => {
                return Object.assign(Object.assign({}, accum), { [arg.name]: path(arg.path, functionArgs) });
            }, {});
            const funcBody = propSchema.async
                ? `return ( async () => { ${propValue.trim()} })()`
                : `${propValue.trim()}`;
            const funcResult = createAndRunFunction(funcBody, Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign(Object.assign({}, externalState.localState), passedData), { args: functionArgs }) }), {
                onAppFunctionError: this.appEngineDSLValidator.getFunctionPassErrorNotification(propPath),
                libraries: this.appDSLLibraries,
            });
            return funcResult || null;
        };
    }
    /**
     * Transforms action prop.
     */
    transformActionProp({ propPath, propValue, propDSL: propSchema }, externalState, { generateNode }) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            return this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
        }
        PropAssert.Value.assertIsActionProp(propValue, pathToString(propPath), {
            allowJSInjection: true,
        });
        PropAssert.Schema.assertIsActionProp(propSchema, pathToString(propPath));
        return (...functionArgs) => {
            const transformedArgs = !propValue.args
                ? {}
                : this.transformDSLPropListToReactProps({
                    initialPropPath: propPath,
                    propListValue: propValue.args,
                    propListDSL: ACTIONS_ARGS_SCHEMA[propValue.actionType],
                }, externalState, { generateNode });
            const passedData = propSchema.args.reduce((accum, arg) => {
                return Object.assign(Object.assign({}, accum), { [arg.name]: path(arg.path, functionArgs) });
            }, {});
            const getCustomCodeFunctionBody = () => {
                var _a, _b, _c, _d;
                if (propSchema.async) {
                    return `return ( async () => { ${(_b = (_a = propValue.args) === null || _a === void 0 ? void 0 : _a.code) === null || _b === void 0 ? void 0 : _b.trim()} })()`;
                }
                return `${(_d = (_c = propValue.args) === null || _c === void 0 ? void 0 : _c.code) === null || _d === void 0 ? void 0 : _d.trim()}`;
            };
            const getFunctionCodeFunctionBody = () => {
                const functionProps = serialize.parse(String(propValue.args.function) || '');
                const functionState = Object.assign(Object.assign(Object.assign({}, externalState.globalState), externalState.localState), externalState.localStates)[functionProps.nameFunction];
                if (!functionState) {
                    throw new Error(`Function with name "${functionProps.nameFunction}" not found`);
                }
                const codes = Object.values(functionProps.parameters);
                const cleanArguments = codes.map(arg => {
                    return hasPropJsCode(arg) ? transformPropWithJsCode(arg) : arg;
                });
                const functionString = `${functionState.code}(${cleanArguments.join(', ')})`;
                return `${functionString === null || functionString === void 0 ? void 0 : functionString.trim()}`;
            };
            const getUpdateStateCodeFunctionBody = () => {
                const stateArgs = propValue.args.stateArgs;
                const { stateName, stateType, stateValue, stateAction, stateKey } = stateArgs;
                const transformedStateValue = stateValue && hasPropJsCode(stateValue)
                    ? this.runtimePropExecutor.transformPropJsCode({
                        propValue: stateValue,
                        propPath,
                        externalState,
                    })
                    : stateValue;
                const transformedStateKey = stateKey && hasPropJsCode(stateKey)
                    ? this.runtimePropExecutor.transformPropJsCode({
                        propValue: stateKey,
                        propPath,
                        externalState,
                    })
                    : stateKey;
                if (stateName && stateAction) {
                    switch (stateType) {
                        case STATE_TYPES.string:
                            if (transformedStateValue) {
                                return `return ( () => {${stateName}.${stateAction}(${`"${transformedStateValue}"`})})()`;
                            }
                            break;
                        case STATE_TYPES.number:
                            if (transformedStateValue) {
                                return `return ( () => {${stateName}.${stateAction}(${transformedStateValue})})()`;
                            }
                            break;
                        case STATE_TYPES.boolean:
                            if (transformedStateValue && stateAction === STATE_ACTIONS.setValue) {
                                return `return ( () => {${stateName}.${stateAction}(${transformedStateValue})})()`;
                            }
                            return `return ( () => {${stateName}.${stateAction}()})()`;
                        case STATE_TYPES.array:
                            if (transformedStateValue && stateAction === STATE_ACTIONS.setValue) {
                                return `return ( () => {${stateName}.${stateAction}(${isArray(transformedStateValue)
                                    ? JSON.stringify(transformedStateValue)
                                    : transformedStateValue})})()`;
                            }
                            return `return ( () => {${stateName}.${stateAction}(${JSON.stringify(transformedStateValue)})})()`;
                        case STATE_TYPES.object:
                            if (transformedStateValue && transformedStateKey) {
                                return `return ( () => {${stateName}.${stateAction}("${transformedStateKey}","${transformedStateValue}")})()`;
                            }
                            return `return ( () => {${stateName}.${stateAction}(${isObject(transformedStateValue)
                                ? JSON.stringify(transformedStateValue)
                                : transformedStateValue})})()`;
                    }
                }
                return `return ( () => {})()`;
            };
            let funcBody;
            switch (propValue.actionType) {
                case COMPONENT_SETTING_ACTION_TYPE.customCode: {
                    funcBody = getCustomCodeFunctionBody();
                    break;
                }
                case COMPONENT_SETTING_ACTION_TYPE.updateState: {
                    funcBody = getUpdateStateCodeFunctionBody();
                    break;
                }
                case COMPONENT_SETTING_ACTION_TYPE.functionCode: {
                    funcBody = getFunctionCodeFunctionBody();
                    break;
                }
                default: {
                    funcBody = PREDEFINED_ACTIONS[propValue.actionType];
                }
            }
            const handledState = new GetStatesByArgsType(propValue.args, externalState);
            const funcResult = createAndRunFunction(funcBody, Object.assign(Object.assign({}, handledState.getState()), { localState: Object.assign(Object.assign(Object.assign(Object.assign({}, handledState.getState().localState), transformedArgs), passedData), { code: funcBody, args: functionArgs }) }), {
                onAppFunctionError: this.appEngineDSLValidator.getFunctionPassErrorNotification(propPath),
                libraries: this.appDSLLibraries,
            });
            return funcResult || null;
        };
    }
    /**
     * Transforms callback component prop.
     */
    transformCallbackComponentProp({ propPath, propValue, propDSL: propSchema }, externalState, { generateNode }) {
        PropAssert.Value.assertIsCallbackComponentProp(propValue, pathToString(propPath));
        PropAssert.Schema.assertIsCallbackComponentProp(propSchema, pathToString(propPath));
        return (...functionArgs) => {
            const passedData = propSchema.args.reduce((accum, arg) => {
                return Object.assign(Object.assign({}, accum), { [arg.name]: path(arg.path, functionArgs) });
            }, {});
            const passedDataID = pathOr(undefined, propSchema.pathToUniqueKey, functionArgs);
            return propValue.nodes
                .map(child => generateNode &&
                generateNode(child, Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign({}, externalState.localState), (passedData.formProps ? Object.assign({ passedData }, passedData.formProps) : passedData)) }), passedDataID))
                .flat()
                .filter(Boolean);
        };
    }
    /**
     * Transforms string prop.
     */
    transformStringProp({ propPath, propValue }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            let transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (typeof transformedValue === 'number') {
                transformedValue = `${transformedValue}`;
            }
            // workaround until we can't handle several types, e,g, "reactNode" or "string"
            if (isValidReactElement(transformedValue)) {
                return transformedValue;
            }
            if (!this.appEngineDSLValidator.checkTransformedStringProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkStringProp(propValue, propPath)) {
            return null;
        }
        return propValue;
    }
    transformHTMLProp({ propPath, propValue }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            let transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (typeof transformedValue === 'number') {
                transformedValue = `${transformedValue}`;
            }
            if (!this.appEngineDSLValidator.checkHTMLFragmentValidProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkStringProp(propValue, propPath)) {
            return null;
        }
        if (!this.appEngineDSLValidator.checkHTMLFragmentValidProp(propValue, propPath)) {
            return null;
        }
        return propValue;
    }
    /**
     * Transforms enum prop.
     */
    transformEnumProp({ propValue, propPath }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            return this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
        }
        return propValue;
    }
    /**
     * Transforms number prop.
     */
    transformNumberProp({ propPath, propValue, propDSL }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (!this.appEngineDSLValidator.checkTransformedNumberProp(transformedValue, propPath)) {
                return null;
            }
            if (!this.appEngineDSLValidator.checkTransformedNumberProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkNumberProp(propValue, propPath)) {
            return null;
        }
        if (!this.appEngineDSLValidator.checkNumberMinValueProp(propValue, propPath, propDSL)) {
            return null;
        }
        if (!this.appEngineDSLValidator.checkNumberMaxValueProp(propValue, propPath, propDSL)) {
            return null;
        }
        return propValue;
    }
    /**
     * Transforms boolean prop.
     */
    transformBooleanProp({ propPath, propValue }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (!this.appEngineDSLValidator.checkTransformedBooleanProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkBooleanProp(propValue, propPath)) {
            return null;
        }
        return propValue;
    }
    /**
     * Transforms component prop.
     */
    transformComponentProp({ propPath, propValue }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            return this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
        }
        PropAssert.Value.assertIsComponentProp(propValue, pathToString(propPath));
        return getReactComponentByName(this.componentListDSL, propValue);
    }
    /**
     * Transforms asset prop.
     */
    transformAssetProp({ propPath, propValue }, externalState) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (isString(transformedValue)) {
                return transformedValue;
            }
            if (!this.appEngineDSLValidator.checkTransformedAssetProp(transformedValue, propPath)) {
                return null;
            }
            PropAssert.Value.assertIsAssetProp(transformedValue, pathToString(propPath));
            const assetDSL = this.assetListDSL[transformedValue.assetID];
            if (!assetDSL) {
                return null;
            }
            return assetDSL.src;
        }
        if (isString(propValue)) {
            return propValue;
        }
        if (!this.appEngineDSLValidator.checkAssetProp(propValue, propPath)) {
            return null;
        }
        PropAssert.Value.assertIsAssetProp(propValue, pathToString(propPath));
        const assetDSL = this.assetListDSL[propValue.assetID];
        if (!assetDSL) {
            return null;
        }
        return assetDSL.src;
    }
    /**
     * Transforms any prop.
     */
    transformAnyProp({ propName, propPath, propValue, propListDSL, propListValue }, externalState, { generateNode }) {
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            return transformedValue;
        }
        const anyPropDSL = this.generatePropsSchema(propValue);
        return this.transformProp({
            propName,
            propPath,
            propValue,
            propDSL: anyPropDSL,
            propListDSL,
            propListValue,
        }, externalState, {
            generateNode,
        });
    }
    /**
     * Transforms custom properties prop.
     */
    transformCustomPropsProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, { generateNode }) {
        PropAssert.Schema.assertIsCustomPropsProp(propDSL, pathToString(propPath));
        this.appEngineDSLValidator.checkUniqKeyPropOnComponent({
            propName,
            propPath,
            propValue,
            propDSL,
            propListDSL,
            propListValue,
        });
        if (!this.appEngineDSLValidator.checkArrayProp(propValue, propPath)) {
            return null;
        }
        const transformedValue = this.transformAnyProp({ propName, propPath, propDSL, propValue, propListDSL, propListValue }, externalState, {
            generateNode,
        }) || [];
        if (isArray(transformedValue)) {
            return transformedValue === null || transformedValue === void 0 ? void 0 : transformedValue.map(arrayPropItemValue => {
                const { Key, Value } = arrayPropItemValue;
                const key = Key;
                PropAssert.Value.assertIsValidCustomProperty(key, key);
                PropAssert.Value.assertIsValidCustomValue(Value);
                return { [key === null || key === void 0 ? void 0 : key.toLowerCase()]: Value };
            });
        }
        return null;
    }
    /**
     * Transforms add field prop.
     */
    transformfieldValidationProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, { generateNode }) {
        PropAssert.Schema.assertIsFieldValidationProp(propDSL, pathToString(propPath));
        this.appEngineDSLValidator.checkUniqKeyPropOnComponent({
            propName,
            propPath,
            propValue,
            propDSL,
            propListDSL,
            propListValue,
        });
        if (!this.appEngineDSLValidator.checkArrayProp(propValue, propPath)) {
            return null;
        }
        const transformedValue = this.transformAnyProp({ propName, propPath, propDSL, propValue, propListDSL, propListValue }, externalState, {
            generateNode,
        }) || [];
        if (isArray(transformedValue)) {
            return transformedValue === null || transformedValue === void 0 ? void 0 : transformedValue.map(arrayPropItemValue => {
                const { Key, Type, Value, Validation } = arrayPropItemValue;
                const validation = Validation || [];
                PropAssert.Value.assertIsValidFieldValidationProperty(Key, Key);
                PropAssert.Value.assertIsValidFieldValidationProperty(Type, Type);
                PropAssert.Value.assertIsValidFieldValidationValue(Value, Value, Type);
                PropAssert.Value.assertIsArrayProp(validation);
                return { Key, Type, Value, Validation: validation };
            });
        }
        return null;
    }
    /**
     * @returns Scope selector for user-defined custom CSS
     */
    getCustomCSSExtraScope() {
        return '#user-app-root &';
    }
    /**
     * Transforms css prop.
     */
    transformCSSProp({ propName, propPath, propDSL, propValue }, externalState) {
        if (!propValue) {
            return null;
        }
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            let cssString = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (typeof cssString === 'string' &&
                cssString.trim().length > 0 &&
                cssString.trim().slice(-1) !== ';') {
                cssString = `${cssString.trim()};`;
            }
            if (isNull(cssString)) {
                return cssString;
            }
            const cssImportant = typeof cssString === 'string' ? cssString.replaceAll(';', ' !important;') : cssString;
            return createCSSSerializedStylesWithMemo(cssImportant);
        }
        if (!this.appEngineDSLValidator.checkCSSProp(propValue, propPath)) {
            return null;
        }
        PropAssert.Value.assertIsCSSProp(propValue, pathToString(propPath));
        // TODO: replace !important with css interpolation.
        const scopedCSS = typeof propValue === 'string' &&
            propValue.trim().length > 0 &&
            propValue.trim().slice(-1) !== ';'
            ? `${propValue.trim()};`
            : propValue;
        const cssImportant = typeof scopedCSS === 'string'
            ? scopedCSS.replaceAll(/(?<!!important);/g, ' !important;')
            : scopedCSS;
        const result = createCSSSerializedStylesWithMemo(cssImportant);
        const propsStyle = result.styles.split(';label');
        const importantProps = propsStyle && propsStyle[0].replaceAll(/(?:!important);/g, '!important;');
        result.styles = [importantProps, ';label', propsStyle[1]].join('');
        return result;
    }
    /**
     * Transforms style prop.
     */
    transformStyleProp({ propName, propPath, propValue, propDSL: propSchema, propListDSL, propListValue, }, externalState, { generateNode }) {
        PropAssert.Schema.assertIsStyleProp(propSchema, pathToString(propPath));
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (!this.appEngineDSLValidator.checkTransformedStyleProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkStyleProp(propValue, propPath)) {
            return null;
        }
        return this.transformObjectShapeProp({ propPath, propValue, propDSL: propSchema, propListDSL, propListValue, propName }, externalState, {
            generateNode,
        });
    }
    /**
     * Common logic for object and style props.
     */
    transformObjectShapeProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, { generateNode }, requestStateDSL) {
        if (!PropChecker.Value.isObjectProp(propValue)) {
            return null;
        }
        const objectPropSchema = PropChecker.Schema.isObjectPropWithDefinedProps(propDSL)
            ? propDSL.props
            : mapObjIndexed(this.generatePropsSchema, propValue);
        const handledExternalState = (requestStateDSL === null || requestStateDSL === void 0 ? void 0 : requestStateDSL.scope) === STATE_SCOPES.global
            ? Object.assign(Object.assign({}, omit([RUNTIMESTATE.localStates], externalState)), { localStates: {} }) : Object.assign({}, externalState);
        return Object.keys(objectPropSchema).reduce((accum, objectPropItemName) => {
            const objectPropItemValue = propValue[objectPropItemName];
            const objectPropItemSchema = objectPropSchema[objectPropItemName];
            let transformedProp = null;
            if (objectPropItemName === 'backgroundImage') {
                const imageBackground = this.transformAssetProp({
                    propName,
                    propPath: [...propPath, objectPropItemName],
                    propValue: objectPropItemValue,
                    propDSL: objectPropItemSchema,
                    propListDSL,
                    propListValue,
                }, handledExternalState);
                if (imageBackground &&
                    ((imageBackground === null || imageBackground === void 0 ? void 0 : imageBackground.startsWith('data')) || (imageBackground === null || imageBackground === void 0 ? void 0 : imageBackground.startsWith('http')))) {
                    transformedProp = `url("${imageBackground}")`;
                }
                else {
                    transformedProp = imageBackground;
                }
            }
            else {
                transformedProp = this.transformProp({
                    propName,
                    propPath: [...propPath, objectPropItemName],
                    propValue: objectPropItemValue,
                    propDSL: objectPropItemSchema,
                    propListDSL,
                    propListValue,
                }, handledExternalState, { generateNode });
            }
            return isNil(transformedProp)
                ? accum
                : Object.assign(Object.assign({}, accum), { [objectPropItemName]: transformedProp });
        }, {});
    }
    /**
     * Transforms object prop.
     */
    transformObjectProp({ propName, propPath, propValue, propDSL: propSchema, propListDSL, propListValue, }, externalState, { generateNode }, requestStateDSL) {
        PropAssert.Schema.assertIsObjectProp(propSchema, pathToString(propPath));
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (!this.appEngineDSLValidator.checkTransformedObjectProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkObjectProp(propValue, propPath)) {
            return null;
        }
        return this.transformObjectShapeProp({ propName, propPath, propValue, propDSL: propSchema, propListDSL, propListValue }, externalState, {
            generateNode,
        }, requestStateDSL);
    }
    /**
     * Transforms array prop.
     */
    transformArrayProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, { generateNode }) {
        PropAssert.Schema.assertIsArrayProp(propDSL, pathToString(propPath));
        if (this.runtimePropExecutor.hasPropJsCode(propValue)) {
            const transformedValue = this.runtimePropExecutor.transformPropJsCode({
                propValue,
                propPath,
                externalState,
            });
            if (!this.appEngineDSLValidator.checkTransformedArrayProp(transformedValue, propPath)) {
                return null;
            }
            return transformedValue;
        }
        if (!this.appEngineDSLValidator.checkArrayProp(propValue, propPath)) {
            return null;
        }
        return propValue.map((arrayPropItemValue, index) => {
            return this.transformProp({
                propName,
                propPath: [...propPath, index],
                propValue: arrayPropItemValue,
                propDSL: propDSL.item,
                propListDSL,
                propListValue,
            }, externalState, { generateNode });
        });
    }
    /**
     * Generates prop schema if prop is array or object without defined children props.
     */
    generatePropsSchema(propValue) {
        if (hasPropJsCode(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.any };
        if (isString(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.string };
        if (isNumber(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.number };
        if (isBoolean(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.boolean };
        if (isArray(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.array, item: { type: COMPONENT_DSL_PROP_TYPES.any } };
        if (isObject(propValue))
            return { type: COMPONENT_DSL_PROP_TYPES.object };
        return { type: COMPONENT_DSL_PROP_TYPES.notSupported };
    }
    transformProp(propAttributes, externalState, { generateNode }, requestStateDSL, skipTransformation) {
        // skip handle prop if it doesn't exist and requried not set
        if (!propAttributes.propDSL.required && !propAttributes.propValue) {
            return null;
        }
        this.appEngineDSLValidator.checkAllowJSProp(propAttributes);
        this.appEngineDSLValidator.checkUniquePropOnDSL(propAttributes, externalState);
        // required check calls separate because we don't need to continue handle prop in this case
        if (!this.appEngineDSLValidator.checkRequiredProp(propAttributes) ||
            (skipTransformation && propAttributes.propName === 'metaTags')) {
            return null;
        }
        let transformedProp = this.getTransformedProp(propAttributes, externalState, {
            generateNode,
        }, requestStateDSL);
        this.appEngineDSLValidator.checkTransformedRequiredProp(propAttributes, transformedProp);
        if (hasPropJsCode(transformedProp)) {
            const propPath = transformPropWithJsCode(transformedProp).split('.');
            transformedProp = this.runtimePropExecutor.transformPropJsCode({
                propValue: transformedProp,
                propPath,
                externalState,
            });
        }
        return transformedProp;
    }
    /**
     * Transform DSL prop value to runtime value which can be passed to react component.
     */
    getTransformedProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, { generateNode }, requestStateDSL) {
        if (!propDSL) {
            throw new SystemError(ERROR_SCOPES.appEngine, `Prop schema for ${propPath} prop wasn't found.`);
        }
        switch (propDSL.type) {
            case COMPONENT_DSL_PROP_TYPES.reactNode: {
                return this.transformReactNodeProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, {
                    generateNode,
                });
            }
            case COMPONENT_DSL_PROP_TYPES.calculatedReactNode: {
                return this.transformCalculatedReactNodeProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.callback: {
                if (propDSL.body === COMPONENT_DSL_CALLBACK_TYPES.component) {
                    return this.transformCallbackComponentProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, { generateNode });
                }
                return this.transformCallbackCodeProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.action: {
                return this.transformActionProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, {
                    generateNode,
                });
            }
            case COMPONENT_DSL_PROP_TYPES.component: {
                return this.transformComponentProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.string: {
                return this.transformStringProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.htmlFragment: {
                return this.transformHTMLProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.stringOrNumber: {
                return isString(propValue)
                    ? this.transformStringProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState)
                    : this.transformNumberProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.enum: {
                return this.transformEnumProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.number: {
                return this.transformNumberProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.boolean: {
                return this.transformBooleanProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.css: {
                return this.transformCSSProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.style: {
                return this.transformStyleProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, {
                    generateNode,
                });
            }
            case COMPONENT_DSL_PROP_TYPES.asset: {
                return this.transformAssetProp({
                    propName,
                    propPath,
                    propDSL,
                    propValue,
                    propListDSL,
                    propListValue,
                }, externalState);
            }
            case COMPONENT_DSL_PROP_TYPES.object: {
                return this.transformObjectProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, {
                    generateNode,
                }, requestStateDSL);
            }
            case COMPONENT_DSL_PROP_TYPES.array: {
                return this.transformArrayProp({ propName, propPath, propValue, propDSL, propListDSL, propListValue }, externalState, {
                    generateNode,
                });
            }
            case COMPONENT_DSL_PROP_TYPES.any: {
                return this.transformAnyProp({
                    propName,
                    propPath,
                    propDSL,
                    propValue,
                    propListDSL,
                    propListValue,
                }, externalState, {
                    generateNode,
                });
            }
            case COMPONENT_DSL_PROP_TYPES.customProps: {
                const result = this.transformCustomPropsProp({
                    propName,
                    propPath,
                    propDSL,
                    propValue,
                    propListDSL,
                    propListValue,
                }, externalState, {
                    generateNode,
                });
                return result;
            }
            case COMPONENT_DSL_PROP_TYPES.fieldValidation: {
                const result = this.transformfieldValidationProp({
                    propName,
                    propPath,
                    propDSL,
                    propValue,
                    propListDSL,
                    propListValue,
                }, externalState, {
                    generateNode,
                });
                return result;
            }
            default: {
                throw new SystemError(ERROR_SCOPES.appEngine, `${propDSL.type} type for ${propValue} value wasn't handled.`);
            }
        }
    }
    /**
     * Removes all system prop from the transformed prop list.
     */
    removeSystemProps({ propListDSL: propsSchema }, reactPropList) {
        return Object.entries(reactPropList).reduce((accum, [propName, transformedPropValue]) => {
            const propSchema = propsSchema[propName];
            if (propSchema && propSchema.system) {
                return accum;
            }
            return Object.assign(Object.assign({}, accum), { [propName]: transformedPropValue });
        }, {});
    }
    /**
     * Transforms DSL props to props which can be added to react element.
     */
    transformDSLPropListToReactProps({ initialPropPath = [], propListValue = {}, propListDSL = {} }, externalState, { generateNode }, requestStateDSL, skipTransformation) {
        const reactPropList = Object.keys(propListDSL).reduce((accum, propName) => {
            const propValue = propListValue[propName];
            const propDSL = propListDSL[propName];
            const { type } = propDSL;
            const propAttributes = {
                propName,
                propPath: [...initialPropPath, propName],
                propDSL,
                propValue,
                propListDSL,
                propListValue,
            };
            const transformedProp = this.transformProp(propAttributes, externalState, {
                generateNode,
            }, requestStateDSL, skipTransformation);
            if (transformedProp && type === COMPONENT_DSL_PROP_TYPES.customProps) {
                const currentPropArr = transformedProp;
                return Object.assign(Object.assign({}, accum), currentPropArr.reduce((accumCustom, prop) => {
                    return Object.assign(Object.assign({}, accumCustom), prop);
                }, []));
            }
            return isNil(transformedProp)
                ? accum
                : Object.assign(Object.assign({}, accum), { [propName]: transformedProp });
        }, {});
        const reactPropListWithRemovedSystemProps = this.removeSystemProps({ propListValue, propListDSL }, reactPropList);
        return reactPropListWithRemovedSystemProps;
    }
}
const transformProps = ({ propListValue, componentListDSL, assetListDSL, propListDSL, requestStateDSL, }, externalState, { generateNode, appEngineDSLValidator, }, libraries, skipTransformation) => {
    const propTransformer = new PropTransformer(componentListDSL, assetListDSL, {
        appEngineDSLValidator,
    }, libraries);
    const transformedProps = propTransformer.transformDSLPropListToReactProps({
        propListValue,
        propListDSL,
    }, externalState, { generateNode }, requestStateDSL, skipTransformation);
    return transformedProps;
};
export const transformRequestStateProps = ({ requestStateDSL, componentListDSL, assetListDSL, resourceListDSL, propListDSL, }, externalState, { onAppAuditNotifications }, libraries) => {
    var _a, _b;
    const errorGetter = new StateRequestErrorGetter(requestStateDSL, onAppAuditNotifications);
    const stateRequestValidator = new StateRequestValidator(errorGetter);
    const appropriateResourceDSL = resourceListSelectors.getResourceDSL(resourceListDSL, {
        resourceID: requestStateDSL.resourceID,
    });
    const args = mergeDeepRight((_a = requestStateDSL.args) !== null && _a !== void 0 ? _a : {}, (_b = appropriateResourceDSL.default) !== null && _b !== void 0 ? _b : {});
    return transformProps({
        propListValue: args,
        componentListDSL,
        assetListDSL,
        propListDSL,
        requestStateDSL,
    }, externalState, { appEngineDSLValidator: stateRequestValidator }, libraries);
};
export const transformResourceProps = ({ resourceDSL, componentListDSL, assetListDSL, propListDSL }, externalState, { onAppAuditNotifications }, libraries) => {
    const errorGetter = new StateRequestErrorGetter(resourceDSL, onAppAuditNotifications);
    const stateRequestValidator = new StateRequestValidator(errorGetter);
    return transformProps({
        propListValue: resourceDSL,
        componentListDSL,
        assetListDSL,
        propListDSL,
    }, externalState, { appEngineDSLValidator: stateRequestValidator }, libraries);
};
/**
 * Transforms props DSL to js object with props.
 * @returns Object with transformed props.
 */
export const transformNodeProps = ({ nodeDSL, componentListDSL, nodeListDSL, assetListDSL }, externalState, { generateNode, onAppAuditNotifications, }, libraries) => {
    const errorGetter = new NodeErrorGetter(nodeDSL, onAppAuditNotifications);
    const nodeValidator = new NodeValidator(errorGetter, nodeListDSL, nodeDSL);
    nodeValidator.checkNodeAlias();
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const propListDSL = componentListSelectors.getPropListDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const skipTransformation = nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute &&
        !values(Object.assign({}, nodeDSL.context)).length;
    const transformedProps = transformProps({ componentListDSL, assetListDSL, propListValue: nodeDSL.props, propListDSL }, externalState, {
        generateNode,
        appEngineDSLValidator: nodeValidator,
    }, libraries, skipTransformation);
    return componentDSL.schema.passNodeAliasToProps
        ? Object.assign({ __nodeAlias: nodeDSL.alias }, transformedProps) : transformedProps;
};
