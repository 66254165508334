import { Switch as MaterialSwitch, SwitchProps as MaterialSwitchProps } from '@mui/material';

import { InputContainer, InputContainerProps } from '../common';

type SwitchProps = MaterialSwitchProps &
  InputContainerProps & {
    showFx?: boolean;
  };

export const Switch: React.FC<SwitchProps> = ({
  label,
  labelPlacement = 'left',
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
  enableFx,
  isFxEnabled,
  'data-test': dataTest,
  color = 'primary',
  showFx = true,
  ...rest
}) => {
  return (
    <InputContainer
      label={label}
      labelPlacement={labelPlacement}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      enableFx={enableFx}
      isFxEnabled={isFxEnabled}
      showFx={showFx}
      data-test={dataTest}
    >
      <MaterialSwitch data-test={dataTest} color={color} {...rest} />
    </InputContainer>
  );
};
