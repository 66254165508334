import styled from '@emotion/styled';

export const StyledAnchorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AnchorPoint = styled.div`
  height: 50px;
  width: 4px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  cursor: ew-resize;

  opacity: 0.5;

  transition: opacity 100ms;

  :hover,
  :after {
    opacity: 1;
  }
`;
