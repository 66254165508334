import { EventPayload } from '@8base-private/event-handler';

export function sortPayloads(a: EventPayload, b: EventPayload): number {
  if (a.operation === 'arrayInsert' && b.operation === 'arrayInsert') {
    return a.data.index > b.data.index ? 1 : -1;
  }

  if (a.operation === 'arrayDelete' && b.operation === 'arrayDelete') {
    return a.data.index > b.data.index ? -1 : 1;
  }

  return 1;
}
