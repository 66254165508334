import { ERROR_SCOPES, SchemaError } from '@builder/utils';
import { SettingSchemaChecker } from './SettingSchemaChecker';
/**
 * Throw runtime error if setting DSL has incorrect attributes.
 */
export const SettingSchemaAssert = {
    assertHasChildrenSetting(setting) {
        if (!SettingSchemaChecker.hasChildrenSetting(setting)) {
            throw new SchemaError(ERROR_SCOPES.schemas, `Setting "${setting.name}"" expect to contain children, but got ${JSON.stringify(setting)}`);
        }
    },
    assertHasName(setting) {
        if (!SettingSchemaChecker.hasName(setting)) {
            throw new SchemaError(ERROR_SCOPES.schemas, `Setting "${setting.name}"" expect to has a name, but got ${JSON.stringify(setting)}`);
        }
    },
    assertIsRadioPropView(setting) {
        if (!SettingSchemaChecker.isRadioPropView(setting)) {
            throw new SchemaError(ERROR_SCOPES.schemas, `Setting "${setting.name}"" expect to be radio prop, but got ${JSON.stringify(setting)}`);
        }
    },
    assertIsSpacingEditorPropView(setting) {
        if (!SettingSchemaChecker.isSpacingEditorPropView(setting)) {
            throw new SchemaError(ERROR_SCOPES.schemas, `Setting "${setting.name}"" expect to be spacing editor prop, but got ${JSON.stringify(setting)}`);
        }
    },
};
