import { assocPath, isEmpty, max } from 'ramda';
import { isString, isUndefined } from '@builder/utils';
/**
 * @example
 * [{ name: "fizz", path: [1]}, { name: "buzz", path: [3]}] => 3
 */
export const getMaxArgPosition = (args) => args.reduce((accum, arg) => max(accum, arg.path[0]), 0);
/**
 * @example
 * { event: { value: 'valueAlias' }, data: { row: 'row' } } -> '{ event: { value: valueAlias }, data: { row } }'
 */
const transformArgObjectToString = (argObject) => {
    if (isString(argObject)) {
        return argObject;
    }
    const argsString = Object.keys(argObject)
        .reduce((accum, key) => {
        const value = argObject[key];
        const valueStr = transformArgObjectToString(value);
        const argObj = key === valueStr ? valueStr : `${key}: ${valueStr}`;
        return [...accum, argObj];
    }, [])
        .join(', ');
    return `{ ${argsString} }`;
};
/**
 * Transforms function args DSL to the string.
 */
export const transformArgsToString = (args) => {
    if (isEmpty(args)) {
        return '';
    }
    const maxArgPosition = getMaxArgPosition(args);
    const allArgs = args.reduce((accum, arg) => {
        const argStr = arg.name;
        return assocPath(arg.path, argStr, accum);
    }, {});
    // Splits all args together and includes placeholders
    const resultStrArray = [];
    for (let pos = 0, missedArgCounter = 0; pos <= maxArgPosition; pos++) {
        if (!isUndefined(allArgs[pos])) {
            resultStrArray.push(transformArgObjectToString(allArgs[pos]));
        }
        else {
            missedArgCounter += 1;
            resultStrArray.push('_'.repeat(missedArgCounter));
        }
    }
    return resultStrArray.join(', ');
};
