import { NodeDSL, nodeListSelectors } from '@builder/schemas';
import { isString } from '@builder/utils';

import { useDashboardSelectedID, useNodeListDSL } from 'src/providers/ReduxProvider';

export const useSelectedNode = (): NodeDSL | null => {
  const selectedID = useDashboardSelectedID();
  const nodeListDSL = useNodeListDSL();

  if (!isString(selectedID)) return null;

  return nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: selectedID });
};
