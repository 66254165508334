import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getPropertySections, getStyleSections, getDivider, getEventsSection, } from '../../common/settings';
export const BuilderComponentsRouterLinkSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            label: 'Text',
                            name: 'text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['absolute'],
                            label: 'Absolute',
                            name: 'absolute',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['to'],
                            label: 'To',
                            name: 'to',
                            showIf: [
                                {
                                    keyPath: ['absolute'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: false,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['href'],
                            label: 'Href',
                            name: 'href',
                            showIf: [
                                {
                                    keyPath: ['absolute'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['target'],
                            label: 'Target',
                            name: 'target',
                            options: ['_blank', '_self'].map(createSelectOptionByValue),
                            showIf: [
                                {
                                    keyPath: ['absolute'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            options: [
                                'h1',
                                'h2',
                                'h3',
                                'h4',
                                'h5',
                                'h6',
                                'subtitle1',
                                'subtitle2',
                                'body1',
                                'body2',
                                'caption',
                                'button',
                                'overline',
                                'srOnly',
                                'inherit',
                            ].map(createSelectOptionByValue),
                            label: 'Variant',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['underline'],
                            label: 'Underline',
                            options: ['none', 'hover', 'always'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Font Color',
                            options: ['default', 'inherit', 'primary', 'secondary'].map(createSelectOptionByValue),
                        },
                    ],
                },
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
export const getCommonBuilderComponentsRouterLinkSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'text'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'absolute'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'to'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'href'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'target'],
], {
    withStructure: false,
}))(values(BuilderComponentsRouterLinkSettings).flat());
