import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { FIELD_TYPE_OPTIONS } from '../constants';
import { getDivider } from './commonSettings';
/**  @deprecated */
export const getStateSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Local States',
    name: 'localStates',
    titleDescription: {
        title: 'Local States',
        subtitle: 'Determine if this component and its children have access to locally defined states.',
    },
    expandedByDefault: false,
    children: [
        {
            type: COMPONENT_SETTING_TYPES.state,
            label: 'Connected States',
            name: 'state',
        },
    ],
});
export const getConditionSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Conditional Rendering',
    name: 'condition',
    expandedByDefault: false,
    titleDescription: {
        title: 'Conditional Rendering',
        subtitle: 'Add logic controlling if the component should be rendered.',
    },
    children: [
        {
            type: COMPONENT_SETTING_TYPES.condition,
            label: 'Show If',
            name: 'showIf',
        },
    ],
});
export const getFieldValidationSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    name: 'addFieldSection',
    title: 'Add Field Validation',
    children: [
        {
            type: COMPONENT_SETTING_TYPES.list,
            keyPath: ['fieldValidation'],
            name: 'fieldValidation',
            label: 'New Field',
            itemLabelKeyPath: ['Key'],
            newObjectPredefineds: {
                Key: 'fieldname',
            },
            settings: [
                {
                    componentView: COMPONENT_SETTING_VIEWS.text,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['Key'],
                    label: 'Name',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['Type'],
                    label: 'Type',
                    options: FIELD_TYPE_OPTIONS.map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.validationListValue,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: ['Value'],
                    label: 'Initial Value',
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.subList,
                    keyPath: ['Validation'],
                    name: 'validation',
                    label: 'Test',
                    itemLabelKeyPath: ['Key'],
                    newObjectPredefineds: {
                        Key: 'test',
                    },
                    settings: [
                        // used just for save the keyvalue of the test item.
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['Key'],
                            label: 'Key',
                            showIf: [
                                {
                                    keyPath: ['Key'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'noop',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['Arguments'],
                            label: 'Arguments',
                            showIf: [
                                {
                                    keyPath: ['Key'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isIncluded,
                                    value: ['Length', 'Min', 'Max', 'LessThan', 'MoreThan'],
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['ErrorMessage'],
                            label: 'Error Message',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['Limit'],
                            label: 'Limit',
                            showIf: [
                                {
                                    keyPath: ['Key'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isIncluded,
                                    value: ['Length', 'Min', 'Max'],
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['Min'],
                            label: 'Min',
                            showIf: [
                                {
                                    keyPath: ['Key'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'MoreThan',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['Max'],
                            label: 'Max',
                            showIf: [
                                {
                                    keyPath: ['Key'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'LessThan',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['Regex'],
                            label: 'Regex',
                            showIf: [
                                {
                                    keyPath: ['Key'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'Matches',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
});
export const getLooperSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Loop',
    name: 'loopSection',
    expandedByDefault: false,
    children: [
        {
            type: COMPONENT_SETTING_TYPES.iterator,
            dataLabel: 'Loop Data',
            itemNameLabel: 'Item Name',
            name: 'loop',
        },
    ],
});
export const getPropertySections = () => [
    getConditionSection(),
    getDivider(),
];
export const getFormPropertySections = () => [
    getFieldValidationSection(),
    getDivider(),
    getConditionSection(),
    getDivider(),
];
