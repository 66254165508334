var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { forwardRef, useCallback, useRef, useState, useEffect, } from 'react';
import { Button } from '@mui/material';
import debounce from 'lodash.debounce';
import { isNil } from 'ramda';
const ButtonUploadFile = ({ ref, textButton, debounce: debounceTime, onError, maxFileSize, onChange, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, InputProps, accept, rest, }) => {
    const [localValue, setLocalValue] = useState();
    const createDebounceCallback = useCallback((debounceTimeArg) => {
        return debounce((event, nextValue) => {
            var _a;
            const theElement = event;
            theElement.target.files = nextValue;
            onChange === null || onChange === void 0 ? void 0 : onChange(event);
            (_a = InputProps === null || InputProps === void 0 ? void 0 : InputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(InputProps, event);
        }, debounceTimeArg);
    }, [onChange, InputProps]);
    const debouncedChangePropValue = useRef(createDebounceCallback(debounceTime));
    useEffect(() => {
        debouncedChangePropValue.current = createDebounceCallback(debounceTime);
    }, [createDebounceCallback, debounceTime]);
    const isFileExceedingFileSize = useCallback((files) => {
        if (!maxFileSize)
            return false;
        const isExceedingSize = [...files].some(file => file.size > maxFileSize * 1024 * 1024);
        // eslint-disable-next-line no-alert
        isExceedingSize && alert('File exceeded max file size');
        return isExceedingSize;
    }, [maxFileSize]);
    const setDebouncedValue = useCallback(event => {
        var _a, _b;
        if (!isFileExceedingFileSize(event.target.files)) {
            setLocalValue(event.target.files);
            if (isNil(debounceTime)) {
                onChange === null || onChange === void 0 ? void 0 : onChange(event);
                (_a = InputProps === null || InputProps === void 0 ? void 0 : InputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(InputProps, event);
            }
            else {
                debouncedChangePropValue.current(event, event.target.files);
            }
        }
        else if (localValue && inputRef && inputRef.current) {
            inputRef.current.files = localValue;
        }
        else {
            ((_b = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _b === void 0 ? void 0 : _b.value) && (inputRef.current.value = '');
        }
    }, [isFileExceedingFileSize, localValue, debounceTime, onChange, InputProps]);
    const ListFiles = () => {
        if (localValue && localValue.length) {
            const items = [...localValue];
            return (_jsx("ul", { children: items.map(file => (_jsxs("li", { children: [_jsx("span", { children: file.name }), _jsx("button", Object.assign({ type: "button", onClick: e => {
                                const filteredItems = fileListItems(items.filter(item => item.name !== file.name));
                                setLocalValue(filteredItems);
                                debouncedChangePropValue.current(e, filteredItems);
                            } }, { children: "Remove" }))] }, file.name))) }));
        }
        return _jsx(_Fragment, {});
    };
    const inputRef = useRef(null);
    function fileListItems(files) {
        const b = new ClipboardEvent('').clipboardData || new DataTransfer();
        for (let i = 0, len = files.length; i < len; i++)
            b.items.add(files[i]);
        return b.files;
    }
    useEffect(() => {
        if (localValue && inputRef && inputRef.current && !isFileExceedingFileSize(localValue)) {
            inputRef.current.files = localValue;
        }
    }, [isFileExceedingFileSize, localValue]);
    // click the hidden input element
    const handleButtonClick = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: handleButtonClick, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath }, rest, { children: _jsxs("div", Object.assign({ style: { width: '100%' } }, { children: [_jsx("input", Object.assign({ ref: inputRef, type: "file", accept: accept, "max-file-size": maxFileSize, multiple: true, onChange: (event) => {
                                setDebouncedValue(event);
                            }, style: { display: 'none' } }, rest)), textButton] })) })), _jsx(ListFiles, {})] }));
};
export const FileUploadButton = forwardRef((_a, ref) => {
    var { debounce: debounceTime, onChange, InputProps, accept, onError, maxFileSize, textButton } = _a, rest = __rest(_a, ["debounce", "onChange", "InputProps", "accept", "onError", "maxFileSize", "textButton"]);
    return (_jsx(ButtonUploadFile, { ref: ref, debounce: debounceTime, onChange: onChange, onError: onError, maxFileSize: maxFileSize, textButton: textButton, accept: accept, InputProps: InputProps, rest: rest }));
});
