import { useMemo } from 'react';

import { ROOT_LAYOUT_CONTAINER_NODE_ID } from '@builder/schemas';
import { isArray } from '@builder/utils';

import { useLayoutNodesIds, useSelectedNodeID } from 'src/providers';

export const useIsSelectedNodeBelongToLayout = (): boolean => {
  const selectedID = useSelectedNodeID();
  const layoutNodesIds = useLayoutNodesIds();

  return useMemo(() => {
    if (isArray(selectedID)) {
      let isSelectedNodeBelongToLayout = false;
      selectedID.forEach(id => {
        const hasLayoutID = [...layoutNodesIds, ROOT_LAYOUT_CONTAINER_NODE_ID].some(
          item => item === id,
        );
        if (hasLayoutID) {
          isSelectedNodeBelongToLayout = true;
        }
      });
      return isSelectedNodeBelongToLayout;
    }

    return [...layoutNodesIds, ROOT_LAYOUT_CONTAINER_NODE_ID].some(item => item === selectedID);
  }, [layoutNodesIds, selectedID]);
};
