import { isObject, isString } from '@builder/utils';
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES } from '../../constants';
export class NodeSchemaChecker {
    static isTextNode(node) {
        if (!(node === null || node === void 0 ? void 0 : node.name))
            return false;
        return node.name === COMPONENT_DSL_NAMES.Text;
    }
    static isNode(node) {
        if (!node || !(node === null || node === void 0 ? void 0 : node.name) || !(node === null || node === void 0 ? void 0 : node.props)) {
            return false;
        }
        return true;
    }
    static isTypographyNode(node) {
        if (!(node === null || node === void 0 ? void 0 : node.name))
            return false;
        return node.name === COMPONENT_DSL_NAMES.MaterialTypography;
    }
    static isRichTextNode(node) {
        if (!(node === null || node === void 0 ? void 0 : node.name))
            return false;
        return node.name === COMPONENT_DSL_NAMES.BuilderRichText;
    }
    static isHeadingNode(node) {
        if (!(node === null || node === void 0 ? void 0 : node.name))
            return false;
        return node.name === COMPONENT_DSL_NAMES.HeadingSymbol;
    }
    static isOverallTextRelated(node) {
        if (!(node === null || node === void 0 ? void 0 : node.name))
            return false;
        return (NodeSchemaChecker.isTextNode(node) ||
            NodeSchemaChecker.isTypographyNode(node) ||
            NodeSchemaChecker.isRichTextNode(node) ||
            NodeSchemaChecker.isHeadingNode(node));
    }
    static isRouteNode(nodeDSL) {
        var _a, _b, _c;
        if (!NodeSchemaChecker.isNode(nodeDSL)) {
            return false;
        }
        const routeContentNodeID = (_c = (_b = (_a = nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.props) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.nodes) === null || _c === void 0 ? void 0 : _c[0];
        if (!(nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.name) || !routeContentNodeID)
            return false;
        return (nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
            nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout);
    }
    static isStateValid(node) {
        if (!(node === null || node === void 0 ? void 0 : node.states))
            return true;
        const routeStatesDefIds = Object.values(node.context || {}).map(stateDSL => stateDSL.id);
        return node.states.every(state => isObject(state) && isString(state.stateID) && routeStatesDefIds.includes(state.stateID));
    }
    static hasRequiredAttributes(node) {
        return isObject(node) && 'id' in node && 'parentID' in node && 'props' in node;
    }
    static isReactImportNode(node, componentDSL) {
        if (!(node === null || node === void 0 ? void 0 : node.name) || !componentDSL)
            return false;
        return componentDSL.type === COMPONENT_DSL_TYPES.reactImportComponent;
    }
    static isHtmlElementNode(node, componentDSL) {
        if (!(node === null || node === void 0 ? void 0 : node.name) || !componentDSL)
            return false;
        return componentDSL.type === COMPONENT_DSL_TYPES.htmlElement;
    }
    static isReactImportComponent(componentDSL) {
        if (!componentDSL)
            return false;
        return componentDSL.type === COMPONENT_DSL_TYPES.reactImportComponent;
    }
    static isSymbolComponent(componentDSL) {
        if (!componentDSL)
            return false;
        return componentDSL.type === COMPONENT_DSL_TYPES.symbol;
    }
    static isSymbolWithTreeNodesComponent(componentDSL) {
        if (!componentDSL)
            return false;
        return (componentDSL.type === COMPONENT_DSL_TYPES.symbol &&
            Boolean(componentDSL.schema.symbolNodes.name));
    }
    static isHTMLElementComponent(componentDSL) {
        if (!componentDSL)
            return false;
        return componentDSL.type === COMPONENT_DSL_TYPES.htmlElement;
    }
    static isTextComponent(componentDSL) {
        if (!componentDSL)
            return false;
        return (componentDSL.type === COMPONENT_DSL_TYPES.textComponent &&
            componentDSL.name === COMPONENT_DSL_NAMES.Text);
    }
}
