import { applyEvents } from '@8base-private/event-handler';
import { equals, isEmpty, isNil, omit, reject, uniq, uniqWith } from 'ramda';

import {
  ApiTokenAuthDSL,
  AppDSL,
  appDSLSelectors,
  ASSET_TYPES,
  AssetFileDataDSL,
  assetListSelectors,
  assetSelectors,
  AUTH_TOKEN_ID_TEMPLATE,
  COMPONENT_SCHEMAS,
  FONT_TYPES,
  forEachNodeListProps,
  ForEachNodePropsArgs,
  NodeCommonDSL,
  NodeListDSL,
  nodeListSelectors,
  nodeSelectors,
  PresentationTypes,
  RESOURCE_TYPES,
  ResourceBackendEightBaseDSL,
  resourceListSelectors,
  RouteHooksDSL,
  STATE_SCOPES,
  STATE_TYPES,
  StateDSL,
  StateID,
  StateListDSL,
  StateScopeTypes,
  StateWithDefaultDSL,
  WEB_NATIVE_ID_TEMPLATE,
  WebNativeAuthDSL,
  DSLNode,
} from '@builder/schemas';
import { ERROR_SCOPES, generateID, SystemError } from '@builder/utils';

import {
  AppEvents,
  createAppStateType,
  DASHBOARD_EVENTS,
  DashboardContextUpdateEvents,
  DEFAULT_VALUE,
  updateIteratorData,
  updateNodeIfJsCode,
  updateStringArguments,
} from '../../../../../common';
import { dashboardInitial } from '../../../../initial-state';
import { getTransformedPalette } from '../../../../utils';

import { createFontFaceCSS } from './createFontFaceCSS';
import { deleteAsset } from './deleteAsset';
import { themeCSSGlobalSelectorsUpdate } from './themeCSSGlobalSelectorsUpdate';
import { themeCSSGlobalUpdate } from './themeCSSGlobalUpdate';
import { updateAsset } from './updateAsset';

type StateDSLDefaultValue = StateDSL & StateWithDefaultDSL;

const deleteAppState = (
  nodeID: string | undefined,
  temporalAppDSL: AppDSL,
  stateAppDSL: AppDSL,
  id: StateID,
  scope: StateScopeTypes,
): AppDSL => {
  if (nodeID) {
    let newAppDSL = temporalAppDSL;
    const nodeDSL = nodeListSelectors.getNodeDSL(stateAppDSL.nodes, {
      nodeID,
    });

    const newConnectedStates = nodeSelectors
      .getStateConnectionsList(nodeDSL)
      ?.filter(state => state.stateID !== id);

    if (
      scope === STATE_SCOPES.local &&
      (newAppDSL.nodes[nodeID].context as StateListDSL) &&
      (newAppDSL.nodes[nodeID].context as StateListDSL)[id]
    ) {
      newAppDSL = {
        ...newAppDSL,
        nodes: {
          ...newAppDSL.nodes,
          [nodeID]: {
            ...newAppDSL.nodes[nodeID],
            context: {
              ...(omit([id], newAppDSL.nodes[nodeID].context) as Record<string, StateDSL>),
            },
          },
        },
      };

      if (isEmpty(newAppDSL.nodes[nodeID].context)) {
        const { context, ...rest } = newAppDSL.nodes[nodeID];
        newAppDSL = {
          ...newAppDSL,
          nodes: {
            ...newAppDSL.nodes,
            [newAppDSL.nodes[nodeID].id]: { ...rest },
          },
        };
      }
    }

    return appDSLSelectors.assocAppPath(
      newAppDSL,
      ['nodes', nodeID, 'states'],
      uniq(newConnectedStates),
    );
  }

  return {
    ...temporalAppDSL,
  };
};

const createAppState = ({ event, appDSL, newStateId, context }: createAppStateType): AppDSL => {
  let modifiedAppDSL = appDSL;
  const { stateDSL, connectedNodeID, connectedNodeParentID: connectedNodeWrapperID } = event;

  const newContext = {
    stateID: newStateId,
    required: context?.required,
    componentBoundID: context?.componentBoundID,
  };
  // Create new State with updated type
  if (connectedNodeID && stateDSL.scope === STATE_SCOPES.local) {
    // bind state to page and wrapper
    if (connectedNodeWrapperID) {
      const bindStateToParentNode = (nodeID: string) => {
        const nodeDSL = nodeListSelectors.getNodeDSL(appDSL.nodes, {
          nodeID,
        });
        const connectedStates = nodeSelectors.getStateConnectionsList(nodeDSL);
        modifiedAppDSL = appDSLSelectors.assocAppPath(
          modifiedAppDSL,
          ['nodes', nodeID, 'states'],
          uniq([...connectedStates, newContext]),
        );

        modifiedAppDSL = appDSLSelectors.assocAppPath(
          modifiedAppDSL,
          ['nodes', connectedNodeWrapperID, 'context'],
          {
            ...(modifiedAppDSL.nodes[connectedNodeWrapperID]?.context || {}),
            [newStateId]: {
              ...stateDSL,
              parent: connectedNodeWrapperID,
              id: newStateId,
            },
          },
        );
      };

      bindStateToParentNode(connectedNodeWrapperID);
    }

    return {
      ...modifiedAppDSL,
    };
  }

  return {
    ...modifiedAppDSL,
    states: {
      ...modifiedAppDSL.states,
      [newStateId]: {
        ...stateDSL,
        id: newStateId,
      },
    },
  };
};

const cleanAppDSLRouteHooks = (AppDSLRef: AppDSL, state: StateDSL): AppDSL => {
  // GLobal Navigation Events
  if (state.scope === 'global') {
    const { afterEach, beforeEach } = AppDSLRef?.routeHooks as RouteHooksDSL;

    // type request
    if (afterEach.request === state.id) {
      afterEach.request = undefined;
      afterEach.type = undefined;
    }

    if (beforeEach.request === state.id) {
      beforeEach.request = undefined;
      beforeEach.type = undefined;
    }

    // type function
    if (afterEach.function === state.id) {
      afterEach.function = undefined;
      afterEach.type = undefined;
    }

    if (beforeEach.function === state.id) {
      beforeEach.function = undefined;
      beforeEach.type = undefined;
    }
  }

  // Local Navigation Events
  const nodeListArrayDSL = Object.values(AppDSLRef.nodes) as Array<NodeCommonDSL>;
  const newNodesArrayDSL = nodeListArrayDSL.filter(
    (node: NodeCommonDSL) => node?.name === 'BuilderComponentsRoute',
  );

  newNodesArrayDSL?.forEach((node: NodeCommonDSL) => {
    const routerHooks = node?.props?.routerHooks as Record<string, string>;

    if (routerHooks) {
      Object.keys(routerHooks).forEach(property => {
        if (routerHooks[property] === state.id) {
          // Type Request
          if (property.includes('beforeRouteEnter')) {
            routerHooks.beforeRouteEnter = '';
            routerHooks.beforeRouteEnterRequestID = '';
            routerHooks.beforeRouteEnterRequestName = '';
            routerHooks.beforeRouteEnterCode = '';
          }

          if (property.includes('beforeRouteExit')) {
            routerHooks.beforeRouteExit = '';
            routerHooks.beforeRouteExitRequestID = '';
            routerHooks.beforeRouteExitRequestName = '';
            routerHooks.beforeRouteExitCode = '';
          }

          if (property.includes('beforeRouteUpdate')) {
            routerHooks.beforeRouteUpdate = '';
            routerHooks.beforeRouteUpdateRequestID = '';
            routerHooks.beforeRouteUpdateRequestName = '';
            routerHooks.beforeRouteUpdateCode = '';
          }

          // Type Functions
          if (property.includes('beforeRouteEnterFunction')) {
            routerHooks.beforeRouteEnterFunctionID = '';
            routerHooks.beforeRouteEnterFunctionName = '';
          }

          if (property.includes('beforeRouteExitFunction')) {
            routerHooks.beforeRouteExitFunctionID = '';
            routerHooks.beforeRouteExitFunctionName = '';
          }

          if (property.includes('beforeRouteUpdateFunction')) {
            routerHooks.beforeRouteUpdateFunctionID = '';
            routerHooks.beforeRouteUpdateFunctionName = '';
          }
        }
      });
    }
  });

  return AppDSLRef;
};

export const appDSLReducer = (
  appDSL: AppDSL = dashboardInitial.appConfiguration.appDSL,
  event: AppEvents,
): AppDSL => {
  switch (event.type) {
    // node
    case DASHBOARD_EVENTS.componentConditionRuleUpdate: {
      let newAppDSL = { ...appDSL };
      if (!event.value) {
        event.id.forEach(id => {
          newAppDSL = appDSLSelectors.dissocAppPath(newAppDSL, ['nodes', id, 'condition']);
        });

        return newAppDSL;
      }

      event.id.forEach(id => {
        newAppDSL = appDSLSelectors.assocAppPath(
          newAppDSL,
          ['nodes', id, 'condition', 'showIf'],
          event.value,
        );
      });

      return newAppDSL;
    }

    case DASHBOARD_EVENTS.componentAllowedRolesUpdate: {
      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', event.id, 'condition', 'allowedRoles'],
        event.value,
      );
    }

    case DASHBOARD_EVENTS.componentIteratorDataUpdate: {
      let newAppDSL = { ...appDSL };
      event.id.forEach(id => {
        newAppDSL = appDSLSelectors.assocAppPath(newAppDSL, ['nodes', id, 'iterator'], event.value);
      });

      return newAppDSL;
    }

    case DASHBOARD_EVENTS.componentSchemaOverrideUpdate: {
      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', event.id, 'schemaOverride', ...event.schemaOverrideData.keyPath],
        event.schemaOverrideData.keyValue,
      );
    }

    case DASHBOARD_EVENTS.componentLocalStatesUpdate: {
      const nodeDSL = nodeListSelectors.getNodeDSL(appDSL.nodes, { nodeID: event.id });
      const globalStates = nodeSelectors.getGlobalStateConnectionsList(nodeDSL, {
        appStateListDSL: appDSL.states,
      });

      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', event.id, 'states'],
        uniq([...globalStates, ...event.value]),
      );
    }

    case DASHBOARD_EVENTS.componentStatesRemove: {
      const statesWithoutRemoved = (appDSL.nodes[event.id].states || []).filter(
        currentState => event.value.indexOf(currentState.stateID) === -1,
      );

      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', event.id, 'states'],
        uniq(statesWithoutRemoved),
      );
    }

    case DASHBOARD_EVENTS.componentPresentationRemove: {
      const currentPresentations =
        (appDSLSelectors.appPath(appDSL, [
          'nodes',
          event.nodeID,
          'presentations',
        ]) as PresentationTypes[]) ?? [];

      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', event.nodeID, 'presentations'],
        currentPresentations.filter(presentation => presentation !== event.presentation),
      );
    }

    case DASHBOARD_EVENTS.componentPresentationAdd: {
      const currentPresentations =
        (appDSLSelectors.appPath(appDSL, [
          'nodes',
          event.nodeID,
          'presentations',
        ]) as PresentationTypes[]) ?? [];

      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', event.nodeID, 'presentations'],
        [...currentPresentations, event.presentation],
      );
    }

    case DASHBOARD_EVENTS.componentUpdateLayoutAlias: {
      const { nodeID, alias } = event;
      const appNewDSL = structuredClone(appDSL);
      const nodeRoot = nodeListSelectors.getNodeDSL(appNewDSL.nodes, {
        nodeID,
      });

      const childs = nodeListSelectors.getChildIdNodesLayoutByRootId(appNewDSL.nodes, {
        nodeID: nodeRoot.id,
      });

      const newNodes = nodeListSelectors.changeLayoutNameChildByComponent(childs, {
        name: 'BuilderComponentsRouteLayout',
        alias,
      });

      return appDSLSelectors.assocAppPath(
        { ...appNewDSL, nodes: { ...appNewDSL.nodes, ...newNodes } },
        ['nodes', nodeID, 'alias'],
        alias,
      );
    }

    case DASHBOARD_EVENTS.componentUpdateAlias: {
      return appDSLSelectors.assocAppPath(appDSL, ['nodes', event.nodeID, 'alias'], event.alias);
    }

    case DASHBOARD_EVENTS.componentMoveDirection: {
      const { nodeID, parentID, direction } = event;
      const container: DSLNode = appDSL.nodes[parentID];
      const currentNodes = container.props.children?.nodes
        ? [...container.props.children.nodes]
        : [];
      const nodeIndex = currentNodes.indexOf(nodeID);

      if (
        nodeIndex === -1 ||
        nodeIndex + direction < 0 ||
        nodeIndex + direction >= currentNodes.length
      ) {
        return appDSL;
      }

      // Swap positions based on direction: 1 MoveUp, -1 MoveDown
      [currentNodes[nodeIndex], currentNodes[nodeIndex + direction]] = [
        currentNodes[nodeIndex + direction],
        currentNodes[nodeIndex],
      ];

      return appDSLSelectors.assocAppPath(
        appDSL,
        ['nodes', parentID, 'props', 'children', 'nodes'],
        currentNodes,
      );
    }

    // states
    case DASHBOARD_EVENTS.stateCreate: {
      const id = event.stateDSL.id || generateID();
      const { stateDSL, connectedNodeID, nodeForConnectionName, connectedNodeParentID } = event;

      const newAppDSL = createAppState({
        event: {
          stateDSL,
          connectedNodeID,
          nodeForConnectionName,
          connectedNodeParentID,
        },
        appDSL,
        newStateId: id,
      });

      return {
        ...newAppDSL,
      };
    }

    case DASHBOARD_EVENTS.stateConvert: {
      const newID = event.newStateArgs.stateDSL.id || generateID();

      const {
        newStateArgs: {
          stateDSL,
          newConnectedNodeID,
          nodeForConnectionName,
          newConnectedNodeParentID,
        },
        oldStateArgs: { id, oldConnectedNodeID, oldConnectedNodeParentID, state },
      } = event;

      let newAppDSL = structuredClone(appDSL);

      newAppDSL = deleteAppState(oldConnectedNodeID, newAppDSL, appDSL, id, state.scope);

      newAppDSL = deleteAppState(oldConnectedNodeParentID, newAppDSL, appDSL, id, state.scope);

      const AppDSLUpdated = cleanAppDSLRouteHooks(newAppDSL, state);

      newAppDSL = {
        ...AppDSLUpdated,
        states: omit([id], AppDSLUpdated.states),
      };

      newAppDSL = createAppState({
        event: {
          stateDSL,
          connectedNodeID: newConnectedNodeID,
          nodeForConnectionName,
          connectedNodeParentID: newConnectedNodeParentID,
        },
        appDSL: newAppDSL,
        newStateId: newID,
      });

      return {
        ...newAppDSL,
      };
    }

    case DASHBOARD_EVENTS.stateQueryCreate: {
      let newAppDSL = appDSL;
      const asocAppPath = (nodeID: string | undefined, temporalAppDSL: AppDSL): AppDSL => {
        if (nodeID && event.stateDSL.scope === STATE_SCOPES.local) {
          const nodeDSL = nodeListSelectors.getNodeDSL(appDSL.nodes, {
            nodeID,
          });
          const connectedStates = nodeSelectors.getStateConnectionsList(nodeDSL);

          return appDSLSelectors.assocAppPath(
            temporalAppDSL,
            ['nodes', nodeID, 'states'],
            uniq([...connectedStates, { stateID: event.stateDSL.id }]),
          );
        }

        return temporalAppDSL;
      };

      newAppDSL = asocAppPath(event.connectedNodeParentID, newAppDSL);

      if (event.stateDSL.scope === STATE_SCOPES.local && event.connectedNodeParentID) {
        newAppDSL = appDSLSelectors.assocAppPath(
          newAppDSL,
          ['nodes', event.connectedNodeParentID, 'context'],
          {
            ...(newAppDSL.nodes[event.connectedNodeParentID]?.context || {}),
            [event.stateDSL.id]: {
              ...event.stateDSL,
              parent: event.connectedNodeParentID,
              id: event.stateDSL.id,
            },
          },
        );

        return newAppDSL;
      }

      return {
        ...newAppDSL,
        states: {
          ...newAppDSL.states,
          ...(event.stateDSL.scope === STATE_SCOPES.local
            ? {}
            : {
                [event.stateDSL.id]: {
                  ...event.stateDSL,
                  type: STATE_TYPES.query,
                },
              }),
        },
      };
    }

    case DASHBOARD_EVENTS.stateUpdate: {
      let newAppDSL = appDSL;
      if (event.connectedNodeParentID && event.stateDSL.scope === STATE_SCOPES.local) {
        const nodeDSL = nodeListSelectors.getNodeDSL(appDSL.nodes, {
          nodeID: event.connectedNodeParentID,
        });
        const connectedStates = nodeSelectors.getStateConnectionsList(nodeDSL);

        newAppDSL = appDSLSelectors.assocAppPath(
          newAppDSL,
          ['nodes', event.connectedNodeParentID, 'states'],
          uniqWith(equals, [
            ...connectedStates.map(x => reject(isNil, x)),
            { stateID: event.stateDSL.id },
          ]),
        );

        newAppDSL = appDSLSelectors.assocAppPath(
          newAppDSL,
          ['nodes', event.connectedNodeParentID, 'context'],
          {
            ...(newAppDSL.nodes[event.connectedNodeParentID]?.context || {}),
            [event.stateDSL.id]: {
              ...event.stateDSL,
              parent: event.connectedNodeParentID,
              id: event.stateDSL.id,
            },
          },
        );

        return newAppDSL;
      }

      return {
        ...newAppDSL,
        states: {
          ...newAppDSL.states,
          [event.stateDSL.id]: {
            ...({
              ...(newAppDSL.states[event.stateDSL.id] as StateDSL),
              ...(event.stateDSL as StateDSL),
            } as StateDSL),
          },
        },
      };
    }

    case DASHBOARD_EVENTS.stateUpdateType: {
      let newAppDSL = appDSL;
      const newStateId = generateID();
      const {
        stateDSL,
        connectedNodeID,
        connectedNodeParentID,
        nodeForConnectionName,
        id: oldId,
      } = event;

      let currentState = { ...appDSL.states[event.id] };
      if (
        event.stateDSL.scope === STATE_SCOPES.local &&
        appDSL?.nodes &&
        connectedNodeID &&
        oldId
      ) {
        const connectedNode = appDSL.nodes[connectedNodeID];
        if (connectedNode && connectedNode.context) {
          currentState = { ...connectedNode.context[oldId] };
        }
      }

      if (
        event.previousStateName !== event.stateDSL.name &&
        event.stateDSL.type === currentState.type
      ) {
        if (
          DEFAULT_VALUE in currentState &&
          DEFAULT_VALUE in event.stateDSL &&
          (currentState as StateDSLDefaultValue).defaultValue !== event.stateDSL.defaultValue
        ) {
          (currentState as StateDSLDefaultValue).defaultValue = event.stateDSL.defaultValue;
        }

        if (currentState.scope === STATE_SCOPES.local) {
          return {
            ...appDSL,
            nodes: {
              ...appDSL.nodes,
              [connectedNodeID as string]: {
                ...appDSL.nodes[connectedNodeID as string],
                context: {
                  ...appDSL.nodes[connectedNodeID as string].context,
                  [event.id]: {
                    ...stateDSL,
                  },
                },
              },
            },
          };
        }

        return {
          ...appDSL,
          states: {
            ...appDSL.states,
            [event.id]: {
              ...stateDSL,
            },
          },
        };
      }

      const context = appDSL.nodes[connectedNodeID ?? '']?.states?.filter(
        state => state.stateID === stateDSL.id,
      )[0];

      // Delete state
      // Delete state to prevent useCallback error on app engine and code engine
      newAppDSL = deleteAppState(connectedNodeID, appDSL, appDSL, oldId, stateDSL.scope);
      newAppDSL = deleteAppState(connectedNodeParentID, newAppDSL, appDSL, oldId, stateDSL.scope);
      // instance new state
      newAppDSL = {
        ...newAppDSL,
        states: omit([oldId], appDSL.states),
      };
      // create new State
      /// temp fix, need to refactor this https://8base-dev.atlassian.net/browse/PRODUCT-1356

      newAppDSL = createAppState({
        event: {
          stateDSL,
          connectedNodeID: connectedNodeParentID,
          nodeForConnectionName,
          connectedNodeParentID: connectedNodeID,
        },
        appDSL: newAppDSL,
        newStateId,
        context,
      });
      return {
        ...newAppDSL,
      };
    }

    case DASHBOARD_EVENTS.stateUpdateTypeDialog: {
      const { stateDSL } = event as Extract<
        DashboardContextUpdateEvents,
        { type: typeof DASHBOARD_EVENTS.stateUpdateTypeDialog }
      >;
      const { nodes: nodeListDSL } = appDSL;
      const componentListDSL = COMPONENT_SCHEMAS;
      const currentState = { ...appDSL.states[event.id] };
      const updatedNodeListDSL: NodeListDSL = { ...nodeListDSL };

      const updateStateName = (nodePropsArgs: ForEachNodePropsArgs) => {
        const nodeDSL = updatedNodeListDSL[nodePropsArgs.nodeDSL.id];

        updateNodeIfJsCode(nodePropsArgs, nodeDSL, updatedNodeListDSL, event);

        updateStringArguments(nodePropsArgs, nodeDSL, updatedNodeListDSL, event);

        updateIteratorData(nodeDSL, event, updatedNodeListDSL);
      };

      if (
        event.previousStateName !== event.stateDSL.name &&
        event.stateDSL.type === currentState.type
      ) {
        forEachNodeListProps(updateStateName, componentListDSL, nodeListDSL);
        return {
          ...appDSL,
          nodes: updatedNodeListDSL,
          states: {
            ...appDSL.states,
            [event.id]: {
              ...stateDSL,
            },
          },
        };
      }

      return { ...appDSL };
    }

    case DASHBOARD_EVENTS.stateRemove: {
      let newAppDSL = structuredClone(appDSL);

      newAppDSL = deleteAppState(
        event.connectedNodeID,
        newAppDSL,
        appDSL,
        event.id,
        event.state.scope,
      );

      newAppDSL = deleteAppState(
        event.connectedNodeParentID,
        newAppDSL,
        appDSL,
        event.id,
        event.state.scope,
      );

      const AppDSLUpdated = cleanAppDSLRouteHooks(newAppDSL, event.state);

      return {
        ...AppDSLUpdated,
        states: omit([event.id], AppDSLUpdated.states),
      };
    }

    // assets
    case DASHBOARD_EVENTS.createAsset: {
      let id = generateID();
      if (event.assetDSL.type !== ASSET_TYPES.folder) {
        id = (event.assetDSL as AssetFileDataDSL).backendFileID;
      }

      const assetListDSL = appDSL.assets || {};
      const parentID = assetSelectors.getAssetParentID(event.assetDSL);
      const parent = assetListSelectors.getAssetFolderDSL(assetListDSL, { assetID: parentID });

      return {
        ...appDSL,
        assets: {
          ...assetListDSL,
          [parentID]: {
            ...parent,
            children: [...parent.children, id],
          },
          [id]: {
            ...event.assetDSL,
            id,
          },
        },
      };
    }

    case DASHBOARD_EVENTS.updateAsset: {
      return updateAsset({ appDSL, assetDSL: event.assetDSL });
    }

    case DASHBOARD_EVENTS.deleteAsset: {
      return deleteAsset({ appDSL, assetIDs: event.assetIDs });
    }
    // libraries
    case DASHBOARD_EVENTS.createLibrary: {
      const { name } = event.library;
      const libraries = appDSL.libraries || {};

      return {
        ...appDSL,
        libraries: {
          ...libraries,
          [name]: {
            ...event.library,
          },
        },
      };
    }
    case DASHBOARD_EVENTS.updateLibrary: {
      const libraries = appDSL.libraries || {};

      return {
        ...appDSL,
        libraries: {
          ...libraries,
          [event.library.name]: event.library,
        },
      };
    }

    case DASHBOARD_EVENTS.removeLibrary: {
      const libraries = appDSL.libraries || {};

      return {
        ...appDSL,
        libraries: {
          ...omit([event.name], libraries),
        },
      };
    }

    // fonts

    case DASHBOARD_EVENTS.createCustomFont: {
      const id = generateID();
      const assetListDSL = appDSL.assets || {};

      const assetID = assetListSelectors.getAssetIDByName(assetListDSL, {
        assetName: event.asset.name,
      }) as string;

      const newFontDSL = {
        id,
        assetID,
        type: FONT_TYPES.custom,
        ...event.fontDSL,
      };

      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          fonts: {
            ...appDSL.theme?.fonts,
            custom: {
              ...appDSL.theme?.fonts?.custom,
              [id]: newFontDSL,
            },
          },
          css: {
            ...appDSL.theme?.css,
            fontFaces: {
              ...appDSL.theme?.css?.fontFaces,
              [id]: {
                fontID: id,
                css: createFontFaceCSS(newFontDSL),
              },
            },
          },
        },
      };
    }

    case DASHBOARD_EVENTS.deleteCustomFont: {
      return {
        ...deleteAsset({ appDSL, assetIDs: [event.fontDSL.assetID] }),
        theme: {
          ...appDSL.theme,
          fonts: {
            ...appDSL.theme?.fonts,
            custom: omit([event.fontDSL.id], appDSL.theme?.fonts?.custom),
          },
          css: {
            ...appDSL.theme?.css,
            fontFaces: omit([event.fontDSL.id], appDSL.theme?.css?.fontFaces),
          },
        },
      };
    }

    case DASHBOARD_EVENTS.googleFontCreate: {
      const id = generateID();

      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          fonts: {
            ...appDSL.theme?.fonts,
            google: {
              ...appDSL.theme?.fonts?.google,
              [id]: {
                id,
                type: FONT_TYPES.google,
                ...event.googleFontDSL,
              },
            },
          },
        },
      };
    }

    case DASHBOARD_EVENTS.googleFontUpdate: {
      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          fonts: {
            ...appDSL.theme?.fonts,
            google: {
              ...appDSL.theme?.fonts?.google,
              [event.googleFontDSL.id]: event.googleFontDSL,
            },
          },
        },
      };
    }

    case DASHBOARD_EVENTS.googleFontDelete: {
      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          fonts: {
            ...appDSL.theme?.fonts,
            google: omit([event.id], appDSL.theme?.fonts?.google),
          },
        },
      };
    }

    // resources
    case DASHBOARD_EVENTS.createResource: {
      const id = event.resourceDSL.id ?? generateID();
      const uniqName = resourceListSelectors.getUniqResourceNameByTitle(appDSL.resources, {
        title: event.resourceDSL?.title,
      });

      return {
        ...appDSL,
        resources: {
          ...appDSL.resources,
          [id]: {
            ...event.resourceDSL,
            id,
            name: uniqName,
          },
        },
      };
    }

    case DASHBOARD_EVENTS.updateResource: {
      const { id } = event.resourceDSL;
      let newResourceData = event.resourceDSL;
      const currentResourceDSL = appDSL.resources[id] as ResourceBackendEightBaseDSL;
      const uniqName = resourceListSelectors.getUniqResourceNameByTitle(appDSL.resources, {
        title: event.resourceDSL?.title,
      });

      const isResourceWithAuth =
        currentResourceDSL.type === RESOURCE_TYPES.backendEightBase && currentResourceDSL?.auth;

      if (isResourceWithAuth) {
        newResourceData = {
          ...event.resourceDSL,
          auth: { ...currentResourceDSL.auth },
        } as ResourceBackendEightBaseDSL;
      }

      return {
        ...appDSL,
        resources: {
          ...appDSL.resources,
          [id]: {
            ...newResourceData,
            name: uniqName,
          },
        },
      };
    }

    case DASHBOARD_EVENTS.deleteResource: {
      const authResourceDSL = appDSLSelectors.getAuthResourceDSL(appDSL);
      const isAuthResource = event.id === authResourceDSL?.id;

      if (isAuthResource) {
        throw new SystemError(ERROR_SCOPES.schemas, `Can't delete auth resource`);
      }

      return {
        ...appDSL,
        resources: omit([event.id], appDSL.resources),
      };
    }

    // hooks
    case DASHBOARD_EVENTS.updateHooks: {
      const updateHooksData = event.routeHooksDSL;

      return {
        ...appDSL,
        routeHooks: {
          ...updateHooksData,
        },
      };
    }

    // settings
    case DASHBOARD_EVENTS.settingsUpdate: {
      return {
        ...appDSL,
        settings: event.settings,
      };
    }

    case DASHBOARD_EVENTS.authenticationUpdate: {
      const authResourceDSL = appDSLSelectors.getAuthResourceDSL(appDSL);

      if (!authResourceDSL) {
        throw new SystemError(
          ERROR_SCOPES.schemas,
          `Can't update auth IDs because auth resource wasn't found`,
        );
      }

      const webNativeID: { webNativeID?: WebNativeAuthDSL } = event?.authProfileID
        ? { webNativeID: { ...WEB_NATIVE_ID_TEMPLATE, authProfileID: event.authProfileID } }
        : {};
      const authTokenID: { authTokenID?: ApiTokenAuthDSL } = event?.apiAuthToken
        ? { authTokenID: { ...AUTH_TOKEN_ID_TEMPLATE, apiAuthToken: event.apiAuthToken } }
        : {};

      return {
        ...appDSL,
        resources: {
          ...appDSL.resources,
          [authResourceDSL.id]: {
            ...authResourceDSL,
            auth: {
              ...authResourceDSL.auth,
              currentAuthID: event.currentAuthID ?? authResourceDSL.auth?.currentAuthID,
              authList: { ...webNativeID, ...authTokenID },
            },
          },
        },
      };
    }

    case DASHBOARD_EVENTS.authResourceIDUpdate: {
      return {
        ...appDSL,
        settings: {
          ...appDSL.settings,
          authResourceID: event.resourceID,
        },
      };
    }

    case DASHBOARD_EVENTS.userAppResize: {
      return {
        ...appDSL,
        settings: {
          ...appDSL.settings,
          size: event.size,
        },
      };
    }

    // theme
    case DASHBOARD_EVENTS.themeFrameworkSettingsUpdate: {
      const palette = event.value?.palette || {};
      const transformedPalette = getTransformedPalette(palette);

      return {
        ...appDSL,
        theme: { ...appDSL.theme, frameworkSettings: { palette: transformedPalette } },
      };
    }

    // theme css
    case DASHBOARD_EVENTS.cssMediaQueriesSet: {
      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          css: {
            ...appDSL.theme?.css,
            mediaQueries: event.mediaQueriesCSS,
          },
        },
      };
    }

    case DASHBOARD_EVENTS.themeCSSGlobalUpdate: {
      return themeCSSGlobalUpdate({ appDSL, cssBody: event.cssBody });
    }

    case DASHBOARD_EVENTS.themeCSSCustomUpdate: {
      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          css: {
            ...appDSL.theme?.css,
            custom: event.cssBody,
          },
        },
      };
    }

    case DASHBOARD_EVENTS.themeCSSGlobalSelectorsUpdate: {
      return themeCSSGlobalSelectorsUpdate({
        appDSL,
        selectors: event.selectors,
        cssBody: event.cssBody,
      });
    }

    // theme components
    case DASHBOARD_EVENTS.cssComponentSet: {
      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          css: {
            ...appDSL.theme?.css,
            components: {
              ...appDSL.theme?.css?.components,
              [event.name]: event.css,
            },
          },
        },
      };
    }

    case DASHBOARD_EVENTS.cssTypographySet: {
      return {
        ...appDSL,
        theme: {
          ...appDSL.theme,
          css: {
            ...appDSL.theme?.css,
            typography: event.cssBody,
          },
        },
      };
    }

    case DASHBOARD_EVENTS.applyEvents: {
      const { actionEvent } = event.payload;
      const uniquePayloads = uniq([...actionEvent.payload]);

      const newDSL = applyEvents(
        { appDSL: { ...appDSL } },
        {},
        { ...actionEvent, payload: uniquePayloads },
      ).result.appDSL as AppDSL;

      return newDSL;
    }

    default:
      return appDSL;
  }
};
