/* eslint-disable no-console */
/**
 * Extract fragment names && fragment values from location hashPath.
 * @example
 * (#access_token=123&expires_in=2) => { access_token: '123', expires_in: '2' }
 */
export const getCurrentRouteSearchParams = (hashPath) => {
    if (hashPath) {
        const hash = hashPath.substring(1);
        const fragments = hash.split('&').reduce((res, item) => {
            const [n, v] = item.split('=');
            return Object.assign(Object.assign({}, res), { [n]: v });
        }, {});
        return fragments;
    }
    return {};
};
