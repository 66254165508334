import { NodeID } from '@builder/schemas';

import { useDashboardState } from 'src/providers/ReduxProvider';

export const useCollapsedNodes = (): Record<NodeID, boolean> => {
  const {
    navigator: { collapsedNodes },
  } = useDashboardState();

  return collapsedNodes;
};
