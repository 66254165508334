var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEmpty } from 'ramda';
import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_17_8 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.8';
    }
    getChangeList() {
        return ['Removing empty context for local states, and adding empty states for new pages'];
    }
    migrateAppDSL(appDSL) {
        const newDSL = Object.assign({}, appDSL);
        const nodes = Object.assign({}, appDSL.nodes);
        const states = Object.assign({}, appDSL.states);
        const newNodes = Object.entries(nodes).map(([nodeKey, nodeValue]) => {
            if (nodeValue.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
                nodeValue.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout) {
                const checkStates = 'states' in nodeValue;
                if (!checkStates) {
                    return [nodeKey, Object.assign(Object.assign({}, nodeValue), { states: [] })];
                }
            }
            return [nodeKey, nodeValue];
        });
        const newNodesStates = Object.entries(Object.fromEntries(newNodes)).map(([nodeKey, nodeValue]) => {
            const value = nodeValue;
            if ('context' in value) {
                if (isEmpty(value.context)) {
                    const { context } = value, rest = __rest(value, ["context"]);
                    return [nodeKey, Object.assign({}, rest)];
                }
            }
            return [nodeKey, nodeValue];
        });
        const newStates = Object.entries(states).map(([stateKey, stateValue]) => {
            return [stateKey, stateValue];
        });
        return Object.assign(Object.assign({}, newDSL), { nodes: Object.fromEntries(newNodesStates), states: Object.fromEntries(newStates) });
    }
}
export const migration_0_17_8 = new Migration_0_17_8();
