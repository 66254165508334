import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_CALLBACK_TYPES } from '../../constants';
export const REST_ARGS_DSL = {
    method: { type: COMPONENT_DSL_PROP_TYPES.string },
    url: { type: COMPONENT_DSL_PROP_TYPES.string },
    isTriggeredManually: {
        type: COMPONENT_DSL_PROP_TYPES.boolean,
    },
    params: { type: COMPONENT_DSL_PROP_TYPES.object },
    headers: { type: COMPONENT_DSL_PROP_TYPES.object },
    dataType: { type: COMPONENT_DSL_PROP_TYPES.string },
    transformer: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'data',
                path: [0],
            },
        ],
    },
    data: { type: COMPONENT_DSL_PROP_TYPES.any },
    onCompleted: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'data',
                path: [0],
            },
        ],
    },
    onCompletedFunction: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'data',
                path: [0],
            },
        ],
    },
    onError: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'error',
                path: [0],
            },
        ],
    },
    onErrorFunction: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'error',
                path: [0],
            },
        ],
    },
};
