import { NodeSchemaAssert } from './NodeSchemaAssert';
import { NodeSchemaChecker } from './NodeSchemaChecker';
import { PropSchemaAssert } from './PropSchemaAssert';
import { PropSchemaChecker } from './PropSchemaChecker';
import { PropValueAssert } from './PropValueAssert';
import { PropValueChecker } from './PropValueChecker';
import { ResourceValueAssert } from './ResourceValueAssert';
import { ResourceValueChecker } from './ResourceValueChecker';
import { SettingSchemaAssert } from './SettingSchemaAssert';
import { SettingSchemaChecker } from './SettingSchemaChecker';
import { StateValueAssert } from './StateValueAssert';
import { StateValueChecker } from './StateValueChecker';
export const PropChecker = {
    Value: PropValueChecker,
    Schema: PropSchemaChecker,
};
export const PropAssert = {
    Value: PropValueAssert,
    Schema: PropSchemaAssert,
};
export const NodeChecker = {
    Value: NodeSchemaChecker,
};
export const NodeAssert = {
    Value: NodeSchemaAssert,
};
export const SettingAssert = {
    Schema: SettingSchemaAssert,
};
export const SettingChecker = {
    Schema: SettingSchemaChecker,
};
export const ResourceAssert = {
    Schema: ResourceValueAssert,
};
export const ResourceChecker = {
    Schema: ResourceValueChecker,
};
export const StateAssert = {
    Schema: StateValueAssert,
};
export const StateChecker = {
    Schema: StateValueChecker,
};
