import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialPaperSettings } from './MaterialPaper.settings';
export const MaterialPaper = {
    name: COMPONENT_DSL_NAMES.MaterialPaper,
    displayName: 'Paper',
    icon: 'paper',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    source: {
        importName: '@mui/material',
        componentName: 'Paper',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                variant: 'elevation',
                elevation: 1,
                square: false,
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { elevation: { type: COMPONENT_DSL_PROP_TYPES.number, maxValue: 24, minValue: 1 }, square: { type: COMPONENT_DSL_PROP_TYPES.boolean }, style: { type: COMPONENT_DSL_PROP_TYPES.object }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialPaperSettings,
};
