import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_STYLE_TOOLTIP, getBasicPropsSettings } from '../../common';
import { getDivider, getStyleSections, getPropertySections, getEventsSection, } from '../../common/settings';
export const MaterialPaperSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [...getBasicPropsSettings([], true), getDivider(), ...getPropertySections()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            options: ['elevation', 'outlined', 'string'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['square'],
                            label: 'Square',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['elevation'],
                            label: 'Elevation',
                            showIf: [
                                {
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    keyPath: ['variant'],
                                    value: 'elevation',
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
