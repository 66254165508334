/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from 'ramda';
import { RESOURCE_TYPES } from '@builder/schemas';
import { API_STANDS, ERROR_SCOPES, SchemaError } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_11_5 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.5';
    }
    getChangeList() {
        return ['Move auth backend to the  resources DSL section'];
    }
    migrateResourceDSL(resourceDSL, appDSL) {
        if (resourceDSL.type !== RESOURCE_TYPES.backendEightBase) {
            return resourceDSL;
        }
        const currentEndpoint = resourceDSL.endpoint;
        if (!currentEndpoint) {
            throw new SchemaError(ERROR_SCOPES.migrations, `Endpoint not found to migrate resource ${JSON.stringify(resourceDSL)}`);
        }
        const [url, environment] = currentEndpoint.split('_');
        const workspaceID = url.split('/').pop();
        const apiUrl = url.replace(`/${workspaceID}`, '');
        const api = Object.keys(API_STANDS).reduce((apiResult, currentApi) => {
            if (API_STANDS[currentApi] === apiUrl) {
                return currentApi;
            }
            return apiResult;
        }, null);
        if (!api) {
            throw new SchemaError(ERROR_SCOPES.migrations, `Can't transform endpoint ${currentEndpoint} to API. Please, contact us via slack.`);
        }
        return Object.assign(Object.assign({}, omit(['endpoint'], resourceDSL)), { workspaceID,
            api,
            environment });
    }
}
export const migration_0_11_5 = new Migration_0_11_5();
