import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
export const RouterSwitch = {
    name: COMPONENT_DSL_NAMES.RouterSwitch,
    displayName: 'View component',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: 'react-router-dom',
        componentName: 'Switch',
    },
    memoization: {
        disable: true,
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                style: {
                    display: 'flex',
                    flex: '1 1 0%',
                    flexDirection: 'column',
                    height: '100%',
                },
                children: {
                    allowedNodes: [
                        COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                        COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout,
                    ],
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                            props: {
                                path: '/',
                                authAccess: 'any',
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: {
            children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                    COMPONENT_DSL_NAMES.Redirect,
                    COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout,
                ],
            },
        },
    },
    settings: null,
};
