import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FieldInputProps, FormikErrors } from 'formik';
import { editor as MonacoEditorType } from 'monaco-editor';

import { log } from '@builder/utils';

import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { EditorFormDialog, Field, MonacoEditor } from 'src/shared/components';

export type CSSEditorDialogArgs = {
  initialValues?: string;
  label: string;
  onSave: (value: string) => void;
  validateBeforeSave?: (value: string | undefined) => string | undefined;
  dataTest?: string;
  options?: MonacoEditorType.IStandaloneEditorConstructionOptions;
};

const DIALOG_ID = DASHBOARD_DIALOGS.CSS_EDITOR_DIALOG_ID;

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const getEditorInitialValue = (value: string) => {
  return {
    code: value,
  };
};

export const CSSEditorDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<CSSEditorDialogArgs>(DIALOG_ID);

  const onDialogClose = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const submitForm = useCallback(
    (
      values: { code: string },
      helpers: { setErrors: (errors: FormikErrors<{ code: string }>) => void },
    ) => {
      // prevent useless changes
      if (values.code === args?.initialValues || (!values.code && !args?.initialValues)) {
        closeDialog(DIALOG_ID);
        return;
      }

      if (args.validateBeforeSave) {
        const error = args.validateBeforeSave(values.code);

        if (error) {
          return helpers.setErrors({ code: error });
        }
      }

      if (args.onSave && typeof args.onSave === 'function') {
        if (values.code) {
          args.onSave(values.code);
        } else {
          args.onSave('');
        }
      } else {
        log.warn('@builder/client/dialog/CSSEditorDialog does not have onSave args');
      }

      closeDialog(DIALOG_ID);
    },
    [closeDialog, args],
  );

  const onFieldChange = useCallback(
    (field: FieldInputProps<unknown>) => (value?: string) => {
      field.onChange(value);
    },
    [],
  );

  const commonOnMount = useCallback((editor: MonacoEditorType.IStandaloneCodeEditor) => {
    const revertChangesOutsideOfBoundaries = (
      event: MonacoEditorType.IModelContentChangedEvent,
    ) => {
      if (event.isUndoing) {
        return;
      }

      const linesCount = Number(editor.getModel()?.getLineCount());

      for (const ch of event.changes) {
        const isFirstLineEdited = ch.range.startLineNumber === 1;

        const isLastLineEdited =
          ch.range.startLineNumber === linesCount ||
          (ch.range.startLineNumber === linesCount - 1 && ch.text === '\n');

        if (isFirstLineEdited || isLastLineEdited) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          editor.getModel()?.undo();
        }
      }
    };

    editor.onDidChangeModelContent(revertChangesOutsideOfBoundaries);
  }, []);

  return (
    <EditorFormDialog
      isDialogOpened={isOpen}
      onDialogClose={onDialogClose}
      title={args.label}
      formProps={{
        onSubmit: submitForm,
        initialValues: getEditorInitialValue(args.initialValues || ''),
      }}
    >
      {({ errors }) => {
        return (
          <>
            {errors.code && <ErrorText>{errors.code}</ErrorText>}
            <Field name="code" useValueOnChange>
              {({ field }) => (
                <MonacoEditor
                  height="100%"
                  language="css"
                  defaultValue={field.value as string}
                  defaultPath="css-editor-dialog/index.ts"
                  onChange={onFieldChange(field)}
                  onMount={commonOnMount}
                  withBorders={false}
                  options={args.options}
                  data-test={`${args.dataTest}.dialog`}
                />
              )}
            </Field>
          </>
        );
      }}
    </EditorFormDialog>
  );
};
