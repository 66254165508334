import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsMapSettings } from './BuilderComponentsMap.settings';
export const BuilderComponentsMap = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsMap,
    displayName: 'Map',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Map',
    },
    icon: 'map',
    schema: {
        predefineds: {
            props: {
                bootstrapURLKeys: {
                    key: '',
                    language: 'en',
                    region: 'US',
                    libraries: [],
                },
                defaultCenter: { lat: 38.960111370291884, lng: -77.13443562449201 },
                zoom: 10,
                markers: [],
                layerTypes: [],
                mapMargin: [],
                markerCss: `\ntransform:translate(-50%, -109%) rotate(45deg);\nbackground-color: blue;\nwidth: 30px;\nheight: 30px;\nborder-color: white;\nborder-style: solid;\nborder-width: 2px;\nborder-top-left-radius: 30px;\nborder-top-right-radius: 30px;\nborder-bottom-left-radius: 30px;  \n&::after {\ncontent:'';\nwidth: 20px;\nheight: 20px;\nmargin: 3px 0 0 3px;\nbackground: #fff;\nposition: absolute;\nborder-radius: 50%;\n}`,
                style: {
                    height: '300px',
                    width: '100%',
                },
            },
        },
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { markerCss: {
                type: COMPONENT_DSL_PROP_TYPES.string,
            }, bootstrapURLKeys: { type: COMPONENT_DSL_PROP_TYPES.object }, defaultCenter: { type: COMPONENT_DSL_PROP_TYPES.object }, center: { type: COMPONENT_DSL_PROP_TYPES.object }, zoom: { type: COMPONENT_DSL_PROP_TYPES.number }, hoverDistance: { type: COMPONENT_DSL_PROP_TYPES.number }, mapMargin: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: { type: COMPONENT_DSL_PROP_TYPES.number },
            }, layerTypes: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.string,
                },
            }, markers: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                },
            }, options: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                args: [
                    {
                        path: [0],
                        name: 'maps',
                    },
                ],
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
            }, resetBoundsOnResize: { type: COMPONENT_DSL_PROP_TYPES.boolean }, distanceToMouse: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                args: [
                    {
                        name: 'pt',
                        path: [0],
                    },
                    {
                        name: 'mousePos',
                        path: [1],
                    },
                    {
                        name: 'markerProps',
                        path: [2],
                    },
                ],
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
            }, googleMapLoader: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                args: [
                    {
                        name: 'bootstrapURLKeys',
                        path: [0],
                    },
                ],
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
            }, onChildClick: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    { name: 'hoverKey', path: [0] },
                    { name: 'childProps', path: [1] },
                ],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'value', path: [0] }],
            }, onChildMouseLeave: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    { name: 'hoverKey', path: [0] },
                    { name: 'childProps', path: [1] },
                ],
            }, onZoomAnimationStart: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onZoomAnimationEnd: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onDrag: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'map',
                        path: [0],
                    },
                ],
            }, onDragEnd: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'map',
                        path: [0],
                    },
                ],
            }, onChildMouseEnter: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    { name: 'hoverKey', path: [0] },
                    { name: 'childProps', path: [1] },
                ],
            }, onMapTypeIdChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onTilesLoaded: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            } }),
    },
    settings: BuilderComponentsMapSettings,
};
