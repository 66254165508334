import React, { useCallback } from 'react';

import { AppRuntimeStateList, AppRuntimeStates } from '@builder/app-engine';
import { StringPropValue, JSInjectionPropValue, NumberPropValue } from '@builder/schemas';

import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { Autocomplete, AutocompleteOption } from 'src/shared/components';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

export type SelectViewEditorProps<
  T = StringPropValue | NumberPropValue | JSInjectionPropValue | undefined
> = {
  label: string;
  propValue: T;
  options: Array<AutocompleteOption<string | number>>;
  onChangePropValue: (propValue: T) => void;
  required?: boolean;
  nodeID?: string;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  noOptionsText?: string;
  localStates?: AppRuntimeStateList | AppRuntimeStates;
  actionEventDesign?: boolean;
  disabled?: boolean;
};

export const SelectViewEditor: React.FC<SelectViewEditorProps> = ({
  label,
  propValue,
  options,
  onChangePropValue,
  required,
  nodeID,
  'data-test': dataTest,
  showFx = true,
  fxDefaultEnabled = ACTIVEPROP.literal,
  noOptionsText = 'No options',
  localStates,
  actionEventDesign,
  disabled,
}) => {
  const updatePropByEvent = useCallback(
    (newValue: unknown) => {
      onChangePropValue(newValue as string);
    },
    [onChangePropValue],
  );

  return (
    <JsCodeInjectionInput
      label={label}
      propValue={propValue}
      onChangePropValue={onChangePropValue}
      nodeID={nodeID}
      data-test={dataTest}
      showFx={showFx}
      fxDefaultEnabled={fxDefaultEnabled}
    >
      {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
        <Autocomplete
          noOptionsText={noOptionsText}
          fullWidth
          required={required}
          value={nonJsCodePropValue ?? ''}
          label={label}
          onChange={updatePropByEvent}
          options={options}
          isFxEnabled={isFxEnabled}
          enableFx={enableFx}
          data-test={dataTest}
          showFx={showFx}
          actionEventDesign={actionEventDesign}
          disabled={disabled}
        />
      )}
    </JsCodeInjectionInput>
  );
};
