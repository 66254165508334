import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialMenuItemSettings } from './MaterialMenuItem.settings';
export const MaterialMenuItem = {
    name: COMPONENT_DSL_NAMES.MaterialMenuItem,
    displayName: 'Menu Item',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'MenuItem',
    },
    memoization: {
        disable: true,
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { value: { type: COMPONENT_DSL_PROP_TYPES.string }, dense: { type: COMPONENT_DSL_PROP_TYPES.boolean }, behavior: { type: COMPONENT_DSL_PROP_TYPES.string }, disableGutters: { type: COMPONENT_DSL_PROP_TYPES.boolean }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.Text, COMPONENT_DSL_NAMES.BuilderComponentsCheckbox],
            } }),
    },
    settings: MaterialMenuItemSettings,
};
