import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { commonComponentProps } from '../../common/commonProps';
import { SPAN_SETTINGS } from './span.settings';
export const span = {
    name: COMPONENT_DSL_NAMES.span,
    displayName: 'Section (Inline)',
    icon: 'sectionInline',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'span',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36, display: 'inline' }),
            },
        },
        props: Object.assign({}, commonComponentProps),
    },
    settings: SPAN_SETTINGS,
};
