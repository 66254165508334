import * as changeCase from 'change-case';
import { hasPropJsCode, PropAssert, transformPropWithJsCode, PropChecker, TRANSFORM_OUTPUT_MODE, } from '@builder/schemas';
import { trimNewLines, hyphenateStyleName, UNITLESS_NUMBER, isObject, isString, } from '@builder/utils';
import { OUTPUT_PROP_MODES } from '../../../../constants';
import { transformVariableName } from '../../../../utils/transformVariableName';
export class CSSPropTransformer {
    constructor() {
        /**
         * Add missed "px" unit to style prop if it's necessary
         * @param styleName - style property name in camelCase
         * @param styleValue - value of style property
         * @returns style property value as a string
         * @example
         * ('paddingLeft', 20) -> '20px'
         * ('zIndex', 42) -> '42'
         */
        this.generateStyleValue = (styleName, styleValue) => {
            if (!PropChecker.Value.isNumberProp(styleValue) &&
                !PropChecker.Value.isStringProp(styleValue)) {
                return '';
            }
            if (typeof styleValue === 'number' && styleValue !== 0 && !UNITLESS_NUMBER[styleName]) {
                return `${styleValue}px`;
            }
            return String(styleValue).trim();
        };
        /**
         * Transform style object into css string:
         * * @example
         * ```
         * ({
         *   height: "30px", backgroundColor: "{{ theme.palette.secondary.main }}"
         * }) => "height: 30px; background-color: ${theme.palette.secondary.main};"
         * ```
         */
        this.transformStyleObjectToCssString = (stylePropValue) => {
            return Object.entries(stylePropValue).reduce((resultStyles, [currentPropName, currentPropValue]) => {
                // 1. Transform a property name from camelCase notation into kebab-case
                const hyphenatedStyleName = hyphenateStyleName(currentPropName);
                // 2. Handle JS code
                const styleValue = hasPropJsCode(currentPropValue)
                    ? `\${${transformPropWithJsCode(currentPropValue)}}`
                    : this.generateStyleValue(currentPropName, currentPropValue);
                if ((styleValue === null || styleValue === void 0 ? void 0 : styleValue.trim()) === '') {
                    return resultStyles;
                }
                // 3. Create css row:
                // examples:
                // "height: 30px;"
                // "background-color: ${theme.palette.secondary.main};"
                const styleRow = `${hyphenatedStyleName}: ${styleValue};\n`;
                // 4. Merge all lines in a single css string
                return `${resultStyles}${styleRow}`;
            }, '');
        };
    }
    /**
     * @returns True if at least one of style object property has js code injections
     */
    hasJSCodeInTheStyleObject(options) {
        const stylePropValue = this.getStylePropValue(options);
        // Returns true only if code existed in the style property and not in the style itself
        if (hasPropJsCode(stylePropValue)) {
            return false;
        }
        for (const styleName in stylePropValue) {
            if (hasPropJsCode(stylePropValue[styleName])) {
                return true;
            }
        }
        return false;
    }
    /**
     * @returns Appropriate style prop value or null
     */
    getStylePropValue({ propName, propListValues, propDSL, }) {
        const cssPropName = propName;
        const cssPropDSL = propDSL;
        PropAssert.Schema.assertIsCSSProp(cssPropDSL, cssPropName);
        if (!cssPropDSL.targetStylePropName) {
            return null;
        }
        const stylePropName = cssPropDSL.targetStylePropName;
        const stylePropValue = propListValues[stylePropName];
        if (!stylePropValue) {
            return null;
        }
        PropAssert.Value.assertIsStyleProp(stylePropValue, stylePropName, { allowJSInjection: true });
        return stylePropValue;
    }
    mergeCSSWithStyles({ propName, propListDSL, propListValues, propValue, propDSL, }) {
        const cssPropName = propName;
        const cssPropValue = propValue !== null && propValue !== void 0 ? propValue : '';
        const cssPropDSL = propDSL;
        const isOnlyJSCodeInCSS = this.isOnlyJSCodeInCSS({
            propValue,
            propName,
        });
        // Needs to avoid this transformation '{{ symbolProps.css }}' => `${symbolProps.css}`
        const outputMode = isOnlyJSCodeInCSS
            ? TRANSFORM_OUTPUT_MODE.quotes
            : TRANSFORM_OUTPUT_MODE.backticks;
        const cssTransformedValue = hasPropJsCode(cssPropValue)
            ? transformPropWithJsCode(cssPropValue, { outputMode })
            : cssPropValue;
        PropAssert.Schema.assertIsCSSProp(cssPropDSL, cssPropName);
        PropAssert.Value.assertIsCSSProp(cssTransformedValue, cssPropName);
        if (!cssPropDSL.targetStylePropName) {
            return cssTransformedValue;
        }
        const stylePropValue = this.getStylePropValue({
            propName,
            propListValues,
            propDSL,
        });
        // if style prop not found or style prop is js code, e.g. {{ symbolProps.style }}
        if (!stylePropValue || hasPropJsCode(stylePropValue)) {
            return cssTransformedValue;
        }
        if (stylePropValue && cssTransformedValue) {
            return cssTransformedValue;
        }
        return stylePropValue;
    }
    /**
     *
     * @example () => 'nodeAlias2CSS'
     */
    getConstantName({ entityName, propPath, }) {
        return `${transformVariableName(changeCase.camelCase(`${entityName}${propPath.map(path => changeCase.pascalCase(path)).join('')}`, { transform: changeCase.camelCaseTransformMerge }))}CSS`;
    }
    isCSSEmpty(options) {
        const allStyles = JSON.stringify(this.mergeCSSWithStyles(options));
        if (trimNewLines(allStyles) === '') {
            return true;
        }
        return false;
    }
    /**
     * @returns True if it needed to extract css to constant
     * - When merged css is empty
     * - when in the style object existed js code
     */
    isNeededToExtractToConstant(options) {
        return (!this.isCSSEmpty(options) &&
            !hasPropJsCode(options.propValue) &&
            !this.hasJSCodeInTheStyleObject(options));
    }
    /**
     *
     * @example ({ propValue: '{{ symbolProps.css }}' }) => true
     * @example ({ propValue: 'background-color: {{ theme.palette.primary.main }}' }) => false
     * @example ({ propValue: undefined }) => false
     */
    isOnlyJSCodeInCSS({ propValue, propName, }) {
        PropAssert.Value.assertIsCSSProp(propValue, propName, {
            allowJSInjection: true,
            allowEmpty: true,
        });
        const trimmedCSS = propValue === null || propValue === void 0 ? void 0 : propValue.trim();
        return (trimmedCSS === null || trimmedCSS === void 0 ? void 0 : trimmedCSS.startsWith('{{')) && (trimmedCSS === null || trimmedCSS === void 0 ? void 0 : trimmedCSS.endsWith('}}'));
    }
    /**
     * @returns Target css string which was merged with style object
     */
    generateCSS(options) {
        const allStyles = this.mergeCSSWithStyles(options);
        return allStyles;
    }
    /**
     * @example () => 'const nodeAlias2CSS = css`display: flex;`'
     */
    generateCSSConstant(options) {
        var _a, _b;
        if (hasPropJsCode(options.propValue)) {
            return null;
        }
        const cssPropDSL = options.propDSL;
        PropAssert.Schema.assertIsCSSProp(cssPropDSL, options.propName);
        const stylePropName = (_a = cssPropDSL === null || cssPropDSL === void 0 ? void 0 : cssPropDSL.targetStylePropName) !== null && _a !== void 0 ? _a : '';
        const stylePropValue = (_b = options.propListValues[stylePropName]) !== null && _b !== void 0 ? _b : null;
        const isNeededToExtractToConstant = this.isNeededToExtractToConstant(options);
        if (!isNeededToExtractToConstant) {
            return null;
        }
        const cssConstantName = this.getConstantName(options);
        const cssConstantBody = this.generateCSS(options);
        const addImportant = typeof cssConstantBody === 'string'
            ? cssConstantBody.replaceAll(';', ' !important;')
            : cssConstantBody;
        if (options.propValue && stylePropValue) {
            const cssConstantStyleBody = this.generateCSS(Object.assign(Object.assign({}, options), { propValue: undefined }));
            return `const ${cssConstantName} = css\`${`${addImportant}`}\`;
      const ${`${cssConstantName}style`} = ${JSON.stringify(cssConstantStyleBody)};
      `;
        }
        if ((isObject(cssConstantBody) && !Object.keys(cssConstantBody !== null && cssConstantBody !== void 0 ? cssConstantBody : {}).length) ||
            (typeof cssConstantBody === 'string' && cssConstantBody === '')) {
            return null;
        }
        if ((!stylePropValue || !Object.values(stylePropValue).length) &&
            typeof cssConstantBody === 'string') {
            return `const ${cssConstantName} = css\`${`${addImportant}`}\`;`;
        }
        return `const ${cssConstantName} = ${JSON.stringify(cssConstantBody)};`;
    }
    hasStringValue(prop) {
        if (isString(prop)) {
            return !!prop.trim().length;
        }
        return false;
    }
    tranformerValue(prop) {
        if (isObject(prop)) {
            return `{ ${Object.keys(prop).reduce((resultCssJSON, keyCssJSON, index) => {
                const cssValue = prop[keyCssJSON];
                return `${resultCssJSON}${index >= 1 ? ', ' : ''}"${keyCssJSON}": ${cssValue && hasPropJsCode(cssValue)
                    ? transformPropWithJsCode(cssValue)
                    : `"${cssValue}"`}`;
            }, '')} }`;
        }
        return null;
    }
    camelStyles(style) {
        const camelize = (s) => s.replace(/-./g, x => x[1].toUpperCase());
        const camelStyle = JSON.stringify(Object.fromEntries(Object.entries(style !== null && style !== void 0 ? style : {}).map(([key, value]) => [camelize(key), value])));
        return camelStyle;
    }
    /**
     * Transforms css prop to string
     */
    transform(options, outputMode) {
        const isCSSEmpty = this.isCSSEmpty(options);
        if (isCSSEmpty) {
            return null;
        }
        const css = this.mergeCSSWithStyles(options);
        if (!Object.keys(css !== null && css !== void 0 ? css : {}).length) {
            return null;
        }
        const style = this.getStylePropValue(options);
        const hasJSCodeInTheStyleObject = this.hasJSCodeInTheStyleObject(options);
        const hasJSCodeInCSS = hasPropJsCode(options.propValue);
        const isOnlyJSCodeInCSS = this.isOnlyJSCodeInCSS({
            propValue: options.propValue,
            propName: options.propName,
        });
        const transformedValue = this.tranformerValue(style);
        const hasStringValue = this.hasStringValue(css);
        // If property is custom CSS, uses an id selector to give precedence to these styles
        const priorityCss = isString(css) ? css.replaceAll(';', ' !important;') : css;
        const hasPropCodeJS = hasJSCodeInTheStyleObject || hasJSCodeInCSS;
        if (hasStringValue && !hasPropCodeJS) {
            const camelStyle = this.camelStyles(style);
            if (style && isObject(style)) {
                return {
                    styleCSS: options.propPath[0] && options.propPath[0].includes('Props')
                        ? `${camelStyle}`
                        : `{ ${camelStyle} }`,
                    css: options.propPath[0] && options.propPath[0].includes('Props')
                        ? `css\`${priorityCss}\``
                        : `{ css\`${priorityCss}\` }`,
                };
            }
            return options.propPath[0] && options.propPath[0].includes('Props')
                ? `css\`${priorityCss}\``
                : `{ css\`${priorityCss}\` }`;
        }
        if (outputMode === OUTPUT_PROP_MODES.raw &&
            style.backgroundImage) {
            if (options.propPath[0] && options.propPath[0].includes('Props')) {
                if (hasStringValue) {
                    return {
                        styleCSS: JSON.stringify(transformedValue) !== '{}' ? `${transformedValue}` : '',
                        css: `css\`${priorityCss}\``,
                    };
                }
                return JSON.stringify(transformedValue) !== '{}' ? `${transformedValue}` : '';
            }
            if (hasStringValue) {
                return {
                    styleCSS: JSON.stringify(transformedValue) !== '{}' ? `{ ${transformedValue} }` : '',
                    css: `{ css\`${priorityCss}\` }`,
                };
            }
            return JSON.stringify(transformedValue) !== '{}' ? `{ ${transformedValue} }` : '';
        }
        if (isOnlyJSCodeInCSS) {
            return outputMode === OUTPUT_PROP_MODES.jsx ? `{ ${priorityCss} }` : `${priorityCss}`;
        }
        if (hasJSCodeInTheStyleObject || hasJSCodeInCSS) {
            if (hasStringValue) {
                return {
                    styleCSS: outputMode === OUTPUT_PROP_MODES.jsx || outputMode === OUTPUT_PROP_MODES.raw
                        ? `{ ${transformedValue} }`
                        : `${transformedValue}`,
                    css: outputMode === OUTPUT_PROP_MODES.jsx || outputMode === OUTPUT_PROP_MODES.raw
                        ? `{ css\`${priorityCss}\` }`
                        : `css\`${priorityCss}\``,
                };
            }
            return outputMode === OUTPUT_PROP_MODES.jsx
                ? `{ ${transformedValue} }`
                : `${transformedValue}`;
        }
        if (isObject(style) && Object.values(style !== null && style !== void 0 ? style : {}).length) {
            return outputMode === OUTPUT_PROP_MODES.jsx
                ? `{ ${JSON.stringify(style)} }`
                : `${JSON.stringify(style)}`;
        }
        return null;
    }
}
