import { Dispatch } from 'react';

import { DragSourceMonitor } from 'react-dnd';

import {
  DashboardAnimationEvents,
  DashboardContextUpdateEvents,
  DASHBOARD_ANIM_EVENTS,
} from 'src/store';

export const dragStart = (
  id: string | string[],
  send: Dispatch<DashboardContextUpdateEvents | DashboardAnimationEvents>,
  nodeName: string,
) => (_monitor: DragSourceMonitor): void => {
  send({ type: DASHBOARD_ANIM_EVENTS.moveStart, sourceID: id, nodeName });
};
