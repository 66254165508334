import { COLOR_MODELS } from '../../constants';
import { Color, LAB, RGBA } from '../../types';

const RGB_RANGES = {
  min: 0,
  max: 255,
};

const ALPHA_RANGES = {
  min: 0,
  max: 1,
};

const LAB_RANGES = {
  L: {
    min: 0,
    max: 100,
  },
  OTHER: {
    min: -128,
    max: 128,
  },
};

export class Validator {
  public static isValidNumber(num: number, min: number, max: number): boolean {
    return num >= min && num <= max && !isNaN(num);
  }

  public static isValidColor(color: Color): boolean {
    const { type, value } = color;

    let isValid = true;

    switch (type) {
      case COLOR_MODELS.hex:
        // checks that the string consists of either letters (A-Fa-f) or numbers from 0 to 9 and is 3 or 6 characters long, not including #
        isValid = /^#([A-Fa-f\d]{3}|[A-Fa-f\d]{6})$/.test(value as string);
        break;
      case COLOR_MODELS.rgba:
        if (!Validator.isValidNumber((value as RGBA).r, RGB_RANGES.min, RGB_RANGES.max))
          isValid = false;

        if (!Validator.isValidNumber((value as RGBA).g, RGB_RANGES.min, RGB_RANGES.max))
          isValid = false;

        if (!Validator.isValidNumber((value as RGBA).b, RGB_RANGES.min, RGB_RANGES.max))
          isValid = false;

        if (!Validator.isValidNumber((value as RGBA).a, ALPHA_RANGES.min, ALPHA_RANGES.max))
          isValid = false;

        break;
      case COLOR_MODELS.lab:
        if (!Validator.isValidNumber((value as LAB).l, LAB_RANGES.L.min, LAB_RANGES.L.max))
          isValid = false;

        if (!Validator.isValidNumber((value as LAB).a, LAB_RANGES.OTHER.min, LAB_RANGES.OTHER.max))
          isValid = false;

        if (!Validator.isValidNumber((value as LAB).b, LAB_RANGES.OTHER.min, LAB_RANGES.OTHER.max))
          isValid = false;

        break;
    }

    return isValid;
  }
}
