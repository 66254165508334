var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { RESET_STATUS } from '../common';
import { FileUpload } from '../FileUpload';
export const FormFileUpload = forwardRef((_a, ref) => {
    var { fieldProps, inputProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, className, lang, title, translate, accept, maxFileSize } = _a, rest = __rest(_a, ["fieldProps", "inputProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "className", "lang", "title", "translate", "accept", "maxFileSize"]);
    const { setFieldValue, status, setStatus } = useFormikContext();
    useEffect(() => {
        setFieldValue(fieldProps.name, []);
        return () => setFieldValue(fieldProps.name, undefined);
    }, [fieldProps.name, setFieldValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            setFieldValue(fieldProps.name, []);
            (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(inputProps, { target: { files: [] } });
        }
    }, [inputProps, status, fieldProps.name, setFieldValue, setStatus]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, form }) => {
            return (_jsx(FileUpload, Object.assign({ id: id, name: fieldProps.name, value: field.value, InputProps: {
                    onChange: (event, eventfileProps) => {
                        var _a;
                        const { files } = event.target;
                        if (inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) {
                            form.setFieldValue(fieldProps.name, files);
                            (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(inputProps, eventfileProps);
                        }
                        else {
                            form.setFieldValue(fieldProps.name, files);
                        }
                    },
                }, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, translate: translate, lang: lang, className: className, title: title }, inputProps, rest, { ref: ref, accept: accept, maxFileSize: maxFileSize })));
        } })));
});
