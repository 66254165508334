import React, { useRef, useState, useEffect, useMemo } from 'react';

import { Grid, Checkbox } from '@mui/material';
import { pick, path, isNil } from 'ramda';

import { hasPropJsCode, SIZE_POSSIBLE_UNITS } from '@builder/schemas';
import { useBoolState, useDebouncedState } from '@builder/utils';

import { getCurrentValue, valueEndsWithUnit } from '../../utils';
import { TextViewEditor } from '../TextViewEditor';
import { Popper } from 'src/shared/components';
import { EVENT_KEYS } from 'src/shared/constants';

import {
  SpacingTypes,
  SPACING_TYPES,
  MARGIN_BUTTONS,
  PADDING_BUTTONS,
  SPACING_VIEW_EDITOR_POPPER_MODIFIERS,
} from './SpacingViewEditorConstants';
import {
  SpacingViewEditorContent,
  MarginWrapper,
  PaddingWrapper,
  ElementPlaceholder,
  PopperContent,
  PopperTitle,
  PopperTextFieldContent,
  CheckboxContent,
  Label,
} from './SpacingViewEditorPresentation';

export type SpacingViewEditorProps = {
  propValue: React.CSSProperties | undefined;
  onChangeMany: (
    propDataArray: {
      keyValue: unknown;
      keyPath: (string | number)[];
    }[],
  ) => void;
  skipDebounce?: boolean;
  disablePaddings?: boolean;
  showFx?: boolean;
  'data-test'?: string;
};

// null => ''
// 'margin' => 'margin'
// 'marginTop' => 'margin Top'
/**
 *
 * @example
 * null => ''
 * @example
 * 'margin' => 'margin'
 * @example
 * 'marginTop' => 'margin Top'
 *
 * */
const prettifySpacingType = (spacingType: SpacingTypes | null): string => {
  if (!spacingType) {
    return '';
  }

  return spacingType.split(/(?=[A-Z])/).join(' ');
};

const valueOrPlaceholder = (value?: string | number) => {
  const parsedValue = Number(value);

  if (!isNaN(parsedValue)) {
    return `${value}px`;
  }

  return value ?? '-';
};

const getUpdatedRelatedStyles = ({
  styles,
  key,
  value,
}: {
  styles: React.CSSProperties;
  key: SpacingTypes;
  value?: string | number;
}) => {
  if (key.includes('margin')) {
    return {
      ...styles,
      marginTop: value,
      marginRight: value,
      marginBottom: value,
      marginLeft: value,
    };
  }

  return {
    ...styles,
    paddingTop: value,
    paddingRight: value,
    paddingBottom: value,
    paddingLeft: value,
  };
};

const getTransformedValue = (value: string) => {
  // checking for empty string because Number('') will become 0
  // which frustrates UX when you clear input and for some reason it has 0
  if (value === '') {
    return undefined;
  }

  // if it's a valid number then we can pass it as is
  // for example - 20 will become "20px" because of React default styles transformations
  const parsedValue = Number(value);
  if (!value.endsWith('.') && !isNaN(parsedValue)) {
    return parsedValue;
  }

  // to avoid saving result with zeros up front
  // "020em", "020px" e.t.c we transform them to the valid number
  const unit = valueEndsWithUnit(value, SIZE_POSSIBLE_UNITS);
  const currentValue = getCurrentValue(value, unit);
  const numberValue = Number(currentValue);
  if (!isNaN(numberValue)) {
    return `${numberValue}${unit ? unit.value : ''}`;
  }

  // passing all other values as is:
  // "initial", "auto", "some random invalid string"
  return value;
};

const changePropValue = ({
  value,
  isApplyToAll,
  propValue,
  selectedSpacingType,
  onChangeCallback,
}: {
  value?: number | string;
  isApplyToAll: boolean;
  propValue: React.CSSProperties;
  selectedSpacingType: SpacingTypes;
  onChangeCallback: (cssProp: React.CSSProperties) => void;
  showFx?: boolean;
}) => {
  if (isApplyToAll) {
    const updatedStyles = getUpdatedRelatedStyles({
      styles: propValue,
      key: selectedSpacingType as SpacingTypes,
      value,
    });
    return onChangeCallback(updatedStyles);
  }

  return onChangeCallback({
    ...propValue,
    [selectedSpacingType as SpacingTypes]: value,
  });
};

export const SpacingViewEditor: React.FC<SpacingViewEditorProps> = ({
  propValue,
  onChangeMany,
  skipDebounce,
  disablePaddings,
  'data-test': dataTestPrefix,
}) => {
  // to avoid the bug when propValue equals to undefined
  // and on every render we create new object
  // we should memoize initial propValue
  const initialPropValue = useMemo(() => propValue || {}, [propValue]);

  const handleOnChangeMany = (value: React.CSSProperties) => {
    const propDataArray = Object.entries(pick(Object.keys(SPACING_TYPES), value || {})).map(
      ([keyPathProp, keyValue]) => ({
        keyValue,
        keyPath: [keyPathProp],
      }),
    );

    onChangeMany(propDataArray);
  };

  const [debouncedValue, setDebouncedValue, setOnlyLocalValue] = useDebouncedState(
    initialPropValue,
    handleOnChangeMany,
    {
      skipDebounce,
    },
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [
    isApplyToAll,
    { setTrue: setIsApplyToAllTrue, setFalse: setIsApplyToAllFalse },
  ] = useBoolState(false);
  const [selectedSpacingType, setSelectedSpacingType] = useState<SpacingTypes | null>(null);

  useEffect(() => {
    // TextField.autoFocus prop does not work when selectedSpacingType was changed
    if (selectedSpacingType && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedSpacingType]);

  const isPopperOpen = Boolean(anchorEl) && Boolean(selectedSpacingType);
  const values = useMemo(() => pick(Object.keys(SPACING_TYPES), debouncedValue || {}), [
    debouncedValue,
  ]);
  const selectedTypeValue = useMemo(() => {
    if (!selectedSpacingType) {
      return undefined;
    }

    const targetValue = path<string | number>([selectedSpacingType], values);
    return !isNil(targetValue) ? String(targetValue) : undefined;
  }, [selectedSpacingType, values]);

  const updateProp = (value?: string, eventType?: string) => {
    const transformedValue = getTransformedValue(value ?? '');
    let appropiatedCallback = setDebouncedValue;
    if (hasPropJsCode(transformedValue)) {
      switch (eventType) {
        case 'blur': {
          appropiatedCallback = setDebouncedValue;
          break;
        }
        case 'change': {
          appropiatedCallback = setOnlyLocalValue;
          break;
        }
      }
    } else {
      appropiatedCallback = setDebouncedValue;
    }

    changePropValue({
      value: transformedValue,
      isApplyToAll,
      propValue: debouncedValue,
      selectedSpacingType: selectedSpacingType as SpacingTypes,
      onChangeCallback: appropiatedCallback,
    });
  };

  const openTooltip = (spacingType: SpacingTypes) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setIsApplyToAllFalse();

    setSelectedSpacingType(spacingType);
    setAnchorEl(wrapperRef.current);
  };

  const closePopper = () => {
    setSelectedSpacingType(null);
    setAnchorEl(null);
  };

  const changeApplyToAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      const updatedStyles = getUpdatedRelatedStyles({
        styles: debouncedValue,
        key: selectedSpacingType as SpacingTypes,
        value: selectedTypeValue,
      });
      setDebouncedValue(updatedStyles);
      return setIsApplyToAllTrue();
    }

    return setIsApplyToAllFalse();
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case EVENT_KEYS.ENTER:
      case EVENT_KEYS.ESCAPE: {
        closePopper();
        break;
      }
    }
  };

  return (
    <SpacingViewEditorContent ref={wrapperRef}>
      <MarginWrapper>
        <Label>Margin</Label>
        {MARGIN_BUTTONS.map(({ type, ButtonComponent, dataTest }) => (
          <ButtonComponent
            key={type}
            onClick={openTooltip(type)}
            data-test={`${dataTestPrefix}.${dataTest}`}
          >
            {valueOrPlaceholder(path([type], values))}
          </ButtonComponent>
        ))}
      </MarginWrapper>
      <PaddingWrapper>
        <If condition={!disablePaddings}>
          <Label>Padding</Label>
          {PADDING_BUTTONS.map(({ type, ButtonComponent, dataTest }) => (
            <ButtonComponent
              key={type}
              onClick={openTooltip(type)}
              data-test={`${dataTestPrefix}.${dataTest}`}
            >
              {valueOrPlaceholder(path([type], values))}
            </ButtonComponent>
          ))}
        </If>
      </PaddingWrapper>
      <ElementPlaceholder />
      <Popper
        open={isPopperOpen}
        onClose={closePopper}
        anchorEl={anchorEl}
        modifiers={SPACING_VIEW_EDITOR_POPPER_MODIFIERS}
      >
        <PopperContent>
          <PopperTitle variant="subtitle2">{prettifySpacingType(selectedSpacingType)}</PopperTitle>
          <PopperTextFieldContent container>
            <TextViewEditor
              skipDebounce={false}
              autoFocus
              multiline={false}
              onKeyDown={handleInputKeyDown}
              propValue={selectedTypeValue}
              onChangePropValue={updateProp}
              possibleUnits={SIZE_POSSIBLE_UNITS}
              data-test={`${dataTestPrefix}.spacingInput`}
              showFx={false}
            />
          </PopperTextFieldContent>
          <Grid container>
            <CheckboxContent
              label="Apply to all"
              labelPlacement="end"
              name="spacingEditor.applyToAll"
              control={
                <Checkbox
                  color="primary"
                  size="medium"
                  checked={isApplyToAll}
                  onChange={changeApplyToAll}
                  data-test={`${dataTestPrefix}.applyToAll`}
                />
              }
            />
          </Grid>
        </PopperContent>
      </Popper>
    </SpacingViewEditorContent>
  );
};
