import React from 'react';

import styled from '@emotion/styled';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import {
  ComponentSettingSelectOption,
  ComponentSettingSelectCustomMenuItemProps,
} from '@builder/schemas';

import { InputContainer, InputContainerProps } from '../common';

export type SelectProps = MuiSelectProps &
  InputContainerProps & {
    options?: ComponentSettingSelectOption[];
    renderCustomMenuItem?: React.FC<ComponentSettingSelectCustomMenuItemProps>;
  };

const StyledMuiSelect = styled(MuiSelect)`
  flex: 1;
  height: ${({ theme }) => theme.layout.controls.height}px;
  & .MuiSelect-selectMenu {
    display: flex;
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    border-radius: 4px;
  }
  & .MuiSelect-icon {
    top: calc(50% - 10px);
  }
`;

export const Select: React.FC<SelectProps> = ({
  label,
  error,
  options,
  variant,
  icon,
  isTextIcon,
  helperText,
  renderCustomMenuItem,
  'data-test': dataTest,

  ...props
}) => {
  return (
    <InputContainer
      label={label}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
    >
      <StyledMuiSelect
        variant="outlined"
        IconComponent={KeyboardArrowDownIcon}
        data-test={dataTest}
        {...props}
      >
        {options?.map(option => (
          <MenuItem
            key={Array.isArray(option.value) ? option.value[0] : option.value}
            disabled={option.disabled}
            value={option.value}
          >
            <Choose>
              <When condition={renderCustomMenuItem}>
                {renderCustomMenuItem?.({ label: option.label, value: option.value })}
              </When>
              <Otherwise>{option.label}</Otherwise>
            </Choose>
          </MenuItem>
        ))}
      </StyledMuiSelect>
    </InputContainer>
  );
};
