import convert from 'color-convert';

import { PERCENT } from '../../constants';
import { HSBA, RGBA } from '../../types';
import { roundToDigits } from '../helpers';

export class Rgba implements RGBA {
  public r: number;

  public g: number;

  public b: number;

  public a: number;

  constructor(rgba: RGBA | string) {
    if (typeof rgba === 'string') {
      const [r, g, b, a] = rgba.split(',');

      this.r = roundToDigits(Number.parseFloat(r));
      this.g = roundToDigits(Number.parseFloat(g));
      this.b = roundToDigits(Number.parseFloat(b));
      this.a = roundToDigits(Number.parseFloat(a), 2);

      return;
    }

    this.r = roundToDigits(rgba.r);
    this.g = roundToDigits(rgba.g);
    this.b = roundToDigits(rgba.b);
    this.a = roundToDigits(rgba.a, 2);
  }

  toHsb(): HSBA {
    const [h, s, b] = convert.rgb.hsv([this.r, this.g, this.b]);
    // Saturation and brightness are being translated from percent to decimal from 0 to 1
    return {
      h,
      s: s / PERCENT,
      b: b / PERCENT,
      a: this.a,
    };
  }

  toString(hasOnlyNumbers = false): string {
    const rgbaString = Object.values(this).join(', ');
    return hasOnlyNumbers ? rgbaString : `rgba(${rgbaString})`;
  }
}
