import styled from '@emotion/styled';
import { Height as HeightIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const StyledInputAdornment = styled.div`
  position: absolute;
  z-index: 1;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledHeightIcon = styled(HeightIcon)`
  transform: rotate(45deg);
`;

const StyledIconButton = styled(IconButton)`
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  font-size: ${({ theme }) => theme.spacing(2.5)};
  margin-right: 3px;
`;

export const CodeEditorOpenButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <StyledInputAdornment>
      <StyledIconButton edge="end" size="small" onClick={onClick}>
        <StyledHeightIcon fontSize="inherit" />
      </StyledIconButton>
    </StyledInputAdornment>
  );
};
