import { path } from 'ramda';
import { COMPONENT_DSL_NAMES, EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID, ERROR_PAGE_ROOT_ROUTE_NODE_ID, NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID, NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID, nodeListSelectors, } from '@builder/schemas';
import { Migration } from '../Migration';
const ERROR_PAGE_DSL = {
    [ERROR_PAGE_ROOT_ROUTE_NODE_ID]: {
        name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
        id: ERROR_PAGE_ROOT_ROUTE_NODE_ID,
        parentID: EMPTY_PAGE_ROUTER_SWITCH_NODE_ID,
        props: {
            children: { nodes: [ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID] },
            path: '/500',
            authAccess: 'any',
        },
        states: [],
        alias: 'Error Page Wrapper',
        schemaOverride: {
            disableDelete: true,
        },
    },
    [ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID]: {
        name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
        id: ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID,
        parentID: ERROR_PAGE_ROOT_ROUTE_NODE_ID,
        props: {
            children: { nodes: [] },
            style: {
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: '0%',
                flexDirection: 'column',
                height: '100%',
            },
        },
        states: [],
        alias: 'Error Page',
    },
};
const NOT_FOUND_PAGE_DSL = {
    [NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID]: {
        name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
        id: NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID,
        parentID: EMPTY_PAGE_ROUTER_SWITCH_NODE_ID,
        props: {
            children: { nodes: [NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID] },
            path: '/404',
            authAccess: 'any',
        },
        states: [],
        alias: 'Not Found Wrapper',
        schemaOverride: {
            disableDelete: true,
        },
    },
    [NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID]: {
        name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
        id: NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID,
        parentID: NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID,
        props: {
            children: { nodes: [] },
            style: {
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: '0%',
                flexDirection: 'column',
                height: '100%',
            },
        },
        states: [],
        alias: 'Not Found Page',
    },
};
const NEW_PAGES = ['/404', '/500'];
class Migration_0_14_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.14.1';
    }
    getChangeList() {
        return [`[Routes] Added default 404 page`, `[Routes] Added default 500 page`];
    }
    migrateAppDSL(appDSL) {
        const nodeListDSL = this.migrateNodeListDSL(appDSL.nodes, appDSL);
        const routeNodes = nodeListSelectors.getAllRouteNodes(nodeListDSL);
        const hasErrorPage = routeNodes.some(route => route.props.path === '/500');
        const hasNotFoundPage = routeNodes.some(route => route.props.path === '/404');
        let newNodeListDSL = Object.assign({}, nodeListDSL);
        if (!hasErrorPage) {
            newNodeListDSL = Object.assign(Object.assign({}, newNodeListDSL), ERROR_PAGE_DSL);
        }
        if (!hasNotFoundPage) {
            newNodeListDSL = Object.assign(Object.assign({}, newNodeListDSL), NOT_FOUND_PAGE_DSL);
        }
        if (path([EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, 'props', 'routes', 'nodes'], newNodeListDSL) &&
            !path([EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, 'props', 'routes', 'nodes'], newNodeListDSL).includes(ERROR_PAGE_ROOT_ROUTE_NODE_ID)) {
            newNodeListDSL = Object.assign(Object.assign({}, newNodeListDSL), { [EMPTY_PAGE_ROUTER_SWITCH_NODE_ID]: Object.assign(Object.assign({}, newNodeListDSL[EMPTY_PAGE_ROUTER_SWITCH_NODE_ID]), { props: Object.assign(Object.assign({}, newNodeListDSL[EMPTY_PAGE_ROUTER_SWITCH_NODE_ID].props), { routes: {
                            nodes: path([EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, 'props', 'routes', 'nodes'], newNodeListDSL).concat([ERROR_PAGE_ROOT_ROUTE_NODE_ID]),
                        } }) }) });
        }
        if (path([EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, 'props', 'routes', 'nodes'], newNodeListDSL) &&
            !path([EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, 'props', 'routes', 'nodes'], newNodeListDSL).includes(NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID)) {
            newNodeListDSL = Object.assign(Object.assign({}, newNodeListDSL), { [EMPTY_PAGE_ROUTER_SWITCH_NODE_ID]: Object.assign(Object.assign({}, newNodeListDSL[EMPTY_PAGE_ROUTER_SWITCH_NODE_ID]), { props: Object.assign(Object.assign({}, newNodeListDSL[EMPTY_PAGE_ROUTER_SWITCH_NODE_ID].props), { routes: {
                            nodes: path([EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, 'props', 'routes', 'nodes'], newNodeListDSL).concat([NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID]),
                        } }) }) });
        }
        return Object.assign(Object.assign({}, appDSL), { nodes: Object.assign({}, newNodeListDSL) });
    }
    migrateNodeDSL(nodeDSL, appDSL) {
        if (nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute) {
            const routeNode = nodeDSL;
            if (NEW_PAGES.includes(routeNode.props.path)) {
                return Object.assign(Object.assign({}, nodeDSL), { schemaOverride: {
                        disableDelete: true,
                    } });
            }
        }
        return Object.assign({}, nodeDSL);
    }
}
export const migration_0_14_1 = new Migration_0_14_1();
