var _a, _b;
import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { NS_ID, getBasicPropsSettings, BASIC_PROPS_TOOLTIP } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
import { MaterialTypography } from '../MaterialTypography';
import { getCommonTypographySettings } from '../MaterialTypography/MaterialTypography.settings';
export const MaterialBreadcrumbsSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'separator',
                            label: 'Separator',
                            keyPath: ['separator'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                    settings: getCommonTypographySettings(),
                                    predefineds: {
                                        alias: 'Breadcrumbs Separator',
                                        schemaOverride: {
                                            interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                        },
                                        props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                                nodes: [
                                                    {
                                                        name: COMPONENT_DSL_NAMES.Text,
                                                        props: {
                                                            children: '/',
                                                        },
                                                    },
                                                ],
                                            } }),
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                    predefineds: {
                                        alias: 'Breadcrumbs Separator',
                                        schemaOverride: {
                                            interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                        },
                                        props: Object.assign(Object.assign({}, (_b = BuilderComponentsIcon.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { name: 'KeyboardArrowRight' }),
                                    },
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['maxItems'],
                            label: 'Max Items',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['itemsAfterCollapse'],
                            label: 'Items After Collapse',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['itemsBeforeCollapse'],
                            label: 'Items Before Collapse',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['expandText'],
                            label: 'Expand Text',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
