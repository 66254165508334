import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basicDSL from './style-guide-dsl/CheckboxStyleGuide/basic.json';
import colorDSL from './style-guide-dsl/CheckboxStyleGuide/color.json';
import labelPlacementDSL from './style-guide-dsl/CheckboxStyleGuide/labelPlacement.json';
import sizeDSL from './style-guide-dsl/CheckboxStyleGuide/size.json';
export const CheckboxStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiCheckbox,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
    dirName: 'CheckboxStyleGuide',
    title: 'Checkbox',
    classNames: [
        '.MuiCheckbox-root',
        '.Mui-checked',
        '.Mui-disabled',
        '.MuiCheckbox-indeterminate',
        '.MuiCheckbox-colorPrimary',
        '.MuiCheckbox-colorSecondary',
    ],
    stories: [
        {
            title: 'Basic',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        checked: true,
                        disabled: false,
                        label: undefined,
                    },
                },
                {
                    props: {
                        checked: false,
                        disabled: false,
                        label: undefined,
                    },
                },
                {
                    props: {
                        checked: false,
                        disabled: true,
                        label: undefined,
                    },
                },
                {
                    props: {
                        checked: true,
                        disabled: true,
                        label: undefined,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: basicDSL,
        },
        {
            title: 'Label Placement',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        labelPlacement: 'end',
                    },
                },
                {
                    props: {
                        labelPlacement: 'start',
                    },
                },
                {
                    props: {
                        labelPlacement: 'top',
                    },
                },
                {
                    props: {
                        labelPlacement: 'bottom',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: labelPlacementDSL,
        },
        {
            title: 'Color',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                        checked: true,
                        label: undefined,
                    },
                },
                {
                    props: {
                        color: 'secondary',
                        checked: true,
                        label: undefined,
                    },
                },
                {
                    props: {
                        color: 'success',
                        checked: true,
                        label: undefined,
                    },
                },
                {
                    props: {
                        color: 'default',
                        checked: true,
                        label: undefined,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: colorDSL,
        },
        {
            title: 'Size',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        checked: true,
                        size: 'small',
                        label: undefined,
                    },
                },
                {
                    props: {
                        checked: true,
                        size: 'medium',
                        label: undefined,
                    },
                },
                {
                    props: {
                        checked: true,
                        size: 'large',
                        label: undefined,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: sizeDSL,
        },
    ],
};
