import { omit } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_11_7 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.7';
    }
    getChangeList() {
        return ['Remove title from assets', 'Transform all assets names to correct names'];
    }
    migrateAssetDSL(assetDSL, appDSL) {
        const assetDSLWithCorrectName = Object.assign(Object.assign({}, assetDSL), { name: assetDSL.name.replace(/[^A-Za-z\d]/g, '') });
        return omit(['title'], assetDSLWithCorrectName);
    }
}
export const migration_0_11_7 = new Migration_0_11_7();
