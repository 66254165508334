import { getRenderIDFromPath } from './common';
export const createRenderPathData = ({ nodeID, parentRenderPath, iterationSuffix = undefined, }) => {
    var _a;
    const identity = iterationSuffix === undefined ? nodeID : `{{[${nodeID}][${iterationSuffix}]}}`;
    const renderPath = [...((_a = parentRenderPath === null || parentRenderPath === void 0 ? void 0 : parentRenderPath.renderPath) !== null && _a !== void 0 ? _a : []), identity];
    return {
        renderID: getRenderIDFromPath(renderPath),
        renderPath,
        parent: parentRenderPath,
    };
};
export function* traverseRenderParents(renderPathData) {
    let parentData = renderPathData.parent;
    while (parentData) {
        yield parentData;
        parentData = parentData.parent;
    }
}
