import immutableUpdate from 'immutability-helper';
import { assocPath, path, head, isNil } from 'ramda';
import { createSelector } from 'reselect';
import { COMPONENT_DSL_INTERACTION_TYPES } from '../constants';
import { PropAssert, NodeChecker } from '../validation';
import * as componentListSelectors from './component-list-selectors';
import * as nodeListSelectors from './node-list-selectors';
import * as nodeSchemaSelectors from './node-schema-selectors';
import * as nodeSelectors from './node-selectors';
const getNodeListDSL = (state) => state.nodeListDSL;
const getComponentListDSL = (state) => state.componentListDSL;
const getNodeID = (_, props) => props.nodeID;
export const getNodeSchema = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    return {
        nodeDSL,
        componentDSL,
    };
});
export const getNodeDSL = createSelector(getNodeSchema, ({ nodeDSL }) => nodeDSL);
export const getMinSizeStyles = createSelector(getNodeSchema, nodeSchemaSelectors.getMinSizeStyles);
export const geCodeFileStructure = createSelector(getNodeSchema, nodeSchemaSelectors.getCodeFileStructure);
export const getMergedSchemaOverrides = createSelector(getNodeSchema, nodeSchemaSelectors.getMergedSchemaOverrides);
export const isFirstChildTextAndEmpty = createSelector(getNodeDSL, getNodeListDSL, getComponentListDSL, (nodeDSL, nodeListDSL, componentListDSL) => {
    const childrenNodes = nodeSelectors.getAllImmediateChildrenIDs(nodeDSL, {
        componentListDSL,
    });
    const firstChild = nodeListDSL[head(childrenNodes) || ''];
    if (!firstChild) {
        return false;
    }
    return nodeSelectors.isTextNode(firstChild) && nodeSelectors.isChildrenPropEmpty(firstChild);
});
export const isFirstChildFragmentAndEmpty = createSelector(getNodeDSL, getNodeListDSL, getComponentListDSL, (nodeDSL, nodeListDSL, componentListDSL) => {
    const childrenNodes = nodeSelectors.getAllImmediateChildrenIDs(nodeDSL, {
        componentListDSL,
    });
    const firstChild = nodeListDSL[head(childrenNodes) || ''];
    if (!firstChild) {
        return false;
    }
    return (nodeSelectors.isFragmentNode(firstChild) && nodeSelectors.isChildrenPropEmpty(firstChild));
});
export const hasChildren = createSelector(getNodeDSL, getComponentListDSL, (nodeDSL, componentListDSL) => {
    const childrenNodes = nodeSelectors.getAllImmediateChildrenIDs(nodeDSL, { componentListDSL });
    return childrenNodes.length > 0;
});
/**
 * @returns True if node is empty and min size must be applied.
 */
export const isNodeEmpty = createSelector(getNodeDSL, hasChildren, isFirstChildTextAndEmpty, isFirstChildFragmentAndEmpty, 
// eslint-disable-next-line no-shadow
(nodeDSL, hasChildren, isFirstChildTextAndEmpty, isFirstChildFragmentAndEmpty) => {
    const isIconNode = nodeSelectors.isIconNode(nodeDSL);
    const isImageNode = nodeSelectors.isImageNode(nodeDSL);
    /**
     * Node is empty if:
     *  - Node has no children.
     *  - Node is image ind src is empty.
     *  - Node has fragment as child and this fragment is empty.
     *  - Node has text as child and this fragment is empty.
     */
    let isEmpty = !hasChildren || isFirstChildTextAndEmpty || isFirstChildFragmentAndEmpty;
    if (isImageNode) {
        isEmpty = nodeSelectors.isImageNodeAndEmpty(nodeDSL);
    }
    else if (isIconNode) {
        isEmpty = nodeSelectors.isIconNodeAndEmpty(nodeDSL);
    }
    return isEmpty;
});
/**
 * @returns All nodes which are must be extracted to file
 */
export const getNodeListWithFileStructure = createSelector(getNodeListDSL, getComponentListDSL, (nodeListDSL, componentListDSL) => {
    return Object.values(nodeListDSL).filter(nodeDSL => {
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
        });
        const codeFileStructure = nodeSchemaSelectors.getCodeFileStructure({
            nodeDSL,
            componentDSL,
        });
        return Boolean(codeFileStructure);
    });
});
/**
 * @returns List of nodes which are placed in the same code file
 */
export const getCodeFileNodeList = createSelector(getNodeDSL, getNodeListDSL, getComponentListDSL, (nodeDSL, nodeListDSL, componentListDSL) => {
    const childrenNodeIDs = nodeListSelectors.getAllImmediateChildrenIDs(nodeListDSL, {
        componentListDSL,
        nodeID: nodeDSL.id,
    });
    return childrenNodeIDs.reduce((accum, childNodeID) => {
        const childNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: childNodeID });
        const childComponentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: childNodeDSL.name,
        });
        const codeFileStructure = nodeSchemaSelectors.getCodeFileStructure({
            nodeDSL: childNodeDSL,
            componentDSL: childComponentDSL,
        });
        if (codeFileStructure) {
            return accum;
        }
        return Object.assign(Object.assign(Object.assign({}, accum), getCodeFileNodeList({ componentListDSL, nodeListDSL }, {
            nodeID: childNodeDSL.id,
        })), { [childNodeDSL.id]: childNodeDSL });
    }, {
        [nodeDSL.id]: nodeDSL,
    });
});
/**
 * @returns List of first-level nodes which are must be extracted to the separate file
 */
export const getChildrenCodeFileNodeList = createSelector(getNodeDSL, getNodeListDSL, getComponentListDSL, (nodeDSL, nodeListDSL, componentListDSL) => {
    const childrenNodeIDs = nodeListSelectors.getAllImmediateChildrenIDs(nodeListDSL, {
        componentListDSL,
        nodeID: nodeDSL.id,
    });
    return childrenNodeIDs.reduce((accum, childNodeID) => {
        const childNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: childNodeID });
        const childComponentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: childNodeDSL.name,
        });
        const codeFileStructure = nodeSchemaSelectors.getCodeFileStructure({
            nodeDSL: childNodeDSL,
            componentDSL: childComponentDSL,
        });
        if (codeFileStructure) {
            return Object.assign(Object.assign({}, accum), { [childNodeDSL.id]: childNodeDSL });
        }
        return Object.assign(Object.assign({}, accum), getChildrenCodeFileNodeList({ componentListDSL, nodeListDSL }, {
            nodeID: childNodeDSL.id,
        }));
    }, {});
});
/**
 * @example of removing "componentToRemove"
 * {
 *  root: {
 *    parentID: null,
 *    props: {
 *      children: {
 *        nodes: ['componentToRemove'],
 *      },
 *    },
 *  },
 *  componentToRemove: {
 *    parentID: 'root',
 *    props: {
 *      children: ['componentToRemoveChildren'],
 *    },
 *  },
 *  componentToRemoveChildren: {
 *     parentID: 'componentToRemove',
 *     props: {},
 *   },
 * }
 *
 * =>
 *
 * {
 *   root: {
 *     parentID: null,
 *     props: {
 *       children: {
 *         nodes: [],
 *       },
 *     },
 *   },
 * }
 * */
export const removeComponentFromRelatedComponents = createSelector(getNodeListDSL, getComponentListDSL, getNodeID, (nodeListDSL, componentListDSL, nodeID) => {
    const componentToRemove = nodeListDSL[nodeID];
    const { parentID } = componentToRemove;
    PropAssert.Value.assertIsStringProp(parentID);
    const toRemoveFrom = nodeListDSL[parentID];
    const toRemoveFromSchemaDSL = componentListDSL[toRemoveFrom.name].schema.props;
    const toRemoveFromProps = toRemoveFrom.props;
    const childrenTargetProp = nodeSelectors.findNodePlacedPropNameInParent(toRemoveFromSchemaDSL, toRemoveFromProps, nodeID);
    const toRemoveFromChildren = path(childrenTargetProp, toRemoveFrom.props);
    PropAssert.Value.assertIsReactNodeProp(toRemoveFromChildren);
    PropAssert.Value.assertIsRenderableNodeProp(toRemoveFromChildren);
    const isLastChildrenNode = toRemoveFromChildren.nodes.length === 1;
    const componentIndex = toRemoveFromChildren.nodes.findIndex(childID => nodeID === childID);
    const updatedToRemoveFromProps = assocPath(childrenTargetProp, isLastChildrenNode
        ? { nodes: [] }
        : Object.assign(Object.assign({}, toRemoveFromChildren), { nodes: immutableUpdate(toRemoveFromChildren.nodes, {
                $splice: [[componentIndex, 1]],
            }) }), toRemoveFrom.props);
    const updatedParent = Object.assign(Object.assign({}, toRemoveFrom), { props: Object.assign({}, updatedToRemoveFromProps) });
    const childrenIDs = nodeListSelectors.getAllChildrenIDs(nodeListDSL, {
        nodeID,
        componentListDSL,
    });
    const removedFromComponents = (function removeFromComponents() {
        const newComponents = Object.assign({}, nodeListDSL);
        // self
        delete newComponents[nodeID];
        // and all children as well
        childrenIDs.forEach(function removeChildren(childID) {
            delete newComponents[childID];
        });
        return newComponents;
    })();
    return Object.assign(Object.assign({}, removedFromComponents), { [parentID]: updatedParent });
});
export const isApplyingCopyBufferDisabled = createSelector(getNodeDSL, getComponentListDSL, (nodeDSL, componentListDSL) => {
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const { dndTargetPropName } = componentDSL.schema;
    return NodeChecker.Value.isOverallTextRelated(nodeDSL) || isNil(dndTargetPropName);
});
export const isCopyingDisabled = createSelector(getNodeDSL, getComponentListDSL, (nodeDSL, componentListDSL) => {
    var _a, _b;
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const isRootNode = nodeSelectors.isRoot(nodeDSL);
    const interaction = nodeSchemaSelectors.getInteraction({ nodeDSL, componentDSL });
    const disableCopying = isRootNode ||
        interaction === COMPONENT_DSL_INTERACTION_TYPES.none ||
        interaction === COMPONENT_DSL_INTERACTION_TYPES.onlyEditable ||
        ((_b = (_a = componentDSL === null || componentDSL === void 0 ? void 0 : componentDSL.schema) === null || _a === void 0 ? void 0 : _a.accessibility) === null || _b === void 0 ? void 0 : _b.disableCopying);
    return Boolean(disableCopying);
});
