import { omit } from 'ramda';
export class GroupOfStates {
    constructor(states) {
        this.getTimesUsed = (state, states) => {
            let timesUsed = 0;
            for (const innerState of states) {
                const innerStateWithoutName = omit(['name'], innerState);
                if (state.name !== innerState.name &&
                    (JSON.stringify(innerStateWithoutName).includes(`${state.name}.`) ||
                        JSON.stringify(innerStateWithoutName).includes(`${state.name}[`) ||
                        JSON.stringify(innerStateWithoutName).includes(`${state.name}(`) ||
                        JSON.stringify(innerStateWithoutName).includes(`${state.name}?`))) {
                    timesUsed += 1;
                }
            }
            return timesUsed;
        };
        this.calculateTimesUsed = () => {
            this.states.forEach((stateToCalculate) => {
                // eslint-disable-next-line no-param-reassign
                stateToCalculate.timesUsed += this.getTimesUsed(stateToCalculate, this.states);
            });
        };
        this.sortFromLargestToSmallest = () => {
            this.states.sort((a, b) => {
                return b.timesUsed - a.timesUsed;
            });
        };
        this.groupStatesByTimesUsed = () => {
            const [firstState] = this.states;
            const lastState = this.states[this.states.length - 1];
            const statesByTimesUsed2 = [];
            for (let index = firstState.timesUsed; index >= lastState.timesUsed; index -= 1) {
                statesByTimesUsed2.push(this.states.filter((state) => state.timesUsed === index));
            }
            return statesByTimesUsed2;
        };
        this.states = states;
    }
    sort() {
        this.calculateTimesUsed();
        this.sortFromLargestToSmallest();
        return this.groupStatesByTimesUsed();
    }
}
