import React, {
  createContext,
  MutableRefObject,
  useRef,
  useEffect,
  useContext,
  useState,
  useCallback,
  useReducer,
} from 'react';

import { useAuth } from '@8base-react/auth';

import { DraftEngine } from 'src/shared/draft-engine';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

type DraftEngineContextType = {
  draftEngineRef: MutableRefObject<DraftEngine | undefined>;
  isConnected: boolean;
};

export const DraftEngineContext = createContext<DraftEngineContextType>({
  draftEngineRef: { current: undefined },
  isConnected: false,
});

const shouldRenew = (expiresAt: number) => Math.round(Date.now() / 1000) >= expiresAt - 3600;

export const DraftEngineProvider: React.FC = ({ children }) => {
  const { authState, authClient } = useAuth();
  const { workspaceID } = useCurrentWorkspaceID();
  const draftEngineRef = useRef<DraftEngine>();
  const [isConnected, setConnected] = useState(false);
  const [, forceUpdate] = useReducer(i => i + 1, 0);

  const stateCallback = useCallback(
    async (state: boolean) => {
      setConnected(state);

      if (!state) {
        const tokenInfo = authClient.getTokenInfo();

        if (authClient.checkIsAuthorized() && shouldRenew(tokenInfo.exp)) {
          const { email, idToken } = await authClient.checkSession();

          authClient.setState({
            token: idToken,
            email,
          });
        }
      }
    },
    [setConnected, authClient],
  );

  useEffect(() => {
    if (authState.token && workspaceID) {
      const draftEngine = new DraftEngine({
        authorization: authState.token,
        workspaceId: workspaceID,
        stateCallback,
      });

      draftEngineRef.current = draftEngine;
      draftEngine.connect();

      forceUpdate();

      return () => {
        draftEngine.disconnect();
        draftEngineRef.current = undefined;
      };
    }
  }, [authState.token, workspaceID, stateCallback]);

  return (
    <DraftEngineContext.Provider value={{ draftEngineRef, isConnected }}>
      {children}
    </DraftEngineContext.Provider>
  );
};

export function useDraftEngine(): {
  draftEngine: DraftEngine | undefined;
  isConnected: boolean;
} {
  const { draftEngineRef, isConnected } = useContext(DraftEngineContext);

  return {
    draftEngine: draftEngineRef.current,
    isConnected,
  };
}
