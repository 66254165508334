var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
export const FormFieldArray = forwardRef((_a, ref) => {
    var { 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, style, className, id, title } = _a, props = __rest(_a, ['data-test', 'data-node-id', 'data-node-render-path', "style", "className", "id", "title"]);
    const { setFieldValue } = useFormikContext();
    useEffect(() => {
        return () => setFieldValue(props.name, undefined);
    }, [props.name, setFieldValue]);
    return (_jsx("div", Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, className: className, style: style, id: id, title: title }, { children: _jsx(FieldArray, Object.assign({}, props)) })));
});
