import { UTM_PARAMETERS } from '../constants';

export const getUtmParams = (url: string): Record<string, string> => {
  const { searchParams } = new URL(url);
  const result: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    if (UTM_PARAMETERS.includes(key)) {
      result[key] = value;
    }
  }

  return result;
};
