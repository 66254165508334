import { useMemo, useRef } from 'react';

import {
  UseOverlayDragOnlyArgs,
  UseOverlayDndResult,
  useOverlayDragOnly,
  UseOverlayDndArgs,
  useOverlayDropOnly,
} from './common';

/**
 * Not very useful on its own, but added for consistency
 */
export function useOverlayDrag(props: UseOverlayDragOnlyArgs): Omit<UseOverlayDndResult, 'isOver'> {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isDragging }, drag] = useOverlayDragOnly({ ...props });

  drag(ref);

  return useMemo(() => {
    return { ref, isDragging };
  }, [isDragging]);
}

export function useOverlayDrop(
  props: Omit<UseOverlayDndArgs, 'name'>,
): Omit<UseOverlayDndResult, 'isDragging'> {
  const ref = useRef<HTMLDivElement | null>(null);
  const dndConfig = useMemo(() => ({ ...props, ref }), [props]);
  const [{ isOver }, drop] = useOverlayDropOnly(dndConfig);

  drop(ref);

  return useMemo(() => {
    return { ref, isOver };
  }, [isOver]);
}

export function useOverlayDnd({ target, name }: UseOverlayDndArgs): UseOverlayDndResult {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isOver }, drop] = useOverlayDropOnly({ target, ref });
  const [{ isDragging }, drag] = useOverlayDragOnly({ nodeID: target.nodeID, nodeName: name });

  drag(drop(ref));

  return useMemo(() => {
    return { ref, isOver, isDragging };
  }, [isDragging, isOver]);
}
