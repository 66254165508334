/**
 * @example getPercentageTitle(0.44) => '44%'
 * */
export const getPercentageTitle = (value: number): string => `${Math.round(value * 100)}%`;

/**
 * @example roundToDigits(0.444215, 2) => 0.44
 * @example roundToDigits(0.544215) => 1
 * */
export const roundToDigits = (value: number, digitsCount = 0): number => {
  const discharge = +(1 + Array.from({ length: digitsCount }).fill('0').join(''));
  return Math.round(value * discharge) / discharge;
};
