import React from 'react';

import { useTheme } from '@emotion/react';
import {
  CircleOutlined as CircleOutlinedIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { Checkbox, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { bindToggle, usePopupState, anchorRef } from 'material-ui-popup-state/hooks';

import { AssetID } from '@builder/schemas';
import { isNil } from '@builder/utils';

import { AssetFileWithTemporaryStatesDSL } from '../../common/types';
import { AssetMedia } from '../AssetMedia';
import { AssetPropertiesPopper, ASSET_PROPERTIES_POPPER_ID } from '../AssetPropertiesPopper';
import { CssGrid, Icon } from 'src/shared/components';

import {
  SecondaryActionContainer,
  StyledListItem,
  StyledListItemButton,
} from './AssetListItem.styles';

type AssetListItemProps = {
  selectedAssetID?: AssetID;
  asset: AssetFileWithTemporaryStatesDSL;
  onSelectAsset?: (assetID?: AssetID) => void;
  toggleAssetSelectMultipleDeletion: (assetID: AssetID) => void;
};

export const AssetListItem: React.FC<AssetListItemProps> = ({
  asset,
  selectedAssetID,
  onSelectAsset,
  toggleAssetSelectMultipleDeletion,
}) => {
  const popupState = usePopupState({
    variant: 'popper',
    popupId: ASSET_PROPERTIES_POPPER_ID,
  });
  const popperRef = anchorRef(popupState);
  const bindToggleState = bindToggle(popupState);
  const isSelectionAssetForComponentView = !isNil(onSelectAsset);
  const assetAction = isSelectionAssetForComponentView
    ? { onClick: () => onSelectAsset?.(asset.id) }
    : bindToggleState;
  const theme = useTheme();

  return (
    <StyledListItem
      data-test={`assets.assetItem.listItem_${asset.name}`}
      secondaryAction={
        !isSelectionAssetForComponentView && (
          <CssGrid gridAutoFlow="column" gridTemplateColumns="auto auto" gap={1}>
            <SecondaryActionContainer>
              <IconButton
                edge="end"
                size="small"
                {...bindToggleState}
                data-test={`assets.assetItem.settingsButton_${asset.name}`}
              >
                <Icon width={15} height={15} name="settings" />
              </IconButton>
            </SecondaryActionContainer>
            <SecondaryActionContainer isSelected={asset.isSelected}>
              <Checkbox
                data-test={`assets.assetItem.selectAssetCheckbox_${asset.name}`}
                checked={asset.isSelected}
                onClick={() => toggleAssetSelectMultipleDeletion(asset.id)}
                icon={<CircleOutlinedIcon fontSize="medium" />}
                checkedIcon={
                  <CheckCircleIcon fontSize="medium" htmlColor={theme.palette.common.white} />
                }
              />
            </SecondaryActionContainer>
          </CssGrid>
        )
      }
    >
      <StyledListItemButton
        ref={popperRef as React.Ref<HTMLDivElement>}
        selected={selectedAssetID ? selectedAssetID === asset.id : popupState.isOpen}
        isSelected={asset.isSelected}
        dense
        {...assetAction}
      >
        <ListItemIcon>
          <AssetMedia
            assetType={asset.type}
            downloadUrl={asset.previewUrl}
            width="32px"
            height="32px"
            borderRadius="4px"
            listItem
          />
        </ListItemIcon>
        <ListItemText
          data-test="assets.assetItem.name"
          primary={asset.name}
          primaryTypographyProps={{
            noWrap: true,
            style: {
              maxWidth: '165px',
            },
          }}
        />
      </StyledListItemButton>
      <If condition={popupState.isOpen}>
        <AssetPropertiesPopper asset={asset} popupState={popupState} />
      </If>
    </StyledListItem>
  );
};
