export const FONT_TYPES = {
    custom: 'custom',
    google: 'google',
};
export const GOOGLE_FONT_VARIANTS = {
    thin100: '100',
    thin200: '200',
    thin100Italic: '100italic',
    thin200Italic: '200italic',
    light300: '300',
    light300Italic: '300italic',
    regular400: 'regular',
    regular400Italic: 'italic',
    medium500: '500',
    medium500Italic: '500italic',
    semiBold600: '600',
    semiBold600Italic: '600italic',
    bold700: '700',
    bold700Italic: '700italic',
    extraBold800: '800',
    extraBold800Italic: '800italic',
    black900: '900',
    black900Italic: '900italic',
};
export const FONT_CSS_FORMATS = {
    ttf: 'truetype',
    otf: 'opentype',
    woff: 'woff',
    woff2: 'woff2',
};
export const FONT_MIMES_TO_FORMATS = {
    'font/ttf': FONT_CSS_FORMATS.ttf,
    'font/otf': FONT_CSS_FORMATS.otf,
    'font/woff': FONT_CSS_FORMATS.woff,
    'font/woff2': FONT_CSS_FORMATS.woff2,
};
