import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basicRatingDSL from './style-guide-dsl/RatingStyleGuide/basicRating.json';
import customizationDSL from './style-guide-dsl/RatingStyleGuide/customization.json';
import ratingPrecisionDSL from './style-guide-dsl/RatingStyleGuide/ratingPrecision.json';
import sizesDSL from './style-guide-dsl/RatingStyleGuide/sizes.json';
export const RatingStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiRating,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRating,
    dirName: 'RatingStyleGuide',
    title: 'Rating',
    classNames: [
        '.MuiRating-root',
        '.MuiRating-sizeSmall',
        '.MuiRating-sizeMedium',
        '.MuiRating-sizeLarge',
        '.Mui-readOnly',
        '.Mui-disabled',
        '.Mui-focusVisible',
        '.MuiRating-visuallyHidden',
        '.MuiRating-label',
        '.MuiRating-labelEmptyValueActive',
        '.MuiRating-icon',
        '.MuiRating-iconEmpty',
        '.MuiRating-iconFilled',
        '.MuiRating-iconHover',
        '.MuiRating-iconFocus',
        '.MuiRating-iconActive',
        '.MuiRating-decimal',
    ],
    stories: [
        {
            title: 'Basic rating',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRating,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        readOnly: false,
                    },
                },
                {
                    props: {
                        readOnly: true,
                    },
                },
                {
                    props: {
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: basicRatingDSL,
        },
        {
            title: 'Rating precision',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRating,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        precision: 0.5,
                        defaultValue: 1,
                    },
                },
                {
                    props: {
                        precision: 1,
                        defaultValue: 1,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: ratingPrecisionDSL,
        },
        {
            title: 'Sizes',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRating,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        size: 'small',
                    },
                },
                {
                    props: {
                        size: 'medium',
                    },
                },
                {
                    props: {
                        size: 'large',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: sizesDSL,
        },
        {
            title: 'Customization',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsRating,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        icon: 'Favorite',
                    },
                },
                {
                    props: {
                        emptyIcon: 'FavoriteBorder',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: customizationDSL,
        },
    ],
};
