import { Migration } from '../Migration';
class Migration_0_19_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.4';
    }
    getChangeList() {
        return ['Disabled: Fix styleProps nested in textField and Select components'];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    migrateNodeDSL(nodeDSL) {
        // this migration is disabled becuase is breaking styles in the app
        return nodeDSL;
    }
}
export const migration_0_19_4 = new Migration_0_19_4();
