import { dashboardInitial } from '../../../initial-state';
import { AppEvents, DashboardState } from 'src/store';

import { appDSLReducer } from './appDSLReducer';

export const appConfigurationReducer = (
  state: DashboardState['appConfiguration'] = dashboardInitial.appConfiguration,
  event: AppEvents,
): DashboardState['appConfiguration'] => {
  return {
    ...state,
    appDSL: appDSLReducer(state.appDSL, event),
  };
};
