import { isEmpty } from 'ramda';

import {
  NodeListDSL,
  nodeListSelectors,
  nodeSelectors,
  NodeID,
  ComponentListDSL,
  appNodesSchemaSelectors,
  NodeChecker,
  ROOT_LAYOUT_CONTAINER_NODE_ID,
  COMPONENT_DSL_NAMES,
  isDialogComponent,
  StateDSLWithDialog,
} from '@builder/schemas';
import { SystemError, ERROR_SCOPES, isUndefined, isArray } from '@builder/utils';

import { DashboardState } from '../../common';
import { dashboardSelectors } from 'src/store';

import { stateRemove } from './stateRemove';

/**
 * Business function to create new components & selectedID state
 * by removing a given node & all its children
 */
const removeComponentByID = ({
  nodeListDSL,
  componentListDSL,
  selectedID,
  id,
}: {
  nodeListDSL: NodeListDSL;
  componentListDSL: ComponentListDSL;
  selectedID: DashboardState['operations']['selectedID'];
  id: NodeID;
}) => {
  const removedFromComponents = appNodesSchemaSelectors.removeComponentFromRelatedComponents(
    { nodeListDSL, componentListDSL },
    {
      nodeID: id,
    },
  );
  const removedFromSelected = removeComponentFromSelected({
    nodeListDSL,
    componentListDSL,
    selectedID,
    id,
  });

  return { removedFromComponents, removedFromSelected };
};

const removeComponentFromSelected = ({
  nodeListDSL,
  componentListDSL,
  selectedID,
  id,
}: {
  nodeListDSL: NodeListDSL;
  componentListDSL: ComponentListDSL;
  selectedID: DashboardState['operations']['selectedID'];
  id: NodeID;
}) => {
  const childrenIDs = nodeListSelectors.getAllChildrenIDs(nodeListDSL, {
    nodeID: id,
    componentListDSL,
  });

  const removedFromSelected = (function removeFromSelected() {
    if (selectedID === null) {
      return null;
    }

    if (selectedID === id) {
      return null;
    }

    if (childrenIDs.some(childID => childID === selectedID)) {
      return null;
    }

    if (isArray(selectedID)) {
      const filteredSelectedID = selectedID.filter(selectedNodeId => id !== selectedNodeId);
      return filteredSelectedID?.length ? filteredSelectedID : null;
    }

    return selectedID;
  })();

  return removedFromSelected;
};

export const componentRemove = (
  state: DashboardState,
  nodeID: NodeID,
  currentPathName?: string,
): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  let newState: DashboardState = state;
  const componentToRemove = nodeListDSL[nodeID];
  const { parentID } = componentToRemove;

  const updateStates = (statesIds: string[]) => {
    return {
      ...newState,
      appConfiguration: {
        ...newState.appConfiguration,
        appDSL: {
          ...newState.appConfiguration.appDSL,
          nodes: Object.keys(newState.appConfiguration.appDSL.nodes).reduce((newNodes, nodeKey) => {
            const nodeDSL = newState.appConfiguration.appDSL.nodes[nodeKey];

            return {
              ...newNodes,
              [nodeKey]: {
                ...nodeDSL,
                ...(nodeDSL.context
                  ? {
                      context: Object.keys(nodeDSL.context)
                        .filter(stateKey => !statesIds.includes(stateKey))
                        .reduce(
                          (newContext, contextKey) => ({
                            ...newContext,
                            ...(nodeDSL.context
                              ? { [contextKey]: nodeDSL.context[contextKey] }
                              : {}),
                          }),
                          {},
                        ),
                    }
                  : {}),
              },
            } as NodeListDSL;
          }, {}),
          states: stateRemove(newState.appConfiguration.appDSL.states, statesIds),
        },
      },
    };
  };

  if (!isUndefined(currentPathName)) {
    const currentRouteNodeDSL = nodeListSelectors.getCurrentRouteContentNodeDSL(nodeListDSL, {
      currentPathname: currentPathName,
    });
    const currentRouteNode = nodeListDSL[currentRouteNodeDSL.id as string];
    const currentLocal = nodeListDSL[currentRouteNode?.parentID as string];
    const boundStates: string[] = [];
    const childrenIDs = nodeListSelectors.getAllChildrenIDs(nodeListDSL, {
      nodeID,
      componentListDSL,
    });

    const pushBoundStates = (nodeId: string | string[]) => {
      currentLocal.states?.forEach(currentRouteState => {
        if (typeof nodeId === 'string') {
          if (currentRouteState.componentBoundID === nodeId) {
            boundStates.push(currentRouteState.stateID);
          }
        }

        for (let i = 0; i < nodeId.length; i++) {
          if (currentRouteState.componentBoundID === nodeId[i]) {
            boundStates.push(currentRouteState.stateID);
          }
        }
      });
    };

    pushBoundStates(nodeID as string);
    pushBoundStates(childrenIDs);

    if (!isEmpty(boundStates)) {
      newState = updateStates(boundStates);
      const removeStateFromBoundNode = (nodeId: string, boundState: string) => {
        newState.appConfiguration.appDSL.nodes[
          nodeId
        ].states = newState.appConfiguration.appDSL.nodes[nodeId].states?.filter(
          item => item.stateID !== boundState,
        );
      };

      boundStates.forEach(currentBoundState => {
        removeStateFromBoundNode(currentRouteNodeDSL.id, currentBoundState);
        removeStateFromBoundNode(currentRouteNodeDSL.parentID as string, currentBoundState);
      });
    }
  }

  const requiredStateIDs = nodeSelectors.getRequiredStateConnectionIDs(componentToRemove);
  if (!isEmpty(requiredStateIDs)) {
    newState = updateStates(requiredStateIDs);
  }

  if (parentID && nodeListDSL[parentID]) {
    const { removedFromComponents, removedFromSelected } = removeComponentByID({
      nodeListDSL: dashboardSelectors.getNodeListDSL(newState),
      componentListDSL: dashboardSelectors.getComponentListDSL(newState),
      selectedID: newState.operations.selectedID,
      id: nodeID,
    });

    newState = {
      ...newState,
      operations: { ...newState.operations, selectedID: removedFromSelected },
      appConfiguration: {
        ...newState.appConfiguration,
        appDSL: {
          ...newState.appConfiguration.appDSL,
          nodes: removedFromComponents,
        },
      },
    };
  }

  if (componentToRemove?.name === COMPONENT_DSL_NAMES.DialogSymbol) {
    const stateRemoveIDS = Object.values(state.appConfiguration.appDSL.states)
      ?.filter(
        (stateProp: StateDSLWithDialog) =>
          stateProp?.dialogBoundID === componentToRemove.id ||
          stateProp?.componentBoundID === componentToRemove.id,
      )
      ?.map(stateProp => stateProp.id);
    newState = updateStates(stateRemoveIDS);
  }

  if (componentToRemove?.parentID && isDialogComponent(componentToRemove?.parentID, nodeListDSL)) {
    const stateRemoveIDS = Object.values(state.appConfiguration.appDSL.states)
      ?.filter(
        (stateProp: StateDSLWithDialog) =>
          stateProp?.componentBoundID === componentToRemove.id ||
          (stateProp.componentBoundID &&
            !newState.appConfiguration.appDSL.nodes?.[stateProp?.componentBoundID] &&
            stateProp.id !== 'errorState'),
      )
      ?.map(stateProp => stateProp.id);
    newState = updateStates(stateRemoveIDS);
  }

  const isRouteNode = NodeChecker.Value.isRouteNode(componentToRemove);
  const isRouteLayoutNode = isRouteNode && currentPathName?.includes('/__layouts/__');

  if (
    isRouteNode &&
    componentToRemove.props.path === state.router.currentRoute &&
    !isRouteLayoutNode
  ) {
    const deletedRoutePath = componentToRemove.props.path;
    const allRouteNodes = nodeListSelectors.getAllRouteNodesIncludeSystem(nodeListDSL);
    const currentRouteIndex = allRouteNodes.findIndex(
      routeNode => routeNode.props.path === deletedRoutePath,
    );
    const withoutDeletedNodesNodeListDSL = dashboardSelectors.getNodeListDSL(newState);
    const allRouteNodesWithoutDeleted = nodeListSelectors.getAllRouteNodesIncludeSystem(
      withoutDeletedNodesNodeListDSL,
    );

    const nextRoute =
      allRouteNodesWithoutDeleted[currentRouteIndex - 1] ||
      allRouteNodesWithoutDeleted[currentRouteIndex + 1];

    if (!nextRoute) {
      throw new SystemError(ERROR_SCOPES.appEngine, `At least one route must exist`);
    }

    newState = {
      ...newState,
      router: {
        ...newState.router,
        history: newState.router.history.filter(historyPath => historyPath !== deletedRoutePath),
        currentRoute: nextRoute.props.path,
      },
    };
  }

  if (isRouteLayoutNode) {
    const deletedRoutePath = componentToRemove.props.path;
    const routerSwitchID = componentToRemove.parentID;
    const componentBoxID = nodeListDSL[routerSwitchID as NodeID].parentID as NodeID;
    const currentPageLayoutID = nodeListDSL[componentBoxID].parentID as NodeID;
    const relatedLayoutIDs = [routerSwitchID, componentBoxID, currentPageLayoutID];
    const prevLayouts =
      newState.appConfiguration.appDSL.nodes[ROOT_LAYOUT_CONTAINER_NODE_ID].props.layouts;

    // New layout list
    newState = {
      ...newState,
      appConfiguration: {
        ...newState.appConfiguration,
        appDSL: {
          ...newState.appConfiguration.appDSL,
          nodes: {
            ...newState.appConfiguration.appDSL.nodes,
            [ROOT_LAYOUT_CONTAINER_NODE_ID]: {
              ...newState.appConfiguration.appDSL.nodes[ROOT_LAYOUT_CONTAINER_NODE_ID],
              props: {
                ...newState.appConfiguration.appDSL.nodes[ROOT_LAYOUT_CONTAINER_NODE_ID].props,
                layouts: {
                  nodes: [
                    ...(prevLayouts as { nodes: string[] }).nodes.filter(
                      node => node !== currentPageLayoutID,
                    ),
                  ],
                },
              },
            },
          },
        },
      },
    };

    // Delete related nodes of layout
    newState = {
      ...newState,
      appConfiguration: {
        ...newState.appConfiguration,
        appDSL: {
          ...newState.appConfiguration.appDSL,
          nodes: {
            ...Object.fromEntries(
              Object.entries(newState.appConfiguration.appDSL.nodes).filter(([key]) => {
                return !relatedLayoutIDs.includes(key);
              }),
            ),
          },
        },
      },
    };

    // next-page
    const allLayoutNodes = nodeListSelectors
      .getAllRouteNodesIncludeSystem(nodeListDSL)
      .filter(
        node => node.props.path.includes('/__layouts/__') && node.id !== 'BasePageRootRoute_ID',
      );
    const currentLayoutIndex = allLayoutNodes.findIndex(
      routeNode => routeNode.props.path === deletedRoutePath,
    );
    const withoutDeletedNodesNodeListDSL = dashboardSelectors.getNodeListDSL(newState);
    const allRouteNodesWithoutDeleted = nodeListSelectors.getAllRouteNodesIncludeSystem(
      withoutDeletedNodesNodeListDSL,
    );
    const allLayoutNodesWithoutDeleted = allRouteNodesWithoutDeleted.filter(
      node => node.props.path.includes('/__layouts/__') && node.id !== 'BasePageRootRoute_ID',
    );

    const nextRoute =
      currentLayoutIndex === 0
        ? allLayoutNodesWithoutDeleted[0]
        : allLayoutNodesWithoutDeleted[currentLayoutIndex - 1] ||
          allLayoutNodesWithoutDeleted[currentLayoutIndex + 1];
    newState = {
      ...newState,
      router: {
        ...newState.router,
        history: newState.router.history.filter(historyPath => historyPath !== deletedRoutePath),
        currentRoute: nextRoute?.props.path || '/',
      },
    };
  }

  return newState;
};
