import React, { useState } from 'react';

import styled from '@emotion/styled';
import { isNil, update, dissoc, isEmpty } from 'ramda';

import { JSInjection } from '@builder/schemas';
import { generateID } from '@builder/utils';

import { TextViewEditor } from 'src/features/node-settings/setting-views';
import { useTheme } from 'src/providers/ThemeProvider';
import { Icon } from 'src/shared/components';

const PairListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  grid-column-gap: ${({ theme }) => theme.spacing(1)};
`;

const PairListItemContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 24px;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const PairListItemTextField = styled.div`
  & .MuiFormControl-root {
    flex: auto;
  }
`;

const DeleteButtonContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ theme }) => theme.spacing(3)};
`;

type PairListProps = {
  firstColumnLabel?: string;
  secondColumnLabel?: string;
  items: PairListItem[];
  errors?: Partial<PairListItem>[];
  onChange: (items: PairListItem[]) => void;
  'data-test'?: string;
};

export type PairListItem = {
  key?: PairListItemValue;
  value?: PairListItemValue;
};

export type PairListItemValue = string | JSInjection;

type PairListItemKeys = keyof PairListItem;

type PairListItemWithID = PairListItem & {
  id: string;
};

const mapItemsWithID = (items: PairListItem[]): PairListItemWithID[] => {
  const emptyItem = {
    id: generateID(),
  };

  return [...items.map(item => ({ ...item, id: generateID() })), emptyItem];
};

const mapItemsWithoutID = (items: PairListItemWithID[]): PairListItem[] => {
  return items
    .map(item => dissoc<PairListItem>('id', item))
    .filter(itemWithoutID => !isEmpty(itemWithoutID));
};

export const mapObjectToPairListItems = (
  object: Record<string, PairListItemValue>,
): PairListItem[] => {
  return Object.entries(object).map(([key, value]) => ({
    key,
    value,
  }));
};

export const mapPairListItemsToObject = (
  items: PairListItem[],
): Record<string, PairListItemValue> => {
  return items.reduce((acc, curr) => {
    if (curr.key && !isNil(curr.value)) {
      // eslint-disable-next-line no-param-reassign
      acc[curr.key] = curr.value;
    }

    return acc;
  }, {} as Record<string, PairListItemValue>);
};

export const PairList: React.FC<PairListProps> = ({
  firstColumnLabel = 'Key',
  secondColumnLabel = 'Value',
  items,
  errors,
  onChange,
  'data-test': dataTest,
}) => {
  const theme = useTheme();
  const [itemsWithIDs, setItemsWithIDs] = useState<PairListItemWithID[]>(mapItemsWithID(items));

  const onItemChange = ({
    itemID,
    path,
    value,
  }: {
    itemID: string;
    path: PairListItemKeys;
    value: PairListItemValue;
  }) => {
    const targetItemIndex = itemsWithIDs.findIndex(item => item.id === itemID);
    const isLastItem = targetItemIndex === itemsWithIDs.length - 1;
    const updatedItem = {
      ...itemsWithIDs[targetItemIndex],
      [path]: value,
    };
    const updatedItems = update(targetItemIndex, updatedItem, itemsWithIDs);

    if (isLastItem) {
      const updatedItemsWithNewItem = [...updatedItems, { id: generateID() }];
      setItemsWithIDs(updatedItemsWithNewItem);
    } else {
      setItemsWithIDs(updatedItems);
    }

    onChange(mapItemsWithoutID(updatedItems));
  };

  const onItemDelete = (itemID: string) => () => {
    const withoutDeletedItem = itemsWithIDs.filter(item => item.id !== itemID);
    setItemsWithIDs(withoutDeletedItem);
    onChange(mapItemsWithoutID(withoutDeletedItem));
  };

  return (
    <PairListContent>
      {itemsWithIDs.map((item, index, array) => {
        const isLastItem = index === array.length - 1;
        return (
          <PairListItemContent key={item.id}>
            <PairListItemTextField>
              <TextViewEditor
                skipDebounce
                multiline={false}
                error={Boolean(errors?.[index]?.key)}
                helperText={errors?.[index]?.key}
                placeholder={firstColumnLabel}
                propValue={item.key}
                showFx={false}
                onChangePropValue={value =>
                  onItemChange({ itemID: item.id, path: 'key', value: value as PairListItemValue })
                }
                data-test={`${dataTest}.key`}
                data-enum={`${dataTest}.${index}.name`}
              />
            </PairListItemTextField>
            <PairListItemTextField>
              <TextViewEditor
                skipDebounce
                showFx={false}
                multiline={false}
                error={Boolean(errors?.[index]?.value)}
                helperText={errors?.[index]?.value}
                placeholder={secondColumnLabel}
                propValue={item.value}
                onChangePropValue={value =>
                  onItemChange({
                    itemID: item.id,
                    path: 'value',
                    value: value as PairListItemValue,
                  })
                }
                data-test={`${dataTest}.value`}
                data-enum={`${dataTest}.${index}.value`}
                data-key={`${dataTest}.${item.key}.value`}
              />
            </PairListItemTextField>

            {!isLastItem && (
              <DeleteButtonContent>
                <Icon
                  element="button"
                  name="delete"
                  color={theme.palette.grey[400]}
                  onClick={onItemDelete(item.id)}
                  data-enum={`${dataTest}.deleteBtn`}
                />
              </DeleteButtonContent>
            )}
          </PairListItemContent>
        );
      })}
    </PairListContent>
  );
};
