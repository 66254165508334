import { useEffect, useState } from 'react';
export const useStringState = (defaultState = '') => {
    // CODE_ENGINE_USAGE_START
    const [stringState, setStringState] = useState(defaultState);
    useEffect(() => {
        setStringState(defaultState);
    }, [defaultState]);
    return { value: stringState, setValue: setStringState };
    // CODE_ENGINE_USAGE_END
};
