import { createSelector } from 'reselect';
import { SchemaError, ERROR_SCOPES } from '@builder/utils';
import { ASSETS_ROOT_FOLDER_ID, ASSET_TYPES } from '../constants';
const getAssetDSL = (assetDSL) => assetDSL;
export const isFileAssetDSL = createSelector(getAssetDSL, (assetDSL) => {
    return assetDSL.type !== ASSET_TYPES.folder;
});
export const getAssetParentID = createSelector(getAssetDSL, assetDSL => {
    const { parentID } = assetDSL;
    if (!parentID && (assetDSL === null || assetDSL === void 0 ? void 0 : assetDSL.id) !== ASSETS_ROOT_FOLDER_ID) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Asset does not have parent ID ${parentID}. Every asset but root folder must have parentID.`);
    }
    return parentID;
});
export const getAssetChildren = createSelector(getAssetDSL, assetDSL => {
    if (assetDSL.type !== ASSET_TYPES.folder) {
        return undefined;
    }
    return assetDSL.children;
});
