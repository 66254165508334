import { pipe } from 'ramda';
import { assocSettings, getCommonFormFieldPropertiesSettings, NS_ID, getCommonFormFieldActionSettings, } from '../../common';
import { getBuilderComponentsRatingSettings } from '../../material-components/BuilderComponentsRating/BuilderComponentsRating.settings';
const basicRatingSettings = getBuilderComponentsRatingSettings(['ratingProps']);
const PREFIX_PATH = ['ratingProps'];
const basicBuilderComponentsRatingSettings = getBuilderComponentsRatingSettings(PREFIX_PATH);
export const BuilderComponentsFormRatingSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldPropertiesSettings(), ...settings.children] }))))(basicRatingSettings.properties),
    style: basicRatingSettings.style,
    events: pipe(assocSettings([NS_ID.events.root, NS_ID.events.events], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldActionSettings(), ...settings.children] }))))(basicBuilderComponentsRatingSettings.events),
};
