import { AssetsGenerator } from '../assets-generator';
import { ImportsGenerator } from '../imports-generator';
import { ASSETS_PROVIDER_CONST, USE_ASSETS_CONST } from './constants';
export class AssetsProvider {
    constructor({ appDSL, componentListDSL, assetBackendList, assetListDSL, useAssetDirectImports, }) {
        this.assetsGenerator = new AssetsGenerator({
            appDSL,
            assetBackendList,
            useAssetDirectImports,
        });
        this.importsGenerator = new ImportsGenerator({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
        this.useAssetDirectImports = useAssetDirectImports;
    }
    generateAssetsProvider() {
        return `
      import React, { createContext, useContext } from 'react';

      ${this.useAssetDirectImports ? this.importsGenerator.generateAssetImports() : ''}

      type AssetName = string;

      type Asset = Record<string, unknown>;

      type AssetList = Record<AssetName, Asset>;

      const AssetsContext = createContext<AssetList>({});

      const assets = ${this.assetsGenerator.generateAssetList()}

      export const ${ASSETS_PROVIDER_CONST}: React.FC = ({ children }) => {
        return (
          <AssetsContext.Provider value={assets}>
            { children }
          </AssetsContext.Provider>
        )
      }

      export const ${USE_ASSETS_CONST} = (): AssetList => {
        return useContext(AssetsContext);
      }
    `;
    }
}
