import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getBasicPropsSettings, getStylesSection } from '../../common';
import { getConditionSection, getDivider } from '../../common/settings';
export const MaterialDialogContentTextSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [getConditionSection(), getDivider(), ...getBasicPropsSettings()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
};
