import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_18_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.0';
    }
    getChangeList() {
        return ['Adding missing state array for layout wrapper'];
    }
    migrateAppDSL(appDSL) {
        const newDSL = Object.assign({}, appDSL);
        const nodes = Object.assign({}, appDSL.nodes);
        const newNodes = Object.entries(nodes).map(([nodeKey, nodeValue]) => {
            if ((nodeValue.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute &&
                nodeValue.props.system &&
                'incrementAlias' in nodeValue) ||
                nodeValue.name === COMPONENT_DSL_NAMES.BasePageLayout) {
                if (!nodeValue.states) {
                    return [nodeKey, Object.assign(Object.assign({}, nodeValue), { states: [] })];
                }
            }
            return [nodeKey, nodeValue];
        });
        return Object.assign(Object.assign({}, newDSL), { nodes: Object.fromEntries(newNodes) });
    }
}
export const migration_0_18_0 = new Migration_0_18_0();
