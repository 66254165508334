import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import actionsDSL from './style-guide-dsl/AlertStyleGuide/actions.json';
import basicAlertsDSL from './style-guide-dsl/AlertStyleGuide/basicAlerts.json';
import descriptionDSL from './style-guide-dsl/AlertStyleGuide/description.json';
import filledDSL from './style-guide-dsl/AlertStyleGuide/filled.json';
import variantsDSL from './style-guide-dsl/AlertStyleGuide/variants.json';
export const AlertStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiAlert,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialAlert,
    dirName: 'AlertStyleGuide',
    title: 'Alert',
    classNames: [
        '.MuiAlert-root',
        '.MuiAlert-filled',
        '.MuiAlert-outlined',
        '.MuiAlert-standard',
        '.MuiAlert-standardSuccess',
        '.MuiAlert-standardInfo',
        '.MuiAlert-standardWarning',
        '.MuiAlert-standardError',
        '.MuiAlert-outlinedSuccess',
        '.MuiAlert-outlinedInfo',
        '.MuiAlert-outlinedWarning',
        '.MuiAlert-outlinedError',
        '.MuiAlert-filledSuccess',
        '.MuiAlert-filledInfo',
        '.MuiAlert-filledWarning',
        '.MuiAlert-filledError',
        '.MuiAlert-icon',
        '.MuiAlert-message',
        '.MuiAlert-action',
    ],
    stories: [
        {
            title: 'Basic alerts',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAlert,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        severity: 'error',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an error alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'warning',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a warning alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'info',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an info alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'success',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a success alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: basicAlertsDSL,
        },
        {
            title: 'Variants',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAlert,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        severity: 'error',
                        variant: 'outlined',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an error alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'warning',
                        variant: 'outlined',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a warning alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'info',
                        variant: 'outlined',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an info alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'success',
                        variant: 'outlined',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a success alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: variantsDSL,
        },
        {
            title: 'Filled',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAlert,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        severity: 'error',
                        variant: 'filled',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an error alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'warning',
                        variant: 'filled',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a warning alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'info',
                        variant: 'filled',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an info alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'success',
                        variant: 'filled',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a success alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: filledDSL,
        },
        {
            title: 'Description',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAlert,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        severity: 'error',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAlertTitle,
                                    props: {
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.Text,
                                                    props: {
                                                        children: 'Error',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an error alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'success',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAlertTitle,
                                    props: {
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.Text,
                                                    props: {
                                                        children: 'Success',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a success alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: descriptionDSL,
        },
        {
            title: 'Actions',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAlert,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        severity: 'error',
                        onClose: {
                            actionType: 'functionCode',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is an error alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        severity: 'success',
                        onClose: {
                            actionType: 'functionCode',
                        },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'This is a success alert — check it out!',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: actionsDSL,
        },
    ],
};
