var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
export const Tooltip = forwardRef((_a, ref) => {
    var { children, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, className, lang, translate } = _a, tooltipProps = __rest(_a, ["children", 'data-test', 'data-node-id', 'data-node-render-path', "id", "className", "lang", "translate"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, className: className, lang: lang, translate: translate }, { children: _jsx(MuiTooltip, Object.assign({}, tooltipProps, { children: _jsx("div", { children: children }) })) })));
});
