import React, { useEffect, useMemo, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import { OFFSET, INITIAL_POSITION } from './GlobalTooltip.constants';
import { Tooltip } from './GlobalTooltip.styles';

type GlobalTooltipProps = {
  title: React.ReactNode;
  isVisible: boolean;
};

export const GlobalTooltip: React.FC<GlobalTooltipProps> = ({ title, isVisible }) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  /**
   * defines the position of the tooltip relative to the mouse cursor, by default bottom right
   * */
  const [isRightSide, setIsRightSide] = useState(true);
  const [isBottomSide, setIsBottomSide] = useState(true);
  /**
   * cursor position relative to the document
   * */
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>(INITIAL_POSITION);

  /**
   * position of the tooltip relative to the cursor in pixels along the x-axis
   * */
  const left = useMemo<number>(
    () =>
      isRightSide
        ? OFFSET + mousePosition.x
        : mousePosition.x - OFFSET - (tooltipRef.current?.getBoundingClientRect().width ?? 0),
    [isRightSide, mousePosition.x],
  );

  /**
   * position of the tooltip relative to the cursor in pixels along the y-axis
   * */
  const top = useMemo<number>(
    () =>
      isBottomSide
        ? OFFSET + mousePosition.y
        : mousePosition.y - OFFSET - (tooltipRef.current?.getBoundingClientRect().height ?? 0),
    [isBottomSide, mousePosition.y],
  );

  useEffect(
    /**
     * subscribe and unsubscribe to mouse movement events
     * */
    function subscribeToMouseMoveEvent() {
      const handleMouseMove = (event: MouseEvent) => {
        setMousePosition({ x: event.clientX, y: event.clientY });
      };

      if (isVisible) {
        document.addEventListener('mousemove', handleMouseMove);
      } else {
        setMousePosition(INITIAL_POSITION);
      }

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
      };
    },
    [isVisible],
  );

  useEffect(
    function toggleTooltipHorizontalPosition() {
      const tooltipWidth = tooltipRef.current?.offsetWidth ?? 0;
      const isTooltipOutsideBody = mousePosition.x + OFFSET + tooltipWidth > window.innerWidth;

      if (isTooltipOutsideBody) {
        setIsRightSide(false);
      } else {
        setIsRightSide(true);
      }
    },
    [mousePosition.x],
  );

  useEffect(
    function toggleTooltipVerticalPosition() {
      const tooltipHeight = tooltipRef.current?.offsetHeight ?? 0;
      const isTooltipOutsideBody = mousePosition.y + OFFSET + tooltipHeight > window.innerHeight;

      if (isTooltipOutsideBody) {
        setIsBottomSide(false);
      } else {
        setIsBottomSide(true);
      }
    },
    [mousePosition.y],
  );

  return createPortal(
    isVisible ? (
      <Tooltip ref={tooltipRef} left={left} top={top}>
        {title}
      </Tooltip>
    ) : null,
    document.body,
  );
};
