/* eslint-disable camelcase */
import { COMPONENT_DSL_NAMES, ROOT_NODE_ID, ROOT_LAYOUT_CONTAINER_NODE_ID, BASE_PAGE_ROUTER_SWITCH_NODE_ID, EMPTY_PAGE_LAYOUT_NODE_ID, EMPTY_PAGE_ROUTER_SWITCH_NODE_ID, EMPTY_PAGE_ROOT_ROUTE_NODE_ID, EMPTY_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID, ASSETS_ROOT_FOLDER_ID, COMPONENT_SCHEMAS, INITIAL_APP_VERSION, SCHEMA_DSL_VERSION, COMPONENT_DSL_INTERACTION_TYPES, ASSET_TYPES, ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID, ERROR_PAGE_ROOT_ROUTE_NODE_ID, NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID, NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID, } from '@builder/schemas';
export const emptyExample = {
    appDSL: {
        nodes: {
            [ROOT_NODE_ID]: {
                id: ROOT_NODE_ID,
                name: COMPONENT_DSL_NAMES.root,
                parentID: null,
                props: {
                    children: { nodes: [ROOT_LAYOUT_CONTAINER_NODE_ID] },
                },
                alias: COMPONENT_SCHEMAS[COMPONENT_DSL_NAMES.root].displayName,
                hiddenLayout: true,
            },
            [ROOT_LAYOUT_CONTAINER_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.RootLayoutsContainer,
                id: ROOT_LAYOUT_CONTAINER_NODE_ID,
                parentID: ROOT_NODE_ID,
                props: {
                    layouts: {
                        nodes: [EMPTY_PAGE_LAYOUT_NODE_ID],
                    },
                    redirects: { nodes: [] },
                },
                states: [],
                alias: COMPONENT_SCHEMAS[COMPONENT_DSL_NAMES.RootLayoutsContainer].displayName,
                hiddenLayout: true,
            },
            [EMPTY_PAGE_LAYOUT_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BaseLayout,
                id: EMPTY_PAGE_LAYOUT_NODE_ID,
                parentID: ROOT_LAYOUT_CONTAINER_NODE_ID,
                props: {
                    content: { nodes: [BASE_PAGE_ROUTER_SWITCH_NODE_ID] },
                },
                states: [],
                alias: 'Empty Layout',
                hiddenLayout: true,
            },
            [EMPTY_PAGE_LAYOUT_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BaseLayout,
                id: EMPTY_PAGE_LAYOUT_NODE_ID,
                parentID: ROOT_LAYOUT_CONTAINER_NODE_ID,
                props: {
                    content: { nodes: [EMPTY_PAGE_ROUTER_SWITCH_NODE_ID] },
                },
                states: [],
                hiddenLayout: true,
                alias: 'Empty Layout',
            },
            [EMPTY_PAGE_ROUTER_SWITCH_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.RouterSwitchSymbol,
                id: EMPTY_PAGE_ROUTER_SWITCH_NODE_ID,
                parentID: EMPTY_PAGE_LAYOUT_NODE_ID,
                schemaOverride: {
                    interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                },
                props: {
                    routes: {
                        nodes: [
                            EMPTY_PAGE_ROOT_ROUTE_NODE_ID,
                            ERROR_PAGE_ROOT_ROUTE_NODE_ID,
                            NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID,
                        ],
                    },
                    redirects: { nodes: [] },
                },
                states: [],
                hiddenLayout: true,
                alias: 'Empty Layout Page Switcher',
            },
            [EMPTY_PAGE_ROOT_ROUTE_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                id: EMPTY_PAGE_ROOT_ROUTE_NODE_ID,
                parentID: EMPTY_PAGE_ROUTER_SWITCH_NODE_ID,
                props: {
                    children: { nodes: [EMPTY_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID] },
                    path: '/',
                    authAccess: 'any',
                },
                states: [],
                alias: 'Home Page Wrapper',
            },
            [EMPTY_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                id: EMPTY_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID,
                parentID: EMPTY_PAGE_ROOT_ROUTE_NODE_ID,
                props: {
                    children: { nodes: [] },
                    style: {
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: '0%',
                        flexDirection: 'column',
                        height: '100%',
                    },
                },
                states: [],
                alias: 'Home Page',
            },
            [ERROR_PAGE_ROOT_ROUTE_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                id: ERROR_PAGE_ROOT_ROUTE_NODE_ID,
                parentID: EMPTY_PAGE_ROUTER_SWITCH_NODE_ID,
                props: {
                    children: { nodes: [ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID] },
                    path: '/500',
                    authAccess: 'any',
                },
                states: [],
                alias: 'Error Page Wrapper',
                schemaOverride: {
                    disableDelete: true,
                },
            },
            [ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                id: ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID,
                parentID: ERROR_PAGE_ROOT_ROUTE_NODE_ID,
                props: {
                    children: { nodes: [] },
                    style: {
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: '0%',
                        flexDirection: 'column',
                        height: '100%',
                    },
                },
                states: [],
                alias: 'Error Page',
            },
            [NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                id: NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID,
                parentID: EMPTY_PAGE_ROUTER_SWITCH_NODE_ID,
                props: {
                    children: { nodes: [NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID] },
                    path: '/404',
                    authAccess: 'any',
                },
                states: [],
                alias: 'Not Found Wrapper',
                schemaOverride: {
                    disableDelete: true,
                },
            },
            [NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID]: {
                name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                id: NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID,
                parentID: NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID,
                props: {
                    children: { nodes: [] },
                    style: {
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: '0%',
                        flexDirection: 'column',
                        height: '100%',
                    },
                },
                states: [],
                alias: 'Not Found Page',
            },
        },
        states: {
            errorState: {
                name: 'uncaughtErrors',
                type: 'array',
                scope: 'global',
                defaultValue: [{ message: '', error: '', code: '' }],
                id: 'errorState',
            },
        },
        theme: {
            framework: 'MUI',
            mediaQueries: {
                'x-small': {
                    id: 'x-small',
                    name: 'xSmall',
                    title: 'X Small',
                    max: '576',
                },
                'small-576-768': {
                    id: 'small-576-768',
                    name: 'small',
                    title: 'Small',
                    min: '576',
                    max: '768',
                },
                'medium-768-992': {
                    id: 'medium-768-992',
                    name: 'medium',
                    title: 'Medium',
                    min: '768',
                    max: '992',
                },
                'large-992-1200': {
                    id: 'large-992-1200',
                    name: 'large',
                    title: 'Large',
                    min: '992',
                    max: '1200',
                },
                'extra-large-1200-1400': {
                    id: 'extra-large-1200-1400',
                    name: 'extraLarge',
                    title: 'Extra Large',
                    min: '1200',
                    max: '1400',
                },
                'extra-extra-large-1400': {
                    id: 'extra-extra-large-1400',
                    name: 'extraExtraLarge',
                    title: 'Extra Extra Large',
                    min: '1400',
                },
            },
            fonts: {
                google: {},
                custom: {},
            },
            frameworkSettings: {
                name: 'custom',
                palette: {
                    primary: {
                        main: '#008DCE',
                        light: '#3BB4EB',
                        dark: '#006492',
                        contrastText: '#FFFFFF',
                    },
                    secondary: {
                        main: '#6EF1DB',
                        light: '#34CCB3',
                        dark: '#06816C',
                        contrastText: '#202020',
                    },
                    error: {
                        main: '#D14646',
                        contrastText: '#FFFFFF',
                    },
                    background: {
                        default: '#FFFFFF',
                        paper: '#FFFFFF',
                    },
                    text: {
                        primary: '#202020',
                    },
                },
                typography: {
                    fontSize: 14,
                },
                spacing: 8,
            },
            css: {
                mediaQueries: `@media only screen and (max-width: 576px) {}

@media only screen and (min-width: 576px) and (max-width: 768px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 992px) and (max-width: 1200px) {}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {}

@media only screen and (min-width: 1400px) {}`,
                global: ':root {\n  --primaryMain: #008DCE;\n --primaryLight: #3BB4EB;\n --primaryDark: #006492;\n --primaryContrastText: #fff;\n --secondaryMain: #6EF1DB;\n --secondaryLight: #34CCB3;\n --secondaryDark: #06816C;\n --secondaryContrastText: #fff;\n  --backgroundDefault: #fff;\n  --backgroundSurface: #fff;\n  --errorMain: #d32f2f;\n --errorContrastText: #fff;\n   --textBackgroundPrimary: #212121;\n}',
            },
        },
        settings: {
            view: {
                applyMinSizeStyles: true,
            },
            eject: {
                applyMinSizeStyles: false,
            },
        },
        assets: {
            [ASSETS_ROOT_FOLDER_ID]: {
                id: ASSETS_ROOT_FOLDER_ID,
                type: ASSET_TYPES.folder,
                parentID: null,
                name: 'assets',
                children: [],
            },
        },
        resources: {},
        routeHooks: {
            beforeEach: {
                name: 'BeforeEachGlobalHook',
                code: '// Your code here',
                scope: 'global',
            },
            afterEach: {
                name: 'AfterEachGlobalHook',
                code: '// Your code here',
                scope: 'global',
            },
        },
        libraries: {},
    },
    userComponentsDSL: {},
    appVersion: INITIAL_APP_VERSION,
    schemaDSLVersion: SCHEMA_DSL_VERSION,
};
