import React, { useCallback, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { InputAdornment } from '@mui/material';
import { equals } from 'ramda';

import { AppRuntimeStates } from '@builder/app-engine';
import { JSInjection, PossibleUnitTypes, POSSIBLE_UNIT_TYPES } from '@builder/schemas';

import { getCurrentValue, getInitialUnit, valueEndsWithUnit } from '../../utils';
import { AutocompleteOption, TextField } from 'src/shared/components';
import { EVENT_KEYS } from 'src/shared/constants';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';
import { useDoubleClickEditText } from 'src/shared/hooks';

import { HTMLInput } from './HTMLInput';

type ValueType = string | JSInjection | undefined;

export type HTMLViewEditorProps = {
  targetNodeId?: string;
  name?: string;
  label?: string;
  propValue: ValueType;
  error?: boolean;
  helperText?: React.ReactNode;
  onChangePropValue: (propValue: ValueType, eventType?: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  nodeID?: string;
  possibleUnits?: PossibleUnitTypes[];
  skipDebounce?: boolean;
  placeholder?: string;
  multiline?: boolean;
  autoFocus?: boolean;
  'data-test'?: string;
  'data-enum'?: string;
  'data-key'?: string;
  hideExpandableButton?: boolean;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  options?: Array<AutocompleteOption<string | number>>;
  localStates?: AppRuntimeStates | undefined;
};

const UnitWrapper = styled.span`
  color: ${({ theme }) => theme.palette.grey[400]};
  cursor: pointer;
`;

const changePropsValue = (
  unit: PossibleUnitTypes | undefined,
  value: ValueType,
  callback: (propValue: ValueType, eventType?: string) => void,
  eventType?: string,
) => {
  if (!unit || unit.type === POSSIBLE_UNIT_TYPES.value) {
    return callback(value, eventType);
  }

  if (value) {
    if (value.endsWith(unit.value)) {
      return callback(value, eventType);
    }

    const newValue = `${value}${unit.value}`;
    return callback(newValue, eventType);
  }

  callback(undefined);
};

export const HTMLViewEditor: React.FC<HTMLViewEditorProps> = ({
  targetNodeId,
  name,
  label,
  propValue,
  onChangePropValue,
  possibleUnits,
  skipDebounce = false,
  placeholder,
  multiline,
  error,
  helperText,
  autoFocus,
  onKeyDown,
  'data-test': dataTest,
  'data-enum': dataEnum,
  'data-key': dataKey,
  showFx = true,
  fxDefaultEnabled = ACTIVEPROP.literal,
  localStates,
}) => {
  const [currentUnit, setCurrentUnit] = useState<PossibleUnitTypes | undefined>(
    getInitialUnit(propValue, possibleUnits),
  );
  const [, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const currentValue = getCurrentValue(propValue, currentUnit);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useDoubleClickEditText<React.MutableRefObject<HTMLInputElement | null>>({
    targetNodeId: targetNodeId as string,
    inputLabel: label as string,
    ref: inputRef,
  });

  const handleUpdatePropChange = useCallback(
    (value: ValueType, eventType?: string) => {
      const newPossibleUnit = valueEndsWithUnit(value, possibleUnits);

      if (
        currentUnit?.type !== POSSIBLE_UNIT_TYPES.value &&
        newPossibleUnit &&
        !equals(newPossibleUnit, currentUnit)
      ) {
        setCurrentUnit(newPossibleUnit);
        return onChangePropValue(value, eventType);
      }

      changePropsValue(currentUnit, value, onChangePropValue, eventType);
    },
    [currentUnit, onChangePropValue, possibleUnits],
  );

  const openMenu = useCallback((event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case EVENT_KEYS.ARROW_DOWN:
      case EVENT_KEYS.ARROW_UP: {
        if (!currentValue) {
          break;
        }

        const direction = event.key === EVENT_KEYS.ARROW_UP ? 1 : -1;
        const transformedValue = Number(currentValue);

        if (!isNaN(transformedValue)) {
          changePropsValue(currentUnit, String(transformedValue + direction), onChangePropValue);
        }
      }
    }

    onKeyDown?.(event);
  };

  return (
    <>
      <HTMLInput
        label={label ?? ''}
        onChangePropValue={handleUpdatePropChange}
        nodeID={targetNodeId}
        propValue={currentValue}
        data-test={dataTest}
        fxDefaultEnabled={fxDefaultEnabled}
        localStates={localStates}
      >
        {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
          <TextField
            inputRef={inputRef}
            fullWidth
            variant="outlined"
            isFxEnabled={isFxEnabled}
            enableFx={enableFx}
            size="small"
            name={name}
            showFx={showFx}
            label={label}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            autoFocus={autoFocus}
            multiline={multiline}
            value={currentValue ?? nonJsCodePropValue}
            skipDebounce={skipDebounce}
            onKeyDown={handleKeyDown}
            onChange={event =>
              handleUpdatePropChange(event?.currentTarget?.value ?? event, 'change')
            }
            onBlur={event => handleUpdatePropChange(event?.currentTarget?.value ?? event, 'blur')}
            onDebounceChange={event => {
              handleUpdatePropChange(event, 'change');
            }}
            data-test={dataTest}
            data-enum={dataEnum}
            data-key={dataKey}
            absoluteEndAdornmentPosition={!currentUnit}
            noCode
            InputProps={{
              endAdornment: currentUnit && (
                <InputAdornment position="end">
                  <UnitWrapper onClick={openMenu}>
                    {!currentUnit.value ? '-' : currentUnit.value}
                  </UnitWrapper>
                </InputAdornment>
              ),
            }}
          />
        )}
      </HTMLInput>
    </>
  );
};
