import { isEmpty } from 'ramda';
import { hasPropJsCode, transformPropWithJsCode } from '@builder/schemas';
import { ERROR_SCOPES, isBoolean, SchemaValidationError, serialize } from '@builder/utils';
import { ROLES_STATE_NAME } from '../../app-states-generator/predefined-state/constants';
const transformShowIfCondition = (showIfConditionValue) => {
    if (!showIfConditionValue) {
        return '';
    }
    if (isBoolean(showIfConditionValue)) {
        return showIfConditionValue.toString();
    }
    if (!hasPropJsCode(showIfConditionValue)) {
        throw new SchemaValidationError(ERROR_SCOPES.appEngine, `Condition value should be JS code, but got: ${serialize.stringify(showIfConditionValue)}`);
    }
    return transformPropWithJsCode(showIfConditionValue);
};
const transformAllowedRolesCondition = (allowedRoles) => {
    if (isEmpty(allowedRoles)) {
        return '';
    }
    return `${ROLES_STATE_NAME}.hasPermission(${serialize.stringify(allowedRoles)})`;
};
export const transformCondition = (showIfConditionValue, allowedRoles) => {
    const transformedShowIfCondition = transformShowIfCondition(showIfConditionValue);
    const transformedAllowedRolesCondition = transformAllowedRolesCondition(allowedRoles);
    if (transformedShowIfCondition && transformedAllowedRolesCondition) {
        return `${transformedShowIfCondition} && ${transformedAllowedRolesCondition}`;
    }
    return transformedShowIfCondition || transformedAllowedRolesCondition;
};
