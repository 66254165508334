import { ComponentSettingDividerDSL, ComponentSettingDSL } from '@builder/schemas';

import { deepEqual } from 'src/shared/utils';

const compareOptions = (
  a: ComponentSettingDSL | ComponentSettingDividerDSL,
  b: ComponentSettingDSL | ComponentSettingDividerDSL,
): boolean => {
  if ('options' in a && 'options' in b) {
    const areOptionsEqual = deepEqual(a.options, b.options);

    return areOptionsEqual;
  }

  return false;
};

export const isSettingEqual = (
  a: ComponentSettingDSL | ComponentSettingDividerDSL,
  b: ComponentSettingDSL | ComponentSettingDividerDSL,
): boolean => {
  const itemsWithOptions = 'options' in a && 'options' in b;

  if (itemsWithOptions) {
    return compareOptions(a, b);
  }

  if (a.name && b.name && a.name === b.name && a?.type === b?.type) {
    return true;
  }

  if (a.type === 'divider' && b.type === 'divider') return true;

  if ('title' in a && 'title' in b) {
    return a.title === b.title;
  }

  if ('label' in a && 'label' in b) {
    if (a.label === 'Id' && b.label === 'Id') return false;

    return a.label === b.label;
  }

  return false;
};
