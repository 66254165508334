import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basicChipDSL from './style-guide-dsl/ChipStyleGuide/basicChip.json';
import clickableDSL from './style-guide-dsl/ChipStyleGuide/clickable.json';
import deletableDSL from './style-guide-dsl/ChipStyleGuide/deletable.json';
import sizeDSL from './style-guide-dsl/ChipStyleGuide/size.json';
export const ChipStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiChip,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialChip,
    dirName: 'ChipStyleGuide',
    title: 'Chip',
    classNames: [
        '.MuiChip-root',
        '.MuiChip-sizeSmall',
        '.MuiChip-sizeMedium',
        '.MuiChip-colorPrimary',
        '.MuiChip-colorSecondary',
        '.Mui-disabled',
        '.MuiChip-clickable',
        '.MuiChip-clickableColorPrimary',
        '.MuiChip-clickableColorSecondary',
        '.MuiChip-deletable',
        '.MuiChip-deletableColorPrimary',
        '.MuiChip-deletableColorSecondary',
        '.MuiChip-outlined',
        '.MuiChip-filled',
        '.MuiChip-outlinedPrimary',
        '.MuiChip-outlinedSecondary',
        '.MuiChip-avatar',
        '.MuiChip-avatarSmall',
        '.MuiChip-avatarMedium',
        '.MuiChip-avatarColorPrimary',
        '.MuiChip-avatarColorSecondary',
        '.MuiChip-icon',
        '.MuiChip-iconSmall',
        '.MuiChip-iconMedium',
        '.MuiChip-iconColorPrimary',
        '.MuiChip-iconColorSecondary',
        '.MuiChip-label',
        '.MuiChip-labelSmall',
        '.MuiChip-labelMedium',
        '.MuiChip-deleteIcon',
        '.MuiChip-deleteIconSmall',
        '.MuiChip-deleteIconMedium',
        '.MuiChip-deleteIconColorPrimary',
        '.MuiChip-deleteIconColorSecondary',
        '.MuiChip-deleteIconOutlinedColorPrimary',
        '.MuiChip-deleteIconOutlinedColorSecondary',
        '.Mui-focusVisible',
    ],
    stories: [
        {
            title: 'Basic chip',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialChip,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'success',
                    },
                },
                {
                    props: {
                        color: 'error',
                        variant: 'outlined',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: basicChipDSL,
        },
        {
            title: 'Clickable',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialChip,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'success',
                        onClick: {
                            actionType: 'functionCode',
                        },
                    },
                },
                {
                    props: {
                        color: 'error',
                        variant: 'outlined',
                        onClick: {
                            actionType: 'functionCode',
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: clickableDSL,
        },
        {
            title: 'Deletable',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialChip,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'success',
                        onDelete: {
                            actionType: 'functionCode',
                        },
                    },
                },
                {
                    props: {
                        color: 'error',
                        variant: 'outlined',
                        onDelete: {
                            actionType: 'functionCode',
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: deletableDSL,
        },
        {
            title: 'Size',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialChip,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'success',
                        size: 'small',
                    },
                },
                {
                    props: {
                        color: 'error',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: sizeDSL,
        },
    ],
};
