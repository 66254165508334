var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, RESET_STATUS } from '../common';
import { Switch } from '../Switch';
export const FormSwitch = forwardRef((_a, ref) => {
    var { switchProps, fieldProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, value, autofocus, id, title, className, lang, translate, onChange } = _a, rest = __rest(_a, ["switchProps", "fieldProps", 'data-test', 'data-node-id', 'data-node-render-path', "value", "autofocus", "id", "title", "className", "lang", "translate", "onChange"]);
    const { setFieldValue, initialValues, status, setStatus, values } = useFormikContext();
    const initialValue = typeof initialValues[fieldProps.name] === 'boolean' ? initialValues[fieldProps.name] : false;
    useEffect(() => {
        var _a;
        const updateStateEvent = {
            target: {
                checked: initialValue,
            },
        };
        setFieldValue(fieldProps.name, initialValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(updateStateEvent);
        (_a = switchProps === null || switchProps === void 0 ? void 0 : switchProps.onChange) === null || _a === void 0 ? void 0 : _a.call(switchProps, updateStateEvent, initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.name, initialValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            const updateStateEvent = {
                target: {
                    checked: initialValue,
                },
            };
            setFieldValue(fieldProps.name, initialValue);
            onChange === null || onChange === void 0 ? void 0 : onChange(updateStateEvent);
            (_a = switchProps === null || switchProps === void 0 ? void 0 : switchProps.onChange) === null || _a === void 0 ? void 0 : _a.call(switchProps, updateStateEvent, initialValue);
        }
    }, [switchProps, status, setStatus, onChange, fieldProps.name, setFieldValue, initialValue]);
    useEffect(() => {
        if (switchProps.value != null &&
            onChange != null &&
            values[fieldProps.name] !== switchProps.checked) {
            setFieldValue(fieldProps.name, switchProps.checked);
        }
    }, [fieldProps.name, onChange, setFieldValue, switchProps.checked, switchProps.value, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(Switch, Object.assign({}, rest, switchProps, { ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, checked: field.value, onChange: (event, checked) => {
                    form.setFieldValue(fieldProps.name, checked);
                    onChange === null || onChange === void 0 ? void 0 : onChange(event);
                }, name: fieldProps.name, error: isInvalid, helperText: errorText, autoFocus: autofocus, value: field.value || value, id: id, title: title, className: className, lang: lang, translate: translate })));
        } })));
});
