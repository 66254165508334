var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, RESET_STATUS } from '../common';
import { Rating } from '../Rating';
export const FormRating = forwardRef((_a, ref) => {
    var { fieldProps, ratingProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, className, translate, onChange = () => { } } = _a, rest = __rest(_a, ["fieldProps", "ratingProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "className", "translate", "onChange"]);
    const { setFieldValue, status, setStatus, initialValues, values } = useFormikContext();
    const initialValue = initialValues[fieldProps.name] || ratingProps.defaultValue;
    useEffect(() => {
        var _a;
        setFieldValue(fieldProps.name, initialValue);
        (_a = ratingProps === null || ratingProps === void 0 ? void 0 : ratingProps.onChange) === null || _a === void 0 ? void 0 : _a.call(ratingProps, {
            target: {
                value: initialValue,
            },
        }, initialValue || 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.name, initialValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            setFieldValue(fieldProps.name, initialValue);
            (_a = ratingProps === null || ratingProps === void 0 ? void 0 : ratingProps.onChange) === null || _a === void 0 ? void 0 : _a.call(ratingProps, {
                target: {
                    value: initialValue,
                },
            }, initialValue || 0);
        }
    }, [fieldProps.name, initialValue, ratingProps, setFieldValue, setStatus, status]);
    useEffect(() => {
        if (ratingProps.value != null &&
            ratingProps.onChange != null &&
            ratingProps.value !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, ratingProps.value);
        }
    }, [fieldProps.name, ratingProps.onChange, ratingProps.value, setFieldValue, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(Rating, Object.assign({}, rest, ratingProps, { id: id, name: fieldProps.name, value: field.value || (ratingProps === null || ratingProps === void 0 ? void 0 : ratingProps.value) || 0.0, onChange: (event, value) => {
                    var _a;
                    field.onChange(event);
                    setFieldValue(fieldProps.name, value || 0);
                    (_a = ratingProps === null || ratingProps === void 0 ? void 0 : ratingProps.onChange) === null || _a === void 0 ? void 0 : _a.call(ratingProps, event, value || 0);
                }, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, title: title, className: className, lang: lang, translate: translate, error: isInvalid, helperText: errorText })));
        } })));
});
