const FILEPATH_PARTS_REGEX = /^.*[\\/]/;
/**
 * @description trims the directories from filepath
 * @param filepath - relative or absolute path to the file
 * @returns filename.ext without parent directories
 * --------
 * @example "../folder/filename.ext" -> "filename.ext"
 */
export const getFileNameFromPath = (filepath) => {
    if (!filepath) {
        return null;
    }
    return filepath.replace(FILEPATH_PARTS_REGEX, '');
};
