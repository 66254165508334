import { useMemo } from 'react';

import { MigrationList } from '@builder/migrations';
import { AppConfiguration, SchemaDSLVersion } from '@builder/schemas';

import { useAppConfiguration } from 'src/providers/ReduxProvider';

type UseMigrationsReturnType = {
  schemaDSLVersion: SchemaDSLVersion;
  isDSLVersionMoreThanExpected: boolean;
  needsToMigrate: boolean;
};

const migrationList = new MigrationList();

export const useMigrations = (
  targetAppConfiguration?: AppConfiguration,
): UseMigrationsReturnType => {
  const currentAppConfiguration = useAppConfiguration();
  const appConfiguration = targetAppConfiguration || currentAppConfiguration;

  // Memoize the result using React's useMemo hook
  const result = useMemo(() => {
    const schemaDSLVersion = migrationList.getSchemaDSLVersion();
    const isDSLVersionMoreThanExpected = migrationList.isDSLVersionMoreThanExpected(
      appConfiguration,
    );
    const needsToMigrate = migrationList.needsToMigrate(appConfiguration);

    return {
      schemaDSLVersion,
      isDSLVersionMoreThanExpected,
      needsToMigrate,
    };
  }, [appConfiguration]);

  return result;
};
