import {
  ColorPartial,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material/styles/createPalette';
import { isEmpty, isNil, path } from 'ramda';

import {
  ReactNodePropValue,
  NodeDSL,
  NodeID,
  PropChecker,
  NodePropValue,
  PropAssert,
  NodeListDSL,
  nodeListSelectors,
} from '@builder/schemas';
import { isNumber, isObject } from '@builder/utils';

import { PARENT } from 'src/shared/constants';

export type StyleObject = {
  [key: string]: string | number;
};

// TODO: move all this to selectors & checkers

export const assertIsNotPage = (id: string | null): string => {
  if (id === null) {
    throw new Error('Page id is not allowed here');
  }

  return id;
};

export const parseReactNode = (propValue: NodePropValue): ReactNodePropValue => {
  if (PropChecker.Value.isEmptyProp(propValue)) {
    return { nodes: [] };
  }

  PropAssert.Value.assertIsReactNodeProp(propValue);

  return propValue;
};

/**
 * @deprecated seems like a node-list selector
 */
export const findParents = (nodeID: NodeID, nodeListDSL: NodeListDSL): NodeDSL[] => {
  const parents: NodeDSL[] = [];
  let node: NodeDSL | null = nodeListDSL[nodeID];
  while (node && node.parentID !== null) {
    node = nodeListDSL[node.parentID];
    parents.push(node);
  }

  return parents;
};

export const getTargetNodeID = ({
  nodeID,
  nodePath,
  nodeListDSL,
}: {
  nodeID: NodeID;
  nodePath: Array<string | number>;
  nodeListDSL: NodeListDSL;
}): string => {
  let targetNodeID = nodeID;
  if (isEmpty(nodePath)) {
    return targetNodeID;
  }

  for (let i = 0; i < nodePath.length; i++) {
    const currentKey = nodePath[i];

    if (currentKey === PARENT) {
      const targetParentNode = nodeListSelectors.getParentNodeDSL(nodeListDSL, {
        nodeID: targetNodeID,
      });
      if (targetParentNode) {
        targetNodeID = targetParentNode?.id;
      }
    } else if (typeof currentKey === 'string') {
      const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: targetNodeID });
      let childIndexPath = i + 1;
      let childIndex = nodePath[childIndexPath];
      const additionalPath = [];

      while (!isNumber(childIndex) && childIndexPath < nodePath.length - 1) {
        additionalPath.push(childIndex);
        childIndexPath += 1;
        childIndex = nodePath[childIndexPath];
        i += 1;
      }

      const newTargetNodeID = path<string | undefined>(
        ['props', currentKey, ...additionalPath, 'nodes', childIndex],
        nodeDSL,
      );

      if (newTargetNodeID) {
        targetNodeID = newTargetNodeID;
      }
    }
  }

  return targetNodeID;
};

/**
 * Need to remove empty object in palette because of material ui throw an error in this case.
 * @example
 * {
 *   palette: {
 *     primary: {
 *       main: '',
 *     },
 *   },
 *   typography: {
 *     fontSize: 14,
 *   },
 * }
 * =>
 * {
 *   typography: {
 *     fontSize: 14,
 *   },
 * }
 * */
export const getTransformedPalette = (palette: PaletteOptions): PaletteOptions => {
  const transformedPalette: PaletteOptions = Object.keys(palette).reduce((acc, paletteKey) => {
    const paletteValue = palette[paletteKey as keyof PaletteOptions];

    if (isNil(paletteValue)) {
      return acc;
    }

    if (
      (isObject(paletteValue) && isEmpty((paletteValue as SimplePaletteColorOptions).main)) ||
      isEmpty((paletteValue as ColorPartial)[500])
    ) {
      return acc;
    }

    return isEmpty(paletteValue) ? acc : { ...acc, [paletteKey]: paletteValue };
  }, {} as PaletteOptions);

  return transformedPalette;
};

export const convertReactStyleToCSS = (styleObject: StyleObject): string => {
  let cssString = '';
  for (const key in styleObject) {
    if (Object.hasOwnProperty.call(styleObject, key)) {
      const cssKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      const cssValue = styleObject[key];
      cssString += `${cssKey}:${cssValue};\n`;
    }
  }

  return cssString.trimEnd();
};

export const getLiteralStyleValues = (
  styleObject: StyleObject,
): { literalStyle: StyleObject; rest: StyleObject } => {
  const literalStyle: StyleObject = {};
  const rest: StyleObject = {};

  if (!styleObject || typeof styleObject !== 'object') {
    return { literalStyle: {}, rest: {} };
  }

  for (const [key, value] of Object.entries(styleObject)) {
    if (typeof value === 'string') {
      if (value.includes('{{') && value.includes('}}')) {
        rest[key] = value;
      } else {
        literalStyle[key] = value;
      }
    } else {
      rest[key] = value;
    }
  }

  return { literalStyle, rest };
};
