import { pipe } from 'ramda';
import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, DISPLAY_OPTION_VALUES, NS_ID, assocSettings, disableRadioOptions, disableSpacingPaddings, getBasicPropsSettings, pickSettings, trimDividers, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
export const getBuilderComponentsDateTimePickerSettings = (prefixPath = []) => {
    return {
        properties: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.properties.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        name: NS_ID.properties.basic,
                        titleDescription: BASIC_PROPS_TOOLTIP,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'label'],
                                label: 'Label',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'views'],
                                label: 'Views',
                                skipDebounce: false,
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'value'],
                                label: 'Value',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'inputFormat'],
                                label: 'Input format',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'mask'],
                                label: 'Mask',
                                showIf: [
                                    {
                                        keyPath: [...prefixPath, 'inputFormat'],
                                        type: COMPONENT_SETTING_CONDITION_TYPES.isNotNil,
                                    },
                                    {
                                        keyPath: [...prefixPath, 'rifmFormatter'],
                                        type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                                    },
                                ],
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'acceptRegex'],
                                label: 'Accept regex',
                                showIf: [
                                    {
                                        keyPath: [...prefixPath, 'inputFormat'],
                                        type: COMPONENT_SETTING_CONDITION_TYPES.isNotNil,
                                    },
                                ],
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'rifmFormatter'],
                                label: 'Rifm formatter',
                                showIf: [
                                    {
                                        keyPath: [...prefixPath, 'mask'],
                                        type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                                    },
                                ],
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'ampm'],
                                label: 'AM/PM',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'ampmInClock'],
                                label: 'AM/PM In Clock',
                                showIf: [
                                    {
                                        keyPath: [...prefixPath, 'ampm'],
                                        type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                        value: true,
                                    },
                                ],
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'closeOnSelect'],
                                label: 'Close on select',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'dayOfWeekFormatter'],
                                label: 'Day of week formatter',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'shouldDisableDate'],
                                label: 'Should disable date',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'shouldDisableMonth'],
                                label: 'Should disable month',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'shouldDisableTime'],
                                label: 'Should disable time',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'shouldDisableYear'],
                                label: 'Should disable year',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'readOnly'],
                                label: 'Read only',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disabled'],
                                label: 'Disabled',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disableFuture'],
                                label: 'Disable future',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disableHighlightToday'],
                                label: 'Disable highlight today',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disableMaskedInput'],
                                label: 'Disable masked input',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disableOpenPicker'],
                                label: 'Disable open picker',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disablePast'],
                                label: 'Disable past',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'loading'],
                                label: 'Loading',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'defaultCalendarMonth'],
                                label: 'Default calendar month',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'maxDate'],
                                label: 'Max date',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'maxDateTime'],
                                label: 'Max date time',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'maxTime'],
                                label: 'Max time',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'minDate'],
                                label: 'Min date',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'minDateTime'],
                                label: 'Min date time',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'minTime'],
                                label: 'Min time',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disableIgnoringDatePartForTimeValidation'],
                                label: 'Disable ignoring date for time validation',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.number,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'minutesStep'],
                                label: 'Minutes step',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'openTo'],
                                label: 'Open To',
                                options: ['day', 'hours', 'minutes', 'month', 'seconds', 'year'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'showDaysOutsideCurrentMonth'],
                                label: 'Show days outside current month',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'showToolbar'],
                                label: 'Show toolbar',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'desktopModeMediaQuery'],
                                label: 'Desktop mode media query',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'toolbarFormat'],
                                label: 'Toolbar format',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'toolbarPlaceholder'],
                                label: 'Toolbar placeholder',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'toolbarTitle'],
                                label: 'Toolbar title',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'helperText'],
                                label: 'Helper text',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'error'],
                                label: 'Error',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'reduceAnimations'],
                                label: 'Reduce animations',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'getClockLabelText'],
                                label: 'Get clock label text',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'getOpenDialogAriaText'],
                                label: 'Get open dialog aria text',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                type: COMPONENT_SETTING_TYPES.prop,
                                hasFxButton: true,
                                keyPath: [...prefixPath, 'getViewSwitchingButtonText'],
                                label: 'Get view switch button text',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'leftArrowButtonText'],
                                label: 'Left arrow button text',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'rightArrowButtonText'],
                                label: 'Right arrow button text',
                            },
                        ],
                    },
                    getDivider(),
                    ...getBasicPropsSettings(),
                    getDivider(),
                    ...getPropertySections(),
                ],
            },
        ],
        style: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.style.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        titleDescription: BASIC_STYLE_TOOLTIP,
                        name: NS_ID.style.basic,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'inputVariant'],
                                label: 'Input Variant',
                                options: ['standard', 'outlined', 'filled'].map(createSelectOptionByValue),
                            },
                        ],
                    },
                    getDivider(),
                    ...pipe(assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], disableSpacingPaddings), assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])), pickSettings([
                        [NS_ID.style.spacing.root],
                        [NS_ID.style.display.root],
                        [NS_ID.style.flexChild.root],
                        [NS_ID.style.gridChild.root],
                        [NS_ID.style.font.root, NS_ID.style.font.color],
                        [NS_ID.style.font.root, NS_ID.style.font.fontFamily],
                        [NS_ID.style.font.root, NS_ID.style.font.fontSize],
                        [NS_ID.style.font.root, NS_ID.style.font.fontWeight],
                        [NS_ID.style.font.root, NS_ID.style.font.textAlign],
                        [NS_ID.style.font.root, NS_ID.style.font.textDecoration],
                        [NS_ID.style.font.root, NS_ID.style.font.textTransform],
                        [NS_ID.style.size.root, NS_ID.style.size.width],
                        [NS_ID.style.size.root, NS_ID.style.size.height],
                        [NS_ID.style.background.root],
                        [NS_ID.style.border.root],
                        [NS_ID.style.css.root],
                    ]), trimDividers)(getStyleSections(prefixPath)),
                ],
            },
        ],
        events: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.events.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.eventsSection,
                        name: NS_ID.events.events,
                        title: 'Events',
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onChangeAction',
                                label: 'On Change',
                                keyPath: [...prefixPath, 'onChange'],
                                children: getCommonActionSettings([...prefixPath, 'onChange']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onAcceptAction',
                                label: 'On Accept',
                                keyPath: [...prefixPath, 'onAccept'],
                                children: getCommonActionSettings([...prefixPath, 'onAccept']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onCloseAction',
                                label: 'On Close',
                                keyPath: [...prefixPath, 'onClose'],
                                children: getCommonActionSettings([...prefixPath, 'onClose']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onErrorAction',
                                label: 'On Error',
                                keyPath: [...prefixPath, 'onError'],
                                children: getCommonActionSettings([...prefixPath, 'onError']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onMonthChangeAction',
                                label: 'On Month Change',
                                keyPath: [...prefixPath, 'onMonthChange'],
                                children: getCommonActionSettings([...prefixPath, 'onMonthChange']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onOpenAction',
                                label: 'On Open',
                                keyPath: [...prefixPath, 'onOpen'],
                                children: getCommonActionSettings([...prefixPath, 'onOpen']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onViewChange',
                                label: 'On view change',
                                keyPath: [...prefixPath, 'onViewChange'],
                                children: getCommonActionSettings([...prefixPath, 'onViewChange']),
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onYearChangeAction',
                                label: 'On Year Change',
                                keyPath: [...prefixPath, 'onYearChange'],
                                children: getCommonActionSettings([...prefixPath, 'onYearChange']),
                            },
                            ...getCommonActionListSettings(),
                        ],
                    },
                ],
            },
        ],
    };
};
export const BuilderComponentsDateTimePickerSettings = getBuilderComponentsDateTimePickerSettings();
