import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { commonComponentProps } from '../../common/commonProps';
import { BLOCKQUOTE_SETTINGS } from './blockquote.settings';
export const blockquote = {
    name: COMPONENT_DSL_NAMES.blockquote,
    displayName: 'Quote',
    icon: 'quote',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'blockquote',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign({}, commonComponentProps),
    },
    settings: BLOCKQUOTE_SETTINGS,
};
