import React from 'react';

import { Avatar, AvatarGroup } from '@mui/material';

import { reactMemo, getUserInitials } from '@builder/utils';

import { TeamMemberWithColor } from 'src/store';

type ActiveMembersProps = {
  maxAvatars: number;
  avatarSize: number;
  activeMembers: Record<string, TeamMemberWithColor>;
  additionalAvatarsPopupProps: Record<string, unknown>;
  userDetailsPopupProps: Record<string, unknown>;
  userDetailsOnMouseOver: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    member: TeamMemberWithColor,
  ) => void;
};

export const ActiveMembers: React.FC<ActiveMembersProps> = reactMemo(
  'ActiveMembers',
  ({
    maxAvatars,
    avatarSize,
    additionalAvatarsPopupProps,
    activeMembers,
    userDetailsOnMouseOver,
    userDetailsPopupProps,
  }: ActiveMembersProps) => {
    return (
      <>
        <AvatarGroup
          max={maxAvatars}
          componentsProps={{
            additionalAvatar: {
              sx: { height: avatarSize, width: avatarSize },
              style: { backgroundColor: '#70777E', color: '#fff', outline: '2px solid #fff' },
              ...additionalAvatarsPopupProps,
            },
          }}
        >
          {Object.entries(activeMembers).map(([sessionId, member]) => {
            const initials = getUserInitials(member ?? {});
            return (
              <Avatar
                key={sessionId}
                alt={member.email}
                src={member.avatar?.downloadUrl || ''}
                sx={{ height: avatarSize, width: avatarSize }}
                {...userDetailsPopupProps}
                onMouseOver={event => userDetailsOnMouseOver(event, member)}
                style={{ backgroundColor: '#70777E', color: '#fff' }}
                css={{ outline: `2px solid ${member.color}` }}
              >
                {initials}
              </Avatar>
            );
          })}
        </AvatarGroup>
      </>
    );
  },
);
