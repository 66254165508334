export const APP_URL = {
  root: '/',
  public: {
    auth: {
      root: '/auth',
      callback: '/auth/callback',
    },
  },
  workspace: {
    root: '/workspace/:workspaceID',
    dashboard: '/workspace/:workspaceID/dashboard',
    preview: '/workspace/:workspaceID/preview',
  },
};

export const getWorkspaceDashboardUrl = ({ workspaceID }: { workspaceID: string }): string => {
  return APP_URL.workspace.dashboard.replace(':workspaceID', workspaceID);
};
