import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import ReactMonacoEditor, { EditorProps, Monaco } from '@monaco-editor/react';
import {
  GraphQLSchema,
  IntrospectionQuery,
  getIntrospectionQuery,
  buildClientSchema,
} from 'graphql';
import { Position, getAutocompleteSuggestions } from 'graphql-language-service';
import { editor as MonacoEditorType } from 'monaco-editor';
import * as monaco from 'monaco-editor';

import { StateListDSL, NodeDSL, ResourceDSL } from '@builder/schemas';
import { useBoolState } from '@builder/utils';

import { getMonacoEditorBorderHighlightingStyles } from 'src/shared/styles/monaco';

const MonacoEditorContent = styled.div<{
  isFocused: boolean;
  withBorders: boolean;
  error?: boolean;
}>`
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;

  ${({ theme, isFocused, withBorders, error }) =>
    getMonacoEditorBorderHighlightingStyles(theme, { isFocused, error, withBorders })}
`;

export interface IGraphQLQuery {
  url: string;
  variables: string;
  query: string;
}

export type MonacoEditorProps = EditorProps & {
  appStatesDSL?: StateListDSL;
  iterableNodeListDSL?: NodeDSL[];
  gqlSchema?: GraphQLSchema;
  error?: boolean;
  withBorders?: boolean;
  resource?: ResourceDSL;
  'data-test'?: string;
};

type ModelType = 'operations' | 'variables' | 'response';

export const FILE_SYSTEM_PATH: Record<ModelType, `${string}.${'graphql' | 'json'}`> = {
  operations: 'operations.graphql',
  variables: 'variables.json',
  response: 'response.json',
};

export const MonacoGraphqlEditor = ({
  appStatesDSL = {},
  options,
  error,
  onChange,
  gqlSchema,
  withBorders = true,
  iterableNodeListDSL = [],
  wrapperClassName = 'monaco-editor-wrapper',
  language,
  'data-test': dataTest,
  onMount,
  resource,
  ...rest
}: MonacoEditorProps): JSX.Element => {
  const [showHover, setHover] = useState(true);
  const defaultValue = useRef(rest.defaultValue);
  const [isFocused, { setTrue: setIsFocusedTrue, setFalse: setIsFocusedFalse }] = useBoolState(
    false,
  );
  const fetcher = createGraphiQLFetcher({ url: `${resource?.api}/${resource?.id}` });

  const initializeSchema = useCallback(async () => {
    async function getSchema(): Promise<IntrospectionQuery> {
      const data = await fetcher({
        query: getIntrospectionQuery(),
        operationName: 'IntrospectionQuery',
      });
      const introspectionResponse =
        'data' in data && ((data.data as unknown) as IntrospectionQuery);

      if (!introspectionResponse) {
        throw new Error('this demo does not support subscriptions or http multipart yet');
      }

      return introspectionResponse;
    }

    try {
      const schemaOperations = getSchema().then(async introspectionResponse => {
        const schemaResult = buildClientSchema(introspectionResponse);

        return schemaResult;
      });

      return schemaOperations;
    } catch (e) {
      console.error(e);
    }
  }, [fetcher]);

  useEffect(() => {
    initializeSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOnMount = useCallback(
    async (editor: MonacoEditorType.IStandaloneCodeEditor, monacoEditor: Monaco) => {
      const schemaResult = await initializeSchema();

      const ctxprovideCompletionItems = async (
        position: monaco.Position,
        codeEditor: MonacoEditorType.IStandaloneCodeEditor,
        schemaData: GraphQLSchema | undefined,
      ): Promise<monaco.languages.CompletionList | null | undefined> => {
        if (!schemaData) {
          initializeSchema();
        }

        const value = codeEditor.getValue();
        const graphQLPosition = new Position(position.lineNumber - 1, position.column - 1);
        graphQLPosition.setCharacter(position.column - 1);
        graphQLPosition.line = position.lineNumber - 1;

        if (schemaData) {
          const suggestions = getAutocompleteSuggestions(schemaData, value, graphQLPosition);
          return {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            suggestions: suggestions.map((s: any) => ({
              ...s,
              insertText: s.label,
              kind: 3,
              isDeprecated: undefined,
            })),
          };
        }

        return {
          suggestions: [],
        };
      };

      monacoEditor.languages.registerHoverProvider('typescript', {
        provideHover(model, position) {
          if (model.getWordAtPosition(position)?.word === 'event') {
            setHover(false);
          }

          setHover(true);

          return {
            range: new monacoEditor.Range(
              1,
              1,
              model.getLineCount(),
              model.getLineMaxColumn(model.getLineCount()),
            ),
            contents: [{ value: '' }],
          };
        },
      });
      editor.onDidFocusEditorText(() => {
        setIsFocusedTrue();
      });
      editor.onDidBlurEditorText(() => {
        setIsFocusedFalse();
      });

      monacoEditor.languages.registerHoverProvider('graphql', {
        provideHover(model, position) {
          if (model.getWordAtPosition(position)?.word === 'event') {
            setHover(false);
          }

          setHover(true);

          return {
            range: new monaco.Range(
              1,
              1,
              model.getLineCount(),
              model.getLineMaxColumn(model.getLineCount()),
            ),
            contents: [{ value: '' }],
          };
        },
      });
      monacoEditor.languages.registerCompletionItemProvider('graphql', {
        async provideCompletionItems(model, position) {
          return ctxprovideCompletionItems(position, editor, schemaResult);
        },
      });

      onMount?.(editor, monacoEditor);
    },
    [initializeSchema, onMount, setIsFocusedFalse, setIsFocusedTrue],
  );

  const monacoEditorOptions = useMemo(
    () => ({
      minimap: { enabled: false },
      insertSpaces: true,
      tabSize: 2,
      showDeprecated: false,
      hover: { enabled: showHover },
      ...options,
    }),
    [options, showHover],
  );

  return (
    <MonacoEditorContent
      data-test={dataTest}
      isFocused={isFocused}
      error={error}
      withBorders={withBorders}
    >
      <ReactMonacoEditor
        {...rest}
        onChange={(value, event) => {
          if (onChange) {
            onChange(value, event);
          }
        }}
        defaultValue={defaultValue.current}
        language={language}
        wrapperClassName={wrapperClassName}
        onMount={defaultOnMount}
        options={monacoEditorOptions}
        data-test={dataTest}
        theme="vs-dark"
      />
    </MonacoEditorContent>
  );
};
