var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useState, useEffect } from 'react';
import * as MaterialIcons from '@mui/icons-material';
import { Rating as MuiRating } from '@mui/material';
const capitalize = (value = '') => {
    const head = value[0] || '';
    const rest = value.slice(1) || '';
    return head.toUpperCase() + rest;
};
const buildCustomIcon = (customIcon) => {
    if (customIcon) {
        const { name, variant, color, style } = customIcon.props;
        const importVariant = variant === 'filled' ? '' : capitalize(variant);
        const MaterialIcon = MaterialIcons[`${name}${importVariant}`];
        return (_jsx(MaterialIcon, { fontSize: "inherit", name: name, color: color, style: { fill: style.fill } }));
    }
};
export const Rating = forwardRef((_a, ref) => {
    var _b, _c;
    var { onChange, onChangeActive, value, defaultValue = 0, precision, readOnly, getLabelText, icon: initlIcon, emptyIcon: initEmptyIcon, disabled, emptyLabelText, highlightSelectedOnly, max, name, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, size, style, css, id, title, translate, lang } = _a, otherRatingProps = __rest(_a, ["onChange", "onChangeActive", "value", "defaultValue", "precision", "readOnly", "getLabelText", "icon", "emptyIcon", "disabled", "emptyLabelText", "highlightSelectedOnly", "max", "name", 'data-test', 'data-node-id', 'data-node-render-path', "size", "style", "css", "id", "title", "translate", "lang"]);
    const [localValue, setLocalValue] = useState(value || 0);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const customIcon = buildCustomIcon(Array.isArray(initlIcon) ? initlIcon[0] : initlIcon);
    const emptyCustomIcon = buildCustomIcon(Array.isArray(initEmptyIcon) ? initEmptyIcon[0] : initEmptyIcon);
    useEffect(() => {
        if (defaultValue !== undefined && !isFirstLoad) {
            setIsFirstLoad(true);
            onChange === null || onChange === void 0 ? void 0 : onChange({
                target: {
                    value: Number(defaultValue),
                },
            }, defaultValue);
        }
    }, [defaultValue, isFirstLoad, onChange]);
    useEffect(() => {
        setLocalValue(value || 0);
    }, [value]);
    return (_jsx("div", Object.assign({ "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, style: style, css: css, id: id, title: title, lang: lang, translate: translate }, { children: _jsx(MuiRating, Object.assign({}, otherRatingProps, { ref: ref, name: name, value: localValue, defaultValue: defaultValue, emptyLabelText: emptyLabelText, getLabelText: getLabelText, onChange: (event, currentValue) => {
                onChange === null || onChange === void 0 ? void 0 : onChange(event, currentValue || 0);
            }, onChangeActive: onChangeActive, precision: precision, readOnly: readOnly, icon: ((_b = customIcon === null || customIcon === void 0 ? void 0 : customIcon.props) === null || _b === void 0 ? void 0 : _b.name) ? customIcon : undefined, emptyIcon: ((_c = emptyCustomIcon === null || emptyCustomIcon === void 0 ? void 0 : emptyCustomIcon.props) === null || _c === void 0 ? void 0 : _c.name) ? emptyCustomIcon : undefined, disabled: disabled, max: max, highlightSelectedOnly: highlightSelectedOnly, size: size })) })));
});
