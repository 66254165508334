import { Version } from '@builder/schemas';

import { joinVersion } from './joinVersion';
import { splitVersion } from './splitVersion';

/**
 * Tuple of increment values that get added to each subversion
 * @example "0.0.0.1" + [0, 0, 1, 1] -> "0.0.1.2"
 */
type VersionIncrementTuple = number[];

export const incrementVersion = (version: Version, increment: VersionIncrementTuple): Version => {
  const currentVersionTuple = splitVersion(version).map(Number);
  const newVersionTuple = currentVersionTuple.map((part, index) => String(part + increment[index]));

  return joinVersion(newVersionTuple);
};
