import { stateListSelectors, StateAssert } from '@builder/schemas';
import { EMITTER_EVENTS, isEmptyString } from '@builder/utils';
import { useUserAppEventEmitter } from '../../providers';
export const useRequestRunner = (stateListDSL) => {
    const emitter = useUserAppEventEmitter();
    const run = (requestID) => {
        if (!requestID || isEmptyString(requestID) || !(requestID in stateListDSL)) {
            return;
        }
        const requestDSL = stateListSelectors.getStateDSL(stateListDSL, { id: requestID });
        StateAssert.Schema.assertIsStateRequest(requestDSL);
        emitter.emit(EMITTER_EVENTS.refetchQuery, {
            queryID: requestID,
            options: requestDSL.args,
        });
    };
    return { run };
};
