import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const FormThreeListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  grid-column-gap: ${({ theme }) => theme.spacing(1)};
  min-height: 50px;
`;

export const FormThreeListItemContent = styled.div`
  display: grid;
  grid-template-columns: 4fr 1.5fr 24px;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const FormThreeListItemTextField = styled.div`
  & .MuiOutlinedInput-root {
    flex: auto;
    height: 32px;
  }
`;
export const Placeholder = styled.div`
  color: #aaa;
`;

export const StyledTextField = styled(TextField)`
  min-width: 100%;
  p {
    line-height: normal;
  }
  .MuiFormHelperText-root {
    margin-bottom: ${({ theme }) => theme.spacing(-1)};
  }
`;

export const FormThreeListDeleteButtonContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ theme }) => theme.spacing(3)};
`;
