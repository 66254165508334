import { Migration } from '../Migration';
class Migration_0_16_5 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.5';
    }
    getChangeList() {
        return ['Copied local states of wrapper pages to pages'];
    }
    migrateAppDSL(appDSL) {
        const newDSL = Object.assign({}, appDSL);
        const nodes = Object.assign({}, newDSL.nodes);
        const states = Object.assign({}, newDSL.states);
        const statesEntries = Object.entries(states);
        const newNodesValues = Object.entries(nodes).map(([nodeKey, nodeValue]) => {
            var _a, _b;
            if ((_a = nodeValue.name) === null || _a === void 0 ? void 0 : _a.includes('Layout')) {
                if (nodeValue.props.children) {
                    const parentKey = nodeValue.parentID;
                    if (nodes[parentKey].states) {
                        const newNodeValue = Object.assign({}, nodeValue);
                        newNodeValue.states = [...((_b = nodes[parentKey].states) !== null && _b !== void 0 ? _b : [])];
                        return [nodeKey, newNodeValue];
                    }
                }
            }
            return [nodeKey, nodeValue];
        });
        return Object.assign(Object.assign({}, newDSL), { nodes: Object.fromEntries(newNodesValues), states: Object.fromEntries(statesEntries) });
    }
}
export const migration_0_16_5 = new Migration_0_16_5();
