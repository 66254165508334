import { AppEvents, createReducer, DASHBOARD_EVENTS, DashboardState } from '../../common';
import { dashboardInitial } from '../initial-state';

import {
  ROUTER_REDUCER_EVENTS,
  routerReducer,
  ROUTE_REDUCER_EVENTS,
  routeReducer,
  COMPONENTS_REDUCER_EVENTS,
  componentsReducer,
  USER_COMPONENTS_REDUCER_EVENTS,
  userComponentsReducer,
  COPY_BUFFER_REDUCER_EVENTS,
  copyBufferReducer,
  NAVIGATOR_REDUCER_EVENTS,
  navigationReducer,
  MEDIA_QUERY_REDUCER_EVENTS,
  mediaQueryReducer,
} from './complex';
import { cleanUpReducer } from './complex/cleanUpDSL';
import { LAYOUT_REDUCER_EVENTS, layoutReducer } from './complex/layoutReducer';

const { reducerEventsDomain: complexReducer, reducerEvents } = createReducer<
  DashboardState,
  AppEvents
>(declare => [
  // router
  declare<typeof ROUTER_REDUCER_EVENTS[number]>({
    events: ROUTER_REDUCER_EVENTS,
    reduce: routerReducer,
  }),

  // Layout
  declare<typeof LAYOUT_REDUCER_EVENTS[number]>({
    events: LAYOUT_REDUCER_EVENTS,
    reduce: layoutReducer,
  }),

  // route
  declare<typeof ROUTE_REDUCER_EVENTS[number]>({
    events: ROUTE_REDUCER_EVENTS,
    reduce: routeReducer,
  }),

  // components
  declare<typeof COMPONENTS_REDUCER_EVENTS[number]>({
    events: COMPONENTS_REDUCER_EVENTS,
    reduce: componentsReducer,
  }),

  // components
  declare<typeof DASHBOARD_EVENTS.appConfigurationDSLCleanUp>({
    events: [DASHBOARD_EVENTS.appConfigurationDSLCleanUp],
    reduce: cleanUpReducer,
  }),

  // user components
  declare<typeof USER_COMPONENTS_REDUCER_EVENTS[number]>({
    events: USER_COMPONENTS_REDUCER_EVENTS,
    reduce: userComponentsReducer,
  }),

  // copy buffer
  declare<typeof COPY_BUFFER_REDUCER_EVENTS[number]>({
    events: COPY_BUFFER_REDUCER_EVENTS,
    reduce: copyBufferReducer,
  }),

  // navigator
  declare<typeof NAVIGATOR_REDUCER_EVENTS[number]>({
    events: NAVIGATOR_REDUCER_EVENTS,
    reduce: navigationReducer,
  }),

  // media query
  declare<typeof MEDIA_QUERY_REDUCER_EVENTS[number]>({
    events: MEDIA_QUERY_REDUCER_EVENTS,
    reduce: mediaQueryReducer,
  }),
]);

export const DASHBOARD_COMPLEX_REDUCER_EVENTS = reducerEvents as (
  | typeof ROUTER_REDUCER_EVENTS[number]
  | typeof ROUTE_REDUCER_EVENTS[number]
  | typeof COMPONENTS_REDUCER_EVENTS[number]
  | typeof USER_COMPONENTS_REDUCER_EVENTS[number]
  | typeof COPY_BUFFER_REDUCER_EVENTS[number]
  | typeof NAVIGATOR_REDUCER_EVENTS[number]
  | typeof MEDIA_QUERY_REDUCER_EVENTS[number]
)[];

export const dashboardComplexReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return complexReducer[event.type]?.(state, event) ?? state;
};
