import { useCallback } from 'react';

import { Grid } from '@mui/material';

import {
  NodePropValue,
  componentSettingSelectors,
  ComponentSettingCustomFontDSL,
  CustomFontListDSL,
  GoogleFontListDSL,
  FontsDSL,
  FontID,
  CustomFontDSL,
} from '@builder/schemas';
import { memo, isNil } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { Autocomplete } from 'src/shared/components';

type CustomFontSelectorTypeProps = {
  setting: ComponentSettingCustomFontDSL;
  dataTestPrefix?: string;
  /**
   * The value that is stored in the DSL by the corresponding keyPath.
   */
  keyValue: NodePropValue | null;
  /**
   * Callback to update keyValue.
   */
  onChange: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
};

const getFontOption = (fontKey: string, fontArray: (GoogleFontListDSL | CustomFontListDSL)[]) => {
  const fontOption = fontArray[(fontKey as unknown) as number] as Record<FontID, CustomFontDSL>;
  return {
    label: (fontOption.fontFamily as unknown) as string,
    value: (fontOption.fontFamily as unknown) as string,
  };
};

export const CustomFontSelectorType = memo(
  'CustomFontSelectorType',
  ({ setting, dataTestPrefix, keyValue, onChange }: CustomFontSelectorTypeProps): JSX.Element => {
    const { appDSL } = useNodeSettingsProps();
    const fontsDSL = appDSL.theme?.fonts;
    const keyName = componentSettingSelectors.getSettingsKeyNamePath(setting);
    const isPropRequired = componentSettingSelectors.isRequired(setting);

    let fontArray = Object.values(fontsDSL as FontsDSL);
    fontArray = Object.assign({}, ...fontArray);

    let fontListOptions = Object.keys(fontArray).map(font => getFontOption(font, fontArray));
    fontListOptions = [...fontListOptions, ...setting.options] as {
      label: string;
      value: string;
    }[];

    const dataTest = `${dataTestPrefix}.${keyName}`;

    const updateProp = useCallback(
      (value: unknown) => {
        if (isPropRequired && (isNil(value) || value === '')) {
          return;
        }

        if (value === '') {
          onChange({
            keyValue: null,
            keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
          });
          return;
        }

        onChange({
          keyValue: value,
          keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
        });
      },
      [isPropRequired, onChange, setting],
    );

    return (
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          value={keyValue as string}
          label={setting.label}
          onChange={updateProp}
          options={fontListOptions}
          data-test={dataTest}
        />
      </Grid>
    );
  },
);
