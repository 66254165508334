import { isEmpty } from 'ramda';
import { nodeListSelectors, appDSLSelectors, resourceListSelectors, RESOURCE_TYPES, resourceSelectors, } from '@builder/schemas';
import { serialize } from '@builder/utils';
import { transformVariableName } from '../../utils/transformVariableName';
import { RESOURCE_NAMES_CONST, RESOURCE_TYPES_CONST, RESOURCE_TYPINGS_CONST, } from '../types-generator/constants';
import { RESOURCES_LIST_CONST, ROUTES_LIST_CONST, APP_AUTH_RESOURCE_CONST, ALL_RESOURCES_LIST_CONST, ALL_LIBRARIES_LIST_CONST, } from './constants';
export function camelize(str) {
    return (str
        // eslint-disable-next-line func-names
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
        .replace(/\s+/g, ''));
}
export class ConstantsGenerator {
    constructor(appDSL) {
        this.nodeListDSL = appDSL.nodes;
        this.resourceListDSL = appDSL.resources;
        this.appDSL = appDSL;
    }
    generateLibrariesFile() {
        const librariesListDSL = this.appDSL.libraries;
        return `export const ${ALL_LIBRARIES_LIST_CONST} = ${serialize.stringify(librariesListDSL)};`;
    }
    generateRoutesFile() {
        return `
      export const ${ROUTES_LIST_CONST} = ${serialize.stringify(nodeListSelectors.getAllRouteNodes(this.nodeListDSL).map(routeNodeDSL => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            return ({
                path: routeNodeDSL.props.path,
                beforeRouteEnter: {
                    type: (_a = routeNodeDSL.props.routerHooks) === null || _a === void 0 ? void 0 : _a.beforeRouteEnter,
                    code: (_b = routeNodeDSL.props.routerHooks) === null || _b === void 0 ? void 0 : _b.beforeRouteEnterCode,
                    request: (_c = routeNodeDSL.props.routerHooks) === null || _c === void 0 ? void 0 : _c.beforeRouteEnterRequestName,
                    function: (_d = routeNodeDSL.props.routerHooks) === null || _d === void 0 ? void 0 : _d.beforeRouteEnterFunctionName,
                },
                beforeRouteUpdate: {
                    type: (_e = routeNodeDSL.props.routerHooks) === null || _e === void 0 ? void 0 : _e.beforeRouteUpdate,
                    code: (_f = routeNodeDSL.props.routerHooks) === null || _f === void 0 ? void 0 : _f.beforeRouteUpdateCode,
                    request: (_g = routeNodeDSL.props.routerHooks) === null || _g === void 0 ? void 0 : _g.beforeRouteUpdateRequestName,
                    function: (_h = routeNodeDSL.props.routerHooks) === null || _h === void 0 ? void 0 : _h.beforeRouteUpdateFunctionName,
                },
                beforeRouteExit: {
                    type: (_j = routeNodeDSL.props.routerHooks) === null || _j === void 0 ? void 0 : _j.beforeRouteExit,
                    code: (_k = routeNodeDSL.props.routerHooks) === null || _k === void 0 ? void 0 : _k.beforeRouteExitCode,
                    request: (_l = routeNodeDSL.props.routerHooks) === null || _l === void 0 ? void 0 : _l.beforeRouteExitRequestName,
                    function: (_m = routeNodeDSL.props.routerHooks) === null || _m === void 0 ? void 0 : _m.beforeRouteExitFunctionName,
                },
                title: routeNodeDSL.props.title,
                exact: routeNodeDSL.props.exact,
                strict: routeNodeDSL.props.strict,
                fragment: {},
                // eslint-disable-next-line no-underscore-dangle
                meta: routeNodeDSL.props.__meta,
                // eslint-disable-next-line no-underscore-dangle
                params: routeNodeDSL.props.__defaultPathParams,
                name: camelize(routeNodeDSL.alias ? routeNodeDSL.alias.replace('Wrapper', '') : ''),
            });
        }))};
    `;
    }
    generateResourcesFile() {
        const resourceListDSL = this.appDSL.resources;
        const resourcesObject = Object.values(resourceListDSL).reduce((resources, resource) => (Object.assign(Object.assign({}, resources), { [transformVariableName(resource.name)]: resource })), {});
        return `export const ${ALL_RESOURCES_LIST_CONST} = ${serialize.stringify(resourcesObject)};`;
    }
    generateResourceFile() {
        const authResourceDSL = appDSLSelectors.getAuthResourceDSL(this.appDSL);
        const authResourceConst = authResourceDSL
            ? `export const ${APP_AUTH_RESOURCE_CONST}: ${RESOURCE_TYPINGS_CONST.Resource} = {
          name: ${RESOURCE_NAMES_CONST}.${authResourceDSL.name},
          endpoint: '${resourceSelectors.getResourceEndpoint(authResourceDSL)}',
          type: ${RESOURCE_TYPES_CONST}.${authResourceDSL.type},
        } as const;`
            : '';
        const resourcesListConstInitialValue = authResourceConst
            ? `[${APP_AUTH_RESOURCE_CONST}.name as typeof ${RESOURCE_NAMES_CONST}.${authResourceDSL === null || authResourceDSL === void 0 ? void 0 : authResourceDSL.name}]: ${APP_AUTH_RESOURCE_CONST},`
            : '';
        const resourcesListConst = `
      export const ${RESOURCES_LIST_CONST}: ${RESOURCE_TYPINGS_CONST.ResourceList} = {
        ${resourcesListConstInitialValue}
        ${Object.values(this.resourceListDSL)
            .filter(resource => resource.id !== (authResourceDSL === null || authResourceDSL === void 0 ? void 0 : authResourceDSL.id) && resource.type !== RESOURCE_TYPES.rest)
            .map(resource => `${transformVariableName(resource.name)}: {
                name: ${RESOURCE_NAMES_CONST}.${transformVariableName(resource.name)},
                endpoint: '${resourceSelectors.getResourceEndpoint(resource)}',
                type: ${RESOURCE_TYPES_CONST}.${resource.type},
              }`)
            .join(', ')}
      }
    `;
        return `
      import { ${RESOURCE_TYPES_CONST}, ${RESOURCE_NAMES_CONST}, ${RESOURCE_TYPINGS_CONST.ResourceList}${authResourceDSL ? `, ${RESOURCE_TYPINGS_CONST.Resource}` : ''} } from '../types';

      ${authResourceConst}

      ${resourcesListConst}
    `;
    }
    generateIndexFile() {
        const eightBaseAndGraphqlResourceArrayDSL = resourceListSelectors.get8baseAndGraphqlResourceArrayDSL(this.appDSL.resources);
        const useResourceClientImport = isEmpty(eightBaseAndGraphqlResourceArrayDSL)
            ? ''
            : "export * from './resource';";
        return `
      export * from './routes';
      export * from './resources';
      ${useResourceClientImport}
    `;
    }
}
