import { pipe } from 'ramda';
import { GENERATOR_VARIANTS, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, GENERATOR_SHAPE_TYPES, COMPONENT_DSL_NAMES, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_ACTION_TYPE, } from '../../../constants';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, disableSpacingPaddings, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getDivider, getPropertySections, getStyleSections } from '../../common/settings';
import { getCommonTextSettings } from '../../text-components/Text/Text.settings';
import { getCommonBuilderComponentsRouterLinkSettings } from '../BuilderComponentsRouterLink/BuilderComponentsRouterLink.settings';
export const BuilderComponentsDataGridSettingFormatters = {
    usd: 'return isNaN(Number(value)) ? value : new Intl.NumberFormat("en", {style: "currency", currency: "USD", maximumFractionDigits: 0}).format(value)',
    usdCents: 'return isNaN(Number(value)) ? value : new Intl.NumberFormat("en", {style: "currency", currency: "USD"}).format(value)',
    boolean: 'return typeof value !== "boolean" ? value : value ? "True" : "False"',
    date: 'const dt = new Date(value as Date); return isNaN(dt.getTime()) ? value : Intl.DateTimeFormat("en", {timeZone: "UTC"}).format(dt)',
    dateLocal: 'const dt = new Date(value as Date); return isNaN(dt.getTime()) ? value : Intl.DateTimeFormat("en",  {year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" }).format(dt)',
    float: 'return isNaN(Number(value)) ? value : new Intl.NumberFormat("en").format(value)',
    integer: 'return isNaN(Number(value)) ? value : new Intl.NumberFormat("en", {maximumFractionDigits: 0}).format(value)',
    percent: 'return isNaN(Number(value)) ? value : new Intl.NumberFormat("en", {style: "percent"}).format(value)',
};
const getActionChildrenSettings = (prefixPath = [], nodePath) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'actionType'],
        resetPathBeforeOnChange: [[...prefixPath, 'args']],
        label: 'Type',
        options: [
            {
                value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                label: 'Custom Code',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.jsEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'args', 'code'],
        nodePath,
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.customCode,
            },
        ],
        defaultValue: '// Your JavaScript code here',
        label: 'Custom Code',
    },
];
const getTableColumns = () => [
    { label: 'ID', value: 'id' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'First Name', value: 'firstName' },
    { label: 'Birthday', value: 'birthday' },
    { label: 'Balance', value: 'balance' },
];
export const BuilderComponentsDataGridSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'basic',
                    title: 'Basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['rows'],
                            label: 'Data',
                            skipDebounce: false,
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.dataGenerator,
                            type: COMPONENT_SETTING_TYPES.dataGenerator,
                            label: 'Auto Generate Columns',
                            warningDialogTitle: 'Delete Columns',
                            warningDialogMessage: 'Are you sure you want to clear current columns?',
                            variant: GENERATOR_VARIANTS.keysToArray,
                            name: 'generateColumns',
                            sourcePath: ['rows'],
                            receivePath: ['columns'],
                            compareKeys: ['field'],
                            generatedDataShape: {
                                field: GENERATOR_SHAPE_TYPES.key,
                                headerName: GENERATOR_SHAPE_TYPES.capitalizeKey,
                            },
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.updatedPrimaryKey,
                            keyPath: ['primaryField'],
                            label: 'Primary field',
                            options: getTableColumns(),
                        },
                        getDivider(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'columns',
                            title: 'Columns',
                            children: [
                                {
                                    type: COMPONENT_SETTING_TYPES.list,
                                    keyPath: ['columns'],
                                    name: 'columns',
                                    label: 'Columns',
                                    itemLabelKeyPath: ['headerName'],
                                    newObjectPredefineds: {
                                        field: 'newColumn',
                                        headerName: 'New Column',
                                        width: 100,
                                    },
                                    settings: [
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['field'],
                                            label: 'Key',
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.text,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['headerName'],
                                            label: 'Name',
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['hide'],
                                            label: 'Hide',
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.number,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['width'],
                                            label: 'Width',
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['valueGetter'],
                                            label: 'Transform',
                                        },
                                        {
                                            type: COMPONENT_SETTING_TYPES.node,
                                            keyPath: ['renderCell'],
                                            name: 'renderCell',
                                            label: 'Render Cell',
                                            items: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.Text,
                                                    settings: getCommonTextSettings(),
                                                },
                                                {
                                                    name: COMPONENT_DSL_NAMES.BuilderComponentsRouterLink,
                                                    settings: getCommonBuilderComponentsRouterLinkSettings(),
                                                },
                                                {
                                                    name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
                                                    settings: [],
                                                },
                                            ],
                                        },
                                        {
                                            componentView: COMPONENT_SETTING_VIEWS.select,
                                            type: COMPONENT_SETTING_TYPES.prop,
                                            keyPath: ['valueFormatter'],
                                            label: 'Format',
                                            options: [
                                                { label: 'Text', value: 'return value' },
                                                {
                                                    label: 'USD',
                                                    value: BuilderComponentsDataGridSettingFormatters.usd,
                                                },
                                                {
                                                    label: 'USD (cents)',
                                                    value: BuilderComponentsDataGridSettingFormatters.usdCents,
                                                },
                                                {
                                                    label: 'Boolean',
                                                    value: BuilderComponentsDataGridSettingFormatters.boolean,
                                                },
                                                {
                                                    label: 'Date',
                                                    value: BuilderComponentsDataGridSettingFormatters.date,
                                                },
                                                {
                                                    label: 'Date time (local timezone)',
                                                    value: BuilderComponentsDataGridSettingFormatters.dateLocal,
                                                },
                                                {
                                                    label: 'Float',
                                                    value: BuilderComponentsDataGridSettingFormatters.float,
                                                },
                                                {
                                                    label: 'Integer',
                                                    value: BuilderComponentsDataGridSettingFormatters.integer,
                                                },
                                                {
                                                    label: 'Percent',
                                                    value: BuilderComponentsDataGridSettingFormatters.percent,
                                                },
                                                { label: 'URL link', value: 'return value' },
                                                { label: 'Button', value: 'return value' },
                                                { label: 'Checkbox', value: 'return value' },
                                                { label: 'Datepicker', value: 'return value' },
                                                { label: 'Dropdown', value: 'return value' },
                                                { label: 'Modal', value: 'return value' },
                                                { label: 'Object', value: 'return value' },
                                                { label: 'Radio button', value: 'return value' },
                                                { label: 'Text input', value: 'return value' },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        getDivider(),
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['rowCount'],
                            label: 'Row Count',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['loading'],
                            label: 'Loading',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'pagination',
                    title: 'Pagination',
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['pageSize'],
                            label: 'Rows Per Page',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.jsonEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['rowsPerPageOptions'],
                            label: 'Rows Per Page Options',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['hideFooter'],
                            label: 'Hide Footer',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['hideFooterPagination'],
                            label: 'Hide Footer Pagination',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['hideFooterSelectedRowCount'],
                            label: 'Hide Footer Selected Row Count',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'advanced',
                    title: 'Advanced',
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['checkboxSelection'],
                            label: 'Checkbox Selection',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableSelectionOnClick'],
                            label: 'Disable Selection On Click',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableColumnSelector'],
                            label: 'Disable Column Selector',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'basic',
                    title: 'Basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showCellRightBorder'],
                            label: 'Show Cell Right Border',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showColumnRightBorder'],
                            label: 'Show Column Right Border',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['showTableBorder'],
                            label: 'Show Table Border',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['rowHeight'],
                            label: 'Row Height',
                        },
                    ],
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], disableSpacingPaddings), pickSettings([
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.size.root, NS_ID.style.size.height],
                    [NS_ID.style.size.root, NS_ID.style.size.width],
                    [NS_ID.style.size.root, NS_ID.style.size.overflow],
                    [NS_ID.style.background.root],
                    [NS_ID.style.border.root],
                    [NS_ID.style.css.root],
                ]))(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: 'events',
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCellClickAction',
                            keyPath: ['onCellClick'],
                            label: 'On Cell Click',
                            children: getActionChildrenSettings(['onCellClick']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCellHoverAction',
                            keyPath: ['onCellHover'],
                            label: 'On Cell Hover',
                            children: getActionChildrenSettings(['onCellHover']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onColumnHeaderClickAction',
                            keyPath: ['onColumnHeaderClick'],
                            label: 'On Column Header Click',
                            children: getActionChildrenSettings(['onColumnHeaderClick']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onErrorAction',
                            keyPath: ['onError'],
                            label: 'On Error',
                            children: getActionChildrenSettings(['onError']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onPageChangeAction',
                            keyPath: ['onPageChange'],
                            label: 'On Page Change',
                            children: getActionChildrenSettings(['onPageChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onPageSizeChangeAction',
                            keyPath: ['onPageSizeChange'],
                            label: 'On Page Size Change',
                            children: getActionChildrenSettings(['onPageSizeChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onRowClickAction',
                            keyPath: ['onRowClick'],
                            label: 'On Row Click',
                            children: getActionChildrenSettings(['onRowClick']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onRowHoverAction',
                            keyPath: ['onRowHover'],
                            label: 'On Row Hover',
                            children: getActionChildrenSettings(['onRowHover']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onRowSelectedAction',
                            keyPath: ['onRowSelected'],
                            label: 'On Row Selected',
                            children: getActionChildrenSettings(['onRowSelected']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onSelectionChangeAction',
                            keyPath: ['onSelectionChange'],
                            label: 'On Selection Change',
                            children: getActionChildrenSettings(['onSelectionChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onSortModelChangeAction',
                            keyPath: ['onSortModelChange'],
                            label: 'On Sort Model Change',
                            children: getActionChildrenSettings(['onSortModelChange']),
                        },
                    ],
                },
            ],
        },
    ],
};
