import { mapObjIndexed } from 'ramda';
import { mapSettingsDSL, mapPropListDSL, } from '@builder/schemas';
import { generateID } from '@builder/utils';
import { createErrorMigrationResult, createSuccessMigrationResult } from './createMigrationResult';
export class Migration {
    constructor() {
        this.migrate = (configuration) => {
            try {
                const migratedAppConfiguration = this.migrateAppConfiguration(configuration);
                return createSuccessMigrationResult(Object.assign(Object.assign({}, migratedAppConfiguration), { schemaDSLVersion: this.toVersion }), {
                    fromVersion: configuration.schemaDSLVersion,
                    toVersion: this.toVersion,
                    changeList: this.getChangeList(),
                });
            }
            catch (err) {
                return createErrorMigrationResult({
                    message: `Internal Error when migrating from ${configuration.schemaDSLVersion} to ${this.toVersion} version: ${err.message}`,
                    fromVersion: configuration.schemaDSLVersion,
                    toVersion: this.toVersion,
                });
            }
        };
    }
    /**
     * @returns List of changes without dots at the end
     */
    getChangeList() {
        return [];
    }
    generateSecuredNodeAlias(nodeListDSL, aliasPrefix) {
        const nodesArrayDSL = Object.values(nodeListDSL);
        const allNodesAliases = nodesArrayDSL.map(({ alias }) => alias).filter(Boolean);
        const allSameAliasesCounters = allNodesAliases
            .map(alias => Number(alias.replace(new RegExp(`^${aliasPrefix} `), '')))
            .filter(counter => counter && !isNaN(counter));
        const newIndex = Math.max(0, ...allSameAliasesCounters) + 1;
        return `${aliasPrefix} ${newIndex}`;
    }
    generateSecuredNodeID(nodeListDSL) {
        // 1. create uniq ID
        let securedNodeID = generateID();
        // 2. Make sure that a node with such ID
        // doesn't exist in the nodeListDSL already
        while (nodeListDSL[securedNodeID]) {
            securedNodeID = generateID();
        }
        // 3. return secured uniq ID
        return securedNodeID;
    }
    generateSecuredStateName(stateListDSL, stateNamePrefix) {
        const nodesArrayDSL = Object.values(stateListDSL);
        const allStateNames = nodesArrayDSL.map(({ name }) => name).filter(Boolean);
        const allSameStateNamesCounters = allStateNames
            .map(name => Number(name.replace(new RegExp(`^${stateNamePrefix}_`), '')))
            .filter(counter => counter && !isNaN(counter));
        const newIndex = Math.max(0, ...allSameStateNamesCounters) + 1;
        return `${stateNamePrefix}_${newIndex}`;
    }
    migrateNodeDSL(nodeDSL, appDSL) {
        return nodeDSL;
    }
    migrateNodeListDSL(nodeListDSL, appDSL) {
        return mapObjIndexed((nodeDSL, propName) => this.migrateNodeDSL(nodeDSL, appDSL), nodeListDSL);
    }
    migrateStateDSL(stateDSL, appDSL) {
        return stateDSL;
    }
    migrateStateListDSL(stateListDSL, appDSL) {
        return mapObjIndexed(stateDSL => this.migrateStateDSL(stateDSL, appDSL), stateListDSL);
    }
    migrateResourceDSL(resourceDSL, appDSL) {
        return resourceDSL;
    }
    migrateResourceListDSL(resourceListDSL, appDSL) {
        return mapObjIndexed(resourceDSL => this.migrateResourceDSL(resourceDSL, appDSL), resourceListDSL);
    }
    migrateAssetDSL(assetDSL, appDSL) {
        return assetDSL;
    }
    migrateAssetListDSL(assetListDSL, appDSL) {
        return mapObjIndexed(assetDSL => this.migrateAssetDSL(assetDSL, appDSL), assetListDSL || {});
    }
    migratePropDSL(propDSL, appDSL) {
        return propDSL;
    }
    migratePropListDSL(propListDSL, appDSL) {
        return mapPropListDSL((propDSL, propName) => this.migratePropDSL(propDSL, appDSL), propListDSL);
    }
    migrateSettingDSL(setting, appDSL) {
        return setting;
    }
    migrateSettingListDSL(settings, appDSL) {
        return mapSettingsDSL(setting => this.migrateSettingDSL(setting, appDSL), settings);
    }
    migrateUserComponentSettingListDSL(settings, appDSL) {
        if (!settings) {
            return settings;
        }
        return {
            properties: this.migrateSettingListDSL(settings.properties, appDSL),
            style: this.migrateSettingListDSL(settings.style, appDSL),
            events: this.migrateSettingListDSL(settings.events, appDSL),
        };
    }
    migrateUserComponentListDSL(userComponentListDSL, appDSL) {
        return mapObjIndexed(userComponent => {
            if (!userComponent.schema.symbolNodes) {
                return userComponent;
            }
            return Object.assign(Object.assign({}, userComponent), { schema: Object.assign(Object.assign({}, userComponent.schema), { props: this.migratePropListDSL(userComponent.schema.props, appDSL), symbolNodes: mapObjIndexed(nodeDSL => this.migrateNodeDSL(nodeDSL, appDSL), userComponent.schema.symbolNodes) }) });
        }, userComponentListDSL);
    }
    migrateAppDSL(appDSL) {
        const { nodes: nodeListDSL, states: stateListDSL, resources: resourceListDSL, assets: assetListDSL, } = appDSL;
        const migratedNodeListDSL = this.migrateNodeListDSL(nodeListDSL, appDSL);
        const migratedStateListDSL = this.migrateStateListDSL(stateListDSL, appDSL);
        const migratedResourceListDSL = this.migrateResourceListDSL(resourceListDSL, appDSL);
        const migratedAssetListDSL = this.migrateAssetListDSL(assetListDSL, appDSL);
        return Object.assign(Object.assign({}, appDSL), { nodes: migratedNodeListDSL, states: migratedStateListDSL, resources: migratedResourceListDSL, assets: assetListDSL ? migratedAssetListDSL : assetListDSL });
    }
    migrateAppConfiguration(configuration) {
        const { appDSL, userComponentsDSL: userComponentListDSL } = configuration;
        const migratedAppDSL = this.migrateAppDSL(appDSL);
        const migratedUserComponentListDSL = this.migrateUserComponentListDSL(userComponentListDSL, appDSL);
        return Object.assign(Object.assign({}, configuration), { userComponentsDSL: migratedUserComponentListDSL, appDSL: migratedAppDSL });
    }
}
