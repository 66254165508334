import * as changeCase from 'change-case';
import { stateListSelectors } from '@builder/schemas';
import { hasUsedVarInJs } from '../../../../utils';
import { STATE_SUFFIX } from '../../constants';
import { generateHookDeclarationNameWithPageName, isCustomEntry, isNotCurrentState, } from '../../utils';
/**
 * Provides an api to generate state names.
 */
export class BaseAppStateAccessor {
    getStatesUsed() {
        // Only non-custom states can use another states.
        if (isCustomEntry(this.stateDSL)) {
            return [];
        }
        const customStatesUsed = stateListSelectors
            .getStateArrayDSL(this.stateListDSL)
            .filter(({ name }) => hasUsedVarInJs(name, this.stateDSL)) // Get custom states used in the mutation.
            .map(({ name }) => name)
            .filter(name => isNotCurrentState(name, this.stateDSL)); // Get all the names.
        return customStatesUsed;
    }
    getHookCallArgumentsString() {
        return this.getStatesUsed().join(',');
    }
    getHookDeclarationArgumentsString() {
        return this.getStatesUsed().join(',');
    }
    getHookAssociatedConstantsString() {
        return '';
    }
    getHookDeclarationName(appDSL) {
        const originalDeclarationName = `use${changeCase.pascalCase(`${this.stateDSL.name}`)}`;
        if (this.stateDSL.scope === 'local')
            return generateHookDeclarationNameWithPageName(appDSL, this.stateDSL, originalDeclarationName);
        return `${originalDeclarationName}${STATE_SUFFIX}`;
    }
    getStateTypeName() {
        return '';
    }
    getStateName() {
        return this.stateDSL.name;
    }
    getHookTypings() {
        const stateName = this.getStateName();
        const stateTypeName = this.getStateTypeName();
        return `declare const ${stateName}: ${stateTypeName};`;
    }
}
