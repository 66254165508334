import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialCardContentSettings } from './MaterialCardContent.settings';
export const MaterialCardContent = {
    name: COMPONENT_DSL_NAMES.MaterialCardContent,
    displayName: 'Card Content',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'CardContent',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign({}, commonComponentProps),
    },
    settings: MaterialCardContentSettings,
};
