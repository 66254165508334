import { IMPORT_DEPENDENCY_TYPES, } from '@builder/schemas';
const ASSET_MIME_TYPE_EXTENSIONS = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/x-png': '.png',
    'image/webp': '.webp',
    'image/svg+xml': '.svg',
    'font/ttf': '.ttf',
    'font/otf': '.otf',
    'font/woff': '.woff',
    'font/woff2': '.woff2',
    'application/pdf': '.pdf',
};
export class AssetGenerator {
    constructor(assetFileDSL) {
        this.assetFileDSL = assetFileDSL;
    }
    getParentFoldersPath() {
        return '';
    }
    getAssetFileName() {
        return this.assetFileDSL.name;
    }
    getAssetFileExtension() {
        const { path } = this.assetFileDSL;
        const mimeType = this.assetFileDSL.mimeType;
        if (ASSET_MIME_TYPE_EXTENSIONS[mimeType]) {
            return ASSET_MIME_TYPE_EXTENSIONS[mimeType];
        }
        return path.slice(path.lastIndexOf('.'));
    }
    /**
     * Parent folders path, name and extension of the asset
     * @example '/images/blog/image.png'
     */
    getImportPostfix() {
        return `${this.getParentFoldersPath()}${this.getAssetFileName()}${this.getAssetFileExtension()}`;
    }
    getRelativePath() {
        return `./${this.getImportPostfix()}`;
    }
    getImportData() {
        return {
            importName: `../assets/${this.getImportPostfix()}`,
            varName: this.assetFileDSL.name,
            importType: IMPORT_DEPENDENCY_TYPES.default,
        };
    }
}
