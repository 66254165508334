var _a;
import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_NAMES, COMPONENT_DSL_CALLBACK_TYPES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonStyleProps } from '../../common/commonProps';
import { MaterialTypography } from '../MaterialTypography';
import { BuilderComponentsMultiselectSettings } from './BuilderComponentsMultiselect.settings';
export const BuilderComponentsMultiselect = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsMultiselect,
    displayName: 'Multiselector',
    icon: 'multiSelect',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Multiselect',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.array,
                    name: 'multiselect',
                    defaultValue: [],
                },
            ],
            props: {
                variant: 'outlined',
                label: 'Multiselect',
                size: 'medium',
                behavior: 'Default',
                value: '{{ __STATES__.multiselect.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.multiselect.setValue(event.target.value)',
                    },
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialMenuItem,
                            iterator: {
                                data: [
                                    { value: 'One', label: 'One!' },
                                    { value: 'Two', label: 'Two!' },
                                    { value: 'Three', label: 'Three!' },
                                ],
                                name: 'item',
                            },
                            props: {
                                value: '{{ item.value }}',
                                children: {
                                    nodes: [
                                        {
                                            name: 'Text',
                                            props: {
                                                children: '{{ item.label }}',
                                            },
                                        },
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
                                            props: {
                                                color: 'primary',
                                                size: 'medium',
                                                // auxLabel is important for the checkboxes that are inside a multiselect
                                                auxLabel: '{{ item.label }}',
                                                checked: '{{ __STATES__.multiselect.value.indexOf(item.value) > -1 }}',
                                                labelPlacement: 'end',
                                                label: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                            alias: 'Checkbox Label',
                                                            schemaOverride: {
                                                                interaction: 'only-editable',
                                                            },
                                                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                            props: {
                                                                                children: '{{ item.label }}',
                                                                            },
                                                                        },
                                                                    ],
                                                                } }),
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialChip,
                                            props: {
                                                label: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: '{{ item.label }}',
                                                            },
                                                        },
                                                    ],
                                                },
                                                variant: 'filled',
                                                size: 'medium',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { autoComplete: { type: COMPONENT_DSL_PROP_TYPES.string }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, behavior: { type: COMPONENT_DSL_PROP_TYPES.enum }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, defaultValue: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableUnderline: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, error: { type: COMPONENT_DSL_PROP_TYPES.string }, inputComponent: { type: COMPONENT_DSL_PROP_TYPES.elementType }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, margin: { type: COMPONENT_DSL_PROP_TYPES.enum }, multiline: { type: COMPONENT_DSL_PROP_TYPES.boolean }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'child',
                        path: [1],
                    },
                ],
            }, placeholder: { type: COMPONENT_DSL_PROP_TYPES.string }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean }, rows: { type: COMPONENT_DSL_PROP_TYPES.string }, rowsMax: { type: COMPONENT_DSL_PROP_TYPES.string }, value: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.any,
                },
            }, autoWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, displayEmpty: { type: COMPONENT_DSL_PROP_TYPES.boolean }, native: { type: COMPONENT_DSL_PROP_TYPES.boolean }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, 
            // TODO: Icon component
            IconComponent: { type: COMPONENT_DSL_PROP_TYPES.component, componentName: 'img' }, 
            // TODO: ?
            input: { type: COMPONENT_DSL_PROP_TYPES.elementType }, 
            // TODO: node
            label: { type: COMPONENT_DSL_PROP_TYPES.string }, labelID: { type: COMPONENT_DSL_PROP_TYPES.string }, labelWidth: { type: COMPONENT_DSL_PROP_TYPES.number }, MenuProps: { type: COMPONENT_DSL_PROP_TYPES.object }, SelectDisplayProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign({}, commonStyleProps),
            } }),
    },
    settings: BuilderComponentsMultiselectSettings,
};
