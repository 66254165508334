import { CSSConstantGenerator } from './CSSConstantGenerator';
/**
 * Generate constant style for list of nodes
 */
export class CSSConstantListGenerator {
    constructor(nodeListDSL, componentListDSL) {
        this.cssConstantGenerator = new CSSConstantGenerator(nodeListDSL, componentListDSL);
    }
    /**
     * Goes through the all nodes and generates css constant for all styles in the each node
     */
    generateCSSConstantList(usedNodeListDSL) {
        return Object.values(usedNodeListDSL).reduce((accum, nodeDSL) => {
            return accum + this.cssConstantGenerator.generateCSSConstant(nodeDSL.id);
        }, '');
    }
}
