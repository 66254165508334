import { useEffect, useMemo } from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import {
  AsyncSessionStatusQuery,
  AsyncSessionStatusQueryVariables,
  SystemAsyncSessionStatusResponse,
} from '../__generated__';
import { ASYNC_SESSION_STATUS_QUERY } from '../fragments';
import { APOLLO_OPERATION_CONTEXT, ASYNC_SESSION_STATUSES } from 'src/shared/constants';

type UseAsyncSessionReturn = {
  asyncSession?: SystemAsyncSessionStatusResponse | null;
  error?: ApolloError;
  loading: boolean;
};

export const isSessionCompleted = (status?: string): boolean => {
  return status === ASYNC_SESSION_STATUSES.error || status === ASYNC_SESSION_STATUSES.success;
};

export const useAsyncSession = (sessionId?: string): UseAsyncSessionReturn => {
  const { data, error, refetch } = useQuery<
    AsyncSessionStatusQuery,
    AsyncSessionStatusQueryVariables
  >(ASYNC_SESSION_STATUS_QUERY, {
    context: {
      [APOLLO_OPERATION_CONTEXT.IGNORE_ERROR_MESSAGE]: true,
    },
    variables: {
      sessionId: sessionId as string,
    },
    skip: !sessionId,
  });

  const asyncSession = useMemo(() => data?.system?.asyncSessionStatus, [data]);

  const isLoading = useMemo(() => Boolean(sessionId) && !isSessionCompleted(asyncSession?.status), [
    sessionId,
    asyncSession,
  ]);

  useEffect(() => {
    if (isLoading && !error) {
      const timeoutId = setInterval(refetch, 2500);
      return () => clearInterval(timeoutId);
    }
  }, [isLoading, error, refetch]);

  return {
    asyncSession,
    error,
    loading: isLoading,
  };
};
