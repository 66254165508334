const UI_BUILDER_PREFIX = 'UI_BUILDER';

export const APOLLO_OPERATION_CONTEXT = {
  SUCCESS_MESSAGE: `${UI_BUILDER_PREFIX}_SUCCESS_MESSAGE`,
  ERROR_MESSAGE: `${UI_BUILDER_PREFIX}_ERROR_MESSAGE`,
  IGNORE_ERROR_MESSAGE: `${UI_BUILDER_PREFIX}_IGNORE_ERROR_MESSAGE`,
  ENDPOINT_URL: `${UI_BUILDER_PREFIX}_ENDPOINT_URL`,
  NO_BATCH: `${UI_BUILDER_PREFIX}_NO_BATCH`,
  BATCH_KEY: `${UI_BUILDER_PREFIX}_BATCH_KEY`,
  NO_AUTH: `${UI_BUILDER_PREFIX}_NO_AUTH`,
  AUTH_TOKEN: `${UI_BUILDER_PREFIX}_AUTH_TOKEN`,
  WORKSPACE_ID: `${UI_BUILDER_PREFIX}_WORKSPACE_ID`,
};
