export const APP_ENGINE_AUDIT_SCOPES = {
    node: 'node',
    stateRequest: 'state-request',
    state: 'state',
    auth: 'auth',
    request: 'request',
};
export const APP_ENGINE_AUDIT_TYPES = {
    error: 'error',
    warning: 'warning',
    info: 'info',
};
