import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_HEAD } from './tableHead.settings';
export const tableHead = {
    displayName: 'Table Head',
    name: COMPONENT_DSL_NAMES.tableHead,
    icon: 'htmlTableHead',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'thead',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.table,
                },
            },
        ],
        predefineds: {
            props: {
                style: {
                    height: 36,
                    paddingBottom: 10,
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.tableRow, COMPONENT_DSL_NAMES.Iterator],
            } }),
    },
    settings: TABLE_HEAD,
};
