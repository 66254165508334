import React, { useMemo, useCallback, SyntheticEvent } from 'react';

import { Box } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { pathOr } from 'ramda';

import {
  ComponentSettingNodeDSL,
  ComponentSettingDSL,
  componentSettingSelectors,
  ComponentDSLNameTypes,
  componentListSelectors,
  NodeID,
  NodeDSL,
  ComponentSettingNodeItemDSL,
  ComponentSettingNodeItemPredefinedsDSL,
} from '@builder/schemas';

import { SettingsListItem } from '../../common/components/SettingsListItem';
import { NodeSettingCommonProps } from '../../types';
import { NodeSettingsAddNodeButton } from '../common/NodeSettingsAddNodeButton';
import { useAppDispatch, useComponentListDSL, useNodeListDSL } from 'src/providers/ReduxProvider';
import { Button, CssGrid, Icon, Switch } from 'src/shared/components';
import { DASHBOARD_EVENTS } from 'src/store';

type NodeTypeProps = {
  onUpdateProp: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  setting: ComponentSettingNodeDSL;
  'data-test'?: string;
  selectedNodeDSL: NodeDSL;
  onCreateNode: (nodeData: {
    targetNodeID: NodeID;
    targetPropName: Array<string | number>;
    newComponentName: ComponentDSLNameTypes;
    predefineds?: ComponentSettingNodeItemPredefinedsDSL;
  }) => void;
  onDeleteNode: (nodeData: { nodeID: NodeID }) => void;

  children: (args: {
    settings: ComponentSettingDSL[];
    targetNode: NodeSettingCommonProps['selectedNodeDSL'];
  }) => React.ReactNode;
  prefixKeyPath: Array<string | number>;
};

const NODE_ADD_POPUP_ID = 'nodeTypeAddNode';
const NODE_EDIT_POPUP_ID = 'nodeTypeEditNode';

export const NodeType: React.FC<NodeTypeProps> = ({
  setting,
  prefixKeyPath,
  onUpdateProp,
  children,
  selectedNodeDSL,
  onCreateNode,
  onDeleteNode,
  'data-test': dataTest,
}) => {
  const settingPopupState = usePopupState({ variant: 'popper', popupId: NODE_EDIT_POPUP_ID });
  const componentListPopupState = usePopupState({
    variant: 'popover',
    popupId: NODE_ADD_POPUP_ID,
  });

  const send = useAppDispatch();
  const componentListDSL = useComponentListDSL();
  const nodeListDSL = useNodeListDSL();
  const targetPropChildrenID = useMemo(
    () =>
      pathOr<NodeID>('', [...prefixKeyPath, ...setting.keyPath, 'nodes', 0], selectedNodeDSL.props),
    [prefixKeyPath, selectedNodeDSL.props, setting.keyPath],
  );
  const targetPropChildrenNode = nodeListDSL[targetPropChildrenID];
  const possibleComponents = componentSettingSelectors.getNodeSettingPossibleComponents(setting);
  const targetPropChildrenNodeSettings =
    possibleComponents.find(({ name }) => name === targetPropChildrenNode?.name)?.settings || [];
  const hasMoreThanOnePossibleComponent = componentSettingSelectors.hasMoreThanOnePossibleComponent(
    setting,
  );
  const nodeSettingTitle = targetPropChildrenNode
    ? componentListSelectors.getDisplayName(componentListDSL, {
        componentName: targetPropChildrenNode.name,
      })
    : setting.label;

  const deleteNode = useCallback((): void => {
    onDeleteNode({ nodeID: targetPropChildrenID });
    // not adding prefixKeyPath because it's added higher in onUpdateProp prop
    onUpdateProp({ keyValue: undefined, keyPath: setting.keyPath });
  }, [onDeleteNode, onUpdateProp, setting.keyPath, targetPropChildrenID]);

  const createNode = useCallback(
    (newComponent: ComponentSettingNodeItemDSL) => {
      componentListPopupState.close();

      if (targetPropChildrenID) {
        deleteNode();
      }

      onCreateNode({
        targetNodeID: selectedNodeDSL.id,
        targetPropName: [...prefixKeyPath, ...setting.keyPath],
        newComponentName: newComponent.name,
        predefineds: newComponent.predefineds,
      });

      settingPopupState.open();
    },
    [
      componentListPopupState,
      deleteNode,
      onCreateNode,
      prefixKeyPath,
      selectedNodeDSL.id,
      setting.keyPath,
      settingPopupState,
      targetPropChildrenID,
    ],
  );

  const selectNode = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      send({
        type: DASHBOARD_EVENTS.componentSelect,
        id: targetPropChildrenNode?.id,
      });
    },
    [send, targetPropChildrenNode?.id],
  );

  return (
    <CssGrid>
      <Choose>
        <When condition={!targetPropChildrenNode && !hasMoreThanOnePossibleComponent}>
          <Switch
            checked={Boolean(targetPropChildrenNode)}
            onChange={() => createNode(possibleComponents[0])}
            label={setting.label}
            data-test={`${dataTest}.addItemSwitch`}
          />
        </When>
        <When condition={!targetPropChildrenNode && hasMoreThanOnePossibleComponent}>
          <NodeSettingsAddNodeButton
            possibleComponents={possibleComponents}
            onAdd={createNode}
            buttonText={`Add ${setting.label}`}
            popupState={componentListPopupState}
            data-test={dataTest}
          />
        </When>
        <Otherwise>
          <SettingsListItem
            label={setting.label}
            title={nodeSettingTitle}
            popupState={settingPopupState}
            data-test={dataTest}
          >
            <CssGrid gap={2}>
              {children({
                settings: targetPropChildrenNodeSettings,
                targetNode: targetPropChildrenNode,
              })}
              <Box>
                <Button
                  endIcon={<Icon width={16} height={16} name="openComponent" />}
                  onClick={selectNode}
                >
                  Go to Component
                </Button>
              </Box>
              <If condition={!setting.required}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="error"
                    onClick={deleteNode}
                    data-test={`${dataTest}.popup.deleteBtn`}
                  >
                    Delete
                  </Button>
                </Box>
              </If>
            </CssGrid>
          </SettingsListItem>
        </Otherwise>
      </Choose>
    </CssGrid>
  );
};
