import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import { AppConfiguration, NodeDSL } from '@builder/schemas';

export interface ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
    currentConfiguration: AppConfiguration,
    previousConfiguration: AppConfiguration,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null;
}

export const SCOPE_GLOBAL = 'global';
