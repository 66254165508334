import { generateID } from '@builder/utils';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_ACTION_TYPE, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, LANG_OPTIONS, TRANSLATE_OPTIONS, } from '../../constants';
import { createSelectOptionByValue } from '../../utils';
import { SIZE_POSSIBLE_UNITS, STYLE_SECTION_TYPES, FONT_POSSIBLE_UNITS, BORDER_WIDTH_POSSIBLE_UNITS, BORDER_RADIUS_POSSIBLE_UNITS, GRID_AUTOFLOW_OPTIONS, GRID_PLACE_SELF_OPTIONS, GRID_PLACE_CONTENT_OPTIONS, GRID_PLACE_ITEMS_OPTIONS, } from './constants';
/** @deprecated */
export const getCSSProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.cssEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'css'],
        label: 'CSS',
    },
];
/** @deprecated */
export const getSpacingProps = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Spacing',
        name: STYLE_SECTION_TYPES.spacing,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.spacingEditor,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style'],
                label: 'Spacing',
            },
        ],
    },
];
/** @deprecated */
export const getFlexChildProps = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Flex Child',
        name: STYLE_SECTION_TYPES.spacing,
        expandedByDefault: false,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'flexGrow'],
                label: 'Flex Grow',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'flexShrink'],
                label: 'Flex Shrink',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'flexBasis'],
                label: 'Flex Basis',
                possibleUnits: SIZE_POSSIBLE_UNITS,
            },
        ],
    },
];
/** @deprecated */
export const getGridChildProps = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Grid Child',
        name: STYLE_SECTION_TYPES.spacing,
        expandedByDefault: false,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridArea'],
                label: 'Grid Area',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridColumn'],
                label: 'Grid Column',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridColumnStart'],
                label: 'Grid Column Start',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridColumnEnd'],
                label: 'Grid Column End',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridRow'],
                label: 'Grid Row',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridRowStart'],
                label: 'Grid Row Start',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'gridRowEnd'],
                label: 'Grid Row End',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'placeSelf'],
                label: 'Place Self',
                options: GRID_PLACE_SELF_OPTIONS.map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.radio,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'alignSelf'],
                label: 'Align Self',
                options: [
                    {
                        value: 'start',
                        icon: 'styleAlignStart',
                        tooltip: 'Start',
                    },
                    {
                        value: 'center',
                        icon: 'styleAlignCenter',
                        tooltip: 'Center',
                    },
                    {
                        value: 'end',
                        icon: 'styleAlignEnd',
                        tooltip: 'End',
                    },
                    {
                        value: 'stretch',
                        icon: 'styleAlignStretch',
                        tooltip: 'Stretch',
                    },
                ],
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.radio,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'justifySelf'],
                label: 'Justify Self',
                options: [
                    {
                        value: 'start',
                        icon: 'styleAlignStart',
                        tooltip: 'Start',
                    },
                    {
                        value: 'center',
                        icon: 'styleAlignCenter',
                        tooltip: 'Center',
                    },
                    {
                        value: 'end',
                        icon: 'styleAlignEnd',
                        tooltip: 'End',
                    },
                    {
                        value: 'stretch',
                        icon: 'styleAlignStretch',
                        tooltip: 'Stretch',
                    },
                ],
            },
        ],
    },
];
/** @deprecated */
export const getDisplayProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'display'],
        label: 'Display',
        options: [
            {
                value: 'block',
                icon: 'displayBlock',
                // TODO: ask text for tooltip, now it is copy from webflow
                tooltip: 'Block elements start on a new line and take up the full available width.',
            },
            {
                value: 'flex',
                icon: 'displayFlex',
                // TODO: ask text for tooltip, now it is copy from webflow
                tooltip: 'Flex lays out its child elements on a horizontal or vertical axis',
            },
            {
                value: 'grid',
                icon: 'displayGrid',
                // TODO: ask text for tooltip, now it is copy from webflow
                tooltip: 'Grid lets you place items within rows and columns',
            },
            {
                value: 'inline-block',
                icon: 'displayInlineBlock',
                // TODO: ask text for tooltip, now it is copy from webflow
                tooltip: 'Inline-block behaves like inline, but accepts width and height properties',
            },
            {
                value: 'inline',
                icon: 'displayInline',
                // TODO: ask text for tooltip, now it is copy from webflow
                tooltip: `Inline is the default for text content. The text's font size and line height determine its size`,
            },
            {
                value: 'none',
                icon: 'visibilityOff',
                // TODO: ask text for tooltip, now it is copy from webflow
                tooltip: 'None hides elements',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridTemplate'],
        label: 'Grid Template',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridTemplateAreas'],
        label: 'Grid Template Areas',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridTemplateColumns'],
        label: 'Grid Template Columns',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridTemplateRows'],
        label: 'Grid Template Rows',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridAutoColumns'],
        label: 'Grid Auto Columns',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridAutoRows'],
        label: 'Grid Auto Rows',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridAutoFlow'],
        label: 'Grid Auto Flow',
        options: GRID_AUTOFLOW_OPTIONS.map(createSelectOptionByValue),
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridColumnGap'],
        label: 'Grid Column Gap',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridRowGap'],
        label: 'Grid Row Gap',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'gridGap'],
        label: 'Grid Gap',
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'placeContent'],
        label: 'Place Content',
        options: GRID_PLACE_CONTENT_OPTIONS.map(createSelectOptionByValue),
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'placeItems'],
        label: 'Place Items',
        options: GRID_PLACE_ITEMS_OPTIONS.map(createSelectOptionByValue),
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'alignItems'],
        label: 'Align Items',
        options: [
            {
                value: 'start',
                icon: 'styleAlignStart',
                tooltip: 'Start',
            },
            {
                value: 'center',
                icon: 'styleAlignCenter',
                tooltip: 'Center',
            },
            {
                value: 'end',
                icon: 'styleAlignEnd',
                tooltip: 'End',
            },
            {
                value: 'stretch',
                icon: 'styleAlignStretch',
                tooltip: 'Stretch',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'alignContent'],
        label: 'Align Content',
        options: [
            {
                value: 'start',
                icon: 'styleJustifyStart',
                tooltip: 'Start',
            },
            {
                value: 'center',
                icon: 'styleJustifyCenter',
                tooltip: 'Center',
            },
            {
                value: 'end',
                icon: 'styleJustifyEnd',
                tooltip: 'End',
            },
            {
                value: 'stretch',
                icon: 'styleAlignStretch',
                tooltip: 'Stretch',
            },
            {
                value: 'space-between',
                icon: 'styleJustifyBetween',
                tooltip: 'Space between',
            },
            {
                value: 'space-around',
                icon: 'styleJustifyAround',
                tooltip: 'Space around',
            },
            {
                value: 'space-evenly',
                icon: 'SE',
                isTextIcon: true,
                tooltip: 'Space evenly',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'justifyItems'],
        label: 'Justify Items',
        options: [
            {
                value: 'start',
                icon: 'styleAlignStart',
                tooltip: 'Start',
            },
            {
                value: 'center',
                icon: 'styleAlignCenter',
                tooltip: 'Center',
            },
            {
                value: 'end',
                icon: 'styleAlignEnd',
                tooltip: 'End',
            },
            {
                value: 'stretch',
                icon: 'styleAlignStretch',
                tooltip: 'Stretch',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'justifyContent'],
        label: 'Justify Content',
        options: [
            {
                value: 'start',
                icon: 'styleJustifyStart',
                tooltip: 'Start',
            },
            {
                value: 'center',
                icon: 'styleJustifyCenter',
                tooltip: 'Center',
            },
            {
                value: 'end',
                icon: 'styleJustifyEnd',
                tooltip: 'End',
            },
            {
                value: 'stretch',
                icon: 'styleAlignStretch',
                tooltip: 'Stretch',
            },
            {
                value: 'space-between',
                icon: 'styleJustifyBetween',
                tooltip: 'Space between',
            },
            {
                value: 'space-around',
                icon: 'styleJustifyAround',
                tooltip: 'Space around',
            },
            {
                value: 'space-evenly',
                icon: 'SE',
                isTextIcon: true,
                tooltip: 'Space evenly',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'grid',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'flexDirection'],
        label: 'Direction',
        options: [
            {
                value: 'row',
                icon: 'styleArrowRight',
                tooltip: 'Row',
            },
            {
                value: 'row-reverse',
                icon: 'styleArrowLeft',
                tooltip: 'Row reverse',
            },
            {
                value: 'column-reverse',
                icon: 'styleArrowUp',
                tooltip: 'Column reverse',
            },
            {
                value: 'column',
                icon: 'styleArrowDown',
                tooltip: 'Column',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'flex',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'alignItems'],
        label: 'Align',
        options: [
            {
                value: 'flex-start',
                icon: 'styleAlignStart',
                tooltip: 'Flex start',
            },
            {
                value: 'center',
                icon: 'styleAlignCenter',
                tooltip: 'Center',
            },
            {
                value: 'flex-end',
                icon: 'styleAlignEnd',
                tooltip: 'Flex end',
            },
            {
                value: 'stretch',
                icon: 'styleAlignStretch',
                tooltip: 'Stretch',
            },
            {
                value: 'baseline',
                icon: 'styleAlignBaseline',
                tooltip: 'Baseline',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'flex',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'justifyContent'],
        label: 'Justify',
        options: [
            {
                value: 'flex-start',
                icon: 'styleJustifyStart',
                tooltip: 'Flex start',
            },
            {
                value: 'center',
                icon: 'styleJustifyCenter',
                tooltip: 'Center',
            },
            {
                value: 'flex-end',
                icon: 'styleJustifyEnd',
                tooltip: 'Flex end',
            },
            {
                value: 'space-between',
                icon: 'styleJustifyBetween',
                tooltip: 'Space between',
            },
            {
                value: 'space-around',
                icon: 'styleJustifyAround',
                tooltip: 'Space around',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'flex',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'flexWrap'],
        label: 'Children',
        options: [
            {
                value: 'nowrap',
                icon: 'cross',
                tooltip: 'nowrap',
            },
            {
                value: 'wrap',
                icon: 'Wrap',
                isTextIcon: true,
                tooltip: '',
            },
            {
                value: 'wrap-reverse',
                icon: 'Reverse',
                isTextIcon: true,
                tooltip: '',
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, 'style', 'display'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'flex',
            },
        ],
    },
];
/** @deprecated */
export const getSizeProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'width'],
        name: 'width',
        label: 'Width',
        possibleUnits: SIZE_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'height'],
        name: 'height',
        label: 'Height',
        possibleUnits: SIZE_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'minWidth'],
        label: 'Min Width',
        possibleUnits: SIZE_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'minHeight'],
        label: 'Min Height',
        possibleUnits: SIZE_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'maxWidth'],
        label: 'Max Width',
        possibleUnits: SIZE_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'maxHeight'],
        label: 'Max Height',
        possibleUnits: SIZE_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'overflow'],
        label: 'Overflow',
        options: [
            {
                value: 'visible',
                icon: 'visibilityOn',
                tooltip: 'Visible',
            },
            {
                value: 'hidden',
                icon: 'visibilityOff',
                tooltip: 'Hidden',
            },
            {
                value: 'scroll',
                icon: 'styleScroll',
                tooltip: 'Scroll',
            },
            {
                value: 'auto',
                icon: 'styleAuto',
                tooltip: '',
            },
        ],
    },
];
/** @deprecated */
export const getMarginProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'margin'],
        label: 'All',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'marginLeft'],
        label: 'Left',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'marginRight'],
        label: 'Right',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'marginTop'],
        label: 'Top',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'marginBottom'],
        label: 'Bottom',
    },
];
/** @deprecated */
export const getPaddingProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'padding'],
        label: 'All',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'paddingLeft'],
        label: 'Left',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'paddingRight'],
        label: 'Right',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'paddingTop'],
        label: 'Top',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'paddingBottom'],
        label: 'Bottom',
    },
];
/** @deprecated */
export const getFontProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'fontFamily'],
        label: 'Font',
        options: [
            {
                label: 'Serif',
                value: 'serif',
            },
            {
                label: 'Sans-serif',
                value: 'sans-serif',
            },
            {
                label: 'Monospace',
                value: 'monospace',
            },
            {
                label: 'Arial',
                value: 'arial',
            },
            {
                label: 'Arial Black',
                value: 'arial-black',
            },
            {
                label: 'Verdana',
                value: 'verdana',
            },
            {
                label: 'Times New Roman',
                value: 'times-new-roman',
            },
            {
                label: 'Didot',
                value: 'didot',
            },
            {
                label: 'Georgia',
                value: 'georgia',
            },
            {
                label: 'American Typewriter',
                value: 'American Typewriter',
            },
            {
                label: 'Courier',
                value: 'Courier',
            },
            {
                label: 'Monaco',
                value: 'Monaco',
            },
            {
                label: 'Bradley Hand',
                value: 'Bradley Hand',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'fontWeight'],
        label: 'Weight',
        options: [
            {
                label: '100 - Thin',
                value: 100,
            },
            {
                label: '200 - Extra Light',
                value: 200,
            },
            {
                label: '300 - Light',
                value: 300,
            },
            {
                label: '400 - Normal',
                value: 400,
            },
            {
                label: '500 - Medium',
                value: 500,
            },
            {
                label: '600 - Semi Bold',
                value: 600,
            },
            {
                label: '700 - Bold',
                value: 700,
            },
            {
                label: '800 - Extra Bold',
                value: 800,
            },
            {
                label: '900 - Black',
                value: 900,
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'fontSize'],
        label: 'Size',
        possibleUnits: FONT_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'lineHeight'],
        label: 'Line height',
        possibleUnits: FONT_POSSIBLE_UNITS,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'color'],
        label: 'Color',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'textAlign'],
        label: 'Align',
        options: [
            {
                value: 'left',
                icon: 'styleTextAlignLeft',
                tooltip: 'Left',
            },
            {
                value: 'center',
                icon: 'styleTextAlignCenter',
                tooltip: 'Center',
            },
            {
                value: 'right',
                icon: 'styleTextAlignRight',
                tooltip: 'Right',
            },
            {
                value: 'justify',
                icon: 'styleTextAlignJustify',
                tooltip: 'Justify',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'fontStyle'],
        label: 'Italicize',
        options: [
            {
                value: 'normal',
                icon: 'styleFontNormal',
                tooltip: 'Normal',
            },
            {
                value: 'italic',
                icon: 'styleFontItalic',
                tooltip: 'Italic',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'textDecoration'],
        label: 'Decoration',
        options: [
            {
                value: 'none',
                icon: 'cross',
                tooltip: '',
            },
            {
                value: 'line-through',
                icon: 'styleTextDecorationStrike',
                tooltip: 'Line through',
            },
            {
                value: 'underline',
                icon: 'styleTextDecorationUnderline',
                tooltip: 'Underline',
            },
            {
                value: 'overline',
                icon: 'styleTextDecorationOverline',
                tooltip: 'Overline',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'textTransform'],
        label: 'Capitalize',
        options: [
            {
                value: 'none',
                icon: 'cross',
                tooltip: '',
            },
            {
                value: 'uppercase',
                icon: 'styleTextTransformUppercase',
                tooltip: 'Uppercase',
            },
            {
                value: 'capitalize',
                icon: 'styleTextDecorationCapitalize',
                tooltip: 'Capitalize',
            },
            {
                value: 'lowercase',
                icon: 'styleTextDecorationLowercase',
                tooltip: 'Lowercase',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'direction'],
        label: 'Direction',
        options: [
            {
                value: 'none',
                icon: 'styleTextDirectionLtr',
                tooltip: 'ltr',
            },
            {
                value: 'rtl',
                icon: 'styleTextDirectionRtl',
                tooltip: 'rtl',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'whiteSpace'],
        label: 'Breaking',
        options: [
            {
                value: 'normal',
                label: 'Normal',
            },
            {
                value: 'nowrap',
                label: 'No Wrap',
            },
            {
                value: 'pre',
                label: 'Pre',
            },
            {
                value: 'pre-wrap',
                label: 'Pre Wrap',
            },
            {
                value: 'break-spaces',
                label: 'Break Spaces',
            },
        ],
    },
];
/** @deprecated */
export const getBackgroundProps = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'backgroundColor'],
        label: 'Color',
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'backgroundImage'],
        label: 'Image',
    },
];
/** @deprecated */
const getBorderSetting = ({ prefixPath = [], prefixStyle, showIf, }) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        label: 'Style',
        keyPath: [...prefixPath, 'style', `${prefixStyle}Style`],
        options: [
            {
                value: 'none',
                icon: 'cross',
                tooltip: '',
            },
            {
                value: 'solid',
                icon: 'styleBorderStyleSolid',
                tooltip: '',
            },
            {
                value: 'dashed',
                icon: 'styleBorderStyleDashed',
                tooltip: '',
            },
            {
                value: 'dotted',
                icon: 'styleBorderStyleDotted',
                tooltip: '',
            },
        ],
        showIf,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', `${prefixStyle}Width`],
        label: 'Width',
        possibleUnits: BORDER_WIDTH_POSSIBLE_UNITS,
        showIf,
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.colorEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', `${prefixStyle}Color`],
        label: 'Color',
        showIf,
    },
];
/** @deprecated */
export const getBorderProps = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.mixed,
        label: 'Radius',
        name: 'radius',
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.radio,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, '__borderRadiusMode'],
                options: [
                    {
                        value: 'singleValue',
                        icon: 'styleBorderRadiusSingleValue',
                        tooltip: '',
                    },
                    {
                        value: 'separateValues',
                        icon: 'styleBorderRadiusSeparateValues',
                        tooltip: '',
                    },
                ],
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefixPath, 'style', 'borderRadius'],
                possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'borderTopLeftRadius'],
        label: 'Top left',
        icon: 'styleTopLeftCorner',
        possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
        showIf: [
            {
                keyPath: [...prefixPath, '__borderRadiusMode'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'separateValues',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'borderTopRightRadius'],
        label: 'Top right',
        icon: 'styleTopRightCorner',
        possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
        showIf: [
            {
                keyPath: [...prefixPath, '__borderRadiusMode'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'separateValues',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'borderBottomRightRadius'],
        label: 'Bottom right',
        icon: 'styleBottomRightCorner',
        possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
        showIf: [
            {
                keyPath: [...prefixPath, '__borderRadiusMode'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'separateValues',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'style', 'borderBottomLeftRadius'],
        label: 'Bottom left',
        icon: 'styleBottomLeftCorner',
        possibleUnits: BORDER_RADIUS_POSSIBLE_UNITS,
        showIf: [
            {
                keyPath: [...prefixPath, '__borderRadiusMode'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'separateValues',
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.radio,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, '__borderMode'],
        options: [
            {
                value: 'singleValue',
                icon: 'styleBorderSingleValue',
                tooltip: '',
            },
            {
                value: 'separateValues',
                icon: 'styleBorderSeparateValues',
                tooltip: '',
            },
        ],
    },
    ...getBorderSetting({
        prefixPath,
        prefixStyle: 'border',
        showIf: [
            {
                keyPath: [...prefixPath, '__borderMode'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'singleValue',
            },
        ],
    }),
    {
        type: COMPONENT_SETTING_TYPES.popupsSection,
        name: 'borderSettings',
        children: [
            {
                type: COMPONENT_SETTING_TYPES.popup,
                name: 'borderTop',
                label: 'Top',
                title: 'Top border',
                icon: 'styleBorderTop',
                children: getBorderSetting({
                    prefixPath,
                    prefixStyle: 'borderTop',
                    showIf: [
                        {
                            keyPath: [...prefixPath, '__borderMode'],
                            type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                            value: 'separateValues',
                        },
                    ],
                }),
            },
            {
                type: COMPONENT_SETTING_TYPES.popup,
                name: 'borderRight',
                label: 'Right',
                title: 'Right border',
                icon: 'styleBorderRight',
                children: getBorderSetting({
                    prefixPath,
                    prefixStyle: 'borderRight',
                    showIf: [
                        {
                            keyPath: [...prefixPath, '__borderMode'],
                            type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                            value: 'separateValues',
                        },
                    ],
                }),
            },
            {
                type: COMPONENT_SETTING_TYPES.popup,
                name: 'borderBottom',
                label: 'Bottom',
                title: 'Bottom border',
                icon: 'styleBorderBottom',
                children: getBorderSetting({
                    prefixPath,
                    prefixStyle: 'borderBottom',
                    showIf: [
                        {
                            keyPath: [...prefixPath, '__borderMode'],
                            type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                            value: 'separateValues',
                        },
                    ],
                }),
            },
            {
                type: COMPONENT_SETTING_TYPES.popup,
                name: 'borderLeft',
                label: 'Left',
                title: 'Left border',
                icon: 'styleBorderLeft',
                children: getBorderSetting({
                    prefixPath,
                    prefixStyle: 'borderLeft',
                    showIf: [
                        {
                            keyPath: [...prefixPath, '__borderMode'],
                            type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                            value: 'separateValues',
                        },
                    ],
                }),
            },
        ],
        showIf: [
            {
                keyPath: [...prefixPath, '__borderMode'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: 'separateValues',
            },
        ],
    },
];
/** @deprecated */
export const getStateSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Local States',
    name: 'localStates',
    expandedByDefault: false,
    children: [
        {
            type: COMPONENT_SETTING_TYPES.state,
            label: 'Connected States',
            name: 'state',
        },
    ],
});
/** @deprecated */
export const getConditionSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Conditional Rendering',
    name: 'condition',
    expandedByDefault: false,
    children: [
        {
            type: COMPONENT_SETTING_TYPES.condition,
            label: 'Show If',
            name: 'showIf',
        },
    ],
});
/** @deprecated */
export const getDivider = (name, devMode) => ({
    type: COMPONENT_SETTING_TYPES.divider,
    name: name || generateID(4),
    devMode,
});
/** @deprecated */
export const getLooperSection = () => ({
    type: COMPONENT_SETTING_TYPES.section,
    title: 'Loop',
    name: 'loopSection',
    expandedByDefault: false,
    children: [
        {
            type: COMPONENT_SETTING_TYPES.iterator,
            dataLabel: 'Loop Data',
            itemNameLabel: 'Item Name',
            name: 'loop',
        },
    ],
});
/** @deprecated */
export const getCommonPropertiesSections = () => [getConditionSection()];
/** @deprecated */
export const getActionSettings = (prefixPath = [], additionalSettings = []) => [
    {
        type: COMPONENT_SETTING_TYPES.eventsSection,
        name: 'events',
        title: 'Events',
        children: [...additionalSettings, ...getCommonActionListSettings(prefixPath)],
    },
];
/** @deprecated */
export const getCommonActionListSettings = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onClickAction',
        label: 'On Click',
        keyPath: [...prefixPath, 'onClick'],
        children: getCommonActionSettings([...prefixPath, 'onClick']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseOverAction',
        label: 'On Mouse Over',
        keyPath: [...prefixPath, 'onMouseOver'],
        children: getCommonActionSettings([...prefixPath, 'onMouseOver']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseOutAction',
        label: 'On Mouse Out',
        keyPath: [...prefixPath, 'onMouseOut'],
        children: getCommonActionSettings([...prefixPath, 'onMouseOut']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseDownAction',
        label: 'On Mouse Down',
        keyPath: [...prefixPath, 'onMouseDown'],
        children: getCommonActionSettings([...prefixPath, 'onMouseDown']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseUpAction',
        label: 'On Mouse Up',
        keyPath: [...prefixPath, 'onMouseUp'],
        children: getCommonActionSettings([...prefixPath, 'onMouseUp']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseEnterAction',
        label: 'On Mouse Enter',
        keyPath: [...prefixPath, 'onMouseEnter'],
        children: getCommonActionSettings([...prefixPath, 'onMouseEnter']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseLeaveAction',
        label: 'On Mouse Leave',
        keyPath: [...prefixPath, 'onMouseLeave'],
        children: getCommonActionSettings([...prefixPath, 'onMouseLeave']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onWheelAction',
        label: 'Mouse Wheel',
        keyPath: [...prefixPath, 'onWheel'],
        children: getCommonActionSettings([...prefixPath, 'onWheel']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onContextMenuAction',
        label: 'Context',
        keyPath: [...prefixPath, 'onContextMenu'],
        children: getCommonActionSettings([...prefixPath, 'onContextMenu']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onAuxClickAction',
        label: 'Aux Click',
        keyPath: [...prefixPath, 'onAuxClick'],
        children: getCommonActionSettings([...prefixPath, 'onAuxClick']),
    },
];
export const getCommonActionSettings = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'actionType'],
        resetPathBeforeOnChange: [[...prefixPath, 'args']],
        label: 'Type',
        options: [
            {
                value: COMPONENT_SETTING_ACTION_TYPE.changePage,
                label: 'Change Page',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                label: 'Custom Code',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.closePopup,
                label: 'Close Popup',
                showIf: [
                    {
                        type: COMPONENT_SETTING_CONDITION_TYPES.hasAncestors,
                        value: [
                            COMPONENT_DSL_NAMES.BuilderComponentsMenu,
                            COMPONENT_DSL_NAMES.BuilderComponentsPopover,
                            COMPONENT_DSL_NAMES.BuilderComponentsPopper,
                        ],
                    },
                ],
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        label: 'Page',
        keyPath: [...prefixPath, 'args', 'url'],
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.changePage,
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.jsEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'args', 'code'],
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.customCode,
            },
        ],
        defaultValue: '// Your JavaScript code here',
        label: 'Custom Code',
    },
];
export const getBasicPropsSettings = (prefix = [], expandedByDefault = false) => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Common',
        name: 'Common',
        expandedByDefault,
        titleDescription: {
            title: 'Common',
            subtitle: 'Common properties for all components',
        },
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefix, 'id'],
                label: 'Id',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefix, 'title'],
                label: 'Title',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.text,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefix, 'className'],
                label: 'Class',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefix, 'lang'],
                label: 'Language',
                options: LANG_OPTIONS.map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: [...prefix, 'translate'],
                label: 'Translate',
                options: TRANSLATE_OPTIONS.map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['draggable'],
                label: 'Draggable',
            },
            getDivider(),
            {
                type: COMPONENT_SETTING_TYPES.section,
                name: 'customPropsSection',
                title: 'Custom Properties',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.list,
                        keyPath: ['customProps'],
                        name: 'customProps',
                        label: 'Custom Properties',
                        itemLabelKeyPath: ['Key'],
                        newObjectPredefineds: {
                            Key: 'propname',
                            Value: 'value',
                        },
                        settings: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['Key'],
                                label: 'Key',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['Value'],
                                label: 'Value',
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
