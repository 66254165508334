import React, { forwardRef, useState, useCallback } from 'react';

import { Chip, FormControl, MenuItem, SelectProps as MuiSelectProps } from '@mui/material';
import { without } from 'ramda';

import { ComponentSettingSelectOption } from '@builder/schemas';

import { MENU_PROPS } from './MultipleSelectChip.constants';
import {
  StyledInputLabel,
  HiddenSelect,
  AddButton,
  ChipsContainer,
} from './MultipleSelectChip.styles';

export type MultipleSelectChipProps = Omit<MuiSelectProps, 'value' | 'onChange' | 'label'> & {
  label: string;
  value: string[];
  options: ComponentSettingSelectOption[];
  onChange: (value: string[]) => void;
};

export const MultipleSelectChip = forwardRef<HTMLDivElement, MultipleSelectChipProps>(
  ({ value: fieldValue = [], onChange, options, label, ...rest }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpened = Boolean(anchorEl);

    const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = useCallback(() => setAnchorEl(null), []);

    const handleChange = useCallback(
      event => {
        const {
          target: { value },
        } = event;

        onChange(value);
      },
      [onChange],
    );

    const onDelete = useCallback(
      val => {
        const newValue = without(val, fieldValue);
        onChange(newValue);
      },
      [onChange, fieldValue],
    );

    return (
      <div>
        {label && <StyledInputLabel title={label} />}
        <FormControl ref={ref}>
          <ChipsContainer>
            {fieldValue.map(item => (
              <Chip key={item} label={item} onDelete={() => onDelete(item)} />
            ))}
            <AddButton onClick={openMenu}>Add</AddButton>
          </ChipsContainer>
          <HiddenSelect
            multiple
            value={fieldValue}
            onChange={handleChange}
            MenuProps={{
              anchorEl,
              ...MENU_PROPS,
            }}
            open={isMenuOpened}
            onClose={closeMenu}
            {...rest}
          >
            {options.map((option: ComponentSettingSelectOption) => (
              <MenuItem key={option.value as string} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </HiddenSelect>
        </FormControl>
      </div>
    );
  },
);
