import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_SETTINGS } from './table.settings';
export const table = {
    displayName: 'Table',
    name: COMPONENT_DSL_NAMES.table,
    icon: 'htmlTable',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'table',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: {
                    height: 36,
                    paddingBottom: 10,
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.tableColumnGroup,
                        },
                        {
                            name: COMPONENT_DSL_NAMES.tableCaption,
                            props: {
                                style: {
                                    height: 36,
                                    paddingBottom: 10,
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                            props: {
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'Table caption',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            name: COMPONENT_DSL_NAMES.tableHead,
                            props: {
                                style: {
                                    height: 36,
                                    paddingBottom: 10,
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.tableRow,
                                            props: {
                                                style: {
                                                    height: 36,
                                                    paddingBottom: 10,
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableHeader,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Header 1',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableHeader,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Header 2',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableHeader,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Header 3',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            name: COMPONENT_DSL_NAMES.tableBody,
                            props: {
                                children: {
                                    style: {
                                        height: 36,
                                        paddingBottom: 10,
                                    },
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.tableRow,
                                            props: {
                                                style: {
                                                    height: 36,
                                                    paddingBottom: 10,
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 1',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 2',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 3',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                        {
                                            name: COMPONENT_DSL_NAMES.tableRow,
                                            props: {
                                                style: {
                                                    height: 36,
                                                    paddingBottom: 10,
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 1a',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 2a',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 3a',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                        {
                                            name: COMPONENT_DSL_NAMES.tableRow,
                                            props: {
                                                style: {
                                                    height: 36,
                                                    paddingBottom: 10,
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 1b',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 2b',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: {
                                                                                                children: 'Data 3b',
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            name: COMPONENT_DSL_NAMES.tableFoot,
                            props: {
                                style: {
                                    height: 36,
                                    paddingBottom: 10,
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.tableRow,
                                            props: {
                                                style: {
                                                    height: 36,
                                                    paddingBottom: 10,
                                                },
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: { children: 'Foot1' },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: { children: 'Foot2' },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                        {
                                                            name: COMPONENT_DSL_NAMES.tableDataCell,
                                                            props: {
                                                                style: {
                                                                    height: 36,
                                                                    paddingBottom: 10,
                                                                },
                                                                children: {
                                                                    nodes: [
                                                                        {
                                                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                                            props: {
                                                                                children: {
                                                                                    nodes: [
                                                                                        {
                                                                                            name: COMPONENT_DSL_NAMES.Text,
                                                                                            props: { children: 'Foot3' },
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.tableCaption,
                    COMPONENT_DSL_NAMES.tableColumnGroup,
                    COMPONENT_DSL_NAMES.tableHead,
                    COMPONENT_DSL_NAMES.tableBody,
                    COMPONENT_DSL_NAMES.tableRow,
                    COMPONENT_DSL_NAMES.tableFoot,
                ],
            } }),
    },
    settings: TABLE_SETTINGS,
};
