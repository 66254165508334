import { COMPONENT_DSL_PROP_TYPES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_3_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.3.0';
    }
    getChangeList() {
        return [`Add additional information to the css prop DSL`];
    }
    migratePropDSL(propDSL) {
        if (propDSL.type === COMPONENT_DSL_PROP_TYPES.css) {
            return Object.assign(Object.assign({}, propDSL), { targetStylePropName: 'style' });
        }
        return propDSL;
    }
}
export const migration_0_3_0 = new Migration_0_3_0();
