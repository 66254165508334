import { isUndefined } from '@builder/utils';
import { createStateRequestError } from '../app-audit';
import { useRuntimeErrorCallbacks } from '../providers';
import { createAndRunFunction } from './createAndRunFunction';
export const useTransformData = ({ libraries }) => {
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    return ({ appStateDSL, appRuntimeState, }, response) => {
        const { args: { transformer = '', onTransformerFunction: transformerFunction, typeOnTransformer }, id: stateID, } = appStateDSL;
        if (!transformer && typeOnTransformer === 'code') {
            return response;
        }
        if (!transformerFunction && typeOnTransformer === 'function') {
            return response;
        }
        const transformedData = createAndRunFunction(typeOnTransformer === 'code' || !typeOnTransformer
            ? transformer
            : `return ${transformerFunction === null || transformerFunction === void 0 ? void 0 : transformerFunction.split('(')[0]}(data)`, Object.assign(Object.assign({}, appRuntimeState), { localState: Object.assign(Object.assign({}, appRuntimeState === null || appRuntimeState === void 0 ? void 0 : appRuntimeState.localState), { data: response }) }), {
            libraries,
            throwReactRenderError: true,
            onAppFunctionError: error => onAppAuditNotifications([
                createStateRequestError({
                    message: error.message,
                    stateID,
                    propPath: ['args', 'transformer'],
                }),
            ]),
        });
        if (isUndefined(transformedData))
            return response;
        return transformedData;
    };
};
