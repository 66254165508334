import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getStylesSection, getActionSettings } from '../../common';
export const BaseLayoutSettings = {
    properties: [],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
