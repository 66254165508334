import { QueryResult, useQuery } from '@apollo/client';

import { useCurrentWorkspaceID } from '../../hooks';
import { DeployQuery, DeployQueryVariables } from '../__generated__';
import { DEPLOY_QUERY } from '../fragments';

export const useDeploy = (
  buildName: string,
  isDeploying: boolean,
): QueryResult<DeployQuery, DeployQueryVariables> => {
  const { workspaceID } = useCurrentWorkspaceID();
  return useQuery<DeployQuery, DeployQueryVariables>(DEPLOY_QUERY, {
    variables: {
      workspaceId: workspaceID as string,
      buildName,
    },
    skip: !isDeploying && buildName === '',
  });
};
