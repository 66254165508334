import { ASSET_TYPES } from '@builder/schemas';
import { AssetGenerator } from './AssetGenerator';
import { FontAssetGenerator } from './FontAssetGenerator';
export class AssetGeneratorFactory {
    static create(assetFileDSL) {
        switch (assetFileDSL.type) {
            case ASSET_TYPES.font: {
                return new FontAssetGenerator(assetFileDSL);
            }
            default:
                return new AssetGenerator(assetFileDSL);
        }
    }
}
