import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  ListNode,
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $isHeadingNode,
  $createQuoteNode,
  $createHeadingNode,
  HeadingTagType,
} from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatQuote as FormatQuoteIcon,
  Link as LinkIcon,
  Redo as RedoIcon,
  Undo as UndoIcon,
} from '@mui/icons-material';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
  $createParagraphNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { CUSTOM_TEXT_FORMATS, HEADINGS_FORMATS, PARAGRAPH, TEXT_FORMATS } from './constants';
import FloatingLinkEditor from './FloatingLinkEditor';
import { getSelectedNode } from './utils';

const EditorButton = React.forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    isActive: boolean;
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    value: string;
  }
>((props, ref) => {
  const { isActive, children, disabled, value, onClick } = props;

  return (
    <ToggleButton
      ref={ref}
      value={value}
      size="small"
      selected={isActive}
      disabled={disabled}
      onClick={e => onClick(e)}
    >
      {children}
    </ToggleButton>
  );
});

const StyledFormControl = styled(FormControl)`
  grid-column: 1/5;

  & {
    .MuiSelect-root {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`;

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-input {
    padding: 0;
  }
`;

export default function Toolbar(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const linkBtnRef = useRef<HTMLButtonElement | null>(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [linkPopperAnchorEl, setLinkPopperAnchorEl] = useState<HTMLElement | null>(null);
  const [blockType, setBlockType] = useState(PARAGRAPH);
  const [formats, setFormats] = useState(['']);

  const getBlockType = useCallback(
    (selection: RangeSelection) => {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();

          return type;
        }

        const type = $isHeadingNode(element) ? element.getTag() : element.getType();

        return type;
      }

      return PARAGRAPH;
    },
    [editor],
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      // setBlockType
      const currentBlockType = getBlockType(selection);
      setBlockType(currentBlockType);

      // update formats state
      const isBold = selection.hasFormat(TEXT_FORMATS.BOLD);
      const isItalic = selection.hasFormat(TEXT_FORMATS.ITALIC);
      const isUnorderedList = currentBlockType === CUSTOM_TEXT_FORMATS.UL;
      const isOrderedList = currentBlockType === CUSTOM_TEXT_FORMATS.OL;
      const isQuote = currentBlockType === CUSTOM_TEXT_FORMATS.QUOTE;
      const isLink = $isLinkNode(parent) || $isLinkNode(node);

      const selectionFormats: Array<string> = [
        { format: TEXT_FORMATS.BOLD, value: isBold },
        { format: TEXT_FORMATS.ITALIC, value: isItalic },
        { format: CUSTOM_TEXT_FORMATS.UL, value: isUnorderedList },
        { format: CUSTOM_TEXT_FORMATS.OL, value: isOrderedList },
        { format: CUSTOM_TEXT_FORMATS.QUOTE, value: isQuote },
        { format: CUSTOM_TEXT_FORMATS.LINK, value: isLink },
      ]
        .map(({ format, value }) => (value ? format : ''))
        .filter(Boolean);

      setFormats(selectionFormats);
    }
  }, [getBlockType]);

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    });
  }, [updateToolbar, editor]);

  const updateTextSize = useCallback(
    (tag: HeadingTagType | typeof PARAGRAPH) => {
      if (tag === PARAGRAPH) {
        if (blockType !== PARAGRAPH) {
          editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
              $setBlocksType(selection, () => $createParagraphNode());
            }
          });
        }

        return;
      }

      if (blockType !== tag) {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode(tag));
          }
        });
      }
    },
    [blockType, editor],
  );

  // Save history
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        _payload => {
          updateToolbar();
          return false;
        },
        1,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        1,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        1,
      ),
    );
  }, [editor, updateToolbar]);

  return (
    <>
      <StyledFormControl variant="outlined">
        <StyledSelect IconComponent={KeyboardArrowDownIcon} defaultValue="plain">
          <MenuItem value="plain" onClick={() => updateTextSize(PARAGRAPH)}>
            Normal
          </MenuItem>
          <MenuItem value="h1" onClick={() => updateTextSize(HEADINGS_FORMATS.H1)}>
            Heading 1
          </MenuItem>
          <MenuItem value="h2" onClick={() => updateTextSize(HEADINGS_FORMATS.H2)}>
            Heading 2
          </MenuItem>
          <MenuItem value="h3" onClick={() => updateTextSize(HEADINGS_FORMATS.H3)}>
            Heading 3
          </MenuItem>
          <MenuItem value="h4" onClick={() => updateTextSize(HEADINGS_FORMATS.H4)}>
            Heading 4
          </MenuItem>
          <MenuItem value="h5" onClick={() => updateTextSize(HEADINGS_FORMATS.H5)}>
            Heading 5
          </MenuItem>
          <MenuItem value="h6" onClick={() => updateTextSize(HEADINGS_FORMATS.H6)}>
            Heading 6
          </MenuItem>
        </StyledSelect>
      </StyledFormControl>

      <Button
        css={{ gridColumn: '5/6', maxWidth: '40px', minWidth: '40px' }}
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
      >
        <UndoIcon />
      </Button>
      <Button
        css={{ gridColumn: '6/7', maxWidth: '40px', minWidth: '40px' }}
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
      >
        <RedoIcon />
      </Button>

      <ToggleButtonGroup css={{ gridColumn: '1/7', justifyContent: 'space-between' }}>
        <EditorButton
          value="bold"
          isActive={formats.includes(TEXT_FORMATS.BOLD)}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, TEXT_FORMATS.BOLD);
          }}
        >
          <FormatBoldIcon />
        </EditorButton>
        <EditorButton
          value="italic"
          isActive={formats.includes(TEXT_FORMATS.ITALIC)}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, TEXT_FORMATS.ITALIC);
          }}
        >
          <FormatItalicIcon />
        </EditorButton>
        <EditorButton
          value="ul"
          isActive={formats.includes(CUSTOM_TEXT_FORMATS.UL)}
          onClick={() => {
            if (blockType !== CUSTOM_TEXT_FORMATS.UL) {
              editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
            } else {
              editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            }
          }}
        >
          <FormatListBulletedIcon />
        </EditorButton>
        <EditorButton
          value="ol"
          isActive={formats.includes(CUSTOM_TEXT_FORMATS.OL)}
          onClick={() => {
            if (blockType !== CUSTOM_TEXT_FORMATS.OL) {
              editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
            } else {
              editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            }
          }}
        >
          <FormatListNumberedIcon />
        </EditorButton>
        <EditorButton
          value="quote"
          isActive={formats.includes(CUSTOM_TEXT_FORMATS.QUOTE)}
          onClick={() => {
            if (blockType !== CUSTOM_TEXT_FORMATS.QUOTE) {
              editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                  $setBlocksType(selection, () => $createQuoteNode());
                }
              });
            } else {
              editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                  $setBlocksType(selection, () => $createParagraphNode());
                }
              });
            }
          }}
        >
          <FormatQuoteIcon />
        </EditorButton>
        <EditorButton
          ref={linkBtnRef}
          value="link"
          isActive={formats.includes(CUSTOM_TEXT_FORMATS.LINK)}
          onClick={() => {
            if (!formats.includes(CUSTOM_TEXT_FORMATS.LINK)) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
            }

            setLinkPopperAnchorEl(linkBtnRef.current);
          }}
        >
          <LinkIcon />
        </EditorButton>
      </ToggleButtonGroup>

      {linkPopperAnchorEl ? (
        <FloatingLinkEditor
          anchorEl={linkPopperAnchorEl}
          editor={editor}
          hideEditor={() => setLinkPopperAnchorEl(null)}
        />
      ) : null}
    </>
  );
}
