import assets from './assets.json';
import basicExample from './basicExample.json';
import { emptyExample } from './emptyExample';
import formarray from './formarray.json';
import functions from './functions.json';
import looper from './looper.json';
import modalExample from './modalExample.json';
import modals from './modals.json';
import onboarding from './onboarding.json';
import resources from './resources.json';
import routing from './routing.json';
import state from './state.json';
import styling from './styling.json';
export { themes } from './themes';
export const examples = {
    emptyExample,
    basicExample,
    onboarding,
    assets,
    formarray,
    functions,
    looper,
    modalExample,
    modals,
    resources,
    styling,
    routing,
    state,
};
