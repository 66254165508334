import { Completion, CompletionContext, CompletionResult } from '@codemirror/autocomplete';

const countPointsInString = (str = ''): number => {
  return str.split('.').length - 1;
};

const countCharsUntilFirstWordWithDots = (text: string): number => {
  const regex = /\{{2}\s*([\w.\s]*)/;
  const match = text.match(regex);

  if (match) {
    return match[0].length - match[1].length;
  }

  return 0;
};

const isPositionBetweenDoubleCurlyBraces = (text = '', position = 0): boolean => {
  const openCurlyIndex = text.lastIndexOf('{{', position);
  const closeCurlyIndex = text.indexOf('}}', position);

  return openCurlyIndex !== -1 && closeCurlyIndex !== -1 && openCurlyIndex < closeCurlyIndex;
};

const customCompletions = (
  context: CompletionContext,
  options?: Completion[],
): CompletionResult | null => {
  const word = context.matchBefore(/[\w{}.]*/);
  // check if the word is enclosed in double curly braces
  const isDoubleCurlyBraces = isPositionBetweenDoubleCurlyBraces(
    context.state.sliceDoc(),
    context.pos,
  );
  let fromIncrement = 0;
  let filteredOptions: Completion[] | undefined = [];
  if (isDoubleCurlyBraces) {
    fromIncrement = countCharsUntilFirstWordWithDots(word?.text || '');
    filteredOptions = options?.filter(option => {
      return countPointsInString(option.label) === countPointsInString(word?.text);
    });
  }

  return {
    from: (word?.from || 0) + fromIncrement,
    options: filteredOptions,
  } as CompletionResult;
};

export default customCompletions;
