import { EventPayload } from '@8base-private/event-handler';

import { NodeDSL } from '@builder/schemas';

import { ActionScopeStrategy, SCOPE_GLOBAL } from './ActionScopeStrategy';

export class SystemAndLayoutsStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null {
    const { path } = currentRouteNode.props as { path?: string };
    if (path?.includes('/__layouts/__')) {
      return { eventPayload: { ...eventPayload, scope: SCOPE_GLOBAL }, newEventsToPush: [] };
    }

    return null;
  }
}
