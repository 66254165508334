/* eslint-disable no-case-declarations */
/* eslint-disable no-template-curly-in-string */
import dayjs from 'dayjs';
import * as Yup from 'yup';
const generateYupType = (type) => {
    switch (type) {
        case 'boolean':
            return Yup.boolean();
        case 'number':
            return Yup.number();
        case 'date':
            return Yup.date();
        case 'string':
        default:
            return Yup.string();
    }
};
const createDynamicErrorMessage = (template, replacements) => Object.keys(replacements).reduce((errorMessage, key) => {
    const replacementValue = replacements[key];
    const stringValue = replacementValue instanceof Date ? replacementValue.toISOString() : String(replacementValue);
    return errorMessage.split(key).join(stringValue);
}, template);
const testFunctions = {
    Required: (_, schema, __, errorMessage) => schema.required(errorMessage),
    // String Type Cases
    Email: (_, schema, __, errorMessage) => schema.email(errorMessage),
    Url: (_, schema, __, errorMessage) => schema.url(errorMessage),
    uuid: (_, schema, __, errorMessage) => schema.uuid(errorMessage),
    Matches: (_, schema, __, errorMessage, ___, ____, regex) => schema.matches(new RegExp(regex), errorMessage),
    Length: (fieldKeys, schema, __, errorMessage, arg, limit) => {
        if (limit) {
            return schema.length(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(limit), errorMessage
                ? ({ length }) => createDynamicErrorMessage(errorMessage, { '${length}': length })
                : undefined);
        }
        return schema;
    },
    // Number Type Cases
    Positive: (_, schema, __, errorMessage) => schema.positive(errorMessage),
    Negative: (_, schema, __, errorMessage) => schema.negative(errorMessage),
    Integer: (_, schema, __, errorMessage) => schema.integer(errorMessage),
    LessThan: (fieldKeys, schema, __, errorMessage, arg, ___, ____, _____, max) => {
        if (max) {
            return schema.lessThan(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(max), errorMessage
                ? ({ less }) => createDynamicErrorMessage(errorMessage, { '${less}': less })
                : undefined);
        }
        return schema;
    },
    MoreThan: (fieldKeys, schema, __, errorMessage, arg, ____, _____, min, ______) => {
        if (min) {
            return schema.moreThan(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(min), errorMessage
                ? ({ more }) => createDynamicErrorMessage(errorMessage, { '${more}': more })
                : undefined);
        }
        return schema;
    },
    // Mixed Type Cases (String, Number and Date)
    Min: (fieldKeys, schema, type, errorMessage, arg, limit) => {
        if (limit) {
            switch (type) {
                case 'string':
                    return schema.min(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(limit), errorMessage
                        ? ({ min }) => createDynamicErrorMessage(errorMessage, { '${min}': min })
                        : undefined);
                case 'number':
                    return schema.min(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(limit), errorMessage
                        ? ({ min }) => createDynamicErrorMessage(errorMessage, { '${min}': min })
                        : undefined);
                case 'date':
                    const dateLimit = arg && dayjs(arg).isValid() ? String(arg) : String(limit);
                    return schema.min(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : dateLimit, errorMessage
                        ? ({ min }) => createDynamicErrorMessage(errorMessage, { '${min}': min })
                        : undefined);
                default:
                    return schema;
            }
        }
        return schema;
    },
    Max: (fieldKeys, schema, type, errorMessage, arg, limit) => {
        if (limit) {
            switch (type) {
                case 'string':
                    return schema.max(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(limit), errorMessage
                        ? ({ max }) => createDynamicErrorMessage(errorMessage, { '${max}': max })
                        : undefined);
                case 'number':
                    return schema.max(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : Number(arg) || Number(limit), errorMessage
                        ? ({ max }) => createDynamicErrorMessage(errorMessage, { '${max}': max })
                        : undefined);
                case 'date':
                    const dateLimit = arg && dayjs(arg).isValid() ? String(arg) : String(limit);
                    return schema.max(arg && fieldKeys.includes(arg) ? Yup.ref(arg) : dateLimit, errorMessage
                        ? ({ max }) => createDynamicErrorMessage(errorMessage, { '${max}': max })
                        : undefined);
                default:
                    return schema;
            }
        }
        return schema;
    },
};
export const generateYupSchema = (fieldValidation) => {
    const fieldKeys = fieldValidation.map(field => field.Key);
    const validationErrorYupSchema = Yup.object().shape(fieldValidation.reduce((accFields, fieldProp) => {
        const { Key: FieldKey, Type, Validation } = fieldProp;
        const yupType = generateYupType(Type);
        if (Type && Validation) {
            const fieldSchema = Validation.reduce((schema, testCase) => {
                const { Key, Arguments, ErrorMessage, Limit, Regex, Min, Max, } = testCase;
                const testFunction = testFunctions[Key];
                if (testFunction) {
                    return testFunction(fieldKeys, schema, Type, ErrorMessage, Arguments, Limit, Regex, Min, Max);
                }
                return schema;
            }, yupType);
            return Object.assign(Object.assign({}, accFields), { [FieldKey]: fieldSchema });
        }
        return accFields;
    }, {}));
    return validationErrorYupSchema;
};
