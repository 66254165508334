import { head } from 'ramda';
export const getUserInitials = ({ firstName, lastName, }) => {
    const initials = `${head(firstName !== null && firstName !== void 0 ? firstName : '')} ${head(lastName !== null && lastName !== void 0 ? lastName : '')}`.trim();
    return initials;
};
export const getUserFullName = (user) => {
    var _a, _b;
    const fullName = `${(_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : ''} ${(_b = user === null || user === void 0 ? void 0 : user.lastName) !== null && _b !== void 0 ? _b : ''}`.trim();
    return fullName;
};
