/* eslint-disable import/no-webpack-loader-syntax */
import { CODE_ENGINE_MARKERS } from '../../../../predefined-states';
import { BaseAppStateAccessor } from './BaseAppStateAccessor';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import useArrayStateRaw from '!!raw-loader!../../../../predefined-states/useArrayState.template.ts';
export class ArrayStateAccessor extends BaseAppStateAccessor {
    constructor(stateDSL, stateListDSL) {
        super();
        this.stateDSL = stateDSL;
        this.stateListDSL = stateListDSL;
    }
    getHookDeclarationName() {
        return `useArrayState`;
    }
    getHookCallArgumentsString() {
        var _a;
        return JSON.stringify((_a = this.stateDSL.defaultValue) !== null && _a !== void 0 ? _a : []);
    }
    getHookDeclarationArgumentsString() {
        return `defaultState: Array<unknown>`;
    }
    getHookDeclarationBodyString() {
        return useArrayStateRaw
            .replace(new RegExp(`(.|\n|\r)*${CODE_ENGINE_MARKERS.codeEngineUsageStart}`), '')
            .replace(new RegExp(`${CODE_ENGINE_MARKERS.codeEngineUsageEnd}(.|\n|\r)*`), '');
    }
    getStateTypeName() {
        return 'AppRuntimeArrayState';
    }
}
