import { ValueOf } from '@builder/utils';

import { ThemeColorPickerProps } from './ThemeColorPickerInput';
import { ThemeSettingsSectionProps } from './ThemeSettingsSection';

export const THEME_SETTINGS_SECTIONS: Record<
  string,
  Pick<ThemeSettingsSectionProps, 'title' | 'captions'>
> = {
  primaryAndSecondary: {
    title: 'Primary and secondary colors',
    captions: [
      'Your primary color can be used to make a color theme for your app, including dark and light primary color variants. A secondary color provides more ways to accent and distinguish your product. Having a secondary color is optional, and should be applied sparingly to accent select parts of your UI.',
    ],
  },
  surfaceBackgroundError: {
    title: 'Surface, background, and error colors',
    captions: ['Surface colors affect surfaces of components, such as cards, sheets, and menus.'],
  },
  typographyIconography: {
    title: 'Typography and iconography colors',
    captions: [
      '“On” colors. App surfaces use colors from specific categories in your color palette, such as a primary color. Whenever elements, such as text or icons, appear in front of those surfaces, those elements should use colors designed to be clear and legible against the colors behind them.',
      '“On” colors are primarily applied to text, iconography, and strokes. Sometimes, they are applied to surfaces.',
    ],
  },
  componentView: {
    title: 'Choose which background to view component on:',
  },
};

export const DEFAULT_CSS_COLOR_VARIABLES_NAME = {
  primaryMain: '--primaryMain',
  primaryLight: '--primaryLight',
  primaryDark: '--primaryDark',
  secondaryMain: '--secondaryMain',
  secondaryLight: '--secondaryLight',
  secondaryDark: '--secondaryDark',
  backgroundDefault: '--backgroundDefault',
  backgroundSurface: '--backgroundSurface',
  errorMain: '--errorMain',
  primaryContrastText: '--primaryContrastText',
  secondaryContrastText: '--secondaryContrastText',
  textBackgroundPrimary: '--textBackgroundPrimary',
  errorContrastText: '--errorContrastText',
} as const;

export type DefaultCSSColorVariableTypes = ValueOf<typeof DEFAULT_CSS_COLOR_VARIABLES_NAME>;

export const DEFAULT_CSS_COLOR_VARIABLES_KEY = {
  primaryMain: 'primaryMain',
  primaryLight: 'primaryLight',
  primaryDark: 'primaryDark',
  secondaryMain: 'secondaryMain',
  secondaryLight: 'secondaryLight',
  secondaryDark: 'secondaryDark',
  backgroundDefault: 'backgroundDefault',
  backgroundSurface: 'backgroundSurface',
  errorMain: 'errorMain',
  primaryContrastText: 'primaryContrastText',
  secondaryContrastText: 'secondaryContrastText',
  textBackgroundPrimary: 'textBackgroundPrimary',
  errorContrastText: 'errorContrastText',
} as const;

export type DefaultCSSColorVariableKeyTypes = ValueOf<typeof DEFAULT_CSS_COLOR_VARIABLES_KEY>;

export const DEFAULT_CSS_COLOR_VARIABLES_PROPS: Record<
  DefaultCSSColorVariableKeyTypes,
  {
    colorPickerProps: Pick<
      ThemeColorPickerProps,
      | 'label'
      | 'labelIcon'
      | 'labelPlacement'
      | 'labelSize'
      | 'currentColorPropertyName'
      | 'fontColorPropertyName'
    >;
    frameworkSettingsPath: Array<string | number>;
  }
> = {
  primaryMain: {
    colorPickerProps: {
      label: 'Primary',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryMain,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'primary', 'main'],
  },
  primaryLight: {
    colorPickerProps: {
      label: 'Light',
      labelPlacement: 'bottom',
      labelSize: 'small',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryLight,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'primary', 'light'],
  },
  primaryDark: {
    colorPickerProps: {
      label: 'Dark',
      labelPlacement: 'bottom',
      labelSize: 'small',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryDark,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'primary', 'dark'],
  },
  secondaryMain: {
    colorPickerProps: {
      label: 'Secondary',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryMain,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'secondary', 'main'],
  },
  secondaryLight: {
    colorPickerProps: {
      label: 'Light',
      labelPlacement: 'bottom',
      labelSize: 'small',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryLight,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'secondary', 'light'],
  },
  secondaryDark: {
    colorPickerProps: {
      label: 'Dark',
      labelPlacement: 'bottom',
      labelSize: 'small',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryDark,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'secondary', 'dark'],
  },
  backgroundDefault: {
    colorPickerProps: {
      label: 'Background',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.backgroundDefault,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.textBackgroundPrimary,
    },
    frameworkSettingsPath: ['palette', 'background', 'default'],
  },
  backgroundSurface: {
    colorPickerProps: {
      label: 'Surface',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.backgroundSurface,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.textBackgroundPrimary,
    },
    frameworkSettingsPath: ['palette', 'background', 'paper'],
  },
  errorMain: {
    colorPickerProps: {
      label: 'Error',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.errorMain,
      fontColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.errorContrastText,
    },
    frameworkSettingsPath: ['palette', 'error', 'main'],
  },
  primaryContrastText: {
    colorPickerProps: {
      label: 'On Primary',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'primary', 'contrastText'],
  },
  secondaryContrastText: {
    colorPickerProps: {
      label: 'On Secondary',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryContrastText,
    },
    frameworkSettingsPath: ['palette', 'secondary', 'contrastText'],
  },
  textBackgroundPrimary: {
    colorPickerProps: {
      label: 'On Background/Surface',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.textBackgroundPrimary,
    },
    frameworkSettingsPath: ['palette', 'text', 'primary'],
  },
  errorContrastText: {
    colorPickerProps: {
      label: 'On Error',
      currentColorPropertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.errorContrastText,
    },
    frameworkSettingsPath: ['palette', 'error', 'contrastText'],
  },
};

export const DEFAULT_CHANGE_CSS_COLOR_VARIABLES = {
  settings: [
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryMain,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryMain,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryLight,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryLight,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryDark,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryDark,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryContrastText,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryContrastText,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryMain,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryMain,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryLight,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryLight,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryDark,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryDark,
    },
    {
      value: DEFAULT_CSS_COLOR_VARIABLES_NAME.primaryContrastText,
      propertyName: DEFAULT_CSS_COLOR_VARIABLES_NAME.secondaryContrastText,
    },
  ],
} as const;
