import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_19_6 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.6';
    }
    getChangeList() {
        return ['Add primaryField with id column by default in BuilderComponentsDataGrid'];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.BuilderComponentsDataGrid: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { primaryField: 'id' }) });
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_19_6 = new Migration_0_19_6();
