import React from 'react';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import {
  CssGrid,
  DescriptionTooltipWrapper,
  DescriptionTooltipWrapperProps,
} from 'src/shared/components';

const REQUEST_TOOLTIP_TYPES = {
  transformer: 'Transformer',
  callbacks: 'Callbacks',
  variables: 'Variables',
};

const RequestSectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ theme }) => theme.spacing(5.5)};
`;

type RequestSectionProps = {
  children: React.ReactNode;
  maxWidth?: string | undefined;
  title?: string | undefined;
  guidanceText?: string | undefined;
  titleDescription?: Omit<DescriptionTooltipWrapperProps, 'children' | 'wrapCondition'>;
};

export const RequestSection: React.FC<RequestSectionProps> = ({
  children,
  title,
  guidanceText,
  titleDescription,
  maxWidth,
}) => {
  const Tooltip = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.disabled};
    margin-bottom: 5px;
  `;
  return (
    <div>
      <RequestSectionTitleContainer>
        <div>
          <DescriptionTooltipWrapper
            title={titleDescription?.title || ''}
            subtitle={titleDescription?.subtitle || ''}
            wrapCondition={
              title === REQUEST_TOOLTIP_TYPES.transformer ||
              title === REQUEST_TOOLTIP_TYPES.callbacks ||
              title === REQUEST_TOOLTIP_TYPES.variables
            }
          >
            <If condition={title}>
              <Typography variant="subtitle2">{title}</Typography>
            </If>
          </DescriptionTooltipWrapper>
          <If condition={guidanceText}>{guidanceText && <Tooltip>{guidanceText}</Tooltip>}</If>
        </div>
      </RequestSectionTitleContainer>

      <CssGrid gap={1.5} width="100%" maxWidth={maxWidth}>
        {children}
      </CssGrid>
    </div>
  );
};
