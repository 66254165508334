import { useCallback, useMemo, useRef, useState } from 'react';

import styled from '@emotion/styled';
import ReactMonacoEditor, { EditorProps, Monaco } from '@monaco-editor/react';
import { GraphQLSchema } from 'graphql';
import { editor as MonacoEditorType, IRange } from 'monaco-editor';

import { StateListDSL, NodeDSL, StateScopeTypes } from '@builder/schemas';
import { useBoolState } from '@builder/utils';

import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { useAppDispatch, useDialogState } from 'src/providers';
import { getMonacoEditorBorderHighlightingStyles } from 'src/shared/styles/monaco';
import { UI_BUILDER_EVENTS } from 'src/store';

const MonacoEditorContent = styled.div<{
  isFocused: boolean;
  withBorders: boolean;
  error?: boolean;
}>`
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;

  ${({ theme, isFocused, withBorders, error }) =>
    getMonacoEditorBorderHighlightingStyles(theme, { isFocused, error, withBorders })}
`;

export type MonacoEditorProps = EditorProps & {
  appStatesDSL?: StateListDSL;
  iterableNodeListDSL?: NodeDSL[];
  gqlSchema?: GraphQLSchema;
  error?: boolean;
  withBorders?: boolean;
  'data-test'?: string;
  editorScope?: string;
};

export const MonacoEditor = ({
  appStatesDSL = {},
  options,
  error,
  gqlSchema,
  withBorders = true,
  iterableNodeListDSL = [],
  wrapperClassName = 'monaco-editor-wrapper',
  language,
  editorScope,
  'data-test': dataTest,
  onMount,
  ...rest
}: MonacoEditorProps): JSX.Element => {
  const { openDialog, closeDialog, dialogContext } = useDialogState();
  const [isFocused, { setTrue: setIsFocusedTrue, setFalse: setIsFocusedFalse }] = useBoolState(
    false,
  );
  const [showHover, setHover] = useState(true);
  const send = useAppDispatch();
  const defaultValue = useRef(rest.defaultValue);
  const codeEditorDialog = useRef(dialogContext.state);
  const currentCodeEditorDialog =
    codeEditorDialog?.current[DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID];
  const currentDialogLabel = currentCodeEditorDialog?.args?.label || 'defaultDialogLabel';
  const hasGlobalScope = editorScope === 'global';

  const defaultOnMount = useCallback(
    (editor: MonacoEditorType.IStandaloneCodeEditor, monaco: Monaco) => {
      if (!(currentDialogLabel as string).includes('(Code)')) {
        editor.addAction({
          id: 'functionRefactor',
          label: 'Refactor as function',
          contextMenuGroupId: 'navigation',
          contextMenuOrder: 1.5,
          run() {
            const editorCode = editor.getModel()?.getValue();
            const selectedCode = editor
              .getModel()
              ?.getValueInRange(editor.getSelection() as IRange);
            const previousCodeEditorDialog =
              codeEditorDialog?.current[DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID];

            const previousCodeEditorArgs = previousCodeEditorDialog?.isOpen
              ? {
                  id: DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID,
                  args: previousCodeEditorDialog.args,
                  code: editorCode,
                }
              : undefined;
            if (previousCodeEditorDialog?.isOpen) {
              const autoSaveCustomCode = previousCodeEditorDialog?.args?.onSave as (
                value: string | undefined,
              ) => void;
              autoSaveCustomCode(editorCode);
            } else {
              editor.saveViewState();
            }

            openDialog(DASHBOARD_DIALOGS.REFACTOR_FUNCTION_DIALOG_ID, {
              codeString: selectedCode,
              hasGlobalScope,
              previousCodeEditorArgs,
              currentEditor: editor,
              onSave: async (_id: string, scope: StateScopeTypes, functionName: string) => {
                const newCode = editorCode?.replace(selectedCode as string, `${functionName}()`);
                defaultValue.current = newCode;
                send({
                  type: UI_BUILDER_EVENTS.successAppNotify,
                  successMessage: 'Your function was created',
                  functionData: { _id, scope },
                });

                if (
                  previousCodeEditorDialog?.isOpen &&
                  previousCodeEditorDialog?.args?.label === 'Custom Code'
                ) {
                  const autoSaveCustomCode = previousCodeEditorDialog?.args?.onSave as (
                    value: string | undefined,
                  ) => void;
                  autoSaveCustomCode(newCode);

                  openDialog(DASHBOARD_DIALOGS.CODE_EDITOR_DIALOG_ID, {
                    ...previousCodeEditorDialog?.args,
                    initialValues: newCode,
                    hasRefactoredFunction: true,
                  });
                } else {
                  editor.getModel()?.setValue(newCode as string);
                  closeDialog(DASHBOARD_DIALOGS.REFACTOR_FUNCTION_DIALOG_ID);
                }
              },
            });
          },
        });
      }

      monaco.languages.registerHoverProvider('typescript', {
        provideHover(model, position) {
          if (model.getWordAtPosition(position)?.word === 'event') {
            setHover(false);
          }

          setHover(true);

          return {
            range: new monaco.Range(
              1,
              1,
              model.getLineCount(),
              model.getLineMaxColumn(model.getLineCount()),
            ),
            contents: [{ value: '' }],
          };
        },
      });
      editor.onDidFocusEditorText(() => {
        setIsFocusedTrue();
      });
      editor.onDidBlurEditorText(() => {
        setIsFocusedFalse();
      });

      onMount?.(editor, monaco);
    },
    [
      closeDialog,
      currentDialogLabel,
      hasGlobalScope,
      onMount,
      openDialog,
      send,
      setIsFocusedFalse,
      setIsFocusedTrue,
    ],
  );

  const monacoEditorOptions = useMemo(
    () => ({
      minimap: { enabled: false },
      insertSpaces: true,
      tabSize: 2,
      showDeprecated: false,
      hover: { enabled: showHover },
      ...options,
    }),
    [options, showHover],
  );

  return (
    <MonacoEditorContent
      data-test={dataTest}
      isFocused={isFocused}
      error={error}
      withBorders={withBorders}
    >
      <ReactMonacoEditor
        {...rest}
        defaultValue={defaultValue.current}
        language={language}
        wrapperClassName={wrapperClassName}
        onMount={defaultOnMount}
        options={monacoEditorOptions}
        data-test={dataTest}
        theme="vs-dark"
      />
    </MonacoEditorContent>
  );
};
