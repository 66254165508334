import { useSelector, shallowEqual } from 'react-redux';

import { DashboardState, Store } from 'src/store';

export const useDashboardNavigatorCollapsedNodes = (): DashboardState['navigator']['collapsedNodes'] => {
  const collapsedNodes = useSelector<Store, DashboardState['navigator']['collapsedNodes']>(
    state => state.dashboard.navigator.collapsedNodes,
    shallowEqual,
  );

  return collapsedNodes;
};
