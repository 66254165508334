import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { SystemWorkspaceItem, AvailableWorkspacesListQuery } from '../__generated__';
import { BACKEND_WORKSPACES_LIST_QUERY } from '../fragments';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

type UseBackendWorkspacesReturn = {
  backendWorkspaces: SystemWorkspaceItem[];
  loading: boolean;
};

export const useBackendWorkspaces = (): UseBackendWorkspacesReturn => {
  const { workspaceID } = useCurrentWorkspaceID();

  const { data, loading } = useQuery<AvailableWorkspacesListQuery>(BACKEND_WORKSPACES_LIST_QUERY, {
    skip: !workspaceID,
  });

  const backendWorkspaces = useMemo(
    () => (data?.system?.workspacesList?.items as SystemWorkspaceItem[]) || [],
    [data],
  );

  return { backendWorkspaces, loading };
};
