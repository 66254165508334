import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { NS_ID, getBasicPropsSettings } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const TABLE_CAPTION = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [...getBasicPropsSettings([], true), getDivider(), ...getPropertySections()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
