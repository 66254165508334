import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { NS_ID, getBasicPropsSettings, pickSettings, removeAllDividers } from '../../common';
import { getConditionSection, getDivider, getEventsSection, getStyleSections, } from '../../common/settings';
export const MaterialAccordionDetailsSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                ...getBasicPropsSettings([], true),
                getDivider(),
                getConditionSection(),
                getDivider(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonAccordionDetailsSettings = () => pipe(pickSettings([
    [NS_ID.style.root, NS_ID.style.spacing.root, NS_ID.style.spacing.editor],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.fontFamily],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.fontSize],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.color],
], { withStructure: false }), removeAllDividers)(values(MaterialAccordionDetailsSettings).flat());
