import { omit } from 'ramda';
import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_8_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.8.0';
    }
    getChangeList() {
        return [
            `Migrating few components'
      'Please, check all components with '[CHECK]' suffix`,
        ];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.MaterialChip: {
                if (nodeDSL.props.variant === 'default') {
                    return Object.assign(Object.assign({}, nodeDSL), { props: omit(['variant'], nodeDSL.props) });
                }
                return nodeDSL;
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsIcon: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { fontSize: nodeDSL.props.fontSize === 'default' ? 'medium' : nodeDSL.props.fontSize }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsPopper: {
                return Object.assign(Object.assign({}, nodeDSL), { alias: `${nodeDSL.alias} [CHECK]`, props: Object.assign(Object.assign({}, omit(['elevation', 'anchorOrigin', 'transformOrigin'], nodeDSL.props)), { placement: 'bottom' }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsDataGrid: {
                return Object.assign(Object.assign({}, nodeDSL), { props: omit(['hideFooterRowCount', 'showToolbar', 'showRowBorder'], nodeDSL.props) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsDatePicker: {
                return Object.assign(Object.assign({}, nodeDSL), { props: omit([
                        'allowKeyboardControl',
                        'animateYearScrolling',
                        'autoOk',
                        'disableToolbar',
                        'emptyLabel',
                        'inputVariant',
                        'invalidLabel',
                        'maskChar',
                        'strictCompareDates',
                        'variant',
                    ], nodeDSL.props) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsFormDatePicker: {
                const datePickerProps = (nodeDSL.props.datePickerProps || {});
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { datePickerProps: omit([
                            'allowKeyboardControl',
                            'animateYearScrolling',
                            'autoOk',
                            'disableToolbar',
                            'emptyLabel',
                            'inputVariant',
                            'invalidLabel',
                            'maskChar',
                            'strictCompareDates',
                            'variant',
                        ], datePickerProps) }) });
            }
            default: {
                return nodeDSL;
            }
        }
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        const migratedNodesListDSL = this.migrateNodeListDSL(appDSL.nodes, appDSL);
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: migratedNodesListDSL }) });
    }
}
export const migration_0_8_0 = new Migration_0_8_0();
