import React, { SyntheticEvent, useCallback } from 'react';

import { Grid } from '@mui/material';
import { usePopupState, anchorRef } from 'material-ui-popup-state/hooks';
import { path, pathOr, update } from 'ramda';

import {
  ComponentSettingNodeListDSL,
  componentSettingSelectors,
  NodeID,
  NodePropValue,
  PropAssert,
  ComponentSettingDSL,
  nodeListSelectors,
  NodeDSL,
  ComponentSettingNodeItemDSL,
} from '@builder/schemas';
import { memo, pathToString } from '@builder/utils';

import { SettingsDraggableListItem } from '../../common/components';
import { useNodeSettingsProps } from '../../node-settings-generator';
import { NodeSettingCommonProps } from '../../types';
import { NodeSettingsAddNodeButton } from '../common/NodeSettingsAddNodeButton';
import { useAppDispatch, useNodeListDSL } from 'src/providers/ReduxProvider';
import { Button, CssGrid, Icon } from 'src/shared/components';
import { DASHBOARD_EVENTS } from 'src/store';
import { getTargetNodeID } from 'src/store/dashboard/utils';

type NodeListTypeProps = {
  setting: ComponentSettingNodeListDSL;
  onUpdateProp: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  children: (args: {
    settings: ComponentSettingDSL[];
    targetNodeDSL: NodeSettingCommonProps['selectedNodeDSL'];
    nodeListSectionPath: Array<string | number>;
  }) => React.ReactNode;
  sectionPath: Array<string | number>;
  prefixKeyPath: Array<string | number>;
};

type NodeListTypeContentProps = {
  node: NodeDSL;
  itemLabelKeyPath: string[];
  itemLabelNodePath: Array<string | number>;
  index: number;
  reorderListItems: (dragIndex: number, hoverIndex: number) => void;
  'data-test'?: string;
};

const NodeListTypeContent: React.FC<NodeListTypeContentProps> = ({
  node,
  itemLabelKeyPath,
  itemLabelNodePath,
  index,
  reorderListItems,
  children,
  'data-test': dataTest,
}) => {
  const { appDSL } = useNodeSettingsProps();
  const targetLabelNodeID = getTargetNodeID({
    nodeID: node.id,
    nodePath: itemLabelNodePath,
    nodeListDSL: appDSL.nodes,
  });
  const targetLabelNode = nodeListSelectors.getNodeDSL(appDSL.nodes, {
    nodeID: targetLabelNodeID,
  });

  const label = !itemLabelKeyPath.length
    ? `${targetLabelNode.name} ${index}`
    : path<string>(['props', ...itemLabelKeyPath], targetLabelNode);

  return (
    <SettingsDraggableListItem
      index={index}
      data-test={dataTest}
      label={label}
      title={label}
      onReorder={reorderListItems}
    >
      {children}
    </SettingsDraggableListItem>
  );
};

const NODE_LIST_ADD_NODE_POPUP_ID = 'nodeListTypeAddNode';

/**
 * Renders prop list to show arrays of data.
 */
export const NodeListType = memo(
  'NodeListType',
  ({
    setting: nodeListSetting,
    prefixKeyPath,
    onUpdateProp,
    children,
    sectionPath,
  }: NodeListTypeProps): JSX.Element => {
    const componentListPopupState = usePopupState({
      variant: 'popover',
      popupId: NODE_LIST_ADD_NODE_POPUP_ID,
    });
    const send = useAppDispatch();
    const componentListPopupRef = anchorRef(componentListPopupState);
    const { selectedNodeDSL, onCreateNode, onDeleteNode } = useNodeSettingsProps();
    const nodeListDSL = useNodeListDSL();
    const keyPath = componentSettingSelectors.getSettingsKeyPath(nodeListSetting);
    const keyValue = pathOr(
      { nodes: [] },
      [...prefixKeyPath, ...keyPath],
      selectedNodeDSL.props,
    ) as NodePropValue | null;
    const itemLabelKeyPath = componentSettingSelectors.getItemLabelKeyPath(nodeListSetting);
    const itemLabelNodePath = componentSettingSelectors.getItemLabelNodePath(nodeListSetting);
    const possibleComponents = componentSettingSelectors.getNodeSettingPossibleComponents(
      nodeListSetting,
    );
    const hasMoreThanOnePossibleComponent = componentSettingSelectors.hasMoreThanOnePossibleComponent(
      nodeListSetting,
    );

    const propName = nodeListSetting.keyPath.join('.');

    PropAssert.Value.assertIsRenderableNodeProp(keyValue, propName);

    const deleteNodeByID = useCallback(
      (nodeID: NodeID) => () => {
        onDeleteNode({ nodeID });
      },
      [onDeleteNode],
    );

    const createNode = useCallback(
      (newComponent: ComponentSettingNodeItemDSL) => {
        onCreateNode({
          targetNodeID: selectedNodeDSL.id,
          targetPropName: [...prefixKeyPath, ...nodeListSetting.keyPath],
          newComponentName: newComponent.name,
          predefineds: newComponent.predefineds,
        });

        componentListPopupState.close();
      },
      [
        onCreateNode,
        selectedNodeDSL.id,
        prefixKeyPath,
        nodeListSetting.keyPath,
        componentListPopupState,
      ],
    );

    const reorderListItems = useCallback(
      (dragIndex: number, dropIndex: number) => {
        const dragColumn = keyValue.nodes[dragIndex];
        const dropColumn = keyValue.nodes[dropIndex];
        const withReplacedDragColumn = update(dropIndex, dragColumn, keyValue.nodes);
        const reorderedListItems = update(dragIndex, dropColumn, withReplacedDragColumn);

        onUpdateProp({
          keyValue: reorderedListItems,
          keyPath: [...keyPath, 'nodes'],
        });
      },
      [keyPath, keyValue.nodes, onUpdateProp],
    );

    const selectNode = useCallback(
      (childrenNodeID: NodeID) => (event: SyntheticEvent) => {
        event.stopPropagation();
        send({
          type: DASHBOARD_EVENTS.componentSelect,
          id: childrenNodeID,
        });
      },
      [send],
    );

    const currentSectionPath = [...sectionPath, ...nodeListSetting.keyPath];
    const dataTest = pathToString(['propSettings', ...currentSectionPath]);

    return (
      <CssGrid
        ref={componentListPopupRef as React.Ref<HTMLDivElement>}
        data-test={`${dataTest}.nodeListRoot`}
      >
        {keyValue.nodes.map((childrenNodeID, index: number) => {
          const childrenNode = nodeListSelectors.getNodeDSL(nodeListDSL, {
            nodeID: childrenNodeID,
          });
          const childrenNodeSettings =
            possibleComponents.find(({ name }) => name === childrenNode?.name)?.settings || [];

          return (
            <NodeListTypeContent
              key={`node-list-${childrenNode.id}`}
              data-test={dataTest}
              node={childrenNode}
              itemLabelKeyPath={itemLabelKeyPath}
              itemLabelNodePath={itemLabelNodePath}
              index={index}
              reorderListItems={reorderListItems}
            >
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  {children({
                    settings: childrenNodeSettings,
                    targetNodeDSL: childrenNode,
                    nodeListSectionPath: [...currentSectionPath, 'item', index],
                  })}
                </Grid>
                <Grid item>
                  <Button
                    endIcon={<Icon width={16} height={16} name="openComponent" />}
                    onClick={selectNode(childrenNode.id)}
                  >
                    Go to Component
                  </Button>
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="error"
                    onClick={deleteNodeByID(childrenNode.id)}
                    data-test={`${dataTest}.popup.delete`}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </NodeListTypeContent>
          );
        })}
        <Choose>
          <When condition={!hasMoreThanOnePossibleComponent}>
            <Button
              onClick={() => createNode(possibleComponents[0])}
              data-test={`${dataTest}.addItemBtn`}
              variant="contained"
              color="default"
            >
              <span>Add Item</span>
            </Button>
          </When>
          <Otherwise>
            <NodeSettingsAddNodeButton
              possibleComponents={possibleComponents}
              onAdd={createNode}
              buttonText="Add Item"
              popupState={componentListPopupState}
              data-test={dataTest}
            />
          </Otherwise>
        </Choose>
      </CssGrid>
    );
  },
);
