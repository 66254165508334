import { omit, values } from 'ramda';
import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { ERROR_SCOPES, SchemaError } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_6_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.6.0';
    }
    getChangeList() {
        return ['Change page name source'];
    }
    migrateNodeListDSL(nodeListDSL) {
        const routeContentNodeListDSL = values(nodeListDSL).filter(nodeDSL => nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout);
        const migratedNodeListDSL = Object.assign({}, nodeListDSL);
        for (const routeContentNodeDSL of routeContentNodeListDSL) {
            const routeNodeDSL = migratedNodeListDSL[routeContentNodeDSL.parentID];
            const routeOldTitle = routeNodeDSL.props.title;
            const routeTitle = routeOldTitle === 'Root' ? 'Root Page' : routeOldTitle;
            if (!routeNodeDSL) {
                throw new SchemaError(ERROR_SCOPES.migrations, `Parent route of "${routeContentNodeDSL.id}" not found`);
            }
            if (!routeTitle) {
                throw new SchemaError(ERROR_SCOPES.migrations, `Route title of "${routeContentNodeDSL.id}" not found`);
            }
            migratedNodeListDSL[routeContentNodeDSL.id] = Object.assign(Object.assign({}, routeContentNodeDSL), { alias: routeTitle });
            migratedNodeListDSL[routeNodeDSL.id] = Object.assign(Object.assign({}, routeNodeDSL), { alias: `${routeTitle} Wrapper`, props: omit(['title'], routeNodeDSL.props) });
        }
        return migratedNodeListDSL;
    }
}
export const migration_0_6_0 = new Migration_0_6_0();
