import React, { useCallback, useEffect, useState } from 'react';

import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { last, path } from 'ramda';

import { Arguments, ComponentSettingSelectOption } from '@builder/schemas';
import { mustBeCorrectVariableName, generateID } from '@builder/utils';

import { Field } from '../../../../shared/components/form/Field';
import { LIST_TYPES } from '../../common';
import { useTheme } from 'src/providers/ThemeProvider';
import { Icon } from 'src/shared/components';

import {
  FormThreeListDeleteButtonContent,
  FormThreeListContent,
  FormThreeListItemContent,
  FormThreeListItemTextField,
  StyledTextField,
} from './FormThreeList.styles';

type FormThreeListProps = {
  firstColumnLabel?: string;
  secondColumnLabel?: string;
  thirdColumnLabel?: string;
  firstSelectOptions: ComponentSettingSelectOption[];
  name: string;
  'data-test'?: string;
};

const FormThreeListArray: React.FC<
  FormThreeListProps & { arrayHelpers: FieldArrayRenderProps }
> = ({ firstColumnLabel = 'Name', name: fieldName, arrayHelpers, 'data-test': dataTest }) => {
  const theme = useTheme();
  const { values } = useFormikContext<Record<string, Arguments[]>>();
  const [nameFieldErrors, setNameFieldErrors] = useState<(string | undefined)[]>([]);
  const fieldValue = path(fieldName.split('.'), values) as Arguments[];

  const mustBeUnique = useCallback(
    (argumentsIndex: number, value: string) => {
      const result = fieldValue.find(
        (argument, index) => argument.name === value && argumentsIndex !== index,
      )
        ? 'Name must be unique in arguments'
        : undefined;
      return result;
    },
    [fieldValue],
  );

  const validateTextField = useCallback(
    (argumentsIndex: number, value: string) => {
      if (value) {
        const isIncorrectVariableName = mustBeCorrectVariableName(value);
        if (isIncorrectVariableName) {
          return isIncorrectVariableName;
        }

        const isNotUniqueName = mustBeUnique(argumentsIndex, value);
        if (isNotUniqueName) {
          return isNotUniqueName;
        }
      }

      return undefined;
    },
    [mustBeUnique],
  );

  useEffect(() => {
    const fielfErrors = fieldValue.map((value, index) => validateTextField(index, value.name));
    setNameFieldErrors(fielfErrors);
  }, [fieldValue, validateTextField]);

  return (
    <FormThreeListContent>
      {fieldValue?.map((item, index) => {
        const isLastItem = index === fieldValue.length - 1;

        return (
          <FormThreeListItemContent key={item.id}>
            <FormThreeListItemTextField>
              <Field name={`${fieldName}.${index}.name`} useValueOnChange>
                {({ field }) => (
                  <StyledTextField
                    multiline={false}
                    error={Boolean(nameFieldErrors[index])}
                    helperText={nameFieldErrors[index]}
                    placeholder={firstColumnLabel}
                    name={field.name}
                    value={field.value}
                    onChange={e => {
                      const { value } = e.target;
                      field.onChange(value);

                      const lastFieldValueItem = last(fieldValue);
                      const isUserStartTyping = !field?.value && value?.length >= 1;
                      const isLastRowChanged = item.id === lastFieldValueItem?.id;

                      if (isUserStartTyping && isLastRowChanged) {
                        arrayHelpers.insert(fieldValue.length, {
                          id: generateID(),
                          definition: '',
                          isList: LIST_TYPES.noList,
                        });
                      }
                    }}
                    data-test={`${dataTest}.key`}
                  />
                )}
              </Field>
            </FormThreeListItemTextField>
            <FormThreeListItemTextField>
              <Field name={`${fieldName}.${index}.definition` as const} useValueOnChange>
                {({ field }) => (
                  <StyledTextField
                    multiline={false}
                    placeholder="Default value"
                    name={field.name}
                    value={field.value}
                    onChange={e => {
                      const { value } = e.target;
                      field.onChange(value);
                    }}
                    data-test={`${dataTest}.key`}
                  />
                )}
              </Field>
            </FormThreeListItemTextField>
            {!isLastItem && (
              <FormThreeListDeleteButtonContent>
                <Icon
                  element="button"
                  name="delete"
                  color={theme.palette.grey[400]}
                  onClick={() => arrayHelpers.remove(index)}
                  data-test={`${dataTest}.deleteBtn`}
                />
              </FormThreeListDeleteButtonContent>
            )}
          </FormThreeListItemContent>
        );
      })}
    </FormThreeListContent>
  );
};

export const FormThreeList: React.FC<FormThreeListProps> = ({
  name,
  'data-test': dataTest,
  firstSelectOptions,
}) => {
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        return (
          <>
            <FormThreeListArray
              name={name}
              arrayHelpers={arrayHelpers}
              data-test={dataTest}
              firstSelectOptions={firstSelectOptions}
            />
          </>
        );
      }}
    />
  );
};
