import { AFTER_EACH_NAME, BEFORE_EACH_NAME } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_15_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.15.1';
    }
    getChangeList() {
        return ['Add routeHooks to AppDSL'];
    }
    migrateAppDSL(appDSL) {
        return Object.assign(Object.assign({}, appDSL), { routeHooks: {
                beforeEach: {
                    name: BEFORE_EACH_NAME,
                    code: 'return function({to, from, state}) {\n  // type here\n}',
                    scope: 'global',
                },
                afterEach: {
                    name: AFTER_EACH_NAME,
                    code: 'return function({to, from, state}) {\n  // type here\n}',
                    scope: 'global',
                },
            } });
    }
}
export const migration_0_15_1 = new Migration_0_15_1();
