import React, { useCallback, useMemo } from 'react';

import styled from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { editor } from 'monaco-editor';
import { isEmpty } from 'ramda';

import { FormRadioGroup, FormSwitch, getIsInvalid } from '@builder/components';
import {
  stateListSelectors,
  StateFunctionDSL,
  STATE_TYPES,
  StateScopeTypes,
  nameUniqueValidator,
  STATE_SCOPES,
} from '@builder/schemas';
import {
  generateID,
  incrementName,
  required,
  composeValidators,
  mustBeCorrectVariableName,
} from '@builder/utils';

import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { EDITOR_PLACE_HOLDER } from 'src/features/functions-manager/common';
import { FunctionFormValues } from 'src/features/functions-manager/components/FunctionHeader';
import { useValidateJsCode } from 'src/features/functions-manager/util';
import { useDialogState } from 'src/providers';
import { useAppDispatch, useAppDSL, useAppDSLStates } from 'src/providers/ReduxProvider';
import {
  Button,
  Field,
  FieldRenderProps,
  Form,
  FormRenderProps,
  TextField,
} from 'src/shared/components';
import { ReactComponent as FunctionIcon } from 'src/shared/components/Icon/icons/function.svg';
import { useDashboardCurrentRouteContentNodeDSL } from 'src/shared/hooks';
import { DASHBOARD_EVENTS, UI_BUILDER_EVENTS } from 'src/store';

const DEFAULT_NAME = 'newFunction';
const DEFAULT_CODE = EDITOR_PLACE_HOLDER;
const DIALOG_ID = DASHBOARD_DIALOGS.REFACTOR_FUNCTION_DIALOG_ID;

export type RefactorAsFunctionDialogArgs = {
  codeString?: string;
  hasGlobalScope?: boolean;
  previousCodeEditorArgs?: Record<string, unknown>;
  currentEditor?: editor.IStandaloneCodeEditor;
  onSave: (_id: string, scope: StateScopeTypes, name: string) => void;
};

type LocalFunction = Partial<StateFunctionDSL>;

const RefactorFunctionDialogContent = styled.div`
  height: calc(100% - 0px);
  width: 100%;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledLabel = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  color: #abb1b8;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

const StyledTitle = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
  width: 413.71px;
  line-height: 22px;
  height: 22px;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

export const RefactorAsFunctionDialog: React.FC = () => {
  const appDSL = useAppDSL();
  const appStatesDSL = useAppDSLStates();
  const { validate } = useValidateJsCode();
  const send = useAppDispatch();
  const { isOpen, openDialog, closeDialog, args } = useDialogState<RefactorAsFunctionDialogArgs>(
    DIALOG_ID,
  );
  const currentRouteContentNodeDSL = useDashboardCurrentRouteContentNodeDSL();
  const parentID = currentRouteContentNodeDSL?.parentID;
  const functionCode = args.codeString;
  const hasAsyncParameters = !!functionCode?.includes('await');
  const codeScope = STATE_SCOPES.global as string;
  const previousCodeEditorDialog = args.previousCodeEditorArgs;

  const SWITCH_FIELD = {
    fieldProps: { name: `isAsyncFunction` },
    switchProps: {
      label: undefined,
      disabled: false,
    },
  };

  const RADIO_FIELD = {
    fieldProps: { name: `scope`, validate: args.hasGlobalScope ? undefined : required },
    radioGroupProps: { label: '', row: true, disabled: false },
  };

  const handleClose = useCallback(() => {
    if (previousCodeEditorDialog) {
      openDialog(
        previousCodeEditorDialog.id as string,
        ({
          ...(previousCodeEditorDialog?.args as Record<string, unknown>),
          initialValues: previousCodeEditorDialog.code,
        } as unknown) as RefactorAsFunctionDialogArgs,
      );
    } else {
      closeDialog(DIALOG_ID);
    }
  }, [closeDialog, openDialog, previousCodeEditorDialog]);

  const handleSave = useCallback(
    (_id: string, scope: StateScopeTypes, name: string) => {
      args.onSave(_id, scope, name);
    },
    [args],
  );

  const defaultFunctionName = useMemo(
    () =>
      incrementName({
        nameToIncrement: DEFAULT_NAME,
        dictionary: stateListSelectors.getStateNameArrayDSL(appStatesDSL),
        delimiter: '_',
      }),
    [appStatesDSL],
  );

  const sendCreateNewStateEvent = useCallback(
    (stateFunctionDSL: StateFunctionDSL, scope: StateScopeTypes) =>
      send({
        type: DASHBOARD_EVENTS.stateCreate,
        stateDSL: { ...stateFunctionDSL, scope },
        connectedNodeID: currentRouteContentNodeDSL?.id,
        connectedNodeParentID: currentRouteContentNodeDSL?.parentID ?? undefined,
      }),
    [send, currentRouteContentNodeDSL],
  );

  const saveFunction = useCallback(
    localFunction => {
      const handledlocalFunction = localFunction;
      const functionBody = functionCode;
      const functionScope = args.hasGlobalScope ? 'global' : handledlocalFunction.scope;
      const functionID = handledlocalFunction.id;
      const functionName = handledlocalFunction.name;

      if (!isEmpty(functionCode)) {
        handledlocalFunction.code = `return ${
          handledlocalFunction.isAsyncFunction ? 'async ' : ''
        }function() {\n  ${functionBody}\n}`;

        if (!validate(handledlocalFunction.code)) {
          return;
        }

        sendCreateNewStateEvent(
          {
            ...({
              ...handledlocalFunction,
              arguments: [],
              bodyFunction: functionBody,
            } as Required<LocalFunction>),
            type: STATE_TYPES.function,
          },
          functionScope,
        );

        handleSave(functionID, functionScope, functionName);
      } else {
        send({
          type: UI_BUILDER_EVENTS.errorAppNotify,
          errorMessage: 'Select a range of code in the editor.',
        });
      }
    },
    [args.hasGlobalScope, functionCode, handleSave, send, sendCreateNewStateEvent, validate],
  );

  const initialValues = useMemo(() => {
    return {
      id: generateID(),
      arguments: [{ id: generateID(), definition: '' }],
      scope: codeScope,
      isAsyncFunction: hasAsyncParameters,
      name: defaultFunctionName,
      code: functionCode ?? DEFAULT_CODE,
    };
  }, [codeScope, defaultFunctionName, functionCode, hasAsyncParameters]);

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogTitle>
        <StyledTitle variant="body1">Create New Function</StyledTitle>
      </DialogTitle>
      <RefactorFunctionDialogContent>
        <Form enableReinitialize onSubmit={saveFunction} initialValues={initialValues}>
          {(formRenderProps: FormRenderProps<FunctionFormValues>) => (
            <form onSubmit={formRenderProps.handleSubmit}>
              <StyledLabel variant="body1">Function Name *</StyledLabel>
              <Field
                name="name"
                useValueOnChange
                validate={composeValidators(
                  required,
                  mustBeCorrectVariableName,
                  nameUniqueValidator({
                    appDSL,
                    initialValueName: initialValues.name,
                    section: 'Function',
                    scope: codeScope,
                    parentID,
                  }),
                )}
              >
                {({ field, meta, form }: FieldRenderProps) => {
                  const isInvalid = getIsInvalid({ meta, form });
                  const errorText = isInvalid ? meta.error : undefined;
                  return (
                    <TextField
                      variant="outlined"
                      value={field.value}
                      title={field.value}
                      data-test="refactorAsFunction.editor.name"
                      error={isInvalid}
                      helperText={errorText}
                      onChange={event => field.onChange(event.target.value)}
                    />
                  );
                }}
              </Field>

              <StyledLabel variant="body1">Scope</StyledLabel>
              <FormRadioGroup
                fieldProps={RADIO_FIELD.fieldProps}
                radioGroupProps={{
                  ...RADIO_FIELD.radioGroupProps,
                }}
              >
                <FormControlLabel
                  checked={args.hasGlobalScope ? true : undefined}
                  value="global"
                  control={<Radio />}
                  label="Global"
                />
                <FormControlLabel value="local" control={<Radio />} label="Local" />
              </FormRadioGroup>
              <StyledLabel variant="body1">Async Function</StyledLabel>
              <FormSwitch
                fieldProps={SWITCH_FIELD.fieldProps}
                switchProps={{
                  ...SWITCH_FIELD.switchProps,
                  disabled: hasAsyncParameters,
                }}
                onChange={event => event.target.value}
              />
              <FunctionIcon />
              <DialogActions>
                <Button variant="contained" color="default" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Form>
      </RefactorFunctionDialogContent>
    </Dialog>
  );
};
