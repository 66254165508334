import React, { useState, useMemo, useCallback } from 'react';

import styled from '@emotion/styled';
import { Tab as MuiTab, Tabs as MuiTabs, Theme } from '@mui/material';

import { useIsDevModeEnabled } from 'src/providers/ReduxProvider';

export type TabType<T extends string> = {
  label: string;
  value: T;
  dataTest: string;
  component?: React.ElementType;
  devModeContent?: boolean;
};

type TabsProps<T extends string> = {
  tabsList: Array<TabType<T>>;
  initialValue?: T;
  renderTabContent?: React.FC<{ selectedTab: T }>;
  padding?: 'none' | 'md';
  className?: string;
  onChange?: (value: T) => void;
};

const getPaddings = (theme: Theme) => ({
  none: '0 0',
  md: `${theme.spacing(1.5)}`,
});

const TabContent = styled.div<{ padding: 'none' | 'md' }>`
  flex: 1;
  padding: ${({ theme, padding }) => getPaddings(theme)[padding]};
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const TabsContainer = styled.div<{ padding: 'none' | 'md' }>`
  padding: 0 ${({ theme, padding }) => getPaddings(theme)[padding]};
`;

export const Tabs = <T extends string>({
  tabsList,
  initialValue,
  renderTabContent,
  padding = 'md',
  onChange,
  className,
}: TabsProps<T>): JSX.Element => {
  const devModeEnabled = useIsDevModeEnabled();
  const [selectedTab, setSelectedTab] = useState<T>(() => initialValue ?? tabsList[0]?.value);

  const changeTab = (_: unknown, newValue: T) => {
    setSelectedTab(newValue);
    onChange?.(newValue);
  };

  const SelectedComponent = useMemo(
    () => (tabsList.find(tab => tab.value === selectedTab) as TabType<T>)?.component,
    [selectedTab, tabsList],
  );

  const renderContent = useCallback(() => {
    if (renderTabContent) {
      return renderTabContent({ selectedTab });
    }

    if (SelectedComponent) {
      return <SelectedComponent />;
    }

    return null;
  }, [SelectedComponent, renderTabContent, selectedTab]);

  return (
    <>
      <TabsContainer padding={padding}>
        <MuiTabs
          value={selectedTab}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className={className}
          onChange={changeTab}
        >
          {tabsList
            .filter(({ devModeContent }) => (devModeContent && devModeEnabled) || !devModeContent)
            .map(tab => (
              <MuiTab
                key={tab.label}
                label={tab.label}
                data-test={tab.dataTest}
                value={tab.value}
              />
            ))}
        </MuiTabs>
      </TabsContainer>
      <TabContent padding={padding}>{renderContent()}</TabContent>
    </>
  );
};
