import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, getBasicPropsSettings, getCommonPropertiesSections, getDivider, getStylesSection, } from '../../common';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_PROPS_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['disableGutters'],
                label: 'Disable Gutters',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['variant'],
                options: ['regular', 'dense'].map(createSelectOptionByValue),
                label: 'Variant',
            },
        ],
    },
    getDivider(),
    ...getBasicPropsSettings(),
    getDivider(),
    ...getCommonPropertiesSections(),
];
export const MaterialToolbarSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
};
