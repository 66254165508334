import { gql } from '@apollo/client';

export const CERTIFICATE_INFO_FRAGMENT = gql`
  fragment CertificateInfo on AppbuilderCertificateInfo {
    issuer
    domainName
    expireAt
  }
`;

export const DEPLOY_SETTINGS_QUERY = gql`
  query DeploySettings($workspaceId: String!) {
    appbuilder {
      settings(workspaceId: $workspaceId) {
        projectUrl
        nameServers
        hostedZoneDomain
        distributionURL
        cdn
        certificate {
          ...CertificateInfo
        }
      }
    }
  }
  ${CERTIFICATE_INFO_FRAGMENT}
`;

export const DEPLOY_SETTINGS_CERTIFICATE_UPSERT_MUTATION = gql`
  mutation DeploySettingsCertificateUpsert($data: AppbuilderUploadCertificateInput!) {
    appbuilder {
      certificateUpload(data: $data) {
        ...CertificateInfo
      }
    }
  }
  ${CERTIFICATE_INFO_FRAGMENT}
`;

export const DEPLOY_SETTINGS_CERTIFICATE_DELETE_MUTATION = gql`
  mutation DeploySettingsCertificateDelete($data: AppbuilderCertificateResetInput!) {
    appbuilder {
      certificateReset(data: $data) {
        success
      }
    }
  }
`;

export const DEPLOY_SETTINGS_HOSTED_ZONE_CREATE_MUTATION = gql`
  mutation DeploySettingsHostedZoneCreate($data: AppbuilderHostedZoneCreateInput!) {
    appbuilder {
      hostedZoneCreate(data: $data) {
        hostedZoneDomain
        nameServers
      }
    }
  }
`;

export const DEPLOY_SETTINGS_HOSTED_ZONE_DELETE_MUTATION = gql`
  mutation DeploySettingsHostedZoneDelete($data: AppbuilderHostedZoneDeleteInput!) {
    appbuilder {
      hostedZoneDelete(data: $data) {
        success
      }
    }
  }
`;

export const DEPLOY_SETTINGS_UPDATE_MUTATION = gql`
  mutation DeploySettingsUpdate($data: AppbuilderSettingsChangeInput!) {
    appbuilder {
      settingsChange(data: $data) {
        success
      }
    }
  }
`;
