var _a;
import { omit } from 'ramda';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsTextField } from '../../material-components/BuilderComponentsTextField';
import { BuilderComponentsFormTextFieldSettings } from './BuilderComponentsFormTextField.settings';
export const BuilderComponentsFormTextField = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormTextField,
    displayName: 'Form Textfield',
    icon: 'formTextfield',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormTextField',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'FormTextField',
                    defaultValue: '',
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormTextField',
                },
                inputProps: Object.assign({}, omit(['value', 'onChange'], (_a = BuilderComponentsTextField.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)),
                value: '{{ __STATES__.FormTextField.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FormTextField.setValue(event.target.value)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { formik: { type: COMPONENT_DSL_PROP_TYPES.object }, inputProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsTextField.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            } }),
    },
    settings: BuilderComponentsFormTextFieldSettings,
};
