import React, { useContext, createContext, useCallback, useState } from 'react';

import { pathOr } from 'ramda';

import { ValueOf } from '@builder/utils';

import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { EMPTY_OBJECT, noop } from 'src/shared/utils';

type BaseDialogArgsType = Record<string, unknown>;

export type OpenDialogFn<T = BaseDialogArgsType> = (dialogID: string, args?: T) => void;
type CloseDialogFn = (dialogID: string) => void;

type DialogContextState = Record<
  string,
  {
    isOpen: boolean;
    args?: BaseDialogArgsType;
  }
>;

type DialogContextType<T = BaseDialogArgsType> = {
  openDialog: OpenDialogFn<T>;
  closeDialog: CloseDialogFn;
  state: DialogContextState;
};

type DialogHookResult<T = BaseDialogArgsType> = {
  openDialog: OpenDialogFn<T>;
  closeDialog: CloseDialogFn;
  args: T;
  isOpen: boolean;
  dialogContext: DialogContextType<T>;
};

const DialogContext = createContext<DialogContextType<BaseDialogArgsType>>({
  openDialog: noop,
  closeDialog: noop,
  state: EMPTY_OBJECT,
});

/**
 * Use only for dashboard dialogs
 */
export const DialogProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<DialogContextState>({});

  const openDialog = useCallback((id: string, args: Record<string, unknown> = {}) => {
    setState({
      [id]: {
        isOpen: true,
        args,
      },
    });
  }, []);

  const closeDialog = useCallback((id: string) => {
    setState({
      [id]: {
        isOpen: false,
      },
    });
  }, []);

  return (
    <DialogContext.Provider
      value={{
        state,
        openDialog,
        closeDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

/**
 * Use to open/close dashboard dialogs
 */
export function useDialogState<T = BaseDialogArgsType>(
  id?: ValueOf<typeof DASHBOARD_DIALOGS>,
): DialogHookResult<T> {
  const dialogContext = useContext(DialogContext) as DialogContextType<T>;
  const args = (id
    ? pathOr(EMPTY_OBJECT, ['state', id, 'args'], dialogContext)
    : EMPTY_OBJECT) as T;
  const isOpen = id ? pathOr(false, ['state', id, 'isOpen'], dialogContext) : false;

  return {
    openDialog: dialogContext.openDialog,
    closeDialog: dialogContext.closeDialog,
    args,
    isOpen,
    dialogContext,
  };
}

export function useAllDialogsState<T = BaseDialogArgsType>(): DialogContextState {
  const dialogContext = useContext(DialogContext) as DialogContextType<T>;

  return dialogContext.state;
}
