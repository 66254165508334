import React from 'react';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import { DesignScopes } from 'src/features/design-scopes';
import { useUIBuilderLeftPanel } from 'src/providers';
import { DescriptionTooltipWrapper, DescriptionTooltipWrapperProps } from 'src/shared/components';
import { LEFT_PANEL_TABS, LeftPanelTabsType } from 'src/store';

const LeftPanelSectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.spacing(3)};
`;

interface LeftPanelSectionTitleProps {
  title: string;
  titleDescription?: Omit<DescriptionTooltipWrapperProps, 'children' | 'wrapCondition'>;
  rightPart?: React.ReactNode;
}

const SHOW_AVATARS_ON_TABS: LeftPanelTabsType[] = [
  LEFT_PANEL_TABS.assets,
  LEFT_PANEL_TABS.hooks,
  LEFT_PANEL_TABS.functions,
  LEFT_PANEL_TABS.requests,
];

export const LeftPanelSectionTitle: React.FC<LeftPanelSectionTitleProps> = ({
  title,
  titleDescription,
  rightPart,
}) => {
  const leftPanel = useUIBuilderLeftPanel();

  return (
    <LeftPanelSectionTitleContainer>
      <Box display="flex" alignItems="center">
        <DescriptionTooltipWrapper
          title={titleDescription?.title || ''}
          subtitle={titleDescription?.subtitle || ''}
          wrapCondition={Boolean(titleDescription)}
        >
          <Typography variant="h6">{title}</Typography>
        </DescriptionTooltipWrapper>
        <If condition={SHOW_AVATARS_ON_TABS.includes(leftPanel.currentTab)}>
          <Box ml={2}>
            <DesignScopes
              popperPlacement="bottom-start"
              scopeFilter={state => state.global === leftPanel.currentTab}
            />
          </Box>
        </If>
      </Box>
      <div>{rightPart}</div>
    </LeftPanelSectionTitleContainer>
  );
};
