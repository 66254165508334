import { QueryResult, useQuery } from '@apollo/client';

import { DeployStepsListQuery, DeployStepsListQueryVariables } from '../__generated__';
import { DEPLOY_STEPS_LIST_QUERY } from '../fragments';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

export const useDeploySteps = (
  deployId: string,
): QueryResult<DeployStepsListQuery, DeployStepsListQueryVariables> => {
  const { workspaceID } = useCurrentWorkspaceID();

  return useQuery<DeployStepsListQuery, DeployStepsListQueryVariables>(DEPLOY_STEPS_LIST_QUERY, {
    variables: {
      workspaceId: workspaceID as string,
      filter: {
        deploy: {
          id: { equals: deployId },
        },
      },
    },
    skip: !deployId || !workspaceID,
  });
};
