// TODO: replace it with acorn or similar parser
/**
 * @returns List of the dependencies which are existed in the function code.
 */
export const getFunctionDeps = (functionCode, deps) => {
    return deps.filter(dependency => {
        return hasFunctionDeps(functionCode, dependency);
    });
};
export const hasFunctionDeps = (functionCode, dependency) => {
    const regExp = new RegExp(`${dependency}.`, 'gm');
    return regExp.test(functionCode);
};
