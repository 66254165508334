import { omit, isEmpty } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_17_9 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.9';
    }
    getChangeList() {
        return [
            'Refactor __legacyTheme into coreTheme Object from DSL, and components inside theme property',
        ];
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const appDSLToTransform = appDSL;
        const defaultTheme = {
            name: 'custom',
            palette: {
                primary: {
                    main: '#008DCE',
                    light: '#3BB4EB',
                    dark: '#006492',
                    contrastText: '#FFFFFF',
                },
                secondary: {
                    main: '#6EF1DB',
                    light: '#34CCB3',
                    dark: '#06816C',
                    contrastText: '#202020',
                },
                error: {
                    main: '#D14646',
                    contrastText: '#FFFFFF',
                },
                background: {
                    default: '#FFFFFF',
                    paper: '#FFFFFF',
                },
                text: {
                    primary: '#202020',
                },
            },
            typography: {
                fontSize: 14,
            },
            spacing: 8,
        };
        const frameworkSettings = appDSLToTransform.__legacyTheme && isEmpty(appDSLToTransform.__legacyTheme)
            ? defaultTheme
            : appDSLToTransform.__legacyTheme;
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, omit(['__legacyTheme'], appDSL)), { theme: Object.assign(Object.assign({}, omit(['components'], appDSL.theme)), { frameworkSettings }) }) });
    }
}
export const migration_0_17_9 = new Migration_0_17_9();
