import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { appDSLSelectors } from '@builder/schemas';
import { AppEngineStates } from '../../app-state/AppEngineStates';
import { ApolloProvider } from '../ApolloProvider';
import { RouterProvider } from '../RouterProvider';
import { ThemeProvider } from '../ThemeProvider';
/**
 * Adds providers to app engine.
 */
export const AppEngineProvider = ({ appDSL, assetBackendList, componentListDSL, renderReactComponent, appEngineUniqStateKey, onRouterUrlChange, skipPresentations, onRuntimeStateReport, contextWindow, cacheKey, currentRoute, presentationStates, appDSLRef, }) => {
    const apiEndpoint = appDSLSelectors.getAuthResourceEndpoint(appDSL);
    const currentAuthDSL = appDSLSelectors.getAuthResourceCurrentAuthDSL(appDSL);
    return (_jsx(ApolloProvider, Object.assign({ apiEndpoint: apiEndpoint, authDSL: currentAuthDSL }, { children: _jsx(ThemeProvider, Object.assign({ theme: appDSL.theme, assets: appDSL.assets, assetBackendList: assetBackendList, contextWindow: contextWindow, cacheKey: cacheKey }, { children: _jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterLuxon }, { children: _jsx(RouterProvider, Object.assign({ onRouterUrlChange: onRouterUrlChange, appDSL: appDSL, currentRoute: currentRoute, appDSLRef: appDSLRef }, { children: _jsx(AppEngineStates, { presentationStates: presentationStates, currentRoute: currentRoute, componentListDSL: componentListDSL, appDSL: appDSL, assetBackendList: assetBackendList, skipPresentations: skipPresentations, renderReactComponent: renderReactComponent, onRuntimeStateReport: onRuntimeStateReport }, appEngineUniqStateKey) })) })) })) }), `${appEngineUniqStateKey}-Provider`));
};
