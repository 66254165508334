import React, { useCallback } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

import { useDebouncedState } from '@builder/utils';

import { InputContainer, InputContainerProps } from '../common';

export type TextFieldProps = MuiTextFieldProps & InputContainerProps;

const BLACK_LIST_PROPS = ['enableFx', 'isFxEnabled', 'absoluteEndAdornmentPosition'];
const shouldForwardProp = (prop: string): boolean => !BLACK_LIST_PROPS.includes(prop);

export type CustomTextFieldProps = TextFieldProps & {
  absoluteEndAdornmentPosition?: boolean;
  skipDebounce?: boolean;
  noCode?: boolean;
  onDebounceChange?: (value: string) => void;
};

export const TextField: React.FC<CustomTextFieldProps> = ({
  label,
  icon,
  isTextIcon,
  error,
  variant = 'standard',
  value = '',
  fullWidth,
  'data-test': dataTest,
  multiline = true,
  maxRows = 4,
  helperText,
  absoluteEndAdornmentPosition,
  onChange,
  onDebounceChange = () => undefined,
  skipDebounce = true,
  enableFx,
  isFxEnabled,
  showFx,
  noCode = false,
  ...props
}) => {
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    value as string,
    onDebounceChange,
    {
      skipDebounce,
    },
  );

  const updateValueByDebounce = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDebouncedValue(event.currentTarget.value);
    },
    [setDebouncedValue],
  );

  return (
    <InputContainer
      label={label}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      enableFx={enableFx}
      isFxEnabled={isFxEnabled}
      showFx={showFx}
      noCode={noCode}
    >
      <StyledTextField
        {...props}
        onChange={skipDebounce ? onChange : updateValueByDebounce}
        absoluteEndAdornmentPosition={absoluteEndAdornmentPosition}
        error={error}
        value={skipDebounce ? value : debouncedValue}
        variant={variant}
        multiline={multiline}
        maxRows={maxRows}
        data-test={dataTest}
      />
    </InputContainer>
  );
};

const styleWithScrollAndRightIcon = css`
  padding-right: 30px;
`;

export const StyledTextField = styled(MuiTextField, { shouldForwardProp })<{
  multiline: boolean;
  absoluteEndAdornmentPosition?: boolean;
}>`
  flex: 1;
  height: ${({ theme, multiline }) => (!multiline ? `${theme.layout.controls.height}px` : 'auto')};
  & input {
    padding: 0;
  }
  & textarea {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
  & .MuiInputBase-root {
    height: ${({ multiline }) => (!multiline ? `100%` : 'auto')};

    ${({ absoluteEndAdornmentPosition }) =>
      absoluteEndAdornmentPosition ? styleWithScrollAndRightIcon : undefined}
  }
`;
