var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_11_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.2';
    }
    getChangeList() {
        return [`Breadcrumbs: replace Text separator with Typography separator`];
    }
    migrateNodeListDSL(nodeListDSL) {
        let createdNodeListDSL = {};
        const separatorPrefixID = 'm_0_11_2_breadcrumb_typography_separator';
        let separatorIDsCounter = 0;
        const createSeparatorNode = ({ allNodeListDSL, parentID, aliasPrefix, separator, }) => {
            var _a;
            const existedSeparatorID = (_a = separator === null || separator === void 0 ? void 0 : separator.nodes) === null || _a === void 0 ? void 0 : _a[0];
            if (!existedSeparatorID) {
                return {
                    newNodeListDSL: {},
                };
            }
            const existedSeparatorNode = allNodeListDSL[existedSeparatorID];
            if (existedSeparatorNode.name !== 'Text') {
                return {
                    separator,
                    newNodeListDSL: {},
                };
            }
            const materialTypographyID = `${separatorPrefixID}_${separatorIDsCounter}`;
            const materialTypographyAlias = this.generateSecuredNodeAlias(allNodeListDSL, aliasPrefix);
            separatorIDsCounter += 1;
            return {
                separator: {
                    nodes: [materialTypographyID],
                },
                newNodeListDSL: {
                    [materialTypographyID]: {
                        name: 'MaterialTypography',
                        alias: materialTypographyAlias,
                        schemaOverride: {
                            interaction: 'only-editable',
                        },
                        props: {
                            variant: 'inherit',
                            align: 'inherit',
                            color: 'inherit',
                            paragraph: false,
                            children: {
                                nodes: [existedSeparatorID],
                            },
                        },
                        id: materialTypographyID,
                        states: [],
                        parentID,
                    },
                },
            };
        };
        const migratedNodeListDSL = mapObjIndexed((nodeDSL, propName) => {
            switch (nodeDSL.name) {
                case 'MaterialBreadcrumbs': {
                    const _a = nodeDSL.props, { separator } = _a, otherProps = __rest(_a, ["separator"]);
                    const { newNodeListDSL, separator: newSeparator } = createSeparatorNode({
                        allNodeListDSL: Object.assign(Object.assign({}, nodeListDSL), createdNodeListDSL),
                        parentID: nodeDSL.id,
                        aliasPrefix: 'Breadcrumb Separator',
                        separator: separator,
                    });
                    createdNodeListDSL = Object.assign(Object.assign({}, createdNodeListDSL), newNodeListDSL);
                    const breadcrumbsProps = Object.assign({}, otherProps);
                    if (separator) {
                        breadcrumbsProps.separator = newSeparator;
                    }
                    return Object.assign(Object.assign({}, nodeDSL), { props: breadcrumbsProps });
                }
                default:
                    return nodeDSL;
            }
        }, nodeListDSL);
        return Object.assign(Object.assign({}, migratedNodeListDSL), createdNodeListDSL);
    }
}
export const migration_0_11_2 = new Migration_0_11_2();
