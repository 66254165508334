import { LocalStateContainerGenerator } from './LocalStateContainerGenerator';
export class LocalStateContainerListGenerator {
    constructor({ appDSL, componentListDSL, assetListDSL, }) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
        this.assetListDSL = assetListDSL;
    }
    generateStateContainers(usedNodeListDSL) {
        return Object.values(usedNodeListDSL)
            .map(nodeDSL => {
            const localStateContainerGenerator = new LocalStateContainerGenerator({
                appDSL: this.appDSL,
                componentListDSL: this.componentListDSL,
                nodeDSL,
                assetListDSL: this.assetListDSL,
            });
            if (!localStateContainerGenerator.hasLocalStates()) {
                return '';
            }
            return localStateContainerGenerator.generateLocalStateContainerDeclaration();
        })
            .filter(Boolean)
            .join('\n');
    }
}
