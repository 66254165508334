import { ValueOf } from '@builder/utils';

import {
  MarginTopButton,
  MarginRightButton,
  MarginBottomButton,
  MarginLeftButton,
  PaddingTopButton,
  PaddingRightButton,
  PaddingBottomButton,
  PaddingLeftButton,
} from './SpacingViewEditorPresentation';

export const SPACING_TYPES = {
  marginTop: 'marginTop',
  marginRight: 'marginRight',
  marginBottom: 'marginBottom',
  marginLeft: 'marginLeft',
  paddingTop: 'paddingTop',
  paddingRight: 'paddingRight',
  paddingBottom: 'paddingBottom',
  paddingLeft: 'paddingLeft',
} as const;

export type SpacingTypes = ValueOf<typeof SPACING_TYPES>;

export const MARGIN_BUTTONS = [
  {
    type: SPACING_TYPES.marginTop,
    ButtonComponent: MarginTopButton,
    dataTest: 'marginTop',
  },
  {
    type: SPACING_TYPES.marginRight,
    ButtonComponent: MarginRightButton,
    dataTest: 'marginRight',
  },
  {
    type: SPACING_TYPES.marginBottom,
    ButtonComponent: MarginBottomButton,
    dataTest: 'marginBottom',
  },
  {
    type: SPACING_TYPES.marginLeft,
    ButtonComponent: MarginLeftButton,
    dataTest: 'marginLeft',
  },
];

export const PADDING_BUTTONS = [
  {
    type: SPACING_TYPES.paddingTop,
    ButtonComponent: PaddingTopButton,
    dataTest: 'paddingTop',
  },
  {
    type: SPACING_TYPES.paddingRight,
    ButtonComponent: PaddingRightButton,
    dataTest: 'paddingRight',
  },
  {
    type: SPACING_TYPES.paddingBottom,
    ButtonComponent: PaddingBottomButton,
    dataTest: 'paddingBottom',
  },
  {
    type: SPACING_TYPES.paddingLeft,
    ButtonComponent: PaddingLeftButton,
    dataTest: 'paddingLeft',
  },
];

export const SPACING_VIEW_EDITOR_POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 20],
    },
  },
];
