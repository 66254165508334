import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import { AppConfiguration, NodeDSL } from '@builder/schemas';

import { ActionScopeStrategy, SCOPE_GLOBAL } from './ActionScopeStrategy';

export class UndoRedoStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
    currentConfiguration: AppConfiguration,
    previousConfiguration: AppConfiguration,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null {
    const newEventsToPush: EventPayload[] = [];
    let scope = SCOPE_GLOBAL;

    scope = action?.payload?.actionEvent?.payload?.[0]?.scope || SCOPE_GLOBAL;

    return {
      eventPayload: {
        ...eventPayload,
        scope,
      },
      newEventsToPush,
    };
  }
}
