import React, { useCallback, useMemo, useRef } from 'react';

import { Grid } from '@mui/material';
import { path, pathOr, update, remove } from 'ramda';

import {
  ComponentSettingListDSL,
  componentSettingSelectors,
  PropAssert,
  ComponentSettingDSL,
  ArrayPropValue,
} from '@builder/schemas';
import { incrementName, memo, pathToString } from '@builder/utils';

import { SettingsDraggableListItem } from '../../common/components';
import { useNodeSettingsProps } from '../../node-settings-generator';
import { ValidationListItem } from '../../node-settings-generator/NodeSettingsGenerator';
import { CssGrid, Button } from 'src/shared/components';

type ListTypeProps = {
  setting: ComponentSettingListDSL;
  onUpdateProp: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  children: (args: {
    settings: ComponentSettingDSL[];
    prefixKeyPath: Array<string | number>;
    listSectionPath: Array<string | number>;
    validationList?: ValidationListItem;
  }) => React.ReactNode;

  sectionPath: Array<string | number>;
};

const ListTypeContent: React.FC<{
  item: Record<string, unknown>;
  itemLabelKeyPath: string[];
  index: number;
  fixedTittle: string | undefined;
  reorderListItems: (dragIndex: number, hoverIndex: number) => void;
  'data-test'?: string;
}> = ({
  item,
  itemLabelKeyPath,
  index,
  fixedTittle,
  reorderListItems,
  children,
  'data-test': dataTest,
}) => {
  const label = path<string>(itemLabelKeyPath, item);

  return (
    <SettingsDraggableListItem
      index={index}
      data-test={dataTest}
      label={label}
      title={fixedTittle || label}
      onReorder={reorderListItems}
    >
      {children}
    </SettingsDraggableListItem>
  );
};

/**
 * Renders prop list to show arrays of data.
 */
export const ListType = memo(
  'ListType',
  ({ setting: listSetting, onUpdateProp, children, sectionPath }: ListTypeProps): JSX.Element => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const keyPath = componentSettingSelectors.getSettingsKeyPath(listSetting);
    const keyValue = pathOr([], keyPath, selectedNodeDSL.props) as ArrayPropValue | null;
    const propName = componentSettingSelectors.getSettingsKeyName(listSetting);
    const itemLabelKeyPath = componentSettingSelectors.getItemLabelKeyPath(listSetting);

    PropAssert.Value.assertIsArrayProp(keyValue, propName);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const defaultItemName = useMemo(
      () =>
        incrementName({
          nameToIncrement: listSetting.newObjectPredefineds?.[itemLabelKeyPath[0]] as string,
          dictionary: keyValue.map(item => item[itemLabelKeyPath[0]]) as string[],
          delimiter: '',
        }),
      [itemLabelKeyPath, keyValue, listSetting.newObjectPredefineds],
    );

    const createItem = () => {
      onUpdateProp({
        keyValue: { [itemLabelKeyPath[0]]: defaultItemName },
        keyPath: [...keyPath, keyValue.length],
      });
    };

    const reorderListItems = useCallback(
      (dragIndex: number, dropIndex: number) => {
        const dragItem = keyValue[dragIndex];
        const dropItem = keyValue[dropIndex];
        const withReplacedDragItem = update(dropIndex, dragItem, keyValue);
        const reorderedListItems = update(dragIndex, dropItem, withReplacedDragItem);

        onUpdateProp({
          keyValue: reorderedListItems,
          keyPath,
        });
      },
      [keyPath, keyValue, onUpdateProp],
    );

    const currentSectionPath = [...sectionPath, propName];
    const dataTest = pathToString(['propSettings', ...currentSectionPath]);
    const isfieldValidationList = listSetting.name === 'fieldValidation';

    return (
      <CssGrid ref={wrapperRef} data-test={`${dataTest}.nodeListRoot`}>
        {keyValue.map((childNode, index: number) => {
          return (
            <ListTypeContent
              // eslint-disable-next-line react/no-array-index-key
              key={`list-${index}`}
              data-test={dataTest}
              item={childNode}
              itemLabelKeyPath={itemLabelKeyPath}
              index={index}
              fixedTittle={isfieldValidationList ? 'New Field' : undefined}
              reorderListItems={reorderListItems}
            >
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  {children({
                    settings: listSetting.settings,
                    prefixKeyPath: [...keyPath, index],
                    listSectionPath: [...currentSectionPath, 'item', index],
                    validationList: {
                      name: childNode.Key,
                      type: childNode.Type,
                      validation: childNode.Validation,
                    },
                  })}
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => {
                      const withoutDeletedItem = remove(index, 1, keyValue);
                      onUpdateProp({ keyValue: withoutDeletedItem, keyPath });
                    }}
                    data-test={`${dataTest}.popup.deleteItemBtn`}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </ListTypeContent>
          );
        })}

        <Button
          onClick={createItem}
          data-test={`${dataTest}.addItemBtn`}
          variant="contained"
          color="default"
        >
          <span>Add Item</span>
        </Button>
      </CssGrid>
    );
  },
);
