import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_NAMES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialTypographySettings } from './MaterialTypography.settings';
export const MaterialTypography = {
    name: COMPONENT_DSL_NAMES.MaterialTypography,
    displayName: 'Typography',
    icon: 'typography',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@mui/material',
        componentName: 'Typography',
    },
    overlayOutlineWhenEmpty: true,
    schema: Object.assign(Object.assign({ presentations: [visibleByCondition], mainPropPath: {
            mainProp: 'Text',
        } }, getSysMinSizeStyle({ minWidth: 16, minHeight: 16, display: null })), { supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        }, predefineds: {
            props: {
                variant: 'inherit',
                align: 'inherit',
                color: 'inherit',
                paragraph: false,
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Typography',
                            },
                        },
                    ],
                },
            },
        }, props: Object.assign(Object.assign({}, commonComponentProps), { component: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, align: { type: COMPONENT_DSL_PROP_TYPES.enum }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, gutterBottom: { type: COMPONENT_DSL_PROP_TYPES.boolean }, noWrap: { type: COMPONENT_DSL_PROP_TYPES.boolean }, paragraph: { type: COMPONENT_DSL_PROP_TYPES.boolean } }) }),
    settings: MaterialTypographySettings,
};
