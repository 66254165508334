import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react';
import { AuthProvider } from '@8base-react/auth';
import { AuthLink } from '@8base/apollo-links';
import { AUTH_STRATEGIES, Auth } from '@8base/auth';
import errorCodes from '@8base/error-codes';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider as BaseApolloProvider, } from '@apollo/client';
import { onError as createErrorLink } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { AUTH_TYPES } from '@builder/schemas';
import { useRuntimeErrorCallbacks } from '../RuntimeErrorProvider';
const getAuthClient = (apiEndpoint, authDSL) => {
    if (!authDSL) {
        return null;
    }
    if (authDSL.type === AUTH_TYPES.token) {
        return Auth.createClient({
            strategy: AUTH_STRATEGIES.API_TOKEN,
            subscribable: true,
            storageOptions: {
                storageKey: 'user-app-token-auth',
            },
        }, {
            apiToken: authDSL.apiAuthToken,
        });
    }
    if (authDSL.type === AUTH_TYPES.webNative) {
        return Auth.createClient({
            strategy: AUTH_STRATEGIES.WEB_8BASE_NATIVE,
            subscribable: true,
            storageOptions: {
                storageKey: 'user-app-web-auth',
            },
        }, {
            apiEndpoint,
            authProfileId: authDSL.authProfileID,
        });
    }
    return null;
};
const initialApolloClient = new ApolloClient({
    connectToDevTools: false,
    cache: new InMemoryCache(),
});
export const ApolloProvider = ({ apiEndpoint, authDSL, children, }) => {
    const { onAppFunctionError } = useRuntimeErrorCallbacks();
    const [apolloClient, setApolloClient] = useState(initialApolloClient);
    const [authClient, setAuthClient] = useState(null);
    const previousApiEndpoint = useRef();
    useEffect(() => {
        if (apiEndpoint) {
            previousApiEndpoint.current = apiEndpoint;
            const currentAuthClient = getAuthClient(apiEndpoint, authDSL);
            setAuthClient(currentAuthClient);
            const errorLink = createErrorLink(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(graphQLError => {
                        console.warn(`[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`);
                        const errorCode = graphQLError.code;
                        if (!(errorCode === errorCodes.ValidationErrorCode ||
                            errorCode === errorCodes.NotAuthorizedErrorCode)) {
                            onAppFunctionError(graphQLError);
                        }
                    });
                }
                else if (networkError) {
                    console.warn(`[Network error]: ${networkError}`);
                    onAppFunctionError(networkError);
                }
            });
            const authLink = currentAuthClient
                ? new AuthLink({
                    getAuthState: () => currentAuthClient === null || currentAuthClient === void 0 ? void 0 : currentAuthClient.getState(),
                    onAuthError: () => currentAuthClient === null || currentAuthClient === void 0 ? void 0 : currentAuthClient.logout(),
                    onIdTokenExpired: () => currentAuthClient === null || currentAuthClient === void 0 ? void 0 : currentAuthClient.refreshToken(),
                })
                : null;
            const apolloLinks = authLink
                ? ApolloLink.from([errorLink, authLink, createHttpLink({ uri: apiEndpoint })])
                : ApolloLink.from([errorLink, createHttpLink({ uri: apiEndpoint })]);
            setApolloClient(new ApolloClient({
                connectToDevTools: false,
                cache: new InMemoryCache(),
                link: apolloLinks,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authDSL, apiEndpoint]);
    if (!authClient) {
        return _jsx(BaseApolloProvider, Object.assign({ client: apolloClient }, { children: children }));
    }
    return (_jsx(AuthProvider, Object.assign({ authClient: authClient }, { children: _jsx(BaseApolloProvider, Object.assign({ client: apolloClient }, { children: children })) })));
};
