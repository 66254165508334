import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { getCommonSymbolStyleProps, getCommonSymbolEventProps, commonComponentProps, commonActionProps, commonReactProps, } from '../../common/commonProps';
import { GridContainerSymbolSettings } from './GridContainerSymbol.settings';
export const GridContainerSymbol = {
    name: COMPONENT_DSL_NAMES.GridContainerSymbol,
    displayName: 'Grid',
    icon: 'grid',
    type: COMPONENT_DSL_TYPES.symbol,
    deprecated: {
        version: '0.4.0',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyRemovable,
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.GridItemSymbol,
                            props: {},
                        },
                    ],
                },
                style: getPredefinedStyles(),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.GridItemSymbol],
            }, alignContent: { type: COMPONENT_DSL_PROP_TYPES.enum }, alignItems: { type: COMPONENT_DSL_PROP_TYPES.enum }, direction: { type: COMPONENT_DSL_PROP_TYPES.enum }, justifyContent: { type: COMPONENT_DSL_PROP_TYPES.enum }, spacing: { type: COMPONENT_DSL_PROP_TYPES.enum }, wrap: { type: COMPONENT_DSL_PROP_TYPES.enum }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialGrid,
            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { container: true, alignContent: '{{ symbolProps.alignContent }}', alignItems: '{{ symbolProps.alignItems }}', direction: '{{ symbolProps.direction }}', justifyContent: '{{ symbolProps.justifyContent }}', spacing: '{{ symbolProps.spacing }}', wrap: '{{ symbolProps.wrap }}', children: '{{ symbolProps.children }}', draggable: '{{ symbolProps.draggable }}' }),
        },
    },
    settings: GridContainerSymbolSettings,
};
