import React, { useCallback, useMemo } from 'react';

import { Clear as ClearIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { ComponentSettingAllowedRolesDSL } from '@builder/schemas';
import { memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { Chip, Autocomplete, AutocompleteOption } from 'src/shared/components';

type AllowedRolesTypeProps = {
  setting: ComponentSettingAllowedRolesDSL;
  onUpdateAllowedRoles: (allowedRoles: string[]) => void;
  'data-test'?: string;
};

export const AllowedRolesType = memo(
  'AllowedRolesType',
  ({
    setting,
    onUpdateAllowedRoles,
    'data-test': dataTest,
  }: AllowedRolesTypeProps): JSX.Element => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const nodeAllowedRoles = useMemo(() => selectedNodeDSL.condition?.allowedRoles || [], [
      selectedNodeDSL.condition?.allowedRoles,
    ]);

    const onChangeAllowedRoles = useCallback(
      (value?: string[]) => {
        if (!value) return;

        onUpdateAllowedRoles(value);
      },
      [onUpdateAllowedRoles],
    );

    return (
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          multiple
          disableCloseOnSelect
          value={nodeAllowedRoles}
          label={setting.label}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={(option as AutocompleteOption<string>).label}
                label={(option as AutocompleteOption<string>).label}
                deleteIcon={<ClearIcon />}
              />
            ))
          }
          onChange={onChangeAllowedRoles}
          // The roles have been removed from the default states because they are currently unused
          // The plan is to resume roles in the future, so this component is not removed
          options={[]}
          data-test={dataTest}
        />
      </Grid>
    );
  },
);
