import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import {
  componentSettingSelectors,
  stateListSelectors,
  ComponentSettingStateSelectorDSL,
  STATE_TYPES,
  CustomStateTypesDSL,
  createSelectOptionByValue,
  UpdateStateArgs,
  STATE_ACTIONS,
  hasPropJsCode,
  nodeListSelectors,
} from '@builder/schemas';
import { isArray, isNil, isObject, isJsonString, memo } from '@builder/utils';

import { TextViewEditor } from '../../setting-views';
import { useAppDSLStates, useNodeListDSL } from 'src/providers';
import { Autocomplete } from 'src/shared/components';
import { useDashboardCurrentRouteNodeDSL } from 'src/shared/hooks';

type StateKeys = keyof UpdateStateArgs;

type RequestSelectorTypeProps = {
  setting: ComponentSettingStateSelectorDSL;
  dataTestPrefix?: string;
  /**
   * The value that is stored in the DSL by the corresponding keyPath.
   */
  keyArgs: UpdateStateArgs;
  /**
   * Callback to update keyValue.
   */
  onChange: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
};

const STATE_KEYS: Record<string, StateKeys> = {
  type: 'stateType',
  action: 'stateAction',
  value: 'stateValue',
  key: 'stateKey',
};

export const StateSelectorType = memo(
  'StateSelectorType',
  ({ setting, dataTestPrefix, keyArgs = {}, onChange }: RequestSelectorTypeProps): JSX.Element => {
    const nodeListDSL = useNodeListDSL();
    const userAppStates = useAppDSLStates();
    const currentRouteNodeDSL = useDashboardCurrentRouteNodeDSL();
    const bindedLayoutNode = nodeListSelectors.getBindedLayoutNodeDSL(nodeListDSL, {
      nodeID: currentRouteNodeDSL.id,
    });
    const routePath = currentRouteNodeDSL.props.path as string;
    const isLayoutRoute = routePath ? routePath.startsWith('/__layouts/') : false;
    const bindedLayoutStates = bindedLayoutNode?.context;
    const allLayoutStates = Object.values(nodeListDSL)
      .filter(node => {
        const { props } = node;
        return (
          props?.path && typeof props.path === 'string' && props.path.startsWith('/__layouts/')
        );
      })
      .reduce((acc, node) => {
        return { ...acc, ...node.context };
      }, {});
    const customStatesArray = stateListSelectors
      .getCustomStateArrayDSL(userAppStates)
      .filter(state => {
        if (Object.values(allLayoutStates).includes(state)) {
          return (
            isLayoutRoute ||
            !!(bindedLayoutStates && Object.values(bindedLayoutStates).includes(state))
          );
        }

        return true;
      });
    const keyName = componentSettingSelectors.getSettingsKeyNamePath(setting);
    const keyPath = componentSettingSelectors.getSettingsKeyPath(setting);
    const [formatError, setFormatError] = useState<string | null>(null);
    const { stateName, stateType, stateAction, stateValue, stateKey } = keyArgs;
    const customStatesOptions = customStatesArray.reduce(
      (acc: { label: string; value: string }[], state) => {
        if (
          stateAction === STATE_ACTIONS.insertItemInArray ||
          stateAction === STATE_ACTIONS.removeItemFromArray ||
          stateAction === STATE_ACTIONS.setProperty
        ) {
          return [...acc, { label: state.name, value: `{{   ${state.name}.value   }}` }];
        }

        if (state.type === stateType) {
          return [...acc, { label: state.name, value: `{{   ${state.name}.value   }}` }];
        }

        return acc;
      },
      [],
    );
    const createActionOptions = (type: CustomStateTypesDSL | undefined) => {
      switch (type) {
        case STATE_TYPES.string:
        case STATE_TYPES.number:
          return [STATE_ACTIONS.setValue];

        case STATE_TYPES.object:
          return [STATE_ACTIONS.setValue, STATE_ACTIONS.setProperty];

        case STATE_TYPES.array:
          return [
            STATE_ACTIONS.setValue,
            STATE_ACTIONS.insertItemInArray,
            STATE_ACTIONS.removeItemFromArray,
          ];

        case STATE_TYPES.boolean:
          return [
            STATE_ACTIONS.setValue,
            STATE_ACTIONS.setTrue,
            STATE_ACTIONS.setFalse,
            STATE_ACTIONS.toggle,
          ];

        default:
          return [];
      }
    };

    const onChangeState = useCallback(
      (newStateName?: string) => {
        if (!newStateName) {
          return;
        }

        const newNodeStatesConnection = customStatesArray?.find(
          ({ name: connectionStateName }) => connectionStateName === newStateName,
        );

        if (newNodeStatesConnection) {
          onChange({
            keyValue: {
              stateID: newNodeStatesConnection.id,
              stateName: newNodeStatesConnection.name,
              stateType: newNodeStatesConnection.type as CustomStateTypesDSL,
            },
            keyPath,
          });
        }
      },
      [customStatesArray, keyPath, onChange],
    );

    const onChangeUpdateStateArgs = useCallback(
      (selectedStateKey: StateKeys, newValue?: string) => {
        if (!newValue) {
          return;
        }

        if (selectedStateKey === STATE_KEYS.action) {
          setFormatError(null);
          onChange({
            keyValue: {
              ...keyArgs,
              stateAction: newValue,
              stateValue: undefined,
              stateKey: undefined,
            },
            keyPath,
          });
        }

        if (hasPropJsCode(newValue)) {
          onChange({
            keyValue: {
              ...keyArgs,
              [selectedStateKey]: newValue,
            },
            keyPath,
          });
        } else {
          switch (stateType) {
            case STATE_TYPES.string: {
              setFormatError(null);
              onChange({
                keyValue: {
                  ...keyArgs,
                  [selectedStateKey]: newValue,
                },
                keyPath,
              });
              break;
            }
            case STATE_TYPES.number: {
              if (String(newValue).match(/^(-|\+)?(0|[1-9]\d*)?(\d+)?$/)) {
                setFormatError(null);
                onChange({
                  keyValue: {
                    ...keyArgs,
                    [selectedStateKey]: newValue,
                  },
                  keyPath,
                });
              } else {
                setFormatError(`Please enter a valid ${stateType} format.`);
              }

              break;
            }

            case STATE_TYPES.boolean: {
              if (newValue === 'true' || newValue === 'false') {
                setFormatError(null);
                onChange({
                  keyValue: {
                    ...keyArgs,
                    [selectedStateKey]: newValue,
                  },
                  keyPath,
                });
              } else {
                setFormatError(`Please enter a valid ${stateType} format.`);
              }

              break;
            }
            case STATE_TYPES.array:
            case STATE_TYPES.object: {
              if (stateAction === STATE_ACTIONS.setValue) {
                if (newValue && isJsonString(newValue)) {
                  const parsedValue = JSON.parse(newValue);
                  if (
                    (stateType === STATE_TYPES.array && isArray(parsedValue)) ||
                    (stateType === STATE_TYPES.object && isObject(parsedValue))
                  ) {
                    setFormatError(null);
                    onChange({
                      keyValue: {
                        ...keyArgs,
                        [selectedStateKey]: newValue,
                      },
                      keyPath,
                    });
                  } else {
                    setFormatError(`Please enter a valid ${stateType} format.`);
                  }
                } else {
                  setFormatError(`Please enter a valid ${stateType} format.`);
                }
              } else {
                onChange({
                  keyValue: {
                    ...keyArgs,
                    [selectedStateKey]: newValue,
                  },
                  keyPath,
                });
              }

              break;
            }
          }
        }
      },
      [keyArgs, keyPath, onChange, stateAction, stateType],
    );

    const stateListOptions = customStatesArray.map(state => {
      return { value: state.name, label: state.name };
    });
    const dataTest = `${dataTestPrefix}.${keyName}`;

    return (
      <>
        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              value={stateName}
              label={setting.label}
              onChange={onChangeState}
              options={stateListOptions}
              data-test={dataTest}
            />
          </Grid>
          {stateType && (
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                value={stateAction}
                label="Action"
                onChange={newAction => onChangeUpdateStateArgs(STATE_KEYS.action, newAction)}
                options={createActionOptions(stateType).map(createSelectOptionByValue)}
                data-test={`${dataTest}.${STATE_KEYS.action}`}
              />
            </Grid>
          )}
          {stateType === STATE_TYPES.object && stateAction === STATE_ACTIONS.setProperty && (
            <Grid item xs={12}>
              <TextViewEditor
                label="Key"
                propValue={stateKey as string}
                onChangePropValue={newValue => onChangeUpdateStateArgs(STATE_KEYS.key, newValue)}
                data-test={`${dataTest}.${STATE_KEYS.key}`}
                customStateOptions={customStatesOptions}
              />
            </Grid>
          )}
          {stateType &&
            stateAction &&
            ![STATE_ACTIONS.setTrue, STATE_ACTIONS.setFalse, STATE_ACTIONS.toggle].includes(
              stateAction,
            ) && (
              <Grid item xs={12}>
                <TextViewEditor
                  label={
                    stateType === STATE_TYPES.array && stateAction !== STATE_ACTIONS.setValue
                      ? 'Item'
                      : 'Value'
                  }
                  propValue={stateValue as string}
                  onChangePropValue={newValue =>
                    onChangeUpdateStateArgs(STATE_KEYS.value, newValue)
                  }
                  data-test={`${dataTest}.${STATE_KEYS.value}`}
                  error={!isNil(formatError)}
                  helperText={formatError}
                  customStateOptions={customStatesOptions}
                />
              </Grid>
            )}
        </Grid>
      </>
    );
  },
);
