import { Migration } from '../Migration';
class Migration_0_19_5 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.5';
    }
    getChangeList() {
        return ['Delete default code in beforeEach and afterEach'];
    }
    migrateAppDSL(appDSL) {
        var _a, _b, _c;
        const transFormDefaultcode = (code) => {
            const regexWrapper = /^return function\(\{([\w\s,]+)\}\) \{\s+\/\/ type here\n\}$/;
            const match = code.match(regexWrapper);
            return match ? '// Your code here' : code;
        };
        const routerHooks = appDSL.routeHooks || {
            beforeEach: {
                name: 'BeforeEachGlobalHook',
                code: '// Your code here',
                scope: 'global',
            },
            afterEach: {
                name: 'AfterEachGlobalHook',
                code: '// Your code here',
                scope: 'global',
            },
        };
        const beforeEachCode = (_a = transFormDefaultcode(routerHooks.beforeEach.code || '')) === null || _a === void 0 ? void 0 : _a.replace("\nconst isTokenExpired = (token) => {\n    try {\n      const tokenObject = JSON.parse(atob(token.split('.')[1]));\n      const expirationDate = new Date(tokenObject.exp * 1000); // Convert expiration time to milliseconds\n      const currentDate = new Date();\n      return currentDate > expirationDate;\n    } catch (error) {\n      return true; // If parsing the token fails, consider it expired\n    }\n  } \n\n", '');
        const afterEachCode = transFormDefaultcode(routerHooks.afterEach.code || '');
        return Object.assign(Object.assign({}, appDSL), { routeHooks: {
                beforeEach: Object.assign(Object.assign({}, (_b = appDSL.routeHooks) === null || _b === void 0 ? void 0 : _b.beforeEach), { code: beforeEachCode, name: 'BeforeEachGlobalHook', scope: 'global' }),
                afterEach: Object.assign(Object.assign({}, (_c = appDSL.routeHooks) === null || _c === void 0 ? void 0 : _c.afterEach), { code: afterEachCode, name: 'AfterEachGlobalHook', scope: 'global' }),
            } });
    }
}
export const migration_0_19_5 = new Migration_0_19_5();
