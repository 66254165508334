import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_PROP_TYPES, TYPE_DEFINITION_TYPES, } from '../../constants';
export const commonReactProps = {
    key: {
        type: COMPONENT_DSL_PROP_TYPES.number,
    },
};
export const commonSystemProps = {
    __borderRadiusMode: {
        type: COMPONENT_DSL_PROP_TYPES.string,
        system: true,
    },
    __borderMode: {
        type: COMPONENT_DSL_PROP_TYPES.string,
        system: true,
    },
};
export const commonStyleProps = {
    /** @deprecated */
    classes: {
        type: COMPONENT_DSL_PROP_TYPES.object,
    },
    style: {
        type: COMPONENT_DSL_PROP_TYPES.style,
    },
    css: {
        type: COMPONENT_DSL_PROP_TYPES.css,
        targetStylePropName: 'style',
    },
    /** @deprecated */
    className: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
};
export const commonActionProps = {
    onClick: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onMouseOver: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onMouseOut: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onMouseDown: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onMouseUp: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onMouseEnter: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onMouseLeave: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onWheel: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onContextMenu: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onAuxClick: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onDragStart: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onDragOver: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
    onDrop: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'event',
                path: [0],
                schema: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    props: {
                        id: {
                            type: COMPONENT_DSL_PROP_TYPES.string,
                            uniq: true,
                        },
                    },
                    typeDefinition: {
                        type: TYPE_DEFINITION_TYPES.import,
                        definition: 'SyntheticEvent',
                        imports: [
                            {
                                varName: 'SyntheticEvent',
                                importName: 'react',
                            },
                        ],
                    },
                },
            },
        ],
    },
};
export const commonBasicProps = {
    id: {
        type: COMPONENT_DSL_PROP_TYPES.string,
        uniqAmongAllNodes: true,
    },
    title: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
    className: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
    lang: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
    translate: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
    draggable: {
        type: COMPONENT_DSL_PROP_TYPES.boolean,
    },
    accept: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
    maxFileSize: {
        type: COMPONENT_DSL_PROP_TYPES.number,
    },
    customProps: {
        type: COMPONENT_DSL_PROP_TYPES.customProps,
        props: {
            type: COMPONENT_DSL_PROP_TYPES.array,
            item: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    key: {
                        type: COMPONENT_DSL_PROP_TYPES.string,
                        required: true,
                    },
                    value: {
                        type: COMPONENT_DSL_PROP_TYPES.string,
                        required: true,
                    },
                },
            },
        },
    },
};
export const fieldValidationProps = {
    fieldValidation: {
        type: COMPONENT_DSL_PROP_TYPES.fieldValidation,
        props: {
            type: COMPONENT_DSL_PROP_TYPES.array,
            item: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    key: {
                        type: COMPONENT_DSL_PROP_TYPES.string,
                        required: true,
                    },
                    type: {
                        type: COMPONENT_DSL_PROP_TYPES.string,
                        required: false,
                    },
                    value: {
                        type: COMPONENT_DSL_PROP_TYPES.string,
                        required: false,
                    },
                    validation: {
                        type: COMPONENT_DSL_PROP_TYPES.array,
                        props: {
                            type: COMPONENT_DSL_PROP_TYPES.array,
                            item: {
                                type: COMPONENT_DSL_PROP_TYPES.object,
                                props: {
                                    key: {
                                        type: COMPONENT_DSL_PROP_TYPES.string,
                                        required: true,
                                    },
                                    arguments: {
                                        type: COMPONENT_DSL_PROP_TYPES.string,
                                        required: false,
                                    },
                                    errorMessage: {
                                        type: COMPONENT_DSL_PROP_TYPES.string,
                                        required: true,
                                    },
                                    limit: {
                                        type: COMPONENT_DSL_PROP_TYPES.number,
                                        required: false,
                                    },
                                    min: {
                                        type: COMPONENT_DSL_PROP_TYPES.number,
                                        required: false,
                                    },
                                    max: {
                                        type: COMPONENT_DSL_PROP_TYPES.number,
                                        required: false,
                                    },
                                    regex: {
                                        type: COMPONENT_DSL_PROP_TYPES.string,
                                        required: false,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
export const commonComponentProps = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonActionProps), commonSystemProps), commonReactProps), commonBasicProps), { children: {
        type: COMPONENT_DSL_PROP_TYPES.reactNode,
    } });
export const commonSymbolProps = {
    path: {
        type: COMPONENT_DSL_PROP_TYPES.enum,
        allowJS: false,
    },
    exact: {
        type: COMPONENT_DSL_PROP_TYPES.boolean,
    },
    router: {
        type: COMPONENT_DSL_PROP_TYPES.string,
    },
};
export const commonFormFieldProps = {
    name: {
        type: COMPONENT_DSL_PROP_TYPES.string,
        required: true,
        uniq: true,
    },
    validate: {
        type: COMPONENT_DSL_PROP_TYPES.action,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'value',
                path: [0],
            },
        ],
    },
};
export const getCommonSymbolEventProps = (prefixPath = [], pathRequired = false) => {
    const pathSeparator = pathRequired ? '.' : '?.';
    const prefix = prefixPath.length > 0 ? `symbolProps.${prefixPath.join(pathSeparator)}` : 'symbolProps';
    return {
        onClick: `{{ ${prefix}${pathSeparator}onClick }}`,
        onMouseOver: `{{ ${prefix}${pathSeparator}onMouseOver }}`,
        onMouseOut: `{{ ${prefix}${pathSeparator}onMouseOut }}`,
        onMouseDown: `{{ ${prefix}${pathSeparator}onMouseDown }}`,
        onMouseUp: `{{ ${prefix}${pathSeparator}onMouseUp }}`,
        onMouseEnter: `{{ ${prefix}${pathSeparator}onMouseEnter }}`,
        onMouseLeave: `{{ ${prefix}${pathSeparator}onMouseLeave }}`,
        onWheel: `{{ ${prefix}${pathSeparator}onWheel }}`,
        onContextMenu: `{{ ${prefix}${pathSeparator}onContextMenu }}`,
        onAuxClick: `{{ ${prefix}${pathSeparator}onAuxClick }}`,
        onDragStart: `{{ ${prefix}${pathSeparator}onDragStart }}`,
        onDragOver: `{{ ${prefix}${pathSeparator}onDragOver }}`,
        onDrop: `{{ ${prefix}${pathSeparator}onDrop }}`,
    };
};
export const getCommonSymbolStyleProps = (prefixPath = [], pathRequired = false) => {
    const pathSeparator = pathRequired ? '.' : '?.';
    const prefix = prefixPath.length > 0 ? `symbolProps.${prefixPath.join(pathSeparator)}` : 'symbolProps';
    return {
        css: `{{ ${prefix}${pathSeparator}css }}`,
        style: `{{ ${prefix}${pathSeparator}style }}`,
        classes: `{{ ${prefix}${pathSeparator}classes }}`,
        className: `{{ ${prefix}${pathSeparator}className }}`,
    };
};
export const getCommonSymbolBasicProps = (prefixPath = [], pathRequired = false) => {
    const pathSeparator = pathRequired ? '.' : '?.';
    const prefix = prefixPath.length > 0 ? `symbolProps.${prefixPath.join(pathSeparator)}` : 'symbolProps';
    return {
        id: `{{ ${prefix}${pathSeparator}id }}`,
        title: `{{ ${prefix}${pathSeparator}title }}`,
        lang: `{{ ${prefix}${pathSeparator}lang }}`,
        translate: `{{ ${prefix}${pathSeparator}translate }}`,
        className: `{{ ${prefix}${pathSeparator}className }}`,
        draggable: `{{ ${prefix}${pathSeparator}draggable }}`,
        accept: `{{ ${prefix}${pathSeparator}accept }}`,
        maxFileSize: `{{ ${prefix}${pathSeparator}maxFileSize }}`,
    };
};
