import { values } from 'ramda';

import { StateListDSL } from '@builder/schemas';

import { filterStatesByType } from './common';
import { ARRAY, OBJECT, VALUE } from './constants';
import { getMetadataValues } from './getMetadataValues';
import { GlobalAndLocalStates, MetaStates } from './types';

export const getAllArrayAndObjectsStates = (
  states: StateListDSL,
  appRuntimeStates: GlobalAndLocalStates,
): MetaStates => {
  const statesArrayType = filterStatesByType(states, ARRAY);
  const statesObjectType = filterStatesByType(states, OBJECT);

  const metaArrayTypeStates = values(statesArrayType).reduce((accum, stateArrayType) => {
    return {
      ...accum,
      [stateArrayType.name]: {
        id: stateArrayType.id,
        name: stateArrayType.name,
        type: ARRAY,
        value:
          stateArrayType.type === ARRAY ? appRuntimeStates[stateArrayType.name]?.value || [] : null,
        children: null,
      },
    };
  }, {});
  const metaObjectTypeStates = getMetadataValues(statesObjectType, appRuntimeStates, VALUE);

  return {
    ...metaArrayTypeStates,
    ...metaObjectTypeStates,
  };
};
