var _a;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, STATE_BOOLEAN_VARIANTS, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, getCommonSymbolEventProps, getCommonSymbolStyleProps, } from '../../common/commonProps';
import { MaterialAccordionSummary } from '../../material-components/MaterialAccordionSummary';
import { AccordionSymbolSettings } from './AccordionSymbol.settings';
export const AccordionSymbol = {
    name: COMPONENT_DSL_NAMES.AccordionSymbol,
    displayName: 'Accordion',
    icon: 'accordion',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.other,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            selectNodeInside: ['summary', 'children'],
            mainProp: 'Text',
        },
        dndTargetPropName: 'details',
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.openClose,
                    name: 'accordion',
                    defaultValue: true,
                },
            ],
            props: {
                expanded: '{{ __STATES__.accordion.isOpened }}',
                summary: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialAccordionSummary,
                            props: Object.assign(Object.assign({}, (_a = MaterialAccordionSummary.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { onClick: {
                                    actionType: 'customCode',
                                    args: {
                                        code: '__STATES__.accordion.toggle()',
                                    },
                                } }),
                        },
                    ],
                },
                details: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialAccordionDetails,
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { summary: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialAccordionSummary],
            }, details: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialAccordionDetails],
            }, actions: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialAccordionActions],
            }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, square: { type: COMPONENT_DSL_PROP_TYPES.boolean }, expanded: { type: COMPONENT_DSL_PROP_TYPES.boolean }, defaultExpanded: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialAccordion,
            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { disabled: '{{ symbolProps.disabled }}', square: '{{ symbolProps.square }}', expanded: '{{ symbolProps.expanded }}', defaultExpanded: '{{ symbolProps.defaultExpanded }}', children: '{{ [symbolProps.summary, symbolProps.details, symbolProps.actions] }}', id: '{{ symbolProps.id }}', title: '{{ symbolProps.title }}', className: '{{ symbolProps.className }}', lang: '{{ symbolProps.lang }}', translate: '{{ symbolProps.translate }}', draggable: '{{ symbolProps.draggable }}' }),
        },
    },
    settings: AccordionSymbolSettings,
};
