import { STATE_BOOLEAN_VARIANTS, STATE_REQUEST_VARIANTS, STATE_TYPES, } from '@builder/schemas';
import { ERROR_SCOPES, SystemError } from '@builder/utils';
import { ArrayStateAccessor } from './ArrayStateAccessor';
import { FunctionStateAccessor } from './FunctionStateAccessor';
import { LazyQueryStateAccessor } from './LazyQueryStateAccessor';
import { MutationStateAccessor } from './MutationStateAccessor';
import { NumberStateAccessor } from './NumberStateAccessor';
import { ObjectStateAccessor } from './ObjectStateAccessor';
import { OpenCloseBooleanStateAccessor } from './OpenCloseBooleanStateAccessor';
import { QueryStateAccessor } from './QueryStateAccessor';
import { RestStateAccessor } from './RestStateAccessor';
import { StringStateAccessor } from './StringStateAccessor';
import { TrueFalseBooleanStateAccessor } from './TrueFalseBooleanStateAccessor';
export class AppStateAccessorFactory {
    constructor({ stateListDSL, resourceListDSL, assetListDSL, authResourceDSL, }) {
        this.stateListDSL = stateListDSL;
        this.resourceListDSL = resourceListDSL;
        this.assetListDSL = assetListDSL;
        this.authResourceDSL = authResourceDSL;
    }
    createAppBoolStateDSLAccessor(appState) {
        switch (appState.variant) {
            case STATE_BOOLEAN_VARIANTS.openClose: {
                return new OpenCloseBooleanStateAccessor(appState, this.stateListDSL);
            }
            case STATE_BOOLEAN_VARIANTS.trueFalse: {
                return new TrueFalseBooleanStateAccessor(appState, this.stateListDSL);
            }
        }
        throw new SystemError(ERROR_SCOPES.codeEngine, `${appState.variant} variant not found.`);
    }
    createQueryStateDSLAccessor(appState) {
        switch (appState.variant) {
            case STATE_REQUEST_VARIANTS.mutation: {
                return new MutationStateAccessor({
                    appState,
                    stateListDSL: this.stateListDSL,
                    assetListDSL: this.assetListDSL,
                    resourceListDSL: this.resourceListDSL,
                    authResourceDSL: this.authResourceDSL,
                });
            }
            case STATE_REQUEST_VARIANTS.query: {
                return new QueryStateAccessor({
                    appState,
                    stateListDSL: this.stateListDSL,
                    assetListDSL: this.assetListDSL,
                    resourceListDSL: this.resourceListDSL,
                    authResourceDSL: this.authResourceDSL,
                });
            }
            case STATE_REQUEST_VARIANTS.lazyQuery: {
                return new LazyQueryStateAccessor({
                    appState,
                    stateListDSL: this.stateListDSL,
                    assetListDSL: this.assetListDSL,
                    resourceListDSL: this.resourceListDSL,
                    authResourceDSL: this.authResourceDSL,
                });
            }
            case STATE_REQUEST_VARIANTS.rest: {
                return new RestStateAccessor({
                    appState,
                    stateListDSL: this.stateListDSL,
                    resourceListDSL: this.resourceListDSL,
                    assetListDSL: this.assetListDSL,
                });
            }
        }
        throw new SystemError(ERROR_SCOPES.codeEngine, `${appState.variant} variant not found.`);
    }
    createAppStateAccessor(appState) {
        switch (appState.type) {
            case STATE_TYPES.boolean: {
                return this.createAppBoolStateDSLAccessor(appState);
            }
            case STATE_TYPES.number: {
                return new NumberStateAccessor(appState, this.stateListDSL);
            }
            case STATE_TYPES.string: {
                return new StringStateAccessor(appState, this.stateListDSL);
            }
            case STATE_TYPES.function: {
                return new FunctionStateAccessor(appState, this.stateListDSL);
            }
            case STATE_TYPES.query: {
                return this.createQueryStateDSLAccessor(appState);
            }
            case STATE_TYPES.object: {
                return new ObjectStateAccessor(appState, this.stateListDSL);
            }
            case STATE_TYPES.array: {
                return new ArrayStateAccessor(appState, this.stateListDSL);
            }
        }
        throw new SystemError(ERROR_SCOPES.codeEngine, `${appState.type} type not found.`);
    }
}
