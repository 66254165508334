import { omit, pathOr } from 'ramda';

import {
  AppDSL,
  COMPONENT_DSL_NAMES,
  NodeDSL,
  NodeID,
  NodeListDSL,
  nodeListSelectors,
  ReactNodePropValue,
  StateListDSL,
  NodeStateConnectionDSL,
  StateDSL,
  nodeSelectors,
  ComponentListDSL,
  ClonedNodeDSL,
} from '@builder/schemas';
import { incrementName, generateID, isArray, isObject, isString } from '@builder/utils';

import { dashboardSelectors, DashboardState } from 'src/store';

type copyPageNodeWithChildrenParams = {
  nodeDSL: NodeDSL;
  stateListDSL: StateListDSL;
  componentListDSL: ComponentListDSL;
  nodeListDSL: NodeListDSL;
  newPageID: string;
};

type getAllChildrenNodesParams = {
  nodeDSL: NodeDSL;
  nodeListDSL: NodeListDSL;
  componentListDSL: ComponentListDSL;
  parentID: NodeID;
};

/**
 * This function replaces old ids with their corresponding new ids from the given prop
 * using the currentNodesGrandChildrenIdMap to map between the old id and its new id
 * @param prop NodeDSL
 * @param currentNodesGrandChildrenIdMap map that contains the old id as key and the new id as value e.g {"oldId": "newId"}
 * @returns new node with updated ids
 */
const updateOldIdsWithNewIdsFromNodeDSL = (
  prop: string | string[] | Record<string, unknown>,
  currentNodesGrandChildrenIdMap: Record<string, string>,
) => {
  if (isString(prop)) {
    const newId = currentNodesGrandChildrenIdMap[prop];
    return newId || prop;
  }

  if (isArray(prop)) {
    const updatedArray: string[] = prop.map(value =>
      typeof value === 'object'
        ? (updateOldIdsWithNewIdsFromNodeDSL(value, currentNodesGrandChildrenIdMap) as string)
        : value,
    );

    return updatedArray;
  }

  if (isObject(prop)) {
    let updatedNode = { ...prop };
    Object.entries(prop).forEach(([key, value]) => {
      if (isString(value)) {
        const newId = currentNodesGrandChildrenIdMap[value];
        updatedNode = {
          ...updatedNode,
          [key]: newId || value,
        };
      }

      if (isObject(value)) {
        updatedNode = {
          ...updatedNode,
          [key]: updateOldIdsWithNewIdsFromNodeDSL(value, currentNodesGrandChildrenIdMap),
        };
      }

      if (isArray(value)) {
        updatedNode = {
          ...updatedNode,
          [key]: (value as string[]).map(v =>
            updateOldIdsWithNewIdsFromNodeDSL(v, currentNodesGrandChildrenIdMap),
          ),
        };
      }
    });

    return updatedNode;
  }

  return prop;
};

const getAllChildrenNodes = ({
  nodeDSL,
  nodeListDSL,
  componentListDSL,
  parentID,
}: getAllChildrenNodesParams) => {
  const childrenNodes: ClonedNodeDSL[] = [];

  const allChildrenIDs = nodeSelectors.getAllImmediateChildrenIDs(nodeDSL, {
    componentListDSL,
  });

  allChildrenIDs.forEach(childID => {
    const childNodeDSL = nodeListDSL[childID];
    const newParentId = generateID();

    const grandChildren = nodeSelectors.getAllImmediateChildrenIDs(childNodeDSL, {
      componentListDSL,
    });

    let grandChildrenNodes: ClonedNodeDSL[] = [];
    if (grandChildren?.length > 0) {
      grandChildrenNodes = getAllChildrenNodes({
        parentID: newParentId,
        nodeDSL: childNodeDSL,
        nodeListDSL,
        componentListDSL,
      });
      childrenNodes.push(...grandChildrenNodes);
    }

    const currentNodeChildren = grandChildrenNodes.filter(n => n.parentID === newParentId);

    const currentNodesGrandChildrenIdMap = currentNodeChildren.reduce(
      (acc, curr) => ({ ...acc, [curr.oldID as string]: curr.id }),
      {},
    );
    const childNodeWithUpdatedIds = updateOldIdsWithNewIdsFromNodeDSL(
      { ...childNodeDSL },
      currentNodesGrandChildrenIdMap,
    ) as NodeDSL;

    // push current node to childrenNodes
    childrenNodes.push({
      ...childNodeWithUpdatedIds,
      oldID: childNodeDSL.id,
      id: newParentId,
      parentID,
    });
  });

  return childrenNodes;
};

const copyStatesWithNewIDs = (
  nodeStates: NodeStateConnectionDSL[],
  stateListDSL: StateListDSL,
  newNodes: ClonedNodeDSL[],
  newPageID: string,
) => {
  const allOldNodeStates = nodeStates?.reduce(
    (uniqueObjects: NodeStateConnectionDSL[], currentObject) => {
      const exists = uniqueObjects.some(
        object =>
          object.stateID === currentObject.stateID &&
          object.required === currentObject.required &&
          object.componentBoundID === currentObject.componentBoundID,
      );

      if (!exists) {
        return [...uniqueObjects, currentObject];
      }

      return uniqueObjects;
    },
    [],
  );

  const allNodeIdStates: NodeStateConnectionDSL[] = [];
  let oldNewIDMap = {};

  const newStateListDSL = allOldNodeStates?.reduce((acc: StateListDSL, state) => {
    const stateDSL = stateListDSL[state.stateID] as StateDSL & { defaultValue?: unknown };

    if (!stateDSL) {
      return acc;
    }

    const newID = generateID();
    const oldBoundID = state.componentBoundID;
    const newBoundID = newNodes.find(node => node.oldID === oldBoundID)?.id;

    allNodeIdStates.push({ ...state, stateID: newID, componentBoundID: newBoundID });
    oldNewIDMap = { ...oldNewIDMap, [state.stateID]: newID };
    const newState = {
      ...stateDSL,
      id: newID,
      parent: newPageID,
    };

    return { ...acc, [newState.id]: newState };
  }, {});

  return { newStateListDSL, allNodeIdStates, oldNewIDMap };
};

const copyPageNodeWithChildren = ({
  nodeDSL,
  stateListDSL,
  componentListDSL,
  nodeListDSL,
  newPageID,
}: copyPageNodeWithChildrenParams) => {
  const newChildrenCloneDSL = getAllChildrenNodes({
    parentID: newPageID,
    nodeDSL,
    nodeListDSL,
    componentListDSL,
  });

  const nodeStates = pathOr<NodeStateConnectionDSL[]>([], ['states'], nodeDSL);
  const { newStateListDSL, allNodeIdStates, oldNewIDMap } = copyStatesWithNewIDs(
    nodeStates,
    stateListDSL,
    newChildrenCloneDSL,
    newPageID,
  );

  const routeLayout = newChildrenCloneDSL.find(
    child => child.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
  ) as NodeDSL;

  const updatedRouterHooks = updateOldIdsWithNewIdsFromNodeDSL(
    nodeDSL.props.routerHooks as Record<string, unknown>,
    oldNewIDMap,
  );

  const newPageNodeDSL = {
    ...nodeDSL,
    id: newPageID,
    states: allNodeIdStates,
    context: newStateListDSL,
    props: {
      ...nodeDSL.props,
      routerHooks: updatedRouterHooks,
      children: {
        nodes: [routeLayout.id],
      },
    },
  };

  const newChildrenAsNodeDSL: NodeDSL[] = newChildrenCloneDSL.map(child => {
    const childWithNoOldID = omit(['oldID'], child);
    const childWithUpdatedEventArgs = updateOldIdsWithNewIdsFromNodeDSL(
      childWithNoOldID,
      oldNewIDMap,
    );

    return childWithUpdatedEventArgs as NodeDSL;
  });
  return { newPageNodeDSL, newChildrenNodes: newChildrenAsNodeDSL };
};

export const pageClone = (
  state: DashboardState,
  nodeID: NodeID,
  newPageID: string,
  appDSLToCopy: AppDSL,
  _currentPathName?: string,
): DashboardState => {
  const nodeListDSL = structuredClone(appDSLToCopy.nodes) as NodeListDSL;
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const sourceNode = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID,
  });

  const parentID = sourceNode.parentID as string;

  const routeAliasList = nodeListSelectors
    .getAllDefaultRouteLayoutNodes(nodeListDSL)
    .map((routeNode: { alias: string }) => {
      return routeNode.alias;
    }, []);

  const allLocalStatesList = Object.values(nodeListDSL)
    .filter((node: NodeDSL) => Object.hasOwn(node, 'context'))
    .map((node: NodeDSL) => node.context)
    .flat()
    .reduce((acc, current) => {
      return { ...acc, ...current };
    }, {}) as StateListDSL;

  const { newChildrenNodes, newPageNodeDSL } = copyPageNodeWithChildren({
    nodeDSL: sourceNode,
    stateListDSL: allLocalStatesList,
    componentListDSL,
    nodeListDSL,
    newPageID,
  });

  const newNodeLayoutDSL = newChildrenNodes.filter(
    node => node.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
  )[0];

  const newNodeRouteLayoutAlias = incrementName({
    nameToIncrement: `${newNodeLayoutDSL.alias} Copy` || '',
    dictionary: routeAliasList,
    delimiter: ' ',
  });

  const newChildrenPageNodes = newChildrenNodes.reduce((acc, childrenNode) => {
    if (childrenNode.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout) {
      return {
        ...acc,
        [childrenNode.id]: {
          ...childrenNode,
          alias: newNodeRouteLayoutAlias,
          parentID: newPageNodeDSL.id,
        },
      };
    }

    return { ...acc, [childrenNode.id]: childrenNode };
  }, {});

  const newPath = `/${newNodeRouteLayoutAlias.toLowerCase().replace(/ /g, '-')}`;

  const newStateWithPage = {
    ...state,
    operations: {
      ...state.operations,
      selectedID: null,
      hoveredID: null,
    },
    appConfiguration: {
      ...state.appConfiguration,
      appDSL: {
        ...state.appConfiguration.appDSL,
        nodes: {
          ...state.appConfiguration.appDSL.nodes,
          [parentID]: {
            ...state.appConfiguration.appDSL.nodes[parentID],
            props: {
              ...state.appConfiguration.appDSL.nodes[parentID].props,
              routes: {
                nodes: [
                  ...(state.appConfiguration.appDSL.nodes[parentID].props
                    .routes as ReactNodePropValue)?.nodes,
                  newPageNodeDSL.id,
                ],
              },
            },
          },
          [newPageNodeDSL.id]: {
            ...newPageNodeDSL,
            alias: `${newNodeRouteLayoutAlias} Wrapper`,
            schemaOverride: {
              disableDelete: false,
            },
            props: {
              ...newPageNodeDSL.props,
              path: newPath,
            },
          },
          ...newChildrenPageNodes,
        },
      },
    },
  };

  return newStateWithPage;
};
