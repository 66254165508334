import { EventPayload } from '@8base-private/event-handler';

import { NodeDSL } from '@builder/schemas';

import { ActionScopeStrategy, SCOPE_GLOBAL } from './ActionScopeStrategy';

export class CloneGlobalDialogStrategy implements ActionScopeStrategy {
  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
  ): {
    eventPayload: EventPayload;
    newEventsToPush: EventPayload[];
  } | null {
    return { eventPayload: { ...eventPayload, scope: SCOPE_GLOBAL }, newEventsToPush: [] };
  }
}
