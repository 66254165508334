import _ from 'lodash';

import { ComponentSettingPropDSL } from '@builder/schemas';

import { BUSINESS_LOGIC_SETTINGS_NAME } from '../constants';

export const filterBusinessLogicFromSettingList = <T>(settingList: T): T => {
  const filteredSections: ComponentSettingPropDSL[] = _.filter(
    settingList as ComponentSettingPropDSL[],
    (setting: ComponentSettingPropDSL) =>
      !BUSINESS_LOGIC_SETTINGS_NAME[setting.name as keyof typeof BUSINESS_LOGIC_SETTINGS_NAME],
  );

  const sectionsWithFilteredProps = filteredSections.reduce(
    (acc: ComponentSettingPropDSL[], current) => {
      if ('children' in current) {
        const childrenFiltered = (current.children as ComponentSettingPropDSL[]).filter(
          setting =>
            !BUSINESS_LOGIC_SETTINGS_NAME[
              setting.label as keyof typeof BUSINESS_LOGIC_SETTINGS_NAME
            ],
        );

        const newSetting = { ...current, children: childrenFiltered };

        return [...acc, newSetting];
      }

      return [...acc, current];
    },
    [],
  );

  return sectionsWithFilteredProps as T;
};
