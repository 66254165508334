import { transformSymbolNodes } from '../utils/schema-transformers/transformSymbolNodes';
import { AccordionSymbol } from './composite-components/AccordionSymbol';
import { AlertSymbol } from './composite-components/AlertSymbol';
import { AppBarSymbol } from './composite-components/AppBarSymbol';
import { CardSymbol } from './composite-components/CardSymbol';
import { DialogSymbol } from './composite-components/DialogSymbol';
import { FileDropZoneBaseSymbol } from './composite-components/FileDropZoneBaseSymbol';
import { FileDropZoneSymbol } from './composite-components/FileDropZoneSymbol';
import { GridContainerSymbol } from './composite-components/GridContainerSymbol';
import { GridItemSymbol } from './composite-components/GridItemSymbol';
import { HeadingSymbol } from './composite-components/HeadingSymbol';
import { ListItemIconSymbol } from './composite-components/ListItemIconSymbol';
import { ListItemSymbol } from './composite-components/ListItemSymbol';
import { LocalDialogSymbol } from './composite-components/LocalDialogSymbol';
import { NavListItemSymbol } from './composite-components/NavListItemSymbol';
import { RouterSwitchSymbol } from './composite-components/RouterSwitchSymbol';
import { BuilderComponentsForm } from './form-components/BuilderComponentsForm';
import { BuilderComponentsFormAutocomplete } from './form-components/BuilderComponentsFormAutocomplete';
import { BuilderComponentsFormCheckbox } from './form-components/BuilderComponentsFormCheckbox';
import { BuilderComponentsFormDatePicker } from './form-components/BuilderComponentsFormDatePicker';
import { BuilderComponentsFormDateTimePicker } from './form-components/BuilderComponentsFormDateTimePicker';
import { BuilderComponentsFormFieldArray } from './form-components/BuilderComponentsFormFieldArray';
import { BuilderComponentsFormFileUpload } from './form-components/BuilderComponentsFormFileUpload';
import { BuilderComponentsFormMultiselect } from './form-components/BuilderComponentsFormMultiselect';
import { BuilderComponentsFormRadioGroup } from './form-components/BuilderComponentsFormRadioGroup';
import { BuilderComponentsFormRating } from './form-components/BuilderComponentsFormRating';
import { BuilderComponentsFormRichtextEditor } from './form-components/BuilderComponentsFormRichtextEditor';
import { BuilderComponentsFormSelect } from './form-components/BuilderComponentsFormSelect';
import { BuilderComponentsFormSlider } from './form-components/BuilderComponentsFormSlider';
import { BuilderComponentsFormSwitch } from './form-components/BuilderComponentsFormSwitch';
import { BuilderComponentsFormTextField } from './form-components/BuilderComponentsFormTextField';
import { BuilderComponentsFormTimePicker } from './form-components/BuilderComponentsFormTimePicker';
import { BuilderComponentsRadio } from './form-components/BuilderComponentsRadio';
import { MaterialFormLabel } from './form-components/MaterialFormLabel';
import { a } from './html-elements/a';
import { audio } from './html-elements/audio';
import { blockquote } from './html-elements/blockquote';
import { canvas } from './html-elements/canvas';
import { div } from './html-elements/div';
import { em } from './html-elements/em';
import { form } from './html-elements/form';
import { h1, h2, h3, h4, h5, h6 } from './html-elements/heading';
import { img } from './html-elements/img';
import { li } from './html-elements/li';
import { ol } from './html-elements/ol';
import { p } from './html-elements/p';
import { pre } from './html-elements/pre';
import { root } from './html-elements/root';
import { span } from './html-elements/span';
import { strong } from './html-elements/strong';
import { table } from './html-elements/table';
import { tableBody } from './html-elements/tableBody';
import { tableCaption } from './html-elements/tableCaption';
import { tableColumn } from './html-elements/tableColumn';
import { tableColumnGroup } from './html-elements/tableColumnGroup';
import { tableDataCell } from './html-elements/tableDataCell';
import { tableFoot } from './html-elements/tableFoot';
import { tableHead } from './html-elements/tableHead';
import { tableHeader } from './html-elements/tableHeader';
import { tableRow } from './html-elements/tableRow';
import { ul } from './html-elements/ul';
import { video } from './html-elements/video';
import { AsyncContent } from './layouts/AsyncContent';
import { BaseLayout } from './layouts/BaseLayout';
import { BasePageLayout } from './layouts/BasePageLayout';
import { BuilderComponentsRouteLayout } from './layouts/BuilderComponentsRouteLayout';
import { MobileLayout } from './layouts/MobileLayout';
import { PageLayout } from './layouts/PageLayout';
import { RootLayoutsContainer } from './layouts/RootLayoutsContainer';
import { BuilderComponentsAutocomplete } from './material-components/BuilderComponentsAutocomplete';
import { BuilderComponentsAutocompleteOption } from './material-components/BuilderComponentsAutocompleteOption';
import { BuilderComponentsBox } from './material-components/BuilderComponentsBox';
import { BuilderComponentsButton } from './material-components/BuilderComponentsButton';
import { BuilderComponentsCheckbox } from './material-components/BuilderComponentsCheckbox';
import { BuilderComponentsDataGrid } from './material-components/BuilderComponentsDataGrid';
import { BuilderComponentsDatePicker } from './material-components/BuilderComponentsDatePicker';
import { BuilderComponentsDateTimePicker } from './material-components/BuilderComponentsDateTimePicker';
import { BuilderComponentsFileDropZone } from './material-components/BuilderComponentsFileDropZone';
import { BuilderComponentsFileDropZoneBase } from './material-components/BuilderComponentsFileDropZoneBase';
import { BuilderComponentsFilePicker } from './material-components/BuilderComponentsFilePicker';
import { BuilderComponentsFileUpload } from './material-components/BuilderComponentsFileUpload';
import { BuilderComponentsFileUploadBase } from './material-components/BuilderComponentsFileUploadBase';
import { BuilderComponentsFileUploadButton } from './material-components/BuilderComponentsFileUploadButton';
import { BuilderComponentsFileUploadButtonBase } from './material-components/BuilderComponentsFileUploadButtonBase';
import { BuilderComponentsFloatingActionButton } from './material-components/BuilderComponentsFloatingActionButton';
import { BuilderComponentsFooter } from './material-components/BuilderComponentsFooter';
import { BuilderComponentsHtmlFragment } from './material-components/BuilderComponentsHtmlFragment';
import { BuilderComponentsIcon } from './material-components/BuilderComponentsIcon';
import { BuilderComponentsImage } from './material-components/BuilderComponentsImage';
import { BuilderComponentsMap } from './material-components/BuilderComponentsMap';
import { BuilderComponentsMenu } from './material-components/BuilderComponentsMenu';
import { BuilderComponentsMultiselect } from './material-components/BuilderComponentsMultiselect';
import { BuilderComponentsPopover } from './material-components/BuilderComponentsPopover';
import { BuilderComponentsPopper } from './material-components/BuilderComponentsPopper';
import { BuilderComponentsRadioGroup } from './material-components/BuilderComponentsRadioGroup';
import { BuilderComponentsRating } from './material-components/BuilderComponentsRating';
import { BuilderComponentsRichtextEditor } from './material-components/BuilderComponentsRichtextEditor';
import { BuilderComponentsRouterLink } from './material-components/BuilderComponentsRouterLink';
import { BuilderComponentsSelect } from './material-components/BuilderComponentsSelect';
import { BuilderComponentsSlider } from './material-components/BuilderComponentsSlider';
import { BuilderComponentsSnackbar } from './material-components/BuilderComponentsSnackbar';
import { BuilderComponentsSnackbarContent } from './material-components/BuilderComponentsSnackbarContent';
import { BuilderComponentsSpeedDial } from './material-components/BuilderComponentsSpeedDial';
import { BuilderComponentsSpeedDialAction } from './material-components/BuilderComponentsSpeedDialAction';
import { BuilderComponentsSwitch } from './material-components/BuilderComponentsSwitch';
import { BuilderComponentsTab } from './material-components/BuilderComponentsTab';
import { BuilderComponentsTabs } from './material-components/BuilderComponentsTabs';
import { BuilderComponentsTextField } from './material-components/BuilderComponentsTextField';
import { BuilderComponentsTimePicker } from './material-components/BuilderComponentsTimePicker';
import { BuilderComponentsTooltip } from './material-components/BuilderComponentsTooltip';
import { MaterialAccordion } from './material-components/MaterialAccordion';
import { MaterialAccordionActions } from './material-components/MaterialAccordionActions';
import { MaterialAccordionDetails } from './material-components/MaterialAccordionDetails';
import { MaterialAccordionSummary } from './material-components/MaterialAccordionSummary';
import { MaterialAlert } from './material-components/MaterialAlert';
import { MaterialAlertTitle } from './material-components/MaterialAlertTitle';
import { MaterialAppBar } from './material-components/MaterialAppBar';
import { MaterialAvatar } from './material-components/MaterialAvatar';
import { MaterialBadge } from './material-components/MaterialBadge';
import { MaterialBreadcrumbs } from './material-components/MaterialBreadcrumbs';
import { MaterialButtonGroup } from './material-components/MaterialButtonGroup';
import { MaterialCard } from './material-components/MaterialCard';
import { MaterialCardContent } from './material-components/MaterialCardContent';
import { MaterialCardHeader } from './material-components/MaterialCardHeader';
import { MaterialCardMedia } from './material-components/MaterialCardMedia';
import { MaterialChip } from './material-components/MaterialChip';
import { MaterialDialog } from './material-components/MaterialDialog';
import { MaterialDialogActions } from './material-components/MaterialDialogActions';
import { MaterialDialogContent } from './material-components/MaterialDialogContent';
import { MaterialDialogContentText } from './material-components/MaterialDialogContentText';
import { MaterialDialogTitle } from './material-components/MaterialDialogTitle';
import { MaterialDivider } from './material-components/MaterialDivider';
import { MaterialDrawer } from './material-components/MaterialDrawer';
import { MaterialDrawerContent } from './material-components/MaterialDrawerContent';
import { MaterialGrid } from './material-components/MaterialGrid';
import { MaterialIconButton } from './material-components/MaterialIconButton';
import { MaterialList } from './material-components/MaterialList';
import { MaterialListItem } from './material-components/MaterialListItem';
import { MaterialListItemButton } from './material-components/MaterialListItemButton';
import { MaterialListItemIcon } from './material-components/MaterialListItemIcon';
import { MaterialListItemText } from './material-components/MaterialListItemText';
import { MaterialMenuItem } from './material-components/MaterialMenuItem';
import { MaterialPaper } from './material-components/MaterialPaper';
import { MaterialToggleButton } from './material-components/MaterialToggleButton';
import { MaterialToggleButtonGroup } from './material-components/MaterialToggleButtonGroup';
import { MaterialToolbar } from './material-components/MaterialToolbar';
import { MaterialTypography } from './material-components/MaterialTypography';
import { BuilderComponentsRoute } from './routing-components/BuilderComponentsRoute';
import { BuilderComponentsRouteForLayout } from './routing-components/BuilderComponentsRouteForLayout';
import { Redirect } from './routing-components/Redirect';
import { RouterSwitch } from './routing-components/RouterSwitch';
import { BuilderRichText } from './text-components/BuilderRichText';
import { Text } from './text-components/Text';
import { Fragment } from './utils-components/Fragment';
import { Iterator } from './utils-components/Iterator';
export const COMPONENT_SCHEMAS = transformSymbolNodes({
    componentListDSL: {
        [AccordionSymbol.name]: AccordionSymbol,
        [AlertSymbol.name]: AlertSymbol,
        [AppBarSymbol.name]: AppBarSymbol,
        [AsyncContent.name]: AsyncContent,
        [BaseLayout.name]: BaseLayout,
        [BasePageLayout.name]: BasePageLayout,
        [BuilderComponentsAutocomplete.name]: BuilderComponentsAutocomplete,
        [BuilderComponentsAutocompleteOption.name]: BuilderComponentsAutocompleteOption,
        [BuilderComponentsBox.name]: BuilderComponentsBox,
        [BuilderComponentsButton.name]: BuilderComponentsButton,
        [BuilderComponentsCheckbox.name]: BuilderComponentsCheckbox,
        [BuilderComponentsDataGrid.name]: BuilderComponentsDataGrid,
        [BuilderComponentsDatePicker.name]: BuilderComponentsDatePicker,
        [BuilderComponentsDateTimePicker.name]: BuilderComponentsDateTimePicker,
        [BuilderComponentsFileDropZone.name]: BuilderComponentsFileDropZone,
        [BuilderComponentsFileDropZoneBase.name]: BuilderComponentsFileDropZoneBase,
        [BuilderComponentsFilePicker.name]: BuilderComponentsFilePicker,
        [BuilderComponentsFileUpload.name]: BuilderComponentsFileUpload,
        [BuilderComponentsFileUploadBase.name]: BuilderComponentsFileUploadBase,
        [BuilderComponentsFileUploadButton.name]: BuilderComponentsFileUploadButton,
        [BuilderComponentsFileUploadButtonBase.name]: BuilderComponentsFileUploadButtonBase,
        [BuilderComponentsFloatingActionButton.name]: BuilderComponentsFloatingActionButton,
        [BuilderComponentsFooter.name]: BuilderComponentsFooter,
        [BuilderComponentsForm.name]: BuilderComponentsForm,
        [BuilderComponentsFormAutocomplete.name]: BuilderComponentsFormAutocomplete,
        [BuilderComponentsFormCheckbox.name]: BuilderComponentsFormCheckbox,
        [BuilderComponentsFormDatePicker.name]: BuilderComponentsFormDatePicker,
        [BuilderComponentsFormDateTimePicker.name]: BuilderComponentsFormDateTimePicker,
        [BuilderComponentsFormFieldArray.name]: BuilderComponentsFormFieldArray,
        [BuilderComponentsFormFileUpload.name]: BuilderComponentsFormFileUpload,
        [BuilderComponentsFormMultiselect.name]: BuilderComponentsFormMultiselect,
        [BuilderComponentsFormRadioGroup.name]: BuilderComponentsFormRadioGroup,
        [BuilderComponentsFormRating.name]: BuilderComponentsFormRating,
        [BuilderComponentsFormRichtextEditor.name]: BuilderComponentsFormRichtextEditor,
        [BuilderComponentsFormSelect.name]: BuilderComponentsFormSelect,
        [BuilderComponentsFormSlider.name]: BuilderComponentsFormSlider,
        [BuilderComponentsFormSwitch.name]: BuilderComponentsFormSwitch,
        [BuilderComponentsFormTextField.name]: BuilderComponentsFormTextField,
        [BuilderComponentsFormTimePicker.name]: BuilderComponentsFormTimePicker,
        [BuilderComponentsHtmlFragment.name]: BuilderComponentsHtmlFragment,
        [BuilderComponentsIcon.name]: BuilderComponentsIcon,
        [BuilderComponentsImage.name]: BuilderComponentsImage,
        [BuilderComponentsMap.name]: BuilderComponentsMap,
        [BuilderComponentsMenu.name]: BuilderComponentsMenu,
        [BuilderComponentsMultiselect.name]: BuilderComponentsMultiselect,
        [BuilderComponentsPopover.name]: BuilderComponentsPopover,
        [BuilderComponentsPopper.name]: BuilderComponentsPopper,
        [BuilderComponentsRadio.name]: BuilderComponentsRadio,
        [BuilderComponentsRadioGroup.name]: BuilderComponentsRadioGroup,
        [BuilderComponentsRating.name]: BuilderComponentsRating,
        [BuilderComponentsRichtextEditor.name]: BuilderComponentsRichtextEditor,
        [BuilderComponentsRoute.name]: BuilderComponentsRoute,
        [BuilderComponentsRouteForLayout.name]: BuilderComponentsRouteForLayout,
        [BuilderComponentsRouteLayout.name]: BuilderComponentsRouteLayout,
        [BuilderComponentsRouterLink.name]: BuilderComponentsRouterLink,
        [BuilderComponentsSelect.name]: BuilderComponentsSelect,
        [BuilderComponentsSlider.name]: BuilderComponentsSlider,
        [BuilderComponentsSnackbar.name]: BuilderComponentsSnackbar,
        [BuilderComponentsSnackbarContent.name]: BuilderComponentsSnackbarContent,
        [BuilderComponentsSpeedDial.name]: BuilderComponentsSpeedDial,
        [BuilderComponentsSpeedDialAction.name]: BuilderComponentsSpeedDialAction,
        [BuilderComponentsSwitch.name]: BuilderComponentsSwitch,
        [BuilderComponentsTab.name]: BuilderComponentsTab,
        [BuilderComponentsTabs.name]: BuilderComponentsTabs,
        [BuilderComponentsTextField.name]: BuilderComponentsTextField,
        [BuilderComponentsTimePicker.name]: BuilderComponentsTimePicker,
        [BuilderComponentsTooltip.name]: BuilderComponentsTooltip,
        [CardSymbol.name]: CardSymbol,
        [DialogSymbol.name]: DialogSymbol,
        [FileDropZoneBaseSymbol.name]: FileDropZoneBaseSymbol,
        [FileDropZoneSymbol.name]: FileDropZoneSymbol,
        [GridContainerSymbol.name]: GridContainerSymbol,
        [GridItemSymbol.name]: GridItemSymbol,
        [HeadingSymbol.name]: HeadingSymbol,
        [ListItemIconSymbol.name]: ListItemIconSymbol,
        [ListItemSymbol.name]: ListItemSymbol,
        [LocalDialogSymbol.name]: LocalDialogSymbol,
        [MaterialAccordion.name]: MaterialAccordion,
        [MaterialAccordionActions.name]: MaterialAccordionActions,
        [MaterialAccordionDetails.name]: MaterialAccordionDetails,
        [MaterialAccordionSummary.name]: MaterialAccordionSummary,
        [MaterialAlert.name]: MaterialAlert,
        [MaterialAlertTitle.name]: MaterialAlertTitle,
        [MaterialAppBar.name]: MaterialAppBar,
        [MaterialAvatar.name]: MaterialAvatar,
        [MaterialBadge.name]: MaterialBadge,
        [MaterialBreadcrumbs.name]: MaterialBreadcrumbs,
        [MaterialButtonGroup.name]: MaterialButtonGroup,
        [MaterialCard.name]: MaterialCard,
        [MaterialCardContent.name]: MaterialCardContent,
        [MaterialCardHeader.name]: MaterialCardHeader,
        [MaterialCardMedia.name]: MaterialCardMedia,
        [MaterialChip.name]: MaterialChip,
        [MaterialDialog.name]: MaterialDialog,
        [MaterialDialogActions.name]: MaterialDialogActions,
        [MaterialDialogContent.name]: MaterialDialogContent,
        [MaterialDialogContentText.name]: MaterialDialogContentText,
        [MaterialDialogTitle.name]: MaterialDialogTitle,
        [MaterialDivider.name]: MaterialDivider,
        [MaterialDrawer.name]: MaterialDrawer,
        [MaterialDrawerContent.name]: MaterialDrawerContent,
        [MaterialFormLabel.name]: MaterialFormLabel,
        [MaterialGrid.name]: MaterialGrid,
        [MaterialIconButton.name]: MaterialIconButton,
        [MaterialList.name]: MaterialList,
        [MaterialListItem.name]: MaterialListItem,
        [MaterialListItemButton.name]: MaterialListItemButton,
        [MaterialListItemIcon.name]: MaterialListItemIcon,
        [MaterialListItemText.name]: MaterialListItemText,
        [MaterialMenuItem.name]: MaterialMenuItem,
        [MaterialPaper.name]: MaterialPaper,
        [MaterialToggleButton.name]: MaterialToggleButton,
        [MaterialToggleButtonGroup.name]: MaterialToggleButtonGroup,
        [MaterialToolbar.name]: MaterialToolbar,
        [MaterialTypography.name]: MaterialTypography,
        [MobileLayout.name]: MobileLayout,
        [NavListItemSymbol.name]: NavListItemSymbol,
        [PageLayout.name]: PageLayout,
        [Redirect.name]: Redirect,
        [RootLayoutsContainer.name]: RootLayoutsContainer,
        [RouterSwitch.name]: RouterSwitch,
        [RouterSwitchSymbol.name]: RouterSwitchSymbol,
        [Fragment.name]: Fragment,
        [Iterator.name]: Iterator,
        [Text.name]: Text,
        [BuilderRichText.name]: BuilderRichText,
        [a.name]: a,
        [audio.name]: audio,
        [blockquote.name]: blockquote,
        [blockquote.name]: blockquote,
        [canvas.name]: canvas,
        [div.name]: div,
        [em.name]: em,
        [form.name]: form,
        [h1.name]: h1,
        [h2.name]: h2,
        [h3.name]: h3,
        [h4.name]: h4,
        [h5.name]: h5,
        [h6.name]: h6,
        [img.name]: img,
        [li.name]: li,
        [ol.name]: ol,
        [p.name]: p,
        [pre.name]: pre,
        [root.name]: root,
        [span.name]: span,
        [strong.name]: strong,
        [table.name]: table,
        [tableBody.name]: tableBody,
        [tableCaption.name]: tableCaption,
        [tableColumn.name]: tableColumn,
        [tableColumnGroup.name]: tableColumnGroup,
        [tableDataCell.name]: tableDataCell,
        [tableFoot.name]: tableFoot,
        [tableHead.name]: tableHead,
        [tableHeader.name]: tableHeader,
        [tableRow.name]: tableRow,
        [ul.name]: ul,
        [video.name]: video,
    },
});
export { SIZE_POSSIBLE_UNITS, ACTIONS_ARGS_SCHEMA, PREDEFINED_ACTIONS } from './common';
