import React, { lazy, Suspense, useCallback } from 'react';

import styled from '@emotion/styled';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Typography } from '@mui/material';
import { Switch, Route, Redirect } from 'react-router-dom';

import { processEnv, buildUrl, memo } from '@builder/utils';

import { CenterLayout, Spinner } from 'src/shared/components';
import { APP_URL } from 'src/shared/constants';
import { useAuthCheckSession, useInitialLoad } from 'src/shared/hooks';

import { PreviewPage } from './preview';

const Auth = lazy(() => import(/* webpackChunkName: "auth" */ './auth'));
const DashboardPage = lazy(() => import(/* webpackChunkName: "dashboard" */ './dashboard'));

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 600px;
`;

const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)`
  width: 40px !important;
  height: 40px !important;
  font-size: 40px !important;
  line-height: 40px !important;
`;

const PublicRoutes: React.FC = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route path={APP_URL.public.auth.root} component={Auth} />
      <Redirect to={APP_URL.public.auth.root} />
    </Switch>
  </Suspense>
);

const PrivateRoutes: React.FC = memo('PrivateRoutes', () => {
  const { isLoading, error, shouldRedirectToDashboard, redirectWorkspaceID } = useInitialLoad();
  const redirectToConsoleURL = useCallback(
    () => window.location.replace(processEnv.getConsoleURL()),
    [],
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <CenterLayout>
        <ErrorWrapper>
          <StyledCancelOutlinedIcon color="error" />
          <Typography variant="h4">Something went wrong</Typography>
        </ErrorWrapper>
      </CenterLayout>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path={APP_URL.workspace.dashboard} component={DashboardPage} />
        <Route path={APP_URL.workspace.preview} component={PreviewPage} />
        <Choose>
          <When condition={shouldRedirectToDashboard}>
            <Redirect
              to={buildUrl(APP_URL.workspace.dashboard, {
                pathParams: { workspaceID: redirectWorkspaceID },
              })}
            />
          </When>
          <When condition={!shouldRedirectToDashboard}>
            <Redirect
              to={buildUrl(APP_URL.workspace.preview, {
                pathParams: { workspaceID: redirectWorkspaceID },
              })}
            />
          </When>
          <Otherwise>{redirectToConsoleURL()}</Otherwise>
        </Choose>
      </Switch>
    </Suspense>
  );
});

export const RootRoutes: React.FC = memo('RootRoutes', () => {
  const { loading: checkingAuthSession, isAuthorized } = useAuthCheckSession();

  if (checkingAuthSession) {
    return <Spinner />;
  }

  return isAuthorized ? <PrivateRoutes /> : <PublicRoutes />;
});
