import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';

import { CopyBuffer } from '../common';

export const getCopyBuffer = (copyBuffer: CopyBuffer): CopyBuffer => copyBuffer;

export const isCopyBufferEmpty = createSelector(getCopyBuffer, copyBuffer => {
  return isEmpty(copyBuffer);
});
