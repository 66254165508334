import { gql } from '@apollo/client';

export const UPLOADCARE_SIGN_INFO_QUERY = gql`
  query UploadcareSignInfo {
    system {
      fileUploadSignInfo {
        ... on UploadcareSignInfoResponse {
          expire
          publicKey
          signature
        }
      }
    }
  }
`;
