var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { ToggleButton as MuiToggleButton, } from '@mui/material';
export const ToggleButton = forwardRef((_a, ref) => {
    var { loading, children, onClick, classes, startIcon, endIcon } = _a, rest = __rest(_a, ["loading", "children", "onClick", "classes", "startIcon", "endIcon"]);
    return (_jsxs(MuiToggleButton, Object.assign({}, rest, { classes: classes, ref: ref }, { children: [' ', startIcon && startIcon, _jsx("div", Object.assign({ style: { width: '100%' } }, { children: children })), " ", endIcon && endIcon] })));
});
