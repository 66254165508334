import React, { useCallback, useMemo } from 'react';

import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { bindPopover, PopupState } from 'material-ui-popup-state/hooks';
import { pick } from 'ramda';

import {
  AssetFileWithBackendDataDSL,
  AssetImageDSL,
  ASSET_TYPES,
  AssetFileDSL,
} from '@builder/schemas';
import { composeValidators, memo, mustBeCorrectVariableName, required } from '@builder/utils';

import { AssetMedia } from '../AssetMedia';
import { AssetMeta } from '../AssetMeta';
import { UploadcareFilePicker } from '../UploadcareFilePicker';
import { useAppDSL } from 'src/providers';
import { useTheme } from 'src/providers/ThemeProvider';
import {
  Popper,
  CssGrid,
  Button,
  Form,
  FormRenderProps,
  Icon,
  FormTextField,
} from 'src/shared/components';
import { useAssetsHooks } from 'src/shared/graphql/hooks';
import { CreateAssetData } from 'src/shared/graphql/hooks/useAssetsHooks';
import { assetNameUniqueValidator } from 'src/shared/utils';

import {
  ALT_FIELD_PROPS,
  ASSET_PROPERTIES_POPPER_MODIFIERS,
  DESCRIPTION_FIELD_PROPS,
  NAME_FIELD_PROPS,
} from './AssetPropertiesPopper.constants';
import {
  AssetMediaContainer,
  PopupContent,
  PopupHeader,
  PopupTitle,
} from './AssetPropertiesPopper.styles';

type AssetPropertiesFormValues = {
  name: string;
  alt: string | undefined;
  description: string | undefined;
};

type AssetPropertiesPopperProps = {
  asset: AssetFileWithBackendDataDSL;
  popupState: PopupState;
};

export const AssetPropertiesPopper: React.FC<AssetPropertiesPopperProps> = memo(
  'AssetPropertiesPopper',
  ({ asset, popupState }) => {
    const theme = useTheme();
    const bindPopoverState = bindPopover(popupState);
    const appDSL = useAppDSL();
    const {
      assetFileNames,
      updateAsset,
      deleteAssetWithConfirmation,
      replaceFileAsset,
      createAssetOptions,
    } = useAssetsHooks();

    const initialValues = useMemo(
      () => ({
        name: asset.name,
        alt: (asset as AssetImageDSL)?.alt,
        description: asset?.description,
      }),
      [asset],
    );

    const onAssetUpdate = useCallback(
      (values: AssetPropertiesFormValues) => {
        const imageFields =
          asset.type === ASSET_TYPES.image
            ? {
                height: asset.height,
                width: asset.width,
              }
            : {};

        const dateNow = DateTime.now().toUTC().toISO();

        const previousAssetData = pick(
          ['id', 'type', 'parentID', 'backendFileID', 'size', 'createdAt', 'createdBy'],
          asset,
        );

        const assetData = {
          ...previousAssetData,
          updatedAt: dateNow,
          ...imageFields,
          ...values,
        } as AssetFileDSL;

        updateAsset(assetData);
      },
      [asset, updateAsset],
    );

    const onAssetDelete = useCallback(() => {
      deleteAssetWithConfirmation({ assetIDs: [asset.id], afterDeleteCallback: popupState.close });
    }, [asset.id, deleteAssetWithConfirmation, popupState]);

    const onAssetReplace = useCallback(
      async (assetCreateInput: CreateAssetData) => {
        await replaceFileAsset(asset, assetCreateInput);
      },
      [asset, replaceFileAsset],
    );

    return (
      <Popper
        data-test="assets.assetPropertiesPopper"
        modifiers={ASSET_PROPERTIES_POPPER_MODIFIERS}
        placement="right-start"
        {...bindPopoverState}
      >
        <Form enableReinitialize onSubmit={onAssetUpdate} initialValues={initialValues}>
          {({ handleSubmit, dirty, resetForm }: FormRenderProps<AssetPropertiesFormValues>) => (
            <form onSubmit={handleSubmit}>
              <PopupContent>
                <CssGrid gap={2}>
                  <PopupHeader>
                    <PopupTitle variant="body1">Asset Properties</PopupTitle>
                    <Icon
                      data-test="assets.assetPropertiesPopper.closeButton"
                      element="button"
                      width="16"
                      height="16"
                      name="cross"
                      fill={theme.palette.grey[500]}
                      hoverFill={theme.palette.common.white}
                      onClick={popupState?.close}
                    />
                  </PopupHeader>
                  <CssGrid
                    css={css`
                      margin-top: 8px;
                    `}
                    gridTemplateColumns="55px 1fr"
                    gridColumnGap={1.5}
                  >
                    <AssetMediaContainer>
                      <AssetMedia
                        assetType={asset.type}
                        downloadUrl={asset.previewUrl}
                        height="55px"
                      />
                    </AssetMediaContainer>
                    <AssetMeta asset={asset} />
                  </CssGrid>
                  <CssGrid gridTemplateColumns="1fr 1fr" gap={1}>
                    <UploadcareFilePicker
                      assetFileNames={assetFileNames}
                      onFileUpload={onAssetReplace}
                      mutationLoading={createAssetOptions.loading}
                      AddButtonComponent={props => (
                        <Button variant="outlined" color="primary" fullWidth {...props}>
                          Replace
                        </Button>
                      )}
                    />
                    <Button
                      onClick={onAssetDelete}
                      variant="outlined"
                      color="error"
                      fullWidth
                      data-test="assets.assetPropertiesPopper.deleteButton"
                    >
                      Delete
                    </Button>
                  </CssGrid>
                  <FormTextField
                    data-test="assets.assetPropertiesPopper.form.name"
                    fieldProps={{
                      ...NAME_FIELD_PROPS.fieldProps,
                      validate: composeValidators(
                        required,
                        mustBeCorrectVariableName,
                        assetNameUniqueValidator(appDSL.assets, initialValues?.name),
                      ),
                    }}
                    inputProps={NAME_FIELD_PROPS.inputProps}
                  />
                  <If condition={asset.type === ASSET_TYPES.image}>
                    <FormTextField
                      data-test="assets.assetPropertiesPopper.form.alt"
                      fieldProps={ALT_FIELD_PROPS.fieldProps}
                      inputProps={ALT_FIELD_PROPS.inputProps}
                    />
                  </If>
                  <FormTextField
                    data-test="assets.assetPropertiesPopper.form.description"
                    fieldProps={DESCRIPTION_FIELD_PROPS.fieldProps}
                    inputProps={DESCRIPTION_FIELD_PROPS.inputProps}
                  />
                  <Grid container gap={1}>
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={!dirty}
                      data-test="assets.assetPropertiesPopper.saveButton"
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => resetForm()}
                      disabled={!dirty}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </CssGrid>
              </PopupContent>
            </form>
          )}
        </Form>
      </Popper>
    );
  },
);
