import styled from '@emotion/styled';
import { DialogActions, DialogContent } from '@mui/material';

export const VersionText = styled.span`
  font-weight: 500;
  font-size: 1.1em;
`;

export const DialogContentWithoutPaddings = styled(DialogContent)`
  padding: 0;
`;

export const DialogContentWithIcon = styled(DialogContent)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const DialogContentCenter = styled(DialogContent)`
  text-align: center;
`;

export const DialogActionsCenter = styled(DialogActions)`
  justify-content: center;
`;
