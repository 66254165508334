import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonActionProps, commonBasicProps, commonComponentProps, commonStyleProps, commonSystemProps, getCommonSymbolBasicProps, getCommonSymbolEventProps, getCommonSymbolStyleProps, } from '../../common/commonProps';
import { AppBarSymbolSettings } from './AppBarSymbol.settings';
export const AppBarSymbol = {
    name: COMPONENT_DSL_NAMES.AppBarSymbol,
    displayName: 'Header',
    icon: 'header',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.tableHeader,
                },
            },
        ],
        predefineds: {
            props: {
                appBarProps: {
                    color: 'default',
                    position: 'relative',
                    style: getPredefinedStyles(),
                },
                toolbarProps: {
                    variant: 'regular',
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { appBarProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonActionProps), commonStyleProps), commonSystemProps), commonBasicProps), { color: { type: COMPONENT_DSL_PROP_TYPES.enum }, position: { type: COMPONENT_DSL_PROP_TYPES.enum }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
            }, toolbarProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign(Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonSystemProps), commonBasicProps), { disableGutters: { type: COMPONENT_DSL_PROP_TYPES.boolean }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
            } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialAppBar,
            props: Object.assign(Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps(['appBarProps'])), getCommonSymbolEventProps(['appBarProps'])), getCommonSymbolBasicProps(['appBarProps'])), { color: '{{ symbolProps.appBarProps?.color }}', position: '{{ symbolProps.appBarProps?.position }}', draggable: '{{ symbolProps.draggable }}', children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialToolbar,
                            props: Object.assign(Object.assign({}, getCommonSymbolStyleProps(['toolbarProps'])), { children: '{{ symbolProps.children }}', disableGutters: '{{ symbolProps.toolbarProps?.disableGutters }}', variant: '{{ symbolProps.toolbarProps?.variant }}' }),
                        },
                    ],
                } }),
        },
    },
    settings: AppBarSymbolSettings,
};
