/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import * as FullStory from '@fullstory/browser';
import Cookies from 'js-cookie';

import { isProductionStand, isStagingStand, log } from '@builder/utils';

import { useCurrentTeamMember } from 'src/shared/graphql/hooks';
import { getUtmParams } from 'src/shared/utils/getUTMParamsFromUrl';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pendo: any;
    pendoUserMetadata: {
      visitor: {
        id: string | undefined;
        name: string;
        email: string;
        created_at: string;
      };
      account: {
        id: string | undefined;
      };
    };
  }
}

const VISITOR_ID_NAME = '8base-visitor-id';

export const AnalyticsProvider: React.FC = ({ children }) => {
  useEffect(() => {
    const visitorId = Cookies.get(VISITOR_ID_NAME);

    if (visitorId) {
      FullStory.identify(visitorId);
    }
  }, []);

  const { teamMember } = useCurrentTeamMember();

  useEffect(() => {
    const visitorId = Cookies.get(VISITOR_ID_NAME);

    if (visitorId && teamMember) {
      if (isStagingStand() || isProductionStand()) {
        const userMetadata = {
          visitor: {
            id: visitorId,
            name: `${teamMember.firstName} ${teamMember.lastName}`,
            email: teamMember.email,
            created_at: teamMember.registeredAt,
            ...getUtmParams(window.location.toString()),
          },
          account: {
            id: visitorId,
          },
        };
        window.pendo.initialize(userMetadata);
        window.pendoUserMetadata = userMetadata;
      } else if (isProductionStand()) {
        log.warn(`${VISITOR_ID_NAME} not found`);
      }
    }
  }, [teamMember]);

  return <>{children}</>;
};
