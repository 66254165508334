import { PopoverOrigin } from '@mui/material';

import { ValueOf } from 'src/shared/components';

export const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const LABEL_PLACEMENT = {
  top: 'top',
  bottom: 'bottom',
} as const;

export type LabelPlacementTypes = ValueOf<typeof LABEL_PLACEMENT>;

export const LABEL_SIZE = {
  small: 'small',
  medium: 'medium',
} as const;

export type LabelSizeTypes = ValueOf<typeof LABEL_SIZE>;
