import { Migration } from '../Migration';
class Migration_0_16_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.1';
    }
    getChangeList() {
        return ['add ownership to local states'];
    }
    searchParentRoute(id, nodes) {
        if (nodes[id].parentID) {
            const { parentID } = nodes[id];
            if (parentID) {
                if (nodes[parentID].props.path !== undefined) {
                    return parentID;
                }
                return this.searchParentRoute(parentID, nodes);
            }
        }
        return '';
    }
    migrateAppDSL(appDSL) {
        let nodesValues = Object.entries(appDSL.nodes);
        const newDSL = Object.assign({}, appDSL);
        const HOME_PAGE_ID = 'EmptyPageRootRouteLayout_ID';
        const statesEntries = Object.entries(newDSL.states).map(([key, state]) => {
            var _a;
            const newState = Object.assign({}, state);
            if (newState.parent === undefined) {
                let isConnected;
                let parentID;
                let countConnections = 0;
                for (let i = 0; i < nodesValues.length; i++) {
                    const node = nodesValues[i][1];
                    isConnected = (_a = node.states) === null || _a === void 0 ? void 0 : _a.find(nodeState => nodeState.stateID === newState.id);
                    if (isConnected) {
                        countConnections += 1;
                        const parentRouteID = this.searchParentRoute(node.id, newDSL.nodes);
                        if (newDSL.nodes[parentRouteID]) {
                            [parentID] = newDSL.nodes[parentRouteID].props
                                .children.nodes;
                        }
                    }
                }
                if (countConnections > 1) {
                    newState.scope = 'global';
                    nodesValues = nodesValues.map(([keyNode, valueNode]) => {
                        var _a, _b;
                        const newValueNode = Object.assign({}, valueNode);
                        if ((_a = newValueNode.states) === null || _a === void 0 ? void 0 : _a.find(nodeState => nodeState.stateID === newState.id)) {
                            newValueNode.states = (_b = newValueNode.states) === null || _b === void 0 ? void 0 : _b.filter(nodeState => nodeState.stateID !== newState.id);
                        }
                        return [keyNode, newValueNode];
                    });
                }
                newState.parent = parentID || HOME_PAGE_ID;
            }
            return [key, newState];
        });
        return Object.assign(Object.assign({}, newDSL), { nodes: Object.fromEntries(nodesValues), states: Object.fromEntries(statesEntries) });
    }
}
export const migration_0_16_1 = new Migration_0_16_1();
