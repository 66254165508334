import React from 'react';

import styled from '@emotion/styled';

import { ComponentSettingSpacingDSL } from '@builder/schemas';

type SectionTypeProps = {
  spacingSetting: ComponentSettingSpacingDSL;
  children: React.ReactNode;
  'data-test'?: string;
};

export const SpacingType = styled.div<SectionTypeProps>`
  display: grid;
  grid-gap: ${props => props.theme.spacing(props.spacingSetting.spacing)};
`;
