import styled from '@emotion/styled';
import { Divider, Paper } from '@mui/material';

import { Button } from 'src/shared/components';

export const MenuContent = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing(1.5)} 0`};
  min-width: 170px;
`;

export const MenuButton = styled(Button)`
  justify-content: flex-start;
  font-weight: normal;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`;

export const MenuDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.spacing(0.5)} 0`};
`;
