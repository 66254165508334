import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { keys, values } from 'ramda';
import { matchPath } from 'react-router-dom';
import { RouteHookContext } from '@builder/components';
import { nodeListSelectors, } from '@builder/schemas';
import { PathContext } from '../../app-state/path-context/PathContext';
import { useRequestRunner } from '../../app-state/predefined-hooks/useRequestRunner';
import { createAndReturnFunction } from '../../utils';
import { UI_BUILDER_MODES, useDashboardHook } from '../DashboardProvider';
const DEFAULT_HOOK_CODE = 'return function(){}';
function selectProperties(obj) {
    if (obj) {
        const { title, meta, name, path } = obj;
        return { title, meta, name, path };
    }
}
/**
 * Use only for dashboard RouteHooks
 */
export const RouteHookProvider = ({ globalHooks, nodeListDSL = {}, stateList = {}, children, currentRoute: nextPath, appRuntimeState, libraries, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { mode } = useDashboardHook();
    const { previousPath, currentPath, isMounted, setMounted } = useContext(PathContext);
    const isPreviewMode = mode === UI_BUILDER_MODES.preview;
    const beforeEach = isPreviewMode ? globalHooks === null || globalHooks === void 0 ? void 0 : globalHooks.beforeEach : undefined;
    const afterEach = isPreviewMode ? globalHooks === null || globalHooks === void 0 ? void 0 : globalHooks.afterEach : undefined;
    const routesArray = useMemo(() => nodeListSelectors.getAllRouteNodes(nodeListDSL), [nodeListDSL]);
    const requestRunner = useRequestRunner(stateList);
    const EMPTY_STRING = '';
    const currentRoute = useMemo(() => routesArray.find((routeNode) => {
        const currentRoutePath = routeNode.props.path;
        if (currentRoutePath !== '/')
            return matchPath(currentPath, {
                path: currentRoutePath,
                exact: true,
            });
        return currentPath === currentRoutePath;
    }), [currentPath, routesArray]);
    const { router } = appRuntimeState === null || appRuntimeState === void 0 ? void 0 : appRuntimeState.predefinedState;
    const currentRouteStateItem = ((router === null || router === void 0 ? void 0 : router.list) || []).find(item => matchPath(currentPath, { path: item.path, exact: true }));
    const prevRouteStateItem = ((router === null || router === void 0 ? void 0 : router.list) || []).find(item => matchPath(previousPath || '', { path: item.path, exact: true }));
    const isAppBuilderPreview = window.top !== window;
    const currentRouteContextNames = values((currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.context) || {}).map(state => state.name);
    const isContextAndLocalstatesSynchronized = JSON.stringify(keys(appRuntimeState.localStates)) === JSON.stringify(currentRouteContextNames);
    return (_jsx(RouteHookContext.Provider, Object.assign({ value: {
            setMounted,
            isMounted,
            state: Object.assign(Object.assign(Object.assign({}, appRuntimeState.predefinedState), appRuntimeState.globalState), appRuntimeState.localStates),
            nextPath,
            currentPath: currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.path,
            currentRoute,
            location: JSON.stringify(selectProperties(currentRouteStateItem)),
            prevLocation: JSON.stringify(selectProperties(prevRouteStateItem)),
            globalHooks: {
                beforeEach,
                afterEach,
            },
            localHooks: {
                beforeRouteEnter: isPreviewMode
                    ? {
                        type: (_a = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _a === void 0 ? void 0 : _a.beforeRouteEnter,
                        code: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_b = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _b === void 0 ? void 0 : _b.beforeRouteEnterCode
                            : EMPTY_STRING,
                        request: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_c = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _c === void 0 ? void 0 : _c.beforeRouteEnterRequestID
                            : EMPTY_STRING,
                        function: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_d = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _d === void 0 ? void 0 : _d.beforeRouteEnterFunctionID
                            : EMPTY_STRING,
                    }
                    : undefined,
                beforeRouteUpdate: isPreviewMode
                    ? {
                        type: (_e = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _e === void 0 ? void 0 : _e.beforeRouteUpdate,
                        code: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? ((_f = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _f === void 0 ? void 0 : _f.beforeRouteUpdateCode) || DEFAULT_HOOK_CODE
                            : DEFAULT_HOOK_CODE,
                        request: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_g = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _g === void 0 ? void 0 : _g.beforeRouteUpdateRequestID
                            : DEFAULT_HOOK_CODE,
                        function: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_h = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _h === void 0 ? void 0 : _h.beforeRouteUpdateFunctionID
                            : DEFAULT_HOOK_CODE,
                    }
                    : undefined,
                beforeRouteExit: isPreviewMode
                    ? {
                        type: (_j = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _j === void 0 ? void 0 : _j.beforeRouteExit,
                        code: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? ((_k = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _k === void 0 ? void 0 : _k.beforeRouteExitCode) || DEFAULT_HOOK_CODE
                            : DEFAULT_HOOK_CODE,
                        request: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_l = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _l === void 0 ? void 0 : _l.beforeRouteExitRequestID
                            : EMPTY_STRING,
                        function: isContextAndLocalstatesSynchronized || !isAppBuilderPreview
                            ? (_m = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props.routerHooks) === null || _m === void 0 ? void 0 : _m.beforeRouteExitFunctionID
                            : EMPTY_STRING,
                    }
                    : undefined,
            },
            runRequest: (requestID) => {
                requestRunner.run(requestID);
            },
            createFunction: (code, location) => {
                return createAndReturnFunction(code, appRuntimeState, mode, { libraries, isNewFunction: false }, {
                    to: location.to,
                    from: location.from,
                    state: Object.assign(Object.assign(Object.assign({}, appRuntimeState.predefinedState), appRuntimeState.globalState), appRuntimeState.localStates),
                });
            },
            stateList,
        } }, { children: children })));
};
