import {
  ComponentDSL,
  NodeDSL,
  ComponentSettingDSL,
  ComponentSettingCommonPropDSL,
  ComponentSettingSelectPropDSL,
  COMPONENT_SETTING_VIEWS,
  COMPONENT_SETTING_TYPES,
  StateListDSL,
  StylePropValue,
  stateListSelectors,
  ComponentSettingWithChildrenDSL,
  ObjectPropValue,
} from '@builder/schemas';

import { validateIsFormComponent } from './validateIsFormComponent';

export function filterValidStyles(
  componentDSL: ComponentDSL,
  nodeDSL: NodeDSL,
  localStates: StateListDSL,
  globalStates: StateListDSL,
  style: { [key: string]: string },
): {
  validStyles: { [key: string]: never };
  notCopiedReference: boolean;
} {
  const validStyles = {};
  let notCopiedReference = false;
  const componentSettingsStyles = componentDSL.settings?.style;
  if (componentSettingsStyles != null && Array.isArray(componentSettingsStyles)) {
    const setting = componentSettingsStyles.find(
      item => item.type === 'rootSection',
    ) as ComponentSettingWithChildrenDSL;
    if (setting != null) {
      const children = setting.children as ComponentSettingDSL[];
      const listOfStyles = children
        .filter(
          item =>
            item.type === COMPONENT_SETTING_TYPES.section ||
            item.type === COMPONENT_SETTING_TYPES.prop,
        )
        .reduce((acc, item) => {
          if (item.type === COMPONENT_SETTING_TYPES.section) {
            const typeProps = item.children?.filter(
              itemChildren => itemChildren.type === COMPONENT_SETTING_TYPES.prop,
            ) as ComponentSettingCommonPropDSL[];
            return [...acc, ...typeProps];
          }

          if (item.type === COMPONENT_SETTING_TYPES.prop) {
            return [...acc, item];
          }

          return acc;
        }, [] as ComponentSettingCommonPropDSL[]);
      Object.entries(style).forEach(([key, value]) => {
        const foundedValue = listOfStyles.find(styleSetting => {
          return styleSetting.keyPath.includes(key);
        });
        const castValue = foundedValue as ComponentSettingSelectPropDSL;
        if (key === 'style') {
          const newStyleObject = Object.entries(value).reduce(
            (acc, [styleKey, styleValue]: [string, string | number]) => {
              const isStateValue =
                typeof styleValue === 'string' && /{{(.*?)+\.(.*?)}}/.test(styleValue);
              if (isStateValue) {
                const stateValue = styleValue
                  .replace(/[{}']+/g, '')
                  .split('.')[0]
                  .trim();
                const isLocalState = stateListSelectors.isStateExist(localStates, {
                  name: stateValue,
                });
                const isGlobalState = stateListSelectors.isStateExist(globalStates, {
                  name: stateValue,
                });
                if (isLocalState || isGlobalState) {
                  return { ...acc, ...{ [styleKey]: styleValue } };
                }

                notCopiedReference = true;
                return { ...acc, ...{ [styleKey]: '' } };
              }

              return { ...acc, ...{ [styleKey]: styleValue } };
            },
            {} as never,
          );
          const { isFormComponent, key: keyFormComponent } = validateIsFormComponent(
            Object.keys(nodeDSL.props),
          );
          if (isFormComponent && keyFormComponent != null) {
            const castFormProps = nodeDSL.props[keyFormComponent] as ObjectPropValue;
            const castStyle = castFormProps.style as StylePropValue;
            if (castStyle != null && typeof castStyle === 'object') {
              Object.assign(validStyles, {
                [key]: { ...castStyle, ...newStyleObject },
              });
            } else {
              Object.assign(validStyles, {
                [key]: newStyleObject,
              });
            }
          } else if (nodeDSL.props.style != null && typeof nodeDSL.props.style === 'object') {
            Object.assign(validStyles, {
              [key]: { ...nodeDSL.props.style, ...newStyleObject },
            });
          } else {
            Object.assign(validStyles, {
              [key]: newStyleObject,
            });
          }
        } else {
          switch (foundedValue?.componentView) {
            case COMPONENT_SETTING_VIEWS.select:
            case COMPONENT_SETTING_VIEWS.multiselect:
            case COMPONENT_SETTING_VIEWS.radio:
              if (key !== 'css' && key !== 'style') {
                if (castValue.options?.some(option => option.value === value)) {
                  Object.assign(validStyles, { [key]: value });
                }
              } else {
                Object.assign(validStyles, { [key]: value });
              }

              break;
            default:
              Object.assign(validStyles, { [key]: value });
              break;
          }
        }
      });
    }
  }

  return { validStyles, notCopiedReference };
}
