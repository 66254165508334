import { useMemo, useCallback } from 'react';

import { EventType } from '@8base-private/event-handler';
import { MutationTuple, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { nanoid as uuid } from 'nanoid';

import {
  AssetBackendMeta,
  AssetDSL,
  AssetDataDSL,
  AssetFileWithBackendDataDSL,
  AssetID,
  assetListSelectors,
  ASSETS_ROOT_FOLDER_ID,
  AssetFileDSL,
} from '@builder/schemas';

import { AssetCreateMutation, AssetCreateMutationVariables } from '../__generated__';
import { ASSET_CREATE_MUTATION } from '../fragments';
import { AssetDeleteDialogArgs, DASHBOARD_DIALOGS } from 'src/dialogs';
import { useDialogState, useDraftEngine } from 'src/providers';
import { useAppDispatch, useAssetListDSL } from 'src/providers/ReduxProvider';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';
import { useCurrentWorkspaceID } from 'src/shared/hooks';
import {
  clearAssetDSL,
  generateVariableNameFromString,
  getAssetMetaFieldsByType,
} from 'src/shared/utils';
import { DASHBOARD_EVENTS } from 'src/store';

import { useAssetsBackendHooks, useAssetsBackendHooksReturn } from './useAssetsBackendHooks';
import { useCurrentTeamMember } from './useCurrentTeamMember';

type UseAssetsHooksReturn = {
  assetFileArray: AssetFileWithBackendDataDSL[];
  assetListOptions: useAssetsBackendHooksReturn['assetListOptions'];
  assetFileNames: string[];
  createFileAsset: (assetCreateInput: CreateAssetData) => Promise<AssetDataDSL | undefined>;
  replaceFileAsset: (
    assetDSL: AssetFileWithBackendDataDSL,
    assetCreateInput: CreateAssetData,
  ) => Promise<void>;
  createAssetOptions: MutationTuple<AssetCreateMutation, AssetCreateMutationVariables>[1];
  updateAsset: (assetDSL: AssetDSL) => void;
  deleteAsset: (assetIDs: AssetID[]) => void;
  deleteAssetWithConfirmation: (args: AssetDeleteDialogArgs) => void;
};

export type CreateAssetData = {
  fileId: string;
  filename: string;
  meta: AssetBackendMeta;
};

export const useAssetsHooks = (): UseAssetsHooksReturn => {
  const send = useAppDispatch();
  const { draftEngine } = useDraftEngine();
  const { workspaceID } = useCurrentWorkspaceID();
  const assetListDSL = useAssetListDSL();
  const { teamMember } = useCurrentTeamMember();
  const { assetBackendArray, assetListOptions } = useAssetsBackendHooks();
  const { openDialog } = useDialogState<AssetDeleteDialogArgs>();

  const assetFileArray = useMemo(
    () =>
      assetListSelectors.getAssetFileWithBackendDataArray(assetListDSL, {
        assetBackendArray,
      }),
    [assetBackendArray, assetListDSL],
  );
  const assetFileNames = useMemo(() => assetFileArray.map(({ name }) => name), [assetFileArray]);

  const [createAssetMutation, createAssetOptions] = useMutation<
    AssetCreateMutation,
    AssetCreateMutationVariables
  >(ASSET_CREATE_MUTATION, {
    refetchQueries: ['AssetsList'],
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: `Successfully created the asset`,
    },
    onCompleted: () => {
      if (draftEngine) {
        draftEngine.send({
          type: EventType.Invalidate,
          uuid: uuid(),
          payload: 'AssetsList',
        });
      }
    },
  });

  const createFileAsset = useCallback(
    async (assetCreateInput: CreateAssetData) => {
      if (!assetCreateInput.fileId) {
        return;
      }

      const dateNow = DateTime.now().toUTC().toISO();

      const createAssetResponse = await createAssetMutation({
        variables: {
          data: {
            app: { connect: { workspaceId: workspaceID } },
            file: { create: assetCreateInput },
            key: assetCreateInput.fileId,
            content: {
              ...assetCreateInput.meta,
              createdAt: dateNow,
              createdBy: teamMember?.email as string,
              updatedAt: dateNow,
            },
          },
        },
      });

      const assetCreateData = createAssetResponse.data?.assetCreate;

      const correctFileName = generateVariableNameFromString({
        value: assetCreateInput.filename,
        existedValues: assetFileNames,
      });

      const assetMeta = getAssetMetaFieldsByType(
        assetCreateData?.content ?? {},
      ) as AssetBackendMeta;

      const newAssetDSL = clearAssetDSL(({
        name: correctFileName,
        parentID: ASSETS_ROOT_FOLDER_ID,
        backendFileID: assetCreateData?.file?.fileId as string,
        mimetype: assetCreateInput.meta?.mimetype,
        createdAt: dateNow,
        createdBy: teamMember?.email as string,
        updatedAt: dateNow,
        ...assetMeta,
      } as unknown) as AssetFileDSL) as AssetDataDSL;

      send({
        type: DASHBOARD_EVENTS.createAsset,
        assetDSL: newAssetDSL,
      });

      return newAssetDSL;
    },
    [assetFileNames, teamMember, createAssetMutation, workspaceID, send],
  );

  const replaceFileAsset = useCallback(
    async (assetDSL: AssetFileWithBackendDataDSL, assetCreateInput: CreateAssetData) => {
      if (!assetCreateInput.fileId) {
        return;
      }

      const dateNow = DateTime.now().toUTC().toISO();

      const createAssetResponse = await createAssetMutation({
        variables: {
          data: {
            app: { connect: { workspaceId: workspaceID } },
            file: { create: assetCreateInput },
            key: assetCreateInput.fileId,
            content: {
              ...assetCreateInput.meta,
              name: assetDSL.name,
              createdAt: assetDSL.createdAt,
              createdBy: assetDSL.createdBy,
              updatedAt: dateNow,
            },
          },
        },
      });

      const assetCreateData = createAssetResponse.data?.assetCreate;

      const assetMeta = getAssetMetaFieldsByType(
        assetCreateData?.content ?? {},
      ) as AssetBackendMeta;

      const newAssetDSL = clearAssetDSL(({
        name: assetDSL.name,
        parentID: ASSETS_ROOT_FOLDER_ID,
        backendFileID: assetCreateData?.file?.fileId as string,
        mimetype: assetCreateInput.meta?.mimetype,
        createdAt: assetDSL.createdAt,
        createdBy: assetDSL.createdBy,
        updatedAt: dateNow,
        ...assetMeta,
      } as unknown) as AssetFileDSL) as AssetDataDSL;

      send({
        type: DASHBOARD_EVENTS.deleteAsset,
        assetIDs: [assetDSL.id],
      });

      send({
        type: DASHBOARD_EVENTS.createAsset,
        assetDSL: newAssetDSL,
      });
    },
    [createAssetMutation, workspaceID, send],
  );

  const updateAsset = useCallback(
    (assetDSL: AssetDSL) =>
      send({
        type: DASHBOARD_EVENTS.updateAsset,
        assetDSL: clearAssetDSL(assetDSL) as AssetDSL,
      }),
    [send],
  );

  const deleteAsset = useCallback(
    (assetIDs: AssetID[]) =>
      send({
        type: DASHBOARD_EVENTS.deleteAsset,
        assetIDs,
      }),
    [send],
  );

  const deleteAssetWithConfirmation = useCallback(
    ({
      assetIDs,
      afterDeleteCallback,
    }: {
      assetIDs: AssetID[];
      afterDeleteCallback?: () => void;
    }) => {
      openDialog(DASHBOARD_DIALOGS.ASSET_DELETE_DIALOG_ID, {
        assetIDs,
        afterDeleteCallback,
      });
    },
    [openDialog],
  );

  return {
    assetFileArray,
    assetListOptions,
    assetFileNames,
    createFileAsset,
    replaceFileAsset,
    createAssetOptions,
    updateAsset,
    deleteAsset,
    deleteAssetWithConfirmation,
  };
};
