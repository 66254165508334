import React, { useCallback, useState } from 'react';

import { Dialog } from '@mui/material';

import {
  composeValidators,
  maxLength,
  hasNotContainSpecialCharacters,
  required,
  mustNotStartWithSpaces,
  mustBeCorrectLabelName,
} from '@builder/utils';

import { useAppDispatch } from '../../../providers';
import { Button } from '../Button';
import { CssGrid } from '../CssGrid';
import { Icon } from '../Icon';
import { TextField } from '../TextField';
import { RIGHT_PANEL_TABS, UI_BUILDER_EVENTS } from 'src/store';

import { PopupContent, PopupHeader, PopupTitle } from './NodeCreateGroupDialog.styles';

type NodeCreateGroupPopperProps = {
  isOpen: boolean;
  onClose: (event: React.SyntheticEvent<Element, Event>) => void;
  onCreateGroup?: (event: React.SyntheticEvent<Element, Event>, groupName: string) => void;
};

export const NodeCreateGroupDialog: React.FC<NodeCreateGroupPopperProps> = ({
  isOpen,
  onCreateGroup,
  onClose,
}) => {
  const [groupName, setGroupName] = useState('');
  const send = useAppDispatch();
  const [groupNameValidationError, setGroupNameValidationError] = useState<string | void>('');
  const nameValidation = composeValidators(
    mustBeCorrectLabelName,
    mustNotStartWithSpaces,
    maxLength(50),
    hasNotContainSpecialCharacters,
    required,
  );

  const changeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGroupName(event.target.value);
      setGroupNameValidationError(nameValidation(event.target.value));
    },
    [nameValidation],
  );

  const createGroup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCreateGroup?.(event, groupName);

    send({
      type: UI_BUILDER_EVENTS.updateRightPanel,
      rightPanel: {
        currentTab: RIGHT_PANEL_TABS.groupsList,
      },
    });

    onClose(event);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <PopupContent>
        <PopupHeader
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <PopupTitle>New Group</PopupTitle>
          <Icon
            element="button"
            width="16px"
            height="16px"
            name="cross"
            color="white"
            onClick={onClose}
          />
        </PopupHeader>
        <CssGrid gap={1.5} style={{ marginTop: 10 }}>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            value={groupName}
            onChange={changeName}
            error={Boolean(groupNameValidationError)}
            helperText={groupNameValidationError}
            multiline={false}
            onClick={event => {
              event.stopPropagation();
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!groupName || Boolean(groupNameValidationError)}
            onClick={event => createGroup(event)}
          >
            Create
          </Button>
        </CssGrid>
      </PopupContent>
    </Dialog>
  );
};
