import React from 'react';

import styled from '@emotion/styled';
import { Button, Grid, List, Typography } from '@mui/material';

import { AssetID } from '@builder/schemas';

import { AssetFileWithTemporaryStatesDSL } from '../../common/types';
import { AssetCard } from '../AssetCard';
import { AssetListItem } from '../AssetListItem';
import { CssGrid } from 'src/shared/components';
import { AssetViewModeTypes, ASSET_VIEW_MODES } from 'src/store';

type AssetsGridProps = {
  selectedAssetID?: AssetID;
  onSelectAsset?: (assetID?: AssetID) => void;
  toggleAssetSelection: (assetID: AssetID) => void;
  assetList: AssetFileWithTemporaryStatesDSL[];
  assetViewMode: AssetViewModeTypes;
};

const AssetsContainer = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
`;

const StyledButton = styled(Button)`
  width: 40px;
  height: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const AssetsGrid: React.FC<AssetsGridProps> = ({
  selectedAssetID,
  onSelectAsset,
  assetList,
  assetViewMode,
  toggleAssetSelection,
}) => {
  return (
    <AssetsContainer>
      <If condition={onSelectAsset}>
        <Grid mb={1} height="20px" container gap={0.5} alignItems="center">
          <Typography variant="body1">Choose an asset to replace.</Typography>
          <If condition={selectedAssetID}>
            <StyledButton variant="text" color="primary" onClick={() => onSelectAsset?.()}>
              Cancel
            </StyledButton>
          </If>
        </Grid>
      </If>
      <Choose>
        <When condition={assetViewMode === ASSET_VIEW_MODES.grid}>
          <CssGrid gridTemplateColumns="1fr 1fr 1fr" gridRowGap={2} gridColumnGap={0.5}>
            {assetList.map((asset: AssetFileWithTemporaryStatesDSL) => (
              <AssetCard
                key={asset?.id}
                selectedAssetID={selectedAssetID}
                asset={asset}
                onSelectAsset={onSelectAsset}
                toggleAssetSelectMultipleDeletion={toggleAssetSelection}
              />
            ))}
          </CssGrid>
        </When>
        <When condition={assetViewMode === ASSET_VIEW_MODES.list}>
          <List>
            {assetList.map((asset: AssetFileWithTemporaryStatesDSL) => (
              <AssetListItem
                key={asset?.id}
                selectedAssetID={selectedAssetID}
                asset={asset}
                onSelectAsset={onSelectAsset}
                toggleAssetSelectMultipleDeletion={toggleAssetSelection}
              />
            ))}
          </List>
        </When>
      </Choose>
    </AssetsContainer>
  );
};
