import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { assocSettings, NS_ID } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getCommonFormFieldPropertiesSettings, } from '../../common/settings';
import { getBuilderComponentsCheckboxSettings } from '../../material-components/BuilderComponentsCheckbox/BuilderComponentsCheckbox.settings';
const PREFIX_PATH = ['checkboxProps'];
const basicBuilderComponentsCheckboxSettings = getBuilderComponentsCheckboxSettings(PREFIX_PATH);
export const BuilderComponentsFormCheckboxSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldPropertiesSettings(), ...settings.children] }))))(basicBuilderComponentsCheckboxSettings.properties),
    style: basicBuilderComponentsCheckboxSettings.style,
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: ['onChange'],
                            children: getCommonActionSettings(['onChange']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
