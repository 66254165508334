import { getStyleSections } from './settings';
export * from './helpers';
export * from './settings/formSettings';
export * from './commonSections';
export * from './constants';
export { ACTIONS_ARGS_SCHEMA, PREDEFINED_ACTIONS } from './commonActions';
/**
 * TODO: remove after refactor
 * @deprecated
 */
export const getStylesSection = ({ prefixPath } = { prefixPath: [] }) => getStyleSections(prefixPath);
