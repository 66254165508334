import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, getBasicPropsSettings, NS_ID, STYLE_SECTION_TYPES, } from '../../common';
import { getBackgroundSection, getBorderSection, getCommonActionListSettings, getCommonActionSettings, getCSSSection, getCursorSection, getDivider, getFlexChildSection, getGridChildSection, getLayoutSection, getPropertySections, getSizeSection, getSpacingSection, } from '../../common/settings';
import { getCommonTabSettings } from '../BuilderComponentsTab/BuilderComponentsTab.settings';
export const getBuilderComponentsTabsSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            name: 'label',
                            nodePath: [...prefixPath, 'label', 0],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'arialabel'],
                            label: 'Arialabel',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'arialabelledby'],
                            label: 'Arialabelledby',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'value',
                            label: 'Value',
                            keyPath: [...prefixPath, 'value'],
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: 'tab-items',
                    title: 'Tab Items',
                    expandedByDefault: false,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.nodeList,
                            keyPath: ['children'],
                            label: 'Tab Items',
                            name: 'tabItems',
                            itemLabelKeyPath: ['children'],
                            itemLabelNodePath: ['label', 0, 'children', 0],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                                    settings: getCommonTabSettings(),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'indicatorColor'],
                    label: 'Indicator Color',
                    name: 'indicatorColor',
                    options: ['primary', 'secondary'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'orientation'],
                    label: 'Orientation',
                    name: 'orientation',
                    options: ['horizontal', 'vertical'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'variant'],
                    label: 'Variant',
                    name: 'variant',
                    options: ['scrollable', 'fullWidth', 'standard'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'scrollButtons'],
                    label: 'Scroll Buttons',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'centered'],
                    label: 'Centered',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'visibleScrollbar'],
                    label: 'Visible Scrollbar',
                },
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Custom Tab Header sx',
                    name: STYLE_SECTION_TYPES.css,
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.cssEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'headersx'],
                            name: 'headersx',
                            label: 'Header Styles (sx)',
                        },
                    ],
                },
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Custom Tab Panel sx',
                    name: STYLE_SECTION_TYPES.css,
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.cssEditor,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'panelsx'],
                            name: 'panelsx',
                            label: 'Panel Styles (sx)',
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...prefixPath, 'onChange'],
                            children: getCommonActionSettings([...prefixPath, 'onChange']),
                        },
                        ...getCommonActionListSettings(prefixPath),
                    ],
                },
            ],
        },
    ],
});
const getStyleSections = (prefixPath = []) => [
    getLayoutSection(prefixPath),
    getDivider(),
    getSpacingSection(prefixPath),
    getDivider(),
    getFlexChildSection(prefixPath),
    getDivider(),
    getGridChildSection(prefixPath),
    getDivider(),
    getSizeSection(prefixPath),
    getDivider(),
    getBackgroundSection(prefixPath),
    getDivider(),
    getBorderSection({ prefixPath, systemPrefixPath: prefixPath }),
    getDivider(),
    getCursorSection(prefixPath),
    getDivider(),
    getCSSSection(prefixPath),
];
export const BuilderComponentsTabsSettings = getBuilderComponentsTabsSettings();
