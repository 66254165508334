import { StateListDSL } from '@builder/schemas';

import { filterStatesByType } from './common';
import { DATA, QUERY } from './constants';
import { getMetadataValues } from './getMetadataValues';
import { GlobalAndLocalStates, MetaStates } from './types';

export const getAllQueryStates = (
  states: StateListDSL,
  appRuntimeStates: GlobalAndLocalStates,
): MetaStates => {
  const statesQueryType = filterStatesByType(states, QUERY);
  const metaObjectTypeStates = getMetadataValues(statesQueryType, appRuntimeStates, DATA);

  return {
    ...metaObjectTypeStates,
  };
};
