import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonActionProps, commonBasicProps, commonComponentProps, commonReactProps, } from '../../common/commonProps';
import { FileDropZoneBaseSymbolSettings } from './FileDropZoneBaseSymbol.settings';
export const FileDropZoneBaseSymbol = {
    name: COMPONENT_DSL_NAMES.FileDropZoneBaseSymbol,
    displayName: 'File Dropzone 8Base',
    icon: 'FileDropZoneBase',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'alias',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.object,
                    name: 'FileDropZoneBase',
                    defaultValue: {
                        fileUploaded: {
                            filename: '',
                            fileId: '',
                        },
                        upload: 'fn',
                        progress: 0,
                    },
                },
            ],
            props: {
                disabled: false,
                exceededFileSizeText: 'Max file size exceeded ({maxSize})',
                wrongFileTypeText: 'Wrong file type uploaded',
                css: '\n  transition: all .2s;\n  &.disabled {\n    opacity:.5;\n  } \n  &.hovering {\n    background-color: rgba(0,0,0,.2);\n  }\n',
                setMethods: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FileDropZoneBase.setValue(methods)',
                    },
                },
                icon: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: {
                                name: 'FileUploadOutlined',
                            },
                        },
                    ],
                },
                inputText: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Drag and drop files here',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                uploadedItemsText: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: '{{__STATES__.FileDropZoneBase.value.fileUploaded?.filename || "" }}',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), commonBasicProps), { icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, inputText: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialTypography],
            }, uploadedItemsText: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialTypography],
            }, onSuccess: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, apiToken: { type: COMPONENT_DSL_PROP_TYPES.string }, setMethods: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'methods',
                        path: [0],
                    },
                ],
            }, uploadOnChange: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, accept: { type: COMPONENT_DSL_PROP_TYPES.string }, maxFileSize: { type: COMPONENT_DSL_PROP_TYPES.number }, workspaceId: { type: COMPONENT_DSL_PROP_TYPES.string }, exceededFileSizeText: { type: COMPONENT_DSL_PROP_TYPES.string }, wrongFileTypeText: { type: COMPONENT_DSL_PROP_TYPES.string } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.BuilderComponentsFileDropZoneBase,
            props: {
                style: '{{ symbolProps.style }}',
                onClick: '{{ symbolProps.onClick }}',
                onChange: '{{ symbolProps.onChange }}',
                css: '{{ symbolProps.css }}',
                children: '{{ [symbolProps.icon, symbolProps.inputText, symbolProps.uploadedItemsText] }}',
                id: '{{ symbolProps.id }}',
                title: '{{ symbolProps.title }}',
                className: '{{ symbolProps.className }}',
                lang: '{{ symbolProps.lang }}',
                translate: '{{ symbolProps.translate }}',
                draggable: '{{ symbolProps.draggable }}',
                setMethods: '{{ symbolProps.setMethods }}',
                onSuccess: '{{ symbolProps.onSuccess }}',
                onError: '{{ symbolProps.onError }}',
                apiToken: '{{ symbolProps.apiToken }}',
                uploadOnChange: '{{ symbolProps.uploadOnChange }}',
                accept: '{{ symbolProps.accept }}',
                maxFileSize: '{{ symbolProps.maxFileSize }}',
                workspaceId: '{{ symbolProps.workspaceId }}',
                disabled: '{{ symbolProps.disabled }}',
                exceededFileSizeText: '{{ symbolProps.exceededFileSizeText }}',
                wrongFileTypeText: '{{ symbolProps.wrongFileTypeText }}',
            },
        },
    },
    settings: FileDropZoneBaseSymbolSettings,
};
