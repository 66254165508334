import { createSelector } from 'reselect';

import { DashboardState } from '../common';

export const getDashboardState = (dashboardState: DashboardState): DashboardState => dashboardState;

export const getAppDSL = createSelector(getDashboardState, dashboardState => {
  return dashboardState.appConfiguration.appDSL;
});

export const getComponentListDSL = createSelector(getDashboardState, dashboardState => {
  return { ...dashboardState.components, ...dashboardState.appConfiguration.userComponentsDSL };
});

export const getNodeListDSL = createSelector(getDashboardState, dashboardState => {
  return { ...dashboardState.appConfiguration.appDSL.nodes };
});

export const getStateListDSL = createSelector(getDashboardState, dashboardState => {
  return dashboardState.appConfiguration.appDSL.states;
});
