import { XMLParser } from 'fast-xml-parser';
import { hasPropJsCode, PropChecker, } from '@builder/schemas';
import { ERROR_SCOPES, isArray, isBoolean, isNil, isNumber, isObject, isString, SystemError, } from '@builder/utils';
import { errorMessages } from '../audit-message-getters';
import { isValidReactElement } from '../utils/isValidReactElement';
/**
 * Base class which contains common validators.
 */
export class AppEntityBaseValidator {
    constructor(errorGetter) {
        this.errorGetter = errorGetter;
    }
    getValidationRule({ propPath, isNotValid, message, }) {
        if (isNotValid) {
            this.errorGetter.passErrorNotification({
                propPath,
                message,
            });
            return false;
        }
        return true;
    }
    getFunctionPassErrorNotification(propPath) {
        return (error) => {
            this.getValidationRule({
                propPath,
                isNotValid: true,
                message: errorMessages.getSourceFunctionError(propPath, error.message),
            });
        };
    }
    checkArrayProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isArrayProp(propValue),
            message: errorMessages.getArrayPropError({ propPath, propValue }),
        });
    }
    checkTransformedArrayProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isArray(transformedValue),
            message: errorMessages.getArrayPropError({ propPath, propValue: transformedValue }),
        });
    }
    checkObjectProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isObjectProp(propValue),
            message: errorMessages.getObjectPropError({ propPath, propValue }),
        });
    }
    checkTransformedObjectProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isObject(transformedValue),
            message: errorMessages.getObjectPropError({ propPath, propValue: transformedValue }),
        });
    }
    checkStyleProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isStyleProp(propValue),
            message: errorMessages.getStylePropError({ propPath, propValue }),
        });
    }
    checkTransformedStyleProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isObject(transformedValue),
            message: errorMessages.getStylePropError({ propPath, propValue: transformedValue }),
        });
    }
    checkReactNodeProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isReactNodeProp(propValue),
            message: errorMessages.getReactNodePropError({ propPath, propValue }),
        });
    }
    checkTransformedReactNodeProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isValidReactElement(transformedValue),
            message: errorMessages.getReactNodePropError({ propPath, propValue: transformedValue }),
        });
    }
    checkNumberProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isNumberProp(propValue),
            message: errorMessages.getNumberPropError({ propPath, propValue }),
        });
    }
    checkNumberMaxValueProp(propValue, propPath, propDSL) {
        const { maxValue } = propDSL;
        return this.getValidationRule({
            propPath,
            isNotValid: !!propValue && !!maxValue && propValue > maxValue,
            message: errorMessages.getNumberMaxValuePropError({ propPath, propValue, propDSL }),
        });
    }
    checkNumberMinValueProp(propValue, propPath, propDSL) {
        const { minValue } = propDSL;
        return this.getValidationRule({
            propPath,
            isNotValid: !!propValue && !!minValue && propValue < minValue,
            message: errorMessages.getNumberMinValuePropError({ propPath, propValue, propDSL }),
        });
    }
    checkTransformedNumberProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isNumber(transformedValue),
            message: errorMessages.getNumberPropError({ propPath, propValue: transformedValue }),
        });
    }
    checkStringProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isStringProp(propValue),
            message: errorMessages.getStringPropError({ propPath, propValue }),
        });
    }
    checkHTMLFragmentValidProp(propValue, propPath) {
        const parsingOptions = {
            ignoreAttributes: false,
            unpairedTags: ['hr', 'br', 'link', 'meta'],
            stopNodes: ['*.pre', '*.script'],
            processEntities: true,
            htmlEntities: true,
        };
        function flattenObjectKeys(obj) {
            const keys = Object.keys(obj);
            const result = [];
            for (let i = 0; i < keys.length; i++) {
                if (typeof obj[keys[i]] === 'object') {
                    result.push(...flattenObjectKeys(obj[keys[i]]));
                }
                else {
                    result.push(keys[i]);
                }
            }
            return result;
        }
        try {
            const parser = new XMLParser(parsingOptions);
            const XMLdata = parser.parse(propValue);
            const XMLNodes = Object.keys(XMLdata);
            const hasHTMLTag = XMLNodes.includes('html');
            const hasHeadTag = XMLNodes.includes('head');
            const hasBodyTag = XMLNodes.includes('body');
            const hasScriptTag = XMLNodes.includes('script');
            const flattenedValues = flattenObjectKeys(XMLdata);
            if (hasHTMLTag) {
                return this.getValidationRule({
                    propPath,
                    isNotValid: true,
                    message: errorMessages.getHTMLTagPropError({ propPath, propValue }),
                });
            }
            if (hasHeadTag) {
                return this.getValidationRule({
                    propPath,
                    isNotValid: true,
                    message: errorMessages.getHeadHTMLTagPropError({ propPath, propValue }),
                });
            }
            if (hasBodyTag) {
                return this.getValidationRule({
                    propPath,
                    isNotValid: true,
                    message: errorMessages.getBodyHTMLTagPropError({ propPath, propValue }),
                });
            }
            if (hasScriptTag) {
                return this.getValidationRule({
                    propPath,
                    isNotValid: true,
                    message: errorMessages.getScriptHTMLTagPropError({ propPath, propValue }),
                });
            }
            const errorTags = ['html', 'head', 'body', 'script', 'style'];
            if (flattenedValues.some(value => errorTags.includes(value))) {
                return this.getValidationRule({
                    propPath,
                    isNotValid: true,
                    message: errorMessages.getHTMLTGenericPropError({ propPath, propValue }),
                });
            }
            return true;
        }
        catch (error) {
            return false;
        }
    }
    checkTransformedStringProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isString(transformedValue),
            message: errorMessages.getStringPropError({ propPath, propValue: transformedValue }),
        });
    }
    checkBooleanProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isBooleanProp(propValue),
            message: errorMessages.getBooleanPropError({ propPath, propValue }),
        });
    }
    checkTransformedBooleanProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !isNil(transformedValue) && !isBoolean(transformedValue),
            message: errorMessages.getBooleanPropError({ propPath, propValue: transformedValue }),
        });
    }
    checkAssetProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isAssetProp(propValue),
            message: errorMessages.getAssetPropError({ propPath, propValue }),
        });
    }
    checkTransformedAssetProp(transformedValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isAssetProp(transformedValue),
            message: errorMessages.getAssetPropError({ propPath, propValue: transformedValue }),
        });
    }
    checkCSSProp(propValue, propPath) {
        return this.getValidationRule({
            propPath,
            isNotValid: !PropChecker.Value.isCSSProp(propValue),
            message: errorMessages.getCSSPropError({ propPath, propValue }),
        });
    }
    checkTransformedRequiredProp({ propPath, propDSL }, transformedPropValue) {
        return this.getValidationRule({
            propPath,
            isNotValid: Boolean(propDSL.required) && isNil(transformedPropValue),
            message: errorMessages.getRequiredPropError({ propPath }),
        });
    }
    checkRequiredProp({ propPath, propDSL, propValue }) {
        return this.getValidationRule({
            propPath,
            isNotValid: Boolean(propDSL.required) && isNil(propValue),
            message: errorMessages.getRequiredPropError({ propPath }),
        });
    }
    checkAllowJSProp({ propPath, propDSL, propValue }) {
        return this.getValidationRule({
            propPath,
            isNotValid: propDSL.allowJS === false && hasPropJsCode(propValue),
            message: errorMessages.getDisallowJSPropError({ propPath }),
        });
    }
    checkUniqueProp(_) {
        throw new SystemError(ERROR_SCOPES.appEngine, `Must be implemented`);
    }
    checkUniquePropOnDSL({ propPath, propDSL, propValue }, externalState) {
        return this.getValidationRule({
            propPath,
            isNotValid: false,
            message: errorMessages.getUniqueIdPropError({ propValue, propPath }),
        });
    }
    checkUniqKeyPropOnComponent({ propPath, propListDSL, propValue, }) {
        /* Getting all property keys for current node from the propListDSL object. */
        const allComponentProps = Object.keys(propListDSL);
        const currentCustomPropValue = propValue;
        /* map all keys from custom properties in all keys array. */
        currentCustomPropValue.forEach(arrayPropItemValue => {
            const { Key } = arrayPropItemValue;
            allComponentProps.push(Key);
        });
        /* Checking if there are duplicated values in the array. */
        const hasDuplicatedValues = allComponentProps.filter((element, index) => {
            return allComponentProps.indexOf(element) !== index;
        });
        return this.getValidationRule({
            propPath,
            isNotValid: hasDuplicatedValues.length > 0,
            message: errorMessages.getCustomPropKeyError({
                propValue: hasDuplicatedValues.join(','),
                propPath,
            }),
        });
    }
}
