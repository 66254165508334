import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

import { ComponentSettingSubSectionDSL } from '@builder/schemas';
import { memo } from '@builder/utils';

import { CssGrid } from 'src/shared/components';

type SubSectionTypeProps = {
  subSectionSetting: ComponentSettingSubSectionDSL;
  children: React.ReactNode;
};

const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  line-height: 16px;
`;

export const SubSectionType = memo(
  'SubSectionType',
  ({ subSectionSetting, children }: SubSectionTypeProps): JSX.Element => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Title variant="subtitle1" align="left">
            {subSectionSetting.title}
          </Title>
        </Grid>
        <Grid item xs={12}>
          <CssGrid gap={1} width="100%">
            {children}
          </CssGrid>
        </Grid>
      </Grid>
    );
  },
);
