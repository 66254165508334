import { mustBeCorrectVariableName } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_16_9 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.9';
    }
    getChangeList() {
        return ['Fix all resources with wrong names'];
    }
    migrateResourceDSL(resourceDSL, _appDSL) {
        const isNameCorrect = !mustBeCorrectVariableName(resourceDSL.name);
        if (isNameCorrect) {
            return resourceDSL;
        }
        const newResourceName = `${resourceDSL.name}`
            .replaceAll(/^[^a-zA-Z_]+/g, '_$&')
            .replaceAll(/[^a-zA-Z_0-9]+/g, '_');
        return Object.assign(Object.assign({}, resourceDSL), { name: newResourceName });
    }
}
export const migration_0_16_9 = new Migration_0_16_9();
