import { createSelector } from 'reselect';
import { ERROR_SCOPES, isObject, isString, SchemaValidationError } from '@builder/utils';
import { COMPONENT_DSL_TYPES } from '../constants';
const getComponentDSL = (componentDSL) => componentDSL;
export const getPropSchemaListDSL = createSelector(getComponentDSL, componentDSL => {
    if (!isObject(componentDSL.schema.props)) {
        throw new SchemaValidationError(ERROR_SCOPES.appEngine, `Component props schema for ${componentDSL.name} component not found.`);
    }
    return componentDSL.schema.props;
});
export const getPropSchemaDSL = createSelector(getPropSchemaListDSL, (_, propName) => propName, (propSchemaListDSL, propName) => {
    return propSchemaListDSL && isString(propName) ? propSchemaListDSL[propName] : null;
});
export const getDisplayName = createSelector(getComponentDSL, componentDSL => {
    return componentDSL.displayName;
});
export const getIconName = createSelector(getComponentDSL, componentDSL => { var _a; return (_a = componentDSL.icon) !== null && _a !== void 0 ? _a : 'container'; });
export const isMemoizationEnabled = createSelector(getComponentDSL, componentDSL => {
    var _a;
    const disable = (_a = componentDSL.memoization) === null || _a === void 0 ? void 0 : _a.disable;
    return !disable;
});
export const isComponentOverlayHidden = createSelector(getComponentDSL, componentDSL => {
    var _a, _b;
    const hideOverlay = (_b = (_a = componentDSL === null || componentDSL === void 0 ? void 0 : componentDSL.schema) === null || _a === void 0 ? void 0 : _a.accessibility) === null || _b === void 0 ? void 0 : _b.hideOverlay;
    return Boolean(hideOverlay);
});
export const hideOverlayForNonSelectableElement = createSelector(getComponentDSL, componentDSL => {
    var _a, _b;
    const hideOverlay = (_b = (_a = componentDSL === null || componentDSL === void 0 ? void 0 : componentDSL.schema) === null || _a === void 0 ? void 0 : _a.accessibility) === null || _b === void 0 ? void 0 : _b.hideOverlayForNonSelectableElement;
    return Boolean(hideOverlay);
});
export const isCreatingSymbolDisabled = createSelector(getComponentDSL, componentDSL => {
    var _a, _b;
    const disableCreatingSymbol = (_b = (_a = componentDSL === null || componentDSL === void 0 ? void 0 : componentDSL.schema) === null || _a === void 0 ? void 0 : _a.accessibility) === null || _b === void 0 ? void 0 : _b.disableCreatingSymbol;
    return Boolean(disableCreatingSymbol);
});
export const isSymbol = createSelector(getComponentDSL, (componentDSL) => {
    return componentDSL.type === COMPONENT_DSL_TYPES.symbol;
});
export const isIterable = createSelector(getComponentDSL, componentDSL => {
    return componentDSL.type === COMPONENT_DSL_TYPES.symbol;
});
export const getPresentation = createSelector(getComponentDSL, (_, props) => props.presentation, (componentDSL, presentationName) => {
    if (!componentDSL.schema.presentations) {
        throw new SchemaValidationError(ERROR_SCOPES.schemas, `${presentationName} presentation schema not found for ${componentDSL.name} component`);
    }
    return componentDSL.schema.presentations.find(({ name }) => name === presentationName) || null;
});
export const getPresentationProps = createSelector(getPresentation, presentation => presentation === null || presentation === void 0 ? void 0 : presentation.props);
export const getPresentationCondition = createSelector(getPresentation, presentation => presentation === null || presentation === void 0 ? void 0 : presentation.condition);
export const isOverlayOutlineWhenEmpty = createSelector(getComponentDSL, componentDSL => {
    var _a;
    return (_a = componentDSL.overlayOutlineWhenEmpty) !== null && _a !== void 0 ? _a : false;
});
export const getSettingsDSLWithSplit = createSelector(getComponentDSL, componentDSL => componentDSL.settings);
export const getFlatListSettingsDSL = createSelector(getSettingsDSLWithSplit, settings => {
    if (!settings) {
        return [];
    }
    return [...settings.events, ...settings.properties, ...settings.style];
});
export const isDeprecated = createSelector(getComponentDSL, componentDSL => {
    return Boolean(componentDSL.deprecated);
});
export const getParentContraints = createSelector(getComponentDSL, componentDSL => {
    var _a;
    return (_a = componentDSL.schema.parentContraints) !== null && _a !== void 0 ? _a : [];
});
