import { useMemo } from 'react';

import { ApolloQueryResult, useQuery } from '@apollo/client';

import { isNil } from '@builder/utils';

import { UploadcareSignInfoQuery, UploadcareSignInfoQueryVariables } from '../__generated__';
import { UPLOADCARE_SIGN_INFO_QUERY } from '../fragments';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

type UseUploadcareCredentialsReturn = () => {
  uploadcareSignInfo: {
    publicKey: string | null;
    signature?: string;
    expire?: number;
  };
  loading: boolean;
  error: boolean;
  refetch: () => Promise<ApolloQueryResult<UploadcareSignInfoQuery>>;
};

const UPLOADCARE_RESPONSE_TYPE = 'UploadcareSignInfoResponse';

export const useUploadcareCredentials: UseUploadcareCredentialsReturn = () => {
  const { workspaceID } = useCurrentWorkspaceID();

  const { data, loading, error: fetchingError, refetch } = useQuery<
    UploadcareSignInfoQuery,
    UploadcareSignInfoQueryVariables
  >(UPLOADCARE_SIGN_INFO_QUERY, {
    skip: !workspaceID,
    fetchPolicy: 'no-cache',
  });

  const uploadcareSignInfo = useMemo(() => {
    const info = data?.system?.fileUploadSignInfo;

    if (info?.__typename === UPLOADCARE_RESPONSE_TYPE) {
      return {
        publicKey: info?.publicKey ?? null,
        signature: info?.signature,
        expire: info?.expire ? Number(info.expire) : undefined,
      };
    }

    return { publicKey: null };
  }, [data]);

  const error = !loading && Boolean(fetchingError || isNil(uploadcareSignInfo.publicKey));

  return {
    uploadcareSignInfo,
    loading,
    error,
    refetch,
  };
};
