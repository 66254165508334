import { convertHexToRgb } from './convertHexToRgb';
import { CSS_COLOR_MODEL } from './types';
/**
 * @example
 * clamp({ num: 150, min: 200, max: 500 }) => 200
 * clamp({ num: 400, min: 0, max: 255 }) => 255
 */
const clamp = ({ num, min, max }) => Math.min(Math.max(min, num), max);
/**
 * @example
 * `500 => 255, -10 => 0, 128 => 128`
 */
const parseRGB = (num) => {
    let n = num;
    if (typeof n !== 'number') {
        n = n.endsWith('%') ? (parseFloat(n) * 255) / 100 : parseFloat(n);
    }
    return clamp({ num: Math.round(n), min: 0, max: 255 });
};
/**
 * @example
 * `200 => 100, -100 => 0, 50 => 50`
 */
const parsePercentage = (percentage) => clamp({ num: parseFloat(percentage), min: 0, max: 100 });
/**
 * @example
 * '50%' => 5.0, 200 => 1, -10 => 0
 */
function parseAlpha(alpha) {
    let a = alpha;
    if (typeof a !== 'number') {
        a = a.endsWith('%') ? parseFloat(a) / 100 : parseFloat(a);
    }
    return clamp({ num: a, min: 0, max: 1 });
}
export const getHEX = (hex) => {
    const [r, g, b, a] = convertHexToRgb(hex);
    return Object.assign(Object.assign({}, getRGB(['', ...[r, g, b, a].map(String)])), { type: CSS_COLOR_MODEL.hex });
};
export function getHSL([, h, s, l, a = '1']) {
    let hh = h;
    const hString = String(h);
    if (hString.endsWith('turn')) {
        hh = (parseFloat(hString) * 360) / 1;
    }
    else if (hString.endsWith('rad')) {
        hh = Math.round((parseFloat(hString) * 180) / Math.PI);
    }
    else {
        hh = parseFloat(hString);
    }
    return {
        type: CSS_COLOR_MODEL.hsl,
        value: [hh, parsePercentage(String(s)), parsePercentage(String(l))],
        alpha: parseAlpha(a === null ? 1 : a),
    };
}
export const getLab = ([, l, a, b, alpha = '1']) => {
    return {
        type: CSS_COLOR_MODEL.lab,
        value: [l, a, b].map(parseFloat),
        alpha: parseAlpha(alpha === null ? 1 : alpha),
    };
};
export function getRGBA([, r, g, b, a = '1']) {
    return {
        type: CSS_COLOR_MODEL.rgba,
        value: [r, g, b].map(parseRGB),
        alpha: parseAlpha(a === null ? '1' : a),
    };
}
export function getRGB([, r, g, b, a = '1']) {
    return {
        type: CSS_COLOR_MODEL.rgb,
        value: [r, g, b].map(parseRGB),
        alpha: parseAlpha(a === null ? '1' : a),
    };
}
