import React from 'react';

import styled from '@emotion/styled';
import {
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
  PopperPlacementType,
  ClickAwayListener,
  Fade,
} from '@mui/material';

import { Z_INDEXES_BUILDER } from '@builder/utils';

export type PopperProps = MuiPopperProps & {
  onClose?: (event: MouseEvent | TouchEvent) => void;
  // TODO: fix any to Array<Modifiers> (Material-v4 => Material-v5)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modifiers?: Array<any>;
  placement?: PopperPlacementType;
  zIndex?: number;
  // used for static position without scrolling
  customTransform?: string;
  filter?: string;
  disablePortal?: boolean;
  'data-test'?: string;
};

const DEFAULT_POPPER_MODIFIERS = [
  {
    name: 'hide',
    enabled: true,
  },
];

const DEFAULT_PLACEMENT = 'left';
const BLACK_LIST_PROPS = ['zIndex', 'customTransform'];
const shouldForwardProp = (prop: string): boolean => !BLACK_LIST_PROPS.includes(prop);

export const StyledPopper = styled(MuiPopper, { shouldForwardProp })<{
  zIndex?: number;
  customTransform?: string;
  filter?: string;
}>`
  filter: ${({ filter }) => filter};
  z-index: ${({ zIndex }) => zIndex ?? Z_INDEXES_BUILDER.leftPanel};
  transform: ${({ customTransform }) =>
    customTransform ? `${customTransform} !important` : undefined};
  &[x-out-of-boundaries] {
    visibility: hidden;
    pointer-events: none;
  }
  /* Hide the popper when the reference is hidden */
  &[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }
`;

export const Popper: React.FC<PopperProps> = ({
  open,
  onClose = () => undefined,
  anchorEl,
  modifiers = DEFAULT_POPPER_MODIFIERS,
  placement = DEFAULT_PLACEMENT,
  zIndex,
  customTransform,
  disablePortal,
  filter,
  children,
  'data-test': dataTest,
}) => {
  return (
    <StyledPopper
      open={open}
      data-test={dataTest}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={modifiers}
      disablePortal={disablePortal}
      zIndex={zIndex}
      customTransform={customTransform}
      filter={filter}
      transition
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Fade {...TransitionProps} timeout={200}>
            <div>{children}</div>
          </Fade>
        </ClickAwayListener>
      )}
    </StyledPopper>
  );
};
