import { COMPONENT_DSL_PROP_TYPES } from '../../constants';
export const PREDEFINED_ACTIONS = {
    changePage: 'routerHistory.push(url)',
    requiredValidate: 'return !value ? "Required" : undefined',
    closePopup: 'popupState.onClose()',
    functionCode: 'void',
    runRequest: 'requestRunner.run(requestID)',
    updateState: '__STATE__setvalue(stateValue)',
};
export const ACTIONS_ARGS_SCHEMA = {
    requiredValidate: {},
    closePopup: {},
    customCode: {
        code: { type: COMPONENT_DSL_PROP_TYPES.string },
    },
    changePage: {
        url: { type: COMPONENT_DSL_PROP_TYPES.string },
    },
    functionCode: {
        code: { type: COMPONENT_DSL_PROP_TYPES.string },
    },
    runRequest: {
        requestID: {
            type: COMPONENT_DSL_PROP_TYPES.string,
        },
    },
    updateState: {
        stateArgs: {
            type: COMPONENT_DSL_PROP_TYPES.object,
        },
    },
};
