import { COMPONENT_DSL_NAMES } from '@builder/schemas';

export const BUSINESS_LOGIC_SETTINGS_NAME = {
  listItems: 'listItems',
  radioItems: 'radioItems',
  loopSection: 'loopSection',
  'radio-items': 'radio-items',
  localStates: 'localStates',
  Format: 'Format',
  'Open To': 'Open To',
};

export const COMPONENTS_WITH_CHILDREN_NODE_SETTINGS = [
  COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
  COMPONENT_DSL_NAMES.Iterator,
  COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
];
