import { equals, filter } from 'ramda';

import { StateID, StateListDSL } from '@builder/schemas';

export const stateRemove = (appStatesDSL: StateListDSL, stateIDs: StateID[]): StateListDSL => {
  const statesWithoutDeleted = filter(
    stateDSL => !stateIDs.some(equals(stateDSL.id)),
    appStatesDSL,
  );

  return statesWithoutDeleted;
};
