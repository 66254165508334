import { AppVersion, DeployVersion } from '@builder/schemas';

import { joinVersion } from './joinVersion';

export const concatAppVersionParts = (
  deployVersionPart: DeployVersion,
  appVersionPart: string,
): AppVersion => {
  return joinVersion([deployVersionPart, appVersionPart]);
};
