import { omit } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_7_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.7.1';
    }
    getChangeList() {
        return ['Removed color property from Alert component'];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case 'AlertSymbol':
            case 'MaterialAlert': {
                const colorProperty = nodeDSL.props.color;
                const isValidColorProperty = ['error', 'info', 'success', 'warning'].some(colorVariant => colorVariant === colorProperty);
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, omit(['color'], nodeDSL.props)), { severity: isValidColorProperty ? colorProperty : nodeDSL.props.severity }) });
            }
            default:
                return nodeDSL;
        }
    }
}
export const migration_0_7_1 = new Migration_0_7_1();
