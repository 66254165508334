import { ERROR_SCOPES, SchemaValidationError } from '@builder/utils';
import { NodeSchemaChecker } from './NodeSchemaChecker';
/**
 * Throw runtime error if node DSL has incorrect attributes.
 */
export class NodeSchemaAssert {
    static assertIsStateValid(node) {
        if (!NodeSchemaChecker.isStateValid(node)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Node ${node.name} with ${node.id} id has incorrect state .Expect to be: "{ stateID: string, required?: boolean }[]", but got ${JSON.stringify(node.states)}`);
        }
    }
    static assertHasRequiredAttributes(node) {
        if (!NodeSchemaChecker.hasRequiredAttributes(node)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Node doesn't have required attributes .Expect to be: "{ id: string, parentID: string | null, props: object", but got ${JSON.stringify(node)}`);
        }
    }
    static assertIsRouteNode(node) {
        if (!NodeSchemaChecker.isRouteNode(node)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Node must be route, but got ${JSON.stringify(node)}`);
        }
    }
}
