import { gql } from '@apollo/client';

export const CONFIGURATION_BACKUP_FRAGMENT = gql`
  fragment UserConfigurationBackup on AppbuilderUserConfigurationBackup {
    id
    appDSL
    userComponentsDSL
    appVersion
    schemaDSLVersion
    updatedAt
  }
`;

export const CONFIGURATION_BACKUPS_LIST_QUERY = gql`
  query ConfigurationBackupsList(
    $workspaceId: String!
    $filter: AppbuilderUserConfigurationBackupFilter
    $sort: AppbuilderUserConfigurationBackupSort!
    $first: Int
  ) {
    appbuilder {
      userConfigurationBackupsList(
        workspaceId: $workspaceId
        filter: $filter
        sort: $sort
        first: $first
      ) {
        items {
          id
          updatedAt
          appVersion
          schemaDSLVersion
        }
      }
    }
  }
`;

export const CONFIGURATION_BACKUP_QUERY = gql`
  query ConfigurationBackup($workspaceId: String!, $id: ID!) {
    appbuilder {
      userConfigurationBackup(workspaceId: $workspaceId, id: $id) {
        ...UserConfigurationBackup
        appDSL
        userComponentsDSL
      }
    }
  }
  ${CONFIGURATION_BACKUP_FRAGMENT}
`;

export const CONFIGURATION_BACKUP_CREATE_MUTATION = gql`
  mutation ConfigurationBackupCreate($data: AppbuilderUserConfigurationBackupCreateInput!) {
    appbuilder {
      userConfigurationBackupCreate(data: $data) {
        success
      }
    }
  }
`;
