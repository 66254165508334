import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonActionProps, commonBasicProps, commonComponentProps, commonReactProps, } from '../../common/commonProps';
import { MaterialCardMedia } from '../../material-components/MaterialCardMedia';
import { CardSymbolSettings } from './CardSymbol.settings';
export const CardSymbol = {
    name: COMPONENT_DSL_NAMES.CardSymbol,
    displayName: 'Card',
    icon: 'card',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            selectNodeInside: ['header'],
            mainProp: 'Title',
        },
        predefineds: {
            props: {
                raised: true,
                header: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialCardHeader,
                            props: {
                                title: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Card',
                                            },
                                        },
                                    ],
                                },
                                subheader: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: '',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                content: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialCardContent,
                            props: {
                                style: getPredefinedStyles(),
                            },
                        },
                    ],
                },
                media: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialCardMedia,
                        },
                    ],
                },
            },
        },
        dndTargetPropName: 'content',
        props: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonComponentProps), commonActionProps), commonReactProps), commonBasicProps), { content: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialCardContent],
            }, header: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialCardHeader],
            }, headerProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    title: { type: COMPONENT_DSL_PROP_TYPES.string },
                    subheader: { type: COMPONENT_DSL_PROP_TYPES.string },
                    disableTypography: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                },
            }, media: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialCardMedia],
            }, mediaProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: MaterialCardMedia.schema.props,
            }, raised: { type: COMPONENT_DSL_PROP_TYPES.boolean }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialCard,
            props: {
                raised: '{{ symbolProps.raised }}',
                onClick: '{{ symbolProps.onClick }}',
                onMouseOver: '{{ symbolProps.onMouseOver }}',
                onMouseOut: '{{ symbolProps.onMouseOut }}',
                onMouseDown: '{{ symbolProps.onMouseDown }}',
                onMouseUp: '{{ symbolProps.onMouseUp }}',
                onMouseEnter: '{{ symbolProps.onMouseEnter }}',
                onMouseLeave: '{{ symbolProps.onMouseLeave }}',
                onWheel: '{{ symbolProps.onWheel }}',
                onContextMenu: '{{ symbolProps.onContextMenu }}',
                onAuxClick: '{{ symbolProps.onAuxClick }}',
                onDragOver: '{{ symbolProps.onDragOver }}',
                onDragStart: '{{ symbolProps.onDragStart }}',
                onDrop: '{{ symbolProps.onDrop }}',
                css: '{{ symbolProps.css }}',
                style: '{{ symbolProps.style }}',
                children: '{{ [symbolProps.header, symbolProps.media, symbolProps.content] }}',
                id: '{{ symbolProps.id }}',
                title: '{{ symbolProps.title }}',
                className: '{{ symbolProps.className }}',
                lang: '{{ symbolProps.lang }}',
                translate: '{{ symbolProps.translate }}',
                draggable: '{{ symbolProps.draggable }}',
            },
        },
    },
    settings: CardSymbolSettings,
};
