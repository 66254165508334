import { assocPath, dissocPath, path, values } from 'ramda';
import { createSelector } from 'reselect';
import { ERROR_SCOPES, isUndefined, SchemaError } from '@builder/utils';
import { RESOURCE_TYPES } from '../constants';
import * as resourceListSelectors from './resource-list-selectors';
import * as resourceSelectors from './resource-selectors';
const getAppDSL = (appDSL) => appDSL;
/**
 * Helper proxies ramda assocPath with typechecking.
 */
export const assocAppPath = (state, absolutePath, value) => {
    if (isUndefined(value)) {
        return dissocAppPath(state, absolutePath);
    }
    return assocPath(absolutePath, value, state);
};
/**
 * Helper proxies ramda dissocPath with typechecking.
 */
export const dissocAppPath = (state, absolutePath) => {
    return dissocPath(absolutePath, state);
};
/**
 * Helper proxies ramda path with typechecking.
 */
export const appPath = (state, absolutePath = []) => {
    return path(absolutePath, state);
};
/**
 * Assoc any value inside some node props.
 */
export const assocNodePropsPath = (state, nodeID, relativePath = [], value) => {
    return assocAppPath(state, ['nodes', nodeID, 'props', ...relativePath], value);
};
/**
 * Assoc prop value inside some node.
 */
export const assocNodeProps = (state, nodeID, value) => {
    return assocAppPath(state, ['nodes', nodeID, 'props'], value);
};
/**
 * @returns react-node prop value (e.g. nodes from { ... props: { children: { nodes: [...] }}.
 */
export const getRenderableNodesByPropPath = (state, nodeID, renderPropPath = []) => {
    return appPath(state, ['nodes', nodeID, 'props', ...renderPropPath, 'nodes']);
};
/**
 * @returns node props.
 */
export const getNodeProps = (state, nodeID) => {
    return appPath(state, ['nodes', nodeID, 'props']);
};
/**
 * @returns main auth resource
 */
export const getAuthResourceDSL = createSelector(getAppDSL, appDSL => {
    var _a;
    const authResourceID = (_a = appDSL.settings) === null || _a === void 0 ? void 0 : _a.authResourceID;
    if (!authResourceID) {
        return null;
    }
    const authResource = resourceListSelectors.getResourceDSL(appDSL.resources, {
        resourceID: authResourceID,
    });
    if (authResource.type !== RESOURCE_TYPES.backendEightBase) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Auth must be relative to 8base backend`);
    }
    return authResource;
});
export const getAuthResourceEndpoint = createSelector(getAuthResourceDSL, authResourceDSL => {
    return resourceSelectors.getEightbaseResourceApiURL(authResourceDSL);
});
export const getAuthResourceCurrentAuthDSL = createSelector(getAuthResourceDSL, authResourceDSL => {
    var _a, _b, _c;
    const currentAuthID = (_a = authResourceDSL === null || authResourceDSL === void 0 ? void 0 : authResourceDSL.auth) === null || _a === void 0 ? void 0 : _a.currentAuthID;
    if (!currentAuthID) {
        return null;
    }
    return ((_c = (_b = authResourceDSL === null || authResourceDSL === void 0 ? void 0 : authResourceDSL.auth) === null || _b === void 0 ? void 0 : _b.authList) === null || _c === void 0 ? void 0 : _c[currentAuthID]) || null;
});
export const getAuthResourceAuthListDSL = createSelector(getAuthResourceDSL, authResourceDSL => {
    var _a;
    return ((_a = authResourceDSL === null || authResourceDSL === void 0 ? void 0 : authResourceDSL.auth) === null || _a === void 0 ? void 0 : _a.authList) || {};
});
export const getRouteHooksDSL = createSelector(getAppDSL, appDSL => {
    const { routeHooks } = appDSL;
    return routeHooks;
});
export const getContext = createSelector(getAppDSL, appDSL => {
    const { nodes } = appDSL;
    const [nodeWithContext] = values(nodes).filter(node => 'context' in node);
    return (nodeWithContext === null || nodeWithContext === void 0 ? void 0 : nodeWithContext.context) || {};
});
