/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import errorCodes from '@8base/error-codes';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError as createErrorLink } from '@apollo/client/link/error';
import fetch from 'cross-fetch';
import { RESOURCE_TYPES, } from '@builder/schemas';
import { CheckResourceApiHostValue } from '@builder/utils';
import { APP_ENGINE_AUDIT_SCOPES } from '../../app-audit';
import { useRuntimeErrorCallbacks } from '../../providers';
const is8BaseBackend = (type) => {
    return type === 'backend8base';
};
const getErrorLink = (onAppFunctionError, onAppAuditNotifications, state) => createErrorLink(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(graphQLError => {
            console.warn(`[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`);
            const errorCode = graphQLError.code;
            if (!(errorCode === errorCodes.ValidationErrorCode ||
                errorCode === errorCodes.NotAuthorizedErrorCode)) {
                onAppFunctionError(graphQLError);
                onAppAuditNotifications([
                    {
                        scope: APP_ENGINE_AUDIT_SCOPES.request,
                        type: 'error',
                        stateID: state.id,
                        propPath: ['editor', 'query'],
                        message: graphQLError.message,
                    },
                ]);
            }
        });
    }
    else if (networkError) {
        console.warn(`[Network error]: ${networkError}`);
        onAppFunctionError(networkError);
    }
});
const getResourceEndpoint = (resourceDSL, environment, workspaceID) => {
    if ((resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.type) !== RESOURCE_TYPES.backendEightBase) {
        return resourceDSL === null || resourceDSL === void 0 ? void 0 : resourceDSL.endpoint;
    }
    const apiUrl = CheckResourceApiHostValue(resourceDSL.api) || resourceDSL.api;
    const workspaceApiUrl = [apiUrl, workspaceID || resourceDSL.workspaceID].join('/');
    return resourceDSL.environment
        ? `${workspaceApiUrl}_${environment || resourceDSL.environment}`
        : workspaceApiUrl;
};
const getApolloClient = (resourceGraphqlDSL, onAppFunctionError, onAppAuditNotifications, state, transformedEndpoint, workspaceID, environment, type) => {
    const endpoint = getResourceEndpoint(resourceGraphqlDSL, environment, workspaceID);
    const errorLink = getErrorLink(onAppFunctionError, onAppAuditNotifications, state);
    const apolloLinks = ApolloLink.from([
        errorLink,
        createHttpLink({
            uri: is8BaseBackend(type) ? endpoint : transformedEndpoint,
            fetch,
        }),
    ]);
    return new ApolloClient({
        connectToDevTools: false,
        cache: new InMemoryCache(),
        link: apolloLinks,
    });
};
export const useGraphqlResource = (resourceGraphqlDSL, state, transformedEndpoint, workspaceID, environment, type) => {
    const { onAppFunctionError, onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const [apolloClient, setApolloClient] = useState(getApolloClient(resourceGraphqlDSL, onAppFunctionError, onAppAuditNotifications, state, transformedEndpoint, workspaceID, environment, type));
    const endpoint = getResourceEndpoint(resourceGraphqlDSL, environment, workspaceID);
    useEffect(() => {
        if (endpoint) {
            setApolloClient(getApolloClient(resourceGraphqlDSL, onAppFunctionError, onAppAuditNotifications, state, transformedEndpoint, workspaceID, environment, type));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceGraphqlDSL]);
    return apolloClient;
};
