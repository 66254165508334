import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialChipSettings } from './MaterialChip.settings';
export const MaterialChip = {
    name: COMPONENT_DSL_NAMES.MaterialChip,
    displayName: 'Chip',
    icon: 'chip',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@mui/material',
        componentName: 'Chip',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            props: {
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Chip',
                            },
                        },
                    ],
                },
                variant: 'filled',
                size: 'medium',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { children: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, avatar: { type: COMPONENT_DSL_PROP_TYPES.elementType }, clickable: { type: COMPONENT_DSL_PROP_TYPES.boolean }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, deleteIcon: { type: COMPONENT_DSL_PROP_TYPES.elementType }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, icon: { type: COMPONENT_DSL_PROP_TYPES.elementType }, label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, onDelete: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialChipSettings,
};
