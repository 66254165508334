import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { commonActionProps, commonBasicProps, commonStyleProps } from '../../common/commonProps';
import { BuilderComponentsPopperSettings } from './BuilderComponentsPopper.settings';
export const BuilderComponentsPopper = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsPopper,
    displayName: 'Popper',
    icon: 'popper',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Popper',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        mainPropPath: {
            selectNodeInside: ['target', 'children'],
            mainProp: 'Text',
        },
        predefineds: {
            props: {
                placement: 'bottom',
                target: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.div,
                            alias: 'Popper Target',
                            props: {
                                style: {
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                },
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                                            props: {
                                                color: 'primary',
                                                variant: 'contained',
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'Button',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                content: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialPaper,
                            nodeName: 'PopperContent',
                            alias: 'Popper Content',
                            schemaOverride: Object.assign({}, getSysMinSizeStyle({ display: null, minWidth: 72, minHeight: 36 })),
                        },
                    ],
                },
            },
        },
        passNodeAliasToProps: true,
        props: Object.assign(Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonActionProps), commonBasicProps), { target: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                required: true,
            }, content: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'popupState',
                        path: [0],
                    },
                ],
                required: true,
            }, forceOpen: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            }, placement: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: BuilderComponentsPopperSettings,
};
