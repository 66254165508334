import React, { useCallback } from 'react';

import {
  NodeDSL,
  nodeListSelectors,
  StateDSL,
  StateID,
  stateListSelectors,
  StateScopeTypes,
} from '@builder/schemas';

import { useAppDispatch, useNodeListDSL, useUIBuilderLeftPanel } from 'src/providers/ReduxProvider';
import { DASHBOARD_EVENTS, UI_BUILDER_EVENTS } from 'src/store';

import { useDashboardCurrentRouteContentNodeDSL } from './dashboardRouter';

export const VIEW_STATES = {
  list: 'list',
  item: 'item',
  itemCreate: 'item-create',
} as const;

export type ViewState =
  | { type: typeof VIEW_STATES.list; scope: StateScopeTypes }
  | { type: typeof VIEW_STATES.item; id: string; scope: StateScopeTypes }
  | { type: typeof VIEW_STATES.itemCreate; scope: StateScopeTypes };

type UseManagerReturnType<T extends StateDSL> = {
  isEditorDisplayed: boolean;
  selectedItem: T | undefined;
  setViewStateToCreateItem: (event?: React.MouseEvent) => void;
  setViewStateToItem: (id: string) => void;
  backToListView: () => void;
  selectItem: (id: string) => (event?: React.MouseEvent) => void;
  deleteItem: (id?: string | undefined) => void;
};

export const useStateListManager = <T extends StateDSL>(
  stateListDSL: Record<StateID, T>,
): UseManagerReturnType<T> => {
  const leftPanel = useUIBuilderLeftPanel();
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const currentRouteContentNodeDSL = useDashboardCurrentRouteContentNodeDSL() as NodeDSL;

  const setViewStateToItem = useCallback(
    (targetID: string) => {
      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          ...leftPanel,
          viewPanel: VIEW_STATES.item,
          targetID,
        },
      });
    },
    [leftPanel, send],
  );

  const setViewStateToCreateItem = useCallback(
    (event?: React.MouseEvent) => {
      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          ...leftPanel,
          viewPanel: VIEW_STATES.itemCreate,
        },
      });
    },
    [leftPanel, send],
  );

  const backToListView = useCallback(() => {
    send({
      type: UI_BUILDER_EVENTS.updateLeftPanel,
      leftPanel: {
        ...leftPanel,
        targetID: undefined,
        viewPanel: VIEW_STATES.list,
      },
    });
  }, [leftPanel, send]);

  const selectItem = useCallback(
    (id: string) => (event?: React.MouseEvent) => {
      const isSameOption = leftPanel.viewPanel === VIEW_STATES.item && leftPanel.targetID === id;

      isSameOption
        ? send({
            type: UI_BUILDER_EVENTS.updateLeftPanel,
            leftPanel: {
              ...leftPanel,
              targetID: undefined,
              viewPanel: VIEW_STATES.list,
            },
          })
        : setViewStateToItem(id);
    },
    [leftPanel, send, setViewStateToItem],
  );

  const deleteItem = useCallback(
    (id?: string) => {
      if (id) {
        const stateDSL = stateListSelectors.getStateDSL(stateListDSL, { id });
        const nodeWithConnectedState =
          stateDSL &&
          nodeListSelectors.getNodeWithConnectedState(nodeListDSL, {
            stateDSL,
          });

        send({
          type: DASHBOARD_EVENTS.stateRemove,
          id,
          connectedNodeID: nodeWithConnectedState?.id,
          connectedNodeParentID: currentRouteContentNodeDSL?.id,
          state: stateDSL,
        });
        backToListView();
      }
    },
    [stateListDSL, nodeListDSL, send, currentRouteContentNodeDSL?.id, backToListView],
  );

  const isEditorDisplayed =
    leftPanel.viewPanel === VIEW_STATES.item || leftPanel.viewPanel === VIEW_STATES.itemCreate;

  const selectedItem =
    leftPanel.viewPanel === VIEW_STATES.item ? stateListDSL[leftPanel.targetID ?? ''] : undefined;

  return {
    setViewStateToCreateItem,
    setViewStateToItem,
    backToListView,
    selectItem,
    deleteItem,
    isEditorDisplayed,
    selectedItem,
  };
};
