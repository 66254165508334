export const STYLE_GUIDE_NAMES = {
    MuiButton: 'MuiButton',
    MuiButtonGroup: 'MuiButtonGroup',
    MuiToggleButton: 'MuiToggleButton',
    MuiToggleButtonGroup: 'MuiToggleButtonGroup',
    MuiCheckbox: 'MuiCheckbox',
    MuiRadioGroup: 'MuiRadioGroup',
    MuiRating: 'MuiRating',
    MuiTabs: 'MuiTabs',
    MuiTab: 'MuiTab',
    MuiSwitch: 'MuiSwitch',
    MuiSlider: 'MuiSlider',
    MuiTypography: 'MuiTypography',
    MuiAutocomplete: 'MuiAutocomplete',
    MuiSelect: 'MuiSelect',
    MuiTextField: 'MuiTextField',
    MuiIconButton: 'MuiIconButton',
    MuiAccordion: 'MuiAccordion',
    MuiAvatar: 'MuiAvatar',
    MuiBadge: 'MuiBadge',
    MuiChip: 'MuiChip',
    MuiDivider: 'MuiDivider',
    MuiAlert: 'MuiAlert',
    MuiFab: 'MuiFab',
    MuiSpeedDial: 'MuiSpeedDial',
    MuiSnackbar: 'MuiSnackbar',
};
export const STYLE_GUIDE_ACTIONS = {
    editCSS: 'editCSS',
};
