import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_ACTION_TYPE, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { NS_ID } from '../constants';
export const getCommonActionListSettings = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onClickAction',
        label: 'On Click',
        keyPath: [...prefixPath, 'onClick'],
        children: getCommonActionSettings([...prefixPath, 'onClick']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseOverAction',
        label: 'On Mouse Over',
        keyPath: [...prefixPath, 'onMouseOver'],
        children: getCommonActionSettings([...prefixPath, 'onMouseOver']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseOutAction',
        label: 'On Mouse Out',
        keyPath: [...prefixPath, 'onMouseOut'],
        children: getCommonActionSettings([...prefixPath, 'onMouseOut']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseDownAction',
        label: 'On Mouse Down',
        keyPath: [...prefixPath, 'onMouseDown'],
        children: getCommonActionSettings([...prefixPath, 'onMouseDown']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseUpAction',
        label: 'On Mouse Up',
        keyPath: [...prefixPath, 'onMouseUp'],
        children: getCommonActionSettings([...prefixPath, 'onMouseUp']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseEnterAction',
        label: 'On Mouse Enter',
        keyPath: [...prefixPath, 'onMouseEnter'],
        children: getCommonActionSettings([...prefixPath, 'onMouseEnter']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onMouseLeaveAction',
        label: 'On Mouse Leave',
        keyPath: [...prefixPath, 'onMouseLeave'],
        children: getCommonActionSettings([...prefixPath, 'onMouseLeave']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onWheelAction',
        label: 'Mouse Wheel',
        keyPath: [...prefixPath, 'onWheel'],
        children: getCommonActionSettings([...prefixPath, 'onWheel']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onContextMenuAction',
        label: 'Context',
        keyPath: [...prefixPath, 'onContextMenu'],
        children: getCommonActionSettings([...prefixPath, 'onContextMenu']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onAuxClickAction',
        label: 'Aux Click',
        keyPath: [...prefixPath, 'onAuxClick'],
        children: getCommonActionSettings([...prefixPath, 'onAuxClick']),
    },
    ...getDraggableActionListSettings(prefixPath),
];
// TODO: add validation to show these events only when draggable prop its activate
const getDraggableActionListSettings = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onDragStart',
        label: 'On Drag Start',
        keyPath: [...prefixPath, 'onDragStart'],
        children: getCommonActionSettings([...prefixPath, 'onDragStart']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onDragOver',
        label: 'On Drag Over',
        keyPath: [...prefixPath, 'onDragOver'],
        children: getCommonActionSettings([...prefixPath, 'onDragOver']),
    },
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'onDrop',
        label: 'On Drop',
        keyPath: [...prefixPath, 'onDrop'],
        children: getCommonActionSettings([...prefixPath, 'onDrop']),
    },
];
export const getCommonActionSettings = (prefixPath = []) => [
    {
        componentView: COMPONENT_SETTING_VIEWS.select,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'actionType'],
        resetPathBeforeOnChange: [[...prefixPath, 'args']],
        label: 'Action',
        options: [
            {
                value: COMPONENT_SETTING_ACTION_TYPE.changePage,
                label: 'Navigate',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.runRequest,
                label: 'Run Request',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                label: 'Run Custom Code',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.functionCode,
                label: 'Run Function',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.updateState,
                label: 'Update State',
            },
            {
                value: COMPONENT_SETTING_ACTION_TYPE.closePopup,
                label: 'Close Popup',
                showIf: [
                    {
                        type: COMPONENT_SETTING_CONDITION_TYPES.hasAncestors,
                        value: [
                            COMPONENT_DSL_NAMES.BuilderComponentsMenu,
                            COMPONENT_DSL_NAMES.BuilderComponentsPopover,
                            COMPONENT_DSL_NAMES.BuilderComponentsPopper,
                        ],
                    },
                ],
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        label: 'Page',
        keyPath: [...prefixPath, 'args', 'url'],
        hasFxButton: true,
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.changePage,
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.functionDynamicSelect,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'args', 'function'],
        label: 'Function',
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.functionCode,
            },
        ],
    },
    {
        componentView: COMPONENT_SETTING_VIEWS.jsEditor,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: [...prefixPath, 'args', 'code'],
        hasFxButton: false,
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.customCode,
            },
        ],
        defaultValue: '// Your JavaScript code here',
        label: 'Custom Code',
    },
    {
        type: COMPONENT_SETTING_TYPES.requestSelector,
        keyPath: [...prefixPath, 'args', 'requestID'],
        label: 'Requests',
        name: 'requests',
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.runRequest,
            },
        ],
    },
    {
        type: COMPONENT_SETTING_TYPES.stateSelector,
        keyPath: [...prefixPath, 'args', 'stateArgs'],
        label: 'States',
        name: 'states',
        showIf: [
            {
                keyPath: [...prefixPath, 'actionType'],
                type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                value: COMPONENT_SETTING_ACTION_TYPE.updateState,
            },
        ],
    },
];
export const getEventsSection = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.eventsSection,
        name: NS_ID.events.events,
        title: 'Events',
        children: [...getCommonActionListSettings(prefixPath)],
    },
];
