import { isArray, isObject } from '@builder/utils';
const getTypeOrValue = (propValue) => {
    if (isObject(propValue)) {
        return 'object';
    }
    if (isArray(propValue)) {
        return 'array';
    }
    return propValue;
};
export const getSourceFunctionError = (propPath, errorMessage) => {
    return `${propPath} - ${errorMessage}`;
};
export const getRequiredPropError = ({ propPath }) => {
    return `${propPath} is required`;
};
export const getDisallowJSPropError = ({ propPath }) => {
    return `${propPath} can't contain JS injection`;
};
export const getUniqPropError = ({ propPath }) => {
    return `${propPath} must be unique`;
};
export const getUniqAliasError = ({ propPath }) => {
    return `${propPath} must be unique`;
};
export const getArrayPropError = ({ propValue, propPath }) => {
    return `${propPath} must be array, but got ${getTypeOrValue(propValue)}`;
};
export const getObjectPropError = ({ propValue, propPath }) => {
    return `${propPath} must be valid object, but got ${getTypeOrValue(propValue)}`;
};
export const getStylePropError = ({ propValue, propPath }) => {
    return `${propPath} must be valid object, but got ${getTypeOrValue(propValue)}`;
};
export const getNumberPropError = ({ propValue, propPath }) => {
    return `${propPath} must be number, but got ${getTypeOrValue(propValue)}`;
};
export const getNumberMaxValuePropError = ({ propValue, propPath, propDSL, }) => {
    const numberDSL = propDSL;
    return `${propPath} must be less than ${numberDSL.maxValue}, but got ${getTypeOrValue(propValue)}`;
};
export const getNumberMinValuePropError = ({ propValue, propPath, propDSL, }) => {
    const numberDSL = propDSL;
    return `${propPath} must be greater than ${numberDSL.minValue}, but got ${getTypeOrValue(propValue)}`;
};
export const getStringPropError = ({ propValue, propPath }) => {
    return `${propPath} must be string, but got ${getTypeOrValue(propValue)}`;
};
export const getBooleanPropError = ({ propValue, propPath }) => {
    return `${propPath} must be boolean, but got ${getTypeOrValue(propValue)}`;
};
export const getReactNodePropError = ({ propValue, propPath }) => {
    return `${propPath} must be valid react node, but got ${getTypeOrValue(propValue)}`;
};
export const getCSSPropError = ({ propValue, propPath }) => {
    return `${propPath} must be valid css, but got ${getTypeOrValue(propValue)}`;
};
export const getAssetPropError = ({ propValue, propPath }) => {
    return `${propPath} must be valid object, but got ${getTypeOrValue(propValue)}`;
};
export const getIteratorDataError = ({ iteratorData }) => {
    return `Iterator must be array, but got ${getTypeOrValue(iteratorData)}`;
};
export const getUniqueIdPropError = ({ propValue, propPath }) => {
    return `${propPath} must be a unique, ${propValue} is already used `;
};
export const getCustomPropKeyError = ({ propValue, propPath }) => {
    return `${propPath} must not override any other property, ${propValue} is already in use`;
};
export const getHTMLTGenericPropError = ({ propValue }) => {
    return `Don't use <html>, <head>, <body>, <script> <style> inside HTML code snippet, but your content got ${getTypeOrValue(propValue)}`;
};
export const getHTMLTagPropError = ({ propValue, propPath }) => {
    return `${propPath} must not contain <html></html> tag, but got ${getTypeOrValue(propValue)}`;
};
export const getHeadHTMLTagPropError = ({ propValue, propPath }) => {
    return `${propPath} must not contain <head></head> tag, but got ${getTypeOrValue(propValue)}`;
};
export const getBodyHTMLTagPropError = ({ propValue, propPath }) => {
    return `${propPath} must not contain <body></body> tag, but got ${getTypeOrValue(propValue)}`;
};
export const getScriptHTMLTagPropError = ({ propValue, propPath }) => {
    return `${propPath} must not contain <script></script> tag, but got ${getTypeOrValue(propValue)}`;
};
