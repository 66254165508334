import { MediaQueryDSL, CSS } from '@builder/schemas';
import { CSSParser, isNumericString } from '@builder/utils';

import { DashboardState } from 'src/store/common';

export const updateCSSMediaQuery = (
  dashboardState: DashboardState,
  mediaQuery: MediaQueryDSL,
): CSS => {
  const mediaQueriesCSS = dashboardState.appConfiguration.appDSL?.theme?.css?.mediaQueries || '';
  const prevMediaQuery =
    dashboardState.appConfiguration.appDSL.theme?.mediaQueries?.[mediaQuery.id];

  const isUpdatedMin = mediaQuery.min !== prevMediaQuery?.min;
  const isUpdatedMax = mediaQuery.max !== prevMediaQuery?.max;
  const hasMinInCurrentMediaQuery = isNumericString(mediaQuery.min ?? '');
  const hasMaxInCurrentMediaQuery = isNumericString(mediaQuery.max ?? '');
  const hasMinInPrevMediaQuery = isNumericString(prevMediaQuery?.max ?? '');
  const hasMaxInPrevMediaQuery = isNumericString(prevMediaQuery?.min ?? '');
  const isRemovedMin = hasMinInPrevMediaQuery && !hasMinInCurrentMediaQuery;
  const isRemovedMax = hasMaxInPrevMediaQuery && !hasMaxInCurrentMediaQuery;

  if (prevMediaQuery && mediaQueriesCSS) {
    return CSSParser.updateMediaQueryFeatures(
      mediaQueriesCSS,
      (mediaFeatures, currentMediaQuery) => {
        const hasMinInCSS = CSSParser.hasMediaFeature(mediaFeatures, {
          resolution: 'min-width',
          resolutionUnit: 'px',
          resolutionValue: prevMediaQuery.min,
        });
        const hasMaxInCSS = CSSParser.hasMediaFeature(mediaFeatures, {
          resolution: 'max-width',
          resolutionUnit: 'px',
          resolutionValue: prevMediaQuery.max,
        });

        // when updated media query has min and max value
        if (hasMinInCSS && hasMaxInCSS && hasMinInCurrentMediaQuery && hasMaxInCurrentMediaQuery) {
          return CSSParser.convertMediaFeaturesToMediaString(currentMediaQuery, [
            { resolutionValue: mediaQuery.min },
            { resolutionValue: mediaQuery.max },
          ]);
        }

        // when update only max value in media query
        if (
          !hasMinInCSS &&
          hasMaxInCSS &&
          hasMaxInCurrentMediaQuery &&
          isUpdatedMax &&
          !isRemovedMax
        ) {
          return CSSParser.convertMediaFeaturesToMediaString(currentMediaQuery, [
            { resolutionValue: mediaQuery.max },
          ]);
        }

        // when update only min value in media query
        if (
          !hasMaxInCSS &&
          hasMinInCSS &&
          hasMinInCurrentMediaQuery &&
          isUpdatedMin &&
          !isRemovedMin
        ) {
          return CSSParser.convertMediaFeaturesToMediaString(currentMediaQuery, [
            { resolutionValue: mediaQuery.min },
          ]);
        }

        // when updated media query not has min and has max value
        // we need add new media feature to start
        if (!hasMinInCSS && hasMaxInCSS && isUpdatedMin) {
          return CSSParser.addMediaFeature(currentMediaQuery, {
            resolution: 'min-width',
            resolutionUnit: 'px',
            resolutionValue: mediaQuery.min,
          });
        }

        // when updated media query not has max and has min value
        // we need ad new media feature to end
        if (!hasMaxInCSS && hasMinInCSS && isUpdatedMax) {
          return CSSParser.addMediaFeature(
            currentMediaQuery,
            {
              resolution: 'max-width',
              resolutionUnit: 'px',
              resolutionValue: mediaQuery.max,
            },
            false,
          );
        }

        // when we remove min value from media query
        // we need remove min media feature from CSS
        if (
          hasMinInCSS &&
          hasMaxInCSS &&
          hasMaxInCurrentMediaQuery &&
          !hasMinInCurrentMediaQuery &&
          isRemovedMin
        ) {
          const updatedMediaQuery = CSSParser.removeMediaFeature(currentMediaQuery, {
            resolution: 'min-width',
            resolutionUnit: 'px',
            resolutionValue: prevMediaQuery.min,
          });

          // if we remove min value and update max value in one time moment
          if (isUpdatedMax && hasMaxInCurrentMediaQuery) {
            return CSSParser.convertMediaFeaturesToMediaString(updatedMediaQuery, [
              { resolutionValue: mediaQuery.max },
            ]);
          }

          return updatedMediaQuery;
        }

        // when we remove max value from media query
        // we need remove media max media feature from CSS
        if (
          hasMinInCSS &&
          hasMaxInCSS &&
          hasMinInCurrentMediaQuery &&
          !hasMaxInCurrentMediaQuery &&
          isRemovedMax
        ) {
          const updatedMediaQuery = CSSParser.removeMediaFeature(currentMediaQuery, {
            resolution: 'max-width',
            resolutionUnit: 'px',
            resolutionValue: prevMediaQuery.max,
          });

          // if remove max value and update min value in one time moment
          if (isUpdatedMin && hasMinInCurrentMediaQuery) {
            return CSSParser.convertMediaFeaturesToMediaString(updatedMediaQuery, [
              { resolutionValue: mediaQuery.min },
            ]);
          }

          return updatedMediaQuery;
        }

        return currentMediaQuery;
      },
    );
  }

  return mediaQueriesCSS;
};
