import { MIGRATION_TYPES } from './constants';
export const createSuccessMigrationResult = (appConfiguration, info) => {
    return {
        type: MIGRATION_TYPES.success,
        appConfiguration,
        info,
    };
};
export const createErrorMigrationResult = ({ message, fromVersion, toVersion, }) => {
    return {
        type: MIGRATION_TYPES.error,
        message,
        fromVersion,
        toVersion,
    };
};
export const createWarningMigrationResult = ({ message, fromVersion, toVersion, }) => {
    return {
        type: MIGRATION_TYPES.warning,
        message,
        fromVersion,
        toVersion,
    };
};
