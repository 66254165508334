import { keys, values } from 'ramda';

import { StateDSL, StateListDSL } from '@builder/schemas';
import { isUndefined } from '@builder/utils';

import { ARRAY, OBJECT } from './constants';
import { objectsContainArrays } from './objectsContainArrays';
import { objectsContainObjects } from './objectsContainObjects';
import { GlobalAndLocalStates, MetaStates } from './types';

export const getMetadataValues = (
  states: StateListDSL,
  appRuntimeStates: GlobalAndLocalStates,
  keyValue: string,
): MetaStates =>
  values(states).reduce((accum, stateObjectType) => {
    const appRuntimeState = appRuntimeStates[stateObjectType.name];

    if (isUndefined(appRuntimeState)) return accum;

    const statesContainArrays = objectsContainArrays(
      values(appRuntimeState[keyValue as keyof typeof appRuntimeState]) as StateDSL[],
      keys(appRuntimeState[keyValue as keyof typeof appRuntimeState] as string),
    );
    const statesContainObjects = objectsContainObjects(
      values(appRuntimeState[keyValue as keyof typeof appRuntimeState]) as StateDSL[],
      keys(appRuntimeState[keyValue as keyof typeof appRuntimeState] as string),
    );

    return {
      ...accum,
      [stateObjectType.name]: {
        id: stateObjectType.id,
        name: stateObjectType.name,
        type: OBJECT,
        value:
          stateObjectType.type === ARRAY
            ? appRuntimeStates[stateObjectType.name].value || []
            : null,
        children: {
          defaultValue: {
            ...statesContainArrays,
            ...statesContainObjects,
          },
        },
      },
    };
  }, {});
