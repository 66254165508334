var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { forwardRef, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box } from '@mui/material';
// @ts-ignore
import CKEDITOR from 'ckeditor5-custom';
export const RichtextEditor = forwardRef((_a, ref) => {
    var { editorType = 'Classic', data, id, config, disabled, style, onError, onReady, onChange, onFocus, onBlur } = _a, rest = __rest(_a, ["editorType", "data", "id", "config", "disabled", "style", "onError", "onReady", "onChange", "onFocus", "onBlur"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const internalId = useMemo(() => Math.random().toString(36).substr(2, 9), [style]);
    return (_jsxs(Box, Object.assign({ ref: ref, style: style }, rest, { id: id }, { children: [editorType === 'Document' && _jsx("div", { id: `toolbar-${internalId}` }), _jsx(CKEditor, { editor: CKEDITOR[editorType], id: `editor-${editorType}-${JSON.stringify(style)}-${JSON.stringify(config)}`, config: config !== null && config !== void 0 ? config : {}, disabled: disabled, data: data, onReady: editor => {
                    const toolbarContainer = document.querySelector(`#toolbar-${internalId}`);
                    if (toolbarContainer) {
                        toolbarContainer.innerHTML = '';
                        // @ts-ignore
                        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                    }
                    editor.editing.view.change(writer => {
                        if (style === null || style === void 0 ? void 0 : style.height) {
                            const parseHeight = parseInt(String(style === null || style === void 0 ? void 0 : style.height).replaceAll(/\D/g, ''), 10);
                            writer.setStyle('height', `${parseHeight <= 38 ? parseHeight : parseHeight - 38}px`, 
                            // @ts-ignore
                            editor.editing.view.document.getRoot());
                        }
                    });
                    onReady === null || onReady === void 0 ? void 0 : onReady(editor);
                }, onChange: (event, editor) => onChange === null || onChange === void 0 ? void 0 : onChange(event, editor), onBlur: (event, editor) => onBlur === null || onBlur === void 0 ? void 0 : onBlur(event, editor), onFocus: (event, editor) => onFocus === null || onFocus === void 0 ? void 0 : onFocus(event, editor), onError: (error, detail) => onError === null || onError === void 0 ? void 0 : onError(Object.assign(Object.assign({}, error), detail)) })] })));
});
