export const MESSAGES = {
  stateManager: {
    leftPanel: {
      title: 'State',
      titleDescription: 'State',
      subtitleDescription:
        'Catalog of variables that can be accessed while implementing application logic.',
      stateManagerTabs: {
        global: {
          label: 'Global',
          description: 'State elements that are available through the whole application.',
        },
        local: {
          label: 'Local',
          description: 'State elements available only in the current page.',
        },
      },
    },
  },
  assetsManager: {
    leftPanel: {
      title: 'Assets',
      titleDescription: 'Assets',
      subtitleDescription:
        'Add and manage static assets, like images,  to be used in the web application.',
      emptyState: {
        title: 'No Assets',
        description: 'Manage static assets, like images, to be used in the web app application.',
      },
    },
  },
  functionsManager: {
    leftPanel: {
      title: 'Functions',
      titleDescription: 'Functions',
      subtitleDescription:
        'Application logic wrapped in functions so it can be consumed from multiple places through the application.',
    },
    functionsList: {
      global: {
        description: 'Functions accessible through the whole application.',
      },
      local: {
        description: 'Functions accessible on the current page.',
      },
      emptyState: {
        description: {
          global: 'Write your custom JavaScript code and invoke it anywhere in the app.',
          local: 'Write your custom JavaScript code and invoke it within specific page.',
        },
      },
    },
  },
  pagesManager: {
    leftPanel: {
      title: 'Pages',
      titleDescription: 'Pages',
      subtitleDescription:
        'Manage the web app pages and optionally manage layouts with common components.',
      layoutList: {
        title: 'Layouts',
        titleDescription: 'Layouts',
        subtitleDescription: 'Create or edit layouts to organize common elements used in pages.',
      },
      pageList: {
        title: 'Pages',
        titleDescription: 'Pages',
        subtitleDescription: 'Add pages to the web app or manage existing ones.',
      },
      dialogs: {
        title: 'Global Dialogs',
        titleDescription: 'Dialogs',
        subtitleDescription: 'Global dialogs available to be used in the whole application.',
      },
    },
  },
  pageStructure: {
    leftPanel: {
      title: 'Page Structure',
      titleDescription: 'Page Structure',
      subtitleDescription: 'Hierarchical representation of the components from the current page.',
    },
  },
  requestsManager: {
    leftPanel: {
      title: 'Requests',
      titleDescription: 'Requests',
      subtitleDescription: 'Implement interactions with resources services.',
    },
    requestForm: {
      transformer: {
        title: 'Transformer',
        titleDescription: 'Transformer',
        subtitleDescription:
          'It transforms the client request response before they reach the upstream server.',
      },
      callbacks: {
        title: 'Callbacks',
        titleDescription: 'Callbacks',
        subtitleDescription:
          'Executes instructions when the request returns an error or completes successfully.',
      },
      variables: {
        guidanceText:
          'Only declare these variables if you intend to assign value. No need to declare variables if you are going to execute this request via .run({variables: {}})',
        tooltip: {
          global: {
            title: 'Global Request',
            description: 'This type of Request can reference global states only.',
          },
          local: {
            title: 'Local Request',
            description: 'This type of Request can reference both global and local states.',
          },
        },
      },
      triggerManually: {
        title: 'Trigger Manually',
        description: `When enabled, explicitly execute the request in response to events or using the .run()  method.

        Keep it enabled if you want this request to appear in the "Run Request" event dropdown.`,
      },
      error: {
        authotization: {
          title: 'Authentication Error',
          message: 'You must add an authorization header in order to use this request.',
          code: {
            NotAuthorizedError: 'NotAuthorizedError',
            InvalidTokenError: 'InvalidTokenError',
          },
        },
      },
    },
    requestGroupList: {
      global: {
        description: 'Requests available throughout the whole application.',
      },
      local: {
        description: 'Requests available on the current page.',
      },
      emptyState: {
        description: {
          global:
            'Define global requests you want to run when your application is rendered. You can access the result object anywhere in the app.',
          local:
            'Define local requests you want to run when a specific component is rendered. You can access the result object in the component and its children.',
        },
      },
    },
    hiddenRequestResponse: {
      title: 'Hidden Response',
      description:
        'Response intentionally hidden, because "Ignore Results" option enabled. Disable it to see the response.',
    },
    operationType: {
      lazyQuery:
        'Lazy Queries are executed explicitly in response to events or by using the method .run()',
      query: {
        local:
          'Queries are executed when the page loads. You can execute it again using events or the method .run()',
        global:
          'Queries are executed when the application starts. You can execute it again using events or the method .run()',
      },
    },
  },
  resourcesManager: {
    deleteResource: {
      error: "This resource has associated requests and can't be removed",
      remove: 'Remove this resource',
    },
  },
  nodeSettings: {
    settingTypes: {
      eventsSections: {
        defaultTitle: 'Events',
        defaultSettingLabel: 'No description',
        description: 'Define what the application should do on specific events for this component.',
      },
    },
    swichTypes: {
      code: 'Code Format',
      state: 'State Format',
      literal: 'Literal Format',
      pages: 'App Pages',
    },
    events: {
      runRequest: {
        tooltip: {
          title: 'Run Request',
          description: `It only executes manual Requests:
          -Requests with "trigger manually' enabled.
          -Lazy Queries.`,
        },
      },
    },
  },
  dashboard: {
    groupList: {
      emptyState: {
        title: 'No Group Components',
        description:
          'Create Group Components you want to reuse between different parts of the app from the component context menu. When you make a change to the group component, changes will be reflected in all instances.',
      },
    },
    componentList: {
      common: {
        titleDescription: 'Commonly used components',
        subtitleDescription: 'These components are the most used by App Builder users.',
      },
      layout: {
        titleDescription: 'Layouts',
        subtitleDescription:
          'These components are used to organize other components within a page.',
      },
      form: {
        titleDescription: 'Forms',
        subtitleDescription: 'These are input components to be used inside a Form Block.',
      },
      html: {
        titleDescription: 'HTML',
        subtitleDescription: 'This is a set of HTML primitive components.',
      },
      basic: {
        titleDescription: 'Basic',
        subtitleDescription: 'This is a set of HTML primitive components.',
      },
      controls: {
        titleDescription: 'Controls',
        subtitleDescription:
          'These are independent components used to capture data and provide buttons.',
      },
      data: {
        titleDescription: 'Data',
        subtitleDescription:
          'These components are utilities used to create sets of components based on data arrays.',
      },
      other: {
        titleDescription: 'Other',
        subtitleDescription: 'Additional components for general purpose.',
      },
      emptyState: {
        title: 'No matching components',
      },
    },
  },
  routeSettings: {
    route: {
      titleDescription: 'Route',
      subtitleDescription: 'Define the page name and path to access it.',
    },
    pageLayout: {
      titleDescription: 'Page layout',
      subtitleDescription: 'An optional layout with common elements.',
    },
    title: {
      titleDescription: 'Page Title',
      subtitleDescription: 'Define the title of the page.',
    },
    metaTags: {
      titleDescription: 'HTML Meta Tags',
      subtitleDescription: 'Define the meta tags included in the page.',
    },
    metaData: {
      titleDescription: 'Metadata',
      subtitleDescription: 'Set of key-value pairs with attributes to further describe the page.',
    },
    defaultPathParameters: {
      titleDescription: 'Default Path parameters',
      subtitleDescription:
        'Optional values for the route parameters, used during preview (it has no effect on deployed app).',
    },
    LocalNavigationEvents: {
      titleDescription: 'Local Navigation Events',
      subtitleDescription: 'Add business logic to this page’s lifecycle events.',
    },
  },
  emptySate: {
    defaultTitle: 'No elements in state',
    defaultDescriptionAddButton: 'Use the "+" above to add them.',
  },
  validation: {
    nameMustBeUnique: 'Name must be unique in DSL',
  },
  components: {
    actionPopper: {
      findInPageStructure: 'Find in Page Structure',
      openProperties: 'Open Properties pane',
      openStyles: 'Open Styles pane',
      openEvents: 'Open Events pane',
      createGroup: 'Create reusable component',
      clone: 'Clone this component',
      copyToBuffer: 'Copy this component',
      applyCopyBuffer: 'Paste copied component',
      delete: 'Delete component',
      copyStyle: 'Copy style',
      pasteStyle: 'Paste style',
      moveUp: 'Move Up',
      moveDown: 'Move Down',
    },
  },
  dialogs: {
    brokenLoadedDSLDialog: {
      title: 'DSL Conflict Detected',
      body: `We've detected a conflict in your current DSL that could impact your project. To
      keep everything running smoothly, we've prevented further processes. Our support
      team is already addressing this issue. Feel free to contact us for any additional
      information or support you may need.`,
    },
    preventBreakingDSLDialog: {
      title: 'Action Prevented',
      body: `We prevented that last action as we believed it might affect the integrity of the
      project. Our support team had been notified.`,
    },
    lostConnectionDialog: {
      title: 'Connection Lost',
      subTitle: 'Looks like you’re offline',
      body: 'Please check your internet connection and try again.',
    },
  },
  deploy: {
    noHaveDeployMessage:
      'Custom Domain, SSL Certificate and CDN options will be available after you have deployed your app.',
    notifyWhenEnd: 'We will notify you when deployment is complete.',
    inProgress: 'Deployment In Progress',
    notDeployment: 'Not deployed yet',
    failDeploymentDescription: `Publishing failed, please contact support for assistance`,
    failDeploymentTitle: 'Project not deployed',
    successDeploymentTitle: 'Project deployed',
  },
  preview: {
    loading: {
      title: 'Generating App Preview',
      description: 'This will only take a moment...',
    },
  },
} as const;
