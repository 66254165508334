var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable no-new-func */
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { matchPath, useHistory } from 'react-router-dom';
import { stateListSelectors } from '@builder/schemas';
import { isJsonString } from '@builder/utils';
import { Route } from '../Route/Route';
import { RouteHookContext } from '../RouterHookProvider';
const DEFAULT_HOOK_CODE = 'return function(){}';
const HOOK_TYPES = {
    code: 'code',
    request: 'request',
    function: 'function',
};
const SESSION_STORAGE_KEY = 'previousLocation';
const areObjectsDifferent = (obj1, obj2) => {
    const params1 = JSON.stringify(obj1.params);
    const params2 = JSON.stringify(obj2.params);
    const queryString1 = JSON.stringify(obj1.queryString);
    const queryString2 = JSON.stringify(obj2.queryString);
    return params1 !== params2 || queryString1 !== queryString2;
};
const getRouteParams = ({ pattern, location }) => {
    const match = matchPath(location.pathname, { path: pattern });
    const currentParams = match === null || match === void 0 ? void 0 : match.params;
    const currentFragments = location.hash.slice(1);
    const urlSearchQueryString = new URLSearchParams(location.search);
    const urlSearchQueryStringParams = Object.fromEntries(urlSearchQueryString.entries());
    return {
        params: currentParams,
        fragment: currentFragments,
        queryString: urlSearchQueryStringParams,
    };
};
const useGlobalRouterHooks = (path) => {
    const [data, setCallback] = useState({});
    const routerHistory = useHistory();
    const [waitingHooks, setWaitingHooks] = useState(true);
    const { globalHooks, location: currentLocation, localHooks, runRequest, createFunction, state, stateList, currentRoute, isMounted, setMounted, } = useContext(RouteHookContext);
    const states = stateListSelectors.getFunctionStateListDSL(stateList);
    const getHookBody = (code) => {
        var _a, _b;
        const wrapperRegex = /return\s+function\s*\(\s*\{[^}]*\}\s*\)\s*{\s*([\s\S]*)\s*}/;
        const wrapperRegexAsync = /return async\s+function\s*\(\s*\{[^}]*\}\s*\)\s*{\s*([\s\S]*)\s*}/;
        return (_b = (_a = code === null || code === void 0 ? void 0 : code.replace(wrapperRegex, '$1')) === null || _a === void 0 ? void 0 : _a.replace(wrapperRegexAsync, '$1')) !== null && _b !== void 0 ? _b : code;
    };
    const runCodeLocalHooks = ({ to, from, type, }) => {
        var _a, _b;
        const code = getHookBody((_b = (_a = localHooks[type]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : '');
        return createFunction(code, { from, to });
    };
    // TODO Find way to pass values to function
    const runFunctionLocalHooks = ({ to, from, type, }) => {
        var _a, _b, _c;
        /// TODO: fix the code im sending to runFunction, need to check if is either code or or bodyFunction
        return createFunction(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ((_c = states[(_b = (_a = localHooks[type]) === null || _a === void 0 ? void 0 : _a.function) !== null && _b !== void 0 ? _b : '']) === null || _c === void 0 ? void 0 : _c.bodyFunction) || DEFAULT_HOOK_CODE, { from, to });
    };
    const runCodeGlobalHooks = ({ to, from, type, }) => {
        var _a, _b;
        /// TODO: fix the code im sending to runFunction, need to check if is either code or or bodyFunction
        const code = getHookBody((_b = (_a = globalHooks[type]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : '');
        return createFunction(code, { from, to });
    };
    const runFunctionGlobalHooks = ({ to, from, type, }) => {
        var _a, _b, _c;
        /// TODO: fix the code im sending to runFunction, need to check if is either code or or bodyFunction
        return createFunction(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ((_c = states[(_b = (_a = globalHooks[type]) === null || _a === void 0 ? void 0 : _a.function) !== null && _b !== void 0 ? _b : '']) === null || _c === void 0 ? void 0 : _c.bodyFunction) || DEFAULT_HOOK_CODE, { from, to });
    };
    const runRequestDebounce = useCallback((requestID) => {
        const request = debounce(() => runRequest(requestID), 500);
        request();
    }, [runRequest]);
    useEffect(() => {
        var _a;
        const parsedCurrentLocation = currentLocation ? JSON.parse(currentLocation) : '';
        const prevLocation = (_a = window.sessionStorage) === null || _a === void 0 ? void 0 : _a.getItem(SESSION_STORAGE_KEY);
        const previousLocationWithParams = prevLocation ? JSON.parse(prevLocation) : '';
        const skipHooks = !parsedCurrentLocation ||
            FilterPathLayout(path) ||
            isMounted ||
            (path === null || path === void 0 ? void 0 : path[0]) !== parsedCurrentLocation.path;
        if (skipHooks)
            return;
        const shouldLoadHooks = !parsedCurrentLocation || FilterPathLayout(path) || isMounted;
        if (shouldLoadHooks)
            return;
        setMounted(true);
        const currentLocationWithParams = Object.assign(Object.assign({}, parsedCurrentLocation), getRouteParams({
            pattern: parsedCurrentLocation.path,
            location: routerHistory.location,
        }));
        window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(currentLocationWithParams));
        let mustStopBeforeExit = false;
        setWaitingHooks(false);
        setCallback({
            runBeforeEach: () => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                switch ((_a = globalHooks === null || globalHooks === void 0 ? void 0 : globalHooks.beforeEach) === null || _a === void 0 ? void 0 : _a.type) {
                    case HOOK_TYPES.code:
                        (_b = runCodeGlobalHooks({
                            from: previousLocationWithParams,
                            to: currentLocationWithParams,
                            stateCode: state,
                            type: 'beforeEach',
                        })) === null || _b === void 0 ? void 0 : _b();
                        break;
                    case HOOK_TYPES.request:
                        runRequestDebounce(((_c = globalHooks.beforeEach) === null || _c === void 0 ? void 0 : _c.request) || '');
                        break;
                    case HOOK_TYPES.function:
                        (_d = runFunctionGlobalHooks({
                            from: previousLocationWithParams,
                            to: currentLocationWithParams,
                            stateCode: state,
                            type: 'beforeEach',
                        })) === null || _d === void 0 ? void 0 : _d();
                        break;
                    default:
                        break;
                }
                switch ((_e = localHooks === null || localHooks === void 0 ? void 0 : localHooks.beforeRouteEnter) === null || _e === void 0 ? void 0 : _e.type) {
                    case HOOK_TYPES.code:
                        (_f = runCodeLocalHooks({
                            from: previousLocationWithParams,
                            to: currentLocationWithParams,
                            stateCode: state,
                            type: 'beforeRouteEnter',
                        })) === null || _f === void 0 ? void 0 : _f();
                        break;
                    case HOOK_TYPES.request:
                        runRequestDebounce(((_g = localHooks.beforeRouteEnter) === null || _g === void 0 ? void 0 : _g.request) || '');
                        break;
                    case HOOK_TYPES.function:
                        (_h = runFunctionLocalHooks({
                            from: previousLocationWithParams,
                            to: currentLocationWithParams,
                            stateCode: state,
                            type: 'beforeRouteEnter',
                        })) === null || _h === void 0 ? void 0 : _h();
                        break;
                    default:
                        break;
                }
                setWaitingHooks(true);
            },
            runAfterEach: () => {
                var _a, _b, _c, _d;
                switch ((_a = globalHooks === null || globalHooks === void 0 ? void 0 : globalHooks.afterEach) === null || _a === void 0 ? void 0 : _a.type) {
                    case HOOK_TYPES.code:
                        (_b = runCodeGlobalHooks({
                            from: previousLocationWithParams,
                            to: currentLocationWithParams,
                            stateCode: state,
                            type: 'afterEach',
                        })) === null || _b === void 0 ? void 0 : _b();
                        break;
                    case HOOK_TYPES.request:
                        runRequestDebounce(((_c = globalHooks.afterEach) === null || _c === void 0 ? void 0 : _c.request) || '');
                        break;
                    case HOOK_TYPES.function:
                        (_d = runFunctionGlobalHooks({
                            from: previousLocationWithParams,
                            to: currentLocationWithParams,
                            stateCode: state,
                            type: 'afterEach',
                        })) === null || _d === void 0 ? void 0 : _d();
                        break;
                    default:
                        break;
                }
            },
        });
        const unListen = routerHistory.listen(locationChanged => {
            var _a, _b, _c, _d;
            const isSamePath = !!matchPath(locationChanged.pathname, {
                path: parsedCurrentLocation === null || parsedCurrentLocation === void 0 ? void 0 : parsedCurrentLocation.path,
                exact: true,
            });
            const actualLocation = Object.assign(Object.assign({}, parsedCurrentLocation), getRouteParams({
                pattern: parsedCurrentLocation.path,
                location: locationChanged,
            }));
            const prevUpdateLocation = window.sessionStorage
                ? window.sessionStorage.getItem(SESSION_STORAGE_KEY)
                : actualLocation;
            const parsedPrevUpdateLocation = prevUpdateLocation && isJsonString(prevUpdateLocation)
                ? JSON.parse(prevUpdateLocation)
                : undefined;
            const sameRouteWithDifferentParams = isSamePath &&
                parsedPrevUpdateLocation &&
                areObjectsDifferent(actualLocation, parsedPrevUpdateLocation);
            if (sameRouteWithDifferentParams) {
                window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(actualLocation));
                switch ((_a = localHooks === null || localHooks === void 0 ? void 0 : localHooks.beforeRouteUpdate) === null || _a === void 0 ? void 0 : _a.type) {
                    case HOOK_TYPES.code:
                        (_b = runCodeLocalHooks({
                            from: parsedPrevUpdateLocation,
                            to: actualLocation,
                            stateCode: state,
                            type: 'beforeRouteUpdate',
                        })) === null || _b === void 0 ? void 0 : _b();
                        break;
                    case HOOK_TYPES.request:
                        runRequestDebounce(((_c = localHooks === null || localHooks === void 0 ? void 0 : localHooks.beforeRouteUpdate) === null || _c === void 0 ? void 0 : _c.request) || '');
                        break;
                    case HOOK_TYPES.function:
                        (_d = runFunctionLocalHooks({
                            from: parsedPrevUpdateLocation,
                            to: actualLocation,
                            stateCode: state,
                            type: 'beforeRouteUpdate',
                        })) === null || _d === void 0 ? void 0 : _d();
                        break;
                    default:
                        break;
                }
                if (!isSamePath) {
                    unListen();
                }
                mustStopBeforeExit = true;
            }
        });
        return () => {
            var _a, _b, _c, _d, _e, _f;
            if (isMounted)
                return;
            switch ((_b = (_a = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props) === null || _a === void 0 ? void 0 : _a.routerHooks) === null || _b === void 0 ? void 0 : _b.beforeRouteExit) {
                case HOOK_TYPES.code:
                    (_c = runCodeLocalHooks({
                        from: previousLocationWithParams,
                        to: currentLocationWithParams,
                        stateCode: state,
                        type: 'beforeRouteExit',
                    })) === null || _c === void 0 ? void 0 : _c();
                    break;
                case HOOK_TYPES.request:
                    runRequestDebounce(((_e = (_d = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.props) === null || _d === void 0 ? void 0 : _d.routerHooks) === null || _e === void 0 ? void 0 : _e.beforeRouteExitRequestID) || '');
                    break;
                case HOOK_TYPES.function:
                    (_f = runFunctionLocalHooks({
                        from: previousLocationWithParams,
                        to: currentLocationWithParams,
                        stateCode: state,
                        type: 'beforeRouteExit',
                    })) === null || _f === void 0 ? void 0 : _f();
                    break;
                default:
                    break;
            }
            setWaitingHooks(true);
            if (mustStopBeforeExit)
                mustStopBeforeExit = false;
            unListen();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation]);
    return { data, waitingHooks };
};
export const Content = (props) => {
    const { data } = props;
    useEffect(() => {
        data.runBeforeEach();
        data.runAfterEach();
    }, [data]);
    return _jsx(Route, Object.assign({}, props));
};
export const FilterPathLayout = (path) => {
    return path.some((item) => item.includes('/__layouts'));
};
export const RouteHook = forwardRef((_a, ref) => {
    var props = __rest(_a, []);
    const { data, waitingHooks } = useGlobalRouterHooks(props.path);
    if (waitingHooks) {
        return _jsx(Route, Object.assign({}, props));
    }
    return _jsx(Content, Object.assign({}, props, { data: data }));
});
