import { Migration } from '../Migration';
class Migration_0_18_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.4';
    }
    getChangeList() {
        return ['Updating toggle button group schema'];
    }
    migrateAppDSL(appDSL) {
        const filteredNodes = Object.values(appDSL.nodes).filter((node) => {
            return node.name === 'MaterialToggleButtonGroup';
        });
        if (filteredNodes.length === 0) {
            return appDSL;
        }
        const nodes = Object.assign({}, appDSL.nodes);
        filteredNodes.forEach((parentNode) => {
            if ('children' in parentNode.props) {
                const { children } = parentNode.props;
                if (Array.isArray(children.nodes)) {
                    children.nodes.forEach((childId) => {
                        if (typeof childId === 'string') {
                            if (nodes[childId] && 'label' in nodes[childId].props) {
                                delete nodes[childId].props.label;
                            }
                        }
                    });
                }
            }
        });
        return Object.assign(Object.assign({}, appDSL), { nodes });
    }
}
export const migration_0_18_4 = new Migration_0_18_4();
