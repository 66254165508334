var _a, _b;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { ListItemSymbol } from '../../composite-components/ListItemSymbol';
import { MaterialListSettings } from './MaterialList.settings';
export const MaterialList = {
    name: COMPONENT_DSL_NAMES.MaterialList,
    displayName: 'List',
    icon: 'list',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@mui/material',
        componentName: 'List',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'alias',
        },
        predefineds: {
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.ListItemSymbol,
                            props: Object.assign(Object.assign({}, (_a = ListItemSymbol.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { listItemText: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                            props: {
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'List Item 1',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                } }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.ListItemSymbol,
                            props: Object.assign(Object.assign({}, (_b = ListItemSymbol.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { listItemText: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialListItemText,
                                            props: {
                                                children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'List Item 2',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { dense: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disablePadding: { type: COMPONENT_DSL_PROP_TYPES.boolean }, subheader: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.MaterialListItem,
                    COMPONENT_DSL_NAMES.MaterialListItemButton,
                    COMPONENT_DSL_NAMES.ListItemSymbol,
                    COMPONENT_DSL_NAMES.NavListItemSymbol,
                ],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: MaterialListSettings,
};
