import { useCallback } from 'react';

import { Grid } from '@mui/material';
import { matchPath } from 'react-router-dom';

import {
  componentSettingSelectors,
  ComponentSettingRequestSelectorDSL,
  stateListSelectors,
  resourceSelectors,
  NodePropValue,
  resourceListSelectors,
  ResourceListDSL,
  StateRequestDSL,
  nodeListSelectors,
  NodeRouteDSL,
  NodeID,
} from '@builder/schemas';
import { memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { Autocomplete } from 'src/shared/components';
import { useDashboardRouter } from 'src/shared/hooks';

type RequestSelectorTypeProps = {
  setting: ComponentSettingRequestSelectorDSL;
  dataTestPrefix?: string;
  /**
   * The value that is stored in the DSL by the corresponding keyPath.
   */
  keyValue: NodePropValue | null;
  /**
   * Callback to update keyValue.
   */
  onChange: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
};

const getStateOption = (resources: ResourceListDSL) => (stateDSL: StateRequestDSL) => {
  if (!stateDSL.resourceID) {
    return { value: stateDSL.id, label: stateDSL.name };
  }

  const { resourceID } = stateDSL;
  const resourceDSL = resourceListSelectors.getResourceDSL(resources, {
    resourceID,
  });
  const resourceName = resourceSelectors.getResourceTitle(resourceDSL);
  const label = `${stateDSL.name} (${resourceName})`;
  return { value: stateDSL.id, label };
};

export const RequestSelectorType = memo(
  'RequestSelectorType',
  ({ setting, dataTestPrefix, keyValue, onChange }: RequestSelectorTypeProps): JSX.Element => {
    const { selectedNodeDSL, appDSL } = useNodeSettingsProps();
    const dashboardRouterState = useDashboardRouter();
    const allRouteNodes = nodeListSelectors.getAllRouteNodesIncludeSystem(appDSL.nodes);
    const currentRouteNodeDSL = allRouteNodes.find(route =>
      matchPath(dashboardRouterState.currentRoute, {
        path: route.props.path as string,
        exact: true,
      }),
    ) as NodeRouteDSL<NodeID>;

    const stateArrayDSL = stateListSelectors.getAccessibleRunnableQueryWithScopeArrayDSL(
      { ...appDSL.states, ...currentRouteNodeDSL.context },
      {
        nodeListDSL: appDSL.nodes,
        nodeID: selectedNodeDSL.id,
      },
    );

    const keyName = componentSettingSelectors.getSettingsKeyNamePath(setting);

    const onChangeState = useCallback(
      (newStateID?: string) => {
        const keyPath = componentSettingSelectors.getSettingsKeyPath(setting);

        if (!newStateID) {
          onChange({
            keyValue: undefined,
            keyPath,
          });
          return;
        }

        const newNodeStatesConnection = stateArrayDSL?.find(
          ({ id: stateID }) => stateID === newStateID,
        );

        if (newNodeStatesConnection) {
          onChange({
            keyValue: newStateID,
            keyPath,
          });
        }
      },
      [onChange, setting, stateArrayDSL],
    );

    const stateListOptions = stateArrayDSL.map(state => getStateOption(appDSL.resources)(state));
    const dataTest = `${dataTestPrefix}.${keyName}`;

    return (
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          value={keyValue as string}
          label={setting.label}
          onChange={onChangeState}
          options={stateListOptions}
          data-test={dataTest}
        />
      </Grid>
    );
  },
);
