import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, getBasicPropsSettings } from '../../common';
import { getStyleSections, getEventsSection, getPropertySections, getDivider, } from '../../common/settings';
export const MaterialMenuItemSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['value'],
                            label: 'Value',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            label: 'Label',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.iterator,
                            dataLabel: 'Loop Data',
                            itemNameLabel: 'Item Name',
                            name: 'loop',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
