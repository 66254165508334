import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsRichtextEditor } from '../../material-components/BuilderComponentsRichtextEditor';
import { BuilderComponentsFormRichtextEditorSettings } from './BuilderComponentsFormRichtextEditor.settings';
export const BuilderComponentsFormRichtextEditor = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormRichtextEditor,
    displayName: 'Form Rich Text Editor',
    icon: 'richtexteditor',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormRichtextEditor',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'FormRichtextEditor',
                    defaultValue: '',
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormRichtextEditor',
                },
                richTextProps: {
                    value: '{{ __STATES__.FormRichtextEditor.value }}',
                    defaultValue: '',
                    readOnly: false,
                },
                editorType: 'Classic',
                data: '{{ __STATES__.FormRichtextEditor.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FormRichtextEditor.setValue(editor.getData())',
                    },
                },
                style: getPredefinedStyles({ height: 200 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { editorType: { type: COMPONENT_DSL_PROP_TYPES.string }, stateName: { type: COMPONENT_DSL_PROP_TYPES.any }, data: { type: COMPONENT_DSL_PROP_TYPES.string }, richTextProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsRichtextEditor.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            }, onReady: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'editor',
                        path: [0],
                    },
                ],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'editor',
                        path: [1],
                    },
                ],
            }, onFocus: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'editor',
                        path: [1],
                    },
                ],
            }, onBlur: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'editor',
                        path: [1],
                    },
                ],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'error',
                        path: [0],
                    },
                ],
            }, id: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, config: { type: COMPONENT_DSL_PROP_TYPES.object }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsFormRichtextEditorSettings,
};
