var _a;
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialTypography } from '../../material-components/MaterialTypography';
import { BuilderComponentsRadioSettings } from './BuilderComponentsRadio.settings';
export const BuilderComponentsRadio = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
    displayName: 'Radio Button',
    icon: 'radioButton',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Radio',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.Iterator,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.Fragment,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsFormRadioGroup,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsFormFieldArray,
                },
            },
        ],
        presentations: [visibleByCondition],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            props: {
                name: 'radio',
                value: 'radio1',
                color: 'primary',
                size: 'medium',
                labelPlacement: 'end',
                style: getPredefinedStyles({ paddingRight: 10 }),
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            alias: 'Radio Label',
                            schemaOverride: {
                                interaction: 'only-editable',
                            },
                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Radio Item',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, checked: { type: COMPONENT_DSL_PROP_TYPES.boolean }, checkedIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, icon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabledRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, labelPlacement: { type: COMPONENT_DSL_PROP_TYPES.enum }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsRadioSettings,
};
