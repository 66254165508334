import { omit } from 'ramda';
import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_9_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.9.3';
    }
    getChangeList() {
        return [`Updated MaterialListItem, ListItemSymbol and NavListItemSymbol`];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.MaterialListItem: {
                return Object.assign(Object.assign({}, nodeDSL), { props: omit([
                        'autoFocus',
                        'button',
                        'ContainerComponent',
                        'ContainerProps',
                        'disabled',
                        'disabledElevation',
                        'disableFocusRipple',
                        'disableRipple',
                        'selected',
                    ], nodeDSL.props) });
            }
            case COMPONENT_DSL_NAMES.ListItemSymbol: {
                if (!nodeDSL.props.button) {
                    return Object.assign(Object.assign({}, nodeDSL), { props: omit(['text', 'autoFocus', 'disabled', 'selected'], nodeDSL.props) });
                }
                return Object.assign(Object.assign({}, nodeDSL), { props: omit(['text'], nodeDSL.props) });
            }
            case COMPONENT_DSL_NAMES.NavListItemSymbol: {
                return Object.assign(Object.assign({}, nodeDSL), { props: omit(['button', 'text'], nodeDSL.props) });
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_9_3 = new Migration_0_9_3();
