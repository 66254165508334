import React from 'react';

import styled from '@emotion/styled';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

const StyledButton = styled(IconButton)`
  width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
  font-size: ${({ theme }) => theme.spacing(3)};
`;

export const BackButton: React.FC<IconButtonProps> = ({ onClick, ...restProps }) => {
  return (
    <StyledButton size="small" onClick={onClick} {...restProps}>
      <ArrowBackIcon fontSize="inherit" />
    </StyledButton>
  );
};
