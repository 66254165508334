import React, { useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { useFormikContext } from 'formik';

import { ComponentSettingSelectOption } from '@builder/schemas';
import { useBoolState } from '@builder/utils';

import { StateRestDSLFormValues } from '../../common/types';
import { FunctionDefinitionContainer } from 'src/features/functions-manager/components/sections/FunctionDefinitionContainer';
import { useStateFunctionListDSL } from 'src/features/functions-manager/hooks';
import { CssEditorOpenButton as EditorOpenButton } from 'src/features/node-settings/setting-views/CSSViewEditor/CSSViewEditor';
import { useInitMonaco } from 'src/features/utils/useInitMonaco';
import { useUpdateMonacoStateTypes } from 'src/features/utils/useUpdateMonacoStateTypes';
import { useAppDSLStates, useUIBuilderLeftPanel } from 'src/providers';
import {
  Field,
  FieldRenderProps,
  MonacoEditor,
  InputContainer,
  EditorFormDialog,
  CssGrid,
  Select,
} from 'src/shared/components';
import { MESSAGES } from 'src/shared/constants';
import { TOOLTIP_TRANSFORMER } from 'src/shared/constants/toolTipsDescription';
import { useDashboardCurrentRouteNodeDSL } from 'src/shared/hooks';

import { RequestSection } from './RequestSection';

const OPTIONS = {
  minimap: {
    enabled: false,
  },
  scrollbar: {
    horizontalScrollbarSize: 4,
    verticalScrollbarSize: 4,
  },
} as const;
export const EditorContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const ContextParameter = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
`;
export const ButtonEditor = styled.div`
  position: relative;
  padding-top: 0px;
`;

const SELECT_OPTIONS = [
  { value: 'code', label: 'Custom Code' },
  { value: 'function', label: 'Run function' },
] as ComponentSettingSelectOption[];

export const RequestTransformersSection: React.FC = () => {
  const appStatesDSL = useAppDSLStates();
  const [mountedStates, setMountedStates] = useState(appStatesDSL);
  const [editorValue, setEditorValue] = useState('');
  const [dialogValue, setDialogValue] = useState('');
  const { globalStateFunctionArrayDSL, localStateFunctionArrayDSL } = useStateFunctionListDSL();
  const dashBoardCurrentRoute = useDashboardCurrentRouteNodeDSL();
  const { scope } = useUIBuilderLeftPanel();
  const localStatesInCurrentRoute = Object.values(dashBoardCurrentRoute?.states ?? {}).map(
    state => state.stateID,
  );
  const functionOptions = useMemo(() => {
    const functionGloblalOptions = globalStateFunctionArrayDSL.map(({ name }) => ({
      label: name,
      value: `${name}()`,
    }));
    const functionLocalOptions =
      scope === 'global'
        ? []
        : localStateFunctionArrayDSL
            .filter(state => localStatesInCurrentRoute?.includes(state.id))
            .map(({ name }) => ({
              label: name,
              value: `${name}()`,
            }));
    return [...functionGloblalOptions, ...functionLocalOptions];
  }, [globalStateFunctionArrayDSL, localStateFunctionArrayDSL, localStatesInCurrentRoute, scope]);
  const [isDialogOpened, { setTrue: openDialog, setFalse: closeDialog }] = useBoolState();
  const { setFieldValue } = useFormikContext<StateRestDSLFormValues>();
  const submitForm = () => {
    setFieldValue('args.transformer', dialogValue);
    setEditorValue(dialogValue);
    closeDialog();
  };

  const initMonaco = useInitMonaco(setMountedStates, scope);
  useUpdateMonacoStateTypes(mountedStates, setMountedStates, scope);

  return (
    <RequestSection
      title={MESSAGES.requestsManager.requestForm.transformer.title}
      titleDescription={{
        title: MESSAGES.requestsManager.requestForm.transformer.titleDescription,
        subtitle: MESSAGES.requestsManager.requestForm.transformer.subtitleDescription,
      }}
      data-test="requestsManager.editor.section.transformer"
    >
      <Field name="args.transformer" useValueOnChange>
        {({ field, form }: FieldRenderProps) => {
          setEditorValue(field.value);
          return (
            <>
              <CssGrid alignItems="flex-end" gridTemplateColumns="1fr 2fr" gap={1}>
                <Select
                  name="typeOnTransformer"
                  data-test="requestsManager.select.onCompleted"
                  label="Transformer"
                  value={form.values.args.typeOnTransformer || 'code'}
                  onChange={prop => {
                    form.setFieldValue('args.typeOnTransformer', prop.target.value);
                    form.setFieldValue('args.onTransformerFunction', '');
                    form.setFieldValue('args.transformer', '');
                  }}
                  options={SELECT_OPTIONS}
                />
                <If condition={form.values.args.typeOnTransformer === 'function'}>
                  <Select
                    name="function"
                    data-test="requestsManager.select.onError"
                    label="Function"
                    value={form.values.args.onTransformerFunction || ''}
                    onChange={prop => {
                      form.setFieldValue('args.onTransformerFunction', prop.target.value);
                    }}
                    options={functionOptions}
                  />
                </If>
              </CssGrid>
              <If
                condition={
                  form.values.args.typeOnTransformer === 'code' ||
                  !form.values.args.typeOnTransformer
                }
              >
                <InputContainer label="Code">
                  <FunctionDefinitionContainer
                    functionName=""
                    isAsyncFunction={false}
                    args={[' data ']}
                    isContext
                    context={{
                      parameter: ' data ',
                      action: 'transformer',
                      tooltipInfo: TOOLTIP_TRANSFORMER,
                    }}
                  >
                    <EditorContainer>
                      <ButtonEditor>
                        <EditorOpenButton
                          onClick={() => {
                            setDialogValue(field.value);
                            openDialog();
                          }}
                        />
                      </ButtonEditor>

                      <MonacoEditor
                        language="typescript"
                        height="60px"
                        defaultValue={field.value}
                        value={editorValue}
                        options={OPTIONS}
                        data-test="requestsManager.editor.transformer"
                        onChange={value => field.onChange(value)}
                        beforeMount={initMonaco}
                        editorScope={scope as string}
                      />
                    </EditorContainer>
                  </FunctionDefinitionContainer>
                  <EditorFormDialog
                    isDialogOpened={isDialogOpened}
                    onDialogClose={closeDialog}
                    title="Transformer"
                    formProps={{
                      onSubmit: submitForm,
                      initialValues: { transformer: editorValue },
                    }}
                    args={{ context: ['data'], action: 'transformer' }}
                  >
                    <MonacoEditor
                      language="typescript"
                      height="100%"
                      value={dialogValue}
                      data-test="requestsManager.editor.transformer"
                      onChange={value => {
                        setDialogValue(value as string);
                      }}
                    />
                  </EditorFormDialog>
                </InputContainer>
              </If>
            </>
          );
        }}
      </Field>
      <Divider sx={{ mt: 0.5 }} />
    </RequestSection>
  );
};
