import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs, { Dayjs } from 'dayjs';

import { StringPropValue, JSInjectionPropValue, NumberPropValue } from '@builder/schemas';

import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { InputContainer } from 'src/shared/components';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

export type TimeFieldViewEditorProps<
  T = StringPropValue | NumberPropValue | JSInjectionPropValue | undefined
> = {
  label: string;
  propValue: T;
  onChangePropValue: (propValue: T) => void;
  required?: boolean;
  nodeID?: string;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  actionEventDesign?: boolean;
  multiline?: boolean;
};

const StyledTimeField = styled(TimeField)<{
  multiline: boolean;
}>`
  width: 100%;
  height: ${({ theme, multiline }) => (!multiline ? `${theme.layout.controls.height}px` : 'auto')};
  & input {
    padding: 0;
  }
  & textarea {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
  & .MuiInputBase-root {
    height: ${({ multiline }) => (!multiline ? `100%` : 'auto')};
  }
`;

const TIME_FORMAT = 'HH:mm:ss';

export const TimeFieldViewEditor: React.FC<TimeFieldViewEditorProps> = ({
  label,
  propValue,
  onChangePropValue,
  required,
  nodeID,
  'data-test': dataTest,
  showFx = true,
  fxDefaultEnabled = ACTIVEPROP.literal,
  actionEventDesign,
  multiline = true,
}) => {
  const [localValue, setLocalValue] = React.useState<Dayjs | null>(
    dayjs(propValue, TIME_FORMAT) || null,
  );
  const updatePropByEvent = useCallback(
    (newTime: string) => {
      if (dayjs(newTime, TIME_FORMAT).isValid()) {
        onChangePropValue(newTime);
      }
    },
    [onChangePropValue],
  );

  return (
    <JsCodeInjectionInput
      label={label}
      propValue={propValue}
      onChangePropValue={onChangePropValue}
      nodeID={nodeID}
      data-test={dataTest}
      showFx={showFx}
      fxDefaultEnabled={fxDefaultEnabled}
    >
      {({ isFxEnabled, enableFx, nonJsCodePropValue }) => {
        const nonJsCodeTimeValue = dayjs(nonJsCodePropValue, TIME_FORMAT);
        return (
          <InputContainer
            label={actionEventDesign ? '' : label}
            enableFx={enableFx}
            isFxEnabled={isFxEnabled}
            data-test={dataTest}
            showFx={actionEventDesign ? false : showFx}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledTimeField
                required={required}
                value={localValue || nonJsCodeTimeValue}
                onChange={newValue => {
                  setLocalValue(newValue as Dayjs);
                }}
                onBlur={event => {
                  updatePropByEvent(event.target.value);
                }}
                format={TIME_FORMAT}
                multiline={multiline}
              />
            </LocalizationProvider>
          </InputContainer>
        );
      }}
    </JsCodeInjectionInput>
  );
};
