import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

type StyledMuiTabProps = {
  margin: number;
};

const StyledMuiTab = styled(MuiTab)<StyledMuiTabProps>`
  font-weight: normal;
  padding: 0;
  min-height: auto;

  :not(:last-child) {
    margin-right: ${({ margin }) => `${margin}px`};
  }
`;

const StyledMuiTabs = styled(MuiTabs)`
  border: none;
`;

type Option = {
  label: string;
  value: string;
};

type CustomTabsProps = {
  selectedValue: string;
  options: Option[];
  gap?: number;
  onChange: (value: string) => void;
};

export const Tabs: React.FC<CustomTabsProps> = ({ selectedValue, options, gap = 25, onChange }) => {
  const handleChangeTab = useCallback(
    (_: unknown, newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <StyledMuiTabs
      value={selectedValue}
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleChangeTab}
      TabIndicatorProps={{ style: { display: 'none' } }}
      sx={{ minHeight: 'auto' }}
    >
      {options.map(option => (
        <StyledMuiTab
          key={option.label}
          label={option.label}
          value={option.value}
          margin={gap}
          disableRipple
          disableFocusRipple
        />
      ))}
    </StyledMuiTabs>
  );
};
