import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, NS_ID, getBasicPropsSettings } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const OL_SETTINGS = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['reversed'],
                            label: 'Reversed',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['start'],
                            label: 'Start',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['type'],
                            label: 'Type',
                            options: [
                                { value: '1', label: '1' },
                                { value: 'a', label: 'a' },
                                { value: 'A', label: 'A' },
                                { value: 'i', label: 'i' },
                                { value: 'I', label: 'I' },
                            ],
                            hasFxButton: true,
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
