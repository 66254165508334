import React, { useEffect } from 'react';

import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { $selectAll } from '@lexical/selection';
import {
  $getRoot,
  $insertNodes,
  $getSelection,
  EditorState,
  LexicalEditor,
  $isRangeSelection,
} from 'lexical';
import debounce from 'lodash/debounce';

import {
  ComponentSettingChildrenTextRichElementDSL,
  componentSettingSelectors,
  NodePropValue,
} from '@builder/schemas';

import { useTheme } from '../../../providers/ThemeProvider';
import { useAppDispatch, useUIBuilderState } from 'src/providers';
import { NODE_SETTINGS_TABS, RIGHT_PANEL_TABS, UI_BUILDER_EVENTS } from 'src/store';

import Toolbar from './Toolbar';

const EditorBody = styled.div`
  text-align: initial;
`;

const EditorControls = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(6, 42px);
  grid-template-rows: 1fr;
`;

const EditorInputArea = styled.div`
  overflow-y: auto;
  max-height: 300px;
  padding: 0 8px;
`;

const EditorInputCss = ({ theme }: { theme: Theme }) => css`
  border: 1px solid ${theme.palette.border.input};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.background.input};

  &:hover {
    border-color: ${theme.palette.primary.main}B3;
  }
  // focus is not working because the external component is not using input field
  &:focus {
    border-color: ${theme.palette.primary.main};
  }
`;

/**
 * Thin wrapper around lexical components
 */
export const RichTextEditor = (props: {
  setting: ComponentSettingChildrenTextRichElementDSL;
  onChange: (propData: { keyValue: unknown; keyPath: (string | number)[] }) => void;
  dataTest: string;
  targetNodeId: string;
  keyValue: NodePropValue<string> | null;
}): JSX.Element => {
  const { onChange, dataTest, setting, keyValue, targetNodeId } = props;
  const { nodeSettingsArgs } = useUIBuilderState().rightPanel;
  const theme = useTheme();
  const send = useAppDispatch();
  const [editor] = useLexicalComposerContext();

  const onEditorChange = debounce((editorState: EditorState, callbackEditor: LexicalEditor) => {
    editorState.read(() => {
      const html = $generateHtmlFromNodes(callbackEditor, null);

      onChange({
        keyValue: html,
        keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
      });
    });
  }, 100);

  // Set Lexical content on first mount
  useEffect(() => {
    editor.update(() => {
      if (keyValue !== '<p><br></p>') {
        const parser = new DOMParser();
        const dom = parser.parseFromString(keyValue as string, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        $insertNodes(nodes);
      }

      const shouldFocusInputRef =
        nodeSettingsArgs?.focusLabelForCurrentNode?.nodeId === targetNodeId &&
        !nodeSettingsArgs?.focusLabelForCurrentNode?.focused;
      if (shouldFocusInputRef) {
        send({
          type: UI_BUILDER_EVENTS.updateRightPanel,
          rightPanel: {
            currentTab: RIGHT_PANEL_TABS.componentList,
            nodeSettingsArgs: {
              currentTab: NODE_SETTINGS_TABS.properties,
              focusLabelForCurrentNode: {
                inputLabelToFocus: nodeSettingsArgs?.focusLabelForCurrentNode?.nodeId as string,
                focused: true,
                nodeId: targetNodeId,
              },
            },
          },
        });

        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $selectAll(selection);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditorBody>
      <EditorControls>
        <Toolbar />
      </EditorControls>

      <EditorInputArea data-test={dataTest} css={EditorInputCss({ theme })}>
        <RichTextPlugin
          contentEditable={<ContentEditable style={{ border: 'none', outline: 'none' }} />}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </EditorInputArea>

      {/* PLUGINS */}
      <HistoryPlugin />
      <LinkPlugin />
      <ListPlugin />
      <OnChangePlugin onChange={onEditorChange} />
      <ClearEditorPlugin />
    </EditorBody>
  );
};
