import { AppEntityBaseValidator } from './AppEntityBaseValidator';
/**
 *  Contains state validators.
 */
export class StateRequestValidator extends AppEntityBaseValidator {
    /**
     * State request returns true anyway because state prop schema doesn't support uniq setting.
     */
    checkUniqueProp(_) {
        return true;
    }
}
