var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useRef, useEffect } from 'react';
export const HtmlFragment = forwardRef((_a, ref) => {
    var { htmlFragment } = _a, props = __rest(_a, ["htmlFragment"]);
    const htmlRef = useRef(null);
    useEffect(() => {
        if (htmlRef.current) {
            htmlRef.current.innerHTML = htmlFragment;
        }
    }, [htmlFragment]);
    return _jsx("div", Object.assign({}, props, { ref: htmlRef }));
});
export default HtmlFragment;
