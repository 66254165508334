import { put, takeEvery } from 'redux-saga/effects';

import { DashboardContextUpdateEvents, DASHBOARD_EVENTS, UI_BUILDER_EVENTS } from '../../common';

type DashboardUndoEvent = Extract<
  DashboardContextUpdateEvents,
  { type: typeof DASHBOARD_EVENTS.dashboardUndo }
>;

function* undoOnceForcefully() {
  yield put<DashboardUndoEvent>({ type: DASHBOARD_EVENTS.dashboardUndo, completely: true });
}

export function* watchForErrorNotices(): Generator {
  yield takeEvery(UI_BUILDER_EVENTS.errorAppCrashedNotify, undoOnceForcefully);
}
