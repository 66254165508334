import { pipe } from 'ramda';
import { assocSettings, NS_ID } from '../../common';
import { getCommonFormFieldPropertiesSettings, getCommonFormFieldActionSettings, } from '../../common/settings';
import { getBuilderComponentsMultiselectSettings } from '../../material-components/BuilderComponentsMultiselect/BuilderComponentsMultiselect.settings';
const basicMultiselectSettings = getBuilderComponentsMultiselectSettings(['selectProps']);
export const BuilderComponentsFormMultiselectSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...settings.children, ...getCommonFormFieldPropertiesSettings()] }))))(basicMultiselectSettings.properties),
    style: basicMultiselectSettings.style,
    events: pipe(assocSettings([NS_ID.events.root, NS_ID.events.events], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldActionSettings(), ...settings.children] }))))(basicMultiselectSettings.events),
};
