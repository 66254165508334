import { mapObjIndexed } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_9_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.9.0';
    }
    getChangeList() {
        return ['Change event section type', 'Add title for event section', 'Remove "Add event" label'];
    }
    migrateSettingDSL(setting, appDSL) {
        switch (setting.type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            case 'settings-picker': {
                const newSetting = {
                    type: 'events-section',
                    name: 'events',
                    title: 'Events',
                    children: [...setting.children],
                };
                return newSetting;
            }
            case 'rootSection': {
                return Object.assign(Object.assign({}, setting), { children: this.migrateSettingListDSL(setting.children, appDSL) });
            }
            default:
                return setting;
        }
    }
    migrateUserComponentListDSL(userComponentListDSL, appDSL) {
        return mapObjIndexed(userComponent => (Object.assign(Object.assign({}, userComponent), { settings: this.migrateUserComponentSettingListDSL(userComponent.settings, appDSL) })), userComponentListDSL);
    }
}
export const migration_0_9_0 = new Migration_0_9_0();
