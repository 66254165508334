import { copyComponentToBuffer, copyBufferApply } from '../../../complex-cases';
import { dashboardInitial } from '../../../initial-state';
import { AppEvents, createReducer, DashboardState, DASHBOARD_EVENTS } from 'src/store/common';

export const COPY_BUFFER_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.copyBufferSet,
  DASHBOARD_EVENTS.copyBufferClear,
  DASHBOARD_EVENTS.copyBufferApply,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof COPY_BUFFER_REDUCER_EVENTS[number]>({
    events: COPY_BUFFER_REDUCER_EVENTS,
    reduce: (state, event) => {
      switch (event.type) {
        case DASHBOARD_EVENTS.copyBufferSet: {
          return copyComponentToBuffer(state, event.nodeID, event.currentPathName);
        }

        case DASHBOARD_EVENTS.copyBufferClear:
          return {
            ...state,
            copyBuffer: {},
          };

        case DASHBOARD_EVENTS.copyBufferApply: {
          return copyBufferApply(state, event.nodeID, event.currentPathName);
        }
      }
    },
  }),
]);

export const copyBufferReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
