import { AppEvents, DASHBOARD_EVENTS, Store } from '../common';

export const userAppRuntimeStateReducer = (
  state: Store['userAppRuntimeState'] = {},
  event: AppEvents,
): Store['userAppRuntimeState'] => {
  if (event.type === DASHBOARD_EVENTS.stateReport) {
    return event.report.states;
  }

  return state;
};
