import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { StyledIconButton } from '../AssetsHeader/AssetsHeader.styles';

export type AssetAddButtonProps = {
  loading: boolean;
  onClick: () => void;
};

export const AssetAddButton: React.FC<AssetAddButtonProps> = ({ loading, ...props }) => (
  <Choose>
    <When condition={loading}>
      <CircularProgress color="info" size={16} />
    </When>

    <Otherwise>
      <StyledIconButton {...props}>
        <AddIcon />
      </StyledIconButton>
    </Otherwise>
  </Choose>
);
