import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { isObject } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_19_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.2';
    }
    getChangeList() {
        return ['Change height style prop to auto in builderComponentsRouteLayouts'];
    }
    migrateNodeDSL(nodeDSL) {
        var _a;
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout: {
                if (((_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.style) && isObject(nodeDSL.props.style)) {
                    return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { style: Object.assign(Object.assign({}, nodeDSL.props.style), { height: 'auto' }) }) });
                }
                return nodeDSL;
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_19_2 = new Migration_0_19_2();
