import React, { useEffect } from 'react';

import { Preview } from 'src/features/preview';
import { useConfigurationGet } from 'src/shared/draft-engine/hooks';

export const PreviewPage = (): JSX.Element => {
  const {
    getConfiguration: getConfigurationForSave,
    getConfigurationResult: appConfigurationResult,
  } = useConfigurationGet();
  const { appConfiguration } = appConfigurationResult?.data || {};
  useEffect(() => {
    getConfigurationForSave();
  }, [getConfigurationForSave]);

  return (
    <div style={{ height: '100vh', overflow: 'none' }}>
      <Preview appConfiguration={appConfiguration} />
    </div>
  );
};
