import { takeEvery } from '@redux-saga/core/effects';
import { getContext, select } from 'redux-saga/effects';

import { EMITTER_EVENTS, EventEmitter } from '@builder/utils';

import { DashboardContextUpdateEvents, DASHBOARD_EVENTS, Store } from 'src/store/common';

function* sendEmitterChangeRouteOnPathUpdate(
  event: Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.routerPathUpdate }>,
) {
  try {
    const emitter: EventEmitter = yield getContext('emitter');

    emitter.emit(EMITTER_EVENTS.changeRoute, { route: event.route });
  } catch (error) {
    console.warn('An error occurred in sendEmitterChangeRouteOnPathUpdate:', error);
  }
}

function* sendEmitterChangeRouteOnCurrentRouteUpdate(
  event: Extract<
    DashboardContextUpdateEvents,
    { type: typeof DASHBOARD_EVENTS.routeCurrentUpdate }
  >,
) {
  try {
    const emitter: EventEmitter = yield getContext('emitter');
    const currentRoute: string = yield select(
      (store: Store) => store.dashboard.router.currentRoute,
    );

    emitter.emit(EMITTER_EVENTS.changeRoute, { route: currentRoute });
  } catch (error) {
    console.warn('An error occurred in sendEmitterChangeRouteOnCurrentRouteUpdate:', error);
  }
}

export function* watchRouteChange(): Generator {
  yield takeEvery(DASHBOARD_EVENTS.routerPathUpdate, sendEmitterChangeRouteOnPathUpdate);
  yield takeEvery(DASHBOARD_EVENTS.routeCurrentUpdate, sendEmitterChangeRouteOnCurrentRouteUpdate);
}
