import { COMPONENT_LIST_MODE, MENU_GROUP_NAMES } from '../constants';
const sortComponentsByDisplayName = (a, b) => {
    var _a, _b;
    const fa = (_a = a.displayName) !== null && _a !== void 0 ? _a : '';
    const fb = (_b = b.displayName) !== null && _b !== void 0 ? _b : '';
    return fa.localeCompare(fb);
};
export const getComponentMenuList = (componentListDSL, { isDevMode = false }) => {
    const componentListGroupMap = Object.values(componentListDSL).reduce((accum, componentDSL) => {
        var _a;
        if (!componentDSL.componentListGroupName ||
            (!isDevMode && componentDSL.schema.componentListMode === COMPONENT_LIST_MODE.layout)) {
            return accum;
        }
        const existedComponentsInTheGroup = (_a = accum[componentDSL.componentListGroupName]) !== null && _a !== void 0 ? _a : [];
        if (componentDSL.isCommonlyUsed) {
            accum.common.push({
                id: componentDSL.name,
                componentName: componentDSL.name,
                displayName: componentDSL.displayName,
            });
            accum.common.sort(sortComponentsByDisplayName);
        }
        const foundIdx = accum.form.findIndex(el => el.componentName === 'BuilderComponentsForm');
        if (foundIdx !== -1) {
            const tempElement = accum.form.splice(foundIdx, 1);
            accum.form.unshift(tempElement[0]);
            accum.form.sort(sortComponentsByDisplayName);
        }
        const shortComponents = [
            ...existedComponentsInTheGroup,
            {
                id: componentDSL.name,
                componentName: componentDSL.name,
                displayName: componentDSL.displayName,
            },
        ]
            .sort(sortComponentsByDisplayName)
            // TODO: Hide Drawer components for now, as they are not ready yet
            .filter(item => item.displayName !== 'Drawer');
        return Object.assign(Object.assign({}, accum), { [componentDSL.componentListGroupName]: shortComponents });
    }, {
        [MENU_GROUP_NAMES.common]: [],
        [MENU_GROUP_NAMES.layout]: [],
        [MENU_GROUP_NAMES.form]: [],
        [MENU_GROUP_NAMES.html]: [],
        [MENU_GROUP_NAMES.controls]: [],
        [MENU_GROUP_NAMES.basic]: [],
        [MENU_GROUP_NAMES.data]: [],
        [MENU_GROUP_NAMES.other]: [],
    });
    return Object.keys(componentListGroupMap).map(groupName => ({
        groupName,
        components: componentListGroupMap[groupName],
    }));
};
