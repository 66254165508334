import { COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const AsyncContentSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['loading'],
                            label: 'Loading',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['type'],
                            label: 'Type',
                            options: [
                                {
                                    label: 'Circular',
                                    value: 'circular',
                                },
                                {
                                    label: 'Linear',
                                    value: 'linear',
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: 'prop',
                            keyPath: ['variant'],
                            label: 'Variant',
                            options: [
                                {
                                    label: 'Determinate',
                                    value: 'determinate',
                                },
                                {
                                    label: 'Indeterminate',
                                    value: 'indeterminate',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['value'],
                            label: 'Value',
                            showIf: [
                                {
                                    keyPath: ['variant'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'determinate',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: 'prop',
                            keyPath: ['color'],
                            label: 'Color',
                            options: [
                                {
                                    label: 'Primary',
                                    value: 'primary',
                                },
                                {
                                    label: 'Secondary',
                                    value: 'secondary',
                                },
                                {
                                    label: 'Inherit',
                                    value: 'inherit',
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['stretch'],
                            label: 'Stretch',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['circularProgressProps', 'size'],
                            showIf: [
                                {
                                    keyPath: ['type'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'circular',
                                },
                            ],
                            label: 'Size',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['circularProgressProps', 'thickness'],
                            showIf: [
                                {
                                    keyPath: ['type'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: 'circular',
                                },
                            ],
                            label: 'Thickness',
                        },
                    ],
                },
                ...getStyleSections(),
                getDivider(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
