import { DateTime } from 'luxon';
const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
/**
 * @param date - ISO string
 * @returns 1 hour ago
 */
export const getTimeAgo = (date, unit) => {
    const dateTime = DateTime.fromISO(date);
    const diff = dateTime.diffNow().shiftTo(...units);
    const currentUnit = unit || units.find(unitEl => diff.get(unitEl) !== 0) || 'second';
    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(currentUnit)), currentUnit);
};
/**
 * @param date - ISO string
 * @returns some days ago
 */
export const getDaysAgo = (date) => getTimeAgo(date, 'day');
