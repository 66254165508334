/* eslint-disable lines-between-class-members */
import { Event } from '@8base-private/event-handler';
import qs from 'qs';
import WebSocket from 'reconnecting-websocket';

import { processEnv } from '@builder/utils';

type Options = {
  workspaceId: string;
  authorization: string;
  stateCallback?: (isConnected: boolean) => void;
};

type EventListener = (event: Event) => void;

export class DraftEngine {
  protected connection?: WebSocket;
  protected eventListeners = new Set<EventListener>();

  constructor(protected options: Options) {}

  protected handleReconnect = (): void => {
    this.connection?.reconnect();
  };

  protected handleState = (): void => {
    if (this.options.stateCallback) {
      this.options.stateCallback(this.isConnected());
    }
  };
  protected handleMessage = (message: MessageEvent): void => {
    const event: Event = JSON.parse(message.data);

    for (const listener of this.eventListeners.values()) {
      listener(event);
    }
  };

  generateUrl(): string {
    const { authorization, workspaceId } = this.options;
    const query = qs.stringify({ authorization });

    return `${processEnv.getDraftServerURL()}/workspace/${workspaceId}?${query}`;
  }

  connect(): void {
    this.connection = new WebSocket(this.generateUrl());

    window.addEventListener('offline', this.handleReconnect);

    if (this.options.stateCallback) {
      this.connection.addEventListener('open', this.handleState);
      this.connection.addEventListener('close', this.handleState);
      this.connection.addEventListener('error', this.handleState);
    }

    this.connection.addEventListener('message', this.handleMessage);
  }

  disconnect(): void {
    this.connection?.close();

    window.removeEventListener('offline', this.handleReconnect);

    if (this.options.stateCallback) {
      this.connection?.removeEventListener('open', this.handleState);
      this.connection?.removeEventListener('close', this.handleState);
      this.connection?.removeEventListener('error', this.handleState);
    }

    this.connection?.removeEventListener('message', this.handleMessage);
    this.connection = undefined;
  }

  isConnected(): boolean {
    return this.connection?.readyState === 1;
  }

  send(event: Event): void {
    this.connection?.send(JSON.stringify(event));
  }

  addEventListener(listener: EventListener): void {
    this.eventListeners.add(listener);
  }

  removeEventListener(listener: EventListener): void {
    this.eventListeners.delete(listener);
  }

  removeAllEventListeners(): void {
    for (const listener of this.eventListeners.values()) {
      this.eventListeners.delete(listener);
    }
  }
}
