import { incrementName } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_11_8 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.8';
    }
    getChangeList() {
        return ['Change all non-unique state names to unique'];
    }
    // Not the best algorithm, but migration is not critical and all new names will be unique and readable
    migrateStateListDSL(stateListDSL, appDSL) {
        const stateArrayDSL = Object.values(stateListDSL);
        const newStateArrayDSL = stateArrayDSL.map((stateDSL, index) => {
            const dictionary = Object.values(stateArrayDSL)
                .filter(stateDSLItem => stateDSLItem.id !== stateDSL.id)
                .map(state => state.name);
            const newStateDSL = Object.assign(Object.assign({}, stateDSL), { name: incrementName({ nameToIncrement: stateDSL.name, dictionary, delimiter: '_' }) });
            stateArrayDSL[index] = newStateDSL;
            return newStateDSL;
        });
        return newStateArrayDSL.reduce((acc, curr) => {
            return Object.assign(Object.assign({}, acc), { [curr.id]: curr });
        }, {});
    }
}
export const migration_0_11_8 = new Migration_0_11_8();
