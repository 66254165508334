import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_NAMES, COMPONENT_DSL_CALLBACK_TYPES, PRESENTATIONS, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsAutocompleteSettings } from './BuilderComponentsAutocomplete.settings';
export const BuilderComponentsAutocomplete = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete,
    displayName: 'Autocomplete',
    icon: 'autoComplete',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Autocomplete',
    },
    schema: {
        presentations: [
            {
                name: PRESENTATIONS.visible,
                props: {
                    open: true,
                },
            },
        ],
        mainPropPath: {
            mainProp: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.object,
                    name: 'autocomplete',
                    defaultValue: { label: '', value: '' },
                },
            ],
            props: {
                variant: 'outlined',
                label: 'Autocomplete',
                options: [
                    { label: 'the ultimate', value: 'the_ultimate' },
                    { label: 'question of life ', value: 'question_of_life' },
                    { label: 'the universe', value: 'the_universe' },
                    { label: 'and everything', value: 'and_everything' },
                ],
                getOptionLabel: "return autocompleteOption?.value?.label || '';",
                autoComplete: false,
                autoHighlight: false,
                autoSelect: false,
                clearOnEscape: false,
                clearText: 'Clear',
                closeText: 'Close',
                disableClearable: false,
                disableCloseOnSelect: false,
                disabled: false,
                disabledItemsFocusable: false,
                disableListWrap: false,
                disablePortal: false,
                freeSolo: false,
                fullWidth: false,
                includeInputInList: false,
                limitTags: -1,
                loading: false,
                loadingText: 'Loading…',
                multiple: false,
                noOptionsText: 'No options',
                openOnFocus: false,
                openText: 'Open',
                size: 'medium',
                value: '{{ __STATES__.autocomplete.value.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.autocomplete.setValue(value || { label: "", value: "" })',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { options: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: { type: COMPONENT_DSL_PROP_TYPES.object },
            }, renderOption: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [0, 'id'],
                args: [
                    {
                        name: 'renderOptionProps',
                        path: [0],
                    },
                    {
                        name: 'autocompleteOption',
                        path: [1],
                    },
                    {
                        name: 'autocompleteState',
                        path: [2],
                    },
                ],
            }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, label: { type: COMPONENT_DSL_PROP_TYPES.string }, autoComplete: { type: COMPONENT_DSL_PROP_TYPES.boolean }, autoHighlight: { type: COMPONENT_DSL_PROP_TYPES.boolean }, autoSelect: { type: COMPONENT_DSL_PROP_TYPES.boolean }, 
            // TODO: add type COMPONENT_DSL_PROP_TYPES.or for blurOnSelect prop
            // https://8base-dev.atlassian.net/browse/APB-903
            blurOnSelect: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, clearOnBlur: { type: COMPONENT_DSL_PROP_TYPES.boolean }, clearOnEscape: { type: COMPONENT_DSL_PROP_TYPES.boolean }, clearText: { type: COMPONENT_DSL_PROP_TYPES.string }, closeText: { type: COMPONENT_DSL_PROP_TYPES.string }, clearIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, 
            // TODO: make defaultValue prop to be able to be "Any"
            // https://8base-dev.atlassian.net/browse/APB-904
            defaultValue: { type: COMPONENT_DSL_PROP_TYPES.object }, disableClearable: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableCloseOnSelect: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabledItemsFocusable: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableListWrap: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disablePortal: { type: COMPONENT_DSL_PROP_TYPES.boolean }, filterOptions: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'autocompleteOptionsList',
                        path: [0],
                    },
                    {
                        name: 'autocompleteState',
                        path: [1],
                    },
                ],
            }, filterSelectedOptions: { type: COMPONENT_DSL_PROP_TYPES.boolean }, 
            // TODO: add type COMPONENT_DSL_PROP_TYPES.or for forcePopupIcon prop
            // https://8base-dev.atlassian.net/browse/APB-903
            forcePopupIcon: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, freeSolo: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, getLimitTagsText: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'limitTagsMore',
                        path: [0],
                    },
                ],
            }, getOptionDisabled: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'autocompleteOption',
                        path: [0],
                    },
                    {
                        name: 'autocompleteValue',
                        path: [1],
                    },
                ],
            }, getOptionSelected: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'autocompleteOption',
                        path: [0],
                    },
                    {
                        name: 'autocompleteValue',
                        path: [1],
                    },
                ],
            }, groupBy: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'autocompleteOptionsList',
                        path: [0],
                    },
                ],
            }, handleHomeEndKeys: { type: COMPONENT_DSL_PROP_TYPES.boolean }, includeInputInList: { type: COMPONENT_DSL_PROP_TYPES.boolean }, inputValue: { type: COMPONENT_DSL_PROP_TYPES.object }, limitTags: { type: COMPONENT_DSL_PROP_TYPES.number }, ListboxProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
            }, loading: { type: COMPONENT_DSL_PROP_TYPES.boolean }, loadingText: { type: COMPONENT_DSL_PROP_TYPES.string }, 
            // TODO: implement "multiple" in the separate task
            // https://8base-dev.atlassian.net/browse/APB-905
            multiple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, noOptionsText: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0], // React.SyntheticEvent
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                    {
                        name: 'reason',
                        path: [2],
                    },
                    {
                        name: 'details',
                        path: [3],
                    },
                    {
                        name: 'fieldProps',
                        path: [4],
                    },
                ],
            }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'reason',
                        path: [1],
                    },
                ],
            }, onHighlightChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'autocompleteOption',
                        path: [1],
                    },
                    {
                        name: 'reason',
                        path: [2],
                    },
                ],
            }, onInputChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                    {
                        name: 'reason',
                        path: [2],
                    },
                    {
                        name: 'details',
                        path: [3],
                    },
                ],
            }, 
            // Be carefull: We use prop isOptionEqualToValue in the FormAutocomplete component
            isOptionEqualToValue: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'autocompleteOption',
                        path: [0],
                    },
                    {
                        name: 'autocompleteValue',
                        path: [1],
                    },
                ],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, openOnFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, openText: { type: COMPONENT_DSL_PROP_TYPES.string }, PaperComponent: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, PopperComponent: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, popupIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, 
            // TODO: implement "renderGroup" in the separate task
            // https://8base-dev.atlassian.net/browse/APB-906
            renderGroup: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'autocompleteOption',
                        path: [0],
                    },
                ],
            }, 
            // TODO: implement "renderInput" in the separate task
            // https://8base-dev.atlassian.net/browse/APB-907
            renderInput: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'autocompleteParams',
                        path: [0],
                    },
                ],
            }, 
            // TODO: implement "renderTags" in the separate task
            // https://8base-dev.atlassian.net/browse/APB-908
            renderTags: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'autocompleteValue',
                        path: [0],
                    },
                    {
                        name: 'getTagProps',
                        path: [1],
                    },
                ],
            }, selectOnFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, 
            // TODO: make value prop to be able to be "Any"
            // https://8base-dev.atlassian.net/browse/APB-904
            value: { type: COMPONENT_DSL_PROP_TYPES.string }, 
            // TODO: implement "ChipProps" in the separate task
            // https://8base-dev.atlassian.net/browse/APB-909
            ChipProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    avatar: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
                    clickable: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    color: { type: COMPONENT_DSL_PROP_TYPES.enum },
                    deleteIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
                    disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    icon: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
                    label: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
                    size: { type: COMPONENT_DSL_PROP_TYPES.enum },
                    variant: { type: COMPONENT_DSL_PROP_TYPES.enum },
                    onDelete: {
                        type: COMPONENT_DSL_PROP_TYPES.callback,
                        body: COMPONENT_DSL_CALLBACK_TYPES.code,
                        args: [
                            {
                                name: 'event',
                                path: [0],
                            },
                            {
                                name: 'value',
                                path: [1],
                            },
                        ],
                    },
                    // TODO: add type COMPONENT_DSL_PROP_TYPES.or for component prop
                    // https://8base-dev.atlassian.net/browse/APB-903
                    component: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, // cannot describe now this type string | reactNode
                },
            } }),
    },
    settings: BuilderComponentsAutocompleteSettings,
};
