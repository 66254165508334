import { useCallback } from 'react';

import styled from '@emotion/styled';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';
import { ButtonBase, InputAdornment } from '@mui/material';

import { NumberPropValue } from '@builder/schemas';
import { useDebouncedState } from '@builder/utils';

import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { TextField } from 'src/shared/components';

const NumberInputButton = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  height: 10px;

  & :hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &.Mui-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  & svg {
    font-size: 18px;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  display: flex;
  flex-direction: column;
  margin: 0;
  height: auto;

  > button:nth-child(2) {
    margin-top: 4px;
  }
`;

export type NumberViewEditorProps<T extends NumberPropValue | undefined> = {
  label?: string;
  propValue: T;
  onChangePropValue: (propValue: T) => void;
  nodeID?: string;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  skipDebounce?: boolean;
  placeholder?: string;
  multiline?: boolean;
  min?: number;
  max?: number;
  disabled?: boolean;
};

export const minSafeInteger = Number.MIN_SAFE_INTEGER;
export const maxSafeInteger = Number.MAX_SAFE_INTEGER;

export const NumberViewEditor = <T extends NumberPropValue | undefined>({
  label,
  propValue,
  onChangePropValue,
  nodeID,
  'data-test': dataTest,
  showFx = true,
  skipDebounce,
  placeholder,
  multiline,
  min = minSafeInteger,
  max = maxSafeInteger,
  disabled = false,
}: NumberViewEditorProps<T>): JSX.Element => {
  const [debouncedValue, setDebouncedValue] = useDebouncedState<T>(propValue, onChangePropValue, {
    skipDebounce,
  });

  const updatePropByEvent = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (event.target.value === '') {
        return setDebouncedValue(undefined as T);
      }

      const parsedValue = Number(event.target.value);

      if (isNaN(parsedValue)) {
        return setDebouncedValue(undefined as T);
      }

      if (parsedValue > max) return setDebouncedValue(max as T);

      if (parsedValue < min) return setDebouncedValue(min as T);

      // regexp allows only numbers and '-' character, can be easily edited to allow float type
      if (event.target.value.match(/^(-|\+)?(0|[1-9]\d*)?(\d+)?$/)) {
        return setDebouncedValue(parsedValue as T);
      }
    },
    [max, min, setDebouncedValue],
  );

  const increment = () => {
    const parsedResult = (Number(debouncedValue) ?? 0) + 1;

    if (isNaN(parsedResult)) {
      return setDebouncedValue(undefined as T);
    }

    if (parsedResult > max) return setDebouncedValue(max as T);

    return setDebouncedValue(parsedResult as T);
  };

  const decrement = () => {
    const parsedResult = (Number(debouncedValue) ?? 0) - 1;

    if (isNaN(parsedResult)) {
      return setDebouncedValue(undefined as T);
    }

    if (parsedResult < min) return setDebouncedValue(min as T);

    return setDebouncedValue(parsedResult as T);
  };

  return (
    <JsCodeInjectionInput
      label={label ?? ''}
      propValue={debouncedValue}
      onChangePropValue={onChangePropValue}
      nodeID={nodeID}
      data-test={dataTest}
      showFx={showFx}
      typeField="number"
    >
      {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
        <TextField
          fullWidth
          variant="outlined"
          label={label}
          showFx={showFx}
          value={nonJsCodePropValue}
          size="small"
          onChange={updatePropByEvent}
          isFxEnabled={isFxEnabled}
          enableFx={enableFx}
          placeholder={placeholder}
          data-test={dataTest}
          multiline={multiline}
          disabled={disabled}
          InputProps={{
            endAdornment: !multiline && (
              <StyledInputAdornment position="end">
                <NumberInputButton
                  onTouchStart={increment}
                  onMouseDown={increment}
                  disabled={debouncedValue === max}
                >
                  <ArrowDropUpIcon />
                </NumberInputButton>
                <NumberInputButton
                  onTouchStart={decrement}
                  onMouseDown={decrement}
                  disabled={debouncedValue === min}
                >
                  <ArrowDropDownIcon />
                </NumberInputButton>
              </StyledInputAdornment>
            ),
          }}
        />
      )}
    </JsCodeInjectionInput>
  );
};
