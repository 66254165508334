import { isEmpty } from 'ramda';

import { MediaQueryDataDSL, CSS } from '@builder/schemas';

import { DashboardState } from 'src/store/common';

export const createCSSMediaQuery = (
  dashboardState: DashboardState,
  mediaQuery: MediaQueryDataDSL,
): CSS => {
  const mediaQueriesCSS = dashboardState.appConfiguration.appDSL.theme?.css?.mediaQueries || '';

  return `${mediaQueriesCSS}\n\n@media only screen ${
    !isEmpty(mediaQuery.min) ? `and (min-width: ${mediaQuery.min}px)` : ''
  } ${!isEmpty(mediaQuery.max) ? `and (max-width: ${mediaQuery.max}px)` : ''}{\n}`;
};
