import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { AUTOCOMPLETE_SCHEMA } from './common/AutocompleteSchema';
import basicDSL from './style-guide-dsl/AutocompleteStyleGuide/basicSmallFeatures.json';
export const AutocompleteStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiAutocomplete,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete,
    dirName: 'AutocompleteStyleGuide',
    title: 'AutoComplete',
    classNames: [
        '.MuiAutocomplete-root',
        '.MuiAutocomplete-fullWidth',
        '.Mui-focused',
        '.MuiAutocomplete-tag',
        '.MuiAutocomplete-tagSizeSmall',
        '.MuiAutocomplete-tagSizeMedium',
        '.MuiAutocomplete-hasPopupIcon',
        '.MuiAutocomplete-hasClearIcon',
        '.MuiAutocomplete-inputRoot',
        '.MuiAutocomplete-input',
        '.MuiAutocomplete-inputFocused',
        '.MuiAutocomplete-endAdornment',
        '.MuiAutocomplete-clearIndicator',
        '.MuiAutocomplete-popupIndicator',
        '.MuiAutocomplete-popupIndicatorOpen',
        '.MuiAutocomplete-popper',
        '.MuiAutocomplete-popperDisablePortal',
        '.MuiAutocomplete-paper',
        '.MuiAutocomplete-listbox',
        '.MuiAutocomplete-loading',
        '.MuiAutocomplete-noOptions',
        '.MuiAutocomplete-option',
        '.MuiAutocomplete-groupLabel',
        '.MuiAutocomplete-groupUl',
    ],
    stories: [
        {
            title: 'Basic & Small features',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete,
            baseNodeListDSL: AUTOCOMPLETE_SCHEMA.baseNodeListDSL,
            rootID: AUTOCOMPLETE_SCHEMA.rootID,
            css: AUTOCOMPLETE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        label: 'Basic Autocomplete',
                        placeholder: 'Basic Autocomplete',
                    },
                },
                {
                    props: {
                        disableCloseOnSelect: true,
                        label: 'disableCloseOnSelect',
                        placeholder: 'disableCloseOnSelect',
                    },
                },
                {
                    props: {
                        clearOnEscape: true,
                        label: 'clearOnEscape',
                        placeholder: 'clearOnEscape',
                    },
                },
                {
                    props: {
                        disableClearable: true,
                        label: 'disableClearable',
                        placeholder: 'disableClearable',
                    },
                },
                {
                    props: {
                        includeInputInList: true,
                        label: 'includeInputInList',
                        placeholder: 'includeInputInList',
                    },
                },
                {
                    props: {
                        autocomplete: true,
                        label: 'autocomplete',
                        placeholder: 'autocomplete',
                    },
                },
                {
                    props: {
                        disableListWrap: true,
                        label: 'disableListWrap',
                        placeholder: 'disableListWrap',
                    },
                },
                {
                    props: {
                        openOnFocus: true,
                        label: 'openOnFocus',
                        placeholder: 'openOnFocus',
                    },
                },
                {
                    props: {
                        autoHighlight: true,
                        label: 'autoHighlight',
                        placeholder: 'autoHighlight',
                    },
                },
                {
                    props: {
                        autoSelect: true,
                        label: 'autoSelect',
                        placeholder: 'autoSelect',
                    },
                },
                {
                    props: {
                        disabled: true,
                        label: 'disabled',
                        placeholder: 'disabled',
                    },
                },
                {
                    props: {
                        clearOnBlur: true,
                        label: 'clearOnBlur',
                        placeholder: 'clearOnBlur',
                    },
                },
                {
                    props: {
                        selectOnFocus: true,
                        label: 'selectOnFocus',
                        placeholder: 'selectOnFocus',
                    },
                },
                {
                    props: { multiple: true, label: 'Multiple', placeholder: 'Multiple' },
                },
                {
                    props: {
                        multiple: true,
                        limitTags: 2,
                        label: 'Multiple Limit Tags',
                        placeholder: 'Multiple Limit Tags',
                    },
                },
            ],
            schemaDSLVersion: '0.15.1',
            isAppDSLCommitted: true,
            generatedAppDSL: basicDSL,
        },
    ],
};
