import { AppRuntimeError, ERROR_SCOPES } from '@builder/utils';
import { RuntimePropExecutor } from '../RuntimePropExecutor';
/**
 * Transforms iterator data dsl to js data.
 * @example
 * {{ [1,2,3] }} => [1,2,3]
 * {{ someData }} => [1,2,3]
 */
export const transformIteratorData = (iteratorData, externalState, nodeValidator) => {
    if (!iteratorData) {
        return null;
    }
    const runtimeExecutor = new RuntimePropExecutor({ errorGetter: nodeValidator.errorGetter });
    if (runtimeExecutor.hasPropJsCode(iteratorData)) {
        const transformedIteratorData = runtimeExecutor.transformPropJsCode({
            propValue: iteratorData,
            propPath: ['iterator', 'data'],
            externalState,
        });
        if (!nodeValidator.checkIteratorData(transformedIteratorData, ['iterator', 'data'])) {
            return null;
        }
        return transformedIteratorData;
    }
    if (!nodeValidator.checkIteratorData(iteratorData, ['iterator', 'data'])) {
        return null;
    }
    return iteratorData;
};
/**
 * Transforms iterator DSL name to js string;
 * @example
 * {{ 'item' }} => 'item'
 */
export const transformIteratorName = (iteratorName, externalState, nodeValidator) => {
    const runtimeExecutor = new RuntimePropExecutor({ errorGetter: nodeValidator.errorGetter });
    if (!runtimeExecutor.hasPropJsCode(iteratorName)) {
        return iteratorName;
    }
    const transformedIteratorName = runtimeExecutor.transformPropJsCode({
        propValue: iteratorName,
        propPath: ['iterator', 'name'],
        externalState,
    });
    if (!transformedIteratorName) {
        throw new AppRuntimeError(ERROR_SCOPES.appEngine, `Iterator name expected to be not empty string, but got ${JSON.stringify(transformedIteratorName)}`);
    }
    return transformedIteratorName;
};
