var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { SpeedDial as MaterialSpeedDial, SpeedDialIcon as MaterialSpeedDialIcon, } from '@mui/material';
import { styled } from '@mui/material/styles';
const StyledMaterialSpeedDial = styled(MaterialSpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));
export const SpeedDial = forwardRef((props, ref) => {
    const { id, icon, open, lang, style, onOpen, hidden, onClose, children, openIcon, translate, ariaLabel, direction, FabProps = {}, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = props, restOfProps = __rest(props, ["id", "icon", "open", "lang", "style", "onOpen", "hidden", "onClose", "children", "openIcon", "translate", "ariaLabel", "direction", "FabProps", 'data-test', 'data-node-id', 'data-node-render-path']);
    return (_jsx(StyledMaterialSpeedDial, Object.assign({}, restOfProps, { id: id, ref: ref, lang: lang, open: open, style: style, hidden: hidden, onOpen: onOpen, onClose: onClose, FabProps: FabProps, translate: translate, direction: direction, ariaLabel: ariaLabel, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, icon: _jsx(MaterialSpeedDialIcon, { icon: icon, openIcon: openIcon }) }, { children: children })));
});
