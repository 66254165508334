import { examples } from '@builder/examples';
import { COMPONENT_SCHEMAS, LAYOUT_MODES } from '@builder/schemas';

import { DashboardState } from '../../common';

const getInitialUserAppSchema = () => {
  // The dev only feature to setup which example would load by default
  const customExample = localStorage.getItem('customExample') as keyof typeof examples;

  if (customExample && examples[customExample]) {
    return examples[customExample];
  }

  return examples.emptyExample;
};

export const dashboardInitial: DashboardState = {
  iFrameRefreshed: true,
  copyBuffer: {},
  copyStyle: { componentName: '', styles: {} },
  openAsRootNode: { nodeID: '' },
  loadingConfiguration: false,
  appConfiguration: getInitialUserAppSchema(),
  components: COMPONENT_SCHEMAS,
  operations: {
    selectedID: null,
    dndPreview: null,
    dndInProgress: null,
    dndOverNavigation: false,
    hoveredID: null,
    focusedNodeRenderID: null,
    lastCreatedID: null,
  },
  history: {
    future: [],
    past: [],
  },
  router: { currentRoute: '/', history: [] },
  themeManager: {
    isOpen: false,
  },
  navigator: {
    collapsedNodes: {},
  },
  layout: {
    layoutMode: LAYOUT_MODES.currentRouteNodes,
    layoutSelected: null,
  },
};
