import { AssetTypes, ASSET_TYPES } from '@builder/schemas';

export const getAssetTypeByMime = (mimeType?: string | null): Exclude<AssetTypes, 'folder'> => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/x-png':
    case 'image/webp': {
      return ASSET_TYPES.image;
    }

    case 'image/svg+xml': {
      return ASSET_TYPES.svg;
    }

    case 'application/pdf': {
      return ASSET_TYPES.pdf;
    }

    case 'font/ttf':
    case 'font/otf':
    case 'font/woff':
    case 'font/woff2': {
      return ASSET_TYPES.font;
    }

    default: {
      return ASSET_TYPES.other;
    }
  }
};
