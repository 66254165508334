export function validateIsFormComponent(
  keyPath: (string | number)[],
): {
  isFormComponent: boolean;
  key?: string;
} {
  const formKeyPaths = [
    'inputProps',
    'autocompleteProps',
    'checkboxProps',
    'datePickerProps',
    'selectProps',
    'radioGroupProps',
    'sliderProps',
    'switchProps',
    'timePickerProps',
  ];
  const key = formKeyPaths.find(r => keyPath.includes(r));
  return {
    isFormComponent: key != null,
    key,
  };
}
