/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { omit, values, clone, mapObjIndexed } from 'ramda';
import { ROOT_LAYOUT_CONTAINER_NODE_ID } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_15_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.15.4';
    }
    getChangeList() {
        return ['Adding editable layouts support to old layouts'];
    }
    // protected migrateNodeDSL(nodeDSL: NodeDSL): NodeDSL {
    //   if (nodeDSL.name === COMPONENT_DSL_NAMES.img) {
    //     return {
    //       ...nodeDSL,
    //       name: COMPONENT_DSL_NAMES.BuilderComponentsImage,
    //     };
    //   }
    //   return nodeDSL;
    // }
    // protected migrateSettingDSL(setting: ComponentSettingDSL): ComponentSettingDSL {
    //   if (SettingChecker.Schema.isSettingTypeOfProp(setting) && setting.resetPathBeforeOnChange) {
    //     return {
    //       ...setting,
    //       // typings changed for resetPathBeforeOnChange from [] to [][]
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-ignore
    //       resetPathBeforeOnChange: [setting.resetPathBeforeOnChange],
    //     };
    //   }
    //   return setting;
    // }
    migrateAppDSL(appDSL) {
        const AUTENTICATED_EDITABLE_LAYOUT = {
            'P-keoa0X': {
                name: 'BasePageLayout',
                alias: 'Authenticated Layout',
                props: {
                    content: {
                        nodes: ['FLg8AaQA'],
                    },
                    leftMenu: {
                        nodes: ['LeftNavLayoutMenu_ID'],
                    },
                    header: {
                        nodes: ['LeftNavLayoutHeader_ID'],
                    },
                },
                id: 'P-keoa0X',
                parentID: 'RootLayoutsContainer_ID',
                states: [],
            },
            FLg8AaQA: {
                name: 'BuilderComponentsBox',
                props: {
                    children: {
                        nodes: ['5pX7iZii'],
                    },
                    style: {
                        height: '100%',
                    },
                },
                states: [],
                alias: 'Authenticated Layout Box',
                id: 'FLg8AaQA',
                parentID: 'P-keoa0X',
            },
            '5pX7iZii': {
                name: 'RouterSwitchSymbol',
                componentListMode: 'layout',
                hiddenLayout: false,
                system: true,
                schemaOverride: {
                    interaction: 'only-editable',
                },
                props: {
                    routes: {
                        nodes: ['JvfYDAbc'],
                    },
                    redirects: {
                        nodes: [],
                    },
                },
                states: [],
                alias: 'Authenticated Layout Switch',
                id: '5pX7iZii',
                parentID: 'FLg8AaQA',
            },
            JvfYDAbc: {
                name: 'BuilderComponentsRoute',
                props: {
                    children: {
                        nodes: ['O65mPs1X'],
                    },
                    path: '/__layouts/__dashboard',
                    system: true,
                    authAccess: 'any',
                },
                alias: 'Authenticated Layout Route',
                incrementAlias: false,
                id: 'JvfYDAbc',
                parentID: '5pX7iZii',
            },
            O65mPs1X: {
                name: 'BuilderComponentsRouteLayout',
                props: {
                    children: {
                        nodes: ['jaQ_WMBs'],
                    },
                    style: {
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: '0%',
                        flexDirection: 'column',
                        height: '100%',
                    },
                },
                states: [],
                alias: 'Authenticated Layout Page',
                id: 'O65mPs1X',
                parentID: 'JvfYDAbc',
            },
            jaQ_WMBs: {
                name: 'RouterSwitchSymbol',
                alias: 'View Component 1',
                props: {
                    routes: {
                        nodes: [],
                    },
                    redirects: {
                        nodes: [],
                    },
                },
                id: 'jaQ_WMBs',
                parentID: 'O65mPs1X',
                states: [],
            },
        };
        const LEFT_NAV_LAYOUT_NODE_ID = 'LeftNavLayout_ID';
        if (appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID] !== undefined) {
            appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].alias = 'Authenticated Layout Deprecated';
            const nodeListDSL = this.migrateNodeListDSL(appDSL.nodes, appDSL);
            let newNodeListDSL = Object.assign({}, nodeListDSL);
            newNodeListDSL = Object.assign(Object.assign({}, newNodeListDSL), AUTENTICATED_EDITABLE_LAYOUT);
            const newLayouts = {
                nodes: ['BaseLayout_ID', 'LeftNavLayout_ID', 'P-keoa0X'],
            };
            appDSL.nodes.LeftNavRouterSwitch_ID.hiddenLayout = true;
            appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props = Object.assign({}, omit(['leftMenu', 'header'], appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props));
            appDSL.nodes = Object.assign({}, newNodeListDSL);
            if (typeof appDSL.nodes.LeftNavLayoutMenu_ID !== 'undefined') {
                appDSL.nodes.LeftNavLayoutMenu_ID.parentID = 'P-keoa0X';
            }
            else {
                appDSL.nodes['P-keoa0X'].props = Object.assign({}, omit(['leftMenu'], appDSL.nodes['P-keoa0X'].props));
            }
            if (typeof appDSL.nodes.LeftNavLayoutHeader_ID !== 'undefined') {
                appDSL.nodes.LeftNavLayoutHeader_ID.parentID = 'P-keoa0X';
            }
            else {
                appDSL.nodes['P-keoa0X'].props = Object.assign({}, omit(['header'], appDSL.nodes['P-keoa0X'].props));
            }
            appDSL.nodes[ROOT_LAYOUT_CONTAINER_NODE_ID].props.layouts = newLayouts;
            appDSL.nodes['P-keoa0X'].props.drawerOpen =
                appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props.drawerOpen;
            appDSL.nodes['P-keoa0X'].props.drawerOnClose =
                appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props.drawerOnClose;
            appDSL.nodes['P-keoa0X'].props.drawerAnchor =
                appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props.drawerAnchor;
            appDSL.nodes['P-keoa0X'].props.drawerVariant =
                appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props.drawerVariant;
            appDSL.nodes['P-keoa0X'].props.drawerOpenWidth =
                appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props.drawerOpenWidth;
            appDSL.nodes['P-keoa0X'].props.drawerClosedWidth =
                appDSL.nodes[LEFT_NAV_LAYOUT_NODE_ID].props.drawerClosedWidth;
            appDSL.nodes.jaQ_WMBs.props = Object.assign({}, appDSL.nodes.LeftNavRouterSwitch_ID.props);
            appDSL.nodes.LeftNavRouterSwitch_ID.props = {
                routes: {
                    nodes: [],
                },
                redirects: {
                    nodes: [],
                },
            };
            const ViewComponentNodes = appDSL.nodes.jaQ_WMBs.props;
            values(ViewComponentNodes).forEach(state => {
                if (state !== undefined) {
                    const _clonedNodes = clone(state);
                    _clonedNodes.nodes.forEach((_element) => {
                        appDSL.nodes[_element].parentID = AUTENTICATED_EDITABLE_LAYOUT.jaQ_WMBs.id;
                    });
                }
            });
            const { nodes } = appDSL;
            mapObjIndexed(node => {
                if (node.name === 'BuilderComponentsRoute' &&
                    node.id !== 'ErrorPageRootRoute_ID' &&
                    node.id !== 'NotFoundPageRootRoute_ID' &&
                    node.id !== 'ErrorPageRootRoute_ID' &&
                    node.id !== 'EmptyPageRootRoute_ID' &&
                    node.id !== 'JvfYDAbc' &&
                    node.parentID !== 'EmptyPageRouterSwitch_ID') {
                    node.parentID = AUTENTICATED_EDITABLE_LAYOUT.jaQ_WMBs.id;
                }
            }, nodes);
        }
        return appDSL;
    }
}
export const migration_0_15_4 = new Migration_0_15_4();
