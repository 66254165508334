import { appNodesSchemaSelectors, } from '@builder/schemas';
import { ComponentFileAccessor } from './ComponentFileAccessor';
/**
 * Provides an api to generate extracted components imports.
 */
export class ComponentFileListImportsGenerator {
    constructor(appDSL, componentListDSL) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
    }
    getImportDataList(usedNodeListDSL, currentNodeID) {
        return appNodesSchemaSelectors
            .getNodeListWithFileStructure({
            nodeListDSL: usedNodeListDSL,
            componentListDSL: this.componentListDSL,
        })
            .filter(nodeDSL => nodeDSL.id !== currentNodeID)
            .map(nodeDSL => {
            const componentFileAccessor = new ComponentFileAccessor({
                appDSL: this.appDSL,
                componentListDSL: this.componentListDSL,
                nodeID: nodeDSL.id,
            });
            return componentFileAccessor.getImport();
        });
    }
}
