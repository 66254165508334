import { keys, omit } from 'ramda';
import { RUNTIMESTATE, ACTION_ARGS, } from '@builder/schemas';
import { serialize } from '@builder/utils';
export class GetStatesByArgsType {
    constructor(propValueArgs, externalState) {
        this.args = propValueArgs;
        [this.argValue] = keys(propValueArgs);
        this.externalState = externalState;
    }
    parser(functionValue) {
        return serialize.parse(String(functionValue) || '');
    }
    isGlobalState(globalStates, nameFunction) {
        return keys(globalStates).indexOf(nameFunction) !== -1;
    }
    getState() {
        if (this.argValue === ACTION_ARGS.function &&
            this.externalState.globalState &&
            this.isGlobalState(this.externalState.globalState, this.parser(this.args.function).nameFunction)) {
            const response = Object.assign(Object.assign({}, omit([RUNTIMESTATE.localStates, RUNTIMESTATE.localState], this.externalState)), { localState: {}, localStates: {} });
            return response;
        }
        return this.externalState;
    }
}
