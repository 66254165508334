import { ASSETS_ROOT_FOLDER_ID, ASSET_TYPES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_10_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.10.0';
    }
    getChangeList() {
        return ['Add assets to the DSL structure'];
    }
    migrateAppDSL(appDSL) {
        return Object.assign(Object.assign({}, appDSL), { assets: {
                [ASSETS_ROOT_FOLDER_ID]: {
                    id: ASSETS_ROOT_FOLDER_ID,
                    type: ASSET_TYPES.folder,
                    parentID: null,
                    name: 'assets',
                    children: [],
                },
            } });
    }
}
export const migration_0_10_0 = new Migration_0_10_0();
