import { path, isEmpty } from 'ramda';

import {
  NodeID,
  nodeListSelectors,
  componentListSelectors,
  NodeListDSL,
  StateListDSL,
  stateListSelectors,
  NodeDSL,
  NodeStateConnectionDSL,
  isDialogComponent,
  isLocalDialogComponent,
} from '@builder/schemas';
import { ERROR_SCOPES, SystemError, isNull } from '@builder/utils';

import { parseReactNode } from '../utils';
import { DashboardState, dashboardSelectors } from 'src/store';

import { copyNodeWithChildren, reduceStateListWithIDs } from './componentCopy';
import { updateAppDSL } from './updateAppDSL';
import { globalBoundedStates, globalNestedStates } from './utils';

export const copyBufferApply = (
  state: DashboardState,
  nodeID: NodeID,
  currentPathName: string,
): DashboardState => {
  const newState = structuredClone(state);
  const copyBufferNodes = state.copyBuffer.nodes;

  if (isEmpty(copyBufferNodes) || !copyBufferNodes) {
    return state;
  }

  const componentListDSL = dashboardSelectors.getComponentListDSL(newState);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(newState);
  const stateListDSL = dashboardSelectors.getStateListDSL(newState);
  const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID,
  });

  const targetNodeComponentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
    componentName: targetNodeDSL.name,
  });
  const targetPropName = targetNodeComponentDSL.schema.dndTargetPropName ?? '';
  const targetProp = parseReactNode(path([targetPropName], targetNodeDSL.props));

  // multiple components logic
  const rootCopyBufferNodeDSLs = Object.values(copyBufferNodes as NodeListDSL).filter(node =>
    isNull(node.parentID),
  );

  const rootRouteNodeDSL = Object.values(nodeListDSL).filter(
    node => node.props.path === currentPathName,
  )[0] as NodeDSL;

  const localStateList = rootRouteNodeDSL.context as StateListDSL;

  if (!targetNodeDSL.parentID) {
    throw new SystemError(
      ERROR_SCOPES.dashboard,
      `Parent ID not found for the target ${JSON.stringify(targetNodeDSL)}`,
    );
  }

  const copyBufferStateListDSL = getCopyBufferStateListDSL({
    copyBufferStates: newState.copyBuffer.states || {},
    existingStateListDSL: {
      ...stateListDSL,
      ...localStateList,
    },
  });
  let usedStates: StateListDSL = {};
  const usedBoundedStates: NodeStateConnectionDSL[] = [];
  const isDialogTarget = isDialogComponent(nodeID, {
    ...state.copyBuffer.nodes,
    ...nodeListDSL,
  } as NodeListDSL);

  const isLocalDialogTarget = isLocalDialogComponent(nodeID, {
    ...state.copyBuffer.nodes,
    ...nodeListDSL,
  } as NodeListDSL);

  const isDialogBufferNodes = isDialogComponent(rootCopyBufferNodeDSLs[0].id, {
    ...state.copyBuffer.nodes,
    ...nodeListDSL,
  } as NodeListDSL);

  const newNodes = rootCopyBufferNodeDSLs.map(rootCopyBufferNodeDSL => {
    const childrenIDs = nodeListSelectors.getAllChildrenIDs(copyBufferNodes, {
      nodeID: rootCopyBufferNodeDSL.id,
      componentListDSL,
    });

    const nestedStates = [
      ...(rootRouteNodeDSL?.states ?? []),
      ...(state.copyBuffer.nestedStates ?? []),
    ]?.filter(states =>
      [...childrenIDs, rootCopyBufferNodeDSL.id, targetNodeDSL.id].includes(
        states.componentBoundID as string,
      ),
    );

    const dialogBoundedStates =
      isDialogTarget || isDialogBufferNodes ? globalBoundedStates({ stateListDSL }) : [];

    const boundedStates = [
      ...(rootRouteNodeDSL.states ?? []),
      ...dialogBoundedStates,
      ...(state.copyBuffer.nestedStates ?? []),
    ];
    const {
      nodeDSL: newNodeDSL,
      newStates,
      childrenNodes: newChildrenNodes,
      newBoundedStates,
    } = copyNodeWithChildren({
      nodeDSL: { ...rootCopyBufferNodeDSL, parentID: nodeID },
      nodeListDSL: { ...nodeListDSL, ...state.copyBuffer.nodes } as NodeListDSL,
      componentListDSL,
      stateListDSL: {
        ...stateListDSL,
        ...localStateList,
        ...usedStates,
        ...state.copyBuffer.statesCopyToBUffer,
      },
      replacedStatesList: copyBufferStateListDSL,
      nestedStates: [
        ...(nestedStates ?? []),
        ...globalNestedStates({ stateListDSL, childrenIDs, rootCopyBufferNodeDSL }),
      ],
      boundedStates,
      rootRouteNodeDSL,
      isDialogTarget,
      isLocalDialogTarget,
      isDialogBufferNodes,
    });

    usedStates = {
      ...usedStates,
      ...newStates,
    };

    usedBoundedStates.push(...newBoundedStates);
    return {
      nodeDSL: newNodeDSL,
      newStates,
      newChildrenNodes,
      newBoundedStates,
    };
  });

  const newStatesListWithIDs: StateListDSL = newNodes.reduce((accum, { newStates }) => {
    return {
      ...accum,
      ...reduceStateListWithIDs(newStates),
    };
  }, {});

  const newChildrenNodesListDSL = newNodes.reduce((accum, { newChildrenNodes }) => {
    return {
      ...accum,
      ...newChildrenNodes.reduce((nodesAccum, nodeDSLWithoutID) => {
        return {
          ...nodesAccum,
          [nodeDSLWithoutID.id]: nodeDSLWithoutID,
        };
      }, {}),
    };
  }, {});

  const routeRouteNodeID = rootRouteNodeDSL.id;
  const newContext = newStatesListWithIDs
    ? reduceStateListWithIDs(newStatesListWithIDs)
    : rootRouteNodeDSL.context;
  const newBoundedStates: NodeStateConnectionDSL[] | undefined = newNodes
    ?.map(node => node.newBoundedStates)
    ?.flat()
    ?.filter((boundedState: NodeStateConnectionDSL) => {
      const contextIDs = Object.keys(
        {
          ...nodeListDSL[routeRouteNodeID].context,
          ...newContext,
        } ?? {},
      );
      return contextIDs.includes(boundedState.stateID);
    })
    ?.reduce((acc: NodeStateConnectionDSL[], current: NodeStateConnectionDSL) => {
      const x = acc.find(item => item.stateID === current.stateID);
      if (!x) {
        return acc.concat([current]);
      }

      return acc;
    }, []);

  // here we need to filter out original from the copy buffer only if router path has changed
  if (state.copyBuffer.currentPathName !== currentPathName) {
    const updatedAppDSL = updateAppDSL({
      newState,
      newContext,
      targetNodeDSL,
      targetPropName,
      targetProp,
      newNodes,
      isDialogTarget,
      isDialogBufferNodes,
      state,
      nodeListDSL,
      newChildrenNodesListDSL,
      routeRouteNodeID,
      rootRouteNodeDSL,
      newBoundedStates,
      componentListDSL,
      stateListDSL,
    });

    const copyBufferNodesIDs = Object.keys(copyBufferNodes);
    const nodesWithoutOriginalCopyBuffer = Object?.values(updatedAppDSL?.nodes || {})
      ?.filter(node => !copyBufferNodesIDs?.includes(node.id))
      ?.reduce((acc, node) => ({ ...acc, [node.id]: node }), {});

    return {
      ...newState,
      appConfiguration: {
        ...newState.appConfiguration,
        appDSL: { ...updatedAppDSL, nodes: nodesWithoutOriginalCopyBuffer },
      },
    };
  }

  return {
    ...newState,
    appConfiguration: {
      ...newState.appConfiguration,
      appDSL: updateAppDSL({
        newState,
        newContext,
        targetNodeDSL,
        targetPropName,
        targetProp,
        newNodes,
        isDialogTarget,
        isDialogBufferNodes,
        state,
        nodeListDSL,
        newChildrenNodesListDSL,
        routeRouteNodeID,
        rootRouteNodeDSL,
        newBoundedStates,
        componentListDSL,
        stateListDSL,
      }),
    },
  };
};

const getCopyBufferStateListDSL = ({
  copyBufferStates,
  existingStateListDSL,
}: {
  copyBufferStates: StateListDSL;
  existingStateListDSL: StateListDSL;
}): StateListDSL => {
  const replacedStatesListDSL: StateListDSL = {};

  Object.values(copyBufferStates).reduce((acc, copyBufferStateDSL) => {
    const isStateExist = stateListSelectors.isStateExist(
      { ...existingStateListDSL, ...acc },
      { name: copyBufferStateDSL.name },
    );

    if (!isStateExist) {
      replacedStatesListDSL[copyBufferStateDSL.name] = copyBufferStateDSL;

      return {
        ...acc,
        [copyBufferStateDSL.id]: copyBufferStateDSL,
      };
    }

    const newStateDSL = {
      ...copyBufferStateDSL,
      name: stateListSelectors.calculateStateName(
        { ...existingStateListDSL, ...acc },
        {
          name: copyBufferStateDSL.name,
        },
      ),
    };

    replacedStatesListDSL[copyBufferStateDSL.name] = newStateDSL;

    return {
      ...acc,
      [newStateDSL.id]: newStateDSL,
    };
  }, {} as StateListDSL);

  return replacedStatesListDSL;
};
