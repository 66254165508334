import { useRef, useCallback } from 'react';

import { useAppDispatch } from 'src/providers/ReduxProvider';
import { noop } from 'src/shared/utils';
import { DASHBOARD_EVENTS } from 'src/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOverlayHover = ({
  enableOnMove,
  enableOnLeave,
  selector,
}: {
  enableOnMove: boolean;
  enableOnLeave: boolean;
  selector: string;
}) => {
  const send = useAppDispatch();
  const nodeHoverRef = useRef<string | null>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseMove = useCallback(
    enableOnMove
      ? event => {
          const mouseOverNodeElID = (event.target as HTMLElement)
            .closest(selector)
            ?.getAttribute('data-node-id');
          if (mouseOverNodeElID && nodeHoverRef.current !== mouseOverNodeElID) {
            nodeHoverRef.current = mouseOverNodeElID;
            send({
              type: DASHBOARD_EVENTS.componentHover,
              id: mouseOverNodeElID,
            });
          }
        }
      : noop,
    [send, enableOnMove],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMouseLeave = useCallback(
    enableOnLeave
      ? () => {
          nodeHoverRef.current = null;
          send({ type: DASHBOARD_EVENTS.componentHoverRemove });
        }
      : noop,
    [enableOnLeave, send],
  );

  return { onMouseMove, onMouseLeave };
};
