import React, { createContext, useContext } from 'react';

import { ERROR_SCOPES, isNull, SystemError, EventEmitter } from '@builder/utils';

export const EventEmitterContext = createContext<EventEmitter | null>(null);

const eventEmitter = new EventEmitter();

export const EventEmitterProvider: React.FC = ({ children }) => {
  return (
    <EventEmitterContext.Provider value={eventEmitter}>{children}</EventEmitterContext.Provider>
  );
};

export const useEventEmitter = (): EventEmitter => {
  const emitter = useContext(EventEmitterContext);

  if (isNull(emitter)) {
    throw new SystemError(ERROR_SCOPES.uiBuilder, 'Event Emitter must be initialized');
  }

  return emitter;
};
