import { QueryResult, useQuery } from '@apollo/client';

import { DeploysListQuery, DeploysListQueryVariables } from '../__generated__';
import { DEPLOYS_LIST_QUERY } from '../fragments';
import { useCurrentWorkspaceID } from 'src/shared/hooks';

export const useDeployList = (): QueryResult<DeploysListQuery, DeploysListQueryVariables> => {
  const { workspaceID } = useCurrentWorkspaceID();

  return useQuery<DeploysListQuery, DeploysListQueryVariables>(DEPLOYS_LIST_QUERY, {
    variables: {
      workspaceId: workspaceID as string,
      sort: { createdAt: 'DESC' },
    },
    skip: !workspaceID,
    fetchPolicy: 'no-cache',
  });
};
