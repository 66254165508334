import { BASE_COMPONENTS_NAMES, COMPONENT_DSL_IMPORT_SOURCES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_CALLBACK_TYPES, STATE_TYPES, STATE_SCOPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsFileUploadBaseSettings } from './BuilderComponentsFileUploadBase.settings';
export const BuilderComponentsFileUploadBase = {
    name: BASE_COMPONENTS_NAMES.BuilderComponentsFileUploadBase,
    displayName: 'File Upload 8Base',
    icon: 'fileUploadBase',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: COMPONENT_DSL_IMPORT_SOURCES.builderComponents,
        codeEngineImportName: 'shared/components',
        componentName: 'FileUploadBase',
    },
    schema: {
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.object,
                    name: 'FilePicker8Base',
                    defaultValue: {
                        fileUploaded: {
                            filename: '',
                            fileId: '',
                        },
                        upload: 'fn',
                        progress: 0,
                    },
                },
            ],
            props: {
                value: {},
                disabled: false,
                setMethods: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FilePicker8Base.setValue(methods)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onSuccess: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, apiToken: { type: COMPONENT_DSL_PROP_TYPES.string }, setMethods: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'methods',
                        path: [0],
                    },
                ],
            }, uploadOnChange: { type: COMPONENT_DSL_PROP_TYPES.boolean }, accept: { type: COMPONENT_DSL_PROP_TYPES.string }, maxFileSize: { type: COMPONENT_DSL_PROP_TYPES.number }, workspaceId: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsFileUploadBaseSettings,
};
