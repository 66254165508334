import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { BASIC_STYLE_TOOLTIP, NS_ID, pickSettings, removeAllDividers } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const MaterialAccordionActionsSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: getPropertySections(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'disableSpacing',
                            keyPath: ['disableSpacing'],
                            label: 'Disable Spacing',
                        },
                    ],
                },
                getDivider(),
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonAccordionActionsSettings = () => pipe(pickSettings([
    [NS_ID.style.root, NS_ID.style.basic, 'disableSpacing'],
    [NS_ID.style.root, NS_ID.style.spacing.root, NS_ID.style.spacing.editor],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.fontFamily],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.fontSize],
    [NS_ID.style.root, NS_ID.style.font.root, NS_ID.style.font.color],
], { withStructure: false }), removeAllDividers)(values(MaterialAccordionActionsSettings).flat());
