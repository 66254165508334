import { omit } from 'ramda';
import { nodeListSelectors, componentListSelectors } from '../../selectors';
import { transformReactNodeProps } from './transformNodeListToTree';
const transformSymbolNodeListToTree = ({ nodeListDSL, componentListDSL, }) => {
    const nodeDSL = nodeListSelectors.getRootNodeDSL(nodeListDSL);
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    return Object.assign(Object.assign({}, omit(['id', 'parentID'], nodeDSL)), { props: transformReactNodeProps({
            nodeListDSL,
            componentListDSL,
            nodeProps: nodeDSL.props,
            propsSchema: componentDSL.schema.props,
        }) });
};
export const transformSymbolListToTree = ({ userComponentDSL, componentListDSL, }) => {
    return Object.assign(Object.assign({}, userComponentDSL), { schema: Object.assign(Object.assign({}, userComponentDSL.schema), { symbolNodes: transformSymbolNodeListToTree({
                nodeListDSL: userComponentDSL.schema.symbolNodes || {},
                componentListDSL,
            }) }) });
};
