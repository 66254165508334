import { isEmpty, omit, uniq, values } from 'ramda';
import { TIMES_USED, TIMES_USED_BY_GROUP } from './constants';
import { reorderStates } from './reorderStates';
export const getTimesUsed = (state, states) => {
    let timesUsed = 0;
    for (const innerState of states) {
        const innerStateWithoutName = omit(['name'], innerState);
        if (state.name !== innerState.name &&
            (JSON.stringify(innerStateWithoutName).includes(`${state.name}.`) ||
                JSON.stringify(innerStateWithoutName).includes(`${state.name}[`) ||
                JSON.stringify(innerStateWithoutName).includes(`${state.name}(`) ||
                JSON.stringify(innerStateWithoutName).includes(`${state.name}?`))) {
            timesUsed += 1;
        }
    }
    return timesUsed;
};
export const sortFromLargestToSmallest = (statesToSort) => {
    statesToSort.sort((a, b) => {
        return b.timesUsed - a.timesUsed;
    });
};
export const statesListIsEmpty = (states) => {
    return isEmpty(states) || values(states).length === 1;
};
export const getFirstItemOfTheArray = (statesMetadata) => {
    return statesMetadata[0];
};
export const getLastItemOfTheArray = (statesMetadata) => {
    return statesMetadata[statesMetadata.length - 1];
};
export const reduceMatrixToArray = (groupOfStatesByTimesUsedByLevels) => {
    return uniq(groupOfStatesByTimesUsedByLevels).flat();
};
export const getOrderedStatesAsStateDSLList = (groupOfStatesByTimesUsedByLevels, onAppAuditNotifications) => reorderStates(reduceMatrixToArray(groupOfStatesByTimesUsedByLevels), onAppAuditNotifications).map((state) => omit([TIMES_USED, TIMES_USED_BY_GROUP], state));
