import React, { useCallback } from 'react';

import { WarningAmberOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import {
  nodeListSelectors,
  NodeRouteDSL,
  EMPTY_PAGE_ROUTER_SWITCH_NODE_ID as DEFAULT_LAYOUT_ID,
} from '@builder/schemas';

import { DialogContentWithIcon } from 'src/dialogs';
import { useAppDispatch, useComponentListDSL, useNodeListDSL } from 'src/providers/ReduxProvider';
import { Button } from 'src/shared/components';
import { DASHBOARD_EVENTS, UI_BUILDER_EVENTS } from 'src/store';

export const LayoutDeleteDialog = ({
  opened,
  currentRoute,
  close,
}: {
  currentRoute: NodeRouteDSL;
  opened: boolean;
  close: () => void;
}): React.ReactElement => {
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();
  const currentPathName = currentRoute.props.path;
  const deleteCurrentLayout = useCallback((): void => {
    let isLayoutUsed = false;
    if (currentRoute.id) {
      const pagesLayoutIDs = nodeListSelectors.getAllChildrenLayoutRouteNodes(nodeListDSL, {
        componentListDSL,
        nodeID: currentRoute.id,
      });

      if (pagesLayoutIDs.length !== 0) {
        isLayoutUsed = true;
        send({
          type: DASHBOARD_EVENTS.routeLayoutUpdate,
          routeIDs: pagesLayoutIDs.map(route => route.id),
          layoutID: DEFAULT_LAYOUT_ID,
        });
      }

      send({
        type: DASHBOARD_EVENTS.componentRemove,
        id: currentRoute.id,
        currentPathName,
      });

      send({
        type: UI_BUILDER_EVENTS.successAppNotify,
        successMessage: isLayoutUsed
          ? 'The layout was successfully deleted; its associations with the project pages were removed too.'
          : 'The layout was successfully deleted.',
      });
    }

    close();
  }, [close, componentListDSL, currentPathName, currentRoute.id, nodeListDSL, send]);

  const currentRouteContentDSL = currentRoute
    ? nodeListSelectors.getNodeDSL(nodeListDSL, {
        nodeID: currentRoute.props.children.nodes[0],
      })
    : null;

  const currentNodeContentAlias = currentRouteContentDSL?.alias || '';
  return (
    <Dialog open={opened} onClose={close}>
      <DialogTitle>Delete Layout</DialogTitle>
      <DialogContentWithIcon>
        <WarningAmberOutlined color="error" />
        <DialogContentText>
          Delete Layout &quot;{currentNodeContentAlias}&quot;
          <Typography variant="body3" sx={{ mt: '4px' }}>
            Are you sure you want to delete the current Layout?
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="default" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={deleteCurrentLayout}
          data-test="deleteRouteDialog.submitBtn"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
