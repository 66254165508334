import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import { PriorityHigh as PriorityHighIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { DialogContentWithIcon } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';

const DIALOG_ID = DASHBOARD_DIALOGS.UNSAVED_CHANGES_DIALOG_ID;

export type UnsavedChangesDialogArgs = {
  onDecline: () => void;
  onSave: () => void;
};

const StyledTypography = styled(Typography)`
  font-size: 13px;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

export const UnsavedChangesDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<UnsavedChangesDialogArgs>(DIALOG_ID);

  const handleClose = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const handleDecline = useCallback(() => {
    args.onDecline();
    closeDialog(DIALOG_ID);
  }, [args, closeDialog]);

  const handleSave = useCallback(() => {
    args.onSave();
    closeDialog(DIALOG_ID);
  }, [args, closeDialog]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle>Unsaved changes</DialogTitle>
      <DialogContentWithIcon>
        <PriorityHighIcon color="warning" />
        <DialogContentText>
          Unsaved changes
          <StyledTypography variant="body1">
            Do you want to save your project before performing this operation?
          </StyledTypography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="default" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" color="default" onClick={handleDecline}>
          Don’t save
        </Button>
        <Button variant="contained" color="success" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
