import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
const PREFIX_PATH = ['appBarProps'];
export const AppBarSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                ...getBasicPropsSettings(['appBarProps'], true),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['appBarProps', 'color'],
                            options: ['primary', 'secondary', 'transparent', 'default', 'inherit'].map(createSelectOptionByValue),
                            label: 'Color',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['toolbarProps', 'variant'],
                            options: ['regular', 'dense'].map(createSelectOptionByValue),
                            label: 'Variant',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['toolbarProps', 'disableGutters'],
                            label: 'Disable Gutters',
                        },
                    ],
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.size.root, NS_ID.style.size.minHeight], setting => (Object.assign(Object.assign({}, setting), { keyPath: ['toolbarProps', 'style', 'minHeight'] }))))(getStyleSections(PREFIX_PATH)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
