import { useLayoutEffect } from 'react';

import { useDragLayer, XYCoord } from 'react-dnd';

import { isArray } from '@builder/utils';

import { getPreviewTransform } from './common';
import { DraggableItem } from './logic';

const DRAGGING_CLASS = 'dragging';
const getOffsetWithCompensation = (
  offset: XYCoord | null,
  compensation?: XYCoord,
): XYCoord | null => {
  if (offset === null) {
    return null;
  }

  if (compensation) {
    return {
      x: offset.x + compensation.x,
      y: offset.y + compensation.y,
    };
  }

  return offset;
};

/**
 * Custom dnd layer monitors whatever is being dragged at the moment
 * and if the item is over the layer -- layer is in control of the item presentation!
 *
 * Custom layer renders a draggable over it.
 */
export const useBaseLayer = (props: {
  draggablePreviewRef: React.MutableRefObject<HTMLDivElement | null>;
  previewSizeRef: React.MutableRefObject<{ width: number; height: number }>;
  offsetCompensation?: XYCoord;
}): {
  item: DraggableItem;
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
  isDragging: boolean;
} => {
  const offsetCompensation = props.offsetCompensation || { x: 0, y: 0 };
  const dragLayerProps = useDragLayer(monitor => {
    const initial = monitor.getInitialClientOffset();
    const current = monitor.getClientOffset();

    return {
      item: monitor.getItem() as DraggableItem,
      initialOffset: getOffsetWithCompensation(initial, offsetCompensation),
      currentOffset: getOffsetWithCompensation(current, offsetCompensation),
      isDragging: monitor.isDragging(),
    };
  });

  const { currentOffset, initialOffset, isDragging, item } = dragLayerProps;
  const { draggablePreviewRef, previewSizeRef } = props;
  const isOverlayItem = isArray(item);

  useLayoutEffect(
    // forcing cursor to DRAGGING_CLASS state everywhere
    function overrideCursor() {
      if (dragLayerProps.isDragging) {
        document.body.classList.add(DRAGGING_CLASS);
      } else {
        document.body.classList.remove(DRAGGING_CLASS);
      }
    },
    [dragLayerProps.isDragging],
  );

  useLayoutEffect(
    // updating preview transform style every tick of movement
    function updatePreviewTransform() {
      if (draggablePreviewRef.current && isDragging) {
        const style = getPreviewTransform({
          initialOffset,
          currentOffset,
          previewWidth: previewSizeRef.current.width,
          previewHeight: previewSizeRef.current.height,
          scaleDown: isOverlayItem,
        });

        const el = draggablePreviewRef.current;
        if (style.transform) {
          el.style.transform = style.transform;
        }
      }
    },
    // we are only interested in coordinates changing
    // eslint-disable-next-line
    [initialOffset, currentOffset],
  );

  return dragLayerProps;
};
