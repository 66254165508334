import { Migration } from '../Migration';
class Migration_0_18_7 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.7';
    }
    getChangeList() {
        return ['Updating props in text, typography and heading components'];
    }
    migrateAppDSL(appDSL) {
        var _a, _b, _c;
        const typography = (_b = (_a = appDSL.theme) === null || _a === void 0 ? void 0 : _a.css) === null || _b === void 0 ? void 0 : _b.typography;
        if (typography && !typography.includes('.MuiTypography')) {
            return appDSL;
        }
        const newTypography = typography && typography.replace(/\.MuiTypography/g, '.MuiTypography-root.MuiTypography');
        return Object.assign(Object.assign({}, appDSL), { theme: Object.assign(Object.assign({}, appDSL.theme), { css: Object.assign(Object.assign({}, (_c = appDSL === null || appDSL === void 0 ? void 0 : appDSL.theme) === null || _c === void 0 ? void 0 : _c.css), { typography: newTypography === null || newTypography === void 0 ? void 0 : newTypography.replace('.MuiTypography-root.MuiTypography-root', '.MuiTypography-root') }) }) });
    }
}
export const migration_0_18_7 = new Migration_0_18_7();
