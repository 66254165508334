import { pick } from 'ramda';
import { Migration } from '../Migration';
const COMMON_PROPS = ['id', 'type', 'name', 'title'];
const COMMON_FILE_PROPS = ['parentID', 'backendFileID', 'description', 'size'];
const FOLDER_PROPS = ['parentID', 'children'];
const IMAGE_PROPS = ['alt', 'height', 'width'];
class Migration_0_11_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.4';
    }
    getChangeList() {
        return [
            'We do not keep a "downloadUrl" prop in the DSL anymore',
            'A "downloadUrl" prop of an asset was renamed into "src"',
        ];
    }
    migrateAssetDSL(assetDSL, appDSL) {
        switch (assetDSL.type) {
            case 'folder': {
                return pick([...COMMON_PROPS, ...FOLDER_PROPS], assetDSL);
            }
            case 'image': {
                return pick([...COMMON_PROPS, ...COMMON_FILE_PROPS, ...IMAGE_PROPS], assetDSL);
            }
            default: {
                return pick([...COMMON_PROPS, ...COMMON_FILE_PROPS], assetDSL);
            }
        }
    }
}
export const migration_0_11_4 = new Migration_0_11_4();
