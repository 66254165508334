import React, { useCallback, useEffect } from 'react';

import { Grid } from '@mui/material';
import _ from 'lodash';

import {
  NodePropValue,
  componentSettingSelectors,
  ComponentSettingPrimaryKeyPropDSL,
} from '@builder/schemas';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { useSelectedNodeID } from 'src/providers/ReduxProvider';
import { Autocomplete } from 'src/shared/components';

export type DataGeneratorColumnsTypeProps = {
  setting: ComponentSettingPrimaryKeyPropDSL;
  dataTestPrefix?: string;
  keyValue?: NodePropValue | null;
  onChange: (propData: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  'data-test'?: string;
};

type DataGridColumn = {
  label: string;
  value: string;
};

export const DataGeneratorColumnsType: React.FC<DataGeneratorColumnsTypeProps> = ({
  setting,
  keyValue,
  onChange,
  'data-test': dataTest,
}): JSX.Element => {
  const { appDSL } = useNodeSettingsProps();
  const targetID = useSelectedNodeID();
  const [columns, setColumns] = React.useState<{ label: string; value: string }[]>([]);
  const [columnIdObject, setColumnIdObject] = React.useState<DataGridColumn | null>(null);

  const updateProp = useCallback(
    (value: unknown) => {
      if (_.isUndefined(value) || value === '') {
        onChange({
          keyValue: null,
          keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
        });
        return;
      }

      onChange({
        keyValue: value,
        keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
      });
    },
    [onChange, setting],
  );

  useEffect(() => {
    if (_.isNull(keyValue)) {
      setColumnIdObject({ label: '', value: '' });
    }

    const appDSLColumns = appDSL?.nodes[targetID as string].props?.columns || null;
    let transformColumns = null;
    if (appDSLColumns) {
      transformColumns = (appDSLColumns as Record<string, string>[]).map(column => ({
        label: column?.headerName,
        value: column?.field,
      }));
    }

    if (keyValue) {
      const columnIdElement = columns.find(column => column.value === keyValue);
      setColumnIdObject(columnIdElement as DataGridColumn);
    }

    if (transformColumns && !_.isEqual(transformColumns, columns)) {
      setColumns(transformColumns);
    }
  }, [appDSL, targetID, columns, keyValue]);

  useEffect(() => {
    if (columns.length && _.isNull(columnIdObject)) {
      const columnIdElement = columns.find(column => column.value === 'id');

      if (columnIdElement) {
        setColumnIdObject(columnIdElement);
        updateProp(columnIdElement.value);
      }
    }
  }, [columns, columnIdObject, updateProp]);

  return (
    <Grid item xs={12}>
      <Autocomplete
        fullWidth
        value={columnIdObject?.value || (keyValue as string)}
        label={setting.label}
        onChange={updateProp}
        options={columns}
        data-test={dataTest}
      />
    </Grid>
  );
};
