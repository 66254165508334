import React, { useCallback, useState } from 'react';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { ValueOf } from '@builder/utils';

import { CustomTextFieldProps } from '../TextField';
import { Icon, TextField } from 'src/shared/components';

const INLINE_TEXT_FIELD_MODES = {
  edit: 'edit',
  preview: 'preview',
} as const;

export type InlineTextFieldModeTypes = ValueOf<typeof INLINE_TEXT_FIELD_MODES>;

const PreviewContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Container = styled.div<{ mode: InlineTextFieldModeTypes }>`
  position: relative;
  max-width: 270px;

  .MuiTypography-h6 {
    line-height: ${({ theme }) => theme.spacing(2)};
    padding-top: 2px;
    padding-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.text.primary};
    letter-spacing: 0.2px;
  }
  ${PreviewContainer} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    visibility: ${({ mode }) => (mode === INLINE_TEXT_FIELD_MODES.preview ? 'visible' : 'hidden')};
  }

  .MuiInput-input {
    line-height: 16px;
    font-size: 16px;
    font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 0;
  }

  .MuiTextField-root {
    position: absolute;
    top: 0px;
    opacity: ${({ mode }) => (mode === INLINE_TEXT_FIELD_MODES.edit ? 1 : 0)};
    height: auto;
    width: auto;
  }
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

const StyledTypography = styled(Typography)`
  max-width: 320px;
  min-height: 20px;
`;

export const InlineTextField: React.FC<
  Pick<
    CustomTextFieldProps,
    | 'value'
    | 'onDebounceChange'
    | 'onChange'
    | 'skipDebounce'
    | 'multiline'
    | 'helperText'
    | 'title'
    | 'data-test'
    | 'error'
    | 'autoFocus'
    | 'disabled'
    | 'id'
  > & {
    onBlur?: () => void;
    editFirst?: boolean;
  }
> = ({ value, title, onBlur, editFirst = false, disabled = false, ...restProps }) => {
  const [mode, setMode] = useState<InlineTextFieldModeTypes>(
    editFirst ? INLINE_TEXT_FIELD_MODES.edit : INLINE_TEXT_FIELD_MODES.preview,
  );
  const handleOnBlur = useCallback(() => {
    setMode(INLINE_TEXT_FIELD_MODES.preview);
    onBlur?.();
  }, [onBlur]);

  return (
    <Container mode={mode}>
      <PreviewContainer>
        <StyledTypography variant="h6">{title}</StyledTypography>
        <If condition={!disabled}>
          <Icon name="pen" />
        </If>
      </PreviewContainer>
      <TextField
        value={value}
        onBlur={handleOnBlur}
        onFocus={() => setMode(INLINE_TEXT_FIELD_MODES.edit)}
        title={title}
        multiline={false}
        disabled={disabled}
        {...restProps}
      />
    </Container>
  );
};
