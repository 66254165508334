import { useMemo } from 'react';

import { NodeDSL, RenderPathData, componentListSelectors } from '@builder/schemas';
import { isArray } from '@builder/utils';

import { getInvalidMessage } from '../common';
import { OverlayState } from 'src/features/dashboard/overlay/common/types';
import {
  useComponentListDSL,
  useDndInProgress,
  useDndPreview,
  useFocusedNodeRenderID,
  useIsHovered,
  useNodeByID,
  useNodeListDSL,
  useSelectedNodeID,
} from 'src/providers/ReduxProvider';
import { MOVE_VARIANT } from 'src/store';

export const useOverlayState = ({
  nodeDSL,
  renderPathData,
}: {
  nodeDSL: NodeDSL;
  renderPathData: RenderPathData | null;
}): OverlayState => {
  const componentListDSL = useComponentListDSL();
  const nodeListDSL = useNodeListDSL();
  const dndPreview = useDndPreview();
  const dndInProgress = useDndInProgress();
  const dndTargetNode = useNodeByID(dndPreview?.target?.nodeID || '');
  const selectedID = useSelectedNodeID();
  const focusedNodeRenderID = useFocusedNodeRenderID();
  const isFocused = renderPathData?.renderID === focusedNodeRenderID;
  const isSelected = nodeDSL.id === selectedID || selectedID?.includes(nodeDSL.id);
  const isHovered = useIsHovered(nodeDSL.id);

  const overlayState = useMemo<Omit<OverlayState, 'isFocused'>>(
    function reduceState() {
      if (dndInProgress) {
        const showAsSource = isArray(dndInProgress.sourceID)
          ? dndInProgress.sourceID.includes(nodeDSL.id)
          : dndInProgress.sourceID === nodeDSL.id;

        if (showAsSource) {
          return { type: 'dnd-source' };
        }
      }

      if (dndInProgress && dndPreview) {
        const kind = dndPreview.type;
        const showAsTarget = dndPreview.target.nodeID === nodeDSL.id;

        if (showAsTarget) {
          const dragTargetDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: dndInProgress.nodeName,
          });

          const dropTargetDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: dndTargetNode.name,
          });

          const invalidMessage = getInvalidMessage(nodeListDSL, {
            dragTargetDSL,
            dropTargetDSL,
            dropTargetNode: dndTargetNode,
          });

          switch (kind) {
            case MOVE_VARIANT.before:
            case MOVE_VARIANT.after: {
              return dndPreview.kind === 'valid'
                ? { type: 'dnd-target-valid', kind }
                : { type: 'dnd-target-invalid', kind, invalidMessage };
            }
            case MOVE_VARIANT.into: {
              if (
                dndTargetNode.name === 'BuilderComponentsForm' &&
                !dropTargetDSL.name.includes('Form') &&
                !dragTargetDSL.name.includes('Button')
              )
                return dndPreview.kind === 'valid'
                  ? { type: 'dnd-target-valid', kind }
                  : { type: 'dnd-target-invalid', kind, invalidMessage };

              return dndPreview.kind === 'valid'
                ? { type: 'dnd-target-valid', kind }
                : { type: 'dnd-target-invalid', kind, invalidMessage };
            }

            default:
              return { type: 'dnd-target-invalid', kind, invalidMessage };
          }
        }

        const isTargetParent = dndTargetNode?.parentID === nodeDSL.id;
        const isTargetingItself = dndPreview.target.nodeID === dndInProgress.sourceID;
        if (isTargetParent && !isTargetingItself) {
          if (dndPreview.type !== MOVE_VARIANT.into) {
            return dndPreview.kind === 'invalid'
              ? { type: 'dnd-target-invalid-container' }
              : { type: 'dnd-target-valid-container' };
          }
        }
      }

      if (isSelected && !dndInProgress) {
        return { type: 'selected-idle' };
      }

      if (isHovered && !dndInProgress) {
        return { type: 'hovered' };
      }

      return { type: 'idle' };
    },
    [
      dndInProgress,
      dndPreview,
      isSelected,
      isHovered,
      nodeDSL.id,
      dndTargetNode,
      componentListDSL,
      nodeListDSL,
    ],
  );

  const { type, kind } = overlayState as Record<string, unknown>;

  return useMemo<OverlayState>(
    () => ({ ...overlayState, isFocused } as OverlayState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kind, type, dndInProgress?.nodeName, isFocused],
  );
};
