import { COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { ROOT_SETTINGS } from './root.settings';
export const root = {
    name: COMPONENT_DSL_NAMES.root,
    displayName: 'Root',
    codeName: 'Root',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.root,
    schema: {
        codeFileStructure: {
            componentPath: 'RootContent',
            postfix: 'Content',
        },
        dndTargetPropName: 'children',
        accessibility: {
            disableCopying: true,
        },
        props: {
            children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            },
            dialogs: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.DialogSymbol],
            },
        },
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.div,
            props: {
                style: {
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    height: '100%',
                },
                children: '{{ [symbolProps.children, symbolProps.dialogs] }}',
            },
        },
    },
    settings: ROOT_SETTINGS,
};
