/* eslint-disable react-hooks/rules-of-hooks */
import { STATE_TYPES, STATE_REQUEST_VARIANTS, } from '@builder/schemas';
import { useStringState, useNumberState, useObjectState, useArrayState } from './predefined-states';
import { useAppEngineBooleanState, useAppEngineRestState, useAppEngineFunctionState, useAppEngineLazyQueryState, useAppEngineMutationState, useAppEngineQueryState, } from './states';
/**
 * @returns Runtime state based on appropriate DSL description.
 */
const useAppEngineStateFactory = ({ appStateDSL, componentListDSL, resourceListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    // We can use hooks in conditions because we remount the application on every state change.
    if (appStateDSL.type === STATE_TYPES.boolean) {
        return useAppEngineBooleanState(appStateDSL);
    }
    if (appStateDSL.type === STATE_TYPES.string) {
        return useStringState(appStateDSL.defaultValue);
    }
    if (appStateDSL.type === STATE_TYPES.number) {
        return useNumberState(appStateDSL.defaultValue);
    }
    if (appStateDSL.type === STATE_TYPES.object) {
        return useObjectState(appStateDSL.defaultValue);
    }
    if (appStateDSL.type === STATE_TYPES.array) {
        return useArrayState(appStateDSL.defaultValue);
    }
    if (appStateDSL.type === STATE_TYPES.function) {
        return useAppEngineFunctionState(appStateDSL, appRuntimeState, libraries);
    }
    if (appStateDSL.type === STATE_TYPES.query) {
        if (appStateDSL.variant === STATE_REQUEST_VARIANTS.query) {
            return useAppEngineQueryState({
                appStateDSL,
                resourceListDSL,
                componentListDSL,
                assetListDSL,
                appRuntimeState,
                libraries,
            });
        }
        if (appStateDSL.variant === STATE_REQUEST_VARIANTS.lazyQuery) {
            return useAppEngineLazyQueryState({
                appStateDSL,
                resourceListDSL,
                componentListDSL,
                assetListDSL,
                appRuntimeState,
                libraries,
            });
        }
        if (appStateDSL.variant === STATE_REQUEST_VARIANTS.mutation) {
            return useAppEngineMutationState({
                appStateDSL,
                resourceListDSL,
                componentListDSL,
                assetListDSL,
                appRuntimeState,
                libraries,
            });
        }
        if (appStateDSL.variant === STATE_REQUEST_VARIANTS.rest) {
            return useAppEngineRestState({
                appStateDSL,
                resourceListDSL,
                componentListDSL,
                assetListDSL,
                appRuntimeState,
                libraries,
            });
        }
    }
    return null;
};
/**
 * Transform global and local states DSL declarations to the appropriate js state
 */
export const useGlobalAndLocalStates = ({ globalStateList, localStateList, componentListDSL, resourceListDSL, assetListDSL, appRuntimeState, libraries, }) => {
    const globalStateResult = globalStateList === null || globalStateList === void 0 ? void 0 : globalStateList.reduce((accum, stateDSL) => {
        const stateResult = useAppEngineStateFactory({
            appStateDSL: stateDSL,
            componentListDSL,
            resourceListDSL,
            assetListDSL,
            appRuntimeState: Object.assign(Object.assign({}, appRuntimeState), { temporaryState: accum }),
            libraries,
        });
        if (!stateResult) {
            return accum;
        }
        return Object.assign(Object.assign({}, accum), { [stateDSL.name]: stateResult });
    }, {});
    const localStatesResult = localStateList === null || localStateList === void 0 ? void 0 : localStateList.reduce((accum, stateDSL) => {
        const stateResult = useAppEngineStateFactory({
            appStateDSL: stateDSL,
            componentListDSL,
            resourceListDSL,
            assetListDSL,
            appRuntimeState: Object.assign(Object.assign({}, appRuntimeState), { globalState: globalStateResult, temporaryState: accum }),
            libraries,
        });
        if (!stateResult) {
            return accum;
        }
        return Object.assign(Object.assign({}, accum), { [stateDSL.name]: stateResult });
    }, {});
    return {
        globalState: globalStateResult,
        localStates: localStatesResult,
    };
};
