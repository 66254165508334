var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
export const Box = forwardRef((_a, ref) => {
    var { children, style = {}, display, overflow, textOverflow, visibility, whiteSpace, flexDirection, flexWrap, justifyContent, alignItems, alignContent, order, flex, flexGrow, flexShrink, alignSelf, color, backgroundColor, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, htmlElement = 'div', id, title, className, lang, translate } = _a, rest = __rest(_a, ["children", "style", "display", "overflow", "textOverflow", "visibility", "whiteSpace", "flexDirection", "flexWrap", "justifyContent", "alignItems", "alignContent", "order", "flex", "flexGrow", "flexShrink", "alignSelf", "color", "backgroundColor", 'data-test', 'data-node-id', 'data-node-render-path', "htmlElement", "id", "title", "className", "lang", "translate"]);
    return React.createElement(htmlElement, Object.assign(Object.assign({ ref }, rest), { 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id,
        title,
        className,
        lang,
        translate, style: Object.assign({ display,
            overflow,
            textOverflow,
            visibility,
            whiteSpace,
            flexDirection,
            flexWrap,
            justifyContent,
            alignItems,
            alignContent,
            order,
            flex,
            flexGrow,
            flexShrink,
            alignSelf,
            color,
            backgroundColor, minHeight: '36 px' }, style) }), children);
});
