import { nodeListSelectors } from '@builder/schemas';
import { isArray } from '@builder/utils';

import {
  useAppDispatch,
  useSelectedNodeID,
  useNodeListDSL,
  useComponentListDSL,
  useAppDSLStates,
} from 'src/providers/ReduxProvider';
import { useCurrentPathname } from 'src/shared/hooks';
import { DASHBOARD_EVENTS } from 'src/store';

type NodeActions = {
  isDeletable: boolean;
  deleteFunc: () => void;
  nodeID: string;
};
export const useGetDeletionActionsInfo = (): Record<string, NodeActions> | undefined => {
  const send = useAppDispatch();
  const selectedID = useSelectedNodeID();
  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();
  const currentPathName = useCurrentPathname();
  const userAppStates = useAppDSLStates();

  if (isArray(selectedID)) {
    const isComponentDeletableList: Record<string, NodeActions> = selectedID.reduce(
      (acc, currentNodeID) => {
        const isDeletable = nodeListSelectors.isNodeDeletable(nodeListDSL, {
          nodeID: currentNodeID,
          componentListDSL,
        });

        const deleteNode = () =>
          send({
            type: DASHBOARD_EVENTS.componentRemove,
            id: currentNodeID,
            currentPathName,
            connectedStates: nodeListSelectors.getConnectedStates(nodeListDSL, {
              nodeID: currentNodeID,
              componentListDSL,
              stateListDSL: userAppStates,
            }),
          });

        return {
          ...acc,
          [currentNodeID]: { isDeletable, deleteFunc: deleteNode, nodeID: currentNodeID },
        };
      },
      {},
    );

    return isComponentDeletableList;
  }
};
