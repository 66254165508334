import { traverseRenderParents } from './renderPath';
export const getAllImmediateRenderNodeChildren = (nodeElementDataRecords, nodeListDSL, parentPathData) => {
    return Object.values(nodeElementDataRecords)
        .filter(currentElementData => {
        if (!nodeListDSL[currentElementData.nodeID]) {
            // node could be already unregistered
            return false;
        }
        const currentElementPath = currentElementData.renderPathData.renderPath;
        const currentLevel = currentElementPath.length;
        const expectedLevel = parentPathData.renderPath.length + 1;
        if (currentLevel >= expectedLevel) {
            // child gotta have longer path than its parent
            for (let i = 0; i < parentPathData.renderPath.length; i += 1) {
                if (currentElementPath[i] !== parentPathData.renderPath[i]) {
                    // child gotta have the same starting path (last element is irrelevant)
                    return false;
                }
            }
            if (currentLevel === expectedLevel) {
                // child has expected level
                // and all the starting path elements are the same
                return true;
            }
            // child lies deeper than expected
            // which legit may happen when we are force-hiding overlays for some of them
            for (const cursorParentData of traverseRenderParents(currentElementData.renderPathData)) {
                const cursorParentElement = nodeElementDataRecords[cursorParentData.renderID];
                if (!cursorParentElement) {
                    // we are only traversing deep enough to find first selectable (overlay-renderable) parent
                    // eslint-disable-next-line no-continue
                    continue;
                }
                // as soon as the first selectable parent is found
                // we are checking it against target
                return cursorParentData.renderID === parentPathData.renderID;
            }
        }
        // wrong level
        // wrong parent
        return false;
    })
        .map(nodeElementData => ({
        nodeID: nodeElementData.nodeID,
        renderPathData: nodeElementData.renderPathData,
    }));
};
