var _a;
import { omit } from 'ramda';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, PRESENTATIONS, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsAutocomplete } from '../../material-components/BuilderComponentsAutocomplete';
import { BuilderComponentsFormAutocompleteSettings } from './BuilderComponentsFormAutocomplete.settings';
export const BuilderComponentsFormAutocomplete = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormAutocomplete,
    displayName: 'Form Autocomplete',
    icon: 'formAutocomplete',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormAutocomplete',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [
            {
                name: PRESENTATIONS.visible,
                props: {
                    autocompleteProps: {
                        open: true,
                    },
                },
            },
        ],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.object,
                    name: 'FormAutocomplete',
                    defaultValue: { label: '', value: '' },
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormAutocomplete',
                },
                autocompleteProps: Object.assign(Object.assign({}, omit(['value', 'onChange'], (_a = BuilderComponentsAutocomplete.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)), { label: 'Form Autocomplete', value: '{{ __STATES__.FormAutocomplete.value.value }}', isOptionEqualToValue: 'return autocompleteOption.value === autocompleteValue.value;' }),
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FormAutocomplete.setValue(value || { label: "", value: "" })',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { autocompleteProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsAutocomplete.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                    {
                        name: 'reason',
                        path: [2],
                    },
                    {
                        name: 'details',
                        path: [3],
                    },
                    {
                        name: 'fieldProps',
                        path: [4],
                    },
                ],
            } }),
    },
    settings: BuilderComponentsFormAutocompleteSettings,
};
