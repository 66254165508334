import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import {
  FormControlLabel,
  FormControlLabelProps,
  SwitchProps,
  FormHelperText,
  Switch,
} from '@mui/material';

import { getIsInvalid } from '@builder/components';

import { Field, FieldRenderProps, FieldProps } from '../Field';

export type FormSwitchProps = {
  fieldProps: FieldProps;
  switchProps: Omit<FormControlLabelProps, 'control'> & SwitchProps;
};

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
`;

export const FormSwitch = forwardRef<HTMLDivElement, FormSwitchProps>(
  ({ switchProps, fieldProps }, ref) => {
    const { disabled, label, labelPlacement = 'end', ...otherSwitchProps } = switchProps ?? {};

    return (
      <Field name={fieldProps.name} validate={fieldProps.validate}>
        {({ field, meta, form }: FieldRenderProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid ? meta.error : undefined;

          return (
            <div ref={ref}>
              <StyledFormControlLabel
                disabled={disabled}
                label={label}
                labelPlacement={labelPlacement}
                name={fieldProps.name}
                control={
                  <Switch {...otherSwitchProps} checked={field.value} onChange={field.onChange} />
                }
              />
              {isInvalid && <FormHelperText error>{errorText}</FormHelperText>}
            </div>
          );
        }}
      </Field>
    );
  },
);
