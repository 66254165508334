/* eslint-disable no-bitwise */
// '#fff'
const SHORTHAND_HEX_LENGTH = 3;
// '#fffe
const SHORTHAND_HEX_LENGTH_WITH_ALPHA = 4;
// '#ffffff'
const FULL_HEX_LENGTH = 6;
// '#ffffffee'
const MAX_RGB_COLOR_VALUE = 255;
const FULL_HEX_LENGTH_WITH_ALPHA = 8;
const HEX_CHARACTERS_PATTERN = 'a-f\\d';
const MATCH_3_OR_4_HEX_PATTERN = `#?[${HEX_CHARACTERS_PATTERN}]{${SHORTHAND_HEX_LENGTH}}[${HEX_CHARACTERS_PATTERN}]?`;
const MATCH_6_OR_8_HEX_PATTERN = `#?[${HEX_CHARACTERS_PATTERN}]{${FULL_HEX_LENGTH}}([${HEX_CHARACTERS_PATTERN}]{2})?`;
const NON_HEX_CHARS_REGEXP = new RegExp(`[^#${HEX_CHARACTERS_PATTERN}]`, 'gi');
const VALID_HEX_SIZE_REGEXP = new RegExp(`^${MATCH_3_OR_4_HEX_PATTERN}$|^${MATCH_6_OR_8_HEX_PATTERN}$`, 'i');
export const convertHexToRgb = (hex, format = 'array') => {
    if (typeof hex !== 'string' ||
        NON_HEX_CHARS_REGEXP.test(hex) ||
        !VALID_HEX_SIZE_REGEXP.test(hex)) {
        throw new TypeError('Expected a valid hex string');
    }
    let currentHex = hex.replace(/^#/, '');
    let alphaFromHex = 1;
    if (currentHex.length === FULL_HEX_LENGTH_WITH_ALPHA) {
        alphaFromHex =
            Number.parseInt(currentHex.slice(FULL_HEX_LENGTH, FULL_HEX_LENGTH_WITH_ALPHA), 16) /
                MAX_RGB_COLOR_VALUE;
        currentHex = currentHex.slice(0, FULL_HEX_LENGTH);
    }
    if (currentHex.length === SHORTHAND_HEX_LENGTH_WITH_ALPHA) {
        alphaFromHex =
            Number.parseInt(currentHex.slice(SHORTHAND_HEX_LENGTH, SHORTHAND_HEX_LENGTH_WITH_ALPHA).repeat(2), 16) / MAX_RGB_COLOR_VALUE;
        currentHex = currentHex.slice(0, SHORTHAND_HEX_LENGTH);
    }
    if (currentHex.length === SHORTHAND_HEX_LENGTH) {
        currentHex =
            currentHex[0] + currentHex[0] + currentHex[1] + currentHex[1] + currentHex[2] + currentHex[2];
    }
    // NOTE: please don't touch this bitwise operators
    // more info here:
    // https://oliverbenns.com/posts/hex-to-rgb/
    const number = Number.parseInt(currentHex, 16);
    const red = number >> 16;
    const green = (number >> 8) & MAX_RGB_COLOR_VALUE;
    const blue = number & MAX_RGB_COLOR_VALUE;
    const alpha = alphaFromHex;
    if (format === 'array') {
        return [red, green, blue, alpha];
    }
    const alphaString = alpha === 1 ? '' : ` / ${Number((alpha * 100).toFixed(2))}%`;
    return `rgb(${red} ${green} ${blue}${alphaString})`;
};
