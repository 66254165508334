import React, { useCallback, useState } from 'react';

import { Dialog } from '@mui/material';

import { composeValidators, required, mustBeCorrectClassName } from '@builder/utils';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { TextField } from '../TextField';

import {
  PopupContent,
  PopupHeader,
  PopupTitle,
  PopupActions,
} from './NodeCSSAsGlobalDialog.styles';

type NodeCSSAsGlobalDialogProps = {
  isOpen: boolean;
  onClose: (event: React.SyntheticEvent<Element, Event>) => void;
  onSaveCSS?: (event: React.SyntheticEvent<Element, Event>, cssClassName: string) => void;
};

export const NodeCSSAsGlobalDialog: React.FC<NodeCSSAsGlobalDialogProps> = ({
  isOpen,
  onSaveCSS,
  onClose,
}) => {
  const [className, setClassName] = useState('');
  const [classNameValidationError, setClassNameValidationError] = useState<string | void>('');
  const nameValidation = composeValidators(required, mustBeCorrectClassName);

  const changeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setClassName(event.target.value);
      setClassNameValidationError(nameValidation(event.target.value));
    },
    [nameValidation],
  );

  const onCreateClass = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onSaveCSS?.(event, className);
    onClose(event);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 8 },
      }}
    >
      <PopupHeader
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <PopupTitle>Save as Global CSS Style</PopupTitle>
        <Icon
          element="button"
          width="16px"
          height="16px"
          name="cross"
          color="white"
          onClick={onClose}
        />
      </PopupHeader>
      <PopupContent
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div style={{ marginBottom: 32, marginTop: 8 }}>
          <TextField
            label="Class name"
            variant="outlined"
            size="small"
            value={className}
            onChange={changeName}
            error={Boolean(classNameValidationError)}
            helperText={classNameValidationError}
            multiline={false}
            onClick={event => {
              event.stopPropagation();
            }}
          />
        </div>
        <PopupActions>
          <Button
            variant="contained"
            color="default"
            size="small"
            onClick={event => onClose(event)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            disabled={!className || Boolean(classNameValidationError)}
            onClick={event => onCreateClass(event)}
          >
            Save
          </Button>
        </PopupActions>
      </PopupContent>
    </Dialog>
  );
};
