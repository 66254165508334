import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getStylesSection, getActionSettings } from '../../common';
const getPropertiesSettings = () => [];
export const MaterialDrawerContentSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
