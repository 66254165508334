import { isUndefined } from '@builder/utils';
import { Migration } from '../Migration';
class Migration_0_16_8 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.8';
    }
    getChangeList() {
        return ['Removed unexisting conected states from wrapper nodes'];
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        const nodes = Object.assign({}, appDSL.nodes);
        const states = Object.assign({}, appDSL.states);
        const newNodesValues = Object.entries(nodes).map(([nodeKey, nodeValue]) => {
            var _a, _b;
            if (((_a = nodeValue.states) === null || _a === void 0 ? void 0 : _a.length) && ((_b = nodeValue.states) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                const filteredSates = nodeValue.states.filter(({ stateID }) => !isUndefined(states[stateID]));
                const newNodeValue = Object.assign(Object.assign({}, nodeValue), { states: filteredSates });
                return [nodeKey, newNodeValue];
            }
            return [nodeKey, nodeValue];
        });
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { nodes: Object.fromEntries(newNodesValues) }) });
    }
}
export const migration_0_16_8 = new Migration_0_16_8();
