import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_CALLBACK_TYPES } from '../../constants';
export const LAZY_QUERY_ARGS_DSL = {
    body: { type: COMPONENT_DSL_PROP_TYPES.string },
    variables: { type: COMPONENT_DSL_PROP_TYPES.object },
    fetchPolicy: { type: COMPONENT_DSL_PROP_TYPES.enum },
    errorPolicy: { type: COMPONENT_DSL_PROP_TYPES.enum },
    onCompleted: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'data',
                path: [0],
            },
        ],
        allowJS: true,
    },
    onError: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'error',
                path: [0],
            },
        ],
        allowJS: true,
    },
    transformer: {
        type: COMPONENT_DSL_PROP_TYPES.callback,
        body: COMPONENT_DSL_CALLBACK_TYPES.code,
        args: [
            {
                name: 'data',
                path: [0],
            },
        ],
        allowJS: true,
    },
    skip: { type: COMPONENT_DSL_PROP_TYPES.boolean },
    displayName: { type: COMPONENT_DSL_PROP_TYPES.string },
    pollInterval: { type: COMPONENT_DSL_PROP_TYPES.stringOrNumber },
    notifyOnNetworkStatusChange: { type: COMPONENT_DSL_PROP_TYPES.boolean },
    context: { type: COMPONENT_DSL_PROP_TYPES.object },
};
