import React from 'react';

import { TREE_VIEW_SVG_PATTERN_DARK_ID, TREE_VIEW_SVG_PATTERN_LIGHT_ID } from './constants';

/* These patterns are needed for an optimized display of component tree nesting */
export const getSvgPatterns = (): JSX.Element => {
  return (
    <svg
      id="patterns"
      width="0"
      height="0"
      style={{ position: 'absolute', top: '0px', left: '0px' }}
    >
      <defs>
        <pattern
          id={TREE_VIEW_SVG_PATTERN_DARK_ID}
          x="0"
          y="0"
          width="12"
          height="34"
          patternUnits="userSpaceOnUse"
        >
          <line x1="6.5" y1="0" x2="6.5" y2="34" stroke="#5b636c78" strokeWidth="1" />
        </pattern>
        <pattern
          id={TREE_VIEW_SVG_PATTERN_LIGHT_ID}
          x="0"
          y="0"
          width="12"
          height="34"
          patternUnits="userSpaceOnUse"
        >
          <line x1="6.5" y1="0" x2="6.5" y2="34" stroke="rgba(255,255,255,0.2)" strokeWidth="1" />
        </pattern>
      </defs>
    </svg>
  );
};
