var _a, _b, _c, _d, _e, _f;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_CALLBACK_TYPES, STATE_TYPES, STATE_SCOPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsTab } from '../BuilderComponentsTab/BuilderComponentsTab';
import { MaterialTypography } from '../MaterialTypography';
import { BuilderComponentsTabsSettings } from './BuilderComponentsTabs.settings';
export const BuilderComponentsTabs = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsTabs,
    displayName: 'Tab Group',
    icon: 'tabGroup',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Tabs',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.number,
                    name: 'tabs',
                    defaultValue: 1,
                },
            ],
            props: {
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialFormLabel,
                            alias: 'Tabs Label',
                            props: {
                                component: 'label',
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Tabs',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                value: '{{ __STATES__.tabs.value }}',
                indicatorColor: 'primary',
                arialabel: '',
                arialabelledby: '',
                scrollButtons: false,
                visibleScrollbar: false,
                orientation: 'horizontal',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.tabs.setValue(value)',
                    },
                },
                textcolor: 'primary',
                variant: 'standard',
                centered: false,
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsTab.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { label: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                            alias: 'Tab Label',
                                            schemaOverride: {
                                                interaction: 'only-editable',
                                            },
                                            props: Object.assign(Object.assign({}, (_b = MaterialTypography.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'TAB ITEM',
                                                            },
                                                        },
                                                    ],
                                                } }),
                                        },
                                    ],
                                }, value: 1 }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                            props: Object.assign(Object.assign({}, (_c = BuilderComponentsTab.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { label: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                            alias: 'Tab Label',
                                            schemaOverride: {
                                                interaction: 'only-editable',
                                            },
                                            props: Object.assign(Object.assign({}, (_d = MaterialTypography.schema.predefineds) === null || _d === void 0 ? void 0 : _d.props), { children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'TAB ITEM',
                                                            },
                                                        },
                                                    ],
                                                } }),
                                        },
                                    ],
                                }, value: 2 }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsTab,
                            props: Object.assign(Object.assign({}, (_e = BuilderComponentsTab.schema.predefineds) === null || _e === void 0 ? void 0 : _e.props), { label: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                            alias: 'Tab Label',
                                            schemaOverride: {
                                                interaction: 'only-editable',
                                            },
                                            props: Object.assign(Object.assign({}, (_f = MaterialTypography.schema.predefineds) === null || _f === void 0 ? void 0 : _f.props), { children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'TAB ITEM',
                                                            },
                                                        },
                                                    ],
                                                } }),
                                        },
                                    ],
                                }, value: 3 }),
                        },
                    ],
                },
                style: getPredefinedStyles({ display: 'grid' }),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, arialabel: { type: COMPONENT_DSL_PROP_TYPES.string }, arialabelledby: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.number }, textcolor: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.string }, orientation: { type: COMPONENT_DSL_PROP_TYPES.enum }, scrollButtons: { type: COMPONENT_DSL_PROP_TYPES.boolean }, visibleScrollbar: { type: COMPONENT_DSL_PROP_TYPES.boolean }, centered: { type: COMPONENT_DSL_PROP_TYPES.boolean }, headersx: { type: COMPONENT_DSL_PROP_TYPES.string }, panelsx: { type: COMPONENT_DSL_PROP_TYPES.string }, indicatorColor: { type: COMPONENT_DSL_PROP_TYPES.string }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsTab, COMPONENT_DSL_NAMES.Iterator],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: 'value',
                        path: [1],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.string,
                        },
                    },
                ],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsTabsSettings,
};
