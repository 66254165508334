import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
export const PathContext = createContext({
    previousPath: undefined,
    currentPath: '',
    isMounted: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMounted: (value) => { },
});
export const PathProvider = ({ path, children }) => {
    const [previousPath, setPreviousPath] = useState(undefined);
    const [currentPath, setCurrentPath] = useState(path || '');
    const [isMounted, setMounted] = useState(false);
    useEffect(() => {
        setPreviousPath(currentPath);
        setCurrentPath(path || '');
        return () => {
            setMounted(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);
    return (_jsx(PathContext.Provider, Object.assign({ value: { previousPath, currentPath, isMounted, setMounted } }, { children: children })));
};
