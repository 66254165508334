var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo, useState, useEffect, } from 'react';
import { FormHelperText, TextField, FormControl } from '@mui/material';
import { StaticTimePicker, MobileTimePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { TimePicker as MuiTimePicker, } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
const COMPONENTTYPES = {
    MuiTimePicker,
    StaticTimePicker,
    MobileTimePicker,
    DesktopTimePicker,
};
const DEFAULT_FORMAT = 'HH:mm:ss';
const DEFAULT_MAX_TIME = '23:59:59';
const DEFAULT_MIN_TIME = '00:00:00';
export const TimePicker = forwardRef((_a, ref) => {
    var _b;
    var { onChange = () => undefined, value = '', initialFocusedTime, renderInput, error, helperText, disabled, className, style, css, inputVariant, responsiveness, hours, minutesView, seconds, views, maxTime, minTime, ampm, showToolbar, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, otherTimePickerProps = __rest(_a, ["onChange", "value", "initialFocusedTime", "renderInput", "error", "helperText", "disabled", "className", "style", "css", "inputVariant", "responsiveness", "hours", "minutesView", "seconds", "views", "maxTime", "minTime", "ampm", "showToolbar", 'data-test', 'data-node-id', 'data-node-render-path']);
    const inputFormat = (_b = otherTimePickerProps.inputFormat) !== null && _b !== void 0 ? _b : DEFAULT_FORMAT;
    const initialValue = useMemo(() => value || initialFocusedTime, [initialFocusedTime, value]);
    const parsedTime = useMemo(() => { var _a; return DateTime.fromFormat((_a = initialValue !== null && initialValue !== void 0 ? initialValue : value) !== null && _a !== void 0 ? _a : '', inputFormat); }, [value, initialValue, inputFormat]);
    const isValidTime = parsedTime.isValid;
    const [localValue, setLocalValue] = useState(isValidTime ? parsedTime : null);
    const currentDate = new Date();
    const formatedDate = `${currentDate.getUTCFullYear()}-${currentDate.getUTCMonth() + 1}-${currentDate.getUTCDate()}T`;
    const setMaxTime = `${formatedDate}${maxTime || DEFAULT_MAX_TIME}`;
    const setMinTime = `${formatedDate}${minTime || DEFAULT_MIN_TIME}`;
    const InputProps = useMemo(() => (Object.assign({ css,
        className,
        error }, otherTimePickerProps.InputProps)), [css, className, error, otherTimePickerProps.InputProps]);
    const handleTimeChange = (time) => {
        setLocalValue(time);
        onChange === null || onChange === void 0 ? void 0 : onChange(time, time === null || time === void 0 ? void 0 : time.toFormat(inputFormat));
    };
    const renderInputFunction = renderInput || (params => _jsx(TextField, Object.assign({}, params, { variant: inputVariant })));
    const setViews = [
        hours ? 'hours' : '',
        minutesView ? 'minutes' : '',
        seconds ? 'seconds' : '',
    ].filter(element => {
        return element !== '';
    });
    const setTimePicker = (timePickerType) => {
        switch (timePickerType) {
            case 'mobile': {
                return COMPONENTTYPES.MobileTimePicker;
            }
            case 'desktop': {
                return COMPONENTTYPES.DesktopTimePicker;
            }
            case 'static': {
                return COMPONENTTYPES.StaticTimePicker;
            }
            default:
                return COMPONENTTYPES.MuiTimePicker;
        }
    };
    useEffect(() => {
        if (!parsedTime.invalidReason)
            setLocalValue(parsedTime);
        else
            setLocalValue(null);
    }, [value, parsedTime]);
    const DynamicTimePicker = setTimePicker(responsiveness || 'default');
    return (_jsxs(FormControl, Object.assign({ disabled: disabled, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: otherTimePickerProps.id, title: otherTimePickerProps.title, lang: otherTimePickerProps.lang, translate: otherTimePickerProps.translate, className: className, onClick: otherTimePickerProps.onClick, onMouseOver: otherTimePickerProps.onMouseOver, onMouseOut: otherTimePickerProps.onMouseOut, onMouseDown: otherTimePickerProps.onMouseDown, onMouseUp: otherTimePickerProps.onMouseUp, onMouseEnter: otherTimePickerProps.onMouseEnter, onMouseLeave: otherTimePickerProps.onMouseLeave, onWheel: otherTimePickerProps.onWheel, onContextMenu: otherTimePickerProps.onContextMenu, onAuxClick: otherTimePickerProps.onAuxClick, style: style }, { children: [_jsx(DynamicTimePicker, Object.assign({}, otherTimePickerProps, { views: setViews, maxTime: dayjs(setMaxTime), minTime: dayjs(setMinTime), ampm: ampm, showToolbar: !!showToolbar, disabled: disabled, InputProps: InputProps, onChange: handleTimeChange, value: localValue, inputFormat: inputFormat, renderInput: renderInputFunction, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath })), helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
