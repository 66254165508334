import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES, COMPONENT_DSL_PROP_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { commonComponentProps } from '../../common/commonProps';
import { OL_SETTINGS } from './ol.settings';
export const ol = {
    name: COMPONENT_DSL_NAMES.ol,
    displayName: COMPONENT_DSL_NAMES.ol,
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    icon: 'orderedList',
    source: {
        htmlElement: 'ol',
    },
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 30, minHeight: 30 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { reversed: { type: COMPONENT_DSL_PROP_TYPES.boolean }, start: { type: COMPONENT_DSL_PROP_TYPES.number }, type: { type: COMPONENT_DSL_PROP_TYPES.enum }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.li],
            } }),
    },
    settings: OL_SETTINGS,
};
