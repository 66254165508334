import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
export const form = {
    name: COMPONENT_DSL_NAMES.form,
    displayName: COMPONENT_DSL_NAMES.form,
    type: COMPONENT_DSL_TYPES.htmlElement,
    source: {
        htmlElement: 'form',
    },
    schema: {
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { onSubmit: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            } }),
    },
    settings: null,
};
