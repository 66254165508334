import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { BuilderComponentsRouteLayoutSettings } from './BuilderComponentsRouteLayout.settings';
export const BuilderComponentsRouteLayout = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
    displayName: 'Route Content',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'RouteLayout',
    },
    memoization: {
        disable: true,
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        codeFileStructure: {
            componentPath: '/pages',
            postfix: 'Page',
        },
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                style: {
                    display: 'flex',
                    flexGrow: '1',
                    flexShrink: '1',
                    flexBasis: '0%',
                    flexDirection: 'column',
                    height: 'auto',
                },
                children: {
                    nodes: [],
                },
            },
        },
        props: {
            children: { type: COMPONENT_DSL_PROP_TYPES.reactNode },
            onMount: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            },
            stretch: {
                type: COMPONENT_DSL_PROP_TYPES.boolean,
            },
            style: {
                type: COMPONENT_DSL_PROP_TYPES.style,
            },
            css: {
                type: COMPONENT_DSL_PROP_TYPES.css,
                targetStylePropName: 'style',
            },
        },
    },
    settings: BuilderComponentsRouteLayoutSettings,
};
