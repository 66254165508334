import React from 'react';

import { Divider } from '@mui/material';
import { path } from 'ramda';

import {
  ComponentSettingDSL,
  COMPONENT_SETTING_TYPES,
  componentSettingSelectors,
  NodePropValue,
} from '@builder/schemas';
import { pathToString, memo, ERROR_SCOPES, SchemaValidationError } from '@builder/utils';

import {
  SubSectionType,
  SectionType,
  PropType,
  RootSectionType,
  SpacingType,
  GridType,
  GridItemType,
} from '../setting-types';
import { getSettingKey } from '../utils/getSettingKey';
import { useUIBuilderState } from 'src/providers/ReduxProvider';
import { CssGrid } from 'src/shared/components';

export type NodeCommonSettingsGeneratorProps = {
  setting: ComponentSettingDSL;
  sectionPath: Array<string | number>;
  sourceEntity: Record<string, unknown>;
  onUpdateProp: (propData: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  onUpdatePropMany: (
    propDataArray: {
      keyValue: unknown;
      keyPath: Array<string | number>;
    }[],
  ) => void;
};

export const NodeCommonSettingsGenerator = memo(
  'NodeCommonSettingsGenerator',
  ({
    setting,
    sectionPath,
    sourceEntity,
    onUpdateProp,
    onUpdatePropMany,
  }: NodeCommonSettingsGeneratorProps): JSX.Element | null => {
    const { devModeEnabled } = useUIBuilderState();
    const isHiddenByDevMode = !devModeEnabled && setting.devMode;
    const isShowingSetting = componentSettingSelectors.isShowingSetting(setting.showIf, {
      sourceEntity,
      nodeListDSL: {},
    });

    if (!isShowingSetting || isHiddenByDevMode) {
      return null;
    }

    const recursivelyPassedProps = {
      sourceEntity,
      onUpdateProp,
      onUpdatePropMany,
    };

    switch (setting.type) {
      case COMPONENT_SETTING_TYPES.rootSection:
        return (
          <RootSectionType rootSectionSetting={setting}>
            {setting.children.map(childSetting => {
              return (
                <NodeCommonSettingsGenerator
                  key={getSettingKey(childSetting, `section=root`)}
                  {...recursivelyPassedProps}
                  setting={childSetting}
                  sectionPath={sectionPath}
                />
              );
            })}
          </RootSectionType>
        );

      case COMPONENT_SETTING_TYPES.spacing:
        return (
          <SpacingType spacingSetting={setting}>
            {setting.children.map(childSetting => {
              return (
                <NodeCommonSettingsGenerator
                  key={getSettingKey(childSetting, `section=spacing`)}
                  {...recursivelyPassedProps}
                  setting={childSetting}
                  sectionPath={sectionPath}
                />
              );
            })}
          </SpacingType>
        );

      case COMPONENT_SETTING_TYPES.section:
        return (
          <SectionType sectionSetting={setting} data-test={`propSettings.${setting.name}`}>
            {setting.children.map(childSetting => {
              return (
                <NodeCommonSettingsGenerator
                  key={getSettingKey(childSetting, `section=${setting.name}`)}
                  {...recursivelyPassedProps}
                  setting={childSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              );
            })}
          </SectionType>
        );

      case COMPONENT_SETTING_TYPES.subSection:
        return (
          <SubSectionType subSectionSetting={setting}>
            {setting.children.map(childSetting => (
              <CssGrid key={getSettingKey(childSetting, `sub-section=${setting.name}`)}>
                <NodeCommonSettingsGenerator
                  {...recursivelyPassedProps}
                  setting={childSetting}
                  sectionPath={sectionPath.concat(setting.name)}
                />
              </CssGrid>
            ))}
          </SubSectionType>
        );

      case COMPONENT_SETTING_TYPES.grid:
        return (
          <GridType gridSetting={setting}>
            {setting.children.map(childSetting => (
              <NodeCommonSettingsGenerator
                key={getSettingKey(childSetting, `grid=${setting.name}`)}
                {...recursivelyPassedProps}
                setting={childSetting}
                sectionPath={sectionPath.concat(setting.name)}
              />
            ))}
          </GridType>
        );

      case COMPONENT_SETTING_TYPES.gridItem:
        return (
          <GridItemType gridItemSetting={setting}>
            {setting.children.map(childSetting => (
              <NodeCommonSettingsGenerator
                key={getSettingKey(childSetting, `gridItem=${setting.name}`)}
                {...recursivelyPassedProps}
                setting={childSetting}
                sectionPath={sectionPath.concat(setting.name)}
              />
            ))}
          </GridItemType>
        );

      case COMPONENT_SETTING_TYPES.prop: {
        const keyPath = componentSettingSelectors.getSettingsKeyPath(setting);
        const keyValue = path(keyPath, sourceEntity) as NodePropValue | null;

        return (
          <PropType
            setting={setting}
            keyValue={keyValue}
            dataTestPrefix={pathToString(sectionPath)}
            onChange={onUpdateProp}
            onChangeMany={onUpdatePropMany}
          />
        );
      }

      case COMPONENT_SETTING_TYPES.divider: {
        return <Divider />;
      }

      default:
        throw new SchemaValidationError(
          ERROR_SCOPES.schemas,
          `Setting type "${setting.type}" not supported in common setting generator`,
        );
    }
  },
);
