import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, COMPONENT_DSL_NAMES, COMPONENT_DSL_INTERACTION_TYPES, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, disableRadioOptions, DISPLAY_OPTION_VALUES, getBasicPropsSettings, NS_ID, } from '../../common';
import { getCommonActionListSettings, getCommonActionSettings, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const getBuilderComponentsCheckboxSettings = (prefixPath = []) => {
    var _a, _b, _c;
    return ({
        properties: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.properties.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        name: NS_ID.properties.basic,
                        titleDescription: BASIC_PROPS_TOOLTIP,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.nodeText,
                                keyPath: ['children'],
                                name: 'label',
                                nodePath: [...prefixPath, 'label', 0],
                                label: 'Label',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.text,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'value'],
                                label: 'Value',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'checked'],
                                name: 'checked',
                                label: 'Checked',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'required'],
                                name: 'required',
                                label: 'Required',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                label: 'Indeterminate',
                                name: 'indeterminate',
                                keyPath: [...prefixPath, 'indeterminate'],
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'disabled'],
                                name: 'disabled',
                                label: 'Disabled',
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.node,
                                name: 'checkedIcon',
                                label: 'Checked Icon',
                                keyPath: [...prefixPath, 'checkedIcon'],
                                items: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        settings: getCommonIconSettings(),
                                        predefineds: {
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                            props: Object.assign(Object.assign({}, (((_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props) || {})), { fontSize: 'small' }),
                                        },
                                    },
                                ],
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.node,
                                name: 'uncheckedIcon',
                                label: 'Unchecked Icon',
                                keyPath: [...prefixPath, 'icon'],
                                items: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        settings: getCommonIconSettings(),
                                        predefineds: {
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                            props: Object.assign(Object.assign({}, (((_b = BuilderComponentsIcon.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props) || {})), { fontSize: 'small' }),
                                        },
                                    },
                                ],
                            },
                            {
                                type: COMPONENT_SETTING_TYPES.node,
                                name: 'indeterminateIcon',
                                label: 'Indeterminate Icon',
                                keyPath: [...prefixPath, 'indeterminateIcon'],
                                items: [
                                    {
                                        name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                        settings: getCommonIconSettings(),
                                        predefineds: {
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                            props: Object.assign(Object.assign({}, (((_c = BuilderComponentsIcon.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props) || {})), { fontSize: 'small' }),
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    getDivider(),
                    ...getBasicPropsSettings(),
                    getDivider(),
                    ...getPropertySections(),
                ],
            },
        ],
        style: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                name: NS_ID.style.root,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.section,
                        title: 'Basic',
                        name: NS_ID.style.basic,
                        titleDescription: BASIC_STYLE_TOOLTIP,
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'color'],
                                label: 'Color',
                                name: 'color',
                                options: [
                                    'default',
                                    'primary',
                                    'secondary',
                                    'error',
                                    'info',
                                    'success',
                                    'warning',
                                ].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: [...prefixPath, 'size'],
                                label: 'Size',
                                name: 'size',
                                options: ['medium', 'small'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                name: 'labelPlacement',
                                label: 'Label Placement',
                                keyPath: [...prefixPath, 'labelPlacement'],
                                options: ['top', 'bottom', 'start', 'end'].map(createSelectOptionByValue),
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                label: 'Disable Ripple',
                                keyPath: [...prefixPath, 'disableRipple'],
                            },
                        ],
                    },
                    getDivider(),
                    ...pipe(assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])))(getStyleSections(prefixPath)),
                ],
            },
        ],
        events: [
            {
                type: COMPONENT_SETTING_TYPES.rootSection,
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.eventsSection,
                        name: NS_ID.events.events,
                        title: 'Events',
                        children: [
                            {
                                type: COMPONENT_SETTING_TYPES.action,
                                name: 'onChangeAction',
                                label: 'On Change',
                                keyPath: [...prefixPath, 'onChange'],
                                children: getCommonActionSettings([...prefixPath, 'onChange']),
                            },
                            ...getCommonActionListSettings(),
                        ],
                    },
                ],
            },
        ],
    });
};
export const BuilderComponentsCheckboxSettings = getBuilderComponentsCheckboxSettings();
