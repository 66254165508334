import { values } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_15_2 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.15.2';
    }
    getChangeList() {
        return [`Add unasigned local states to the home page node on the DSL`];
    }
    migrateAppDSL(appDSL) {
        // Add unasigned local states to the home page node on the DSL
        const stringNodesDSL = JSON.stringify(appDSL.nodes);
        const unasignedStates = [];
        const HOME_PAGE_ID = 'EmptyPageRootRouteLayout_ID';
        const newAppDSL = Object.assign({}, appDSL);
        if (appDSL === null || appDSL === void 0 ? void 0 : appDSL.states) {
            values(appDSL === null || appDSL === void 0 ? void 0 : appDSL.states).forEach(state => {
                if (state.scope === 'local') {
                    const stateID = state.id;
                    if (!stringNodesDSL.includes(stateID))
                        unasignedStates.push(stateID);
                }
            });
            if (newAppDSL.nodes[HOME_PAGE_ID]) {
                if (newAppDSL.nodes[HOME_PAGE_ID].states) {
                    newAppDSL.nodes[HOME_PAGE_ID].states = unasignedStates.map(a => ({ stateID: a })) || [];
                }
            }
        }
        return newAppDSL;
    }
}
export const migration_0_15_2 = new Migration_0_15_2();
