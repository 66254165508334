var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, RESET_STATUS } from '../common';
import { Slider } from '../Slider';
export const FormSlider = forwardRef((_a, ref) => {
    var { sliderProps, fieldProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, className, lang, translate, onChange } = _a, rest = __rest(_a, ["sliderProps", "fieldProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "className", "lang", "translate", "onChange"]);
    const { setFieldValue, initialValues, status, setStatus, values } = useFormikContext();
    const initialValue = initialValues[fieldProps.name]
        ? Number(initialValues[fieldProps.name])
        : parseInt(sliderProps.defaultValue, 10) || 30;
    useEffect(() => {
        var _a;
        const updateStateEvent = {
            target: {
                value: initialValue,
            },
        };
        setFieldValue(fieldProps.name, initialValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(updateStateEvent, initialValue, 1);
        (_a = sliderProps.onChange) === null || _a === void 0 ? void 0 : _a.call(sliderProps, updateStateEvent, initialValue, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.name, initialValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            const updateStateEvent = {
                target: {
                    value: initialValue,
                },
            };
            onChange === null || onChange === void 0 ? void 0 : onChange(updateStateEvent, initialValue, 1);
            (_a = sliderProps.onChange) === null || _a === void 0 ? void 0 : _a.call(sliderProps, updateStateEvent, initialValue, 1);
        }
    }, [status, setStatus, onChange, initialValue, sliderProps]);
    useEffect(() => {
        if (sliderProps.value != null &&
            onChange != null &&
            sliderProps.value !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, sliderProps.value);
        }
    }, [fieldProps.name, onChange, setFieldValue, sliderProps.value, values]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, meta, form }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(Slider, Object.assign({}, rest, sliderProps, { value: field.value || sliderProps.value, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, name: fieldProps.name, onChange: (event, value, activeThumb) => {
                    var _a;
                    field.onChange(event);
                    (_a = sliderProps.onChange) === null || _a === void 0 ? void 0 : _a.call(sliderProps, event, value, 1);
                    onChange === null || onChange === void 0 ? void 0 : onChange(event, value, activeThumb);
                }, error: isInvalid, helperText: errorText, id: id, title: title, className: className, lang: lang, translate: translate })));
        } })));
});
