var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { Formik } from 'formik';
import { generateYupSchema } from '../common/generateYupSchema';
export const Form = forwardRef((_a, ref) => {
    var { style, className, children, onSubmit, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, fieldValidation = [] } = _a, props = __rest(_a, ["style", "className", "children", "onSubmit", 'data-test', 'data-node-id', 'data-node-render-path', "fieldValidation"]);
    const validationErrorYupSchema = useMemo(() => generateYupSchema(fieldValidation), [
        fieldValidation,
    ]);
    const initialFieldValues = fieldValidation
        ? Object.values(fieldValidation).reduce((acc, fieldProp) => {
            return Object.assign(Object.assign({}, acc), { [fieldProp.Key]: fieldProp.Value || '' });
        }, {})
        : {};
    const updateFormKey = JSON.stringify(initialFieldValues);
    return (_jsx(Formik, Object.assign({ initialValues: initialFieldValues, onSubmit: onSubmit, validationSchema: validationErrorYupSchema }, { children: formProps => {
            return (_jsx("form", Object.assign({ ref: ref, style: style, className: className, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath }, props, { onSubmit: formProps.handleSubmit }, { children: typeof children === 'function' ? children(formProps) : children })));
        } }), updateFormKey));
});
