import { createNodeError } from '../../../../app-audit';
/**
 * Class pass node errors in appropriate format.
 */
export class NodeErrorGetter {
    constructor(node, onAppAuditNotifications) {
        this.node = node;
        this.onAppAuditNotifications = onAppAuditNotifications;
    }
    passErrorNotification({ propPath, message, }) {
        this.onAppAuditNotifications([
            createNodeError({
                nodeID: this.node.id,
                propPath,
                message,
            }),
        ]);
    }
}
