import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, COMPONENT_SETTING_CONDITION_TYPES, LANG_OPTIONS, TRANSLATE_OPTIONS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, NS_ID } from '../../common';
import { getPropertySections, getStyleSections, getDivider, getCommonActionSettings, getCommonActionListSettings, } from '../../common/settings';
import { getCommonIconSettings } from '../../material-components/BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const AlertSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'title',
                            label: 'Title',
                            keyPath: ['title'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAlertTitle,
                                    settings: [
                                        {
                                            type: COMPONENT_SETTING_TYPES.nodeText,
                                            label: 'Title',
                                            keyPath: ['children'],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            label: 'Text',
                            keyPath: ['children'],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'icon',
                            label: 'Icon',
                            keyPath: ['icon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'action',
                            label: 'Action Button',
                            keyPath: ['action'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                                    settings: [
                                        {
                                            type: COMPONENT_SETTING_TYPES.nodeText,
                                            keyPath: ['children'],
                                            label: 'Text',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['closeText'],
                            label: 'Close Text',
                            showIf: [
                                {
                                    keyPath: ['action'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isNil,
                                },
                            ],
                        },
                        getDivider(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            title: 'Common',
                            name: 'Common',
                            expandedByDefault: false,
                            titleDescription: {
                                title: 'Common',
                                subtitle: 'Common properties for all components',
                            },
                            children: [
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.text,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['id'],
                                    label: 'Id',
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.text,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['className'],
                                    label: 'Class',
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['lang'],
                                    label: 'Language',
                                    options: LANG_OPTIONS.map(createSelectOptionByValue),
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['translate'],
                                    label: 'Translate',
                                    options: TRANSLATE_OPTIONS.map(createSelectOptionByValue),
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['draggable'],
                                    label: 'Draggable',
                                },
                                getDivider(),
                                {
                                    type: COMPONENT_SETTING_TYPES.section,
                                    name: 'customPropsSection',
                                    title: 'Custom Properties',
                                    children: [
                                        {
                                            type: COMPONENT_SETTING_TYPES.list,
                                            keyPath: ['customProps'],
                                            name: 'customProps',
                                            label: 'Custom Properties',
                                            itemLabelKeyPath: ['Key'],
                                            newObjectPredefineds: {
                                                Key: 'propname',
                                                Value: 'value',
                                            },
                                            settings: [
                                                {
                                                    componentView: COMPONENT_SETTING_VIEWS.text,
                                                    type: COMPONENT_SETTING_TYPES.prop,
                                                    keyPath: ['Key'],
                                                    label: 'Key',
                                                },
                                                {
                                                    componentView: COMPONENT_SETTING_VIEWS.text,
                                                    type: COMPONENT_SETTING_TYPES.prop,
                                                    keyPath: ['Value'],
                                                    label: 'Value',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        getDivider(),
                        ...getPropertySections(),
                    ],
                },
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['variant'],
                            label: 'Variant',
                            options: ['filled', 'outlined', 'standard'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['severity'],
                            label: 'Severity',
                            options: ['error', 'info', 'success', 'warning'].map(createSelectOptionByValue),
                        },
                    ],
                },
                ...getStyleSections(),
                getDivider(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onClose',
                            label: 'On Close',
                            keyPath: ['onClose'],
                            children: getCommonActionSettings(['onClose']),
                        },
                        ...getCommonActionListSettings(),
                    ],
                },
            ],
        },
    ],
};
