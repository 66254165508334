import React, { ElementType, forwardRef, useCallback } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@mui/material';

import { ERROR_SCOPES, SystemError } from '@builder/utils';

export type ButtonProps<C extends ElementType = 'button'> = MuiButtonProps<C> & {
  component?: C;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  target?: string;
  circularProgressColor?: string;
};

const CircularProgressWrapper = styled.div<ButtonProps>`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: ${props =>
    (props.color === 'primary' || props.color === 'secondary' || props.color === 'success') &&
    props.variant === 'contained'
      ? 'white'
      : 'black'};
`;

const ButtonContainedDefault = styled(MuiButton)<ButtonProps>`
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.grey[700]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[700]};
    filter: brightness(1.2);
  }
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ loading, children, color, onClick, circularProgressColor, ...rest }, ref) => {
    const onClickCallback = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!loading) {
          onClick && onClick(event);
        }
      },
      [loading, onClick],
    );

    const buttonCommonProps = {
      ...rest,
      ref,
      onClick: onClickCallback,
      css:
        loading &&
        css`
          color: transparent;
        `,
    };

    const buttonChildren = (
      <>
        {children}
        <If condition={loading}>
          <CircularProgressWrapper color={color} variant={rest.variant}>
            <CircularProgress color="inherit" size="1em" style={{ color: circularProgressColor }} />
          </CircularProgressWrapper>
        </If>
      </>
    );

    if (color === 'default' && rest.variant !== 'contained') {
      throw new SystemError(
        ERROR_SCOPES.dashboard,
        `Needs to implement ${rest.variant} variant for default color`,
      );
    }

    if (color === 'default' && rest.variant === 'contained') {
      return (
        <ButtonContainedDefault {...buttonCommonProps}>{buttonChildren}</ButtonContainedDefault>
      );
    }

    return (
      <MuiButton {...buttonCommonProps} color={color}>
        {buttonChildren}
      </MuiButton>
    );
  },
);
