var _a, _b, _c, _d, _e, _f, _g, _h;
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { BuilderComponentsSpeedDialAction } from '../BuilderComponentsSpeedDialAction';
import { BuilderComponentsSpeedDialSettings } from './BuilderComponentsSpeedDial.settings';
export const BuilderComponentsSpeedDial = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDial,
    displayName: 'Speed Dial',
    icon: 'speedDial',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'SpeedDial',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.boolean,
                    name: 'speedDial',
                    defaultValue: false,
                    variant: 'true-false',
                },
            ],
            props: {
                ariaLabel: 'Speed Dial',
                direction: 'up',
                hidden: false,
                icon: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { name: 'Add', fontSize: 'small' }),
                            schemaOverride: {
                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                            },
                        },
                    ],
                },
                openIcon: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: Object.assign(Object.assign({}, (_b = BuilderComponentsIcon.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { name: 'Close', fontSize: 'small' }),
                            schemaOverride: {
                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                            },
                        },
                    ],
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDialAction,
                            alias: 'SpeedDial Action',
                            props: Object.assign(Object.assign({}, (_c = BuilderComponentsSpeedDialAction.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { delay: 0, open: false, tooltipOpen: false, tooltipTitle: 'Copy', tooltipPlacement: 'left', icon: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                            props: Object.assign(Object.assign({}, (_d = BuilderComponentsIcon.schema.predefineds) === null || _d === void 0 ? void 0 : _d.props), { name: 'ContentCopy', fontSize: 'small' }),
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                        },
                                    ],
                                } }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDialAction,
                            alias: 'SpeedDial Action',
                            props: Object.assign(Object.assign({}, (_e = BuilderComponentsSpeedDialAction.schema.predefineds) === null || _e === void 0 ? void 0 : _e.props), { delay: 0, open: false, tooltipOpen: false, tooltipTitle: 'Print', tooltipPlacement: 'left', icon: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                            props: Object.assign(Object.assign({}, (_f = BuilderComponentsIcon.schema.predefineds) === null || _f === void 0 ? void 0 : _f.props), { name: 'Print', fontSize: 'small' }),
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                        },
                                    ],
                                } }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsSpeedDialAction,
                            alias: 'SpeedDial Action',
                            props: Object.assign(Object.assign({}, (_g = BuilderComponentsSpeedDialAction.schema.predefineds) === null || _g === void 0 ? void 0 : _g.props), { delay: 0, open: false, tooltipOpen: false, tooltipTitle: 'Save', tooltipPlacement: 'left', icon: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                            props: Object.assign(Object.assign({}, (_h = BuilderComponentsIcon.schema.predefineds) === null || _h === void 0 ? void 0 : _h.props), { name: 'Save', fontSize: 'small' }),
                                            schemaOverride: {
                                                interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
                open: '{{ __STATES__.speedDial.value }}',
                onOpen: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.speedDial.setValue(true)',
                    },
                },
                onClose: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.speedDial.setValue(false)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { id: { type: COMPONENT_DSL_PROP_TYPES.string }, events: { type: COMPONENT_DSL_PROP_TYPES.object }, style: { type: COMPONENT_DSL_PROP_TYPES.style }, sx: { type: COMPONENT_DSL_PROP_TYPES.string }, FabProps: { type: COMPONENT_DSL_PROP_TYPES.object }, ariaLabel: { type: COMPONENT_DSL_PROP_TYPES.string }, direction: { type: COMPONENT_DSL_PROP_TYPES.string }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, hidden: { type: COMPONENT_DSL_PROP_TYPES.boolean }, icon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, openIcon: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsIcon],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'args',
                        path: [0],
                    },
                ],
            }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'args',
                        path: [0],
                    },
                ],
            }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.BuilderComponentsSpeedDialAction,
                    COMPONENT_DSL_NAMES.Iterator,
                ],
            } }),
    },
    settings: BuilderComponentsSpeedDialSettings,
};
