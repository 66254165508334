import React, { useMemo } from 'react';

import { Typography } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import { useTheme } from 'src/providers/ThemeProvider';
import { CssGrid } from 'src/shared/components';

export type ThemeSettingsSectionProps = {
  title: string;
  captions?: string[];
};

export const ThemeSettingsSection: React.FC<ThemeSettingsSectionProps> = ({
  title,
  captions,
  children,
}) => {
  const theme = useTheme();
  const showCaption = useMemo(() => !isNil(captions) && !isEmpty(captions), [captions]);

  return (
    <CssGrid gridRowGap={2}>
      <Typography variant="h6">{title}</Typography>
      <If condition={showCaption}>
        <CssGrid gridRowGap={1}>
          {captions &&
            captions.map(caption => (
              <Typography key={caption} variant="body1" color={theme.palette.grey[400]}>
                {caption}
              </Typography>
            ))}
        </CssGrid>
      </If>
      {children}
    </CssGrid>
  );
};
