import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialDialogActionsSettings } from './MaterialDialogActions.settings';
export const MaterialDialogActions = {
    name: COMPONENT_DSL_NAMES.MaterialDialogActions,
    displayName: 'Dialog Actions',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'DialogActions',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { disableSpacing: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: MaterialDialogActionsSettings,
};
