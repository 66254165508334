var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import * as MaterialIcons from '@mui/icons-material';
const capitalize = (value = '') => {
    const head = value[0] || '';
    const rest = value.slice(1) || '';
    return head.toUpperCase() + rest;
};
const DEFAULT_SIZE = 20;
export const Icon = forwardRef((_a, ref) => {
    var _b, _c, _d, _e;
    var { name, customIcon, variant = 'filled', style, color, fontSize, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, rest = __rest(_a, ["name", "customIcon", "variant", "style", "color", "fontSize", 'data-test', 'data-node-id', 'data-node-render-path']);
    const styleTransform = Object.assign(Object.assign({}, style), { display: (style === null || style === void 0 ? void 0 : style.display) === 'flex' ? 'inline-flex' : style === null || style === void 0 ? void 0 : style.display });
    if (customIcon) {
        return (_jsx("span", Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, style: style }, rest, { children: _jsx("svg", Object.assign({ width: (_b = style === null || style === void 0 ? void 0 : style.width) !== null && _b !== void 0 ? _b : DEFAULT_SIZE, height: (_c = style === null || style === void 0 ? void 0 : style.height) !== null && _c !== void 0 ? _c : DEFAULT_SIZE }, { children: _jsx("image", { href: customIcon, width: (_d = style === null || style === void 0 ? void 0 : style.width) !== null && _d !== void 0 ? _d : DEFAULT_SIZE, height: (_e = style === null || style === void 0 ? void 0 : style.height) !== null && _e !== void 0 ? _e : DEFAULT_SIZE }) })) })));
    }
    const importVariant = variant === 'filled' ? '' : capitalize(variant);
    const MaterialIcon = MaterialIcons[`${name}${importVariant}`];
    if (MaterialIcon) {
        return (
        // SvgIconComponent isn't aware of its forwardRef nature for some reason
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _jsx(MaterialIcon, Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, fontSize: fontSize, color: color, style: styleTransform }, rest)));
    }
    return (_jsx("span", Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, style: style }, rest)));
});
