import React, { useCallback } from 'react';

import { WarningAmberOutlined as WarningAmberOutlinedIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle } from '@mui/material';

import { DialogContentWithIcon } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { useAppDispatch, useUIBuilderLeftPanel } from 'src/providers/ReduxProvider';
import { Button } from 'src/shared/components';
import { UI_BUILDER_EVENTS, LEFT_PANEL_TABS } from 'src/store';

const DIALOG_ID = DASHBOARD_DIALOGS.NO_RESOURCES_WARNING_DIALOG_ID;

export const NoResourcesWarningDialog: React.FC = () => {
  const send = useAppDispatch();
  const leftPanel = useUIBuilderLeftPanel();
  const { isOpen, closeDialog } = useDialogState(DIALOG_ID);

  const closeNoBackendWarningDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const goToResources = useCallback(() => {
    send({
      type: UI_BUILDER_EVENTS.updateLeftPanel,
      leftPanel: {
        ...leftPanel,
        currentTab: LEFT_PANEL_TABS.resources,
      },
    });

    closeDialog(DIALOG_ID);
  }, [closeDialog, leftPanel, send]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs" data-test="dialog.noResourcesSpecified">
      <DialogTitle>No resources specified</DialogTitle>
      <DialogContentWithIcon>
        <WarningAmberOutlinedIcon color="warning" />
        <DialogContentText>You need to specify at least one resource.</DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="default" onClick={closeNoBackendWarningDialog}>
          Close
        </Button>
        <Button variant="contained" color="success" onClick={goToResources}>
          Go to Resources
        </Button>
      </DialogActions>
    </Dialog>
  );
};
