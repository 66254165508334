export const shallowEqualNaive = (
  record1: Record<string, unknown>,
  record2: Record<string, unknown>,
  keys: string[],
): boolean => {
  for (const key of keys) {
    if (record1[key] !== record2[key]) {
      return false;
    }
  }

  return true;
};
