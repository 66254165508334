var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ERROR_SCOPES, isObject, SchemaValidationError } from '@builder/utils';
import { staticImplements } from '../types';
let AppResourcesValidator = class AppResourcesValidator {
    static checkValid(appResourcesMaybe) {
        if (!isObject(appResourcesMaybe)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `App Resources DSL must be an object, but got ${typeof appResourcesMaybe}`);
        }
    }
};
AppResourcesValidator = __decorate([
    staticImplements()
], AppResourcesValidator);
export { AppResourcesValidator };
