import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

import { getUserFullName, getUserInitials } from '@builder/utils';

import { Maybe, TeamMemberFragment } from 'src/shared/graphql/__generated__';

type UserAvatarProps = Omit<Parameters<typeof Avatar>[0], 'alt' | 'src'> & {
  user?: Maybe<TeamMemberFragment>;
};

const StyledAvatar = styled(Avatar)<Parameters<typeof Avatar>[0]>`
  cursor: pointer;
  width: 36px;
  height: 36px;
`;

export const UserAvatar: React.FC<UserAvatarProps> = ({ user, ...rest }) => {
  const fullName = getUserFullName(user ?? {});
  const initials = getUserInitials(user ?? {});

  if (user?.avatar?.downloadUrl) {
    return <StyledAvatar {...rest} src={user.avatar.downloadUrl} alt={fullName} />;
  }

  return (
    <StyledAvatar {...rest} alt={fullName}>
      {initials}
    </StyledAvatar>
  );
};
