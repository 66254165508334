var _a, _b, _c;
import { BuilderComponentsIcon } from '../component-schemas/material-components/BuilderComponentsIcon';
import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import iconAvatarsDSL from './style-guide-dsl/AvatarStyleGuide/iconAvatars.json';
import imageAvatarsDSL from './style-guide-dsl/AvatarStyleGuide/imageAvatars.json';
import letterAvatarsDSL from './style-guide-dsl/AvatarStyleGuide/letterAvatars.json';
import variantsDSL from './style-guide-dsl/AvatarStyleGuide/variants.json';
export const AvatarStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiAvatar,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialAvatar,
    dirName: 'AvatarStyleGuide',
    title: 'Avatar',
    classNames: [
        '.MuiAvatar-root',
        '.MuiAvatar-colorDefault',
        '.MuiAvatar-circular',
        '.MuiAvatar-rounded',
        '.MuiAvatar-square',
        '.MuiAvatar-img',
        '.MuiAvatar-fallback',
    ],
    stories: [
        {
            title: 'Image avatars',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAvatar,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        src: 'https://images.unsplash.com/photo-1654044331015-f2bc89997b42?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80',
                    },
                },
                {
                    props: {
                        src: 'https://images.unsplash.com/photo-1653972230942-f81579004c0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
                    },
                },
                {
                    props: {
                        src: 'https://images.unsplash.com/photo-1653923138014-6fef26b225e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: imageAvatarsDSL,
        },
        {
            title: 'Letter avatars',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAvatar,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        style: { backgroundColor: 'orange' },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'AB',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        style: { backgroundColor: 'green' },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'CD',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        style: { backgroundColor: 'blue' },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'EF',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: letterAvatarsDSL,
        },
        {
            title: 'Icon avatars',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAvatar,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        style: { backgroundColor: 'orange' },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: Object.assign(Object.assign({}, (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { name: 'Mail' }),
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        style: { backgroundColor: 'green' },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: Object.assign(Object.assign({}, (_b = BuilderComponentsIcon.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { name: 'AccessTime' }),
                                },
                            ],
                        },
                    },
                },
                {
                    props: {
                        style: { backgroundColor: 'blue' },
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    props: Object.assign(Object.assign({}, (_c = BuilderComponentsIcon.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { name: 'AdfScanner' }),
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: iconAvatarsDSL,
        },
        {
            title: 'Variants',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAvatar,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'circular',
                    },
                },
                {
                    props: {
                        variant: 'rounded',
                    },
                },
                {
                    props: {
                        variant: 'square',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: variantsDSL,
        },
    ],
};
