import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialToolbarSettings } from './MaterialToolbar.settings';
export const MaterialToolbar = {
    name: COMPONENT_DSL_NAMES.MaterialToolbar,
    displayName: 'Toolbar',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'Toolbar',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, disableGutters: { type: COMPONENT_DSL_PROP_TYPES.boolean }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialToolbarSettings,
};
