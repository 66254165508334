import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import orientation from './style-guide-dsl/ButtonGroupStyleGuide/buttonGroupOrientation.json';
export const ButtonGroupStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiButtonGroup,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialButtonGroup,
    dirName: 'ButtonGroupStyleGuide',
    title: 'ButtonGroup',
    classNames: [
        '.MuiButtonGroup-root',
        '.MuiButtonGroup-contained',
        '.MuiButtonGroup-outlined',
        '.MuiButtonGroup-text',
        '.MuiButtonGroup-disableElevation',
        '.Mui-disabled',
        '.MuiButtonGroup-fullWidth',
        '.MuiButtonGroup-vertical',
        '.MuiButtonGroup-grouped',
        '.MuiButtonGroup-groupedHorizontal',
        '.MuiButtonGroup-groupedVertical',
        '.MuiButtonGroup-groupedText',
        '.MuiButtonGroup-groupedTextHorizontal',
        '.MuiButtonGroup-groupedTextVertical',
    ],
    stories: [
        {
            title: 'Orientation',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialButtonGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            cases: [
                {
                    props: {
                        orientation: 'horizontal',
                    },
                },
                {
                    props: {
                        orientation: 'vertical',
                    },
                },
            ],
            generatedAppDSL: orientation,
        },
    ],
};
