import * as changeCase from 'change-case';
import { omit, pick } from 'ramda';
import { LAZY_QUERY_ARGS_DSL, resourceListSelectors, COMPONENT_DSL_PROP_TYPES, resourceSelectors, } from '@builder/schemas';
import { OUTPUT_PROP_MODES } from '../../../../constants';
import { transformProps, transformQueryProps, } from '../../../app-nodes-generator/prop-transformers';
import { getCallbackAndTransformer, resetCallbacksInLazyQuery } from '../../utils';
import { GqlBaseStateAccessor } from './GqlBaseStateAccessor';
const ARGS_TO_RETURN = ['variables', 'fetchPolicy', 'errorPolicy', 'skip', 'pollInterval'];
export class LazyQueryStateAccessor extends GqlBaseStateAccessor {
    constructor({ appState, stateListDSL, resourceListDSL, authResourceDSL, assetListDSL, }) {
        super();
        this.stateDSL = appState;
        this.stateListDSL = stateListDSL;
        this.assetListDSL = assetListDSL;
        this.resourceListDSL = resourceListDSL;
        this.authResourceDSL = authResourceDSL;
    }
    getGqlQueryName() {
        return changeCase.constantCase(this.stateDSL.name);
    }
    getHookAssociatedConstantsString() {
        return `const ${this.getGqlQueryName()} = gql\`${this.stateDSL.args.body}\`;`;
    }
    getResultString() {
        const hookArgs = ['queryResult', 'onCompleted', 'onError', 'run', 'transformer'];
        return `useLazyQueryState( { ${hookArgs.join(', ')} } );`;
    }
    getHookDeclarationBodyString(gqlGeneratedTypes) {
        var _a, _b, _c;
        const resourceDSL = resourceListSelectors.getResourceDSL(this.resourceListDSL, {
            resourceID: this.stateDSL.resourceID,
        });
        const resource = this.resourceListDSL[this.stateDSL.resourceID];
        const endPoint = resourceSelectors.getResourceEndpointCodeEngine(resource);
        const resourceClient = `
        const apiEndpoint = ${endPoint}
        const client = useMemo(() => useResourceClient(apiEndpoint), [apiEndpoint, JSON.stringify(${endPoint})])`;
        const headers = Object.assign(Object.assign({}, (_b = (_a = resourceDSL.default) === null || _a === void 0 ? void 0 : _a.context) === null || _b === void 0 ? void 0 : _b.headers), (_c = this.stateDSL.args.context) === null || _c === void 0 ? void 0 : _c.headers);
        const args = Object.assign(Object.assign({}, this.stateDSL.args), { context: Object.assign(Object.assign({}, this.stateDSL.args.context), { headers }) });
        const propsResult = transformQueryProps({
            propData: {
                entityName: this.stateDSL.name,
                props: Object.assign({ name: this.stateDSL.name, type: this.stateDSL.type, headers: args.context.headers, notifyOn: args.notifyOnNetworkStatusChange }, pick(ARGS_TO_RETURN, args)),
                propListDSL: Object.assign({ name: { type: COMPONENT_DSL_PROP_TYPES.string }, type: { type: COMPONENT_DSL_PROP_TYPES.string }, headers: { type: COMPONENT_DSL_PROP_TYPES.object }, notifyOn: LAZY_QUERY_ARGS_DSL.notifyOnNetworkStatusChange }, pick(ARGS_TO_RETURN, LAZY_QUERY_ARGS_DSL)),
            },
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        const transformationPropsMap = transformProps({
            propData: {
                props: args,
                propListDSL: LAZY_QUERY_ARGS_DSL,
                entityName: this.stateDSL.name,
                propPath: [],
            },
            outputMode: OUTPUT_PROP_MODES.js,
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        const transformedArgs = resetCallbacksInLazyQuery(transformQueryProps({
            propData: {
                props: omit(['body', 'transformer'], args),
                propListDSL: LAZY_QUERY_ARGS_DSL,
                entityName: this.stateDSL.name,
                additionalProps: 'client,',
            },
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        }));
        const onTransformer = getCallbackAndTransformer(this.stateDSL, 'onTranformer', transformationPropsMap);
        const onCompleted = getCallbackAndTransformer(this.stateDSL, 'onCompleted', transformationPropsMap);
        const onError = getCallbackAndTransformer(this.stateDSL, 'onError', transformationPropsMap);
        const genericName = this.getGenericGqlAction(gqlGeneratedTypes, 'query');
        const queryGeneric = genericName ? `<${genericName}>` : '';
        const transformerType = genericName ? `: ${genericName}` : '';
        return `
      ${resourceClient}
      const onCompleted = useCallback(${onCompleted || `() => undefined`}, []);
      const onError = useCallback(${onError || `() => undefined`}, []);

      const  [run, queryResult] = useLazyQuery${queryGeneric}(${this.getGqlQueryName()}, ${transformedArgs})
      const propsResult = ${propsResult};
      const isTriggeredManually = true;
      const transformer = useCallback((data${transformerType}) => {
        ${onTransformer || 'return data'}
      }, []);

      const result = ${this.getResultString()}


      return {
        ...queryResult,
        ...propsResult,
        ...result,
        query: \`${args.body}\`,
      };
    `;
    }
    getHookTypings(gqlGeneratedTypes) {
        const stateName = this.getStateName();
        const hookBody = this.getHookDeclarationBodyString(gqlGeneratedTypes);
        return `const ${stateName} = (() => { ${hookBody} })()`;
    }
}
