import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsIconSettings } from './BuilderComponentsIcon.settings';
export const BuilderComponentsIcon = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
    displayName: 'Icon',
    icon: 'icon',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Icon',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                name: 'Help',
                variant: 'filled',
                color: 'inherit',
                fontSize: 'medium',
                viewBox: '0 0 24 24',
                style: getPredefinedStyles({ display: 'flex', minWidth: 24, minHeight: 24 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { customIcon: { type: COMPONENT_DSL_PROP_TYPES.asset }, srcSet: { type: COMPONENT_DSL_PROP_TYPES.string }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, fontSize: { type: COMPONENT_DSL_PROP_TYPES.enum }, viewBox: { type: COMPONENT_DSL_PROP_TYPES.string }, htmlColor: { type: COMPONENT_DSL_PROP_TYPES.string }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsIconSettings,
};
