import {
  AppDSL,
  ComponentDSL,
  NodeDSL,
  NodeListDSL,
  NodeStateConnectionDSL,
  ReactNodePropValue,
  StateDSL,
  StateDSLWithDialog,
  StateListDSL,
  addAliasToAllNodes,
} from '@builder/schemas';

import { DashboardState } from 'src/store/common';

type newNodesType = {
  nodeDSL: NodeDSL;
  newStates: StateListDSL;
  newChildrenNodes: NodeDSL[];
  newBoundedStates: NodeStateConnectionDSL[];
}[];

type ComponentsTypes = {
  [x: string]: ComponentDSL;
};

type DialogBoundedStatesType = {
  stateID: string;
  required: boolean;
  componentBoundID: string | undefined;
}[];

const updateStates = ({
  isDialogTarget,
  state,
  newContext,
  stateListDSL,
}: {
  isDialogTarget: string | undefined;
  state: DashboardState;
  newContext: StateListDSL | undefined;
  stateListDSL: StateListDSL;
}): StateListDSL => {
  if (isDialogTarget) {
    return { ...state.appConfiguration.appDSL.states, ...newContext };
  }

  return {
    ...stateListDSL,
  };
};

const updateRouteRouteNodeID = ({
  dialogBoundedStates,
  isDialogTarget,
  isDialogBufferNodes,
  nodeListDSL,
  routeRouteNodeID,
  newContext,
  rootRouteNodeDSL,
  newBoundedStates,
}: {
  dialogBoundedStates: DialogBoundedStatesType | undefined | null;
  isDialogTarget: string | undefined;
  isDialogBufferNodes: string | undefined;
  nodeListDSL: NodeListDSL;
  routeRouteNodeID: string;
  newContext: Record<string, StateDSL> | undefined;
  rootRouteNodeDSL: NodeDSL;
  newBoundedStates: NodeStateConnectionDSL[];
}): NodeDSL => {
  if (!isDialogTarget && isDialogBufferNodes && dialogBoundedStates) {
    return {
      ...nodeListDSL[routeRouteNodeID],
      context: {
        ...nodeListDSL[routeRouteNodeID].context,
        ...newContext,
      },
      states: [...dialogBoundedStates, ...(rootRouteNodeDSL.states ?? [])],
    };
  }

  return {
    ...nodeListDSL[routeRouteNodeID],
    context: {
      ...nodeListDSL[routeRouteNodeID].context,
      ...newContext,
    },
    states: newBoundedStates || rootRouteNodeDSL.states,
  };
};

const updateNodeList = ({
  newContext,
  targetNodeDSL,
  targetPropName,
  targetProp,
  newNodes,
  isDialogTarget,
  isDialogBufferNodes,
  nodeListDSL,
  newChildrenNodesListDSL,
  routeRouteNodeID,
  rootRouteNodeDSL,
  newBoundedStates,
}: {
  newContext: StateListDSL | undefined;
  targetNodeDSL: NodeDSL;
  targetPropName: string;
  targetProp: ReactNodePropValue;
  newNodes: newNodesType;
  isDialogTarget: string | undefined;
  isDialogBufferNodes: string | undefined;
  nodeListDSL: NodeListDSL;
  newChildrenNodesListDSL: Record<string, unknown>;
  routeRouteNodeID: string;
  rootRouteNodeDSL: NodeDSL;
  newBoundedStates: NodeStateConnectionDSL[];
}): NodeListDSL => {
  const updateTargetNode = {
    ...targetNodeDSL,
    props: {
      ...targetNodeDSL.props,
      [targetPropName]: {
        ...targetProp,
        nodes: [...targetProp.nodes, ...newNodes.map(node => node.nodeDSL.id)],
      },
    },
  };

  const updateNewNodes = {
    ...newNodes.reduce(
      (prev, current) => ({
        ...prev,
        [current.nodeDSL.id]: { ...current.nodeDSL, parentID: targetNodeDSL.id },
      }),
      {},
    ),
  };

  if (isDialogTarget || (!isDialogTarget && isDialogBufferNodes)) {
    const dialogBoundedStates = Object.values(newContext ?? {}).map(globalState => {
      return {
        stateID: globalState.id,
        required: true,
        componentBoundID: (globalState as StateDSLWithDialog).componentBoundID,
      };
    });

    if (isDialogTarget) {
      return {
        ...nodeListDSL,
        ...newChildrenNodesListDSL,
        [targetNodeDSL.id]: updateTargetNode,
        ...updateNewNodes,
      };
    }

    if (!isDialogTarget && isDialogBufferNodes) {
      return {
        ...nodeListDSL,
        ...newChildrenNodesListDSL,
        [routeRouteNodeID]: updateRouteRouteNodeID({
          dialogBoundedStates,
          isDialogTarget,
          isDialogBufferNodes,
          nodeListDSL,
          routeRouteNodeID,
          newContext,
          rootRouteNodeDSL,
          newBoundedStates,
        }),
        [targetNodeDSL.id]: updateTargetNode,
        ...updateNewNodes,
      };
    }
  }

  return {
    ...nodeListDSL,
    ...newChildrenNodesListDSL,
    [routeRouteNodeID]: updateRouteRouteNodeID({
      dialogBoundedStates: undefined,
      isDialogTarget,
      isDialogBufferNodes,
      nodeListDSL,
      routeRouteNodeID,
      newContext,
      rootRouteNodeDSL,
      newBoundedStates,
    }),
    [targetNodeDSL.id]: updateTargetNode,
    ...updateNewNodes,
  };
};

const updateAlias = (
  newChildrenNodesListDSL: Record<string, unknown>,
  newNodes: newNodesType,
  targetNodeDSL: NodeDSL,
): NodeListDSL => ({
  ...newChildrenNodesListDSL,
  ...newNodes.reduce(
    (prev, current) => ({
      ...prev,
      [current.nodeDSL.id]: { ...current.nodeDSL, parentID: targetNodeDSL.id },
    }),
    {},
  ),
});

export const updateAppDSL = ({
  newState,
  newContext,
  targetNodeDSL,
  targetPropName,
  targetProp,
  newNodes,
  isDialogTarget,
  isDialogBufferNodes,
  state,
  nodeListDSL,
  newChildrenNodesListDSL,
  routeRouteNodeID,
  rootRouteNodeDSL,
  newBoundedStates,
  componentListDSL,
  stateListDSL,
}: {
  newState: DashboardState;
  newContext: StateListDSL | undefined;
  targetNodeDSL: NodeDSL;
  targetPropName: string;
  targetProp: ReactNodePropValue;
  newNodes: newNodesType;
  isDialogTarget: string | undefined;
  isDialogBufferNodes: string | undefined;
  state: DashboardState;
  nodeListDSL: NodeListDSL;
  newChildrenNodesListDSL: Record<string, unknown>;
  routeRouteNodeID: string;
  rootRouteNodeDSL: NodeDSL;
  newBoundedStates: NodeStateConnectionDSL[];
  componentListDSL: ComponentsTypes;
  stateListDSL: StateListDSL;
}): AppDSL => ({
  ...newState.appConfiguration.appDSL,
  nodes: addAliasToAllNodes({
    nodeListDSL:
      updateNodeList({
        newContext,
        targetNodeDSL,
        targetPropName,
        targetProp,
        newNodes,
        isDialogTarget,
        isDialogBufferNodes,
        nodeListDSL: { ...state.copyBuffer.nodes, ...nodeListDSL },
        newChildrenNodesListDSL,
        routeRouteNodeID,
        rootRouteNodeDSL,
        newBoundedStates,
      }) ?? newState.appConfiguration.appDSL.nodes,
    updateAliasForNodes: updateAlias(newChildrenNodesListDSL, newNodes, targetNodeDSL),
    componentListDSL,
  }),
  states: updateStates({ isDialogTarget, state: newState, newContext, stateListDSL }),
});
