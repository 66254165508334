var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import { Menu as MuiMenu } from '@mui/material';
import { bindTrigger, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
export const Menu = forwardRef((_a, ref) => {
    var { target, content, forceOpen, __nodeAlias, style, css: cssProp, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, className, lang, translate } = _a, menuProps = __rest(_a, ["target", "content", "forceOpen", "__nodeAlias", "style", "css", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "className", "lang", "translate"]);
    const popupState = usePopupState({
        variant: 'popover',
        popupId: __nodeAlias,
    });
    const bindMenuState = useMemo(() => bindMenu(popupState), [popupState]);
    const bindTriggerState = useMemo(() => bindTrigger(popupState), [popupState]);
    const open = forceOpen === undefined ? bindMenuState.open : forceOpen;
    return (_jsxs("div", Object.assign({ ref: ref, style: style, css: cssProp, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, className: className, lang: lang, translate: translate }, { children: [_jsx("div", Object.assign({}, bindTriggerState, { css: css `
            display: inline-flex;
          ` }, { children: target })), _jsx(MuiMenu, Object.assign({}, bindMenuState, menuProps, { open: open }, { children: content({ onClose: bindMenuState.onClose }) }))] })));
});
