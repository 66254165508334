import { ASSET_TYPES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_11_6 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.6';
    }
    getChangeList() {
        return ['Added empty "createdAt", "updatedAt", "createdBy" fields in asset files'];
    }
    migrateAssetDSL(assetDSL) {
        switch (assetDSL.type) {
            case ASSET_TYPES.folder: {
                return assetDSL;
            }
            default: {
                return Object.assign(Object.assign({}, assetDSL), { createdBy: '', createdAt: '', updatedAt: '' });
            }
        }
    }
}
export const migration_0_11_6 = new Migration_0_11_6();
