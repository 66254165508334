import { getCommonActionSettings, getConditionSection } from '../../component-schemas/common';
import { visibleByCondition } from '../../component-schemas/common/commonPresentations';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../constants';
import { componentListSelectors, nodeListSelectors } from '../../selectors';
import { transformNodeListToTree } from './transformNodeListToTree';
import { transformNodeTreeToListMemoized } from './transformNodeTreeToList';
export const createSymbolFromNode = ({ nodeListDSL, componentListDSL, nodeID, componentName, }) => {
    const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    const newComponentName = componentListSelectors.getIncrementedComponentName(componentListDSL, {
        componentName: componentName || `${componentDSL.name}UserSymbol`,
    });
    const newComponentDisplayName = componentListSelectors.getIncrementedComponentDisplayName(componentListDSL, {
        componentDisplayName: componentName || `${componentDSL.displayName} User Symbol`,
    });
    const symbolNodes = transformNodeTreeToListMemoized({
        nodesTree: transformNodeListToTree({
            nodeListDSL,
            componentListDSL,
            nodeID,
        }),
        componentListDSL,
    });
    return {
        name: newComponentName,
        displayName: newComponentDisplayName,
        icon: 'customGroup',
        type: COMPONENT_DSL_TYPES.symbol,
        schema: {
            presentations: [visibleByCondition],
            dndTargetPropName: 'children',
            predefineds: {},
            props: {
                stringProp: {
                    type: COMPONENT_DSL_PROP_TYPES.string,
                },
                numberProp: {
                    type: COMPONENT_DSL_PROP_TYPES.number,
                },
                booleanProp: {
                    type: COMPONENT_DSL_PROP_TYPES.boolean,
                },
                children: {
                    type: COMPONENT_DSL_PROP_TYPES.reactNode,
                },
                onClick: {
                    type: COMPONENT_DSL_PROP_TYPES.action,
                    body: COMPONENT_DSL_CALLBACK_TYPES.code,
                    args: [
                        {
                            name: 'event',
                            path: [0],
                        },
                    ],
                },
            },
            symbolNodes,
        },
        settings: {
            properties: [
                {
                    type: COMPONENT_SETTING_TYPES.rootSection,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['stringProp'],
                            label: 'String Prop',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['numberProp'],
                            label: 'Number Prop',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['booleanProp'],
                            label: 'Boolean Prop',
                        },
                        getConditionSection(),
                    ],
                },
            ],
            style: [
                {
                    type: COMPONENT_SETTING_TYPES.rootSection,
                    children: [],
                },
            ],
            events: [
                {
                    type: COMPONENT_SETTING_TYPES.rootSection,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.eventsSection,
                            name: 'events',
                            title: 'Events',
                            children: [
                                {
                                    type: COMPONENT_SETTING_TYPES.action,
                                    name: 'onClickAction',
                                    label: 'Click',
                                    keyPath: ['onClick'],
                                    children: getCommonActionSettings(['onClick']),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    };
};
