import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
import { getCommonMaterialCardContentSettings } from '../../material-components/MaterialCardContent/MaterialCardContent.settings';
import { getCommonMaterialCardHeaderSettings } from '../../material-components/MaterialCardHeader/MaterialCardHeader.settings';
import { getCommonMaterialCardMediaSettings } from '../../material-components/MaterialCardMedia/MaterialCardMedia.settings';
export const CardSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'header',
                            label: 'Header',
                            keyPath: ['header'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialCardHeader,
                                    settings: getCommonMaterialCardHeaderSettings(),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'content',
                            label: 'Content',
                            keyPath: ['content'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialCardContent,
                                    settings: getCommonMaterialCardContentSettings(),
                                },
                            ],
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'media',
                            label: 'Media',
                            keyPath: ['media'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialCardMedia,
                                    settings: getCommonMaterialCardMediaSettings(),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['raised'],
                            label: 'Raised',
                        },
                    ],
                },
                ...getStyleSections(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
