interface testCasesTypes {
  [index: string]: Array<string>;
}

export const TEST_CASES = {
  string: ['Required', 'Length', 'Min', 'Max', 'Matches', 'Email', 'Url', 'uuid'],
  number: ['Required', 'Min', 'Max', 'LessThan', 'MoreThan', 'Positive', 'Negative', 'Integer'],
  date: ['Required', 'Min', 'Max'],
} as testCasesTypes;

export const NODE_ACTIONS_POPPER_MODIFIERS = [
  {
    name: 'flip',
    enabled: true,
  },
  {
    name: 'hide',
    enabled: true,
  },
  {
    name: 'preventOverflow',
    options: {
      altBoundary: true,
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 15],
    },
  },
];
