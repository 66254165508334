import {
  COMPONENT_SETTING_TYPES,
  ComponentDSL,
  ComponentSettingCommonPropDSL,
  ComponentSettingDSL,
  NodeDSL,
  ComponentSettingWithChildrenDSL,
} from '@builder/schemas';

import { CopyStyle } from 'src/store';

import { validateIsFormComponent } from './validateIsFormComponent';

export function copyValidStyles(componentDSL: ComponentDSL, nodeDSL: NodeDSL): CopyStyle {
  const componentSettingsStyles = componentDSL.settings?.style;
  const styleObject = {
    componentName: componentDSL.name,
    styles: {},
  };

  if (componentSettingsStyles != null && Array.isArray(componentSettingsStyles)) {
    const setting = componentSettingsStyles.find(
      item => item.type === 'rootSection',
    ) as ComponentSettingWithChildrenDSL;
    if (setting != null) {
      const children = setting.children as ComponentSettingDSL[];
      const listOfStyles = children
        .filter(
          item =>
            item.type === COMPONENT_SETTING_TYPES.section ||
            item.type === COMPONENT_SETTING_TYPES.prop,
        )
        .reduce((acc, item) => {
          if (item.type === COMPONENT_SETTING_TYPES.section) {
            const typeProps = item.children?.filter(
              itemChildren =>
                (itemChildren.type === COMPONENT_SETTING_TYPES.prop ||
                  itemChildren.type === COMPONENT_SETTING_TYPES.customFont) &&
                itemChildren.keyPath[0] !== 'style' &&
                itemChildren.keyPath[0] !== 'css',
            ) as ComponentSettingCommonPropDSL[];
            return [...acc, ...typeProps];
          }

          if (item.type === COMPONENT_SETTING_TYPES.prop) {
            return [...acc, item];
          }

          return acc;
        }, [] as ComponentSettingCommonPropDSL[]);

      listOfStyles.forEach(style => {
        const [firstKey, secondKey] = style.keyPath;
        const { isFormComponent } = validateIsFormComponent(style.keyPath);
        if (isFormComponent) {
          if (nodeDSL.props != null && nodeDSL.props[firstKey] != null) {
            const inputProps = nodeDSL.props[firstKey];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const styleValue = nodeDSL.props[firstKey][secondKey];
            if (inputProps != null) {
              if (styleValue != null) {
                if (secondKey === 'css') {
                  Object.assign(styleObject.styles, {
                    css: styleValue,
                  });
                }

                if (secondKey === 'style') {
                  Object.assign(styleObject.styles, {
                    style: styleValue,
                  });
                }

                const styleValuePredefined =
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  componentDSL.schema.predefineds?.props?.[firstKey][secondKey];
                if (styleValuePredefined !== styleValue) {
                  Object.assign(styleObject.styles, {
                    [secondKey]: styleValue,
                  });
                }
              }
            }
          }
        } else {
          const styleValue = nodeDSL.props?.[firstKey];
          const styleValuePredefined = componentDSL.schema.predefineds?.props?.[firstKey];
          if (styleValue != null) {
            if (styleValuePredefined !== styleValue) {
              Object.assign(styleObject.styles, {
                [firstKey]: styleValue,
              });
            }
          }
        }
      });
      const styleProp = nodeDSL.props?.style;
      if (styleProp != null) {
        Object.assign(styleObject.styles, {
          style: styleProp,
        });
      }

      const cssProp = nodeDSL.props?.css;
      if (cssProp != null) {
        Object.assign(styleObject.styles, {
          css: cssProp,
        });
      }
    }
  }

  return styleObject;
}
