import { createSelector } from 'reselect';
import { SchemaError, ERROR_SCOPES } from '@builder/utils';
import { LIBRARIES } from '../constants';
export const getLibraryDataAndVersion = createSelector((libraryDSL) => libraryDSL, (libraryDSL) => {
    const libraryData = LIBRARIES.find(lib => lib.name === libraryDSL.name);
    if (!libraryData) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Library data for ${libraryDSL.name} not found`);
    }
    const versionData = libraryData.versions.find(({ version }) => version === libraryDSL.version);
    if (!versionData) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Typings for ${libraryDSL.name} v${libraryDSL.version} not found`);
    }
    return { libraryData, versionData };
});
export const getLibraryTypesVersion = createSelector(getLibraryDataAndVersion, ({ versionData }) => versionData.typesVersion);
export const getLibraryCDN = createSelector(getLibraryDataAndVersion, ({ versionData }) => versionData.CDN);
