import { STATE_TYPES } from '@builder/schemas';
import { Migration } from '../Migration';
const CUSTOM_STATE_TYPE = 'custom';
class Migration_0_13_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.13.1';
    }
    getChangeList() {
        return ['Change custom state to function state and adding required properties'];
    }
    migrateStateDSL(stateDSL) {
        if (stateDSL.type === CUSTOM_STATE_TYPE) {
            return Object.assign(Object.assign({}, stateDSL), { type: STATE_TYPES.function, arguments: [], return: {
                    definition: 'void',
                    isList: 'nolist',
                } });
        }
        return stateDSL;
    }
}
export const migration_0_13_1 = new Migration_0_13_1();
