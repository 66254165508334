export const NODE_ACTIONS_POPPER_MODIFIERS = [
  {
    name: 'flip',
    enabled: true,
  },
  {
    name: 'hide',
    enabled: true,
  },
  {
    name: 'preventOverflow',
    options: {
      altBoundary: true,
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 5],
    },
  },
];
