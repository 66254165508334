import { nodeListSelectors } from '@builder/schemas';
import { createStateError } from '../app-audit';
const getStateTypeText = (state) => {
    switch (state.type) {
        case 'query':
            return 'Query';
        case 'function':
            return 'Function';
        default:
            return 'State';
    }
};
export const verifyUniqStateNames = ({ localStateList, globalStateList, nodeList, componentListDSL, currentRoute, onAppAuditNotifications, }) => {
    var _a;
    const globalStateListNames = globalStateList.map(state => state.name);
    const currentRouteNode = nodeListSelectors.getCurrentRouteNodeDSL(nodeList, {
        currentPathname: currentRoute,
    });
    const layoutStates = localStateList.filter(state => state.parent !== currentRouteNode.id);
    const currentRouteLayoutId = getCurrentPageLayoutId();
    (_a = Object.values(currentRouteNode.context || {})) === null || _a === void 0 ? void 0 : _a.forEach(currentRouteState => {
        const layoutState = layoutStates.find(state => state.name === currentRouteState.name);
        if (layoutState && layoutState.parent === currentRouteLayoutId) {
            onAppAuditNotifications([
                createStateError({
                    message: `${currentRouteState.name} name is already being used in the associated layout`,
                    stateID: currentRouteState.id,
                    propPath: ['name'],
                }),
            ]);
        }
    });
    localStateList.forEach(state => {
        if (globalStateListNames.includes(state.name)) {
            onAppAuditNotifications([
                createStateError({
                    message: `${getStateTypeText(state)} name "${state.name}" is already used in global state`,
                    stateID: state.id,
                    propPath: ['name'],
                }),
            ]);
        }
    });
    // path to find needed  currentRouteNode.parentID -> parentId (RouterSwitchSymbol) -> parentID (BuilderComponentsRouteLayout) -> parentId (BuilderComponentsRoute)
    function getCurrentPageLayoutId() {
        const routerSwitchSymbol = nodeList[(currentRouteNode === null || currentRouteNode === void 0 ? void 0 : currentRouteNode.parentID) || ''];
        const builderComponentsRouteLayout = nodeList[(routerSwitchSymbol === null || routerSwitchSymbol === void 0 ? void 0 : routerSwitchSymbol.parentID) || ''];
        const builderComponentsRoute = nodeList[(builderComponentsRouteLayout === null || builderComponentsRouteLayout === void 0 ? void 0 : builderComponentsRouteLayout.parentID) || ''];
        return (builderComponentsRoute === null || builderComponentsRoute === void 0 ? void 0 : builderComponentsRoute.id) || '';
    }
};
