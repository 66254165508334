import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { assocSettings, getCommonFormFieldPropertiesSettings, NS_ID, getCommonActionSettings, } from '../../common';
import { getBuilderComponentsDatePickerSettings } from '../../material-components/BuilderComponentsDatePicker/BuilderComponentsDatePicker.settings';
const basicDatePickerSettings = getBuilderComponentsDatePickerSettings(['datePickerProps']);
const PREFIX_PATH = ['datePickerProps'];
export const BuilderComponentsFormDatePickerSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldPropertiesSettings(), ...settings.children] }))))(basicDatePickerSettings.properties),
    style: basicDatePickerSettings.style,
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: NS_ID.events.events,
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onChangeAction',
                            label: 'On Change',
                            keyPath: [...PREFIX_PATH, 'onChange'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onAcceptAction',
                            label: 'On Accept',
                            keyPath: [...PREFIX_PATH, 'onAccept'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onAccept']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onCloseAction',
                            label: 'On Close',
                            keyPath: [...PREFIX_PATH, 'onClose'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onClose']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onErrorAction',
                            label: 'On Error',
                            keyPath: [...PREFIX_PATH, 'onError'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onError']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMonthChangeAction',
                            label: 'On Month Change',
                            keyPath: [...PREFIX_PATH, 'onMonthChange'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onMonthChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onYearChangeAction',
                            label: 'On Year Change',
                            keyPath: [...PREFIX_PATH, 'onYearChange'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onYearChange']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onOpenAction',
                            label: 'On Open',
                            keyPath: [...PREFIX_PATH, 'onOpen'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'onOpen']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'rifmFormatterAction',
                            label: 'Rifm Formatter',
                            keyPath: [...PREFIX_PATH, 'rifmFormatter'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'rifmFormatter']),
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'shouldDisableDateAction',
                            label: 'Should Disable Date',
                            keyPath: [...PREFIX_PATH, 'shouldDisableDate'],
                            children: getCommonActionSettings([...PREFIX_PATH, 'shouldDisableDate']),
                        },
                        ...getCommonActionSettings(),
                    ],
                },
            ],
        },
    ],
};
