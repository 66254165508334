var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { forwardRef, useEffect } from 'react';
import { FormControl, Typography, Tabs as MaterialTabs, } from '@mui/material';
import Box from '@mui/material/Box';
const colorsPalette = {
    primary: '#1976d2',
    secondary: '#6ef1db',
    inherit: '#1976d2',
};
function createObjectFromCssString(stringValue) {
    var _a, _b;
    const separator = (stringValue === null || stringValue === void 0 ? void 0 : stringValue.includes(';')) ? ';' : ',';
    let finalObject = {};
    if (stringValue) {
        const stringValueArray = stringValue.toString().split(separator);
        for (let i = 0; i < stringValueArray.length; i++) {
            const key = (_a = stringValueArray[i].split(':')[0]) === null || _a === void 0 ? void 0 : _a.replace(/\s+/g, '');
            const sxValue = (_b = stringValueArray[i].split(':')[1]) === null || _b === void 0 ? void 0 : _b.replace(/'|\s+/g, '');
            finalObject = Object.assign(Object.assign({}, finalObject), { [key]: sxValue });
        }
        return finalObject;
    }
}
function TabPanel(props) {
    const { children, value, index } = props, otherTabPanelProps = __rest(props, ["children", "value", "index"]);
    return (_jsx(Box, Object.assign({ role: "tabpanel", hidden: Number(value) !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}` }, otherTabPanelProps, { children: Number(value) === index && (_jsx(Box, Object.assign({ sx: { p: 3 } }, { children: _jsx(Typography, Object.assign({}, otherTabPanelProps, { children: children })) }))) })));
}
export const Tabs = forwardRef((_a, ref) => {
    var { disabled, label: initLabel, value: initialValue, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, style, id, title, className, lang, translate, children, variant, textcolor = 'primary', color, orientation, scrollButtons, headersx, panelsx, onChange, centered, visibleScrollbar } = _a, otherTabProps = __rest(_a, ["disabled", "label", "value", 'data-test', 'data-node-id', 'data-node-render-path', "style", "id", "title", "className", "lang", "translate", "children", "variant", "textcolor", "color", "orientation", "scrollButtons", "headersx", "panelsx", "onChange", "centered", "visibleScrollbar"]);
    const [colorState, setColorState] = React.useState(colorsPalette.primary);
    let labels = children || Array();
    let content = Array();
    if (labels.length === undefined && typeof labels === 'object')
        labels = [labels];
    if (labels.length > 0) {
        content = labels === null || labels === void 0 ? void 0 : labels.map((child, i) => {
            const childy = child;
            const { value: valueContent, children: valueContentText } = childy.props;
            return (_jsx(TabPanel, Object.assign({ value: initialValue, index: Number(valueContent) }, { children: valueContentText }), valueContent));
        });
    }
    const sxObjectPanel = createObjectFromCssString(panelsx);
    const sxObjectHeader = createObjectFromCssString(headersx);
    useEffect(() => {
        const headerColor = colorsPalette[textcolor];
        setColorState(headerColor);
    }, [textcolor]);
    return (_jsx(FormControl, Object.assign({ sx: Object.assign({ typography: 'body1' }, style), className: className, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, lang: lang, translate: translate }, { children: _jsxs(Box, Object.assign({ sx: orientation === 'vertical'
                ? Object.assign(Object.assign({ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }, style), sxObjectHeader) : Object.assign(Object.assign({ borderBottom: 1, borderColor: 'divider' }, style), sxObjectHeader) }, { children: [_jsx(MaterialTabs, Object.assign({}, otherTabProps, { value: initialValue, centered: centered, allowScrollButtonsMobile: true, scrollButtons: Boolean(scrollButtons), orientation: orientation, visibleScrollbar: Boolean(visibleScrollbar), onChange: onChange, variant: variant, sx: Object.assign(Object.assign({ color: colorState }, style), sxObjectHeader) }, { children: labels })), _jsx(Box, Object.assign({ style: Object.assign(Object.assign(Object.assign({}, sxObjectPanel), { width: '100%' }), style) }, otherTabProps, { children: content }))] })) })));
});
