import { appNodesSchemaSelectors, } from '@builder/schemas';
import { formatCode } from '../../utils';
import { ComponentFileGenerator } from './ComponentFileGenerator';
import { generateCustomIconComponent, generateCustomRatingComponent, generateCustomRichtextEditorComponent, generateRichTextCustomComponent, generateSliderCustomComponent, } from './utils';
/**
 * Generates all files which must be generated from the nodeListDSL
 */
export class ComponentFileListUsageGenerator {
    constructor({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
        this.componentFileGenerators = this.getComponentFileGenerators({
            appDSL,
            componentListDSL,
            assetBackendList,
            assetListDSL,
        });
    }
    getComponentFileGenerators({ appDSL, componentListDSL, assetBackendList, assetListDSL, }) {
        const nodesWithFileStructure = appNodesSchemaSelectors.getNodeListWithFileStructure({
            nodeListDSL: this.appDSL.nodes,
            componentListDSL: this.componentListDSL,
        });
        return nodesWithFileStructure.map(nodeDSL => new ComponentFileGenerator({
            appDSL,
            componentListDSL,
            nodeID: nodeDSL.id,
            assetBackendList,
            assetListDSL,
        }));
    }
    getFoldersStructure() {
        return this.componentFileGenerators.reduce((accum, componentFileGenerator) => {
            var _a;
            const folderPath = componentFileGenerator.componentFileAccessor.getFolderPath();
            const existedAccessors = (_a = accum[folderPath]) !== null && _a !== void 0 ? _a : [];
            return Object.assign(Object.assign({}, accum), { [folderPath]: [...existedAccessors, componentFileGenerator] });
        }, {});
    }
    generateComponentsFileStructure(appDSL) {
        const foldersStructure = this.getFoldersStructure();
        return Object.keys(foldersStructure).reduce((accum, folderPath) => {
            const componentFileGenerators = foldersStructure[folderPath];
            const indexFile = componentFileGenerators
                .map(componentFileGenerator => componentFileGenerator.generateComponentExport())
                .join('\n');
            const componentsFiles = componentFileGenerators.reduce((componentsFilesAccum, componentFileGenerator) => {
                return Object.assign(Object.assign({}, componentsFilesAccum), { [`src/${componentFileGenerator.componentFileAccessor.getPathToFile()}`]: {
                        content: formatCode(componentFileGenerator.generateComponentFile()),
                    } });
            }, {});
            return Object.assign(Object.assign(Object.assign({}, accum), componentsFiles), { 'src/shared/components/RichText/RichText.tsx': {
                    content: formatCode(generateRichTextCustomComponent()),
                }, 'src/shared/components/Slider/Slider.tsx': {
                    content: formatCode(generateSliderCustomComponent()),
                }, 'src/shared/components/Icon/Icon.tsx': {
                    content: formatCode(generateCustomIconComponent(appDSL)),
                }, 'src/shared/components/Rating/Rating.tsx': {
                    content: formatCode(generateCustomRatingComponent(appDSL)),
                }, 'src/shared/components/RichtextEditor/RichtextEditor.tsx': {
                    content: formatCode(generateCustomRichtextEditorComponent(appDSL)),
                }, [`src/${folderPath}/index.ts`]: {
                    content: formatCode(indexFile),
                } });
        }, {});
    }
}
