import { put, takeEvery } from 'redux-saga/effects';

import {
  ComponentDSLNameTypes,
  NodeTemplateDSL,
  SchemaOverrideDSL,
  StateTemplateDSL,
} from '@builder/schemas';

import {
  ComponentCreateMeta,
  ComponentMoveMeta,
  DashboardContextUpdateEvents,
  DASHBOARD_EVENTS,
  NODE_SETTINGS_TABS,
  RightPanelType,
  RIGHT_PANEL_TABS,
  UIBuilderEvents,
  UI_BUILDER_EVENTS,
} from 'src/store';

type UpdateRightPanelEvent = Extract<
  UIBuilderEvents,
  { type: typeof UI_BUILDER_EVENTS.updateRightPanel; rightPanel: RightPanelType }
>;

type ComponentCreateEvent = Extract<
  DashboardContextUpdateEvents,
  {
    type: typeof DASHBOARD_EVENTS.componentCreate;
    place: ComponentCreateMeta;
    value: {
      name: ComponentDSLNameTypes;
      props?: NodeTemplateDSL['props'];
      states?: StateTemplateDSL[];
      schemaOverride?: SchemaOverrideDSL;
      selectNodeOnCreate?: boolean;
    };
  }
>;

function* selectPropertyTab(event: ComponentCreateEvent) {
  if ((event.place as ComponentMoveMeta)?.kind) {
    yield put<UpdateRightPanelEvent>({
      type: UI_BUILDER_EVENTS.updateRightPanel,
      rightPanel: {
        currentTab: RIGHT_PANEL_TABS.componentList,
        nodeSettingsArgs: {
          currentTab: NODE_SETTINGS_TABS.properties,
        },
      },
    });
  }
}

export function* watchComponentCreate(): Generator {
  yield takeEvery(DASHBOARD_EVENTS.componentCreate, selectPropertyTab);
}
