/* eslint-disable no-bitwise */
import { COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, COMPONENT_LIST_MODE, MENU_GROUP_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { RouterSwitchSymbolSettings } from './RouterSwitchSymbol.settings';
export const RouterSwitchSymbol = {
    name: COMPONENT_DSL_NAMES.RouterSwitchSymbol,
    displayName: 'View Component',
    icon: 'pageSwitcher',
    type: COMPONENT_DSL_TYPES.symbol,
    kind: COMPONENT_DSL_KINDS.routerChangeableContent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    componentListMode: COMPONENT_LIST_MODE.page || COMPONENT_LIST_MODE.layout,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.Iterator,
                },
            },
        ],
        presentations: [visibleByCondition],
        componentListMode: COMPONENT_LIST_MODE.layout,
        dndTargetPropName: 'routes',
        accessibility: {
            disableCopying: true,
        },
        predefineds: {
            props: {
                routes: {
                    nodes: [],
                },
                redirects: {
                    nodes: [],
                },
            },
        },
        props: {
            routes: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [
                    COMPONENT_DSL_NAMES.BuilderComponentsRoute,
                    COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout,
                ],
            },
            redirects: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.Redirect],
            },
        },
        hiddenLayout: true,
        system: false,
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.div,
            props: {
                style: {
                    display: 'flex',
                    flex: '1 1 0%',
                    flexDirection: 'column',
                    height: '100%',
                    borderColor: '#8cc90c',
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.RouterSwitch,
                            props: {
                                children: '{{ [symbolProps.routes, symbolProps.redirects] }}',
                            },
                        },
                    ],
                },
            },
        },
    },
    settings: RouterSwitchSymbolSettings,
};
