import { ValueOf } from '@builder/utils';

export const COMPONENT_DSL_TYPES = {
  htmlElement: 'html-element',
  basicElement: 'basic-element',
  reactImportComponent: 'react-import-component',
  textComponent: 'text-component',
  symbol: 'symbol',
} as const;

export type ComponentDSLTypes = ValueOf<typeof COMPONENT_DSL_TYPES>;

export const COMPONENT_DSL_KINDS = {
  root: 'root',
  layout: 'layout',
  route: 'route',
  modal: 'modal',
  routerChangeableContent: 'router-changeable-content',
  rootLayoutContainer: 'root-layout-container',
} as const;

export type ComponentDSLKinds = ValueOf<typeof COMPONENT_DSL_KINDS>;

export const COMPONENT_DSL_RULES_INTERACTION_TYPES = {
  insideOnly: 'inside-only',
  exceptOnly: 'except-only',
} as const;

export type ComponentDSLRulesPropTypes = ValueOf<typeof COMPONENT_DSL_RULES_INTERACTION_TYPES>;

export type ComponentDSLInsideOnlyType = {
  type: ComponentDSLRulesPropTypes;
  value: ComponentDSLNameTypes;
};

export const COMPONENT_DSL_PROP_TYPES = {
  action: 'action',
  array: 'array',
  asset: 'asset',
  boolean: 'boolean',
  calculatedReactNode: 'calculated-react-node',
  callback: 'callback',
  css: 'css',
  number: 'number',
  object: 'object',
  reactNode: 'react-node',
  string: 'string',
  stringOrNumber: 'stringOrNumber',
  style: 'style',
  /** component not supported */
  component: 'component',
  /** enum not supported */
  enum: 'enum',
  /** element-type not supported */
  elementType: 'element-type',
  /** uses for cases when we can't imagine which type should be there */
  any: 'any',
  customProps: 'customProps',
  fieldValidation: 'fieldValidation',
  htmlFragment: 'htmlFragment',
  notSupported: '__not_supported__',
} as const;

export type ComponentDSLPropTypes = ValueOf<typeof COMPONENT_DSL_PROP_TYPES>;

export const COMPONENT_DSL_CALLBACK_TYPES = {
  component: 'component',
  code: 'code',
} as const;

export type ComponentDSLCallbackTypes = ValueOf<typeof COMPONENT_DSL_CALLBACK_TYPES>;

export const COMPONENT_DSL_INTERACTION_TYPES = {
  onlyRemovable: 'only-removable',
  onlyEditable: 'only-editable',
  all: 'all',
  withinParent: 'within-parent',
  none: 'none',
} as const;

export type ComponentDSLInteractionTypes = ValueOf<typeof COMPONENT_DSL_INTERACTION_TYPES> | string;

export const COMPONENT_DSL_INSIDE_ONLY_TYPES = {
  directParent: 'directParent',
  anyAncestor: 'anyAncestor',
} as const;

export type ComponentDSLInsideOnlyTypes = ValueOf<typeof COMPONENT_DSL_INSIDE_ONLY_TYPES>;

export const COMPONENT_DSL_IMPORT_SOURCES = {
  materialUICore: '@mui/material',
  builderComponents: '@builder/components',
  sharedComponents: 'shared/components',
  reactRouterDOM: 'react-router-dom',
  react: 'react',
} as const;

export type ComponentDSLImportSourceTypes = ValueOf<typeof COMPONENT_DSL_IMPORT_SOURCES>;

export const MATERIAL_COMPONENT_DSL_NAMES = {
  BuilderComponentsAutocomplete: 'BuilderComponentsAutocomplete',
  BuilderComponentsAutocompleteOption: 'BuilderComponentsAutocompleteOption',
  BuilderComponentsBadge: 'BuilderComponentsBadge',
  BuilderComponentsBox: 'BuilderComponentsBox',
  BuilderComponentsButton: 'BuilderComponentsButton',
  BuilderComponentsCheckbox: 'BuilderComponentsCheckbox',
  BuilderComponentsDataGrid: 'BuilderComponentsDataGrid',
  BuilderComponentsDatePicker: 'BuilderComponentsDatePicker',
  BuilderComponentsDateTimePicker: 'BuilderComponentsDateTimePicker',
  BuilderComponentsFileDropZone: 'BuilderComponentsFileDropZone',
  BuilderComponentsFileDropZoneBase: 'BuilderComponentsFileDropZoneBase',
  BuilderComponentsFilePicker: 'BuilderComponentsFilePicker',
  BuilderComponentsFileUpload: 'BuilderComponentsFileUpload',
  BuilderComponentsFileUploadButton: 'BuilderComponentsFileUploadButton',
  BuilderComponentsFloatingActionButton: 'BuilderComponentsFloatingActionButton',
  BuilderComponentsFooter: 'BuilderComponentsFooter',
  BuilderComponentsHtmlFragment: 'BuilderComponentsHtmlFragment',
  BuilderComponentsIcon: 'BuilderComponentsIcon',
  BuilderComponentsImage: 'BuilderComponentsImage',
  BuilderComponentsMap: 'BuilderComponentsMap',
  BuilderComponentsMenu: 'BuilderComponentsMenu',
  BuilderComponentsMultiselect: 'BuilderComponentsMultiselect',
  BuilderComponentsPopover: 'BuilderComponentsPopover',
  BuilderComponentsPopper: 'BuilderComponentsPopper',
  BuilderComponentsRating: 'BuilderComponentsRating',
  BuilderComponentsRichtextEditor: 'BuilderComponentsRichtextEditor',
  BuilderComponentsRouterLink: 'BuilderComponentsRouterLink',
  BuilderComponentsSelect: 'BuilderComponentsSelect',
  BuilderComponentsSlider: 'BuilderComponentsSlider',
  BuilderComponentsSnackBar: 'BuilderComponentsSnackBar',
  BuilderComponentsSnackbarContent: 'BuilderComponentsSnackbarContent',
  BuilderComponentsSpeedDial: 'BuilderComponentsSpeedDial',
  BuilderComponentsSpeedDialAction: 'BuilderComponentsSpeedDialAction',
  BuilderComponentsSwitch: 'BuilderComponentsSwitch',
  BuilderComponentsTab: 'BuilderComponentsTab',
  BuilderComponentsTabs: 'BuilderComponentsTabs',
  BuilderComponentsTextField: 'BuilderComponentsTextField',
  BuilderComponentsTimePicker: 'BuilderComponentsTimePicker',
  BuilderComponentsTooltip: 'BuilderComponentsTooltip',
  MaterialAccordion: 'MaterialAccordion',
  MaterialAccordionActions: 'MaterialAccordionActions',
  MaterialAccordionDetails: 'MaterialAccordionDetails',
  MaterialAccordionSummary: 'MaterialAccordionSummary',
  MaterialAlert: 'MaterialAlert',
  MaterialAlertTitle: 'MaterialAlertTitle',
  MaterialAppBar: 'MaterialAppBar',
  MaterialAvatar: 'MaterialAvatar',
  MaterialBadge: 'MaterialBadge',
  MaterialBreadcrumbs: 'MaterialBreadcrumbs',
  MaterialButtonGroup: 'MaterialButtonGroup',
  MaterialCard: 'MaterialCard',
  MaterialCardContent: 'MaterialCardContent',
  MaterialCardHeader: 'MaterialCardHeader',
  MaterialCardMedia: 'MaterialCardMedia',
  MaterialChip: 'MaterialChip',
  MaterialDialog: 'MaterialDialog',
  MaterialDialogActions: 'MaterialDialogActions',
  MaterialDialogContent: 'MaterialDialogContent',
  MaterialDialogContentText: 'MaterialDialogContentText',
  MaterialDialogTitle: 'MaterialDialogTitle',
  MaterialDivider: 'MaterialDivider',
  MaterialDrawer: 'MaterialDrawer',
  MaterialDrawerContent: 'MaterialDrawerContent',
  MaterialGrid: 'MaterialGrid',
  MaterialIconButton: 'MaterialIconButton',
  MaterialList: 'MaterialList',
  MaterialListItem: 'MaterialListItem',
  MaterialListItemButton: 'MaterialListItemButton',
  MaterialListItemIcon: 'MaterialListItemIcon',
  MaterialListItemText: 'MaterialListItemText',
  MaterialMenuItem: 'MaterialMenuItem',
  MaterialPaper: 'MaterialPaper',
  MaterialToggleButton: 'MaterialToggleButton',
  MaterialToggleButtonGroup: 'MaterialToggleButtonGroup',
  MaterialToolbar: 'MaterialToolbar',
  MaterialTypography: 'MaterialTypography',
} as const;

export type MaterialComponentDSLNameTypes = ValueOf<typeof MATERIAL_COMPONENT_DSL_NAMES>;

export const BASE_COMPONENTS_NAMES = {
  BuilderComponentsFileUploadBase: 'BuilderComponentsFileUploadBase',
  BuilderComponentsFileUploadButtonBase: 'BuilderComponentsFileUploadButtonBase',
} as const;

export type BaseComponentsNamesTypes = ValueOf<typeof BASE_COMPONENTS_NAMES>;

export const FORM_COMPONENT_DSL_NAMES = {
  BuilderComponentsForm: 'BuilderComponentsForm',
  BuilderComponentsFormFieldArray: 'BuilderComponentsFormFieldArray',
  BuilderComponentsFormAutocomplete: 'BuilderComponentsFormAutocomplete',
  BuilderComponentsFormCheckbox: 'BuilderComponentsFormCheckbox',
  BuilderComponentsFormSwitch: 'BuilderComponentsFormSwitch',
  BuilderComponentsFormSlider: 'BuilderComponentsFormSlider',
  BuilderComponentsFormRadioGroup: 'BuilderComponentsFormRadioGroup',
  BuilderComponentsFormRating: 'BuilderComponentsFormRating',
  BuilderComponentsRadioGroup: 'BuilderComponentsRadioGroup',
  BuilderComponentsFormSelect: 'BuilderComponentsFormSelect',
  BuilderComponentsFormMultiselect: 'BuilderComponentsFormMultiselect',
  BuilderComponentsFormTextField: 'BuilderComponentsFormTextField',
  BuilderComponentsFormFileUpload: 'BuilderComponentsFormFileUpload',
  BuilderComponentsRadio: 'BuilderComponentsRadio',
  BuilderComponentsFormDatePicker: 'BuilderComponentsFormDatePicker',
  BuilderComponentsFormDateTimePicker: 'BuilderComponentsFormDateTimePicker',
  BuilderComponentsFormTimePicker: 'BuilderComponentsFormTimePicker',
  MaterialFormLabel: 'MaterialFormLabel',
  BuilderComponentsFormRichtextEditor: 'BuilderComponentsFormRichtextEditor',
} as const;

export type FormComponentDSLNameTypes = ValueOf<typeof FORM_COMPONENT_DSL_NAMES>;

export const ROUTING_COMPONENT_DSL_NAMES = {
  BuilderComponentsRoute: 'BuilderComponentsRoute',
  BuilderComponentsRouteForLayout: 'BuilderComponentsRouteForLayout',
  Redirect: 'Redirect',
  RouterSwitch: 'RouterSwitch',
  BuilderComponentsRouteLayout: 'BuilderComponentsRouteLayout',
} as const;

export type RoutingComponentDSLNameTypes = ValueOf<typeof ROUTING_COMPONENT_DSL_NAMES>;

export const HTML_ELEMENTS_DSL_NAMES = {
  a: 'a',
  audio: 'audio',
  blockquote: 'blockquote',
  canvas: 'canvas',
  div: 'div',
  em: 'em',
  form: 'form',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  img: 'img',
  li: 'List Item',
  ol: 'Ordered List',
  p: 'Paragraph',
  pre: 'pre',
  span: 'span',
  strong: 'strong',
  table: 'table',
  tableBody: 'tableBody',
  tableCaption: 'tableCaption',
  tableColumn: 'tableColumn',
  tableColumnGroup: 'tableColumnGroup',
  tableDataCell: 'tableDataCell',
  tableFoot: 'tableFoot',
  tableHead: 'tableHead',
  tableHeader: 'tableHeader',
  tableRow: 'tableRow',
  ul: 'Unordered List',
  video: 'video',
} as const;

export type HTMLElementDSLNameTypes = ValueOf<typeof HTML_ELEMENTS_DSL_NAMES>;

export const BASIC_ELEMENTS_DSL_NAMES = {
  hyperlink: 'hyperlink',
} as const;

export type BasicElementDSLNameTypes = ValueOf<typeof BASIC_ELEMENTS_DSL_NAMES>;

export const UTILS_DSL_NAMES = {
  Text: 'Text',
  BuilderRichText: 'BuilderRichText',
  Iterator: 'Iterator',
  Fragment: 'Fragment',
  AsyncContent: 'AsyncContent',
} as const;

export type UtilsComponentDSLNameTypes = ValueOf<typeof UTILS_DSL_NAMES>;

export const SYMBOL_DSL_NAMES = {
  AccordionSymbol: 'AccordionSymbol',
  AlertSymbol: 'AlertSymbol',
  GridContainerSymbol: 'GridContainerSymbol',
  GridItemSymbol: 'GridItemSymbol',
  LocalDialogSymbol: 'LocalDialogSymbol',
  DialogSymbol: 'DialogSymbol',
  ListItemIconSymbol: 'ListItemIconSymbol',
  ListItemSymbol: 'ListItemSymbol',
  AppBarSymbol: 'AppBarSymbol',
  HeadingSymbol: 'HeadingSymbol',
  CardSymbol: 'CardSymbol',
  RouterSwitchSymbol: 'RouterSwitchSymbol',
  BaseLayout: 'BaseLayout',
  PageLayout: 'PageLayout',
  MobileLayout: 'MobileLayout',
  BasePageLayout: 'BasePageLayout',
  RootLayoutsContainer: 'RootLayoutsContainer',
  NavListItemSymbol: 'NavListItemSymbol',
  root: 'root',
  FileDropZoneSymbol: 'FileDropZoneSymbol',
  FileDropZoneBaseSymbol: 'FileDropZoneBaseSymbol',
};

export type SymbolDSLNameTypes = ValueOf<typeof SYMBOL_DSL_NAMES>;

export const COMPONENT_DSL_NAMES = {
  ...UTILS_DSL_NAMES,
  ...SYMBOL_DSL_NAMES,
  ...MATERIAL_COMPONENT_DSL_NAMES,
  ...FORM_COMPONENT_DSL_NAMES,
  ...ROUTING_COMPONENT_DSL_NAMES,
  ...HTML_ELEMENTS_DSL_NAMES,
  ...BASIC_ELEMENTS_DSL_NAMES,
  ...BASE_COMPONENTS_NAMES,
};

export type ComponentDSLNameTypes =
  | UtilsComponentDSLNameTypes
  | HTMLElementDSLNameTypes
  | BasicElementDSLNameTypes
  | RoutingComponentDSLNameTypes
  | FormComponentDSLNameTypes
  | MaterialComponentDSLNameTypes
  | BaseComponentsNamesTypes
  | SymbolDSLNameTypes;

export const MENU_GROUP_NAMES = {
  basic: 'basic',
  common: 'common',
  controls: 'controls',
  data: 'data',
  form: 'form',
  html: 'html',
  layout: 'layout',
  other: 'other',
} as const;

export type MenuGroupNameTypes = ValueOf<typeof MENU_GROUP_NAMES>;

export const TYPE_DEFINITION_TYPES = {
  import: 'import',
  system: 'system',
} as const;

export type TypeDefinitionTypes = ValueOf<typeof TYPE_DEFINITION_TYPES>;

export const LAYOUT_MODES = {
  allPageNodes: 'all-page-nodes',
  layoutNodes: 'layout-nodes',
  currentRouteNodes: 'current-route-nodes',
  hiddenCanvasNodes: 'hidden-canvas-nodes',
} as const;

export type LayoutModeTypes = ValueOf<typeof LAYOUT_MODES>;

export const COMPONENT_LIST_MODE = {
  page: 'page',
  layout: 'layout',
} as const;

export type ComponentListModeTypes = ValueOf<typeof COMPONENT_LIST_MODE>;
