import { ReactNode } from 'react';

import styled from '@emotion/styled';

import { ComponentSettingRootDSL } from '@builder/schemas';

type RootSectionTypeProps = {
  rootSectionSetting: ComponentSettingRootDSL;
  children: ReactNode;
};

export const RootSectionType = styled.div<RootSectionTypeProps>`
  display: grid;
  grid-gap: ${props => props.theme.spacing(2)};
`;
