import React from 'react';

import { ComponentSettingGridDSL } from '@builder/schemas';
import { memo } from '@builder/utils';

import { CssGrid } from 'src/shared/components';

type GridTypeProps = {
  gridSetting: ComponentSettingGridDSL;
  children: React.ReactNode;
  'data-test'?: string;
};

export const GridType = memo(
  'GridType',
  ({ gridSetting, children, ...props }: GridTypeProps): JSX.Element => {
    return (
      <CssGrid {...gridSetting.props} width="100%">
        {children}
      </CssGrid>
    );
  },
);
