import { FC, useMemo } from 'react';

import { Completion } from '@codemirror/autocomplete';
import { Extension } from '@codemirror/state';

import { StateCategoriesDSL, stateListSelectors } from '@builder/schemas';

import { useAppDSLStates, useUserAppRuntimeState } from '../../../providers';

import { flatternObject, getObjectProperties, transformFunctions } from './CodeMirror.utils';
import { useCodeMirror } from './codeMirrorInit';

type CodeMirrorProps = {
  extensions?: Extension[];
  options?: Completion[];
  defaultCode?: string;
};

export const CodeMirrorComponent: FC<CodeMirrorProps> = ({
  extensions = [],
  options = [],
  defaultCode,
}: CodeMirrorProps) => {
  const appStatesDSL = useAppDSLStates();
  const { globalState = {}, localStates = {} } = useUserAppRuntimeState();
  const globalCategorized = stateListSelectors.getGlobalStateCategorized(appStatesDSL, globalState);
  const localCategorized = stateListSelectors.getLocalStateCategorized(appStatesDSL, localStates);
  const globalFunctions = stateListSelectors.getFunctionGlobalStateArrayDSL(appStatesDSL);
  const localFunctions = stateListSelectors.getFunctionLocalStateArrayDSL(appStatesDSL);

  const uncategorizedStates = useMemo(() => {
    const result: Record<string, unknown> = {};

    for (const key in localCategorized) {
      if (key in globalCategorized) {
        result[key] = { ...localCategorized[key], ...globalCategorized[key] };
      } else {
        result[key] = localCategorized[key];
      }
    }

    for (const key in globalCategorized) {
      if (!(key in localCategorized)) {
        result[key] = globalCategorized[key];
      }
    }

    return flatternObject(result as StateCategoriesDSL);
  }, [globalCategorized, localCategorized]);

  const transformedFunctionStates = transformFunctions([
    ...globalFunctions,
    ...localFunctions,
  ]).reduce((acc, cur) => ({ ...acc, ...cur }), {}) as Record<string, never>;

  const formattedOptions = getObjectProperties({
    ...uncategorizedStates,
    ...transformedFunctionStates,
  });

  const { ref } = useCodeMirror(extensions, formattedOptions, defaultCode);

  return <div ref={ref} />;
};
