/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from '@builder/utils';

import { BuildDndSpecArgs, DraggableOverlayOrIconItem } from '../logic';
import { ComponentMoveMeta } from 'src/store';

const cache: Map<string, any> = new Map();

export function buildDndSpecMemoizer<T extends (...args: any[]) => any>(func: T): T {
  return function closure(this: unknown, ...args: Parameters<T>): ReturnType<T> {
    const { targetEl, item, nodeListDSL } = args[0] as BuildDndSpecArgs;
    const isOverlayItem = isArray(item);
    const itemIds = isOverlayItem
      ? (item as DraggableOverlayOrIconItem[]).map(x => x.id).join()
      : item.id;

    if (cache.has('rootID')) {
      const rootID = cache.get('rootID');

      if (rootID !== itemIds) {
        cache.clear();
        cache.set('rootID', itemIds);
      }
    } else {
      cache.set('rootID', itemIds);
    }

    const key = `${JSON.stringify(item)}->${targetEl.dataset.nodeId}`;

    if (cache.has(key)) {
      const cachedResult = cache.get(key) as ComponentMoveMeta;
      const cacheTargetNodeID = cachedResult?.target?.nodeID;

      //! RTC user deleted the cached target, cleanup is needed here
      if (!nodeListDSL[cacheTargetNodeID]) {
        cache.delete(key);
        return func.apply(this, args);
      }

      return cachedResult as any;
    }

    const result = func.apply(this, args);
    cache.set(key, result);
    return result;
  } as T;
}
