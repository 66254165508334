import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, } from '@apollo/client';
export const getServerUrl = (resourceId) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resources = window.appResourceList;
    const api = resourceId && resources && resources[resourceId] && resources[resourceId].api;
    return api || process.env.REACT_APP_SERVER_URL || '';
};
export const APP_URL = {
    root: '/',
    public: {
        auth: {
            root: '/auth',
            callback: '/auth/callback',
        },
    },
    workspace: {
        root: '/workspace/:workspaceID',
        dashboard: '/workspace/:workspaceID/dashboard',
        preview: '/workspace/:workspaceID/preview',
    },
};
export const getApolloClient = (workspaceId, token) => {
    const resourceId = workspaceId ? workspaceId.split('_')[0] : '';
    const apiEndpoint = `${getServerUrl(resourceId)}/${workspaceId}`;
    const httpLink = createHttpLink({ uri: apiEndpoint });
    const authLink = new ApolloLink((operation, foward) => {
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        });
        return foward(operation);
    });
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
    });
    return client;
};
