import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsTooltipSettings } from './BuilderComponentsTooltip.settings';
export const BuilderComponentsTooltip = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsTooltip,
    displayName: 'Tooltip',
    icon: 'tooltip',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Tooltip',
    },
    schema: {
        dndTargetPropName: 'children',
        mainPropPath: {
            selectNodeInside: ['children'],
            mainProp: 'Text',
        },
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Typography',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                title: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: {
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Tooltip',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                arrow: false,
                disableInteractive: false,
                disableFocusListener: false,
                disableHoverListener: false,
                disableTouchListener: false,
                enterDelay: 100,
                enterNextDelay: 0,
                enterTouchDelay: 700,
                leaveDelay: 0,
                leaveTouchDelay: 1500,
                placement: 'bottom',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { arrow: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableInteractive: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFocusListener: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableHoverListener: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableTouchListener: { type: COMPONENT_DSL_PROP_TYPES.boolean }, enterDelay: { type: COMPONENT_DSL_PROP_TYPES.number }, enterNextDelay: { type: COMPONENT_DSL_PROP_TYPES.number }, enterTouchDelay: { type: COMPONENT_DSL_PROP_TYPES.number }, leaveDelay: { type: COMPONENT_DSL_PROP_TYPES.number }, leaveTouchDelay: { type: COMPONENT_DSL_PROP_TYPES.number }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, placement: { type: COMPONENT_DSL_PROP_TYPES.enum }, PopperComponent: { type: COMPONENT_DSL_PROP_TYPES.elementType }, PopperProps: { type: COMPONENT_DSL_PROP_TYPES.object }, title: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, TransitionComponent: { type: COMPONENT_DSL_PROP_TYPES.elementType }, TransitionProps: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsTooltipSettings,
};
