import React, { useLayoutEffect, useState } from 'react';

import styled from '@emotion/styled';
import { isEmpty } from 'ramda';

import { hasPropJsCode } from '@builder/schemas';
import { isString } from '@builder/utils';

import { CustomCodeViewEditor } from '../CustomCodeViewEditor';
import { AutoCompleteOptions } from 'src/features/node-settings/types';
import { useDashboardState } from 'src/providers';
import { Autocomplete } from 'src/shared/components';
import { LabelPlacementType } from 'src/shared/components/common/InputContainer';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

import { useJsInjectionOptions } from './useJsInjectionOptions';

const JsCodeInjectionInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

type JsCodeInjectionInputProps<T extends unknown | string> = {
  label: string;
  labelPlacement?: LabelPlacementType;
  propValue?: T;
  onChangePropValue: (propValue: T) => void;
  disableFx?: () => void;
  children: (arg: {
    isFxEnabled: string;
    enableFx?: () => void;
    nonJsCodePropValue?: T;
  }) => React.ReactNode;
  nodeID?: string;
  showFx?: boolean;
  typeField?: string;
  'data-test'?: string;
  fxDefaultEnabled?: string;
  customStateOptions?: AutoCompleteOptions[];
};

export const JsCodeInjectionInput = <T extends unknown | string>({
  label,
  labelPlacement,
  propValue,
  onChangePropValue,
  children,
  nodeID,
  showFx = true,
  disableFx: disableFxAction,
  typeField = 'string',
  fxDefaultEnabled = ACTIVEPROP.literal,
  'data-test': dataTest,
  customStateOptions,
}: JsCodeInjectionInputProps<T>): JSX.Element => {
  const dashboardState = useDashboardState();
  const [mode, setMode] = useState(fxDefaultEnabled);

  const {
    enableFx,
    getAutocompleteLabel,
    getNoOptionText,
    pagesOptions,
    statesOptions,
    isPageProp,
    statesNames,
  } = useJsInjectionOptions({
    ACTIVEPROP,
    setMode,
    disableFxAction,
    onChangePropValue,
    showFx,
    nodeID,
    label,
    typeField,
  });
  const isPageOption = pagesOptions
    .map((page: { value: string }) => page.value)
    .includes(propValue as string);

  const customStateNames =
    customStateOptions && !isEmpty(customStateOptions)
      ? customStateOptions.map(state => state.value)
      : [];

  useLayoutEffect(() => {
    // Show input custom-entries if property already has custom-entries-js injection.
    if (
      isString(propValue) &&
      [...statesNames, ...customStateNames].includes(propValue as string)
    ) {
      return setMode(ACTIVEPROP.state);
    }

    if (isPageProp && isPageOption) {
      return setMode(ACTIVEPROP.page);
    }

    // Show input f(x) if property already has js injection.
    if (hasPropJsCode(propValue) && fxDefaultEnabled) {
      setMode(ACTIVEPROP.code);
    }
    // Run it only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesOptions]);
  return (
    <JsCodeInjectionInputWrapper>
      <Choose>
        <When condition={showFx && mode === ACTIVEPROP.code}>
          <CustomCodeViewEditor
            label={getAutocompleteLabel(label, 'Code')}
            labelPlacement={labelPlacement}
            value={hasPropJsCode(propValue) ? propValue : ''}
            onChange={value => onChangePropValue(value as T)}
            nodeID={nodeID}
            isFxEnabled={mode}
            showFx={showFx}
            enableFx={() => enableFx(ACTIVEPROP.code)}
            data-test={`${dataTest}.jsCodeTextField`}
            dashboardState={dashboardState}
          />
        </When>
        <When condition={showFx && mode === ACTIVEPROP.page && isPageProp}>
          <Autocomplete
            fullWidth
            required
            value={propValue as string}
            label={getAutocompleteLabel(label, 'App Pages')}
            onChange={value => onChangePropValue(value as T)}
            options={pagesOptions}
            isFxEnabled={mode}
            enableFx={() => enableFx(ACTIVEPROP.page)}
            data-test={`${dataTest}.page`}
            showFx={showFx}
            noOptionsText={getNoOptionText('pages')}
          />
        </When>
        <When condition={showFx && mode === ACTIVEPROP.state}>
          <Autocomplete
            fullWidth
            required
            value={propValue as string}
            label={getAutocompleteLabel(label, 'State')}
            onChange={value => onChangePropValue(value as T)}
            options={customStateOptions || statesOptions}
            isFxEnabled={mode}
            enableFx={() => enableFx(ACTIVEPROP.state)}
            data-test={`${dataTest}.jsCodeTextField`}
            showFx={showFx}
            noOptionsText={getNoOptionText('entries')}
          />
        </When>
        <Otherwise>
          {children({
            isFxEnabled: mode,
            enableFx: () => enableFx(ACTIVEPROP.literal),
            nonJsCodePropValue: hasPropJsCode(propValue) ? undefined : propValue,
          })}
        </Otherwise>
      </Choose>
    </JsCodeInjectionInputWrapper>
  );
};
