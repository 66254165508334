import { PaletteOptions } from '@mui/material';
import { assocPath, isEmpty, isNil } from 'ramda';

import { AppDSL, CSS, ThemeFrameworkSettingsDSL, themeSelectors } from '@builder/schemas';
import { ROOT_SELECTORS_ARRAY, CSSParser, getSupportedMuiColor } from '@builder/utils';

import { DEFAULT_CSS_COLOR_VARIABLES_PROPS } from 'src/features/theme-manager-color-settings';

const removeEmptyObjectsFromObject = (
  obj: Record<string, unknown> | undefined,
): Record<string, unknown> | undefined => {
  if (!obj) {
    return obj;
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    if (!isNil(value) && !isEmpty(value)) {
      if (typeof value !== 'object') {
        return {
          ...result,
          [key]: value,
        };
      }

      return {
        ...result,
        [key]: removeEmptyObjectsFromObject(value as Record<string, unknown>),
      };
    }

    return result;
  }, {});
};

/**
 *
 * @example
 * `
 *  const themeDSL = {
 *    palette: {
 *        primary: {
 *           main: "#202020",
 *        },
 *        secondary: {
 *          main: "#42A5F5",
 *          light: undefined,
 *        },
 *        error: {}
 *    }
 *  }
 *
 * removeEmptyValuesFromFrameworkSettings(themeDSL) => ({
 *    palette: {
 *        primary: {
 *           main: "#202020",
 *        },
 *        secondary: {
 *          main: "#42A5F5",
 *        }
 *    }
 *  })
 * `
 */
const removeEmptyValuesFromThemeSettingsPalette = (
  frameworkSettings: ThemeFrameworkSettingsDSL | undefined = {},
): ThemeFrameworkSettingsDSL => {
  const palette = frameworkSettings.palette || {};
  const paletteWithoutUndefinedValues = JSON.parse(JSON.stringify(palette));

  const paletteWithoutEmptyValues = removeEmptyObjectsFromObject(
    paletteWithoutUndefinedValues as Record<string, unknown>,
  ) as PaletteOptions;

  return {
    ...frameworkSettings,
    palette: paletteWithoutEmptyValues,
  };
};

/**
 *
 * @example
 * `
 *  const frameworkSettings = {
 *    palette: {
 *        primary: {
 *           main: "#202020",
 *        }
 *        secondary: {
 *          main: "#42A5F5",
 *        }
 *    }
 *  }
 *
 * const cssBody = `
 *    :root {
 *      --primaryMain: #66BB6A,
 *      --primaryDark: #00695C,
 *      --errorMain: #D32F2F,
 *    }
 * `
 * updateFrameworkSettingsColorVariables({ frameworkSettings, cssBody}) => ({
 *    palette: {
 *        primary: {
 *           main: "#66BB6A",
 *           dark: "#00695C"
 *        }
 *        error: {
 *          main: "#D32F2F"
 *        }
 *    }
 *  })
 * `
 */
export const updateFrameworkSettingsColorVariables = ({
  frameworkSettings,
  cssBody,
}: {
  frameworkSettings?: ThemeFrameworkSettingsDSL;
  cssBody: CSS;
}): ThemeFrameworkSettingsDSL => {
  const variables = cssBody ? CSSParser.getVariablesFromCSSBody(cssBody) : {};

  const updatedDefaultCSSVariables = Object.values(DEFAULT_CSS_COLOR_VARIABLES_PROPS).reduce(
    (result, color) => {
      const varNameWithDash = color.colorPickerProps.currentColorPropertyName;

      if (varNameWithDash in variables) {
        const initialColorValue = variables[varNameWithDash];
        const supportedColorValue = getSupportedMuiColor(initialColorValue);

        return [
          ...result,
          {
            keyPath: color.frameworkSettingsPath,
            keyValue: supportedColorValue,
          },
        ];
      }

      return [
        ...result,
        {
          keyPath: color.frameworkSettingsPath,
          keyValue: undefined,
        },
      ];
    },
    [] as Array<{ keyPath: Array<string | number>; keyValue: string | undefined }>,
  );

  const newFrameworkSettings = updatedDefaultCSSVariables.reduce(
    (result, { keyPath, keyValue }) => {
      return assocPath(keyPath, keyValue, result);
    },
    frameworkSettings,
  );

  return removeEmptyValuesFromThemeSettingsPalette(newFrameworkSettings);
};

export const themeCSSGlobalSelectorsUpdate = ({
  appDSL,
  selectors,
  cssBody,
}: {
  appDSL: AppDSL;
  selectors: string[];
  cssBody: CSS;
}): AppDSL => {
  const { theme } = appDSL;
  const shouldUpdateLegasyTheme = CSSParser.selectorsEquals(selectors, ROOT_SELECTORS_ARRAY);
  const globalCSS = themeSelectors.getGlobalCSS(theme);
  const selectorRule = CSSParser.getCSSRuleFromCSSBodyBySelectors(cssBody, selectors);

  if (!selectorRule) {
    return appDSL;
  }

  const newFrameworkSettings = !shouldUpdateLegasyTheme
    ? theme?.frameworkSettings
    : updateFrameworkSettingsColorVariables({
        frameworkSettings: theme?.frameworkSettings,
        cssBody,
      });

  const updatedGlobalCSS = CSSParser.updateRuleFromCSSBodyBySelectors({
    cssBody: globalCSS,
    selectors,
    cssBodyRule: cssBody,
  });

  return {
    ...appDSL,
    theme: {
      ...appDSL.theme,
      frameworkSettings: {
        ...newFrameworkSettings,
      },
      css: {
        ...appDSL.theme?.css,
        global: updatedGlobalCSS,
      },
    },
  };
};
