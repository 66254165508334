import { useMemo } from 'react';

import { LayoutModeTypes, NodeID } from '@builder/schemas';

import { useEditableNodeIDs } from '../layout';
import { useLayout } from 'src/providers/ReduxProvider';

import { useCollapsedNodes } from './useCollapsedNodes';

export const useDashboardNavigatorNodes = (): {
  isAllNodesExpanded: boolean;
  navigatorNodeIDs: NodeID[];
  layoutMode: LayoutModeTypes;
} => {
  const { layoutMode } = useLayout();
  const collapsedNodes = useCollapsedNodes();
  const navigatorNodeIDs = useEditableNodeIDs();
  const isAllNodesExpanded = useMemo(
    () => navigatorNodeIDs.every(nodeID => !collapsedNodes[nodeID]),
    [collapsedNodes, navigatorNodeIDs],
  );

  return {
    navigatorNodeIDs,
    isAllNodesExpanded,
    layoutMode,
  };
};
