import { isFunction, isPromise } from '@builder/utils';
import { createStateError } from '../../app-audit';
import { useRuntimeErrorCallbacks } from '../../providers';
import { createAndRunFunction } from '../../utils';
export const useAppEngineFunctionState = (appStateDSL, appState, libraries) => {
    const hookCode = appStateDSL.code;
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const funcResult = createAndRunFunction(`${hookCode}`, appState, {
        throwReactRenderError: true,
        onAppFunctionError: error => onAppAuditNotifications([
            createStateError({
                message: error.message,
                stateID: appStateDSL.id,
            }),
        ]),
        libraries,
        isNewFunction: true,
    });
    if (isFunction(funcResult) || isPromise(funcResult)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        funcResult.code = hookCode;
    }
    return funcResult;
};
