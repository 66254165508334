import { observableDiff } from 'deep-diff';
import { nanoid as uuid } from 'nanoid';
import { EventType, EventOperation } from './types';
const pathToString = (path) => {
    if (!path) {
        throw new Error('Path is required');
    }
    return path.join('.');
};
export const generateEvent = (previous, current) => {
    const payload = [];
    const reversePayload = [];
    observableDiff(previous, current, difference => {
        switch (difference.kind) {
            case 'N':
                payload.push({
                    operation: EventOperation.create,
                    scope: 'global',
                    data: {
                        key: pathToString(difference.path),
                        node: difference.rhs,
                    },
                });
                // Add corresponding delete operation to reversePayload
                reversePayload.push({
                    operation: EventOperation.delete,
                    scope: 'global',
                    data: {
                        key: pathToString(difference.path),
                    },
                });
                break;
            case 'E':
                if (difference.path &&
                    difference.path.length &&
                    Number.isInteger(difference.path.slice(-1)[0])) {
                    const arrayReplacePayload = {
                        operation: EventOperation.arrayReplace,
                        scope: 'global',
                        data: {
                            key: pathToString(difference.path.slice(0, -1)),
                            index: difference.path.slice(-1)[0],
                            item: difference.lhs,
                            newItem: difference.rhs,
                        },
                    };
                    payload.push(arrayReplacePayload);
                    // Flip lhs and rhs for reverse operation
                    reversePayload.push(Object.assign(Object.assign({}, arrayReplacePayload), { data: Object.assign(Object.assign({}, arrayReplacePayload.data), { item: difference.rhs, newItem: difference.lhs }) }));
                }
                else {
                    const updatePayload = {
                        operation: EventOperation.update,
                        scope: 'global',
                        data: {
                            key: pathToString(difference.path),
                            node: difference.rhs,
                        },
                    };
                    payload.push(updatePayload);
                    // Flip lhs and rhs for reverse operation
                    reversePayload.push(Object.assign(Object.assign({}, updatePayload), { data: Object.assign(Object.assign({}, updatePayload.data), { node: difference.lhs }) }));
                }
                break;
            case 'D':
                payload.push({
                    operation: EventOperation.delete,
                    scope: 'global',
                    data: {
                        key: pathToString(difference.path),
                    },
                });
                // Add corresponding create operation to reversePayload
                reversePayload.push({
                    operation: EventOperation.create,
                    scope: 'global',
                    data: {
                        key: pathToString(difference.path),
                        node: difference.lhs,
                    },
                });
                break;
            case 'A':
                switch (difference.item.kind) {
                    case 'N':
                        const arrayInsertPayload = {
                            operation: EventOperation.arrayInsert,
                            scope: 'global',
                            data: {
                                key: pathToString(difference.path),
                                index: difference.index,
                                item: difference.item.rhs,
                            },
                        };
                        payload.push(arrayInsertPayload);
                        // Add corresponding arrayDelete operation to reversePayload
                        reversePayload.push({
                            operation: EventOperation.arrayDelete,
                            scope: 'global',
                            data: {
                                item: difference.item.rhs,
                                key: pathToString(difference.path),
                                index: difference.index,
                            },
                        });
                        break;
                    case 'E':
                        const arrayReplacePayload2 = {
                            operation: EventOperation.arrayReplace,
                            scope: 'global',
                            data: {
                                key: pathToString(difference.path),
                                index: difference.index,
                                item: difference.item.lhs,
                                newItem: difference.item.rhs,
                            },
                        };
                        payload.push(arrayReplacePayload2);
                        // Flip lhs and rhs for reverse operation
                        reversePayload.push(Object.assign(Object.assign({}, arrayReplacePayload2), { data: Object.assign(Object.assign({}, arrayReplacePayload2.data), { item: difference.item.rhs, newItem: difference.item.lhs }) }));
                        break;
                    case 'D':
                        payload.push({
                            operation: EventOperation.arrayDelete,
                            scope: 'global',
                            data: {
                                item: difference.item.lhs,
                                key: pathToString(difference.path),
                                index: difference.index,
                            },
                        });
                        // Add corresponding arrayInsert operation to reversePayload
                        reversePayload.push({
                            operation: EventOperation.arrayInsert,
                            scope: 'global',
                            data: {
                                key: pathToString(difference.path),
                                index: difference.index,
                                item: difference.item.lhs,
                            },
                        });
                        break;
                }
                break;
        }
    });
    return {
        type: EventType.Event,
        uuid: uuid(),
        payload,
        reversePayload,
        history: {
            future: [],
            past: [],
        },
    };
};
