import { COMPONENT_DSL_PROP_TYPES } from '../../constants';
export const RESOURCE_DSL = {
    endpoint: { type: COMPONENT_DSL_PROP_TYPES.string },
    name: { type: COMPONENT_DSL_PROP_TYPES.string },
    title: { type: COMPONENT_DSL_PROP_TYPES.string },
    default: { type: COMPONENT_DSL_PROP_TYPES.object },
    id: { type: COMPONENT_DSL_PROP_TYPES.string },
    type: { type: COMPONENT_DSL_PROP_TYPES.string },
    environment: { type: COMPONENT_DSL_PROP_TYPES.string },
    api: { type: COMPONENT_DSL_PROP_TYPES.string },
    workspaceID: { type: COMPONENT_DSL_PROP_TYPES.string },
};
