import React, { useMemo, useRef } from 'react';

import styled from '@emotion/styled';

import { isArray, Z_INDEXES_BUILDER } from '@builder/utils';

import { LAYOUT } from 'src/providers/ThemeProvider';

import { BasicPreview, getPreviewTransform, PREVIEW_SIZE } from './common';
import { useBaseLayer } from './DndBaseLayer';

const DndNavigationLayerContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEXES_BUILDER.dndCustomLayer};
  // all pointers are removed: very important part!
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

/**
 * Custom dnd layer monitors whatever is being dragged at the moment
 * and if the item is over the layer -- layer is in control of the item presentation!
 *
 * Custom layer renders a draggable over it.
 */
export const DndNavigationLayer = (): JSX.Element | null => {
  const draggablePreviewRef = useRef<HTMLDivElement | null>(null);
  const previewSizeRef = useRef({ width: PREVIEW_SIZE, height: PREVIEW_SIZE });

  /**
   * Prefer using refs instead of states, since this thing tick on `mousemove` (very often).
   * You don't wont this to freeze or lag on occasional `setState`.
   */

  const { initialOffset, currentOffset, item, isDragging } = useBaseLayer({
    draggablePreviewRef,
    previewSizeRef,
    offsetCompensation: { x: -LAYOUT.LeftPanel.size, y: -LAYOUT.TopPanel.size },
  });

  const itemType = item && isArray(item) ? item.map(x => x?.type) : item?.type;

  const draggablePreview = useMemo(
    () => {
      if (!isDragging) {
        return null;
      }

      // if whatever else is being dragged
      return (
        <BasicPreview
          ref={draggablePreviewRef}
          {...getPreviewTransform({
            initialOffset,
            currentOffset,
            previewWidth: previewSizeRef.current.width,
            previewHeight: previewSizeRef.current.height,
          })}
        />
      );
    },
    // only want to render if the itemType of a dragging state changes
    // eslint-disable-next-line
    [itemType],
  );

  return <DndNavigationLayerContainer>{draggablePreview}</DndNavigationLayerContainer>;
};
