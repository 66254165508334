import convert from 'color-convert';

import { PERCENT } from '../../constants';
import { HSBA, LAB } from '../../types';
import { roundToDigits } from '../helpers';

export class Lab implements LAB {
  public l: number;

  public a: number;

  public b: number;

  constructor(lab: LAB | string) {
    if (typeof lab === 'string') {
      const [l, a, b] = lab.split(',');

      this.l = roundToDigits(Number.parseFloat(l));
      this.a = roundToDigits(Number.parseFloat(a));
      this.b = roundToDigits(Number.parseFloat(b));

      return;
    }

    this.l = roundToDigits(lab.l);
    this.a = roundToDigits(lab.a);
    this.b = roundToDigits(lab.b);
  }

  toHsb(): HSBA {
    const [h, s, b] = convert.lab.hsv([this.l, this.a, this.b]);
    // Saturation and brightness are being translated from percent to decimal from 0 to 1
    return {
      h,
      s: s / PERCENT,
      b: b / PERCENT,
      a: 1,
    };
  }

  toString(hasOnlyNumbers = false): string {
    const labValues = Object.values(this);
    const labString = labValues.join(', ');
    return hasOnlyNumbers ? labString : `lab(${labValues[0]}% ${labValues[1]} ${labValues[2]})`;
  }
}
