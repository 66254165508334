/* eslint-disable react-hooks/rules-of-hooks */
import { STATE_BOOLEAN_VARIANTS } from '@builder/schemas';
import { isBoolean, SchemaValidationError, ERROR_SCOPES } from '@builder/utils';
import { useOpenCloseModalState, useBooleanState } from '../predefined-states';
export const useAppEngineBooleanState = (appStateDSL) => {
    const defaultState = appStateDSL.defaultValue || false;
    if (!isBoolean(defaultState)) {
        // TODO: think about moving it to StateSchemaAssert helpers
        throw new SchemaValidationError(ERROR_SCOPES.appEngine, `State ${appStateDSL.name} is boolean and should have default value as boolean, but got ${JSON.stringify(defaultState)}`);
    }
    if (appStateDSL.variant === STATE_BOOLEAN_VARIANTS.openClose) {
        return useOpenCloseModalState(defaultState);
    }
    if (appStateDSL.variant === STATE_BOOLEAN_VARIANTS.trueFalse) {
        return useBooleanState(defaultState);
    }
    return null;
};
