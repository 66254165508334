import { themeSelectors, } from '@builder/schemas';
import { FontFaceCSSGenerator } from '../fontface-css-generator';
import { CSS_FILE_NAMES } from './constants';
export class GlobalCSSListImportsGenerator {
    constructor(appDSL, assetBackendList) {
        var _a, _b;
        this.themeDSL = (_a = appDSL.theme) !== null && _a !== void 0 ? _a : {};
        this.assetListDSL = (_b = appDSL.assets) !== null && _b !== void 0 ? _b : {};
        this.assetBackendList = assetBackendList;
    }
    generateGlobalCSSImport() {
        const globalCSS = themeSelectors.getGlobalCSS(this.themeDSL);
        return globalCSS
            ? {
                importName: CSS_FILE_NAMES.global,
            }
            : null;
    }
    generateComponentCSSImport() {
        const componentCSS = themeSelectors.getComponentsCSS(this.themeDSL);
        return componentCSS
            ? {
                importName: CSS_FILE_NAMES.component,
            }
            : null;
    }
    generateFontFaceCSSImport() {
        const fontFaceCssGenerator = new FontFaceCSSGenerator({
            themeDSL: this.themeDSL,
            assetListDSL: this.assetListDSL,
            assetBackendList: this.assetBackendList,
            useAssetDirectImports: true,
        });
        const fontFaceCSS = fontFaceCssGenerator.generateFontFaceCSS();
        return fontFaceCSS
            ? {
                importName: CSS_FILE_NAMES.fontFace,
            }
            : null;
    }
    generateTypographyCSSImport() {
        const typographyCSS = themeSelectors.getTypographyCSS(this.themeDSL);
        return typographyCSS
            ? {
                importName: CSS_FILE_NAMES.typography,
            }
            : null;
    }
    generateCustomCSSImport() {
        const customCSS = themeSelectors.getCustomCSS(this.themeDSL);
        return customCSS
            ? {
                importName: CSS_FILE_NAMES.custom,
            }
            : null;
    }
    generateMediaQueryCSSImport() {
        const mediaQueryCSS = themeSelectors.getMediaQueryCSS(this.themeDSL);
        return mediaQueryCSS
            ? {
                importName: CSS_FILE_NAMES.mediaQuery,
            }
            : null;
    }
    getImportDataList() {
        return [
            this.generateGlobalCSSImport(),
            this.generateComponentCSSImport(),
            this.generateFontFaceCSSImport(),
            this.generateTypographyCSSImport(),
            this.generateCustomCSSImport(),
            this.generateMediaQueryCSSImport(),
        ].filter(Boolean);
    }
}
