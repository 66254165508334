import React, { useCallback } from 'react';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { DialogContentWithIcon } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';

const DIALOG_ID = DASHBOARD_DIALOGS.CONFIRM_DELETE_DIALOG_ID;

export type ConfirmDeleteDialogArgs = {
  message: string;
  sendAction: () => void;
};

export const ConfirmDeleteDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<ConfirmDeleteDialogArgs>(DIALOG_ID);

  const closeConfirmDeleteDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  const closeAndSendAction = () => {
    closeConfirmDeleteDialog();
    args.sendAction();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeConfirmDeleteDialog}
      data-test="resourcesManager.preventDeleteDialog"
    >
      <DialogTitle>Confirm deletion</DialogTitle>
      <DialogContentWithIcon>
        <InfoOutlinedIcon color="primary" />
        <DialogContentText>
          <Typography variant="body1">{`${args.message}`}</Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="success" onClick={closeAndSendAction}>
          Confirm
        </Button>
        <Button onClick={closeConfirmDeleteDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
