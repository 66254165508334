import React, { useLayoutEffect, useState } from 'react';

import styled from '@emotion/styled';

import { AppRuntimeStates } from '@builder/app-engine';
import { hasPropJsCode } from '@builder/schemas';
import { isString } from '@builder/utils';

import { useJsInjectionOptions } from '../common/JsCodeInjectionInput/useJsInjectionOptions';
import { Autocomplete } from 'src/shared/components';
import { LabelPlacementType } from 'src/shared/components/common/InputContainer';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

const HTMLInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

type HTMLInputProps<T extends unknown | string> = {
  label: string;
  labelPlacement?: LabelPlacementType;
  propValue?: T;
  onChangePropValue: (propValue: T) => void;
  disableFx?: () => void;
  children: (arg: {
    isFxEnabled: string;
    enableFx?: () => void;
    nonJsCodePropValue?: T;
  }) => React.ReactNode;
  nodeID?: string;
  showFx?: boolean;
  typeField?: string;
  'data-test'?: string;
  fxDefaultEnabled?: string;
  localStates?: AppRuntimeStates | undefined;
};

export const HTMLInput = <T extends unknown | string>({
  label,
  labelPlacement,
  propValue,
  onChangePropValue,
  children,
  nodeID,
  showFx = true,
  disableFx: disableFxAction,
  typeField = 'string',
  fxDefaultEnabled = ACTIVEPROP.literal,
  'data-test': dataTest,
  localStates,
}: HTMLInputProps<T>): JSX.Element => {
  const [mode, setMode] = useState(fxDefaultEnabled);

  const {
    enableFx,
    getAutocompleteLabel,
    getNoOptionText,
    pagesOptions,
    statesOptions,
    isPageProp,
    statesNames,
  } = useJsInjectionOptions({
    ACTIVEPROP,
    setMode,
    disableFxAction,
    onChangePropValue,
    showFx,
    nodeID,
    label,
    typeField,
  });
  const isPageOption = pagesOptions
    .map((page: { value: string }) => page.value)
    .includes(propValue as string);

  useLayoutEffect(() => {
    // Show input custom-entries if property already has custom-entries-js injection.
    if (isString(propValue) && statesNames.includes(propValue as string)) {
      return setMode(ACTIVEPROP.state);
    }

    if (isPageProp && isPageOption) {
      return setMode(ACTIVEPROP.page);
    }

    // Show input f(x) if property already has js injection.
    if (hasPropJsCode(propValue) && fxDefaultEnabled) {
      setMode(ACTIVEPROP.code);
    }
    // Run it only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HTMLInputWrapper>
      <Choose>
        <When condition={showFx && mode === ACTIVEPROP.page && isPageProp}>
          <Autocomplete
            fullWidth
            required
            value={propValue as string}
            label={getAutocompleteLabel(label, 'App Pages')}
            onChange={value => onChangePropValue(value as T)}
            options={pagesOptions}
            isFxEnabled={mode}
            enableFx={() => enableFx(ACTIVEPROP.page)}
            data-test={`${dataTest}.page`}
            showFx={showFx}
            noOptionsText={getNoOptionText('pages')}
          />
        </When>
        <When condition={showFx && mode === ACTIVEPROP.state}>
          <Autocomplete
            fullWidth
            required
            value={propValue as string}
            label={getAutocompleteLabel(label, 'State')}
            onChange={value => onChangePropValue(value as T)}
            options={statesOptions}
            isFxEnabled={mode}
            enableFx={() => enableFx(ACTIVEPROP.state)}
            data-test={`${dataTest}.jsCodeTextField`}
            showFx={showFx}
            noOptionsText={getNoOptionText('entries')}
          />
        </When>
        <Otherwise>
          {children({
            isFxEnabled: mode,
            enableFx: () => enableFx(ACTIVEPROP.code),
            nonJsCodePropValue: hasPropJsCode(propValue) ? undefined : propValue,
          })}
        </Otherwise>
      </Choose>
    </HTMLInputWrapper>
  );
};
