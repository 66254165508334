var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, getCurrentDate, RESET_STATUS } from '../common';
import { DatePicker } from '../DatePicker';
export const FormDatePicker = forwardRef((_a, ref) => {
    var { fieldProps, datePickerProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, className, translate, onAccept = () => { } } = _a, rest = __rest(_a, ["fieldProps", "datePickerProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "className", "translate", "onAccept"]);
    const { setFieldValue, initialValues, status, setStatus, values } = useFormikContext();
    const initialValue = initialValues[fieldProps.name] || getCurrentDate();
    useEffect(() => {
        var _a;
        setFieldValue(fieldProps.name, initialValue);
        (_a = datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(datePickerProps, initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldProps.name, initialValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            (_a = datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(datePickerProps, initialValue);
        }
    }, [datePickerProps, status, setStatus, initialValue]);
    useEffect(() => {
        if ((datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.value) != null &&
            (datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.onChange) != null &&
            (datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.value) !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.value);
        }
        else if (datePickerProps == null && values[fieldProps.name] !== '') {
            setFieldValue(fieldProps.name, new Date().toLocaleDateString());
        }
    }, [
        datePickerProps,
        datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.onChange,
        datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.value,
        fieldProps.name,
        setFieldValue,
        values,
    ]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, form, meta }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(DatePicker, Object.assign({}, rest, { id: id, title: title, lang: lang, className: className, translate: translate, ref: ref }, datePickerProps, { value: field.value || (datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.value), onChange: (date, value) => {
                    var _a;
                    form.setFieldValue(fieldProps.name, value);
                    (_a = datePickerProps === null || datePickerProps === void 0 ? void 0 : datePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(datePickerProps, value);
                    field.onChange(value);
                    setFieldValue(fieldProps.name, value);
                }, initialFocusedDate: initialValue, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, error: isInvalid, helperText: errorText })));
        } })));
});
