var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { forwardRef, useCallback, useRef, useState, useEffect, } from 'react';
import debounce from 'lodash.debounce';
import { isNil } from 'ramda';
export const FileUpload = forwardRef((_a) => {
    var { debounce: debounceTime, onChange, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, InputProps, title, lang, translate, accept, className, onError, maxFileSize, inputProps } = _a, rest = __rest(_a, ["debounce", "onChange", 'data-test', 'data-node-id', 'data-node-render-path', "id", "InputProps", "title", "lang", "translate", "accept", "className", "onError", "maxFileSize", "inputProps"]);
    const [localValue, setLocalValue] = useState();
    const fileArrayEventGenerator = (files) => {
        const filesArray = Array.from(files);
        const filesEvent = filesArray.map(file => {
            return {
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: file.lastModified,
                webkitRelativePath: file.webkitRelativePath,
            };
        });
        const newEvent = { target: { files: filesEvent } };
        return newEvent;
    };
    const createDebounceCallback = useCallback((debounceTimeArg) => {
        return debounce((event, nextValue) => {
            var _a;
            const theElement = event;
            theElement.target.files = nextValue;
            const filesEvent = fileArrayEventGenerator(theElement.target.files);
            onChange === null || onChange === void 0 ? void 0 : onChange(filesEvent);
            (_a = InputProps === null || InputProps === void 0 ? void 0 : InputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(InputProps, event, filesEvent);
        }, debounceTimeArg);
    }, [onChange, InputProps]);
    const debouncedChangePropValue = useRef(createDebounceCallback(debounceTime));
    useEffect(() => {
        debouncedChangePropValue.current = createDebounceCallback(debounceTime);
    }, [createDebounceCallback, debounceTime]);
    useEffect(() => {
        // eslint-disable-next-line
        const transformedValue = rest.value;
        const filteredItems = fileListItems(transformedValue);
        setLocalValue(filteredItems);
    }, [rest.value]);
    const isFileExceedingFileSize = useCallback((files) => {
        if (!maxFileSize)
            return false;
        const isExceedingSize = [...files].some(file => file.size > maxFileSize * 1024 * 1024);
        // eslint-disable-next-line no-alert
        isExceedingSize && alert('File exceeded max file size');
        return isExceedingSize;
    }, [maxFileSize]);
    const setDebouncedValue = useCallback(event => {
        var _a, _b;
        const filesEvent = fileArrayEventGenerator(event.target.files);
        if (!isFileExceedingFileSize(event.target.files)) {
            setLocalValue(event.target.files);
            if (isNil(debounceTime)) {
                if (onChange) {
                    onChange === null || onChange === void 0 ? void 0 : onChange(filesEvent);
                }
                else {
                    (_a = InputProps === null || InputProps === void 0 ? void 0 : InputProps.onChange) === null || _a === void 0 ? void 0 : _a.call(InputProps, event, filesEvent);
                }
            }
            else {
                debouncedChangePropValue.current(event, event.target.files);
            }
        }
        else if (localValue && inputRef && inputRef.current) {
            inputRef.current.files = localValue;
        }
        else {
            ((_b = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _b === void 0 ? void 0 : _b.value) && (inputRef.current.value = '');
        }
    }, [isFileExceedingFileSize, localValue, debounceTime, onChange, InputProps]);
    const ListFiles = () => {
        if (localValue && localValue.length) {
            const items = [...localValue];
            return (_jsx("ul", { children: items.map(file => (_jsxs("li", { children: [_jsx("span", { children: file.name }), _jsx("button", Object.assign({ type: "button", onClick: e => {
                                const filteredItems = fileListItems(items.filter(item => item.name !== file.name));
                                setLocalValue(filteredItems);
                                debouncedChangePropValue.current(e, filteredItems);
                            } }, { children: "Remove" }))] }, file.name))) }));
        }
        return _jsx(_Fragment, {});
    };
    const inputRef = useRef(null);
    function fileListItems(files) {
        const b = new ClipboardEvent('').clipboardData || new DataTransfer();
        for (let i = 0, len = files === null || files === void 0 ? void 0 : files.length; i < len; i++)
            b.items.add(files[i]);
        return b.files;
    }
    useEffect(() => {
        if (localValue && inputRef && inputRef.current && !isFileExceedingFileSize(localValue)) {
            inputRef.current.files = localValue;
        }
    }, [isFileExceedingFileSize, localValue]);
    return (_jsxs(_Fragment, { children: [_jsx("input", { ref: inputRef, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, type: "file", accept: accept, "max-file-size": maxFileSize, multiple: true, title: title, lang: lang, translate: translate, onChange: (event) => {
                    setDebouncedValue(event);
                }, className: className, required: rest.required, style: rest.style }), _jsx(ListFiles, {})] }));
});
