/* eslint-disable no-param-reassign */
import { isEmpty, values } from 'ramda';
import { stateListSelectors, } from '@builder/schemas';
import { HookGenerator, sortStatesByUse } from '../app-states-generator';
import { GLOBAL_STATE_PROVIDER_CONST, USE_GLOBAL_STATE_CONST } from './constants';
export class GlobalStateProviderGenerator {
    constructor({ appDSL, componentListDSL, assetListDSL, }) {
        this.getHookName = (appState) => {
            const hookGenerator = new HookGenerator({
                appDSL: this.appDSL,
                componentListDSL: this.componentListDSL,
                appStateDSL: appState,
                assetListDSL: this.assetListDSL,
            });
            return hookGenerator.stateAccessor.getStateName();
        };
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
        this.assetListDSL = assetListDSL;
        this.globalStatesArraySorted = sortStatesByUse(stateListSelectors.getGlobalStateArrayDSL(this.appDSL.states));
    }
    /**
     * Generates the definition for all the global states used in the app.
     *
     * @returns The string representing the states definition.
     *
     * @example
     * const booleanState = useBoolean();
     * const arrayState = useArrayState();
     * const queryGraphql = useQueryGraphql(booleanState, arrayState)
     */
    /**
     * Generates the usage definition for all the global states used in some node passed.
     *
     * @param nodeListDSL The list of nodes we want to check if some state is used.
     * @returns The string representing how to use each global state used in some node passed.
     *
     * @example
     * const { booleanState, arrayState, queryGraphql } = useGlobalState();
     */
    generateGlobalStateHook(nodeListDSL) {
        const thereIsNotNodes = values(nodeListDSL).length === 0;
        if (thereIsNotNodes) {
            return '';
        }
        const getImportDataList = this.globalStatesArraySorted.map(this.getHookName);
        if (isEmpty(getImportDataList)) {
            return '';
        }
        return `const { ${getImportDataList.join(',')} } = ${USE_GLOBAL_STATE_CONST}();`;
    }
    generateGlobalStateProvider() {
        return `
      import React, { createContext, useContext } from 'react';
  
      export type GlobalState = Record<string, any>;
      const GlobalStateContext = createContext<GlobalState>({});
  
      export const ${GLOBAL_STATE_PROVIDER_CONST}: React.FC = (props) => {

        return (
          <GlobalStateContext.Provider value={props.states}>
            { props.children }
          </GlobalStateContext.Provider>
        );
      }
  
      export const ${USE_GLOBAL_STATE_CONST} = (): GlobalState => {
        return useContext(GlobalStateContext);
      }
    `;
    }
}
