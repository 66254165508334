import React from 'react';

import { ThemeProvider as EmotionThemeProvider, Global } from '@emotion/react';
import { useTheme as useCoreTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  PaletteOptions as MuiPaletteOptions,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

import { uploadcareStyles } from './UploadcareFilePicker.styles';

declare module '@mui/material/Button/Button' {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}
declare module '@mui/material/styles/createPalette' {
  interface BorderColors {
    input: string;
  }

  interface Palette {
    border: BorderColors;
  }

  interface PaletteOptions {
    border?: Partial<BorderColors>;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    contrastText: string;
  }

  interface CommonColors {
    white: string;
    black: string;
    pdf: string;
    file: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    input: string;
    radioButtonActive: string;
    radioButtonDisabled: string;
    codeCaret: string;
  }
}

export const COLORS = {
  // grays
  white: '#FFFFFF',
  gray100: '#F4F7F9',
  gray200: '#E8EFF5',
  gray300: '#CFD7DE',
  gray400: '#A8B1BA',
  gray500: '#7D828C',
  gray600: '#5B636C',
  gray700: '#475059',
  gray800: '#323C47',
  gray900: '#242C34',
  black: '#000000',
  // blues
  blue100: '#61a5fa',
  blue200: '#238BFF',
  blue300: '#0973F8',
  blue400: '#687695',
  blue500: '#4D5A78',
  // greens
  green100: '#A6E50F',
  green200: '#62C59C',
  green300: '#3DC38C',
  green400: '#00BB6E',
  green500: '#07C27D',
  // orange:
  orange100: '#EF923A',
  // coffee:
  coffee100: '#9A884D',
  // reds
  red100: '#F17B71',
  red200: '#FF6D4A',
  red250: '#DB5252',
  red300: '#FE4B3D',
  red400: '#FF2A25',
  // purples
  purple100: '#9975D0',
  purple200: '#4D329A',
  purple300: '#32235E',
  purple400: '#261B48',
  // mangentas
  magenta100: '#F900AF',
  // yellows
  yellow100: '#FCDC51',
  yellow200: '#FBD324',
  yellow300: '#FFCF00',
} as const;

export const LAYOUT = {
  TopPanel: {
    size: 56,
    RouterMenu: {
      width: 290,
    },
    DomainsManager: {
      width: 290,
    },
    DeployManager: {
      width: 350,
    },
  },
  RightPanel: {
    size: 320,
    padding: 12,
  },
  LeftPanel: {
    size: 60,
    floatingContainerSize: 320,
    navigatorSize: 320,
    functionsManager: 1000,
    nodes: 320,
    hooks: 320,
    pages: 320,
    symbols: 320,
    settings: 'auto',
    navigator: 'auto',
    requests: 'auto',
    functions: 'auto',
    resources: 'auto',
    theme: 'auto',
    assets: 320,
    none: 320,
    iconSize: 32,
    RequestsManager: {
      size: 960,
      list: 320,
      editor: 640,
      previewSize: 640,
    },
    ResourcesManager: {
      list: 360,
    },
    ThemeManager: {
      list: 376,
    },
  },
  Dashboard: {
    margin: 12,
    OverlayControls: {
      height: 20,
      iconWidth: 12,
      iconHeight: 12,
      offset: 4,
    },
  },
  effects: {
    popupShadow: `0px 2px 40px rgba(50, 60, 71, 0.1)`,
  },
  controls: {
    lineHeight: 16,
    height: 32,
    tooltip: {
      maxWidth: '200px',
    },
    colorPickerAdornment: {
      width: 32,
      height: 17,
    },
    input: {
      borderColor: COLORS.gray700,
    },
  },
} as const;

export const PALETTE = {
  mode: 'dark',
  primary: {
    light: COLORS.blue100,
    main: COLORS.blue300,
    dark: COLORS.blue500,
    contrastText: COLORS.white,
  },
  secondary: {
    main: COLORS.red400,
    contrastText: COLORS.white,
  },
  error: {
    light: COLORS.red200,
    main: COLORS.red400,
    dark: COLORS.red400,
    contrastText: COLORS.white,
  },
  warning: {
    light: COLORS.yellow100,
    main: COLORS.yellow300,
    contrastText: COLORS.gray600,
    dark: COLORS.orange100,
  },
  success: {
    light: COLORS.green100,
    main: COLORS.green400,
    dark: COLORS.green500,
  },
  text: {
    primary: COLORS.white,
    secondary: COLORS.gray600,
    contrastText: COLORS.gray900,
  },

  border: {
    input: COLORS.gray600,
  },
  grey: {
    100: COLORS.gray100,
    200: COLORS.gray200,
    300: COLORS.gray300,
    400: COLORS.gray400,
    500: COLORS.gray500,
    600: COLORS.gray600,
    800: COLORS.gray800,
    700: COLORS.gray700,
    900: COLORS.gray900,
  },
  common: {
    white: COLORS.white,
    black: COLORS.gray900,
    pdf: COLORS.red250,
    file: COLORS.coffee100,
  },
  background: {
    default: COLORS.gray800,
    paper: COLORS.gray900,
    input: COLORS.gray700,
    radioButtonActive: COLORS.gray600,
    radioButtonDisabled: COLORS.gray900,
    codeCaret: COLORS.white,
  },
};

const theme = {
  ...createTheme({
    palette: PALETTE as MuiPaletteOptions,
    typography: {
      fontSize: 12,
      fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
      button: {
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '16px',
      },
      body1: {
        fontSize: 12,
        lineHeight: '16px',
      },
      body3: {
        fontSize: 13,
      },
      h4: {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '20px',
        letterSpacing: '0',
      },
      h6: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0',
      },
      subtitle2: {
        textTransform: 'capitalize',
        fontWeight: 600,
      },
      caption: {
        fontSize: 10,
        lineHeight: 11,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body3: 'p',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          // Looks like a bug in Material UI casuse it wants prop "ZIndex"
          // insted of "zIndex" But if I pass here "ZIndex" it will transform
          // into "-z-index" and doesn't work
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          root: {
            zIndex: '1300',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: PALETTE.background.input,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiSvgIcon-root': {
              width: '24px',
              height: '24px',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: '20px 12px !important',
            minWidth: '400px',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontSize: 14,
            color: PALETTE.text.primary,
            lineHeight: '20px',
            letterSpacing: '0.5px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '12px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: PALETTE.background.default,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            padding: '8px 12px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: COLORS.gray600,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            lineHeight: '16px',
            marginBottom: '4px',
            color: COLORS.gray400,
            transform: 'none',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingLeft: 0,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-root': {
              paddingLeft: '0px !important',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            boxShadow: 'none',

            '&:hover': {
              backgroundColor: COLORS.blue200,
              boxShadow: '0px 2px 4px rgba(50, 50, 93, 0.2)',
            },

            '&:active': {
              backgroundColor: COLORS.blue100,
              boxShadow: 'inset 0px 1px 6px rgba(0, 0, 0, 0.15)',
            },
          },
          containedSecondary: {
            boxShadow: 'none',

            '&:hover': {
              backgroundColor: COLORS.red300,
              boxShadow: '0px 2px 4px rgba(50, 50, 93, 0.2)',
            },

            '&:active': {
              backgroundColor: COLORS.red200,
              boxShadow:
                'inset 0px 1px 3px rgba(50, 50, 93, 0.14), inset 0px 4px 6px rgba(51, 80, 107, 0.08);',
            },
          },
          // material-ui doesn't have all override styles in the typings
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          containedSuccess: {
            color: PALETTE.text.primary,
          },
          outlined: {
            border: '1px solid',
            borderColor: COLORS.gray300,
            backgroundColor: COLORS.white,

            ':disabled': {
              color: '#ffffff4d',
              boxShadow: 'none',
              backgroundColor: '#ffffff1f',
              border: '1px solid transparent',
            },

            '&:hover': {
              backgroundColor: COLORS.white,
              boxShadow: '0px 2px 4px rgba(50, 50, 93, 0.2)',
            },

            '&:active': {
              backgroundColor: COLORS.white,
              boxShadow:
                'inset 0px 1px 3px rgba(50, 50, 93, 0.14), inset 0px 4px 6px rgba(51, 80, 107, 0.08)',
            },
          },

          outlinedPrimary: {
            backgroundColor: '#dceffe',
            borderColor: '#dceffe',
          },

          outlinedError: {
            borderColor: '#ffdddd',
            backgroundColor: '#ffdddd',
          },

          textPrimary: { padding: 10, color: COLORS.white },
          root: {
            borderRadius: 5,
            textTransform: 'none',
            height: 32,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 10,
            paddingBottom: 10,
            fontWeight: 600,
            fontSize: 12,
            letterSpacing: 0,
            lineHeight: '16px',
            '&.Mui-disabled': {
              '* svg': {
                opacity: '0.3',
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            fontSize: '16px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: COLORS.gray600,
            },
            '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.blue300,
            },
            backgroundColor: COLORS.gray700,
            fontSize: 12,
            lineHeight: '16px',
            padding: 8,
            paddingRight: 6,
          },
          notchedOutline: {
            borderColor: COLORS.gray300,
          },
          multiline: {
            // with fontsize `theme.layout.controls.fontSize`
            // this artificially confirms to design height 32px for inputs
            '&.MuiOutlinedInput-marginDense': {
              paddingTop: 8,
              paddingBottom: 8,
            },
          },
        },
      },
      MuiFormControlLabel: {
        // MuiFormControlLabel and MuiCheckbox are hacks to make correct overlay fot this items.
        // Think about more correct solution (e.g. define overlay settings in the component DSL).
        styleOverrides: {
          root: { marginLeft: 0, marginRight: 0 },
          labelPlacementStart: { marginLeft: 0, marginRight: 0 },
          label: { marginLeft: 11, marginRight: 11 },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          switchBase: {
            color: COLORS.white,
            top: 4,
            left: 5,
            padding: 4,
            '&.Mui-checked': {
              left: -3,
            },
            '&.Mui-checked + .MuiSwitch-track': {
              opacity: 1,
            },
          },
          root: {
            width: 42,
            height: 28,
            padding: '4px 5px',
            '& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: COLORS.gray600,
              opacity: 1,
            },
            '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: COLORS.gray600,
              opacity: 1,
            },
            '&:hover': {
              '& .MuiButtonBase-root': {
                backgroundColor: 'initial',
              },
              '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
                backgroundColor: COLORS.blue100,
              },
              '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
                backgroundColor: COLORS.red100,
              },
              '& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: COLORS.gray600,
                opacity: 1,
              },
              '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: COLORS.gray600,
                opacity: 1,
              },
            },
          },
          thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
          },
          track: {
            opacity: 1,
            backgroundColor: COLORS.gray300,
            borderRadius: 12,
          },
          colorPrimary: {
            '&.Mui-disabled': {
              color: COLORS.white,
            },
            '&.Mui-checked': {
              backgroundColor: 'initial !important',
              color: COLORS.white,
              '&:hover': {
                backgroundColor: 'initial',
              },
            },
            '&.Mui-checked.Mui-disabled': {
              color: COLORS.white,
            },
          },
          colorSecondary: {
            '&.Mui-disabled': {
              color: COLORS.white,
            },
            '&.Mui-checked': {
              backgroundColor: 'initial !important',
              color: COLORS.white,
              '&:hover': {
                backgroundColor: 'initial',
              },
            },
            '&.Mui-checked.Mui-disabled': {
              color: COLORS.white,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            marginLeft: -11,
            marginRight: -11,
            color: COLORS.gray300,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            paddingRight: 12,
            minWidth: 32,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 12,
            fontWeight: 600,
            textTransform: 'none',
            minWidth: 'auto',
            padding: '0 16px 2px 16px',
            minHeight: 39,
            maxHeight: 39,
          },
          textColorPrimary: {
            color: COLORS.white,
            opacity: 0.3,
            '&.Mui-selected': {
              opacity: 1,
              color: COLORS.white,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${COLORS.gray600}`,
            minHeight: 39,
            maxHeight: 39,
          },
          indicator: {
            height: 1,
            backgroundColor: COLORS.white,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: COLORS.gray200,
              color: COLORS.black,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&.Mui-expanded': {
              margin: 0,
            },
            '&:before': {
              display: 'none',
            },
            backgroundColor: COLORS.gray800,
            backgroundImage: 'none',
          },
          rounded: {
            borderRadius: 0,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingTop: '8px',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: 'auto',
            '&.Mui-expanded': {
              minHeight: 'auto',
            },
            padding: 0,
          },
          content: {
            margin: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        },
      },
    },
  }),
  layout: LAYOUT,
};

export type CustomTheme = typeof theme;

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends CustomTheme {}
}

export const useTheme = (): CustomTheme => {
  const customTheme = useCoreTheme<CustomTheme>();
  return customTheme;
};

export const ThemeProvider: React.FC = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Global styles={uploadcareStyles} />
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </MuiThemeProvider>
  );
};
