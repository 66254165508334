import { isEmpty } from 'ramda';
import { isArray, isObject, isString, isUndefined } from '@builder/utils';
import { createNodeError } from '../../../app-audit';
/**
 * It is necessary to do a re sort process, because there are some cases that can break the logic
 * @example
 * A state is used the most times, and that state calls one of the other states once.
 * It is a problem because if It's the one that is used the most times, there are no more states above it,
 * and the state that is trying to call will appear as undefined.
 *
 * For this reason it is necessary to carry out a reordering process
 */
const isCurrentStateName = (name, state) => {
    return name !== state;
};
export const reorderStates = (states, onAppAuditNotifications) => {
    const sortedStates = [];
    const statesChecked = new Set();
    const path = [];
    const findAndAddState = (state) => {
        // If the state has been checked, skip the process
        if (statesChecked.has(state)) {
            return;
        }
        const lookForStates = (value) => {
            states.forEach(stateToEvaluate => {
                if (isCurrentStateName(stateToEvaluate.name, state) &&
                    value.includes(stateToEvaluate.name)) {
                    // If a state is found, add it before the current state
                    findAndAddState(stateToEvaluate.name);
                }
            });
        };
        // Get current state
        const currentState = states.find(stateToEvaluate => stateToEvaluate.name === state);
        // Detect circular dependency
        if (currentState && onAppAuditNotifications && path.includes(state)) {
            const circularPath = [...path, state].join(' -> ');
            onAppAuditNotifications([
                createNodeError({
                    nodeID: currentState.id,
                    propPath: ['children'],
                    message: `Circular dependency detected: ${circularPath}`,
                }),
            ]);
            return;
        }
        // If there is not state, skip the rest of the process
        if (isUndefined(currentState) || isEmpty(currentState)) {
            return;
        }
        // Get current states keys
        const currentStateKeys = Object.keys(currentState);
        path.push(state);
        // Review if some state has been used in a key of the current state
        currentStateKeys.forEach(currentStateKey => {
            if (currentStateKey !== 'name') {
                const currentStateValue = currentState[currentStateKey];
                if (isString(currentStateValue)) {
                    // Looking for other states in the key value (If It's a string)
                    // If a state is found, add it before the current state
                    lookForStates(currentStateValue);
                }
                if (isArray(currentStateValue)) {
                    // Looking for other states in the key value (If I's an array)
                    // If a state is found, add it before the current state
                    currentStateValue.forEach(currentStateValueItem => {
                        if (isString(currentStateValueItem)) {
                            lookForStates(currentStateValueItem);
                        }
                    });
                }
                if (isObject(currentStateValue)) {
                    // Looking for other states in the key value (If I's an object)
                    // If a state is found, add it before the current state
                    Object.values(currentStateValue).forEach(currentStateValueItem => {
                        if (isString(currentStateValueItem)) {
                            lookForStates(currentStateValueItem);
                        }
                    });
                }
            }
        });
        // Add the current state to the sorted array, and mark It as checked
        sortedStates.unshift(currentState);
        statesChecked.add(state);
        path.pop();
    };
    try {
        // Loop through each state and add it to the sorted array
        states.forEach(stateToEvaluate => findAndAddState(stateToEvaluate.name));
    }
    catch (error) {
        console.error('Possible circular dependency detected', error);
    }
    return sortedStates.reverse();
};
