import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, getBasicPropsSettings, getCommonPropertiesSections, getStylesSection, } from '../../common';
import { getDivider } from '../../common/settings';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_PROPS_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['open'],
                label: 'Open',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['anchor'],
                label: 'Anchor',
                options: ['bottom', 'left', 'right', 'top'].map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.number,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['elevation'],
                label: 'Elevation',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.number,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['transitionDuration'],
                label: 'Transition Duration',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['variant'],
                label: 'Variant',
                options: ['permanent', 'persistent', 'temporary'].map(createSelectOptionByValue),
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                type: COMPONENT_SETTING_TYPES.prop,
                hasFxButton: true,
                keyPath: ['onClose'],
                label: 'On Close',
            },
            {
                type: COMPONENT_SETTING_TYPES.popupsSection,
                name: 'ModalProps',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.popup,
                        name: 'ModalProps',
                        label: 'Modal Props',
                        title: 'Modal Props',
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'hideBackdrop'],
                                label: 'Hide Backdrop',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'closeAfterTransition'],
                                label: 'Close After Transition',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'disableAutoFocus'],
                                label: 'Disable Auto Focus',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'disableEnforceFocus'],
                                label: 'Disable Enforce Focus',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'disableEscapeKeyDown'],
                                label: 'Disable Escape Key Down',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'disablePortal'],
                                label: 'Disable Portal',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'disableRestoreFocus'],
                                label: 'Disable Restore Focus',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'disableScrollLock'],
                                label: 'Disable Scroll Lock',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['ModalProps', 'keepMounted'],
                                label: 'Keep Mounted',
                            },
                        ],
                    },
                ],
            },
            {
                type: COMPONENT_SETTING_TYPES.popupsSection,
                name: 'PaperProps',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.popup,
                        name: 'PaperProps',
                        label: 'Paper Props',
                        title: 'Paper Props',
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.number,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['PaperProps', 'elevation'],
                                label: 'Elevation',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['PaperProps', 'square'],
                                label: 'Square',
                            },
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['PaperProps', 'variant'],
                                label: 'Variant',
                                options: ['elevation', 'outlined'].map(createSelectOptionByValue),
                            },
                        ],
                    },
                ],
            },
            {
                type: COMPONENT_SETTING_TYPES.popupsSection,
                name: 'SlideProps',
                children: [
                    {
                        type: COMPONENT_SETTING_TYPES.popup,
                        name: 'SlideProps',
                        label: 'Slide Props',
                        title: 'Slide Props',
                        children: [
                            {
                                componentView: COMPONENT_SETTING_VIEWS.select,
                                type: COMPONENT_SETTING_TYPES.prop,
                                keyPath: ['SlideProps', 'direction'],
                                label: 'Direction',
                                options: ['down', 'left', 'right', 'up'].map(createSelectOptionByValue),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    getDivider(),
    ...getBasicPropsSettings(),
    getDivider(),
    ...getCommonPropertiesSections(),
];
export const MaterialDrawerSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getPropertiesSettings(),
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStylesSection(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
};
