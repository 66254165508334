import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper, PaperProps } from '@mui/material';

import { ValueOf } from '@builder/utils';

import DeployBG from 'src/assets/images/DeployBG2@2x.png';
import { COLORS } from 'src/providers/ThemeProvider';

export const BACKGROUND_ICON_COLORS = {
  green: 'green',
  red: 'red',
} as const;

type BackgroundIconColors = ValueOf<typeof BACKGROUND_ICON_COLORS>;

type DynamicBackgroundStyleProps = {
  backgroundIconColor?: BackgroundIconColors;
};

type BackgroundWrapperPaperProps = {
  backgroundIconColor?: BackgroundIconColors;
} & PaperProps;

const getIcon = (color: BackgroundIconColors) => {
  switch (color) {
    case BACKGROUND_ICON_COLORS.green:
      return DeployBG;
    case BACKGROUND_ICON_COLORS.red:
      return DeployBG;
  }
};

const dynamicBackgroundStyle = ({ backgroundIconColor }: DynamicBackgroundStyleProps) => css`
  ${backgroundIconColor
    ? css`
        background: url(${getIcon(backgroundIconColor)});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      `
    : css`
        background: ${COLORS.black};
      `}
`;

const StyledPaper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${dynamicBackgroundStyle};
`;

export const BackgroundWrapperPaper: React.FC<BackgroundWrapperPaperProps> = ({
  backgroundIconColor,
  children,
  ...props
}) => {
  return (
    <>
      <StyledPaper backgroundIconColor={backgroundIconColor} {...props}>
        {children}
      </StyledPaper>
    </>
  );
};
