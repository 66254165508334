import React from 'react';

import styled from '@emotion/styled';

import { Icon, IconNameType } from 'src/shared/components';

const StyledTextIcon = styled.span`
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
`;

const IconLabelWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
  position: relative;
`;

export type InputLabelIconProps = {
  icon: string;
  isTextIcon?: boolean;
};

export const InputLabelIcon: React.FC<InputLabelIconProps> = ({ icon, isTextIcon }) => {
  return (
    <Choose>
      <When condition={isTextIcon}>
        <StyledTextIcon>{icon}</StyledTextIcon>
      </When>
      <Otherwise>
        <IconLabelWrapper>
          <Icon name={icon as IconNameType} />
        </IconLabelWrapper>
      </Otherwise>
    </Choose>
  );
};
