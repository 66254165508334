import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsFormFieldArraySettings } from './BuilderComponentsFormFieldArray.settings';
export const BuilderComponentsFormFieldArray = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormFieldArray,
    displayName: 'Form Field Array',
    icon: 'formFieldArray',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormFieldArray',
    },
    schema: {
        dndTargetPropName: 'children',
        mainPropPath: {
            mainProp: 'Name',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.array,
                    name: 'FormFieldArray',
                    defaultValue: [],
                },
            ],
            props: {
                name: '__FORM__.FormFieldArray',
                value: '{{ __STATES__.FormFieldArray.value }}',
                onClick: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FormFieldArray.insertItemInArray(value)',
                    },
                },
                style: getPredefinedStyles({ display: 'grid', minWidth: 36, minHeight: 36 }),
            },
        },
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { name: { type: COMPONENT_DSL_PROP_TYPES.string }, id: { type: COMPONENT_DSL_PROP_TYPES.string, uniqAmongAllNodes: true }, title: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, validateOnChange: { type: COMPONENT_DSL_PROP_TYPES.boolean }, component: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, children: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'arrayHelpers',
                        path: [0],
                    },
                ],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, render: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'arrayHelpers',
                        path: [0],
                    },
                ],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsFormFieldArraySettings,
};
