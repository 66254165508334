import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import containedDSL from './style-guide-dsl/ButtonStyleGuide/contained.json';
import outlinedDSL from './style-guide-dsl/ButtonStyleGuide/outlined.json';
import textOnlyDSL from './style-guide-dsl/ButtonStyleGuide/textOnly.json';
export const ButtonStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiButton,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsButton,
    dirName: 'ButtonStyleGuide',
    title: 'Button',
    classNames: [
        '.MuiLoadingButton-root',
        '.MuiButton-text',
        '.MuiButton-textInherit',
        '.MuiButton-textPrimary',
        '.MuiButton-textSecondary',
        '.MuiButton-outlined',
        '.MuiButton-outlinedInherit',
        '.MuiButton-outlinedPrimary',
        '.MuiButton-outlinedSecondary',
        '.MuiButton-contained',
        '.MuiButton-containedInherit',
        '.MuiButton-containedPrimary',
        '.MuiButton-containedSecondary',
        '.MuiButton-disableElevation',
        '.Mui-focusVisible',
        '.Mui-disabled',
        '.MuiButton-colorInherit',
        '.MuiButton-textSizeSmall',
        '.MuiButton-textSizeMedium',
        '.MuiButton-textSizeLarge',
        '.MuiButton-outlinedSizeSmall',
        '.MuiButton-outlinedSizeMedium',
        '.MuiButton-outlinedSizeLarge',
        '.MuiButton-containedSizeSmall',
        '.MuiButton-containedSizeMedium',
        '.MuiButton-containedSizeLarge',
        '.MuiButton-sizeSmall',
        '.MuiButton-sizeMedium',
        '.MuiButton-sizeLarge',
        '.MuiButton-fullWidth',
        '.MuiButton-startIcon',
        '.MuiButton-endIcon',
        '.MuiButton-iconSizeSmall',
        '.MuiButton-iconSizeMedium',
        '.MuiButton-iconSizeLarge',
        '.MuiLoadingButton-loading',
        '.MuiLoadingButton-loadingIndicator',
        '.MuiLoadingButton-loadingIndicatorCenter',
        '.MuiLoadingButton-loadingIndicatorStart',
        '.MuiLoadingButton-loadingIndicatorEnd',
        '.MuiLoadingButton-endIconLoadingEnd',
        '.MuiLoadingButton-startIconLoadingStart',
    ],
    stories: [
        {
            title: 'Text only',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'text',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'text',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'text',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: textOnlyDSL,
        },
        {
            title: 'Contained',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'contained',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'contained',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'contained',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: containedDSL,
        },
        {
            title: 'Outlined',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsButton,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                        color: 'primary',
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        disabled: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: outlinedDSL,
        },
    ],
};
