var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEmpty } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_6_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.6.3';
    }
    getChangeList() {
        return [`Checkbox: move height prop from root.inputProps.style to root.style`];
    }
    migrateNodeDSL(nodeDSL) {
        var _a, _b;
        switch (nodeDSL.name) {
            case 'BuilderComponentsCheckbox': {
                const _c = nodeDSL.props, { inputProps, style = {} } = _c, otherProps = __rest(_c, ["inputProps", "style"]);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const height = (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.style) === null || _a === void 0 ? void 0 : _a.height;
                const newStyle = Object.assign(Object.assign({}, style), { height });
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, otherProps), { style: !isEmpty(newStyle) ? newStyle : undefined }) });
            }
            case 'BuilderComponentsFormCheckbox': {
                const _d = nodeDSL.props, { checkboxProps = {} } = _d, otherProps = __rest(_d, ["checkboxProps"]);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { inputProps, style = {} } = checkboxProps, otherCheckboxProps = __rest(checkboxProps, ["inputProps", "style"]);
                const height = (_b = inputProps === null || inputProps === void 0 ? void 0 : inputProps.style) === null || _b === void 0 ? void 0 : _b.height;
                const newStyle = Object.assign(Object.assign({}, style), { height });
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, otherProps), { checkboxProps: Object.assign(Object.assign({}, otherCheckboxProps), { style: !isEmpty(newStyle) ? newStyle : undefined }) }) });
            }
            default:
                return nodeDSL;
        }
    }
}
export const migration_0_6_3 = new Migration_0_6_3();
