import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_DATA_CELL } from './tableDataCell.settings';
export const tableDataCell = {
    displayName: 'Table Data Cell',
    name: COMPONENT_DSL_NAMES.tableDataCell,
    icon: 'htmlTableDataCell',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'td',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.tableRow,
                },
            },
        ],
        predefineds: {
            props: {
                style: {
                    height: 36,
                    paddingBottom: 10,
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { colspan: { type: COMPONENT_DSL_PROP_TYPES.number }, headers: { type: COMPONENT_DSL_PROP_TYPES.string }, rowspan: { type: COMPONENT_DSL_PROP_TYPES.number } }),
    },
    settings: TABLE_DATA_CELL,
};
