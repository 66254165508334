import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { VIDEO_SETTINGS } from './video.settings';
export const video = {
    name: COMPONENT_DSL_NAMES.video,
    displayName: 'Video',
    type: COMPONENT_DSL_TYPES.htmlElement,
    icon: 'video',
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'video',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 36, minHeight: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { src: { type: COMPONENT_DSL_PROP_TYPES.asset }, autoPlay: { type: COMPONENT_DSL_PROP_TYPES.boolean }, loop: { type: COMPONENT_DSL_PROP_TYPES.boolean }, muted: { type: COMPONENT_DSL_PROP_TYPES.boolean }, controls: { type: COMPONENT_DSL_PROP_TYPES.boolean }, poster: { type: COMPONENT_DSL_PROP_TYPES.string }, preload: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: VIDEO_SETTINGS,
};
