import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialToggleButtonSettings } from './MaterialToggleButton.settings';
export const MaterialToggleButton = {
    name: COMPONENT_DSL_NAMES.MaterialToggleButton,
    displayName: 'Toggle Button',
    icon: 'toggle',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'ToggleButton',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Text',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
                },
            },
        ],
        presentations: [visibleByCondition],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            props: {
                name: 'toggleButton',
                value: 'toggleButton1',
                color: 'primary',
                variant: 'text',
                size: 'medium',
                disabled: false,
                type: 'toggleButton',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Toggle Button',
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { name: { type: COMPONENT_DSL_PROP_TYPES.string }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableElevation: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFocusRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableRipple: { type: COMPONENT_DSL_PROP_TYPES.boolean }, endIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, sx: { type: COMPONENT_DSL_PROP_TYPES.string }, href: { type: COMPONENT_DSL_PROP_TYPES.string }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, startIcon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, value: { type: COMPONENT_DSL_PROP_TYPES.string || COMPONENT_DSL_PROP_TYPES.array }, target: { type: COMPONENT_DSL_PROP_TYPES.enum }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, type: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: MaterialToggleButtonSettings,
};
