export const IMPORT_FILE_SCOPE_TYPES = {
    appIndex: 'app-index',
    extractedComponent: 'extracted-component',
    hooks: 'hooks',
    symbols: 'symbols',
    all: 'all',
};
export const IMPORT_FILE_USAGE_TYPES = {
    hook: 'hook',
    var: 'var',
};
