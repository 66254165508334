import React from 'react';

import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { path, pathOr, equals, uniqWith, pick } from 'ramda';

import { ShallowAppRuntimeStateList } from '@builder/app-engine';
import {
  transformPropWithJsCode,
  ArrayPropValue,
  ComponentSettingGeneratorEditorPropDSL,
  GENERATOR_SHAPE_TYPES,
  GENERATOR_VARIANTS,
} from '@builder/schemas';
import {
  removeOptionalChainingFromString,
  serialize,
  splitAndCapitalize,
  useBoolState,
} from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { NodeSettingCommonProps } from '../../types';
import { DialogContentWithIcon } from 'src/dialogs';
import { useUserAppRuntimeState } from 'src/providers/ReduxProvider';
import { Button, CssGrid } from 'src/shared/components';

export type DataGeneratorTypeProps = {
  setting: ComponentSettingGeneratorEditorPropDSL;
  onChangePropValue: (propData: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  'data-test'?: string;
};

type NoIdeaWhatTheValueIs = unknown | undefined;

export const parseRawValueAgainstRuntimeState = (
  appRuntimeState: ShallowAppRuntimeStateList,
  selectedNodeDSL: NonNullable<NodeSettingCommonProps['selectedNodeDSL']>,
  sourcePath: DataGeneratorTypeProps['setting']['sourcePath'],
): NoIdeaWhatTheValueIs => {
  // looking for the value
  const dataValue = pathOr(null, ['props', ...sourcePath], selectedNodeDSL);
  if (dataValue === null) {
    return undefined;
  }

  /**
   * Assuming the actual value is an array
   */

  let actualDataValue: NoIdeaWhatTheValueIs;
  if (typeof dataValue === 'object') {
    // and if that's already an object, then it might be one
    // no need to parse anything (?)
    actualDataValue = dataValue as unknown;
    return actualDataValue;
  }

  /**
   * Otherwise if value is not a string -- we have no idea what to do with it
   */

  if (typeof dataValue !== 'string') {
    return undefined;
  }

  /**
   * And if it actually is a string -- we have to transform it first
   * - assuming it has handle-bars `{{ source }}`
   * - or it's a stringified array `[{"field": "x"}]` | `{{ [{"field": "x"}] }}`
   *
   * NOTE:  though if it's a complex data-source like `"[" {{source1}} {{source2}} "]"`
   *        then we just flop hands ...
   */

  const transformedDataValue = transformPropWithJsCode(dataValue);
  // checking a stringified array case
  if (transformedDataValue.startsWith('[')) {
    // would return undefined if there's nothing in there
    // or it's un-parsable
    actualDataValue = serialize.parse<unknown>(dataValue);
    return actualDataValue;
  }

  /**
   * Value is a path to a data-source
   */

  // checking local-state first
  const localDataSource = pathOr(
    undefined,
    [selectedNodeDSL.id, ...removeOptionalChainingFromString(transformedDataValue).split('.')],
    appRuntimeState.localStateList,
  ) as NoIdeaWhatTheValueIs;

  if (localDataSource !== undefined) {
    actualDataValue = localDataSource;
    return actualDataValue;
  }

  // -- then global-state
  const globalDataSource = pathOr(
    undefined,
    [...removeOptionalChainingFromString(transformedDataValue).split('.')],
    appRuntimeState.globalState,
  ) as NoIdeaWhatTheValueIs;

  if (globalDataSource !== undefined) {
    actualDataValue = globalDataSource;
    return actualDataValue;
  }

  // -- couldn't parse the value
  return undefined;
};

const parseArrayDataValue = (
  appRuntimeState: ShallowAppRuntimeStateList,
  selectedNodeDSL: NonNullable<NodeSettingCommonProps['selectedNodeDSL']>,
  sourcePath: DataGeneratorTypeProps['setting']['sourcePath'],
): { array: unknown[]; first: { field: string } } | undefined => {
  const unknownValue = parseRawValueAgainstRuntimeState(
    appRuntimeState,
    selectedNodeDSL,
    sourcePath,
  ) as unknown[] | undefined;

  try {
    const testValue = unknownValue?.[0] || {};
    if (typeof testValue !== 'object' || testValue === null) {
      return undefined;
    }

    return { array: unknownValue as unknown[], first: testValue as { field: string } };
  } catch {
    return undefined;
  }
};

const DEFAULT_WARNING_TITLE = 'Warning';
const DEFAULT_WARNING_MESSAGE = 'Do you want to clear current data?';

export const DataGeneratorType: React.FC<DataGeneratorTypeProps> = ({
  setting,
  onChangePropValue,
  'data-test': dataTest,
}) => {
  const { selectedNodeDSL } = useNodeSettingsProps();
  const { label, warningDialogMessage, warningDialogTitle } = setting;
  const [isDialogOpened, { setTrue: openDialog, setFalse: closeDialog }] = useBoolState(false);
  const appRuntimeState = useUserAppRuntimeState();

  const generateData = (clearExistingData: boolean) => {
    const { variant, sourcePath, receivePath, generatedDataShape, compareKeys } = setting;
    closeDialog();

    switch (variant) {
      case GENERATOR_VARIANTS.keysToArray: {
        const arrayDataValue = parseArrayDataValue(appRuntimeState, selectedNodeDSL, sourcePath);
        if (arrayDataValue === undefined) {
          return;
        }

        const sourceProp = arrayDataValue.first;
        const generatedData = Object.keys(sourceProp)
          .filter(key => !key.startsWith('_'))
          .map(key => {
            return Object.entries(generatedDataShape).reduce(
              (acc, [dataShapeKey, dataShapeValue]) => {
                switch (dataShapeValue) {
                  case GENERATOR_SHAPE_TYPES.key: {
                    return {
                      ...acc,
                      [dataShapeKey]: key,
                    };
                  }

                  case GENERATOR_SHAPE_TYPES.capitalizeKey: {
                    return {
                      ...acc,
                      [dataShapeKey]: splitAndCapitalize(key),
                    };
                  }

                  default: {
                    return {
                      ...acc,
                      [dataShapeKey]: key,
                    };
                  }
                }
              },
              {},
            );
          });

        const currentData = path<ArrayPropValue>(['props', ...receivePath], selectedNodeDSL);
        const mergedData = [...(currentData || []), ...generatedData] as ArrayPropValue;
        const uniqueData = clearExistingData
          ? generatedData
          : (uniqWith((firstValue, secondValue) => {
              return equals(pick(compareKeys, firstValue), pick(compareKeys, secondValue));
            }, mergedData) as ArrayPropValue);

        if (equals(currentData, uniqueData)) {
          return;
        }

        onChangePropValue({
          keyValue: uniqueData,
          keyPath: receivePath,
        });
      }
    }
  };

  return (
    <CssGrid>
      <Button
        variant="contained"
        color="default"
        onClick={openDialog}
        data-test={`${dataTest}.openDialogBtn`}
      >
        {label}
      </Button>
      <Dialog open={isDialogOpened} onClose={closeDialog}>
        <DialogTitle>{warningDialogTitle ?? DEFAULT_WARNING_TITLE}</DialogTitle>
        <DialogContentWithIcon>
          <ErrorOutlineIcon color="error" />
          <DialogContentText>
            {warningDialogTitle ?? DEFAULT_WARNING_TITLE}
            <Typography variant="body3">
              {warningDialogMessage ?? DEFAULT_WARNING_MESSAGE}
            </Typography>
          </DialogContentText>
        </DialogContentWithIcon>
        <DialogActions>
          <Button
            onClick={() => generateData(false)}
            variant="contained"
            color="default"
            data-test={`${dataTest}.mergeDataBtn`}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => generateData(true)}
            color="secondary"
            data-test={`${dataTest}.rewriteDataBtn`}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </CssGrid>
  );
};
