import styled from '@emotion/styled';

import { ComponentSettingPopupSectionDSL, ComponentSettingPopupDSL } from '@builder/schemas';

type PopupsSectionTypeProps = {
  setting: ComponentSettingPopupSectionDSL;

  children: (args: { settings: ComponentSettingPopupDSL[] }) => React.ReactNode;
};

const PopupsSectionTypeContainer = styled.div`
  display: grid;
`;

export const PopupsSectionType = ({ children, setting }: PopupsSectionTypeProps): JSX.Element => {
  return (
    <PopupsSectionTypeContainer>
      {children({ settings: setting.children })}
    </PopupsSectionTypeContainer>
  );
};
