import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { ACCORDION_SCHEMA } from './common/AccordionSchema';
import basic from './style-guide-dsl/AccordionStyleGuide/basic.json';
import disabled from './style-guide-dsl/AccordionStyleGuide/disabled.json';
export const AccordionStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiAccordion,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialAccordion,
    dirName: 'AccordionStyleGuide',
    title: 'Accordion',
    classNames: [
        '.MuiAccordion-root',
        '.MuiAccordion-rounded',
        '.Mui-expanded',
        '.Mui-disabled',
        '.MuiAccordion-gutters',
        '.MuiAccordion-region',
        '.MuiAccordionActions-root',
        '.MuiAccordionActions-spacing',
        '.MuiAccordionDetails-root',
        '.MuiAccordionSummary-root',
        '.Mui-focusVisible',
        '.MuiAccordionSummary-gutters',
        '.MuiAccordionSummary-contentGutters',
        '.MuiAccordionSummary-content',
        '.MuiAccordionSummary-expandIconWrapper',
    ],
    stories: [
        {
            title: 'Basic',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAccordion,
            baseNodeListDSL: ACCORDION_SCHEMA.baseNodeListDSL,
            rootID: ACCORDION_SCHEMA.rootID,
            css: ACCORDION_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        defaultExpanded: true,
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAccordionSummary,
                                    props: {
                                        expandIcon: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                                    props: {
                                                        name: 'ExpandMore',
                                                        fontSize: 'medium',
                                                    },
                                                },
                                            ],
                                        },
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Accordion Title',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Accordion Title',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAccordionDetails,
                                    props: {
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Accordion Details',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAccordionActions,
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: basic,
        },
        {
            title: 'Disabled',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialAccordion,
            baseNodeListDSL: ACCORDION_SCHEMA.baseNodeListDSL,
            rootID: ACCORDION_SCHEMA.rootID,
            css: ACCORDION_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        defaultExpanded: false,
                        disabled: true,
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAccordionSummary,
                                    props: {
                                        expandIcon: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                                    props: {
                                                        name: 'ExpandMore',
                                                        fontSize: 'medium',
                                                    },
                                                },
                                            ],
                                        },
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Accordion Title',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Accordion Disabled',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAccordionDetails,
                                    props: {
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.MaterialTypography,
                                                    alias: 'Accordion Details',
                                                    schemaOverride: {
                                                        interaction: 'only-editable',
                                                    },
                                                    props: {
                                                        children: {
                                                            nodes: [
                                                                {
                                                                    name: COMPONENT_DSL_NAMES.Text,
                                                                    props: {
                                                                        children: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialAccordionActions,
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: disabled,
        },
    ],
};
