import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../constants/component-settings';
/**
 * Provider helpers to check prop schema.
 * It checks by component setting DSL.
 */
export const SettingSchemaChecker = {
    hasChildrenSetting(setting) {
        return Boolean(setting === null || setting === void 0 ? void 0 : setting.children);
    },
    hasName(setting) {
        return Boolean(setting === null || setting === void 0 ? void 0 : setting.name);
    },
    isSettingTypeOfAction(setting) {
        return (setting === null || setting === void 0 ? void 0 : setting.type) === COMPONENT_SETTING_TYPES.action;
    },
    isSettingTypeOfProp(setting) {
        return (setting === null || setting === void 0 ? void 0 : setting.type) === COMPONENT_SETTING_TYPES.prop;
    },
    isRadioPropView(setting) {
        if (!SettingSchemaChecker.isSettingTypeOfProp(setting)) {
            return false;
        }
        return (setting === null || setting === void 0 ? void 0 : setting.componentView) === COMPONENT_SETTING_VIEWS.radio;
    },
    isSpacingEditorPropView(setting) {
        if (!SettingSchemaChecker.isSettingTypeOfProp(setting)) {
            return false;
        }
        return (setting === null || setting === void 0 ? void 0 : setting.componentView) === COMPONENT_SETTING_VIEWS.spacingEditor;
    },
};
