import { omit } from 'ramda';
import { isUndefined } from '@builder/utils';
import { componentListSelectors, nodeListSelectors } from '../../selectors';
import { PropAssert, PropChecker } from '../../validation';
import { hasPropJsCode } from '../prop-transformers/transformPropWithJsCode';
export const transformReactNodeProps = ({ propsSchema, nodeProps, componentListDSL, nodeListDSL, }) => {
    const transformedNodeProps = Object.keys(propsSchema).reduce((accum, propName) => {
        var _a;
        const propSchema = propsSchema[propName];
        const propValue = nodeProps[propName];
        if (isUndefined(propValue)) {
            return accum;
        }
        // if prop is object and its props was defined
        // then transform its props based on schema
        if (PropChecker.Schema.isObjectPropWithDefinedProps(propSchema)) {
            const transformObjectProps = transformReactNodeProps({
                propsSchema: propSchema.props,
                nodeProps: nodeProps[propName],
                componentListDSL,
                nodeListDSL,
            });
            return Object.assign(Object.assign({}, accum), { [propName]: transformObjectProps });
        }
        // if prop is array of objects then transform its props
        if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(propSchema)) {
            const arrayPropSchema = propSchema.item.props;
            const arrayPropValue = nodeProps[propName];
            PropAssert.Value.assertIsArrayProp(arrayPropValue, propName);
            const childrenNodeArrayProps = arrayPropValue.map(arrayPropItemValue => {
                const transformArrayProps = transformReactNodeProps({
                    propsSchema: arrayPropSchema,
                    nodeProps: arrayPropItemValue,
                    componentListDSL,
                    nodeListDSL,
                });
                return transformArrayProps;
            });
            return Object.assign(Object.assign({}, accum), { [propName]: childrenNodeArrayProps });
        }
        // if prop renderable then extract children nodes
        if (PropChecker.Schema.isRenderableProp(propSchema)) {
            const renderableNodeTemplate = nodeProps[propName];
            if (hasPropJsCode(renderableNodeTemplate)) {
                return Object.assign(Object.assign({}, accum), { [propName]: renderableNodeTemplate });
            }
            const transformedChildrenNodes = (_a = renderableNodeTemplate.nodes) === null || _a === void 0 ? void 0 : _a.map(nodeID => {
                const transformRenderableProps = transformNodeListToTree({
                    nodeListDSL,
                    componentListDSL,
                    nodeID,
                });
                return transformRenderableProps;
            });
            return Object.assign(Object.assign({}, accum), { [propName]: Object.assign(Object.assign({}, renderableNodeTemplate), { nodes: transformedChildrenNodes }) });
        }
        // if prop is primitive
        return Object.assign(Object.assign({}, accum), { [propName]: propValue });
    }, {});
    return transformedNodeProps;
};
export const transformNodeListToTree = ({ nodeListDSL, componentListDSL, nodeID, }) => {
    const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID });
    const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
        componentName: nodeDSL.name,
    });
    return Object.assign(Object.assign({}, omit(['id', 'parentID'], nodeDSL)), { props: transformReactNodeProps({
            nodeListDSL,
            componentListDSL,
            nodeProps: nodeDSL.props,
            propsSchema: componentDSL.schema.props,
        }) });
};
