type FilePickerValue = string | null;

/**
 * internally react-widget's value changes based on use-effect hook
 * picker's value is changed if it's not equal to its previous value
 * @returns either empty string or null so that value keeps changing between rerenders
 */
export const clearFilePickerState = (value: FilePickerValue): FilePickerValue =>
  value === null ? '' : null;

export const preventClickPropagation: EventListener = event => event.stopPropagation();
