import { css, SerializedStyles, Theme } from '@emotion/react';

export const getMonacoEditorBorderHighlightingStyles = (
  theme: Theme,
  { isFocused, error, withBorders }: { isFocused: boolean; withBorders: boolean; error?: boolean },
): SerializedStyles => {
  return css`
    .monaco-editor-wrapper {
      border: 1px solid ${theme.palette.border.input};
      border-radius: ${theme.shape.borderRadius}px;

      ${isFocused &&
      withBorders &&
      css`
        border: 1px solid ${theme.palette.primary.main} !important;
        box-shadow: 0 0 0 1px ${theme.palette.primary.main} !important;
      `}

      ${error &&
      withBorders &&
      css`
        border: 2px solid ${theme.palette.error.main} !important;
        box-shadow: 0 0 0 1px ${theme.palette.error.main} !important;
      `}

    :hover {
        border: 1px solid ${theme.palette.primary.main};
      }

      ${!withBorders &&
      css`
        border: 1px solid transparent !important;
      `};
    }
  `;
};
