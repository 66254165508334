import { DEFAULT_IMAGE_COMPONENT_SOURCE } from '@builder/utils';
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { IMG_SETTINGS } from './img.settings';
/**
 * @deprecated
 * @description when chaning img's src resizing might work incorrectly
 * html <img> needs a wrapper block to reside in
 * @see material-components/BuilderComponentsImag
 */
export const img = {
    name: COMPONENT_DSL_NAMES.img,
    displayName: 'Image',
    icon: 'image',
    type: COMPONENT_DSL_TYPES.htmlElement,
    source: {
        htmlElement: 'img',
    },
    deprecated: {
        version: '0.15.1',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                src: DEFAULT_IMAGE_COMPONENT_SOURCE,
                style: getPredefinedStyles({ minHeight: 36, minWidth: 36 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { src: { type: COMPONENT_DSL_PROP_TYPES.asset }, srcSet: { type: COMPONENT_DSL_PROP_TYPES.string }, sizes: { type: COMPONENT_DSL_PROP_TYPES.string }, width: { type: COMPONENT_DSL_PROP_TYPES.number }, height: { type: COMPONENT_DSL_PROP_TYPES.number }, alt: { type: COMPONENT_DSL_PROP_TYPES.string }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: IMG_SETTINGS,
};
