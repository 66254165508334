import { COMPONENT_DSL_PROP_TYPES, PropChecker, TYPE_DEFINITION_TYPES, forEachPropDSL, } from '@builder/schemas';
import { isUndefined } from '@builder/utils';
import { getMaxArgPosition } from './transformArgsToString';
const TYPE_DEFINITIONS = {
    any: 'any',
    undefined: 'undefined',
    anyObject: 'Record<string, any>',
    void: 'void',
    reactNode: 'React.ReactNode',
    serializedStyles: 'SerializedStyles',
    boolean: 'boolean',
    number: 'number',
    string: 'string',
};
/**
 * @example
 * [{ name: "fizz", path: [1]}, { name: "buzz", path: [3, "fizzbuzz"]}] =>
 *  {
 *    1: { name: "fizz", path: [1]},
 *    3: { name: "buzz", path: [3, "fizzbuzz"]}
 *  }
 */
const transformArgsArrayToArgsMap = (args) => {
    return args.reduce((accum, arg) => {
        return Object.assign(Object.assign({}, accum), { [arg.path[0]]: arg });
    }, {});
};
const generateObjectType = (propDSL) => {
    const isObjectPropWithDefinedProps = PropChecker.Schema.isObjectPropWithDefinedProps(propDSL);
    const isStylePropWithDefinedProps = PropChecker.Schema.isStylePropWithDefinedProps(propDSL);
    return isObjectPropWithDefinedProps || isStylePropWithDefinedProps
        ? generateTypeFromPropListDSL(propDSL.props)
        : TYPE_DEFINITIONS.anyObject;
};
const generateFunctionArgTypes = (args) => {
    // 1. Check max argument position to find out how many arguments will have a function
    const maxArgPosition = getMaxArgPosition(args);
    // 2. Transform array of args into object
    // with keys equal to argument position and values equals to the argument data
    const argsMap = transformArgsArrayToArgsMap(args);
    // 3. Generate argument types and fill the argsArray
    const argsArray = [];
    for (let pos = 0, missedArgCounter = 0; pos <= maxArgPosition; pos++) {
        // 3.1 Take argument with position pos if such argement exists
        const curArg = argsMap[pos];
        // 3.2 Generate type of the current argument
        const curArgType = !(curArg === null || curArg === void 0 ? void 0 : curArg.schema)
            ? TYPE_DEFINITIONS.any
            : generateTypeFromPropDSL(curArg.schema);
        // 3.3 Generate name of the current argument
        let curArgName = '';
        if (!isUndefined(curArg)) {
            // If arg.path looks like an array with more than one item [0, 'fizz', 'buzz']
            // we don't want to name it as arg.name === "buzz" because this would look confusing in the ejected code:
            // How it would look in the component prop
            // <Box onClick={({ fizz: { buzz } }) => { alert(buzz) }} />
            // How it would look in the TypeScript definition
            // (buzz: any) => void
            // So we just replace "buzz" with meaningless value like "arg1" in such cases
            curArgName = curArg.path.length > 1 ? `arg${pos}` : curArg.name;
        }
        else {
            missedArgCounter += 1;
            curArgName = '_'.repeat(missedArgCounter);
        }
        // 3.4 Add the current argument to the argsArray
        argsArray.push(`${curArgName}: ${curArgType}`);
    }
    // 4. Return a string with a list of arguments and their types
    return argsArray.join(', ');
};
const generateFunctionReturnType = (propDSL) => {
    const isRenderableProp = PropChecker.Schema.isRenderableProp(propDSL);
    if (isRenderableProp) {
        return TYPE_DEFINITIONS.reactNode;
    }
    return propDSL.async
        ? `Promise<${TYPE_DEFINITIONS.void}>`
        : TYPE_DEFINITIONS.void;
};
const generateFunctionType = (propDSL) => {
    const functionArgs = generateFunctionArgTypes(propDSL.args);
    const functionReturn = generateFunctionReturnType(propDSL);
    return `((${functionArgs}) => ${functionReturn})`;
};
const generateArrayType = (propDSL) => {
    const isArrayPropWithDefinedProps = PropChecker.Schema.isArrayPropWithDefinedProps(propDSL);
    const arrayItemType = isArrayPropWithDefinedProps
        ? generateTypeFromPropDSL(propDSL.item)
        : TYPE_DEFINITIONS.any;
    return `Array<${arrayItemType}>`;
};
const generateTypeFromPropDSL = (propDSL) => {
    const requiredType = generateRequiredTypeFromPropDSL(propDSL);
    if (requiredType === TYPE_DEFINITIONS.any) {
        return requiredType;
    }
    const isRequired = PropChecker.Schema.isRequired(propDSL);
    return `${requiredType}${isRequired ? '' : ' | undefined'}`;
};
const generateRequiredTypeFromPropDSL = (propDSL) => {
    var _a;
    // 1. If current prop has typeDefinition we just return it as a string value
    // Note: typeDefinition could be equal MouseEvent ot something like that
    // And we rely to assumption that this type will exist in the ejected code
    if (PropChecker.Schema.hasTypeDefinition(propDSL)) {
        return (_a = propDSL === null || propDSL === void 0 ? void 0 : propDSL.typeDefinition) === null || _a === void 0 ? void 0 : _a.definition;
    }
    // 2. Generate TypeScript definition based on schema type
    switch (propDSL.type) {
        case COMPONENT_DSL_PROP_TYPES.boolean:
            return TYPE_DEFINITIONS.boolean;
        case COMPONENT_DSL_PROP_TYPES.number:
            return TYPE_DEFINITIONS.number;
        case COMPONENT_DSL_PROP_TYPES.string:
            return TYPE_DEFINITIONS.string;
        case COMPONENT_DSL_PROP_TYPES.css:
            return TYPE_DEFINITIONS.serializedStyles;
        case COMPONENT_DSL_PROP_TYPES.array:
            return generateArrayType(propDSL);
        case COMPONENT_DSL_PROP_TYPES.style:
        case COMPONENT_DSL_PROP_TYPES.object:
            return generateObjectType(propDSL);
        case COMPONENT_DSL_PROP_TYPES.action:
        case COMPONENT_DSL_PROP_TYPES.callback:
            return generateFunctionType(propDSL);
        case COMPONENT_DSL_PROP_TYPES.reactNode:
        case COMPONENT_DSL_PROP_TYPES.calculatedReactNode:
            return TYPE_DEFINITIONS.reactNode;
        case COMPONENT_DSL_PROP_TYPES.component:
        case COMPONENT_DSL_PROP_TYPES.enum:
        case COMPONENT_DSL_PROP_TYPES.elementType:
        case COMPONENT_DSL_PROP_TYPES.notSupported:
        default:
            return TYPE_DEFINITIONS.any;
    }
};
/**
 * Wrap a propName in the quotes if the propName contains a dash "-"
 * @example
 * 'propName' -> 'propName'
 * 'prop-name' -> '"prop-name"'
 */
const generatePropName = (propName) => {
    return propName.includes('-') ? `"${propName}"` : propName;
};
/**
 * Transform propListDSL object into TypeScript definition string:
 * * @example
 * ```
 * ({
 *     children: {
 *         type: "react-node"
 *     },
 *     checked: {
 *         type: "boolean",
 *         required: true
 *     }
 * }) => `{
 *            children?: React.ReactNode;
 *            dialogs: boolean;
 *          }`
 * ```
 */
export const generateTypeFromPropListDSL = (propListDSL) => {
    const typeDefinition = Object.entries(propListDSL).reduce((resultDefinition, [propName, propDSL]) => {
        // These props should not be passed to the component.
        const isSystem = PropChecker.Schema.isSystem(propDSL);
        if (isSystem) {
            return resultDefinition;
        }
        const propDefinitionName = generatePropName(propName);
        const propDefinitionType = generateRequiredTypeFromPropDSL(propDSL);
        const isRequired = PropChecker.Schema.isRequired(propDSL);
        const propDefinition = `${propDefinitionName}${isRequired ? '' : '?'}: ${propDefinitionType};\n`;
        return `${resultDefinition}${propDefinition}`;
    }, '');
    return `{\n ${typeDefinition}\n}`;
};
export const generateTypeImportsFromPropListDSL = (propListDSL) => {
    let imports = [];
    forEachPropDSL(({ propDSL }) => {
        var _a;
        if (((_a = propDSL.typeDefinition) === null || _a === void 0 ? void 0 : _a.type) === TYPE_DEFINITION_TYPES.import) {
            imports = [...imports, ...propDSL.typeDefinition.imports];
        }
        if (PropChecker.Schema.isActionProp(propDSL)) {
            propDSL.args.forEach(actionArg => {
                var _a, _b, _c;
                if (((_b = (_a = actionArg.schema) === null || _a === void 0 ? void 0 : _a.typeDefinition) === null || _b === void 0 ? void 0 : _b.type) === TYPE_DEFINITION_TYPES.import) {
                    imports = [...imports, ...(_c = actionArg.schema) === null || _c === void 0 ? void 0 : _c.typeDefinition.imports];
                }
            });
        }
    }, propListDSL);
    return imports;
};
