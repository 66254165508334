import { RuntimePropExecutor } from '../app-nodes';
import { StateErrorGetter } from '../app-nodes/props-transformers/audit-message-getters/error-getters/StateErrorGetter';
import { useRuntimeErrorCallbacks } from '../providers';
import { transformValue } from './transformValue';
export function useTransformValue(state, libraries) {
    const { onAppAuditNotifications } = useRuntimeErrorCallbacks();
    const errorGetter = new StateErrorGetter('State', onAppAuditNotifications);
    const runtimePropExecutor = new RuntimePropExecutor({ errorGetter, libraries });
    return (toTransform) => transformValue({ toTransform, runtimePropExecutor, state, libraries });
}
