import {
  COMPONENT_DSL_NAMES,
  ComponentListDSL,
  NodeDSL,
  NodeListDSL,
  hasPropJsCode,
  transformPropWithJsCode,
} from '@builder/schemas';
import { isArray, isNull, isObject, isString } from '@builder/utils';

const REPLACE_WITH_LABEL_WHITE_LIST = ['text', 'label', 'children'];
const ALLOWED_COMPONENT_NAMES = [
  COMPONENT_DSL_NAMES.BuilderComponentsButton,
  COMPONENT_DSL_NAMES.MaterialTypography,
  COMPONENT_DSL_NAMES.HeadingSymbol,
  COMPONENT_DSL_NAMES.BuilderComponentsAutocomplete,
  COMPONENT_DSL_NAMES.MaterialToggleButton,
  COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
  COMPONENT_DSL_NAMES.BuilderComponentsCheckbox,
  COMPONENT_DSL_NAMES.BuilderComponentsRadio,
  COMPONENT_DSL_NAMES.BuilderComponentsSelect,
  COMPONENT_DSL_NAMES.BuilderComponentsMultiselect,
  COMPONENT_DSL_NAMES.BuilderComponentsSwitch,
  COMPONENT_DSL_NAMES.BuilderComponentsTextField,
  COMPONENT_DSL_NAMES.Text,
];

// Returns component name if they're in the ALLOWED_COMPONENT_NAMES list, otherwise it will return an empty string
export const getReplaceText = (
  nodeDSL: NodeDSL,
  nodeListDSL: NodeListDSL,
  componentListDSL: ComponentListDSL,
): string => {
  const nodeParent = nodeListDSL[nodeDSL?.parentID || ''];
  const nodeComponentDSL = componentListDSL[nodeDSL?.name];
  const nodeParentComponentDSL = componentListDSL[nodeParent?.name] || undefined;

  if (nodeDSL.name === COMPONENT_DSL_NAMES.Text) {
    return nodeParentComponentDSL?.displayName || '';
  }

  if (ALLOWED_COMPONENT_NAMES.some(name => name === nodeDSL.name)) {
    return nodeComponentDSL?.displayName || '';
  }

  if (ALLOWED_COMPONENT_NAMES.some(name => name === nodeParent?.name)) {
    return nodeParentComponentDSL?.displayName || '';
  }

  return '';
};

export const evalStringToRemoveReference = (
  str: string,
  replaceWith = '',
  stateName: string,
  keyName: string,
): string => {
  const isReplaceableWithName = REPLACE_WITH_LABEL_WHITE_LIST.includes(keyName);
  const transformedJsCode = transformPropWithJsCode(str);
  const stringHasChaining = transformedJsCode.includes('.');
  const isCodeReferencingState = transformedJsCode.includes(stateName);

  if (stringHasChaining && isCodeReferencingState) {
    const replaceWithFormated = replaceWith === 'Typography' ? 'Text' : replaceWith;

    return isReplaceableWithName ? replaceWithFormated : '';
  }

  return str;
};

export const replaceReference = (
  obj: NodeDSL & { [key: string]: unknown },
  stateName: string,
  replaceWithText: string,
): NodeDSL => {
  const modifiedNode = { ...obj };

  for (const [key, value] of Object.entries(obj)) {
    if (!isArray(value) && isObject(value) && !isNull(value)) {
      modifiedNode[key] = replaceReference(value as NodeDSL, stateName, replaceWithText);
    }

    if (isString(modifiedNode[key]) && hasPropJsCode(modifiedNode[key])) {
      modifiedNode[key] = evalStringToRemoveReference(
        modifiedNode[key] as string,
        replaceWithText,
        stateName,
        key,
      );
    }
  }

  return modifiedNode as NodeDSL;
};
