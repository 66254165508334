import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getBasicPropsSettings } from '../../common';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, DISPLAY_OPTION_VALUES, NS_ID, } from '../../common/constants';
import { assocSettings, disableRadioOptions, pickSettings } from '../../common/helpers';
import { getDivider, getEventsSection, getLooperSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const BuilderComponentsRadioSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            name: 'label',
                            nodePath: ['label', 0],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['value'],
                            label: 'Value',
                            name: 'value',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disabled'],
                            label: 'Disabled',
                            name: 'disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['required'],
                            label: 'Required',
                            name: 'required',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['readOnly'],
                            label: 'Read Only',
                            name: 'readOnly',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['autoFocus'],
                            label: 'Auto Focus',
                            name: 'autoFocus',
                        },
                    ],
                },
                getDivider(),
                getLooperSection(),
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Icon Color',
                            name: 'color',
                            options: ['primary', 'secondary'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['size'],
                            label: 'Size',
                            name: 'size',
                            options: ['medium', 'small'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['labelPlacement'],
                            label: 'Label Placement',
                            name: 'labelPlacement',
                            options: ['top', 'bottom', 'start', 'end'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableRipple'],
                            label: 'Disable Ripple',
                            name: 'disableRipple',
                        },
                    ],
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])))(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
export const getCommonRadioSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'label'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'value'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'disabled'],
    [NS_ID.style.root, NS_ID.properties.basic, 'color'],
    [NS_ID.style.root, NS_ID.properties.basic, 'size'],
    [NS_ID.style.root, NS_ID.properties.basic, 'labelPlacement'],
    [NS_ID.style.root, NS_ID.properties.basic, 'disableRipple'],
], { withStructure: false }))(values(BuilderComponentsRadioSettings).flat());
