import { omit } from 'ramda';

import { dashboardInitial } from '../../../initial-state';
import { dashboardSelectors } from 'src/store';
import { AppEvents, createReducer, DashboardState, DASHBOARD_EVENTS } from 'src/store/common';

export const USER_COMPONENTS_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.userComponentCreate,
  DASHBOARD_EVENTS.userComponentUpdate,
  DASHBOARD_EVENTS.userComponentDelete,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof USER_COMPONENTS_REDUCER_EVENTS[number]>({
    events: USER_COMPONENTS_REDUCER_EVENTS,
    reduce: (state, event) => {
      const nodeListDSL = dashboardSelectors.getNodeListDSL(state);

      switch (event.type) {
        case DASHBOARD_EVENTS.userComponentCreate: {
          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              userComponentsDSL: {
                ...state.appConfiguration.userComponentsDSL,
                [event.userComponentDSL.name]: event.userComponentDSL,
              },
            },
          };
        }

        case DASHBOARD_EVENTS.userComponentUpdate: {
          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: {
                ...state.appConfiguration.appDSL,
                nodes: Object.values(nodeListDSL).reduce((acc, nodeDSL) => {
                  if (nodeDSL.name === event.previousName) {
                    return {
                      ...acc,
                      [nodeDSL.id]: {
                        ...nodeDSL,
                        name: event.userComponentDSL.name,
                      },
                    };
                  }

                  return {
                    ...acc,
                    [nodeDSL.id]: nodeDSL,
                  };
                }, {}),
              },
              userComponentsDSL: {
                ...omit([event.previousName], state.appConfiguration.userComponentsDSL),
                [event.userComponentDSL.name]: event.userComponentDSL,
              },
            },
          };
        }

        case DASHBOARD_EVENTS.userComponentDelete: {
          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              userComponentsDSL: omit([event.name], state.appConfiguration.userComponentsDSL),
            },
          };
        }
      }
    },
  }),
]);

export const userComponentsReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
