import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import basic from './style-guide-dsl/ToggleButtonGroupStyleGuide/basic.json';
import color from './style-guide-dsl/ToggleButtonGroupStyleGuide/color.json';
import exclusive from './style-guide-dsl/ToggleButtonGroupStyleGuide/exclusive.json';
export const ToggleButtonGroupStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiToggleButtonGroup,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
    dirName: 'ToggleButtonGroupStyleGuide',
    title: 'ToggleButtonGroup',
    classNames: [
        '.MuiToggleButtonGroup-root	',
        '.MuiToggleButtonGroup-vertical',
        '.Mui-disabled',
        '.MuiToggleButtonGroup-grouped',
        '.MuiToggleButtonGroup-groupedHorizontal',
        '.MuiToggleButtonGroup-groupedVertical',
    ],
    stories: [
        {
            title: 'Orientation',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        orientation: 'horizontal',
                    },
                },
                {
                    props: {
                        orientation: 'vertical',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: basic,
        },
        {
            title: 'Exclusive',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        exclusive: true,
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: exclusive,
        },
        {
            title: 'Color',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialToggleButtonGroup,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        color: 'primary',
                    },
                },
                {
                    props: {
                        color: 'secondary',
                    },
                },
                {
                    props: {
                        color: 'warning',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: color,
        },
    ],
};
