import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonBasicProps, commonStyleProps } from '../../common/commonProps';
import { AsyncContentSettings } from './AsyncContent.settings';
export const AsyncContent = {
    name: COMPONENT_DSL_NAMES.AsyncContent,
    displayName: 'Async Content',
    icon: 'asyncContent',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'AsyncContent',
    },
    memoization: {
        disable: true,
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                stretch: true,
                circularProgressProps: {
                    size: '40px',
                    thickness: 3.6,
                },
                style: getPredefinedStyles({ minHeight: 36, minWidth: 36 }),
                variant: 'indeterminate',
                color: 'primary',
                type: 'circular',
                value: 0,
            },
        },
        props: Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonBasicProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
            }, circularProgressProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    size: { type: COMPONENT_DSL_PROP_TYPES.string },
                    thickness: { type: COMPONENT_DSL_PROP_TYPES.number },
                },
            }, color: { type: COMPONENT_DSL_PROP_TYPES.string }, loading: { type: COMPONENT_DSL_PROP_TYPES.boolean }, stretch: { type: COMPONENT_DSL_PROP_TYPES.boolean }, style: { type: COMPONENT_DSL_PROP_TYPES.object }, type: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.number }, variant: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: AsyncContentSettings,
};
