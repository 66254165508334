const INITIAL = {
    display: 'block',
};
const MIN_SIZE = {
    minWidth: 36,
    minHeight: 36,
};
export const getSysMinSizeStyle = ({ minWidth = MIN_SIZE.minWidth, minHeight = MIN_SIZE.minHeight, } = MIN_SIZE) => {
    return {
        minSizeStyles: {
            minWidth: `${minWidth}px`,
            minHeight: `${minHeight}px`,
        },
    };
};
export const getPredefinedStyles = (props) => (Object.assign(Object.assign({}, INITIAL), props));
