import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, fieldValidationProps } from '../../common/commonProps';
import { BuilderComponentsFormSettings } from './BuilderComponentsForm.settings';
export const BuilderComponentsForm = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
    displayName: 'Form Block',
    icon: 'formBlock',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Form',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                style: getPredefinedStyles({
                    display: 'grid',
                    minWidth: 36,
                    minHeight: 36,
                    justifyContent: 'center',
                }),
            },
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        props: Object.assign(Object.assign(Object.assign({}, commonComponentProps), fieldValidationProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.component,
                pathToUniqueKey: [],
                args: [
                    {
                        name: 'formProps',
                        path: [0],
                    },
                ],
            }, onSubmit: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                async: true,
                args: [
                    {
                        name: 'data',
                        path: [0],
                    },
                    {
                        name: 'actions',
                        path: [1],
                    },
                ],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsFormSettings,
};
