import React from 'react';

import { useTheme } from '@emotion/react';
import {
  CircleOutlined as CircleOutlinedIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { Typography, Checkbox } from '@mui/material';
import { bindToggle, usePopupState, anchorRef } from 'material-ui-popup-state/hooks';

import { AssetID } from '@builder/schemas';
import { isNil } from '@builder/utils';

import { AssetFileWithTemporaryStatesDSL } from '../../common/types';
import { AssetMedia } from '../AssetMedia';
import { AssetPropertiesPopper, ASSET_PROPERTIES_POPPER_ID } from '../AssetPropertiesPopper';
import { Icon } from 'src/shared/components';

import {
  CardPreviewBadgeContainer,
  CardPreviewContainer,
  CheckboxBadge,
  PopperRefDiv,
  SettingsBadge,
  StyledCard,
} from './AssetCard.styles';

type AssetCardProps = {
  selectedAssetID?: AssetID;
  asset: AssetFileWithTemporaryStatesDSL;
  onSelectAsset?: (assetID?: AssetID) => void;
  toggleAssetSelectMultipleDeletion: (assetID: AssetID) => void;
};

export const AssetCard: React.FC<AssetCardProps> = ({
  asset,
  // TODO: figure out
  selectedAssetID,
  onSelectAsset,
  toggleAssetSelectMultipleDeletion,
}) => {
  const popupState = usePopupState({
    variant: 'popper',
    popupId: ASSET_PROPERTIES_POPPER_ID,
  });
  const popperRef = anchorRef(popupState);
  const bindToggleState = bindToggle(popupState);
  const isSelectionAssetForComponentView = !isNil(onSelectAsset);
  const assetAction = isSelectionAssetForComponentView
    ? { onClick: () => onSelectAsset?.(asset.id) }
    : bindToggleState;
  const theme = useTheme();
  return (
    <StyledCard
      isActive={popupState.isOpen}
      isSelected={asset.isSelected}
      data-test={`assets.assetItem.card_${asset.name}`}
    >
      <PopperRefDiv ref={popperRef as React.Ref<HTMLDivElement>} />
      <CardPreviewBadgeContainer>
        <CardPreviewContainer {...assetAction}>
          <AssetMedia
            assetType={asset.type}
            downloadUrl={asset?.previewUrl}
            height={asset.isSelected ? '66px' : '86px'}
          />
        </CardPreviewContainer>
        <If condition={!isSelectionAssetForComponentView}>
          <SettingsBadge
            {...bindToggleState}
            data-test={`assets.assetItem.settingsButton_${asset.name}`}
          >
            <Icon width={15} height={15} name="settings" />
          </SettingsBadge>

          <CheckboxBadge>
            <Checkbox
              data-test={`assets.assetItem.selectAssetCheckbox_${asset.name}`}
              checked={asset.isSelected}
              onClick={() => toggleAssetSelectMultipleDeletion(asset.id)}
              icon={<CircleOutlinedIcon fontSize="medium" />}
              checkedIcon={
                <CheckCircleIcon fontSize="medium" htmlColor={theme.palette.common.white} />
              }
            />
          </CheckboxBadge>
        </If>
      </CardPreviewBadgeContainer>

      <Typography align="center" variant="body1" noWrap data-test="assets.assetItem.name">
        {asset.name}
      </Typography>

      <If condition={popupState.isOpen}>
        <AssetPropertiesPopper asset={asset} popupState={popupState} />
      </If>
    </StyledCard>
  );
};
