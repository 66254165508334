import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getBasicPropsSettings, NS_ID, pickSettings } from '../../common';
import { getDivider } from '../../common/commonSections';
import { getEventsSection, getPropertySections, getStyleSections } from '../../common/settings';
export const BuilderComponentsBoxSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [...getBasicPropsSettings([], true), getDivider(), ...getPropertySections()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonBoxSettings = () => pipe(pickSettings([[NS_ID.style.root, NS_ID.style.spacing.root, NS_ID.style.spacing.editor]], {
    withStructure: false,
}))(values(BuilderComponentsBoxSettings).flat());
