var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { getIsInvalid, RESET_STATUS } from '../common';
import { DateTimePicker } from '../DateTimePicker';
export const FormDateTimePicker = forwardRef((_a, ref) => {
    var { fieldProps, dateTimePickerProps, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, className, translate, onAccept = () => { } } = _a, rest = __rest(_a, ["fieldProps", "dateTimePickerProps", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "className", "translate", "onAccept"]);
    const { setFieldValue, status, setStatus, values } = useFormikContext();
    useEffect(() => {
        setFieldValue(fieldProps.name, (dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value) || '');
        return () => setFieldValue(fieldProps.name, undefined);
    }, [dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value, fieldProps.name, setFieldValue]);
    useEffect(() => {
        var _a;
        if (status === RESET_STATUS) {
            setStatus(undefined);
            (_a = dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(dateTimePickerProps, new Date().toLocaleDateString());
            setFieldValue(fieldProps.name, new Date().toLocaleDateString());
        }
    }, [dateTimePickerProps, status, setStatus, setFieldValue, fieldProps.name]);
    useEffect(() => {
        if ((dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value) != null &&
            (dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.onChange) &&
            (dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value) !== values[fieldProps.name]) {
            setFieldValue(fieldProps.name, dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value);
        }
        else if (dateTimePickerProps == null && values[fieldProps.name] !== '') {
            setFieldValue(fieldProps.name, new Date().toLocaleDateString());
        }
    }, [
        dateTimePickerProps,
        dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.onChange,
        dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value,
        fieldProps.name,
        setFieldValue,
        values,
    ]);
    return (_jsx(Field, Object.assign({ name: fieldProps.name, validate: fieldProps.validate }, { children: ({ field, form, meta }) => {
            const isInvalid = getIsInvalid({ meta, form });
            const errorText = isInvalid ? meta.error : undefined;
            return (_jsx(_Fragment, { children: _jsx(DateTimePicker, Object.assign({}, rest, { id: id, title: title, lang: lang, className: className, translate: translate, ref: ref }, dateTimePickerProps, { value: field.value || (dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value), onChange: (date, value) => {
                        var _a;
                        form.setFieldValue(fieldProps.name, value);
                        (_a = dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.onChange) === null || _a === void 0 ? void 0 : _a.call(dateTimePickerProps, value);
                        field.onChange(value);
                        setFieldValue(fieldProps.name, (dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.initialFocusedDate) || (dateTimePickerProps === null || dateTimePickerProps === void 0 ? void 0 : dateTimePickerProps.value) || '');
                    }, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, error: isInvalid, helperText: errorText })) }));
        } })));
});
