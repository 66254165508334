import React, { useCallback, useMemo, useState } from 'react';

import styled from '@emotion/styled';

import { HSBA } from '../../types';
import { useDragging } from 'src/shared/hooks';
import { Coords, ElementSize, Range } from 'src/shared/types';

type PointerProps = {
  top: number;
  left: number;
  size: number;
};

type SaturationBrightnessSliderProps = {
  width: string;
  height: string;
  hsb: Omit<HSBA, 'a'>;
  pointerSize?: number;
  onSaturationChange: (value: number) => void;
  onBrightnessChange: (value: number) => void;
};

const Container = styled.div<
  Pick<SaturationBrightnessSliderProps, 'width' | 'height'> & { color: string }
>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ color }) => color};
  border-radius: 4px;
  overflow: hidden;
`;

const SaturationGradientMask = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
`;

const BrightnessGradientMask = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
`;

const Pointer = styled.div<PointerProps>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  background-color: transparent;
  box-shadow: 0 0 2px 2px black;
  outline: 2px solid white;
  border-radius: 50%;
`;

const DEFAULT_POINTER_SIZE = 6;
const RANGE: Range = { min: 0, max: 1 };

export const SaturationBrightnessSlider: React.FC<SaturationBrightnessSliderProps> = ({
  width: wrapperWidth,
  height: wrapperHeight,
  hsb,
  onSaturationChange,
  onBrightnessChange,
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const handleContainerRefChange = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setContainerWidth(node.offsetWidth);
      setContainerHeight(node.offsetHeight);
    }
  }, []);

  /**
   * @coords - cursor coords
   * @elementSize - size of dragRef element
   * */
  const handleDragging = useCallback(
    ({ x, y }: Coords, { width: dragRefWidth, height: dragRefHeight }: ElementSize) => {
      // get the new Saturation and Brightness by multiplying the current cursor position by the ratio of the maximum allowed value to the size of the container
      const newSaturation = x * (RANGE.max / dragRefWidth);
      const newBrightness = RANGE.max - y * (RANGE.max / dragRefHeight);
      const isNewSaturationOutOfRange = newSaturation < RANGE.min || newSaturation > RANGE.max;

      if (isNewSaturationOutOfRange) return;

      onSaturationChange(newSaturation);

      const isNewBrightnessOutOfRange = newBrightness < RANGE.min || newBrightness > RANGE.max;

      if (isNewBrightnessOutOfRange) return;

      onBrightnessChange(newBrightness);
    },
    [onBrightnessChange, onSaturationChange],
  );

  const { setDragRef, onDragStart: handleMouseDown } = useDragging({
    onDragging: handleDragging,
  });

  const backgroundColor = useMemo(() => `hsl(${hsb.h}, 100%, 50%)`, [hsb.h]);

  /**
   * we get the cursor shift from the upper left corner
   * @top - same as left, but inverted by subtracting from container's height
   * @left - get the new pointer position by dividing the current cursor position by the ratio of the maximum allowed value to the size of the container
   * */
  const { top, left } = useMemo(
    () => ({
      top: containerHeight - hsb.b / (RANGE.max / containerHeight),
      left: hsb.s / (RANGE.max / containerWidth),
    }),
    [containerWidth, containerHeight, hsb.b, hsb.s],
  );

  return (
    <Container
      ref={node => {
        handleContainerRefChange(node);
        setDragRef(node);
      }}
      width={wrapperWidth}
      height={wrapperHeight}
      color={backgroundColor}
      onMouseDown={handleMouseDown}
      className="no-drag"
      style={{ cursor: 'auto' }}
    >
      <Pointer
        left={left - DEFAULT_POINTER_SIZE / 2}
        top={top - DEFAULT_POINTER_SIZE / 2}
        size={DEFAULT_POINTER_SIZE}
      />
      <SaturationGradientMask>
        <BrightnessGradientMask />
      </SaturationGradientMask>
    </Container>
  );
};
