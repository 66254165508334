import * as changeCase from 'change-case';
import { omit, pick } from 'ramda';
import { MUTATION_ARGS_DSL, resourceListSelectors, COMPONENT_DSL_PROP_TYPES, resourceSelectors, } from '@builder/schemas';
import { OUTPUT_PROP_MODES } from '../../../../constants';
import { transformObjectProps, transformProps, } from '../../../app-nodes-generator/prop-transformers';
import { getCallbackAndTransformer } from '../../utils';
import { GqlBaseStateAccessor } from './GqlBaseStateAccessor';
const ARGS_TO_RETURN = [
    'variables',
    'ignoreResults',
    'awaitRefetchQueries',
    'optimisticResponse',
    'refetchQueries',
];
export class MutationStateAccessor extends GqlBaseStateAccessor {
    constructor({ appState, stateListDSL, resourceListDSL, authResourceDSL, assetListDSL, }) {
        super();
        this.stateDSL = appState;
        this.stateListDSL = stateListDSL;
        this.assetListDSL = assetListDSL;
        this.resourceListDSL = resourceListDSL;
        this.authResourceDSL = authResourceDSL;
    }
    getGqlQueryName() {
        return changeCase.constantCase(this.stateDSL.name);
    }
    getHookAssociatedConstantsString() {
        return `const ${this.getGqlQueryName()} = gql\`${this.stateDSL.args.body}\`;`;
    }
    getHookDeclarationBodyString(gqlGeneratedTypes) {
        var _a, _b, _c;
        const resourceDSL = resourceListSelectors.getResourceDSL(this.resourceListDSL, {
            resourceID: this.stateDSL.resourceID,
        });
        const resource = this.resourceListDSL[this.stateDSL.resourceID];
        const endPoint = resourceSelectors.getResourceEndpointCodeEngine(resource);
        const resourceClient = `
        const apiEndpoint = ${endPoint}
        const client = useMemo(() => useResourceClient(apiEndpoint), [apiEndpoint, JSON.stringify(${endPoint})]);
        `;
        const headers = Object.assign(Object.assign({}, (_b = (_a = resourceDSL.default) === null || _a === void 0 ? void 0 : _a.context) === null || _b === void 0 ? void 0 : _b.headers), (_c = this.stateDSL.args.context) === null || _c === void 0 ? void 0 : _c.headers);
        const args = Object.assign(Object.assign({}, this.stateDSL.args), { context: Object.assign(Object.assign({}, this.stateDSL.args.context), { headers }) });
        const propsResult = transformObjectProps({
            propData: {
                entityName: this.stateDSL.name,
                props: Object.assign({ name: this.stateDSL.name, type: this.stateDSL.type }, pick(ARGS_TO_RETURN, args)),
                propListDSL: Object.assign({ name: { type: COMPONENT_DSL_PROP_TYPES.string }, type: { type: COMPONENT_DSL_PROP_TYPES.string } }, pick(ARGS_TO_RETURN, MUTATION_ARGS_DSL)),
            },
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        const transformedArgs = transformObjectProps({
            propData: {
                props: Object.assign({}, omit(['body', 'transformer', 'onCompleted', 'onError', 'optimisticResponse'], args)),
                propListDSL: MUTATION_ARGS_DSL,
                entityName: this.stateDSL.name,
                additionalProps: `${args.optimisticResponse
                    ? `optimisticResponse: () =>
            {setMutationData(transform(propsResult.optimisticResponse))},`
                    : ''}client,`,
            },
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        const transformationPropsMap = transformProps({
            propData: {
                props: args,
                propListDSL: MUTATION_ARGS_DSL,
                entityName: this.stateDSL.name,
                propPath: [],
            },
            outputMode: OUTPUT_PROP_MODES.js,
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        const onTransformer = getCallbackAndTransformer(this.stateDSL, 'onTranformer', transformationPropsMap);
        const onCompleted = getCallbackAndTransformer(this.stateDSL, 'onCompleted', transformationPropsMap);
        const onError = getCallbackAndTransformer(this.stateDSL, 'onError', transformationPropsMap);
        const genericName = this.getGenericGqlAction(gqlGeneratedTypes, 'query');
        const queryGeneric = genericName ? `<${genericName}>` : '';
        const transformerType = genericName ? `: ${genericName}` : '';
        let autorefetch = '';
        if (!('isTriggeredManually' in this.stateDSL.args) && !this.stateDSL.args.isTriggeredManually) {
            autorefetch = `useEffect(() => {
        fetchData();
      }, [run]);`;
        }
        return `
      ${resourceClient}

      const [mutationData, setMutationData] = useState();
      const propsResult = ${propsResult};
      const transform = (data${transformerType}) => {
        ${onTransformer || 'return data'}
      };
      const onCompleted = ${onCompleted || `() => undefined`};
      const onError = ${onError || `() => undefined`};
      const [run, mutationResult] = useMutation${queryGeneric}(${this.getGqlQueryName()}, ${transformedArgs});


      const fetchData = async (options) => {
        try {
          const response = await run(options).then(({ data }) => {
            const transformedData = transform?.(propsResult.ignoreResults? mutationData: data);
            onCompleted?.(transformedData);
            setMutationData(transformedData);
            mutationResult.error && onError(data);
            return transformedData;
          });
          return response;
        } catch (error) {
          onError(error)
        }
      };

      ${autorefetch}

      return {
        ...mutationResult,
        ...propsResult,
        run: fetchData,
        data: mutationData,
        query: \`${args.body}\`,
      };
    `;
    }
    getHookTypings(gqlGeneratedTypes) {
        const stateName = this.getStateName();
        const hookBody = this.getHookDeclarationBodyString(gqlGeneratedTypes);
        return `const ${stateName} = (() => { ${hookBody} })()`;
    }
}
