import { RESOURCE_TYPES } from './resource';
export const AUTH_TOKEN_ID_TEMPLATE = {
    id: 'authTokenID',
    type: 'token',
    name: 'Token Auth',
};
export const WEB_NATIVE_ID_TEMPLATE = {
    id: 'webNativeID',
    type: 'web-native',
    name: 'Login Auth',
};
export const AUTH_RESOURCE_ID_TEMPLATE = {
    id: '8baseBackend_ID',
    type: RESOURCE_TYPES.backendEightBase,
    name: 'Template resource name',
};
export const AUTH_TYPES = {
    token: 'token',
    webNative: 'web-native',
};
