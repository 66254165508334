import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { SELECT_SCHEMA } from './common/SelectSchema';
import basicDSL from './style-guide-dsl/SelectStyleGuide/basic.json';
import multiselectDSL from './style-guide-dsl/SelectStyleGuide/multiselect.json';
import otherPropsDSL from './style-guide-dsl/SelectStyleGuide/otherProps.json';
import variantDSL from './style-guide-dsl/SelectStyleGuide/variant.json';
export const SelectStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiSelect,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSelect,
    dirName: 'SelectStyleGuide',
    title: 'Select',
    classNames: [
        '.MuiSelect-select',
        '.MuiSelect-multiple',
        '.MuiSelect-filled',
        '.MuiSelect-outlined',
        '.MuiSelect-standard',
        '.Mui-disabled',
        '.MuiSelect-icon',
        '.MuiSelect-iconOpen',
        '.MuiSelect-iconFilled',
        '.MuiSelect-iconOutlined',
        '.MuiSelect-iconStandard',
        '.MuiSelect-nativeInput',
    ],
    stories: [
        {
            title: 'Basic',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSelect,
            baseNodeListDSL: SELECT_SCHEMA.baseNodeListDSL,
            rootID: SELECT_SCHEMA.rootID,
            css: SELECT_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {},
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: basicDSL,
        },
        {
            title: 'Variant ( Filled, Outlined, Standard )',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSelect,
            baseNodeListDSL: SELECT_SCHEMA.baseNodeListDSL,
            rootID: SELECT_SCHEMA.rootID,
            css: SELECT_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'outlined',
                        label: 'Outlined',
                    },
                },
                {
                    props: {
                        variant: 'filled',
                        label: 'Filled',
                    },
                },
                {
                    props: {
                        variant: 'standard',
                        label: 'Standard',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: variantDSL,
        },
        {
            title: 'Other props ( AutoWidth, Disabled, Size )',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSelect,
            baseNodeListDSL: SELECT_SCHEMA.baseNodeListDSL,
            rootID: SELECT_SCHEMA.rootID,
            css: SELECT_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        autoWidth: true,
                        label: 'Auto Width',
                    },
                },
                {
                    props: {
                        disabled: true,
                        label: 'Disabled',
                    },
                },
                {
                    props: {
                        size: 'small',
                        label: 'Small size',
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            // TODO: check what is wrong here
            generatedAppDSL: otherPropsDSL,
        },
        {
            title: 'Multiselect',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsMultiselect,
            baseNodeListDSL: SELECT_SCHEMA.baseNodeListDSL,
            rootID: SELECT_SCHEMA.rootID,
            css: SELECT_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {},
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: multiselectDSL,
        },
    ],
};
