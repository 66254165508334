import { Migration } from '../Migration';
class Migration_0_18_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.3';
    }
    getChangeList() {
        return ['Added global error state'];
    }
    migrateAppDSL(appDSL) {
        const stateNames = Object.values(appDSL.states).map(state => {
            return state.name;
        });
        const generateStateName = (generatedName) => {
            if (stateNames.includes(generatedName)) {
                const newName = `${generatedName}${Math.floor(Math.random() * 10).toString()}`;
                generateStateName(newName);
            }
            return generatedName;
        };
        return Object.assign(Object.assign({}, appDSL), { states: Object.assign(Object.assign({}, appDSL.states), { errorState: {
                    name: generateStateName('uncaughtErrors'),
                    type: 'array',
                    scope: 'global',
                    defaultValue: [{ message: '', error: '', code: '' }],
                    id: 'errorState',
                } }) });
    }
}
export const migration_0_18_3 = new Migration_0_18_3();
