import { NodeSchemaChecker } from '../../validation/helpers/NodeSchemaChecker';
import { transformNodeTreeToListMemoized } from './transformNodeTreeToList';
export const transformSymbolNodes = ({ predefinedComponentListDSL = {}, componentListDSL, }) => {
    return Object.values(componentListDSL).reduce((accum, componentDSL) => {
        if (NodeSchemaChecker.isSymbolWithTreeNodesComponent(componentDSL)) {
            const symbolNodeList = transformNodeTreeToListMemoized({
                nodesTree: componentDSL.schema.symbolNodes,
                componentListDSL: Object.assign(Object.assign({}, predefinedComponentListDSL), componentListDSL),
                withAliases: true,
            });
            return Object.assign(Object.assign({}, accum), { [componentDSL.name]: Object.assign(Object.assign({}, componentDSL), { schema: Object.assign(Object.assign({}, componentDSL.schema), { symbolNodes: symbolNodeList }) }) });
        }
        return Object.assign(Object.assign({}, accum), { [componentDSL.name]: componentDSL });
    }, {});
};
