import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_PROP_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { LI_SETTINGS } from './li.settings';
export const li = {
    name: COMPONENT_DSL_NAMES.li,
    displayName: COMPONENT_DSL_NAMES.li,
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    icon: 'listItemHtml',
    source: {
        htmlElement: 'li',
    },
    schema: {
        dndTargetPropName: 'children',
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.ul,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.ol,
                },
            },
        ],
        props: Object.assign(Object.assign({}, commonComponentProps), { value: { type: COMPONENT_DSL_PROP_TYPES.number } }),
    },
    settings: LI_SETTINGS,
};
