/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeEvery } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';

import {
  NodeListDSL,
  ComponentListDSL,
  NodeID,
  nodeListSelectors,
  componentListSelectors,
} from '@builder/schemas';
import { checkAndTransformPropWithJsCode } from '@builder/schemas/dist/mjs/utils/prop-transformers/transformPropWithJsCode';

import { dashboardSelectors } from 'src/store';
import {
  COMPONENTS_REQUIRE_UPDATE_STATE,
  COMPONENT_SELECT_ACTORS,
  DashboardContextUpdateEvents,
  DASHBOARD_EVENTS,
  Store,
} from 'src/store/common';

const getNodeIDToSelectFromDashboard = (
  toSelectID: NodeID | null,
  nodeListDSL: NodeListDSL,
  componentListDSL: ComponentListDSL,
): NodeID | null => {
  if (!toSelectID) {
    return null;
  }

  const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: toSelectID });
  const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
    componentName: nodeDSL.name,
  });

  if (componentDSL.schema.accessibility?.disableDashboardSelect) {
    return getNodeIDToSelectFromDashboard(nodeDSL.parentID, nodeListDSL, componentListDSL);
  }

  return toSelectID;
};

type ComponentToggleSelectByIDEvent = Extract<
  DashboardContextUpdateEvents,
  { type: typeof DASHBOARD_EVENTS.componentToggleSelectByID }
>;

function* selectNode(
  event: Extract<
    DashboardContextUpdateEvents,
    { type: typeof DASHBOARD_EVENTS.componentToggleSelect }
  >,
) {
  const nodeListDSL: NodeListDSL = yield select((store: Store) =>
    dashboardSelectors.getNodeListDSL(store.dashboard),
  );

  const componentListDSL: ComponentListDSL = yield select((store: Store) =>
    dashboardSelectors.getComponentListDSL(store.dashboard),
  );

  let toSelectID: NodeID | null = event.id;
  if (event.actor === COMPONENT_SELECT_ACTORS.dashboard) {
    toSelectID = getNodeIDToSelectFromDashboard(toSelectID, nodeListDSL, componentListDSL);
  }

  if (toSelectID) {
    const nodeSelect = nodeListDSL[toSelectID];
    const requireUpdateState = COMPONENTS_REQUIRE_UPDATE_STATE.includes(nodeSelect?.name);
    if (requireUpdateState) {
      const allStates = {
        ...(window as any)?.appRuntimeState?.localStates,
        ...(window as any)?.appRuntimeState?.globalState,
      };
      const value = nodeListDSL[nodeListDSL[toSelectID]?.parentID ?? '']?.props?.value as string;
      const stateName = value?.replace('{{', '').replace('}}', '')?.replace('.value', '').trim();

      const getPropValueOutJs = (
        prop: string,
      ): string | number | Record<string, unknown> | boolean => {
        const { transformedValue, hasValidHandlebarJS } = checkAndTransformPropWithJsCode(prop);

        if (hasValidHandlebarJS) {
          const state = allStates[transformedValue?.replace('.value', '')];
          if (checkAndTransformPropWithJsCode(state.value).hasValidHandlebarJS) {
            return getPropValueOutJs(state.value);
          }

          return state.value;
        }

        return prop;
      };

      (window as any)?.appRuntimeState?.localStates?.[stateName]?.setValue(
        getPropValueOutJs(nodeSelect?.props?.value as string),
      );
    }

    yield put<ComponentToggleSelectByIDEvent>({
      type: DASHBOARD_EVENTS.componentToggleSelectByID,
      id: toSelectID,
    });
  }
}

export function* watchNodeSelection(): Generator {
  yield takeEvery(DASHBOARD_EVENTS.componentToggleSelect, selectNode);
}
