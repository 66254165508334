import { COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialDialogContentTextSettings } from './MaterialDialogContentText.settings';
export const MaterialDialogContentText = {
    name: COMPONENT_DSL_NAMES.MaterialDialogContentText,
    displayName: 'Dialog Content Text',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'DialogContentText',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        props: Object.assign({}, commonComponentProps),
    },
    settings: MaterialDialogContentTextSettings,
};
