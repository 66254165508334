import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_18_6 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.6';
    }
    getChangeList() {
        return ['Updating props in text, typography and heading components'];
    }
    migrateAppDSL(appDSL) {
        const COMPONENTS_NAMES = [
            COMPONENT_DSL_NAMES.BuilderRichText,
            COMPONENT_DSL_NAMES.MaterialTypography,
            COMPONENT_DSL_NAMES.HeadingSymbol,
        ];
        const PROPS_NAMES = [
            'display',
            'gridGap',
            'gridRowGap',
            'placeItems',
            'gridAutoFlow',
            'gridAutoRows',
            'gridTemplate',
            'placeContent',
            'gridColumnGap',
            'justifyContent',
            'gridAutoColumns',
            'gridTemplateRows',
            'gridTemplateAreas',
            'gridTemplateColumns',
            'flexDirection',
            'alignItems',
            'justifyContent',
            'flexWrap',
            'marginTop',
            'marginRight',
            'marginBottom',
            'marginLeft',
            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',
            'width',
            'height',
            'minWidth',
            'minHeight',
            'maxWidth',
            'maxHeight',
            'overflow',
        ];
        const filteredNodes = Object.values(appDSL.nodes).filter((node) => {
            return COMPONENTS_NAMES.includes(node.name);
        });
        if (!filteredNodes.length) {
            return appDSL;
        }
        const nodes = Object.assign({}, appDSL.nodes);
        Object.values(nodes).forEach((node) => {
            if (COMPONENTS_NAMES.includes(node.name)) {
                PROPS_NAMES.forEach((prop) => {
                    var _a, _b, _c, _d;
                    if ((_b = (_a = node === null || node === void 0 ? void 0 : node.props) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b[prop]) {
                        // eslint-disable-next-line no-param-reassign
                        (_d = (_c = node === null || node === void 0 ? void 0 : node.props) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? true : delete _d[prop];
                    }
                });
            }
        });
        return appDSL;
    }
}
export const migration_0_18_6 = new Migration_0_18_6();
