var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import Styled from '@emotion/styled';
import GoogleMapReact from 'google-map-react';
const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627,
    },
    zoom: 11,
};
const MapMarker = (_a) => {
    var { markerCss } = _a, rest = __rest(_a, ["markerCss"]);
    const Marker = Styled.div(markerCss);
    return _jsx(Marker, Object.assign({}, rest));
};
export const Map = forwardRef((_a, ref) => {
    var { bootstrapURLKeys = { key: '' }, defaultCenter = defaultProps.center, center, zoom, hoverDistance, mapMargin, layerTypes, markers = [], resetBoundsOnResize, markerCss, options, distanceToMouse, googleMapLoader, onChange, onClick, onChildClick, onChildMouseLeave, onZoomAnimationStart, onZoomAnimationEnd, onDrag, onDragEnd, onChildMouseEnter, onMapTypeIdChange, onTilesLoaded } = _a, rest = __rest(_a, ["bootstrapURLKeys", "defaultCenter", "center", "zoom", "hoverDistance", "mapMargin", "layerTypes", "markers", "resetBoundsOnResize", "markerCss", "options", "distanceToMouse", "googleMapLoader", "onChange", "onClick", "onChildClick", "onChildMouseLeave", "onZoomAnimationStart", "onZoomAnimationEnd", "onDrag", "onDragEnd", "onChildMouseEnter", "onMapTypeIdChange", "onTilesLoaded"]);
    const resetBounds = useMemo(() => !!resetBoundsOnResize, [resetBoundsOnResize]);
    return (
    // Important! Always set the container height explicitly
    _jsx("div", Object.assign({}, rest, { ref: ref }, { children: _jsx(GoogleMapReact, Object.assign({ bootstrapURLKeys: bootstrapURLKeys, defaultCenter: defaultCenter, center: center, zoom: zoom, hoverDistance: hoverDistance, margin: mapMargin, layerTypes: layerTypes, resetBoundsOnResize: resetBounds, options: options, distanceToMouse: distanceToMouse, googleMapLoader: googleMapLoader, onClick: onClick, onChildClick: onChildClick, onChildMouseLeave: onChildMouseLeave, onZoomAnimationStart: onZoomAnimationStart, onZoomAnimationEnd: onZoomAnimationEnd, onDrag: onDrag, onDragEnd: onDragEnd, onChildMouseEnter: onChildMouseEnter, onMapTypeIdChange: onMapTypeIdChange, onTilesLoaded: onTilesLoaded, defaultZoom: defaultProps.zoom, onChange: onChange }, { children: markers.map((_a) => {
                var { lat, lng } = _a, markerProps = __rest(_a, ["lat", "lng"]);
                return (_jsx(MapMarker, Object.assign({ markerCss: markerCss, lat: lat, lng: lng }, markerProps), `${lat}-${lng}`));
            }) })) })));
});
