import { compose, keys, pick, reduce } from 'ramda';
import { ResourceAssert, resourceListSelectors, resourceSelectors, REST_ARGS_DSL, } from '@builder/schemas';
import { removeHandleBars } from '@builder/schemas/dist/mjs/utils/prop-transformers/transformPropWithJsCode';
import { OUTPUT_PROP_MODES } from '../../../../constants';
import { transformProps } from '../../../app-nodes-generator/prop-transformers';
import { getCallbackAndTransformer } from '../../utils';
import { BaseAppStateAccessor } from './BaseAppStateAccessor';
export class RestStateAccessor extends BaseAppStateAccessor {
    constructor({ appState, stateListDSL, resourceListDSL, assetListDSL, }) {
        super();
        this.stateDSL = appState;
        this.stateListDSL = stateListDSL;
        this.resourceListDSL = resourceListDSL;
        this.assetListDSL = assetListDSL;
    }
    getResultString() {
        const { isTriggeredManually } = this.stateDSL.args;
        const hookArgs = [
            'endpoint',
            'requestArgs',
            'onCompleted',
            'onError',
            'transformer',
            `isTriggeredManually: ${Boolean(isTriggeredManually)}`,
        ];
        return `useRestRequest( { ${hookArgs.join(', ')} } );`;
    }
    getHookDeclarationBodyString() {
        var _a, _b;
        const resourceRestDSL = resourceListSelectors.getResourceDSL(this.resourceListDSL, {
            resourceID: this.stateDSL.resourceID,
        });
        ResourceAssert.Schema.assertIsRestResource(resourceRestDSL);
        const endpoint = resourceSelectors.getResourceEndpoint(resourceRestDSL);
        const headers = Object.assign(Object.assign({}, (_a = resourceRestDSL.default) === null || _a === void 0 ? void 0 : _a.headers), this.stateDSL.args.headers);
        const params = Object.assign(Object.assign({}, (_b = resourceRestDSL.default) === null || _b === void 0 ? void 0 : _b.params), this.stateDSL.args.params);
        const args = Object.assign(Object.assign({}, this.stateDSL.args), { headers,
            params });
        const transformationPropsMap = transformProps({
            propData: {
                props: args,
                propListDSL: REST_ARGS_DSL,
                entityName: this.stateDSL.name,
                propPath: [],
            },
            outputMode: OUTPUT_PROP_MODES.js,
            assetListDSL: this.assetListDSL,
            stateListDSL: this.stateListDSL,
        });
        const requestArgs = compose(reduce((accum, propName) => `${accum} ${propName}:${transformationPropsMap[propName]},`, ``), keys, pick(['method', 'params', 'headers', 'url']))(transformationPropsMap);
        const dataArg = args.data ? `body:\`${args.data}\`` : '';
        const onTransformer = getCallbackAndTransformer(this.stateDSL, 'onTranformer', this.stateDSL.args);
        const onCompleted = getCallbackAndTransformer(this.stateDSL, 'onCompleted', transformationPropsMap);
        const onError = getCallbackAndTransformer(this.stateDSL, 'onError', transformationPropsMap);
        return `
      const endpoint = ${removeHandleBars(endpoint)};
      const requestArgs = { ${requestArgs} ${dataArg}} as const;
      const onCompleted = useCallback(${onCompleted || `() => undefined`}, []);
      const onError = useCallback(${onError || `(error) => undefined`}, []);
      const transformer = (data) => {
        ${onTransformer || 'return data'}
      };

      const result = ${this.getResultString()}
      return {
        ...result,
        name: '${this.stateDSL.name}',
        type: '${this.stateDSL.type}',
      };
    `;
    }
    getHookTypings() {
        // copy from 'packages/code-engine/src/constants/project-template/hooks/useRestRequest.template
        const resultType = `{
      data?: unknown;
      loading: boolean;
      error?: unknown;
      refetch: (fetchOptions?: unknown) => Promise<void>;
      run: (fetchOptions?: unknown) => Promise<void>;
      name: string;
      type: string;
      method: string;
      url: string;
      headers: unknown;
      params: unknown;
      body: unknown;
      setArgs: (args: unknown) => void;
    };`;
        const stateName = this.getStateName();
        return `declare const ${stateName}: ${resultType}`;
    }
}
