import React, { useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { values } from 'ramda';

import {
  StringPropValue,
  JSInjectionPropValue,
  NumberPropValue,
  stateListSelectors,
  Arguments,
  ComponentSettingPropDSL,
  DynamicSelectValue,
  StateDSL,
  STATE_SCOPES,
} from '@builder/schemas';
import { serialize } from '@builder/utils';

import { SelectViewEditor } from '../SelectViewEditor';
import { TextViewEditor } from '../TextViewEditor';
import { useAppDSLStates, useUserAppRuntimeState } from 'src/providers/ReduxProvider';
import { AutocompleteOption, CssGrid } from 'src/shared/components';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

export type FunctionDynamicSelectProps<
  T = StringPropValue | NumberPropValue | JSInjectionPropValue | undefined
> = {
  label: string;
  onChangePropValue: (propValue: T) => void;
  nodeID?: string;
  options: Array<AutocompleteOption<string | number>>;
  required?: boolean;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  setting: ComponentSettingPropDSL;
  keyValue: unknown;
};

const StyledTextViewWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.layout.Dashboard.margin}px`};
`;

const getFunctionValues = (keyValue: string): DynamicSelectValue => {
  const currentOption = keyValue
    ? (serialize.parse(keyValue as string) as DynamicSelectValue)
    : null;

  if (currentOption) {
    return {
      nameFunction: currentOption.nameFunction,
      parameters: currentOption.parameters,
      updated: true,
    };
  }

  return {
    nameFunction: '',
    parameters: {},
    updated: false,
  };
};

export const FunctionDynamicSelect: React.FC<FunctionDynamicSelectProps> = ({
  label,
  options,
  onChangePropValue,
  required,
  nodeID,
  'data-test': dataTest,
  showFx = true,
  fxDefaultEnabled = ACTIVEPROP.literal,
  setting,
  keyValue,
}) => {
  const [functionData, setFunctionData] = useState<DynamicSelectValue>(
    getFunctionValues(keyValue as string),
  );

  const updatePropByEvent = (newValue: unknown) => {
    setFunctionData({
      nameFunction: `${String(newValue)}`,
      parameters: {},
      updated: true,
    });
  };

  const {
    icon,
    isTextIcon,
    hasFxButton,
    fxDefaultEnabled: sFxDefaultEnabled,
  } = setting as ComponentSettingPropDSL & {
    icon?: string;
    isTextIcon?: boolean;
    hasFxButton?: boolean;
    fxDefaultEnabled?: string;
  };
  const userAppStates = useAppDSLStates();

  const functionStateDSLName = useMemo(() => {
    return { name: functionData.nameFunction };
  }, [functionData.nameFunction]);

  const functionParametersSettings = useMemo(
    () => stateListSelectors.getFunctionArgumentsByName(userAppStates, functionStateDSLName),
    [userAppStates, functionStateDSLName],
  );

  useEffect(() => {
    setFunctionData(getFunctionValues(keyValue as string));
  }, [keyValue, functionData.nameFunction]);

  useEffect(() => {
    if (functionData.updated) {
      onChangePropValue(JSON.stringify(functionData));
      setFunctionData({
        nameFunction: functionData.nameFunction,
        parameters: functionData.parameters,
        updated: false,
      });
    }
  }, [functionData, onChangePropValue]);

  const argumentsValue = (id: string, propValue: unknown) => {
    setFunctionData({
      nameFunction: functionData.nameFunction,
      parameters: {
        ...functionData.parameters,
        [id]: String(propValue) || '',
      },
      updated: true,
    });
  };

  const appStatesDSL = useAppDSLStates();
  const { localStates } = useUserAppRuntimeState();

  const parametersFunctionArray = functionParametersSettings.map(
    ({ name, definition, id }: Arguments) => {
      const [currentFunction] = values(appStatesDSL).filter(
        (state: StateDSL) => state.name === functionData.nameFunction,
      );

      return (
        <StyledTextViewWrapper key={id}>
          <TextViewEditor
            label={`${name} (${definition})`}
            icon={icon}
            isTextIcon={isTextIcon}
            propValue={functionData.parameters[id]}
            key={id}
            nodeID={id}
            onChangePropValue={propValue => argumentsValue(id, propValue)}
            localStates={currentFunction.scope === STATE_SCOPES.local ? localStates : {}}
          />
        </StyledTextViewWrapper>
      );
    },
  );
  return (
    <>
      <SelectViewEditor
        label={label}
        propValue={functionData.nameFunction}
        onChangePropValue={updatePropByEvent}
        nodeID={nodeID}
        options={options}
        required={setting.required}
        data-test={dataTest}
        showFx={hasFxButton}
        fxDefaultEnabled={sFxDefaultEnabled}
      />
      <CssGrid gap={1.5} width="100%" maxWidth="100%" maxHeight="250px" overFlow>
        {parametersFunctionArray}
      </CssGrid>
    </>
  );
};
