import { pipe } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils/createSelectOptionByValue';
import { assocSettings, BASIC_STYLE_TOOLTIP, disableSpacingPaddings, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const BuilderComponentsPopperSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [...getBasicPropsSettings([], true), getDivider(), ...getPropertySections()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: 'basic',
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['placement'],
                            label: 'Placement',
                            options: [
                                'auto-end',
                                'auto-start',
                                'auto',
                                'bottom-end',
                                'bottom-start',
                                'bottom',
                                'left-end',
                                'left-start',
                                'left',
                                'right-end',
                                'right-start',
                                'right',
                                'top-end',
                                'top-start',
                                'top',
                            ].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...pipe(pickSettings([
                    [NS_ID.style.display.root],
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.css.root],
                ]), assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], disableSpacingPaddings))(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
};
