export const ROOT_NODE_ID = 'root';
export const ROOT_LAYOUT_CONTAINER_NODE_ID = 'RootLayoutsContainer_ID';
export const EMPTY_PAGE_LAYOUT_NODE_ID = 'BaseLayout_ID';
export const EMPTY_PAGE_ROUTER_SWITCH_NODE_ID = 'EmptyPageRouterSwitch_ID';
export const EMPTY_PAGE_ROOT_ROUTE_NODE_ID = 'EmptyPageRootRoute_ID';
export const EMPTY_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID = 'EmptyPageRootRouteLayout_ID';
export const ERROR_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID = 'ErrorPageRootRouteLayout_ID';
export const ERROR_PAGE_ROOT_ROUTE_NODE_ID = 'ErrorPageRootRoute_ID';
export const NOT_FOUND_PAGE_ROOT_ROUTE_NODE_ID = 'NotFoundPageRootRoute_ID';
export const NOT_FOUND_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID = 'NotFoundRootRouteLayout_ID';
export const LEFT_NAV_LAYOUT_NODE_ID = 'LeftNavLayout_ID';
export const LEFT_NAV_ROUTER_CONTENT_NODE_ID = 'LeftNavRouterContent_ID';
export const LEFT_NAV_ROUTER_SWITCH_NODE_ID = 'LeftNavRouterSwitch_ID';
export const LEFT_NAV_LAYOUT_MENU_NODE_ID = 'LeftNavLayoutMenu_ID';
export const LEFT_NAV_LAYOUT_HEADER_NODE_ID = 'LeftNavLayoutHeader_ID';
export const LEFT_NAV_LAYOUT_HEADER_BUTTON_ID = 'LeftNavLayoutHeaderButton_ID';
export const LEFT_NAV_LAYOUT_HEADER_ICON_ID = 'LeftNavLayoutHeaderIcon_ID';
export const MOBILE_LAYOUT_NODE_ID = 'MobileLayout_ID';
export const MOBILE_ROUTER_CONTENT_NODE_ID = 'MobileRouterContent_ID';
export const MOBILE_ROUTER_SWITCH_NODE_ID = 'MobileRouterSwitch_ID';
export const MOBILE_LAYOUT_HEADER_NODE_ID = 'MobileLayoutHeader_ID';
export const MOBILE_LAYOUT_FOOTER_NODE_ID = 'MobileLayoutFooter_ID';
export const BASE_PAGE_LAYOUT = 'BasePageLayout_ID';
export const BASE_PAGE_LAYOUT_NODE_ID = 'BasePageLayout_ID';
export const BASE_PAGE_LAYOUT_CONTENT_NODE_ID = 'BasePageLayoutContent_ID';
export const BASE_ROUTER_CONTENT_NODE_ID = 'BaseRouterContent_ID';
export const BASE_ROUTER_SWITCH_NODE_ID = 'BaseRouterSwitch_ID';
export const BASE_LAYOUT_HEADER_NODE_ID = 'BasePageLayoutHeader_ID';
export const BASE_LAYOUT_FOOTER_NODE_ID = 'BasePageLayoutFooter_ID';
export const BASE_PAGE_ROOT_ROUTE_NODE_ID = 'BasePageRootRoute_ID';
export const BASE_PAGE_ROUTER_SWITCH_NODE_ID = 'BasePageRouterSwitch_ID';
export const BASE_PAGE_ROOT_ROUTE_LAYOUT_NODE_ID = 'BasePageRootRouteLayout_ID';
export const ASSETS_ROOT_FOLDER_ID = 'AssetsRootFolder_ID';
export const MIN_SIZE_PROP_NAME = '__minSizeStyle';
export const AFTER_EACH_NAME = 'AfterEachGlobalHook';
export const BEFORE_EACH_NAME = 'BeforeEachGlobalHook';
export const PRESENTATIONS = {
    visible: 'visible',
    loading: 'loading',
    empty: 'empty',
    hide: 'hide',
};
export const CHECKERS = {
    isVisible: 'is-visible',
};
export const AUTH_ACCESS_TYPES = {
    private: 'private',
    public: 'public',
    any: 'any',
};
