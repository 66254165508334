var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect, useMemo, useState, } from 'react';
import { FormHelperText, TextField, FormControl } from '@mui/material';
import { DesktopDatePicker as MuiDatePicker, } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
const DEFAULT_FORMAT = 'MM/dd/yyyy';
export const DatePicker = forwardRef((_a, ref) => {
    var _b;
    var { onChange = () => undefined, value, initialFocusedDate, renderInput, error, helperText, disabled, className, style, css, inputVariant, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, otherDatePickerProps = __rest(_a, ["onChange", "value", "initialFocusedDate", "renderInput", "error", "helperText", "disabled", "className", "style", "css", "inputVariant", 'data-test', 'data-node-id', 'data-node-render-path']);
    const inputFormat = (_b = otherDatePickerProps.inputFormat) !== null && _b !== void 0 ? _b : DEFAULT_FORMAT;
    const initialValue = useMemo(() => value || initialFocusedDate, [initialFocusedDate, value]);
    const parsedDate = useMemo(() => DateTime.fromFormat(value !== null && value !== void 0 ? value : initialValue, inputFormat), [
        initialValue,
        inputFormat,
        value,
    ]);
    const isValidDate = parsedDate.isValid;
    const [localValue, setLocalValue] = useState(isValidDate ? parsedDate : DateTime.now());
    const InputProps = useMemo(() => (Object.assign({ css,
        className,
        error }, otherDatePickerProps.InputProps)), [css, className, error, otherDatePickerProps.InputProps]);
    const handleDateChange = (date) => {
        setLocalValue(date);
        onChange === null || onChange === void 0 ? void 0 : onChange(date, date === null || date === void 0 ? void 0 : date.toFormat(inputFormat));
    };
    useEffect(() => {
        setLocalValue(parsedDate);
    }, [value, parsedDate]);
    const renderInputFunction = renderInput || (params => _jsx(TextField, Object.assign({}, params, { variant: inputVariant })));
    return (_jsxs(FormControl, Object.assign({ disabled: disabled, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: otherDatePickerProps.id, title: otherDatePickerProps.title, lang: otherDatePickerProps.lang, translate: otherDatePickerProps.translate, className: className, onClick: otherDatePickerProps.onClick, onMouseOver: otherDatePickerProps.onMouseOver, onMouseOut: otherDatePickerProps.onMouseOut, onMouseDown: otherDatePickerProps.onMouseDown, onMouseUp: otherDatePickerProps.onMouseUp, onMouseEnter: otherDatePickerProps.onMouseEnter, onMouseLeave: otherDatePickerProps.onMouseLeave, onWheel: otherDatePickerProps.onWheel, onContextMenu: otherDatePickerProps.onContextMenu, onAuxClick: otherDatePickerProps.onAuxClick, style: style }, { children: [_jsx(MuiDatePicker, Object.assign({}, otherDatePickerProps, { disabled: disabled, InputProps: InputProps, onChange: handleDateChange, value: localValue, inputFormat: inputFormat, renderInput: renderInputFunction, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath })), helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
