import { pipe } from 'ramda';
import { assocSettings, getCommonFormFieldPropertiesSettings, NS_ID, getCommonFormFieldActionSettings, } from '../../common';
import { getBuilderComponentsTimePickerSettings } from '../../material-components/BuilderComponentsTimePicker/BuilderComponentsTimePicker.settings';
const basicTimePickerSettings = getBuilderComponentsTimePickerSettings(['timePickerProps']);
const PREFIX_PATH = ['timePickerProps'];
const basicBuilderComponentsTimePickerSettings = getBuilderComponentsTimePickerSettings(PREFIX_PATH);
export const BuilderComponentsFormTimePickerSettings = {
    properties: pipe(assocSettings([NS_ID.properties.root, NS_ID.properties.basic], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldPropertiesSettings(), ...settings.children] }))))(basicTimePickerSettings.properties),
    style: basicTimePickerSettings.style,
    events: pipe(assocSettings([NS_ID.events.root, NS_ID.events.events], settings => (Object.assign(Object.assign({}, settings), { children: [...getCommonFormFieldActionSettings(), ...settings.children] }))))(basicBuilderComponentsTimePickerSettings.events),
};
