import { put, takeEvery } from '@redux-saga/core/effects';

import {
  DashboardAnimationEvents,
  DashboardContextUpdateEvents,
  DASHBOARD_ANIM_EVENTS,
  DASHBOARD_EVENTS,
  MOVE_VARIANT,
  UI_BUILDER_EVENTS,
} from 'src/store/common';

function* switchToConstruction() {
  yield put({ type: UI_BUILDER_EVENTS.modeRequestConstruction });
}

type NavigatorExpandNodeEvent = Extract<
  DashboardContextUpdateEvents,
  { type: typeof DASHBOARD_EVENTS.navigatorExpandNode }
>;

function* updateNavigationTree(
  event: Extract<DashboardAnimationEvents, { type: typeof DASHBOARD_ANIM_EVENTS.move }>,
) {
  if (event.spec.type === MOVE_VARIANT.into) {
    yield put<NavigatorExpandNodeEvent>({
      type: DASHBOARD_EVENTS.navigatorExpandNode,
      nodeID: event.spec.target.nodeID,
    });
  }
}

type ComponentToggleFocusEvent = Extract<
  DashboardContextUpdateEvents,
  { type: typeof DASHBOARD_EVENTS.componentToggleFocus }
>;

function* dropNodeFocus() {
  yield put<ComponentToggleFocusEvent>({
    type: DASHBOARD_EVENTS.componentToggleFocus,
    renderID: null,
  });
}

export function* watchDnd(): Generator {
  yield takeEvery(DASHBOARD_ANIM_EVENTS.moveStart, switchToConstruction);
  yield takeEvery(DASHBOARD_ANIM_EVENTS.moveStart, dropNodeFocus);
  yield takeEvery(DASHBOARD_ANIM_EVENTS.move, updateNavigationTree);
}
