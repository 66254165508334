import * as changeCase from 'change-case';
import { componentListSelectors, } from '@builder/schemas';
export class SymbolAccessor {
    constructor(componentListDSL) {
        this.componentListDSL = componentListDSL;
    }
    getSymbolComponentDSL(symbolName) {
        return componentListSelectors.getComponentDSL(this.componentListDSL, {
            componentName: symbolName,
        });
    }
    getSymbolNodeListDSL(symbolName) {
        const symbolComponentDSL = this.getSymbolComponentDSL(symbolName);
        return symbolComponentDSL.schema.symbolNodes;
    }
    getSymbolComponentName(symbolName) {
        const symbolComponentDSL = this.getSymbolComponentDSL(symbolName);
        return changeCase.pascalCase(symbolComponentDSL.codeName || symbolComponentDSL.name, {
            transform: changeCase.pascalCaseTransformMerge,
        });
    }
}
