import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_CAPTION } from './tableCaption.settings';
export const tableCaption = {
    displayName: 'Table Caption',
    name: COMPONENT_DSL_NAMES.tableCaption,
    icon: 'htmlTableCaption',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'caption',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.table,
                },
            },
        ],
        predefineds: {
            props: {
                style: {
                    height: 36,
                    paddingBottom: 10,
                },
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign({}, commonComponentProps),
    },
    settings: TABLE_CAPTION,
};
