import { NodeDSL, NodeListDSL } from '@builder/schemas';

import { dashboardInitial } from '../../../initial-state';
import { AppEvents, createReducer, DASHBOARD_EVENTS, DashboardState } from 'src/store/common';

export const CLEAR_DSL_REDUCER_EVENTS = [DASHBOARD_EVENTS.appConfigurationDSLCleanUp];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof CLEAR_DSL_REDUCER_EVENTS[number]>({
    events: CLEAR_DSL_REDUCER_EVENTS,
    reduce: (state, event): DashboardState => {
      switch (event.type) {
        case DASHBOARD_EVENTS.appConfigurationDSLCleanUp: {
          const newState = structuredClone(state);
          const { appDSL } = newState.appConfiguration;
          const allStatesDef: string[] = [
            ...(Object.keys(appDSL.states) as string[]),
            ...Object.keys(appDSL.nodes)
              .map((nodeId: string) => {
                const { context } = appDSL.nodes[nodeId];
                if (context) {
                  return Object.keys(context) as string[];
                }

                return '';
              })
              .flat(),
          ].filter(Boolean);

          const cleanNodes = Object.values(appDSL.nodes as NodeListDSL).map((node: NodeDSL) => {
            let nodeStates = node.states;
            if (node.states) {
              nodeStates = node.states.filter(nodeState =>
                allStatesDef.includes(nodeState.stateID),
              );
            }

            return {
              ...node,
              ...(nodeStates ? { states: nodeStates } : {}),
            };
          });

          const nodes = cleanNodes.reduce((acc: NodeListDSL, node: NodeDSL) => {
            return { ...acc, [node.id]: node };
          }, {});
          return {
            ...newState,
            appConfiguration: { ...newState.appConfiguration, appDSL: { ...appDSL, nodes } },
          };
        }
      }
    },
  }),
]);

export const cleanUpReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
