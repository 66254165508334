export const COMPONENT_DSL_TYPES = {
    htmlElement: 'html-element',
    basicElement: 'basic-element',
    reactImportComponent: 'react-import-component',
    textComponent: 'text-component',
    symbol: 'symbol',
};
export const COMPONENT_DSL_KINDS = {
    root: 'root',
    layout: 'layout',
    route: 'route',
    modal: 'modal',
    routerChangeableContent: 'router-changeable-content',
    rootLayoutContainer: 'root-layout-container',
};
export const COMPONENT_DSL_RULES_INTERACTION_TYPES = {
    insideOnly: 'inside-only',
    exceptOnly: 'except-only',
};
export const COMPONENT_DSL_PROP_TYPES = {
    action: 'action',
    array: 'array',
    asset: 'asset',
    boolean: 'boolean',
    calculatedReactNode: 'calculated-react-node',
    callback: 'callback',
    css: 'css',
    number: 'number',
    object: 'object',
    reactNode: 'react-node',
    string: 'string',
    stringOrNumber: 'stringOrNumber',
    style: 'style',
    /** component not supported */
    component: 'component',
    /** enum not supported */
    enum: 'enum',
    /** element-type not supported */
    elementType: 'element-type',
    /** uses for cases when we can't imagine which type should be there */
    any: 'any',
    customProps: 'customProps',
    fieldValidation: 'fieldValidation',
    htmlFragment: 'htmlFragment',
    notSupported: '__not_supported__',
};
export const COMPONENT_DSL_CALLBACK_TYPES = {
    component: 'component',
    code: 'code',
};
export const COMPONENT_DSL_INTERACTION_TYPES = {
    onlyRemovable: 'only-removable',
    onlyEditable: 'only-editable',
    all: 'all',
    withinParent: 'within-parent',
    none: 'none',
};
export const COMPONENT_DSL_INSIDE_ONLY_TYPES = {
    directParent: 'directParent',
    anyAncestor: 'anyAncestor',
};
export const COMPONENT_DSL_IMPORT_SOURCES = {
    materialUICore: '@mui/material',
    builderComponents: '@builder/components',
    sharedComponents: 'shared/components',
    reactRouterDOM: 'react-router-dom',
    react: 'react',
};
export const MATERIAL_COMPONENT_DSL_NAMES = {
    BuilderComponentsAutocomplete: 'BuilderComponentsAutocomplete',
    BuilderComponentsAutocompleteOption: 'BuilderComponentsAutocompleteOption',
    BuilderComponentsBadge: 'BuilderComponentsBadge',
    BuilderComponentsBox: 'BuilderComponentsBox',
    BuilderComponentsButton: 'BuilderComponentsButton',
    BuilderComponentsCheckbox: 'BuilderComponentsCheckbox',
    BuilderComponentsDataGrid: 'BuilderComponentsDataGrid',
    BuilderComponentsDatePicker: 'BuilderComponentsDatePicker',
    BuilderComponentsDateTimePicker: 'BuilderComponentsDateTimePicker',
    BuilderComponentsFileDropZone: 'BuilderComponentsFileDropZone',
    BuilderComponentsFileDropZoneBase: 'BuilderComponentsFileDropZoneBase',
    BuilderComponentsFilePicker: 'BuilderComponentsFilePicker',
    BuilderComponentsFileUpload: 'BuilderComponentsFileUpload',
    BuilderComponentsFileUploadButton: 'BuilderComponentsFileUploadButton',
    BuilderComponentsFloatingActionButton: 'BuilderComponentsFloatingActionButton',
    BuilderComponentsFooter: 'BuilderComponentsFooter',
    BuilderComponentsHtmlFragment: 'BuilderComponentsHtmlFragment',
    BuilderComponentsIcon: 'BuilderComponentsIcon',
    BuilderComponentsImage: 'BuilderComponentsImage',
    BuilderComponentsMap: 'BuilderComponentsMap',
    BuilderComponentsMenu: 'BuilderComponentsMenu',
    BuilderComponentsMultiselect: 'BuilderComponentsMultiselect',
    BuilderComponentsPopover: 'BuilderComponentsPopover',
    BuilderComponentsPopper: 'BuilderComponentsPopper',
    BuilderComponentsRating: 'BuilderComponentsRating',
    BuilderComponentsRichtextEditor: 'BuilderComponentsRichtextEditor',
    BuilderComponentsRouterLink: 'BuilderComponentsRouterLink',
    BuilderComponentsSelect: 'BuilderComponentsSelect',
    BuilderComponentsSlider: 'BuilderComponentsSlider',
    BuilderComponentsSnackBar: 'BuilderComponentsSnackBar',
    BuilderComponentsSnackbarContent: 'BuilderComponentsSnackbarContent',
    BuilderComponentsSpeedDial: 'BuilderComponentsSpeedDial',
    BuilderComponentsSpeedDialAction: 'BuilderComponentsSpeedDialAction',
    BuilderComponentsSwitch: 'BuilderComponentsSwitch',
    BuilderComponentsTab: 'BuilderComponentsTab',
    BuilderComponentsTabs: 'BuilderComponentsTabs',
    BuilderComponentsTextField: 'BuilderComponentsTextField',
    BuilderComponentsTimePicker: 'BuilderComponentsTimePicker',
    BuilderComponentsTooltip: 'BuilderComponentsTooltip',
    MaterialAccordion: 'MaterialAccordion',
    MaterialAccordionActions: 'MaterialAccordionActions',
    MaterialAccordionDetails: 'MaterialAccordionDetails',
    MaterialAccordionSummary: 'MaterialAccordionSummary',
    MaterialAlert: 'MaterialAlert',
    MaterialAlertTitle: 'MaterialAlertTitle',
    MaterialAppBar: 'MaterialAppBar',
    MaterialAvatar: 'MaterialAvatar',
    MaterialBadge: 'MaterialBadge',
    MaterialBreadcrumbs: 'MaterialBreadcrumbs',
    MaterialButtonGroup: 'MaterialButtonGroup',
    MaterialCard: 'MaterialCard',
    MaterialCardContent: 'MaterialCardContent',
    MaterialCardHeader: 'MaterialCardHeader',
    MaterialCardMedia: 'MaterialCardMedia',
    MaterialChip: 'MaterialChip',
    MaterialDialog: 'MaterialDialog',
    MaterialDialogActions: 'MaterialDialogActions',
    MaterialDialogContent: 'MaterialDialogContent',
    MaterialDialogContentText: 'MaterialDialogContentText',
    MaterialDialogTitle: 'MaterialDialogTitle',
    MaterialDivider: 'MaterialDivider',
    MaterialDrawer: 'MaterialDrawer',
    MaterialDrawerContent: 'MaterialDrawerContent',
    MaterialGrid: 'MaterialGrid',
    MaterialIconButton: 'MaterialIconButton',
    MaterialList: 'MaterialList',
    MaterialListItem: 'MaterialListItem',
    MaterialListItemButton: 'MaterialListItemButton',
    MaterialListItemIcon: 'MaterialListItemIcon',
    MaterialListItemText: 'MaterialListItemText',
    MaterialMenuItem: 'MaterialMenuItem',
    MaterialPaper: 'MaterialPaper',
    MaterialToggleButton: 'MaterialToggleButton',
    MaterialToggleButtonGroup: 'MaterialToggleButtonGroup',
    MaterialToolbar: 'MaterialToolbar',
    MaterialTypography: 'MaterialTypography',
};
export const BASE_COMPONENTS_NAMES = {
    BuilderComponentsFileUploadBase: 'BuilderComponentsFileUploadBase',
    BuilderComponentsFileUploadButtonBase: 'BuilderComponentsFileUploadButtonBase',
};
export const FORM_COMPONENT_DSL_NAMES = {
    BuilderComponentsForm: 'BuilderComponentsForm',
    BuilderComponentsFormFieldArray: 'BuilderComponentsFormFieldArray',
    BuilderComponentsFormAutocomplete: 'BuilderComponentsFormAutocomplete',
    BuilderComponentsFormCheckbox: 'BuilderComponentsFormCheckbox',
    BuilderComponentsFormSwitch: 'BuilderComponentsFormSwitch',
    BuilderComponentsFormSlider: 'BuilderComponentsFormSlider',
    BuilderComponentsFormRadioGroup: 'BuilderComponentsFormRadioGroup',
    BuilderComponentsFormRating: 'BuilderComponentsFormRating',
    BuilderComponentsRadioGroup: 'BuilderComponentsRadioGroup',
    BuilderComponentsFormSelect: 'BuilderComponentsFormSelect',
    BuilderComponentsFormMultiselect: 'BuilderComponentsFormMultiselect',
    BuilderComponentsFormTextField: 'BuilderComponentsFormTextField',
    BuilderComponentsFormFileUpload: 'BuilderComponentsFormFileUpload',
    BuilderComponentsRadio: 'BuilderComponentsRadio',
    BuilderComponentsFormDatePicker: 'BuilderComponentsFormDatePicker',
    BuilderComponentsFormDateTimePicker: 'BuilderComponentsFormDateTimePicker',
    BuilderComponentsFormTimePicker: 'BuilderComponentsFormTimePicker',
    MaterialFormLabel: 'MaterialFormLabel',
    BuilderComponentsFormRichtextEditor: 'BuilderComponentsFormRichtextEditor',
};
export const ROUTING_COMPONENT_DSL_NAMES = {
    BuilderComponentsRoute: 'BuilderComponentsRoute',
    BuilderComponentsRouteForLayout: 'BuilderComponentsRouteForLayout',
    Redirect: 'Redirect',
    RouterSwitch: 'RouterSwitch',
    BuilderComponentsRouteLayout: 'BuilderComponentsRouteLayout',
};
export const HTML_ELEMENTS_DSL_NAMES = {
    a: 'a',
    audio: 'audio',
    blockquote: 'blockquote',
    canvas: 'canvas',
    div: 'div',
    em: 'em',
    form: 'form',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    img: 'img',
    li: 'List Item',
    ol: 'Ordered List',
    p: 'Paragraph',
    pre: 'pre',
    span: 'span',
    strong: 'strong',
    table: 'table',
    tableBody: 'tableBody',
    tableCaption: 'tableCaption',
    tableColumn: 'tableColumn',
    tableColumnGroup: 'tableColumnGroup',
    tableDataCell: 'tableDataCell',
    tableFoot: 'tableFoot',
    tableHead: 'tableHead',
    tableHeader: 'tableHeader',
    tableRow: 'tableRow',
    ul: 'Unordered List',
    video: 'video',
};
export const BASIC_ELEMENTS_DSL_NAMES = {
    hyperlink: 'hyperlink',
};
export const UTILS_DSL_NAMES = {
    Text: 'Text',
    BuilderRichText: 'BuilderRichText',
    Iterator: 'Iterator',
    Fragment: 'Fragment',
    AsyncContent: 'AsyncContent',
};
export const SYMBOL_DSL_NAMES = {
    AccordionSymbol: 'AccordionSymbol',
    AlertSymbol: 'AlertSymbol',
    GridContainerSymbol: 'GridContainerSymbol',
    GridItemSymbol: 'GridItemSymbol',
    LocalDialogSymbol: 'LocalDialogSymbol',
    DialogSymbol: 'DialogSymbol',
    ListItemIconSymbol: 'ListItemIconSymbol',
    ListItemSymbol: 'ListItemSymbol',
    AppBarSymbol: 'AppBarSymbol',
    HeadingSymbol: 'HeadingSymbol',
    CardSymbol: 'CardSymbol',
    RouterSwitchSymbol: 'RouterSwitchSymbol',
    BaseLayout: 'BaseLayout',
    PageLayout: 'PageLayout',
    MobileLayout: 'MobileLayout',
    BasePageLayout: 'BasePageLayout',
    RootLayoutsContainer: 'RootLayoutsContainer',
    NavListItemSymbol: 'NavListItemSymbol',
    root: 'root',
    FileDropZoneSymbol: 'FileDropZoneSymbol',
    FileDropZoneBaseSymbol: 'FileDropZoneBaseSymbol',
};
export const COMPONENT_DSL_NAMES = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, UTILS_DSL_NAMES), SYMBOL_DSL_NAMES), MATERIAL_COMPONENT_DSL_NAMES), FORM_COMPONENT_DSL_NAMES), ROUTING_COMPONENT_DSL_NAMES), HTML_ELEMENTS_DSL_NAMES), BASIC_ELEMENTS_DSL_NAMES), BASE_COMPONENTS_NAMES);
export const MENU_GROUP_NAMES = {
    basic: 'basic',
    common: 'common',
    controls: 'controls',
    data: 'data',
    form: 'form',
    html: 'html',
    layout: 'layout',
    other: 'other',
};
export const TYPE_DEFINITION_TYPES = {
    import: 'import',
    system: 'system',
};
export const LAYOUT_MODES = {
    allPageNodes: 'all-page-nodes',
    layoutNodes: 'layout-nodes',
    currentRouteNodes: 'current-route-nodes',
    hiddenCanvasNodes: 'hidden-canvas-nodes',
};
export const COMPONENT_LIST_MODE = {
    page: 'page',
    layout: 'layout',
};
