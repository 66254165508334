import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { STRONG_SETTINGS } from './strong.settings';
export const strong = {
    name: COMPONENT_DSL_NAMES.strong,
    displayName: COMPONENT_DSL_NAMES.strong,
    type: COMPONENT_DSL_TYPES.htmlElement,
    source: {
        htmlElement: 'strong',
    },
    schema: {
        dndTargetPropName: 'children',
        props: Object.assign({}, commonComponentProps),
    },
    settings: STRONG_SETTINGS,
};
