import React, { useMemo, useState, useEffect } from 'react';

import { useFormikContext } from 'formik';

import {
  ResourceBackendEightBaseWithWorkspaceDSL,
  ResourceGraphqlDSL,
  ResourceRestDSL,
} from '@builder/schemas';
import { processEnv, useBoolState } from '@builder/utils';

import { InputContainerProps, InputContainer } from '../common';
import { EditorFormDialog } from '../EditorFormDialog';
import { MonacoEditorProps } from '../MonacoEditor';
import { MonacoGraphqlEditor } from '../MonacoEditor/MonacoGraphqlEditor';
import { CssEditorOpenButton as EditorOpenButton } from 'src/features/node-settings/setting-views/CSSViewEditor/CSSViewEditor';
import { StateRestDSLFormValues } from 'src/features/requests-manager/common/types';
import { EditorContainer } from 'src/features/requests-manager/components/sections/RequestTransformersSection';
import { useGqlSchema, useUIBuilderLeftPanel } from 'src/providers';

export type GraphQLEditorProps = Omit<InputContainerProps, 'isFxEnabled' | 'enableFx'> &
  MonacoEditorProps & {
    resource?: ResourceBackendEightBaseWithWorkspaceDSL | ResourceRestDSL | ResourceGraphqlDSL;
  };

export const GraphQLEditor: React.FC<GraphQLEditorProps> = ({
  value,
  label,
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
  height,
  resource,
  'data-test': dataTest,
  ...rest
}) => {
  const { schema } = useGqlSchema();
  const [editorValue, setEditorValue] = useState(value);
  const [dialogValue, setDialogValue] = useState(editorValue);
  const [isDialogOpened, { setTrue: openDialog, setFalse: closeDialog }] = useBoolState();
  const { setFieldValue } = useFormikContext<StateRestDSLFormValues>();
  const { scope } = useUIBuilderLeftPanel();

  const options = useMemo(
    () =>
      ({
        minimap: {
          enabled: false,
        },
        scrollbar: {
          horizontalScrollbarSize: 4,
          verticalScrollbarSize: 4,
        },

        // the following options is necessary for e2e tests
        // please keep it in the mind if you want to change them
        autoClosingBrackets: 'never',
        // the env flag is supposed to be used only in e2e
        wordBasedSuggestions: !processEnv.getSkipAuthCheckSession(),
      } as const),
    [],
  );

  const submitForm = () => {
    setFieldValue('args.body', dialogValue);
    setEditorValue(dialogValue);
    closeDialog();
  };

  // Updating the Editor Value when the value property changes
  useEffect(
    function updateEditorValue() {
      setEditorValue(value);
    },
    [value],
  );

  return (
    <InputContainer
      label={label}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      data-test={dataTest}
    >
      <EditorContainer>
        <EditorOpenButton
          onClick={() => {
            setDialogValue(value);
            openDialog();
          }}
        />
        <MonacoGraphqlEditor
          language="graphql"
          gqlSchema={schema}
          height={height || '240px'}
          options={options}
          defaultValue={value}
          value={editorValue}
          data-test={dataTest}
          resource={resource}
          editorScope={scope as string}
          {...rest}
        />
      </EditorContainer>
      <EditorFormDialog
        isDialogOpened={isDialogOpened}
        onDialogClose={closeDialog}
        title={label as string}
        formProps={{
          onSubmit: submitForm,
          initialValues: { graphQL: editorValue },
        }}
      >
        <MonacoGraphqlEditor
          language="graphql"
          gqlSchema={schema}
          height={height || '240px'}
          options={options}
          defaultValue={value}
          value={dialogValue}
          data-test={dataTest}
          resource={resource}
          {...rest}
          onChange={e => {
            setDialogValue(e);
          }}
        />
      </EditorFormDialog>
    </InputContainer>
  );
};
