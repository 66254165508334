import { isNil } from 'ramda';
import { RuntimePropExecutor } from '../../RuntimePropExecutor';
import { NodeErrorGetter } from '../audit-message-getters';
const defaultOnAppAuditNotifications = (appAuditNotifications) => appAuditNotifications.forEach(console.warn);
/**
 * Transforms properties from JS code and returns an array without undefined\null properties
 * @nodePropValuesArray array of properties from nodes
 * @return array of NodePropValue values like nodePropValuesArray
 * */
export const transformPropsFromJsCode = ({ nodePropValuesArray, propPath, externalState, nodeDSL, onAppAuditNotifications = defaultOnAppAuditNotifications, }) => {
    const errorGetter = new NodeErrorGetter(nodeDSL, onAppAuditNotifications);
    const runtimePropExecutor = new RuntimePropExecutor({ errorGetter });
    const transformedJsCodeProps = nodePropValuesArray
        .map(prop => {
        const isJsCode = runtimePropExecutor.hasPropJsCode(prop);
        if (isJsCode) {
            const transformedProp = runtimePropExecutor.transformPropJsCode({
                propValue: prop,
                propPath,
                externalState,
            });
            return transformedProp;
        }
        return prop;
    })
        .filter(prop => !isNil(prop));
    return transformedJsCodeProps;
};
