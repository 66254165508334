import { COMPONENT_SETTING_TYPES } from '../../../constants';
import { getActionSettings, getBasicPropsSettings } from '../../common';
import { getDivider } from '../../common/settings';
export const MaterialAccordionSettings = {
    properties: [...getBasicPropsSettings([], true), getDivider()],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
