var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ApolloProvider, gql, useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import * as filestack from 'filestack-js';
import { getApolloClient } from './utils';
export const FILE_SELECT_UPLOAD_INFO_MUTATION = gql `
  mutation createFile($file: FileCreateInput!) {
    fileCreate(data: $file) {
      id
    }
  }
`;
export const FILE_SELECT_UPLOAD_INFO_QUERY = gql `
  query FileUploadInfo {
    fileUploadInfo {
      policy
      signature
      apiKey
      path
    }
  }
`;
const ButtonFileUpload = ({ ref, textButton, onSuccess, onError, uploadOnChange, maxFileSize, setMethods, onChange, rest, }) => {
    const [fileStackClient, setFileStackClient] = useState();
    const [uploadedFile, setUploadedFile] = useState({
        filename: '',
        fileId: '',
    });
    const [progress, setProgress] = useState(0);
    const { data: fileUploadInfo } = useQuery(FILE_SELECT_UPLOAD_INFO_QUERY, {
        onCompleted(data) {
            const { apiKey, policy, signature } = data.fileUploadInfo;
            setFileStackClient(filestack.init(apiKey, {
                security: {
                    policy,
                    signature,
                },
                sessionCache: false,
            }));
        },
        onError() {
            setFileStackClient(null);
        },
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [addFile] = useMutation(FILE_SELECT_UPLOAD_INFO_MUTATION);
    const isFileExceedingFileSize = useCallback((file) => {
        if (!maxFileSize)
            return false;
        const isExceedingSize = file.size > maxFileSize * 1024 * 1024;
        // eslint-disable-next-line no-alert
        isExceedingSize && alert('File exceeded max file size');
        return isExceedingSize;
    }, [maxFileSize]);
    const uploadFile = useCallback((files) => __awaiter(void 0, void 0, void 0, function* () {
        if (!files)
            return;
        if (isFileExceedingFileSize(files[0])) {
            onError && onError(new Error('File exceeded max file size'));
            return;
        }
        setProgress(0);
        try {
            const response = yield (fileStackClient === null || fileStackClient === void 0 ? void 0 : fileStackClient.upload(files[0], {
                onProgress: evt => {
                    setProgress(evt.totalPercent);
                },
            }, {
                path: fileUploadInfo === null || fileUploadInfo === void 0 ? void 0 : fileUploadInfo.fileUploadInfo.path,
            }));
            yield addFile({
                variables: {
                    file: { filename: response.filename, fileId: response.handle },
                },
            });
            setUploadedFile({ filename: response.filename, fileId: response.handle });
            onSuccess && onSuccess();
        }
        catch (e) {
            const errorMsg = new Error(JSON.stringify(e));
            onError && onError(errorMsg);
            setProgress(0);
        }
    }), [
        addFile,
        fileStackClient,
        fileUploadInfo === null || fileUploadInfo === void 0 ? void 0 : fileUploadInfo.fileUploadInfo.path,
        isFileExceedingFileSize,
        onError,
        onSuccess,
    ]);
    const inputRef = useRef(null);
    useEffect(() => {
        setMethods((prev) => (Object.assign(Object.assign({}, prev), { fileUploaded: uploadedFile, progress, upload: () => uploadFile(selectedFile) })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, progress, uploadedFile]);
    if (!fileStackClient) {
        return (_jsx(Button, Object.assign({ fullWidth: true, size: "medium", disabled: true }, rest, { children: _jsx("div", Object.assign({ style: { width: '100%' } }, { children: textButton })) })));
    }
    const handleButtonClick = () => {
        var _a;
        // click the hidden input element
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (_jsx(Button, Object.assign({ fullWidth: true, size: "medium" }, rest, { onClick: handleButtonClick }, { children: _jsxs("div", Object.assign({ style: { width: '100%' } }, { children: [_jsx("input", Object.assign({ type: "file", onChange: uploadOnChange
                        ? e => {
                            uploadFile(e.target.files);
                            if (onChange !== undefined) {
                                onChange(e.target.files);
                            }
                        }
                        : e => setSelectedFile(e.target.files), ref: inputRef }, rest, { style: { display: 'none' } })), textButton] })) })));
};
export const FileUploadButtonBase = forwardRef((_a, ref) => {
    var { textButton, onSuccess, onError, uploadOnChange, maxFileSize, workspaceId, setMethods, value, apiToken, onChange } = _a, rest = __rest(_a, ["textButton", "onSuccess", "onError", "uploadOnChange", "maxFileSize", "workspaceId", "setMethods", "value", "apiToken", "onChange"]);
    const client = useMemo(() => {
        return getApolloClient(workspaceId, apiToken);
    }, [apiToken, workspaceId]);
    return (_jsx(ApolloProvider, Object.assign({ client: client }, { children: _jsx(ButtonFileUpload, { textButton: textButton, setMethods: setMethods, ref: ref, onSuccess: onSuccess, uploadOnChange: uploadOnChange, onChange: onChange, onError: onError, maxFileSize: maxFileSize, rest: rest }) })));
});
