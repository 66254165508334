import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, MENU_GROUP_NAMES, COMPONENT_DSL_PROP_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { commonComponentProps } from '../../common/commonProps';
import { UL_SETTINGS } from './ul.settings';
export const ul = {
    name: COMPONENT_DSL_NAMES.ul,
    displayName: COMPONENT_DSL_NAMES.ul,
    icon: 'unorderedList',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'ul',
    },
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: getPredefinedStyles({ minWidth: 30, minHeight: 30 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.li],
            } }),
    },
    settings: UL_SETTINGS,
};
