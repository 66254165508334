import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Logo8baseAppBuilderHorizontal } from './icons/8baseLogoAppBuilderHorizontal.svg';
import { ReactComponent as Logo8baseAppBuilderVertical } from './icons/8baseLogoAppBuilderVertical.svg';
import { ReactComponent as Logo8baseFull } from './icons/8baseLogoFull.svg';
import { ReactComponent as Logo8baseSmall } from './icons/8baseLogoSmall.svg';
import { ReactComponent as AccordionIcon } from './icons/accordion.svg';
import { ReactComponent as AddIcon } from './icons/add.svg';
import { ReactComponent as AlertIcon } from './icons/alert.svg';
import { ReactComponent as ArrowBackIcon } from './icons/arrowBack.svg';
import { ReactComponent as AssetsIcon } from './icons/assets.svg';
import { ReactComponent as AsyncContent } from './icons/asyncContent.svg';
import { ReactComponent as AudioIcon } from './icons/audio.svg';
import { ReactComponent as AutoCompleteIcon } from './icons/autoComplete.svg';
import { ReactComponent as AvatarIcon } from './icons/avatar.svg';
import { ReactComponent as Backend } from './icons/backend.svg';
import { ReactComponent as Badge } from './icons/badge.svg';
import { ReactComponent as Breadcrumbs } from './icons/breadcrumbs.svg';
import { ReactComponent as ButtonIcon } from './icons/button.svg';
import { ReactComponent as ButtonGroupIcon } from './icons/buttongroup.svg';
import { ReactComponent as CanvasIcon } from './icons/canvas.svg';
import { ReactComponent as Card } from './icons/card.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as CheckboxIcon } from './icons/checkbox.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevronDown.svg';
import { ReactComponent as ChevronRightIcon } from './icons/chevronRight.svg';
import { ReactComponent as ChevronTopIcon } from './icons/chevronTop.svg';
import { ReactComponent as Chip } from './icons/chip.svg';
import { ReactComponent as ContainerIcon } from './icons/container.svg';
import { ReactComponent as CrossIcon } from './icons/cross.svg';
import { ReactComponent as CustomGroupIcon } from './icons/customGroup.svg';
import { ReactComponent as DatePickerIcon } from './icons/datePicker.svg';
import { ReactComponent as DateTimeIcon } from './icons/dateTimePicker.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { ReactComponent as DeployIcon } from './icons/deployRocket.svg';
import { ReactComponent as DisplayBlockIcon } from './icons/displayBlock.svg';
import { ReactComponent as DisplayFlexIcon } from './icons/displayFlex.svg';
import { ReactComponent as DisplayGridIcon } from './icons/displayGrid.svg';
import { ReactComponent as DisplayInlineIcon } from './icons/displayInline.svg';
import { ReactComponent as DisplayInlineBlockIcon } from './icons/displayInlineBlock.svg';
import { ReactComponent as Divider } from './icons/divider.svg';
import { ReactComponent as DownIcon } from './icons/down.svg';
import { ReactComponent as DragIndicatorIcon } from './icons/dragIndicator.svg';
import { ReactComponent as Drawer } from './icons/drawer.svg';
import { ReactComponent as EventsIcon } from './icons/eventIcon.svg';
import { ReactComponent as FileDropZoneIcon } from './icons/fileDropZone.svg';
import { ReactComponent as FileDropZoneBaseIcon } from './icons/fileDropZoneBase.svg';
import { ReactComponent as FileUploadIcon } from './icons/fileUpload.svg';
import { ReactComponent as fileUploadBaseIcon } from './icons/fileUploadBase.svg';
import { ReactComponent as FileUploadButtonIcon } from './icons/fileUploadButton.svg';
import { ReactComponent as FileUploadButtonBaseIcon } from './icons/fileUploadButtonBase.svg';
import { ReactComponent as FloatingActionButton } from './icons/floatingActionButton.svg';
import { ReactComponent as FolderOpenIcon } from './icons/folderOpen.svg';
import { ReactComponent as Footer } from './icons/footer.svg';
import { ReactComponent as FormAutocompleteIcon } from './icons/formAutocomplete.svg';
import { ReactComponent as FormBlockIcon } from './icons/formBlock.svg';
import { ReactComponent as FormCheckboxIcon } from './icons/formCheckbox.svg';
import { ReactComponent as FormDatePicker } from './icons/formDatePicker.svg';
import { ReactComponent as FormDateTimePicker } from './icons/formDateTimePicker.svg';
import { ReactComponent as FormEditableText } from './icons/formEditableText.svg';
import { ReactComponent as FormFieldArrayIcon } from './icons/formFieldArray.svg';
import { ReactComponent as FormFileUpload } from './icons/formFileUpload.svg';
import { ReactComponent as FormMultiselect } from './icons/formMultiselect.svg';
import { ReactComponent as FormSelector } from './icons/formnSelector.svg';
import { ReactComponent as FormSliderIcon } from './icons/formSlider.svg';
import { ReactComponent as FormSwitchIcon } from './icons/formSwitch.svg';
import { ReactComponent as FormTextfield } from './icons/formTextfield.svg';
import { ReactComponent as FormTimePicker } from './icons/formTimePicker.svg';
import { ReactComponent as FunctionIcon } from './icons/function.svg';
import { ReactComponent as GraphQLIcon } from './icons/graphQL.svg';
import { ReactComponent as GridIcon } from './icons/grid.svg';
import { ReactComponent as HamburgerIcon } from './icons/hamburger.svg';
import { ReactComponent as HamburgerOpenIcon } from './icons/hamburgerOpen.svg';
import { ReactComponent as HeaderIcon } from './icons/header.svg';
import { ReactComponent as HeadingIcon } from './icons/heading.svg';
import { ReactComponent as HelpIcon } from './icons/help.svg';
import { ReactComponent as HTMLIcon } from './icons/html.svg';
import { ReactComponent as HTMLTable } from './icons/htmlTable.svg';
import { ReactComponent as HTMLTableBody } from './icons/htmlTableBody.svg';
import { ReactComponent as HTMLTableCaption } from './icons/htmlTableCaption.svg';
import { ReactComponent as HTMLTableColumn } from './icons/htmlTableColumn.svg';
import { ReactComponent as HTMLTableColumnGroup } from './icons/htmlTableColumnGroup.svg';
import { ReactComponent as HTMLTableDataCell } from './icons/htmlTableDataCell.svg';
import { ReactComponent as HTMLTableFoot } from './icons/htmlTableFoot.svg';
import { ReactComponent as HTMLTableHead } from './icons/htmlTableHead.svg';
import { ReactComponent as HTMLTableHeader } from './icons/htmlTableHeader.svg';
import { ReactComponent as HTMLTableRow } from './icons/htmlTableRow.svg';
import { ReactComponent as HyperLinkIcon } from './icons/hyperlink.svg';
import { ReactComponent as IconIcon } from './icons/icon.svg';
import { ReactComponent as IconButton } from './icons/iconButton.svg';
import { ReactComponent as ImageWhiteIcon } from './icons/image-white.svg';
import { ReactComponent as ImageIcon } from './icons/image.svg';
import { ReactComponent as InputIcon } from './icons/input.svg';
import { ReactComponent as InsertDriveFileOutlined } from './icons/insertDriveFileOutlined.svg';
import { ReactComponent as LaptopIcon } from './icons/laptop.svg';
import { ReactComponent as LeftPanel } from './icons/leftPanel.svg';
import { ReactComponent as LightningIcon } from './icons/lightning.svg';
import { ReactComponent as LinkIcon } from './icons/link.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as ListItemIcon } from './icons/listItem.svg';
import { ReactComponent as ListItemHtml } from './icons/listItemHtml.svg';
import { ReactComponent as LocalDialogIcon } from './icons/localDialog.svg';
import { ReactComponent as LodashIcon } from './icons/lodash.svg';
import { ReactComponent as LooperIcon } from './icons/looper.svg';
import { ReactComponent as LuxonIcon } from './icons/luxon.svg';
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as MathJsIcon } from './icons/mathjs.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as MetaIcon } from './icons/meta.svg';
import { ReactComponent as MomentJsIcon } from './icons/momentjs.svg';
import { ReactComponent as MultiselectIcon } from './icons/multiSelect.svg';
import { ReactComponent as NavigatorIcon } from './icons/navigator.svg';
import { ReactComponent as NavListItem } from './icons/navListItem.svg';
import { ReactComponent as NotificationIcon } from './icons/notifications.svg';
import { ReactComponent as OpenComponent } from './icons/openComponent.svg';
import { ReactComponent as OrderedList } from './icons/orderedList.svg';
import { ReactComponent as PageLayoutIcon } from './icons/pageLayout.svg';
import { ReactComponent as PageSwitcherIcon } from './icons/pageSwitcher.svg';
import { ReactComponent as PaletteOutlined } from './icons/palette.svg';
import { ReactComponent as PaperIcon } from './icons/paper.svg';
import { ReactComponent as ParagraphIcon } from './icons/paragraph.svg';
import { ReactComponent as PenIcon } from './icons/pen.svg';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as Popper } from './icons/popper.svg';
import { ReactComponent as QueryGraphql } from './icons/queryGraphql.svg';
import { ReactComponent as QueryRest } from './icons/queryRest.svg';
import { ReactComponent as Quote } from './icons/quote.svg';
import { ReactComponent as RadioButtonIcon } from './icons/radioButton.svg';
import { ReactComponent as RadioGroupIcon } from './icons/radioGroup.svg';
import { ReactComponent as RatingIcon } from './icons/rating.svg';
import { ReactComponent as RedoIcon } from './icons/redo.svg';
import { ReactComponent as RequestsIcon } from './icons/requests.svg';
import { ReactComponent as RestIcon } from './icons/rest.svg';
import { ReactComponent as RestLightIcon } from './icons/restLight.svg';
import { ReactComponent as RichtextEditor } from './icons/richtexteditor.svg';
import { ReactComponent as RocketIcon } from './icons/rocket.svg';
import { ReactComponent as RouteIcon } from './icons/route.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as SectionBlock } from './icons/section-block.svg';
import { ReactComponent as SectionInline } from './icons/section-inline.svg';
import { ReactComponent as SelectIcon } from './icons/select.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import { ReactComponent as SettingsFiltersIcon } from './icons/settingsFilters.svg';
import { ReactComponent as Share } from './icons/share.svg';
import { ReactComponent as SliderIcon } from './icons/slider.svg';
import { ReactComponent as Snackbar } from './icons/snackbar.svg';
import { ReactComponent as SpaceDashboard } from './icons/spaceDashboard.svg';
import { ReactComponent as SpeedDialIcon } from './icons/speedDial.svg';
import { ReactComponent as StateIcon } from './icons/state.svg';
import { ReactComponent as StyleAlignBaseline } from './icons/styleAlignBaseline.svg';
import { ReactComponent as StyleAlignCenter } from './icons/styleAlignCenter.svg';
import { ReactComponent as StyleAlignEnd } from './icons/styleAlignEnd.svg';
import { ReactComponent as StyleAlignStart } from './icons/styleAlignStart.svg';
import { ReactComponent as StyleAlignStretch } from './icons/styleAlignStretch.svg';
import { ReactComponent as StyleArrowDown } from './icons/styleArrowDown.svg';
import { ReactComponent as StyleArrowLeft } from './icons/styleArrowLeft.svg';
import { ReactComponent as StyleArrowRight } from './icons/styleArrowRight.svg';
import { ReactComponent as StyleArrowUp } from './icons/styleArrowUp.svg';
import { ReactComponent as StyleAuto } from './icons/styleAuto.svg';
import { ReactComponent as StyleBorderBottom } from './icons/styleBorderBottom.svg';
import { ReactComponent as StyleBorderLeft } from './icons/styleBorderLeft.svg';
import { ReactComponent as StyleBorderRadiusSeparateValues } from './icons/styleBorderRadiusSeparateValues.svg';
import { ReactComponent as StyleBorderRadiusSingleValue } from './icons/styleBorderRadiusSingleValue.svg';
import { ReactComponent as StyleBorderRight } from './icons/styleBorderRight.svg';
import { ReactComponent as StyleBorderSeparateValues } from './icons/styleBorderSeparateValues.svg';
import { ReactComponent as StyleBorderSingleValue } from './icons/styleBorderSingleValue.svg';
import { ReactComponent as StyleBorderStyleDashed } from './icons/styleBorderStyleDashed.svg';
import { ReactComponent as StyleBorderStyleDotted } from './icons/styleBorderStyleDotted.svg';
import { ReactComponent as StyleBorderStyleSolid } from './icons/styleBorderStyleSolid.svg';
import { ReactComponent as StyleBorderTop } from './icons/styleBorderTop.svg';
import { ReactComponent as StyleBottomLeftCorner } from './icons/styleBottomLeftCorner.svg';
import { ReactComponent as StyleBottomRightCorner } from './icons/styleBottomRightCorner.svg';
import { ReactComponent as StyleFontItalic } from './icons/styleFontItalic.svg';
import { ReactComponent as StyleFontNormal } from './icons/styleFontNormal.svg';
import { ReactComponent as StyleJustifyAround } from './icons/styleJustifyAround.svg';
import { ReactComponent as StyleJustifyBetween } from './icons/styleJustifyBetween.svg';
import { ReactComponent as StyleJustifyCenter } from './icons/styleJustifyCenter.svg';
import { ReactComponent as StyleJustifyEnd } from './icons/styleJustifyEnd.svg';
import { ReactComponent as StyleJustifyStart } from './icons/styleJustifyStart.svg';
import { ReactComponent as StyleScroll } from './icons/styleScroll.svg';
import { ReactComponent as StyleTextAlignCenter } from './icons/styleTextAlignCenter.svg';
import { ReactComponent as StyleTextAlignJustify } from './icons/styleTextAlignJustify.svg';
import { ReactComponent as StyleTextAlignLeft } from './icons/styleTextAlignLeft.svg';
import { ReactComponent as StyleTextAlignRight } from './icons/styleTextAlignRight.svg';
import { ReactComponent as StyleTextDecorationCapitalize } from './icons/styleTextDecorationCapitalize.svg';
import { ReactComponent as StyleTextDecorationLowercase } from './icons/styleTextDecorationLowercase.svg';
import { ReactComponent as StyleTextDecorationOverline } from './icons/styleTextDecorationOverline.svg';
import { ReactComponent as StyleTextDecorationStrike } from './icons/styleTextDecorationStrike.svg';
import { ReactComponent as StyleTextDecorationUnderline } from './icons/styleTextDecorationUnderline.svg';
import { ReactComponent as StyleTextDirectionLtr } from './icons/styleTextDirectionLtr.svg';
import { ReactComponent as StyleTextDirectionRtl } from './icons/styleTextDirectionRtl.svg';
import { ReactComponent as StyleTextTransformUppercase } from './icons/styleTextTransformUppercase.svg';
import { ReactComponent as StyleTopLeftCorner } from './icons/styleTopLeftCorner.svg';
import { ReactComponent as StyleTopRightCorner } from './icons/styleTopRightCorner.svg';
import { ReactComponent as SwitchIcon } from './icons/switch.svg';
import { ReactComponent as TabGroupIcon } from './icons/tabGroup.svg';
import { ReactComponent as TableIcon } from './icons/table.svg';
import { ReactComponent as TabIcon } from './icons/tabs.svg';
import { ReactComponent as TextIcon } from './icons/text.svg';
import { ReactComponent as ThemeIcon } from './icons/theme.svg';
import { ReactComponent as TimePickerIcon } from './icons/timepicker.svg';
import { ReactComponent as Toggle } from './icons/toggle.svg';
import { ReactComponent as ToggleGroup } from './icons/toggleGroup.svg';
import { ReactComponent as Tooltip } from './icons/tooltip.svg';
import { ReactComponent as Typography } from './icons/typography.svg';
import { ReactComponent as UiAntd } from './icons/uiAntd.svg';
import { ReactComponent as UiBootstrap } from './icons/uiBootstrap.svg';
import { ReactComponent as UiBulma } from './icons/uiBulma.svg';
import { ReactComponent as UiMaterial } from './icons/uiMaterial.svg';
import { ReactComponent as UiSemantic } from './icons/uiSemantic.svg';
import { ReactComponent as UndoIcon } from './icons/undo.svg';
import { ReactComponent as UnfoldLessIcon } from './icons/unfoldLess.svg';
import { ReactComponent as UnfoldMoreIcon } from './icons/unfoldMore.svg';
import { ReactComponent as UnorderList } from './icons/unorderedList.svg';
import { ReactComponent as VideoIcon } from './icons/video.svg';
import { ReactComponent as ViewQuilt } from './icons/viewQuilt.svg';
import { ReactComponent as VisibilityOff } from './icons/visibilityOff.svg';
import { ReactComponent as VisibilityOn } from './icons/visibilityOn.svg';

export const ICON_NAMES = {
  // Keep this list alphabetized
  accordion: 'accordion',
  add: 'add',
  alert: 'alert',
  arrowBack: 'arrowBack',
  assets: 'assets',
  asyncContent: 'asyncContent',
  audio: 'audio',
  autoComplete: 'autoComplete',
  avatar: 'avatar',
  backend: 'backend',
  badge: 'badge',
  breadcrumbs: 'breadcrumbs',
  button: 'button',
  buttonGroup: 'buttonGroup',
  canvas: 'canvas',
  card: 'card',
  check: 'check',
  checkbox: 'checkbox',
  chevronDown: 'chevronDown',
  chevronRight: 'chevronRight',
  chevronTop: 'chevronTop',
  chip: 'chip',
  container: 'container',
  cross: 'cross',
  customGroup: 'customGroup',
  datePicker: 'datePicker',
  dateTimePicker: 'dateTimePicker',
  delete: 'delete',
  deploy: 'deploy',
  displayBlock: 'displayBlock',
  displayFlex: 'displayFlex',
  displayGrid: 'displayGrid',
  displayInline: 'displayInline',
  displayInlineBlock: 'displayInlineBlock',
  divider: 'divider',
  down: 'down',
  dragIndicator: 'dragIndicator',
  drawer: 'drawer',
  events: 'events',
  FileDropZone: 'FileDropZone',
  FileDropZoneBase: 'FileDropZoneBase',
  fileUpload: 'fileUpload',
  fileUploadBase: 'fileUploadBase',
  fileUploadButton: 'fileUploadButton',
  fileUploadButtonBase: 'fileUploadButtonBase',
  floatingActionButton: 'floatingActionButton',
  folderOpen: 'folderOpen',
  footer: 'footer',
  formAutocomplete: 'formAutocomplete',
  formBlock: 'formBlock',
  formCheckbox: 'formCheckbox',
  formDatePicker: 'formDatePicker',
  formDateTimePicker: 'formDateTimePicker',
  formEditableText: 'formEditableText',
  formFieldArray: 'formFieldArray',
  formFileUpload: 'formFileUpload',
  formMultiselect: 'formMultiselect',
  formnSelector: 'formnSelector',
  formSlider: 'formSlider',
  formSwitch: 'formSwitch',
  formTextfield: 'formTextfield',
  formTimePicker: 'formTimePicker',
  function: 'function',
  graphQL: 'graphQL',
  grid: 'grid',
  hamburger: 'hamburger',
  hamburgerOpen: 'hamburgerOpen',
  header: 'header',
  heading: 'heading',
  help: 'help',
  html: 'html',
  htmlTable: 'htmlTable',
  htmlTableBody: 'htmlTableBody',
  htmlTableCaption: 'htmlTableCaption',
  htmlTableColumn: 'htmlTableColumn',
  htmlTableColumnGroup: 'htmlTableColumnGroup',
  htmlTableDataCell: 'htmlTableDataCell',
  htmlTableFoot: 'htmlTableFoot',
  htmlTableHead: 'htmlTableHead',
  htmlTableHeader: 'htmlTableHeader',
  htmlTableRow: 'htmlTableRow',
  hyperLink: 'hyperLink',
  icon: 'icon',
  iconButton: 'iconButton',
  image: 'image',
  imageWhite: 'imageWhite',
  input: 'input',
  insertDriveFileOutlined: 'insertDriveFileOutlined',
  laptop: 'laptop',
  leftPanel: 'leftPanel',
  lightning: 'lightning',
  link: 'link',
  list: 'list',
  listItem: 'listItem',
  listItemHtml: 'listItemHtml',
  localDialog: 'localDialog',
  lodash: 'lodash',
  logo8baseAppBuilderHorizontal: 'logo8baseAppBuilderHorizontal',
  logo8baseAppBuilderVertical: 'logo8baseAppBuilderVertical',
  logo8baseFull: 'logo8baseFull',
  logo8baseSmall: 'logo8baseSmall',
  looper: 'looper',
  luxon: 'luxon',
  map: 'map',
  mathjs: 'mathjs',
  menu: 'menu',
  meta: 'meta',
  moment: 'moment',
  multiSelect: 'multiSelect',
  navigator: 'navigator',
  navListItem: 'navListItem',
  notification: 'notification',
  openComponent: 'openComponent',
  orderedList: 'orderedList',
  pageLayout: 'pageLayout',
  pageSwitcher: 'pageSwitcher',
  palette: 'palette',
  paper: 'paper',
  paragraph: 'paragraph',
  pen: 'pen',
  play: 'play',
  popper: 'popper',
  queryGraphql: 'queryGraphql',
  queryRest: 'queryRest',
  quote: 'quote',
  radioButton: 'radioButton',
  radioGroup: 'radioGroup',
  rating: 'rating',
  redo: 'redo',
  requests: 'requests',
  rest: 'rest',
  restLight: 'restLight',
  richtexteditor: 'richtexteditor',
  rocket: 'rocket',
  route: 'route',
  search: 'search',
  sectionBlock: 'sectionBlock',
  sectionInline: 'sectionInline',
  select: 'select',
  settings: 'settings',
  settingsFilters: 'settingsFilters',
  share: 'share',
  slider: 'slider',
  snackbar: 'snackbar',
  spaceDashboard: 'spaceDashboard',
  speedDial: 'speedDial',
  state: 'state',
  styleAlignBaseline: 'styleAlignBaseline',
  styleAlignCenter: 'styleAlignCenter',
  styleAlignEnd: 'styleAlignEnd',
  styleAlignStart: 'styleAlignStart',
  styleAlignStretch: 'styleAlignStretch',
  styleArrowDown: 'styleArrowDown',
  styleArrowLeft: 'styleArrowLeft',
  styleArrowRight: 'styleArrowRight',
  styleArrowUp: 'styleArrowUp',
  styleAuto: 'styleAuto',
  styleBorderBottom: 'styleBorderBottom',
  styleBorderLeft: 'styleBorderLeft',
  styleBorderRadiusSeparateValues: 'styleBorderRadiusSeparateValues',
  styleBorderRadiusSingleValue: 'styleBorderRadiusSingleValue',
  styleBorderRight: 'styleBorderRight',
  styleBorderSeparateValues: 'styleBorderSeparateValues',
  styleBorderSingleValue: 'styleBorderSingleValue',
  styleBorderStyleDashed: 'styleBorderStyleDashed',
  styleBorderStyleDotted: 'styleBorderStyleDotted',
  styleBorderStyleSolid: 'styleBorderStyleSolid',
  styleBorderTop: 'styleBorderTop',
  styleBottomLeftCorner: 'styleBottomLeftCorner',
  styleBottomRightCorner: 'styleBottomRightCorner',
  styleFontItalic: 'styleFontItalic',
  styleFontNormal: 'styleFontNormal',
  styleJustifyAround: 'styleJustifyAround',
  styleJustifyBetween: 'styleJustifyBetween',
  styleJustifyCenter: 'styleJustifyCenter',
  styleJustifyEnd: 'styleJustifyEnd',
  styleJustifyStart: 'styleJustifyStart',
  styleScroll: 'styleScroll',
  styleTextAlignCenter: 'styleTextAlignCenter',
  styleTextAlignJustify: 'styleTextAlignJustify',
  styleTextAlignLeft: 'styleTextAlignLeft',
  styleTextAlignRight: 'styleTextAlignRight',
  styleTextDecorationCapitalize: 'styleTextDecorationCapitalize',
  styleTextDecorationLowercase: 'styleTextDecorationLowercase',
  styleTextDecorationOverline: 'styleTextDecorationOverline',
  styleTextDecorationStrike: 'styleTextDecorationStrike',
  styleTextDecorationUnderline: 'styleTextDecorationUnderline',
  styleTextDirectionLtr: 'styleTextDirectionLtr',
  styleTextDirectionRtl: 'styleTextDirectionRtl',
  styleTextTransformUppercase: 'styleTextTransformUppercase',
  styleTopLeftCorner: 'styleTopLeftCorner',
  styleTopRightCorner: 'styleTopRightCorner',
  switch: 'switch',
  tab: 'tab',
  tabGroup: 'tabGroup',
  table: 'table',
  text: 'text',
  theme: 'theme',
  timepicker: 'timepicker',
  toggle: 'toggle',
  toggleGroup: 'toggleGroup',
  tooltip: 'tooltip',
  typography: 'typography',
  uiAntd: 'uiAntd',
  uiBootstrap: 'uiBootstrap',
  uiBulma: 'uiBulma',
  uiMaterial: 'uiMaterial',
  uiSemantic: 'uiSemantic',
  undo: 'undo',
  unfoldLess: 'unfoldLess',
  unfoldMore: 'unfoldMore',
  unorderedList: 'unorderedList',
  video: 'video',
  viewQuilt: 'viewQuilt',
  visibilityOff: 'visibilityOff',
  visibilityOn: 'visibilityOn',
} as const;

export type ValueOf<T> = T[keyof T];

export type IconNameType = keyof typeof ICON_NAMES;

export const ICON_ELEMENT_TYPES = {
  span: 'span',
  button: 'button',
} as const;

export type IconElementTypes = ValueOf<typeof ICON_ELEMENT_TYPES>;

type IconProps = {
  name: IconNameType;
  width?: number | string;
  height?: number | string;
  fill?: string;
  hoverFill?: string;
  element?: IconElementTypes;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  color?: string;
  style?: React.CSSProperties;
  'data-test'?: string;
  'data-value'?: string;
};

const ICONS: Record<IconNameType, React.FC<React.SVGProps<SVGSVGElement>>> = {
  // Keep this list alphabetized
  accordion: AccordionIcon,
  add: AddIcon,
  alert: AlertIcon,
  arrowBack: ArrowBackIcon,
  assets: AssetsIcon,
  asyncContent: AsyncContent,
  audio: AudioIcon,
  autoComplete: AutoCompleteIcon,
  avatar: AvatarIcon,
  backend: Backend,
  badge: Badge,
  breadcrumbs: Breadcrumbs,
  button: ButtonIcon,
  buttonGroup: ButtonGroupIcon,
  canvas: CanvasIcon,
  card: Card,
  check: Check,
  checkbox: CheckboxIcon,
  chevronDown: ChevronDownIcon,
  chevronRight: ChevronRightIcon,
  chevronTop: ChevronTopIcon,
  chip: Chip,
  container: ContainerIcon,
  cross: CrossIcon,
  customGroup: CustomGroupIcon,
  datePicker: DatePickerIcon,
  dateTimePicker: DateTimeIcon,
  delete: DeleteIcon,
  deploy: DeployIcon,
  displayBlock: DisplayBlockIcon,
  displayFlex: DisplayFlexIcon,
  displayGrid: DisplayGridIcon,
  displayInline: DisplayInlineIcon,
  displayInlineBlock: DisplayInlineBlockIcon,
  divider: Divider,
  down: DownIcon,
  dragIndicator: DragIndicatorIcon,
  drawer: Drawer,
  events: EventsIcon,
  FileDropZone: FileDropZoneIcon,
  FileDropZoneBase: FileDropZoneBaseIcon,
  fileUpload: FileUploadIcon,
  fileUploadBase: fileUploadBaseIcon,
  fileUploadButton: FileUploadButtonIcon,
  fileUploadButtonBase: FileUploadButtonBaseIcon,
  floatingActionButton: FloatingActionButton,
  folderOpen: FolderOpenIcon,
  footer: Footer,
  formAutocomplete: FormAutocompleteIcon,
  formBlock: FormBlockIcon,
  formCheckbox: FormCheckboxIcon,
  formDatePicker: FormDatePicker,
  formDateTimePicker: FormDateTimePicker,
  formEditableText: FormEditableText,
  formFieldArray: FormFieldArrayIcon,
  formFileUpload: FormFileUpload,
  formMultiselect: FormMultiselect,
  formnSelector: FormSelector,
  formSlider: FormSliderIcon,
  formSwitch: FormSwitchIcon,
  formTextfield: FormTextfield,
  formTimePicker: FormTimePicker,
  function: FunctionIcon,
  graphQL: GraphQLIcon,
  grid: GridIcon,
  hamburger: HamburgerIcon,
  hamburgerOpen: HamburgerOpenIcon,
  header: HeaderIcon,
  heading: HeadingIcon,
  help: HelpIcon,
  html: HTMLIcon,
  htmlTable: HTMLTable,
  htmlTableBody: HTMLTableBody,
  htmlTableCaption: HTMLTableCaption,
  htmlTableColumn: HTMLTableColumn,
  htmlTableColumnGroup: HTMLTableColumnGroup,
  htmlTableDataCell: HTMLTableDataCell,
  htmlTableFoot: HTMLTableFoot,
  htmlTableHead: HTMLTableHead,
  htmlTableHeader: HTMLTableHeader,
  htmlTableRow: HTMLTableRow,
  hyperLink: HyperLinkIcon,
  icon: IconIcon,
  iconButton: IconButton,
  image: ImageIcon,
  imageWhite: ImageWhiteIcon,
  input: InputIcon,
  insertDriveFileOutlined: InsertDriveFileOutlined,
  laptop: LaptopIcon,
  leftPanel: LeftPanel,
  lightning: LightningIcon,
  link: LinkIcon,
  list: ListIcon,
  listItem: ListItemIcon,
  listItemHtml: ListItemHtml,
  localDialog: LocalDialogIcon,
  lodash: LodashIcon,
  logo8baseAppBuilderHorizontal: Logo8baseAppBuilderHorizontal,
  logo8baseAppBuilderVertical: Logo8baseAppBuilderVertical,
  logo8baseFull: Logo8baseFull,
  logo8baseSmall: Logo8baseSmall,
  looper: LooperIcon,
  luxon: LuxonIcon,
  map: MapIcon,
  mathjs: MathJsIcon,
  menu: Menu,
  meta: MetaIcon,
  moment: MomentJsIcon,
  multiSelect: MultiselectIcon,
  navigator: NavigatorIcon,
  navListItem: NavListItem,
  notification: NotificationIcon,
  openComponent: OpenComponent,
  orderedList: OrderedList,
  pageLayout: PageLayoutIcon,
  pageSwitcher: PageSwitcherIcon,
  palette: PaletteOutlined,
  paper: PaperIcon,
  paragraph: ParagraphIcon,
  pen: PenIcon,
  play: PlayIcon,
  popper: Popper,
  queryGraphql: QueryGraphql,
  queryRest: QueryRest,
  quote: Quote,
  radioButton: RadioButtonIcon,
  radioGroup: RadioGroupIcon,
  rating: RatingIcon,
  redo: RedoIcon,
  requests: RequestsIcon,
  rest: RestIcon,
  restLight: RestLightIcon,
  richtexteditor: RichtextEditor,
  rocket: RocketIcon,
  route: RouteIcon,
  search: SearchIcon,
  sectionBlock: SectionBlock,
  sectionInline: SectionInline,
  select: SelectIcon,
  settings: SettingsIcon,
  settingsFilters: SettingsFiltersIcon,
  share: Share,
  slider: SliderIcon,
  snackbar: Snackbar,
  spaceDashboard: SpaceDashboard,
  speedDial: SpeedDialIcon,
  state: StateIcon,
  styleAlignBaseline: StyleAlignBaseline,
  styleAlignCenter: StyleAlignCenter,
  styleAlignEnd: StyleAlignEnd,
  styleAlignStart: StyleAlignStart,
  styleAlignStretch: StyleAlignStretch,
  styleArrowDown: StyleArrowDown,
  styleArrowLeft: StyleArrowLeft,
  styleArrowRight: StyleArrowRight,
  styleArrowUp: StyleArrowUp,
  styleAuto: StyleAuto,
  styleBorderBottom: StyleBorderBottom,
  styleBorderLeft: StyleBorderLeft,
  styleBorderRadiusSeparateValues: StyleBorderRadiusSeparateValues,
  styleBorderRadiusSingleValue: StyleBorderRadiusSingleValue,
  styleBorderRight: StyleBorderRight,
  styleBorderSeparateValues: StyleBorderSeparateValues,
  styleBorderSingleValue: StyleBorderSingleValue,
  styleBorderStyleDashed: StyleBorderStyleDashed,
  styleBorderStyleDotted: StyleBorderStyleDotted,
  styleBorderStyleSolid: StyleBorderStyleSolid,
  styleBorderTop: StyleBorderTop,
  styleBottomLeftCorner: StyleBottomLeftCorner,
  styleBottomRightCorner: StyleBottomRightCorner,
  styleFontItalic: StyleFontItalic,
  styleFontNormal: StyleFontNormal,
  styleJustifyAround: StyleJustifyAround,
  styleJustifyBetween: StyleJustifyBetween,
  styleJustifyCenter: StyleJustifyCenter,
  styleJustifyEnd: StyleJustifyEnd,
  styleJustifyStart: StyleJustifyStart,
  styleScroll: StyleScroll,
  styleTextAlignCenter: StyleTextAlignCenter,
  styleTextAlignJustify: StyleTextAlignJustify,
  styleTextAlignLeft: StyleTextAlignLeft,
  styleTextAlignRight: StyleTextAlignRight,
  styleTextDecorationCapitalize: StyleTextDecorationCapitalize,
  styleTextDecorationLowercase: StyleTextDecorationLowercase,
  styleTextDecorationOverline: StyleTextDecorationOverline,
  styleTextDecorationStrike: StyleTextDecorationStrike,
  styleTextDecorationUnderline: StyleTextDecorationUnderline,
  styleTextDirectionLtr: StyleTextDirectionLtr,
  styleTextDirectionRtl: StyleTextDirectionRtl,
  styleTextTransformUppercase: StyleTextTransformUppercase,
  styleTopLeftCorner: StyleTopLeftCorner,
  styleTopRightCorner: StyleTopRightCorner,
  switch: SwitchIcon,
  tab: TabIcon,
  tabGroup: TabGroupIcon,
  table: TableIcon,
  text: TextIcon,
  theme: ThemeIcon,
  timepicker: TimePickerIcon,
  toggle: Toggle,
  toggleGroup: ToggleGroup,
  tooltip: Tooltip,
  typography: Typography,
  uiAntd: UiAntd,
  uiBootstrap: UiBootstrap,
  uiBulma: UiBulma,
  uiMaterial: UiMaterial,
  uiSemantic: UiSemantic,
  undo: UndoIcon,
  unfoldLess: UnfoldLessIcon,
  unfoldMore: UnfoldMoreIcon,
  unorderedList: UnorderList,
  video: VideoIcon,
  viewQuilt: ViewQuilt,
  visibilityOff: VisibilityOff,
  visibilityOn: VisibilityOn,
};

type IconWrapper = {
  fill?: string;
  hoverFill?: string;
  width: number | string;
  height: number | string;
};

const getCommonIconWrapperStyles = ({ fill, hoverFill, width, height }: IconWrapper) => css`
  display: flex;
  line-height: 1;
  padding: 0;
  border: none;
  background-color: transparent;

  width: ${typeof width === 'number' ? `${width}px` : width};
  height: ${typeof height === 'number' ? `${height}px` : height};

  color: ${fill || 'white'};

  > svg {
    height: 100%;
    width: 100%;
    fill: ${fill || ''};
    & path {
      fill: ${fill || ''};
    }
  }

  &:active,
  &:hover {
    color: ${hoverFill};
    > svg {
      fill: ${hoverFill || ''};
      & path {
        fill: ${hoverFill || ''};
      }
    }
  }
`;

export const IconWrapperSpan = styled.span<IconWrapper>`
  ${props => getCommonIconWrapperStyles(props)}
`;

export const IconWrapperButton = styled.button<IconWrapper>`
  cursor: pointer;
  ${props => getCommonIconWrapperStyles(props)}
  &:focus {
    outline: none;
  }
`;

export const Icon: React.FC<IconProps> = ({
  name,
  width = 28,
  height = 28,
  fill,
  hoverFill,
  element = ICON_ELEMENT_TYPES.span,
  color,
  onClick,
  style,
  'data-test': dataTest,
  'data-value': dataValue,
}) => {
  const IconSvg = ICONS[name] || ICONS.container;

  if (element === ICON_ELEMENT_TYPES.button) {
    return (
      <IconWrapperButton
        data-test={dataTest}
        data-value={dataValue}
        fill={fill}
        hoverFill={hoverFill}
        width={width}
        height={height}
        onClick={onClick}
        style={style}
      >
        <IconSvg />
      </IconWrapperButton>
    );
  }

  return (
    <IconWrapperSpan
      data-test={dataTest}
      data-value={dataValue}
      fill={fill}
      hoverFill={hoverFill}
      width={width}
      height={height}
      style={style}
    >
      <IconSvg />
    </IconWrapperSpan>
  );
};

export { DownIcon };
