import debounce from 'lodash.debounce';
import { SnackbarMessage, VariantType } from 'notistack';
import { Dispatch } from 'redux';

import { COMPONENT_DSL_NAMES, NodeListDSL } from '@builder/schemas';
import { isString } from '@builder/utils';

import { AppEvents, ItemErrorContext, UI_BUILDER_EVENTS } from 'src/store';

import { callBackPrefix, COLUMNS, VARIABLES } from './constants';

export const issuesSelector = (
  errors: ItemErrorContext[],
  warnings: ItemErrorContext[],
  nodeListDSL: NodeListDSL,
  message: SnackbarMessage,
): ItemErrorContext =>
  Object.values([...errors, ...warnings]).filter(issue => {
    const { targetID, context } = issue;
    const { fullPath, message: reportIssueMesssage, propPath } = context;
    const notifyMessage = (message as string).toLocaleLowerCase();
    const errorMessageList = isString(notifyMessage) ? notifyMessage?.split('-') : [''];
    const isCallback = callBackPrefix.includes(errorMessageList[0].trim());
    const isVariable = errorMessageList[0].includes(VARIABLES);
    const reportMessage = reportIssueMesssage
      .replaceAll("'", '')
      .replaceAll('.', '')
      .toLocaleLowerCase();
    const section = fullPath[fullPath.length - 1];
    let nodeAlias = nodeListDSL[targetID]?.alias;
    if (nodeAlias) {
      const errorPropPath = `${nodeAlias} - ${propPath
        .slice(1)
        .join(',')} - ${reportMessage}`.toLowerCase();
      const prefix = fullPath[fullPath.length - 2] === 'style' ? 'style,' : '';
      const symbolPropPrefix = errorMessageList[1]?.split(',')[0].trim();
      let errorKey = `${nodeAlias} - ${prefix}${section} - ${reportMessage}`.toLowerCase();
      const isListProp = fullPath[3] === COLUMNS;
      const isTextProp =
        isString(message) && section === 'children' && errorMessageList[0].includes('text');
      const isFormComponent =
        nodeAlias.includes('Form') &&
        nodeListDSL[targetID].name !== COMPONENT_DSL_NAMES.BuilderComponentsForm;
      if (isListProp) {
        errorKey = `${nodeAlias} - ${fullPath[3]},${fullPath[4]},${section} - ${reportMessage}`.toLowerCase();
      }

      if (isTextProp) {
        const errorID = Object.values(nodeListDSL).filter(node => {
          return node.alias?.toLowerCase() === errorMessageList[0].trim();
        })[0]?.parentID;
        nodeAlias = nodeListDSL[errorID ?? '']?.alias;
        errorMessageList[0] = `${nodeAlias} `;
        return errorMessageList.join('-').toLowerCase().includes(errorKey);
      }

      if (isFormComponent) {
        const errorSymbolProp = `${nodeAlias} - ${symbolPropPrefix},${prefix}${section} - ${reportMessage}`.toLowerCase();
        return (
          errorKey.includes(notifyMessage) ||
          errorPropPath.includes(notifyMessage) ||
          errorSymbolProp.includes(notifyMessage)
        );
      }

      return errorKey.includes(notifyMessage) || errorPropPath.includes(notifyMessage);
    }

    if (isVariable) {
      const variableMessage = `${
        fullPath[fullPath.length - 2]
      },${section} - ${reportMessage}`.toLowerCase();
      return variableMessage.includes(notifyMessage as string);
    }

    if (isCallback) {
      return reportMessage
        .toLowerCase()
        .includes(errorMessageList[1].trim().toLowerCase() as string);
    }

    return reportIssueMesssage.includes(notifyMessage) || notifyMessage.includes(reportMessage);
  })[0];

export const launchNotification = debounce(
  (send: Dispatch<AppEvents>, message: string, variant: VariantType | undefined) => {
    send({
      type: UI_BUILDER_EVENTS.notificationSend,
      notification: {
        message,
        options: { variant },
      },
    });
  },
  500,
);
