import { Extension } from '@codemirror/state';
import { EditorView } from '@codemirror/view';

export const customOnChange = (callback: (value: string) => void): Extension => {
  return EditorView.updateListener.of(update => {
    if (update.docChanged) {
      callback(update.state.sliceDoc());
    }
  });
};

export const customOnBlur = (callback: (value: string) => void): Extension => {
  return EditorView.domEventHandlers({
    blur(e, view) {
      callback(view.state.sliceDoc());
    },
  });
};
