var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import { Popover as MuiPopover } from '@mui/material';
import { bindTrigger, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
/** @deprecated */
export const Popover = forwardRef((_a, ref) => {
    var { target, content, forceOpen, __nodeAlias, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, popoverProps = __rest(_a, ["target", "content", "forceOpen", "__nodeAlias", 'data-test', 'data-node-id', 'data-node-render-path']);
    const popupState = usePopupState({
        variant: 'popover',
        popupId: __nodeAlias,
    });
    const bindPopoverState = useMemo(() => bindPopover(popupState), [popupState]);
    const bindTriggerState = useMemo(() => bindTrigger(popupState), [popupState]);
    const open = forceOpen === undefined ? bindPopoverState.open : forceOpen;
    return (_jsxs("div", Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath }, { children: [_jsx("div", Object.assign({}, bindTriggerState, { css: css `
            display: inline-flex;
          ` }, { children: target })), _jsx(MuiPopover, Object.assign({}, bindPopoverState, popoverProps, { open: open }, { children: content({ onClose: bindPopoverState.onClose }) }))] })));
});
