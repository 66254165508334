import { argTypes } from 'src/dialogs/GlobalDialogs/CodeEditorDialog/CodeEditorDialog';

const SnackBarEvent = ['BuilderComponentsSnackBar'];
const COMPONENTS_ACCEPTS_VALUE = [
  'BuilderComponentsRating',
  'BuilderComponentsFormRating',
  'BuilderComponentsRadioGroup',
  'BuilderComponentsFormRadioGroup',
  'BuilderComponentsTabs',
];

const COMPONENTS_ACCEPTS_EDITOR = [
  'BuilderComponentsRichtextEditor',
  'BuilderComponentsFormRichtextEditor',
];

const COMPONENTS_ACCEPTS_CHILD = [
  'BuilderComponentsSelect',
  'BuilderComponentsFormSelect',
  'BuilderComponentsMultiselect',
  'BuilderComponentsFormMultiselect',
];

const COMPONENTS_ACCEPTS_VALUE_AND_ACTIVETHUMB = [
  'BuilderComponentsSlider',
  'BuilderComponentsFormSlider',
];
const COMPONENTS_ACCEPTS_VALUE_REASON_AND_DETAILS = [
  'BuilderComponentsAutocomplete',
  'BuilderComponentsFormAutocomplete',
];

const getNewAcceptedArgs = (
  WHITE_LIST: string[],
  componentName: string,
  valueToAccept: string | string[],
) => {
  const newArgsArray = typeof valueToAccept === 'string' ? [valueToAccept] : [...valueToAccept];
  return WHITE_LIST.includes(componentName) ? newArgsArray : [];
};

export const eventKeys = (componentName: string): argTypes[] => [
  {
    action: 'onChange',
    context: [
      'event',
      ...getNewAcceptedArgs(COMPONENTS_ACCEPTS_VALUE, componentName, 'value'),
      ...getNewAcceptedArgs(COMPONENTS_ACCEPTS_EDITOR, componentName, 'editor'),
      ...getNewAcceptedArgs(COMPONENTS_ACCEPTS_VALUE_AND_ACTIVETHUMB, componentName, [
        'value',
        'activeThumb',
      ]),
      ...getNewAcceptedArgs(COMPONENTS_ACCEPTS_CHILD, componentName, 'child'),
      ...getNewAcceptedArgs(COMPONENTS_ACCEPTS_VALUE_REASON_AND_DETAILS, componentName, [
        'value',
        'reason',
        'details',
      ]),
    ],
  },
  {
    action: 'onClick',
    context: ['event'],
  },
  {
    action: 'onMouseOver',
    context: ['event'],
  },
  {
    action: 'onMouseOut',
    context: ['event'],
  },
  {
    action: 'onMouseDown',
    context: ['event'],
  },
  {
    action: 'onMouseUp',
    context: ['event'],
  },
  {
    action: 'onMouseEnter',
    context: ['event'],
  },
  {
    action: 'onMouseLeave',
    context: ['event'],
  },
  {
    action: 'onWheel',
    context: ['event'],
  },
  {
    action: 'onContextMenu',
    context: ['event'],
  },
  {
    action: 'onAuxClick',
    context: ['event'],
  },
  {
    action: 'onSubmit',
    context: ['event', 'data'],
  },
  {
    action: 'onDragOver',
    context: ['event'],
  },
  {
    action: 'onClose',
    context: ['event', ...getNewAcceptedArgs(SnackBarEvent, componentName, 'reason')],
  },
];
