import { isObject } from '../type-checkers';
/**
 * Converts deep object to the flat array of objects which contains path and value.
 * @example
 * { a: 42, b: { c: 0 }} => [{ path: ['a'], value: 42 }, { path: ['b', 'c'], value: 0 }]
 */
export const flatObject = (deepObj, prefixPath = []) => {
    return Object.keys(deepObj).reduce((accum, key) => {
        const value = deepObj[key];
        const currentPath = [...prefixPath, key];
        if (isObject(value)) {
            return [...accum, ...flatObject(value, currentPath)];
        }
        return [...accum, { path: currentPath, value }];
    }, []);
};
