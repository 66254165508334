import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import body1DSL from './style-guide-dsl/TypographyStyleGuide/body1.json';
import body2DSL from './style-guide-dsl/TypographyStyleGuide/body2.json';
import buttonDSL from './style-guide-dsl/TypographyStyleGuide/button.json';
import captionDSL from './style-guide-dsl/TypographyStyleGuide/caption.json';
import h1DSL from './style-guide-dsl/TypographyStyleGuide/h1.json';
import h2DSL from './style-guide-dsl/TypographyStyleGuide/h2.json';
import h3DSL from './style-guide-dsl/TypographyStyleGuide/h3.json';
import h4DSL from './style-guide-dsl/TypographyStyleGuide/h4.json';
import h5DSL from './style-guide-dsl/TypographyStyleGuide/h5.json';
import h6DSL from './style-guide-dsl/TypographyStyleGuide/h6.json';
import overlineDSL from './style-guide-dsl/TypographyStyleGuide/overline.json';
import subtitle1DSL from './style-guide-dsl/TypographyStyleGuide/subtitle1.json';
import subtitle2DSL from './style-guide-dsl/TypographyStyleGuide/subtitle2.json';
const rootBase = '.MuiTypography-root';
export const TYPOGRAPHY_VARIANT_CLASSES = {
    root: rootBase,
    h1: `${rootBase}.MuiTypography-h1`,
    h2: `${rootBase}.MuiTypography-h2`,
    h3: `${rootBase}.MuiTypography-h3`,
    h4: `${rootBase}.MuiTypography-h4`,
    h5: `${rootBase}.MuiTypography-h5`,
    h6: `${rootBase}.MuiTypography-h6`,
    subtitle1: `${rootBase}.MuiTypography-subtitle1`,
    subtitle2: `${rootBase}.MuiTypography-subtitle2`,
    body1: `${rootBase}.MuiTypography-body1`,
    body2: `${rootBase}.MuiTypography-body2`,
    button: `${rootBase}.MuiTypography-button`,
    caption: `${rootBase}.MuiTypography-caption`,
    overline: `${rootBase}.MuiTypography-overline`,
};
export const TYPOGRAPHY_COMMON_CLASSES = {
    inherit: '.MuiTypography-inherit',
    alignLeft: '.MuiTypography-alignLeft',
    alignCenter: '.MuiTypography-alignCenter',
    alignRight: '.MuiTypography-alignRight',
    alignJustify: '.MuiTypography-alignJustify',
    noWrap: '.MuiTypography-noWrap',
    gutterBottom: '.MuiTypography-gutterBottom',
    paragraph: '.MuiTypography-paragraph',
};
export const TYPOGRAPHY_CLASS_NAMES = [
    ...Object.values(TYPOGRAPHY_VARIANT_CLASSES),
    ...Object.values(TYPOGRAPHY_COMMON_CLASSES),
];
export const TypographyStyleGuideGlobalStyle = {
    title: 'root',
};
export const TypographyStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiTypography,
    componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
    dirName: 'TypographyStyleGuide',
    title: 'Typography',
    classNames: TYPOGRAPHY_CLASS_NAMES,
    stories: [
        {
            title: 'h1',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'h1',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'h1',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: h1DSL,
        },
        {
            title: 'h2',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'h2',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'h2',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: h2DSL,
        },
        {
            title: 'h3',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'h3',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'h3',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: h3DSL,
        },
        {
            title: 'h4',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'h4',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'h4',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: h4DSL,
        },
        {
            title: 'h5',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'h5',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'h5',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: h5DSL,
        },
        {
            title: 'h6',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'h6',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'h6',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: h6DSL,
        },
        {
            title: 'subtitle1',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'subtitle1',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'subtitle1',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: subtitle1DSL,
        },
        {
            title: 'subtitle2',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'subtitle2',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'subtitle2',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: subtitle2DSL,
        },
        {
            title: 'body1',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'body1',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'body1',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: body1DSL,
        },
        {
            title: 'body2',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'body2',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'body2',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: body2DSL,
        },
        {
            title: 'button',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'button',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'button',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: buttonDSL,
        },
        {
            title: 'caption',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'caption',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'caption',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: captionDSL,
        },
        {
            title: 'overline',
            componentNameDSL: COMPONENT_DSL_NAMES.MaterialTypography,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        variant: 'overline',
                        children: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.Text,
                                    props: {
                                        children: 'overline',
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.14.0',
            isAppDSLCommitted: true,
            generatedAppDSL: overlineDSL,
        },
    ],
};
