var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class LibraryTypesGenerator {
    /**
     * Fetches the types from the CDN and returns the content and the filePath
     *
     * @example
     * {
     * libraryDSL: {
     *  id: 'mathjs',
     *  name: 'mathjs',
     *  alias: 'math',
     *  version: '0.0.0',
     * },
     * varName: 'math',
     * versionData: {
     *  version: '0.0.0',
     *  typesCollection: ['/index.d.ts'],
     *  typesVersion: '0.0.0',
     *  cdn: 'https://',
     * }
     * } => [
     *  {
     *    content: '...',
     *    filePath: '@mathjs/index.d.ts',
     *  },
     * ]
     */
    generateTypes(libraryData) {
        return __awaiter(this, void 0, void 0, function* () {
            const { libraryDSL, versionData } = libraryData;
            const { typesCollection, typesVersion } = versionData;
            return Promise.all(typesCollection.map((typeCollection) => __awaiter(this, void 0, void 0, function* () {
                const cdnTypesUrl = `${typesVersion}${typeCollection}`;
                const result = yield fetch(cdnTypesUrl);
                const content = yield result.text();
                const filePath = `@${libraryDSL.name}${typeCollection}`;
                return { content, filePath };
            })));
        });
    }
}
