var _a, _b, _c;
import { omit } from 'ramda';
import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsRadioGroup } from '../../material-components/BuilderComponentsRadioGroup';
import { BuilderComponentsFormRadioGroupSettings } from './BuilderComponentsFormRadioGroup.settings';
export const BuilderComponentsFormRadioGroup = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormRadioGroup,
    displayName: 'Form Radio Button Group',
    icon: 'radioGroup',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormRadioGroup',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [visibleByCondition],
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'FormRadioGroup',
                    defaultValue: 'value1',
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormRadioGroup',
                },
                radioGroupProps: Object.assign(Object.assign({}, omit(['value', 'onChange', 'children'], (_a = BuilderComponentsRadioGroup.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)), { value: '{{ __STATES__.FormRadioGroup.value }}' }),
                children: (_c = (_b = BuilderComponentsRadioGroup.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props) === null || _c === void 0 ? void 0 : _c.children,
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.FormRadioGroup.setValue(value)',
                    },
                },
                style: getPredefinedStyles(),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: 'value',
                        path: [1],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.string,
                        },
                    },
                ],
            }, radioGroupProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsRadioGroup.schema.props,
            }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsRadio, COMPONENT_DSL_NAMES.Iterator],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsFormRadioGroupSettings,
};
