import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_18_8 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.18.8';
    }
    getChangeList() {
        return ['Update badge component to app builder'];
    }
    migrateNodeDSL(nodeDSL) {
        switch (nodeDSL.name) {
            case 'MaterialBadge': {
                return Object.assign(Object.assign({}, nodeDSL), { name: COMPONENT_DSL_NAMES.MaterialBadge });
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_18_8 = new Migration_0_18_8();
