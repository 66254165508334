export const COMPONENT_SETTING_VIEWS = {
    select: 'select',
    multiselect: 'multi-select',
    checkbox: 'checkbox',
    multiCheckbox: 'multi-checkbox',
    text: 'text',
    textArea: 'text-area',
    number: 'number',
    jsonEditor: 'json-editor',
    jsEditor: 'js-editor',
    cssEditor: 'css-editor',
    listEditor: 'list-editor',
    nodeEditor: 'node-editor',
    colorEditor: 'color-editor',
    iconEditor: 'icon-editor',
    dataGenerator: 'data-generator',
    spacingEditor: 'spacing-editor',
    radio: 'radio',
    assetEditor: 'asset-editor',
    functionDynamicSelect: 'function-dynamic-select',
    selectEightBaseAddedWorkspaces: 'select-base-workspaces',
    updatedPrimaryKey: 'updated-primary-key',
    validationListValue: 'validation-list-value',
};
export const COMPONENT_SETTING_TYPES = {
    action: 'action',
    allowedRoles: 'allowed-roles',
    // TODO: childrenTextRich => nodeTextRich
    childrenTextRich: 'children-text-rich',
    condition: 'condition',
    customFont: 'custom-font',
    customProperties: 'custom-properties',
    dataGenerator: 'data-generator',
    divider: 'divider',
    eventsSection: 'events-section',
    grid: 'grid',
    gridItem: 'gridItem',
    htmlFragment: 'html-fragment',
    iterator: 'iterator',
    list: 'list',
    mixed: 'mixed',
    node: 'node',
    nodeAlias: 'node-alias',
    nodeList: 'node-list',
    nodeText: 'node-text',
    popup: 'popup',
    popupsSection: 'popupsSection',
    prop: 'prop',
    requestSelector: 'request-selector',
    rootSection: 'rootSection',
    schemaOverride: 'schema-override',
    section: 'section',
    spacing: 'spacing',
    state: 'state',
    stateSelector: 'state-selector',
    subList: 'sub-list',
    subSection: 'sub-section',
    textMultiSelect: 'text-multi-select',
    updatedPrimaryKey: 'updated-primary-key',
};
export const COMPONENT_SETTING_CONDITION_TYPES = {
    equals: 'equals',
    notEquals: 'notEquals',
    isNil: 'isNil',
    isNotNil: 'isNotNil',
    hasAncestors: 'hasAncestors',
    isIncluded: 'isIncluded',
};
export const GENERATOR_VARIANTS = {
    keysToArray: 'keys-to-array',
};
export const GENERATOR_SHAPE_TYPES = {
    key: 'key',
    capitalizeKey: 'capitalizeKey',
};
export const POSSIBLE_UNIT_TYPES = {
    postfix: 'postfix',
    value: 'value',
};
export const COMPONENT_SETTING_POSSIBLE_UNITS = {
    px: { value: 'px', type: POSSIBLE_UNIT_TYPES.postfix },
    percent: { value: '%', type: POSSIBLE_UNIT_TYPES.postfix },
    rem: { value: 'rem', type: POSSIBLE_UNIT_TYPES.postfix },
    em: { value: 'em', type: POSSIBLE_UNIT_TYPES.postfix },
    vh: { value: 'vh', type: POSSIBLE_UNIT_TYPES.postfix },
    vw: { value: 'vw', type: POSSIBLE_UNIT_TYPES.postfix },
    vmin: { value: 'vmin', type: POSSIBLE_UNIT_TYPES.postfix },
    vmax: { value: 'vmax', type: POSSIBLE_UNIT_TYPES.postfix },
    none: { value: '', type: POSSIBLE_UNIT_TYPES.value },
};
export const COMPONENT_SETTING_ACTION_TYPE = {
    changePage: 'changePage',
    customCode: 'customCode',
    functionCode: 'functionCode',
    closePopup: 'closePopup',
    requiredValidate: 'requiredValidate',
    runRequest: 'runRequest',
    updateState: 'updateState',
};
export const COMPONENT_SETTING_ACTION_LABEL = {
    changePage: 'Navigate',
    customCode: 'Run Custom Code',
    functionCode: 'Run Function',
    closePopup: 'Close Popup',
    requiredValidate: 'Required Validate',
    runRequest: 'Run Request',
    updateState: 'Update State',
};
export const COMPONENT_SETTING_ACTION_COLORS = {
    changePage: 'success',
    customCode: 'warning',
    functionCode: 'success',
    closePopup: 'success',
    requiredValidate: 'success',
    runRequest: 'success',
    updateState: 'success',
};
export const COMPONENT_SETTING_FUNCTION_TYPE = {
    functionName: 'functionName',
};
export const COMPONENT_SETTING_FUNCTION_LABEL = {
    functionName: '',
};
export const DIVIDER_POSITIONS = {
    top: 'top',
    bottom: 'bottom',
    both: 'both',
};
export const TRANSLATE_OPTIONS = ['yes', 'no'];
export const LANG_OPTIONS = [
    'AA',
    'AF',
    'SQ',
    'AM',
    'AR',
    'HY',
    'AS',
    'AY',
    'AZ',
    'BA',
    'EU',
    'BN',
    'DZ',
    'BH',
    'BI',
    'BR',
    'BG',
    'MY',
    'BE',
    'KM',
    'CA',
    'ZH',
    'CO',
    'HR',
    'CS',
    'DA',
    'NL',
    'EN',
    'EO',
    'ET',
    'FO',
    'FJ',
    'FI',
    'FR',
    'FY',
    'GD',
    'GL',
    'KA',
    'DE',
    'EL',
    'KL',
    'GN',
    'GU',
    'HA',
    'IW',
    'HI',
    'HU',
    'IS',
    'IN',
    'IA',
    'IE',
    'IK',
    'GA',
    'IT',
    'JA',
    'JW',
    'KN',
    'KS',
    'KK',
    'RW',
    'KY',
    'RN',
    'KO',
    'KU',
    'LO',
    'LA',
    'LV',
    'LN',
    'LT',
    'MK',
    'MG',
    'MS',
    'ML',
    'MT',
    'MI',
    'MR',
    'MO',
    'MN',
    'NA',
    'NE',
    'NO',
    'OC',
    'OR',
    'OM',
    'PS',
    'FA',
    'PL',
    'PT',
    'PA',
    'QU',
    'RM',
    'RO',
    'RU',
    'SM',
    'SG',
    'SA',
    'SR',
    'SH',
    'ST',
    'TN',
    'SN',
    'SD',
    'SI',
    'SS',
    'SK',
    'SL',
    'SO',
    'ES',
    'SU',
    'SW',
    'SV',
    'TL',
    'TG',
    'TA',
    'TT',
    'TE',
    'TH',
    'BO',
    'TI',
    'TO',
    'TS',
    'TR',
    'TK',
    'TW',
    'UK',
    'UR',
    'UZ',
    'VI',
    'VO',
    'CY',
    'WO',
    'XH',
    'JI',
    'YO',
    'ZU',
];
export const TARGET_OPTIONS = ['_blank', '_self', '_parent', '_top'];
export const REL_OPTIONS = [
    'alternate',
    'author',
    'bookmark',
    'external',
    'help',
    'license',
    'next',
    'nofollow',
    'noreferrer',
    'noopener',
    'prev',
    'search',
    'tag',
];
export const REFERRER_POLICY_OPTIONS = [
    'no-referrer',
    'no-referrer-when-downgrade',
    'origin',
    'origin-when-cross-origin',
    'same-origin',
    'strict-origin',
    'strict-origin-when-cross-origin',
    'unsafe-url',
];
