import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_BOOLEAN_VARIANTS, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonStyleProps } from '../../common/commonProps';
import { MaterialDrawerSettings } from './MaterialDrawer.settings';
export const MaterialDrawer = {
    name: COMPONENT_DSL_NAMES.MaterialDrawer,
    displayName: 'Drawer',
    icon: 'drawer',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.layout,
    source: {
        importName: '@mui/material',
        componentName: 'Drawer',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: 'global',
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.openClose,
                    name: 'drawer',
                    defaultValue: true,
                },
                {
                    scope: 'global',
                    type: STATE_TYPES.number,
                    name: 'drawerWidth',
                    defaultValue: 242,
                },
            ],
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialDrawerContent,
                            props: {
                                style: {
                                    // eslint-disable-next-line no-template-curly-in-string
                                    width: '{{ `${__STATES__.drawerWidth.value}px` }}',
                                    height: '100%',
                                },
                            },
                        },
                    ],
                },
                open: '{{ __STATES__.drawer.isOpened }}',
                onClose: '{{ __STATES__.drawer.close }}',
                anchor: 'left',
                elevation: 16,
                transitionDuration: 300,
                variant: 'temporary',
                ModalProps: {
                    closeAfterTransition: false,
                    disableAutoFocus: false,
                    disableEnforceFocus: false,
                    disableEscapeKeyDown: false,
                    disablePortal: true,
                    disableRestoreFocus: false,
                    disableScrollLock: false,
                    hideBackdrop: false,
                    keepMounted: false,
                },
                PaperProps: {
                    elevation: 1,
                    square: true,
                    variant: 'elevation',
                },
                SlideProps: {
                    direction: 'right',
                },
            },
        },
        props: Object.assign(Object.assign(Object.assign({}, commonStyleProps), commonComponentProps), { open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, anchor: { type: COMPONENT_DSL_PROP_TYPES.enum }, elevation: { type: COMPONENT_DSL_PROP_TYPES.number }, transitionDuration: { type: COMPONENT_DSL_PROP_TYPES.number }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, ModalProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    // TODO: add BackdropProps
                    closeAfterTransition: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    disableAutoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    disableEnforceFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    disableEscapeKeyDown: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    disablePortal: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    disableRestoreFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    disableScrollLock: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    hideBackdrop: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    keepMounted: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                },
            }, PaperProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    elevation: { type: COMPONENT_DSL_PROP_TYPES.number },
                    square: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                    variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, // 'elevation' | 'outlined'
                },
            }, SlideProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    direction: { type: COMPONENT_DSL_PROP_TYPES.enum }, // 'down' | 'left' | 'right' | 'up'
                },
            } }),
    },
    settings: MaterialDrawerSettings,
};
