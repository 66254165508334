import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_COLUMN } from './tableColumn.settings';
export const tableColumn = {
    displayName: 'Table Column',
    name: COMPONENT_DSL_NAMES.tableColumn,
    icon: 'htmlTableColumn',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'col',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.tableColumnGroup,
                },
            },
        ],
        props: Object.assign(Object.assign({}, commonComponentProps), { span: { type: COMPONENT_DSL_PROP_TYPES.number } }),
    },
    settings: TABLE_COLUMN,
};
