import { NodeDSL, NodeListDSL, nodeListSelectors } from '@builder/schemas';

import { CHECKMARKS } from '../constants';

import { multiselectsValidations } from './validations';

export const showGoComponentButton = (
  selectedNodeDSL: NodeDSL,
  propLabel: string,
  keyValue: string | number,
  nodeListDSL: NodeListDSL,
): {
  show: boolean;
  componentID: string;
} => {
  const {
    isBuilderComponentsMultiselect,
    isBuilderComponentsFormMultiselect,
    isBehaviorPropAndCheckOrChip,
  } = multiselectsValidations(selectedNodeDSL, propLabel, keyValue);

  if (isBuilderComponentsMultiselect || isBuilderComponentsFormMultiselect) {
    const selectNodeDSLNodes = isBuilderComponentsMultiselect
      ? (selectedNodeDSL?.props?.children as { nodes: string[] })?.nodes
      : (selectedNodeDSL?.props?.selectProps as { children: { nodes: string[] } }).children.nodes;

    const childrenNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
      nodeID: selectNodeDSLNodes[0],
    });

    const childrenNodes = (childrenNodeDSL?.props?.children as { nodes: string[] })?.nodes;

    return {
      show: isBehaviorPropAndCheckOrChip && childrenNodes.length > 1,
      componentID: childrenNodes[keyValue === CHECKMARKS ? 1 : 2],
    };
  }

  return {
    show: false,
    componentID: '',
  };
};
