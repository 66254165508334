var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect } from 'react';
export const RouteLayout = forwardRef((_a, ref) => {
    var { onMount, children, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, rest = __rest(_a, ["onMount", "children", 'data-test', 'data-node-id', 'data-node-render-path']);
    useEffect(() => {
        onMount === null || onMount === void 0 ? void 0 : onMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath }, rest, { children: children })));
});
