import { isEmpty } from 'ramda';
import { isArray, isBoolean, isNil, isNumber, isObject, isString, isUndefined, } from '@builder/utils';
import { hasPropJsCode } from '../../utils/prop-transformers/transformPropWithJsCode';
const withAllowEmpty = (func) => {
    return (propValue, options) => {
        const { allowEmpty } = options || {};
        if (isUndefined(allowEmpty)) {
            return func(propValue, options);
        }
        if (allowEmpty && isNil(propValue))
            return true;
        if (!allowEmpty && isNil(propValue))
            return false;
        return func(propValue, options);
    };
};
const withAllowJSInjection = (func) => {
    return (propValue, options) => {
        const { allowJSInjection } = options || {};
        const hasJSInjection = hasPropJsCode(propValue);
        if (isUndefined(allowJSInjection)) {
            return func(propValue, options);
        }
        if (allowJSInjection && hasJSInjection)
            return true;
        if (!allowJSInjection && hasJSInjection)
            return false;
        return func(propValue, options);
    };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pipeOptions(...functions) {
    if (functions.length === 3) {
        const [a, b, input] = functions;
        return b(a(input));
    }
    const [a, input] = functions;
    return a(input);
}
/**
 * Provider helpers to check prop schema.
 * It checks prop by node DSL.
 */
export class PropValueChecker {
    static isEmptyObjectProp(propValue) {
        return isObject(propValue) && isEmpty(propValue);
    }
    static isEmptyProp(propValue) {
        return propValue === null || propValue === undefined;
    }
    static isNotEmptyProp(propValue) {
        return propValue !== null && propValue !== undefined;
    }
    static isJSInjectionProp(propValue) {
        return hasPropJsCode(propValue);
    }
}
PropValueChecker.isRenderableProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return PropValueChecker.isRenderableNodesProp(propValue);
});
PropValueChecker.isRenderablePropEmpty = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    if (!propValue)
        return false;
    return propValue.nodes.length === 0;
});
/**
 * Return true if there are other nodes in renderable prop.
 */
PropValueChecker.isRenderableNodesProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return (!isNil(propValue) && isObject(propValue) && 'nodes' in propValue && isArray(propValue.nodes));
});
PropValueChecker.isReactNodeProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return (!isNil(propValue) && isObject(propValue) && 'nodes' in propValue && isArray(propValue.nodes));
});
PropValueChecker.isCalculatedProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return (!isNil(propValue) &&
        isObject(propValue) &&
        'calc' in propValue &&
        'data' in propValue &&
        isString(propValue.calc) &&
        (isObject(propValue.data) || isArray(propValue.data)));
});
PropValueChecker.isCallbackCodeProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isString(propValue);
});
PropValueChecker.isActionProp = pipeOptions(withAllowEmpty, (propValue) => {
    // `onClick: {}` - valid case for actions
    if (!isNil(propValue) && isObject(propValue)) {
        return isEmpty(propValue) || 'actionType' in propValue;
    }
    return false;
});
PropValueChecker.isActionPropWithArgs = pipeOptions(withAllowEmpty, (propValue) => {
    return (!isNil(propValue) && isObject(propValue) && 'actionType' in propValue && 'args' in propValue);
});
PropValueChecker.isCallbackComponentProp = pipeOptions(withAllowEmpty, (propValue) => {
    return !isNil(propValue) && isObject(propValue) && 'nodes' in propValue;
});
PropValueChecker.isComponentProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isString(propValue);
});
PropValueChecker.isCSSProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue, options) => {
    return !isNil(propValue) && isString(propValue);
});
PropValueChecker.isObjectProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isObject(propValue);
});
PropValueChecker.isStyleProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isObject(propValue);
});
PropValueChecker.isArrayProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isArray(propValue);
});
PropValueChecker.isNumberProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isNumber(propValue);
});
PropValueChecker.isStringProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isString(propValue);
});
PropValueChecker.isBooleanProp = pipeOptions(withAllowEmpty, withAllowJSInjection, (propValue) => {
    return !isNil(propValue) && isBoolean(propValue);
});
PropValueChecker.isAssetProp = pipeOptions(withAllowEmpty, (propValue) => {
    return !isNil(propValue) && isObject(propValue) && 'assetID' in propValue;
});
PropValueChecker.isValidCustomProp = (propValue) => {
    const isValid = /^[a-zA-Z0-9]+([-_][a-zA-Z0-9]+)*$/.test(propValue);
    return isValid;
};
PropValueChecker.isValidCustomvalue = (propValue) => {
    const isValid = /^[^'"]*$/.test(propValue);
    return isValid;
};
PropValueChecker.isValidFieldValidationProp = (propValue) => {
    const isValid = /^[a-zA-Z0-9_@./:]+([-_][a-zA-Z0-9_@./:]+)*$/.test(propValue);
    return isValid;
};
PropValueChecker.isValidFieldValidationValue = (propValue) => {
    // Regular expression to match valid field validation values (alphanumeric, underscores, @, ., :, and spaces)
    const fieldValidationRegex = /^[a-zA-Z0-9_@./: ]+([-_][a-zA-Z0-9_@./: ]+)*$/;
    const isValidFieldValidation = fieldValidationRegex.test(propValue);
    // Regular expression to match valid URLs
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const isValidURL = urlRegex.test(propValue);
    return isValidFieldValidation || isValidURL;
};
PropValueChecker.isValidFieldValidationDateTime = (propValue) => {
    const isValid = /^[a-zA-Z0-9_@./: ]+([-_][a-zA-Z0-9_@./: ]+)*$/.test(propValue);
    return isValid;
};
