import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, COMPONENT_DSL_INTERACTION_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialFormLabelSettings } from './MaterialFormLabel.settings';
export const MaterialFormLabel = {
    name: COMPONENT_DSL_NAMES.MaterialFormLabel,
    displayName: 'Form Label',
    icon: 'listItem',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'FormLabel',
    },
    schema: {
        presentations: [visibleByCondition],
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        accessibility: {
            disableCreatingSymbol: true,
        },
        predefineds: {
            props: {
                component: 'label',
                children: 'Form Label',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { component: { type: COMPONENT_DSL_PROP_TYPES.string }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, error: { type: COMPONENT_DSL_PROP_TYPES.boolean }, filled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean } }),
    },
    settings: MaterialFormLabelSettings,
};
