import { useMemo, useState, useEffect } from 'react';

import { useQuery, ApolloQueryResult } from '@apollo/client';
import { pick } from 'ramda';

import { AppConfiguration } from '@builder/schemas';

import { APOLLO_OPERATION_CONTEXT, ASYNC_SESSION_STATUSES } from '../../constants';
import { UserConfigurationQuery } from '../__generated__';
import { USER_CONFIGURATION_QUERY } from '../fragments';
import { useCurrentWorkspaceID, useDashboardCurrentRouteNodeDSL } from 'src/shared/hooks';
import { BaseGraphQLError } from 'src/shared/types';

import { useAsyncSession } from './useAsyncSession';

type UseSavedConfigurationReturn = {
  savedConfiguration?: AppConfiguration;
  loading: boolean;
  error?: string;
  refetch: () => Promise<ApolloQueryResult<UserConfigurationQuery>>;
};

export const useSavedConfiguration = (): UseSavedConfigurationReturn => {
  const { workspaceID } = useCurrentWorkspaceID();
  const currentActiveRoute = useDashboardCurrentRouteNodeDSL();
  const [sessionId, setSessionId] = useState<string>();
  const { asyncSession, error: sessionError, loading: sessionLoading } = useAsyncSession(sessionId);

  const { data, error, loading, refetch } = useQuery<UserConfigurationQuery>(
    USER_CONFIGURATION_QUERY,
    {
      variables: {
        workspaceId: workspaceID,
        pageKeys: currentActiveRoute.id,
      },
      context: {
        [APOLLO_OPERATION_CONTEXT.IGNORE_ERROR_MESSAGE]: true,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      onError: ({ graphQLErrors }) => {
        for (const graphQLError of graphQLErrors as BaseGraphQLError[]) {
          if (graphQLError.code === 'AsyncTaskPendingErrorCode') {
            setSessionId(graphQLError.details.sessionId);
            break;
          }
        }
      },
      skip: !workspaceID,
    },
  );

  useEffect(() => {
    if (sessionId && !sessionLoading) {
      if (asyncSession?.status === ASYNC_SESSION_STATUSES.success) {
        refetch({
          workspaceId: workspaceID,
          pageKeys: currentActiveRoute.id,
        });

        setSessionId(undefined);
      }
    }
  }, [sessionId, sessionLoading, asyncSession, refetch, workspaceID, currentActiveRoute.id]);

  const savedConfiguration = useMemo(() => {
    if (data?.appbuilder?.userConfiguration) {
      return pick(
        ['appDSL', 'userComponentsDSL', 'appVersion', 'schemaDSLVersion'],
        data?.appbuilder?.userConfiguration,
      ) as AppConfiguration;
    }
  }, [data]);

  const resultError = useMemo(() => {
    if (asyncSession?.status === ASYNC_SESSION_STATUSES.error) {
      return asyncSession?.result;
    }

    return sessionError?.message || error?.message;
  }, [error, sessionError, asyncSession]);

  return { savedConfiguration, loading, error: resultError, refetch };
};
