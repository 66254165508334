import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_ACTION_TYPE, } from '../../../constants';
import { getStyleSections, getDivider } from '../../common/settings';
export const BuilderComponentsRouteLayoutSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Common',
                    name: 'Common',
                    expandedByDefault: true,
                    titleDescription: {
                        title: 'Common',
                        subtitle: 'Common properties for all components',
                    },
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['title'],
                            nodePath: ['__parent'],
                            label: 'Title',
                        },
                    ],
                },
                getDivider(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.eventsSection,
                    name: 'events',
                    title: 'Events',
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.action,
                            name: 'onMountAction',
                            keyPath: ['onMount'],
                            label: 'Mount',
                            children: [
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.select,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['onMount', 'actionType'],
                                    resetPathBeforeOnChange: [['onMount', 'args']],
                                    label: 'Type',
                                    options: [
                                        {
                                            value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                                            label: 'Custom Code',
                                        },
                                    ],
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['onMount', 'args', 'code'],
                                    showIf: [
                                        {
                                            keyPath: ['onMount', 'actionType'],
                                            type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                            value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                                        },
                                    ],
                                    defaultValue: '// Your JavaScript code here',
                                    label: 'Custom Code',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
