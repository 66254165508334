import { omit } from 'ramda';

import { generateID } from '@builder/utils';

import { AppEvents, createReducer, DashboardState, DASHBOARD_EVENTS } from 'src/store/common';
import { dashboardInitial } from 'src/store/dashboard/initial-state';

import { createCSSMediaQuery } from './createCSSMediaQuery';
import { deleteCSSMediaQuery } from './deleteCSSMediaQuery';
import { updateCSSMediaQuery } from './updateCSSMedieaQuery';

export const MEDIA_QUERY_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.mediaQueryCreate,
  DASHBOARD_EVENTS.mediaQueryUpdate,
  DASHBOARD_EVENTS.mediaQueryDelete,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof MEDIA_QUERY_REDUCER_EVENTS[number]>({
    events: MEDIA_QUERY_REDUCER_EVENTS,
    reduce: (state, event): DashboardState => {
      switch (event.type) {
        case DASHBOARD_EVENTS.mediaQueryCreate: {
          const id = generateID();
          const { mediaQuery } = event;

          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: {
                ...state.appConfiguration.appDSL,
                theme: {
                  ...state.appConfiguration.appDSL.theme,
                  css: {
                    ...state.appConfiguration.appDSL.theme?.css,
                    mediaQueries: createCSSMediaQuery(state, mediaQuery),
                  },
                  mediaQueries: {
                    ...state.appConfiguration.appDSL.theme?.mediaQueries,
                    [id]: {
                      ...event.mediaQuery,
                      id,
                    },
                  },
                },
              },
            },
          };
        }

        case DASHBOARD_EVENTS.mediaQueryUpdate: {
          const { mediaQuery } = event;

          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: {
                ...state.appConfiguration.appDSL,
                theme: {
                  ...state.appConfiguration.appDSL.theme,
                  css: {
                    ...state.appConfiguration.appDSL.theme?.css,
                    mediaQueries: updateCSSMediaQuery(state, mediaQuery),
                  },
                  mediaQueries: {
                    ...state.appConfiguration.appDSL.theme?.mediaQueries,
                    [mediaQuery.id]: event.mediaQuery,
                  },
                },
              },
            },
          };
        }

        case DASHBOARD_EVENTS.mediaQueryDelete: {
          const { mediaQuery } = event;
          return {
            ...state,
            appConfiguration: {
              ...state.appConfiguration,
              appDSL: {
                ...state.appConfiguration.appDSL,
                theme: {
                  ...state.appConfiguration.appDSL.theme,
                  css: {
                    ...state.appConfiguration.appDSL.theme?.css,
                    mediaQueries: deleteCSSMediaQuery(state, mediaQuery),
                  },
                  mediaQueries: omit(
                    [mediaQuery.id],
                    state.appConfiguration.appDSL.theme?.mediaQueries,
                  ),
                },
              },
            },
          };
        }
      }
    },
  }),
]);

export const mediaQueryReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
