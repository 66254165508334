import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useContext, createContext } from 'react';
const RuntimeErrorContext = createContext({
    onAppAuditNotifications: () => undefined,
    onAppFunctionError: () => undefined,
});
export const RuntimeErrorProvider = ({ onAppAuditNotifications, onAppFunctionError, children, }) => {
    return (_jsx(RuntimeErrorContext.Provider, Object.assign({ value: { onAppAuditNotifications, onAppFunctionError } }, { children: children })));
};
export const useRuntimeErrorCallbacks = () => {
    return useContext(RuntimeErrorContext);
};
