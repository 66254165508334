import { libraryListSelectors, librarySelectors, IMPORT_DEPENDENCY_TYPES, } from '@builder/schemas';
import { IMPORT_FILE_SCOPE_TYPES } from '../../../constants';
import { hasUsedVarInJSCode, hasUsedVarInNodeListDSL } from '../../../utils';
import { LIBRARY_IMPORT_OPTIONS, PREDEFINED_IMPORTS } from './constants';
import { PredefinedStateGenerator } from './PredefinedStateGenerator';
/**
 * Provides an api to generate predefined state imports.
 */
export class PredefinedStateListImportsGenerator {
    constructor(componentListDSL, libraryListDSL) {
        this.predefinedStateGenerators = [];
        const predefinedImportsStateGenerators = PREDEFINED_IMPORTS.map(predefinedStateData => {
            return new PredefinedStateGenerator(predefinedStateData.importData, predefinedStateData.options);
        });
        const libraryImportsStateGenerators = libraryListSelectors
            .getLibraryArrayDSL(libraryListDSL)
            .map(libraryDSL => {
            const { libraryData } = librarySelectors.getLibraryDataAndVersion(libraryDSL);
            const varName = libraryData.importType === IMPORT_DEPENDENCY_TYPES.default
                ? IMPORT_DEPENDENCY_TYPES.default
                : libraryData.varName;
            const libraryImport = {
                importData: {
                    importType: varName === IMPORT_DEPENDENCY_TYPES.default ? undefined : libraryData.importType,
                    importName: libraryData.name,
                    varName,
                    varNameAlias: libraryDSL.alias,
                },
                options: LIBRARY_IMPORT_OPTIONS,
            };
            return new PredefinedStateGenerator(libraryImport.importData, libraryImport.options);
        });
        this.predefinedStateGenerators = [
            ...predefinedImportsStateGenerators,
            ...libraryImportsStateGenerators,
        ];
        this.componentListDSL = componentListDSL;
        this.libraries = libraryListDSL;
    }
    getExtractedComponentImportDataList(usedNodeListDSL) {
        const predefinedImportData = this.getPredefinedImports(IMPORT_FILE_SCOPE_TYPES.extractedComponent)
            .filter(predefinedStateGenerator => {
            if (!predefinedStateGenerator.mustBeOmitted()) {
                return true;
            }
            const usedVariable = predefinedStateGenerator.stateAccessor.getStateName();
            return hasUsedVarInNodeListDSL(usedVariable, usedNodeListDSL, this.componentListDSL);
        })
            .map(predefinedStateGenerator => predefinedStateGenerator.importData);
        return predefinedImportData;
    }
    getSymbolImportDataList(usedNodeListDSL) {
        const predefinedImportData = this.getPredefinedImports(IMPORT_FILE_SCOPE_TYPES.symbols)
            .filter(predefinedStateGenerator => {
            if (!predefinedStateGenerator.mustBeOmitted()) {
                return true;
            }
            const usedVariable = predefinedStateGenerator.stateAccessor.getStateName();
            return hasUsedVarInNodeListDSL(usedVariable, usedNodeListDSL, this.componentListDSL);
        })
            .map(predefinedStateGenerator => predefinedStateGenerator.importData);
        return predefinedImportData;
    }
    getHookImportDataList(hookCode) {
        const { libraries } = this;
        const predefinedImportData = this.getPredefinedImports(IMPORT_FILE_SCOPE_TYPES.hooks)
            .filter(predefinedStateGenerator => {
            if (!predefinedStateGenerator.mustBeOmitted()) {
                return true;
            }
            const usedVariable = predefinedStateGenerator.stateAccessor.getStateName();
            return (hasUsedVarInJSCode(usedVariable, hookCode) ||
                Object.keys(libraries !== null && libraries !== void 0 ? libraries : {}).includes(usedVariable));
        })
            .map(predefinedStateGenerator => predefinedStateGenerator.importData);
        return predefinedImportData;
    }
    getPredefinedImports(scope) {
        return this.predefinedStateGenerators.filter(predefinedStateGenerator => {
            return (predefinedStateGenerator.scopes.indexOf(scope) >= 0 ||
                predefinedStateGenerator.scopes.indexOf(IMPORT_FILE_SCOPE_TYPES.all) >= 0);
        });
    }
    getImportDataList(scope) {
        return this.getPredefinedImports(scope).map(predefinedStateGenerator => predefinedStateGenerator.importData);
    }
}
