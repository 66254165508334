/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isEmpty, mergeDeepLeft, path } from 'ramda';

import {
  NodeTemplateDSL,
  NodeID,
  PropAssert,
  nodeSelectors,
  nodeListSelectors,
  componentListSelectors,
  ComponentDSLNameTypes,
  StateTemplateDSL,
  ReactNodePropValue,
  SchemaOverrideDSL,
  generateNodeDSLFromTemplate,
} from '@builder/schemas';
import { ERROR_SCOPES, SystemError } from '@builder/utils';

import {
  ComponentCreateIntoMeta,
  ComponentCreateMeta,
  ComponentCreateRelativeMeta,
  DashboardState,
  DND_TARGET_TYPES,
} from '../../common';
import { parseReactNode } from '../utils';
import { dashboardSelectors } from 'src/store';

type NodePredefinedData = {
  name: ComponentDSLNameTypes;
  displayName?: string;
  alias?: string;
  props?: NodeTemplateDSL['props'];
  states?: StateTemplateDSL[];
  selectNodeOnCreate?: boolean;
  incrementAlias?: boolean;
  schemaOverride?: SchemaOverrideDSL;
  __presentationRouteName?: string;
  componentListMode?: string;
  shouldMergeProps?: boolean;
  currentRouteNodeDSLId?: string;
  currentRouteParentNodeDSLId?: string;
};

/**
 * Creates new nodes and states from the NodeTemplate and pastes them to the state
 */
const createNodeDSLFromTemplate = (
  state: DashboardState,
  {
    componentName,
    alias,
    predefinedProps = {},
    predefinedStates = [],
    selectNodeOnCreate = true,
    incrementAlias = true,
    parentID,
    parentPropName,
    toIndex,
    schemaOverride,
    currentRouteNodeDSLId,
    currentRouteParentNodeDSLId,
  }: {
    parentID: NodeID;
    alias?: string;
    parentPropName: Array<string | number>;
    toIndex: number;
    componentName: ComponentDSLNameTypes;
    predefinedProps?: NodeTemplateDSL['props'];
    predefinedStates?: StateTemplateDSL[];
    incrementAlias?: boolean;
    selectNodeOnCreate?: boolean;
    schemaOverride?: SchemaOverrideDSL;
    currentRouteNodeDSLId?: string;
    currentRouteParentNodeDSLId?: string;
  },
): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  const stateListDSL = dashboardSelectors.getStateListDSL(state);
  const parentNode = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: parentID });

  const parentChildrenProp = path<ReactNodePropValue>(parentPropName, parentNode.props) ?? {
    nodes: [],
  };
  PropAssert.Value.assertIsRenderableProp<NodeID>(parentChildrenProp, parentPropName.join('.'));

  const { appDSL: newUserAppStateWithUpdatedParent, rootNodeDSL } = generateNodeDSLFromTemplate(
    {
      appDSL: state.appConfiguration.appDSL,
      componentListDSL,
      nodeListDSL,
      stateListDSL,
    },
    {
      componentName,
      alias,
      predefinedProps,
      predefinedStates,
      selectNodeOnCreate,
      incrementAlias,
      parentID,
      parentPropName,
      toIndex,
      schemaOverride,
      currentRouteNodeDSLId,
      currentRouteParentNodeDSLId,
    },
  );

  const newOperations = selectNodeOnCreate
    ? {
        ...state.operations,
        selectedID: rootNodeDSL.id,
        lastCreatedID: rootNodeDSL.id,
      }
    : state.operations;
  return {
    ...state,
    operations: newOperations,
    appConfiguration: {
      ...state.appConfiguration,
      appDSL: newUserAppStateWithUpdatedParent,
    },
  };
};

const insertComponentInto = (
  state: DashboardState,
  place: ComponentCreateIntoMeta,
  {
    name: componentName,
    alias,
    props,
    states,
    incrementAlias = true,
    selectNodeOnCreate = true,
    schemaOverride,
    shouldMergeProps = false,
    currentRouteNodeDSLId,
    currentRouteParentNodeDSLId,
  }: NodePredefinedData,
): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, { componentName });
  const predefinedProps = shouldMergeProps
    ? mergeDeepLeft(props || {}, componentDSL.schema.predefineds?.props || {})
    : props || componentDSL.schema.predefineds?.props;

  const predefinedStates = states || componentDSL.schema.predefineds?.states || [];
  const intoTarget = place.target;
  const target = nodeListDSL[intoTarget.nodeID];
  const children = parseReactNode(path(intoTarget.propName, target.props));
  // @ts-ignore: Unreachable code error
  componentDSL?.schema?.predefineds?.props?.children?.nodes?.forEach(node => {
    if (node.props?.predefineds?.states !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      node.props?.predefineds?.states?.forEach((predefinedState: any) => {
        predefinedStates?.push(predefinedState);
      });
    }
  });

  return createNodeDSLFromTemplate(state, {
    componentName,
    alias,
    incrementAlias,
    parentID: intoTarget.nodeID,
    toIndex: children.nodes.length,
    parentPropName: intoTarget.propName,
    predefinedProps,
    predefinedStates,
    selectNodeOnCreate,
    schemaOverride,
    currentRouteNodeDSLId,
    currentRouteParentNodeDSLId,
  });
};

const insertComponentRelative = (
  state: DashboardState,
  place: ComponentCreateRelativeMeta,
  {
    name: componentName,
    props,
    states,
    alias,
    incrementAlias = true,
    selectNodeOnCreate = true,
    currentRouteNodeDSLId,
    currentRouteParentNodeDSLId,
  }: NodePredefinedData,
): DashboardState => {
  const componentListDSL = dashboardSelectors.getComponentListDSL(state);
  const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
  const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, { componentName });
  const predefinedProps = props || componentDSL.schema.predefineds?.props;
  const predefinedStates = states || componentDSL.schema.predefineds?.states || [];
  const targetID = place.target.nodeID;
  const targetNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID: targetID,
  });

  if (!targetNodeDSL.parentID)
    throw new SystemError(
      ERROR_SCOPES.dashboard,
      `Parent ID not found for the target ${JSON.stringify(targetNodeDSL)}`,
    );

  const parentNodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID: targetNodeDSL.parentID,
  });
  const parentNodeSchemaDSL = componentListDSL[parentNodeDSL.name].schema.props;
  const parentNodeDSLProps = parentNodeDSL.props;
  const parentPropName = nodeSelectors.findNodePlacedPropNameInParent(
    parentNodeSchemaDSL,
    parentNodeDSLProps,
    targetNodeDSL.id,
  );

  // @ts-ignore: Unreachable code error
  componentDSL?.schema?.predefineds?.props?.children?.nodes?.forEach(node => {
    if (node.props?.predefineds?.states !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      node.props?.predefineds?.states.forEach((predefinedState: any) =>
        predefinedStates?.push(predefinedState),
      );
    }
  });

  if (isEmpty(parentPropName)) {
    throw new SystemError(ERROR_SCOPES.dashboard, `Parent prop not found.`);
  }

  const childrenProp = path<ReactNodePropValue>(parentPropName, parentNodeDSL.props);
  PropAssert.Value.assertIsRenderableProp<NodeID>(childrenProp, parentPropName.join('.'));

  // find the index where to insert new node
  let toIndex = 0;
  if (childrenProp) {
    toIndex = (childrenProp.nodes.indexOf(targetID) ?? 0) + (place.type === 'after' ? 1 : 0);
  }

  return createNodeDSLFromTemplate(state, {
    componentName,
    alias,
    incrementAlias,
    parentID: targetNodeDSL.parentID,
    toIndex,
    parentPropName,
    predefinedProps,
    predefinedStates,
    selectNodeOnCreate,
    currentRouteNodeDSLId,
    currentRouteParentNodeDSLId,
  });
};

export const componentCreate = (
  state: DashboardState,
  place: ComponentCreateMeta,
  nodePredefinedData: NodePredefinedData,
): DashboardState => {
  if (place.target.type === DND_TARGET_TYPES.into) {
    return insertComponentInto(state, place as ComponentCreateIntoMeta, nodePredefinedData);
  }

  return insertComponentRelative(state, place as ComponentCreateRelativeMeta, nodePredefinedData);
};
