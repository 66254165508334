var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ComponentDSLValidator_1;
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ERROR_SCOPES, isObject, SchemaValidationError } from '@builder/utils';
import { COMPONENT_DSL_TYPES } from '../../../constants';
import { staticImplements } from '../types';
let ComponentDSLValidator = ComponentDSLValidator_1 = class ComponentDSLValidator {
    static checkValid(componentDSLMaybe) {
        if (!(componentDSLMaybe === null || componentDSLMaybe === void 0 ? void 0 : componentDSLMaybe.name)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Component DSL has no name`);
        }
    }
    static checkValidSymbolTree(componentDSLMaybe) {
        ComponentDSLValidator_1.checkValid(componentDSLMaybe);
        if (componentDSLMaybe.type === COMPONENT_DSL_TYPES.symbol) {
            if (!isObject(componentDSLMaybe.schema.symbolNodes)) {
                throw new SchemaValidationError(ERROR_SCOPES.schemas, `Symbol component DSL must has 'symbolNodes' prop`);
            }
        }
    }
};
ComponentDSLValidator = ComponentDSLValidator_1 = __decorate([
    staticImplements()
], ComponentDSLValidator);
export { ComponentDSLValidator };
