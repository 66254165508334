import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, PRESENTATIONS, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsDataGridSettings } from './BuilderComponentsDataGrid.settings';
export const BuilderComponentsDataGrid = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsDataGrid,
    displayName: 'Table',
    icon: 'table',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'DataGrid',
    },
    schema: {
        presentations: [
            visibleByCondition,
            {
                name: PRESENTATIONS.loading,
                props: {
                    loading: true,
                },
            },
            {
                name: PRESENTATIONS.empty,
                props: {
                    rows: [],
                },
            },
        ],
        predefineds: {
            props: {
                autoHeight: true,
                pageSize: 5,
                rowsPerPageOptions: [5, 10, 15],
                rows: [
                    {
                        id: 1,
                        lastName: 'Snow',
                        firstName: 'Jon',
                        age: 35,
                        birthday: '1975-03-12',
                        balance: 186,
                    },
                    {
                        id: 2,
                        lastName: 'Lannister',
                        firstName: 'Cersei',
                        birthday: '1965-05-07',
                        age: 42,
                        balance: 52,
                    },
                    {
                        id: 3,
                        lastName: 'Lannister',
                        firstName: 'Jaime',
                        birthday: '1990-06-01',
                        age: 45,
                    },
                    {
                        id: 4,
                        lastName: 'Stark',
                        firstName: 'Arya',
                        birthday: '1987-03-22',
                        age: 16,
                        balance: 113,
                    },
                    {
                        id: 5,
                        lastName: 'Targaryen',
                        firstName: 'Daenerys',
                        birthday: '1987-03-11',
                        balance: 7431,
                    },
                    {
                        id: 6,
                        lastName: 'Melisandre',
                        birthday: '1995-01-15',
                        balance: 224,
                        age: 150,
                    },
                    {
                        id: 7,
                        lastName: 'Clifford',
                        firstName: 'Ferrara',
                        age: 44,
                        balance: 1241,
                    },
                    {
                        id: 8,
                        lastName: 'Frances',
                        firstName: 'Rossini',
                        birthday: '1998-06-09',
                        age: 36,
                        balance: 789,
                    },
                    {
                        id: 9,
                        lastName: 'Roxie',
                        firstName: 'Harvey',
                        birthday: '2001-03-12',
                        age: 65,
                        balance: 253,
                    },
                ],
                columns: [
                    {
                        field: 'id',
                        headerName: 'ID',
                        width: 100,
                    },
                    {
                        field: 'lastName',
                        headerName: 'Last Name',
                        width: 200,
                    },
                    {
                        field: 'firstName',
                        headerName: 'First Name',
                        width: 200,
                    },
                    {
                        field: 'birthday',
                        headerName: 'Birthday',
                        width: 100,
                    },
                    {
                        field: 'balance',
                        headerName: 'Balance',
                        width: 100,
                    },
                ],
                primaryField: 'id',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { autoHeight: { type: COMPONENT_DSL_PROP_TYPES.boolean }, autoPageSize: { type: COMPONENT_DSL_PROP_TYPES.boolean }, checkboxSelection: { type: COMPONENT_DSL_PROP_TYPES.boolean }, columns: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                    props: {
                        field: { type: COMPONENT_DSL_PROP_TYPES.string },
                        headerName: { type: COMPONENT_DSL_PROP_TYPES.string },
                        width: { type: COMPONENT_DSL_PROP_TYPES.number },
                        type: { type: COMPONENT_DSL_PROP_TYPES.string },
                        hide: { type: COMPONENT_DSL_PROP_TYPES.boolean },
                        valueFormatter: {
                            type: COMPONENT_DSL_PROP_TYPES.callback,
                            body: COMPONENT_DSL_CALLBACK_TYPES.code,
                            args: [
                                { name: 'id', path: [0, 'id'] },
                                { name: 'field', path: [0, 'field'] },
                                { name: 'value', path: [0, 'value'] },
                                { name: 'api', path: [0, 'api'] },
                            ],
                        },
                        valueGetter: {
                            type: COMPONENT_DSL_PROP_TYPES.callback,
                            body: COMPONENT_DSL_CALLBACK_TYPES.code,
                            args: [{ name: 'item', path: [0, 'row'] }],
                        },
                        renderCell: {
                            type: COMPONENT_DSL_PROP_TYPES.callback,
                            body: COMPONENT_DSL_CALLBACK_TYPES.component,
                            pathToUniqueKey: [0, 'row', 'id'],
                            args: [{ name: 'item', path: [0, 'row'] }],
                        },
                    },
                },
            }, columnBuffer: { type: COMPONENT_DSL_PROP_TYPES.number }, columnTypes: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, components: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, density: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, disableExtendRowFullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableSelectionOnClick: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableColumnSelector: { type: COMPONENT_DSL_PROP_TYPES.boolean }, error: { type: COMPONENT_DSL_PROP_TYPES.string }, headerHeight: { type: COMPONENT_DSL_PROP_TYPES.number }, hideFooter: { type: COMPONENT_DSL_PROP_TYPES.boolean }, hideFooterPagination: { type: COMPONENT_DSL_PROP_TYPES.boolean }, hideFooterSelectedRowCount: { type: COMPONENT_DSL_PROP_TYPES.boolean }, icons: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, loading: { type: COMPONENT_DSL_PROP_TYPES.boolean }, logger: { type: COMPONENT_DSL_PROP_TYPES.object }, logLevel: { type: COMPONENT_DSL_PROP_TYPES.string }, nonce: { type: COMPONENT_DSL_PROP_TYPES.string }, onCellClick: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onCellHover: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onColumnHeaderClick: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onPageChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onPageSizeChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onRowClick: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onRowHover: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onRowSelected: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onSelectionChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, onSortModelChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, page: { type: COMPONENT_DSL_PROP_TYPES.number }, pageSize: { type: COMPONENT_DSL_PROP_TYPES.number, maxValue: 100 }, paginationMode: { type: COMPONENT_DSL_PROP_TYPES.enum }, rows: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: {
                    type: COMPONENT_DSL_PROP_TYPES.object,
                },
            }, rowCount: { type: COMPONENT_DSL_PROP_TYPES.number }, rowHeight: { type: COMPONENT_DSL_PROP_TYPES.number }, rowsPerPageOptions: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                item: { type: COMPONENT_DSL_PROP_TYPES.number },
            }, scrollbarSize: { type: COMPONENT_DSL_PROP_TYPES.number }, showCellRightBorder: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showColumnRightBorder: { type: COMPONENT_DSL_PROP_TYPES.boolean }, sortingMode: { type: COMPONENT_DSL_PROP_TYPES.enum }, sortingOrder: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, sortModel: { type: COMPONENT_DSL_PROP_TYPES.notSupported }, showTableBorder: { type: COMPONENT_DSL_PROP_TYPES.boolean }, primaryField: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsDataGridSettings,
};
