var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef, useCallback, useRef, useState, useEffect, } from 'react';
import { InputAdornment, TextField as MuiTextField, } from '@mui/material';
import debounce from 'lodash.debounce';
import { isNil } from 'ramda';
import { fieldValidation } from '../common';
export const TextField = forwardRef((_a, ref) => {
    var { debounce: debounceTime, onChange, value, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, id, title, lang, translate, InputProps = {}, helperText, helperErrorText, regularExpression, validation, error } = _a, rest = __rest(_a, ["debounce", "onChange", "value", 'data-test', 'data-node-id', 'data-node-render-path', "id", "title", "lang", "translate", "InputProps", "helperText", "helperErrorText", "regularExpression", "validation", "error"]);
    const [localValue, setLocalValue] = useState(value);
    const createDebounceCallback = useCallback((debounceTimeArg) => {
        return debounce((event, nextValue) => {
            // eslint-disable-next-line no-param-reassign
            event.target.value = nextValue;
            onChange === null || onChange === void 0 ? void 0 : onChange(event);
        }, debounceTimeArg);
    }, [onChange]);
    useEffect(() => {
        setLocalValue(value);
    }, [value]);
    const shrinkLabel = ['date', 'time', 'color', 'month', 'datetime-local', 'week'].includes(rest.type);
    const [errorType, setErrorType] = useState(false);
    const handleValidation = (event) => {
        const validate = fieldValidation(event.target.value, validation, regularExpression, InputProps.required);
        setErrorType(!!validate);
    };
    const debouncedChangePropValue = useRef(createDebounceCallback(debounceTime));
    useEffect(() => {
        debouncedChangePropValue.current = createDebounceCallback(debounceTime);
    }, [createDebounceCallback, debounceTime]);
    const setDebouncedValue = (event) => {
        setLocalValue(event.target.value);
        if (isNil(debounceTime)) {
            onChange === null || onChange === void 0 ? void 0 : onChange(event);
        }
        else {
            debouncedChangePropValue.current(event, event.target.value);
        }
    };
    const { startAdornment: initialStartAdornment, endAdornment: initialEndAdornment } = InputProps, restInputProps = __rest(InputProps, ["startAdornment", "endAdornment"]);
    const { style: styleProps, className } = rest, restProps = __rest(rest, ["style", "className"]);
    // I'm not sure if it's necessary but this is the way how
    // They add adornments in the TextFields (In the documentation)
    // https://material-ui.com/components/text-fields/#icons
    const startAdornment = initialStartAdornment && (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: initialStartAdornment })));
    const endAdornment = initialEndAdornment && (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: initialEndAdornment })));
    return (_jsx(MuiTextField, Object.assign({ ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, lang: lang, style: styleProps, translate: translate, error: errorType || error, helperText: errorType ? helperErrorText : helperText, sx: (restProps === null || restProps === void 0 ? void 0 : restProps.fullWidth) ? {} : { width: 'fit-content' }, InputLabelProps: { shrink: shrinkLabel ? true : undefined }, InputProps: Object.assign({ startAdornment,
            endAdornment,
            className }, restInputProps) }, restProps, { value: localValue, onChange: event => {
            handleValidation(event);
            setDebouncedValue(event);
        } })));
});
