import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialAppBarSettings } from './MaterialAppBar.settings';
export const MaterialAppBar = {
    name: COMPONENT_DSL_NAMES.MaterialAppBar,
    displayName: 'Header',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'AppBar',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                color: 'primary',
                position: 'relative',
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.MaterialToolbar],
            }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, position: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialAppBarSettings,
};
