var _a, _b, _c, _d;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_CALLBACK_TYPES, STATE_TYPES, STATE_SCOPES, TYPE_DEFINITION_TYPES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsRadio } from '../../form-components/BuilderComponentsRadio';
import { MaterialTypography } from '../MaterialTypography';
import { BuilderComponentsRadioGroupSettings } from './BuilderComponentsRadioGroup.settings';
export const BuilderComponentsRadioGroup = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsRadioGroup,
    displayName: 'Radio Button Group',
    icon: 'radioGroup',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'RadioGroup',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'radioGroup',
                    defaultValue: 'value1',
                },
            ],
            props: {
                label: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialFormLabel,
                            alias: 'Radio Group Label',
                            props: {
                                component: 'label',
                                children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Radio Group',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                row: false,
                value: '{{ __STATES__.radioGroup.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.radioGroup.setValue(value)',
                    },
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsRadio.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { label: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                            alias: 'Radio Label',
                                            schemaOverride: {
                                                interaction: 'only-editable',
                                            },
                                            props: Object.assign(Object.assign({}, (_b = MaterialTypography.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'Value 1',
                                                            },
                                                        },
                                                    ],
                                                } }),
                                        },
                                    ],
                                }, value: 'value1' }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsRadio,
                            props: Object.assign(Object.assign({}, (_c = BuilderComponentsRadio.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { label: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                                            alias: 'Radio Label',
                                            schemaOverride: {
                                                interaction: 'only-editable',
                                            },
                                            props: Object.assign(Object.assign({}, (_d = MaterialTypography.schema.predefineds) === null || _d === void 0 ? void 0 : _d.props), { children: {
                                                    nodes: [
                                                        {
                                                            name: COMPONENT_DSL_NAMES.Text,
                                                            props: {
                                                                children: 'Value 2',
                                                            },
                                                        },
                                                    ],
                                                } }),
                                        },
                                    ],
                                }, value: 'value2' }),
                        },
                    ],
                },
                style: getPredefinedStyles(),
            },
        },
        dndTargetPropName: 'children',
        props: Object.assign(Object.assign({}, commonComponentProps), { label: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.BuilderComponentsRadio, COMPONENT_DSL_NAMES.Iterator],
            }, row: { type: COMPONENT_DSL_PROP_TYPES.boolean }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.object,
                            typeDefinition: {
                                type: TYPE_DEFINITION_TYPES.import,
                                definition: 'ChangeEvent<HTMLInputElement>',
                                imports: [
                                    {
                                        varName: 'ChangeEvent',
                                        importName: 'react',
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: 'value',
                        path: [1],
                        schema: {
                            type: COMPONENT_DSL_PROP_TYPES.string,
                        },
                    },
                ],
            }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: BuilderComponentsRadioGroupSettings,
};
