import React, { useLayoutEffect, useMemo, useRef } from 'react';

import styled from '@emotion/styled';

import { getComponentDataProps, COMPONENT_DSL_NAMES } from '@builder/schemas';
import { isArray, isNil, Z_INDEXES_BUILDER } from '@builder/utils';

import { USER_APP_IFRAME_ID } from '../constants';
import { useDashboardAnimState } from 'src/providers/ReduxProvider';
import { ComponentPresentationOnly } from 'src/shared/components';

import {
  BasicPreview,
  getPreviewTransform,
  IconPreview,
  PREVIEW_ICON_SIZE,
  PREVIEW_SIZE,
} from './common';
import { useBaseLayer } from './DndBaseLayer';

const DndDashboardLayerContainer = styled.div`
  position: absolute;
  z-index: ${Z_INDEXES_BUILDER.dndCustomLayer};
  // all pointers are removed: very important part!
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

/**
 * Custom dnd layer monitors whatever is being dragged at the moment
 * and if the item is over the layer -- layer is in control of the item presentation!
 *
 * Custom layer renders a draggable over it.
 */
export const DndDashboardLayer = (): JSX.Element | null => {
  const draggablePreviewRef = useRef<HTMLDivElement | null>(null);
  const previewCloneDataRef = useRef<{ nodeClone: HTMLElement; styles: string }[] | null>();
  const previewSizeRef = useRef({ width: PREVIEW_SIZE, height: PREVIEW_SIZE });

  /**
   * Prefer using refs instead of states, since this thing tick on `mousemove` (very often).
   * You don't wont this to freeze or lag on occasional `setState`.
   */

  const { isDragging, initialOffset, currentOffset, item } = useBaseLayer({
    draggablePreviewRef,
    previewSizeRef,
  });
  const itemType = useMemo(() => (isArray(item) ? item.map(x => x?.type) : item?.type), [item]);
  const isOverlayItem = isArray(item);

  useLayoutEffect(
    function updatePreviewHost() {
      if (
        isDragging &&
        isOverlayItem &&
        draggablePreviewRef.current &&
        !draggablePreviewRef.current.childNodes.length
      ) {
        const shadowRoot = draggablePreviewRef.current.attachShadow({ mode: 'closed' });
        if (previewCloneDataRef.current) {
          previewCloneDataRef.current.forEach(cloneData => {
            const { nodeClone, styles } = cloneData;
            // creating a shadow root to encapsulate styles of a clone
            // shadow will have a container which will actually host a clone
            const underShadowContainer = document.createElement('div');
            // making it into a flex-column to force clone to resize properly to fit the preview
            underShadowContainer.style.display = 'flex';
            underShadowContainer.style.flexDirection = 'column';

            // adding all the styles
            const styleNode = document.createElement('style');
            styleNode.innerHTML = styles;

            // appending the styles
            underShadowContainer.appendChild(styleNode);

            // appending the clone
            underShadowContainer.appendChild(nodeClone);

            // appending everything to the shadow-root
            shadowRoot.appendChild(underShadowContainer);
          });
        }
      }
    },
    // only want to render if the itemType of a dragging state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOverlayItem, itemType],
  );

  const draggablePreview = useMemo(
    () => {
      // if nothing is getting dragged - no preview should be rendered
      if (!isDragging) {
        draggablePreviewRef.current = null;
        previewCloneDataRef.current = null;
        return null;
      }

      previewSizeRef.current.width = PREVIEW_SIZE;
      previewSizeRef.current.height = PREVIEW_SIZE;

      // if the icon is being dragged
      if (!isOverlayItem) {
        previewSizeRef.current.width = PREVIEW_ICON_SIZE;
        previewSizeRef.current.height = PREVIEW_ICON_SIZE;
        return (
          <IconPreview
            ref={draggablePreviewRef}
            {...getPreviewTransform({
              initialOffset,
              currentOffset,
              previewWidth: previewSizeRef.current.width,
              previewHeight: previewSizeRef.current.height,
            })}
          >
            <ComponentPresentationOnly
              isDragging={isDragging}
              title={item?.meta?.title}
              icon={item?.meta?.icon}
              isLayoutEditorButton={item?.meta?.name === COMPONENT_DSL_NAMES.RouterSwitch}
            />
          </IconPreview>
        );
      }

      // if dashboard node is being dragged
      if (isOverlayItem) {
        const iframe = document.querySelector(
          `[data-test="${USER_APP_IFRAME_ID}"]`,
        ) as HTMLIFrameElement;

        let highestWidth = 0;
        let highestHeight = 0;

        const itemsData = item
          .map(draggableItem => {
            const dataProps = getComponentDataProps({
              nodeID: draggableItem.id,
              nodeName: draggableItem.name,
            });
            const dataNodeID = dataProps['data-node-id'];
            const dataName = dataProps['data-test'];
            const dataQuerySelector = `[data-node-id="${dataNodeID}"][data-test="${dataName}"]`;
            const el = iframe?.contentWindow?.document.querySelector<HTMLElement>(
              dataQuerySelector,
            ) as HTMLElement;

            if (el) {
              const nodeClone = el.cloneNode(true) as HTMLElement;
              const styleNodes = iframe?.contentWindow?.document.querySelectorAll('style') || [];
              const styles = [...styleNodes]
                .flatMap(style => [...(style.sheet?.cssRules || [])])
                .map(rule => rule.cssText)
                .join('\n');

              highestWidth = el.offsetWidth > highestWidth ? el.offsetWidth : highestWidth;
              highestHeight = el.offsetHeight > highestHeight ? el.offsetHeight : highestHeight;

              previewSizeRef.current.width = highestWidth;
              previewSizeRef.current.height = highestHeight;

              return { nodeClone, styles };
            }

            return null;
          })
          .filter(data => !isNil(data)) as { nodeClone: HTMLElement; styles: string }[];

        previewCloneDataRef.current = itemsData;

        return (
          /* using this one to drag a preview around */
          <div
            ref={draggablePreviewRef}
            style={{
              background: 'white',
              boxShadow: `0 0 1px 1px rgba(0, 0, 0, 0.1)`,
              opacity: 0.7,
              width: previewSizeRef.current.width,
              height: 20,
              ...getPreviewTransform({
                initialOffset,
                currentOffset,
                previewWidth: previewSizeRef.current.width,
                previewHeight: 20,
                scaleDown: true,
              }),
            }}
          />
        );
      }

      // if whatever else is being dragged
      return (
        <BasicPreview
          ref={draggablePreviewRef}
          {...getPreviewTransform({
            initialOffset,
            currentOffset,
            previewWidth: previewSizeRef.current.width,
            previewHeight: previewSizeRef.current.height,
          })}
        />
      );
    },
    // only want to render if the itemType of a dragging state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOverlayItem, itemType],
  );

  const { dndOverNavigation } = useDashboardAnimState();
  useLayoutEffect(
    // updating preview transform style every tick of movement
    function hidePreviewWhenOverNavigation() {
      if (draggablePreviewRef.current && isDragging) {
        const el = draggablePreviewRef.current;
        switch (true) {
          case dndOverNavigation && el.style.display !== 'none':
            el.style.display = 'none';
            break;

          case !dndOverNavigation && el.style.display !== 'block':
            el.style.display = 'block';
            break;
        }
      }
    },
    // we are only interested in coordinates changing
    // eslint-disable-next-line
    [initialOffset, currentOffset],
  );

  return <DndDashboardLayerContainer>{draggablePreview}</DndDashboardLayerContainer>;
};
