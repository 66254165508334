import { tail } from 'ramda';
import { ERROR_SCOPES, SchemaError } from '@builder/utils';
import { COMPONENT_SETTING_TYPES } from '../../constants';
import { SettingAssert } from '../../validation';
/**
 * @example
 * ([['display'], ['size', 'width'],  ['size', 'height']]) => ['display']
 */
const getOneLevelSettingPathList = (settingPathList) => {
    return settingPathList.filter(settingPath => settingPath.length === 1).flat();
};
/**
 * @example
 * ([['display'], ['size', 'width'],  ['size', 'height']]) => { size: [['width'], ['height']] }
 */
const getTailsSettingPathList = (settingPathList) => {
    return settingPathList
        .filter(settingPath => settingPath.length > 1)
        .reduce((accum, settingPath) => {
        var _a;
        const settingName = settingPath[0];
        const currentTailPath = (_a = accum[settingName]) !== null && _a !== void 0 ? _a : [];
        return Object.assign(Object.assign({}, accum), { [settingName]: [...currentTailPath, tail(settingPath)] });
    }, {});
};
/**
 * Returns settings which was described in the @settingPathList
 */
export const pickSettings = (settingPathList, { withStructure = true } = {}) => settingList => {
    const oneLevelSettingPathList = getOneLevelSettingPathList(settingPathList);
    const tailsSettingPathList = getTailsSettingPathList(settingPathList);
    settingPathList.forEach(settingPath => {
        if (!settingList.find(setting => setting.name === settingPath[0])) {
            throw new SchemaError(ERROR_SCOPES.schemas, `Setting with name "${settingPath[0]}" not found.`);
        }
    });
    const pickedSettingList = settingList
        .map(setting => {
        if (setting.type === COMPONENT_SETTING_TYPES.divider) {
            return setting;
        }
        if (oneLevelSettingPathList.some(settingName => setting.name === settingName)) {
            return setting;
        }
        if (Object.keys(tailsSettingPathList).some(settingName => setting.name === settingName)) {
            SettingAssert.Schema.assertHasChildrenSetting(setting);
            SettingAssert.Schema.assertHasName(setting);
            const pickedSetting = pickSettings(tailsSettingPathList[setting.name], { withStructure })(setting.children);
            if (withStructure) {
                return Object.assign(Object.assign({}, setting), { children: pickedSetting });
            }
            return pickedSetting;
        }
        return null;
    })
        .flat()
        .filter(Boolean);
    return trimDividers(removeDividerDuplicates(pickedSettingList));
};
/**
 * Removes settings which was described in the @settingPathList
 */
export const omitSettings = (settingPathList) => settingList => {
    const oneLevelSettingPathList = getOneLevelSettingPathList(settingPathList);
    const tailsSettingPathList = getTailsSettingPathList(settingPathList);
    settingPathList.forEach(settingPath => {
        if (!settingList.find(setting => setting.name === settingPath[0])) {
            throw new SchemaError(ERROR_SCOPES.schemas, `Setting with name "${settingPath[0]}" not found.`);
        }
    });
    const omittedSettingList = settingList
        .map(setting => {
        if (oneLevelSettingPathList.some(settingName => setting.name === settingName)) {
            return null;
        }
        if (Object.keys(tailsSettingPathList).some(settingName => setting.name === settingName)) {
            SettingAssert.Schema.assertHasChildrenSetting(setting);
            SettingAssert.Schema.assertHasName(setting);
            return Object.assign(Object.assign({}, setting), { children: omitSettings(tailsSettingPathList[setting.name])(setting.children) });
        }
        return setting;
    })
        .filter(Boolean);
    return trimDividers(removeDividerDuplicates(omittedSettingList));
};
/**
 * @returns List of settings without divider duplicates
 */
export const removeDividerDuplicates = settingList => {
    return settingList.filter((settings, index) => {
        var _a;
        if (settings.type === COMPONENT_SETTING_TYPES.divider &&
            ((_a = settingList[index + 1]) === null || _a === void 0 ? void 0 : _a.type) === COMPONENT_SETTING_TYPES.divider) {
            return false;
        }
        return true;
    });
};
/**
 * @returns List of settings without dividers
 */
export const removeAllDividers = settingList => {
    return settingList.filter((settings, index) => {
        if (settings.type === COMPONENT_SETTING_TYPES.divider) {
            return false;
        }
        return true;
    });
};
/**
 * @returns List of settings without divider at the start and end
 */
export const trimDividers = settingList => {
    return settingList.filter((settings, index) => {
        if ((settings.type === COMPONENT_SETTING_TYPES.divider && index === 0) ||
            (settings.type === COMPONENT_SETTING_TYPES.divider && index === settingList.length - 1)) {
            return false;
        }
        return true;
    });
};
export const disableRadioOptions = (optionNames) => (setting) => {
    SettingAssert.Schema.assertIsRadioPropView(setting);
    return Object.assign(Object.assign({}, setting), { options: setting.options.map(option => optionNames.includes(option.value) ? Object.assign(Object.assign({}, option), { disabled: true }) : option) });
};
export const disableSpacingPaddings = (setting) => {
    SettingAssert.Schema.assertIsSpacingEditorPropView(setting);
    return Object.assign(Object.assign({}, setting), { disablePaddings: true });
};
/**
 * @returns List of settings with overrode setting.
 */
export const assocSettings = (settingPath, callback) => settingList => {
    const settingName = settingPath[0];
    if (!settingList.find(setting => setting.name === settingName)) {
        throw new SchemaError(ERROR_SCOPES.schemas, `Setting with name "${settingName}" not found.`);
    }
    return settingList.map(setting => {
        if (setting.name !== settingName) {
            return setting;
        }
        if (settingPath.length > 1) {
            SettingAssert.Schema.assertHasChildrenSetting(setting);
            return Object.assign(Object.assign({}, setting), { children: assocSettings(tail(settingPath), callback)(setting.children) });
        }
        return callback(setting);
    });
};
