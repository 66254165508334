import { nodeListSelectors } from '@builder/schemas';

import { AppEvents, createReducer, DashboardState, DASHBOARD_EVENTS } from 'src/store/common';
import { dashboardInitial } from 'src/store/dashboard/initial-state';
import { dashboardSelectors } from 'src/store/selectors';

export const NAVIGATOR_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.navigatorCollapseNode,
  DASHBOARD_EVENTS.navigatorExpandNode,
  DASHBOARD_EVENTS.navigatorExpandNodeList,
  DASHBOARD_EVENTS.navigatorCollapseNodeList,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof NAVIGATOR_REDUCER_EVENTS[number]>({
    events: NAVIGATOR_REDUCER_EVENTS,
    reduce: (state, event): DashboardState => {
      const navigatorState = state.navigator;

      switch (event.type) {
        case DASHBOARD_EVENTS.navigatorCollapseNode: {
          const { nodeID } = event;

          if (event.recursive) {
            const componentListDSL = dashboardSelectors.getComponentListDSL(state);

            const collapsedNodes = nodeListSelectors
              .getAllChildrenIDs(state.appConfiguration.appDSL.nodes, { nodeID, componentListDSL })
              .reduce<Record<string, boolean>>(
                (acc, childNodeID) => ({ ...acc, [childNodeID]: false }),
                { ...state.navigator.collapsedNodes, [event.nodeID]: false },
              );

            return {
              ...state,
              navigator: {
                ...state.navigator,
                collapsedNodes,
              },
            };
          }

          return {
            ...state,
            navigator: {
              ...state.navigator,
              collapsedNodes: { ...state.navigator.collapsedNodes, [nodeID]: true },
            },
          };
        }

        case DASHBOARD_EVENTS.navigatorExpandNode: {
          const targetNodeDSL = nodeListSelectors.getNodeDSL(state.appConfiguration.appDSL.nodes, {
            nodeID: event.nodeID,
          });

          const collapsedNodes = nodeListSelectors
            .getParents(state.appConfiguration.appDSL.nodes, { nodeID: event.nodeID })
            .concat([targetNodeDSL])
            .reduce<Record<string, boolean>>(
              (acc, parentOrTarget) => ({ ...acc, [parentOrTarget.id]: false }),
              { ...state.navigator.collapsedNodes },
            );

          return {
            ...state,
            navigator: {
              ...state.navigator,
              collapsedNodes,
            },
          };
        }

        case DASHBOARD_EVENTS.navigatorExpandNodeList: {
          const collapsedNodes = event.nodeIDs.reduce((acc, id) => ({ ...acc, [id]: false }), {});

          return {
            ...state,
            navigator: {
              ...navigatorState,
              collapsedNodes: {
                ...navigatorState.collapsedNodes,
                ...collapsedNodes,
              },
            },
          };
        }

        case DASHBOARD_EVENTS.navigatorCollapseNodeList: {
          const collapsedNodes = event.nodeIDs.reduce((acc, id) => ({ ...acc, [id]: true }), {});

          return {
            ...state,
            navigator: {
              ...navigatorState,
              collapsedNodes: {
                ...navigatorState.collapsedNodes,
                ...collapsedNodes,
              },
            },
          };
        }
      }
    },
  }),
]);

export const navigationReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
