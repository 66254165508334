import React, { useCallback, useEffect, useState } from 'react';

import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { Input, Paper, Popper } from '@mui/material';
import { $getSelection, $isRangeSelection, LexicalEditor, SELECTION_CHANGE_COMMAND } from 'lexical';

import { getSelectedNode } from './utils';

export default function FloatingLinkEditor({
  editor,
  anchorEl,
  hideEditor,
}: {
  editor: LexicalEditor;
  anchorEl: HTMLElement | null;
  hideEditor: () => void;
}): JSX.Element | null {
  const isOpen = Boolean(anchorEl);
  const [linkUrl, setLinkUrl] = useState('');

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }

    return true;
  }, []);

  const onPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();

      if (e.key !== 'Enter') return;

      if (linkUrl !== '') {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
        hideEditor();
        return;
      }

      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      hideEditor();
    },
    [editor, hideEditor, linkUrl],
  );

  // keep the editor state in sync
  useEffect(() => {
    const selectionChangeListener = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        4,
      ),
    );

    return selectionChangeListener();
  }, [editor, updateLinkEditor]);

  // set Initial state for the editor input
  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  // save history
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        1,
      ),
    );
  }, [editor, updateLinkEditor]);

  return isOpen ? (
    <Popper placement="left-start" id={anchorEl?.id} anchorEl={anchorEl} open={isOpen}>
      <Paper sx={{ padding: 2 }}>
        <Input
          onChange={e => {
            setLinkUrl(e.target.value);
          }}
          onKeyDown={onPressEnter}
          value={linkUrl}
          placeholder="Link"
        />
      </Paper>
    </Popper>
  ) : null;
}
