import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { ComponentSettingConditionDSL, JSInjection } from '@builder/schemas';
import { isBoolean, memo, useDebouncedState } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { CustomCodeViewEditor } from '../../setting-views';
import { JsCodeInjectionInput } from '../../setting-views/common/JsCodeInjectionInput';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

type ConditionTypeProps = {
  setting: ComponentSettingConditionDSL;
  onChangeConditionValue: (propValue?: JSInjection) => void;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
};

export const ConditionType = memo(
  'ConditionType',
  ({
    setting,
    onChangeConditionValue,
    'data-test': dataTest,
    showFx = true,
    fxDefaultEnabled = ACTIVEPROP.literal,
  }: ConditionTypeProps): JSX.Element => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const { label, skipDebounce } = setting;
    const nodeID = selectedNodeDSL.id;
    const conditionValue = selectedNodeDSL.condition?.showIf;
    const jsConditionValue = isBoolean(conditionValue) ? `{{ ${conditionValue}}}` : conditionValue;

    const [debouncedValue, setDebouncedValue] = useDebouncedState<string | undefined>(
      jsConditionValue,
      onChangeConditionValue,
      { skipDebounce },
    );

    const updateValueByEvent = useCallback(
      (newValue?: string) => {
        setDebouncedValue(newValue);
      },
      [setDebouncedValue],
    );

    return (
      <JsCodeInjectionInput
        label={label}
        onChangePropValue={setDebouncedValue}
        nodeID={nodeID}
        showFx={showFx}
        propValue={debouncedValue}
        data-test={dataTest}
        fxDefaultEnabled={fxDefaultEnabled}
        typeField="boolean"
      >
        {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
          <Grid item xs={12}>
            <CustomCodeViewEditor
              label={label}
              value={nonJsCodePropValue ?? (debouncedValue || '')}
              onChange={updateValueByEvent}
              nodeID={nodeID}
              showFx={showFx}
              isFxEnabled={isFxEnabled}
              enableFx={enableFx}
              placeholder="true"
              data-test={dataTest}
            />
          </Grid>
        )}
      </JsCodeInjectionInput>
    );
  },
);
