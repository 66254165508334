import { createStateError } from '../../../../app-audit';
/**
 * Class pass state errors in appropriate format.
 */
export class StateErrorGetter {
    constructor(stateID, onAppAuditNotifications) {
        this.stateID = stateID;
        this.onAppAuditNotifications = onAppAuditNotifications;
    }
    passErrorNotification({ message }) {
        this.onAppAuditNotifications([
            createStateError({
                stateID: this.stateID,
                message,
            }),
        ]);
    }
}
