import * as changeCase from 'change-case';
import { appNodesSchemaSelectors, componentListSelectors, nodeListSelectors, nodeSelectors, } from '@builder/schemas';
import { ERROR_SCOPES, SchemaError } from '@builder/utils';
import { transformVariableName } from '../../utils/transformVariableName';
export class ComponentFileAccessor {
    constructor({ appDSL, componentListDSL, nodeID, }) {
        this.appDSL = appDSL;
        this.componentListDSL = componentListDSL;
        this.nodeDSL = nodeListSelectors.getNodeDSL(this.appDSL.nodes, { nodeID });
        this.componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: this.nodeDSL.name,
        });
        const codeFileStructure = appNodesSchemaSelectors.geCodeFileStructure({
            nodeListDSL: this.appDSL.nodes,
            componentListDSL: this.componentListDSL,
        }, {
            nodeID,
        });
        if (!codeFileStructure) {
            throw new SchemaError(ERROR_SCOPES.codeEngine, `${nodeID} node doesn't support excluding to file`);
        }
        this.codeFileStructure = codeFileStructure;
    }
    /**
     * @example () => 'routes/route-1'
     */
    getComponentPath() {
        // normalize path and removes symbols which can't be used in the path
        // '/routes/route-1/' => 'routes/route-1'
        // 'rout.%3es/ route-1' => 'routes/route-1'
        return (this.codeFileStructure.componentPath || '')
            .replace(/[^A-Z0-9/-_]/gi, '')
            .replace(/^\//, '')
            .replace(/\/$/, '');
    }
    /**
     * @example () => 'Page'
     */
    getPostfix() {
        // removes symbols which can't be used in the file name
        // 'Pa -ge' => 'Page'
        return (this.codeFileStructure.postfix || '').replace(/[^A-Z0-9_/]/gi, '');
    }
    /**
     * @example () => 'Route_1'
     */
    getComponentName() {
        return transformVariableName(`${changeCase.pascalCase(nodeSelectors.getNodeAlias(this.nodeDSL, { componentListDSL: this.componentListDSL }), { transform: changeCase.pascalCaseTransformMerge })}${this.getPostfix()}`);
    }
    /**
     * @example () => 'Route_1Page.tsx'
     */
    getComponentFileName() {
        return `${this.getComponentName()}.tsx`;
    }
    /**
     * @example () => 'routes/route-1/Route_1Page.tsx'
     */
    getPathToFile() {
        return `${this.getComponentPath()}/${this.getComponentFileName()}`;
    }
    /**
     * @example () => 'routes/route-1'
     */
    getFolderPath() {
        return this.getComponentPath();
    }
    getImport() {
        return {
            importName: this.getFolderPath(),
            varName: this.getComponentName(),
        };
    }
}
