import { COMPONENT_DSL_NAMES, NodeDSL } from '@builder/schemas';

import { isLayoutNode } from 'src/features/dashboard/overlay/utils/isLayoutNode';
import { useLayoutNodesIds, useNodeByID, useSelectedNodeID } from 'src/providers';

export const useIsMultiselectAvailable = (targetNode: NodeDSL): boolean => {
  const selectedID = useSelectedNodeID();
  const isSelected = targetNode.id === selectedID;
  const selectedNodeDSL = useNodeByID(selectedID as string);
  const layoutIds = useLayoutNodesIds();
  const isClickedComponentLayout = isLayoutNode(targetNode.id, layoutIds);
  const isClickedComponentRoute =
    targetNode.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
    targetNode.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout;
  const isCurrentComponentRouteOrLayout =
    isLayoutNode(selectedNodeDSL?.parentID as string, layoutIds) ||
    selectedNodeDSL?.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute;

  if (isClickedComponentLayout || isClickedComponentRoute) return false;

  if (isCurrentComponentRouteOrLayout) return false;

  if (!selectedID || isSelected) {
    return false;
  }

  return true;
};
