import { ERROR_SCOPES, SchemaValidationError } from '@builder/utils';
import { PropValueChecker } from './PropValueChecker';
/**
 * Throw runtime error if prop value in the nodes DSL has incorrect attributes.
 */
export class PropValueAssert {
    static assertIsRenderableProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isRenderableProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has renderable type and should be in the next format: "{ nodes: string[] } }", but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsRenderableNodeProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isRenderableNodesProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has renderable type and should be in the next format: "{ nodes: string[] }", but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsReactNodeProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isReactNodeProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has react-node type and should be in the next format: "{ nodes: string[] }", but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsCalculatedProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isCalculatedProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has react-node type and should be in the next format: "{ nodes: string[] }", but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsCallbackCodeProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isCallbackCodeProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has callback type and should be string, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsActionProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isActionProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has action type and should be in the next format: "{ actionType: string, args: object }", but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsCallbackComponentProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isCallbackComponentProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has callback component type and should be in the next format: "{ component: string, props: object }", but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsComponentProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isComponentProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has component type and should be string, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsCSSProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isCSSProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has css type and should be string, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsObjectProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isObjectProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has object type and should be object, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsStyleProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isStyleProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has style type and should be object, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsArrayProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isArrayProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has array type and should be array, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsFieldValidationProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isArrayProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has array type and should be array, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsCustomPropsProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isArrayProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has array type and should be array, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsValidCustomProperty(propValue, propName, options = {}) {
        if (!PropValueChecker.isValidCustomProp(propValue)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has an invalid format ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsValidCustomValue(propValue) {
        if (!PropValueChecker.isValidCustomvalue(propValue)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Custom value has an invalid format ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsValidFieldValidationProperty(propValue, propName, options = {}) {
        if (!PropValueChecker.isValidFieldValidationProp(propValue)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has an invalid format ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsValidFieldValidationValue(propValue, propName, propType, options = {}) {
        switch (propType) {
            case 'date':
            case 'time':
                if (!PropValueChecker.isValidFieldValidationDateTime(propValue)) {
                    throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has an invalid date-time format ${JSON.stringify(propValue)}`);
                }
                break;
            default:
                if (!PropValueChecker.isValidFieldValidationValue(propValue)) {
                    throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has an invalid value format ${JSON.stringify(propValue)}`);
                }
        }
    }
    static assertIsNumberProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isNumberProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has number type and should be number, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsStringOrNumberProp(propValue, propName, options = {}) {
        if (!(PropValueChecker.isStringProp(propValue, options) ||
            PropValueChecker.isNumberProp(propValue, options))) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has string or number type type and should be string or number, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsStringProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isStringProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has string type and should be string, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsBooleanProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isBooleanProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has boolean type and should be boolean, but got ${JSON.stringify(propValue)}`);
        }
    }
    static assertIsAssetProp(propValue, propName, options = {}) {
        if (!PropValueChecker.isAssetProp(propValue, options)) {
            throw new SchemaValidationError(ERROR_SCOPES.schemas, `Prop ${propName} has asset type and should be object, but got ${JSON.stringify(propValue)}`);
        }
    }
}
