import { omit } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, STATE_SCOPES, STATE_TYPES, COMPONENT_DSL_CALLBACK_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsTimePicker } from '../../material-components/BuilderComponentsTimePicker';
import { BuilderComponentsFormTimePickerSettings } from './BuilderComponentsFormTimePicker.settings';
export const BuilderComponentsFormTimePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormTimePicker,
    displayName: 'Form Time Picker',
    icon: 'formTimePicker',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormTimePicker',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'FormTimePicker',
                    defaultValue: '',
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormTimePicker',
                },
                timePickerProps: Object.assign(Object.assign({}, omit(['value', 'onChange'], BuilderComponentsTimePicker.schema.props.timePickerProps)), { value: '{{ __STATES__.FormTimePicker.value }}', onChange: {
                        actionType: 'customCode',
                        args: {
                            code: `__STATES__.FormTimePicker.setValue(value)`,
                        },
                    }, ampm: true, showToolbar: true, minutesStep: 1, open: false, openTo: 'hours', hours: true, minutesView: true, seconds: false, responsiveness: 'desktop', orientation: 'portrait', inputFormat: 'HH:mm:ss', inputVariant: 'outlined', label: 'Time Picker', minTime: '00:00:00', maxTime: '23:59:59' }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'time',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, timePickerProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsTimePicker.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            } }),
    },
    settings: BuilderComponentsFormTimePickerSettings,
};
