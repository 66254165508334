import { isEmpty } from 'ramda';
import { isNumber } from '../type-checkers';
/**
 * @example
 * 0 => true
 * '10' => true
 * 'stringValue' => false
 */
const isNumberLike = (value) => {
    if (isNumber(value) && !isNaN(value)) {
        return true;
    }
    const valueAsNumber = Number(value);
    return isNumber(valueAsNumber) && !isNaN(valueAsNumber);
};
/**
 * @example
 * 0 => true
 * '10' => true
 * 'hyphen-value' => true
 * 'space value' => true
 * 'someString' => false
 * ':root' => true
 */
const shouldBePlacedInQuotes = (str) => isNumberLike(str) || / |-|:/.test(str);
/**
 * @example
 * ('', 'newValue') => ''
 * ('start.next', 'newValue') => '.'
 * ('start.next', 0) => ''
 * ('start.next', 'some-value') => ''
 */
const getSeparator = (previousPath, newValue) => {
    if (isEmpty(previousPath) || shouldBePlacedInQuotes(newValue)) {
        return '';
    }
    return '.';
};
/**
 * @example
 * ['formProps', 'value'] => 'formProps.value'
 * ['Button 1', 'props', 'name'] => ['Button 1'].props.name
 * ['entity', 'some-property', 'test'] => entity['some-property'].test
 * ['some-entity', 'some-property', 'test'] => ['some-entity']['some-property'].test
 * ['some-entity', '0', 'some-property', 'test'] => ['some-entity'][0]['some-property'].test
 */
export const pathToString = (propPath) => {
    return propPath.reduce((acc, current) => {
        const separator = getSeparator(acc, String(current));
        if (shouldBePlacedInQuotes(current)) {
            return `${acc}${separator}['${current}']`;
        }
        return `${acc}${separator}${current}`;
    }, '');
};
