import { gql } from '@apollo/client';

export const USER_CONFIGURATION_QUERY = gql`
  query UserConfiguration($workspaceId: String!, $pageKeys: [String!]) {
    appbuilder {
      userConfiguration(workspaceId: $workspaceId, pageKeys: $pageKeys) {
        appDSL
        userComponentsDSL
        appVersion
        schemaDSLVersion
      }
    }
  }
`;

export const USER_CONFIGURATION_MIGRATE_MUTATION = gql`
  mutation UserConfigurationMigrate($data: AppbuilderUserConfigurationInput!) {
    appbuilder {
      userConfigurationMigrate(data: $data) {
        sessionId
      }
    }
  }
`;
