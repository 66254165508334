import React, { useCallback, useRef } from 'react';

import { DraggableData, DraggableFunction } from '../types';

type UseDraggingOptions = {
  onDragging?: DraggableFunction;
  onDrop?: DraggableFunction;
};

type UseDraggingReturn<T extends HTMLElement> = {
  setDragRef: (node: T | null) => void;
  onDragStart: (event: React.MouseEvent<Node>) => void;
};

export const useDragging = <T extends HTMLElement>({
  onDragging,
  onDrop,
}: UseDraggingOptions = {}): UseDraggingReturn<T> => {
  const relativeContainerRef = useRef<HTMLElement | null>(document.body);

  const setDragRef = useCallback((node: T | null) => {
    relativeContainerRef.current = node;
  }, []);

  const calculateData = useCallback(
    (event: MouseEvent | React.MouseEvent<Node>): DraggableData | null => {
      if (relativeContainerRef.current) {
        const { x, y } = relativeContainerRef.current.getBoundingClientRect();
        const { offsetWidth: width, offsetHeight: height } = relativeContainerRef.current;
        // axis offset calculation
        const leftOffset = event.clientX - Math.round(x);
        const topOffset = event.clientY - Math.round(y);
        // do not allow the pointer to go beyond the container
        const newX = Math.min(Math.max(0, leftOffset), width);
        const newY = Math.min(Math.max(0, topOffset), height);

        return {
          coords: { x: newX, y: newY },
          elementSize: { width, height },
        };
      }

      return null;
    },
    [],
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent | React.MouseEvent<Node>) => {
      event.preventDefault();

      const data = calculateData(event);

      if (data) {
        const { coords, elementSize } = data;
        onDragging?.(coords, elementSize);
      }
    },
    [calculateData, onDragging],
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent | React.MouseEvent<Node>) => {
      event.preventDefault();

      const data = calculateData(event);

      if (data) {
        const { coords, elementSize } = data;
        onDrop?.(coords, elementSize);
      }

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    },
    [calculateData, handleMouseMove, onDrop],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<Node>) => {
      handleMouseMove(event);

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp],
  );

  return {
    setDragRef,
    onDragStart: handleMouseDown,
  };
};
