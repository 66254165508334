import { PresentationData } from '@builder/app-engine';
import { NodeDSL, nodeListSelectors } from '@builder/schemas';

import { useNodeListDSL, useUserAppRuntimeState } from 'src/providers/ReduxProvider';

export const useOpenedDialogNodeDSL = (): NodeDSL | null => {
  const nodeListDSL = useNodeListDSL();
  const userAppRuntimeState = useUserAppRuntimeState();
  const allDialogsNodes = nodeListSelectors.getAllDialogsNodes(nodeListDSL);

  const openedDialogNodeDSL = allDialogsNodes.find(dialogNodeDSL => {
    const presentation =
      userAppRuntimeState.presentationList?.[dialogNodeDSL.id] ?? ({} as PresentationData);

    return Boolean(presentation?.visible?.isNodeMatchPresentation);
  });

  return openedDialogNodeDSL || null;
};
