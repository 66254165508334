import styled from '@emotion/styled';

import { Button } from 'src/shared/components';

export const NodeSettingsTitleContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: ${({ theme }) => theme.spacing(4)} 1fr ${({ theme }) => theme.spacing(4)};
  grid-auto-columns: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(1.75)};
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
`;

export const AliasEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  height: 51px;
  max-width: 100%;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  min-width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
  font-size: 24px;
  padding: 0;
`;

export const StyledHelperText = styled.div`
  padding-top: 0px;
  margin-top: ${({ theme }) => theme.spacing(-1)};
`;
