import { SyntheticEvent, useCallback, useMemo } from 'react';

import {
  createSymbolFromNode,
  NodeID,
  NodeDSL,
  NodeListDSL,
  COMPONENT_DSL_NAMES,
  EMPTY_PAGE_ROUTER_SWITCH_NODE_ID as DEFAULT_LAYOUT_ID,
  nodeListSelectors,
  componentListSelectors,
  stateListSelectors,
  StateListDSL,
} from '@builder/schemas';
import { isArray } from '@builder/utils';

import { handleSendFullStoryEvent } from '../fullStory';
import { filterValidStyles, copyValidStyles } from '../utils';
import {
  useAppDispatch,
  useAppDSLStates,
  useComponentListDSL,
  useDashboardState,
  useNodeListDSL,
} from 'src/providers/ReduxProvider';
import {
  DASHBOARD_EVENTS,
  LEFT_PANEL_TABS,
  NODE_SETTINGS_TABS,
  RIGHT_PANEL_TABS,
  UI_BUILDER_EVENTS,
} from 'src/store';

import { useCurrentPathname } from './dashboardRouter';
import { useCurrentWorkspaceID } from './useCurrentWorkspaceID';

type UseNodeActions = (
  nodesId: NodeID[] | NodeID,
) => {
  deleteNode: (event: SyntheticEvent) => void;
  createSymbolToBuffer: (event: SyntheticEvent, name: string) => Promise<void>;
  copyComponent: (event: SyntheticEvent) => void;
  copyStyle: (event: SyntheticEvent) => void;
  pasteStyle: (event: SyntheticEvent) => void;
  copyComponentToBuffer: (event: SyntheticEvent) => void;
  applyBufferToNode: (event: SyntheticEvent) => void;
  openAsRoot: (event: SyntheticEvent) => void;
  backToView: (event: SyntheticEvent) => void;
  saveAsGlobalCSS: (event: SyntheticEvent, className: string) => void;
};

const CUSTOM_CSS_SETTING = 'customCSS';

export const useNodeActions: UseNodeActions = nodesId => {
  const handleFSEvent = (
    method: string,
    item: NodeDSL,
    nodeListDSL: NodeListDSL,
    workspaceID: string | null,
  ) => {
    const componentType = (item.name as unknown) as string;
    const targetID = (item.parentID as unknown) as string;
    const targetNode = nodeListDSL[targetID];
    const target =
      targetNode.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout
        ? targetNode.alias
        : targetNode.name;
    handleSendFullStoryEvent(`The user creates a component by drag/drop, copy/paste, or cloning`, {
      componentType,
      target,
      method,
      workspaceID,
      defineStr: `The ${componentType} created in the ${target} for the ${method} method`,
    });
  };

  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const userAppStates = useAppDSLStates();
  const localStates = useMemo<StateListDSL>(() => {
    return stateListSelectors.getLocalStateListDSL(userAppStates);
  }, [userAppStates]);
  const globalStates = useMemo<StateListDSL>(() => {
    return stateListSelectors.getGlobalStateListDSL(userAppStates);
  }, [userAppStates]);
  const componentListDSL = useComponentListDSL();
  const currentPathName = useCurrentPathname();
  const isMultiSelected = isArray(nodesId);
  const { workspaceID } = useCurrentWorkspaceID();
  const { copyStyle: dashboardCopyStyle } = useDashboardState();

  const deleteNode = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (isMultiSelected) {
        nodesId.forEach(nodeId => {
          if (nodeListDSL[nodeId].name === COMPONENT_DSL_NAMES.RouterSwitchSymbol) {
            const nodeList = nodeListDSL[nodeId as string].props.routes as { nodes: string[] };
            send({
              type: DASHBOARD_EVENTS.routeLayoutUpdate,
              routeIDs: nodeList?.nodes,
              layoutID: DEFAULT_LAYOUT_ID,
            });
          }

          send({
            type: DASHBOARD_EVENTS.componentRemove,
            id: nodeId,
            currentPathName,
            connectedStates: nodeListSelectors.getConnectedStates(nodeListDSL, {
              componentListDSL,
              nodeID: nodeId,
              stateListDSL: userAppStates,
            }),
          });
        });

        return;
      }

      if (nodeListDSL[nodesId as string].name === COMPONENT_DSL_NAMES.RouterSwitchSymbol) {
        const nodeList = nodeListDSL[nodesId as string].props.routes as { nodes: string[] };
        send({
          type: DASHBOARD_EVENTS.routeLayoutUpdate,
          routeIDs: nodeList?.nodes,
          layoutID: DEFAULT_LAYOUT_ID,
        });

        send({
          type: UI_BUILDER_EVENTS.notificationSend,
          notification: {
            message: 'This layout won’t be available to use until it has a view component.',
            options: { variant: 'warning' },
          },
        });
      }

      send({
        type: DASHBOARD_EVENTS.componentRemove,
        id: nodesId,
        currentPathName,
        connectedStates: nodeListSelectors.getConnectedStates(nodeListDSL, {
          nodeID: nodesId,
          componentListDSL,
          stateListDSL: userAppStates,
        }),
      });
    },
    [componentListDSL, currentPathName, isMultiSelected, nodeListDSL, nodesId, send, userAppStates],
  );

  //* disabled in multiselect!
  const createSymbolToBuffer = useCallback(
    async (event: SyntheticEvent, name) => {
      // stop propagation was disabled to show new group component created
      // event.stopPropagation();

      if (isMultiSelected) {
        return;
      }

      const userComponentDSL = createSymbolFromNode({
        nodeListDSL,
        componentListDSL,
        nodeID: nodesId,
        componentName: name,
      });
      handleFSEvent(
        DASHBOARD_EVENTS.userComponentCreate,
        nodeListDSL[nodesId],
        nodeListDSL,
        workspaceID,
      );
      send({ type: DASHBOARD_EVENTS.userComponentCreate, userComponentDSL });
    },
    [isMultiSelected, nodeListDSL, componentListDSL, nodesId, workspaceID, send],
  );

  const copyStyle = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (typeof nodesId === 'string') {
        const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: nodesId });
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
          componentName: nodeDSL.name,
        });
        const styleObject = copyValidStyles(componentDSL, nodeDSL);
        send({
          type: DASHBOARD_EVENTS.componentCopyStyle,
          componentName: componentDSL.name,
          style: styleObject,
        });
      }
    },
    [componentListDSL, nodeListDSL, nodesId, send],
  );

  const pasteStyle = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      const { styles } = dashboardCopyStyle;

      if (typeof nodesId === 'string') {
        const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: nodesId });
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
          componentName: nodeDSL.name,
        });
        const { validStyles, notCopiedReference } = filterValidStyles(
          componentDSL,
          nodeDSL,
          localStates,
          globalStates,
          styles,
        );
        if (Object.keys(validStyles).length > 0) {
          send({
            type: DASHBOARD_EVENTS.componentPasteStyle,
            id: nodesId,
            style: validStyles,
          });
        }

        if (notCopiedReference) {
          send({
            type: UI_BUILDER_EVENTS.notificationSend,
            notification: {
              message: `Values referencing a local style attribute can not be copied to the target object.`,
              options: { variant: 'info' },
            },
          });
        }
      } else if (Array.isArray(nodesId)) {
        let notCopiedReferences = false;
        nodesId.forEach(nodeId => {
          const nodeDSL = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: nodeId });
          const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName: nodeDSL.name,
          });
          const { validStyles, notCopiedReference } = filterValidStyles(
            componentDSL,
            nodeDSL,
            localStates,
            globalStates,
            styles,
          );
          if (Object.keys(validStyles).length > 0) {
            send({
              type: DASHBOARD_EVENTS.componentPasteStyle,
              id: nodesId,
              style: validStyles,
            });
          }

          if (notCopiedReference && !notCopiedReferences) {
            notCopiedReferences = true;
            send({
              type: UI_BUILDER_EVENTS.notificationSend,
              notification: {
                message: `Values referencing a local style attribute can not be copied to the target object.`,
                options: { variant: 'info' },
              },
            });
          }
        });
      }
    },
    [componentListDSL, dashboardCopyStyle, globalStates, localStates, nodeListDSL, nodesId, send],
  );

  const copyComponent = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (isMultiSelected) {
        nodesId.forEach(nodeId => {
          send({
            type: DASHBOARD_EVENTS.componentCopy,
            id: nodeId,
            currentPathName,
            node: nodeListDSL[nodeId],
          });

          handleFSEvent(
            DASHBOARD_EVENTS.componentClone,
            nodeListDSL[nodeId],
            nodeListDSL,
            workspaceID,
          );
        });

        return;
      }

      handleFSEvent(
        DASHBOARD_EVENTS.componentClone,
        nodeListDSL[nodesId],
        nodeListDSL,
        workspaceID,
      );
      send({
        type: DASHBOARD_EVENTS.componentCopy,
        id: nodesId,
        currentPathName,
        node: nodeListDSL[nodesId],
      });
    },
    [isMultiSelected, nodeListDSL, nodesId, workspaceID, send, currentPathName],
  );

  const openAsRoot = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      send({
        type: DASHBOARD_EVENTS.openAsRoot,
        nodeID: nodesId as string,
      });
    },
    [nodesId, send],
  );

  const backToView = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      send({
        type: DASHBOARD_EVENTS.openAsRoot,
        nodeID: '' as string,
      });
    },
    [send],
  );

  const copyComponentToBuffer = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (isMultiSelected) {
        send({
          type: DASHBOARD_EVENTS.copyBufferSet,
          nodeID: nodesId,
          currentPathName,
        });

        nodesId.forEach(nodeId => {
          handleFSEvent(
            DASHBOARD_EVENTS.copyBufferSet,
            nodeListDSL[nodeId],
            nodeListDSL,
            workspaceID,
          );
        });

        return;
      }

      handleFSEvent(DASHBOARD_EVENTS.copyBufferSet, nodeListDSL[nodesId], nodeListDSL, workspaceID);
      send({
        type: DASHBOARD_EVENTS.copyBufferSet,
        nodeID: [nodesId],
        currentPathName,
      });
    },
    [isMultiSelected, nodeListDSL, nodesId, workspaceID, send, currentPathName],
  );

  const applyBufferToNode = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (isMultiSelected) {
        nodesId.forEach(nodeId => {
          send({
            type: DASHBOARD_EVENTS.copyBufferApply,
            nodeID: nodeId,
            currentPathName,
          });

          handleFSEvent(
            DASHBOARD_EVENTS.copyBufferApply,
            nodeListDSL[nodeId],
            nodeListDSL,
            workspaceID,
          );
        });

        return;
      }

      handleFSEvent(
        DASHBOARD_EVENTS.copyBufferApply,
        nodeListDSL[nodesId],
        nodeListDSL,
        workspaceID,
      );
      send({
        type: DASHBOARD_EVENTS.copyBufferApply,
        nodeID: nodesId,
        currentPathName,
      });
    },
    [isMultiSelected, nodeListDSL, nodesId, workspaceID, send, currentPathName],
  );

  const saveAsGlobalCSS = useCallback(
    (event: SyntheticEvent, className) => {
      event.stopPropagation();

      if (isMultiSelected) {
        return;
      }

      send({
        type: UI_BUILDER_EVENTS.updateRightPanel,
        rightPanel: {
          currentTab: RIGHT_PANEL_TABS.componentList,
          nodeSettingsArgs: {
            currentTab: NODE_SETTINGS_TABS.style,
          },
          forceReload: Date.now(),
        },
      });

      setTimeout(() => {
        send({
          type: DASHBOARD_EVENTS.componentSaveAsGlobalCSS,
          nodeID: nodesId,
          className,
        });
      }, 100);

      send({
        type: UI_BUILDER_EVENTS.successAppNotify,
        successMessage: 'A new custom CSS style was successfully created.',
        action: () => {
          send({
            type: UI_BUILDER_EVENTS.updateLeftPanel,
            leftPanel: {
              currentTab: LEFT_PANEL_TABS.theme,
              args: {
                appSettingsInnerTab: CUSTOM_CSS_SETTING,
                scrollDown: true,
              },
            },
          });
        },
      });
    },
    [isMultiSelected, nodesId, send],
  );

  return {
    deleteNode,
    createSymbolToBuffer,
    copyComponent,
    copyStyle,
    pasteStyle,
    copyComponentToBuffer,
    applyBufferToNode,
    openAsRoot,
    backToView,
    saveAsGlobalCSS,
  };
};
