import { pipe } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { assocSettings, BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, disableSpacingPaddings, getBasicPropsSettings, NS_ID, pickSettings, trimDividers, } from '../../common';
import { getEventsSection, getDivider, getPropertySections, getStyleSections, } from '../../common/settings';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const MaterialIconButtonSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['type'],
                            label: 'Type',
                            options: ['button', 'submit', 'reset'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['label'],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disabled'],
                            label: 'Disabled',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['href'],
                            label: 'href',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['target'],
                            label: 'Target',
                            options: ['_blank', '_self'].map(createSelectOptionByValue),
                            showIf: [
                                {
                                    keyPath: ['href'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.isNotNil,
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Icon',
                    name: NS_ID.properties.basic,
                    expandedByDefault: false,
                    children: [
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'icon',
                            label: 'Icon',
                            required: true,
                            keyPath: ['children'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['color'],
                            label: 'Font Color',
                            options: ['default', 'inherit', 'primary', 'secondary'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['size'],
                            label: 'Size',
                            options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['fullWidth'],
                            label: 'Full Width',
                        },
                    ],
                },
                getDivider(),
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    name: NS_ID.style.advanced,
                    title: 'Advanced',
                    expandedByDefault: false,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableElevation'],
                            label: 'Disable Elevation',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableFocusRipple'],
                            label: 'Disable Focus Ripple',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableRipple'],
                            label: 'Disable Ripple',
                        },
                    ],
                },
                getDivider(),
                ...pipe(pickSettings([
                    [NS_ID.style.display.root],
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.size.root, NS_ID.style.size.width],
                    [NS_ID.style.size.root, NS_ID.style.size.height],
                    [NS_ID.style.css.root],
                ]), assocSettings([NS_ID.style.spacing.root, NS_ID.style.spacing.editor], disableSpacingPaddings), trimDividers)(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
