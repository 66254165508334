import { omit, path } from 'ramda';
import { STATE_REQUEST_VARIANTS, STATE_TYPES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_11_9 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.11.9';
    }
    getChangeList() {
        return ["Moved request's transformer and graphQL query body to args"];
    }
    migrateStateDSL(stateDSL) {
        if (stateDSL.type === STATE_TYPES.query) {
            if (stateDSL.variant === STATE_REQUEST_VARIANTS.query ||
                stateDSL.variant === STATE_REQUEST_VARIANTS.lazyQuery) {
                return Object.assign(Object.assign({}, omit(['transformer', 'query'], stateDSL)), { args: Object.assign(Object.assign({}, stateDSL.args), { transformer: path(['transformer'], stateDSL), body: path(['query'], stateDSL) }) });
            }
            if (stateDSL.variant === STATE_REQUEST_VARIANTS.mutation) {
                return Object.assign(Object.assign({}, omit(['transformer', 'mutation'], stateDSL)), { args: Object.assign(Object.assign({}, stateDSL.args), { transformer: path(['transformer'], stateDSL), body: path(['mutation'], stateDSL) }) });
            }
        }
        return stateDSL;
    }
}
export const migration_0_11_9 = new Migration_0_11_9();
