import { useCallback } from 'react';

import { Grid } from '@mui/material';
import { path } from 'ramda';

import { appNodesSchemaSelectors, ComponentSettingSchemaOverrideDSL } from '@builder/schemas';
import { memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { JsCodeInjectionInput } from '../../setting-views/common/JsCodeInjectionInput';
import { useComponentListDSL, useNodeListDSL } from 'src/providers/ReduxProvider';
import { TextField } from 'src/shared/components';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';

type SchemaOverrideTypeProps = {
  setting: ComponentSettingSchemaOverrideDSL;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  onChangeSchemaOverrideValue: (schemaOverrideValue: {
    keyValue: unknown;
    keyPath: Array<string | number>;
  }) => void;

  'data-test'?: string;
};

export const SchemaOverrideType = memo(
  'SchemaOverrideType',
  ({
    setting,
    onChangeSchemaOverrideValue,
    showFx = true,
    'data-test': dataTest,
    fxDefaultEnabled = ACTIVEPROP.literal,
  }: SchemaOverrideTypeProps): JSX.Element => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const componentListDSL = useComponentListDSL();
    const nodeListDSL = useNodeListDSL();

    const schemaOverride = appNodesSchemaSelectors.getMergedSchemaOverrides(
      { nodeListDSL, componentListDSL },
      {
        nodeID: selectedNodeDSL.id,
      },
    );

    const inputValue = path(setting.keyPath, schemaOverride) as string | undefined;

    const updateValueByEvent = useCallback(
      (newValue?: string) => {
        onChangeSchemaOverrideValue({ keyValue: newValue, keyPath: setting.keyPath });
      },
      [onChangeSchemaOverrideValue, setting.keyPath],
    );

    return (
      <JsCodeInjectionInput
        label={setting.label}
        onChangePropValue={updateValueByEvent}
        nodeID={selectedNodeDSL.id}
        showFx={showFx}
        data-test={dataTest}
        fxDefaultEnabled={fxDefaultEnabled}
        typeField="string"
        propValue={inputValue}
      >
        {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={setting.label}
              value={inputValue ?? nonJsCodePropValue}
              isFxEnabled={isFxEnabled}
              enableFx={enableFx}
              onDebounceChange={updateValueByEvent}
              data-test={`${dataTest}.name`}
              skipDebounce={false}
            />
          </Grid>
        )}
      </JsCodeInjectionInput>
    );
  },
);
