import React from 'react';

import styled from '@emotion/styled';

import { inputState } from '../../types';
import { CopyToClipboard } from 'src/shared/components';

type PresentationInputProps = {
  isInvalid?: boolean;
};

type ColorPreviewProps = {
  color: string;
  input: inputState;
  onChange: (newInput: string) => void;
};

const ColorPreviewWrapper = styled.div`
  display: flex;
  gap: 4px;
  height: 30px;
`;

const ColorView = styled.div<{ color?: string }>`
  min-width: 30px;
  border-radius: 4px;
  background-color: ${({ color }) => color || 'transparent'};
`;

const PresentationInputWrapper = styled.div<PresentationInputProps>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.grey[600]};
  outline: 1px solid ${({ isInvalid, theme }) => (isInvalid ? theme.palette.error.main : 'none')};
`;

const PresentationInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.palette.grey[300]};
  background-color: transparent;
`;

export const ColorPreview: React.FC<ColorPreviewProps> = ({ color, input, onChange }) => {
  const { value, isInvalid = false } = input;

  return (
    <ColorPreviewWrapper className="no-drag" style={{ cursor: 'auto' }}>
      <ColorView color={color} />
      <PresentationInputWrapper isInvalid={isInvalid}>
        <PresentationInput
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        />
        <CopyToClipboard text={value} />
      </PresentationInputWrapper>
    </ColorPreviewWrapper>
  );
};
