import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialAvatarSettings } from './MaterialAvatar.settings';
export const MaterialAvatar = {
    name: COMPONENT_DSL_NAMES.MaterialAvatar,
    displayName: 'Avatar',
    icon: 'avatar',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@mui/material',
        componentName: 'Avatar',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                variant: 'circular',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { alt: { type: COMPONENT_DSL_PROP_TYPES.string }, component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, imgProps: { type: COMPONENT_DSL_PROP_TYPES.object }, sizes: { type: COMPONENT_DSL_PROP_TYPES.string }, src: { type: COMPONENT_DSL_PROP_TYPES.string }, srcSet: { type: COMPONENT_DSL_PROP_TYPES.string }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialAvatarSettings,
};
