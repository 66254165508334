import { mapObjIndexed } from 'ramda';
import { COMPONENT_DSL_NAMES, SettingChecker, } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_15_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.15.3';
    }
    getChangeList() {
        return ['Image component resizing fixed'];
    }
    migrateNodeDSL(nodeDSL) {
        if (nodeDSL.name === COMPONENT_DSL_NAMES.img) {
            return Object.assign(Object.assign({}, nodeDSL), { name: COMPONENT_DSL_NAMES.BuilderComponentsImage });
        }
        return nodeDSL;
    }
    migrateSettingDSL(setting) {
        if (SettingChecker.Schema.isSettingTypeOfProp(setting) && setting.resetPathBeforeOnChange) {
            return Object.assign(Object.assign({}, setting), { 
                // typings changed for resetPathBeforeOnChange from [] to [][]
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                resetPathBeforeOnChange: [setting.resetPathBeforeOnChange] });
        }
        return setting;
    }
    migrateUserComponentListDSL(userComponentListDSL, appDSL) {
        return mapObjIndexed(userComponent => {
            if (!userComponent.schema.symbolNodes) {
                return userComponent;
            }
            return Object.assign(Object.assign({}, userComponent), { schema: Object.assign(Object.assign({}, userComponent.schema), { props: this.migratePropListDSL(userComponent.schema.props, appDSL), symbolNodes: mapObjIndexed(nodeDSL => this.migrateNodeDSL(nodeDSL), userComponent.schema.symbolNodes) }), settings: this.migrateUserComponentSettingListDSL(userComponent.settings, appDSL) });
        }, userComponentListDSL);
    }
}
export const migration_0_15_3 = new Migration_0_15_3();
