const FONTFACE_SRC_REGEX = /{{ src }}/;
const FALLBACK_URL = '';
/**
 * @description replaces {{src}} placeholder in the CSS font-face definition with actual url
 * @param fontFaceCSS - font-face CSS block with {{src}} in place of url() function
 * @param url - actual font location (weblink or relative file path)
 * @returns font-face CSS definition with valid url
 */
export const injectFontFaceUrl = (fontFaceCSS, url) => {
    return fontFaceCSS.replace(FONTFACE_SRC_REGEX, url !== null && url !== void 0 ? url : FALLBACK_URL);
};
