export const Z_INDEXES_APP = {
    drawer: 1201,
    overlay: 1201,
    breadcrumbs: 1203,
    localDialog: 1205,
};
export const Z_INDEXES_BUILDER = {
    dialogs: 1203,
    leftPanel: 1204,
    // should be top-most, a somewhere at the top
    dndCustomLayer: 9000,
};
