import React, { forwardRef } from 'react';

import { getIsInvalid } from '@builder/components';

import { SelectViewEditor } from '../../../../features/node-settings/setting-views/SelectViewEditor';
import { Select, SelectProps } from '../../Select';
import { Field, FieldProps, FieldRenderProps } from '../Field';

export type FormSelectProps = {
  fieldProps: FieldProps;
  selectProps?: SelectProps;
  options?: {
    crossIcon: boolean;
    values: Record<string, string>;
  };
};

export const FormSelectField = forwardRef<HTMLDivElement, FormSelectProps>(
  ({ fieldProps, selectProps, options }, ref) => {
    return (
      <Field {...fieldProps}>
        {({ field, meta, form }: FieldRenderProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid ? meta.error : undefined;
          return (
            <>
              <If condition={!options?.crossIcon}>
                <Select
                  ref={ref}
                  {...selectProps}
                  name={fieldProps.name}
                  value={field.value}
                  onChange={field.onChange}
                  helperText={errorText}
                  error={isInvalid}
                />
              </If>
              <If condition={options?.crossIcon}>
                <SelectViewEditor
                  label={fieldProps.name}
                  propValue={options?.values?.[fieldProps.name]}
                  onChangePropValue={propValue => {
                    form.setFieldValue(fieldProps.name, propValue);
                  }}
                  options={[
                    { value: 'code', label: 'Custom Code' },
                    { value: 'request', label: 'Run request' },
                    { value: 'function', label: 'Run function' },
                  ]}
                  showFx={false}
                />
              </If>
            </>
          );
        }}
      </Field>
    );
  },
);
