var _a;
import { values } from 'ramda';
import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, NS_ID, getBasicPropsSettings, pickSettings, } from '../../common';
import { getBackgroundSection, getBorderSection, getCSSSection, getCursorSection, getDivider, getEventsSection, getFlexChildSection, getGridChildSection, getPropertySections, getSpacingSection, } from '../../common/settings';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { getCommonIconSettings } from '../BuilderComponentsIcon/BuilderComponentsIcon.settings';
export const BuilderComponentsSpeedDialActionSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['delay'],
                            name: 'delay',
                            label: 'Delay',
                        },
                        getDivider(),
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['open'],
                            name: 'open',
                            label: 'Open',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'icon',
                            label: 'Icon',
                            keyPath: ['icon'],
                            required: true,
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                    predefineds: {
                                        schemaOverride: {
                                            interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
                                        },
                                        props: Object.assign(Object.assign({}, (((_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props) || {})), { fontSize: 'small', name: 'icon' }),
                                    },
                                },
                            ],
                        },
                        getDivider(),
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['tooltipOpen'],
                            name: 'tooltipOpen',
                            label: 'Tooltip open',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            nodePath: ['tooltipTitle', 0],
                            keyPath: ['tooltipTitle'],
                            name: 'tooltipTitle',
                            label: 'Tooltip Tittle',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['tooltipPlacement'],
                            name: 'tooltipPlacement',
                            label: 'Tooltip Placement',
                            options: [
                                'bottom-end',
                                'bottom-start',
                                'bottom',
                                'left-end',
                                'left-start',
                                'left',
                                'right-end',
                                'right-start',
                                'right',
                                'top-end',
                                'top-start',
                                'top',
                            ].map(createSelectOptionByValue),
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.section,
            title: 'Basic',
            name: NS_ID.style.basic,
            titleDescription: BASIC_STYLE_TOOLTIP,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Floating Action Button',
                    name: 'FabProps',
                    expandedByDefault: true,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['FabProps', 'variant'],
                            label: 'Variant',
                            options: ['circular', 'extended'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['FabProps', 'size'],
                            label: 'Size',
                            options: ['small', 'medium', 'large'].map(createSelectOptionByValue),
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['FabProps', 'sx', 'bgcolor'],
                            label: 'Color',
                            options: [
                                { label: 'Default', value: 'default.main' },
                                { label: 'Error', value: 'error.main' },
                                { label: 'Info', value: 'info.main' },
                                { label: 'Inherit', value: 'inherit.main' },
                                { label: 'Primary', value: 'primary.main' },
                                { label: 'Secondary', value: 'secondary.main' },
                                { label: 'Success', value: 'success.main' },
                                { label: 'Warning', value: 'warning.main' },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            hasFxButton: true,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['FabProps', 'sx', '&:hover', 'bgcolor'],
                            label: 'Hover Color',
                            options: [
                                { label: 'Default', value: 'default.main' },
                                { label: 'Error', value: 'error.main' },
                                { label: 'Info', value: 'info.main' },
                                { label: 'Inherit', value: 'inherit.main' },
                                { label: 'Primary', value: 'primary.main' },
                                { label: 'Secondary', value: 'secondary.main' },
                                { label: 'Success', value: 'success.main' },
                                { label: 'Warning', value: 'warning.main' },
                            ],
                        },
                        getDivider(),
                        {
                            type: COMPONENT_SETTING_TYPES.section,
                            name: 'advanced',
                            title: 'Advanced',
                            titleDescription: {
                                title: 'Advanced',
                                subtitle: 'Additional component-specific attributes',
                            },
                            expandedByDefault: false,
                            children: [
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['FabProps', 'disableElevation'],
                                    label: 'Disable Elevation',
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['FabProps', 'disableFocusRipple'],
                                    label: 'Disable Focus Ripple',
                                },
                                {
                                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                                    type: COMPONENT_SETTING_TYPES.prop,
                                    keyPath: ['FabProps', 'disableRipple'],
                                    label: 'Disable Ripple',
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                getSpacingSection(),
                getDivider(),
                getFlexChildSection(),
                getDivider(),
                getGridChildSection(),
                getDivider(),
                getBackgroundSection(),
                getDivider(),
                getBorderSection({ prefixPath: [], systemPrefixPath: [] }),
                getDivider(),
                getCursorSection(),
                getDivider(),
                getCSSSection(),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonBuilderComponentsSpeedDialActionSettings = () => pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'delay'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'open'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'icon'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'tooltipOpen'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'tooltipTitle'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'tooltipPlacement'],
], { withStructure: false })(values(BuilderComponentsSpeedDialActionSettings).flat());
