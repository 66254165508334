import { NodeDSL, nodeListSelectors, STATE_SCOPES } from '@builder/schemas';

import { useAppDispatch } from 'src/providers';
import { useNodeListDSL, useUIBuilderLeftPanel } from 'src/providers/ReduxProvider';
import { useDashboardCurrentRouteNodeDSL, VIEW_STATES } from 'src/shared/hooks';
import {
  DASHBOARD_EVENTS,
  ItemErrorContext,
  LEFT_PANEL_TABS,
  NodeSettingsTabTypes,
  RIGHT_PANEL_TABS,
  ROUTER_UI_LIST_STATES,
  UI_BUILDER_EVENTS,
} from 'src/store';

type IssuesFinderType = {
  updatePanel: () => void;
  description: string;
  title: string;
  isLongDescription: boolean;
};

export const useIssuesFinder = (issue: ItemErrorContext): IssuesFinderType => {
  const {
    isDupeStateName,
    isComponent,
    isPage,
    isLayout,
    isState,
    panelSection,
    message,
    title,
    pageID,
    layoutID,
    stateID,
    stateScope,
    nodeID,
    locators,
  } = issue.context;
  const send = useAppDispatch();
  const currentActiveRoute = useDashboardCurrentRouteNodeDSL();
  const nodeListDSL = useNodeListDSL();
  const routeList = nodeListSelectors.getAllRouteNodes(nodeListDSL);
  const routeNode = routeList.filter(route => route.id === pageID)[0];
  const leftPanel = useUIBuilderLeftPanel();

  let updatePanel = () => {
    /**/
  };
  if (isComponent) {
    updatePanel = () => {
      if (!nodeListDSL[nodeID]) {
        return;
      }

      const page = nodeListDSL[pageID || layoutID];
      const currentPath = page?.props?.path as string;

      send({ type: DASHBOARD_EVENTS.componentSelectedRemove });

      setTimeout(() => {
        if (currentActiveRoute?.props?.path !== currentPath) {
          send({ type: DASHBOARD_EVENTS.routerPathUpdate, route: currentPath });
        }

        send({ type: DASHBOARD_EVENTS.dashboardClearSelection });
        send({
          type: UI_BUILDER_EVENTS.updateLeftPanel,
          leftPanel: {
            currentTab: LEFT_PANEL_TABS.none,
            args: {
              isAffectOnDashboardLayout: false,
            },
          },
        });

        send({
          type: DASHBOARD_EVENTS.componentToggleSelectByID,
          id: nodeID,
        });

        send({
          type: UI_BUILDER_EVENTS.updateRightPanel,
          rightPanel: {
            currentTab: RIGHT_PANEL_TABS.componentList,
            nodeSettingsArgs: {
              currentTab: panelSection as NodeSettingsTabTypes,
            },
          },
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (locators?.location || [])?.forEach(({ action, attribute }: any, i) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const ref = document.querySelector(`${attribute}`) as any;

          if (!ref) {
            return;
          }

          if (action === 'click') {
            ref?.click();
            ref?.focus();
          }

          if (action === 'focus') {
            ref?.scrollIntoView(true);
            ref?.focus({ preventScroll: false });
          }
        });
      }, 0);
    };
  }

  if (isPage || isLayout) {
    updatePanel = () => {
      if (!nodeListDSL[pageID || layoutID]) {
        return;
      }

      const page = nodeListDSL[pageID || layoutID];
      const currentPath = page?.props?.path as string;

      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          currentTab: LEFT_PANEL_TABS.none,
          args: {
            isAffectOnDashboardLayout: false,
          },
        },
      });

      const newLeftPanel = {
        currentTab: panelSection as keyof typeof LEFT_PANEL_TABS,
        currentRoute: {
          type: ROUTER_UI_LIST_STATES.selectedSettings,
          route: routeNode,
        },
      };

      setTimeout(() => {
        if (currentActiveRoute?.props?.path !== currentPath) {
          send({ type: DASHBOARD_EVENTS.routerPathUpdate, route: currentPath });
        }

        send({ type: DASHBOARD_EVENTS.dashboardClearSelection });

        send({
          type: UI_BUILDER_EVENTS.updateLeftPanel,
          leftPanel: {
            currentTab: LEFT_PANEL_TABS.none,
            args: {
              isAffectOnDashboardLayout: false,
            },
          },
        });

        send({
          type: UI_BUILDER_EVENTS.updateLeftPanel,
          leftPanel: newLeftPanel,
        });

        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (locators.location || []).forEach(({ action, attribute }: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const ref = document.querySelector(`${attribute}`) as any;

            if (!ref) {
              return;
            }

            if (action === 'click') {
              ref?.click();
              ref?.scrollIntoView(true);
              ref?.focus({ preventScroll: false });
            }
          });
        }, 0);
      }, 0);
    };
  }

  if (isState) {
    updatePanel = () => {
      const page: NodeDSL =
        stateScope === STATE_SCOPES.global ? currentActiveRoute : nodeListDSL[pageID || layoutID];
      const currentPath = page?.props?.path as string;
      const newLeftPanel = {
        currentTab: panelSection as keyof typeof LEFT_PANEL_TABS,
        targetID: stateID,
        scope: stateScope,
        viewPanel: VIEW_STATES.item,
        args: {
          ...leftPanel.args,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          currentTab: LEFT_PANEL_TABS.none,
          args: {
            isAffectOnDashboardLayout: false,
          },
        },
      });

      setTimeout(() => {
        if (currentActiveRoute?.props?.path !== currentPath) {
          send({ type: DASHBOARD_EVENTS.routerPathUpdate, route: currentPath });
        }

        send({ type: DASHBOARD_EVENTS.dashboardClearSelection });

        send({
          type: UI_BUILDER_EVENTS.updateLeftPanel,
          leftPanel: {
            currentTab: LEFT_PANEL_TABS.none,
            args: {
              isAffectOnDashboardLayout: false,
            },
          },
        });

        send({
          type: UI_BUILDER_EVENTS.updateLeftPanel,
          leftPanel: newLeftPanel,
        });

        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (locators?.location || [])?.forEach(({ action, attribute }: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const ref = document.querySelector(`${attribute}`) as any;

            if (!ref) {
              return;
            }

            if (action === 'click') {
              ref?.click();
              ref?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
              ref?.focus({ preventScroll: false });
            }
          });
        }, 0);
      }, 0);
    };
  }

  if (isDupeStateName) {
    updatePanel = () => {
      const page: NodeDSL = nodeListDSL[pageID];
      const currentPath = page?.props?.path as string;

      send({
        type: UI_BUILDER_EVENTS.updateLeftPanel,
        leftPanel: {
          currentTab: LEFT_PANEL_TABS.none,
          args: {
            isAffectOnDashboardLayout: false,
          },
        },
      });

      setTimeout(() => {
        if (currentActiveRoute?.props?.path !== currentPath) {
          send({ type: DASHBOARD_EVENTS.routerPathUpdate, route: currentPath });
        }

        send({ type: DASHBOARD_EVENTS.dashboardClearSelection });

        send({
          type: UI_BUILDER_EVENTS.updateLeftPanel,
          leftPanel: {
            currentTab: LEFT_PANEL_TABS.hooks,
            args: {
              isAffectOnDashboardLayout: false,
            },
          },
        });
      }, 0);
    };
  }

  return {
    updatePanel,
    description: message,
    title,
    isLongDescription: message?.length > 90,
  };
};
