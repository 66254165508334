import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { getSysMinSizeStyle } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsBoxSettings } from '../BuilderComponentsBox/BuilderComponentsBox.settings';
export const builderComponentsBoxProps = {
    /** Display */
    display: { type: COMPONENT_DSL_PROP_TYPES.string },
    overflow: { type: COMPONENT_DSL_PROP_TYPES.string },
    textOverflow: { type: COMPONENT_DSL_PROP_TYPES.string },
    visibility: { type: COMPONENT_DSL_PROP_TYPES.string },
    whiteSpace: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Flex */
    flexDirection: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexWrap: { type: COMPONENT_DSL_PROP_TYPES.string },
    justifyContent: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignItems: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignContent: { type: COMPONENT_DSL_PROP_TYPES.string },
    order: { type: COMPONENT_DSL_PROP_TYPES.string },
    flex: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexGrow: { type: COMPONENT_DSL_PROP_TYPES.string },
    flexShrink: { type: COMPONENT_DSL_PROP_TYPES.string },
    alignSelf: { type: COMPONENT_DSL_PROP_TYPES.string },
    /** Palette */
    color: { type: COMPONENT_DSL_PROP_TYPES.string },
    backgroundColor: { type: COMPONENT_DSL_PROP_TYPES.string },
};
/**
 * DEPRECATED! Remove issue - https://8base-dev.atlassian.net/browse/APB-1426
 */
export const BuilderComponentsAutocompleteOption = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsAutocompleteOption,
    displayName: 'Autocomplete Option',
    icon: 'container',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Box',
    },
    overlayOutlineWhenEmpty: true,
    schema: Object.assign(Object.assign({ interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable, dndTargetPropName: 'children' }, getSysMinSizeStyle()), { presentations: [visibleByCondition], props: Object.assign(Object.assign({}, commonComponentProps), builderComponentsBoxProps) }),
    settings: BuilderComponentsBoxSettings,
};
