import { useCallback, useState } from 'react';
import { useEffectDeep } from '@builder/utils';
export const useObjectState = (defaultState = {}) => {
    // CODE_ENGINE_USAGE_START
    const [value, setValue] = useState(defaultState);
    useEffectDeep(() => {
        setValue(defaultState);
    }, [defaultState]);
    const getProperty = useCallback((keyName) => {
        if (value && value[keyName]) {
            return value[keyName];
        }
    }, [value]);
    const setProperty = useCallback((keyName, keyValue) => {
        if (value && keyName) {
            return setValue(Object.assign(Object.assign({}, value), { [keyName]: keyValue }));
        }
    }, [value]);
    return {
        value,
        getProperty,
        setProperty,
        setValue,
    };
    // CODE_ENGINE_USAGE_END
};
