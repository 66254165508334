var _a;
import { omit } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, STATE_SCOPES, STATE_TYPES, COMPONENT_DSL_CALLBACK_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonFormFieldProps } from '../../common/commonProps';
import { BuilderComponentsDateTimePicker } from '../../material-components/BuilderComponentsDateTimePicker';
import { BuilderComponentsFormDateTimePickerSettings } from './BuilderComponentsFormDateTimePicker.settings';
const timeElapsed = Date.now();
const today = new Date(timeElapsed);
export const BuilderComponentsFormDateTimePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsFormDateTimePicker,
    displayName: 'Form Date Time Picker',
    icon: 'formDateTimePicker',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.form,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'FormDateTimePicker',
    },
    schema: {
        mainPropPath: {
            mainProp: 'Label',
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsForm,
                },
            },
        ],
        presentations: [visibleByCondition],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'FormDateTimePicker',
                    defaultValue: today.toLocaleDateString(),
                },
            ],
            props: {
                fieldProps: {
                    name: '__FORM__.FormDateTimePicker',
                },
                dateTimePickerProps: Object.assign(Object.assign({}, omit(['value', 'onChange'], (_a = BuilderComponentsDateTimePicker.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props)), { value: '{{ __STATES__.FormDateTimePicker.value }}', onChange: {
                        actionType: 'customCode',
                        args: {
                            code: '__STATES__.FormDateTimePicker.setValue(date)',
                        },
                    } }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { onAccept: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, dateTimePickerProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: BuilderComponentsDateTimePicker.schema.props,
            }, fieldProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: commonFormFieldProps,
            } }),
    },
    settings: BuilderComponentsFormDateTimePickerSettings,
};
