import React, { useCallback, useMemo, useRef } from 'react';

import { StatePayload } from '@8base-private/event-handler';
import styled from '@emotion/styled';
import { PopperPlacementType } from '@mui/material';
import { bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import { indexBy } from 'ramda';

import { reactMemo } from '@builder/utils';

import { useUIBuilderUsersSessions } from 'src/providers';
import {
  useCurrentTeamMember,
  useCurrentWorkspace,
  useTeamMemberList,
} from 'src/shared/graphql/hooks';
import { StatePayloadWithColor, TeamMemberWithColor } from 'src/store';

import { ActiveMembers } from './components/ActiveMembers';
import { AdditionalMembersPopper } from './components/AdditionalMembersPopper';
import { UserDetailsPopper } from './components/UserDetailsPopper';

const AvatarsContainer = styled.div`
  display: flex;
  max-width: 200px;
  align-items: center;
  justify-content: flex-start;
`;

type DesignScopesProps = {
  avatarSize?: number;
  popperPlacement?: PopperPlacementType;
  scopeFilter?: (state: Partial<StatePayload>) => boolean;
  maxAvatars?: number;
};

const ADDITIONAL_AVATARS_POPUP_ID = 'DesignScopesAdditionalAvatars';
const USER_DETAILS_POPUP_ID = 'DesignScopesUserDetails';

export const DesignScopes: React.FC<DesignScopesProps> = reactMemo(
  'DesignScopes',
  ({
    avatarSize = 30,
    popperPlacement = 'left',
    scopeFilter = () => true,
    maxAvatars = 3,
  }: DesignScopesProps) => {
    const { backendWorkspaceId, backendWorkspaceApiHost } = useCurrentWorkspace();
    const { data } = useTeamMemberList(backendWorkspaceId || '', backendWorkspaceApiHost || '');
    const { teamMember } = useCurrentTeamMember();
    const userSessions = useUIBuilderUsersSessions();
    const selectedMemberRef = useRef<TeamMemberWithColor | null>(null);

    const environmentMembers = useMemo(() => {
      return data?.system?.environmentMembersList?.items || [];
    }, [data]);

    const activeMembers: Record<string, TeamMemberWithColor> = useMemo(() => {
      const membersMap = indexBy(member => member.email, environmentMembers);

      const isValidMember = (
        accMembers: Record<string, StatePayloadWithColor>,
        member: StatePayloadWithColor,
      ) => {
        const isValidData = member.data && scopeFilter(member.data);
        const isExistingMember = membersMap[member.email];
        const isNotCurrentMember = member.email !== teamMember?.email;
        const isUniqueEmail = !Object.values(accMembers).find(
          accMember => accMember.email === member.email,
        );

        return isValidData && isExistingMember && isNotCurrentMember && isUniqueEmail;
      };

      return Object.values(userSessions).reduce((acc, state) => {
        if (isValidMember(acc, state)) {
          return {
            ...acc,
            [state.sessionId]: { ...membersMap[state.email], color: state.color },
          };
        }

        return acc;
      }, {});
    }, [environmentMembers, scopeFilter, teamMember?.email, userSessions]);

    const additionalAvatarsPopupState = usePopupState({
      variant: 'popper',
      popupId: ADDITIONAL_AVATARS_POPUP_ID,
    });

    const additionalAvatarsPopupProps = bindHover(additionalAvatarsPopupState);

    const userDetailsPopupState = usePopupState({
      variant: 'popper',
      popupId: USER_DETAILS_POPUP_ID,
    });

    const userDetailsPopupProps = bindHover(userDetailsPopupState);
    const userDetailsOnMouseOver = useCallback(
      (event, member) => {
        selectedMemberRef.current = member;
        // Typescript and flow typing for "material-ui-popup-state@1.9.0" are different,
        // in runtime we can use only onMouseOver, not onMouseEnter as defined
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userDetailsPopupProps.onMouseOver(event);
      },
      [userDetailsPopupProps],
    );

    return (
      <AvatarsContainer>
        <ActiveMembers
          maxAvatars={maxAvatars}
          avatarSize={avatarSize}
          additionalAvatarsPopupProps={additionalAvatarsPopupProps}
          activeMembers={activeMembers}
          userDetailsPopupProps={userDetailsPopupProps}
          userDetailsOnMouseOver={userDetailsOnMouseOver}
        />

        <If condition={Object.keys(activeMembers).length > maxAvatars}>
          <AdditionalMembersPopper
            placement={popperPlacement}
            popupState={additionalAvatarsPopupState}
            activeMembers={activeMembers}
            maxAvatars={100}
            avatarSize={avatarSize}
            additionalAvatarsPopupProps={additionalAvatarsPopupProps}
            userDetailsPopupProps={userDetailsPopupProps}
            userDetailsOnMouseOver={userDetailsOnMouseOver}
          />
        </If>

        <UserDetailsPopper
          placement={popperPlacement}
          popupState={userDetailsPopupState}
          member={selectedMemberRef.current}
        />
      </AvatarsContainer>
    );
  },
);
