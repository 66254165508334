import React, { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { path, pathOr, update, remove, isEmpty } from 'ramda';

import {
  componentSettingSelectors,
  PropAssert,
  ComponentSettingDSL,
  ArrayPropValue,
  NodePropValue,
  ComponentSettingSubListDSL,
} from '@builder/schemas';
import { memo, pathToString } from '@builder/utils';

import { SettingsDraggableListItem } from '../../common/components';
import { TypographyStyled } from '../../common/components/SettingsListItem/SettingsListItem.styles';
import { useNodeSettingsProps } from '../../node-settings-generator';
import { ValidationListItem } from '../../node-settings-generator/NodeSettingsGenerator';
import { CssGrid, Button } from 'src/shared/components';
import { AddTestPopper } from 'src/shared/components/AddTestPopper';
import { TEST_CASES } from 'src/shared/components/AddTestPopper/AddTestPopper.constants';

type SubListTypeProps = {
  setting: ComponentSettingSubListDSL;
  validationList?: ValidationListItem;
  prefixKeyPath: (string | number)[];
  onUpdateProp: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  children: (args: {
    settings: ComponentSettingDSL[];
    prefixKeyPath: Array<string | number>;
    listSectionPath: Array<string | number>;
    validationList?: ValidationListItem;
  }) => React.ReactNode;

  sectionPath: Array<string | number>;
};

const ListTypeContent: React.FC<{
  item: Record<string, unknown>;
  itemLabelKeyPath: string[];
  index: number;
  fixedTittle: string | undefined;
  reorderListItems: (dragIndex: number, hoverIndex: number) => void;
  'data-test'?: string;
  isMenuPopperOpened?: boolean;
}> = ({
  item,
  itemLabelKeyPath,
  index,
  fixedTittle,
  reorderListItems,
  children,
  'data-test': dataTest,
  isMenuPopperOpened,
}) => {
  const label = path<string>(itemLabelKeyPath, item);

  return (
    <SettingsDraggableListItem
      index={index}
      data-test={dataTest}
      label={label}
      title={fixedTittle ? `${label} ${fixedTittle}` : label}
      onReorder={reorderListItems}
      isMenuPopperOpened={isMenuPopperOpened}
    >
      {children}
    </SettingsDraggableListItem>
  );
};

/**
 * Renders prop list to show arrays of data.
 */
export const SubListType = memo(
  'ListType',
  ({
    setting: listSetting,
    onUpdateProp,
    children,
    sectionPath,
    validationList = {
      name: '',
      type: '',
      validation: [],
    },
    prefixKeyPath,
  }: SubListTypeProps): JSX.Element => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const keyPath = componentSettingSelectors.getSettingsKeyPath(listSetting);
    const keyValue = pathOr(
      [],
      [...prefixKeyPath, ...keyPath],
      selectedNodeDSL.props,
    ) as ArrayPropValue | null;
    const propName = componentSettingSelectors.getSettingsKeyName(listSetting);
    const itemLabelKeyPath = componentSettingSelectors.getItemLabelKeyPath(listSetting);
    PropAssert.Value.assertIsArrayProp(keyValue, propName);
    const { type: listType } = validationList;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const toggleMenu = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();

      if (menuAnchorEl || !event) {
        return setMenuAnchorEl(null);
      }

      setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
      setMenuAnchorEl(null);
    };

    const handleAddTest = (
      type: NodePropValue<string>,
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      event?.stopPropagation();

      if (menuAnchorEl || !event) {
        return setMenuAnchorEl(null);
      }

      setMenuAnchorEl(event.currentTarget);
    };

    const reorderListItems = useCallback(
      (dragIndex: number, dropIndex: number) => {
        const dragItem = keyValue[dragIndex];
        const dropItem = keyValue[dropIndex];
        const withReplacedDragItem = update(dropIndex, dragItem, keyValue);
        const reorderedListItems = update(dragIndex, dropItem, withReplacedDragItem);

        onUpdateProp({
          keyValue: reorderedListItems,
          keyPath: [...prefixKeyPath, ...keyPath],
        });
      },
      [keyPath, keyValue, onUpdateProp, prefixKeyPath],
    );

    const currentSectionPath = [...sectionPath, propName];
    const dataTest = pathToString(['propSettings', ...currentSectionPath]);
    const usedTestKeys = keyValue.map(item => item.Key);
    const selectedTypeCases = listType
      ? TEST_CASES[listType as string]?.filter(testCase => !usedTestKeys.includes(testCase))
      : [];
    const isTestList = listSetting.name === 'validation';

    return (
      <>
        <TypographyStyled variant="h6">Validation</TypographyStyled>
        <CssGrid ref={wrapperRef} data-test={`${dataTest}.nodeListRoot`}>
          {keyValue.map((childNode, index: number) => {
            return (
              <>
                <ListTypeContent
                  key={childNode.Key as string}
                  data-test={dataTest}
                  item={childNode}
                  itemLabelKeyPath={itemLabelKeyPath}
                  index={index}
                  fixedTittle={isTestList ? 'Test' : undefined}
                  reorderListItems={reorderListItems}
                  isMenuPopperOpened={isMenuOpen}
                >
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      {children({
                        settings: listSetting.settings,
                        prefixKeyPath: [...prefixKeyPath, ...keyPath, index],
                        listSectionPath: [...currentSectionPath, 'item', index],
                        validationList,
                      })}
                    </Grid>

                    <Grid container item justifyContent="flex-end">
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => {
                          const withoutDeletedItem = remove(index, 1, keyValue);
                          onUpdateProp({ keyValue: withoutDeletedItem, keyPath });
                        }}
                        data-test={`${dataTest}.popup.deleteItemBtn`}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </ListTypeContent>
              </>
            );
          })}
          <AddTestPopper
            placement="left-start"
            open={isMenuOpen}
            onClose={closeMenu}
            toggleMenu={toggleMenu}
            anchorEl={menuAnchorEl}
            onUpdateProp={onUpdateProp}
            parentKeyPath={keyPath}
            parentKeyValue={keyValue}
            selectedTypeCases={selectedTypeCases}
          />
          <Button
            onClick={event => {
              handleAddTest(listType, event);
            }}
            data-test={`${dataTest}.addTestBtn`}
            variant="contained"
            color="default"
            disabled={
              listType === undefined ||
              listType === 'time' ||
              listType === 'boolean' ||
              isEmpty(selectedTypeCases)
            }
          >
            <span>Add Test</span>
          </Button>
        </CssGrid>
      </>
    );
  },
);
