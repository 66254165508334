import styled from '@emotion/styled';

type CssGridItemProps = {
  gridArea?: React.CSSProperties['gridArea'];
  gridRowStart?: React.CSSProperties['gridRowStart'];
  gridRowEnd?: React.CSSProperties['gridRowEnd'];
  gridColumnStart?: React.CSSProperties['gridColumnStart'];
  gridColumnEnd?: React.CSSProperties['gridColumnEnd'];
  alignSelf?: React.CSSProperties['alignSelf'];
  justifySelf?: React.CSSProperties['justifySelf'];
};

export const CssGridItem = styled.div<CssGridItemProps>`
  ${props =>
    props.gridArea &&
    `
    grid-area: ${props.gridArea};
  `}

  ${props =>
    props.gridRowStart &&
    `
    grid-row-start: ${props.gridRowStart};
  `}

  ${props =>
    props.gridRowEnd &&
    `
    grid-row-end: ${props.gridRowEnd};
  `}

  ${props =>
    props.gridColumnStart &&
    `
    grid-column-start: ${props.gridColumnStart};
  `}

  ${props =>
    props.gridColumnEnd &&
    `
    grid-column-end: ${props.gridColumnEnd};
  `}

  ${props =>
    props.alignSelf &&
    `
    align-self: ${props.alignSelf};
  `}

  ${props =>
    props.justifySelf &&
    `
    justify-self: ${props.justifySelf};
  `}
`;
