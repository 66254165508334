import { COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { HEADING_SETTINGS } from './heading.settings';
const commonDescription = {
    type: COMPONENT_DSL_TYPES.htmlElement,
    schema: {
        dndTargetPropName: 'children',
        props: Object.assign({}, commonComponentProps),
    },
    settings: HEADING_SETTINGS,
};
export const h1 = Object.assign({ name: COMPONENT_DSL_NAMES.h1, displayName: COMPONENT_DSL_NAMES.h1, source: {
        htmlElement: 'h1',
    } }, commonDescription);
export const h2 = Object.assign({ name: COMPONENT_DSL_NAMES.h2, displayName: COMPONENT_DSL_NAMES.h2, source: {
        htmlElement: 'h2',
    } }, commonDescription);
export const h3 = Object.assign({ name: COMPONENT_DSL_NAMES.h3, displayName: COMPONENT_DSL_NAMES.h3, source: {
        htmlElement: 'h3',
    } }, commonDescription);
export const h4 = Object.assign({ name: COMPONENT_DSL_NAMES.h4, displayName: COMPONENT_DSL_NAMES.h4, source: {
        htmlElement: 'h4',
    } }, commonDescription);
export const h5 = Object.assign({ name: COMPONENT_DSL_NAMES.h5, displayName: COMPONENT_DSL_NAMES.h5, source: {
        htmlElement: 'h5',
    } }, commonDescription);
export const h6 = Object.assign({ name: COMPONENT_DSL_NAMES.h6, displayName: COMPONENT_DSL_NAMES.h6, source: {
        htmlElement: 'h6',
    } }, commonDescription);
