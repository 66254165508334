/* eslint-disable no-param-reassign */
import { values } from 'ramda';
import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
const LAZY_QUERY = 'lazyQuery';
const STANDBY = 'standby';
class Migration_0_19_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.0';
    }
    getChangeList() {
        return [
            'Revemove NotFoundPageRootRoute_ID from redirects',
            'Replace fetchPolicy for lazyQueries requests',
        ];
    }
    isLazyQueryVariant(variant) {
        return variant === LAZY_QUERY;
    }
    migrateStateDSL(stateDSL) {
        if (this.isLazyQueryVariant(stateDSL.variant)) {
            stateDSL.args.fetchPolicy = STANDBY;
        }
        return stateDSL;
    }
    migrateNodeDSL(nodeDSL) {
        var _a, _b, _c;
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.RootLayoutsContainer: {
                return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { redirects: {
                            nodes: (_c = (_b = (_a = nodeDSL === null || nodeDSL === void 0 ? void 0 : nodeDSL.props) === null || _a === void 0 ? void 0 : _a.redirects) === null || _b === void 0 ? void 0 : _b.nodes) === null || _c === void 0 ? void 0 : _c.filter(item => item !== 'NotFoundPageRootRoute_ID'),
                        } }) });
            }
            case COMPONENT_DSL_NAMES.BuilderComponentsRoute: {
                values(Object.assign({}, nodeDSL.context)).forEach(localState => {
                    if (this.isLazyQueryVariant(localState.variant)) {
                        localState.args.fetchPolicy = STANDBY;
                    }
                });
                return nodeDSL;
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_19_0 = new Migration_0_19_0();
