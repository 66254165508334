import { useCallback, useState } from 'react';
import { useEffectDeep } from '@builder/utils';
export const useArrayState = (defaultState = []) => {
    // CODE_ENGINE_USAGE_START
    const [array, setArray] = useState(defaultState);
    useEffectDeep(() => {
        setArray(defaultState);
    }, [defaultState]);
    const shallowEqual = useCallback((itemA, itemB) => {
        const keys1 = Object.keys(itemA);
        const keys2 = Object.keys(itemB);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (itemA[key] !== itemB[key]) {
                return false;
            }
        }
        return true;
    }, []);
    const hasItemInArray = useCallback((itemToFind) => {
        const item = array.find(element => shallowEqual(element, itemToFind));
        return item !== undefined;
    }, [array, shallowEqual]);
    const insertItemInArray = useCallback((itemToInsert) => {
        setArray([...array, itemToInsert]);
    }, [array]);
    const removeItemFromArray = useCallback((itemToFind) => {
        const withoutRemovedItem = array.filter(element => element !== itemToFind);
        setArray(withoutRemovedItem);
    }, [array]);
    return {
        value: array,
        setValue: setArray,
        hasItemInArray,
        insertItemInArray,
        removeItemFromArray,
    };
    // CODE_ENGINE_USAGE_END
};
