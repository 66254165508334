import { isNil } from 'ramda';

import { AssetBackendMeta, ASSET_TYPES, FontMimes, FONT_MIMES_TO_FORMATS } from '@builder/schemas';
import { ensureCorrectMimetype } from '@builder/utils';

type AssetBackendSystemMeta = Record<string, unknown>;
type AssetFileDSLMetaFields = Omit<AssetBackendMeta, 'mimetype'>;

export const getAssetMetaFieldsByType = (
  assetMeta: AssetBackendMeta & AssetBackendSystemMeta,
): AssetFileDSLMetaFields => {
  if (isNil(assetMeta?.type)) {
    return { type: ASSET_TYPES.other } as AssetFileDSLMetaFields;
  }

  const commonMeta = {
    type: assetMeta?.type,
    name: assetMeta?.name,
    size: assetMeta?.size,
  };

  switch (assetMeta.type) {
    case ASSET_TYPES.image: {
      return {
        ...commonMeta,
        height: assetMeta.height,
        width: assetMeta.width,
      } as AssetFileDSLMetaFields;
    }

    case ASSET_TYPES.font: {
      const fontMime = ensureCorrectMimetype(assetMeta?.path, assetMeta?.mimetype);
      const cssFormat = FONT_MIMES_TO_FORMATS[fontMime as FontMimes];

      return {
        ...commonMeta,
        format: cssFormat,
      } as AssetFileDSLMetaFields;
    }

    case ASSET_TYPES.svg:
    case ASSET_TYPES.pdf:
    case ASSET_TYPES.other: {
      return commonMeta as AssetFileDSLMetaFields;
    }
  }
};
