import React, { useCallback } from 'react';

import styled from '@emotion/styled';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { SCHEMA_DSL_VERSION } from '@builder/schemas';

import { DialogContentWithIcon, VersionText } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';

const DIALOG_ID = DASHBOARD_DIALOGS.MIGRATION_RESULT_DIALOG_ID;

export type MigrationResultDialogArgs = {
  changelist: string[];
};

const MigrationChangeList = styled.ul`
  padding-left: 15px;
  margin-block-end: 20px;
`;

const Changes = styled.div`
  font-size: 13px;
  margin-left: ${({ theme }) => theme.spacing(5.5)};
`;

export const MigrationResultDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<MigrationResultDialogArgs>(DIALOG_ID);

  const handleClose = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle>Migrations</DialogTitle>
      <DialogContentWithIcon>
        <CheckCircleOutlineIcon color="success" />
        <DialogContentText>
          Schema was successfully migrated to <VersionText>{SCHEMA_DSL_VERSION}</VersionText>{' '}
          version
        </DialogContentText>
      </DialogContentWithIcon>
      <Changes>
        <If condition={args.changelist.length > 0}>
          <Typography variant="body2">Applied Changes:</Typography>
          <MigrationChangeList>
            {args.changelist.map(item => (
              <li key={item}>
                <Typography variant="body3">{item}</Typography>
              </li>
            ))}
          </MigrationChangeList>
        </If>
      </Changes>
      <DialogActions>
        <Button variant="contained" color="success" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
