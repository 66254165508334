import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { TABLE_FOOT } from './tableFoot.settings';
export const tableFoot = {
    displayName: 'Table Foot',
    name: COMPONENT_DSL_NAMES.tableFoot,
    icon: 'htmlTableFoot',
    type: COMPONENT_DSL_TYPES.htmlElement,
    componentListGroupName: MENU_GROUP_NAMES.html,
    source: {
        htmlElement: 'tfoot',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.table,
                },
            },
        ],
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: {
                    height: 36,
                    paddingBottom: 10,
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { children: {
                type: COMPONENT_DSL_PROP_TYPES.reactNode,
                allowedNodes: [COMPONENT_DSL_NAMES.tableRow, COMPONENT_DSL_NAMES.Iterator],
            } }),
    },
    settings: TABLE_FOOT,
};
