import { useCallback, useState } from 'react';
export const useBoolState = (defaultState = false) => {
    const [boolState, setBoolState] = useState(defaultState);
    const setBoolStateToTrue = useCallback(() => {
        setBoolState(true);
    }, []);
    const setBoolStateToFalse = useCallback(() => {
        setBoolState(false);
    }, []);
    const toggleBoolState = useCallback(() => {
        setBoolState(!boolState);
    }, [boolState]);
    return [
        boolState,
        { setTrue: setBoolStateToTrue, setFalse: setBoolStateToFalse, toggle: toggleBoolState },
    ];
};
