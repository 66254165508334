import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialAlertSettings } from './MaterialAlert.settings';
export const MaterialAlert = {
    name: COMPONENT_DSL_NAMES.MaterialAlert,
    displayName: 'Alert',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'Alert',
    },
    schema: {
        presentations: [visibleByCondition],
        predefineds: {
            props: {
                closeText: 'Close',
                role: 'alert',
                severity: 'success',
                variant: 'standard',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Alert',
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { action: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, closeText: { type: COMPONENT_DSL_PROP_TYPES.string }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, icon: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, iconMapping: { type: COMPONENT_DSL_PROP_TYPES.object }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                ],
            }, role: { type: COMPONENT_DSL_PROP_TYPES.string }, severity: { type: COMPONENT_DSL_PROP_TYPES.enum }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialAlertSettings,
};
