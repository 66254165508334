import React from 'react';

import { AddOutlined as AddOutlinedIcon } from '@mui/icons-material';
import { css } from '@mui/material';

import { Button } from '../Button';
import { MESSAGES } from 'src/shared/constants';

import {
  EmptyStateContainer,
  EmptyStateContent,
  IconContainer,
  StyledTypography,
} from './EmptyState.styles';

type EmptyStateProps = {
  title?: string;
  description?: string;
  icon?: JSX.Element;
  addCustomButton?: React.ReactNode;
  buttonTitle?: string;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  'data-test'?: string;
};

const DEFAULT_DESCRIPTION_ADD_BUTTON = MESSAGES.emptySate.defaultDescriptionAddButton;

export const EmptyState: React.FC<EmptyStateProps> = ({
  title = MESSAGES.emptySate.defaultTitle,
  description,
  icon,
  buttonTitle,
  onButtonClick,
  addCustomButton,
  'data-test': dataTest,
}) => {
  const hasAddButton = Boolean(buttonTitle || addCustomButton);
  return (
    <EmptyStateContainer data-test={dataTest}>
      <EmptyStateContent>
        <IconContainer>{icon}</IconContainer>
        <StyledTypography variant="h6">{title}</StyledTypography>
        <If condition={description}>
          <StyledTypography variant="body1">{description}</StyledTypography>
          <If condition={Boolean(description && hasAddButton)}>
            <StyledTypography variant="body1">{DEFAULT_DESCRIPTION_ADD_BUTTON}</StyledTypography>
          </If>
        </If>
        <Choose>
          <When condition={Boolean(addCustomButton)}>{addCustomButton}</When>
          <Otherwise>
            <If condition={buttonTitle}>
              <Button
                startIcon={<AddOutlinedIcon />}
                size="small"
                css={css`
                  margin-top: 20px;
                `}
                variant="contained"
                color="success"
                onClick={onButtonClick}
              >
                {buttonTitle}
              </Button>
            </If>
          </Otherwise>
        </Choose>
      </EmptyStateContent>
    </EmptyStateContainer>
  );
};
