export var EventType;
(function (EventType) {
    EventType["Get"] = "GET";
    EventType["GetResponse"] = "GET_RESPONSE";
    EventType["Event"] = "EVENT";
    EventType["BroadcastEvent"] = "BROADCAST_EVENT";
    EventType["Save"] = "SAVE";
    EventType["BroadcastSave"] = "BROADCAST_SAVE";
    EventType["BroadcastSaveState"] = "BROADCAST_SAVE_STATE";
    EventType["Replace"] = "REPLACE";
    EventType["BroadcastReplace"] = "BROADCAST_REPLACE";
    EventType["Scope"] = "SCOPE";
    EventType["Invalidate"] = "INVALIDATE";
    EventType["BroadcastInvalidate"] = "BROADCAST_INVALIDATE";
    EventType["State"] = "STATE";
    EventType["BroadcastState"] = "BROADCAST_STATE";
    EventType["Error"] = "ERROR";
})(EventType || (EventType = {}));
export var EventOperation;
(function (EventOperation) {
    EventOperation["create"] = "create";
    EventOperation["update"] = "update";
    EventOperation["delete"] = "delete";
    EventOperation["arrayInsert"] = "arrayInsert";
    EventOperation["arrayDelete"] = "arrayDelete";
    EventOperation["arrayReplace"] = "arrayReplace";
})(EventOperation || (EventOperation = {}));
