import { RESOURCE_TYPES } from '@builder/schemas';
import { API_STAND_KEY, API_STANDS } from '@builder/utils';
import { Migration } from '../Migration';
function getKeyByValue(object, value) {
    const keys = Object.keys(object);
    return keys.find(key => object[key] === value);
}
class Migration_0_12_1 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.12.1';
    }
    getChangeList() {
        return ['Fix broken 8base resource'];
    }
    migrateResourceDSL(resourceDSL, appDSL) {
        if (resourceDSL.type !== RESOURCE_TYPES.backendEightBase) {
            return resourceDSL;
        }
        const { api } = resourceDSL;
        const API_STAND_KEY_ARRAY = Object.values(API_STAND_KEY);
        if (API_STAND_KEY_ARRAY.includes(api)) {
            return resourceDSL;
        }
        const actualApi = getKeyByValue(API_STANDS, api) || API_STAND_KEY.prod;
        return Object.assign(Object.assign({}, resourceDSL), { api: actualApi });
    }
}
export const migration_0_12_1 = new Migration_0_12_1();
