import React, { useCallback, useMemo, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Add as AddIcon } from '@mui/icons-material';
import { css, Grid, IconButton, List, ListItemButton, Paper, Typography } from '@mui/material';
import { isEmpty, isNil, path } from 'ramda';

import {
  ActionPropValue,
  CommonSettingDSLWithChildren,
  ComponentSettingActionDSL,
  ComponentSettingDSL,
  ComponentSettingPropDSL,
  ComponentSettingEventsSectionDSL,
  COMPONENT_SETTING_TYPES,
  SettingChecker,
  PropChecker,
  componentSettingSelectors,
} from '@builder/schemas';
import { memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { NodeSettingCommonProps } from '../../types';
import { getSettingKey } from '../../utils';
import { useNodeListDSL } from 'src/providers';
import { CssGrid, Popper, EmptyState, Icon } from 'src/shared/components';
import { MESSAGES } from 'src/shared/constants';

const SelectedSettingsContent = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  &:empty {
    margin-bottom: 0;
  }
`;

const PossibleSettingsContent = styled(Paper)`
  width: 140px;
  padding: ${({ theme }) => theme.spacing(1.5)} 0;
  background-color: ${({ theme }) => theme.palette.background.input};
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
`;

const EmptyPossibleSettings = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const PossibleSettingsList = styled(List)`
  width: 100%;
  padding: 0;
  max-height: ${({ theme }) =>
    `calc(100vh - ${theme.layout.TopPanel.size}px - ${theme.spacing(8)})`};
  overflow-x: auto;
`;

const PossibleSettingListItem = styled(ListItemButton)`
  font-size: 13px;
`;

type EventsSectionProps = Pick<NodeSettingCommonProps, 'sectionPath'> & {
  setting: ComponentSettingEventsSectionDSL;
  children: (args: {
    settings: ComponentSettingDSL[];
    settingsPickerSectionPath: Array<string | number>;
  }) => React.ReactNode;
  onUpdateProp: (propData: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  'data-test'?: string;
};

const DEFAULT_SETTING_LABEL = MESSAGES.nodeSettings.settingTypes.eventsSections.defaultSettingLabel;
const DEFAULT_TITLE = MESSAGES.nodeSettings.settingTypes.eventsSections.defaultTitle;
const DESCRIPTION_TEXT = MESSAGES.nodeSettings.settingTypes.eventsSections.description;

export const EventsSection = memo(
  'EventsSection',
  ({
    setting,
    children,
    sectionPath,
    onUpdateProp,
    'data-test': dataTest,
  }: EventsSectionProps): JSX.Element => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isPopperOpen = Boolean(anchorEl);
    const wrapperRef = useRef<HTMLButtonElement>(null);
    const nodeList = useNodeListDSL();

    // choose settings actions/events was added and displayed as editable events
    const getNonEmptySettings = useCallback(
      (
        settingsList: ComponentSettingDSL[],
      ): (ComponentSettingPropDSL | ComponentSettingActionDSL)[] => {
        return settingsList.reduce((acc, possibleSetting) => {
          if (SettingChecker.Schema.isSettingTypeOfAction(possibleSetting)) {
            const possibleSettingAction = path<ActionPropValue>(
              possibleSetting.keyPath,
              selectedNodeDSL.props,
            );

            if (PropChecker.Value.isEmptyObjectProp(possibleSettingAction)) {
              return [...acc, possibleSetting];
            }

            const actionTypeValue = path<string>(
              [...possibleSetting.keyPath, 'actionType'],
              selectedNodeDSL.props,
            );

            return !isNil(actionTypeValue) ? [...acc, possibleSetting] : acc;
          }

          if (!SettingChecker.Schema.isSettingTypeOfProp(possibleSetting)) {
            if (SettingChecker.Schema.hasChildrenSetting(possibleSetting)) {
              const childrenSettings = getNonEmptySettings(
                (possibleSetting as CommonSettingDSLWithChildren).children,
              );

              return [...acc, ...childrenSettings];
            }

            return acc;
          }

          const settingValue = path(possibleSetting.keyPath, selectedNodeDSL.props);
          return !isNil(settingValue) ? [...acc, possibleSetting] : acc;
        }, [] as (ComponentSettingPropDSL | ComponentSettingActionDSL)[]);
      },
      [selectedNodeDSL.props],
    );

    // choose settings actions/events can be added from popover
    const getPossibleSettings = useCallback(
      (
        settingsList: ComponentSettingDSL[],
      ): (ComponentSettingPropDSL | ComponentSettingActionDSL)[] => {
        return settingsList.reduce((acc, possibleSetting) => {
          const isShowingSetting = componentSettingSelectors.isShowingSetting(
            possibleSetting?.showIf,
            {
              sourceEntity: selectedNodeDSL,
              nodeListDSL: nodeList,
            },
          );

          if (!isShowingSetting) {
            return acc;
          }

          if (SettingChecker.Schema.isSettingTypeOfAction(possibleSetting)) {
            const possibleSettingAction = path<ActionPropValue>(
              possibleSetting.keyPath,
              selectedNodeDSL.props,
            );

            if (PropChecker.Value.isEmptyObjectProp(possibleSettingAction)) {
              return acc;
            }

            const actionTypeValue = path<string>(
              [...possibleSetting.keyPath, 'actionType'],
              selectedNodeDSL.props,
            );

            return isNil(actionTypeValue) ? [...acc, possibleSetting] : acc;
          }

          if (!SettingChecker.Schema.isSettingTypeOfProp(possibleSetting)) {
            if (SettingChecker.Schema.hasChildrenSetting(possibleSetting)) {
              const childrenSettings = getPossibleSettings(possibleSetting.children);

              return [...acc, ...childrenSettings];
            }

            return acc;
          }

          const settingValue = path(possibleSetting.keyPath, selectedNodeDSL.props);
          return isNil(settingValue) ? [...acc, possibleSetting] : acc;
        }, [] as (ComponentSettingPropDSL | ComponentSettingActionDSL)[]);
      },
      [nodeList, selectedNodeDSL],
    );

    const { selectedSettings, possibleSettings } = useMemo(() => {
      return {
        selectedSettings: getNonEmptySettings(setting.children),
        possibleSettings: getPossibleSettings(setting.children),
      };
    }, [setting.children, getNonEmptySettings, getPossibleSettings]);

    const openPossibleSettingsDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (anchorEl) {
        return closeSettingsPopper();
      }

      setAnchorEl(wrapperRef.current);
    };

    const closeSettingsPopper = () => {
      setAnchorEl(null);
    };

    const addSetting = (filteredSetting: ComponentSettingDSL) => {
      if (filteredSetting.type === COMPONENT_SETTING_TYPES.action && filteredSetting.keyPath) {
        onUpdateProp({ keyValue: {}, keyPath: filteredSetting.keyPath });
      }

      closeSettingsPopper();
    };

    return (
      <Grid container>
        <Grid item container justifyContent="space-between" alignItems="center" mb={1.5}>
          <Typography variant="h6">{setting.title ?? DEFAULT_TITLE}</Typography>
          <Grid item>
            <IconButton
              onClick={openPossibleSettingsDropdown}
              data-test={`${dataTest}.openPossibleSettingsBtn`}
              ref={wrapperRef}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Choose>
          <When condition={isEmpty(selectedSettings)}>
            <List
              css={css`
                padding: 12vh 1rem;
                overflow: auto;
              `}
            >
              <EmptyState
                title="No Events"
                description={DESCRIPTION_TEXT}
                icon={<Icon width={150} height={100} name="events" />}
              />
            </List>
          </When>
          <Otherwise>
            <SelectedSettingsContent>
              {children({
                settings: selectedSettings,
                settingsPickerSectionPath: [...sectionPath, setting.name],
              })}
            </SelectedSettingsContent>
          </Otherwise>
        </Choose>

        <Popper
          placement="left-start"
          open={isPopperOpen}
          onClose={closeSettingsPopper}
          anchorEl={anchorEl}
        >
          <PossibleSettingsContent>
            <CssGrid>
              <Choose>
                <When condition={!isEmpty(possibleSettings)}>
                  <PossibleSettingsList>
                    {possibleSettings.map(possibleSetting => {
                      const possibleSettingKeyPath = possibleSetting.keyPath || [];
                      const label = possibleSetting.label ?? DEFAULT_SETTING_LABEL;
                      const possibleSettingDataTest = `${dataTest}.${possibleSettingKeyPath.join(
                        '.',
                      )}.possibleSetting`;

                      return (
                        <PossibleSettingListItem
                          key={getSettingKey(possibleSetting, 'possibleSetting')}
                          onClick={() => addSetting(possibleSetting)}
                          data-test={possibleSettingDataTest}
                        >
                          {label}
                        </PossibleSettingListItem>
                      );
                    })}
                  </PossibleSettingsList>
                </When>
                <Otherwise>
                  <EmptyPossibleSettings>
                    <Typography align="center">No items found</Typography>
                  </EmptyPossibleSettings>
                </Otherwise>
              </Choose>
            </CssGrid>
          </PossibleSettingsContent>
        </Popper>
      </Grid>
    );
  },
);
