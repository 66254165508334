var _a, _b, _c;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialTypography } from '../MaterialTypography';
import { MaterialBreadcrumbsSettings } from './MaterialBreadcrumbs.settings';
export const MaterialBreadcrumbs = {
    name: COMPONENT_DSL_NAMES.MaterialBreadcrumbs,
    displayName: 'Breadcrumbs',
    icon: 'breadcrumbs',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@mui/material',
        componentName: 'Breadcrumbs',
    },
    overlayOutlineWhenEmpty: true,
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'alias',
        },
        predefineds: {
            props: {
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: Object.assign(Object.assign({}, (_a = MaterialTypography.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'One',
                                            },
                                        },
                                    ],
                                } }),
                        },
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            props: Object.assign(Object.assign({}, (_b = MaterialTypography.schema.predefineds) === null || _b === void 0 ? void 0 : _b.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: 'Two',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
                expandText: 'Show path',
                itemsAfterCollapse: 1,
                itemsBeforeCollapse: 1,
                maxItems: 8,
                separator: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialTypography,
                            alias: 'Breadcrumbs Separator',
                            schemaOverride: {
                                interaction: 'only-editable',
                            },
                            props: Object.assign(Object.assign({}, (_c = MaterialTypography.schema.predefineds) === null || _c === void 0 ? void 0 : _c.props), { children: {
                                    nodes: [
                                        {
                                            name: COMPONENT_DSL_NAMES.Text,
                                            props: {
                                                children: '/',
                                            },
                                        },
                                    ],
                                } }),
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { component: { type: COMPONENT_DSL_PROP_TYPES.component }, expandText: { type: COMPONENT_DSL_PROP_TYPES.string }, itemsAfterCollapse: { type: COMPONENT_DSL_PROP_TYPES.number }, itemsBeforeCollapse: { type: COMPONENT_DSL_PROP_TYPES.number }, maxItems: { type: COMPONENT_DSL_PROP_TYPES.number }, separator: { type: COMPONENT_DSL_PROP_TYPES.reactNode } }),
    },
    settings: MaterialBreadcrumbsSettings,
};
