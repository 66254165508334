import { ComponentSettingDSL } from '@builder/schemas';
import { isArray, isObject } from '@builder/utils';

/**
 * Generates uniq key based on setting content. Uses in react key prop.
 */
export const getSettingKey = (setting: ComponentSettingDSL, sectionPrefix = ''): string => {
  return `${sectionPrefix}:${Object.keys(setting)
    .filter(
      key =>
        !isObject(setting[key as keyof ComponentSettingDSL]) &&
        !isArray(setting[key as keyof ComponentSettingDSL]),
    )
    .map(key => `{${key}=${setting[key as keyof ComponentSettingDSL]}}`)
    .join(',')}`;
};
