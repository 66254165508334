import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Interpolation } from '@emotion/styled';
import { Dialog, Theme, Typography } from '@mui/material';

import { useDeployContext } from '../../../providers/DeployProvider';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { DeployProgress } from 'src/features/deploy/deploy-progress';
import { useDialogState } from 'src/providers';
import { Button, SimpleSpinner } from 'src/shared/components';
import { ReactComponent as CheckIcon } from 'src/shared/components/Icon/icons/check.svg';
import { DEPLOY_STATUSES } from 'src/shared/constants';
import { useDeploySettingsHooks } from 'src/shared/graphql/hooks';

import {
  BACKGROUND_ICON_COLORS,
  BackgroundWrapperPaper,
} from './components/BackgroundWrapperPaper';
import { ButtonsWrapper, StyledCancelOutlinedIcon } from './DeployProgressDialog.styles';
import {
  DeployProgressWrapper,
  DialogWrapper,
  PRIMARY_BUTTON_STYLES,
  SECONDARY_BUTTON_STYLES,
} from './styles';

type DeployProgressDialogArgs = {
  buildName: string;
};

const DIALOG_ID = DASHBOARD_DIALOGS.DEPLOY_PROGRESS_DIALOG_ID;

export const DeployProgressDialog: React.FC = () => {
  const { isOpen, closeDialog } = useDialogState<DeployProgressDialogArgs>(DIALOG_ID);
  const { deploy, isLoadingDeployResult } = useDeployContext();
  const { deploySettingsResult } = useDeploySettingsHooks();
  const projectUrl = useMemo(() => {
    return deploySettingsResult.data?.appbuilder?.settings?.projectUrl as string;
  }, [deploySettingsResult]);

  const handleDialogClose = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  // When we perform first deployment we assign update settings (ex: projectUrl)
  useEffect(() => {
    if (deploy) {
      deploySettingsResult.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploy, deploySettingsResult.refetch]);

  const backgroundIconColor =
    deploy?.status === DEPLOY_STATUSES.resolved
      ? BACKGROUND_ICON_COLORS.green
      : BACKGROUND_ICON_COLORS.red;

  const deployWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(
    function scrollDeployLogsToBottom() {
      if (deploy?.status === DEPLOY_STATUSES.pending) {
        const documentNode = deployWrapperRef.current;

        if (documentNode) {
          const timeoutId = setInterval(() => {
            documentNode.scroll({
              top: documentNode.scrollHeight,
              behavior: 'smooth',
            });
          }, 1000);

          const scrollEventListener = () => {
            clearInterval(timeoutId);
          };

          documentNode.addEventListener('wheel', scrollEventListener);

          return () => {
            clearInterval(timeoutId);
            documentNode.removeEventListener('wheel', scrollEventListener);
          };
        }
      }
    },
    [deploy],
  );

  return (
    <Dialog
      fullScreen
      open={isOpen}
      PaperComponent={props => (
        <BackgroundWrapperPaper {...props} backgroundIconColor={backgroundIconColor} />
      )}
    >
      <DialogWrapper>
        <Choose>
          <When condition={isLoadingDeployResult}>
            <SimpleSpinner />
          </When>
          <Otherwise>
            <Choose>
              <When condition={!deploy || deploy?.status === DEPLOY_STATUSES.pending}>
                <SimpleSpinner style={{ marginTop: '80px' }} />
                <Typography style={{ fontSize: '32px' }}>Deployment in progress</Typography>
                <DeployProgressWrapper ref={deployWrapperRef}>
                  <DeployProgress deploy={deploy} />
                </DeployProgressWrapper>
                <Button
                  css={PRIMARY_BUTTON_STYLES as Interpolation<Theme>}
                  variant="contained"
                  onClick={handleDialogClose}
                >
                  Close
                </Button>
              </When>
              <When condition={deploy?.status === DEPLOY_STATUSES.resolved}>
                <CheckIcon style={{ marginTop: '-200px' }} />
                <Typography style={{ fontSize: '32px' }}>Project deployed</Typography>
                <div style={{ width: '800px' }}>
                  <Typography
                    style={{ fontSize: '20px' }}
                    fontWeight="normal"
                    lineHeight="1.6"
                    textAlign="center"
                  >
                    {deploy?.message}
                  </Typography>
                </div>
                <ButtonsWrapper>
                  <Button
                    css={SECONDARY_BUTTON_STYLES as Interpolation<Theme>}
                    variant="contained"
                    color="default"
                    onClick={handleDialogClose}
                  >
                    Go Back
                  </Button>
                  <Button
                    css={PRIMARY_BUTTON_STYLES as Interpolation<Theme>}
                    variant="contained"
                    disabled={!projectUrl}
                    href={projectUrl ?? ''}
                    target="_blank"
                  >
                    Open App
                  </Button>
                </ButtonsWrapper>
              </When>
              <When condition={deploy?.status === DEPLOY_STATUSES.rejected}>
                <StyledCancelOutlinedIcon color="error" />
                <Typography variant="h3">Deployment failed</Typography>
                <Typography variant="h4" fontWeight="normal" lineHeight="1.6">
                  {deploy?.message}
                </Typography>
                <DeployProgressWrapper>
                  <DeployProgress deploy={deploy} />
                </DeployProgressWrapper>
                <Button
                  css={PRIMARY_BUTTON_STYLES as Interpolation<Theme>}
                  variant="contained"
                  onClick={handleDialogClose}
                >
                  Go Back
                </Button>
              </When>
            </Choose>
          </Otherwise>
        </Choose>
      </DialogWrapper>
    </Dialog>
  );
};
