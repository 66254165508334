import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

export const PopupContent = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1.5)};
  width: ${({ theme }) => theme.layout.RightPanel.size}px;
  box-shadow: ${({ theme }) => theme.layout.effects.popupShadow};
  border-radius: 8px;
`;

export const PopupHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  margin: -${({ theme }) => theme.spacing(1.5)};
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const PopupTitle = styled(Typography)`
  font-weight: 600;
`;

export const AssetMediaContainer = styled.div`
  .MuiCardMedia-root {
    border-radius: 8px;
  }
`;
