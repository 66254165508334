import { MutableRefObject } from 'react';

import {
  generateEvent,
  ActionEvent,
  EventType,
  StateEvent,
  EventPayload,
} from '@8base-private/event-handler';
import { nanoid as uuid } from 'nanoid';
import { equals } from 'ramda';
import { AnyAction as ReduxAction, Middleware, Reducer, CombinedState } from 'redux';
import Rollbar from 'rollbar';

import { AppConfiguration, NodeDSL } from '@builder/schemas';
import { API_STANDS, processEnv } from '@builder/utils';

import { DraftEngine } from '../../shared/draft-engine';
import { AppEvents, DASHBOARD_EVENTS, LEFT_PANEL_TABS, Store, UI_BUILDER_EVENTS } from '../common';
import { getAppConfiguration, getCurrentRouteNode } from '../selectors/store-selectors';
import { DASHBOARD_DIALOGS } from 'src/dialogs';
import { OpenDialogFn } from 'src/providers/DialogProvider';
import { AUTH_KEY } from 'src/shared/constants';
import { getEnvironmentString } from 'src/shared/utils';

import { getActionScope } from './utils/calculateScope';
import { assertDSL } from './utils/DSLChecker';
import { sortPayloads } from './utils/sortPayloads';

const IGNORED_EVENTS: string[] = [
  DASHBOARD_EVENTS.appConfigurationInit,
  DASHBOARD_EVENTS.appConfigurationUpdate,
  DASHBOARD_EVENTS.appConfigurationUpdateDslNodes,
  DASHBOARD_EVENTS.componentDeselect,
  DASHBOARD_EVENTS.componentHover,
  DASHBOARD_EVENTS.componentHoverRemove,
  DASHBOARD_EVENTS.componentSelect,
  DASHBOARD_EVENTS.componentSelectedRemove,
  DASHBOARD_EVENTS.componentToggleFocus,
  DASHBOARD_EVENTS.componentToggleSelect,
  DASHBOARD_EVENTS.componentToggleSelectByID,
  DASHBOARD_EVENTS.dashboardClear,
  DASHBOARD_EVENTS.dashboardClearSelection,
  DASHBOARD_EVENTS.dashboardIFrameRefresh,
  DASHBOARD_EVENTS.dashboardSetLoadingConfiguration,
  DASHBOARD_EVENTS.layoutChange,
  DASHBOARD_EVENTS.layoutModeUpdate,
  DASHBOARD_EVENTS.navigatorCollapseNode,
  DASHBOARD_EVENTS.navigatorCollapseNodeList,
  DASHBOARD_EVENTS.navigatorExpandNode,
  DASHBOARD_EVENTS.navigatorExpandNodeList,
  DASHBOARD_EVENTS.routerPathUpdate,
  DASHBOARD_EVENTS.stateReport,
  DASHBOARD_EVENTS.updateNodeMeasures,
  DASHBOARD_EVENTS.historyFlush,
  DASHBOARD_EVENTS.historyPush,
  DASHBOARD_EVENTS.routerPathUpdateByUserApp,
  DASHBOARD_EVENTS.userAppResize,
];

const OBSERVED_DASHBOARD_EVENTS = [
  ...Object.values(DASHBOARD_EVENTS).filter(event => !IGNORED_EVENTS.includes(event)),
];

const OBSERVED_UI_BUILDER_EVENTS = [UI_BUILDER_EVENTS.updateLeftPanel];
const { email = '' }: Record<string, string> = JSON.parse(localStorage?.getItem(AUTH_KEY) || '{}');

function createActionEvent(
  previousConfiguration: AppConfiguration,
  currentConfiguration: AppConfiguration,
  currentRouteNode: NodeDSL,
  action: ReduxAction,
): ActionEvent {
  const event = generateEvent(previousConfiguration, currentConfiguration);
  const newEventsToPush: EventPayload[] = [];

  const processedPayload = event.payload.reduce(
    (acc: EventPayload[], sourceEventPayload: EventPayload) => {
      const { eventPayload, newEventsToPush: eventsToPush } = getActionScope(
        currentRouteNode,
        sourceEventPayload,
        action,
        currentConfiguration,
        previousConfiguration,
      );
      return [...acc, eventPayload, ...eventsToPush];
    },
    [],
  );

  const processedReversePayload = event.reversePayload?.reduce(
    (acc: EventPayload[], sourceEventPayload: EventPayload) => {
      const { eventPayload, newEventsToPush: eventsToPush } = getActionScope(
        currentRouteNode,
        sourceEventPayload,
        action,
        currentConfiguration,
        previousConfiguration,
      );
      return [...acc, eventPayload, ...eventsToPush];
    },
    [],
  );

  const payloadsToReturn = [...processedPayload, ...newEventsToPush].sort(sortPayloads);
  const reversePayloadToReturn = [...(processedReversePayload || []), ...newEventsToPush].sort(
    sortPayloads,
  );

  return {
    ...event,
    payload: payloadsToReturn,
    email,
    reversePayload: reversePayloadToReturn,
  };
}

function createStateEvent(state: Store): StateEvent {
  const { id: currentRouteNodeId } = getCurrentRouteNode(state);
  const { currentTab } = state.uiBuilder.leftPanel;

  return {
    type: EventType.State,
    uuid: uuid(),
    payload: {
      global: currentTab === LEFT_PANEL_TABS.none ? undefined : currentTab,
      local: currentRouteNodeId,
    },
  };
}

function createBroadcastChangesMiddleware(
  draftEngineRef: MutableRefObject<DraftEngine | undefined>,
  rollbar: Rollbar,
  openDialog: OpenDialogFn,
  workspaceID: string | null,
  rootReducer: Reducer<CombinedState<Store>, AppEvents>,
): Middleware {
  return store => next => action => {
    if (OBSERVED_UI_BUILDER_EVENTS.includes(action.type)) {
      const nextState = rootReducer({ ...store.getState() }, action);
      draftEngineRef.current?.send(createStateEvent(nextState));
    }

    if (!OBSERVED_DASHBOARD_EVENTS.includes(action.type)) return next(action);

    const tempStore = rootReducer({ ...store.getState() }, action);
    const isTempStoreValid = OBSERVED_DASHBOARD_EVENTS.includes(action.type)
      ? assertDSL(
          tempStore.dashboard.appConfiguration.appDSL,
          tempStore.dashboard.appConfiguration?.userComponentsDSL,
        )
      : true;

    if (isTempStoreValid) {
      const previousState = store.getState();
      next(action);
      const currentState = store.getState();

      if (OBSERVED_DASHBOARD_EVENTS.includes(action.type)) {
        const previousConfiguration = getAppConfiguration(previousState);
        const currentConfiguration = getAppConfiguration(currentState);
        const currentRouteNode = getCurrentRouteNode(currentState);

        if (!equals(previousConfiguration, currentConfiguration)) {
          const event = createActionEvent(
            previousConfiguration,
            currentConfiguration,
            currentRouteNode,
            action,
          );

          // UNDO/REDO
          if (action.type === DASHBOARD_EVENTS.applyEvents) {
            const { reversePayload, ...restAction } = event;
            const history = {
              past: currentState.dashboard.history.past,
              future: currentState.dashboard.history.future,
            };

            const actionEvent = { ...restAction, history };

            draftEngineRef.current?.send(actionEvent);
          } else if (
            // this is to prevent saving history for global scope events
            // TODO: remove this if when we start supporting global scope events
            !event.payload.some((payload: EventPayload) => payload.scope === 'global')
          ) {
            store.dispatch({
              type: DASHBOARD_EVENTS.dashboardUpdateHistory,
              payload: event,
            });

            const newStore = store.getState();

            const history = {
              past: newStore.dashboard.history.past,
              future: newStore.dashboard.history.future,
            };

            const actionEvent = { ...event, history };

            draftEngineRef.current?.send(actionEvent);
          } else {
            draftEngineRef.current?.send(event);
          }
        }
      }

      return;
    }

    openDialog(DASHBOARD_DIALOGS.PREVENT_BREAKING_DSL_DIALOG_ID);

    // Log the error
    rollbar.error(`actionPreventedError: Action prevented for ${workspaceID}`, {
      action,
      apiEnvironment: getEnvironmentString(API_STANDS, processEnv.getServerURL()),
      appDSL: store.getState().dashboard.appConfiguration,
    });
  };
}

export { createBroadcastChangesMiddleware as default };
