import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { AppVersion, NodeDSL, StateDSL } from '@builder/schemas';

import { APOLLO_OPERATION_CONTEXT } from '../../constants';
import { DeployProductionMutation, DeployProductionMutationVariables } from '../__generated__';
import { DEPLOY_PRODUCTION_MUTATION } from '../fragments';
import { useAppDispatch } from 'src/providers';
import { useCurrentWorkspaceID } from 'src/shared/hooks';
import { APP_RENDER_ERRORS, ItemErrorContext, UI_BUILDER_EVENTS } from 'src/store';

type StartDeployOptions = {
  deployedAppVersion: AppVersion;
};

type UseApplicationDeployReturn = () => {
  startingDeploy: boolean;
  startDeploy: (options: StartDeployOptions) => Promise<string | null>;
};

export const useApplicationDeploy: UseApplicationDeployReturn = () => {
  const { workspaceID } = useCurrentWorkspaceID();
  const send = useAppDispatch();

  const [deployAppBuilderMutation, deployAppBuilderMutationOptions] = useMutation<
    DeployProductionMutation,
    DeployProductionMutationVariables
  >(DEPLOY_PRODUCTION_MUTATION, {
    context: {
      [APOLLO_OPERATION_CONTEXT.SUCCESS_MESSAGE]: 'AppBuilder project deploy successfully started',
    },
  });

  const startDeploy = useCallback(
    async ({ deployedAppVersion }: StartDeployOptions) => {
      if (!workspaceID) {
        return null;
      }

      try {
        const deployResponse = await deployAppBuilderMutation({
          variables: {
            data: { workspaceId: workspaceID, deployedAppVersion },
          },
        });

        return deployResponse.data?.appbuilder?.deploy?.buildName ?? null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const notificationErrors: ItemErrorContext[] = [];
        error.graphQLErrors?.[0]?.details?.errors?.forEach(
          (deployError: { page: NodeDSL; message: string; state: StateDSL }) => {
            const notification: ItemErrorContext = {
              type: '@builder/error',
              context: {
                nodeID: '',
                isLayout: false,
                panelSection: 'pages',
                isDupeStateName: true,
                type: 'error',
                scope: 'node',
                propPath: [''],
                locators: {
                  highlight: [{ attribute: '', type: '' }],
                  location: [{ action: '', attribute: '' }],
                },
                pageID: deployError.page.id,
                title: `Pages / ${deployError.page.alias} / States / Local / ${deployError.state.name} / name`,
                message: deployError.message,
                fullPath: [''],
                targetID: '',
                layoutID: '',
              },
              key: new Date().toISOString(),
              targetID: '',
            };

            send({
              type: UI_BUILDER_EVENTS.notificationSend,
              notification: {
                message: deployError.message,
                options: {
                  variant: 'error',
                },
              },
            });
            notificationErrors.push(notification);
          },
        );

        if (notificationErrors.length) {
          send({
            type: APP_RENDER_ERRORS.pushError,
            payload: notificationErrors,
          });
        }

        return null;
      }
    },
    [workspaceID, deployAppBuilderMutation, send],
  );

  return {
    startingDeploy: deployAppBuilderMutationOptions.loading,
    startDeploy,
  };
};
