import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { IntrospectionUrlQuery } from '../__generated__';
import { INTROSPECTION_URL_QUERY } from '../fragments';
import { useUserApiEndpoint } from 'src/providers/ReduxProvider';
import { APOLLO_OPERATION_CONTEXT } from 'src/shared/constants';

type UseIntrospectionUrlReturn = {
  introspectionUrl?: string;
};

export const useIntrospectionUrl = (): UseIntrospectionUrlReturn => {
  const userApiEndpoint = useUserApiEndpoint();

  const { data } = useQuery<IntrospectionUrlQuery>(INTROSPECTION_URL_QUERY, {
    skip: !userApiEndpoint,
    context: {
      [APOLLO_OPERATION_CONTEXT.ENDPOINT_URL]: userApiEndpoint,
      [APOLLO_OPERATION_CONTEXT.NO_AUTH]: true,
    },
  });

  const introspectionUrl = useMemo(() => data?.system?.introspection?.url, [data]);

  return {
    introspectionUrl,
  };
};
