import { DEFAULT_META_TAG_NAME } from '@builder/schemas/src/constants';

import { AutocompleteOption } from 'src/shared/components';

export const DEFAULT_META_TAG_LABEL = {
  description: 'Description',
  keywords: 'Keywords',
  charset: 'Charset',
  author: 'Author',
  viewport: 'Viewport',
} as const;

export const DEFAULT_META_TAG_OPTIONS: AutocompleteOption[] = [
  {
    value: DEFAULT_META_TAG_NAME.description,
    label: DEFAULT_META_TAG_LABEL.description,
  },
  {
    value: DEFAULT_META_TAG_NAME.keywords,
    label: DEFAULT_META_TAG_LABEL.keywords,
  },
  {
    value: DEFAULT_META_TAG_NAME.charset,
    label: DEFAULT_META_TAG_LABEL.charset,
  },
  {
    value: DEFAULT_META_TAG_NAME.author,
    label: DEFAULT_META_TAG_LABEL.author,
  },
  {
    value: DEFAULT_META_TAG_NAME.viewport,
    label: DEFAULT_META_TAG_LABEL.viewport,
  },
];

export const UTF8_VALUE = 'UTF-8';

export const META_TAG_CHARSET_OPTIONS: AutocompleteOption[] = [
  {
    label: 'ASCII',
    value: 'ASCII',
  },
  {
    label: UTF8_VALUE,
    value: UTF8_VALUE,
  },
  {
    label: 'ISO-8859-1',
    value: 'ISO-8859-1',
  },
  {
    label: 'Windows-1252',
    value: 'Windows-1252',
  },
];

export const DEFAULT_NEW_META_TAG = {
  name: '',
  content: '',
} as const;
