import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_NAMES, COMPONENT_DSL_CALLBACK_TYPES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, commonStyleProps } from '../../common/commonProps';
import { BuilderComponentsSelectSettings } from './BuilderComponentsSelect.settings';
export const BuilderComponentsSelect = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSelect,
    displayName: 'Selector',
    icon: 'select',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    isCommonlyUsed: true,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Select',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Label',
        },
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                    name: 'selectField',
                    defaultValue: '',
                },
            ],
            props: {
                variant: 'outlined',
                label: 'Select',
                size: 'medium',
                value: '{{ __STATES__.selectField.value }}',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.selectField.setValue(event.target.value)',
                    },
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.MaterialMenuItem,
                            iterator: {
                                data: [
                                    { id: 1, value: 'One', label: 'One!' },
                                    { id: 2, value: 'Two', label: 'Two!' },
                                    { id: 3, value: 'Three', label: 'Three!' },
                                ],
                                name: 'item',
                            },
                            props: {
                                value: '{{ item.value }}',
                                children: {
                                    nodes: [
                                        {
                                            name: 'Text',
                                            props: {
                                                children: '{{ item.label }}',
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { autoComplete: { type: COMPONENT_DSL_PROP_TYPES.string }, autoFocus: { type: COMPONENT_DSL_PROP_TYPES.boolean }, behavior: { type: COMPONENT_DSL_PROP_TYPES.string }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, defaultValue: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableUnderline: { type: COMPONENT_DSL_PROP_TYPES.boolean }, fullWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, error: { type: COMPONENT_DSL_PROP_TYPES.string }, inputComponent: { type: COMPONENT_DSL_PROP_TYPES.elementType }, inputProps: { type: COMPONENT_DSL_PROP_TYPES.object }, margin: { type: COMPONENT_DSL_PROP_TYPES.enum }, multiline: { type: COMPONENT_DSL_PROP_TYPES.boolean }, name: { type: COMPONENT_DSL_PROP_TYPES.string }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'child',
                        path: [1],
                    },
                ],
            }, placeholder: { type: COMPONENT_DSL_PROP_TYPES.string }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, required: { type: COMPONENT_DSL_PROP_TYPES.boolean }, rows: { type: COMPONENT_DSL_PROP_TYPES.string }, rowsMax: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.string }, autoWidth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, displayEmpty: { type: COMPONENT_DSL_PROP_TYPES.boolean }, native: { type: COMPONENT_DSL_PROP_TYPES.boolean }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, size: { type: COMPONENT_DSL_PROP_TYPES.enum }, 
            // TODO: Icon component
            IconComponent: { type: COMPONENT_DSL_PROP_TYPES.component, componentName: 'img' }, 
            // TODO: ?
            input: { type: COMPONENT_DSL_PROP_TYPES.elementType }, 
            // TODO: node
            label: { type: COMPONENT_DSL_PROP_TYPES.string }, labelID: { type: COMPONENT_DSL_PROP_TYPES.string }, MenuProps: { type: COMPONENT_DSL_PROP_TYPES.object }, SelectDisplayProps: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: Object.assign({}, commonStyleProps),
            } }),
    },
    settings: BuilderComponentsSelectSettings,
};
