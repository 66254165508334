import { without } from 'ramda';

import {
  AppDSL,
  AssetID,
  AssetListDSL,
  assetListSelectors,
  assetSelectors,
  ASSET_TYPES,
} from '@builder/schemas';

export const deleteAsset = ({
  appDSL,
  assetIDs,
}: {
  appDSL: AppDSL;
  assetIDs: AssetID[];
}): AppDSL => {
  const assetListDLS = appDSL.assets || {};
  const folderIDs = assetIDs.filter(assetID => assetListDLS[assetID].type === ASSET_TYPES.folder);
  const descendantFolderIDs = assetListSelectors.getAllDescendantFolderIDs(assetListDLS, {
    folderIDs,
  });
  const folderToDeleteIDs = [...folderIDs, ...descendantFolderIDs];

  const newAssetListDSL = Object.entries(assetListDLS).reduce((result, [assetID, assetDSL]) => {
    const parentID = assetSelectors.getAssetParentID(assetDSL);
    const shouldBeDeleted =
      assetIDs.some(id => id === assetID) ||
      folderToDeleteIDs.some(folderID => folderID === parentID);

    if (shouldBeDeleted) {
      return result;
    }

    if (assetDSL.type !== ASSET_TYPES.folder) {
      return {
        ...result,
        [assetID]: assetDSL,
      };
    }

    return {
      ...result,
      [assetID]: {
        ...assetDSL,
        children: without(assetIDs, assetDSL.children),
      },
    };
  }, {} as AssetListDSL);

  return {
    ...appDSL,
    assets: newAssetListDSL,
  };
};
