import React, { useCallback, useState } from 'react';

import styled from '@emotion/styled';
import { Height as HeightIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { editor as MonacoEditorType } from 'monaco-editor';
import { Dispatch } from 'redux';

import { useBoolState, useDebouncedState } from '@builder/utils';

import { useAppDispatch } from 'src/providers/ReduxProvider';
import {
  Field,
  FieldRenderProps,
  EditorFormDialog,
  MonacoEditor,
  InputContainer,
  InputContainerProps,
} from 'src/shared/components';
import { getMonacoEditorBorderHighlightingStyles } from 'src/shared/styles/monaco';
import { AppEvents, UI_BUILDER_EVENTS } from 'src/store';

export type CSSViewEditorProps = Omit<InputContainerProps, 'isFxEnabled' | 'enableFx'> & {
  value?: string;
  onChange: (propValue: string) => void;
  title: string;
  'data-test'?: string;
  showFx?: boolean;
  fxDefaultEnabled?: string;
};

const Container = styled.div<{ isFocused: boolean; error?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;

  ${({ theme, isFocused, error }) =>
    getMonacoEditorBorderHighlightingStyles(theme, { isFocused, error, withBorders: true })}
`;

const StyledHeightIcon = styled(HeightIcon)`
  transform: rotate(45deg);
`;

const StyledInputAdornment = styled.div`
  position: absolute;
  z-index: 10;
  right: 16px;
  top: 4px;
  padding: 0;

  opacity: 0.2;

  :hover {
    opacity: 1;
  }
`;

export const CssEditorOpenButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): JSX.Element => {
  return (
    <StyledInputAdornment>
      <IconButton edge="end" size="small" onClick={onClick}>
        <StyledHeightIcon />
      </IconButton>
    </StyledInputAdornment>
  );
};

const EDITOR_OPTIONS = {
  minimap: {
    enabled: false,
  },
  scrollbar: {
    horizontalScrollbarSize: 4,
    verticalScrollbarSize: 4,
  },
  lineNumbers: 'off',
  glyphMargin: false,
  folding: false,
  lineDecorationsWidth: 10,
  lineNumbersMinChars: 0,
  padding: {
    top: 8,
  },
} as const;

// prettify value with adding spaces because monaco doesn't have prettier for css
const getWrappedValue = (value: string) => {
  return value.includes('.currentElement')
    ? value.trim()
    : `.currentElement {\n  ${value.trim()}  \n}`;
};

const getNormalizedValues = (value: string) => {
  // removing ".currentElement" placeholder, curly braces and new lines
  let newValue = value;
  if (newValue.includes('currentElement')) {
    newValue = newValue.trim().replace('.currentElement {', '').trim().slice(0, -1);
  }

  return newValue;
};

const hastMedia = (propValue: string) => {
  const mediaQueryRegex = /@media[^{]+\{[^}]*\}/g;
  return mediaQueryRegex.test(propValue as string);
};

const extractMediaContent = (propValue: string) => {
  const mediaQueryRegex = /@media[^{]+\{([^}]+)\}/g;
  const mediaQueryContents = propValue.match(mediaQueryRegex);
  const extractedContent = mediaQueryContents
    ? mediaQueryContents.map(query => query.match(/\{([^}]+)\}/)?.[1])
    : [];
  return extractedContent;
};

function sendError(send: Dispatch<AppEvents>, normalizedValues: string) {
  send({
    type: UI_BUILDER_EVENTS.notificationSend,
    notification: {
      message: `Please note that the Custom CSS property you are editing does not support @media (${extractMediaContent(
        normalizedValues,
      )} ).  ${JSON.stringify(normalizedValues)}`,
      options: { variant: 'error' },
    },
  });
}

export const CSSViewEditor = ({
  title,
  value = '',
  onChange,
  'data-test': dataTest,
  label,
  labelPlacement,
  variant,
  error,
  icon,
  isTextIcon,
  helperText,
}: CSSViewEditorProps): JSX.Element => {
  const [isDialogOpened, { setTrue: openDialog, setFalse: closeDialog }] = useBoolState();
  const [isFocused, { setTrue: setIsFocusedTrue, setFalse: setIsFocusedFalse }] = useBoolState(
    false,
  );
  const [localEditor, setLocalEditor] = useState<
    MonacoEditorType.IStandaloneCodeEditor | undefined
  >();
  const send = useAppDispatch();

  const handleChange = useCallback(
    (newValue = '') => {
      const normalizedValues = getNormalizedValues(newValue);
      if (!hastMedia(normalizedValues)) {
        onChange(normalizedValues);
      } else {
        const cleanedCode = normalizedValues.replace(/@media[^{]+{[^}]+}/g, '');
        sendError(send, normalizedValues);
        onChange(cleanedCode);
      }
    },
    [onChange, send],
  );

  const submitForm = useCallback(
    (values: { css: string }) => {
      const normalizedValues = getNormalizedValues(values.css);

      if (!hastMedia(values.css)) {
        onChange(normalizedValues);

        if (normalizedValues !== getNormalizedValues(localEditor?.getValue() || '')) {
          localEditor?.setValue(getWrappedValue(normalizedValues));
        }

        closeDialog();
      } else {
        const cleanedCode = normalizedValues.replace(/@media[^{]+{[^}]+}/g, '');
        sendError(send, normalizedValues);
        onChange(cleanedCode);
      }
    },
    [closeDialog, localEditor, onChange, send],
  );

  const commonOnMount = useCallback(
    (editor: MonacoEditorType.IStandaloneCodeEditor) => {
      const revertChangesOutsideOfBoundaries = (
        event: MonacoEditorType.IModelContentChangedEvent,
      ) => {
        if (event.isUndoing) {
          return;
        }

        const linesCount = editor.getModel()?.getLineCount();

        for (const ch of event.changes) {
          const isFirstLineEdited = ch.range.startLineNumber === 1;
          const isLastLineEdited = ch.range.startLineNumber === linesCount;

          if (isFirstLineEdited || isLastLineEdited) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            editor.getModel()?.undo();
          }
        }
      };

      editor.onDidChangeModelContent(revertChangesOutsideOfBoundaries);
      editor.onDidFocusEditorText(() => {
        setIsFocusedTrue();
      });
      editor.onDidBlurEditorText(() => {
        setIsFocusedFalse();
      });
    },
    [setIsFocusedFalse, setIsFocusedTrue],
  );

  const onMountMonacoTextarea = useCallback(
    (editor: MonacoEditorType.IStandaloneCodeEditor) => {
      commonOnMount(editor);
      setLocalEditor(editor);
    },
    [commonOnMount],
  );

  const [debouncedValue, setDebouncedValue] = useDebouncedState(value, handleChange, {
    debounceTime: 1000,
  });

  return (
    <InputContainer
      label={label || title}
      labelPlacement={labelPlacement}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      data-test={dataTest}
    >
      <>
        <Container isFocused={isFocused} error={error} data-test={dataTest}>
          <CssEditorOpenButton onClick={openDialog} />
          <MonacoEditor
            height="200px"
            width="100%"
            language="scss"
            defaultValue={getWrappedValue(debouncedValue)}
            onChange={(newValue = '') => {
              setDebouncedValue(newValue);
            }}
            onMount={onMountMonacoTextarea}
            options={EDITOR_OPTIONS}
            wrapperClassName="monaco-editor-wrapper"
          />
        </Container>
        <EditorFormDialog
          isDialogOpened={isDialogOpened}
          onDialogClose={closeDialog}
          title={title}
          formProps={{
            onSubmit: submitForm,
            initialValues: { css: getWrappedValue(debouncedValue) },
          }}
        >
          <Field name="css" useValueOnChange>
            {({ field }: FieldRenderProps) => (
              <MonacoEditor
                height="100%"
                language="scss"
                defaultValue={field.value as string}
                onChange={field.onChange}
                onMount={commonOnMount}
              />
            )}
          </Field>
        </EditorFormDialog>
      </>
    </InputContainer>
  );
};
