import { COMPONENT_DSL_CALLBACK_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, STATE_SCOPES, STATE_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsDateTimePickerSettings } from './BuilderComponentsDateTimePicker.settings';
export const BuilderComponentsDateTimePicker = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsDateTimePicker,
    displayName: 'Date Time Picker',
    icon: 'dateTimePicker',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.controls,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'DateTimePicker',
    },
    schema: {
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Label',
        },
        predefineds: {
            states: [
                {
                    defaultValue: '',
                    name: 'dateTimePicker',
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.string,
                },
            ],
            props: {
                ampm: false,
                ampmInClock: false,
                closeOnSelect: true,
                emptyLabel: 'Date Time Picker',
                inputVariant: 'outlined',
                invalidLabel: 'ERROR',
                label: 'Date Time Picker',
                onChange: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.dateTimePicker.setValue(value)',
                    },
                },
                value: '{{ __STATES__.dateTimePicker.value }}',
                views: ['year', 'month', 'day', 'hours', 'minutes', 'seconds'],
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { acceptRegex: { type: COMPONENT_DSL_PROP_TYPES.string }, views: {
                type: COMPONENT_DSL_PROP_TYPES.array,
                allowJS: true,
                item: { type: COMPONENT_DSL_PROP_TYPES.string },
            }, ampm: { type: COMPONENT_DSL_PROP_TYPES.boolean }, ampmInClock: { type: COMPONENT_DSL_PROP_TYPES.boolean }, closeOnSelect: { type: COMPONENT_DSL_PROP_TYPES.boolean }, dayOfWeekFormatter: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'day', path: [0] }],
            }, defaultCalendarMonth: { type: COMPONENT_DSL_PROP_TYPES.string, allowJS: true }, desktopModeMediaQuery: { type: COMPONENT_DSL_PROP_TYPES.string }, disabled: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableFuture: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableHighlightToday: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableIgnoringDatePartForTimeValidation: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableMaskedInput: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disableOpenPicker: { type: COMPONENT_DSL_PROP_TYPES.boolean }, disablePast: { type: COMPONENT_DSL_PROP_TYPES.boolean }, draggable: { type: COMPONENT_DSL_PROP_TYPES.boolean }, emptyLabel: { type: COMPONENT_DSL_PROP_TYPES.string }, error: { type: COMPONENT_DSL_PROP_TYPES.boolean }, getClockLabelText: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    { name: 'view', path: [0] },
                    { name: 'time', path: [1] },
                    { name: 'adapter', path: [2] },
                ],
            }, getOpenDialogAriaText: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    { name: 'date', path: [0] },
                    { name: 'utils', path: [1] },
                ],
            }, getViewSwitchingButtonText: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'currentView', path: [0] }],
            }, helperText: { type: COMPONENT_DSL_PROP_TYPES.string }, invalidLabel: { type: COMPONENT_DSL_PROP_TYPES.string }, inputFormat: { type: COMPONENT_DSL_PROP_TYPES.string }, inputVariant: { type: COMPONENT_DSL_PROP_TYPES.enum }, label: { type: COMPONENT_DSL_PROP_TYPES.string }, leftArrowButtonText: { type: COMPONENT_DSL_PROP_TYPES.string }, loading: { type: COMPONENT_DSL_PROP_TYPES.boolean }, mask: { type: COMPONENT_DSL_PROP_TYPES.string }, maxDate: { type: COMPONENT_DSL_PROP_TYPES.string }, maxDateTime: { type: COMPONENT_DSL_PROP_TYPES.string }, maxTime: { type: COMPONENT_DSL_PROP_TYPES.string }, minDate: { type: COMPONENT_DSL_PROP_TYPES.string }, minDateTime: { type: COMPONENT_DSL_PROP_TYPES.string }, minTime: { type: COMPONENT_DSL_PROP_TYPES.string }, minutesStep: { type: COMPONENT_DSL_PROP_TYPES.number }, onAccept: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                ],
            }, onChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onError: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'error',
                        path: [0],
                    },
                    {
                        name: 'value',
                        path: [1],
                    },
                ],
            }, onMonthChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                ],
            }, onOpen: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [],
            }, onViewChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'view',
                        path: [0],
                    },
                ],
            }, onYearChange: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'date',
                        path: [0],
                    },
                ],
            }, openTo: { type: COMPONENT_DSL_PROP_TYPES.enum }, shouldDisableDate: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'day', path: [0] }],
            }, shouldDisableMonth: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'month', path: [0] }],
            }, shouldDisableTime: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    { name: 'timeValue', path: [0] },
                    { name: 'clockType', path: [1] },
                ],
            }, shouldDisableYear: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'year', path: [0] }],
            }, readOnly: { type: COMPONENT_DSL_PROP_TYPES.boolean }, reduceAnimations: { type: COMPONENT_DSL_PROP_TYPES.boolean }, rifmFormatter: {
                type: COMPONENT_DSL_PROP_TYPES.callback,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [{ name: 'str', path: [0] }],
            }, rightArrowButtonText: { type: COMPONENT_DSL_PROP_TYPES.string }, showDaysOutsideCurrentMonth: { type: COMPONENT_DSL_PROP_TYPES.boolean }, showToolbar: { type: COMPONENT_DSL_PROP_TYPES.boolean }, toolbarFormat: { type: COMPONENT_DSL_PROP_TYPES.string }, toolbarPlaceholder: { type: COMPONENT_DSL_PROP_TYPES.string }, toolbarTitle: { type: COMPONENT_DSL_PROP_TYPES.string }, value: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsDateTimePickerSettings,
};
