/**
 * @example
 * "textSecondary" => "Text Secondary"
 * 0 => "0"
 * "inherit" => "Inherit"
 * */
export const splitAndCapitalize = (value) => {
    if (!value) {
        return '';
    }
    const arrayOfWords = value.toString().match(/[A-Z]+[^A-Z]*|[^A-Z]+/g) || [];
    const transformedString = arrayOfWords
        .map(word => `${word[0] ? word[0].toLocaleUpperCase() : ''}${word.slice(1)}`)
        .join(' ');
    return transformedString;
};
export const capitalize = (value = '') => {
    const head = value[0] || '';
    const rest = value.slice(1) || '';
    return head.toUpperCase() + rest;
};
/**
 * @example
 * `
 *   :root {
 *     --primary: "#fff";
 *     --secondary: "#000";
 *   }
 *
 *  body {
 *     font-size: 12px;
 *   }
 *
 *   .some_class {
 *     color: var(--primary);
 *   }
 * ` -> `:root{--primary:#fff;--secondary:#000;}body{font-size:12px;}.some_class{color:var(--primary);}`
 */
export const removeSpacesAndLineBreaks = (value = '') => {
    return value.replace(/\r?\n|\r|\s/g, '');
};
/**
 * @example
 * `
 * \n
 * \n
 *   :root {
 *     --primary: "#fff";
 *     --secondary: "#000";
 *   }
 * \n
 * \n
 *
 * ` -> `:root{
 *     --primary:#fff;--secondary:#000;
 *  }`
 */
export const removeLineBreaks = (str = '') => {
    return str.replace(/\n{2,}/g, `\n`);
};
/**
 * @example
 * "'text'" -> "text"
 */
export const removeDoubleQuotes = (str = '') => {
    return str.replace(/["']/g, '');
};
export const hasHandlebars = (str = '') => {
    return /(\{\{[\s]*.*?[\s]*\}\})/g.test(str);
};
export const removeOptionalChainingFromString = (str) => {
    if (!str)
        return '';
    return str.replaceAll('?', '');
};
export const isURL = (str = '') => {
    return new RegExp(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g).test(str);
};
