/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import * as changeCase from 'change-case';
import { isEmpty, uniq } from 'ramda';
import { nodeSelectors, stateListSelectors, } from '@builder/schemas';
import { isString, isUndefined } from '@builder/utils';
import { CodeNodeGenerator } from '../../../app-nodes-generator';
import { USE_GLOBAL_STATE_CONST } from '../../../providers-generators';
import { sortHooksCalls, sortStatesByUse } from '../../utils';
import { HookGenerator } from '../hooks-generator';
export class LocalStateContainerGenerator {
    constructor({ appDSL, componentListDSL, nodeDSL, assetListDSL, }) {
        this.statesSortedByGroups = [];
        this.getHookName = (appState) => {
            const hookGenerator = new HookGenerator({
                appDSL: this.appDSL,
                componentListDSL: this.componentListDSL,
                appStateDSL: appState,
                assetListDSL: this.assetListDSL,
                appCurrentContextStatesDSL: this.getCurrentContextStatesAndGlobalStates(),
            });
            return hookGenerator.stateAccessor.getStateName();
        };
        this.getHookCall = (appState) => {
            const hookGenerator = new HookGenerator({
                appDSL: this.appDSL,
                componentListDSL: this.componentListDSL,
                appStateDSL: appState,
                assetListDSL: this.assetListDSL,
                appCurrentContextStatesDSL: this.getCurrentContextStatesAndGlobalStates(),
            });
            return hookGenerator.getHookCall();
        };
        this.getLocalHooksMeta = (localHooksCalls, hookNames) => {
            return localHooksCalls.map(localHook => {
                const [name] = hookNames.filter(hookName => localHook.includes(`const ${hookName} =`));
                return {
                    call: localHook,
                    name,
                    timesUsed: 0,
                };
            });
        };
        this.matchHookNameInText = (text, hookName) => {
            return (text.includes(`(${hookName})`) ||
                text.includes(`${hookName})`) ||
                text.includes(`${hookName},`));
        };
        this.textsAreDifferent = (text1, text2) => {
            return text1 !== text2;
        };
        this.textsMatch = (text1, text2) => {
            return text1 === text2;
        };
        this.getTimesHookNamesAreUsed = (localHooksMeta, hookNames) => {
            hookNames.forEach(hookName => {
                localHooksMeta.forEach(localHookMeta => {
                    if (this.textsAreDifferent(hookName, localHookMeta.name) &&
                        this.matchHookNameInText(localHookMeta.call, hookName)) {
                        localHooksMeta.forEach(localMeta => {
                            if (this.textsMatch(localMeta.name, hookName)) {
                                localMeta.timesUsed += 1;
                            }
                        });
                    }
                });
            });
        };
        this.getMaxnumber = (arr) => arr.reduce((max, obj) => {
            if (obj.timesUsed > max) {
                return obj.timesUsed;
            }
            return max;
        }, arr[0].timesUsed);
        this.groupByTimesUsed = (maxNumAStateIsUsed, localHooksMeta, statesGroupByTimesUsed) => {
            for (let item = maxNumAStateIsUsed; item >= 0; item--) {
                const items = localHooksMeta.filter(state => state.timesUsed === item);
                statesGroupByTimesUsed.push(items);
            }
        };
        this.sortByTimesUsedLargestToSmallest = (localHooksMeta) => {
            localHooksMeta.sort((a, b) => {
                return b.timesUsed - a.timesUsed;
            });
        };
        this.sortGroupOfStates = (statesGroupByTimesUsed, statesNames) => {
            for (const stateGroup of statesGroupByTimesUsed) {
                if (stateGroup.length > 0) {
                    const initialMaxNumber = this.getMaxnumber(stateGroup);
                    this.getTimesHookNamesAreUsed(stateGroup, statesNames);
                    const endMaxNumber = this.getMaxnumber(stateGroup);
                    if (endMaxNumber !== initialMaxNumber) {
                        const innerNames = stateGroup.map(state => state.name);
                        const innerStatesGroupByTimesUsed = [];
                        this.groupByTimesUsed(endMaxNumber, stateGroup, innerStatesGroupByTimesUsed);
                        this.sortGroupOfStates(innerStatesGroupByTimesUsed.filter(innerState => innerState.length > 0), innerNames);
                    }
                    this.sortByTimesUsedLargestToSmallest(stateGroup);
                    this.statesSortedByGroups.push(stateGroup);
                }
            }
        };
        this.appDSL = appDSL;
        this.nodeDSL = nodeDSL;
        this.componentListDSL = componentListDSL;
        this.assetListDSL = assetListDSL;
    }
    /**
     * Get local states sortered by type.
     */
    getLocalStates() {
        const localStates = nodeSelectors.getLocalStateArrayDSL(this.nodeDSL, {
            appStateListDSL: this.appDSL.states,
        });
        return sortStatesByUse(localStates);
    }
    getCurrentContextStatesAndGlobalStates() {
        return [
            ...this.getLocalStates(),
            ...stateListSelectors.getGlobalStateArrayDSL(this.appDSL.states),
        ].reduce((accum, current) => {
            return Object.assign(Object.assign({}, accum), { [current.id]: current });
        }, {});
    }
    hasLocalStates() {
        return this.getLocalStates().length > 0;
    }
    getLocalStateContainerName() {
        const nodeAlias = nodeSelectors.getNodeAlias(this.nodeDSL, {
            componentListDSL: this.componentListDSL,
        });
        return `${changeCase.pascalCase(nodeAlias)}ContainerState`.replace('_', '');
    }
    /**
     * Generates the global states.
     */
    generateGlobalStateUsedInContainer(localHooks) {
        const globalStatesUsedInContainer = stateListSelectors
            .getGlobalStateArrayDSL(this.appDSL.states)
            .map(this.getHookName);
        const noUsesInLocalContainer = globalStatesUsedInContainer.length === 0;
        if (noUsesInLocalContainer) {
            return '';
        }
        if (localHooks) {
            const isAUsedGlobalHookCall = (globalHookCall) => {
                return localHooks.some(localHook => localHook.includes(globalHookCall));
            };
            const globalStatesUsedInLocalHook = globalStatesUsedInContainer.filter(globalHooksCall => {
                return isAUsedGlobalHookCall(globalHooksCall);
            });
            return isEmpty(globalStatesUsedInLocalHook)
                ? ''
                : `const { ${globalStatesUsedInLocalHook.join(',')} } = ${USE_GLOBAL_STATE_CONST}();`;
        }
        return `const { ${globalStatesUsedInContainer.join(',')} } = ${USE_GLOBAL_STATE_CONST}();`;
    }
    reorderStates(states) {
        const sortedStates = [];
        const checked = new Set();
        const path = [];
        function findAndAddState(state) {
            if (checked.has(state)) {
                return;
            }
            const currentState = states.find(stateToEvaluate => stateToEvaluate.name === state);
            if (currentState && path.includes(state)) {
                const circularPath = [...path, state].join(' -> ');
                console.error(`Circular dependency detected > ${circularPath}`);
                return;
            }
            if (isUndefined(currentState) || isEmpty(currentState)) {
                return;
            }
            const { call } = currentState;
            path.push(state);
            states.forEach(stateToEvaluate => {
                if ((stateToEvaluate.name !== state && call.includes(`(${stateToEvaluate.name})`)) ||
                    call.includes(`${stateToEvaluate.name})`) ||
                    call.includes(`${stateToEvaluate.name},`)) {
                    findAndAddState(stateToEvaluate.name);
                }
            });
            sortedStates.unshift(currentState);
            checked.add(state);
            path.pop();
        }
        try {
            states.forEach(stateToEvaluate => findAndAddState(stateToEvaluate.name));
        }
        catch (error) {
            console.error('Possible circular dependency >', error);
        }
        return sortedStates.reverse();
    }
    generateLocalStateContainerDeclaration() {
        var _a, _b, _c;
        const localStates = this.getLocalStates();
        const localStateContainerName = this.getLocalStateContainerName();
        const localHooksCalls = localStates.map(this.getHookCall);
        const globalHooksCalls = this.generateGlobalStateUsedInContainer(localHooksCalls);
        const hookNames = localStates.map(this.getHookName);
        const statesGroupByTimesUsed = [];
        const localHooksMeta = this.getLocalHooksMeta(localHooksCalls, hookNames);
        this.getTimesHookNamesAreUsed(localHooksMeta, hookNames);
        this.groupByTimesUsed(this.getMaxnumber(localHooksMeta), localHooksMeta, statesGroupByTimesUsed);
        try {
            this.sortGroupOfStates(statesGroupByTimesUsed, hookNames);
        }
        catch (error) {
            console.error('Possible circular dependency', error);
        }
        const layoutStates = CodeNodeGenerator.getParentLayoutStates(this.nodeDSL.parentID, this.appDSL);
        const getRoute = () => {
            return ((!isUndefined(this.nodeDSL.props.path) &&
                isString(this.nodeDSL.props.path) &&
                this.nodeDSL.props.path.split('/')[1]) ||
                '');
        };
        const conditionToReturnEmptyStates = this.nodeDSL.props.path === '/'
            ? `if (currentPath !== "${this.nodeDSL.props.path}") return null;`
            : `if (currentPath.length === 1 || !currentPath.startsWith("/${getRoute()}")) return null;`;
        return `
      const ${localStateContainerName} = ({children, currentPath, ${layoutStates ? layoutStates.join(',') : ''}}) => {
        ${((_c = (_b = (_a = this.nodeDSL) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c.includes('__layouts'))
            ? ''
            : conditionToReturnEmptyStates}
        
        ${globalHooksCalls}
        ${sortHooksCalls(this.reorderStates(uniq(this.statesSortedByGroups.flat())).map(localHookMeta => localHookMeta.call)).join('\n')}

        return children({ ${uniq(hookNames).join(',')} });
      }
    `;
    }
    wrapInLocalStateContainerCall(jsxContent) {
        var _a, _b, _c;
        const localStates = this.getLocalStates();
        const localStateContainerName = this.getLocalStateContainerName();
        const hookNames = localStates.map(this.getHookName);
        const isLayoutNode = ((_c = (_b = (_a = this.nodeDSL) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c.includes('/__layouts/')) && this.nodeDSL.props.system;
        return `
      <${localStateContainerName} ${isLayoutNode ? '' : '{...props}'} currentPath={routerLocation.pathname}>
        {({ ${uniq(hookNames).join(',')} }) => (${jsxContent})}
      </${localStateContainerName}>
    `;
    }
}
