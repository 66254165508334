import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { getCommonPropertiesSections, getDivider, getStylesSection, getActionSettings, getBasicPropsSettings, } from '../../common';
import { getCommonListItemSettings } from '../../composite-components/ListItemSymbol/ListItemSymbol.settings';
import { getCommonNavListItemSettings } from '../../composite-components/NavListItemSymbol/NavListItemSymbol.settings';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        name: 'listItems',
        title: 'List Items',
        children: [
            {
                type: COMPONENT_SETTING_TYPES.nodeList,
                keyPath: ['children'],
                label: 'List Items',
                name: 'listItems',
                itemLabelKeyPath: ['children'],
                itemLabelNodePath: ['listItemText', 0, 'children', 0],
                items: [
                    { name: COMPONENT_DSL_NAMES.ListItemSymbol, settings: getCommonListItemSettings() },
                    { name: COMPONENT_DSL_NAMES.NavListItemSymbol, settings: getCommonNavListItemSettings() },
                ],
            },
        ],
    },
    getDivider(),
    ...getBasicPropsSettings(),
    getDivider(),
    ...getCommonPropertiesSections(),
    getDivider(),
];
export const getStyleSettings = (prefixPath = []) => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['dense'],
                label: 'Dense',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.checkbox,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['disablePadding'],
                label: 'Disable Padding',
            },
        ],
    },
    getDivider(),
    ...getStylesSection({ prefixPath }),
];
export const MaterialListSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [...getPropertiesSettings()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSettings(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
