import { css, SerializedStyles, Theme } from '@emotion/react';

export const uploadcareStyles = (theme: Theme): SerializedStyles => css`
  .uploadcare {
    &--panel {
      font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
      padding: 42px 12px 12px 12px;
      &.uploadcare--dialog__panel {
        background-color: ${theme.palette.background.paper};
      }
      &__content {
        background-color: ${theme.palette.background.default};
        border-radius: 4px;
        color: ${theme.palette.common.white};
        margin-left: 12px;
      }
    }
    &--menu {
      &__items {
        background-color: ${theme.palette.background.paper};
      }
      &__icon {
        padding: 0 5px;
        height: 28px;
        width: 28px;
        margin: 0;
      }
      &__item {
        margin-bottom: 2px;
        &_tab_file,
        &_tab_url {
          background-color: ${theme.palette.background.paper};
          border-radius: 4px;
          color: ${theme.palette.common.white};
          height: 32px;
          &:focus,
          &:hover {
            background-color: ${theme.palette.primary.main};
            color: ${theme.palette.common.white};
            opacity: 0.6;
          }
        }
        &_current {
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.common.white};
          &:hover {
            opacity: 1;
          }
        }
        &:after {
          font-size: 12px;
          font-weight: 400;
        }
        &.uploadcare--menu__item_tab_url {
          .uploadcare--icon.uploadcare--menu__icon {
            height: 20px;
            padding: 0 9px;
            width: 20px;
          }
        }
      }
    }
    &--text {
      font-size: 13px;
      font-weight: 400;
      height: 15px;
      letter-spacing: 0.2px;
      line-height: 16px;
      margin-bottom: 0;
      &_size_large {
        font-size: 32px;
        font-weight: 500;
        height: 25px;
        letter-spacing: 0.5px;
        margin-bottom: 24px;
      }
      &_size_extra-large {
        font-size: 0;
        height: 50px;
        margin-top: -60px;
        visibility: hidden;
        &:before {
          content: 'Drag & drop any files';
          font-size: 32px;
          font-weight: 500;
          margin: 0 auto;
          visibility: visible;
        }
      }
    }
    &--input {
      background-color: ${theme.palette.background.input};
      border-color: ${theme.palette.border.input};
      color: ${theme.palette.common.white};
      font-size: 12px;
      height: 32px;
      letter-spacing: 0.5px;
      margin-bottom: 0px;
      margin-top: 30px;
      ::placeholder {
        color: ${theme.palette.grey[400]};
        font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
      }
    }
    &--dialog {
      &__close {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: 32px;
        justify-content: end;
        padding: 0 12px;
        width: 40px;
        background-color: ${theme.palette.background.default};
        &:enabled,
        &:hover {
          background-color: ${theme.palette.background.default};
          border: 0;
        }
        .uploadcare--icon {
          width: 16px;
        }
      }
      &__panel {
        &:before {
          background-color: ${theme.palette.background.default};
          content: 'Upload File ';
          color: ${theme.palette.common.white};
          font-size: 12px;
          font-weight: 600;
          height: 32px;
          left: 0;
          padding: 5px 0 0 12px;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
    &--tab__action-button {
      background-color: ${theme.palette.primary.main};
      &:defined {
        background-color: ${theme.palette.primary.main};
      }
      cursor: pointer;
      font-weight: 600;
      font-size: 12px;
      height: 32px;
      letter-spacing: 0.5px;
      margin-top: 30px;
      padding: 0 20px;
      &:hover {
        font-weight: 600;
        font-size: 12px;
      }
      &:disabled {
        background-color: ${theme.palette.background.input} !important;
        border-color: ${theme.palette.background.input} !important;
        color: ${theme.palette.grey[400]} !important;
        letter-spacing: 0.5px !important;
      }
    }
    &--dragging {
      .uploadcare--draganddrop,
      .uploadcare--panel__content,
      .uploadcare--tab__content
        .uploadcare--draganddrop
        .uploadcare--draganddrop_supported
        .uploadcare--dragging {
        background-color: ${theme.palette.background.paper};
        border-style: none;
      }
      .uploadcare--text.uploadcare--text_size_extra-large.uploadcare--dragging__show {
        visibility: hidden;
        &:before {
          color: ${theme.palette.primary.main};
          content: 'Drop a file here';
          font-family: 'Poppins';
          font-style: normal;
          font-size: 32px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 24px;
          margin: 0 auto;
          visibility: visible;
        }
      }
      .uploadcare--panel__content {
        border: 2px dashed ${theme.palette.primary.main};
        border-radius: 4px;
      }
    }
    &--powered-by {
      display: none;
    }
  }

  @media (max-width: 500px) {
    .uploadcare--text_size_large {
      visibility: hidden;
    }
  }

  @media (max-width: 1208px) {
    .uploadcare {
      &--menu {
        &.uploadcare--panel__menu,
        &__item {
          width: 35px;
        }
      }
    }
  }
`;
