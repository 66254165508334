import React, { useCallback } from 'react';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { DialogContentWithIcon } from '../../common/components';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';

const DIALOG_ID = DASHBOARD_DIALOGS.PREVENT_DELETE_SYMBOL_DIALOG_ID;

export type PreventDeleteSymbolDialogArgs = {
  symbolDisplayName?: string;
};

export const PreventDeleteSymbolDialog: React.FC = () => {
  const { isOpen, closeDialog, args } = useDialogState<PreventDeleteSymbolDialogArgs>(DIALOG_ID);

  const closePreventDeleteSymbolDialog = useCallback(() => {
    closeDialog(DIALOG_ID);
  }, [closeDialog]);

  return (
    <Dialog
      open={isOpen}
      onClose={closePreventDeleteSymbolDialog}
      data-test="symbolsManager.preventDeleteDialog"
    >
      <DialogTitle>Delete Symbol</DialogTitle>
      <DialogContentWithIcon>
        <InfoOutlinedIcon color="primary" />
        <DialogContentText>
          {`Deleting the symbol "${args?.symbolDisplayName}" is not allowed`}
          <Typography mt={0.5} variant="body3">
            You can not delete a component if it is been used
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="success" onClick={closePreventDeleteSymbolDialog}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
