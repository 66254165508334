import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_17_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.4';
    }
    getChangeList() {
        return [
            `Remove mui/lab, delete loading prop from Button and downgrade Luxon to version 2.3.0.`,
        ];
    }
    migrateAppConfiguration(configuration) {
        const { appDSL } = configuration;
        const { nodes } = appDSL;
        const libraries = Object.assign({}, (appDSL.libraries || {}));
        const newLibs = Object.entries(libraries).map(([libKey, libValue]) => {
            if (libKey === 'luxon') {
                return ['luxon', Object.assign(Object.assign({}, libValue), { version: '2.3.0' })];
            }
            return [libKey, libValue];
        });
        Object.values(nodes).forEach(node => {
            if (node.name === COMPONENT_DSL_NAMES.BuilderComponentsButton && node.props.loading) {
                // eslint-disable-next-line no-param-reassign
                delete node.props.loading;
                return node;
            }
        });
        return Object.assign(Object.assign({}, configuration), { appDSL: Object.assign(Object.assign({}, configuration.appDSL), { libraries: Object.fromEntries(newLibs) }) });
    }
}
export const migration_0_17_4 = new Migration_0_17_4();
