import { isEmpty } from 'ramda';
/**
 * @example
 * ({ strToIncrement: 'name', dictionary: ['name_1', 'name_2', 'name_4'], delimiter: '_' }) => 'name_5'
 * ({ strToIncrement: 'name', dictionary: [], delimiter: '_' }) => 'name'
 * ({ strToIncrement: 'name', dictionary: ['name'], delimiter: '_' }) => 'name_2'
 */
export const incrementName = ({ nameToIncrement, dictionary, delimiter, }) => {
    const regexpPattern = new RegExp(`^${nameToIncrement}`);
    const allAppropriateNames = dictionary.filter(name => regexpPattern.test(name));
    if (isEmpty(allAppropriateNames)) {
        return nameToIncrement;
    }
    const nameWithDelimiterRegExp = new RegExp(`^${nameToIncrement}${delimiter}`);
    const allAliasesCounters = allAppropriateNames
        .map(name => name === null || name === void 0 ? void 0 : name.replace(nameWithDelimiterRegExp, ''))
        .map(stringCounter => Number(stringCounter))
        .filter(counter => counter && !isNaN(counter));
    const newIndex = Math.max(1, ...allAliasesCounters) + 1;
    return `${nameToIncrement}${delimiter}${newIndex}`;
};
