import { mapObjIndexed } from 'ramda';
import { PropChecker } from '../../validation/helpers';
export const mapPropListDSL = (callback, propListDSL) => {
    return mapObjIndexed((propDSL, propName) => {
        let newPropDSL = callback(propDSL, propName);
        if (PropChecker.Schema.isObjectPropWithDefinedProps(newPropDSL)) {
            newPropDSL = Object.assign(Object.assign({}, newPropDSL), { props: mapPropListDSL(callback, newPropDSL.props) });
        }
        if (PropChecker.Schema.isArrayPropWithDefinedObjectProps(newPropDSL)) {
            newPropDSL = Object.assign(Object.assign({}, newPropDSL), { item: Object.assign(Object.assign({}, newPropDSL.item), { props: mapPropListDSL(callback, newPropDSL.item.props) }) });
        }
        return newPropDSL;
    }, propListDSL);
};
