import { omit } from 'ramda';
import { Migration } from '../Migration';
class Migration_0_17_6 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.17.6';
    }
    getChangeList() {
        return [`Remove parent from global state.`];
    }
    migrateStateDSL(stateDSL) {
        if (stateDSL.scope === 'global') {
            return Object.assign({}, omit(['parent'], stateDSL));
        }
        return stateDSL;
    }
}
export const migration_0_17_6 = new Migration_0_17_6();
