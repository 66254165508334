import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { builderComponentsBoxProps } from '../BuilderComponentsBox/BuilderComponentsBox';
import { MaterialDrawerContentSettings } from './MaterialDrawerContent.settings';
export const MaterialDrawerContent = {
    name: COMPONENT_DSL_NAMES.MaterialDrawerContent,
    displayName: 'MaterialDrawerContent',
    icon: 'container',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Box',
    },
    schema: {
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                style: {
                    width: '300px',
                    height: '100%',
                },
            },
        },
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyEditable,
        props: Object.assign(Object.assign({}, commonComponentProps), builderComponentsBoxProps),
    },
    settings: MaterialDrawerContentSettings,
};
