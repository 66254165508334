import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledThemeColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  min-width: 250px;
  max-width: 250px;
  padding: 25px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.background.default};
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export const StyledTypography = styled(Typography)`
  line-height: 1;
  font-weight: normal;
  cursor: auto;
  width: fit-content;
`;

export const TabsWrapper = styled.div`
  width: 130px;
  margin: 0 auto;
  cursor: auto;
`;

export const SlidersBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: auto;
`;
