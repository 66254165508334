import React, { useCallback } from 'react';

import { WarningAmberOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { pathOr } from 'ramda';

import { NodeDSL, nodeListSelectors, NodeRouteDSL, nodeSelectors } from '@builder/schemas';

import { DialogContentWithIcon } from 'src/dialogs';
import { useAppDispatch, useNodeListDSL } from 'src/providers/ReduxProvider';
import { Button } from 'src/shared/components';
import { DASHBOARD_EVENTS } from 'src/store';

export const RouteDeleteDialog = ({
  opened,
  currentRoute,
  close,
}: {
  currentRoute: NodeRouteDSL;
  opened: boolean;
  close: () => void;
}): React.ReactElement => {
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const isCurrentRouteRoot = nodeSelectors.isRootRoute(currentRoute as NodeDSL);
  const currentPathName = currentRoute.props.path;
  const deleteCurrentRoute = useCallback((): void => {
    if (currentRoute.id) {
      send({
        type: DASHBOARD_EVENTS.componentRemove,
        id: currentRoute.id,
        currentPathName,
      });
    }

    close();
  }, [close, currentRoute?.id, send, currentPathName]);

  const currentRouteContentDSL = nodeListSelectors.getNodeDSL(nodeListDSL, {
    nodeID: currentRoute.props.children.nodes[0],
  });

  const currentNodeContentAlias = pathOr<string>('', ['alias'], currentRouteContentDSL);

  return (
    <Dialog open={opened} onClose={close}>
      <DialogTitle>Delete Page</DialogTitle>
      <DialogContentWithIcon>
        <WarningAmberOutlined color="error" />
        <DialogContentText>
          Delete Page &quot;{currentNodeContentAlias}&quot; -{' '}
          {pathOr('/', ['props', 'path'], currentRoute)}
          <Typography variant="body3" sx={{ mt: '4px' }}>
            {isCurrentRouteRoot
              ? 'You can not delete the root page'
              : 'Are you sure you want to delete the current page?'}
          </Typography>
        </DialogContentText>
      </DialogContentWithIcon>
      <DialogActions>
        <Button variant="contained" color="default" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={deleteCurrentRoute}
          disabled={isCurrentRouteRoot}
          data-test="deleteRouteDialog.submitBtn"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
