import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils/createSelectOptionByValue';
import { getActionSettings, BASIC_PROPS_TOOLTIP, getBasicPropsSettings } from '../../common';
import { getDivider, getStyleSections } from '../../common/settings';
const getPropertiesSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.section,
        title: 'Basic',
        name: 'basic',
        titleDescription: BASIC_PROPS_TOOLTIP,
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['color'],
                options: ['primary', 'secondary', 'transparent', 'default', 'inherit'].map(createSelectOptionByValue),
                label: 'Color',
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['position'],
                options: ['absolute', 'fixed', 'relative', 'static', 'sticky'].map(createSelectOptionByValue),
                label: 'Position',
            },
        ],
    },
];
export const MaterialAppBarSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: [...getPropertiesSettings(), getDivider(), ...getBasicPropsSettings()],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getStyleSections(),
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getActionSettings(),
        },
    ],
};
