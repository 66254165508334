import { IMPORT_DEPENDENCY_TYPES } from './import';
export const EXPORT_AS_NAMESPACE = 'export as namespace';
export const LICENSES_TYPES = {
    mit: 'MIT',
    apache: 'Apache-2.0',
};
export const LIBRARY_TYPES = {
    mathjs: 'mathjs',
    lodash: 'lodash',
    luxon: 'luxon',
    moment: 'moment',
};
const CDN_URL = 'https://cdn.jsdelivr.net/npm';
const getLodashCDN = (version) => `${CDN_URL}/lodash@${version}/lodash.min.js`;
const getLuxonCDN = (version) => `${CDN_URL}/luxon@${version}/build/global/luxon.min.js`;
const getMathJsCDN = (version) => `${CDN_URL}/mathjs@${version}/lib/browser/math.min.js`;
const getMomentJsCDN = (version) => `${CDN_URL}/moment@${version}/moment.min.js`;
const getLodashCDNTypes = (version) => `${CDN_URL}/@types/lodash@${version}`;
const getLuxonCDNTypes = (version) => `${CDN_URL}/@types/luxon@${version}`;
const getMathJsCDNTypes = (version) => `${CDN_URL}/@types/mathjs@${version}`;
const getMomentJsCDNTypes = (version) => `${CDN_URL}/@types/moment@${version}`;
const lodashTypesArray = [
    '/index.d.ts',
    '/common/common.d.ts',
    '/common/array.d.ts',
    '/common/collection.d.ts',
    '/common/date.d.ts',
    '/common/function.d.ts',
    '/common/lang.d.ts',
    '/common/math.d.ts',
    '/common/number.d.ts',
    '/common/object.d.ts',
    '/common/seq.d.ts',
    '/common/string.d.ts',
    '/common/util.d.ts',
];
const luxonTypesArray = [
    '/index.d.ts',
    '/src/datetime.d.ts',
    '/src/duration.d.ts',
    '/src/info.d.ts',
    '/src/interval.d.ts',
    '/src/luxon.d.ts',
    '/src/misc.d.ts',
    '/src/settings.d.ts',
    '/src/zone.d.ts',
];
const mathJsTypesArray = ['/index.d.ts'];
const momentJsTypesArray = ['/index.d.ts'];
export const LIBRARIES = [
    {
        name: LIBRARY_TYPES.lodash,
        varName: '_',
        description: 'Utility library delivering modularity, performance & extras',
        importType: IMPORT_DEPENDENCY_TYPES.default,
        types: '@types/lodash',
        versions: [
            {
                version: '4.17.21',
                CDN: getLodashCDN('4.17.21'),
                typesVersion: getLodashCDNTypes('4.14.179'),
                typesCollection: lodashTypesArray,
            },
            {
                version: '4.17.20',
                CDN: getLodashCDN('4.17.20'),
                typesVersion: getLodashCDNTypes('4.14.179'),
                typesCollection: lodashTypesArray,
            },
            {
                version: '4.17.19',
                CDN: getLodashCDN('4.17.19'),
                typesVersion: getLodashCDNTypes('4.14.179'),
                typesCollection: lodashTypesArray,
            },
        ],
        info: {
            license: LICENSES_TYPES.mit,
            size: '72.5 kb',
            urls: {
                github: 'https://github.com/lodash/lodash',
                home: 'https://lodash.com/',
            },
        },
    },
    {
        name: LIBRARY_TYPES.luxon,
        varName: 'luxon',
        description: 'A library for working with dates and times in JavaScript',
        types: '@types/luxon',
        importType: IMPORT_DEPENDENCY_TYPES.module,
        versions: [
            {
                version: '2.3.0',
                CDN: getLuxonCDN('2.3.0'),
                typesVersion: getLuxonCDNTypes('2.0.5'),
                typesCollection: luxonTypesArray,
            },
            {
                version: '2.2.0',
                CDN: getLuxonCDN('2.2.0'),
                typesVersion: getLuxonCDNTypes('2.0.5'),
                typesCollection: luxonTypesArray,
            },
        ],
        info: {
            license: LICENSES_TYPES.mit,
            size: '207.59 kb',
            urls: {
                github: 'https://github.com/moment/luxon/',
                home: 'https://moment.github.io/luxon/',
            },
        },
    },
    {
        name: LIBRARY_TYPES.mathjs,
        varName: 'math',
        importType: IMPORT_DEPENDENCY_TYPES.module,
        description: `Math.js is an extensive math library for JavaScript and Node.js. It features a flexible expression parser 
    with support for symbolic computation, comes with a large set of built-in functions and constants, and offers an 
    integrated solution to work with different data types like numbers, big numbers, complex numbers, fractions, units, 
    and matrices. Powerful and easy to use.`,
        types: '@types/mathjs',
        versions: [
            {
                version: '10.4.3',
                CDN: getMathJsCDN('10.4.3'),
                typesVersion: getMathJsCDNTypes('9.3.2'),
                typesCollection: mathJsTypesArray,
            },
        ],
        info: {
            license: LICENSES_TYPES.apache,
            size: '178 kb',
            urls: {
                github: 'https://github.com/josdejong/mathjs',
                home: 'https://mathjs.org/',
            },
        },
    },
    {
        name: LIBRARY_TYPES.moment,
        varName: 'moment',
        description: 'A JavaScript date library for parsing, validating, manipulating, and formatting dates.',
        importType: IMPORT_DEPENDENCY_TYPES.default,
        types: '@types/moment',
        versions: [
            {
                version: '2.29.3',
                CDN: getMomentJsCDN('2.29.3'),
                typesVersion: getMomentJsCDNTypes('2.11.29'),
                typesCollection: momentJsTypesArray,
            },
        ],
        info: {
            license: LICENSES_TYPES.mit,
            size: '700 kb',
            urls: {
                github: 'https://github.com/moment/moment',
                home: 'https://momentjs.com/',
            },
        },
    },
];
