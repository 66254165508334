import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, getCommonSymbolEventProps, getCommonSymbolStyleProps, } from '../../common/commonProps';
import { BuilderComponentsIconSettings } from '../../material-components/BuilderComponentsIcon/BuilderComponentsIcon.settings';
/** @deprecated */
export const ListItemIconSymbol = {
    name: COMPONENT_DSL_NAMES.ListItemIconSymbol,
    displayName: 'List Item Icon',
    icon: 'icon',
    type: COMPONENT_DSL_TYPES.symbol,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.MaterialList,
                },
            },
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsMenu,
                },
            },
        ],
        presentations: [visibleByCondition],
        dndTargetPropName: 'children',
        predefineds: {
            props: {
                name: 'Help',
                style: {
                    marginRight: '32px',
                },
                variant: 'filled',
                color: 'inherit',
                fontSize: 'default',
                viewBox: '0 0 24 24',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { name: { type: COMPONENT_DSL_PROP_TYPES.string }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, fontSize: { type: COMPONENT_DSL_PROP_TYPES.enum }, viewBox: { type: COMPONENT_DSL_PROP_TYPES.string }, htmlColor: { type: COMPONENT_DSL_PROP_TYPES.string }, customIcon: { type: COMPONENT_DSL_PROP_TYPES.string } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialListItemIcon,
            props: Object.assign(Object.assign({}, getCommonSymbolEventProps()), { style: {
                    minWidth: 'auto',
                    color: 'inherit',
                }, draggable: '{{ symbolProps.draggable }}', children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: Object.assign(Object.assign({}, getCommonSymbolStyleProps()), { name: '{{ symbolProps.name }}', variant: '{{ symbolProps.variant }}', color: '{{ symbolProps.color }}', fontSize: '{{ symbolProps.fontSize }}', viewBox: '{{ symbolProps.viewBox }}', htmlColor: '{{ symbolProps.htmlColor }}', customIcon: '{{ symbolProps.customIcon }}' }),
                        },
                    ],
                } }),
        },
    },
    settings: BuilderComponentsIconSettings,
};
