import { useCallback } from 'react';

import { Grid } from '@mui/material';

import {
  ComponentSettingPropDSL,
  COMPONENT_SETTING_VIEWS,
  NodePropValue,
  PropAssert,
  componentSettingSelectors,
} from '@builder/schemas';
import { isNil, memo } from '@builder/utils';

import { useNodeSettingsProps } from '../../node-settings-generator';
import { HTMLViewEditor } from '../../setting-views';
import { useNodeListDSL } from 'src/providers/ReduxProvider';
import { getTargetNodeID } from 'src/store/dashboard/utils';

type HTMLFragmentProps = {
  /**
   * Setting which determines element to render.
   */
  setting: ComponentSettingPropDSL;
  /**
   * Prefix to add before each data-test attribute.
   */
  dataTestPrefix?: string;
  /**
   * The value that is stored in the DSL by the corresponding keyPath.
   */
  keyValue: NodePropValue | null;
  /**
   * Callback to update keyValue.
   */
  onChange: (arg: { keyValue: unknown; keyPath: Array<string | number> }) => void;
  /**
   * Callback to update several keyValue.
   */
  onChangeMany: (
    propDataArray: {
      keyValue: unknown;
      keyPath: (string | number)[];
    }[],
  ) => void;
};

/**
 * Renders appropriate input field depends on component setting.
 */
export const HTMLFragment = memo(
  'HTMLFragment',
  ({ setting, keyValue, dataTestPrefix = '', onChange }: HTMLFragmentProps): JSX.Element | null => {
    const { selectedNodeDSL } = useNodeSettingsProps();
    const nodeID = selectedNodeDSL?.id;
    const nodeListDSL = useNodeListDSL();
    const nodePath = componentSettingSelectors.getSettingsNodePath(setting);
    const keyName = componentSettingSelectors.getSettingsKeyNamePath(setting);
    const isPropRequired = componentSettingSelectors.isRequired(setting);
    const dataTest = `${dataTestPrefix}.${keyName}`;
    const { label = '' } = setting;
    const targetNodeId = getTargetNodeID({
      nodeID,
      nodePath,
      nodeListDSL,
    });

    const updateProp = useCallback(
      (value: unknown) => {
        if (isPropRequired && (isNil(value) || value === '')) {
          return;
        }

        if (value === '') {
          onChange({
            keyValue: undefined,
            keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
          });
          return;
        }

        onChange({
          keyValue: value,
          keyPath: componentSettingSelectors.getSettingsKeyPath(setting),
        });
      },
      [isPropRequired, onChange, setting],
    );

    switch (setting.componentView) {
      case COMPONENT_SETTING_VIEWS.text: {
        PropAssert.Value.assertIsStringOrNumberProp(
          keyValue,
          componentSettingSelectors.getSettingsKeyNamePath(setting),
          { allowEmpty: true },
        );

        const parsedValue = keyValue === undefined ? undefined : String(keyValue);

        return (
          <Grid item xs={12}>
            <HTMLViewEditor
              targetNodeId={targetNodeId}
              label={label}
              propValue={parsedValue}
              onChangePropValue={updateProp}
              nodeID={nodeID}
              data-test={dataTest}
            />
          </Grid>
        );
      }

      default:
        return null;
    }
  },
);
