import { Migration } from '../Migration';
class Migration_0_13_4 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.13.4';
    }
    getChangeList() {
        return ['Add default media queries and empty media query CSS rules'];
    }
    migrateAppDSL(appDSL) {
        const { theme } = appDSL;
        return Object.assign(Object.assign({}, appDSL), { theme: Object.assign(Object.assign({}, theme), { css: Object.assign(Object.assign({}, theme === null || theme === void 0 ? void 0 : theme.css), { mediaQueries: `@media only screen and (max-width: 576px) {}

@media only screen and (min-width: 576px) and (max-width: 768px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 992px) and (max-width: 1200px) {}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {}

@media only screen and (min-width: 1400px) {}` }), mediaQueries: {
                    'x-small': {
                        id: 'x-small',
                        name: 'xSmall',
                        title: 'X Small',
                        max: '576',
                    },
                    'small-576-768': {
                        id: 'small-576-768',
                        name: 'small',
                        title: 'Small',
                        min: '576',
                        max: '768',
                    },
                    'medium-768-992': {
                        id: 'medium-768-992',
                        name: 'medium',
                        title: 'Medium',
                        min: '768',
                        max: '992',
                    },
                    'large-992-1200': {
                        id: 'large-992-1200',
                        name: 'large',
                        title: 'Large',
                        min: '992',
                        max: '1200',
                    },
                    'extra-large-1200-1400': {
                        id: 'extra-large-1200-1400',
                        name: 'extraLarge',
                        title: 'Extra Large',
                        min: '1200',
                        max: '1400',
                    },
                    'extra-extra-large-1400': {
                        id: 'extra-extra-large-1400',
                        name: 'extraExtraLarge',
                        title: 'Extra Extra Large',
                        min: '1400',
                    },
                } }) });
    }
}
export const migration_0_13_4 = new Migration_0_13_4();
