import { parse, Key } from 'path-to-regexp';
import { isEmpty } from 'ramda';
import { matchPath } from 'react-router-dom';

import { MetaTag, NodeDSL } from '@builder/schemas';
import { log, mustBeCorrectVariableName, regexpForECMAScriptFunctionName } from '@builder/utils';

import { FormMetaTagArray, PairListItemValue } from 'src/shared/components';

/**
 * Returns names from path /:foo/:bar -> ['foo', 'bar']
 */
const extractPathParamNames = (path: string) => {
  try {
    return parse(path)
      .filter((p): p is Key => typeof p !== 'string')
      .map(p => p.name as string);
  } catch (error) {
    log.warn(error);
    return [];
  }
};

/**
 * Checks for empty name, value fields, and that names exists in path
 * return string array mapped by same order as input defaultPathParams
 */
export function validateDefaultPathParams({
  path,
  defaultPathParams,
}: {
  path: string;
  defaultPathParams: DefaultPathParam[];
}): Partial<DefaultPathParam>[] | undefined {
  const pathParamNames = extractPathParamNames(path);
  const pathDefaultParamErrors: Partial<DefaultPathParam>[] = [];

  defaultPathParams.forEach(({ key, value }, index) => {
    if (isEmpty(key)) {
      pathDefaultParamErrors[index] = {
        key: 'Required',
      };
      return;
    }

    if (!pathParamNames.includes(key)) {
      pathDefaultParamErrors[index] = {
        key: 'Param is not found in path',
      };
      return;
    }

    if (isEmpty(value)) {
      pathDefaultParamErrors[index] = {
        value: 'Value is required',
      };
    }

    if (!regexpForECMAScriptFunctionName.test(key)) {
      pathDefaultParamErrors[index] = {
        key: 'Param is not a valid javascript variable name',
      };
    }
  });

  return isEmpty(pathDefaultParamErrors) ? undefined : pathDefaultParamErrors;
}

/**
 * Checks for empty name, value fields, and that names exists in path
 * return string array mapped by same order as input defaultPathParams
 */
export function validateDefaultMetadata(
  oldMetadata: DefaultPathMetadata[],
  metadata: DefaultPathMetadata[],
): Partial<DefaultPathMetadata>[] | undefined {
  const pathDefaultParamErrors: Partial<DefaultPathMetadata>[] = [];

  const foundMetadataElement = metadata.map(({ key, value }, index) => {
    const metadataElementFinded = metadata.findIndex(
      (metadataElement, elementIndex) =>
        metadataElement.key === key && metadata[elementIndex].key === key && index !== elementIndex,
    );

    return metadataElementFinded;
  });

  const repeatedElements = foundMetadataElement.filter(element => element >= 0);

  const focusField = metadata
    .map((field, idx) => {
      if (field.key !== oldMetadata[idx]?.key || field.value !== oldMetadata[idx]?.value) {
        return idx;
      }

      return -1;
    })
    .filter(field => field !== -1)[0];

  metadata.forEach(({ key, value }, index) => {
    if (isEmpty(key)) {
      pathDefaultParamErrors[index] = {
        key: 'Required',
      };
      return;
    }

    const isCorrectVariableName = mustBeCorrectVariableName(key);
    if (isCorrectVariableName) {
      pathDefaultParamErrors[index] = {
        key: isCorrectVariableName,
      };
      return;
    }

    if (foundMetadataElement[index] >= 0 && focusField === index) {
      pathDefaultParamErrors[index] = {
        key: 'Metadata key must be unique',
      };
      return;
    }

    if (focusField === index && repeatedElements.length > 0) {
      foundMetadataElement.forEach((ele, idx) => {
        if (ele >= 0) {
          pathDefaultParamErrors[idx] = {
            key: 'Metadata key must be unique',
          };
        }
      });
      return;
    }

    if (isEmpty(value)) {
      pathDefaultParamErrors[index] = {
        value: 'Value is required',
      };
    }
  });

  return isEmpty(pathDefaultParamErrors) ? undefined : pathDefaultParamErrors;
}

/**
 * Checks for empty value, lowercase and uniqueness for params
 */
export function validatePath({
  path,
  allRouteNodes,
  currentRoute,
}: {
  path?: string;
  allRouteNodes: NodeDSL[];
  currentRoute?: NodeDSL;
}): string | undefined {
  if (!path || path === '') return 'Required';

  const pathParamNames = extractPathParamNames(path);

  if (pathParamNames.some(name => !name.match(/^[a-z][a-zA-Z\d]+$/)))
    return 'All param names should be in lower case allowing to use numbers';

  if (pathParamNames.length !== new Set(pathParamNames).size) return 'Param names should be unique';

  if (
    allRouteNodes.find(
      route =>
        route.id !== currentRoute?.id &&
        Boolean(
          matchPath(route.props.path as string, {
            path,
            exact: true,
          }),
        ),
    )
  ) {
    return 'Path already exists';
  }

  if (pathParamNames.some(param => !regexpForECMAScriptFunctionName.test(param))) {
    return 'Param is not a valid javascript variable name';
  }
}

export function validateRouteAlias({
  nodeAlias,
  allRouteNodes,
  currentRoute,
}: {
  nodeAlias?: string;
  allRouteNodes: NodeDSL[];
  currentRoute?: NodeDSL;
}): string | undefined {
  if (!nodeAlias || nodeAlias === '') return 'Required';

  const isCorrectVariableName = mustBeCorrectVariableName(nodeAlias.replace(/ /g, ''));

  if (isCorrectVariableName) {
    return 'Invalid page name';
  }

  if (
    allRouteNodes.find(
      // Routes has alias such as "Home Page Wrapper" and route content has alias such as "Home Page"
      route =>
        route.id !== currentRoute?.id &&
        route.alias?.replace(' Wrapper', '').trim() === nodeAlias.trim(),
    )
  ) {
    return 'Page name already exists';
  }

  if (nodeAlias === 'currentRoute') return 'That name is forbbiden';
}

export const validateRouteForm = ({
  values,
  allRouteNodes,
  currentRoute,
}: {
  values: RouteFormValues;
  allRouteNodes: NodeDSL[];
  currentRoute?: NodeDSL;
}): FormErrors => {
  const errors: FormErrors = {};
  const nodeAliasErrors = validateRouteAlias({
    allRouteNodes,
    currentRoute,
    nodeAlias: values.nodeAlias,
  });
  if (nodeAliasErrors) {
    errors.nodeAlias = nodeAliasErrors;
  }

  const metaTagErrors = validateRouteMetaTags({ metaTags: values?.metaTags });
  if (metaTagErrors) {
    errors.metaTags = metaTagErrors;
  }

  return errors;
};

export function removeWrapperFromRouteAlias(routeAlias = ''): string {
  return routeAlias?.replace(' Wrapper', '').trim();
}

export const validateRouteMetaTags = ({
  metaTags = [],
}: {
  metaTags?: MetaTag[];
}): Partial<MetaTag | undefined>[] | undefined => {
  const uniqMetaTagNames = new Set();
  const metaTagErrors = metaTags.map(metaTag => {
    const metaTagError: Partial<MetaTag> = {};
    if (!metaTag.name) {
      metaTagError.name = 'Meta Tag Name is required';
    } else if (uniqMetaTagNames.has(metaTag.name)) {
      metaTagError.name = 'Meta Tag Name should be uniq';
    } else {
      uniqMetaTagNames.add(metaTag.name);
    }

    return isEmpty(metaTagError) ? undefined : metaTagError;
  });

  // [undefined, { name: "Meta Tag Name is required" }] => true
  // [undefined, undefined] => false
  const hasError = metaTagErrors.some(error => Boolean(error));
  return hasError ? metaTagErrors : undefined;
};

export function validateLayoutAliasIsUnique({
  nodeAlias,
  allRouteNodes,
  currentRoute,
  allBaseLayoutNodes,
}: {
  nodeAlias?: string;
  allRouteNodes: NodeDSL[];
  allBaseLayoutNodes: NodeDSL[];
  currentRoute?: NodeDSL;
}): string | undefined {
  if (!nodeAlias || nodeAlias === '') return 'Required';

  if (
    allRouteNodes.find(
      // Routes has alias such as "Home Page Wrapper" and route content has alias such as "Home Page"
      route =>
        route.id !== currentRoute?.id &&
        removeWrapperFromRouteAlias(route.alias) === nodeAlias.trim(),
    ) ||
    allBaseLayoutNodes.find(
      // Routes has alias such as "Home Page Wrapper" and route content has alias such as "Home Page"
      route =>
        route.id !== currentRoute?.id &&
        removeWrapperFromRouteAlias(route.alias) === nodeAlias.trim(),
    )
  ) {
    return 'Layout name already exists';
  }

  if (nodeAlias === 'currentRoute') return 'That name is forbbiden';
}

export function validateDialogAliasIsUnique({
  nodeAlias,
  allDialogNodes,
}: {
  nodeAlias?: string;
  allDialogNodes: NodeDSL[];
}): string | undefined {
  if (!nodeAlias || nodeAlias === '') return 'Required';

  if (allDialogNodes.find(dialog => dialog.alias === nodeAlias)) {
    return 'Layout name already exists';
  }
}

export const validateDialogForm = ({
  values,
  allDialogNodes,
}: {
  values: DialogFormValues;
  allDialogNodes: NodeDSL[];
}): FormErrors => {
  const errors: FormErrors = {};
  const nodeAliasErrors = validateDialogAliasIsUnique({
    allDialogNodes,
    nodeAlias: values.nodeAlias,
  });

  if (nodeAliasErrors) {
    errors.nodeAlias = nodeAliasErrors;
  }

  /**
   * This patter is used to validate
   * The value only can contain: numbers, letters, space, "_" and "-"
   */
  const pattern = new RegExp('^[A-Za-z0-9_\\- ]+$');
  const isCorrectVariableName = mustBeCorrectVariableName(values.nodeAlias.replace(/ /g, ''));
  if (
    !isNaN(+values?.nodeAlias.charAt(0)) ||
    values?.nodeAlias.slice(-1) === ' ' ||
    values?.nodeAlias.charAt(0) === ' ' ||
    isCorrectVariableName ||
    !pattern.test(values?.nodeAlias)
  ) {
    errors.nodeAlias = 'Invalid dialog name.';
  }

  if (values?.nodeAlias?.length > 25) {
    errors.nodeAlias = 'Dialog name is too long (maximum is 25 characters)';
  }

  if (values?.nodeAlias?.length < 3) {
    errors.nodeAlias = 'The Dialog name should contain at least 3 characters.';
  }

  return errors;
};

export const validateLayoutForm = ({
  values,
  allRouteNodes,
  currentRoute,
  allBaseLayoutNodes,
}: {
  values: RouteFormValues;
  allRouteNodes: NodeDSL[];
  allBaseLayoutNodes: NodeDSL[];
  currentRoute?: NodeDSL;
}): FormErrors => {
  const errors: FormErrors = {};
  const nodeAliasErrors = validateLayoutAliasIsUnique({
    allRouteNodes,
    allBaseLayoutNodes,
    currentRoute,
    nodeAlias: values.nodeAlias,
  });

  if (nodeAliasErrors) {
    errors.nodeAlias = nodeAliasErrors;
  }

  /**
   * This patter is used to validate
   * The value only can contain: numbers, letters, space, "_" and "-"
   */
  const pattern = new RegExp('^[A-Za-z0-9_\\- ]+$');
  if (
    !isNaN(+values?.nodeAlias.charAt(0)) ||
    values?.nodeAlias.slice(-1) === ' ' ||
    values?.nodeAlias.charAt(0) === ' ' ||
    !pattern.test(values?.nodeAlias)
  ) {
    errors.nodeAlias = 'Invalid layout name.';
  }

  if (values?.nodeAlias?.length > 25) {
    errors.nodeAlias = 'Layout name is too long (maximum is 25 characters)';
  }

  if (values?.nodeAlias?.length < 3) {
    errors.nodeAlias = 'The layout name should contain at least 3 characters.';
  }

  return errors;
};

/**
 * Create url using default path params, if default param is not found then left as is param name
 */
export const createUrlFromPathWithDefaultPathParams = (
  path: string,
  defaultPathParams: DefaultPathParam[],
): string => {
  const parsedPath = parse(path);
  return parsedPath.reduce((res, item) => {
    if (typeof item === 'string') return res + item;

    const key = item as Key;
    const pathParam = defaultPathParams.find(param => param.key === key.name);
    return `${res}${key.prefix}${pathParam ? pathParam.value : `:${key.name}`}`;
  }, '') as string;
};

export const getLayoutUrl = (layoutName: string): string => {
  return `/__layouts/__${layoutName.replace(/\s+/g, '').toLowerCase()}`;
};

export const checkIsSubmitButtonDisabled = ({
  dirty,
  errors,
  touched,
}: {
  dirty: boolean;
  errors?: FormErrors;
  touched?: FormTouched;
}): boolean => {
  if (!dirty) {
    return true;
  }

  const hasErorrs = !isEmpty(errors);
  if (!hasErorrs) {
    return false;
  }

  const metaTagErrors = errors?.metaTags || [];
  const hasMetaTagErrors = !isEmpty(metaTagErrors);
  if (!hasMetaTagErrors) {
    return false;
  }

  const touchedMetaTags = touched?.metaTags || [];
  const hasTouchedMetaTagErrors = !isEmpty(
    touchedMetaTags.filter((metaTag, index) => {
      return Boolean(metaTag?.name) && Boolean(metaTagErrors[index]);
    }),
  );

  if (!hasTouchedMetaTagErrors) {
    return false;
  }

  return true;
};

export type DefaultPathParam = { key: string; value: string };

export type DefaultPathMetadata = { key?: string; value?: PairListItemValue };

export type RouteDefaultParam = { name: string; value: string };

export type RouteDefaultMetadata = Record<string, PairListItemValue>;

type RouteFormValues = {
  nodeAlias: string;
  path: string;
  layoutID: string;
  defaultPathParams: DefaultPathParam[];
  meta: DefaultPathMetadata[];
  metaTags: MetaTag[];
};

type DialogFormValues = {
  nodeAlias: string;
};

export type FormErrors = {
  nodeAlias?: string;
  path?: string;
  layoutID?: string;
  defaultPathParams?: Partial<DefaultPathParam>[];
  meta?: Partial<DefaultPathParam>[];
  metaTags?: Partial<MetaTag | undefined>[];
};

export type FormTouched = {
  metaTags?: FormMetaTagArray;
  [x: string]: unknown;
};
