import { MutableRefObject, useCallback, useMemo } from 'react';

import debounce from 'lodash.debounce';
import { path } from 'ramda';
import {
  ConnectDropTarget,
  DragLayerMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';

import { ComponentDSLNameTypes, NodeID } from '@builder/schemas';
import { isArray } from '@builder/utils';

import {
  DROPPABLE_AREAS,
  DraggableItem,
  DroppingIntoOverlayResult,
  DROPPABLES_2_DRAGGABLES,
  DRAGGABLES,
  dragEnd,
  dragStart,
} from '../../logic';
import { dragHoverForComponentOverlay, dragHoverForNavItemOverlay } from '../../logic/dragHover';
import { LazyLeafMarkerMapRef } from 'src/features/navigator';
import {
  useAppDispatch,
  useComponentListDSL,
  useNodeListDSL,
  useSelectedNodeID,
} from 'src/providers/ReduxProvider';
import { useCurrentWorkspaceID } from 'src/shared/hooks';
import { BasicTargetInfo, DashboardState } from 'src/store';

export type UseOverlayDndArgs = {
  // TODO: target arg is confusing as hell
  target: BasicTargetInfo;
  name: ComponentDSLNameTypes;
};

export type UseOverlayDragOnlyArgs = { nodeID: NodeID; nodeName: string };

export type UseOverlayDndResult = {
  ref: MutableRefObject<HTMLDivElement | null>;
  isOver: boolean;
  isDragging: boolean;
};

type UseOverlayDropOnlyCoreArgs = Omit<UseOverlayDndArgs, 'name'> & {
  ref: MutableRefObject<HTMLDivElement | null>;
};

type UseOverlayDropOnlyCoreMode =
  | { type: 'default' }
  | {
      type: 'navigator' | 'placeholder';
      currentRootNodeID?: NodeID;
      collapsedNodes?: DashboardState['navigator']['collapsedNodes'];
      lazyLeafMarkerMapRef?: LazyLeafMarkerMapRef;
    };

/**
 * Not meant for public consumption!
 * @monitor should not be exposed like that
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useOverlayDropOnlyCore(
  { target, ref }: UseOverlayDropOnlyCoreArgs,
  mode: UseOverlayDropOnlyCoreMode = { type: 'default' },
) {
  const send = useAppDispatch();
  const componentListDSL = useComponentListDSL();
  const nodeListDSL = useNodeListDSL();
  const DROP_AREA_TYPE = DROPPABLE_AREAS.OVERLAY;

  const hoverHandler = useCallback(
    () =>
      (mode.type === 'default' ? dragHoverForComponentOverlay : dragHoverForNavItemOverlay)({
        send,
        // placeholder will still act as as drop-zone, but wont change the current dnd spec of a draggable item
        ref: mode.type === 'placeholder' ? { current: null } : ref,
        target,
        componentListDSL,
        nodeListDSL,
        currentRootNodeID: path(['currentRootNodeID'], mode),
        collapsedNodes: path(['collapsedNodes'], mode),
        lazyLeafMarkerMapRef: path(['lazyLeafMarkerMapRef'], mode),
      }),
    [componentListDSL, mode, nodeListDSL, ref, send, target],
  );

  const dropResults = useDrop<
    DraggableItem,
    DroppingIntoOverlayResult | undefined,
    { isOver: boolean; item: DraggableItem | null; monitor: DropTargetMonitor }
  >({
    accept: DROPPABLES_2_DRAGGABLES[DROP_AREA_TYPE],
    drop(_, monitor): DroppingIntoOverlayResult | undefined {
      if (monitor.isOver({ shallow: true })) {
        return { name: DROP_AREA_TYPE, target };
      }
    },
    hover: debounce((item, monitor) => {
      hoverHandler()(item, monitor);
    }, 100),
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      item: monitor.getItem(),
      monitor,
    }),
  });

  return useMemo(() => ({ dropResults, hoverHandler }), [dropResults, hoverHandler]);
}

/**
 * Default public implementation of drop-only overlay!
 */
export function useOverlayDropOnly({
  target,
  ref,
}: Omit<UseOverlayDndArgs, 'name'> & { ref: MutableRefObject<HTMLDivElement | null> }): [
  {
    isOver: boolean;
  },
  ConnectDropTarget,
] {
  const dndConfig = useMemo(() => ({ target, ref }), [target, ref]);
  const { dropResults } = useOverlayDropOnlyCore(dndConfig, { type: 'default' });
  const [{ isOver }, connectedDropTarget] = dropResults;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [{ isOver }, connectedDropTarget], [isOver]);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useOverlayDragOnly({ nodeID, nodeName }: UseOverlayDragOnlyArgs) {
  const send = useAppDispatch();
  const nodeListDSL = useNodeListDSL();
  const { workspaceID } = useCurrentWorkspaceID();
  const selectedNodesIds = useSelectedNodeID();

  return useDrag({
    type: DRAGGABLES.OVERLAY,
    item: monitor => {
      // only use if the items were already selected, otherwise is not useful using unsync'ed selectedNodeIds
      if (isArray(selectedNodesIds) && selectedNodesIds.length > 1) {
        dragStart(selectedNodesIds, send, nodeName)(monitor);
        return selectedNodesIds.map(id => ({
          type: DRAGGABLES.OVERLAY,
          id,
          name: nodeListDSL[id].name,
          dndMutableMeta: null,
        }));
      }

      dragStart(nodeID, send, nodeName)(monitor);
      return [
        {
          type: DRAGGABLES.OVERLAY,
          id: nodeID,
          name: nodeName,
          dndMutableMeta: null,
        },
      ];
    },
    end: dragEnd(send, nodeListDSL, workspaceID),
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
}
