import { forwardRef, Ref } from 'react';

import { getIsInvalid } from '@builder/components';

import { TextField, TextFieldProps } from '../../TextField';
import { Field, FieldProps, FieldRenderProps } from '../Field';

export type FormTextFieldProps = {
  fieldProps: FieldProps;
  inputProps?: TextFieldProps;
  'data-test'?: string;
};

export const FormTextField = forwardRef(
  (
    { fieldProps, inputProps = {}, 'data-test': dataTest }: FormTextFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <Field {...fieldProps} useValueOnChange>
        {({ field, meta, form }: FieldRenderProps) => {
          const isInvalid = getIsInvalid({ meta, form });
          const errorText = isInvalid ? meta.error : undefined;
          return (
            <TextField
              ref={ref}
              {...inputProps}
              name={fieldProps.name}
              value={field.value}
              onChange={event => field.onChange(event.target.value || undefined)}
              error={isInvalid}
              helperText={errorText}
              data-test={dataTest}
            />
          );
        }}
      </Field>
    );
  },
);
