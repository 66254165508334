var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { ToggleButtonGroup as MuiToggleButtonGroup, FormControl, FormHelperText, } from '@mui/material';
export const ToggleButtonGroup = forwardRef((_a, ref) => {
    var { size, className, style, css, error, helperText, variant, value, onChange, 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath, children, id, title, lang, translate, color, classes, label } = _a, otherToggleGroupProps = __rest(_a, ["size", "className", "style", "css", "error", "helperText", "variant", "value", "onChange", 'data-test', 'data-node-id', 'data-node-render-path', "children", "id", "title", "lang", "translate", "color", "classes", "label"]);
    return (_jsxs(FormControl, Object.assign({ size: size, className: className, style: style, css: css, ref: ref, "data-test": dataTest, "data-node-id": dataNodeID, "data-node-render-path": dataRenderPath, id: id, title: title, lang: lang, translate: translate }, { children: [label, _jsx(MuiToggleButtonGroup, Object.assign({}, otherToggleGroupProps, { size: size, color: color, classes: classes, value: value, onChange: onChange, id: id, className: className, title: title, lang: lang, translate: translate }, { children: children })), helperText && _jsx(FormHelperText, Object.assign({ error: error }, { children: helperText }))] })));
});
