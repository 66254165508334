import { pipe, values } from 'ramda';
import { COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { getBasicPropsSettings, NS_ID, pickSettings, assocSettings, disableRadioOptions, DISPLAY_OPTION_VALUES, BASIC_PROPS_TOOLTIP, } from '../../common';
import { getDivider, getEventsSection, getPropertySections, getStyleSections, } from '../../common/settings';
export const getCommonTabSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'label'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'value'],
], { withStructure: false }))(values(BuilderComponentsTabSettings).flat());
export const getBuilderComponentsTabSettings = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            keyPath: ['children'],
                            name: 'label',
                            nodePath: [...prefixPath, 'label', 0],
                            label: 'Label',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'header',
                            label: 'Header',
                            keyPath: [...prefixPath, 'header'],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.number,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'value',
                            label: 'Tab Index',
                            keyPath: [...prefixPath, 'value'],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'wrapped'],
                            name: 'wrapped',
                            label: 'Wrapped',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'disabled'],
                            name: 'disabled',
                            label: 'Disabled',
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'icon'],
                    label: 'Icon',
                    name: 'icon',
                    options: ['phone', 'favorite', 'person', 'none'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.select,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'iconposition'],
                    label: 'Icon Position',
                    name: 'iconposition',
                    options: ['top', 'start', 'end', 'bottom'].map(createSelectOptionByValue),
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'disableFocusRipple'],
                    name: 'disableFocusRipple',
                    label: 'Disable Focus Ripple',
                },
                {
                    componentView: COMPONENT_SETTING_VIEWS.checkbox,
                    type: COMPONENT_SETTING_TYPES.prop,
                    keyPath: [...prefixPath, 'disableRipple'],
                    name: 'disableRipple',
                    label: 'Disable Ripple',
                },
                getDivider(),
                ...pipe(assocSettings([NS_ID.style.display.root, NS_ID.style.display.display], disableRadioOptions([DISPLAY_OPTION_VALUES.grid, DISPLAY_OPTION_VALUES.flex])))(getStyleSections(prefixPath)),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            children: getEventsSection(),
        },
    ],
});
export const BuilderComponentsTabSettings = getBuilderComponentsTabSettings();
