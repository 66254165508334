import { useMemo } from 'react';

import { NodeID } from '@builder/schemas';

export const useIsHoveredNodeBelongToLayout = (
  layoutNodesIds: string[],
  nodeID: NodeID,
): boolean => {
  return useMemo(() => layoutNodesIds.some(item => item === nodeID), [layoutNodesIds, nodeID]);
};
