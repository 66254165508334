import React, { useState, useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { Grid, Typography } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import { log } from '@builder/utils';

import { StyledPre } from '../DeployProgress.styles';
import { DEPLOY_STEP_NAME_TEXTS, DEPLOY_STEP_NAMES, DeployStepNames } from 'src/shared/constants';
import { DeployStepFragment } from 'src/shared/graphql/__generated__';

type DeployStepProgressProps = {
  deployStep: DeployStepFragment;
};

export const DeployStepProgress: React.FC<DeployStepProgressProps> = ({ deployStep }) => {
  const [logContents, setLogContents] = useState('Loading...');

  useEffect(
    function fetchLogContentsFromS3() {
      const fetchLog = () => {
        fetch(deployStep.log as string)
          .then(response => {
            if (!response.ok) {
              throw new Error('Something went wrong');
            } else {
              return response.text();
            }
          })
          .then(contents => {
            if (!isEmpty(contents)) {
              setLogContents(contents);
            }
          })
          .catch(error => log.error(error));
      };

      if (isNil(deployStep.exitCode)) {
        const timeoutId = setInterval(fetchLog, 2500);
        return () => clearInterval(timeoutId);
      }

      fetchLog();
    },
    [deployStep],
  );

  return (
    <Grid container direction="column" gap={2}>
      <Grid item container direction="row" gap={1} alignItems="center">
        <Grid item>
          <Choose>
            <When condition={deployStep.exitCode === 0}>
              <CheckCircleIcon style={{ verticalAlign: 'top' }} />
            </When>
            <When condition={isNil(deployStep.exitCode)}>
              <PendingIcon style={{ verticalAlign: 'top' }} />
            </When>
            <Otherwise>
              <ErrorIcon style={{ verticalAlign: 'top' }} />
            </Otherwise>
          </Choose>
        </Grid>
        <Grid item>
          <Choose>
            <When condition={deployStep.step === DEPLOY_STEP_NAMES.download}>
              <Typography variant="body1">
                {DEPLOY_STEP_NAME_TEXTS[deployStep.step as DeployStepNames]}
              </Typography>
            </When>
            <When condition={deployStep.step === DEPLOY_STEP_NAMES.deploy}>
              <Typography variant="body1">
                {DEPLOY_STEP_NAME_TEXTS[deployStep.step as DeployStepNames]}
              </Typography>
            </When>
            <Otherwise>
              <Typography variant="body1">Unknown step</Typography>
            </Otherwise>
          </Choose>
        </Grid>
      </Grid>
      <Grid item>
        <StyledPre>{logContents}</StyledPre>
      </Grid>
    </Grid>
  );
};
