import { update } from 'ramda';
import { matchPath } from 'react-router-dom';

import { nodeListSelectors } from '@builder/schemas';

import { dashboardInitial } from '../../../initial-state';
import { dashboardSelectors } from 'src/store';
import { AppEvents, createReducer, DashboardState, DASHBOARD_EVENTS } from 'src/store/common';

const ROUTER_HISTORY_LIMIT = 20;

export const ROUTER_REDUCER_EVENTS = [
  DASHBOARD_EVENTS.routerPathUpdateByUserApp,
  DASHBOARD_EVENTS.routerPathUpdate,
];

const { reducerEventsDomain } = createReducer<DashboardState, AppEvents>(declare => [
  declare<typeof ROUTER_REDUCER_EVENTS[number]>({
    events: ROUTER_REDUCER_EVENTS,
    reduce: (state, event) => {
      if (state.router.currentRoute === event.route) return state;

      const nodeListDSL = dashboardSelectors.getNodeListDSL(state);
      const allRouteNodes = nodeListSelectors.getAllRouteNodesIncludeSystem(nodeListDSL);
      const currentRoute = allRouteNodes.find(route =>
        matchPath(event.route, {
          path: route.props.path as string,
          exact: true,
        }),
      );

      if (!currentRoute) return state;

      const updatedHistory =
        state.router.history.length < ROUTER_HISTORY_LIMIT
          ? [...state.router.history, event.route]
          : update(state.router.history.length, event.route, state.router.history);
      return {
        ...state,
        operations: {
          ...state.operations,
          selectedID: null,
          hoveredID: null,
        },
        router: {
          ...state.router,
          currentRoute: event.route,
          history: updatedHistory,
        },
      };
    },
  }),
]);

export const routerReducer = (
  state: DashboardState = dashboardInitial,
  event: AppEvents,
): DashboardState => {
  return reducerEventsDomain[event.type]?.(state, event) ?? state;
};
