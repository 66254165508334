import { COMPONENT_SETTING_ACTION_TYPE, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants/component-settings';
export const getCommonFormFieldPropertiesSettings = () => [
    {
        componentView: COMPONENT_SETTING_VIEWS.text,
        type: COMPONENT_SETTING_TYPES.prop,
        keyPath: ['fieldProps', 'name'],
        label: 'Name',
    },
];
export const getCommonFormFieldActionSettings = () => [
    {
        type: COMPONENT_SETTING_TYPES.action,
        name: 'validateAction',
        label: 'Validate',
        keyPath: ['fieldProps', 'validate'],
        children: [
            {
                componentView: COMPONENT_SETTING_VIEWS.select,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['fieldProps', 'validate', 'actionType'],
                resetPathBeforeOnChange: [['fieldProps', 'validate', 'args']],
                label: 'Type',
                options: [
                    {
                        value: COMPONENT_SETTING_ACTION_TYPE.requiredValidate,
                        label: 'Required Validate',
                    },
                    {
                        value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                        label: 'Custom Code',
                    },
                ],
            },
            {
                componentView: COMPONENT_SETTING_VIEWS.jsEditor,
                type: COMPONENT_SETTING_TYPES.prop,
                keyPath: ['fieldProps', 'validate', 'args', 'code'],
                showIf: [
                    {
                        keyPath: ['fieldProps', 'validate', 'actionType'],
                        type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                        value: COMPONENT_SETTING_ACTION_TYPE.customCode,
                    },
                ],
                defaultValue: '// Your JavaScript code here',
                label: 'Custom Code',
            },
        ],
    },
];
