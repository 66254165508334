import { uniq } from 'ramda';
import { HookGenerator } from './HookGenerator';
/**
 * Provides an api to generate hooks state usage.
 */
export class HookListUsageGenerator {
    constructor({ appDSL, componentListDSL, assetListDSL, }) {
        this.appDSL = appDSL;
        this.hookGenerators = Object.values(this.appDSL.states).map(appState => new HookGenerator({
            appDSL: this.appDSL,
            componentListDSL,
            appStateDSL: appState,
            assetListDSL,
        }));
    }
    /**
     * @returns All global app calls of hooks which was generated by app state.
     */
    generateGlobalStateHookCalls() {
        return this.hookGenerators.reduce((accum, hookGenerator) => {
            const hookCall = hookGenerator.getHookCall();
            return `${accum}\n ${hookCall}`;
        }, '');
    }
    /**
     *
     * @returns All constants associated with hooks.
     */
    generateStatesDeclarations() {
        return this.hookGenerators.reduce((accum, hookGenerator) => {
            const hookDeclaration = hookGenerator.getHookDeclaration();
            return `${accum}\n ${hookDeclaration}`;
        }, '');
    }
    /**
     * @returns All possible hook declarations.
     */
    generateDeclarations() {
        return this.generateStatesDeclarations();
    }
    generateHooksExportDeclarations() {
        const hookNames = this.hookGenerators.map(hookGenerator => {
            const hookName = hookGenerator.stateAccessor.getHookDeclarationName(this.appDSL);
            return hookName;
        });
        const predefinedHooksExports = `
      export { useRoutes } from './predefined/useRoutes';
      export { useRouterHooks } from './predefined/useRouterHooks';
      export { useRestRequest } from './predefined/useRestRequest';
      export { useLazyQueryState } from './predefined/useLazyQueryState';
      export { useResources } from './predefined/useResources';
      export { useLibraries } from './predefined/useLibraries';
    `;
        return `
      ${predefinedHooksExports}

      ${uniq(hookNames)
            .map(hookName => {
            return `export { ${hookName} } from './${hookName}';`;
        })
            .join('\n')}
    `;
    }
}
