import { COMPONENT_DSL_INSIDE_ONLY_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { getPredefinedStyles } from '../../common/commonPredefineds';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps, getCommonSymbolEventProps, getCommonSymbolStyleProps, } from '../../common/commonProps';
import { HeadingSymbolSettings } from './HeadingSymbol.settings';
export const HeadingSymbol = {
    name: COMPONENT_DSL_NAMES.HeadingSymbol,
    displayName: 'Heading',
    icon: 'heading',
    type: COMPONENT_DSL_TYPES.symbol,
    componentListGroupName: MENU_GROUP_NAMES.other,
    overlayOutlineWhenEmpty: true,
    schema: {
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.exceptOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.anyAncestor,
                    name: COMPONENT_DSL_NAMES.tableHeader,
                },
            },
        ],
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Text',
        },
        supportDoubleClickTextEdit: {
            enabled: true,
            inputLabelToFocus: 'Text',
        },
        predefineds: {
            props: {
                variant: 'h6',
                align: 'inherit',
                color: 'textPrimary',
                component: 'h6',
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: 'Heading',
                            },
                        },
                    ],
                },
                style: getPredefinedStyles({ minHeight: 16, minWidth: 16 }),
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { variant: { type: COMPONENT_DSL_PROP_TYPES.enum }, align: { type: COMPONENT_DSL_PROP_TYPES.enum }, color: { type: COMPONENT_DSL_PROP_TYPES.enum }, gutterBottom: { type: COMPONENT_DSL_PROP_TYPES.boolean }, noWrap: { type: COMPONENT_DSL_PROP_TYPES.boolean }, component: { type: COMPONENT_DSL_PROP_TYPES.enum }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
        symbolNodes: {
            name: COMPONENT_DSL_NAMES.MaterialTypography,
            props: Object.assign(Object.assign(Object.assign({}, getCommonSymbolStyleProps()), getCommonSymbolEventProps()), { component: '{{ symbolProps.component }}', variant: '{{ symbolProps.variant }}', align: '{{ symbolProps.align }}', color: '{{ symbolProps.color }}', gutterBottom: '{{ symbolProps.gutterBottom }}', id: '{{ symbolProps.id }}', title: '{{ symbolProps.title }}', className: '{{ symbolProps.className }}', lang: '{{ symbolProps.lang }}', translate: '{{ symbolProps.translate }}', draggable: '{{ symbolProps.draggable }}', noWrap: '{{ symbolProps.noWrap }}', children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: '{{ symbolProps.children }}',
                            },
                        },
                    ],
                } }),
        },
    },
    settings: HeadingSymbolSettings,
};
