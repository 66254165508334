import { pipe, values } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_CONDITION_TYPES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { createSelectOptionByValue } from '../../../utils';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, getBasicPropsSettings, NS_ID, pickSettings, } from '../../common';
import { getDivider, getEventsSection, getLooperSection, getPropertySections, getStyleSections, } from '../../common/settings';
import { getCommonBoxSettings } from '../../material-components/BuilderComponentsBox/BuilderComponentsBox.settings';
import { getCommonIconSettings } from '../../material-components/BuilderComponentsIcon/BuilderComponentsIcon.settings';
import { getCommonBadgeSettings } from '../../material-components/MaterialBadge/MaterialBadge.settings';
import { getCommonChipSettings } from '../../material-components/MaterialChip/MaterialChip.settings';
export const ListItemSymbolSettings = {
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.nodeText,
                            name: 'listItemText',
                            keyPath: ['children'],
                            nodePath: ['listItemText', 0],
                            label: 'Text',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'selected',
                            keyPath: ['selected'],
                            label: 'Selected',
                            showIf: [
                                {
                                    keyPath: ['button'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'disabled',
                            keyPath: ['disabled'],
                            label: 'Disabled',
                            showIf: [
                                {
                                    keyPath: ['button'],
                                    type: COMPONENT_SETTING_CONDITION_TYPES.equals,
                                    value: true,
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            name: 'button',
                            keyPath: ['button'],
                            label: 'Button',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            name: 'startIcon',
                            label: 'Left Component',
                            keyPath: ['startIcon'],
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                                    settings: getCommonIconSettings(),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialBadge,
                                    settings: getCommonBadgeSettings(),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.MaterialChip,
                                    settings: getCommonChipSettings(),
                                },
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsBox,
                                    settings: getCommonBoxSettings(),
                                },
                            ],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            label: 'Tooltip Title',
                            keyPath: ['tooltipTitle'],
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['tooltipPlacement'],
                            options: [
                                'bottom-end',
                                'bottom-start',
                                'bottom',
                                'left-end',
                                'left-start',
                                'left',
                                'right-end',
                                'right-start',
                                'right',
                                'top-end',
                                'top-start',
                                'top',
                            ].map(createSelectOptionByValue),
                            label: 'Tooltip Placement',
                        },
                    ],
                },
                getDivider(),
                getLooperSection(),
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
                ...getPropertySections(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.select,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['alignItems'],
                            options: ['flex-start', 'center'].map(createSelectOptionByValue),
                            label: 'Align Items',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['dense'],
                            label: 'Dense',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['disableGutters'],
                            label: 'Disable Gutters',
                        },
                        {
                            componentView: COMPONENT_SETTING_VIEWS.checkbox,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: ['divider'],
                            label: 'Divider',
                        },
                    ],
                },
                getDivider(),
                ...pipe(pickSettings([
                    [NS_ID.style.display.root],
                    [NS_ID.style.spacing.root],
                    [NS_ID.style.flexChild.root],
                    [NS_ID.style.gridChild.root],
                    [NS_ID.style.size.root],
                    [NS_ID.style.background.root],
                    [NS_ID.style.font.root],
                    [NS_ID.style.cursor.root],
                    [NS_ID.style.css.root],
                ]))(getStyleSections()),
            ],
        },
    ],
    events: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.events.root,
            children: getEventsSection(),
        },
    ],
};
export const getCommonListItemSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'listItemText'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'selected'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'disabled'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'button'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'startIcon'],
], { withStructure: false }))(values(ListItemSymbolSettings).flat());
