import { resourceSelectors, AUTH_TYPES, appDSLSelectors, resourceListSelectors, } from '@builder/schemas';
export class ApolloProviderGenerator {
    constructor(appDSL) {
        this.appDSL = appDSL;
    }
    getAuthResource() {
        return appDSLSelectors.getAuthResourceDSL(this.appDSL);
    }
    getAuthDSL() {
        const authResource = appDSLSelectors.getAuthResourceDSL(this.appDSL);
        const authDSL = appDSLSelectors.getAuthResourceCurrentAuthDSL(this.appDSL);
        if (!authResource || !authDSL) {
            return null;
        }
        return authDSL;
    }
    getApiEndpoint() {
        var _a;
        const authResourceEndpoint = `\`${appDSLSelectors.getAuthResourceEndpoint(this.appDSL)}\``;
        const fallbackApiEndpoint = (_a = resourceListSelectors
            .getEightBaseResourceArrayDSL(this.appDSL.resources)
            .map(resource => resourceSelectors.getResourceEndpointCodeEngine(resource))) === null || _a === void 0 ? void 0 : _a[0];
        return authResourceEndpoint || fallbackApiEndpoint;
    }
    generateApolloClient() {
        const authDSL = this.getAuthDSL();
        const apiEndpoint = this.getApiEndpoint();
        if (!apiEndpoint) {
            return '';
        }
        if (!authDSL) {
            return `
        const apolloClientInstance = new ApolloClient({
          uri: ${apiEndpoint},
          cache: new InMemoryCache(),
        });
      `;
        }
        if (authDSL.type === AUTH_TYPES.webNative) {
            return `
          const authClient = Auth.createClient(
            {
              strategy: AUTH_STRATEGIES.WEB_8BASE_NATIVE,
              subscribable: true,
            },
            {
              apiEndpoint: '${apiEndpoint}',
              authProfileId: '${authDSL.authProfileID}',
          }
        );
      `;
        }
        if (authDSL.type === AUTH_TYPES.token) {
            return `
        const authClient = Auth.createClient(
          {
            strategy: AUTH_STRATEGIES.API_TOKEN,
            subscribable: true,
          },
          {
            apiToken: '${authDSL.apiAuthToken}',
          }
        );
      `;
        }
        return '';
    }
    wrapInApolloProvider(children) {
        const authDSL = this.getAuthDSL();
        const apiEndpoint = this.getApiEndpoint();
        if (!apiEndpoint) {
            return `
      ${children}
     `;
        }
        if (!authDSL) {
            return `
       <ApolloProvider client={apolloClientInstance}>
          ${children}
        </ApolloProvider>
      `;
        }
        return `
      <AppProvider
        uri={'${apiEndpoint}'}
        authClient={authClient as any}
        onRequestSuccess={() => {}}
        onRequestError={() => {}}
      >
        {({ loading }) => (
          <AsyncContent loading={loading} stretch>
            ${children}
          </AsyncContent>
        )}
      </AppProvider>
    `;
    }
}
