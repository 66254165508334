import { pathOr } from 'ramda';

import { NodeElementData, NodeElementDataRecords, RenderPathData } from '@builder/schemas';

import { USER_APP_IFRAME_ID } from 'src/shared/constants';

/**
 * @deprecated Seems like you cannot really depend on NodeElementDataRecords to scan children,
 * since some of them might not be exposed by AppEngine for whatever reason.
 *
 * For this to work the registry should hold all existing elements' data
 * (AppEngine should expose all components indiscriminately).
 *
 * Prefer `getImmediateChildrenElRectanglesImperative` as a more stable approach for now.
 */
export function* getImmediateChildrenElRectangles(
  allElementDataRecords: NodeElementDataRecords,
  immediateRenderedChildren: {
    nodeID: string;
    renderPathData: RenderPathData;
  }[],
): Generator<DOMRect | null> {
  for (const {
    renderPathData: { renderID: childRenderID },
  } of immediateRenderedChildren) {
    const childElementData = allElementDataRecords[childRenderID];
    const childElementRect = childElementData?.rect;
    yield childElementRect;
  }
}

export function* getImmediateChildrenElRectanglesImperative(
  nonSelectableData: NodeElementData,
): Generator<DOMRect | null> {
  const iframe = document.querySelector(`[data-test="${USER_APP_IFRAME_ID}"]`) as HTMLIFrameElement;
  const allChildElements =
    iframe?.contentWindow?.document.querySelectorAll(
      `[data-node-render-path^="${nonSelectableData.renderPathData.renderID}::"]`,
    ) || [];

  const expectedChildrenLevel = nonSelectableData.renderPathData.renderPath.length + 1;
  const elementsByLevel = [...allChildElements].filter(el => {
    const elRenderPath = pathOr<string>('', ['dataset', 'nodeRenderPath'], el);
    return elRenderPath.split('::').length === expectedChildrenLevel;
  });

  for (const childEl of elementsByLevel) {
    const childElementRect: DOMRect | null = childEl.getBoundingClientRect().toJSON();
    yield childElementRect;
  }
}
