/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { loader } from '@monaco-editor/react';
import { isEmpty, values } from 'ramda';

import {
  appDSLSelectors,
  StateDSL,
  StateListDSL,
  StateScopeTypes,
  STATE_SCOPES,
} from '@builder/schemas';

import { useAppDSL, useAppDSLStates, useResourceListDSL } from 'src/providers';
import { addAppStatesTypes } from 'src/shared/components';
import { getTheDifferentValues } from 'src/shared/utils';

export const useUpdateMonacoStateTypes = (
  mountedStates: StateListDSL,
  setMountedStates: (val: StateListDSL) => void,
  scope: StateScopeTypes | undefined,
): void => {
  const appDSL = useAppDSL();
  const appStatesDSL = useAppDSLStates();
  const resourceListDSL = useResourceListDSL();
  const authResourceDSL = appDSLSelectors.getAuthResourceDSL(appDSL);
  const thereAreNewStates = values(mountedStates).length !== values(appStatesDSL).length;

  useEffect(() => {
    if (thereAreNewStates) {
      let unmountedStates = getTheDifferentValues<StateDSL>(
        values(appStatesDSL),
        values(mountedStates),
      );
      if (scope === STATE_SCOPES.global) {
        unmountedStates = unmountedStates.filter(state => state.scope === STATE_SCOPES.global);
      }

      if (!isEmpty(unmountedStates)) {
        const newStates = unmountedStates.reduce((accum, current) => {
          return {
            ...accum,
            [current.id]: current,
          };
        }, {});

        loader.init().then(monaco => {
          addAppStatesTypes({
            monaco,
            gqlSchema: undefined,
            appStatesDSL: newStates,
            resourceListDSL,
            assetListDSL: {},
            authResourceDSL,
          });
        });
      }
    }

    setMountedStates(appStatesDSL);
  }, [appStatesDSL, authResourceDSL, resourceListDSL, mountedStates, thereAreNewStates, scope]);
};
