var _a;
import { COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsIcon } from '../BuilderComponentsIcon';
import { MaterialBadgeSettings } from './MaterialBadge.settings';
export const MaterialBadge = {
    name: COMPONENT_DSL_NAMES.MaterialBadge,
    displayName: 'Badge',
    icon: 'badge',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Badge',
    },
    schema: {
        dndTargetPropName: 'children',
        presentations: [visibleByCondition],
        mainPropPath: {
            mainProp: 'Badge Content',
        },
        predefineds: {
            props: {
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                badgeContent: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.Text,
                            props: {
                                children: '1',
                            },
                        },
                    ],
                },
                children: {
                    nodes: [
                        {
                            name: COMPONENT_DSL_NAMES.BuilderComponentsIcon,
                            props: Object.assign(Object.assign({}, (_a = BuilderComponentsIcon.schema.predefineds) === null || _a === void 0 ? void 0 : _a.props), { name: 'Mail' }),
                        },
                    ],
                },
                classes: {},
                color: 'secondary',
                max: 99,
                overlap: 'rectangular',
                style: {
                    width: '24px',
                },
                showZero: false,
                variant: 'standard',
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { anchorOrigin: {
                type: COMPONENT_DSL_PROP_TYPES.object,
                props: {
                    vertical: {
                        type: COMPONENT_DSL_PROP_TYPES.enum,
                    },
                    horizontal: {
                        type: COMPONENT_DSL_PROP_TYPES.enum,
                    },
                },
            }, badgeContent: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, color: { type: COMPONENT_DSL_PROP_TYPES.string }, component: { type: COMPONENT_DSL_PROP_TYPES.elementType }, invisible: { type: COMPONENT_DSL_PROP_TYPES.boolean }, max: { type: COMPONENT_DSL_PROP_TYPES.number }, overlap: { type: COMPONENT_DSL_PROP_TYPES.enum }, showZero: { type: COMPONENT_DSL_PROP_TYPES.boolean }, variant: { type: COMPONENT_DSL_PROP_TYPES.enum } }),
    },
    settings: MaterialBadgeSettings,
};
