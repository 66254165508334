import * as ReactData from 'react';
import * as ApolloClientData from '@apollo/client';
import * as MaterialUiCoreData from '@mui/material';
import * as AxiosData from 'axios';
import * as LuxonData from 'luxon';
import * as NanoidData from 'nanoid';
import * as ReactRouterDomData from 'react-router-dom';
export const APP_ENGINE_PREDEFINED_IMPORTS = [
    {
        varName: 'React',
        importData: ReactData,
    },
    {
        varName: 'gql',
        importData: ApolloClientData.gql,
    },
    {
        varName: 'useLazyQuery',
        importData: ApolloClientData.useLazyQuery,
    },
    {
        varName: 'useQuery',
        importData: ApolloClientData.useQuery,
    },
    {
        varName: 'useMutation',
        importData: ApolloClientData.useMutation,
    },
    {
        varName: 'useApolloClient',
        importData: ApolloClientData.useApolloClient,
    },
    {
        varName: 'useReactiveVar',
        importData: ApolloClientData.useReactiveVar,
    },
    {
        varName: 'useSubscription',
        importData: ApolloClientData.useSubscription,
    },
    {
        varName: 'useHistory',
        importData: ReactRouterDomData.useHistory,
    },
    {
        varName: 'useLocation',
        importData: ReactRouterDomData.useLocation,
    },
    {
        varName: 'matchPath',
        importData: ReactRouterDomData.matchPath,
    },
    {
        varName: 'BrowserRouter',
        importData: ReactRouterDomData.BrowserRouter,
    },
    {
        varName: 'DateTime',
        importData: LuxonData.DateTime,
    },
    {
        varName: 'createTheme',
        importData: MaterialUiCoreData.createTheme,
    },
    {
        varName: 'CssBaseline',
        importData: MaterialUiCoreData.CssBaseline,
    },
    {
        varName: 'nanoid',
        importData: NanoidData.nanoid,
    },
    {
        varName: 'axios',
        importData: AxiosData,
    },
];
