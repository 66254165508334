var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef } from 'react';
export const FilePicker = forwardRef((_a, ref) => {
    var { 'data-test': dataTest, 'data-node-id': dataNodeID, 'data-node-render-path': dataRenderPath } = _a, otherFilePickerProps = __rest(_a, ['data-test', 'data-node-id', 'data-node-render-path']);
    // material-ui-dropzone doesn't support material v5 right now.
    // Look for a fork or other lib for FilePicker
    return null;
    /* return (
      <div
        ref={ref}
        data-test={dataTest}
        data-node-id={dataNodeID}
        data-node-render-path={dataRenderPath}
      >
        <DropzoneArea
          {...otherFilePickerProps}
          // temporary solution, waiting to fix: https://github.com/Yuvaleros/material-ui-dropzone/issues/265
          // right now maybe replace with user's own alert on change / on delete events
          showAlerts={false}
        />
      </div>
    ); */
});
