export const isString = (value) => typeof value === 'string';
export const isNumber = (value) => typeof value === 'number';
export const isBoolean = (value) => typeof value === 'boolean';
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value) => typeof value === 'function';
export const isObject = (value) => typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null &&
    !(value instanceof Promise);
export const isPromise = (value) => value instanceof Promise;
export const isArray = (value) => Array.isArray(value);
export const isNull = (value) => value === null;
export const isUndefined = (value) => value === undefined;
export const isNil = (value) => value === null || value === undefined;
export const isJsonString = (value) => {
    try {
        JSON.parse(value);
    }
    catch (e) {
        return false;
    }
    return true;
};
export const isNilOrEmptyArray = (value) => {
    if (isArray(value)) {
        return value.length === 0;
    }
    return isNil(value);
};
export const isEmptyString = (value) => {
    return value === '';
};
