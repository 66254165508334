import { COMPONENT_DSL_NAMES, STYLE_GUIDE_NAMES, STYLE_GUIDE_ACTIONS } from '../constants';
import { BASE_SCHEMA } from './common';
import actionSnackbar from './style-guide-dsl/SnackbarStyleGuide/action.json';
import basicSnackbar from './style-guide-dsl/SnackbarStyleGuide/basic.json';
export const SnackbarStyleGuide = {
    name: STYLE_GUIDE_NAMES.MuiSnackbar,
    componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSnackBar,
    dirName: 'SnackbarStyleGuide',
    title: 'Snackbar',
    classNames: [
        '.MuiSnackbar-root',
        '.MuiSnackbarContent-root',
        '.MuiSnackbarContent-message',
        '.MuiSnackbarContent-action',
    ],
    stories: [
        {
            title: 'Basic',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSnackbarContent,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        message: 'Snackbar content',
                    },
                },
            ],
            schemaDSLVersion: '0.19.7',
            isAppDSLCommitted: true,
            generatedAppDSL: basicSnackbar,
        },
        {
            title: 'Action',
            componentNameDSL: COMPONENT_DSL_NAMES.BuilderComponentsSnackbarContent,
            baseNodeListDSL: BASE_SCHEMA.baseNodeListDSL,
            rootID: BASE_SCHEMA.rootID,
            css: BASE_SCHEMA.css,
            actions: [STYLE_GUIDE_ACTIONS.editCSS],
            cases: [
                {
                    props: {
                        message: 'Snackbar content',
                        action: {
                            nodes: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                                    props: {
                                        children: {
                                            nodes: [
                                                {
                                                    name: COMPONENT_DSL_NAMES.Text,
                                                    props: {
                                                        children: 'Action',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
            schemaDSLVersion: '0.19.7',
            isAppDSLCommitted: true,
            generatedAppDSL: actionSnackbar,
        },
    ],
};
