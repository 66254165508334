import React, { useCallback, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { ContentCopy as ContentCopyIcon, Check as CheckIcon } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

type StyledButtonProps = {
  size?: string;
};

type CopyToClipboardProps = {
  text: string;
  size?: string;
};

const StyledButton = styled.button<StyledButtonProps>`
  padding: 0;

  width: ${({ size = '18px' }) => size};
  height: ${({ size = '18px' }) => size};

  border: none;
  outline: none;

  background-color: transparent;

  color: ${({ theme }) => `${theme.palette.grey[400]}`};

  :not(:disabled) {
    cursor: pointer;
  }

  :not(:disabled):hover {
    color: ${({ theme }) => `${theme.palette.grey[200]}`};
  }

  :not(:disabled):active {
    color: ${({ theme }) => `${theme.palette.grey[600]}`};
  }
`;

const FilledIcon = (icon: React.FC<SvgIconProps>) => styled(icon)`
  width: 100%;
  height: 100%;
`;

const FilledContentCopyIcon = FilledIcon(ContentCopyIcon);
const FilledCheckIconIcon = FilledIcon(CheckIcon);

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text, size }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleButtonClick = useCallback(async () => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  }, [text]);

  useEffect(() => {
    setIsCopied(false);
  }, [text]);

  return (
    <StyledButton size={size} onClick={handleButtonClick} disabled={isCopied}>
      {isCopied ? <FilledCheckIconIcon color="success" /> : <FilledContentCopyIcon />}
    </StyledButton>
  );
};
