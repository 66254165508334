import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsSnackbarContentSettings } from './BuilderComponentsSnackbarContent.settings';
export const BuilderComponentsSnackbarContent = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSnackbarContent,
    displayName: 'snackbarContent',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'SnackbarContent',
    },
    schema: {
        dndTargetPropName: 'children',
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsSnackBar,
                },
            },
        ],
        props: Object.assign(Object.assign({}, commonComponentProps), { message: { type: COMPONENT_DSL_PROP_TYPES.string }, action: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, backgroungColor: { type: COMPONENT_DSL_PROP_TYPES.string } }),
    },
    settings: BuilderComponentsSnackbarContentSettings,
};
