import { COMPONENT_DSL_NAMES } from '@builder/schemas';
import { Migration } from '../Migration';
class Migration_0_19_9 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.19.9';
    }
    getChangeList() {
        return ['Add FieldProps to FormRichTextEditor'];
    }
    migrateNodeDSL(nodeDSL) {
        var _a, _b, _c;
        switch (nodeDSL.name) {
            case COMPONENT_DSL_NAMES.BuilderComponentsFormRichtextEditor: {
                if (((_a = nodeDSL.props) === null || _a === void 0 ? void 0 : _a.data) && typeof ((_b = nodeDSL.props) === null || _b === void 0 ? void 0 : _b.data) === 'string') {
                    const matches = (_c = nodeDSL.props) === null || _c === void 0 ? void 0 : _c.data.match(/(\w+)\.value/);
                    if (matches && matches.length > 1) {
                        return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), { fieldProps: { name: matches[1] } }) });
                    }
                }
                return nodeDSL;
            }
            default: {
                return nodeDSL;
            }
        }
    }
}
export const migration_0_19_9 = new Migration_0_19_9();
