import React from 'react';

import styled from '@emotion/styled';
import { Box, List, Paper, Popper, PopperPlacementType, Typography, Fade } from '@mui/material';
import { bindPopover, PopupState } from 'material-ui-popup-state/hooks';

import { reactMemo } from '@builder/utils';

import { TeamMemberWithColor } from 'src/store';

import { ActiveMembers } from './ActiveMembers';

type AdditionalMembersPopperProps = {
  placement: PopperPlacementType;
  popupState: PopupState;
  maxAvatars: number;
  avatarSize: number;
  activeMembers: Record<string, TeamMemberWithColor>;
  additionalAvatarsPopupProps: Record<string, unknown>;
  userDetailsPopupProps: Record<string, unknown>;
  userDetailsOnMouseOver: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    member: TeamMemberWithColor,
  ) => void;
};

const StyledPopper = styled(Popper)`
  z-index: 1400;
`;

export const AdditionalMembersPopper: React.FC<AdditionalMembersPopperProps> = reactMemo(
  'AdditionalMembersPopper',
  ({
    placement,
    popupState,
    maxAvatars,
    avatarSize,
    additionalAvatarsPopupProps,
    activeMembers,
    userDetailsOnMouseOver,
    userDetailsPopupProps,
  }: AdditionalMembersPopperProps) => {
    return (
      <StyledPopper placement={placement} {...bindPopover(popupState)} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Typography variant="h6">Active Users</Typography>
              </Box>
              <List sx={{ bgcolor: 'background.paper' }}>
                <ActiveMembers
                  maxAvatars={maxAvatars}
                  avatarSize={avatarSize}
                  additionalAvatarsPopupProps={additionalAvatarsPopupProps}
                  activeMembers={activeMembers}
                  userDetailsPopupProps={userDetailsPopupProps}
                  userDetailsOnMouseOver={userDetailsOnMouseOver}
                />
              </List>
            </Paper>
          </Fade>
        )}
      </StyledPopper>
    );
  },
);
