import { createSelector } from 'reselect';
import { ASSET_TYPES } from '../constants';
import { getAssetFileArrayDSL } from './asset-list-selectors';
const getFontsDSL = (fontsDSL) => fontsDSL;
const getFontFamily = (_, props) => props.fontFamily;
const getGoogleFontsListFromApiResponse = (_, props) => props.allGoogleFontsList;
export const getCustomFontListDSL = createSelector(getFontsDSL, fontsDSL => { var _a; return (_a = fontsDSL.custom) !== null && _a !== void 0 ? _a : {}; });
export const getCustomFontArrayDSL = createSelector(getCustomFontListDSL, customFontListDSL => Object.values(customFontListDSL));
export const getCustomFontAssetArray = createSelector(getAssetFileArrayDSL, assetFileArrayDSL => assetFileArrayDSL.filter(assetFile => assetFile.type === ASSET_TYPES.font));
export const getCustomFontAssetNames = createSelector(getCustomFontAssetArray, customFontAssetArrayDSL => customFontAssetArrayDSL.map(customFontAssetDSL => customFontAssetDSL.name));
export const getGoogleFontListDSL = createSelector(getFontsDSL, fontListDSL => { var _a; return (_a = fontListDSL.google) !== null && _a !== void 0 ? _a : {}; });
export const getGoogleFontArrayDSL = createSelector(getGoogleFontListDSL, googleFontListDSL => Object.values(googleFontListDSL));
export const getSelectedGoogleFontsList = createSelector(getGoogleFontArrayDSL, getGoogleFontsListFromApiResponse, (googleFontArrayDSL, googleFontListFromApi) => {
    const googleFontsList = {};
    for (const font of googleFontArrayDSL) {
        if (googleFontListFromApi[font.fontFamily]) {
            googleFontsList[font.fontFamily] = googleFontListFromApi[font.fontFamily];
        }
    }
    return googleFontsList;
});
export const getGoogleFontByFontFamily = createSelector([getGoogleFontArrayDSL, getFontFamily], (googleFontArrayDSL, fontFamily) => googleFontArrayDSL.find(font => font.fontFamily === fontFamily));
