/* eslint-disable no-param-reassign */
import { assocPath, isEmpty, path } from 'ramda';

import { createAndRunFunction } from '@builder/app-engine/src/utils/createAndRunFunction';
import {
  NodeListDSL,
  NodeID,
  nodeListSelectors,
  NodeDSL,
  JSInjection,
  replaceState,
  StateDSL,
  NodePropValue,
  ComponentPropDSL,
  NodePropListValues,
  ComponentDSL,
  PropChecker,
  hasPropJsCode,
  ACTIONS_ARGS_SCHEMA,
  COMPONENT_DSL_PROP_TYPES,
  ActionPropValue,
  UpdateStateArgs,
  PropAssert,
} from '@builder/schemas';
import { checkAndTransformPropWithJsCode } from '@builder/schemas/dist/mjs/utils/prop-transformers/transformPropWithJsCode';
import { isString } from '@builder/utils';

import { DASHBOARD_EVENTS } from './constants';
import { DashboardContextUpdateEvents, EventNavigationType } from './types';

export const isValidTargetToMove = (
  nodeListDSL: NodeListDSL,
  sourceID: NodeID,
  targetID: NodeID,
): boolean => {
  if (sourceID === targetID) {
    return false;
  }

  // dropping over itself is forbidden
  if (nodeListSelectors.hasParentNode(nodeListDSL, { nodeID: targetID, parentID: sourceID })) {
    return false;
  }

  return true;
};

export const updateNode = ({
  nodeDSL,
  propPath,
  propValue,
  updatedNodeListDSL,
  event,
}: {
  nodeDSL: NodeDSL;
  propPath: string[];
  propValue: JSInjection;
  updatedNodeListDSL: NodeListDSL;
  event: Record<string, unknown>;
}): void => {
  updatedNodeListDSL[nodeDSL.id] = assocPath(
    ['props', ...propPath],
    replaceState(propValue, {
      newName: (event.stateDSL as StateDSL).name,
      previousName: (event as Record<string, string>).previousStateName,
    }),
    nodeDSL,
  );
};

export const updateNodeNavigation = ({
  nodeDSL,
  propPath,
  updatedNodeListDSL,
  event,
}: {
  nodeDSL: NodeDSL;
  propPath: string[];
  updatedNodeListDSL: NodeListDSL;
  event: Record<string, unknown>;
}): void => {
  const transformProp = (event.nodeAlias as string)
    ?.replace(/\s+(.)/g, (match, letra) => letra.toUpperCase())
    ?.trim()
    ?.replace(/^./, match => match.toLowerCase());

  // eslint-disable-next-line no-param-reassign
  updatedNodeListDSL[nodeDSL.id] = assocPath(
    ['props', ...propPath],
    {
      actionType: 'changePage',
      args: {
        url: (transformProp ? `{{ router['${transformProp}']?.path }}` : '') as string,
      },
    },
    nodeDSL,
  );
};

export type EventDialogDSL = {
  id: string;
  stateDSL: {
    id: string;
    name: string;
  };
  previousStateName: string;
};

type ForEachNodePropsArgs = {
  propName: string;
  propPath: string[];
  propValue: NodePropValue<NodeID>;
  propDSL: ComponentPropDSL;
  propListValues: NodePropListValues<NodeID>;
  propListDSL: ComponentDSL['schema']['props'];
  nodeDSL: NodeDSL;
};

export const updateNodeIfJsCode = (
  nodePropsArgs: ForEachNodePropsArgs,
  nodeDSL: NodeDSL,
  updatedNodeListDSL: NodeListDSL,
  event:
    | Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateUpdate }>
    | Extract<
        DashboardContextUpdateEvents,
        { type: typeof DASHBOARD_EVENTS.stateUpdateTypeDialog }
      >,
): void => {
  if (hasPropJsCode(nodePropsArgs.propValue)) {
    const propPath = [...nodePropsArgs.propPath, nodePropsArgs.propName];

    updateNode({
      nodeDSL,
      propPath,
      propValue: nodePropsArgs.propValue,
      updatedNodeListDSL,
      event,
    });
  }
};

export const updateNavigationEvent = (
  nodePropsArgs: ForEachNodePropsArgs,
  nodeDSL: NodeDSL,
  updatedNodeListDSL: NodeListDSL,
  event: EventNavigationType & { previusPath?: string },
  nodesUpdated: NodeDSL[],
): void => {
  const updateProp = (nodePropsArgs?.propValue as ActionPropValue)?.args?.url;
  if (isString(updateProp)) {
    const { hasValidHandlebarJS, transformedValue } = checkAndTransformPropWithJsCode(updateProp);

    if (hasValidHandlebarJS && transformedValue && event.previusPath) {
      const propValue = createAndRunFunction(
        `return ${transformedValue}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).appRuntimeState,
      ) as string;

      if (propValue === event.previusPath && nodePropsArgs.propValue) {
        const propPath = [...nodePropsArgs.propPath, nodePropsArgs.propName];
        nodesUpdated.push(nodeDSL);

        updateNodeNavigation({
          nodeDSL,
          propPath,
          updatedNodeListDSL,
          event,
        });
      }
    }
  }
};

export const updateStringArguments = (
  nodePropsArgs: ForEachNodePropsArgs,
  nodeDSL: NodeDSL,
  updatedNodeListDSL: NodeListDSL,
  event:
    | Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateUpdate }>
    | Extract<
        DashboardContextUpdateEvents,
        { type: typeof DASHBOARD_EVENTS.stateUpdateTypeDialog }
      >,
): void => {
  if (PropChecker.Value.isActionPropWithArgs(nodePropsArgs.propValue)) {
    const argsSchema = ACTIONS_ARGS_SCHEMA[nodePropsArgs.propValue.actionType];
    const hasArguments = Boolean(argsSchema) && !isEmpty(argsSchema);

    if (!hasArguments) {
      return;
    }

    const isUpdateStateEvent = Object.keys(argsSchema).includes('stateArgs');

    const argumentNames = Object.entries(argsSchema)
      .filter(
        ([_, argSchema]) =>
          argSchema.type ===
          (isUpdateStateEvent ? COMPONENT_DSL_PROP_TYPES.object : COMPONENT_DSL_PROP_TYPES.string),
      )
      .map(([argName, _]) => argName);

    argumentNames.forEach(argumentName => {
      const argValue = path<NodePropValue>(
        ['props', ...nodePropsArgs.propPath, nodePropsArgs.propName, 'args', argumentName],
        nodeDSL,
      );

      if (!argValue) {
        return;
      }

      const propPath = [...nodePropsArgs.propPath, nodePropsArgs.propName, 'args', argumentName];

      if (isUpdateStateEvent) {
        const newState = event.stateDSL;
        const oldState = argValue as UpdateStateArgs;
        if (
          oldState.stateName === newState.name ||
          oldState.stateName === event.previousStateName
        ) {
          if (oldState.stateName !== newState.name) {
            updatedNodeListDSL[nodeDSL.id] = assocPath(
              ['props', ...propPath],
              { ...oldState, stateName: newState.name },
              nodeDSL,
            );
          }

          if (oldState.stateType !== newState.type) {
            updatedNodeListDSL[nodeDSL.id] = assocPath(
              ['props', ...propPath],
              { stateID: newState.id, stateName: newState.name, stateType: newState.type },
              nodeDSL,
            );
          }
        }
      } else {
        PropAssert.Value.assertIsStringProp(argValue);

        updateNode({
          nodeDSL,
          propPath,
          propValue: argValue as JSInjection,
          updatedNodeListDSL,
          event,
        });
      }
    });
  }
};

export const updateIteratorData = (
  nodeDSL: NodeDSL,
  event:
    | Extract<DashboardContextUpdateEvents, { type: typeof DASHBOARD_EVENTS.stateUpdate }>
    | Extract<
        DashboardContextUpdateEvents,
        { type: typeof DASHBOARD_EVENTS.stateUpdateTypeDialog }
      >,
  updatedNodeListDSL: NodeListDSL,
): void => {
  if (nodeDSL.iterator && hasPropJsCode(nodeDSL.iterator.data)) {
    // eslint-disable-next-line no-param-reassign
    updatedNodeListDSL[nodeDSL.id] = assocPath(
      ['iterator', 'data'],
      replaceState(nodeDSL.iterator.data, {
        newName: event.stateDSL.name,
        previousName: event.previousStateName,
      }),
      nodeDSL,
    );
  }
};
