import { appConfigurationSelectors } from '../selectors';
export const nameUniqueValidator = ({ appDSL, initialValueName, section, scope, parentID, }) => (value) => {
    if (initialValueName === value) {
        return undefined;
    }
    const isNameUsedInDSL = appConfigurationSelectors.isNameUsedInDSL({
        appDSL,
    }, {
        name: value,
        parentID: parentID,
        scope: scope,
    });
    if (isNameUsedInDSL) {
        return `${section} should have the unique name`;
    }
};
