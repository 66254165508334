import { COMPONENT_DSL_INTERACTION_TYPES, COMPONENT_DSL_NAMES, COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, } from '../../../constants';
import { visibleByCondition } from '../../common/commonPresentations';
import { commonComponentProps } from '../../common/commonProps';
import { MaterialCardMediaSettings } from './MaterialCardMedia.settings';
export const MaterialCardMedia = {
    name: COMPONENT_DSL_NAMES.MaterialCardMedia,
    displayName: 'Card Media',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    source: {
        importName: '@mui/material',
        componentName: 'CardMedia',
    },
    schema: {
        interaction: COMPONENT_DSL_INTERACTION_TYPES.onlyRemovable,
        accessibility: {
            hideOverlay: true,
        },
        presentations: [visibleByCondition],
        props: Object.assign(Object.assign({}, commonComponentProps), { image: { type: COMPONENT_DSL_PROP_TYPES.asset }, title: { type: COMPONENT_DSL_PROP_TYPES.string }, style: { type: COMPONENT_DSL_PROP_TYPES.object } }),
    },
    settings: MaterialCardMediaSettings,
};
