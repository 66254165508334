import { pipe, values } from 'ramda';
import { COMPONENT_DSL_NAMES, COMPONENT_SETTING_TYPES, COMPONENT_SETTING_VIEWS, } from '../../../constants';
import { BASIC_PROPS_TOOLTIP, BASIC_STYLE_TOOLTIP, NS_ID, getBasicPropsSettings, pickSettings, } from '../../common';
import { getBackgroundSection, getBorderSection, getCSSSection, getCursorSection, getDivider, getTypographySection, } from '../../common/settings';
import { getBuilderComponentsButtonSettings } from '../BuilderComponentsButton/BuilderComponentsButton.settings';
export const getBuilderComponentsSnackbarContent = (prefixPath = []) => ({
    properties: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.properties.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.properties.basic,
                    titleDescription: BASIC_PROPS_TOOLTIP,
                    children: [
                        {
                            componentView: COMPONENT_SETTING_VIEWS.text,
                            type: COMPONENT_SETTING_TYPES.prop,
                            keyPath: [...prefixPath, 'message'],
                            name: 'message',
                            label: 'message',
                        },
                        {
                            type: COMPONENT_SETTING_TYPES.node,
                            keyPath: ['action'],
                            label: 'Action',
                            name: 'action',
                            items: [
                                {
                                    name: COMPONENT_DSL_NAMES.BuilderComponentsButton,
                                    settings: getBuilderComponentsButtonSettings(),
                                },
                            ],
                        },
                    ],
                },
                getDivider(),
                ...getBasicPropsSettings(),
                getDivider(),
            ],
        },
    ],
    style: [
        {
            type: COMPONENT_SETTING_TYPES.rootSection,
            name: NS_ID.style.root,
            children: [
                {
                    type: COMPONENT_SETTING_TYPES.section,
                    title: 'Basic',
                    name: NS_ID.style.basic,
                    titleDescription: BASIC_STYLE_TOOLTIP,
                    children: [
                        getTypographySection(prefixPath),
                        getDivider(),
                        getBackgroundSection(prefixPath),
                        getDivider(),
                        getBorderSection({ prefixPath, systemPrefixPath: prefixPath }),
                        getDivider(),
                        getCursorSection(prefixPath),
                        getDivider(),
                        getCSSSection(prefixPath),
                    ],
                },
            ],
        },
    ],
    events: [],
});
export const BuilderComponentsSnackbarContentSettings = getBuilderComponentsSnackbarContent();
export const getCommonSnackbarContentActionSettings = () => pipe(pickSettings([
    [NS_ID.properties.root, NS_ID.properties.basic, 'message'],
    [NS_ID.properties.root, NS_ID.properties.basic, 'action'],
], {
    withStructure: false,
}))(values(BuilderComponentsSnackbarContentSettings).flat());
