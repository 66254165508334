import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import debounce from 'lodash.debounce';
import { values } from 'ramda';

import { ComponentSettingIteratorDSL, IteratorDSL, NodeDSL } from '@builder/schemas';
import { isUndefined, memo } from '@builder/utils';

import { JSONViewEditor, NumberViewEditor } from '../../setting-views';
import {
  ARRAY,
  AppRuntimeState,
  CODE,
  GlobalAndLocalStates,
  INPUT_TYPES,
  LABELS_SELECT,
  MetaStateWhidPRedefinedValues,
  NO_OPTIONS_TEXT,
  OBJECT,
  PLACEHOLDER_DATA_TYPE,
  PLACEHOLDER_SELECT_OPT,
  REQUEST,
  STATE,
  getAllArrayAndObjectsStates,
  getFirstValues,
} from '../../utils';
import { getAllQueryStates } from '../../utils/iterator/getAllQueryStates';
import { useAppDSL, useUserAppRuntimeState } from 'src/providers';
import { Autocomplete, TextField } from 'src/shared/components';

type IteratorTypeProps = {
  setting: ComponentSettingIteratorDSL;
  targetNodeDSL: NodeDSL;
  onChangeIterator: (propValue: IteratorDSL) => void;
  'data-test'?: string;
};

export const IteratorType = memo(
  'IteratorType',
  ({
    setting,
    targetNodeDSL,
    onChangeIterator,
    'data-test': dataTest,
  }: IteratorTypeProps): JSX.Element => {
    const appRuntimeState = useUserAppRuntimeState();

    const appRuntimeStates: GlobalAndLocalStates = useMemo(
      () =>
        appRuntimeState
          ? {
              ...(appRuntimeState.globalState as AppRuntimeState),
              ...(appRuntimeState.localStates as AppRuntimeState),
            }
          : {},
      [appRuntimeState],
    );
    const appDSL = useAppDSL();
    const localStates = values(appDSL?.nodes)?.reduce((accum, node) => {
      if (!('context' in node)) {
        return accum;
      }

      return { ...accum, ...node.context };
    }, {});

    const { id: nodeID, iterator } = targetNodeDSL;
    const { dataLabel, itemNameLabel, skipDebounce } = setting;
    const inputInitialType = isEmpty(targetNodeDSL?.iterator?.data) ? '' : 'code';
    const [inputType, setInputType] = useState(targetNodeDSL?.iterator?.type || inputInitialType);
    const [temporalyNameValue, setTemporalyNameValue] = useState(iterator?.name);
    const isStateOrRequest = inputType === STATE || inputType === REQUEST;
    const [selectedState, setSelectedState] = useState(
      getFirstValues({
        stateAccessPath: targetNodeDSL?.iterator?.stateAccessPath || [],
        position: 0,
        isStateOrRequest,
      }),
    );
    const [selectedStateFirstChild, setSelectedStateFirstChild] = useState(
      getFirstValues({
        stateAccessPath: targetNodeDSL?.iterator?.stateAccessPath || [],
        position: 1,
        isStateOrRequest,
        selectedState,
      }),
    );

    const [selectedStateSecondChild, setSelectedStateSecondChild] = useState(
      getFirstValues({
        stateAccessPath: targetNodeDSL?.iterator?.stateAccessPath || [],
        position: 2,
        isStateOrRequest,
        selectedStateFirstChild,
      }),
    );

    const [stateAccessPath, setStateAccessPath] = useState(
      isStateOrRequest && targetNodeDSL?.iterator?.stateAccessPath
        ? [...targetNodeDSL?.iterator?.stateAccessPath]
        : [],
    );

    const onChangeIteratorData = useCallback(
      (data: IteratorDSL['data'], accessPath?) => {
        onChangeIterator({
          data:
            isStateOrRequest && !!accessPath.length ? `{{${accessPath.join('?.')} || []}}` : data,
          name: iterator?.name || 'item',
          stateAccessPath: accessPath,
          looperLimitInDevMode: iterator?.looperLimitInDevMode || 0,
          type: inputType,
        });
      },
      [
        iterator?.name,
        onChangeIterator,
        inputType,
        isStateOrRequest,
        iterator?.looperLimitInDevMode,
      ],
    );

    const onChangeIteratorType = (dataType: string | undefined) => {
      onChangeIterator({
        data: iterator?.data || [],
        name: iterator?.name || 'item',
        stateAccessPath: [],
        type: dataType,
      });
    };

    const resetIteratorType = () => {
      onChangeIterator({
        data: [],
        name: iterator?.name || 'item',
        stateAccessPath: [],
        type: undefined,
      });
    };

    const onChangeIteratorName = useCallback((value: string) => {
      setTemporalyNameValue(value);
    }, []);

    const handleBlur = useCallback(() => {
      onChangeIterator({
        data: iterator?.data || [],
        name: temporalyNameValue || '',
        looperLimitInDevMode: iterator?.looperLimitInDevMode || 0,
      });
    }, [iterator?.data, iterator?.looperLimitInDevMode, onChangeIterator, temporalyNameValue]);

    const onChangeIteratorLimitInDevMode = useCallback(
      (value: number) => {
        onChangeIterator({
          data: iterator?.data || [],
          name: iterator?.name || '',
          looperLimitInDevMode: value || 0,
        });
      },
      [iterator?.data, iterator?.name, onChangeIterator],
    );

    const allArrayAndObjectStates = getAllArrayAndObjectsStates(
      {
        ...localStates,
        ...appDSL?.states,
      },
      appRuntimeStates,
    );
    const allQueryStates = getAllQueryStates(
      { ...localStates, ...appDSL?.states },
      appRuntimeStates,
    );
    const isRequest = inputType === REQUEST;
    const selectedStatesByType = isRequest ? allQueryStates : allArrayAndObjectStates;
    const statesAutocompleteOptionFormat = values(selectedStatesByType).map(state => {
      return {
        value: state.name,
        label: state.name,
      };
    });
    const hasNameError = !temporalyNameValue || temporalyNameValue === '';
    const helperTextName = hasNameError ? 'Iterator name should be specified' : '';

    return (
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            value={inputType}
            label="Loop Data"
            placeHolder={PLACEHOLDER_DATA_TYPE}
            onChange={option => {
              setSelectedStateFirstChild('');
              setSelectedStateSecondChild('');
              setStateAccessPath([]);
              setInputType(option);

              if (isUndefined(option)) {
                setInputType('');
                resetIteratorType();
              }

              if (option === CODE) {
                onChangeIteratorType(CODE);
              }
            }}
            options={INPUT_TYPES}
            data-test={`${dataTest}.data.type`}
          />
        </Grid>
        <If condition={inputType === CODE}>
          <Grid item xs={12}>
            <JSONViewEditor
              label={dataLabel}
              propValue={iterator?.data}
              onChangePropValue={debounce(option => onChangeIteratorData(option), 1000)}
              nodeID={nodeID}
              skipDebounce={skipDebounce}
              data-test={`${dataTest}.data`}
            />
          </Grid>
        </If>
        <If condition={isStateOrRequest}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              value={selectedState}
              label={isRequest ? LABELS_SELECT.request : LABELS_SELECT.state}
              placeHolder={PLACEHOLDER_SELECT_OPT}
              onChange={option => {
                const path = isRequest ? `${option}?.data` : `${option}?.value`;
                setStateAccessPath([path]);

                if (
                  selectedStatesByType[option]?.type === ARRAY ||
                  isEmpty(selectedStatesByType[option]?.children?.defaultValue)
                ) {
                  onChangeIteratorData(
                    isRequest
                      ? (selectedStatesByType[option] as MetaStateWhidPRedefinedValues)?.data
                      : (selectedStatesByType[option] as MetaStateWhidPRedefinedValues)?.value,
                    [path],
                  );
                }

                setSelectedState(option);
              }}
              options={statesAutocompleteOptionFormat}
              noOptionsText={NO_OPTIONS_TEXT}
              data-test={`${dataTest}.data.arrayOrObject`}
            />
          </Grid>
        </If>
        <If
          condition={
            inputType !== CODE &&
            selectedStatesByType[selectedState]?.type === OBJECT &&
            !isEmpty(selectedStatesByType[selectedState]?.children?.defaultValue)
          }
        >
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              value={selectedStateFirstChild}
              label="Select Array"
              placeHolder={PLACEHOLDER_SELECT_OPT}
              onChange={option => {
                setStateAccessPath([stateAccessPath[0], option]);

                if (
                  selectedStatesByType[selectedState]?.children?.defaultValue[option]?.type ===
                  ARRAY
                ) {
                  onChangeIteratorData(
                    selectedStatesByType[selectedState]?.children?.defaultValue[option]?.value,
                    [stateAccessPath[0], option],
                  );
                }

                setSelectedStateSecondChild('');
                setSelectedStateFirstChild(option);
              }}
              options={values(selectedStatesByType[selectedState]?.children?.defaultValue).map(
                state => {
                  return {
                    value: state.name,
                    label: state.name,
                  };
                },
              )}
              noOptionsText={NO_OPTIONS_TEXT}
              data-test={`${dataTest}.data.arrayOrObject.value`}
            />
          </Grid>
        </If>
        <If
          condition={
            inputType !== CODE &&
            selectedStatesByType[selectedState]?.children?.defaultValue[selectedStateFirstChild]
              ?.type === OBJECT
          }
        >
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              value={selectedStateSecondChild}
              label="Select Array"
              onChange={option => {
                setStateAccessPath([stateAccessPath[0], stateAccessPath[1], option]);

                if (
                  selectedStatesByType[selectedState]?.children?.defaultValue[
                    selectedStateFirstChild
                  ]?.children[option]?.type === ARRAY
                ) {
                  onChangeIteratorData(
                    selectedStatesByType[selectedState]?.children?.defaultValue[
                      selectedStateFirstChild
                    ]?.children[option]?.value,
                    [stateAccessPath[0], stateAccessPath[1], option],
                  );
                }

                setSelectedStateSecondChild(option);
              }}
              options={values(
                selectedStatesByType[selectedState]?.children?.defaultValue[selectedStateFirstChild]
                  ?.children,
              ).map(state => {
                return {
                  value: state.name,
                  label: state.name,
                };
              })}
              noOptionsText={NO_OPTIONS_TEXT}
              data-test={`${dataTest}.data.arrayOrObject.value.value`}
            />
          </Grid>
        </If>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={itemNameLabel}
            value={temporalyNameValue}
            onDebounceChange={onChangeIteratorName}
            data-test={`${dataTest}.name`}
            error={hasNameError}
            helperText={helperTextName}
            skipDebounce={false}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <NumberViewEditor
            min={0}
            showFx={false}
            label="Limit the number of elements during design"
            propValue={iterator?.looperLimitInDevMode ?? 5}
            onChangePropValue={val => onChangeIteratorLimitInDevMode(val)}
            skipDebounce={false}
          />
        </Grid>
      </Grid>
    );
  },
);
