var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import { useAuth } from '@8base-react/auth';
import { Helmet } from 'react-helmet';
import { Route as ReactRoute, Redirect as ReactRedirect, } from 'react-router-dom';
export const Route = forwardRef((_a, ref) => {
    var { authAccess, title, metaTags = [], children } = _a, rest = __rest(_a, ["authAccess", "title", "metaTags", "children"]);
    const { isAuthorized } = useAuth();
    if (isAuthorized && authAccess === 'public') {
        return _jsx(ReactRedirect, { to: "/" });
    }
    if (!isAuthorized && authAccess === 'private') {
        return _jsx(ReactRedirect, { to: "/login" });
    }
    return (_jsxs(ReactRoute, Object.assign({}, rest, { children: [_jsxs(Helmet, { children: [title && _jsx("title", { children: title }), metaTags.map(metaTag => {
                        switch (metaTag.name) {
                            case 'charset': {
                                return _jsx("meta", { charSet: metaTag.content });
                            }
                            default: {
                                return _jsx("meta", { name: metaTag.name, content: metaTag.content });
                            }
                        }
                    })] }), children] })));
});
