import { useEffect, useState } from 'react';

import _ from 'lodash';

import { PredefinedStates } from '@builder/app-engine';
import {
  COMPONENT_DSL_PROP_TYPES,
  ComponentDSL,
  NodeDSL,
  nodeListSelectors,
} from '@builder/schemas';
import { getNodeSchema } from '@builder/schemas/dist/mjs/selectors/app-nodes-schema-selectors';
import { isString } from '@builder/utils';

import { AutoCompleteOptions, PAGE_LABEL } from 'src/features/node-settings/types';
import {
  useComponentListDSL,
  useNodeAppRuntimeState,
  useNodeListDSL,
  useUserAppRuntimeState,
} from 'src/providers';

type StateResult = {
  type: string;
  variant: string;
  value?: boolean | string | Record<string, unknown> | never[] | number;
  setProperty?: void;
  hasItemInArray?: void;
};

const TYPE_FIELD = {
  object: 'object',
  array: 'array',
  string: 'string',
  number: 'number',
  enum: 'enum',
  stringOrNumber: 'stringOrNumber',
};

const NO_PAGES_NAMES = ['list', 'currentRoute', 'navigate'];

type NodeSchema = {
  nodeDSL: NodeDSL;
  componentDSL: ComponentDSL;
  schema: {
    props: Record<string, { type: string }>;
  };
};

type ActivePropType = { literal: string; page: string; code: string; state: string };

export const useJsInjectionOptions = ({
  ACTIVEPROP,
  setMode,
  disableFxAction,
  onChangePropValue,
  showFx,
  nodeID,
  label,
  typeField,
}: {
  ACTIVEPROP: ActivePropType;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  disableFxAction: (() => void) | undefined;
  onChangePropValue: (propValue: never) => void;
  showFx: boolean;
  nodeID: string | undefined;
  label: string;
  typeField: string;
}): {
  enableFx: (activeProp: string | undefined) => void;
  getAutocompleteLabel: (baseLabel: string, additionalLabel: string) => string;
  getNoOptionText: (baseLabel: string) => string;
  pagesOptions: AutoCompleteOptions[];
  statesOptions: AutoCompleteOptions[];
  isPageProp: boolean;
  statesNames: string[];
} => {
  const nodeListDSL = useNodeListDSL();
  const componentListDSL = useComponentListDSL();
  const { localState, globalState } = useNodeAppRuntimeState(nodeID);
  const { predefinedState = {} as PredefinedStates } = useUserAppRuntimeState();
  const allStates = { ...globalState, ...localState } as Record<string, StateResult>;
  const nodeSchema: NodeSchema | undefined = nodeID
    ? (getNodeSchema({ nodeListDSL, componentListDSL }, { nodeID }) as NodeSchema)
    : undefined;
  const schemaTypeField =
    nodeSchema?.componentDSL?.schema?.props?.[label?.toLowerCase()]?.type || typeField;
  const fieldPropType =
    schemaTypeField !== COMPONENT_DSL_PROP_TYPES.reactNode ? schemaTypeField : typeField;

  const isPageProp = label === PAGE_LABEL;
  const statesNames = Object.entries(allStates).map(([key]) => {
    return `{{   ${key}.value   }}`;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allRoutesNodes = nodeListSelectors.getAllRouteNodes(nodeListDSL) ?? [];
  const [pagesOptions, setPagesOptions] = useState<AutoCompleteOptions[]>([]);

  useEffect(() => {
    if (predefinedState?.router) {
      const newPagesOptions =
        Object.entries(predefinedState.router)
          ?.filter(([key]) => !NO_PAGES_NAMES.includes(key))
          ?.map(([key, value]) => {
            const nodeRote = (allRoutesNodes as NodeDSL[]).find(
              node => node?.props?.path === (value as NodeDSL)?.path,
            );

            const nodeName = nodeRote?.alias?.replace('Wrapper', '') || '';

            return {
              value: key ? `{{ router['${key}']?.path }}` : '',
              label: nodeName ?? '',
            };
          }) ?? [];

      if (!_.isEqual(newPagesOptions, pagesOptions)) {
        setPagesOptions(newPagesOptions);
      }
    }
  }, [allRoutesNodes, pagesOptions, predefinedState.router]);

  const enableFx = (activeProp: string | undefined): void => {
    if (activeProp === ACTIVEPROP.code) {
      setMode(ACTIVEPROP.state);

      if (disableFxAction) {
        disableFxAction();
      } else {
        onChangePropValue(undefined as never);
      }

      return;
    }

    if (activeProp === ACTIVEPROP.literal && showFx) {
      if (isPageProp) {
        setMode(ACTIVEPROP.page);
      } else {
        setMode(ACTIVEPROP.code);
      }

      return;
    }

    if (activeProp === ACTIVEPROP.page && showFx) {
      setMode(ACTIVEPROP.code);
      onChangePropValue(undefined as never);
      return;
    }

    if (activeProp === ACTIVEPROP.state && showFx) {
      setMode(ACTIVEPROP.literal);
      onChangePropValue(undefined as never);
    }
  };

  const getAutocompleteLabel = (baseLabel: string, additionalLabel: string) => {
    return `${baseLabel} (${additionalLabel})`;
  };

  const getNoOptionText = (baseLabel: string) => {
    return `No ${fieldPropType} ${baseLabel} available`;
  };

  const statesOptions: AutoCompleteOptions[] = Object.entries(allStates)
    .filter(([, state]) => {
      const isObjectState = state?.setProperty;
      const isArrayState = state?.hasItemInArray;
      const isStringState = isString(state?.value) && !isObjectState && !isArrayState;
      switch (fieldPropType) {
        case TYPE_FIELD.object:
          return isObjectState;
        case TYPE_FIELD.array:
          return isArrayState;
        case TYPE_FIELD.string:
        case TYPE_FIELD.enum:
          return isStringState;
        case TYPE_FIELD.stringOrNumber:
          return isStringState || typeof state?.value === TYPE_FIELD.number;
        default:
          return typeof state?.value === fieldPropType;
      }
    })
    .map(([key]) => ({
      value: `{{   ${key}.value   }}`,
      label: key,
    }));

  return {
    enableFx,
    getAutocompleteLabel,
    getNoOptionText,
    pagesOptions,
    statesOptions,
    isPageProp,
    statesNames,
  };
};
