import { COMPONENT_DSL_PROP_TYPES, COMPONENT_DSL_TYPES, MENU_GROUP_NAMES, COMPONENT_DSL_NAMES, COMPONENT_DSL_KINDS, COMPONENT_DSL_CALLBACK_TYPES, STATE_SCOPES, STATE_TYPES, STATE_BOOLEAN_VARIANTS, COMPONENT_DSL_RULES_INTERACTION_TYPES, COMPONENT_DSL_INSIDE_ONLY_TYPES, } from '../../../constants';
import { commonComponentProps } from '../../common/commonProps';
import { BuilderComponentsSnackbarSettings } from './BuilderComponentsSnackbar.settings';
export const BuilderComponentsSnackbar = {
    name: COMPONENT_DSL_NAMES.BuilderComponentsSnackBar,
    displayName: 'Snackbar',
    icon: 'snackbar',
    type: COMPONENT_DSL_TYPES.reactImportComponent,
    componentListGroupName: MENU_GROUP_NAMES.other,
    kind: COMPONENT_DSL_KINDS.modal,
    source: {
        importName: '@builder/components',
        codeEngineImportName: 'shared/components',
        componentName: 'Snackbar',
    },
    schema: {
        accessibility: {
            disableCreatingSymbol: true,
            hideOverlayForNonSelectableElement: true,
        },
        parentContraints: [
            {
                type: COMPONENT_DSL_RULES_INTERACTION_TYPES.insideOnly,
                container: {
                    type: COMPONENT_DSL_INSIDE_ONLY_TYPES.directParent,
                    name: COMPONENT_DSL_NAMES.BuilderComponentsRouteLayout,
                },
            },
        ],
        predefineds: {
            states: [
                {
                    scope: STATE_SCOPES.local,
                    type: STATE_TYPES.boolean,
                    variant: STATE_BOOLEAN_VARIANTS.trueFalse,
                    name: 'snackbar',
                    defaultValue: false,
                },
            ],
            props: {
                open: '{{ __STATES__.snackbar.value }}',
                vertical: 'bottom',
                horizontal: 'left',
                autoHideDuration: 3000,
                transition: 'Fade',
                message: 'Snackbar Content',
                onClose: {
                    actionType: 'customCode',
                    args: {
                        code: '__STATES__.snackbar.setValue(false)',
                    },
                },
            },
        },
        props: Object.assign(Object.assign({}, commonComponentProps), { open: { type: COMPONENT_DSL_PROP_TYPES.boolean }, vertical: { type: COMPONENT_DSL_PROP_TYPES.enum }, horizontal: { type: COMPONENT_DSL_PROP_TYPES.enum }, autoHideDuration: { type: COMPONENT_DSL_PROP_TYPES.number }, resumeHideDuration: { type: COMPONENT_DSL_PROP_TYPES.number }, transitionDuration: { type: COMPONENT_DSL_PROP_TYPES.number }, disableWindowBlurListener: { type: COMPONENT_DSL_PROP_TYPES.boolean }, transition: { type: COMPONENT_DSL_PROP_TYPES.string }, message: { type: COMPONENT_DSL_PROP_TYPES.string }, action: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, key: { type: COMPONENT_DSL_PROP_TYPES.string }, children: { type: COMPONENT_DSL_PROP_TYPES.reactNode }, role: { type: COMPONENT_DSL_PROP_TYPES.string }, onClose: {
                type: COMPONENT_DSL_PROP_TYPES.action,
                body: COMPONENT_DSL_CALLBACK_TYPES.code,
                args: [
                    {
                        name: 'event',
                        path: [0],
                    },
                    {
                        name: 'reason',
                        path: [1],
                    },
                ],
            } }),
    },
    settings: BuilderComponentsSnackbarSettings,
};
