import React, { useMemo } from 'react';

import { path } from 'ramda';

import {
  NodeListDSL,
  ComponentSettingDSL,
  ComponentSettingPopupDSL,
  componentSettingSelectors,
  nodeListSelectors,
  NodePropValue,
} from '@builder/schemas';

import { SettingsListItem } from '../../common/components';
import { getTargetNodeID } from 'src/store/dashboard/utils';

type PopupTypeProps = {
  setting: ComponentSettingPopupDSL;
  isActive: boolean;
  'data-test': string;

  children: (args: { settings: ComponentSettingDSL[] }) => React.ReactNode;
};

export const checkIsPopupTypeActive = ({
  settings,
  selectedNodeID,
  nodeListDSL,
}: {
  settings: ComponentSettingDSL[];
  selectedNodeID: string;
  nodeListDSL: NodeListDSL;
}): boolean => {
  let isActive = false;

  settings.forEach(currentSetting => {
    const keyPath = componentSettingSelectors.getSettingsKeyPath(currentSetting);
    const nodePath = componentSettingSelectors.getSettingsNodePath(currentSetting);
    const targetNodeID = getTargetNodeID({
      nodeID: selectedNodeID,
      nodePath,
      nodeListDSL,
    });
    const targetNode = nodeListSelectors.getNodeDSL(nodeListDSL, { nodeID: targetNodeID });
    const keyValue = path(keyPath, targetNode.props) as NodePropValue | null;

    if (keyValue) {
      isActive = true;
    }
  });

  return isActive;
};

export const PopupType: React.FC<PopupTypeProps> = ({
  setting,
  isActive,
  children,
  'data-test': dataTest,
}) => {
  const startIconProps = useMemo(() => {
    return setting.icon
      ? {
          name: setting.icon,
          isTextIcon: setting.isTextIcon,
          isActive,
        }
      : undefined;
  }, [isActive, setting]);

  return (
    <SettingsListItem
      startIconProps={startIconProps}
      label={setting.label}
      title={setting.title}
      data-test={dataTest}
    >
      {children({ settings: setting.children })}
    </SettingsListItem>
  );
};
