import { CSSParser } from '@builder/utils';
import { Migration } from '../Migration';
// the reason why I create new variable in the migration file and don't use already existed one
// it's because this variable shouldn't be updated in future cause migration should always work the same way
const DEFAULT_CSS_COLOR_VARIABLES_NAME = {
    primaryMain: '--primaryMain',
    primaryLight: '--primaryLight',
    primaryDark: '--primaryDark',
    secondaryMain: '--secondaryMain',
    secondaryLight: '--secondaryLight',
    secondaryDark: '--secondaryDark',
    backgroundDefault: '--backgroundDefault',
    backgroundSurface: '--backgroundSurface',
    errorMain: '--errorMain',
    primaryContrastText: '--primaryContrastText',
    secondaryContrastText: '--secondaryContrastText',
    textBackgroundPrimary: '--textBackgroundPrimary',
    erorContrastText: '--erorContrastText',
};
const DEFAULT_CSS_COLOR_VARIABLES_VALUES = {
    primaryMain: '#1976d2',
    secondaryMain: '#9c27b0',
    backgroundDefault: '#fff',
    backgroundSurface: '#fff',
    errorMain: '#d32f2f',
    textBackgroundPrimary: 'rgba(0, 0, 0, 0.87)',
};
class Migration_0_14_0 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.14.0';
    }
    getChangeList() {
        return ['Add default css color variables to the theme'];
    }
    migrateAppDSL(appDSL) {
        var _a;
        const { theme, __legacyTheme: legacyTheme } = appDSL;
        const { primary, secondary, background, error, text } = (legacyTheme === null || legacyTheme === void 0 ? void 0 : legacyTheme.palette) || {};
        const cssVariablesValues = {
            primaryMain: (primary === null || primary === void 0 ? void 0 : primary.main) || DEFAULT_CSS_COLOR_VARIABLES_VALUES.primaryMain,
            primaryLight: primary === null || primary === void 0 ? void 0 : primary.light,
            primaryDark: primary === null || primary === void 0 ? void 0 : primary.dark,
            secondaryMain: (secondary === null || secondary === void 0 ? void 0 : secondary.main) || DEFAULT_CSS_COLOR_VARIABLES_VALUES.secondaryMain,
            secondaryLight: secondary === null || secondary === void 0 ? void 0 : secondary.light,
            secondaryDark: secondary === null || secondary === void 0 ? void 0 : secondary.dark,
            backgroundDefault: (background === null || background === void 0 ? void 0 : background.default) || DEFAULT_CSS_COLOR_VARIABLES_VALUES.backgroundDefault,
            backgroundSurface: (background === null || background === void 0 ? void 0 : background.paper) || DEFAULT_CSS_COLOR_VARIABLES_VALUES.backgroundSurface,
            errorMain: (error === null || error === void 0 ? void 0 : error.main) || DEFAULT_CSS_COLOR_VARIABLES_VALUES.errorMain,
            primaryContrastText: primary === null || primary === void 0 ? void 0 : primary.contrastText,
            secondaryContrastText: secondary === null || secondary === void 0 ? void 0 : secondary.contrastText,
            textBackgroundPrimary: (text === null || text === void 0 ? void 0 : text.primary) || DEFAULT_CSS_COLOR_VARIABLES_VALUES.textBackgroundPrimary,
            erorContrastText: error === null || error === void 0 ? void 0 : error.contrastText,
        };
        const cssGlobal = (_a = theme === null || theme === void 0 ? void 0 : theme.css) === null || _a === void 0 ? void 0 : _a.global;
        const newCssGlobal = Object.entries(DEFAULT_CSS_COLOR_VARIABLES_NAME).reduce((result, [propertyValueName, propertyName]) => {
            const existedValue = cssVariablesValues[propertyValueName];
            if (existedValue) {
                const updatedCssBody = CSSParser.updateCSSPropertyFromCSSBodyBySelectors({
                    cssBody: result,
                    selectors: [':root'],
                    value: existedValue,
                    propertyName,
                });
                return updatedCssBody;
            }
            return result;
        }, cssGlobal);
        return Object.assign(Object.assign({}, appDSL), { theme: Object.assign(Object.assign({}, theme), { css: Object.assign(Object.assign({}, theme === null || theme === void 0 ? void 0 : theme.css), { global: newCssGlobal }) }) });
    }
}
export const migration_0_14_0 = new Migration_0_14_0();
