import { useCallback, useMemo, useState, useEffect } from 'react';

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TextField, Chip, AutocompleteProps as MuiAutocompleteProps } from '@mui/material';

import { usePrevious, isArray, isEmptyString } from '@builder/utils';

import { InputContainer, InputContainerProps } from '../common';

import {
  MuiAutocompleteAction,
  StyledMuiAutocomplete,
  StyledMuiBox,
  StyledPaper,
} from './Autocomplete.styles';

export type AutocompleteOption<T extends string | number | boolean = string | number | boolean> = {
  value: T;
  label: string;
};
type AutocompleteValue = string | number | readonly string[] | readonly number[] | undefined;
type AutocompleteProps<T extends AutocompleteValue> = Omit<
  MuiAutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined>,
  'value' | 'onChange' | 'renderInput' | 'options'
> &
  InputContainerProps & {
    options: AutocompleteOption[];
    value: T;
    onChange: (value: T) => void;
    required?: boolean;
    actionEventDesign?: boolean;
    placeHolder?: string;
  };

export const Autocomplete = <T extends AutocompleteValue>({
  label,
  error,
  getOptionLabel,
  value: rawValue,
  onChange,
  options,
  required,
  multiple,
  variant,
  icon,
  isTextIcon,
  helperText,
  enableFx,
  isFxEnabled,
  size,
  showFx,
  freeSolo,
  'data-test': dataTest,
  actionEventDesign,
  placeHolder,
  ...props
}: AutocompleteProps<T>): JSX.Element => {
  let optionsToShow = options;
  const initialInputValue = { label: '', value: '' } as Record<string, unknown>;
  const [eventType, setEventType] = useState('');
  const [inputValue, setInputValue] = useState(initialInputValue);

  const currentValue = useMemo<unknown>(() => {
    if (freeSolo) {
      return (
        options?.find(opt => opt.value === rawValue) ||
        ({
          label: rawValue || '',
          value: rawValue || '',
        } as AutocompleteOption)
      );
    }

    return isArray(rawValue)
      ? (options.filter(opt =>
          rawValue.find(arrValue => opt.value === arrValue),
        ) as AutocompleteOption[])
      : (options.find(opt => opt.value === rawValue) as AutocompleteOption);
  }, [rawValue, options, freeSolo]);

  const previousValue = usePrevious(currentValue);

  // Filter options if they are already selected to prevent a required state from being removed
  if (isArray(rawValue)) {
    optionsToShow = options.filter(option => !rawValue.includes(option.value));
  }

  useEffect(() => {
    if (currentValue && Object.keys(currentValue).length) {
      setEventType((currentValue as AutocompleteOption)?.label || '');
    }
  }, [currentValue]);

  useEffect(() => {
    if (!currentValue && previousValue && eventType) {
      setEventType('');
    }
  }, [eventType, currentValue, previousValue]);

  const handleChange = useCallback(
    (_: unknown, val: unknown) => {
      if (freeSolo) {
        const currentVal = val as AutocompleteOption & {
          eventType?: string;
        };

        if (currentVal?.eventType) {
          // Create a new value from the user input
          onChange(currentVal?.eventType as T);
        } else {
          onChange((currentVal?.value || currentVal) as T);
        }
      } else {
        if (isArray(val)) {
          if (!val) {
            return;
          }

          onChange(
            ((val as AutocompleteOption[])?.map(
              (arrValue: AutocompleteOption) => arrValue.value,
            ) as unknown) as T,
          );
        }

        onChange((val as AutocompleteOption)?.value as T);
      }
    },
    [onChange, freeSolo],
  );

  const getCustomInputProps = (inputProps: React.InputHTMLAttributes<HTMLInputElement>) => {
    const placeHolderText = placeHolder || 'Choose Action';
    const innerInputProps = { ...inputProps };
    delete innerInputProps.value;
    const { value: inputPropValue } = inputProps;

    return {
      inputProps: {
        value: isEmptyString(inputPropValue as string) ? placeHolderText : inputPropValue,
        ...innerInputProps,
      },
    };
  };

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: unknown) => {
    const inputSize = Object.keys(newInputValue as Record<string, unknown>).length;

    if (inputSize > 0) {
      if (typeof newInputValue === 'string') {
        setInputValue({ label: newInputValue as string, value: newInputValue as string });
      } else {
        setInputValue(newInputValue as Record<string, unknown>);
      }
    } else {
      setInputValue(initialInputValue);
    }
  };

  return (
    <InputContainer
      label={actionEventDesign ? '' : label}
      variant={variant}
      error={error}
      icon={icon}
      isTextIcon={isTextIcon}
      helperText={helperText}
      enableFx={enableFx}
      isFxEnabled={isFxEnabled}
      data-test={dataTest}
      showFx={actionEventDesign ? false : showFx}
    >
      <Choose>
        <When condition={actionEventDesign}>
          <StyledMuiBox>
            <DragIndicatorIcon />
            <MuiAutocompleteAction
              disableClearable={required}
              renderInput={({ inputProps, ...rest }) => {
                return (
                  <TextField
                    {...getCustomInputProps(inputProps)}
                    {...rest}
                    fullWidth
                    error={error}
                    variant="standard"
                    required={required}
                    size={size}
                  />
                );
              }}
              options={optionsToShow ?? []}
              getOptionLabel={option => `${(option as AutocompleteOption).label}`}
              data-test={dataTest}
              onChange={handleChange}
              value={currentValue || null}
              onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
              inputValue={inputValue?.value as string}
              multiple={multiple}
              PaperComponent={StyledPaper as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
              isOptionEqualToValue={(option, value) =>
                (option as AutocompleteOption)?.value === (value as AutocompleteOption)?.value
              }
              size={size}
              freeSolo={freeSolo}
              {...props}
            />
          </StyledMuiBox>
        </When>
        <Otherwise>
          <StyledMuiAutocomplete
            disableClearable={required}
            renderInput={params => {
              if (placeHolder !== undefined) {
                const { inputProps, ...rest } = params;
                return (
                  <TextField
                    {...getCustomInputProps(inputProps)}
                    {...rest}
                    fullWidth
                    error={error}
                    variant="outlined"
                    required={required}
                    size={size}
                  />
                );
              }

              return (
                <TextField
                  {...params}
                  fullWidth
                  error={error}
                  variant="outlined"
                  required={required}
                  size={size}
                />
              );
            }}
            options={optionsToShow ?? []}
            getOptionLabel={option => `${(option as AutocompleteOption).label}`}
            data-test={dataTest}
            onChange={handleChange}
            value={currentValue || null}
            onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
            inputValue={inputValue?.value as string}
            multiple={multiple}
            PaperComponent={StyledPaper as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            popupIcon={<KeyboardArrowDownIcon />}
            renderTags={(values, getTagProps) =>
              values.map((option: unknown, index: number) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option as string}
                  variant="outlined"
                  label={option as string}
                  deleteIcon={<ClearIcon />}
                />
              ))
            }
            isOptionEqualToValue={(option, value) =>
              (option as AutocompleteOption)?.value === (value as AutocompleteOption)?.value
            }
            size={size}
            freeSolo={freeSolo}
            {...props}
          />
        </Otherwise>
      </Choose>
    </InputContainer>
  );
};
