import { omit } from 'ramda';
import { assetListSelectors, } from '@builder/schemas';
import { serialize } from '@builder/utils';
import { AssetGeneratorFactory } from './AssetGeneratorFactory';
export class AssetsGenerator {
    constructor({ appDSL, assetBackendList, useAssetDirectImports, }) {
        this.assetListDSL = appDSL.assets || {};
        this.assetBackendList = assetBackendList;
        this.useAssetDirectImports = useAssetDirectImports;
    }
    getAssetList() {
        const assetFileArrayDSL = assetListSelectors.getAssetFileWithBackendDataArray(this.assetListDSL, {
            assetBackendArray: Object.values(this.assetBackendList),
        });
        return assetFileArrayDSL.reduce((acc, assetFileDSL) => {
            return Object.assign(Object.assign({}, acc), { [assetFileDSL.name]: assetFileDSL });
        }, {});
    }
    generateAssetArray() {
        const assetFileArrayDSL = assetListSelectors.getAssetFileWithBackendDataArray(this.assetListDSL, {
            assetBackendArray: Object.values(this.assetBackendList),
        });
        const assetDeployArray = assetFileArrayDSL.map(assetFileDSL => {
            const assetGenerator = AssetGeneratorFactory.create(assetFileDSL);
            return {
                url: assetFileDSL.src,
                path: assetGenerator.getRelativePath(),
            };
        });
        return serialize.stringify(assetDeployArray, { pretty: true });
    }
    generateAssetList() {
        const assetFileArrayDSL = assetListSelectors.getAssetFileWithBackendDataArray(this.assetListDSL, {
            assetBackendArray: Object.values(this.assetBackendList),
        });
        const assetFileListDSL = assetFileArrayDSL.reduce((acc, assetFileDSL) => {
            return Object.assign(Object.assign({}, acc), { [assetFileDSL.name]: omit(['id', 'parentID', 'backendFileID'], assetFileDSL) });
        }, {});
        if (!this.useAssetDirectImports) {
            return serialize.stringify(assetFileListDSL, { pretty: true });
        }
        return `{ 
      ${Object.values(assetFileListDSL)
            .map(assetFileDSL => {
            const assetGenerator = AssetGeneratorFactory.create(assetFileDSL);
            return `${assetFileDSL.name}: {
          "src": ${assetGenerator.getImportData().varName},
          "previewUrl": ${assetGenerator.getImportData().varName}, ${serialize
                .stringify(omit(['src', 'previewUrl'], assetFileDSL))
                .slice(1, -1)
                .trim()}
        }
        `;
        })
            .join(',')} 
    }`;
    }
}
