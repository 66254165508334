import { Migration } from '../Migration';
class Migration_0_16_3 extends Migration {
    constructor() {
        super(...arguments);
        this.toVersion = '0.16.3';
    }
    getChangeList() {
        return ['change ownership of local states from content node to wrapper node'];
    }
    migrateAppDSL(appDSL) {
        const newDSL = Object.assign({}, appDSL);
        const nodes = Object.assign({}, newDSL.nodes);
        const states = Object.assign({}, newDSL.states);
        const statesEntries = Object.entries(states);
        const newNodesValues = Object.entries(nodes).map(([nodeKey, nodeValue]) => {
            var _a, _b;
            if (nodeValue.props.path) {
                if (nodeValue.props.children !== undefined) {
                    if (nodeValue.props.children.nodes !== undefined) {
                        const childrenKey = nodeValue.props.children
                            .nodes[0];
                        if (nodes[childrenKey].states) {
                            const newNodeValue = Object.assign({}, nodeValue);
                            newNodeValue.states = [
                                ...((_a = nodeValue.states) !== null && _a !== void 0 ? _a : []),
                                ...((_b = nodes[childrenKey].states) !== null && _b !== void 0 ? _b : []),
                            ];
                            for (let i = 0; i < newNodeValue.states.length; i++) {
                                const stateNode = newNodeValue.states[i];
                                for (let z = 0; z < statesEntries.length; z++) {
                                    const [, stateValue] = statesEntries[z];
                                    if (stateValue.id === stateNode.stateID) {
                                        stateValue.parent = newNodeValue.id;
                                    }
                                }
                            }
                            delete nodes[childrenKey].states;
                            return [nodeKey, newNodeValue];
                        }
                    }
                }
            }
            return [nodeKey, nodeValue];
        });
        const HOME_PAGE_WRAPPER_ID = 'EmptyPageRootRoute_ID';
        for (let i = 0; i < statesEntries.length; i++) {
            const [, stateValue] = statesEntries[i];
            let isConneted = false;
            for (let k = 0; k < newNodesValues.length; k++) {
                const [, nodeValue] = newNodesValues[k];
                const newNodeValue = nodeValue;
                if (newNodeValue.states) {
                    if (newNodeValue.states.find(state => state.stateID === stateValue.id)) {
                        isConneted = true;
                    }
                }
            }
            if (!isConneted) {
                stateValue.parent = HOME_PAGE_WRAPPER_ID;
            }
        }
        return Object.assign(Object.assign({}, newDSL), { nodes: Object.fromEntries(newNodesValues), states: Object.fromEntries(statesEntries) });
    }
}
export const migration_0_16_3 = new Migration_0_16_3();
