import { useAuth } from '@8base-react/auth';
import { useQuery } from '@apollo/client';

import {
  CurrentTeamMemberQuery,
  CurrentTeamMemberQueryVariables,
  TeamMemberFragment,
} from '../__generated__';
import { CURRENT_TEAM_MEMBER_QUERY } from '../fragments';

import { useCurrentWorkspace } from './useCurrentWorkspace';

type UseCurrentTeamMemberReturn = {
  teamMember?: TeamMemberFragment | null;
  loading: boolean;
};

// validate permisions to frontend workspace
export const useCurrentTeamMember = (): UseCurrentTeamMemberReturn => {
  const { isAuthorized } = useAuth();
  const { backendWorkspaceId } = useCurrentWorkspace();
  const { workspace } = useCurrentWorkspace();

  const { data, loading } = useQuery<CurrentTeamMemberQuery, CurrentTeamMemberQueryVariables>(
    CURRENT_TEAM_MEMBER_QUERY,
    {
      variables: {
        filter: {
          workspaceId: backendWorkspaceId,
        },
      },
      skip: !isAuthorized || !workspace?.id,
    },
  );

  return {
    teamMember: data?.system?.environmentMember,
    loading,
  };
};
