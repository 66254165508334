import { flattenDeep } from 'lodash';

import { ComponentSettingDSL, componentSettingSelectors } from '@builder/schemas';

export const getSettingKeypathByLabel = (
  label: string,
  name: string | undefined,
  settings: ComponentSettingDSL[],
): (string | number)[] | undefined => {
  const flatted = flattenDeep(
    settings.map(currentSetting => {
      if ('children' in currentSetting) {
        return currentSetting.children;
      }

      return currentSetting;
    }),
  );

  const settingDSL: ComponentSettingDSL | undefined = flatted.find(x => {
    if (name && x.name === name) {
      return true;
    }

    if (!name && 'label' in x && x.label === label) {
      return true;
    }

    return false;
  });

  if (!settingDSL) return undefined;

  const keyPath = componentSettingSelectors.getSettingsKeyPath(settingDSL);

  return keyPath;
};
