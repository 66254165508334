import React from 'react';

import { TypographyProps } from '@mui/material';
import { DateTime } from 'luxon';

import { AssetFileWithBackendDataDSL } from '@builder/schemas';
import { formatFileSize } from '@builder/utils';

import { MetaDescriptionText } from './AssetMeta.styles';

type AssetMetaCommonProps = {
  asset: AssetFileWithBackendDataDSL;
  textAlign?: TypographyProps['align'];
};

export const AssetMetaCommon: React.FC<AssetMetaCommonProps> = ({ asset, textAlign }) => {
  const formattedCreatedAt = asset.createdAt
    ? DateTime.fromISO(asset.createdAt).toFormat('MMMM dd, yyyy')
    : '';

  const formattedUpdatedAt = asset.updatedAt
    ? DateTime.fromISO(asset.updatedAt).toFormat('MMMM dd, yyyy')
    : '';

  return (
    <>
      <MetaDescriptionText
        textAlign={textAlign}
        data-test="assets.assetPropertiesPopper.metaData.type"
      >
        Type: {asset?.mimeType}
      </MetaDescriptionText>
      <MetaDescriptionText
        textAlign={textAlign}
        data-test="assets.assetPropertiesPopper.metaData.fileSize"
      >
        File size: {formatFileSize(asset?.size)}
      </MetaDescriptionText>
      <MetaDescriptionText
        textAlign={textAlign}
        data-test="assets.assetPropertiesPopper.metaData.uploadedOn"
      >
        Uploaded on: {formattedCreatedAt}
      </MetaDescriptionText>
      <MetaDescriptionText
        textAlign={textAlign}
        data-test="assets.assetPropertiesPopper.metaData.updatedOn"
      >
        Updated on: {formattedUpdatedAt}
      </MetaDescriptionText>
      <MetaDescriptionText
        textAlign={textAlign}
        data-test="assets.assetPropertiesPopper.metaData.uploadedBy"
      >
        Uploaded by: {asset.createdBy}
      </MetaDescriptionText>
    </>
  );
};
