import { useCallback, useState } from 'react';

import { Event, EventType } from '@8base-private/event-handler';
import { nanoid as uuid } from 'nanoid';

import { AppConfiguration } from '@builder/schemas';

import { useAppDispatch, useDraftEngine } from '../../../providers';
import { UI_BUILDER_EVENTS } from '../../../store';

type UseConfigurationReplaceReturn = {
  replacingConfiguration: boolean;
  replaceConfiguration: (appConfiguration: AppConfiguration) => Promise<void>;
};

export const useConfigurationReplace = (): UseConfigurationReplaceReturn => {
  const send = useAppDispatch();
  const { draftEngine } = useDraftEngine();
  const [loading, setLoading] = useState(false);

  const replaceConfiguration = useCallback(
    async (appConfiguration: AppConfiguration) => {
      if (!draftEngine) return;

      const eventId = uuid();

      const replaceResponseListener = async (event: Event) => {
        if (event.uuid !== eventId) return;

        switch (event.type) {
          case EventType.BroadcastReplace:
            setLoading(false);
            send({
              type: UI_BUILDER_EVENTS.successAppNotify,
              successMessage: `Successfully replaced app configuration`,
            });
            break;
          case EventType.Error:
            setLoading(false);
            send({
              type: UI_BUILDER_EVENTS.errorAppNotify,
              errorMessage: `Couldn't replace app configuration`,
            });
            break;
        }

        draftEngine.removeEventListener(replaceResponseListener);
      };

      draftEngine.addEventListener(replaceResponseListener);

      setLoading(true);
      draftEngine.send({
        type: EventType.Replace,
        uuid: eventId,
        payload: appConfiguration,
      });
    },
    [draftEngine, send],
  );

  return {
    replacingConfiguration: loading,
    replaceConfiguration,
  };
};
