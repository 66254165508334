import { appDSLSelectors, routeHooksSelectors, stateListSelectors, } from '@builder/schemas';
export class RouteHooksGenerator {
    constructor(appDSL) {
        this.appDSL = appDSL;
        this.routeHooksDSL = appDSLSelectors.getRouteHooksDSL(this.appDSL);
    }
    getRequestName(requestID) {
        const { name } = stateListSelectors.getStateDSL(this.appDSL.states, { id: requestID });
        return name;
    }
    generateUseAfterEachHookCode() {
        const afterEachHook = routeHooksSelectors.getAfterEachHook(this.routeHooksDSL);
        const afterEach = Object.assign(Object.assign({}, afterEachHook), { requestName: afterEachHook.request ? this.getRequestName(afterEachHook.request) : null, functionName: afterEachHook.function ? this.getRequestName(afterEachHook.function) : null });
        return `
      import React, { Fragment } from 'react';
      import { css, SerializedStyles } from '@emotion/react'

      export const useAfterEach = () => {
        return ${JSON.stringify(afterEach)}
      }
    `;
    }
    generateUseBeforeEachHookCode() {
        const beforeEachHook = routeHooksSelectors.getBeforeEachHook(this.routeHooksDSL);
        const beforeEach = Object.assign(Object.assign({}, beforeEachHook), { requestName: beforeEachHook.request ? this.getRequestName(beforeEachHook.request) : null, functionName: beforeEachHook.function ? this.getRequestName(beforeEachHook.function) : null });
        return `
      import React, { Fragment } from 'react';
      import { css, SerializedStyles } from '@emotion/react';

      export const useBeforeEach = () => {
        return ${JSON.stringify(beforeEach)}
      };
  `;
    }
    generateExportsDeclarations() {
        return `
      export { useAfterEach } from './useAfterEach';
      export { useBeforeEach } from './useBeforeEach';
    `;
    }
}
